/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import Measure from 'react-measure';
import moment from 'moment';

import ApStickyBar       from 'common/ApStickyBar/ApStickyBar.js';
import auth              from 'services/Authed/Authed.js';
import api               from 'services/Api/Api.js';
import SvgIcon           from 'common/SvgIcon/SvgIcon.js';
import ApTooltip         from 'common/ApTooltip/ApTooltip.js';
import ApButton          from 'common/ApButton/ApButton.js';
import ApSwitch          from 'common/ApSwitch/ApSwitch.js';
import ApSteps           from 'common/ApSteps/ApSteps.js';
import ApDropdown        from 'common/ApDropdown/ApDropdown.js';
import ApConfirm         from 'common/ApConfirm/ApConfirm.js';
import ApModal           from 'common/ApModal/ApModal.js';
import ComponentEdit     from 'modules/Storage/Components/ComponentEdit/ComponentEdit.js';
import ComponentTooltip  from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';
import PurchaseOrderTooltip  from 'modules/Storage/common/PurchaseOrderTooltip/PurchaseOrderTooltip.js';
import OrderTooltip  from 'modules/Storage/common/OrderTooltip/OrderTooltip.js';

import ApTree from 'common/ApTree/ApTree.js';

import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import { ApInput
       , ApInputStack
       , ApAddon  }      from 'common/ApInput/ApInput.js';

import { formatMoney
       , findItemById
       , onlyNumber
       , errorPopper
       , sqlToDateInput
       , mapTree
       , tr, 
       hasPermissions}       from 'services/Helpers/Helpers.js';

import { getTypeIcon } from 'modules/Storage/common/StorageHelpers.js';

import './Locations.css';

import EditStorageType from './EditStorage/EditStorageType.js';
import InventoryModal from './InventoryModal/InventoryModal';
import ApModalInput from 'common/ApModalInput/ApModalInput';
import { connect } from 'react-redux';
import StorageValue from './Reports/StorageValue';
import StockHistoryModal from '../Components/StockHistory/StockHistoryModal';

class LocationSettings extends React.Component {
    constructor(props)
    {
        super(props);

        this.newCounter = 0;

        this.state = {
            loading: true,
            changesLoading: false,

            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),

            tree: [],

            selectedDetail: 'components_count',
            selectedDetailCumulative: true,

            selectedLocation: null,

            components: [],
            tempComponents: [],
            componentsLoading: false,

            removeLocationConfirmShow: false,
            removeLocationConfirmData: {},

            changesShow: false,
            changesIndex: null,
            confirmChangeShow: false,

            componentEditId: null,
            componentEditShow: false,

            invalid: false,
            locationType: 'S',
            buttonText: tr('show_project_storages'),

            showInventoryModal: false,
            selectedItem: null,
            withPrice: false,
            showMarginInputModal: false,
            marginTop: localStorage.getItem('storage-print-margin-top') ? localStorage.getItem('storage-print-margin-top') : 1,
            marginBottom: localStorage.getItem('storage-print-margin-bottom') ? localStorage.getItem('storage-print-margin-bottom') : 1,
            marginLeft: localStorage.getItem('storage-print-margin-left') ? localStorage.getItem('storage-print-margin-left') : 1,
            marginRight: localStorage.getItem('storage-print-margin-right') ? localStorage.getItem('storage-print-margin-right') : 1,
            marginBetweenHorizontal: localStorage.getItem('storage-print-margin-between-horizontal') ? localStorage.getItem('storage-print-margin-between-horizontal') : 1,
            marginBetweenVertical: localStorage.getItem('storage-print-margin-between-vertical') ? localStorage.getItem('storage-print-margin-between-vertical') : 1,
            cols: this.checkColsRows('cols'),
            rows: this.checkColsRows('rows'),

            historyShow: false,
            historyComponentId: null,
        };

        autoBind(this);
    }

    checkColsRows(type) {
        if (type === 'cols') {
            let cols = 3;
            if (this.props.apStorageSettings.labels_cols) {
                cols = this.props.apStorageSettings.labels_cols;
            } else if (localStorage.getItem('storage-print-cols')) {
                cols = localStorage.getItem('storage-print-cols');
            }
            return cols;
        }
        if (type === 'rows') {
            let rows = 8;
            if (this.props.apStorageSettings.labels_rows) {
                rows = this.props.apStorageSettings.labels_rows;
            } else if (localStorage.getItem('storage-print-rows')) {
                rows = localStorage.getItem('storage-print-rows');
            }
            return rows;
        }


        return 1;
    }

    componentDidMount()
    {
         this.getLocations();
    }

    // ----------------------------------------
    //  Common functions
    // ----------------------------------------

    goToOrder( id )
    {
        window.emitter.emit('goTo', { pathname: `/storage/order/id/${ id }` });
    }

    getLocations()
    {
        this.setState({ loading: true });
        return api({
            method: 'get',
            url: 'storage/location/tree',
        }).then((response) => {
            //console.log('response: ', response);
            this.calculateTree( response.tree, () => {
                this.setState({ loading: false, locationRequestTimestamp: response.timestamp });
            });
        }).catch((error) => {
            let message = tr('get_error');
            this.setState({
                error: message,
                loading: false,
            });
        });
    }

    getStorageLocations()
    {
        // ei käytössä
        this.setState({ loading: true });
        return api({
            method: 'get',
            url: 'storage/location/storageLocations',
        }).then((response) => {
            //console.log('response: ', response);
            this.calculateTree( response, () => {
                this.setState({ loading: false });
            });
        }).catch((error) => {
            let message = tr('get_error');

            this.setState({
                error: message,
                loading: false,
            });
        });
    }

    changeType()
    {
        if (this.state.locationType=='S') 
        {  
            this.setState({locationType:'P'})
            this.setState({buttonText: tr('show_storages')})
            //this.getStorageLocations();
        }
        else
        {
            this.setState({locationType:'S'})
            this.setState({buttonText: tr('show_project_storages')})
            //this.getLocations();
        }
        //this.getLocations();
    }

    getComponents( locationId )
    {
        this.setState({
            componentsLoading: true,
            components: [],
        });

        return api({
            method: 'get',
            url: `storage/location/${ locationId }/components`,
        }).then((response) => {
            this.setState({
                componentsLoading: false,
                components: response.components,
                tempComponents: response.temp,
            });
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            console.error(error);
            this.setState({ componentsLoading: false });
        });

    }

    calculateTree( tree = null, callBack = null )
    {
        if(tree === null)
            tree = this.state.tree.slice(0);
        tree = mapTree( tree, null, ( item ) => {

            let own = {
                components_count:      parseFloat( item.components_count ),
                components_price:      parseFloat( item.components_price ),
                components_price_sell: parseFloat( item.components_price_sell ),
                // components_price_avg: parseFloat( item.components_price_avg ),
            };

            // Clone own stuff to total stuff
            let  all = JSON.parse( JSON.stringify( own ) );


                item.children.forEach(( child ) => {
                    if( child.calculated )
                    {
                        all.components_count      += child.calculated.all.components_count;
                        all.components_price      += child.calculated.all.components_price;
                        all.components_price_sell += child.calculated.all.components_price_sell;
                        // all.components_price_avg += child.calculated.all.components_price_avg;
                    }
                });

                item.calculated = {
                    own: own,
                    all: all,
                }
                if (!item.type || item.type === '')
                    item.type='S';

                return item;

        });

        this.setState({
            tree: tree,
        }, callBack );
    }

    saveTree()
    {
        this.updateSelectedLocationSettings();

        let invalid = false;

        let saveTree = JSON.parse( JSON.stringify( this.state.tree ) );
        saveTree = mapTree( saveTree, null, ( item ) => {
            let components = null;
            if (  Array.isArray( item.components ) )
            {
                components = item.components.map( c => {
                    return {
                        id: c.id,
                        limit_alarm: c.limit_alarm,
                        limit_notification: c.limit_notification,
                    }
                });
            }

            return {
                id: isNaN( parseInt( item.id, 10 ) ) ? null : item.id,
                name: item.name,
                code: item.code,
                type: item.type,
                value: item.value,
                description: item.description,

                can_have_components: item.can_have_components,
                can_take_deliveries: item.can_take_deliveries,

                delivery_address: item.delivery_address,
                delivery_city:    item.delivery_city,
                delivery_contact: item.delivery_contact,
                delivery_country: item.delivery_country,
                delivery_name:    item.delivery_name,
                delivery_zipcode: item.delivery_zipcode,

                components: components,
                children: item.children,
            };
        });

        if( invalid )
        {
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('storage_fields_error') }</strong>,
            });
            return null;
        }

        this.setState({ loading: true });
        return api({
            method: 'post',
            url: 'storage/location/savetree',
            data: { tree: saveTree, timestamp: this.state.locationRequestTimestamp },
        }).then((response) => {
            this.setState({ loading: false });
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });
            this.getLocations();
        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
            errorPopper(error, tr('save_error'));
        });
    }

    // ----------------------------------------
    //  Tree functions
    // ----------------------------------------

    toggleLocationTreeCumulativeDetails()
    {
        this.setState({ selectedDetailCumulative: !this.state.selectedDetailCumulative });
    }

    handleLocationTreeDetailChange( e )
    {
        this.setState({ selectedDetail: e.target.value });
    }

    selectNode( id )
    {
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            item.__selected = false;
            if( id === item.id )
            {
                item.__selected = true;

                if( item.children )
                    item.__open = true;
            }

            return item;
        });

        this.setState({ tree: tree });
    }

    handleLocationSelect( location )
    {
        // Unsaved location id starts with word "new" therefore they cannot be parsed to integer
        const unsaved = isNaN( parseInt( location.id, 10 ) );

        this.updateSelectedLocationSettings();
        this.selectNode( location.id );

        if( location.components )
        {
            const components = JSON.parse( JSON.stringify( location.components ) );
            const tempComponents = JSON.parse( JSON.stringify( location.tempComponents ) );
            this.setState({
                components: components,
                tempComponents: tempComponents,
            });
        }
        else
        {
            if( unsaved )
                this.setState({
                    components: [],
                    tempComponents: [],
                });
            else
                this.getComponents( location.id );
        }
        this.setState({ selectedLocation: location });
    }

    // ----------------------------------------
    //  Location management functions
    // ----------------------------------------

    addLocationClick()
    {
        let tree = this.addLocationToTree( this.state.tree );
        this.calculateTree( tree );

        const location = findItemById( tree, `new${ this.newCounter }` );
        this.handleLocationSelect( location )
    }

    addSubLocationClick( item )
    {
        if(item.type.trim()=='S' || (!item.type) || true )
        {

            let tree = this.addLocationToTree( this.state.tree, item );
            this.calculateTree( tree );

            const location = findItemById( tree, `new${ this.newCounter }` );
            this.handleLocationSelect( location )
        }
        else
        {
            alert(tr('project_storage_sub_error'));
        }
    }

    printLabels( item , price=true, list=false, alv=true, marginsArray=null)
    {
        this.setState({ loading: true });
        let url= `storage/labels/print`;
        if (list) {
            url= `storage/list/print`;
        }
        const margins = {};
        let cols = null;
        let rows = null;
        if (marginsArray) {
            const [marginTop, marginLeft, marginRight, marginBottom, marginBetweenHorizontal, marginBetweenVertical] = marginsArray;

            if (marginsArray.length === 8) {
                cols = !isNaN(parseInt(marginsArray[6])) ? parseInt(marginsArray[6]) : 3;
                rows = !isNaN(parseInt(marginsArray[7])) ? parseInt(marginsArray[7]) : 8;
            }

            margins.marginTop = !isNaN(parseFloat(marginTop)) ? parseFloat(marginTop) : 1;
            margins.marginLeft = !isNaN(parseFloat(marginLeft)) ? parseFloat(marginLeft) : 1;
            margins.marginRight = !isNaN(parseFloat(marginRight)) ? parseFloat(marginRight) : 1;
            margins.marginBottom = !isNaN(parseFloat(marginBottom)) ? parseFloat(marginBottom) : 1;
            margins.marginBetweenVertical = !isNaN(parseFloat(marginBetweenVertical)) ? parseFloat(marginBetweenVertical) : 0;
            margins.marginBetweenHorizontal = !isNaN(parseFloat(marginBetweenHorizontal)) ? parseFloat(marginBetweenHorizontal) : 0;

            localStorage.setItem('storage-print-margin-top', margins.marginTop);
            localStorage.setItem('storage-print-margin-left', margins.marginLeft);
            localStorage.setItem('storage-print-margin-right', margins.marginRight);
            localStorage.setItem('storage-print-margin-bottom', margins.marginBottom);
            localStorage.setItem('storage-print-margin-between-horizontal', margins.marginBetweenHorizontal);
            localStorage.setItem('storage-print-margin-between-vertical', margins.marginBetweenVertical);

            localStorage.setItem('storage-print-cols', cols);
            localStorage.setItem('storage-print-rows', rows);

            this.setState({marginTop, marginLeft, marginRight, marginBottom, marginBetweenVertical, marginBetweenHorizontal, cols, rows});
        }
        api({
            method: 'post',
            url: url,
            responseType: 'blob',
            data: {location: item, price: price, alv: alv, margins, cols, rows}
        }).then(( response ) => {

            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            
            link.setAttribute('download', item.name+'-hyllytarrat.pdf' );
            if (list) {
                link.setAttribute('download', item.name+'-tuotekatalogi.pdf' );
            }
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.setState({ loading: false });

        }).catch( ( error ) => {
            errorPopper(error, 'Tiedoston lataamisessa tapahtui virhe');
            this.setState({ loading: false });
        });

    }

    addLocationToTree( tree, parent = null )
    {
        this.newCounter++;

        let newLocation = {
            id: `new${ this.newCounter }`,
            parent_id: parent ? parent.id : null,
            value: '',
            description: '',
            children: [],
            invalid: true,
        };

        if( parent )
        {
            tree = mapTree( tree, ( item ) => {
                if( item.id === parent.id )
                {
                    item.children.push( newLocation );
                }
                return item;
            });
        }
        else
        {
            tree.push( newLocation );
        }
        return tree;
    }

    removeSubLocationClick( item )
    {
        this.setState({
            removeLocationConfirmShow: true,
            removeLocationConfirmData: item,
        });
    }

    removeSubLocation()
    {
        const location = this.state.removeLocationConfirmData;
        const parentId = location.parent_id;

        let tree = this.state.tree.slice(0);

        if( parentId )
        {
            tree = mapTree( tree, ( item ) => {
                if( item.id === parentId )
                {
                    // Remove the location
                    item.children = item.children.filter( ( child ) => {
                        return ( child.id !== location.id );
                    });
                }
                return item;
            });
        }
        else
        {
            // Remove the root location
            tree = tree.filter( ( child ) => {
                return ( child.id !== location.id );
            });
        }
        this.calculateTree( tree );

        this.setState({
            selectedLocation: null,
            components: [],
        });
    }


    // ----------------------------------------
    //  Location data edit functions
    // ----------------------------------------

    locationInfoChange( e )
    {
        let selectedLocation = { ...this.state.selectedLocation };
        const name = e.target.name;
        let value = e.target.value;
        //let type = e.target.type;

        // Checkboxes
        if( [ 'can_have_components', 'can_take_deliveries' ].includes( name ) )
            selectedLocation[ name ] = !selectedLocation[ name ];
        else
            selectedLocation[ name ] = value;

        this.setState({ selectedLocation });
    }

    /*
     * For performance reasons we only update settings to location tree
     * when selected location is changed or when user clicks save button
     *
     * Otherwise we should loop whole tree structure on each change
     */
    updateSelectedLocationSettings()
    {
        const location = this.state.selectedLocation;
        if(!location)
            return false;

        const components = JSON.parse( JSON.stringify( this.state.components ) );
        const tempComponents = JSON.parse( JSON.stringify( this.state.tempComponents ) );

        const isInvalid = () => {
            if( !location.value ) return true;

            if( location.can_take_deliveries )
            {
                if( !location.delivery_name )    return true;
                if( !location.delivery_address ) return true;
                if( !location.delivery_city )    return true;
                if( !location.delivery_zipcode ) return true;
            }

            let componentError = components.some( c => {
                if ( c.limit_notification === '' )
                    return true;
                if ( c.limit_alarm === '' )
                    return true;
                return false;
            });
            if( componentError ) return true;

            return false;
        }

        this.editLocation({
            id:                  location.id,
            name:                location.name,
            type:                  location.type,
            value:               location.value,
            can_have_components: location.can_have_components,
            can_take_deliveries: location.can_take_deliveries,

            delivery_name:       location.delivery_name,
            delivery_address:    location.delivery_address,
            delivery_city:       location.delivery_city,
            delivery_zipcode:    location.delivery_zipcode,
            delivery_country:    location.delivery_country,
            delivery_contact:    location.delivery_contact,

            components:          components,
            tempComponents:      tempComponents,

            invalid: isInvalid(),
        });
    }

    editLocation( location )
    {
        if( !location.id )
            return false;

        let invalid = false;
        let tree = this.state.tree.slice(0);
        tree = mapTree( tree, ( item, parent ) => {
            if( item.id === location.id )
            {
                for( const prop in location )
                {
                    if( prop === "id" ) continue;
                    item[ prop ] = location[ prop ];
                }
            }

            if( !parent )
                item.code = item.value;
            else
                item.code = `${ parent.code }.${ item.value }`;

            if( item.invalid )
                invalid = true;

            return item;
        });

        this.setState({ invalid: invalid });

        this.calculateTree( tree );
    }

    editLimits( field, index, value )
    {
        if( value !== null )
            value = onlyNumber( value );

        let components = this.state.components.slice( 0 );
        let update = false;
        if (components[index]) {
            const oldValue = components[index][field];
            components[ index ][ field ] = value;
            update = oldValue !== value;
        }

        if (update) {
            this.setState({ components });
        }
    }

    onComponentSave( cEdited )
    {
        const location = this.state.selectedLocation;
        const unsaved = isNaN( parseInt( location.id, 10 ) );
        this.setState({ componentEditShow: false });

        if( !unsaved )
        {
            this.getComponents( location.id );
            window.emitter.emit('popper', {
                type: 'info',
                content: <strong>{ tr('storage_components_changes_reset') }</strong>,
                time: 5000,
            });
        }
    }

    // ----------------------------------------
    //  Rendering functions
    // ----------------------------------------

    renderLocationTree()
    {

        const tree = this.state.tree.slice();

        const renderLocation = ( location, level = 0) => {
            if(!location.type)
                location.type = 'S';
            if(location.type.trim() == this.state.locationType)
            {
                const group = ( this.state.selectedDetailCumulative ? "all" : "own" );

                let classes = ["location"];

                if( location.invalid )
                    classes.push("invalid");

                if( this.state.selectedLocation && this.state.selectedLocation.id == location.id )
                    classes.push("selected");


                let value = false;
                if( this.state.selectedDetail == "components_count" )
                {
                    value = location.calculated[ group ].components_count;
                }
                else if( this.state.selectedDetail == "components_price" )
                {
                    let tmpMoney = location.calculated[ group ].components_price;
                    if( tmpMoney )
                        value = formatMoney( tmpMoney ) + " " + this.state.currencySign;
                }
                else if( this.state.selectedDetail == "components_price_sell" )
                {
                    let tmpMoney = location.calculated[ group ].components_price_sell;
                    if( tmpMoney )
                        value = formatMoney( tmpMoney ) + " " + this.state.currencySign;
                }
                // else if( this.state.selectedDetail == "components_price_avg" )
                // {
                //     let tmpMoney = location.calculated[ group ].components_price_avg;
                //     if( tmpMoney )
                //         value = formatMoney( tmpMoney ) + " " + this.state.currencySign;
                // }
                else if (this.state.selectedDetail === 'last_inventory') {
                    value = location.last_inventory
                        ? moment(location.last_inventory).format('L')
                        : '';
                }

                let canTakeDeliveries = null;
                if( location.can_take_deliveries )
                {
                    canTakeDeliveries = <div className="canTakeDeliveries">
                        <ApTooltip block text={ tr('storage_location_take_deliveries') }>
                            <SvgIcon icon="truck-loading" type="solid" />
                        </ApTooltip>
                    </div>
                }

                let canHaveComponents = null;
                if( location.can_have_components )
                {
                    canHaveComponents = <div className="canHaveComponents">
                        <ApTooltip block text={ tr('storage_location_have_components') }>
                            <SvgIcon icon="cubes" type="solid" />
                        </ApTooltip>
                    </div>
                }


                return <div className="location">

                    { canHaveComponents }
                    { canTakeDeliveries }

                    { location.code }<br />
                    <small>{ location.name }</small>

                    { Boolean( value ) &&
                        <div className="value">{ value }</div>
                    }
                </div>
            }
        };

        return (
             <div className="apLocationTree">

                <div className="detailPicker">
                    <ApInputStack gap="0">
                        <ApAddon noRightBorder width="50px">
                            <ApTooltip block text={ this.state.selectedDetailCumulative ? tr('include_sub_storages') : tr('not_include_sub_storages') } position="right">
                                <div className="apSimpleButton" onClick={ this.toggleLocationTreeCumulativeDetails }>
                                    <SvgIcon icon={ this.state.selectedDetailCumulative ? "folder-open" : "folder" } type="solid" />
                                </div>
                            </ApTooltip>
                        </ApAddon>
                        <ApInput
                            type="select"
                            options={[
                                { value: "components_count", label: tr('components_count') },
                                { value: "components_price", label: tr('components_price') },
                                // { value: "components_price_avg", label: tr('storage_avg_price_buy') },
                                { value: "components_price_sell", label: tr('components_price_sell') },
                                { value: 'last_inventory', label: tr('last_inventory')}
                            ]}
                            id="locationTreeDetail"
                            name="locationTreeDetail"
                            value={ this.state.selectedDetail }
                            onChange={ this.handleLocationTreeDetailChange }
                        />
                    </ApInputStack>
                </div>

                <ApTree
                    tree={ tree }
                    nodeRenderer={ renderLocation }
                    onTreeChange={ this.calculateTree }
                    onNodeClick={ this.handleLocationSelect }
                    locType = {this.state.locationType}
                />
                <hr />

                <ApButton fullWidth color="white" size="small" onClick={ () => this.addLocationClick() } disabled={ this.props.locked || this.state.locationType=='P'}>
                    <SvgIcon icon="plus" type="solid" />
                    { tr('new_storage') }
                </ApButton>
            </div>
        );
    }

    renderLocation()
    {
        const location = this.state.selectedLocation;
        const components = this.state.components;
       
        if( !location )
            return this.renderInfo();
        if( !location.type )
            location.type = 'S'; //Defaulttaa normivarastoon
        
        if(location.type.trim() != this.state.locationType)
            return <div></div>;

        return <div>
            { this.renderSelectedLocationPath() }
            <ApTabs fullWidth>
                <ApTab icon="map-signs" label={ tr('storage_information') }>
                    <div className="padding">
                        { this.renderLocationInfo( location ) }
                    </div>
                </ApTab>
                <ApTab icon="cubes" label={ tr('storage_components') } badge={ components.length ? components.length : null } mountOnSelect>
                    <div className="padding">
                        {this.state.componentsLoading && <div style={{margin: '0 50%'}} className="apInlineLoader"></div>}
                        { this.renderLocationComponents( components ) }
                    </div>
                </ApTab>
                {hasPermissions('storage.locations.edit') && 
                <ApTab icon="chart-pie" label={tr('reports')} mountOnSelect>
                    <div className="padding">
                        <StorageValue locationId={this.state.selectedLocation?.id} />
                    </div>
                </ApTab>}
            </ApTabs>
        </div>
    }

    renderInfo()
    {
        return <div className="apForm">
            <h3 className="apFormHeader">{ tr('storages_storage_locations_edit') }</h3>
            <div className="apFormColumn fullColumn">
                <div className="apInfo small">
                    <SvgIcon icon="sitemap" type="solid" />
                    { tr('sub_storages_info') }
                </div>

                <div className="apInfo small">
                    <SvgIcon icon="cube" type="solid" />
                    { tr('storage_locations_components_info') }
                </div>

                <div className="apInfo small">
                    <SvgIcon icon="save" type="solid" />
                    { tr('storage_locations_edit_info') }
                </div>

                <div className="apInfo small">
                    <SvgIcon icon="truck-loading" type="solid" />
                    { tr('storage_locations_take_deliveries_info1') } <br />
                    { tr('storage_locations_take_deliveries_info2') }
                </div>

                <div className="apInfo small">
                    <SvgIcon icon="cubes" type="solid" />
                    { tr('storage_locations_can_have_components_info1') } <br />
                    { tr('storage_locations_can_have_components_info2') }
                </div>
            </div>
        </div>
    }

    renderSelectedLocationPath()
    {
        const currentLocation = this.state.selectedLocation;
        if( !currentLocation )
            return null;

        const withDropdown = ( item ) => {

            const rootLocation = ( currentLocation.parent_id ? false : true );

            return (
                <div className={ "inner current" + ( rootLocation ? " withIcon" : "" ) }>
                    { rootLocation && <SvgIcon className="stepIcon" icon={ item.icon } type="solid" /> }
                    <strong>{ currentLocation.code }&nbsp;</strong>
                    <small>{ currentLocation.name }&nbsp;</small>

                    <ApDropdown
                        actionId={ currentLocation }
                        actions={[
                            {
                                label: tr('add_sub_storage'),
                                icon: "plus",
                                action: ( item, closeFunc ) => {
                                    this.addSubLocationClick( item );
                                    closeFunc();
                                },
                            },
                            {
                                label: tr('delete_storage'),
                                icon: "trash-alt",
                                // disabled: rootLocation,
                                action: ( item, closeFunc ) => {
                                    this.removeSubLocationClick( item );
                                    closeFunc();
                                }
                            },
                            {
                                label: tr('take_inventory'),
                                icon: "boxes",
                                action: ( item, closeFunc ) => {
                                    this.setState({showInventoryModal: true})
                                    closeFunc();
                                },
                                disabled: isNaN(currentLocation.id)
                            },
                            {
                                label: tr('show_history'),
                                icon: "clipboard-list",
                                action: ( item, closeFunc ) => {
                                    this.setState({historyShow: true, historyComponentId: null})
                                    closeFunc();
                                },
                                disabled: isNaN(currentLocation.id)
                            },
                            {
                                label: tr('print_shelf_stickers_wo_price'),
                                icon: "print",
                                action: ( item, closeFunc ) => {
                                    this.setState({selectedItem: item, showMarginInputModal: true, withPrice: false})
                                    // this.printLabels( item, false );
                                    closeFunc();
                                },
                            },
                            {
                                label: tr('print_shelf_stickers_with_price'),
                                icon: "print",
                                action: ( item, closeFunc ) => {
                                    this.setState({selectedItem: item, showMarginInputModal: true, withPrice: true})
                                    // this.printLabels( item , true );
                                    closeFunc();
                                },
                            },
                            {
                                label: tr('print_shelf_stickers'),
                                icon: "print",
                                action: ( item, closeFunc ) => {
                                    this.printLabels( item, true, true );
                                    closeFunc();
                                },
                            },
                        ]}
                    />
                </div>
            );
        };

        let items = [];
        const iterate = ( id, selected = false ) => {
            const location = findItemById( this.state.tree, id, 'children' );
            if( location )
            {
                items.unshift({
                    icon:           ( !location.parent_id ? "map-signs" : false ),
                    selected:       selected,
                    onClick:        ( selected ? false : () => this.handleLocationSelect( location ) ),
                    name:           location.code,
                    type:           location.type,
                    description:    location.name,
                    customRenderer: ( selected ? withDropdown : false ),
                });

                if( location.parent_id )
                    iterate( location.parent_id );
            }
        };

        iterate( currentLocation.id, true );

        return (
            <ApSteps className="locationPath" steps={ items } />
        )
    }

    renderLocationInfo( location )
    {
        let parentCode = null;
        const parent = findItemById( this.state.tree, location.parent_id, 'children' );
        if( parent )
        {
            parentCode = <ApInput
                id="parent-code"
                name="parent-code"
                width="200px"
                label="Ylempi koodi"
                value={ `${ parent.code}.` }
                disabled={ true }
            />
        }
        const lastInventory = this.state.selectedLocation && this.state.selectedLocation.last_inventory
            ? <div className='apInfo small'><SvgIcon icon='info-circle' type='solid' className='size-small' /> {tr('last_inventory')}: {moment(this.state.selectedLocation.last_inventory).format('L')}</div>
            : null;

        return <div>
            {lastInventory}
            <ApInputStack gap="0">
                { parentCode }

            </ApInputStack>

            <ApInputStack gap="0">
                { parentCode }
                <ApInput
                    type="text"
                    id="name"
                    name="value"
                    label={`${tr('code')} *`}
                    autoComplete="off"
                    value={ location.value }
                    onChange={ this.locationInfoChange }
                    validationState={ location.value ? null : 'error' }
                    disabled={this.state.locationType=='P'}
                />
            </ApInputStack>

            <ApInput
                type="text"
                id="name"
                name="name"
                label={ tr('name') }
                autoComplete="off"
                value={ location.name }
                onChange={ this.locationInfoChange }
            />

            <ApInput
                type="textarea"
                id="description"
                name="description"
                label={ tr('description') }
                autoComplete="off"
                value={ location.description }
                onChange={ this.locationInfoChange }
            />


            <div className="apSwitchBlock">
                <label htmlFor="can_have_components" className="info">
                    { tr('storage_locations_can_have_components') }
                    <small>{ tr('storage_locations_can_have_components_info3') }<br />
                    { tr('storage_locations_can_have_components_info4') }</small>
                </label>
                <ApSwitch
                    name="can_have_components"
                    id="can_have_components"
                    on={ location.can_have_components }
                    onChange={ this.locationInfoChange }
                />
            </div>

            <div className="apSwitchBlock">
                <label htmlFor="can_take_deliveries" className="info">
                    { tr('storage_locations_can_take_deliveries') }
                    <small>{ tr('storage_locations_can_take_deliveries_info') }</small>
                </label>
                <ApSwitch
                    name="can_take_deliveries"
                    id="can_take_deliveries"
                    on={ location.can_take_deliveries }
                    onChange={ this.locationInfoChange }
                />
            </div>
            {/*
                TODO: Tässä tilassa ei kannata laittaa minnekkään.
                Logiikkaa voi jatkaa, jotta varastosta voidaan nopeasti siirtää saldot projektivarastoon.
                <ApButton className="save" color="orange" onClick={ () => this.setState({confirmChangeShow: true})}>
                            <SvgIcon icon="warehouse" type="solid" />
                            Muuta projektivarastoksi
                </ApButton>
            */}
            { Boolean( location.can_take_deliveries ) && this.renderLocationAddress( location ) }
            <ApButton onClick={()=>this.setState({showInventoryModal: true})} color="green">
                <SvgIcon icon='boxes' type='solid' /> {tr('take_inventory')}
            </ApButton>
        </div>
    }

    renderLocationAddress( location )
    {
        const validState = ( field ) => {
            if ( !location[ field ] )
                return 'error';
            return null;
        }

        return <div>
            <div className="apForm">
                <div className="apFormColumn fullColumn">
                    <h3 className="apFormHeader">{ tr('storage_shipping_address') }</h3>

                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('storage_locations_form_autofill_info') }
                    </div>

                    <ApInput
                        type="text"
                        id="delivery_name"
                        name="delivery_name"
                        label={`${tr('recipient')} *`}
                        autoComplete="off"
                        onChange={ this.locationInfoChange }
                        value={ location.delivery_name }
                        validationState={ validState( 'delivery_name' ) }
                    />

                    <div className="receiver-indent">
                        <ApInput
                            type="text"
                            id="delivery_address"
                            name="delivery_address"
                            label={`${tr('address')} *`}
                            autoComplete="off"
                            onChange={ this.locationInfoChange }
                            value={ location.delivery_address }
                            validationState={ validState( 'delivery_address' ) }
                        />

                        <div className="col-container">
                            <div className="col-left">
                                <ApInput
                                    type="text"
                                    id="delivery_zipcode"
                                    name="delivery_zipcode"
                                    label={`${tr('postal_code')} *`}
                                    autoComplete="off"
                                    onChange={ this.locationInfoChange }
                                    value={ location.delivery_zipcode }
                                    validationState={ validState( 'delivery_zipcode' ) }
                                />
                            </div>
                            <div className="col-right">
                                <ApInput
                                    type="text"
                                    id="delivery_city"
                                    name="delivery_city"
                                    label={`${tr('locality')} *`}
                                    autoComplete="off"
                                    onChange={ this.locationInfoChange }
                                    value={ location.delivery_city }
                                    validationState={ validState( 'delivery_city' ) }
                                />
                            </div>
                        </div>

                        <div className="col-left">
                        <ApInput
                            type="text"
                            id="delivery_country"
                            name="delivery_country"
                            label={ tr('country') }
                            autoComplete="off"
                            onChange={ this.locationInfoChange }
                            value={ location.delivery_country }
                        />
                        </div>
                        <ApInput
                            type="text"
                            id="delivery_contact"
                            name="delivery_contact"
                            label={ tr('contact_person') }
                            autoComplete="off"
                            onChange={ this.locationInfoChange }
                            value={ location.delivery_contact }
                        />
                    </div>

                    <EditStorageType
                    show= { this.state.confirmChangeShow }
                    onClose={ () => { this.setState({confirmChangeShow: false })  } }
                    onSave={ () => { } }
                    location = {{ ...this.state.selectedLocation }}
                    />

                </div>
            </div>
        </div>

    }

    renderLimitInput( field, c, cIndex )
    {
        const value = c[ field ];

        if( value === null )
        {
            return <div className="setEnabled" onClick={ () => { this.editLimits( field, cIndex, '' ) } }>
                <ApTooltip text={ tr('storage_locations_limit_not_set') }>
                <input
                    className={ "componentRowInput" }
                    type="text"
                    value=""
                    disabled={ false }
                    onClick={ () => this.editLimits( field, cIndex, '' ) }
                    onChange={ () => {} } // Prevents React warning
                    style={{ cursor: 'pointer' }}
                />
                </ApTooltip>
            </div>
        }
        else
        {
            let inputClass = [ 'componentRowInput' ];
            if( !value ) inputClass.push( 'error' );

            return <div>
                    <div className="setDisabled" onClick={ () => { this.editLimits( field, cIndex, null ) } }>
                        <ApTooltip text={ tr('remove_limit') }>
                        <SvgIcon icon="times-circle" type="solid" />
                        </ApTooltip>
                    </div>
                <input
                    className={ inputClass.join(' ') }
                    type="text"
                    value={ value }
                    onChange={ ( e ) => { this.editLimits( field, cIndex, e.target.value ) } }
                />
                <div className="unit">{ c.unit }</div>
            </div>
        }
    }

   
    renderLocationComponents( components )
    {

            const componentRows = components.map(( c, cIndex ) => {

            const balance = parseFloat(c.balance).toFixed(2);
            const balanceFree = c.balance_free;
            let balanceFreeDom = null;

            if(this.state.selectedLocation.type=='S'  || this.state.selectedLocation.type==null)
            {
                if( balanceFree !== balance )
                {
                    balanceFreeDom = <small className="free">
                        <ApTooltip text={ tr('storage_locations_balance_after') }>
                        { parseFloat(balanceFree).toFixed(2) }
                        </ApTooltip>
                    </small>
                }
            }

            if(this.state.locationType=='S')
            {

            return <tr key={ c.id } className={ "component" }>
                <td className="icon">
                    { this.renderComponentInfo( c ) }
                </td>
                <td className="name">
                    {  c.name }<small className="code">{ c.code }</small>
                </td>

                <td className="fixed balance">
                    { balance }
                    { balanceFreeDom }
                    <div className="unit">{ c.unit }</div>
                </td>

                <td className="fixed">
                    { c.value_per!='-'?formatMoney( c.value_per):c.value_per } {c.value_per!='-'?this.state.currencySign:''}
                </td>

                <td className="fixed">
                    { this.renderLimitInput( 'limit_notification', c, cIndex ) }
                </td>

                <td className="fixed">
                    { this.renderLimitInput( 'limit_alarm', c, cIndex ) }
                </td>

                <td className="">
                    <ApDropdown
                        actionId={ 1 }
                        saveActions={true}
                        actions={[
                            {
                                label: tr('show_storage_component'),
                                icon: "edit",
                                action: ( item, closeFunc ) => {
                                    this.setState({
                                        componentEditShow: true,
                                        componentEditId: c.id,
                                    });
                                    closeFunc();
                                }
                            },
                            {
                                label: tr('show_incoming_changes'),
                                icon: "truck",
                                action: ( item, closeFunc ) => {
                                    this.setState({
                                        changesShow: true,
                                        changesIndex: cIndex,
                                    });
                                    closeFunc();
                                }
                            },
                            {
                                label: tr('show_history'),
                                icon: "clipboard-list",
                                action: ( item, closeFunc ) => {
                                    this.setState({
                                        historyShow: true,
                                        historyComponentId: c.id,
                                    });
                                    closeFunc();
                                }
                            }
                        ]}
                    />
                </td>
            </tr>

            }
            else if(this.state.locationType=='P')
            {

                return <tr key={ c.id } className={ "component" }>
                <td className="icon">
                    { this.renderComponentInfo( c ) }
                </td>
                <td className="name">
                    {  c.name }
                </td>

                <td align="right" className="initial need">
                    { c.limit_notification } 
                   
                </td>

                <td align="right" className="need now">
                    { c.limit_alarm }
                </td>

                <td align="right" className="saldo">
                    { balance }
                </td>
                
                <td className="fixed">
                    { c.value_per!='-'?formatMoney( c.value_per):c.value_per } {c.value_per!='-'?this.state.currencySign:''}
                </td>

                <td align="right" className="coming">
                    { c.order_amount_project && !isNaN(c.order_amount_project) ? parseFloat(c.order_amount_project).toFixed(2) : '0.00' }
                </td>

                <td align="right" className="shortage">
                    { parseFloat(c.balance_free).toFixed(2) }
                </td>

                <td align="right" className="unit">
                    { c.unit }
                </td>

                <td className="">
                    <ApDropdown
                        actionId={ 1 }
                        actions={[
                            {
                                label: tr('show_storage_component'),
                                icon: "edit",
                                action: ( item, closeFunc ) => {
                                    this.setState({
                                        componentEditShow: true,
                                        componentEditId: c.id,
                                    });
                                    closeFunc();
                                }
                            },{
                                label: tr('show_incoming_changes'),
                                icon: "truck",
                                action: ( item, closeFunc ) => {
                                    this.setState({
                                        changesShow: true,
                                        changesIndex: cIndex,
                                    });
                                    closeFunc();
                                }
                            }
                        ]}
                    />
                </td>
            </tr>
 
             }
            });
           
            if(this.state.locationType=='S') //  || this.state.selectedLocation.type==null)
            {
                let table = null;

                if( components && components.length > 0 )
                {
                    table = <table className="componentTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{ tr('name') }</th>
                                <th className="fixed">{ tr('balance') }</th>
                                <th className="fixed">{ tr('po_price') }</th>
                                <th className="fixed">
                                    <ApTooltip position="topright" text={ tr('limit_info') }>
                                        { tr('attention_limit') }
                                    </ApTooltip>
                                </th>
                                <th className="fixed">
                                    <ApTooltip position="topright" text={ tr('limit_info') }>
                                        { tr('alert_limit') }
                                    </ApTooltip>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { componentRows }
                        </tbody>
                    </table>

                    return <div>
                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { tr('storage_locations_components_list_info1') }
                        </div>
                        { table }

                        <div className="bottomInfo apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { tr('storage_locations_components_list_info2') }
                        </div>

                        { this.renderTemporaryComponents() }
                    </div>
                }
            } 
            else if (this.state.locationType=='P')
            {
                let table = null;

                if( components && components.length > 0 )
                {
                    table = <table className="componentTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{ tr('name') }</th>
                                <th className="fixed">{ tr('initial_demand') }</th>
                                <th className="fixed">{ tr('demand_now') }</th>
                                <th className="fixed">{ tr('balance') }</th>
                                <th className="fixed">{ tr('po_price') }</th>
                                <th className="fixed">{ tr('incoming') }</th>
                                <th className="fixed">{ tr('shortage') }</th>
                                <th className="fixed">{ tr('unit') }</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { componentRows }
                        </tbody>
                    </table>

                    return <div>
                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { tr('project_storage_info') }
                        </div>
                        { table }

                    </div>
                }
            }
    }

    renderTemporaryComponents()
    {
        let components = this.state.tempComponents;

        if( components && components.length > 0 )
        {
            const componentRows = components.map(( c, cIndex ) => {
                let targetDom = c.target_final_type;

                if( c.target_final_type === 'location' )
                {
                    targetDom = <div>
                        <SvgIcon icon="archive" type="solid" /> { tr('storage') }
                        <div>{ c.target_final_name }</div>
                    </div>
                }
                else if( c.target_final_type === 'company' )
                {
                    targetDom = <div className="pointer" onClick={ () => { this.goToOrder( c.order_id ) }  }>
                        <SvgIcon icon="building" type="solid" /> { tr('company') }
                        <div>{ c.target_final_name }</div>
                    </div>
                }

                const balance = c.balance;
                const balanceFree = c.balance_free;
                let balanceFreeDom = null;
                if( balanceFree !== balance )
                {
                    balanceFreeDom = <small className="free">
                        <ApTooltip text={ tr('storage_locations_balance_after') }>
                        { balanceFree }
                        </ApTooltip>
                    </small>
                }

                return <tr key={ c.id } className={ "component" }>
                    <td className="icon">
                        { this.renderComponentInfo( c ) }
                    </td>
                    <td className="name">
                        {  c.name }<small className="code">{ c.code }</small>
                    </td>

                    <td className="fixed target">
                        { targetDom }
                    </td>
                    <td className="fixed balance">
                        { balance }
                        { balanceFreeDom }
                        <div className="unit">{ c.unit }</div>
                    </td>
                </tr>
            });

            let table = <table className="componentTable">
                <thead>
                    <tr>
                        <th></th>
                        <th>{ tr('name') }</th>
                        <th className="fixed">{ tr('target') }</th>
                        <th className="fixed">{ tr('balance') }</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    { componentRows }
                </tbody>
            </table>

            return <div className="tempComponentsContainer">
                <h4 className="apFormHeader">{ tr('temporarily_in_storage') }</h4>
                { table }
            </div>
        }
    }

    renderComponentInfo( component )
    {
        const tooltip = <ComponentTooltip component={ component } />
        const icon = <SvgIcon icon={ getTypeIcon( component.type_name ) } type="solid" />
        return <ApTooltip position="top" text={ tooltip }>{ icon }</ApTooltip>
    }


    // send api request to get component coming and needed changes if not already fetched
    getChanges(component) {
        if (component && !component.changes) {
            this.setState({changesLoading: true})
            api({
                url: `storage/location/${this.state.selectedLocation.id}/component/${this.state.components[ this.state.changesIndex ].id}/changes`
            }).then(response => {
                let components = this.state.components.slice(0);
                components = components.map((c) => {
                    if (c.id === component.id) {
                        c.changes = response;
                    }
                    return c;
                });
                this.setState({ components: components });
            }).catch(error => {
                errorPopper(error, tr('get_error'));
            }).finally(()=> {
                this.setState({changesLoading: false})
            });
        }
    }

    renderChangesModal()
    {
        // if( !this.state.changesShow )
        //     return null;

        let component = this.state.components[ this.state.changesIndex ];

        let commingTable = null;
        let neededTable = null;

        if( component?.changes?.coming?.length )
        {
            let changeRows = component.changes.coming.map( change => {
                const tooltip = <PurchaseOrderTooltip po={ change.po } />
                const icon = <SvgIcon icon={ getTypeIcon( 'po' ) } type="solid" />
                return <tr>
                    <td className="type">
                        <ApTooltip position="right" text={ tooltip }>
                            { icon }
                            <span>{ tr('purchase_order') }</span>
                            <span>{ change.po ? change.po.number : null }</span>
                        </ApTooltip>
                    </td>
                    <td className="count text-right">
                        { tr('arrives') } { parseFloat(change.count).toFixed(2) } { component.unit }
                    </td>
                    <td className="created text-right">
                        { sqlToDateInput( change.po ? change.po.date : null ) }
                    </td>
                    <td className="delivery_date text-right">
                        { sqlToDateInput( change.po ?  change.po.delivery_date : null ) }
                    </td>
                </tr>
            });
            commingTable = <table className="changesTable">
                <thead>
                    <tr>
                        <th>{ tr('incoming_goods') }</th>
                        <th className="fixed text-right count">{ tr('change') }</th>
                        <th className="fixed text-right created">{ tr('date') }</th>
                        <th className="fixed text-right delivery_date">{ tr('delivery_date') }</th>
                    </tr>
                </thead>
                <tbody>
                    { changeRows }
                </tbody>
            </table>
        }

        if( component?.changes?.needed?.length )
        {
            let changeRows = component.changes.needed.map( change => {
                const tooltip = <OrderTooltip order={ change.order } />
                const icon = <SvgIcon icon={ getTypeIcon( 'order' ) } type="solid" />
                return <tr>
                    <td className="type">
                        <ApTooltip position="right" text={ tooltip }>
                            { icon }
                            <span>{ tr('order') }</span>
                            <span>{ change.order ? change.order.number : null }</span>
                        </ApTooltip>
                    </td>
                    <td className="count text-right">
                        { tr('outgoing') } { parseFloat(change.count).toFixed(2) } { component.unit }
                    </td>
                    <td className="created text-right">
                        { sqlToDateInput( change.order ? change.order.date : null ) }
                    </td>
                    <td className="delivery_date text-right">
                        { sqlToDateInput( change.order ?  change.order.need_date : null ) }
                    </td>
                </tr>
            });
            neededTable = <table className="changesTable">
                <thead>
                    <tr>
                        <th>{ tr('necessary_goods') }</th>
                        <th className="fixed text-right count">{ tr('change') }</th>
                        <th className="fixed text-right created">{ tr('date') }</th>
                        <th className="fixed text-right delivery_date">{ tr('day_of_need') }</th>
                    </tr>
                </thead>
                <tbody>
                    { changeRows }
                </tbody>
            </table>
        }

        let body = this.state.changesLoading
            ? <div className="apInlineLoader"></div>
            : <div id="">
                {commingTable}
                <br />
                <br />
                {neededTable}

                <div className="countWrapper">
                    <div>
                        {tr('balance_now')}: <span className="count">{parseFloat(component?.balance).toFixed(2)}</span> {component?.unit}
                    </div>
                    <div>
                        {tr('balance_after')}: <span className="count">{parseFloat(component?.balance_free).toFixed(2)}</span> {component?.unit}
                    </div>
                </div>
            </div>;

        return <div className="changesModal">
            <ApModal
                show={ this.state.changesShow }
                handleClose={ () => { this.setState({ changesShow: false }) } }
                closeFromBg
                header={
                    <div className="padding">
                        <h3>{ tr('incoming_changes') }</h3>
                    </div>
                }
                body={body}
                onOpen={() => this.getChanges(component)}
            />
        </div>
    }

    closeInventoryModal(refresh = false) {
        this.setState({showInventoryModal: false});

        if (refresh) {
            this.getLocations();
            this.getComponents(this.state.selectedLocation.id);
        }
    }

    render()
    {
        let body = null;

        if( this.state.loading )
        {
            body = <div className="apBox errorMessage">
                <div className="apInlineLoader"></div>
            </div>
        }

        else if( this.state.error )
        {
            body = <div className="apBox errorMessage">
                { this.state.error }
            </div>
        }
        else
        {
            body = <div style={{overflowX: 'auto'}}>
                <div className="splitView">
                    <div className="left">
                        <div className="supplierList ">
                        { this.renderLocationTree() }
                        </div>
                    </div>
                    <div className="right">
                        <div className="details">
                            <div className="padding">
                                { this.renderLocation() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        return <div id="pageLocations">
            <Measure
                onResize={ () => {
                    if( this.stickyBar )
                        this.stickyBar.updateBounds();
                }}
            >
            {({ measureRef }) => <div ref={ measureRef }>
                    <div className="apBox">
                        <div className="apBoxHeader">
                            <h1>
                                { tr('storages_storage_locations') }
                            </h1>
                            <p> { tr('storages_storage_locations_info') } </p>
                        </div>
                    </div>
                    <ApTabs onChange={ this.changeType }>
                        <ApTab  icon={ getTypeIcon( 'work' ) } label={ tr('storages') }>
                        </ApTab>
                        <ApTab  icon={ getTypeIcon( 'item' ) } label={ tr('project_storages') }>
                        </ApTab>
                    </ApTabs>
                    { body }
                    <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar = node }>
                        <div className="saveBar">
                            <ApButton color="blue" onClick={ this.saveTree } loading={this.state.loading}>
                                <SvgIcon icon="save" type="solid" />
                                { tr('save') }
                            </ApButton>
                        </div>
                    </ApStickyBar>
                </div>
            }
            </Measure>

            { this.renderChangesModal() }

            { this.state.removeLocationConfirmShow && <ApConfirm
                show={ this.state.removeLocationConfirmShow }
                onClose={ () => { this.setState({ removeLocationConfirmShow: false } ) } }
                onConfirm={ this.removeSubLocation }
                header={ `${tr('delete_confirm')} ${tr('storage')}?` }
                body={<div>{ tr('delete_storage_confirm', [this.state.removeLocationConfirmData.code]) }</div>}
            /> }

            <ComponentEdit
                id={ this.state.componentEditId }
                show={ this.state.componentEditShow }
                onClose={() => {this.setState({componentEditShow: false})}}
                onSave={ this.onComponentSave }
            />

            <StockHistoryModal 
                show={this.state.historyShow}
                componentID={this.state.historyComponentId}
                locationID={this.state.selectedLocation?.id}
                onClose={() => {this.setState({historyShow: false, historyComponentId: null})}}
            />

            <InventoryModal 
                show={this.state.showInventoryModal}
                close={this.closeInventoryModal}
                components={this.state.components}
                location={this.state.selectedLocation}
            />

            <ApModalInput 
                show={this.state.showMarginInputModal}
                title={ tr('margins') }
                value={[
                    this.state.marginTop,
                    this.state.marginLeft,
                    this.state.marginRight,
                    this.state.marginBottom,
                    this.state.marginBetweenHorizontal,
                    this.state.marginBetweenVertical,
                    this.state.cols,
                    this.state.rows
                ]}
                label={[
                    `${tr('top_margin')} (${tr('millimeters_abbrv')})`,
                    `${tr('left_margin')} (${tr('millimeters_abbrv')})`,
                    `${tr('right_margin')} (${tr('millimeters_abbrv')})`,
                    `${tr('bottom_margin')} (${tr('millimeters_abbrv')})`,
                    `${tr('between_margin_horizontal')} (${tr('millimeters_abbrv')})`,
                    `${tr('between_margin_vertical')} (${tr('millimeters_abbrv')})`,
                    tr('columns'),
                    tr('rows')
                ]}
                type={[
                    'number',
                    'number',
                    'number',
                    'number',
                    'number',
                    'number',
                    'number',
                    'number'
                ]}
                step={[
                    0.1,
                    0.1,
                    0.1,
                    0.1,
                    0.1,
                    0.1,
                    1,
                    1
                ]}
                onSave={ ( values ) => this.printLabels(this.state.selectedItem, this.state.withPrice, false, true, values) }
                onClose={ () => this.setState({ showMarginInputModal: false, selectedItem: null }) }
            />

        </div>


    }
}

const mapStateToProps = (state) => ({
    apStorageSettings: state.apStorageSettings,
})

export default connect(mapStateToProps)(LocationSettings);
