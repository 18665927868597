import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { tr } from 'services/Helpers/Helpers.js'
import ApButton     from 'common/ApButton/ApButton.js';
import { ApInput }  from 'common/ApInput/ApInput.js';

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import './CommentDialog.css';
import ApSwitch from 'common/ApSwitch/ApSwitch';

class CommentDialog extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            comment: '',
            accepted:false,
        };
        autoBind(this);
    }

    resetState()
    {
        this.setState({
            comment: '',
            accepted:false,
        } );
    }

    save()
    {
        const comment = this.state.comment ? this.state.comment : null;
        this.props.onSave(this.props.meta.action, comment );
    }

    render()
    {
        const meta = this.props.meta;
        if( !meta ) return false;

        const icon = ( meta.icon ) ? <SvgIcon icon={ meta.icon } type="solid" /> : null

        return <div className="commentDialog">
            <ApModal
                show={ this.props.show }
                handleClose={ this.props.onClose }
                className="narrow"
                onOpen={ this.resetState }
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('comment') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <ApInput
                            type="textarea"
                            id="comment"
                            name="comment"
                            label={ tr('comment_to_event_log') }
                            autoComplete="off"
                            value={ this.state.comment }
                            onChange={  ( e ) => this.setState( { comment: e.target.value } ) }
                        />
                        {this.props.meta.action==="save"&& this.props.askDates &&<div>
                            <h4>{tr("is_the_information_correct")}</h4>
                            <p style={{fontWeight:"bold"}}>{tr("date") + ": "}{this.props.dates.date} <br />
                                {tr("due_date") + ": "} {this.props.dates.due_date}</p>
                            
                            <div className="apSwitchBlock" style={{ paddingBottom: "30px" }}>
                                <ApSwitch
                                id={"accepted"}
                                
                                on={this.state.accepted}
                                onChange={() => { this.setState({accepted:!this.state.accepted}) }}
                                disabled={false}
                                />
                            </div>

                            
                        </div>}
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton onClick={ () => { this.props.onClose() } }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton
                            className="comment-submit"
                            color={ meta.color }
                            onClick={this.save}
                            disabled={this.props.meta.action === "save" ? (this.props.askDates?!this.state.accepted:false):false}
                        >
                            { icon }
                            { meta.text }
                        </ApButton>

                    </div>
                }
            />
        </div>
    }
}

CommentDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    meta: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default CommentDialog;
