import React from 'react';
import Timeline, { 
    TimelineHeaders, 
    DateHeader, 
    TimelineMarkers, 
    TodayMarker, 
    CustomMarker, 
    SidebarHeader } from 'new-react-calendar-timeline'
import 'new-react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import autoBind from 'react-autobind';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import './Personnel.css';
import PersonnelModal from './PersonnelModal.js';
import { errorPopper, tr }  from 'services/Helpers/Helpers.js'
import ApModal            from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApTooltip from 'common/ApTooltip/ApTooltip';

class Personnel extends React.Component {

    constructor( props )
    {
        super( props );

        this.state = {

            defaultTimeStart: moment().startOf('isoWeek'), 
            defaultTimeEnd: moment().startOf('isoWeek').add(14, 'days'), 
            selectedItem: null,
            showPersonnelModal: false,
            showAssignmentsModal: false,

            add_assignments: [],
            add_assignments_ids: [],

            showHourLabel: false,
            dragSnapMode: 24 * 60 * 60 * 1000, // daily
            itemHeightRatio: 0.8,
            lineHeight: 60
        }
        autoBind(this)
    }

    componentDidUpdate(props)
    {
        // if (props.items !== this.props.items)
        // {
        //     this.props.calculateGroupValues(this.props.items)
        // }
    }

    toggleGroupChildren( group )
    {
        let { groups, openGroups } = this.props
        let newItems = group.children
        
        if (group && group.children)
        {
            if (!group.open)
            {
                group.children.forEach(child => {
                    openGroups.push(child.id)
                })
            }
            else
            {
                const loopItems = ( array ) => {
                    if( Array.isArray( array ) )
                    {
                        for( let i = 0; i < array.length; i++ )
                        {
                            const item = array[i];
                            const itemIndex = openGroups.indexOf(item.id)

                            if (openGroups.includes(item.id))
                            {
                                openGroups.splice(itemIndex, 1)
                                item.open = false;
                            }
        
                            if( item.children )
                                loopItems( item.children );
                        }
                    }
                    return array;
                };
                
                loopItems( newItems, group.id );

            }
            let newGroups = groups.map(item => {
                if (!openGroups.includes(item.id)) {
                    return (
                        Object.assign({}, item, {
                            open: false
                        })
                    )
                }
                if (group.id === item.id)
                {
                    return (
                        Object.assign({}, item, {
                            open: item.open ? false : true
                        })
                    )
                }
                else
                {
                    return item
                }
            })

            let openParentGroups = []
            newGroups.forEach(group => {
                if (group.open)
                {
                    openParentGroups.push(group.id)
                }
            })

            this.props.updateOpenPersonnelGroups(openGroups);
            this.props.updateOpenPersonnelParentGroups(openParentGroups);
            this.props.updatePersonnelGroups(newGroups);
        }
    }

    handleItemMove (itemId, dragTime, newGroupOrder)
    {
        let { items, groups, openGroups } = this.props;
        let newGroups = [...groups].filter(g => openGroups.includes(g.id));
        const group = newGroups[newGroupOrder];
        let freeItemUpdated, hoursDaily, hoursWeekly, freeItemValue, otherWeekItem;

        items = this.props.summary ? this.props.allItems : items

        // check for daylight saving time change
        if (moment().isDST() && !moment(dragTime).isDST() && this.state.dragSnapMode !== 3600000) {
            dragTime = parseInt(moment(dragTime).add(1, 'hour').format("x"));
        } else if (!moment().isDST() && moment(dragTime).isDST() && this.state.dragSnapMode !== 3600000) {
            dragTime = parseInt(moment(dragTime).subtract(1, 'hour').format("x"));
        }

        let newItems = items.map(item => {
            if (item.id === itemId) 
            {
                if (group.id === 'holiday') {
                    errorPopper('error', tr('use_absence_group'))
                    return item;
                }

                hoursDaily = item.user.contract_detail.hours_daily ? parseFloat(item.user.contract_detail.hours_daily) : 8
                hoursWeekly = item.user.contract_detail.hours_weekly ? parseFloat(item.user.contract_detail.hours_weekly) : 40
                const week = parseInt(item.week.split('-')[0]);
                const year = parseInt(item.week.split('-')[1]);
                const userId = item.user.id;
                let multiplier = 24 / hoursDaily;
                // let multiplier = 1;

                this.props.handleEditPersonnelItem(item, group.id)

                const draggedItemWeek = moment(dragTime).isoWeek();
                const draggedItemYear = moment(dragTime).year();
                
                // Siirto viikolta toiselle
                if (moment(dragTime).isoWeek() != week) {
                    // Ei voi siirtää nykyistä viikkoa aikaisemmalle viikolle
                    // if (moment(dragTime).startOf('isoWeek').isBefore(moment().startOf('isoWeek'), true)) {
                    //     return item;
                    // }
                    const foundItem = this.props.items.find(item => item.id.startsWith(`${draggedItemWeek}-${draggedItemYear}-${userId}`) && item.group === 'available');
                    const availableHours = this.props.items
                        .filter(item => item.id.startsWith(`${draggedItemWeek}-${draggedItemYear}-${userId}`) && item.group === 'available')
                        .reduce((total, item) => item.value + total, 0);

                    if (!foundItem || availableHours < item.value) {
                        errorPopper(null, tr('user_not_enough_available_hours', [item.user.user.name, draggedItemWeek]));
                        return item;
                    } else {
                        otherWeekItem = Object.assign({}, foundItem, {
                            id: foundItem.id+"-"+this.props.items.length,
                            value: item.value,
                            start_time: dragTime,
                            end_time: Number(moment(dragTime).add(item.value * multiplier, 'hours').format('x')),
                            group: group.id
                        })

                        if (moment(otherWeekItem.start_time).isoWeek() != moment(otherWeekItem.end_time).isoWeek()) {
                            otherWeekItem.end_time = Number(moment(otherWeekItem.start_time).endOf('isoWeek').format('x'));
                            otherWeekItem.start_time = Number(moment(otherWeekItem.end_time).subtract(item.value * multiplier, 'hours').format('x'));
                        }

                        this.props.handleDeletePersonnelItem(item);

                        return otherWeekItem;
                    }
                }

                // project start
                if (moment(group.begin_date).week() === week && moment(group.begin_date).year() === year && group.id !== 'available')
                {
                    let weekEnd = parseInt(moment().year(year).week(week).endOf('isoWeek').format('x'))
                    let start_time, end_time, value, roundedValue
                    if (dragTime < parseInt(moment(group.begin_date).format('x')))
                    {
                        end_time = parseInt(moment(group.begin_date).startOf('day').add(item.value * (24 / hoursDaily), 'hours').format('x'))
                        // end_time = parseInt(moment(group.begin_date).startOf('day').add(item.value, 'hours').format('x'))
                        start_time = parseInt(moment(group.begin_date).startOf('day').format('x'))
                        value = item.value
                        if (end_time > weekEnd)
                        {
                            let diff = ((end_time-weekEnd)/60/60/1000).toFixed(5)
                            end_time = weekEnd
                            freeItemValue = diff/multiplier
                            value = item.value-freeItemValue
                        }
                        roundedValue = Math.round((value) * 10) / 10;
                        return (
                            Object.assign({}, item, {
                                start_time: start_time,
                                end_time: end_time,
                                group: group.id,
                                value: value,
                                title: `${item.user.user.name} ${this.props.convertTime(value)}`,
                                work: item.group !== group.id ? null : item.work
                            })
                        )
                    }
                    else if (moment(dragTime+(item.end_time-item.start_time)).week() > week || moment(dragTime+(item.end_time-item.start_time)).year() > year)
                    {
                        let diff = weekEnd - parseInt(moment(group.begin_date).format('x'))
                        end_time = dragTime+(item.end_time-item.start_time)
                        value = item.value
                        if (diff >= (item.end_time-item.start_time))
                        {
                            return (
                                Object.assign({}, item, {
                                    start_time: parseInt(moment().year(year).week(week).endOf('isoWeek').subtract(item.value * (24 / hoursDaily), 'hours').format('x')),
                                    // start_time: parseInt(moment().year(year).week(week).endOf('isoWeek').subtract(item.value, 'hours').format('x')),
                                    end_time: parseInt(moment().year(year).week(week).endOf('isoWeek').format('x')),
                                    group: group.id,
                                    work: item.group !== group.id ? null : item.work
                                  })
                            )
                        }
                        else
                        {
                            let diff = ((weekEnd - parseInt(moment(group.begin_date).format('x')))/60/60/1000).toFixed(5)
                            freeItemValue = item.value - diff/multiplier
                            value = diff/multiplier
                            roundedValue = Math.round((value) * 10) / 10;
                            return (
                                Object.assign({}, item, {
                                    start_time: parseInt(moment(group.begin_date).startOf('day').format('x')),
                                    end_time: parseInt(moment().year(year).week(week).endOf('isoWeek').format('x')),
                                    value: value,
                                    title: `${item.user.user.name} ${this.props.convertTime(value)}`,
                                    group: group.id,
                                    work: item.group !== group.id ? null : item.work
                                  })
                            )
                        }
                    }
                    else
                    {
                        return (
                            Object.assign({}, item, {
                                start_time: dragTime,
                                end_time: dragTime + (item.end_time - item.start_time),
                                group: group.id,
                                work: item.group !== group.id ? null : item.work
                              })
                        )
                    }
                }

                // project end
                else if (moment(group.end_date).week() === week && moment(group.end_date).year() === year && group.id !== 'available')
                {
                    let projectEnd = parseInt(moment(group.end_date).add(1, 'days').format('x'))
                    let start_time, end_time, value, roundedValue
                    if (dragTime < parseInt(moment(group.end_date).startOf('isoWeek').format('x')))
                    {
                        end_time = parseInt(moment(group.end_date).startOf('isoWeek').add(item.value * (24 / hoursDaily), 'hours').format('x'))
                        // end_time = parseInt(moment(group.end_date).startOf('isoWeek').add(item.value, 'hours').format('x'))
                        start_time = parseInt(moment(group.end_date).startOf('isoWeek').format('x'))
                        value = item.value
                        if (end_time > projectEnd)
                        {
                            let diff = parseInt(((end_time-projectEnd)/60/60/1000).toFixed())
                            end_time = projectEnd
                            freeItemValue = diff/multiplier
                            value = parseFloat((item.value-freeItemValue).toFixed(5))
                        }
                        roundedValue = Math.round((value) * 10) / 10;
                        return (
                            Object.assign({}, item, {
                                start_time: start_time,
                                end_time: end_time,
                                group: group.id,
                                value: value,
                                title: `${item.user.user.name} ${this.props.convertTime(value)}`,
                                work: item.group !== group.id ? null : item.work
                            })
                        )
                    }
                    else if (dragTime+(item.end_time-item.start_time) > parseInt(moment(group.end_date).add(1, 'days').format('x')))
                    {
                        let diff = parseInt(moment(group.end_date).add(1, 'days').format('x')) - parseInt(moment().year(year).week(week).startOf('isoWeek').format('x')) 
                        end_time = dragTime+(item.end_time-item.start_time)
                        value = item.value
                        if (diff >= (item.end_time-item.start_time))
                        {
                            return (
                                Object.assign({}, item, {
                                    start_time: parseInt(moment(group.end_date).add(1, 'days').subtract(item.value * (24 / hoursDaily), 'hours').format('x')),
                                    // start_time: parseInt(moment(group.end_date).add(1, 'days').subtract(item.value, 'hours').format('x')),
                                    end_time: parseInt(moment(group.end_date).add(1, 'days').format('x')),
                                    group: group.id,
                                    work: item.group !== group.id ? null : item.work
                                  })
                            )
                        }
                        else
                        {
                            let diff = ((parseInt(moment(group.end_date).add(1, 'days').format('x')) - parseInt(moment().year(year).week(week).startOf('isoWeek').format('x')))/60/60/1000).toFixed(5)
                            freeItemValue = item.value - diff/multiplier
                            value = diff/multiplier
                            roundedValue = Math.round((value) * 10) / 10;
                            return (
                                Object.assign({}, item, {
                                    start_time: parseInt(moment().year(year).week(week).startOf('isoWeek').format('x')),
                                    end_time: parseInt(moment(group.end_date).add(1, 'days').format('x')),
                                    value: value,
                                    title: `${item.user.user.name} ${this.props.convertTime(value)}`,
                                    group: group.id,
                                    work: item.group !== group.id ? null : item.work
                                  })
                            )
                        }
                    }
                    else
                    {
                        return (
                            Object.assign({}, item, {
                                start_time: dragTime,
                                end_time: dragTime + (item.end_time - item.start_time),
                                group: group.id,
                                work: item.group !== group.id ? null : item.work
                              })
                        )
                    }
                }

                else if (moment().week(week).year(year).startOf('week').format('x') < moment(group.begin_date).format('x') && group.id !== 'available')
                {
                    errorPopper('error', tr('project_name_begins_at', [group.title, moment(group.begin_date).format('DD.MM.YYYY')]));
                    return item;
                }

                else if (moment().week(week).year(year).startOf('week').format('x') > moment(group.end_date).format('x') && group.id !== 'available')
                {
                    errorPopper('error', tr('project_name_ended_at', [group.title, moment(group.end_date).format('DD.MM.YYYY')]));
                    return item;
                }

                // week start
                else if ((moment(dragTime).week() < week || moment(dragTime).year() < year) && group.id !== 'available')
                {
                    return (
                        Object.assign({}, item, {
                            start_time: parseInt(moment().year(year).week(week).startOf('isoWeek').format('x')),
                            end_time: parseInt(moment().year(year).week(week).startOf('isoWeek').add(item.value * (24 / hoursDaily), 'hours').format('x')),
                            // end_time: parseInt(moment().year(year).week(week).startOf('isoWeek').add(item.value, 'hours').format('x')),
                            group: group.id,
                            work: item.group !== group.id ? null : item.work
                          })
                    )
                }

                // week end
                else if ((moment(dragTime+(item.end_time-item.start_time)).week() > week || moment(dragTime+(item.end_time-item.start_time)).year() > year) && group.id !== 'available')
                {
                    return (
                        Object.assign({}, item, {
                            start_time: parseInt(moment().year(year).week(week).add(1, 'weeks').startOf('isoWeek').subtract(item.value * (24 / hoursDaily), 'hours').format('x')),
                            // start_time: parseInt(moment().year(year).week(week).add(1, 'weeks').startOf('isoWeek').subtract(item.value, 'hours').format('x')),
                            end_time: parseInt(moment().year(year).week(week).add(1, 'weeks').startOf('isoWeek').format('x')),
                            group: group.id,
                            work: item.group !== group.id ? null : item.work
                          })
                    )
                }

                let userFreeItem = items.find(it => it.group === 'available' && it.user.id === item.user.id && it.week === item.week);

                if (userFreeItem && group.id === 'available' && item.group !== 'available')
                {
                    let freeItem = items.findIndex(it => it.group === 'available' && it.user.id === item.user.id && it.week === item.week)
                    freeItemUpdated = {
                        itemData: userFreeItem,
                        increased: userFreeItem.value + item.value,
                        movedItem: item,
                        freeItem: freeItem,
                        hoursDaily: hoursDaily,
                        hoursWeekly: hoursWeekly
                    }
                    if (item.db_id)
                    {
                        this.props.handleDeletePersonnelItem(item)
                    }
                    if (userFreeItem.db_id)
                    {
                        this.props.handleDeletePersonnelItem(userFreeItem)
                    }
                }

                if (!userFreeItem && group.id === 'available')
                {
                    let week = item.week.split('-')[0];
                    let year = item.week.split('-')[1];
                    let value = item.value >= hoursWeekly ? hoursWeekly : item.value;
                    let roundedValue = Math.round((value) * 10) / 10;

                    return (
                        Object.assign({}, item, {
                            start_time: parseInt(moment().year(year).week(week).startOf('isoWeek').format('x')),
                            end_time: parseInt(moment().year(year).week(week).startOf('isoWeek').add(value * (24 / hoursDaily), 'hours').format('x')),
                            // end_time: parseInt(moment().year(year).week(week).startOf('isoWeek').add(value, 'hours').format('x')),
                            value: value,
                            title: `${item.user.user.name} ${this.props.convertTime(value)}`,
                            group: group.id,
                            work: null
                          })
                    )
                }

                if (userFreeItem && group.id === 'available' && item.group === 'available')
                {
                    return item
                }
                
                return (
                    Object.assign({}, item, {
                        start_time: dragTime,
                        end_time: dragTime + (item.end_time - item.start_time),
                        group: group.id,
                        work: item.group !== group.id ? null : item.work
                      })
                )
            }
            else 
            {
                return item
            }

        })

        const newItemWeek = parseInt(itemId.split('-')[0]);
        const newItemYear = parseInt(itemId.split('-')[1]);
        const newItemTime = [`${newItemWeek}-${newItemYear}`];

        if (otherWeekItem) {
            newItemTime.push(otherWeekItem.week);
        }

        newItems = this.handleAvailableTime(newItems, newItemTime);
        this.props.calculateGroupValues(newItems)
 
    };

    handleItemResize (itemId, time, edge) {
        let { items, groups, weeklyMaxHours } = this.props;
    
        let newGroups = [...groups];

        let timeDifference, freeItemUpdated, newFreeItem, freeItemRemoved, otherWeekItems = []

        items = this.props.summary ? this.props.allItems : items

        let newItems = items.map(item => {
            if (item.id === itemId && item.group !== 'available')
            {

                this.props.handleEditPersonnelItem(item)

                const week = parseInt(item.week.split('-')[0]);
                const year = parseInt(item.week.split('-')[1]);
                let start_time = edge === "left" ? time : item.start_time
                let end_time = edge === "left" ? item.end_time : time
                const hoursDaily = item.user.contract_detail.hours_daily ? parseFloat(item.user.contract_detail.hours_daily) : 8
                const hoursWeekly = item.user.contract_detail.hours_weekly ? parseFloat(item.user.contract_detail.hours_weekly) : 40
                const maxHoursFound = weeklyMaxHours ? weeklyMaxHours.find(weeklyItem => weeklyItem.week === item.week && weeklyItem.user.id === item.user.id) : null
                const maxHours = maxHoursFound ? maxHoursFound.value : hoursWeekly
                timeDifference = ((end_time-start_time)-(item.end_time-item.start_time))/60/60/1000
                let multiplier = 24 / hoursDaily;
                // let multiplier = 1;
                let value = (timeDifference / multiplier) + item.value
                const group = newGroups.find(g => g.id == item.group)
                let nextWeekValue = ((end_time-parseInt(moment().year(year).week(week).add(1, 'weeks').startOf('isoWeek').format('x')))/60/60/1000) / multiplier

                if (value < 1)
                {
                    errorPopper('error', tr('hours_min'));
                    return item;
                }

                else if (nextWeekValue < 1 && moment(end_time).week() > week || moment(end_time).year() > year)
                {
                    timeDifference = ((parseInt(moment().year(year).week(week).add(1, 'weeks').startOf('isoWeek').format('x'))-start_time) - (item.end_time-item.start_time))/60/60/1000
                    value = (timeDifference / multiplier) + item.value
                    end_time = parseInt(moment().year(year).week(week).add(1, 'weeks').startOf('isoWeek').format('x'))
                }

                // project start
                else if (moment(group.begin_date).week() === week && start_time < parseInt(moment(group.begin_date).format('x')))
                {
                    timeDifference = ((end_time-parseInt(moment(group.begin_date).format('x')))-(item.end_time-item.start_time))/60/60/1000
                    value = (timeDifference / multiplier) + item.value
                    start_time = parseInt(moment(group.begin_date).format('x'))
                }

                // project end
                else if (moment(group.end_date).week() === week && end_time > parseInt(moment(group.end_date).add(1, 'days').format('x')))
                {
                    let diff = parseInt(((end_time-moment(group.end_date).add(1, 'days').format('x'))/60/60/1000).toFixed())
                    timeDifference = ((parseInt(moment(group.end_date).add(1, 'days').format('x'))-start_time) - (item.end_time-item.start_time))/60/60/1000
                    value = (timeDifference / multiplier) + item.value
                    end_time = parseInt(moment(group.end_date).add(1, 'days').format('x'))
                }

                // week start
                else if (moment(start_time).week() < week || moment(start_time).year() < year)
                {
                    timeDifference = ((end_time-parseInt(moment().year(year).week(week).startOf('isoWeek').format('x')))-(item.end_time-item.start_time))/60/60/1000
                    value = (timeDifference / multiplier) + item.value
                    start_time = parseInt(moment().year(year).week(week).startOf('isoWeek').format('x'))
                }

                // week end
                else if ((moment(end_time).week() > week || moment(end_time).year() > year) && edge === 'right')
                {
                    value = parseFloat((item.value + (parseInt(moment().year(year).week(week).startOf('isoWeek').add(5, 'days').format('x')) - parseInt(moment(item.end_time).format('x')))/60/60/1000/multiplier).toFixed(5))
                    if (value < 1)
                    {
                        errorPopper('error', `${tr('hours_min')} (${tr('week')} ${week})`);
                        return item;
                    }
                    let currentWeek = parseInt(item.week.split('-')[0])+1;
                    while (currentWeek <= moment(end_time).week())
                    {
                        let currentYear = moment().week(currentWeek).endOf('isoWeek').year()
                        let currentWeekNumber = `${currentWeek}-${currentYear}`;
                        let weekFreeUserItem = items.find(it => it.group === 'available' && it.user.id === item.user.id && it.week === currentWeekNumber);
                        let start = parseInt(moment().week(currentWeek).startOf('isoWeek').format('x'))
                        let end, itemValue;

                        if (moment(end_time).week() > currentWeek || moment(end_time).year() > currentYear)
                        {
                            end = parseInt(moment().week(currentWeek).startOf('isoWeek').add(5, 'days').format('x'))
                            itemValue = hoursWeekly
                        }
                        else
                        {
                            end = parseInt(end_time)
                            let otherTimeDifference = (end-start)/60/60/1000
                            itemValue = otherTimeDifference / multiplier
                        }

                        // project end
                        if ((moment(group.end_date).week() === currentWeek && moment(group.end_date).year() === currentYear) && end > parseInt(moment(group.end_date).add(1, 'days').format('x')))
                        {
                            errorPopper('error', tr('project_name_ends_at', [group.title, moment(group.end_date).format('DD.MM.YYYY')]))
                            end = parseInt(moment(group.end_date).add(1, 'days').format('x'))
                            itemValue = ((end-start)/60/60/1000)/multiplier
                            otherWeekItems.push({
                                id: `${moment(start).format("W")}-${moment(start).format("YYYY")}-${item.user.id}-${otherWeekItems.length}`,
                                item: weekFreeUserItem,
                                start_time: start,
                                end_time: end,
                                value: itemValue,
                                group: item.group,
                                work: item.work ? item.work : null,
                                user: item.user,
                                week: `${moment(start).format("W")}-${moment(start).format("YYYY")}`
                            })
                            if (weekFreeUserItem.db_id)
                            {
                                this.props.handleDeletePersonnelItem(weekFreeUserItem)
                            }
                            break;
                        }

                        else if (weekFreeUserItem && itemValue <= weekFreeUserItem.value && itemValue >= 1)
                        {
                            otherWeekItems.push({
                                id: `${moment(start).format("W")}-${moment(start).format("YYYY")}-${item.user.id}-${otherWeekItems.length}`,
                                item: weekFreeUserItem,
                                start_time: start,
                                end_time: end,
                                value: itemValue,
                                group: item.group,
                                work: item.work ? item.work : null,
                                user: item.user,
                                week: `${moment(start).format("W")}-${moment(start).format("YYYY")}`
                            })
                            if (weekFreeUserItem.db_id)
                            {
                                this.props.handleDeletePersonnelItem(weekFreeUserItem)
                            }
                        }
                        else if (itemValue < 1)
                        {
                            errorPopper('error', `${tr('hours_min')} (${tr('week')} ${currentWeek})`);
                        }
                        else
                        {
                            errorPopper('error', tr('user_not_enough_available_hours', [item.user.user.name, currentWeek]));
                        }
                        currentWeek += 1
                    }

                    end_time = parseInt(moment().year(year).week(week).startOf('isoWeek').add(5, 'days').format('x'))
                }

                let userWeekItems = items.filter(it => it.group !== 'available' && it.id !== item.id && it.user.id === item.user.id && it.week === item.week);
                let total = 0;
                userWeekItems.forEach( item => {
                    if (item.isEntry) return;
                    total += item.value;
                });
                total += value;

                if (total > maxHours)
                {
                    errorPopper('error', tr('user_not_enough_available_hours', [item.user.user.name, week]))
                    return item;
                }
                
                let itemData = items.find(it => it.group === 'available' && it.user.id === item.user.id && it.week === item.week);

                if (!itemData && total < maxHours)
                {
                    newFreeItem = {
                        newFreeItem: true,
                        itemData: item,
                        decreased: maxHours - total,
                        hoursDaily: hoursDaily
                    }
                }
                else if (itemData && total < maxHours)
                {
                    let itemIndex = items.findIndex(it => it.group === 'available' && it.user.id === item.user.id && it.week === item.week)
                    freeItemUpdated = {
                        itemData: itemData,
                        increased: maxHours - total,
                        index: itemIndex,
                        hoursDaily: hoursDaily
                    }
                }
                else if (itemData && total >= maxHours)
                {
                    let itemIndex = items.findIndex(it => it.group === 'available' && it.user.id === item.user.id && it.week === item.week)
                    freeItemRemoved = itemIndex
                }

                return (
                    Object.assign({}, item, {
                        start_time: start_time,
                        end_time: end_time,
                        value: value,
                        title: `${item.user.user.name} ${this.props.convertTime(value)}`
                    })
                )
            }
            else 
            {
                return item
            }
        })

        const newItemWeek = parseInt(itemId.split('-')[0]);
        const newItemYear = parseInt(itemId.split('-')[1]);
        const newItemsTime = [`${newItemWeek}-${newItemYear}`];

        otherWeekItems.forEach(item => {
            newItemsTime.push(item.week);
        })

        newItems.push(...otherWeekItems)
        newItems = this.handleAvailableTime(newItems, newItemsTime);
        this.props.calculateGroupValues(newItems)
    };

    handleAssignmentsEdit() {
        this.setState({ showAssignmentsModal: true });
    }

    closeAssignmentModal()
    {
        this.setState({ showAssignmentsModal: false, add_assignments:[], add_assignments_ids:[] });
    }

    addUserAssigment(user) {
        let add_assignments = [...this.state.add_assignments];
        let add_assignments_ids = [...this.state.add_assignments_ids];
        add_assignments.push(user);
        add_assignments_ids.push(user.id);
        this.setState({ add_assignments: add_assignments, add_assignments_ids: add_assignments_ids });
    }
    
    assignmentsSave() {
        let assignments = this.props.personnel_assignments;
        let add_assignments = this.state.add_assignments;
        add_assignments.map(user => {
            let data={user_id:user.id,
                    name:user.name,
                    manual:true,
                    new:true,
            };
            assignments.push(data);
        });
        this.props.handleAssignmetsEdit(assignments);
        this.setState({ showAssignmentsModal: false, add_assignments:[], add_assignments_ids:[] });
    }

    deleteAssignment(id) {
        this.props.handleDeleteAssignmetItem(id);
    }


    handleModalSave(modalData, oldItem = null)
    {
        let { items, groups, weeklyMaxHours } = this.props;

        let freeItemUpdated, newFreeItem, freeItemRemoved, freeItemUpdatedMove, otherWeekItems = []

        items = this.props.summary ? this.props.allItems : items

        const endOfWeek = moment(modalData.start, "x").endOf("isoWeek").format("x");

        let newItems = items.map(item => {
            if (oldItem && oldItem.id === item.id) return;
            if (item.id === modalData.itemId) 
            {
                const daysWeekly = item.user.contract_detail.days_weekly ? parseFloat(item.user.contract_detail.days_weekly) : 5;
                const hoursDaily = item.user.contract_detail.hours_daily ? parseFloat(item.user.contract_detail.hours_daily) : 8
                const hoursWeekly = item.user.contract_detail.hours_weekly ? parseFloat(item.user.contract_detail.hours_weekly) : 40
                // const maxHoursFound = weeklyMaxHours ? weeklyMaxHours.find(weeklyItem => weeklyItem.week === item.week && weeklyItem.user.id === item.user.id) : null
                // const maxHours = maxHoursFound ? maxHoursFound.value : hoursWeekly
                const multiplier = 24 / hoursDaily;

                let value = modalData.hours ? modalData.hours : item.value

                let week = parseInt(item.week.split('-')[0]);
                let year = parseInt(item.week.split('-')[1]);

                let itemWeek = `${week}-${year}`;

                if (modalData.projectId === 'available') {
                    this.props.handleDeletePersonnelItem(item);
                    return Object.assign({}, item, {
                        group: 'available',
                        work: modalData.workId,
                        value: item.value,
                        start_time: parseInt(moment(item.start_time, 'x').format('x')),
                        end_time: parseInt(moment(item.end_time, 'x').format('x')),
                        title: `${item.user.user.name} ${this.props.convertTime(value)}`
                    });
                }

                let group = groups.find(group => group.id === modalData.projectId);
                // Project begin check
                if (group && group.begin_date && parseInt(moment(group.begin_date).format('x')) > modalData.start_time)
                {
                    errorPopper('error', tr('project_name_begins_at', [group.title, moment(group.begin_date).format('DD.MM.YYYY')]));
                    return item;
                }
                // project end check
                else if (group 
                    && group.end_date 
                    && moment(group.end_date).week() === week 
                    && moment(group.end_date).year() === year 
                    && parseInt(moment(modalData.end, 'x').format('x')) > parseInt(moment(group.end_date).add(1, 'days').format('x')))
                {
                    errorPopper('error', tr('project_name_ends_at', [group.title, moment(group.end_date).format('DD.MM.YYYY')]))
                    return item;
                }
                const allocationsThisWeek = items.filter(
                    (it) =>
                      it.group !== "available" &&
                      it.id !== item.id &&
                      it.user.id === item.user.id &&
                      it.week === itemWeek &&
                      !it.isEntry
                );
                const allocatedHours = allocationsThisWeek.reduce(
                    (prevVal, currentVal) => prevVal + currentVal.value, 0);

                this.props.handleEditPersonnelItem(item, modalData.projectId);

                if (value <= hoursWeekly && moment(modalData.start, 'x').isoWeek() === moment(modalData.start, 'x').add(value * multiplier, 'hours').isoWeek()) {
                    // user does not have enough hours available
                    if (allocatedHours + value > hoursWeekly) {
                        errorPopper('error', tr('user_not_enough_available_hours', [item.user.user.name, week]));
                        return item;
                    }
                    return Object.assign({}, item, {
                        group: modalData.projectId,
                        work: modalData.workId,
                        value: value,
                        start_time: parseInt(moment(modalData.start, 'x').format('x')),
                        end_time: parseInt(moment(modalData.start, 'x').add(value * multiplier, 'hours').format('x')),
                        title: `${item.user.user.name} ${this.props.convertTime(value)}`
                    });
                }
                // multiple weeks selected
                else if (moment(modalData.start, 'x').isoWeek() !== moment(modalData.end, 'x').isoWeek() || value > hoursWeekly) {
                    // last isoweekday for handling weekends with users with more days weekly than 5
                    let lastIsoWeekday = daysWeekly > 5
                        ? daysWeekly
                        : 5;
                    let hours = 0;
                    if (moment(modalData.start, 'x').add(daysWeekly, 'days').week() == week && 
                    moment(modalData.start, 'x').add(daysWeekly, 'days').isoWeekday() <= lastIsoWeekday) {
                        hours = parseFloat(
                            (parseInt(moment(modalData.start, 'x').add(daysWeekly, 'days').format('x')) - 
                            parseInt(moment(modalData.start, 'x').format('x')))/60/60/1000/multiplier).toFixed(2);
                    } else {
                        hours = parseFloat(
                            (parseInt(moment(modalData.start, 'x').startOf('isoWeek').add(lastIsoWeekday, 'days').format('x')) - 
                            parseInt(moment(modalData.start, 'x').format('x')))/60/60/1000/multiplier).toFixed(2);
                    }

                    if (moment(modalData.start, 'x').isoWeekday() <= lastIsoWeekday) {
                        otherWeekItems.push(
                            Object.assign({}, item, {
                                group: modalData.projectId,
                                work: modalData.workId,
                                value: parseFloat(hours),
                                start_time: parseInt(moment(modalData.start, 'x').format('x')),
                                end_time: parseInt(moment(modalData.start, 'x').add(hours * multiplier, 'hours').format('x')),
                                title: `${item.user.user.name} ${this.props.convertTime(value)}`
                            })
                        )

                    }
                    value -= hours;
                }

                // Use remaining hours
                while (value > 0) {
                    week++;
                    const yearNow = moment().week(week).year();
                    const weekNow = moment().year(yearNow).week(week).isoWeek();
                    const itemWeek = `${weekNow}-${yearNow}`
                    const allocationsWeek = items.filter(
                          (it) =>
                            (it.group !== "available" ||
                            it.db_id && it.group === 'available') &&
                            it.id !== item.id &&
                            it.user.id === item.user.id &&
                            it.week === itemWeek &&
                            !it.isEntry
                    );
                    const allocatedHours = allocationsWeek.reduce(
                        (prevVal, currentVal) => prevVal + currentVal.value, 0);
                    if (allocationsWeek.length > 0 && allocatedHours + value > hoursWeekly) {
                        errorPopper('error', tr('user_not_enough_available_hours', [item.user.user.name, weekNow]));
                    } else {
                        const itemHours = value > hoursWeekly
                            ? hoursWeekly
                            : value;

                        const foundAvailableDbItem = items.find(item => 
                            item.db_id &&
                            item.group === 'available' &&
                            item.value == itemHours &&
                            item.week === itemWeek
                        );
                        
                        if (parseInt(moment().year(yearNow).week(weekNow).startOf('isoWeek').add(itemHours * multiplier, 'hours').format('x')) > parseInt(moment(group.end_date).add(1, 'days').format('x'))) {
                            errorPopper('error', tr('project_name_ends_at', [group.title, moment(group.end_date).format('DD.MM.YYYY')]))
                        } else if (foundAvailableDbItem) {
                            this.props.handleEditPersonnelItem(foundAvailableDbItem, modalData.projectId);
                            foundAvailableDbItem.group = modalData.projectId;
                        } else {
                            otherWeekItems.push(
                                Object.assign({}, item, {
                                    id: `${itemWeek}-${items.length}-${item.user.id}`,
                                    group: modalData.projectId,
                                    work: modalData.workId,
                                    value: itemHours,
                                    start_time: parseInt(moment().year(yearNow).week(weekNow).startOf('isoWeek').format('x')),
                                    end_time: parseInt(moment().year(yearNow).week(weekNow).startOf('isoWeek').add(itemHours * multiplier, 'hours').format('x')),
                                    week: itemWeek,
                                    title: `${item.user.user.name} ${this.props.convertTime(value)}`,
                                    db_id: null
                                })
                            );
                        }
                    }
                    value -= hoursWeekly;
                }
            }
            else 
            {
                return item
            }

        });

        
        const newItemWeek = parseInt(modalData.itemId.split('-')[0]);
        const newItemYear = parseInt(modalData.itemId.split('-')[1]);
        const newItemTime = [`${newItemWeek}-${newItemYear}`];
        
        otherWeekItems.forEach(item => {
            newItemTime.push(item.week);
        });
        if (oldItem) {
            newItemTime.push(oldItem.week);
        }
        
        newItems.push(...otherWeekItems);
        newItems = this.handleAvailableTime(newItems, newItemTime);
        this.props.calculateGroupValues(newItems) 
    }

    handleAvailableTime = (items, newItemsTime) => {
        const {projectsStart, projectsEnd, continuous} = this.props

        const weekNow = moment().isoWeek();
        const yearNow = moment().year();
        
        const newItems = items.filter(item => {
            if (!item) return false;
            if (item.group != "available") return true;

            if (item.group == "available" && item.db_id) return true;

            if (item.group == "available" && 
                moment(item.start_time).add(5, "days").format("x") == item.end_time &&
                !newItemsTime.includes(item.week)) {
                    return true;
            }

            if(!newItemsTime.includes(item.week)) return true;
        });

        if (moment().isAfter(projectsEnd)) {
            return items;
        }

        let freeItems = [];

        // calculate new available times
        this.props.personnel.forEach(user => {
            const hoursDaily = user.contract_detail.hours_daily ? parseFloat(user.contract_detail.hours_daily) : 8;
            const hoursWeekly = user.contract_detail.hours_weekly ? parseFloat(user.contract_detail.hours_weekly) : 40;
            const multiplier = parseFloat(24 / hoursDaily);

            // get all allocations not visible on personnel management view
            const filteredUserAllocations = user.allocations.filter(userItem => {
                if (userItem.project_id) {
                    return !this.props.openGroups.includes(userItem.project_id)
                } else {
                    return !this.props.openGroups.includes(`assignment_${userItem.assignment_id}`)
                }
            })

            const hiddenAllocations = filteredUserAllocations.map(item => {
                return Object.assign({}, item, {
                    start_time: parseInt(moment(item.start, "YYYY-MM-DD hh:mm:ss").format("x")),
                    end_time: parseInt(moment(item.end, "YYYY-MM-DD hh:mm:ss").format("x")),
                    user: {
                        id: item.user_id
                    }
                })
            })

            const allocations = newItems.filter(item => 
                (item.group != "available" && user.id == item.user.id)
                || (item.group == "available" && item.db_id && user.id == item.user.id)
                || !item.isProjectSchedule);

            allocations.push(...hiddenAllocations);

            allocations.sort((a,b) => moment(a.start_time, "x").format("x") - moment(b.start_time, "x").format("x"));

            const startYear = (allocations[0])
                ? parseInt(allocations[0].week.split("-")[1])
                : parseInt(moment().startOf("year").format("YYYY"));
            const endYear = moment(projectsEnd).year()
            
            for (let year = startYear; year <= endYear; year++) {
                const weeksAmount = moment().year(year).isLeapYear()
                    ? 53
                    : 52;
                for (let week = 1; week <= weeksAmount; week++) {
                    // ignore weeks that are before start of the project with earliest begin date
                    if (moment().year(year).week(week).startOf("isoWeek").format("x") < moment(projectsStart).format("x"))
                        continue;

                    if (!continuous && moment().year(year).week(week).startOf("isoWeek").format("x") > moment(projectsEnd).format("x"))
                        continue

                    const contractEnd = user.contract_detail.contract_end;
                    const contractEndYear = moment(contractEnd).year();
                    const contractEndWeek = moment(contractEnd).week();

                    const contractHasEnded = !!contractEnd
                        && (year > contractEndYear
                        || (year <= contractEndYear && week > contractEndWeek))
                    

                    if (contractHasEnded) {
                        continue;
                    };

                    let weekNumber = `${week}-${year}`;

                    if (!newItemsTime.includes(weekNumber)) 
                        continue;

                    let isBeforeCurrWeek = false;
                    // Only commented out in case we want to do something with weeks before current week
                    // if (week < weekNow && year <= yearNow) 
                    //     isBeforeCurrWeek = true;
                    // else
                    //     isBeforeCurrWeek = false;

                    const filteredAllocations = allocations.filter(allocation => 
                        allocation.week == weekNumber 
                        && allocation.user.id == user.id
                        && !allocation.isEntry
                        && !allocation.absence
                    );
                    filteredAllocations.sort(
                      (a, b) =>
                        parseInt(moment(a.start_time, "x").format("x")) -
                        parseInt(moment(b.start_time, "x").format("x"))
                    );

                    let total = 0;
                    
                    filteredAllocations.forEach(allocation => {
                        const duration = ((parseInt(moment(allocation.end_time, "x").format('x'))-parseInt(moment(allocation.start_time, "x").format('x')))/60/60/1000)/multiplier;
                        total += duration;
                    });
                    total = hoursWeekly - total.toFixed(3);
                    const daysWeekly = user.contract_detail.days_weekly || 5;

                    // handle new item where user is available full week
                    if (total == hoursWeekly) {
                        const fullWeeks = newItems.filter(item => item.week == weekNumber && item.user.id == user.id && moment(item.start_time).add(5, "days").format("x") == item.end_time);

                        if (fullWeeks.length == 0) {
                            const startOfWeek = parseInt(moment().year(year).week(week).startOf('isoWeek').format('x'))
                            filteredAllocations.push({
                                start_time: startOfWeek,
                                end_time: parseInt(moment(startOfWeek).add(total * multiplier, "hours").format("x")),
                            })
                        }
                    }

                    filteredAllocations.forEach((allocation, index) => {
                        const startOfWeek = parseInt(moment(allocation.start_time, "x").startOf('isoWeek').format('x'));

                        if (hoursWeekly == total) {
                            freeItems.push({
                                id: `${week}-${year}-${user.id}-${newItems.length}-${index}`,
                                user: user,
                                group: 'available',
                                value: total,
                                week: weekNumber,
                                title: `${user.user.name} ${this.props.convertTime(total)}`,
                                start_time: startOfWeek,
                                end_time: parseInt(moment(startOfWeek).add(daysWeekly, "days").format("x")),
                                canMove: isBeforeCurrWeek ? false : true,
                                canResize: isBeforeCurrWeek ? false : true,
                                canChangeGroup: isBeforeCurrWeek ? false : true,
                            })
                            total = 0;
                        }
                        if (total <= 0) return;

                        const startAtBeginningOfWeek = moment(allocation.start_time, "x").format("x") <= startOfWeek;

                        // set available time to beginning of week if allocated time does not start at beginning of week
                        if (index === 0 && !startAtBeginningOfWeek) {
                            let value = ((moment(allocation.start_time, "x").format('x')-moment(startOfWeek).format('x'))/60/60/1000)/multiplier;
                            value = total < value ? total : value;

                            if (value <= 0) return; // 2 items next to each other

                            freeItems.push({
                                id: `${week}-${year}-${user.id}-${newItems.length}-${index}`,
                                user: user,
                                group: 'available',
                                value: value,
                                week: weekNumber,
                                title: `${user.user.name} ${this.props.convertTime(value)}`,
                                start_time: startOfWeek,
                                end_time: parseInt(moment(startOfWeek).add(value * multiplier, "hours").format("x")),
                                canMove: isBeforeCurrWeek ? false : true,
                                canResize: isBeforeCurrWeek ? false : true,
                                canChangeGroup: isBeforeCurrWeek ? false : true,
                            })
                            total -= value;
                        }

                        // next item starts before current item ends
                        if (filteredAllocations[index + 1] && 
                            moment(allocation.end_time, "x").format('x') > moment(filteredAllocations[index + 1].start_time, "x").format("x")) return;

                        let availableTimeStart = startAtBeginningOfWeek 
                            ? startOfWeek 
                            : parseInt(moment(allocation.end_time, "x").format('x'));
                        let availableTimeEnd = filteredAllocations[index + 1]
                            ? parseInt(moment(filteredAllocations[index + 1].start_time, "x").format("x"))
                            : parseFloat(moment(availableTimeStart).add(total*multiplier, 'hours').format('x'));

                        
                        // allocated time starts at beginning of week
                        if (total > 0 && startAtBeginningOfWeek) {
                            let value = ((parseInt(moment(availableTimeEnd).format('x'))-parseInt(moment(allocation.end_time, "x").format('x')))/60/60/1000)/multiplier;
                            value = total < value ? total : value;
                            value = filteredAllocations[index + 1] ? value : total
                            if (value <= 0) return;

                            freeItems.push({
                                id: `${week}-${year}-${user.id}-${newItems.length}-${index+1}`,
                                user: user,
                                group: 'available',
                                value: value,
                                week: weekNumber,
                                title: `${user.user.name} ${this.props.convertTime(value)}`,
                                start_time: parseInt(moment(allocation.end_time, "x").format("x")),
                                end_time: parseInt(moment(allocation.end_time, "x").add(value*multiplier, 'hours').format('x')),
                                canMove: isBeforeCurrWeek ? false : true,
                                canResize: isBeforeCurrWeek ? false : true,
                                canChangeGroup: isBeforeCurrWeek ? false : true,
                            })
                            total -= value;
                        }

                        else if(total > 0) {
                            let value = ((moment(availableTimeEnd).format('x')-moment(availableTimeStart).format('x'))/60/60/1000)/multiplier;
                            value = total < value ? total : value;

                            if (value <= 0) return;

                            freeItems.push({
                                id: `${week}-${year}-${user.id}-${newItems.length}-${index+1}`,
                                user: user,
                                group: 'available',
                                value: value,
                                week: weekNumber,
                                title: `${user.user.name} ${this.props.convertTime(value)}`,
                                start_time: availableTimeStart,
                                end_time: parseInt(moment(availableTimeStart).add(value*multiplier, 'hours').format('x')),
                                canMove: isBeforeCurrWeek ? false : true,
                                canResize: isBeforeCurrWeek ? false : true,
                                canChangeGroup: isBeforeCurrWeek ? false : true,
                            })
                            total -= value;
                        }
                    })
                }
            }
        })
        return newItems.concat(freeItems);
    }

    handleMenuItemClick(item)
    {
        const itemType = typeof(item);
        let itemWeek, itemYear;
        let isHoliday = false;
        let isEntry = false;
        let isProjectSchedule = false;

        if (itemType === "string") {
            const splitItem = item.split("-");
            itemWeek = parseInt(splitItem[0]);
            itemYear = parseInt(splitItem[1]);
            isHoliday = splitItem.includes("holiday");
            isEntry = splitItem.includes("entry");
            isProjectSchedule = splitItem.includes("projectSchedule");
        }
        else if (itemType === "object") {
            itemWeek =parseInt(item.id.split("-")[0]);
            itemYear =parseInt(item.id.split("-")[1]);
        }

        if (!isHoliday && !isEntry && !isProjectSchedule) {
            this.setState({ showPersonnelModal: true });
        }
    }

    closePersonnelModal()
    {
        this.setState({ showPersonnelModal: false });
    }

    onTimeChange(visibleTimeStart, visibleTimeEnd, updateScrollCanvas)
    {
        const days = (visibleTimeEnd - visibleTimeStart)/60/60/24/1000
        if (days >= 1 && days <= 50) {
            updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
        }
    }

    handleLabelFormat(interval, unit, labelWidth)
    {
        this.setState({
            showHourLabel: false,
            dragSnapMode: 24 * 60 * 60 * 1000, // daily
        });

        if (labelWidth > 250) {
            this.setState({showHourLabel: true})
        }

        if (labelWidth < 50)
        {
            return interval[0].format('DD')
        }
        else if (labelWidth < 100)
        {
            return interval[0].format('dd D')
        }
        else if (labelWidth < 150)
        {
            return interval[0].format('dd D.M.')
        }
        else
        {
            this.setState({dragSnapMode: 60 * 60 * 1000}); // hourly
            return interval[0].format('dd LL')
        }
    }

    itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
        let workName, background;
        let maxHeight = this.state.lineHeight * this.state.itemHeightRatio;

        const isBeforeCurrWeek = moment().startOf("isoWeek").format("x") > item.start_time;
        const isHoliday = item.group === "holiday";
        // hide button for editing allocation
        const menuIsHidden = isHoliday || item.isEntry;

        if (item.work)
        {
            this.props.groups.forEach(group => {
                if (group.id == item.group)
                {
                    if (Array.isArray(group.works)) {
                        group.works.forEach(work => {
                            if (work.id == item.work)
                            {
                                workName = work.name
                            }
                        })
                    }
                }
            })
        }
        if (itemContext.selected && !item.isProjectSchedule)
        {
            background = "hsl(33, 100%, 50%)";
        }
        else if (item.group === 'available')
        {
            background = 'hsl(195, 100%, 25%)' // dark blue
        }
        else if (item.isHoliday || item.isEntry) {
            background = "var(--clr-success-dark)";
        } 
        else if (item.isProjectSchedule) {
            background = 'rgba(0, 153, 204, 20%)';
            maxHeight = maxHeight / 2;
        }
        else {
            background = 'var(--clr-blue-main)';
        }
        return (
          <div
            {...getItemProps({
            style: {
                maxHeight,
                color: item.isProjectSchedule ? 'black' : 'white',
                background: background,
                borderColor: 'gray',
                borderStyle: "solid",
                borderWidth: 1,
                borderRadius: 4,
                borderLeftWidth: itemContext.selected ? 3 : 1,
                borderRightWidth: itemContext.selected ? 3 : 1
            },
            onMouseDown: () => {
                if (item.isProjectSchedule) return;
                this.setState({ selectedItem: item })
            },
            onTouchStart: () => {
                if (item.isProjectSchedule) return;
                this.setState({ selectedItem: item })
            }
            })}
          >
              
            {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
            {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
            
            <div
                style={{
                    lineHeight: 'normal',
                    textOverflow: 'ellipsis',
                    padding: '5px 0 0 5px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    height: '100%',
                    fontSize: 12,
                    background: background,

                }}
            >
            
                <div className="personnel-item-container">
                    <div>
                        {item.isProjectSchedule 
                            ? <div>{item.title}</div>
                            : <b>{item.user && item.user.user.name}</b>
                        }

                        {item.work &&
                        <span>
                            {workName}
                        </span>
                        }

                        {(!isHoliday && !item.isProjectSchedule) &&
                        <span>
                        {this.props.convertTime(item.value)} {item.isEntry && item.workName}
                        </span>}
                        {isHoliday &&
                        <span>
                            {item.title}
                        </span>
                        }
                    </div>
                    {!menuIsHidden && <div style={{cursor: "pointer", width: 20, marginRight: "5px"}}>
                        <SvgIcon icon="edit" className="size-small" onClick={() => this.handleMenuItemClick(item)}></SvgIcon>
                    </div>}
                </div>
                {item.isEntry && item.doneEstimate && (
                    <React.Fragment>
                        <div>{tr('degree_of_readiness_estimate')}:</div>
                        <div>{item.doneEstimate}%</div>
                    </React.Fragment>
                )}
            </div>
    
          </div>
        );
      };

    handleGroupTooltip(id)
    {
        const tooltipSpan = document.getElementById(`tooltip-span-${id}`);
        window.onmousemove = (e) => {
            const x = e.clientX, y = e.clientY;
            tooltipSpan.style.top = (y - tooltipSpan.offsetHeight - 5) + 'px';
            tooltipSpan.style.left = (x - (tooltipSpan.offsetWidth/2)) + 'px';
        };
    }

    handleMarkerTooltipEnter(id)
    {
        const tooltip = document.getElementById(`tooltip-marker-${id}`);
        const tooltipSpan = document.getElementById(`tooltip-span-marker-${id}`);
        tooltip.style.backgroundColor = '#0099CC';
        window.onmousemove = (e) => {
            const x = e.clientX, y = e.clientY;
            tooltipSpan.style.top = (y - tooltipSpan.offsetHeight - 5) + 'px';
            tooltipSpan.style.left = (x - (tooltipSpan.offsetWidth/2)) + 'px';
        };
    }

    handleMarkerTooltipLeave(id)
    {
        const tooltip = document.getElementById(`tooltip-marker-${id}`);
        tooltip.style.backgroundColor = 'black';
    }

    handleCanvasDblClick = (groupId, time, e) => {
        // Open personnel modal on canvas double click
        // console.log("group id", groupId);
        // console.log("time", moment(time));
        // console.log("e", e);
        // const foundGroup = this.props.groups.find(group => group.id === groupId);
        // console.log("Found group", foundGroup.title);
    }

    handleZoom = (direction) => {
        if (direction === "in")
            this.scrollRef.changeZoom(0.7);
        if (direction === "out")
            this.scrollRef.changeZoom(1.3);
    }

    render()
    {
        // console.log("props", this.props);
        // console.log("selected item", this.state.selectedItem);
        const { items, groups, openGroups, personnel } = this.props;
        const newGroups = groups
        .filter(g => openGroups.includes(g.id))
        .map(group => {
            let color = '';
            group.allocated = isNaN(group.allocated) ? 0 : group.allocated;
            if (Math.abs(((Math.round(group.value * 10) / 10)-group.allocated)) <= 1)
            {
                // color = 'lightgreen'
                color = 'var(--clr-blue-main)';
            }
            else if ((Math.round(group.value * 10) / 10) > group.allocated)
            {
                color = 'var(--clr-warning-main)';
            }
            else
            {
                color = 'hsl(195, 100%, 25%)'
            }
            
            let classes = [ "arrow" ];

            if( group.open )
                classes.push( "open" );

            const projectEnded = group.end_date 
                ? parseInt(moment(group.end_date).format("x")) < parseInt(moment().format("x"))
                : false;

            const projectEndedMessage = projectEnded 
                ? `${tr("project_ended_at")}: ${moment(group.end_date).format("L")}`
                : "";

            let projectTitleUrl;
            if (this.props.history && group.project_code) {
                projectTitleUrl = `/projects/${group.id}/${group.offer && group.offer.status != 'done' ? 'offer' : 'management'}`;
            } else if (this.props.history) {
                projectTitleUrl = `/assignments/${group.assignment_id}/3`;
            }

            const groupEntryCount = group.entryCount || 0;

            return Object.assign({}, group, {
                title: group.title && !group.disabled ? (
                    <div style={{fontSize: 13}}>
                    <div 
                        onClick={() => this.toggleGroupChildren(group)} 
                        style={{ 
                            width: '100%', 
                            height: '100%', 
                            lineHeight: 'normal', 
                            paddingTop: 10, 
                            cursor: group.children ? 'pointer' : 'default', 
                            paddingLeft: ( group.level * 0.5 ) + "em",
                            display: 'flex', 
                            // alignItems: 'center'
                        }}
                    >
                    <div style={{display: 'flex', width: '100%'}}>
                        <p 
                            onClick={() => projectTitleUrl && this.props.history.push(projectTitleUrl) }
                            title={`${group.title} ${group.project_code != undefined ? group.project_code : ""} ${group.customerName || ""} ${projectEndedMessage}`} 
                            className="pointer project-title"
                            style={{wordBreak: 'break-word', whiteSpace: 'normal', fontSize: 14, color: projectEnded ? "#8b0000" : "inherit"}}>
                                {Number.isInteger(group.id)?'':<SvgIcon icon="tasks" type="solid" className="size-small"/>} {group.title} 
                        </p>
                        {group.children ? <div style={{marginLeft: 'auto'}}><SvgIcon className={classes.join(" ")} icon="chevron-left" type="solid" /></div> : ''}
                    </div>

                    { this.props.summary ? 
                    <span title={tr("hide_project")}>
                        <SvgIcon
                            icon="eye-slash"
                            type="solid"
                            className="hideProject"
                            onClick={ () => this.props.onHideProject(group.id) }
                        />
                    </span>
                    : 
                    null }
          
                    </div>
                        <p
                            title={`${group.title} ${group.project_code} ${group.customerName || ""}`}
                            style={{
                                lineHeight: 'normal', 
                                paddingLeft: ( group.level * 0.5 ) + "em", 
                                marginTop: "-10px",
                                fontSize: "11px"
                            }}>
                            {group.project_code}
                        </p>

                        {group.customerName && 
                            <p style={{
                                lineHeight: 'normal', 
                                paddingLeft: ( group.level * 0.5 ) + "em", 
                                marginTop: "-10px",
                                fontSize: "14px"
                            }}>
                                {group.customerName}
                            </p>
                        }
                    </div>
                ) : (
                     
                <b>{group.title}</b>
                ),
                rightTitle: group.id !== 'available' && !group.disabled ? (
                    <div style={{width: '100%', height: '100%', backgroundColor: group.end_date ? color : 'hsl(195, 100%, 40%)', lineHeight: 'normal', display: 'flex', alignItems: 'center'}}>
                        <div style={{paddingLeft: 4, overflow: 'hidden'}}>
                            <span
                                className="tooltip-total-time tooltip" 
                                onMouseEnter={() => this.handleGroupTooltip(group.id)}
                            >
                                {group.end_date 
                                    ? `${tr('in_total')}: ${this.props.convertTime(group.value)} / ${group.allocated - groupEntryCount < 0 
                                        ? this.props.convertTime(0) 
                                        : this.props.convertTime(group.allocated - groupEntryCount)}`
                                    : `${tr('in_total')}: ${this.props.convertTime(group.value)}`  
                                }
                                <div className="tooltip-span" id={`tooltip-span-${group.id}`}>
                                    {group.works?.map(work => 
                                        <div key={work.id}>
                                            {group.end_date ? 
                                            `${work.name}: ${this.props.convertTime(work.alloc_personnel)} / ${work.alloc_count - (work.entry_count ? work.entry_count : 0) < 0 
                                                ? this.props.convertTime(0) 
                                                : this.props.convertTime(work.alloc_count - (work.entry_count ? work.entry_count : 0))}`
                                            : `${work.name}: ${this.props.convertTime(work.alloc_personnel)}`
                                            }
                                            <div style={{textAlign:"right", fontWeight:"normal"}}>&ensp;{`${tr('degree_of_readiness_estimate')}: ${work.done_estimate} %`}</div>
                                        </div>
                                    )}
                                    {group.end_date 
                                    ? `${tr('in_total')}: ${this.props.convertTime(group.value)} / ${group.allocated - groupEntryCount < 0 
                                        ? this.props.convertTime(0) 
                                        : this.props.convertTime(group.allocated - groupEntryCount)}` 
                                    : `${tr('in_total')}: ${this.props.convertTime(group.value)}`  
                                    }
                                </div>
                            </span>

                        </div>
                    </div>
                ) : (
                    <div style={{ paddingLeft: 20 }}></div>
                )
                });
            });
        
            let classes = [ "padding" ]
            if (this.props.loading) 
            {
                classes.push('loading-mask')
            }

            else if (personnel && personnel.length === 0)
            {
                return (<div className="padding">{ tr('no_people_found_info') }</div>)
            }

            let projectPersonel="";
            
            if (this.props.entries_only_if_in_personnel) {
                let first= true;
                let assigned=this.props.personnel_assignments.map(user => { 
                    let data = <span >{first?'':', '}{user.name} {<SvgIcon icon="trash" type="solid" className="size-tiny pointer" onClick={() => this.deleteAssignment(user.user_id)} />}</span>
                    first=false;
                    return data } );
                
                projectPersonel=<div className="padding">
                    <h2>{tr('working_in_project')}: <SvgIcon icon="plus" type="solid" className="size-medium" onClick={() => this.handleAssignmentsEdit()}></SvgIcon></h2>
                    {assigned}
                    {this.props.personnel_assignments_sub_projects && this.props.personnel_assignments_sub_projects.length 
                        ? (
                            <div>
                                <h4>{tr('working_in_sub_projects')}:</h4>
                                {this.props.personnel_assignments_sub_projects.map(project => (
                                    <>
                                        <h5>{project.project_code} {project.name}:</h5>
                                        <p> &emsp;
                                            {project.assignments.reduce((list, user) => {
                                                list.push(user)
                                                return list;
                                            }, []).join(', ')}
                                        </p>
                                    </>
                                ))}
                            </div>
                        )
                        : null
                    }
                    </div>
            }
            const guideTooltip = (
                <>
                    <div className="tooltip-row">
                        <span>{tr('move_lr')}</span>
                        <span>shift + {tr('mouse_wheel')}</span>
                    </div>
                    <div className="tooltip-row">
                        <span>{tr('zoom_in_out')}</span>
                        <span>alt + {tr('mouse_wheel')}</span>
                    </div>
                    <div className="tooltip-row">
                        <span>{tr('zoom_in_out')} ({tr('fast')})</span>
                        <span>ctrl + {tr('mouse_wheel')}</span>
                    </div>
                    <div className="tooltip-row">
                        {tr('personnel_double_click')}
                    </div>
                    <div className="tooltip-row">
                        <span>{tr('project')} OK</span>
                        <span className="guide-color-box guide-ok"></span>
                    </div>
                    <div className="tooltip-row">
                        <span>{tr('project_estimated_hours_over')}</span>
                        <span className="guide-color-box guide-warning"></span>
                    </div>
                    <div className="tooltip-row">
                        <span>{tr('project_incomplete')}</span>
                        <span className="guide-color-box guide-incomplete"></span>
                    </div>
                    <div className="tooltip-row">
                        <strong>
                            {tr('timeline')}
                        </strong>
                    </div>
                    <div className="tooltip-row">
                        <span>{tr('plans')}</span>
                        <span className="guide-color-box guide-plans"></span>
                    </div>
                    <div className="tooltip-row">
                        <span>{tr('outturn')}</span>
                        <span className="guide-color-box guide-entries"></span>
                    </div>
                </>
            );
            const zoomButtons = (
                <div className="zoom-btn-container">
                    <button onClick={()=>this.handleZoom("out")}>
                        <SvgIcon icon="search-minus" type="solid" className="size-medium" />
                    </button>
                    <button onClick={()=>this.handleZoom("in")}>
                        <SvgIcon icon="search-plus" type="solid" className="size-medium" />
                    </button>
                </div>
            );

            return (
                <div className={classes.join(" ")}>
                
                {projectPersonel}

                { this.props.end && 
                    parseInt(moment(this.props.end).add(1, 'days').format('x')) < parseInt(moment().format('x')) ?
                    <div style={{color: 'red', fontSize: 22}}>{ `${tr('project_ended_at')} ${moment(this.props.end).format('DD.MM.YYYY')}` }</div>
                    :
                    null
                }

                <div>
                    <ApTooltip text={ guideTooltip }>
                        <div className="guide-container">
                            <SvgIcon icon="question-circle" type="solid" className="size-large" />
                            <span>{tr('guide')}</span>
                        </div>
                    </ApTooltip>
                </div>
                

                { newGroups.length && items.length &&
                <Timeline
                    onTimeChange={ this.onTimeChange }
                    groups={newGroups}
                    items={items}
                    defaultTimeStart={this.state.defaultTimeStart}
                    defaultTimeEnd={this.state.defaultTimeEnd}
                    minZoom={24 * 60 * 60 * 1000}
                    maxZoom={64 * 24 * 60 * 60 * 1000}
                    sidebarWidth={190} // also change zoom-btn-container width if you change this
                    itemHeightRatio={this.state.itemHeightRatio}
                    rightSidebarWidth={180}
                    lineHeight={this.state.lineHeight}
                    stackItems
                    onItemMove={this.handleItemMove}
                    onItemResize={this.handleItemResize}
                    itemRenderer={this.itemRenderer}
                    stickyHeader={true}
                    canMove={true}
                    canResize={"both"}
                    dragSnap={this.state.dragSnapMode}
                    onItemDoubleClick={this.handleMenuItemClick}
                    onCanvasDoubleClick={this.handleCanvasDblClick}
                    ref={ref => {this.scrollRef = ref} }
                >
                    <TimelineHeaders className="sticky" style={{backgroundColor: '#0099CC'}}>
                        <SidebarHeader>
                            {() => zoomButtons}
                        </SidebarHeader>
                        <DateHeader unit="primaryHeader" />
                        <DateHeader unit="week" />
                        <DateHeader 
                            unit="day" 
                            labelFormat={this.handleLabelFormat}
                        />
                        {/* {this.state.showHourLabel && (
                            <DateHeader
                            unit="hour"
                            labelFormat="HH"
                            />
                        )} */}
                    </TimelineHeaders>
                    <TimelineMarkers>
                        <TodayMarker interval={ 1000 * 60 * 60 }>
                            { ({ styles, date }) => {
                                styles={...styles, backgroundColor: 'red'}
                                return (
                                    <div style={styles} />
                                )
                            }}
                        </TodayMarker>

                        { this.props.start && 
                        <CustomMarker date={ parseInt(moment(this.props.start).format('x')) }>
                            { ({ styles, date }) => {
                                styles={...styles, width: 4, pointerEvents: 'auto'}
                                return (
                                    <div 
                                    id={`tooltip-marker-${this.props.start}`} 
                                    className="tooltip" 
                                    onMouseEnter={() => this.handleMarkerTooltipEnter(this.props.start)} 
                                    onMouseLeave={() => this.handleMarkerTooltipLeave(this.props.start)}
                                    style={styles}
                                    >
                                    <div className="tooltip-span" id={`tooltip-span-marker-${this.props.start}`}>
                                        <div>{ tr('project_start_date') }</div>  
                                    </div>
                                    </div>
                                )
                            }}
                        </CustomMarker> }

                        { this.props.end && 
                        <CustomMarker date={ parseInt(moment(this.props.end).add(1, 'days').format('x')) }>
                            { ({ styles, date }) => {
                                styles={...styles, width: 4, pointerEvents: 'auto'}
                                return (
                                    <div 
                                    id={`tooltip-marker-${this.props.end}`} 
                                    className="tooltip" 
                                    onMouseEnter={() => this.handleMarkerTooltipEnter(this.props.end)} 
                                    onMouseLeave={() => this.handleMarkerTooltipLeave(this.props.end)}
                                    style={styles}
                                    >
                                    <div className="tooltip-span" id={`tooltip-span-marker-${this.props.end}`}>
                                        <div>{ tr('project_end_date') }</div>  
                                    </div>
                                    </div>
                                )
                            }}
                        </CustomMarker> }

                        { this.props.dateMarkers && this.props.dateMarkers.length > 0 && 
                            this.props.dateMarkers.map(marker => {
                                return (
                                    <CustomMarker key={marker.id} date={ marker.ends ? parseInt(moment(marker.date).add(1, 'days').format('x')) : parseInt(moment(marker.date).format('x')) }>
                                        { ({ styles, date }) => {
                                            styles={...styles, width: 4, pointerEvents: 'auto'}
                                            return (
                                                <div 
                                                    id={`tooltip-marker-${marker.id}`} 
                                                    className="tooltip" 
                                                    onMouseEnter={() => this.handleMarkerTooltipEnter(marker.id)} 
                                                    onMouseLeave={() => this.handleMarkerTooltipLeave(marker.id)}
                                                    style={styles}
                                                >
                                                <div className="tooltip-span" id={`tooltip-span-marker-${marker.id}`}>
                                                    { marker.begins && <b>{ tr('project_start_date') }:</b> }
                                                    { marker.begins && marker.begins.map((begin, index) => {
                                                        return <div key={index}>- { begin }</div>
                                                    }) }

                                                    { marker.ends && <b>{ tr('project_end_date') }:</b>  }
                                                    { marker.ends && marker.ends.map((end, index) => {
                                                        return <div key={index}>- { end }</div>
                                                    }) }
                                                </div>
                                            </div>
                                            )
                                        }}
                                    </CustomMarker>     
                                )
                            })
                        }

                    </TimelineMarkers>

                </Timeline>
                } 

                <PersonnelModal 
					show={this.state.showPersonnelModal}
                    onClose={this.closePersonnelModal}
                    personnel={this.props.personnel}
                    projects={this.props.groups}
                    selectedItem={this.state.selectedItem}
                    handleSave={this.handleModalSave}
                    items={items}
                    convertTime={this.props.convertTime}
                    groups={this.props.groups}
                    handleDeletePersonnelItem={this.props.handleDeletePersonnelItem}
                    handleEditPersonnelItem={this.props.handleEditPersonnelItem}
				/>

                <ApModal
                    show={this.state.showAssignmentsModal}
                    onClose={this.closeAssignmentModal}
                    backdrop="static"
                    className="narrow overflow"
                    header={ 
                        <div className="padding-small">
                            <h4>{ tr('add_persons_to_project') }:</h4>
                        </div>
                    }
                    body={
                        <div className="padding overflow">

                            <ApSelect
                                label={ tr('add_persons_to_project') }
                                value={ this.state.addUser || null }
                                optionRenderer="user"
                                onChange={ ( user ) => { this.addUserAssigment( user ) } }
                                objKeyId="id"
                                objKeyValue="name"
                                apiUrl="project/find/personnel"
                                loading={ this.state.loading }
                                disabled={ this.state.locked || this.state.loading || false }
                                filterNonActives={true}
                                apiData={{ exluded: this.state.add_assignments_ids }}
                            />
                            <h4>{ tr('will_be_added') }:</h4>
                            {this.state.add_assignments.map(user=>{
                                return <div>{user.name}</div>})}
                            </div>
                    }

                    footer={
                        <div>
                            <div style={{display: 'flex'}}>
                            { this.state.error && <div style={{color: 'red', margin: '0 1em 0 auto'}}>{this.state.error}</div> }
                            </div>
                        <div className="padding" style={{display: 'flex', flexDirection: 'row'}}>
                        <ApButton className="cancel" onClick={() => this.closeAssignmentModal()}>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton
                            className="save"
                            color="blue"
                            onClick={ this.assignmentsSave }
                            loading={ this.state.loading }
                            style={{marginLeft: 'auto'}}
                            disabled={this.state.saveDisabled}
                        >
                            <SvgIcon icon="save" type="solid" />
                            { tr('add') }
                        </ApButton>
                        
                        </div>
                        </div>
                    }
                />

                </div> 
            )
    }

}
export default Personnel;