/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApUserImage from 'common/ApUserImage/ApUserImage.js';
import { tr } from 'services/Helpers/Helpers.js';
import { ApInput, ApInputStack } from 'common/ApInput/ApInput.js';

import './UserBrowser.css';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import moment from 'moment';

export default class UserBrowser extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            name: '',
            sorted: true,
            onlyActiveUsers: props.showActiveToggle ?true : false,
        }
        autoBind(this); 
    }

    arrowDisabled( direction )
    {
        const selectedUser = this.props.selectedUser;
        const users = this.filteredUsers();

        if( !users || !selectedUser || users.length == 0 )
            return true;

        const limit = direction == "prev" ? users[0].id : users[ users.length - 1 ].id;

        if( limit === selectedUser )
            return true;

        return false;
    }

    offsetUser( offset = 1 )
    {
        const index = this.filteredUsers().findIndex( u => u.id == this.props.selectedUser );
        const target = this.filteredUsers()[ index + offset ];
        if( target )
        {
            this.props.onChangeUser( target.id );
            let el = document.getElementById(target.id);
            el.scrollIntoView(true);
        }
    }

    handleNameChange(e)
    {
        this.setState({ name: e.target.value });
    }

    filteredUsers()
    {
        let users = this.props.users.sort((a, b) => a.name.localeCompare(b.name));
        if (!this.state.sorted)
        {
            users.reverse()
        }
        if (this.state.onlyActiveUsers) {
            users = users.filter(user => {
                let isValid = false;
                
                if (user.is_active) isValid = true;
                else return false;
                
                if (moment(user.contract_start).isBefore(moment())) isValid = true;
                if (!user.contract_end || moment(user.contract_end).isAfter(moment()))
                return isValid;
            });
        }
        return users.filter(user => user.name.toLowerCase().includes(this.state.name.toLowerCase()));
    }

    render() 
    {
        return (
            <div className="timetrackingUserBrowser">
                { this.props.users.length > 0 &&
                    <div style={{margin: '0.5em 0.5em 0 0.5em'}}>
                        <ApInputStack gap="25">
                        <ApInput
                            // noRightBorder
                            width="300px"
                            id="name"
                            name="name"
                            label={ tr('enter_name') }
                            value={ this.state.name }
                            onChange={ this.handleNameChange }
                        />
                        <ApButton width={this.props.showActiveToggle ? '50px' : 'auto'} onClick={ () => this.setState(prevState => ({sorted: !prevState.sorted})) }>
                            <SvgIcon icon={ this.state.sorted ? 'sort-alpha-down' : 'sort-alpha-up'} type="solid" />
                        </ApButton>
                        {this.props.showActiveToggle &&
                            <label className="onlyActiveSwitch" htmlFor={"only-active-switch" + (this.props.subordinates ? "-subordinates": "")}>
                            <ApSwitch
                                small
                                inline
                                id={"only-active-switch" + (this.props.subordinates ? "-subordinates": "")}
                                on={ this.state.onlyActiveUsers }
                                onChange={ ( e ) => this.setState({ onlyActiveUsers: e.target.checked }) }
                                disabled={ this.state.loading }
                            />
                                { tr('only_active_users') }
                            </label>
                        }
                        </ApInputStack>
                    </div>
                }
                <div className="controls left">
                    <div className={ "arrow prev" + ( this.arrowDisabled( "prev" ) ? " disabled" : "" ) } onClick={ () => this.offsetUser( -1 ) }>
                        <SvgIcon icon="chevron-left" type="solid" />
                    </div>
                </div>
                <div className="users">
                    { this.filteredUsers().map( user => {
                        
                        let classes = [ "user" ];
                        
                        if( this.props.selectedUser == user.id )
                            classes.push( "selected" )

                        return (
                            <div id={ user.id } key={ user.id } className={ classes.join(" ") } onClick={ () => this.props.onChangeUser( user.id ) }>
                                <ApUserImage user={ user } size="small" />
                                <strong>{ user.name }</strong><br />
                                <small>{ tr('entry_count') }: { user.entry_count }</small>
                            </div>
                        );
                    })}
                </div>
                <div className="controls right">
                    <div className={ "arrow next" + ( this.arrowDisabled( "next" ) ? " disabled" : "" ) } onClick={ () => this.offsetUser( 1 ) }>
                        <SvgIcon icon="chevron-right" type="solid" />
                    </div>
                </div>
            </div>
        );
    }
}
