import React from 'react';

import { errorPopper, tr } from 'services/Helpers/Helpers';
import api from 'services/Api/Api.js';
import { ApTab, ApTabs } from 'common/ApTabs/ApTabs';
import CashFlow from './Tabs/CashFlow';
import Period from './Tabs/Period';
import { ApInput, ApInputStack } from 'common/ApInput/ApInput';
import ApButton from 'common/ApButton/ApButton';
import autobind from 'react-autobind';
import moment from 'moment';
import SvgIcon from 'common/SvgIcon/SvgIcon';


class CashFlowForecast extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            sales_orders: [],
            purchase_orders: [],
            all_orders: [],
            in_total: 0,
            orders_in_total: 0,
            po_in_total: 0,

            start: moment().format('YYYY-MM-DD'),
            end: null,
            openingBalance: 0,

            selectedTab: 0,
        };
        autobind(this);
    }

    handleTimeSelector(selection) {
        this.setState({timeSelection: selection})
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        const {start, end} = this.state;

        this.setState({loading: true});

        api({
            method: 'post',
            url: 'cashflowforecast/get/data',
            data: {start, end}
        }).then(response => {
            // console.log(response);
            const all_orders = response.all_orders.sort((a, b) => moment(a.due_date).valueOf() - moment(b.due_date).valueOf());
            all_orders.map((item, index) => {
                if (index === 0) {
                    if (item.type === 'sales_order') {
                        item.cumulative = parseFloat(item.price);
                    }
                    else if (item.type === 'purchase_order') {
                        item.cumulative = -1*parseFloat(item.price);
                    }
                    else if (item.type === 'payment_post') {
                        item.cumulative = parseFloat(item.payment_amount);
                    }
                } else {
                    if (item.type === 'sales_order') {
                        item.cumulative = parseFloat(item.price) + all_orders[index-1].cumulative;
                    }
                    else if (item.type === 'purchase_order') {
                        item.cumulative = -1*parseFloat(item.price) + all_orders[index-1].cumulative;
                    }
                    else if (item.type === 'payment_post') {
                        item.cumulative = parseFloat(item.payment_amount) + all_orders[index-1].cumulative;
                    }
                }
            })
            this.setState({
                loading: false,
                sales_orders: response.orders,
                purchase_orders: response.purchase_orders,
                payment_posts: response.payment_posts,
                all_orders,
                in_total: response.in_total,
                orders_in_total: response.orders_in_total,
                po_in_total: response.po_in_total,
            });
        }).catch(error => {
            this.setState({loading: false});
            console.error(error);
            errorPopper(error, tr('get_error'));
        })
    }

    render() {
        return (
            <div className='apBox' id='cash-flow-forecast'>
                <div className='apBoxHeader'>
                    <h1>{tr('cash_flow_forecast')}</h1>
                </div>
                <div className='padding'>
                    <ApInputStack gap={0}>
                        <ApInput 
                            width="200"
                            type="dateSafe"
                            id="start"
                            name="start"
                            label={tr('start_date')}
                            value={ this.state.start }
                            onChange={ (e) => this.setState({ start: e.target.value }) }
                            clearable
                            autoComplete="off"
                        />
                        <ApInput 
                            width="200"
                            type="dateSafe"
                            id="end"
                            name="end"
                            label={tr('end_date')}
                            value={ this.state.end }
                            onChange={ (e) => this.setState({ end: e.target.value }) }
                            clearable
                            autoComplete="off"
                        />
                        <ApInput 
                            width="200"
                            type="number"
                            id="openingBalance"
                            name="openingBalance"
                            label={tr('opening_balance')}
                            value={ this.state.openingBalance }
                            onChange={ e => this.setState({openingBalance: e.target.value}) }
                            autoComplete="off"
                        />
                        <ApButton 
                            color='green'
                            onClick={this.getData}
                        >
                            {tr('search')} <SvgIcon type='solid' icon='search' />
                        </ApButton>
                    </ApInputStack>
                </div>
                <ApTabs selected={this.state.selectedTab} onChange={this.handleTabChange}>
                    <ApTab icon="receipt" label={ `${tr('weekly')} / ${tr('monthly')}` } mountOnSelect>
                        <Period 
                            all_orders={this.state.all_orders}
                            in_total={this.state.in_total}
                            orders_in_total={this.state.orders_in_total}
                            po_in_total={this.state.po_in_total}
                            loading={this.state.loading}
                            start={this.state.start}
                            end={this.state.end}
                            openingBalance={this.state.openingBalance}
                        />
                    </ApTab>
                    <ApTab icon="money-bill-wave" label={ tr('cash_flow') } mountOnSelect>
                        <CashFlow 
                            all_orders={this.state.all_orders}
                            in_total={this.state.in_total}
                            orders_in_total={this.state.orders_in_total}
                            po_in_total={this.state.po_in_total}
                            loading={this.state.loading}
                            openingBalance={this.state.openingBalance}
                        />
                    </ApTab>
                </ApTabs>
            </div>
        )
    }
}

export default CashFlowForecast;
