import React from 'react';
import autoBind from 'react-autobind';
import ApButton from 'common/ApButton/ApButton.js';
import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import api from 'services/Api/Api.js';
import ApReactTable, { colPreset } from 'common/ApReactTable/ApReactTable.js';
import { debounce } from 'throttle-debounce';
import { tr } from 'services/Helpers/Helpers';
import ApTooltip from 'common/ApTooltip/ApTooltip';

export default class AddProjectsModal extends React.Component
{

	constructor(props)
	{
		super(props);

		this.state = {
			projects: [],
			pages: -1,
			selectedProjects: [],
		};

		autoBind(this);
		if (this.props.assignments) {
			this.getProjectsDebounced = debounce( 500 , this.getAssignments );
		}
		else {
			this.getProjectsDebounced = debounce( 500 , this.getProjects );
		}
		
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.show && prevProps.show !== this.props.show)
        {
			if (this.props.assignments) {
				this.getAssignments({
					page: 0,
					pageSize: 10,
					sorted: [],
					filtered: []
				})
			}
			else {
				this.getProjects({
					page: 0,
					pageSize: 10,
					sorted: [],
					filtered: []
				})
			}
			
        }
	}
	
	getAssignments(state)
	{
		this.setState({ loading: true });
        api({
            method: 'post',
			url: `project/management/personnelassignments`,
			data: {
				page: state.page,
				pageSize: state.pageSize,
				sorted: state.sorted,
				filtered: state.filterable ? state.filtered : [],
			},
        }).then((response) => {
			// assignments have begin_at value. Set begin_at value to begin_date to use same names as projects
			if (response.data) {
				response.data.forEach(project => {
					project.begin_date = project.begin_date
						? project.begin_date
						: project.begin_at;

					project.end_date = project.end_date
						? project.end_date
						: project.end_at;

					if (project.temp_changes) {
						if (project.temp_changes.begin_at) {
							project.begin_date = project.temp_changes.begin_at;
						}
						if (project.temp_changes.end_at) {
							project.end_date = project.temp_changes.end_at;
						}
					}
				})
			}
            this.setState({ 
				projects: response.data,
				pages: response.pages,
                loading: false,
			});
        }).catch((error) => {
            this.setState({
				projects: [],
                pages: -1,
				loading: false 
			});
        });
	}

	getProjects(state)
	{
		if (this.props.assignments) {
			this.getAssignments(state);
		}
		else {	
			this.setState({ loading: true });
			api({
				method: 'post',
				url: `project/management/personnelprojects`,
				data: {
					page: state.page,
					pageSize: state.pageSize,
					sorted: state.sorted,
					filtered: state.filterable ? state.filtered : [],
				},
			}).then((response) => {
				this.setState({ 
					projects: response.data,
					loading: false,
				});
			}).catch((error) => {
				this.setState({
					projects: [],
					pages: -1,
					loading: false 
				});
			});
		}
	}

	handleSave()
	{
		this.props.addProjects(this.state.selectedProjects)
		this.setState({ selectedProjects: [] })
		this.props.onClose()
	}

	handleSelectedRows(indexes)
	{
		let ids = [];
    	indexes.forEach( index => {
    		ids.push( this.state.projects[ index ].id );
		});

		this.setState({ selectedProjects: ids })
	}

	renderForm()
	{
		const projectCodeField = this.props.assignments
			? '_original.project.project_code'
			: '_original.project_code';
		
		const columns = [
			{
				id: 'name', 
				Header: tr('name'), 
				accessor: 'name',
				width: 180,
				Cell: ( props ) => {
					return (
						<div className="nameCell">
							<strong>{ props.original.name }</strong><br/>
							<small>{ props.original.project_code || props.original.project.project_code }</small>
						</div>
					);
				},
				customFilter: {
					type: "text",
					placeholder: tr('name'),
					fields: ["_original.name", projectCodeField]
				},
			},
			{
				id: 'customer',
				Header: tr('customer'),
				accessor: 'customer_name',
				customFilter: {
					type: 'text',
					placeholder: tr('customer'),
					fields: ['_original.customer_name']
				},
			},
			colPreset({
				type: "dateAndDiff",
				id: 'begin_date', 
				Header: tr('start_date'), 
				accessor: "begin_date",
				// width: 200,
				filterable: false,
			}),
			colPreset({
				type: "dateAndDiff",
				id: 'end_date', 
				Header: tr('end_date'), 
				accessor: "end_date",
				// width: 200,
				filterable: false,
			}),
		];

		const form =
		<ApReactTable 
			// onFetchData={ this.getProjectsDebounced }
			pages={ this.state.pages }
			defaultPageSize = { 10 }
			showFiltersInitially
			loading={ this.state.loading }
			data={ this.state.projects } 
			columns={[
				{
					customizable: true, 
					columns
				}
			]}
			filterable
			multiselect={[
				{ 
					icon: "toggle-on", 
					label: tr('add_selected'), 
					action: this.handleSave, 
					closeAfter: true,
				}
			]}
			selectedRowsToParent={this.handleSelectedRows}
			showModal={this.props.show}
			minRows={10}
			// manual
		/>

		return form;
	}

	render()
	{
		const form = this.renderForm();
		return(
			<ApModal
				show={this.props.show}
				handleClose={ this.props.onClose }
				closeFromBg
				className="medium"
				header={ 
					<div className="padding-small">
						{this.props.assignments?<h4>{ tr('add_assignments') }</h4>:<h4>{ tr('add_projects') }</h4>}
					</div>
				}
				body={
					<div className="padding" style={{ marginTop: 10 }}>
					  {form}
					</div>
				}
				footer={
					<div className="padding" style={{display: 'flex', flexDirection: 'row'}}>
						<ApButton className="cancel" onClick={() => this.props.onClose()}>
							<SvgIcon icon="times" type="solid" />
							{ tr('cancel') }
						</ApButton>

						<ApButton
							className="save"
							color="blue"
							onClick={ this.handleSave }
							loading={ this.state.loading }
							style={{marginLeft: 'auto'}}
							disabled={this.state.selectedProjects.length === 0}
						>
							<SvgIcon icon="save" type="solid" />
							{ tr('save') }
						</ApButton>
					</div>
				  }
			/>
		);
	}
}