/* eslint-disable eqeqeq */
import React from 'react';
import moment from 'moment';
import autoBind from 'react-autobind';
import { Collapse } from 'react-bootstrap';

import api                from 'services/Api/Api.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';

import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApSwitch           from 'common/ApSwitch/ApSwitch.js';
import ApSelect           from 'common/ApSelect/ApSelect.js';

import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';

import { ApInput,
         ApInputStack,
         ApAddon }        from 'common/ApInput/ApInput.js';
import { roundToDecimals, tr, keyExists } from 'services/Helpers/Helpers.js';
import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect.js';

import './NewProject.css';

let timer = null;

class NewProject extends React.Component
{
    constructor(props)
    {

        super(props);
        this.state = {
            types: [],
            loading: true,
            statLoading: false,
            codeLoading: false,
            has_customer: true,
            project_storage_none: false,
            project_storage_enabled: false,
            project_storage_required: false,
            project_storage_type: null,
            selected_storage:	null,
            hour_approvers: [],
            billers: [],
            manualTypeChanged:false,
        }

        const customerValidator = ( value ) => {
            if( !this.state.has_customer ) return true;
            return Boolean( value );
        }
        
        const storageValidator = ( value ) => {
            if ( this.state.storage_options == 'storage_disabled' || this.state.storage_options=='storage_enabled' || this.state.main_project_disabled ) {
                return true;
            }
            if ( (this.state.storage_options == 'storage_required_normal') && this.state.selected_storage != null ) {
                return true;
            }
            if ( this.state.storage_options == 'storage_required' && (this.state.use_project_storage || this.state.selected_storage != null )) {
                return true;
            }
            if ( this.state.storage_options == 'storage_required_project' && this.state.use_project_storage ) {
                return true;
            }
            return Boolean( value );
        }


        const endDateValidator = ( value ) => {
            if( this.state.is_continuous ) return true;
            return Boolean( value );
        }

        const offerValidator = ( value ) => {
            if( this.state.do_offer ) return true;
            return Boolean( value );
        }


        this.validator = new ApValidate( this, {
            "name":         { filter: 'required', state: 'error', text: tr('name_required')},
            "project_code": { filter: 'required', state: 'error', text: tr('project_code_mandatory')},
            "customer":     { filter: customerValidator, state: 'error', text: tr('customer_mandatory')},

            "storage":     { filter: storageValidator, state: 'error', text: tr('storage_validate_error')},
            
            "begin_date":   { filter: 'required', state: 'error', text: tr('start_date_mandatory')},
            "end_date":     { filter: endDateValidator, state: 'error', text: tr('end_date_mandatory')},

            "offer_received": { filter: offerValidator, state: 'error', text: tr('offer_received_validate_error')},
            "offer_deadline": { filter: offerValidator, state: 'error', text: tr('offer_deadline') },
            
            "cost_center": {
                filter: (value) => {
                    if (!keyExists(this.state, 'cost_center_is_required', true)) return true;
                    return (value != null ? true : false);
                },
                state: 'error',
                text: tr('mandatory'),
                textAll: tr('cost_center')
            },
            "billers": {
                filter: (value) => {
                    if (!keyExists(this.state, 'billing_hourly', true)) return true;
                    return Boolean(Array.isArray(value) && value.length);
                },
                state: 'error',
                text: tr('mandatory'),
                textAll: tr('biller')
            },
                
            
            
        });

        const oneIsInvalid = ( test ) => {
            let text = null;
            const invalid = test.some( field  => {
                text = this.validator.getText( field )
                return text;
            });
            if( invalid ) return text;
            return null;
        }

        this.steps = [{
            name: tr('basics'),
            render: this.renderStepBasic.bind( this ),
            invalid:  () => {
                return oneIsInvalid([
                    'name',
                    'project_code',
                ]);
            }
        },{
            name: tr('customer'),
            render: this.renderStepCustomer.bind( this ),
            invalid:  () => {
                return oneIsInvalid([
                    'customer',
                ]);
            }
        },{
            name: tr('storage_component_additional_info'),
            render: this.renderStepDetails.bind( this ),
            invalid:  () => {
                return oneIsInvalid([
                    'begin_date',
                    'end_date',
                    'storage',
                    'cost_center',
                    'billers'
                ]);
            }

        },{
            name: tr('offer_calculation'),
            render: this.renderStepOffer.bind( this ),
            invalid:  () => {
                return oneIsInvalid([
                    'offer_received',
                    'offer_deadline',
                ]);
            }
        }];

        autoBind(this);
    }

    resetState()
    {
        let defaultTypeId = null;
        if( this.state.types.length )
            defaultTypeId = this.state.types[ 0 ].id;
        if (this.props.copyProject && this.props.copyProject.type_id) {
            defaultTypeId = this.props.copyProject.type_id;
        }

        let customer, customer_address = null;

        const stateResetData = {

            automaticCode: true,

            type: defaultTypeId,
            name: '',
            project_code: '',
            description: '',


            customer: customer,
            customer_address: null,
            customer_contact: null,
            offer_stats: null,
            newCustomer_name: "",
            newCustomer_id: null,
            newCustomer_taxnumber: "",
            newCustomer_phone: "",
            newCustomer_email: "",
            newCustomer_street: "",
            newCustomer_postal_code: "",
            newCustomer_country: "",
            newCustomer_first_name: "",
            newCustomer_last_name: "",

            manager: null,
            workhour_approver: null,

            is_continuous: false,
            begin_date: moment().format('YYYY-MM-DD'),
            end_date: moment().add(2, 'w').format('YYYY-MM-DD'),
            end_work: moment().add(2, 'w').format('YYYY-MM-DD'),

            do_offer: false,
            offer_received: moment().format('YYYY-MM-DD'),
            offer_deadline: moment().add(1, 'w').format('YYYY-MM-DD'),
            offer_success_probability: null,
            hour_approvers: [],

        }


        if (this.props.placeholders) {
            
            const placeholders = this.props.placeholders;
            // Add more placeholders if needed 
            if (placeholders.customer && placeholders.customer?.id!= null) {
                if (!placeholders.customer.name) {
                    this.getCustomerById(placeholders.customer.id,true);
                    
                }
                // customer = placeholders.customer;
            }

            if (placeholders.name) {
                stateResetData.name = placeholders.name;
            }
            if (placeholders.description) {
                stateResetData.description = placeholders.description;
            }
            if (placeholders.customer_address) {
                stateResetData.customer_address = placeholders.customer_address;
            }
            if (placeholders.customer_contact) {
                stateResetData.customer_contact = placeholders.customer_contact;
            }
            if (placeholders.customer_order_number) {
                stateResetData.customer_order_number = placeholders.customer_order_number;
            }
            if (placeholders.billing_hourly) {
                stateResetData.billing_hourly = placeholders.billing_hourly;
            }
            if (placeholders.is_continuous) {
                stateResetData.is_continuous = placeholders.is_continuous;
            }
            if (placeholders.start) {
                stateResetData.begin_date = placeholders.start;
            }
            if (placeholders.end) {
                stateResetData.end_date = placeholders.end;
            }
            if (placeholders.end_work) {
                stateResetData.end_work = placeholders.end_work;
            }
            if (placeholders.manager) {
                stateResetData.manager = placeholders.manager;
            }
            if (placeholders.hour_approvers) {
                stateResetData.hour_approvers = placeholders.hour_approvers;
            }
            if (placeholders.cost_center) {
                stateResetData.cost_center = placeholders.cost_center;
            }
            if (placeholders.user_add_progress) {
                stateResetData.user_add_progress = placeholders.user_add_progress; 
            }
            if (placeholders.billers) {
                stateResetData.billers = placeholders.billers; 
            }


        }

        

        this.setState(stateResetData, () => {
            this.getSuggestedProjectCode();
        });

    }

    async onOpen()
    {
        if( !this.state.types.length ) {
            await this.getProjectTypes();
        }
        else
            this.resetState();
    	this.getProjectSettings();
    }

    async getProjectTypes()
    {
        this.setState({ loading: true });

        return api({
            method: 'get',
            url: 'projects/management/types',
            errorPopper: tr('get_error'),
        }).then(( response ) => {
            this.setState({
                types: response,
                loading: false,
            }, this.resetState );
            if (this.props.copyProject && this.props.copyProject.type_id) {
                this.typeChange(this.props.copyProject.type_id);
            } else {
                this.typeChange(response[0].id);
            }
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }
    getFromatedCostCenter(id) {
        // this.setState({ loading: true });

        api({
            method: 'get',
            url: 'search/get/formated/costcenter/'+id,
            errorPopper: tr('get_error'),
        }).then((response) => {
            this.setState({
                cost_center: response,
                loading: false,
            });
            
        }).catch((error) => {
            // this.setState({ loading: false });
        });
    }
    

    async getProjectSettings()
    {
        this.setState({ loading: true });

        return api({
            method: 'get',
            url: 'projects/management/settings',
            errorPopper: tr('get_error'),
        }).then(( response ) => {
            let cost_center = null;
            let billers = [];
            let hour_approvers = [];
            let manager = null;
            let type = this.state.type;
            if (response.cost_center_is_enabled && this.props.copyProject && this.props.copyProject.cost_center) {
                cost_center = this.props.copyProject.cost_center;
            }
            if (this.props.copyProject) {
                if (this.props.copyProject.billers) {
                    billers = this.props.copyProject.billers.filter(biller => biller.id);
                }
                if (this.props.copyProject.type_id) {
                    type = this.props.copyProject.type_id;
                }
                if (this.props.copyProject.hour_approvers) {
                    hour_approvers = this.props.copyProject.hour_approvers.filter(hour_approver => hour_approver.id);
                }
                if (this.props.copyProject.manager) {
                    manager = this.props.copyProject.manager.id ? this.props.copyProject.manager : null;
                }
            }

            const dataToState = {
                project_storage_none: response.project_storage_none,
                project_storage_enabled: response.project_storage_enabled,
                project_storage_required: response.project_storage_required,
                //use_project_storage: response.project_storage_enabled,
                project_ext_work_enabled: response.project_ext_work_enabled,
                work_progress_in_timetracking_enabled: response.work_progress_in_timetracking_enabled,
                cost_center_is_required: response.cost_center_is_required,
                cost_center_is_enabled: response.cost_center_is_enabled,
                loading: false,
                cost_center,
                type,
                // billers,
                // hour_approvers,
                // manager,
            };

            if (!this.props.placeholders) {
                dataToState.manager = manager;
                dataToState.hour_approvers = hour_approvers;
                dataToState.billers = billers;

                    
            }
            this.setState(dataToState, () => {
                if( this.state.types.length ) {
                    this.typeChange(type);
                }
            } );
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }
    
    getSuggestedProjectCode()
    {
        if( !this.state.automaticCode )
            return null;

        this.setState({ codeLoading: true });
        api({
            method: 'get',
            url: 'project/type/' + this.state.type + '/nextcode',
            errorPopper: tr('get_error'),
        }).then(response => {
            this.setState({
                project_code: response,
                codeLoading: false,
            }, this.validateStep );
        }).catch((error) => {
            this.setState({ codeLoading: false });
        });
    }

    getProbabilityOptions()
    {
        return [
            { value: 'null', label:  '' },
            { value: 0,    label:   '0%' },
            { value: 10,   label:  '10%' },
            { value: 20,   label:  '20%' },
            { value: 30,   label:  '30%' },
            { value: 40,   label:  '40%' },
            { value: 50,   label:  '50%' },
            { value: 60,   label:  '60%' },
            { value: 70,   label:  '70%' },
            { value: 80,   label:  '80%' },
            { value: 90,   label:  '90%' },
            { value: 100,  label: '100%' },
        ];
    }

    save()
    {
        const state = this.state;
        let data = {
            type_id: state.type,
            name: state.name,
            project_code: state.project_code,
            description: state.description,
        };

        if( state.has_customer )
        {
        	if (state.customer=="new") {
        		
        		data.customer_id = "new";
        		data.customerNew={	name:state.newCustomer_name, 
			                        is_active         :  true,
			                        extranet_active   :  false,
			                        account_manager_id:  null,
			                        industry_id       :  null,
			                        taxnumber         :  state.newCustomer_taxnumber,
			                        phone             :  state.newCustomer_phone,
			                        email             :  state.newCustomer_email,
			                        website           :  null,
			                        turnover          :  null,
			                        employs           :  null,
			                        default_delivery_method_id : null,
			                        default_delivery_term_id   : null,
			                        default_payment_condition_id : null,
			                        default_shipping_address_id : null,
			                        default_billing_address_id : null,
			                        addresses:{},
			                        contacts:{},
			                        groups:{},
			                        events:{},
			                        e_invoicing_edi:null,
			                        e_invoicing_iban:null,
			                        e_invoicing_webservice_marking:null,
			                        operator_edi:null,
			                        operator_bic:null,
			                        operator_char:null};
        		
                if (state.newCustomer_street!="") {
                	data.customerNew['addresses']=new Array();
                	data.customerNew['addresses'].push({
                        id		:	"new0",
                        new 	: 	false,
                        type	:	"general",
                        name	:	tr('default'),
                        street	:	state.newCustomer_street,
                        city	:	state.newCustomer_city,
                        postal_code:state.newCustomer_postal_code,
                        country	:	state.newCustomer_country
                	});
                }
                if (state.newCustomer_first_name!="" && state.newCustomer_last_name!="") {
                	data.customerNew['contacts']=new Array();
                	data.customerNew['contacts'].push({
                        id		:	"new0",
                        new 	: 	false,
                        type	:	"general",
                        name	:	tr('default'),
                        first_name:state.newCustomer_first_name,
                        last_name:state.newCustomer_last_name
                	});
                }
        	}
        	else {
        		data.customer_id = this.state.customer.id;
        	}
            if( state.customer_address )
                data.customer_address_id = this.state.customer_address.id;
            if( state.customer_contact )
                data.customer_contact_id = this.state.customer_contact.id;
			if( state.customer_order_number )
                data.customer_order_number = this.state.customer_order_number;
            
            data.default_item_tax_group_id = state.customer.default_item_tax_group_id;
            data.default_work_tax_group_id = state.customer.default_work_tax_group_id;
            data.default_expence_tax_group_id = state.customer.default_expence_tax_group_id;
        }

        data.billing_hourly = state.billing_hourly;

        if (state.billing_hourly)
            data.billers = state.billers;

        if( state.manager )
            data.manager_id = state.manager.id;

        if( state.workhour_approver )
            data.workhour_approver_id = state.workhour_approver.id;

        if (state.hour_approvers) {
            data.hour_approvers = state.hour_approvers;
        }

        data.begin_date = state.begin_date;
        if( !state.is_continuous ) {
            data.end_date = state.end_date;
            data.end_work = state.end_work;
        }
        
        data.do_offer = state.do_offer;
        data.user_add_progress = state.user_add_progress;
        
        if( state.do_offer )
        {
            data.offer_received = state.offer_received;
            data.offer_deadline = state.offer_deadline;
            data.offer_success_probability = state.offer_success_probability;
            if( data.offer_success_probability  === 'null') data.offer_success_probability = null;
        }

        data.selected_storage_id=null;
        data.use_project_storage = state.use_project_storage;
        if (!state.use_project_storage && !state.project_storage_none) {
            if (state.selected_storage) {
                data.selected_storage_id = state.selected_storage.id;
            }
        }
        data.cost_center_id = state.cost_center ? state.cost_center.id : null;
        data.copy_project_id = this.props.copyProjectId ? this.props.copyProjectId : null;

        if (this.props.placeholders && this.props.placeholders.tree) {
            data.tree = this.props.placeholders.tree;
        }

        this.setState({ loading: true });

        api({
            method: 'post',
            url: 'project/new/save',
            data: data,
            errorPopper: tr('save_error'),
        }).then(( response ) => {
            this.props.onClose();
            this.props.onSave( response );
            this.setState({ loading: false } );
        }).catch((error) => {
            this.setState({ loading: false } );
        });


    }


    getCustomerStats( id )
    {
        this.setState({ statLoading: true });
        api({
            method: 'get',
            url: `project/new/offerstats/${ id }`,
            errorPopper: tr('get_error'),
        }).then(response => {
            this.setState({ 
                statLoading: false,
                offer_stats: response,
            });
        }).catch((error) => {
            this.setState({ statLoading: false });
        });
    }

    getCustomerById(id, dontUpdateLinkeds=false) {
        // this.setState({ loading: true });
        let data = {
            getById: id,
            search:"",
        }
        api({
            method: 'post',
            data: data,
            url: `project/find/customer`,
            errorPopper: tr('get_error'),
        }).then(response => {
            this.setState({
                loading: false,
            });
            this.customerChange(response, dontUpdateLinkeds);
        }).catch((error) => {
            // this.setState({ loading: false });
        });
        // this.getCustomerStats(id);

    }

    handleAutomaticCodeChange()
    {
        this.setState({ automaticCode: !this.state.automaticCode }, () => {
            this.getSuggestedProjectCode();
        });
    }

    customerChange(customer, dontUpdateLinkeds=false)
    {
        if( customer ){
            this.getCustomerStats(customer.id);
            if (customer.cost_center && customer.cost_center.id) {
                this.getFromatedCostCenter(customer.cost_center.id);
            }
        }
          
        else
            this.setState({ offer_stats: null });

        if (dontUpdateLinkeds) {
            this.setState({
                customer: customer,

                offer_stats: null,
            });
        }
        else {
            this.setState({
                customer: customer,
                customer_address: null,
                customer_contact: null,
                offer_stats: null,
            });
        }
        
    }

    getTypeOptions()
    {
        let options = [];
        if( this.state.types )
        {
            this.state.types.forEach( type => {
                options.push({
                    value: type.id,
                    label: type.name,
                });
            });
        }
        return options;
    }

    typeChange(value, manualTypeChange =false)
    {

        this.setState({ type: value });
        if (manualTypeChange) {
            this.setState({ manualTypeChanged: manualTypeChange });
        }

        const settings = this.state.types.find((type) => {
            return Number(type.id) === Number(value)})

        const hasCustomer = settings.project_client_options === "customer_required" || settings.project_client_options === "client_optional";
        let billingHourly = settings.project_billing_options === "hourly_billing_required";
        const doOffer = settings.project_offer_options === "offer_required" || settings.project_offer_options === "offer_optional";
        let isContinuous = (billingHourly || settings.project_continuous_options == 'continuous_required') 
            && settings.project_continuous_options != 'cannot_be_continuous'; //Defaulttaa että tuntilaskutusprojekti on jatkuva/jos merkitty jatkuvaksi

        let useProjectStorage = settings.use_project_storage;
        let projectStorageType = settings.project_storage_type;
        let selectedStorage = settings.selected_storage;
        
        if (settings.project_storage_options=='storage_required_project') {
            useProjectStorage=true;
        }

        if (settings.project_storage_options=='storage_required_normal') {
            useProjectStorage=false;
        }
        
        if ( settings.storage_options == 'storage_disabled' || settings.main_project_logging_disabled ) {
            useProjectStorage=false;
            projectStorageType=null;
            selectedStorage=null;
        }

        const stateData = {
            type: value,
            has_customer: hasCustomer,
            billing_hourly: billingHourly,
            do_offer: doOffer,
            use_project_storage: useProjectStorage,
            storage_options: settings.project_storage_options,
            selected_storage: settings.auto_use_storage_id,
            main_project_disabled: settings.main_project_logging_disabled,
            project_storage_type: projectStorageType,
            selected_storage: selectedStorage,
            is_continuous: isContinuous
        };

        if (manualTypeChange == false && this.state.manualTypeChanged == false && this.props.placeholders
            && this.props?.placeholders.is_continuous && this.props?.placeholders.billing_hourly) {
            delete stateData.is_continuous;
            delete stateData.billing_hourly;
        }



        this.setState(stateData, () => {
            this.getSuggestedProjectCode();
        });
    }
    
    renderSwitchbox( value, label, labelSmall = null, onChangeMore = null, disabled = false )
    {
        let onChange = null;

        let toggleValue = () => this.setState({ [ value ]: !this.state[ value ] })
        if( typeof onChangeMore === 'function' )
            onChange = () => { toggleValue(); onChangeMore(); };
        else 
            onChange = () => { toggleValue(); };

        return <div className={ "apFormGroup" + ( this.state[ value ] ? " success" : "" ) }>
            <div className="apSwitchBlock small">
                <label htmlFor={`newProject_${ value }`} className="info">
                    { label }
                    <small>{ labelSmall }</small>
                </label>
                <ApSwitch
                    id={`newProject_${ value }`}
                    on={ this.state[ value] }
                    onChange={ onChange }
                    disabled={ this.state.loading || disabled }
                />
            </div>
        </div>
    }

    renderStepBasic()
    {
        if( this.state.loading )
        {
            return <div className="text-center">
                <div className="apInlineLoader"></div>
            </div>
        }

        if( !this.state.types.length )
        {
            return <div className="apWarningMsg">
                <h2>{ tr('no_project_types_found') }</h2>
                <p>{ tr('no_project_types_found_info') }</p>
            </div>
        }

        return <div className="stepBasic">

            <ApInputStack gap="0">
                <ApAddon noRightBorder width="200px">
                    { tr('project_type') }
                </ApAddon>
                <ApInput
                    type="select"
                    id="newProject_type"
                    name="type"
                    value={ this.state.type }
                    options={ this.state.types.map( t => { return { value: t.id, label: t.name } }) }
                    onChange={ ( e ) => this.typeChange( e.target.value ,true) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </ApInputStack>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('project_type_info') }
            </div>

            <ApInput
                type="text"
                id="newProject_name"
                name="name"
                label={ tr('name') }
                value={ this.state.name ? this.state.name : '' }
                onChange={ ( e ) => this.handleProjectNameChange(e) }
                validationState={ this.validator.getState('name') }
                tooltip={ this.validator.getText('name') }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />

            <ApInputStack gap="0">
                <ApInput
                    type="text"
                    id="newProject_project_code"
                    name="project_code"
                    label={ tr('project_code') }
                    value={ this.state.project_code }
                    onChange={ ( e ) => this.setState({ project_code: e.target.value }) }


                    validationState={ this.validator.getState('project_code') }
                    tooltip={ this.validator.getText('project_code') }
                    loading={ this.state.loading || this.state.codeLoading }
                    disabled={ this.state.loading }
                    readOnly={ this.state.automaticCode }
                />
                <ApAddon noLeftBorder width="200px" labelFor="newProject_automatic-code-switch">
                    <span>{ tr('automatic') }</span>
                    <ApSwitch
                        small
                        inline
                        id="newProject_automatic-code-switch"
                        on={ this.state.automaticCode }
                        onChange={ this.handleAutomaticCodeChange }
                        disabled={ this.state.loading }
                    />
                </ApAddon>
            </ApInputStack>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('automatic_project_code_info') }
            </div>

            <ApInput
                type="textarea"
                id="newProject_description"
                name="description"
                label={ tr('description') }
                value={ this.state.description }
                rows={ 4 }
                onChange={ ( e ) => this.setState({ description: e.target.value }) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
        </div>
    }

    handleProjectNameChange(event) {
        clearTimeout(timer);
        if (event.target.value.length > 255) {
            // Stop multiple error messages from popping with timer
            timer = setTimeout(()=> {
                window.emitter.emit('popper', {
                    type: 'danger',
                    content: <strong>{ tr('project_name_length_error') }</strong>,
                });
            }, 350);
        } else {
            this.setState({name: event.target.value})
        }
    }

    selectChange( field, value )
    {
        this.setState({ [ field ]: value ? value: null });
    }

    renderStepCustomer()
    {
        const settings = this.state.types.find((type) => {
            return Number(type.id) === Number(this.state.type)})

            let DisabledState = Boolean

            DisabledState = settings.project_client_options == "customer_required" || settings.project_client_options == "cannot_have_customer" ?  true : false;

        const hasCustomerDom  = this.renderSwitchbox(
            'has_customer', 
            tr('project_has_customer'),
            null,
            tr('project_is_attached_to_customer'),
            DisabledState
        );
        let statsDom = null;

        const newCustomerDom  = this.renderSwitchbox(
                'new_customer', 
                tr('create_new_customer'),
                tr('new_customer_is_created_for_project'),
                null,
                settings.project_client_options == "cannot_have_customer"
            );
        
        if (this.state.new_customer) {
        	if (!this.state.has_customer) {
        		this.setState({ has_customer : true });
        	}
        	if (this.state.newCustomer_name!="" && this.state.customer!="new") {
        		this.setState({ customer : "new" })
        	}
        	if (this.state.newCustomer_name!=this.state.newCustomer_name_old) {
        		//console.log(this.state);
        		this.setState({ newCustomer_name_old : this.state.newCustomer_name });
        	}
        }
        
        if( this.state.customer )
        {
        	
            const stats = this.state.offer_stats;
            if( !stats )
                statsDom = <div className="apInlineLoader"></div>
            else
            {
                if( stats.projects )
                {
                    statsDom = <table className="statsTable"><tbody>
                        <tr>
                            <td>{ tr('previous_offers') }</td>
                            <td>{ stats.offers } { tr('pcs') }</td>
                        </tr>
                        <tr>
                            <td>{ tr('offers_accepted') }</td>
                            <td>{ stats.offers_success } { tr('pcs') }</td>
                            <td>{ roundToDecimals( stats.offers_success / stats.offers * 100, 0 ) } %</td>
                        </tr>
                        <tr>
                            <td>{ tr('projects_without_offer') }</td>
                            <td>{ stats.no_offers } { tr('pcs') }</td>
                        </tr>
                    </tbody></table>
                }
                else
                {
                    statsDom = <div>{ tr('no_previous_projects') }</div>
                }
            }
            statsDom = <div>
                <div className="statsTitle">{ tr('selected_customer_stats') }</div>
                { statsDom }
            </div>
        }


        const customerSelectDoms = <Collapse in={ this.state.has_customer && !this.state.new_customer }><div>
            <ApSelect
                label={ tr('customer') }
                value={ this.state.customer }
                optionRenderer={ ( item ) => {
                    return <div>
                        <strong>{ item.name }</strong><br />
                        <small>{ item.taxnumber }</small>
                    </div>
                }}
                onChange={ ( c ) => this.customerChange( c ? c : null ) }
                objKeyId="id"
                objKeyValue="name"
                clearable
                apiUrl="project/find/customer"
                loading={ this.state.loading }
                disabled={ this.state.loading }

                validationState={ this.validator.getState('customer') }
                tooltip={ this.validator.getText('customer') }
            />

            <ApSelect
                label={ tr('billing_address') }
                value={ this.state.customer_address }
                optionRenderer={ (item) => {
                    return (
                        <div>
                            <strong>{ item.name }</strong><br />
                            { item.street }<br />
                            { item.postal_code + " " + item.city }<br />
                            { item.country }
                        </div>
                    );
                }}

                objKeyValue={ ( addr ) => { return `${ addr.name } - ${ addr.street }, ${ addr.city }` } }
                onChange={ ( v ) => this.selectChange( 'customer_address', v ) }
                objKeyId="id"
                clearable
                apiUrl="project/find/customer/address"
                apiData={{
                    crm_company: this.state.customer ? this.state.customer.id : false,
                }}
                loading={ this.state.loading }
                disabled={ this.state.loading || !this.state.customer }
            />
            <ApSelect
                label={ tr('contact_person') }
                value={ this.state.customer_contact }
                optionRenderer={ (item) => <div>{ item.last_name } { item.first_name }</div> }
                onChange={ ( v ) => this.selectChange( 'customer_contact', v ) }
                objKeyValue={ ( contact ) => { return `${ contact.last_name } ${ contact.first_name }` } }
                objKeyId="id"
                clearable
                apiUrl="project/find/customer/contact"
                apiData={{
                    crm_company: this.state.customer ? this.state.customer.id : false,
                }}
                loading={ this.state.loading }
                disabled={ this.state.loading || !this.state.customer }
            />
			<ApInput
				type="text"
				id="customer_order_number"
				name="customer_order_number"
				label={ tr('customer_order_number') }
				value={ this.state.customer_order_number ? this.state.customer_order_number : '' }
				onChange={ ( e ) => this.setState({ customer_order_number: e.target.value }) }
				loading={ this.state.loading }
				disabled={ this.state.loading }
            />
            { statsDom }
        </div></Collapse>

        const newCustomerInfo = <Collapse in={ this.state.has_customer && this.state.new_customer }><div>
		    <ApInput
		        type="text"
		        id="newCustomer_name"
		        name="name"
		        label={ tr('name') }
		        value={ this.state.newCustomer_name ? this.state.newCustomer_name : '' }
		        onChange={ ( e ) => this.setState({ newCustomer_name: e.target.value }) }
		        validationState={ this.validator.getState('name') }
		        tooltip={ this.validator.getText('name') }
		        loading={ this.state.loading }
		        disabled={ this.state.loading }
		    />
		    <ApInput
	        	type="text"
	        	id="newCustomer_taxnumber"
	        	name="taxnumber"
	        	label={ tr('business_id') }
	        	value={ this.state.newCustomer_taxnumber ? this.state.newCustomer_taxnumber : '' }
	        	onChange={ ( e ) => this.setState({ newCustomer_taxnumber: e.target.value }) }
	        	loading={ this.state.loading }
	        	disabled={ this.state.loading }
		    />

		    <ApInput
	        	type="text"
	        	id="newCustomer_phone"
	        	name="phonenum"
	        	label={ tr('phone') }
	        	value={ this.state.newCustomer_phone ? this.state.newCustomer_phone : '' }
	        	onChange={ ( e ) => this.setState({ newCustomer_phone: e.target.value }) }
	        	loading={ this.state.loading }
	        	disabled={ this.state.loading }
		    />
		    <ApInput
        		type="text"
        		id="newCustomer_email"
        		name="email"
        		label={ tr('email_address') }
        		value={ this.state.newCustomer_email ? this.state.newCustomer_email : '' }
        		onChange={ ( e ) => this.setState({ newCustomer_email: e.target.value }) }
        		loading={ this.state.loading }
        		disabled={ this.state.loading }
		    />
		    
		     <ApInput
                    type="text"
                    id="newCustomer_street"
                    name="street"
                    label={ tr('street_address') }
                    value={  this.state.newCustomer_street ? this.state.newCustomer_street : '' }
                    onChange={ (e) =>  this.setState({ newCustomer_street : e.target.value }) }
                />

                <ApInputStack>
                    <ApInput
                        width="140px"
                        type="text"
                        id="newCustomer_postal_code"
                        name="postal_code"
                        label={ tr('postal_code') }
                        value={  this.state.newCustomer_postal_code ? this.state.newCustomer_postal_code : '' }
                        onChange={ (e) =>  this.setState({ newCustomer_postal_code : e.target.value }) }
                    />
                    <ApInput
                        type="text"
                        id="newCustomer_city"
                        name="city"
                        label={ tr('post_office') }
                        value={  this.state.newCustomer_city ? this.state.newCustomer_city : '' }
                        onChange={ (e) =>  this.setState({ newCustomer_city : e.target.value }) }
                    />
                    <ApInput
                        type="text"
                        id="newCustomer_country"
                        name="country"
                        label={ tr('country') }
                        value={  this.state.newCustomer_country ? this.state.newCustomer_country : '' }
                        onChange={ (e) =>  this.setState({ newCustomer_country : e.target.value }) }
                    />
                </ApInputStack>
                    <div><strong>{ tr('contact_person') }</strong></div>
                    <ApInputStack >
        	            <ApInput
        	                type="text"
        	                id="newCustomer_first_name"
        	                name="first_name"
        	                label={ tr('first_name') }
        	                value={ this.state.newCustomer_first_name ? this.state.newCustomer_first_name : ''  }
        	                onChange={ ( e ) => this.setState({ newCustomer_first_name: e.target.value }) }
        		        	loading={ this.state.loading }
        		        	disabled={ this.state.loading }
        	            />
        	            <ApInput
        	                type="text"
        	                id="newCustomer_last_name"
        	                name="last_name"
        	                label={ tr('last_name') }
        	                value={ this.state.newCustomer_last_name ? this.state.newCustomer_last_name : '' }
        	                onChange={  ( e ) => this.setState({ newCustomer_last_name: e.target.value })}
        		        	loading={ this.state.loading }
        		        	disabled={ this.state.loading }
        	            />
                    </ApInputStack>
	    	<ApInput
				type="text"
				id="customer_order_number"
				name="customer_order_number"
				label={ tr('customer_order_number') }
				value={ this.state.customer_order_number ? this.state.customer_order_number : '' }
				onChange={ ( e ) => this.setState({ customer_order_number: e.target.value }) }
				loading={ this.state.loading }
				disabled={ this.state.loading }
	        /> 
	    </div></Collapse>
    
        return <div className="stepCustomer">
            { hasCustomerDom }
            { newCustomerDom }
            { newCustomerInfo }
            { customerSelectDoms }
        </div>
    }
    renderStorageSelect() {
        return <ApSelect
	        label={ `${tr('select_storage_to_use')} *` }
	        value={ this.state.selected_storage }
	        optionRenderer="storage_location_address"
	        onChange={ ( e ) => { this.setState({ selected_storage: e }); }}
	        objKeyId="id"
	        objKeyValue={ ( l ) => {
	            let name = l.name ? `: ${ l.name }` : '';
	            return `${ l.code }${ name }`
	        }}
	        apiUrl="storage/locations/find"
	        apiData={{ 
	            canReceive: true,
                projectStorage: false,
                mainLevel: true
	        }}
        	validationState={ this.validator.getState('storage') }
	    />
    }

    renderStorageSelection()
    {
    	let projectStorageSelectEnabled=false;

        if (this.state.storage_options=='storage_disabled' || this.state.main_project_disabled) {
            return null;
        }
        else if (this.state.storage_options=='storage_enabled') {
            projectStorageSelectEnabled=false;
        }
        else if (this.state.storage_options=='storage_required') {
            projectStorageSelectEnabled=false;
        }
        else if (this.state.storage_options=='storage_required_project') {
            projectStorageSelectEnabled=true;
        }
        else if (this.state.storage_options=='storage_required_normal') {
            projectStorageSelectEnabled=true;
        }
        else if (this.state.storage_options=='storage_required_auto_create') {
            projectStorageSelectEnabled=true;
        }

        const createStorageDom  = this.renderSwitchbox(
                'use_project_storage', 
                tr('use_project_storage'),
                tr('project_storage_is_created_for_project'),
                null,
                projectStorageSelectEnabled
            );

        		return <div>
	        		{createStorageDom}
	                <Collapse in={ !this.state.use_project_storage }>
		                <div className="indentleft">
		                	{this.renderStorageSelect()}
		                </div>
		            </Collapse>
        		</div>
    }
    renderLabel() {
        return <span>{tr('cost_center')}</span>
    };

    renderLabelValue(code) {
        return code.full_name;
        if (!this.currentlyInputingTail()) return code.code + ": " + code.name;
        const separator = this.props.codeSeparator ? this.props.codeSeparator : '';
        const tail = this.props.tail ? this.props.tail : ''
        return `${code.code}${separator}${tail}`;
    };
    currentlyInputingTail() {
        // When code format is free, we only input the tail
        if (this.props.codeLimit && this.props.codeLimit.is_free)
            return true;
        if (!this.props.code) return false;
        return (this.props.code.ancestors.length + 1 === this.props.codeLevelCount);
    }

    renderStepDetails()
    {

        const settings = this.state.types.find((type) => {
            return Number(type.id) === Number(this.state.type)})

        let DisabledBillingState = Boolean

        DisabledBillingState = settings.project_billing_options == "hourly_billing_required" || settings.project_billing_options == "cannot_be_hourly_billable" ?  true : false;
        
        const continuousLocked = (settings.project_continuous_options == 'continuous_required' || settings.project_continuous_options == 'cannot_be_continuous');


        const billingHourlyDom = this.renderSwitchbox(
            'billing_hourly', 
            tr('project_is_hourly_billed'),
            tr('project_billing_hourly_basis'),
            null,
            DisabledBillingState
        );

        const continuousDom = this.renderSwitchbox(
            'is_continuous', 
            tr('project_is_continuous'),
            tr('project_no_end_date'),
            false,
            continuousLocked
        );

        let userAddProgressDom = ""
        if (this.state.work_progress_in_timetracking_enabled ) {   
            userAddProgressDom = this.renderSwitchbox(
                'user_add_progress', 
                tr('work_progress_estimate'),
                tr('work_progress_estimate_info'),
                null,
                false,
            );
        }
        let  workEndDate="";
        if( this.state.project_ext_work_enabled && !this.state.is_continuous)
        {
            workEndDate =<ApInputStack>
                <ApInput
                    type="datetimeV2"
                    name="end_work"
                    id="end_work"
                    value={ this.state.end_work }
                    label="Projektin tuntikirjaukset päättyy"
                    onChange={ ( e ) => { this.setState({ end_work: e }); }}
                    clearable
                    loading={ this.state.loading }
                    disabled={ this.state.loading || this.state.locked }
                />
            </ApInputStack>
        }

        return <div className="stepDetails">
            {billingHourlyDom}
            {continuousDom}

            {this.renderStorageSelection()}

            <ApInputStack>
                <ApInput
                    type="datetimeV2"
                    id="newProject_begin_date"
                    name="begin_date"
                    label={ tr('start_date') }
                    value={ this.state.begin_date }
                    onChange={ ( e ) => { this.setState({ begin_date: e }); }}
                    clearable
                    loading={ this.state.loading }
                    disabled={ this.state.loading }

                    validationState={ this.validator.getState('begin_date') }
                    tooltip={ this.validator.getText('begin_date') }

                />
                <ApInput
                    type="datetimeV2"
                    id="newProject_end_date"
                    name="end_date"
                    label={ tr('end_date') }
                    value={ this.state.is_continuous ? '' : this.state.end_date }
                    onChange={(e) => {
                        if (moment(this.state.end_work) <= moment(e)) {
                            this.setState({ end_date: e, end_work: e });
                        }
                        else {
                            this.setState({ end_date: e });
                        }
                    }}
                    clearable  
                    loading={ this.state.loading }
                    disabled={ this.state.loading || this.state.is_continuous }

                    validationState={ this.validator.getState('end_date') }
                    tooltip={ this.validator.getText('end_date') }

                />
            </ApInputStack>
            { workEndDate }

            <ApSelect
                label={ tr('project_manager') }
                value={ this.state.manager }
                optionRenderer="user"
                onChange={ ( v ) => this.selectChange( 'manager', v ) }

                objKeyId="id"
                objKeyValue="name"
                clearable
                apiUrl="project/find/manager"
                loading={ this.state.loading }
                disabled={ this.state.loading }
                filterNonActives={true}
            />

            <ApSelect
                label={ tr('approver_of_hours') }
                value={ keyExists( this.state, "hour_approvers", true, [] ) }
                optionRenderer="user"
                onChange={ ( v ) => this.selectChange( 'hour_approvers', v ) }
                objKeyId="id"
                clearable
                apiUrl="project/find/workhourapprover"
                loading={ this.state.loading }
                disabled={ this.state.loading }
                filterNonActives={true}
                multiselect
                valueRenderer="user"
            />

            {this.state.billing_hourly 
            ? <ApSelect
                label={ tr('billers') }
                value={ keyExists( this.state, "billers", true, [] ) }
                valueRenderer="user"
                optionRenderer="user"
                onChange={ ( users ) => { this.selectChange( 'billers', users ) } }
                objKeyId="id"
                objKeyValue="name"
                apiUrl="project/find/biller"
                loading={ this.state.loading }
                disabled={ this.state.loading }
                multiselect
                filterNonActives={true}
                validationState={this.validator.getState('billers')}
            />
            : null}
            
            {this.state.cost_center_is_enabled&&<ApNestedSelect
                label={this.renderLabel}
                valueRenderer={this.renderLabelValue}
                value={this.state.cost_center}
                parentRenderer="value"
                parentTooltipRenderer="name"
                optionRenderer={(item) => {
                    return <div className="codeOption">
                        <span className="name">{item.nameTree}</span>
                        <span className="code">{item.code}</span>
                    </div>
                }}
                onChange={(value) => { this.setState({ cost_center:value }) }}
                apiUrl="costCenter/search"
                loading={this.props.loading}
                disabled={this.props.loading || this.props.skipped}
                validationState={this.validator.getState('cost_center')}
                tooltip={this.validator.getText('cost_center')}
                
            //tailInput={tailInput}
            />}
            
            { userAddProgressDom }
            
        </div>
    }

    renderStepOffer()
    {

        const settings = this.state.types.find((type) => {
            return Number(type.id) === Number(this.state.type)})

            let DisabledOfferState = Boolean

            DisabledOfferState = settings.project_offer_options == "offer_required" || settings.project_offer_options == "cannot_make_offer" ?  true : false;

        const doOfferDom = this.renderSwitchbox(
            'do_offer', 
            tr('offer_calculation_for_project'),
            tr('project_offer_calculation_status'),
            null,
            DisabledOfferState,
        );

        let whyDisabledInfo = null;
        if( this.state.billing_hourly )
        {
            whyDisabledInfo = <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('project_offer_calculation_note') }
            </div>
        }

        const offerInputsDom = <Collapse in={ this.state.do_offer }><div>

            <ApInput
                type="datetimeV2"
                id="newProject_offer_received"
                name="offer_received"
                label={ tr('offer_inquiry_received_at') }
                value={ this.state.offer_received || '' }
                onChange={ ( e ) => { this.setState({ offer_received: e }); }}
                clearable  
                loading={ this.state.loading }
                disabled={ this.state.loading }

                validationState={ this.validator.getState('offer_received') }
                tooltip={ this.validator.getText('offer_received') }
            />

            <ApInput
                type="datetimeV2"
                id="newProject_offer_daedline"
                name="offer_deadline"
                label={ tr('offer_deadline') }
                value={ this.state.offer_deadline || '' }
                onChange={ ( e ) => { this.setState({ offer_deadline: e }); }}
                clearable  
                loading={ this.state.loading }
                disabled={ this.state.loading }

                validationState={ this.validator.getState('offer_deadline') }
                tooltip={ this.validator.getText('offer_deadline') }
            />

            <ApInputStack key="probability"  gap="0">
                <ApAddon noRightBorder width="320px">
                    { tr('probability_of_acceptance') }
                </ApAddon>
                <ApInput
                    type="select"
                    options={ this.getProbabilityOptions() }
                    id="propability_select"
                    name="propability_select"
                    value={ this.state.offer_success_probability }
                    onChange={ ( e ) => { this.setState({ offer_success_probability: e.target.value }); }}
                    disabled={ this.state.loading }
                />
            </ApInputStack>


        </div></Collapse>

        return <div className="stepOffer">
            { doOfferDom }
            { whyDisabledInfo }
            { offerInputsDom }
        </div>
    }

    render()
    {
        return <div id="newProject">
            <ApModalWizard
                header={ tr('add_new_project') }
                onOpen={ this.onOpen }
                show={ this.props.show }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.save }
            />
        </div>
    }

}

export default NewProject;