import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';

import ApButton from 'common/ApButton/ApButton.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

import SvgIcon           from 'common/SvgIcon/SvgIcon.js';
import auth from 'services/Authed/Authed.js';
import ApTooltip   from 'common/ApTooltip/ApTooltip.js';


export default class viestisuora extends React.Component
{

	constructor(props)
	{

		super(props);
		
		this.state = {
				token: ''
		};
		this.selectedTab = 1;

		autoBind(this);
		
	}
	
	UNSAFE_componentWillMount()
	{
		this.getToken();
		
	}

	getToken()
	{
		api({
			method: 'get',
			url: 'viestisuora/create/token',
			data: {	}
		}).then((response) => {
			let link = "https://www.amiproservice.fi/apps/easypro.php/"+JSON.parse(JSON.stringify(response));
			window.open(link, "_blank");
			//window.location.href = link;
		}).catch((error) => {
			console.log(error);
		});

	}
	
	render() {
		return <div>{this.state.token}</div>
	}
}