import ApReactTable from 'common/ApReactTable/ApReactTable';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import { poStatusBoxColor } from 'modules/Storage/common/StorageHelpers';
import moment from 'moment';
import React, { Component } from 'react'
import autobind from "react-autobind";
import { formatCurrency, tr } from 'services/Helpers/Helpers';
import api from 'services/Api/Api';

class ProjectPurchaseOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            poData: [],
        };

        autobind(this);
    }

    componentDidUpdate(prevProps) {
        
    }

    getExcel(keys) {
        let selectedPurchaseOrders = [];
        if (!keys.length) {
            selectedPurchaseOrders = [...this.props.purchaseOrders];
        } else {
            selectedPurchaseOrders = this.props.purchaseOrders.filter((po, index) => keys.includes(index));
        }

        const excelData = selectedPurchaseOrders.map(po => {
            return {
                order_number: po.number,
                date: po.date,
                due_date: po.due_date,
                supplier: po.supplier ? po.supplier.name : null,
                status: tr(po.statusText),
                po_total_price: Number(po.price),
                rows: po.components.length,
            }
        });

        if (excelData.length === 0) {
            return;
        }

        this.setState({ loading: true });
        let title = `${this.props.tree[0].name}-${tr('purchase_orders')}`;
        if (title.length > 31) {
            title = title.substring(0, 31);
        }
        api({
            url: 'excel/generate',
            method: 'POST',
            data: {
                rows: excelData,
                title: title,
                sheetTitle: title,
            },
            responseType: 'blob',
            fileTitle: `${this.props.tree[0].name}-${tr('purchase_orders')}.xlsx`
        }).then(response => {
            this.setState({
                loading: false,
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                loading: false,
            });
        })
    }

    render() {
        return (
            <div className='padding'>
                <ApReactTable
                    loading={this.props.loading}
                    rememberId='PdProject_PurchaseOrders'
                    data={this.props.purchaseOrders}
                    multiselect={[
                        {
                            icon: 'file-excel',
                            label: tr('create_excel'),
                            action: this.getExcel,
                            unselectAfter: false,
                            closeAfter: true,
                            alwaysOn: true,
                        }
                    ]}
                    columns={[
                        {
                            Header: tr('purchase_order'),
                            customizable: true,
                            columns: [
                                {
                                    id: 'number',
                                    Header: tr('order_number'),
                                    accessor: acc => Number(acc.number),
                                    filterable: true,
                                    customFilter: {
                                        type: "text",
                                        placeholder: tr('order_number'),
                                    },
                                    Cell : props => props.original.number
                                },
                                {
                                    id: 'poDate',
                                    Header: tr('date'),
                                    accessor: 'date',
                                    filterable: false,
                                    customizable: true,
                                    Cell: props => props.original.date ? moment(props.original.date).format('L') : null
                                },
                                {
                                    id: 'poDueDate',
                                    Header: tr('due_date'),
                                    accessor: 'due_date',
                                    filterable: false,
                                    customizable: true,
                                    Cell: props => props.original.due_date ? moment(props.original.due_date).format('L') : null
                                },
                                {
                                    id: 'supplier.name',
                                    Header: tr('supplier'),
                                    accessor: 'supplier.name',
                                    filterable: true,
                                    customFilter: {
                                        type: "text",
                                        placeholder: tr('supplier'),
                                    },
                                },
                                {
                                    id: 'status_id',
                                    Header: tr('status'),
                                    accessor: 'status_id',
                                    width: 125,
                                    filterable: true,
                                    customFilter: {
                                        type: 'select',
                                        placeholder: tr('status'),
                                        options: this.props.purchaseOrderStatusOptions
                                    },
                                    Cell: (props) => {
                                        return <div className={`apStatusBox ${poStatusBoxColor(props.original.statusName)}`}> {tr(props.original.statusText)}</div>
                                    },
                                    customizable: true,
                                    showInitially: true
                                },
                                {
                                    id: 'poPrice',
                                    Header: tr('po_total_price'),
                                    accessor: acc => Number(acc.price), // muunnos numeroksi, jotta sorttaus menee oikein
                                    width: 150,
                                    filterable: false,
                                    customizable: true,
                                    showInitially: true,
                                    Cell: (props) => formatCurrency(props.original.price)
                                },
                                {
                                    id: 'rows',
                                    Header: tr('rows'),
                                    accessor: acc => Number(acc.components.length),
                                    width: 150,
                                    filterable: false,
                                    customizable: true,
                                    showInitially: true,
                                },
                            ]
                        }
                    ]}
                    filterable
                    showFiltersInitially={true}
                    rowActions={(row) =>
                        <div className="apSimpleButton" onClick={() => this.props.history.push(`/storage/purchase/order/${row.number}`)}>
                            <SvgIcon icon="arrow-right" type="solid" />
                        </div>
                    }
                />
            </div>
        )
    }
}

export default ProjectPurchaseOrders;