/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import { groupify } from 'services/Helpers/Helpers.js';

class LoginAsUser extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            selectedCompany: null,
            selectedUser: null,
            companies: [],
            users: [],
        };
        autoBind(this); 
    }

    componentDidMount()
    {
        this.fetchData();
    }

    fetchData()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'management/userlist',
        }).then(( response ) => {

            //console.log('response', response );

            let companies = [ { value: false, label: " - Kaikki yritykset - " }];
            if( response.companies )
                response.companies.forEach( company => {
                    companies.push( {
                        value: company.id,
                        label: company.name,
                    });
                });

            companies.sort( (a,b) => {
                if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
                if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
                return 0;
            });

            let users = [];
            if( response.users )
                response.users.forEach( user => {
                    users.push({
                        value: user.id,
                        label: user.username + ( user.is_admin ? " (Pääkäyttäjä)" : "" ),
                        company_id: user.company_id,
                    });
                });

            users.sort( (a,b) => {
                if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
                if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
                return 0;
            });
            
            this.setState({ 
                loading: false,
                companies: companies,
                users: users,
            });
            
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    getCompanyName( id )
    {
        for( let i = 0; i < this.state.companies.length; i++ )
            if( this.state.companies[ i ].value == id )
                return this.state.companies[ i ].label;

        return "Nimetön yritys";
    }

    getUsers()
    {
        let users = [{ value: false, label: " - Valitse käyttäjä - " }];

        if( this.state.selectedCompany > 0 )
        {
            this.state.users.forEach( user => {
                if( user.company_id == this.state.selectedCompany )
                    users.push( user );
            });
        }
        else 
        {
            let groups = groupify( this.state.users, "company_id" );

            for( const groupId in groups )
            {
                users.push({
                    label: this.getCompanyName( groupId ),
                    options: groups[ groupId ]
                });
            }
        }
        return users;
    }

    loginAsUser()
    {
        if( !this.state.selectedUser )
            return false;

        this.setState({ loading: true });
         api({
            method: 'post',
            url: 'management/login',
            data: { id: this.state.selectedUser }
        }).then(( response ) => {

            auth.saveToken( response.token );
            auth.reloadSettings( () => {
                window.location.replace('/');
            });
            
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    render() 
    {
        return (
            <div id="pageLoginAsUser">
                
                <p>Voit käyttää tätä työkalua, jos haluat tarkastaa miltä järjestelmä näyttää toisen käyttäjän tilillä. </p>
                <p><strong>HUOM! Käytä toimintoa varoen ja vältä toimintoja joista jää jälkiä käyttäjästä.</strong></p>

                <ApInputStack>
                    <ApInput
                        width="30%"
                        type="select"
                        id="company"
                        name="company"
                        value={ this.state.selectedCompany }
                        onChange={ (e) => { this.setState({ selectedCompany: e.target.value, selectedUser: null }) } }
                        options={ this.state.companies }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }
                    />
                    <ApInput
                        width="30%"
                        type="select"
                        id="user"
                        name="user"
                        value={ this.state.selectedUser }
                        onChange={ (e) => { this.setState({ selectedUser: e.target.value }) } }
                        options={ this.getUsers() }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }
                    />
                </ApInputStack>
                <ApInputStack>
                    <ApAddon custom width="250px">
                        <ApButton color="blue" onClick={ this.loginAsUser } disabled={ !this.state.selectedUser || this.state.loading }>
                            <SvgIcon icon="user-secret" type="solid" />
                            Kirjaudu käyttäjänä
                        </ApButton>
                    </ApAddon>
                </ApInputStack>

            </div>
        );
    }
}

export default LoginAsUser;
