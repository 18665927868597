/* eslint-disable eqeqeq */
import { JWTParser } from 'services/Helpers/Helpers.js';
import api           from 'services/Api/Api.js';
import config        from 'env.js';
import echo          from 'services/Echo/Echo.js';

const Authed = {

    // when token lifetime goes below this number (min) it will be refreshed
    refreshBefore: config.api.refreshtime ? config.api.refreshtime : 15,

    login(username, password)
    {
        return api({
            method: 'post',
            url: '/login',
            data: { username: username, password: password },
            notoken: true,
        }).then((response) => {

            this.saveToken( response.token );

        }).catch((error) => {
            throw error;
        });
    },


    resetPassword( username )
    {
        return api({
            method: 'post',
            url: '/reset/send',
            data: {
                username: username,
                fronturl: `${ window.location.origin }/public/resetpw`,
            },
            notoken: true,
        }).then((response) => {
            return response;
        }).catch((error) => {
            throw error;
        });
    },

    saveToken(token)
    {
        let data =  JWTParser(token);
        window.sessionStorage.setItem('token', token);
        window.sessionStorage.setItem('token-data', JSON.stringify(data));
    },

    removeToken()
    {
        window.sessionStorage.removeItem('token');
        window.sessionStorage.removeItem('token-data');
    },

    tokenCheckAndRefresh()
    {
        let refreshing = window.sessionStorage.getItem('refreshing', true);
        if(refreshing)
            return false;
        const tokenData = this.getTokenData();
        if(!tokenData)
            return false;

        const remaining =  tokenData.exp*1000 - Date.now();
        if(remaining <  this.refreshBefore*60*1000)
        {
            this.tokenRefresh();
        }
    },

    tokenRefresh()
    {
        window.sessionStorage.setItem('refreshing', true);
        return api({
            method: 'get',
            url: '/refreshtoken',
            refreshing: true,
        }).then((response) => {
            window.sessionStorage.removeItem("refreshing");
            this.saveToken(response.token);
        }).catch((error) => {
            console.error(error);
            window.sessionStorage.removeItem("refreshing");
        });
    },

    logout( callApi = true )
    {
        // callApi is to prevent endless loop on not authenticated
        if( callApi )
        {
            api({
                method: 'post',
                url: '/logout',
                norefreshing: true,
            });
        }

        echo.logout();

        this.removeToken();

        window.emitter.emit('goTo', {
            pathname: '/login',
        });
    },

    isLoggedIn()
    {
        if(window.sessionStorage.getItem('token'))
            return true;
        return false;
    },

    getToken()
    {
        return window.sessionStorage.getItem("token")
    },

    getTokenData()
    {
        return JSON.parse(window.sessionStorage.getItem("token-data"));
    },

    // Check if authed user is in a manager company
    isGlobalAdmin()
    {
        const tokenData = this.getTokenData()
        if( tokenData && tokenData.user )
            return tokenData.user.global_admin;
        return false;
    },

    // Check if authed user is in an admin
    isAdmin()
    {
        const tokenData = this.getTokenData()
        if( tokenData && tokenData.user )
            return tokenData.user.admin;
        return false;
    },
    

    // return boolean value regarding user's permission to view given module
    // parameter is given as slug name (consult database)
    hasModule( slug )
    {
        const tokenData = this.getTokenData()
        if( tokenData && tokenData.user && tokenData.user.modules )
            return tokenData.user.modules.includes( slug );
        return false;
    },



    // This is called from src/modules/Login/Login.js after login
    // and sholud be called manually when settings are modifed 
    reloadSettings( onSuccess = null, onError = null )
    {
        api({
            method: 'get',
            url: '/settings',
        }).then(( response ) => {
            window.sessionStorage.setItem('settings', JSON.stringify( response ) );

            if( window.sidebarRef )
                window.sidebarRef.updateBadges();

            if( typeof( onSuccess ) == "function" )
                onSuccess();

        }).catch((error) => {
            if( typeof( onError ) == "function" )
                onError();
        });
    },

    getSettings()
    {   
        const settings = window.sessionStorage.getItem('settings');
        return settings ? JSON.parse( settings ) : false ;
    },

    getUser()
    {
        const settings = this.getSettings();
        if( settings )
            return settings.user;
    },

    getID()
    {
        const user = this.getUser();
        if( user )
            return user.id;
    },

    getName()
    {
        let user = this.getUser()
        if( user )
            return user.name;
    },

    getEmail()
    {
        let user = this.getUser()
        if( user )
            return user.email;
    },

    getLastLogin()
    {
        let user = this.getUser()
        if( user )
            return user.last_login;
    },

    getCompany()
    {
        const settings = this.getSettings();
        if( settings )
            return settings.company;
    },

    getCompanyID()
    {
        const company = this.getCompany();
        if( company )
            return company.id;        
    },

    getCompanySuffix()
    {
        let company = this.getCompany()
        if( company )
            return company.suffix;
    },

    getCurrency()
    {
        let company = this.getCompany()
        if( company )
            return company.currency;
    },

    getCurrencySign()
    {   
        let company = this.getCompany()
        if( company )
            return company.currency_sign;
    },

    getStorageCodeIsFree()
    {
        let company = this.getCompany()
        if( company && company.storage )
            return company.storage.storage_code_is_free;
    },

    /*
    // Check if user is marked as storage manger
    isStorageManager(storageId)
    {
        const allIds = this.getUser().managed_storage_ids;
        return allIds.includes(storageId);
    },
    */

}

export default Authed;
