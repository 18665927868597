import React from 'react';
import autoBind from 'react-autobind';
import { Collapse } from 'react-bootstrap';

import SvgIcon     from 'common/SvgIcon/SvgIcon.js';
import { mapTree } from 'services/Helpers/Helpers.js';

import './ApTree.css';

class ApTree extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
        };
        autoBind(this);
    }

    handleOpen( selected )
    {
        let tree = JSON.parse(JSON.stringify( this.props.tree ));
        tree = mapTree( tree, ( item, parent ) => {
            if( selected.id === item.id )
            {
                // item.__selected = true;

                if( item.children )
                    item.__open = !item.__open;
            }

            return item;
        });
        this.props.onTreeChange( tree );
    }

    toggleBranch( id )
    {
        let tree = JSON.parse(JSON.stringify( this.props.tree ));
        let toggleRest = ( children, value ) => {
            return children.map( item => {
                item.__open = value;
                if( item.children )
                    item.children = toggleRest( item.children, item.__open );
                return item;
            });
        }
        //tree = tree.map( ( item ) => {
        tree = mapTree( tree, ( item, parent ) => {
            if( item.id === id )
            {
                if( item.children )
                {
                    item.__open = !item.__open;
                    item.children = toggleRest( item.children, item.__open );
                }
            }
            return item;
        });
        this.props.onTreeChange( tree );
    }

    renderNode( node )
    {
        let classes = [ 'node' ];

        if( node.__open )
            classes.push( 'open' );

        if( node.invalid )
            classes.push( 'invalid' );

        if( node.__selected )
            classes.push( 'selected' );

        // toggleButton is used on detecting clicking on chevron

        let childIndicator = null;
        if( !this.props.noToggle && Array.isArray( node.children ) && ( node.children.length > 0) )
        {
            childIndicator = <div className="childIndicator toggleButton" 
                onClick={ () => this.handleOpen( node ) }
                onContextMenu={ ( e ) => { e.preventDefault(); this.toggleBranch( node.id ); } }
            >
                <SvgIcon className="toggleButton" icon="chevron-right" type="solid" />
            </div>
            classes.push( 'childIndicatorPadding' );
        }


        let nodeContent = null;
        if( this.props.nodeRenderer )
            nodeContent = this.props.nodeRenderer( node, () => { this.handleOpen( node ) } );
        else
        {
            let value = null;
            if( node.value ) value = <div className="value">{ node.value }</div>;
            nodeContent = <div>
                { node.name }
                { value }
            </div>
        }

        const nodeClick = ( e ) => {
            this.handleOpen( node );
            if( !e.target.classList.contains( 'toggleButton' ) )
                if( typeof this.props.onNodeClick === 'function' )
                    this.props.onNodeClick( node );
        }

        return <div className={ classes.join(" ") }  onClick={ nodeClick }>
            { childIndicator }
            { nodeContent }
        </div>
    }


    renderLeaf( node, level = 0 )
    {
        let open = ( this.props.noToggle ) ? true : node.__open;

        return <li key={ node.id }>
            { this.renderNode( node ) }

            { node.children && node.children.length > 0 &&
                <Collapse in={ open }>
                    <ul className={ `subnode level-${ level }` }>
                        { node.children.map( ( child ) => this.renderLeaf( child, level + 1 ) ) }
                    </ul>
                </Collapse>
            }
        </li>
    };

    render()
    {
        const tree = this.props.tree;

        return <div>
            <div className="apTree">
                <ul className="root">
                    { tree.map( ( l ) => 
                    {
                        //Seuraavat ehdot varastopaikkojen esitystä varten eli tavalliset/projektivarastot JAr, 10.6.2020
                        if(this.props.locType)
                        {              
                            if(l.type == this.props.locType)
                                return this.renderLeaf( l, 1 ) 
                        }
                        else
                        {
                            return this.renderLeaf( l, 1 )
                        }
                    } ) }
                </ul>
            </div>
        </div>
    }
}

export default ApTree;
