import React from 'react';
import autoBind from 'react-autobind';
import './UserMenu.css';
import auth from 'services/Authed/Authed.js';
import ApUserImage from 'common/ApUserImage/ApUserImage.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { tr } from 'services/Helpers/Helpers.js';
import { connect } from 'react-redux';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';

class UserMenu extends React.Component {

    constructor( props ) 
    {
        super( props );
        this.state = {
            changePasswordShowing: false,
            showLogoutConfirm: false,
        }
        autoBind(this); 
    }

    logout()
    {
        if (this.props.apTimer?.status === 'playing') {
            this.setState({ showLogoutConfirm: true });
        } else {
            auth.logout();
        }
    }

    openUserPage()
    {
        window.emitter.emit('goTo', {pathname: '/user'})
        this.props.toggleModal("user")
    }

    openChangePassword()
    {
        window.emitter.emit('goTo', {pathname: '/user/changepassword'})
        this.props.toggleModal("user")
    }

    render()
    {
        const user = auth.getUser();
        
        return (
            <div className="block user">
                <div className="button" onClick={() => { this.props.toggleModal("user"); } }>
                    <span className="text">{ user.name }</span>      
                    <SvgIcon type="solid" icon="user-circle" />
                </div>
                <div className={"topModal" + (this.props.open ? " open" : "" ) }>
                    <div className="padding-small">
                    
                        <div className="profile">

                            <ApUserImage user={ user } block withShadow size="huge" style={{ borderColor: "#fff", marginBottom: "10px" }} />
                            <div className="name">{ user.name }</div>
                            { user.title && <div className="title">{ user.title }</div> }
                        
                        </div>

                        <button 
                            className="apButton small full-width"
                            onClick={ this.openChangePassword }
                        >
                            <SvgIcon icon="key" type="solid" />
                            { tr('change_password') }
                        </button>

                        <button 
                            className="apButton small full-width no-margin"
                            onClick={ this.openUserPage }
                        >
                            <SvgIcon icon="user" type="solid" />
                            { tr('user_page') }
                        </button>

                        <hr />

                        <button className="apButton small full-width red no-margin" onClick={ this.logout }>
                            <SvgIcon icon="lock" type="solid" />
                            { tr('logout') }
                        </button>
                    </div>

                </div>
                <ApConfirm
                    show={this.state.showLogoutConfirm}
                    onClose={() => this.setState({ showLogoutConfirm: false })}
                    onConfirm={() => auth.logout()}
                    header={tr('logout')}
                    body={
                        <div>
                            <p>
                                <strong>{tr('logout_timer_on_warning')}</strong>
                            </p>

                        </div>
                    }
                />
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        apTimer: state.apTimer,
    }
}

export default connect(mapStateToProps)(UserMenu);

