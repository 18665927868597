import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import { keyExists, tr } from 'services/Helpers/Helpers.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import ApSelect from 'common/ApSelect/ApSelect';

export default class EditNewsModal extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			item: null,
			header: null,
			content: null,
			limited: false,
			role: null
		};

		this.validator = new ApValidate( this, {
			"header":           { filter: 'required', state: 'warning', text: tr('mandatory')}
		  });

		autoBind(this);

	}

	componentDidUpdate(prevProps)
	{

		if (this.props.itemId && prevProps.itemId !== this.props.itemId) this.getNewsitem(this.props.itemId);

	}

	getNewsitem(id)
	{

		api({
			method: 'get',
			url: 'company/settings/getNewsItem',
			params: { id: id }
		}).then((response) => {
			this.setState({ 
				item: response,
				header: response.header,
				content: response.content,
				limited: response.limited,
				role: response.role,
			});
		}).catch((error) => {
			console.log(error);
		});

	}

	saveItem()
	{

		api({
			method: 'post',
			url: 'company/settings/saveNewsItem',
			data: {
				id: this.props.itemId,
				header: this.state.header,
				content: this.state.content,
				limited: this.state.limited,
				role:this.state.role
			}
		}).then((response) => {
			this.props.onClose();
			this.clear();
		}).catch((error) => {
			console.log(error);
		});

	}

	deleteItem()
	{

		api({
			method: 'post',
			url: 'company/settings/deleteNewsItem',
			data: {
				id: this.props.itemId,
			}
		}).then((response) => {
			this.props.onClose();
		}).catch((error) => {
			console.log(error);
		});

	}

	clickSaveButton()
	{
		this.saveItem();
	}

	clickCancelButton()
	{
		this.props.onClose();
		this.clear();
	}

	clear()
	{
		this.setState({
			item: null,
			header: null,
			content: null,
			limited: false,
			role: null,
			unsavedChanges: false,
		})
	}

	handleChange(e)
	{	
		this.setState({
			[e.target.name] :e.target.value,
			unsavedChanges: true,
		});
	}

	renderFoobar()
    {
		return (
			<ApSelect
				label={ tr('personnel_group') }
				value={ keyExists( this.state, "role.name", true, null ) }
				valueRenderer={(item) => <div>{item.name}</div>}
				optionRenderer={(item) => <div>{item.name}</div>}
				onChange={ ( role ) => this.setState({ role }) }
				objKeyId="id"
				clearable
				apiUrl="usermanagement/personnelGroups"
			/>
		);
    }

	renderForm()
	{

		const form = <div>
						<ApInput
							name="header"
							id="header"
							label={ tr('header') }
							value={this.state.header}
							type="text"
							onChange={this.handleChange}
							validationState={ this.validator.getState('header') }
						/>
						<ApInput
							name="content"
							id="content"
							label={ tr('content') }
							value={this.state.content}
							type="textarea"
							rows={ 5 }
							onChange={this.handleChange}
						/>
						<strong>{ tr('send_to_selected_group') }:</strong>
						{this.renderFoobar()}
					</div>;

		return form;

	}

	render()
	{

		const form = this.renderForm();

		return(
			<div id='edit-news-modal'>
				<ApModal
					show={this.props.show}
					backdrop="static"
					className="narrow"
					header={ 
						<div className="padding-small">
							<h4>{this.props.itemId === null ? tr('new') : tr('edit') }</h4>
						</div>
					}
					body={
						<div className="padding" style={{ marginTop: 30 }}>
						{form}
						</div>
					}
					footer={
						<div className="padding" style={{display: 'flex', flexDirection: 'row'}}>
			
						<ApButton className="cancel" onClick={() => {this.clickCancelButton(null)}}>
							<SvgIcon icon="times" type="solid" />
							{ tr('cancel') }
						</ApButton>

						{ this.props.itemId && 
							<ApButton className="remove" color="red" style={{marginLeft: 20}} onClick={ this.deleteItem }>
							<SvgIcon icon="trash" type="solid" />
							{ tr('delete') }
							</ApButton>
						}
			
						<ApButton							
							className={ "save" + ( this.state.unsavedChanges ? " highlight" : "" ) }
							color="blue"
							onClick={ this.clickSaveButton }
							loading={ this.state.loading }
							disabled={ Boolean(this.validator.getState('header')) }
							style={{marginLeft: 'auto'}}
						>
							<SvgIcon icon="save" type="solid" />
							{ tr('save') }
						</ApButton>
						
						</div>
					}
				/>
			</div>

		);

	}

}