/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApOptionInput from 'common/ApOptionInput/ApOptionInput.js';

import { keyExists, sqlToDateInput, formatMoney, tr, errorPopper } from  'services/Helpers/Helpers.js';
import { entryIcon } from  'modules/Timetracking/TimetrackingHelpers.js';

import './Payroll.css';
import { connect } from 'react-redux';

class TimetrackingPayroll extends React.Component {

	constructor( props ) 
	{
		super(props);
        this.state = {
            loading: false,
            error: false,
            entries: false,
            selected: [],

            date_start: '',
            date_end: '',
            users: [],
            collectiveAgreements: [],
            personnelGroups: [],
            salaryTypes: [],
            all_entries: false,

            createBatchShow: false,
            batch_name: '',
            batch_format: 'csv', // Maybe later some more options here
        };
        autoBind(this);
	}

    componentDidMount() {
        //this.getEntries();
        this.setState({batch_format: keyExists(this.props, 'apTimetrackingSettings.company.payroll_payment_method', true, 'csv')})
    }

    componentDidUpdate(prevProps) {
        const prevPayment = keyExists(prevProps, 'apTimetrackingSettings.company.payroll_payment_method', true, false);
        const currentPayment = keyExists(this.props, 'apTimetrackingSettings.company.payroll_payment_method', true, false);
        if (prevPayment != currentPayment) {
            this.setState({batch_format: keyExists(this.props, 'apTimetrackingSettings.company.payroll_payment_method', true, 'csv')})
        }
    }

    getEntries()
    {
        this.setState({ loading: true, error: false });
        api({
            method: 'post',
            url: 'timetracking/payroll/entries',
            data: {
                users: this.state.users.map( u => u.id ),
                collectiveAgreements: this.state.collectiveAgreements.map(item => item.id),
                personnelGroups: this.state.personnelGroups.map(item => item.id),
                salaryTypes: this.state.salaryTypes.map(item => item.id),
                date_start: this.state.date_start,
                date_end: this.state.date_end,
                all_entries: this.state.all_entries,
            }
        }).then(( response ) => {

           // console.log( 'ENTRIES', response );

            let parsedResponses = this.parseAllowances(response)

            let selected = [];
            parsedResponses.forEach( ( entry, index ) => {
                if( !entry.readOnly )
                    selected.push( index );
            });
          
            this.setState({
                loading: false,
                entries: parsedResponses,
                selected: selected,
            });

        }).catch( ( error ) => {
            this.setState({ 
                loading: false, 
                error: keyExists( error, "response.data.message", true, "Odottamaton virhe" ) || "VIRHE"
            });
        });
    }

    parseAllowances(response)
    {
        const parsedResponses = []
        const usedAllowances = {}

        response.forEach(entry => {
            if (entry.type === 'allowance' && (entry.user_id in usedAllowances)) {
                if (!usedAllowances[entry.user_id].includes(entry.date)) {
                    parsedResponses.push(entry)
                    usedAllowances[entry.user_id].push(entry.date)
                }
                
            }

            if (entry.type === 'allowance' && !(entry.user_id in usedAllowances)) {
                parsedResponses.push(entry)
                usedAllowances[entry.user_id] = [
                    entry.date
                ]
            }

            if (entry.type !== 'allowance') {
                parsedResponses.push(entry)
            }
        })

        return parsedResponses
    }

    toggleSelected( value )
    {
        let selected = this.state.selected.slice();
        const index = selected.indexOf( value );

        if( index == -1 )
            selected.push( value );
        else 
            selected.splice( index, 1 );

        this.setState({ selected });
    }

    getSelectedUsersCount()
    {
        let userIds = [];
        this.state.selected.forEach( s => {
            const entry = this.state.entries[ s ];
            if( userIds.indexOf( entry.user_id ) == -1 )
                userIds.push( entry.user_id );
        });
        return userIds.length;
    }

    handleBatchInputChange( e )
    {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ [ name ]: value });
    }

    createBatch()
    {
        let batch_name = this.state.batch_name;

        if( batch_name.length == 0 )
            batch_name = `${tr('salary_item')} ${moment().format('DD.MM.YYYY')}`;
        
        this.setState({ 
            createBatchShow: true,
            batch_name: batch_name
        });
    }

    closeModal()
    {
        this.setState({ createBatchShow: false });
    }

    createPayrollBatch()
    {

        const entries = this.state.entries.slice();
        let entryIds = [];
        let extraIds = [];
        this.state.selected.forEach( index => {
            if( entries[ index ].source === "timetracking" )
                entryIds.push( entries[ index ].id );
            else if ( entries[ index ].source === "salaryextra" )
                extraIds.push( entries[ index ].id );
            else 
                console.log('UPS.. unknown source: ' + entries[ index ].source );
        });

        this.setState({ loading: true, error: false });
        api({
            method: 'post',
            url: 'timetracking/payroll/create',
            data: {
                entryIds: entryIds,
                extraIds: extraIds,
                name: this.state.batch_name,
                format: this.state.batch_format,
                date_start: this.state.date_start,
                date_end: this.state.date_end,
            }
        }).then(( response ) => {

            this.getEntries();
            
        }).catch( ( error ) => {
            this.setState({ 
                loading: false, 
                error: keyExists( error, "response.data.message", true, "Odottamaton virhe" ) || "VIRHE"
            });
            errorPopper(error, tr('get_error'));
        });

        this.closeModal();
    }

    renderFooter()
    {
        let info = "";

        if( this.state.loading )
            info = `${tr('loading')}...`;

        else if( !this.state.entries )
            info = tr('select_time_period_persons');

        else if ( this.state.entries.length > 0 )
            info = `${tr('selected')} ${this.state.selected.length} ${tr('payable_entries')} (${this.getSelectedUsersCount()} ${tr('persons_count')})`;
        
        else 
            info = tr('no_payable_entries_found')

        if( this.state.error )
            info = this.state.error;

        return (
            <div className="customFooter">

                <div className={ "info" + ( this.state.error ? " error" : "" ) }>
                    <SvgIcon icon="info-circle" type="solid" />
                    { info }
                </div>

                <ApButton  
                    color="blue" 
                    onClick={ this.createBatch } 
                    disabled={ this.state.selected.length < 1 || this.state.loading } 
                    loading={ this.state.loading }
                >
                    <SvgIcon icon="file-code" type="solid" />
                    { tr('create_payroll_batch_selected') }...
                </ApButton>

            </div>
        );
    }

    render() 
    {
        let diffDateText = '';
        if (this.state.date_end  && this.state.date_start) {
            diffDateText = '(' + (moment(this.state.date_end).diff(moment(this.state.date_start), "days") + 1) +' '+tr('days') + ')';
        }
         return (
            <div id="pageTimetrackingPayroll">
                <ApFormPage 
                    unsaved={ false }
                    //className="timetrackingPayrollForm"
                    customFooter={ this.renderFooter }
                >

                    <div className="apBoxHeader">

                        <div className="apBoxCorner">

                            <ApButton color="white" onClick="/timetracking/payroll/batches" size="small">
                                <SvgIcon icon="history" type="solid" />
                                { tr('previous_payroll_batches') }
                            </ApButton>

                        </div>

                    </div>

                    <div className="filters">

                        <div className="payroll-content">
                        <ApInputStack gap="0">
                            <ApInput
                                width="180"
                                type="datetimeV2"
                                id="date_start"
                                name="date_start"
                                value={ this.state.date_start || '' }
                                onChange={ (e) => this.setState({ date_start: e }) }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                clearable
                                weekNumbers
                            />
                            <ApAddon width="20" noLeftBorder noRightBorder> - </ApAddon>
                            <ApInput
                                width="180"
                                type="datetimeV2"
                                id="date_end"
                                name="date_end"
                                value={ this.state.date_end || '' }
                                onChange={ (e) => this.setState({ date_end: e }) }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                clearable
                                weekNumbers
                            />
                        </ApInputStack>
                        <ApInputStack gap="0">
                            <ApAddon custom gapLeft="10">
                                <ApOptionInput
                                    value={[ this.state.date_start, this.state.date_end ]}
                                    onChange={ ( values ) => { this.setState({ date_start: values[0], date_end: values[1] } ) } }
                                    options={[ "month-3", "month-2", "month-1", "month" ]}
                                />

                                <ApOptionInput
                                    value={[ this.state.date_start, this.state.date_end ]}
                                    onChange={ ( values ) => { this.setState({ date_start: values[0], date_end: values[1] } ) } }
                                    options={[ "week-3", "week-2", "week-1", "week" ]}
                                />
                            </ApAddon>
                        </ApInputStack>
                        </div>

                        <div className="payroll-content">
                            <div>
                            <ApSelect
                                label={ tr('person') }
                                value={ keyExists( this.state, "users" ) ? this.state.users : [] }
                                valueRenderer="user"
                                optionRenderer="user"
                                onChange={ ( users ) => this.setState({ users: users }) }
                                objKeyId="id"
                                clearable
                                apiUrl="timetracking/payroll/users"
                                apiData={{
                                    date_start: this.state.date_start,
                                    date_end: this.state.date_end,
                                }}
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                multiselect
                            />
                            </div>
                            <div className={ "apFormGroup" + ( this.state.all_entries ? " success" : "" ) }>
                                <div className="apSwitchBlock small">
                                    <label htmlFor="all_entries" className="info">
                                        { tr('show_non_payable') }
                                    </label>
                                    <ApSwitch
                                        name="all_entries"
                                        id="all_entries"
                                        on={this.state.all_entries} 
                                        onChange={() => this.setState({ all_entries: !this.state.all_entries }) }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='payroll-content'>
                            <div>
                                <ApSelect
                                    label={ tr('personnel_group') }
                                    value={ keyExists( this.state, "personnelGroups" ) ? this.state.personnelGroups : [] }
                                    valueRenderer={(item) => <div>{item.name}</div>}
                                    optionRenderer={(item) => <div>{item.name}</div>}
                                    onChange={ ( personnelGroups ) => this.setState({ personnelGroups: personnelGroups }) }
                                    objKeyId="id"
                                    clearable
                                    apiUrl="usermanagement/personnelGroups"
                                    apiData= {{
                                        all_entries: this.state.all_entries
                                    }}
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading }
                                    multiselect
                                />
                            </div>

                            <div>
                                <ApSelect
                                    label={ tr('pay_period') }
                                    value={ keyExists( this.state, "salaryTypes" ) ? this.state.salaryTypes : [] }
                                    valueRenderer={(item) => <div>{`${tr(`salary_${item.name}`)}`}</div>}
                                    optionRenderer={(item) => <div>{tr(`salary_${item.name}`)}</div>}
                                    onChange={ ( salaryTypes ) => this.setState({ salaryTypes: salaryTypes }) }
                                    objKeyId="id"
                                    clearable
                                    apiUrl="timetracking/payroll/salarytypes"
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading }
                                    multiselect
                                />
                            </div>
                        </div>

                        <div className='payroll-content'>
                            <div className='collective-agreement'>
                                <ApSelect
                                    label={ tr('collective_agreement') }
                                    value={ keyExists( this.state, "collectiveAgreements" ) ? this.state.collectiveAgreements : [] }
                                    valueRenderer={(item) => <div>{item.name}</div>}
                                    optionRenderer={(item) => <div>{item.name}</div>}
                                    onChange={ ( collectiveAgreements ) => this.setState({ collectiveAgreements: collectiveAgreements }) }
                                    objKeyId="id"
                                    clearable
                                    apiUrl="usermanagement/collectiveAgreements"
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading }
                                    multiselect
                                />
                            </div>
                        </div>
              
                        <ApButton onClick={ () => this.getEntries() }>
                            <SvgIcon icon="filter" type="solid" />
                            { tr('search_entries') }
                        </ApButton>
                        
                    </div>
                    
                    <div className={ "entries" + ( this.state.loading ? " loading" : "" ) }>
                        
                        { this.state.entries.length == 0 &&
                            <div className="apWarningMsg">
                                <SvgIcon block icon="ban" type="solid" size="huge" />
                                <h4>{ tr('no_payable_entries') }</h4>
                                <p>{ tr('no_payable_entries_note') }</p>
                            </div>
                        }

                        { this.state.entries && this.state.entries.map( ( entry, index ) => {
                            
                            const selected = this.state.selected.indexOf( index ) != -1;
                            let classes = [ "entry" ];
                            let icon = "question";

                            if( selected ) 
                                classes.push( "selected" );

                            if( entry.readOnly )
                                classes.push( "readOnly" );

                            if( entry.payroll_batch_id )
                                classes.push( "batched" );

                            let onEntryClick = null;
                            if( !entry.readOnly )
                                onEntryClick = () => this.toggleSelected( index );

                            if( entry.source === "salaryextra" )
                                icon = "trophy";
                            else if ( entry.source === "timetracking" )
                                icon = entryIcon( entry.type );

                            return (
                                <div key={ index } className={ classes.join(" ") } onClick={ onEntryClick }>

                                    <div className="selection">
                                        { entry.payroll_batch_id &&
                                            <ApTooltip text={ tr('awaiting_batch_confirmation') } position="topleft">
                                                <SvgIcon icon="file" type="solid" />
                                            </ApTooltip>
                                        }
                                        { !entry.readOnly &&
                                            <input
                                                type="checkbox"
                                                checked={ selected }
                                                readOnly
                                            />
                                        }
                                        { entry.readOnly && !entry.payroll_batch_id &&
                                            <ApTooltip text={ tr('not_yet_payable') } position="topleft">
                                                <SvgIcon icon="times" type="solid" />
                                            </ApTooltip>
                                        }
                                    </div>
                                    
                                    <strong>{ sqlToDateInput( entry.date ) }</strong>
                                    <span> - { entry.user.name } - </span>
                                    <SvgIcon className="small-inline" icon={ icon } type="solid" />
                                    { entry.source === "salaryextra" &&
                                        <span>
                                            { entry.name }: 
                                            { formatMoney( entry.unit_price * entry.amount ) } { auth.getCurrencySign() }
                                        </span>
                                    }
                                    { entry.source === "timetracking" && entry.type === "hour" &&
                                        <span>
                                            { tr('normal_hours') }: { entry.hours_regular } h, 
                                            { tr('overtime_hours') }: { entry.hours_overtime } h, 
                                            { tr('fee_hours') }: { entry.hours_notation } h
                                        </span>
                                    }
                                    { entry.source === "timetracking" && entry.type === "travel" &&
                                        <span>
                                            { tr('trip_length') }: { entry.total_distance } km, 
                                            { tr('travel_time') }: { entry.hours_travel } h
                                        </span>
                                    }
                                    { entry.source === "timetracking" && entry.type === "allowance" &&
                                        <span>
                                            { entry.description || tr('daily_allowance') }: 
                                            { formatMoney( entry.money_allowance ) } { auth.getCurrencySign() } 
                                        </span>
                                    }
                                    { entry.source === "timetracking" && entry.type === "absence" &&
                                        <span>
                                            { entry.description || tr('absence') }: 
                                            { entry.hours_absence } h
                                        </span>
                                    }
                                
                                    { entry.payroll_batch_id && 
                                        <ApButton className="batchInfoButton" size="tiny" color="white" onClick={ "/timetracking/payroll/batches/" + entry.payroll_batch_id }>
                                            <SvgIcon icon="file-code" type="solid" />
                                            { tr('installment_info') }
                                        </ApButton>
                                    }

                                </div>
                            );
                        })}
                    </div>

                </ApFormPage>

                <ApModal
                    className="createModal narrow"
                    show={ this.state.createBatchShow }
                    handleClose={ this.closeModal }
                    closeFromBg
                    header={ 
                        <div className="padding">
                            <h3>{ tr('create_payroll_batch') }</h3>
                        </div>
                    }
                    body={ 
                        <div className="padding">
                            
                            <ApInput 
                                type="text" 
                                id="batch_name" 
                                name="batch_name" 
                                label={ tr('name') }
                                value={ this.state.batch_name || '' }
                                onChange={ this.handleBatchInputChange }
                                validationState={ this.state.batch_name.length == 0 ? "error" : "" }
                            />

                            <ApInputStack gap="0">
                                <ApAddon noRightBorder width="150">
                                    { tr('saving_format') }
                                </ApAddon>
                                <ApInput 
                                    type="select" 
                                    id="batch_format" 
                                    name="batch_format" 
                                    value={ this.state.batch_format || '' }
                                    options={[
                                        { value: 'fivaldi', label: "Fivaldi (XML)" },
                                        { value: 'nova', label: 'Lähetä Novaan' },
                                        { value: 'netvisor', label: tr('send_to_netvisor') },
                                        { value: 'eFina', label: "eFina (CSV)" },
                                        { value: 'csv', label: tr('csv_spreadsheet') }
                                    ]}
                                    onChange={ this.handleBatchInputChange }
                                />
                            </ApInputStack>

                            <p>{tr('time_period')} {sqlToDateInput(this.state.date_start)} - {sqlToDateInput(this.state.date_end)} {diffDateText}</p>
                            <p>{ tr('salary_item_is_created') } { this.state.selected.length } { tr('on_timetracking_entry_basis') } ({ this.getSelectedUsersCount() } { tr('persons_count') }). </p> 

                        </div>
                    }
                    footer={
                        <div className="padding">
                            
                            <ApButton className="createButton" color="blue" onClick={ this.createPayrollBatch } disabled={ !this.state.batch_name || this.state.loading } loading={ this.state.loading }>
                                <SvgIcon icon="file-code" type="solid" />
                                { tr('create_installment') }
                            </ApButton>

                            <ApButton className="cancelButton" onClick={ this.closeModal } disabled={ this.state.loading }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>

                        </div>
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
	apTimetrackingSettings: state.apTimetrackingSettings
});

export default connect(mapStateToProps)(TimetrackingPayroll);
