import React from 'react';
import autoBind from 'react-autobind';
import { ApInput
       , ApInputStack
       , ApAddon }     from 'common/ApInput/ApInput.js';
import { tr } from 'services/Helpers/Helpers.js';

import ApSelect        from 'common/ApSelect/ApSelect.js';

import './ReceiverDataSelect.css';

class ReceiverDataSelect extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            receiverId: -1,
        };

        this.findTimer = null;
        autoBind(this);
    }

    handleChange(e)
    {
        this.props.onChange({[e.target.name]: e.target.value});
    }

    handleReceiverTypeChange( e )
    {
        this.props.onChange( this.nullReceiver() );
        this.props.onChange({[e.target.name]: e.target.value});
    }

    nullReceiver()
    {
        return {
            receiverTitle:   null,
            receiverId:      null,
            receiverName:    null,
            receiverAddress: null,
            receiverZipcode: null,
            receiverCity:    null,
            receiverCountry: null,
            receiverContactName: null,
        };
    }

    setReceiverStorage( selected )
    {
        if( selected )
        {
            this.props.onChange({
                receiverTitle:   selected.name,
                receiverId:      selected.id,
                receiverName:    selected.delivery_name,
                receiverAddress: selected.delivery_address,
                receiverZipcode: selected.delivery_zipcode,
                receiverCity:    selected.delivery_city,
                receiverCountry: selected.delivery_country,
                receiverContactName: null,
            });
        }
        else
        {
            this.props.onChange( this.nullReceiver() );
        }
    }

    setReceiverProject( selected )
    {
        if( selected )
        {
            this.props.onChange({
                receiverTitle:   selected.name,
                receiverId:      selected.id,
            });
        }
        else
        {
            this.props.onChange( this.nullReceiver() );
        }
    }

    setReceiverCompany( selected )
    {
        if( selected )
        {
            this.props.onChange({
                receiverTitle:   selected.name,
                receiverId:      selected.id,
                receiverName:    selected.name,
                receiverAddress: selected.address_supply_street,
                receiverZipcode: selected.address_supply_postal_code,
                receiverCity:    selected.address_supply_city,
                receiverCountry: selected.address_supply_country,
                receiverContactName: null,
            });
        }
        else
        {
            this.props.onChange( this.nullReceiver() );
        }
    }

    renderReceiverType()
    {
        return <ApInputStack gap="0">
            <ApAddon
                noRightBorder width="200px"
            >
                { tr('recipient') } *
            </ApAddon>
            <ApInput
                type="select"
                options={ [
                    { value: null,      label: '' },
                    { value: 'location', label: tr('own_storage') },
                    { value: 'project_storage', label: tr('project') },
                    { value: 'company', label: tr('company'), disabled: true },
                    // { value: 'other',   label: 'Muu' },
                ] }
                id="receiverType"
                name="receiverType"
                value={ this.props.receiverType }
                onChange={ this.handleReceiverTypeChange }
                validationState={ this.props.validator.getState('receiverType') }
                disabled={ this.props.receiverLocked }
            />
        </ApInputStack>
    }

    renderReceiverSelect()
    {
        if( this.props.receiverType === 'location' )
        {
            return <ApSelect
                label={ `${tr('recipient_storage')} *` }
                value={ this.props.receiverTitle ? this.props.receiverTitle : '' }
                optionRenderer="storage_location_address"
                onChange={ this.setReceiverStorage }
                objKeyId="id"
                clearable
                apiUrl="storage/locations/find"
                apiData={{ 
                    canReceive: true,
                }}
                validationState={ this.props.validator.getState('receiverId') }
                disabled={ this.props.receiverLocked }
            />

        }
        else if( this.props.receiverType === 'project_storage' )
        {
            return <ApSelect
                label={ `${tr('recipient_project')} *` }
                loading={ this.state.loading }
                value={ this.props.receiverTitle ? this.props.receiverTitle : '' }
                optionRenderer="storage_location"
                onChange={ this.setReceiverProject }
                objKeyId="id"
                clearable
                apiUrl="storage/locations/find"
                apiData={{ 
                    canReceive: true,
                    projectStorage: true
                }}
                validationState={ this.props.validator.getState('receiverId') }
                disabled={ this.props.receiverLocked }
            />
        }
        else if( this.props.receiverType === 'company' )
        {
            return <ApSelect
                label={ `${tr('recipient_company')} *` }
                value={ this.props.receiverTitle ? this.props.receiverTitle : '' }
                optionRenderer="crm_company"
                onChange={ this.setReceiverCompany }
                objKeyId="id"
                clearable
                apiUrl="crm/companies/find"
                validationState={ this.props.validator.getState('receiverId') }
                disabled={ this.props.receiverLocked }
            />
        }
        return null;
    }

    renderFields()
    {
        return <div>

            <ApInput
                type="text"
                id="receiverName"
                name="receiverName"
                label={ `${tr('recipient')} *` }
                autoComplete="off"
                onChange={ this.handleChange }
                value={ this.props.receiverName }
                validationState={ this.props.validator.getState('receiverName') }
            />

            <div className="receiver-indent">
                <ApInput
                    type="text"
                    id="receiverAddress"
                    name="receiverAddress"
                    label={ `${tr('address')} *` }
                    autoComplete="off"
                    onChange={ this.handleChange }
                    value={ this.props.receiverAddress }
                    validationState={ this.props.validator.getState('receiverAddress') }
                />

                <div className="col-container">
                    <div className="col-left">
                        <ApInput
                            type="text"
                            id="receiverZipcode"
                            name="receiverZipcode"
                            label={ `${tr('postal_code')} *` }
                            autoComplete="off"
                            onChange={ this.handleChange }
                            value={ this.props.receiverZipcode }
                            validationState={ this.props.validator.getState('receiverZipcode') }
                        />
                    </div>
                    <div className="col-right">
                        <ApInput
                            type="text"
                            id="receiverCity"
                            name="receiverCity"
                            label={ `${tr('post_office')} *` }
                            autoComplete="off"
                            onChange={ this.handleChange }
                            value={ this.props.receiverCity }
                            validationState={ this.props.validator.getState('receiverCity') }
                        />
                    </div>
                </div>

                <div className="col-left">
                <ApInput
                    type="text"
                    id="receiverCountry"
                    name="receiverCountry"
                    label={ tr('country') }
                    autoComplete="off"
                    onChange={ this.handleChange }
                    value={ this.props.receiverCountry }
                />
                </div>
                <ApInput
                    type="text"
                    id="receiverContactName"
                    name="receiverContactName"
                    label={ tr('contact_person') }
                    autoComplete="off"
                    onChange={ this.handleChange }
                    value={ this.props.receiverContactName }
                />
            </div>
        </div>
    }

    render()
    {
        let receiverType    = this.renderReceiverType();
        let receiverSelect  = this.renderReceiverSelect();

        let receiverFields = null;
        if( this.props.receiverId || this.props.receiverType === 'other' )
           receiverFields = this.renderFields();

        return <div id="orderReceiverDataSelect">

            { receiverType }
            { receiverSelect }
            { receiverFields }
        </div>
    }
}

export default ReceiverDataSelect;
