/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
// import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';

import { keyExists, tr, roundToDecimals } from  'services/Helpers/Helpers.js';

import { Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';


class HourEntry extends React.Component {

	constructor(props) 
    {
		super(props);
		this.state = {
			storageBalance: null,
			currentProjectId: null,
		}
        autoBind(this);
	}

	componentDidMount() {
		this.setState({currentProjectId: this.props.data.project_id})

		if (this.props.data.assignment_id) {
			if (this.props.data.project.assignment.component_installation!==undefined && this.props.data.project.assignment.component_installation.length>0) {

				let installations =[];
				this.props.data.project.assignment.component_installation.map(inst => {
					if (inst.entry_id==null && inst.billing_entry_id==null) {
						const options = this.getInstallationOptions();

						const firstAvailable = options.find( o => o.value==inst.project_component_id );

						const installation = {
							id: 					firstAvailable.value,
							value: 					inst.quantity,
							component_id:			firstAvailable.component_id,
							unit: 					firstAvailable.unit,
							trackingunits: 			firstAvailable.trackingunits || [],
						}
						installations.push(installation);
					}
				})
				this.props.onChange( this.eventify( installations ), "installations" );

			}
			
		}
		if (!this.props.data.project.parent_id) {
			this.getStorageBalance(this.props.data.project_id)
		} else {
			this.getStorageBalance(this.props.data.project.parent_id)
		}
	}
	
	componentDidUpdate() {
		if (this.state.currentProjectId !== this.props.data.project_id) {
			this.setState({currentProjectId: this.props.data.project_id})
			//Find project storage
			if (!this.props.data.project.parent_id) {
				this.getStorageBalance(this.props.data.project_id)
			} else {
				this.getStorageBalance(this.props.data.project.parent_id)
			}
		}
	}

    // Imitate input event by wrapping value to "e.target.value" object
    eventify( value )
    {
    	return { target: { value: value } };
    }

	getWorkOptions()
	{
		let options = [];
		const works = keyExists( this.props.data, "project.works", true, [] );
		works.forEach( w => {
			options.push({
				value: w.id,
				label: w.name
			});
		});
		return options;
	}

	getHourTypeOptions( currentValue )
	{
		const hourTypes = this.props.hourTypes;
		const rows = keyExists( this.props, "data.detail.rows", true, [] );

		let options = [];
		hourTypes.forEach( t => {
			options.push({
				value: t.id,
				label: t.name,
				disabled: t.id != currentValue && rows.findIndex( r => r.hour_type_id == t.id ) != -1
			});
		})
		return options;
	}

	async getStorageBalance(id)
    {
		if (!id) return

		let pId = id;
		let notFound = false

		while(true) {
			const project = await api({
				method: 'get',
				url: `projects/id/${pId}`
			})

			if (project === 0) {
				notFound = true
				break
			}

			if (!project.parent_id) {
				pId = project.id
				break
			}

			pId = project.parent_id
		}

		if (!notFound) {
			const response = await api({
				method: 'get',
				url: `project/management/${ pId }/storageBalance`,
			})
			
			this.setState({
				storageBalance : response,
			})
		}   
    }

	getInstallationOptions( currentValue = null )
	{
		const selected = keyExists( this.props.data, "installations", true, [] ).map( i => parseInt( i.id ) );
		let options = [];

		keyExists( this.props.data, "project.components", true, [] ).forEach( c => {
			if( c.in_timetracking )
			{
				let disabled = c.id != currentValue && selected.indexOf( parseInt( c.id ) ) != -1;
				options.push({
					value: c.id,
					label: c.name,
					component_id: c.component_id,
					disabled: disabled,
					unit: keyExists( c, "component.unit", true, null ),
					trackingunits: keyExists(c, "component.trackingunits", true, []),
					alloc_count: keyExists(c, "alloc_count", true, null),
				});
			}
		});
		return options;
	}

	getChargeableOptions( currentValue = null )
	{	
		const selected = keyExists( this.props.data, "chargeables", true, [] ).map( i => parseInt( i.id ) );
		let options = [];

		keyExists( this.props.data, "project.expenses", true, [] ).forEach( e => {
			if( e.in_timetracking )
			{
				let disabled = e.id != currentValue && selected.indexOf( parseInt( e.id ) ) != -1;
				options.push({
					value: e.id,
					label: e.name,
					unit: keyExists( e, "component.unit", true, null ),
					disabled: disabled,
				});
			}
		});
		return options;
	}

	validateDescription()
    {
    	const desc = keyExists( this.props, "data.description", true, '' );

    	let required = false;
    	keyExists( this.props, "data.detail.rows", true, [] ).forEach( row => {
    		if( this.props.hourTypes.find( t => t.id == row.hour_type_id ).is_entry_description_required )
    			required = true;
    	});

    	if( required && !desc )
    		return false;

        return true;
    }

	addRow( type )
	{
		let rows = keyExists( this.props.data, type, true, [] ).slice();

		if( type === "detail.rows" )
		{
			const options = this.getHourTypeOptions();
			const firstAvailable = options.find( o => !o.disabled );
			rows.push({
				hour_type_id: firstAvailable.value,
				hours: '',
			});
		}

		else if ( type === "installations" )
		{
			const options = this.getInstallationOptions();
			const firstAvailable = options.find( o => !o.disabled );

			const installation = {
				id: 					firstAvailable.value,
				value: 					0,
				component_id:			firstAvailable.component_id,
				unit: 					firstAvailable.unit,
				trackingunits: 			firstAvailable.trackingunits || [],
				alloc_count: 			firstAvailable.alloc_count,
			}

			if (this.state.storageBalance) {
				const storageBalance = this.state.storageBalance.find(c => c.component_id === firstAvailable.component_id) 
				
				if (storageBalance) {
					installation.component_balance = storageBalance.balance
					installation.component_balance_free = storageBalance.balance_free
				} else {
					//for rare edge cases
					installation.component_balance = null
					installation.component_balance_free = null
				}	
			}

			if( firstAvailable )
				rows.push(installation);
		}

		else if ( type === "chargeables" )
		{
			const options = this.getChargeableOptions();
			const firstAvailable = options.find( o => !o.disabled );
			if( firstAvailable )
				rows.push({
					id: 			firstAvailable.value,
					value: 			0,
					unit: 			firstAvailable.unit,
				});
		}

		this.props.onChange( this.eventify( rows ), type );
	}

	editRow( type, index, item, newId )
	{
		let options = [];
		if( type === "installations" )
			options = this.getInstallationOptions();

		else if ( type === "chargeables" )
			options = this.getChargeableOptions();

		const newItem = options.find( o => o.value == newId );

		if( newItem )
		{
			item.id = newId;
			item.unit = newItem.unit;

			if( newItem.trackingunits )
				item.trackingunits = newItem.trackingunits;

			if(newItem.component_id && this.state.storageBalance) {
				const storageBalance = this.state.storageBalance.find(c => c.component_id === newItem.component_id) 
				item.alloc_count = newItem.alloc_count;
				if (storageBalance) {
					item.component_id = newItem.component_id
					item.component_balance = storageBalance.balance
					item.component_balance_free = storageBalance.balance_free
				} else {
					//for rare edge cases
					item.component_id = null
					item.component_balance = null
					item.component_balance_free = null
				}
			}

			this.props.onChange( this.eventify( item ), type + "[" + index + "]" );
		}
	}

	removeRow( type, index, minRows = 0 )
	{
		let rows = keyExists( this.props.data, type, true, [] ).slice();
		if( rows.length > minRows )
		{
			rows.splice( index, 1 );
			this.props.onChange( this.eventify( rows ), type );
		}
	}

	calculateAutomaticTime()
	{
		let time = this.getTotalHours() * 60;
		const installations = keyExists( this.props.data, "installations", true, [] );
		const chargeables = keyExists( this.props.data, "chargeables", true, [] );

		let items = installations.concat( chargeables ).map( i => i.time );
		let count = items.length;

		items.forEach( i => {
			if( !isNaN( parseFloat( i ) ) && parseFloat( i ) > 0 )
			{
				time -= i;
				count--;
			}
		});

		if( count > 0 )
			time = time / count;

		if( time < 0 )
			time = 0;

		return Math.round( time );
	}

	getTotalHours()
	{
		const rows = keyExists( this.props, "data.detail.rows", true, [] );
		return rows.filter( r => !this.props.isNotationFunc( r.hour_type_id ) ).map( r => parseFloat( r.hours ) || 0 ).reduce( ( a, b ) => a + b, 0 );
	}

	roundHours( e, index ) 
    {
        // Round hours to nearest 15 min (.25, .5, .75)
        // const roundedValue = Math.round( e.target.value * 4 ) / 4;
        // if( e.target.value != roundedValue )
        //     this.props.onChange( this.eventify( roundedValue ), "detail.rows[" + index + "].hours" );
		// Round to 2 decimals
		const roundedValue = roundToDecimals(e.target.value, 2);
		if( e.target.value != roundedValue )
			this.props.onChange( this.eventify( roundedValue ), "detail.rows[" + index + "].hours" );
    }

    showCustomerWorkNumber()
    {
    	const project = keyExists( this.props, "data.project", true );
    	if( !project )
    		return false;

    	if( !project.billing_hourly )
    		return false;

    	let customer = project.customer_id;
    	if( !customer && project.ancestors.length > 0 )
    		customer = project.ancestors[0].customer_id;

    	if( !customer )
    		return false;

    	return true;
	}

	validateCustomerWorkNumber() {
		// check if customer work number is required in project
		const project = keyExists( this.props, "data.project", true );
		if( !project )
			return '';

		if (project.customer_work_number_required) {
			const customer_work_number_found = keyExists( this.props, "data.customer_work_number", true);
			if (!customer_work_number_found) {
				return 'error';
			}
			return '';
		}
	}
	
	renderProjectStorageChecker(i)
	{

		//console.log(this.props.projectStorageEnabled)
		if (!this.props.projectStorageEnabled) {
			return null
		}

		const balanceFree = !isNaN(i.component_balance_free) ? 
			i.component_balance - (!isNaN(i.value) ? i.value : 0) : 0

		if (!this.props.project_storage_check_balance_strict) {
			return (
				<div>
					<div>{ tr('project_storage') }</div>
					<div className="apInfo small">
						<SvgIcon icon="info-circle" type="solid" />
						{tr('allocated_quantity')}: {i.alloc_count ? i.alloc_count : "-"} {i.unit} <br />
						{tr('available')}: {balanceFree} {i.unit} / {tr('project_storage_balance')}: {i.component_balance} {i.unit}
					</div>
					<hr />
				</div>
			)
		}

		return (
			<div>
				<div>
					<div>{ tr('project_storage') }</div>
					<div className="apInfo small">
						<SvgIcon icon="info-circle" type="solid" />
						{ tr('available') }: {balanceFree} {i.unit} / { tr('project_storage_balance') }: {i.component_balance} {i.unit}
						{balanceFree < 0 && this.state.storageBalance ? 
							<div className="apInfo small">
								<SvgIcon icon="info-circle" type="solid" fill='red'/>
								{ tr('project_storage_note') }
							</div> : null }
					</div>
				</div>
				<hr />
			</div>
		)

	}

    render()
    {
		const rows = keyExists( this.props, "data.detail.rows", true, [] );
		
		const extranet_approvers = keyExists( this.props, "data.project.extranet_approvers", true, [] );

		const totalHours = this.getTotalHours();

		const installations = keyExists( this.props.data, "installations", true, [] );
		
		const installation_options = this.getInstallationOptions();

		const chargeables = keyExists( this.props.data, "chargeables", true, [] );
		const chargeable_options = this.getChargeableOptions();

		const automaticTime = this.calculateAutomaticTime();

		let hourTotal, hourAllocated, color;

		let showRemainingHours = false;
		if (this.props.showRemainingHours) {
			if (this.props.showRemainingHoursOnlySelected && this.props.data.project.show_remaining_hours) {
				showRemainingHours = true;
			} else if (!this.props.showRemainingHoursOnlySelected && !this.props.data.project.show_remaining_hours) {
				showRemainingHours = true;
			}
		}

		if (showRemainingHours) {
			hourTotal = this.props.data && parseFloat(this.props.data.detail.projectwork.hourTotal) || 0;
			hourAllocated = this.props.data && parseFloat(this.props.data.detail.projectwork.alloc_count) || null;

			this.props.weekData && this.props.weekData.forEach(day => {
				day.entries.forEach(entry => {
					if ( String(entry.id).includes('new') && entry.detail.project_work_id === this.props.data.detail.project_work_id ) {
						entry.detail.rows.forEach(row => {
							hourTotal += parseFloat(row.hours);
						});
					}
				});
			});

			color = hourAllocated === null
				? 'inherit'
				: hourTotal > hourAllocated
					? '#ff4444'
					: '#00C851';
		}

		let entryDescriptionelements = <><ApInputStack>
	            	<ApInput 
	                    type="text" 
	                    id="description" 
	                    name="description" 
						label={tr('description')}
						maxLength={255}
						charCounter
	                    value={ keyExists( this.props, "data.description", true, '' ) }
	                    onChange={ this.props.onChange }
	                    validationState={ this.validateDescription() ? "" : "error" }
	                />
	                <ApAddon width="175" labelFor="hour-long-description-switch">
            			<ApSwitch
                            small
                            inline
                            id="hour-long-description-switch"
                            on={ keyExists( this.props, "data.description_long_checked", true, false ) }
                            onChange={ () => this.props.onChange( this.eventify( !this.props.data.description_long_checked ), "description_long_checked" ) }
                        />
                        <small>{ tr('additional_info') }</small>
                	</ApAddon>
	            </ApInputStack>

                <Collapse in={ keyExists( this.props, "data.description_long_checked", true, false ) }>
                	<div>
		                <ApInput 
		                    type="textarea" 
		                    id="description_long" 
		                    name="description_long" 
		                    label={ tr('additional_info') }
		                    value={ keyExists( this.props, "data.detail.description_long", true, "" ) }
		                    onChange={ ( e ) => this.props.onChange( e, "detail.description_long" ) }
		                    charCounter
		                    rows={5}
		                />
		            </div>
			</Collapse>
		</>;
		
		if (this.props.apOrderSettings?.show_in_timetracking_description_to_billable) {
			const description_to_billable_is_mandatory=this.props.apOrderSettings?.timetracking_description_to_billable_is_mandatory;
			entryDescriptionelements = <>
				<ApInputStack>
					<ApInput
						type="text"
						id="description"
						name="description"
						label={tr('description')}
						maxLength={255}
						charCounter
						value={keyExists(this.props, "data.description", true, '')}
						onChange={this.props.onChange}
						validationState={this.validateDescription() ? "" : "error"}
					/>


				</ApInputStack>
				<ApInputStack>
					<ApAddon width="175" labelFor="description-to-billable-switch" >
						<ApSwitch
							small
							inline
							id="description-to-billable-switch"
							on={keyExists(this.props, "data.description_to_billable_dispatch_ref", true, false) || description_to_billable_is_mandatory}
							disabled={description_to_billable_is_mandatory}
							onChange={() => this.props.onChange(this.eventify(!this.props.data.description_to_billable_dispatch_ref), "description_to_billable_dispatch_ref")}
						/>
						<small>{tr('description_to_billable')}</small>
					</ApAddon>

					<ApAddon width="175" labelFor="hour-long-description-switch">
						<ApSwitch
							small
							inline
							id="hour-long-description-switch"
							on={keyExists(this.props, "data.description_long_checked", true, false)}
							onChange={() => this.props.onChange(this.eventify(!this.props.data.description_long_checked), "description_long_checked")}
						/>
						<small>{tr('additional_info')}</small>
					</ApAddon>
				</ApInputStack>
				<Collapse in={keyExists(this.props, "data.description_long_checked", true, false)}>
				<div>
					<ApInput
						type="textarea"
						id="description_long"
						name="description_long"
						label={tr('additional_info')}
						value={keyExists(this.props, "data.detail.description_long", true, "")}
						onChange={(e) => this.props.onChange(e, "detail.description_long")}
						charCounter
						rows={5}
					/>
				</div>
			</Collapse >
			</>
		}

		hourAllocated = roundToDecimals(hourAllocated, 3);
        return (
            <div>

            	<ApInputStack gap="0">
                	<ApAddon noRightBorder width="150">
                		{ tr('work_title') }
                	</ApAddon>
	                <ApInput 
	                    type="select" 
	                    id="project_work_id" 
	                    name="project_work_id" 
	                    value={ keyExists( this.props, "data.detail.project_work_id", true, '' ) }
	                    options={ this.getWorkOptions() }
	                    onChange={ ( e ) => this.props.onChange( e, "detail.project_work_id" ) }
	                />
                </ApInputStack>

				{entryDescriptionelements}

				{ this.props.data.project.user_add_progress &&
				<ApInputStack gap="0">
                	<ApAddon noRightBorder width="350">
                		{tr("degree_of_readiness_estimate")}
                	</ApAddon>
	                <ApInput 
						width="75"
	                    type="number" 
	                    id="done_estimate" 
	                    name="done_estimate" 
	                    value={ keyExists( this.props, "data.done_estimate", true, '' ) }
	                    onChange={ ( e ) => this.props.onChange( e, "done_estimate" ) }
						validationState={ ( this.props.data.done_estimate >= 0 && this.props.data.done_estimate <= 100 )? "" : "error" }
	                />
                	<ApAddon noLeftBorder width="100">
                		{tr("percent")}
                	</ApAddon>
                </ApInputStack>
				}
				
		        { this.showCustomerWorkNumber() &&
		            <ApInput 
	                    type="text" 
	                    id="customer_work_number" 
	                    name="customer_work_number" 
	                    label={ tr('customer_work_number') }
	                    value={ keyExists( this.props, "data.customer_work_number", true, '' ) }
	                    onChange={ this.props.onChange }
						validationState={this.validateCustomerWorkNumber()}
	                />
	            }

				{ extranet_approvers.length>0 &&
					<ApInputStack gap="0">
						<ApAddon noRightBorder width="250">
							Työn vastaanottaja
						</ApAddon>
						<ApInput 
							type="select" 
							id="client_approver_id" 
							name="client_approver_id" 
							value={ keyExists( this.props, "data.client_approver_id", true, '' ) }
							options={ extranet_approvers }
							onChange={ ( e ) => this.props.onChange( e, "client_approver_id" ) }
						/>
					</ApInputStack>
	            }

                <div className="apFormGroup">
					<h6>{tr('timetracking_entries')} <span className="total">- {tr('in_total')}: { roundToDecimals(totalHours,3) } h</span></h6>
					{showRemainingHours &&
					<p><small> {tr('work_time_entries_total')}: <span style={{color}}>{hourTotal} h </span>{hourAllocated && `/  ${hourAllocated} h ${tr('allocated')}`}</small></p> }
	                { rows.map( ( r, index ) => {
	                	return (
	                		<ApInputStack gap="0" key={ index } timetrackingMobileResponsive={true}>
				                <ApInput 
				                	gapRight="10"
				                    type="select" 
				                    id={ "hour_type_id" + index } 
				                    name={ "hour_type_id" + index }
				                    value={ r.hour_type_id }
				                    options={ this.getHourTypeOptions( r.hour_type_id ) }
				                    onChange={ ( e ) => this.props.onChange( e, "detail.rows[" + index + "].hour_type_id" ) }
				                />
				                <ApInput 
				                	width="100"
				                    type="number" 
				                    id={ "hours" + index } 
				                    name={ "hours" + index } 
				                    value={ r.hours }
				                    align="right"
				                    onChange={ ( e ) => this.props.onChange( e, "detail.rows[" + index + "].hours" ) }
				                    onBlur={ ( e ) =>this.roundHours( e, index ) }
				                    validationState={ parseFloat( r.hours ) && totalHours > 0 && totalHours <= 24  ? "" : "error" }
				                />
				                <ApAddon noLeftBorder width="50">
			                		h
			                	</ApAddon>
			                	<ApAddon custom width="50">
			                		<SvgIcon 
			                			className={ "removeRow" + ( rows.length <= 1 ? " disabled" : "" ) } 
			                			icon="times-circle" 
			                			type="solid" 
			                			onClick={ () => this.removeRow( "detail.rows", index, 1 ) } 
			                		/>
			                	</ApAddon>
			                </ApInputStack>
	                	)
	                })}

	                { rows.length < this.props.hourTypes.length &&
	                	<ApButton color="white" size="small" onClick={ () => this.addRow( "detail.rows" ) }>
	                		<SvgIcon icon="plus" type="solid" />
	                		{ tr('add_entry_row') }
	                	</ApButton>
	                }
                </div>

                { installation_options.length > 0 &&
	                <div className="apFormGroup">
	                	<h6>{ tr('progress') }</h6>
						{ installations.map( ( i, index ) => {
                			return <React.Fragment key={ index }>
	                			<ApInputStack gap="0" timetrackingMobileResponsive={true}>
					                <ApInput 
					                	gapRight="10"
					                    type="select" 
					                    id={ "id" + index } 
					                    name={ "id" + index }
					                    value={ i.id }
					                    options={ this.getInstallationOptions( i.id ) }
					                    onChange={ ( e ) => this.editRow( "installations", index, i, e.target.value ) }
					                />
									<ApInput 
										width={ i.unit ? 100 : 150 }
										type="number" 
										id={ "value" + index } 
										name={ "value" + index } 
										value={ i.value }
										align="right"
										onChange={ ( e ) => this.props.onChange( e, "installations[" + index + "].value" ) }
										tooltip={ tr('amount_of_progress') }
										validationState={i.value !== '0' && !i.value ? 'error': ''}
									/>
									{ i.unit &&
										<ApAddon noLeftBorder width="50">
											{ i.unit }
										</ApAddon>
									}
					                <ApInput 
					                	gapLeft="10"
					                	width={ 75 }
					                    type="number" 
					                    id={ "time" + index } 
					                    name={ "time" + index } 
					                    value={ i.time || '' }
					                    placeholder={ automaticTime }
					                    className={ "installationTime " +  ( i.time ? "fixed" : "auto" ) }
					                    align="right"
					                    onChange={ ( e ) => this.props.onChange( e, "installations[" + index + "].time" ) }
					                	tooltip={ tr('time_spent_in_minutes') }
					                />
					                <ApAddon noLeftBorder width="50">min</ApAddon>

				                	<ApAddon custom width="50">
				                		<SvgIcon 
				                			className="removeRow" 
				                			icon="times-circle" 
				                			type="solid" 
				                			onClick={ () => this.removeRow( "installations", index ) } 
				                		/>
				                	</ApAddon>
				                </ApInputStack>
				                { i.trackingunits && i.trackingunits.length > 0 &&
					                <div className="trackingUnits">
						                { i.trackingunits.map( ( t, unit_index ) => 
			                				<ApInputStack gap="0" key={ unit_index }>
								                <ApAddon noRightBorder>
								                	{ t.name }
								                </ApAddon>
								                <ApInput 
								                	width={ t.unit ? 100 : 150 }
								                    type="number" 
								                    id={ "value" + index + "_" + unit_index } 
								                    name={ "value" + index + "_" + unit_index } 
								                    value={ t.value || "" }
								                    align="right"
								                    onChange={ ( e ) => this.props.onChange( e, "installations[" + index + "].trackingunits[" + unit_index + "].value" ) }
								                />
								                { t.unit &&
									                <ApAddon noLeftBorder width="50">
								                		{ t.unit }
								                	</ApAddon>
								                }
							                </ApInputStack>
				                		)}
			                		</div>
				                }

								{ this.renderProjectStorageChecker(i) }
			                </React.Fragment>}
	                	)}

	                	{ installations.length < installation_options.length &&
		                	<ApButton color="white" size="small" onClick={ () => this.addRow( "installations" ) }>
		                		<SvgIcon icon="plus" type="solid" />
		                		{ tr('add_progress') }
		                	</ApButton>
		                }
	                </div>
	            }

	            { chargeable_options.length > 0 &&
	                <div className="apFormGroup">
	                	<h6>{ tr('billing_costs') }</h6>
	                	{ chargeables.map( ( c, index ) => 
                			<ApInputStack gap="2" key={ index } timetrackingMobileResponsive={true}>
								<ApInput 
				                	gapRight="10"
				                    type="select" 
				                    id={ "id" + index } 
				                    name={ "id" + index }
				                    value={ c.id }
				                    options={ this.getChargeableOptions( c.id ) }
				                    onChange={ ( e ) => this.editRow( "chargeables", index, c, e.target.value ) }
				                />
								<ApInput
				                	width={ c.unit ? 100 : 150 }
				                    type="number" 
				                    id={ "value" + index } 
				                    name={ "value" + index } 
				                    value={ c.value }
				                    align="right"
				                    onChange={ ( e ) => this.props.onChange( e, "chargeables[" + index + "].value" ) }
				                	tooltip="Laskutettava määrä"
				                />
				                { c.unit &&
									<ApAddon noLeftBorder width="50">
				                		{ c.unit }
				                	</ApAddon>
				                }
								<ApInput
				                	gapLeft="10"
				                	width={ 75 }
				                    type="number" 
				                    id={ "time" + index } 
				                    name={ "time" + index } 
				                    value={ c.time || '' }
				                    placeholder={ automaticTime }
				                    className={ "installationTime " +  ( c.time ? "fixed" : "auto" ) }
				                    align="right"
				                    onChange={ ( e ) => this.props.onChange( e, "chargeables[" + index + "].time" ) }
				                	tooltip={ tr('time_spent_in_minutes') }
				                />
								<ApAddon noLeftBorder width="50">
									min
								</ApAddon>

								<ApAddon custom width="50">
			                		<SvgIcon 
			                			className="removeRow" 
			                			icon="times-circle" 
			                			type="solid" 
			                			onClick={ () => this.removeRow( "chargeables", index ) } 
			                		/>
			                	</ApAddon>
			                </ApInputStack>       
	                	)}

	                	{ chargeables.length < chargeable_options.length &&
		                	<ApButton color="white" size="small" onClick={ () => this.addRow( "chargeables" ) }>
		                		<SvgIcon icon="plus" type="solid" />
		                		{ tr('add_billable_cost') }
		                	</ApButton>
		                }
	                </div>
	            }

                {/*  this.props.addFieldsRenderer( "hour", this.props.data ) */}

            </div>
        );
    }

}

export default HourEntry;