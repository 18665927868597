/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import { formatMoney, tr } from 'services/Helpers/Helpers.js';

import ApReactTable from 'common/ApReactTable/ApReactTable.js';

export default class ApprovedExtras extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            extras: [],
            pages: -1
        };
        autoBind(this); 
    }

    getApprovedExtras(state)
    {
        const fetchPage = state.page;
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/salary_extras/approved',
            data: {
                page: fetchPage,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        }).then(( response ) => {

            //console.log('approved extras', response );

            this.setState({
                loading: false,
                extras: response.data,
                pages: response.pages
            });
            
        }).catch( ( error ) => {
            console.error(error);
            this.setState({ loading: false });
        });
        
    }

   
    render() 
    {
        return (
            <div id="TimetrackingSalaryExtraApproved">

                <h3>{ tr('approved_supplements') }</h3>
                <p>{ tr('approved_supplements_info') }</p>

                <ApReactTable 
                    loading={ this.state.loading }
                    data={ this.state.extras || [] } 
                    onFetchData={this.getApprovedExtras}
                    manual
                    pages={this.state.pages}
                    columns={[
                        {
                            Header: tr('one_time_supplements_transferred_payroll'),
                            columns: [
                                { id: 'date', Header: tr('date'), accessor: 'date', Cell: (props) => {
                                    return <span>{ moment( props.value ).format('DD.MM.YYYY') }</span>;
                                }},
                                { id: 'user_id', Header: tr('recipient'), accessor: 'user_id', Cell: (props) => props.original.user.name },
                                { id: 'name', Header: tr('name'), accessor: 'name' },
                                { id: 'sum', Header: tr('sum'), accessor: ( row ) => ( row.amount * row.unit_price ), Cell: (props) => { 
                                    return ( 
                                        <span>
                                            { formatMoney( props.value ) } { auth.getCurrencySign() }<br />
                                            <small>{ tr('amount') }: { props.original.amount }, { tr('price') }: { formatMoney( props.original.unit_price ) } { auth.getCurrencySign() }</small>
                                        </span>
                                    );
                                }},
                                { id: 'batch_created', Header: tr('transferred'), accessor: ( props ) => props.payroll_batch ? props.payroll_batch.created_at : null, Cell: (props) => {
                                    if( !props.original.payroll_batch )
                                        return tr('waiting_for_transfer');

                                    return ( 
                                        <span>
                                            { moment( props.value ).format('DD.MM.YYYY') }<br />
                                            <small>{ props.original.payroll_batch.name }</small>
                                        </span>
                                    );
                                }},
                                { id: 'salary_category', Header: tr('salary_type'), accessor: 'salary_category' },
                                { id: 'creator_user_id', Header: tr('creator'), accessor: 'creator_user_id', Cell: (props) => props.original.creator.name },
                            ]
                        }
                    ]}
                    filterable
                    minRows={ 5 }
                    rowActions={[
                        {
                            icon: "edit",
                            label: tr('edit_supplement'),
                            disabled: true,
                            action: ( id ) => {/*console.log('TODO: edit extra', id )*/},
                        },
                        { divider: true },
                        {
                            icon: "trash",
                            label: tr('delete_supplement'),
                            disabled: true,
                            action: ( id ) => {/*console.log('TODO: remove extra', id )*/},
                        },
                    ]}
                />

            </div>
        );
    }
}

