import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import auth from 'services/Authed/Authed.js';
import api from 'services/Api/Api.js';
import ApValidate from 'services/ApValidate/ApValidate.js';

import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApButton from 'common/ApButton/ApButton.js';

import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import { ApInput,
         ApInputStack,
         ApAddon } from 'common/ApInput/ApInput.js';

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import { errorPopper
    , keyExists
    , onlyNumber
    , ucfirst
    , formatMoney
    , tr } from 'services/Helpers/Helpers.js';


import { getComponentStatusColor, getTypeIcon }  from 'modules/Storage/common/StorageHelpers.js';

import ApSwitch from 'common/ApSwitch/ApSwitch.js';
//import CodeSelect from './CodeSelect/CodeSelect.js'
import './NewPayment.css';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ComponentTooltip  from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';

//import PriceInputs from './PriceInputs/PriceInputs.js'

import ApSelect from 'common/ApSelect/ApSelect.js';
import ApFileUpload from 'common/ApFileUpload/ApFileUpload.js';
import File        from 'common/File/File.js';
import { SortableElement } from 'react-sortable-hoc';

import { profitPercentToSellPrice }  from 'services/Helpers/Helpers.js';

class NewPayment extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {

            //Dataa joka liittyy maksuun.
            sum: -1.0,
            paydays: '14 päivää netto',
            customer_reference: '',
            customer_person: '',
            project_manager: '',
            invalidSum: true,

            selectedTab: 0,

            confirmSaveShow: false,
            confirmSaveText: null,
            confirmSaveAction: null,
            
            currencySign: auth.getCurrencySign(),

            //Ulkoiset muuttujat
            dm: null,
            dt: null,
            pc: null,
            dm_enabled: null,
            dt_enabled: null,
            pc_enabled: null,
            dm_required: null,
            dt_required: null,
            pc_required: null,
        };

        this.newAltNameCounter = 0;

        this.validator = new ApValidate(this, {
            sum: [
                { filter: 'required', state: 'error', text: tr('mandatory')},
            ]
        });
        
        autoBind(this);
    }

    componentDidMount() {
        this.getRelated();
    }

    getRelated()
    {
        return api({
            method: 'get',
            url: 'storage/order/new/related',
            errorPopper: tr('get_error'),
        }).then( ( response ) => {

            this.setState({
                dm: response.dm,
                dt: response.dt,
                pc: response.pc,
                dm_enabled: response.dm_enabled,
                dt_enabled: response.dt_enabled,
                pc_enabled: response.pc_enabled,
                dm_required: response.dm_required,
                dt_required: response.dt_required,
                pc_required: response.pc_required,
            });
        });
    }

    //Varmistaa että syötetty arvo pysyy rajoissa.
    handlePaymentSumEdit(newSum) {        
        //Formatoi summa oikein jottei väliviivat haittaa
        newSum = newSum.replace(/\s/g, '');
        const newSumFloat = parseFloat(newSum);

        if (isNaN(newSumFloat)) {
            this.setState({ 
                invalidSum: true,
                sum: `${this.state.currencySign}`,
            });
        } else {
            this.setState({
                invalidSum: false,
                sum: `${newSum}`,
                //sum: `${formatMoney(newSumFloat)}${this.state.currencySign}`,        
            });
        }
    }


    save( status = null )
    {
        this.setState({ confirmSaveShow: false });

        let data = {};

        data.projId = this.props.data.project_id;
        data.paymentId = this.state.payment_id;
        data.name = this.props.data.project.name;
        data.project_code = this.props.data.project_code;
        
        //Summa pitää formatoida 
        let sumFormatted = String(this.state.sum.replace(/\s/g, '')).replace(',', '.');
        data.sum = parseFloat(sumFormatted).toFixed(2);

        data.paydays = parseInt(this.state.paydays);
        data.customer_reference = this.state.customer_reference;
        data.customer_person = this.state.customer_person;
        data.project_manager = this.state.project_manager;

        //console.log('saving...', data );

        
        this.setState({loading: true });
        return api({
            method: 'post',
            url: 'projects/payments/payments/new',
            data: data,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });

            this.setState({ loading: false }, () => {
                if( typeof this.props.onSave === 'function')
                    this.props.onSave( response );
            });
        }).catch((error) => {
            console.error(error);
            errorPopper( error, tr('save_error') );
            this.setState({ loading: false });
        });
        
    }

    onOpen()
    {
        if( this.fileUploadRef )
            this.fileUploadRef.resetFiles();

        this.setState({ selectedTab: 0 });
    }

    codeChange( code, tail )
    {
        this.setState({
            code: code,
            codeTail: tail,
        })
    }

    renderPaymentInfo()
    {
        let info = 'Maksun yhteissumma.';

        return <div className="padding">
            <h4 className="formHeader">{ tr('pricing') }</h4>
            <ApInput
                label={ tr('sum') }
                type="text"
                id="sum"
                name="sum"
                value={ this.state.sum != -1 ? 
                    (this.state.sum).replace(/\s/g, '')
                    : '' }
                //onChange={ ( e ) =>  this.setState({ sum: e.target.value }) }
                onChange={ ( e ) => this.handlePaymentSumEdit(e.target.value) }
                autoComplete="off"
                validationState={ this.validator.getState( 'sum' ) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { info }
            </div>
            
            {/*Näytä virheviesti jos summaa ei määritelty*/}
            {this.state.invalidSum ? <div> 
                <h4>{ tr('incorrect_info') }</h4>
            </div> : null}
            
        </div>
    }

    renderTerms()
    {
        return <div className="padding">
            <h4 className="formHeader">{ tr('terms') }</h4>

                <ApSelect
                    label={ tr('payment_term') }

                    value={ this.state.paydays }

                    optionRenderer={ ( item ) => {
                        return <div>
                            { item.name }
                        </div>
                    }}
                    //onChange={ ( item ) => this.setState({ paydays: item  })  }
                    onChange={ ( item ) => this.handlePaydayEdit(item)  }
                    objKeyId="id"
                    objKeySearchable="name"
                    objKeyValue="name"
                    clearable

                    options={ this.state.pc }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
             
        </div>
    }

    handlePaydayEdit(paydayObject) {
        this.setState({
            paydays: paydayObject.name,
        });
    }

    renderBasicInfoTab()
    {
        return <ApTab key="basicInfo" icon="info-circle" label="Perustiedot">

                <div className="apColumn w50">
                { this.renderPaymentInfo() }
                
            </div>

            <div className="clear">
            <div className="apColumn w50">
                    <div className="padding">
                        { this.renderTerms()}
                    </div>
                </div>   
            </div>
        </ApTab>
    } 

    setPropertyValue( index, value )
    {
        let properties = this.state.properties.slice( 0 );
        properties[ index ].value = value;
        this.setState({ properties: properties });
    }

    setPropertyBoolean( index )
    {
        let properties = this.state.properties.slice( 0 );
        properties[ index ].value = !Boolean( properties[ index ].value );
        this.setState({ properties: properties });
    }

    setCalculatableValue( index, value )
    {
        value = onlyNumber( value );
        let calculatables = this.state.calculatables.slice( 0 );
        calculatables[ index ].value = value;
        this.setState({ calculatables: calculatables });
    }

    removeCalculatable( id )
    {
        let calculatables = this.state.calculatables.slice( 0 );
        calculatables = calculatables.filter( i => i.id !== id )
        this.setState({ calculatables: calculatables });
    }

    renderCalculatables()
    {
        let options = [];

        this.state.calculatableOptions.forEach( o => {
            if( !this.state.calculatables.find( i => i.id === o.id ) )
            {
                let unitDom = null;

                if( o.work_component )
                    unitDom = <SvgIcon icon={ getTypeIcon( 'work' ) } type="solid" />
                else
                    unitDom = o.unit;

                options.push({
                    label: <div>
                        { o.name }
                        <div className="dropDownUnit"> { unitDom }</div>
                    </div>,
                    icon: getTypeIcon( 'calculatable' ),
                    action: ( id, closeFunc ) => {
                        let index = this.state.calculatables.findIndex( i => ( i.name > o.name ) );
                        if( index === -1 ) index = this.state.calculatables.length;

                        let calculatables = this.state.calculatables.slice( 0 );
                        calculatables.splice( index, 0,  {
                            id: o.id,
                            description: o.description,
                            name: o.name,
                            value: null,
                            unit: o.unit,
                            is_boolean : o.is_boolean,
                            work_component: o.work_component,
                        });
                        this.setState({ calculatables: calculatables });
                        closeFunc();
                    },
                })
            }
        });

        let addButton = null;
        if( options.length )
        {
            addButton = <div className="addButton">
                <ApDropdown
                    position="bottom"
                    fullWidth
                    button={ <ApButton color="white">
                        <SvgIcon icon="plus" type="solid" />
                        { tr('add_new_meter') }
                    </ApButton> }
                    actions={ options }
                />
            </div>
        }

        const listDoms = this.state.calculatables.map( ( i, index ) => {

            const unit = i.unit ? i.unit : '';
            let unitDom = null;
            let label = 'Arvo';

            if( i.value ) unitDom = <div className="unit">{ unit }</div>
                else label = <span>{ tr('value') } <small>{ unit }</small></span>;

            let inputContainer = <div className="unitInputContainer">
                <ApInput
                    className="text-right"
                    label={ label }
                    type="text"
                    id={ `calculatable-value-${ i.id }` }
                    name={ `calculatable-value-${ i.id }` }
                    value={ i.value ? i.value : '' }
                    onChange={ ( e ) => this.setCalculatableValue( index, e.target.value ) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }

                    autoComplete="off"

                    validationState={ this.validator.getState('calculatables', index, 'value') }
                    tooltip={ this.validator.getText('calculatables', index, 'value') }

                />
                { unitDom }
            </div>

            let workComponent = null;
            if( i.work_component )
            {
                const tooltip = <ComponentTooltip component={ i.work_component } />
                workComponent = <ApAddon noLeftBorder width="50px">
                    <ApTooltip block text={ tooltip }>
                        <div className="workIcon">
                            <SvgIcon icon={ getTypeIcon( 'work' ) } type="solid" />
                        </div>
                    </ApTooltip>
                </ApAddon>
            }

            return <div key={ i.id } className="calculatable listStack">
                <ApInputStack gap="0">
                    <ApAddon noRightBorder width="200px" className="titleAddon">
                        <ApTooltip block text={ i.description }>
                            <div className="title">{ i.name }</div>
                        </ApTooltip>
                    </ApAddon>
                    { inputContainer }
                    { workComponent }
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete') }>
                            <div className="apSimpleButton" onClick={ () => { this.removeCalculatable( i.id ) }}>
                                <SvgIcon icon="trash" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                </ApInputStack>
            </div>
        });

        return <div>
            { addButton }
            { listDoms }
        </div>
    }

    setAltNameValue( id, value )
    {
        let altNames = this.state.altNames.slice( 0 );
        altNames = this.state.altNames.map( i => {
            if( i.id === id ) i.name = value;
            return i;
        })
        this.setState({ altNames: altNames });
    }

    removeAltName( id )
    {
        let altNames = this.state.altNames.slice( 0 );
        altNames = altNames.filter( i => i.id !== id );
        this.setState({ altNames: altNames });
    }

    addAltName()
    {
        this.newAltNameCounter++;

        let altNames = this.state.altNames.slice( 0 );
        altNames.push({ id: this.newAltNameCounter, name: '' });
        this.setState({ altNames: altNames });
    }

    renderAltNames()
    {
        const addButton = <ApButton color="white" onClick={ this.addAltName }>
            <SvgIcon icon="plus" type="solid" />
            { tr('add_unifying_name') }
        </ApButton>

        let listDoms = [];

        this.state.altNames.forEach( ( i, index ) => {

            if( i.name === this.state.name ) return null;

            listDoms.push( <div key={ index } className="altNames listStack">
                <ApInputStack gap="0">
                    <ApInput
                        label={ tr('unifying_name') }
                        type="text"
                        id={ `altname-value-${ index }` }
                        name={ `altname-value-${ index }` }
                        value={ i.name ? i.name : '' }
                        onChange={ ( e ) => this.setAltNameValue( i.id, e.target.value ) }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }

                        autoComplete="off"

                        validationState={ this.validator.getState('altNames', index, 'name') }
                        tooltip={ this.validator.getText('altNames', index, 'name') }
                    />
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete') }>
                            <div className="apSimpleButton" onClick={ () => { this.removeAltName( i.id ) }}>
                                <SvgIcon icon="trash" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                </ApInputStack>
            </div>)
        });

        listDoms.push(
            <div key="currentname" className="altNames listStack">
                <ApInputStack gap="0">
                    <ApInput
                        label={ tr('unifying_name') }
                        type="text"
                        id={ `altname-current` }
                        name={ `altname-current` }
                        value={ this.state.name }
                        loading={ this.state.loading }
                        disabled={ true }
                        autoComplete="off"
                    />
                </ApInputStack>
            </div>
        )

        return <div>
            { addButton }
            { listDoms }
        </div>
    }

    renderLocationTab()
    {
        return <ApTab key="location" icon="map-signs" label="Varastot">
            <div className="padding">
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('storages_info') }
                </div>
                { this.renderLocations() }

            </div>
        </ApTab>
    }

    addSupplier( selected )
    {
        let suppliers = this.state.suppliers.slice( 0 );
        let index = this.state.suppliers.findIndex( s => ( s.name > selected.name ) );
        suppliers.splice( index, 0,  {
            id: selected.id,
            name: selected.name,
            primary: ( suppliers.length === 0 ),
            purchase_price: null,
        });
        this.setState({ suppliers: suppliers });
    }

    removeSupplier( index )
    {
        let suppliers = this.state.suppliers.slice( 0 );
        suppliers.splice( index, 1 );
        this.setState({ suppliers: suppliers });
    }

    setPrimarySupplier( index )
    {
        let suppliers = this.state.suppliers.slice( 0 );
        
        suppliers = suppliers.map( ( s, i )  => {
            s.primary = ( i === index );
            if (s.primary)
            {
                //console.log('hinta: ', s.purchase_price);
                this.state.price = s.purchase_price;
                //let price_sell = this.state.price_sell;
                let profitPercent = this.state.profitPercent;
                //let discount = this.props.discount;
                let ohinta = s.purchase_price - (s.purchase_price*this.state.discount/100);      
                this.state.price_sell = profitPercentToSellPrice( ohinta,  profitPercent);
            }
            return s;
        });
        
        this.setState({ suppliers: suppliers });
    }   

    setSupplierPrice( index, value )
    {
        if( value ) value = onlyNumber( value );

        let suppliers = this.state.suppliers.slice( 0 );
        suppliers[ index ].purchase_price = value;
        this.setState({ suppliers: suppliers });
    }

    renderSuppliers()
    {
        const addButton = <ApSelect
            label={ tr('add_supplier') }
            value={ null }
            optionRenderer="storage_supplier"
            onChange={ this.addSupplier }
            clearable
            apiUrl="storage/po/supplier/find"
            apiData={{
                excludeIds: this.state.suppliers.map( i => i.id ),
            }}
            loading={ this.state.loading }
            disabled={ this.state.loading }
        />

        const supplierRows = this.state.suppliers.map( ( i, index ) => {
            let primaryDom = <ApTooltip position="topleft" text={ tr('set_as_primary_supplier') } block>
                <ApButton color="white" onClick={ () => { this.setPrimarySupplier( index ) } }>
                    { tr('set') }
                </ApButton>
            </ApTooltip>

            if( i.primary )
            {
                primaryDom = <ApTooltip position="topleft" text={ tr('primary_supplier') } block>
                    <ApButton disabled color="white" onClick={ () => { this.setPrimarySupplier( index ) } }>
                        { tr('primary') }
                    </ApButton>
                </ApTooltip>
            }

            return <tr key={ i.id } className="row">
                <td className="setPrimaryCol">
                    { primaryDom }
                </td>
                <td className="selectSupplierInputCol">
                    <ApInput
                        label={ tr('supplier') }
                        type="text"
                        id={ `supplier-name-${ i.id }` }
                        name={ `supplier-name-${ i.id }` }
                        value={ i.name ? i.name : '' }
                        loading={ this.state.loading }
                        readOnly
                    />
                </td>
                <td className="priceCol">
                    <div className="priceContainer">
                        <ApInput
                            className="text-right"
                            label={ tr('supplier_price') }
                            type="text"
                            id={ `supplier-price-${ i.id }` }
                            name={ `supplier-price-${ i.id }` }
                            value={ i.purchase_price ? i.purchase_price : '' }
                            onChange={ ( e ) => this.setSupplierPrice( index, e.target.value ) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }

                            validationState={ this.validator.getState( 'suppliers', index, 'purchase_price' ) }
                            tooltip={ this.validator.getText( 'suppliers', index, 'purchase_price' ) }

                            noFocusDecimals={ 2 }
                            autoComplete="off"
                        />
                        { Boolean( i.purchase_price ) && <div className="unit">{ this.state.currencySign ? this.state.currencySign : '' }</div> }
                    </div>
                </td>
                <td className="removeCol">
                    <ApTooltip text="Poista" block>
                        <ApButton color="white" onClick={ () => { this.removeSupplier( index ) } }>
                            <SvgIcon icon="trash" type="solid" />
                        </ApButton>
                    </ApTooltip>
                </td>
            </tr>
        });

        return <div>
            { addButton }
            <table className="listTable">
                <tbody>
                    { supplierRows }
                </tbody>
            </table>
        </div>
    }

    removeWholesale( index )
    {
        let wholesales = this.state.wholesale_components.slice( 0 );
        wholesales.splice( index, 1 );
        this.setState({ wholesale_components: wholesales });
    }

    renderWholesales()
    {
        const wholesaleRows = this.state.wholesale_components.map( ( i, index ) => {

            return <tr key={ i.wholesale_component_id } className="row">
                <td className="">
                    <ApInput
                        label={ tr('lvis_supplier') }
                        type="text"
                        id={ `lvis-name-${ i.id }` }
                        name={ `lvis-name-${ i.id }` }
                        value={ ucfirst( i.wholesale_name ) }
                        loading={ this.state.loading }
                        readOnly
                    />
                </td>

                <td className="">
                    <div className="priceContainer">
                        <ApInput
                            className="text-right"
                            label={ tr('supplier_price') }
                            type="text"
                            id={ `lvis-price-${ i.id }` }
                            name={ `lvis-price-${ i.id }` }
                            value={ i.price ? i.price : '' }

                            loading={ this.state.loading }
                            noFocusDecimals={ 2 }
                            readOnly

                        />
                        <div className="unit">{ this.state.currencySign ? this.state.currencySign : '' }</div>
                    </div>
                </td>
                <td className="removeCol">
                    <ApTooltip text="Poista" block>
                        <ApButton color="white" onClick={ () => { this.removeWholesale( index ) } }>
                            <SvgIcon icon="trash" type="solid" />
                        </ApButton>
                    </ApTooltip>
                </td>
            </tr>
        });

        let autoPriceOptions = this.state.wholesale_components.map( i => {
            return { value: i.wholesale_component_id, label: ucfirst( i.wholesale_name ) };
        });
        autoPriceOptions.unshift({ value: null, label: tr('will_not_be_updated') });
        return <div>
            <table className="listTable">
                <tbody>
                    { wholesaleRows }
                </tbody>
            </table>

            <ApInputStack gap="0">
                <ApAddon noRightBorder >
                    { tr('update_product_LVISNet') }
                </ApAddon>
                <ApInput
                    type="select"
                    options={ autoPriceOptions }
                    id="showDetail"
                    name="showDetail"
                    value={ this.state.wholesaleUpdateId  }
                    onChange={ (e) => {
                        let value = parseInt(  e.target.value );
                        if( isNaN( value )) value = null;
                        this.setState({ wholesaleUpdateId: value })
                    }}
                />
            </ApInputStack>
        </div>

    }

    renderSupplierTab()
    {
        return <ApTab key="location" icon={ getTypeIcon( 'supplier' ) } label={ tr('suppliers') }>
            <div className="padding">
                <h4 className="formHeader">
                    <SvgIcon icon={ getTypeIcon( 'supplier' ) } type="solid" /> { tr('suppliers') }
                </h4>
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('storage_component_suppliers_info') }
                </div>
                { this.renderSuppliers() }
            </div>

            <div className="padding">
                <h4 className="formHeader">
                    <SvgIcon icon={ getTypeIcon( 'supplier' ) } type="solid" /> { tr('LVISNet_suppliers') }
                </h4>
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('LVISNet_suppliers_info') }
                </div>
                { this.renderWholesales() }
            </div>
        </ApTab>
    }

    tabChange( selected )
    {
        this.setState({ selectedTab: selected });
    }

    renderBody()
    {
        if( !this.props.data ) return <div></div>;

        let tabs = [];
        tabs.push( this.renderBasicInfoTab() );

        if( this.state.type_name === 'item' )
        {
            tabs.push( this.renderLocationTab() );
            tabs.push( this.renderSupplierTab() );
        }

        return <ApTabs selected={ this.state.selectedTab } onChange={ this.tabChange } >
            { tabs }
        </ApTabs>
    }

    renderHeader()
    {
        return <div><h4 className="centralElement">{ tr('creation_of_payment') }</h4></div>
    }

    renderFooter()
    {
        const validatorText = this.validator.getStatusAll();
        const statusName = this.state.status_name

        return <div className="footer padding">
                <ApButton
                    className="save"
                    //color={ this.state.status_name === 'active' ? 'green' : 'white' }
                    color='green' 
                    onClick={ () =>  this.save( this.state.status_name ) }
                    disabled={ this.state.loading || Boolean( validatorText ) || this.state.invalidSum}
                >
                    <SvgIcon icon="save" type="solid" />
                    { tr('save') }
                </ApButton>
        </div>
    }

    
    renderConfirmSave()
    {
        return <div className="confirmSave ApModalConfirm">
            <ApModal
                show={ this.state.confirmSaveShow }
                handleClose={ () => this.setState({ confirmSaveShow: false }) }
                closeFromBg
                className="narrow"
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('storage_component_confirm_change') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <div>{ this.state.confirmSaveText }</div>
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton className="cancel" onClick={ () => this.setState({ confirmSaveShow: false }) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('no') }
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ () => { this.save( this.state.confirmSaveAction ) } }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('yes') }
                        </ApButton>
                    </div>
                }
            />
        </div>
    }


    render()
    {
        return <div>
            <div className="componentEdit">
                <ApModal
                    show={ this.props.show }
                    handleClose={ this.props.onClose }
                    header={ this.renderHeader() }
                    body={ this.renderBody() }
                    footer={ this.renderFooter() }
                    onOpen={ this.onOpen }
                />
            </div>
            <div className="componentEditConfirmSave">
                { this.renderConfirmSave() }
            </div>
        </div>
    }
}

NewPayment.propTypes = {
    id: PropTypes.number,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func,
};

export default NewPayment;

