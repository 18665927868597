import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import { ApTabs, ApTab } from '../../../common/ApTabs/ApTabs.js';
import api from 'services/Api/Api.js';
import auth from   'services/Authed/Authed.js';
import Notfound  from 'layout/Notfound/Notfound';
import { hasPermissions } from 'services/Helpers/Helpers.js';

import UserHolidays from './tabs/UserHolidays.js';
import OthersHolidays from './tabs/OthersHolidays.js';

import HolidaySections from './HolidaySections.js';
import AnnualHolidays from './AnnualHolidays.js';
import Flextime from '../Flextime/Flextime.js';
import LongerWorkingHoursCompensation from '../LongerWorkingHoursCompensation/LongerWorkingHoursCompensation.js';

class Holidays extends React.Component {

	constructor( props ) 
	{
		super(props);
        this.state = {
            loading: false,
            settings: [],
            open_schedules: []
        };
        autoBind(this);
    }
    
    componentDidMount()
    {
        this.handleRouteInit();
    }
    
    handleRouteInit()
    {
        const pathname = this.props.history.location.pathname;
        if ((pathname === '/workhours/holidays/flextime' && hasPermissions('timetracking.holiday.flextime')))
        {
            window.emitter.emit( 'goTo', {
                pathname: '/workhours/holidays/flextime',
            });
        }
        else if (pathname === '/workhours/holidays/longerworkinghourscompensation' && hasPermissions('timetracking.holiday.longerworkinghourscompensation'))
        {
            window.emitter.emit( 'goTo', {
                pathname: '/workhours/holidays/longerworkinghourscompensation',
            });
        }
        else {
            if (hasPermissions('timetracking.holiday.annualholidays'))
            {
                window.emitter.emit( 'goTo', {
                    pathname: '/workhours/holidays/annualholidays',
                });
            }
            else if (hasPermissions('timetracking.holiday.flextime'))
            {
                window.emitter.emit( 'goTo', {
                    pathname: '/workhours/holidays/flextime',
                });
            }
            else if (hasPermissions('timetracking.holiday.longerworkinghourscompensation'))
            {
                window.emitter.emit( 'goTo', {
                    pathname: '/workhours/holidays/longerworkinghourscompensation',
                });
            }
            else {
                window.emitter.emit( 'goTo', {
                    pathname: '/workhours/holidays/',
                });
            }
        }
    }

    render()
    {
        if (this.props.history.location.pathname === '/workhours/holidays')
        {
            this.handleRouteInit()
        }

        const params = this.props.match.params;

        let form = null;
        if( params.form )
        {
            if( params.form === 'annualholidays' )
                form = <AnnualHolidays />

            if( params.form === 'flextime' )
                form = <Flextime />

            if( params.form === 'longerworkinghourscompensation' )
                form = <LongerWorkingHoursCompensation />
        }
        else
        {
            return <Notfound />
        }

        return (
            <div id="projectContainer">
                <HolidaySections
                    form={ this.props.match.params.form }
                />

                {form}

            </div>
        );
    }
}

export default Holidays
