/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import axios from 'axios';
import moment from 'moment';

import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';
import { keyExists } from 'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
// import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApFileUpload from 'common/ApFileUpload/ApFileUpload.js';

import { formatMoney, tr } from  'services/Helpers/Helpers.js';

import { Collapse } from 'react-bootstrap';


export default class ExpenseEntry extends React.Component {

	constructor(props) 
    {
        super(props);
        this.state = {
        	rateRequests: [],
        };
        autoBind(this);
    }

    // Imitate input event by wrapping value to "e.target.value" object
    eventify( value )
    {
    	return { target: { value: value } };
    }

	getPaymentOptions()
	{
		let options = [{
			value: '',
			label: tr('payment_from_own_money')
		}];
		let companyCards = [];
		const cards = keyExists( this.props, "paymentCards", true, [] );
		cards.forEach( c => {
			companyCards.push({
				value: c.id,
				label: c.name + " - " + c.number
			});
		});

		if( companyCards.length > 0 )
			options.push({
				label: tr('company_payment_card'),
				options: companyCards,
			});
		
		return options;
	}

	getCurrencyOptions() 
	{
		const companyDefaultCurrency = auth.getCurrency();
		let defaultCurrency = {
			value: companyDefaultCurrency,
			label: ''
		};
		let currencies = [];
		keyExists( this.props, "currencies", true, [] ).forEach( c => {
			
			if( c.value == companyDefaultCurrency )
				defaultCurrency.label = c.value + " - " + c.description;
			
			else 
				currencies.push({
					value: c.value,
					label: c.value + " - " + c.description
				});
		});

		return [
			defaultCurrency,
			{ label: tr('other_currencies'), options: currencies }
		];
	}

	expenseFilesChange( files, userAction )
    {
    	if( !userAction )
    		return false; 

    	let fileIds = [];
    	files.forEach( f => {
    		if( !f.removed )
    			fileIds.push( { file_id: f.id } );
    	});
        this.props.onChange( this.eventify( fileIds ), "detail.files" );
    }

	addRow()
	{
		let rows = keyExists( this.props, "data.detail.rows", true , [] ).slice();
		rows.push( {
			description: 		'',
			cost: 				'',
			currency: 			auth.getCurrency(),
			currency_rate: 		null,
            currency_info:      false,
		});
		this.props.onChange( this.eventify( rows ), "detail.rows" );
	}

	removeRow( index )
	{
		let rows = keyExists( this.props, "data.detail.rows", true , [] ).slice();
		if( rows.length > 1 )
		{
			rows.splice( index, 1 );
			this.props.onChange( this.eventify( rows ), "detail.rows" );
		}
	}

	onRowChange( e, index, name )
	{
		this.props.onChange( e, "detail.rows[" + index + "]." + name );

		if( name == "currency" )
			this.getCurrencyRate( e.target.value, index );
	}

	getCurrencyRate( currency, index )
	{
		let rateRequests = this.state.rateRequests.slice();

		if( index in rateRequests )
		{
			if( rateRequests[ index ].loading )
				rateRequests[ index ].cancel.cancel('Old operation canceled');
		}
		else 
		{
			rateRequests[ index ] = {};
		}

		if( currency == auth.getCurrency() )
		{
			this.props.onChange( this.eventify( 1 ), "detail.rows[" + index + "].currency_rate" );
        	this.props.onChange( this.eventify( false ), "detail.rows[" + index + "].currency_info" );
			return false;
		}

		rateRequests[ index ].loading = true;
		rateRequests[ index ].cancel = axios.CancelToken.source();

		this.setState({ rateRequests });

		api({
            cancelToken: rateRequests[ index ].cancel.token,
            method: 'post',
            url: 'currencyrate',
            data: {
                date: this.props.data.date,
                currency: currency
            }
        }).then(( response ) => {
       
        	if( response.rates[ currency ] )
        	{
        		const info = { 
        			base: response.base,
        			date: response.date, 
        			currency: currency, 
        			rate: response.rates[ currency ],
        		};
        		this.props.onChange( this.eventify( response.rates[ currency ] ), "detail.rows[" + index + "].currency_rate" );
        		this.props.onChange( this.eventify( info ), "detail.rows[" + index + "].currency_info" );
        	}
        	rateRequests[ index ].loading = false;
            this.setState({ rateRequests });

        }).catch(( error ) => {
        	rateRequests[ index ].loading = false;
            this.setState({ rateRequests });
        });
	}

	getRateTooltip( info )
	{
		if( !info )
			return "Ei kurssitietoja saatavilla";

		return (
			<span>
				{ moment( info.date ).format('DD.MM.YYYY') } - <strong>{ info.currency }</strong> = { info.rate }<br />
				<small>Lähde: Euroopan keskuspankki</small>
			</span>
		);
	}

	costToDefaultCurrency( index )
    {
    	const row = keyExists( this.props, "data.detail.rows", true , [] )[ index ];
    	if( row && row.cost )
    		return row.cost / ( row.currency_rate && row.currency_rate != 0 ? row.currency_rate : 1 );
    	return 0;
    }

    getTotalCosts()
    {
    	const fix = ( value, defaultValue = 0, zeroValue = 0 ) => {
    		value = parseFloat( value );
    		
    		if( value == 0 )
    			value = zeroValue;

    		return isNaN( value ) ? defaultValue : value;
    	};
    	let total = 0;
    	keyExists( this.props, "data.detail.rows", true, [] ).forEach( r => {
    		if( r.cost )
    			total += fix( r.cost ) / fix( r.currency_rate, 1, 1 );
    	});
    	return total;
    }

    showCustomerWorkNumber()
    {
        const project = keyExists( this.props, "data.project", true );
        if( !project )
            return false;

        if( !project.billing_hourly )
            return false;

        let customer = project.customer_id;
        if( !customer && project.ancestors.length > 0 )
            customer = project.ancestors[0].customer_id;

        if( !customer )
            return false;

        return true;
    }

    render()
    {
		const rows = keyExists( this.props, "data.detail.rows", true, [] );
		
        return (
            <div>

            	<ApInputStack gap="0">
                	<ApAddon noRightBorder width="150">
                		{ tr('payment_method') }
                	</ApAddon>
	                <ApInput 
	                    type="select" 
	                    id="payment_card_id" 
	                    name="payment_card_id" 
	                    value={ keyExists( this.props, "data.detail.payment_card_id", true, '' ) }
	                    options={ this.getPaymentOptions() }
	                    onChange={ ( e ) => this.props.onChange( e, "detail.payment_card_id" ) }
	                />
                </ApInputStack>

                { this.showCustomerWorkNumber() &&
                    <ApInput 
                        type="text" 
                        id="customer_work_number" 
                        name="customer_work_number" 
                        label={ tr('customer_work_number') }
                        value={ keyExists( this.props, "data.customer_work_number", true, '' ) }
                        onChange={ this.props.onChange }
                    />
                }

                <div className={ "apFormGroup" + ( keyExists( this.props, "data.event_checked", true, false ) ? " success" : "" ) }>

                	<div className="apSwitchBlock small">
                        <label htmlFor="expense-event-switch" className="info">
                            { tr('occasion') }
                            <small>{ tr('occasion_info') }</small>
                        </label>
                        <ApSwitch
                            small
                            id="expense-event-switch"
                            on={ keyExists( this.props, "data.event_checked", true, false ) }
                            onChange={ () => this.props.onChange( this.eventify( !this.props.data.event_checked ), "event_checked" ) }
                        />
                    </div>
	                <Collapse in={ keyExists( this.props, "data.event_checked", true, false ) }>
	                	<div>
	                		<hr />
	                		<ApInput 
			                    type="text" 
			                    id="event" 
			                    name="event" 
			                    label={ tr('occasion_name') }
			                    value={ keyExists( this.props, "data.detail.event", true, '' ) }
			                    onChange={ ( e ) => this.props.onChange( e, "detail.event" ) }
			                    validationState={ keyExists( this.props, "data.detail.event", true, '' ) ? "" : "error" }
			                />
	                		<ApInput 
			                    type="textarea" 
			                    id="event_details" 
			                    name="event_details" 
			                    label={ tr('additional_info') }
			                    value={ keyExists( this.props, "data.detail.event_details", true, "" ) }
			                    onChange={ ( e ) => this.props.onChange( e, "detail.event_details" ) }
			                    charCounter
			                    rows={5}
			                />

			                <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('occasion_note') }
                            </div>
	                	</div>
	                </Collapse>
                </div>

                <ApFileUpload 
                    files={ keyExists( this.props, "data.detail.files", true, [] ).map( f => f.file_id ) } 
                    accept="image/jpeg, image/png, application/pdf"
                    description={ "Kuittiselvitys " + this.props.data.date }
                    onChange={ this.expenseFilesChange }
                    //uploadedFiles={ [] }
                    maxFiles={ 5 }
                    //showRemoved 
                    //returnRemoved
                    maxSize={ 1024 * 1024 * 1 } // 1 Mt
                    noFilesLabel={ tr('add_receipt_photo') }
                    noFilesSubLabel={ tr('add_receipt_note') }
                    addButtonLabel={`${tr('add_receipt')}...`}
                />

                <div className="apFormGroup">
                	<h6>{ tr('expense_rows') } <span className="total">- { tr('in_total') }: { formatMoney( this.getTotalCosts() ) } { auth.getCurrency() }</span></h6>
	                { rows.map( ( r, index ) => {
	                	return (
	                		<div className="costRow" key={ index }>
		                			<ApInput 
		                				gapRight="10"
					                    type="text" 
					                    id={ "row_description" + index } 
					                    name={ "row_description" + index }
					                    label={ tr('description') }
					                    value={ r.description || "" }
					                    onChange={ ( e ) => this.onRowChange( e, index, "description" ) }
										validationState={ r.description ? "" : "error" }
					               />
								   <br/>
								   <ApInputStack gap="0">
					                <ApInput 
					                	width="120"
					                    type="number" 
					                    id={ "row_cost" + index } 
					                    name={ "row_cost" + index }
					                    label={ tr('price') }
					                    value={ r.cost || "" }
					                    onChange={ ( e ) => this.onRowChange( e, index, "cost" ) }
					                    validationState={ r.cost > 0 ? "" : "error" }
					                />
					                <ApInput 
					                	width="90"
					                    type="select" 
					                    id={ "row_currency" + index } 
					                    name={ "row_currency" + index }
					                    value={ r.currency }
					                    options={ this.getCurrencyOptions() }
					                    onChange={ ( e ) => this.onRowChange( e, index, "currency" ) }
					                />
				                	<ApAddon custom width="50">
				                		<SvgIcon 
				                			className={ "removeRow" + ( rows.length <= 1 ? " disabled" : "" ) } 
				                			icon="times-circle" 
				                			type="solid" 
				                			onClick={ () => this.removeRow( index ) } 
				                		/>
				                	</ApAddon>
				                </ApInputStack>
				                <Collapse in={ r.currency !== auth.getCurrency() }>
				                	<div>
				                		<div className="currencyRate">

				                			<ApInputStack gap="0">
                                                <ApAddon noRightBorder width="70px" align="right">
                                                    { r.currency }
                                                </ApAddon>
                                                <ApInput 
                                                    type="number" 
                                                    id={ "row_currency_rate" + index } 
                                                    name={ "row_currency_rate" + index } 
                                                    label={ tr('exchange_rate') }
                                                    value={ r.currency_rate || "" }
                                                    onChange={ ( e ) => this.onRowChange( e, index, "currency_rate" ) }
                                                    tooltip={ this.getRateTooltip( keyExists( r, "currency_info", true ) ) }
                                                    loading={ index in this.state.rateRequests ? this.state.rateRequests[ index ].loading : false }
                                                    disabled={ index in this.state.rateRequests ? this.state.rateRequests[ index ].loading : false }
                                                    validationState={ !r.currency_rate || r.currency_rate == 0 ? "error" : "" }
                                                />
                                                <ApAddon noLeftBorder width="200px">
                                                    = { formatMoney( this.costToDefaultCurrency( index ) ) } { auth.getCurrency() }
                                                </ApAddon>
                                            </ApInputStack>

				                		</div> 
				                	</div>
				                </Collapse>
			                </div>
	                	)
	                })}

                	<ApButton color="white" size="small" onClick={ this.addRow }>
                		<SvgIcon icon="plus" type="solid" />
                		{ tr('add_expense_row') }
                	</ApButton>
    
                </div>

                {/* this.props.addFieldsRenderer( "expense", this.props.data ) */}


            </div>
        );
    }

}
