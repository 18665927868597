import {combineReducers} from 'redux';

import {
    customerSettingsReducer,
    generalSettingsReducer,
    orderSettingsReducer,
    projectSettingsReducer,
    purchaseSettingsReducer,
    storageSettingsReducer,
    timetrackingSettingsReducer,
    externalSettingsReducer,
    apCostCenterSettingsReducer
} from './settingsReducers';
import { companyOnlineUsersReducer } from "./onlineUsersReducer"
import { timerReducer } from './timerReducer';
import { apReackTableReducer } from './apReackTableReducer';


const reducers = combineReducers({
    apCustomerSettings: customerSettingsReducer,
    apGeneralSettings: generalSettingsReducer,
    apOrderSettings: orderSettingsReducer,
    apProjectSettings: projectSettingsReducer,
    apPurchaseSettings: purchaseSettingsReducer,
    apStorageSettings: storageSettingsReducer,
    apTimetrackingSettings: timetrackingSettingsReducer,
    apCompanyOnlineUsers: companyOnlineUsersReducer,
    apExternalSettings: externalSettingsReducer,
    apTimer: timerReducer,
    apReackTable: apReackTableReducer,
    apCostCenterSettings: apCostCenterSettingsReducer,
});

export default reducers;
