import React from 'react';
import autoBind from 'react-autobind';
import { ApTabs, 
         ApTab } from 'common/ApTabs/ApTabs.js';
import { tr } from 'services/Helpers/Helpers.js';
import Confirm from './tabs/Confirm.js';
import OldHours from './tabs/OldHours.js';

export default class Main extends React.Component
{

	constructor(props)
	{

		super(props);

		autoBind(this);

	}

	render()
	{ 

		return(

			<div className="apBox">

				<div className="apBoxHeader">
					<h1> { tr('confirmation_of_hours') } </h1>
					<p> { tr('confirmation_of_hours_info') } </p>
				</div>

				<ApTabs>

					<ApTab icon="calendar-check" label={ tr('administrator_approval') }>
						<Confirm />
					</ApTab>

					<ApTab icon="calendar-week" label={ tr('timetracking_previous_entries') }>
						<OldHours />
					</ApTab>

				</ApTabs>

			</div>

		);

	}

}
