import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import ApSwitch     from 'common/ApSwitch/ApSwitch.js';
import ApButton     from 'common/ApButton/ApButton.js';
import ApSelect     from 'common/ApSelect/ApSelect.js';
import { ApInput, ApInputStack, ApAddon }  from 'common/ApInput/ApInput.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import { tr } from 'services/Helpers/Helpers.js';

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { validateDate } from  'services/Helpers/Helpers.js';
import './CommentDialog.css';

class CommentDialog extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            comment: '',
            acceptor: null,
            setAcceptor: false,
            selfAcceptor: false,
            skipAcceptor: false,
            skipArrival: false,

            deliveryDate: null,
            dueDate: null,
        };

        this.validator = new ApValidate( this, {
            dueDate: { filter: 'date', text: tr('due_date_invalid') },
            deliveryDate: { filter: 'date', text: tr('delivery_date_invalid') },
        });

        autoBind(this);
    }

    resetState()
    {
        this.setState({
            comment: '',
            acceptor: null,
            setAcceptor: false,
            selfAcceptor: false,
            skipAcceptor: false,
            skipArrival: false,

            deliveryDate: null,
            dueDate: null
        } );
    }

    save()
    {
        const comment = this.state.comment ? this.state.comment : null;

        let acceptor = null;
        let data = {
            delivery_date: this.state.deliveryDate,
            due_date: this.state.dueDate,
            skip_acceptor: this.state.skipAcceptor,
            skip_arrival: this.state.skipArrival
            };

        if(this.props.selectAcceptor && this.state.setAcceptor && this.state.acceptor)
            acceptor = this.state.acceptor.id;

        this.props.onSave(this.props.meta.action, comment, acceptor, data);
    }

    formIsValid()
    {
        if( !this.props.selectAcceptor ) {
                return true;            
        }
        

        if( this.state.setAcceptor ) return Boolean( this.state.acceptor )
        if (this.state.skipAcceptor) {
            return (validateDate( this.state.deliveryDate ) && validateDate( this.state.dueDate ) );
        }
        else {
            return this.state.selfAcceptor;
        }
    }

    toggleSelfAcceptor()
    {
        if (this.props.due_date!=null) {
            this.setState({ dueDate: this.props.due_date});
        }
        if (this.props.delivery_date!=null) {
            this.setState({ deliveryDate: this.props.delivery_date});
        }
        this.setState({ selfAcceptor: !this.state.selfAcceptor });
    }

    toggleSkipAcceptor() { this.setState({ skipAcceptor: !this.state.skipAcceptor }); }

    toggleAcceptor()
    {
        this.setState( {
            selfAcceptor: false,
            setAcceptor: !this.state.setAcceptor,
        });
    }

    
    renderAcceptorSelect()
    {
        if( !this.props.selectAcceptor ) return null;

        let userInput = null;
        if( this.state.setAcceptor )
        {
            userInput = <ApSelect
                label={ tr('further_approver') }
                value={ this.state.acceptor }
                objKeyValue={ ( item ) => { return `${ item.name }`; } }
                optionRenderer="user"
                onChange={ ( selected ) => { this.setState({ acceptor: selected }) } }
                objKeyId="id"
                clearable
                apiUrl="storage/po/acceptors"
            />
        }

        let selfAcceptor = null;
        if( !this.state.setAcceptor )
        {
            selfAcceptor = <div className="apSwitchBlock">
                <label htmlFor="self-acceptor-switch" className="info">
                    { tr('po_self_acceptor_info1') }
                    <small>{ tr('po_self_acceptor_info2') }</small>
                </label>
                <ApSwitch
                    id="self-acceptor-switch"
                    on={ this.state.selfAcceptor }
                    onChange={ this.toggleSelfAcceptor }
                />
            </div>

        }

        return <div className="commentDialog">
            <div className="apSwitchBlock">
                <label htmlFor="add-acceptor-switch" className="info">
                    { tr('add_further_approver') }
                    <small>{ tr('add_further_approver_info') }</small>
                </label>
                <ApSwitch
                    id="add-acceptor-switch"
                    on={ this.state.setAcceptor }
                    onChange={ this.toggleAcceptor }
                />
            </div>
            { selfAcceptor }
            { userInput }
        </div>
    }

    renderSkipOptions() {

        let validatorMessage = this.validator.getStatusAll();
        //TODO: Saatetaan tarvita => Ostotilaustiedosto

        const skipOptionsDiv = <div>
            <ApInputStack gap="0">
                <ApInput
                    type="date"
                    id="due-date"
                    name="due-date"
                    label={ tr('due_date') }
                    value={ this.state.dueDate }
                    onChange={ ( e ) => { this.setState({ dueDate: e.target.value }) } }
                    validationState={ this.validator.getState('dueDate') }
                />
                <ApInput
                    type="date"
                    id="delivery-date"
                    name="delivery-date"
                    label={ tr('delivery_date') }
                    value={ this.state.deliveryDate }
                    onChange={ ( e ) => { this.setState({ deliveryDate: e.target.value }) } }
                    validationState={ this.validator.getState('deliveryDate') }
                />
            </ApInputStack>
            <div className="apSwitchBlock">
                <label htmlFor="add-skip-acceptor-switch" className="info">
                { tr('record_arrival') }
                <small>{ tr('after_creation_delivery_reception') }</small>
                </label>
                <ApSwitch
                        id="skip-arrival-switch"
                        on={ this.state.skipArrival }
                        onChange={ () => { this.setState({ skipArrival: !this.state.skipArrival }) } }
                    />
            </div>
        </div>

        if (this.state.selfAcceptor) {
            return <div>
                <div className="apSwitchBlock">
                <label htmlFor="add-skip-acceptor-switch" className="info">
                    { tr('po_checked_confirmed') }
                    <small>{ tr('po_checked_confirmed_info') }</small>
                </label>
                <ApSwitch
                    id="skip-acceptor-switch"
                    on={ this.state.skipAcceptor }
                    onChange={ this.toggleSkipAcceptor }
                    />
            </div>
            { this.state.skipAcceptor ? skipOptionsDiv : '' }
        </div>
        }
        else {
            return '';
        }
        
    }

    render()
    {
        const meta = this.props.meta;
        if( !meta ) return false;

        const icon = this.state.skipAcceptor ? <SvgIcon icon="clipboard-check" type="solid" />: <SvgIcon icon={ meta.icon } type="solid" /> ;

        return <div className="commentDialog">
            <ApModal
                show={ this.props.show }
                handleClose={ this.props.onClose }
                className="narrow"
                onOpen={ this.resetState }
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('comment') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <ApInput
                            type="textarea"
                            id="comment"
                            name="comment"
                            label={ tr('comment_to_event_log') }
                            autoComplete="off"
                            value={ this.state.comment }
                            onChange={  ( e ) => this.setState( { comment: e.target.value } ) }
                        />
                        { this.renderAcceptorSelect() }
                        { this.props.meta.action == 'save' ? this.renderSkipOptions() : '' }
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton onClick={ () => { this.props.onClose() } }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton
                            className="comment-submit"
                            color={ this.state.skipAcceptor ? "green": meta.color }
                            onClick={ this.save }
                            disabled={ !this.formIsValid() }
                        >
                            { icon }
                            {this.state.skipAcceptor ? "Vahvista ostotilaus": meta.text } 
                        </ApButton>

                    </div>
                }
            />
        </div>
    }
}

CommentDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    meta: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default CommentDialog;
