import ApDropdown from 'common/ApDropdown/ApDropdown';
import ApReactTable, { colPreset } from 'common/ApReactTable/ApReactTable';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import React from 'react';
import autobind from 'react-autobind';
import { errorPopper, keyExists, mapTree, tr } from 'services/Helpers/Helpers';
import PropTypes from "prop-types";
import api from "services/Api/Api";

import './PurchaseOrderOffer.css';
import ApModal from 'common/ApModal/ApModal';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';
import ApButton from 'common/ApButton/ApButton';
import { connect } from 'react-redux';
import ApTooltip from 'common/ApTooltip/ApTooltip';
import ComponentTooltip from 'modules/Storage/common/ComponentTooltip/ComponentTooltip';
import NewPurchaseOrder from 'modules/Storage/PurchaseOrders/NewPurchaseOrder/NewPurchaseOrder';

const statusOptions = [
    {label: tr('all'), value: ''},
    {label: tr('waiting'), value: 'waiting'},
    {label: tr('approved'), value: 'approved'},
    {label: tr('rejected'), value: 'rejected'},
]

const poModalDataOptions = {
    price: {
        label: tr('price'),
        icon: 'euro-sign',
        headerValues: [tr('price'), tr('unit_price'), tr('storage_component_price')],
    },
    amount: {
        label: tr('amount'),
        icon: 'boxes',
        headerValues: [tr('amount')],
    },
}

class SentPurchaseOrderOffers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: [],
            selectedOffer: null,
            selectedFile: null,
            selectFileForPo: null,
            poModalData: null,
            poModalDataRows: [],
            poModalDataHeaders: [],
            selectedIdentifier: 'name',
            otherCols: [],
            newPoShow: false,
        }
        autobind(this);
    }

    componentDidMount() {
        this.getList();
    }

    getList() {
        this.setState({loading: true});
        api({
            url: `project/po/offer/get/${this.props.projectId}`,
        }).then(response => {
            this.setState({loading: false, data: response});
        }).catch(error => {
            this.setState({loading: false});
            errorPopper(error, tr('get_error'));
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.selectedOffer && this.state.selectedOffer && this.state.selectedOffer.id) {
            // this.getFile()
        }
    }

    getFile(file) {
        this.setState({loading: true});
        api({
            url: `file/id/${file.file_id}`,
            data: {
                id: this.state.selectedOffer.id
            },
            responseType: 'blob',
            fileTitle: file.file.original_name
        }).then(response => {
            this.setState({loading: false});
        }).catch(error => {
            this.setState({loading: false});
            errorPopper(error, tr('get_error'));
        });
    }

    readFile(file) {
        this.setState({loading: true});
        api({
            method: "POST",
            url: `project/po/offer/read/file`,
            data: {
                file
            }
        }).then(response => {
            // console.log("res", response);

            let usedCols = [];
            let otherCols = response.headers.map( h => {

                if( !h ) return null;
                h = `${h}`.toLowerCase();

                for ( let key in poModalDataOptions ) {
                    const fOptions = poModalDataOptions[ key ].headerValues;

                    if( !Array.isArray( fOptions ) ) continue;
                    let found = fOptions.find( f => {
                        f = f.toLowerCase();

                        let tmp = h;

                        if( f.slice(-1) === '*' ) {
                            const l = f.length - 1;
                            f = f.substring( 0, l )
                            tmp = h.substring( 0, l )
                        }
                        return ( tmp === f );
                    })

                    if( found ) {
                        // Skip so there are no same column dublicated
                        if( usedCols.includes( key )  )
                            return null;

                        // Also add exclueds as used cols
                        if( poModalDataOptions[ key ].exclude ) {
                            poModalDataOptions[ key ].exclude.forEach( e => usedCols.push( e ));
                        }

                        usedCols.push( key );
                        return key;
                    }
                }
                return null;
            });

            this.setState({
                loading: false,
                poModalData: response,
                poModalDataRows: response.rows,
                poModalDataHeaders: response.headers,
                otherCols
            });
        }).catch(error => {
            this.setState({loading: false});
            errorPopper(error, tr('get_error'));
        });
    }

    getColumns() {
        const columns = [
            colPreset({
                id: 'created_at',
                Header: tr('sent'),
                accessor: 'created_at',
                type: 'datetime',
                filterable: false,
                customizable: true,
                showInitially: true,
            }),
            {
                id: 'creator_name',
                Header: tr('sender'),
                accessor: 'creator_name',
                customFilter: {
					type: "text",
				},
                customizable: true,
                showInitially: true,
            },
            {
                id: 'title',
                Header: tr('header'),
                accessor: 'title',
                customFilter: {
					type: "text",
				},
                customizable: true,
                showInitially: true,
            },
            {
                id: 'text',
                Header: tr('message'),
                accessor: 'text',
                customFilter: {
					type: "text",
				},
                customizable: true,
                showInitially: false,
            },
            {
                id: 'supplier_name',
                Header: tr('supplier'),
                accessor: 'supplier_name',
                customFilter: {
					type: "text",
				},
                customizable: true,
                showInitially: true,
            },
            colPreset({
                id: 'price',
                Header: tr('price'),
                accessor: 'price',
                type: 'currency',
                filterable: false,
                customizable: true,
                showInitially: true,
            }),
            {
                id: 'file',
                Header: tr('file'),
                accessor: 'file_id',
                width: 50,
                Cell: props => <SvgIcon type='solid' icon='file-excel' />,
                onClick: row => {
                    this.setState({selectedOffer: row});
                },
                className: 'overflowableOnHover',
                filterable: false,
                customizable: true,
                showInitially: true,
            },
            {
                id: 'email',
                Header: tr('email'),
                accessor: 'email',
				filterable: true,
                customFilter: {
					type: "text",
				},
                customizable: true,
                showInitially: true,
            },
            {
                id: 'email_sent',
                Header: tr('email_sent'),
                accessor: 'email_sent',
                Cell: (props) => {
					if (props.value)
						return <div className="text-center"><SvgIcon fill="var(--clr-success-main)" icon="check-circle" type="solid" /></div>
                    else
						return <div className="text-center"><SvgIcon fill="var(--clr-error-main)" icon="times-circle" type="solid" /></div>
				},
				customFilter: {
					type: "toggle",
				},
                customizable: true,
                showInitially: true,
            },
            {
                id: 'status',
                Header: tr('status'),
                accessor: row => tr(row.status),
                Cell: props => {
                    let sColor = {
                        waiting: 'info',
                        approved: 'success',
                        rejected: 'error',
                    }
                    return <div className={`apStatusBox ${ sColor[ props.original.status ] }`}> { tr(props.original.status) }</div>
                },
                filterable: true,
                customFilter: {
                    type: 'select',
                    options: statusOptions
                },
                showInitially: true,
            },
        ];

        if (this.props.sheetId) {
            columns.splice(4,0, {
                id: 'sheet_name',
                Header: tr('offer_version'),
                accessor: 'sheet_name',
                customizable: true
            })
        }

        return columns;
    }

    handleApproval(id, approval) {
        const data = JSON.parse(JSON.stringify(this.state.data));
        this.setState({loading: true});
        api({
            method: 'POST',
            url: 'project/po/offer/updatestatus',
            data: {id, status: approval}
        }).then(response => {
            this.setState({loading: false});
            const found = data.findIndex(item => item.id === id);
            if (found !== -1) {
                data[found].status = approval;
                errorPopper(null, tr('status_changed'), 4000, 'success');
                this.setState({data});
            }
        }).catch(error => {
            this.setState({loading: false});
            errorPopper(error, tr('save_error'));
            console.error(error);
        })
    }
    
    handleEmailSent(id, email_sent = true) {
        const data = JSON.parse(JSON.stringify(this.state.data));
        this.setState({loading: true});
        api({
            method: 'POST',
            url: 'project/po/offer/updateemailsent',
            data: {id, email_sent}
        }).then(response => {
            this.setState({loading: false});
            const found = data.findIndex(item => item.id === id);
            if (found !== -1) {
                data[found].email_sent = email_sent;
                errorPopper(null, tr('status_changed'), 4000, 'success');
                this.setState({data});
            }
        }).catch(error => {
            this.setState({loading: false});
            errorPopper(error, tr('save_error'));
            console.error(error);
        })
    }

    renderFileAddHeader() {
        return <div className='padding'><h4>{tr('files')}</h4></div>
    }
    
    renderFileAddBody() {
        // if (!keyExists(this.state.selectedOffer, 'files')) {
        //     return <div className='padding'>
        //         {tr('no_files_added')}
        //     </div>
        // }

        return <div className='padding'>
            {this.state.selectedOffer ? this.state.selectedOffer.files.map((offerFile, index) => {
                return (
                    <ApInputStack key={index}>
                        <ApAddon 
                            tooltip={tr('choose_file')} 
                            onClick={() => {
                                this.getFile(offerFile)
                            }}
                        >
                            <div className='justify-space-between' style={{alignItems: 'center', padding: '0 0.4em'}}>
                                {keyExists(offerFile.file, 'original_name', true, null)} 
                                <SvgIcon type='solid' icon='download' />
                            </div>
                        </ApAddon>
                    </ApInputStack>
                )
            }) : null}
            <ApInput
                type="file"
                accept=".xlsx"
                id="po-selectedFile"
                name="po-selectedFile"
                label={`${tr('add_file')}...`}
                value={ this.state.selectedFile }
                validationState={this.state.selectedFile ? 'success' : 'error'}
                onChange={ ( file ) => { this.setState({ selectedFile: file }) } }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
        </div>
    }

    renderFileAddFooter() {
        return (
            <div className='padding justify-space-between'>
                <ApButton onClick={() => this.setState({selectedOffer: null, selectedFile: null})}>{tr('cancel')}</ApButton>
                <ApButton onClick={() => this.addFileToOffer()} color='blue'><SvgIcon type='solid' icon='save' /> {tr('save')}</ApButton>
            </div>
        )
    }

    addFileToOffer() {
        const offerId = keyExists(this.state.selectedOffer, 'id', true, null);
        const file = this.state.selectedFile;

        if (!offerId || !file) return;

        const data = new FormData();
        data.append('offerId', offerId);
        data.append('file', file);

        this.setState({loading: true});
        api({
            method: 'POST',
            url: 'project/po/offer/add/file',
            data
        }).then(response => {
            // console.log("res", response);
            this.setState({loading: false, selectedOffer: null, selectedFile: null});
            this.getList();
        }).catch(error => {
            errorPopper(error, tr('get_error'));
            console.error(error);
            this.setState({loading: false});
        })
    }

    renderPoDataTableHeader() {
        return <div className='padding'><h4>{tr('create_po_from_inquiry')}</h4></div>
    }
    renderPoDataTableBody() {
        const identifierOptions = [
            {value: 'name', label: tr('name')},
            ...this.props.apStorageSettings.identifiers.map(item => ({value: item.id, label: item.name}))
        ]
        return <div className='padding'>
            {/* <div style={{marginBottom: '1rem'}}>
                <ApInputStack gap="0">
                    <ApAddon width="300px" noRightBorder>
                        <ApTooltip block text={tr('select_identifier_info')} position="right">
                            <div className="apSimpleButton" onClick={this.import}>
                                {tr('select_identifier')}
                            </div>
                        </ApTooltip>
                    </ApAddon>
                    <ApInput
                        type='select'
                        options={identifierOptions}
                        onChange={(e) => this.setState({selectedIdentifier: e.target.value})}
                        value={ this.state.selectedIdentifier}
                    />
                </ApInputStack>
            </div> */}
            <h4 className='formHeader'>{tr('choose_file')}</h4>
            <div style={{marginBottom: '1rem'}}>
                {this.state.selectedOffer ? this.state.selectedOffer.files.map((offerFile, index) => {
                    return (
                        <ApInputStack key={index}>
                            <ApAddon 
                                tooltip={tr('choose_file')} 
                                onClick={() => {
                                    this.readFile(offerFile)
                                }}
                            >
                                <div className='justify-space-between' style={{alignItems: 'center', padding: '0 0.4em'}}>
                                    {keyExists(offerFile.file, 'original_name', true, null)} 
                                    <SvgIcon type='solid' icon='download' />
                                </div>
                            </ApAddon>
                        </ApInputStack>
                    )
                }) : null}
            </div>
            <h4 className='formHeader'>{tr('select_amount_and_price')}</h4>
            <div id='poModalDataTable'>
                <ApReactTable
                    data={this.state.poModalDataRows}
                    columns={this.getPoModalColumns()}
                    loading={this.state.loading}
                />
            </div>
        </div>
    }

    renderPoDataTableFooter() {
        const handleNextButton = () => {
            const newPoData = {};
            const components = this.state.poModalDataRows.map(component => {
                component.id = null;
                component.component_id = component.component ? component.component.id : null;
                component.unit = component.component ? component.component.unit : null;
                // component.location_id = component.storageLocation ? component.storageLocation.id : null;
                // component.target_final_type = 'location';
                component.type_id = 1;
                component.supplier_id = this.state.selectedOffer.supplier.id;
                this.state.otherCols.forEach((col, index) => {
                    if (!col) return;
                    if (col === 'price') {
                        component.purchase_order_price = component.other[index];
                    } else if (col === 'amount') {
                        component.count = component.other[index];
                    }
                });
                if (!component.purchase_order_price) {
                    component.purchase_order_price = null;
                }
                if (!component.count) {
                    component.count = null;
                }
                 return component;
            })

            newPoData.components = components;
            newPoData.supplier = this.state.selectedOffer.supplier;

            if (this.props.tree && this.props.tree[0]) {
                let storageLocation = null;
                let receiverProjectId = null;
                mapTree(this.props.tree, item => {
                    if (storageLocation) return item;
                    if (item.storage_location) {
                        storageLocation = item.storage_location;
                        receiverProjectId = item.id;
                        newPoData.project = item;
                        newPoData.project.storage_location_id = item.storage_location.id;
                    }
                    return item;
                })
                if (storageLocation) {
                    newPoData.receiverType = storageLocation.type == 'S' ? 'location' : 'project';
                    newPoData.receiverTitle = storageLocation.name;
                    newPoData.receiverName = storageLocation.delivery_name;
                    newPoData.receiverAddress = storageLocation.delivery_address;
                    newPoData.receiverCity = storageLocation.delivery_city;
                    newPoData.receiverZipcode = storageLocation.delivery_zipcode;
                    newPoData.receiverContactName = storageLocation.delivery_contact;
                    newPoData.receiverCountry = storageLocation.delivery_country;
                    newPoData.receiverId = storageLocation.id;
                    newPoData.receiverProjectId = receiverProjectId;
                }
            }

            this.setState({
                selectedOffer: null,
                selectFileForPo: null,
                poModalData: null,
                poModalDataHeaders: [],
                poModalDataRows: [],
                otherCols: [],
                newPoShow: true,
                newPoData
            });
        }

        return (
            <div className='padding justify-space-between'>
                <ApButton onClick={() => this.setState({selectedOffer: null, selectedFile: null, poModalData: null, selectFileForPo: null, otherCols: []})}>{tr('cancel')}</ApButton>
                <ApButton onClick={handleNextButton} color='blue'>{tr('continue')} <SvgIcon type='solid' icon='arrow-right' /></ApButton>
            </div>
        )
    }

    getPoModalColumns() {
        let cols = [];

        cols.push({
            Header: '#',
            id: 'nmb',
            width: 50,
            filterable: false,
            sortable: false,
            accessor: 'id',
            className: "overflowableOnHover",
            Cell: props => {
                let dom = <div className="rowNumber">{ props.value }.</div>;
                if( props.value == 2 )
                    return <ApTooltip block text={ tr('excel_first_row_title') } position="topleft">
                        { dom }
                    </ApTooltip>
                return dom;
            }
        });

        cols.push({
            Header: tr('link'),
            id: 'link',
            width: 50,
            className: "overflowableOnHover linkTd center",
            filterable: false,
            Cell: props => {

                const row = props.original;

                let color = "#000"
                let tooltip = null;
                let icon = null;

                if( row.link === 'old' )
                {
                    tooltip = tr('storage_component_link_exists');
                    icon = 'check-circle';
                    color = '#0099CC';
                }
                if( row.link === 'noLink' )
                {
                    tooltip = tr('storage_component_no_link');
                    icon = 'times-circle';
                    color = '#CC0000';
                }
                else if( row.link === 'newLink' )
                {
                    tooltip = tr('storage_component_new_link');
                    icon = 'arrow-alt-circle-left';
                    color = '#388E3C';
                }
                else if( row.link === 'newComponent' )
                {
                    tooltip = tr('new_storage_component');
                    icon = 'plus-circle';
                    color = '#388E3C';
                }

                let tooltipDom = [];
                if( tooltip ) tooltipDom.push(<div key="tt">{ tooltip }</div>);
                if( row.component ) tooltipDom.push(<ComponentTooltip key="c" component={ row.component } />);

                return <div>
                    <ApTooltip block text={ tooltipDom } position="topleft">
                        <SvgIcon icon={ icon } type="solid" fill={ color } />
                    </ApTooltip>
                </div>
            },
        });

        if (this.state.selectedIdentifier == "name") {
            cols.push({
                id: 'name',
                Header: tr('name'),
                // showInitially: true,
                // customizable: true,
                accessor: 'name',
                customFilter: {
                    type: "text",
                    placeholder: tr('enter_name'),
                },
            });
        }
        else {
            let columnName = 'identifier_' + this.state.loadedSelectedColumnIdentifier;
            if (this.state.headerOptions[columnName]) {
                cols.push({
                    id: columnName,
                    Header: this.state.headerOptions[columnName].label,
                    // showInitially: true,
                    // customizable: true,
                    accessor: columnName,
                    customFilter: {
                        type: "text",
                        placeholder: tr('enter_name'),
                    },
                });
                
            }
            else {
                cols.push({
                    id: 'name',
                    Header: tr('name'),
                    // showInitially: true,
                    // customizable: true,
                    accessor: 'name',
                    customFilter: {
                        type: "text",
                        placeholder: tr('enter_name'),
                    },
                });
            }
        }

        this.state.otherCols.forEach((item, index) => {
            const header = row => {
                const actions = this.getHeaderActions( index );

                let title = tr('not_used');

                if (item) {
                    title = poModalDataOptions[ item ].label;
                }

                return <ApDropdown
                    fullWidth
                    button={ <div className="input-menu"><div className="headerLabel">{ title }</div><div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
                    actions={ actions }
                />
            }

            cols.push({
                id: `othercol_${ index }`,
                Header: header,
                // headerClassName: "overflowable",
                sortable: false,
                accessor: row => {
                    return row.other[ index ];
                },
                className: 'otherCell overflowableOnHover',
                Cell: props => {
                    let className= ['other'];
                    if (props.value === 0) props.value = '0';
                    return <div className={ className.join(' ')}>
                        { props.value || <span>&nbsp;</span> }
                    </div>
                },
            })
        })

        return cols;
    }

    getHeaderActions( index )
    {
        let options = [{
            label: tr('not_used'),
            action: ( id, closeFunc ) => {
                this.setOtherColSelected( index, null );
                closeFunc();
            },
        }];

        for ( let key in poModalDataOptions )
        {
            if( this.state.otherCols.includes(key) ) continue;

            options.push( {
                label: poModalDataOptions[ key ].label,
                icon:  poModalDataOptions[ key ].icon,
                action: ( id, closeFunc ) => {
                    this.setOtherColSelected( index, key );
                    closeFunc();
                },
            });
        }
        return options;
    }

    setOtherColSelected( index, value )
    {
        let otherCols = this.state.otherCols.slice(0);
        otherCols = otherCols.map(( col, i ) => {
            if( i === index )
                return value;
            return col;
        });
        this.setState({ otherCols: otherCols });
    }

    render() {
        return (
            <div id='sent-po-offer' className='padding'>
                <ApReactTable
                    loading={this.state.loading}
                    data={this.state.data}
                    columns={this.getColumns()}
                    filterable
                    showFiltersInitially
                    rememberId={this.props.sheetId ? 'PdSentOffersOffer' : 'PdSentOffersProject'}
                    minRows={10}
                    rowActions={row => {
                        const approvalActions = [];
                        if (row.status === 'approved') {
                            approvalActions.push(
                                {
                                    icon: 'pause-circle',
                                    label: tr('waiting'),
                                    action: () => { this.handleApproval(row.id, 'waiting') },
                                    closeAfter: true
                                },
                                {
                                    icon: 'times-circle',
                                    label: tr('rejected'),
                                    action: () => { this.handleApproval(row.id, 'rejected') },
                                    closeAfter: true
                                },
                            );
                        }
                        else if (row.status === 'rejected') {
                            approvalActions.push(
                                {
                                    icon: 'check-circle',
                                    label: tr('approved'),
                                    action: () => { this.handleApproval(row.id, 'approved') },
                                    closeAfter: true
                                },
                                {
                                    icon: 'pause-circle',
                                    label: tr('waiting'),
                                    action: () => { this.handleApproval(row.id, 'waiting') },
                                    closeAfter: true
                                },
                            );
                        } else {
                            approvalActions.push(
                                {
                                    icon: 'check-circle',
                                    label: tr('approved'),
                                    action: () => { this.handleApproval(row.id, 'approved') },
                                    closeAfter: true
                                },
                                {
                                    icon: 'times-circle',
                                    label: tr('rejected'),
                                    action: () => { this.handleApproval(row.id, 'rejected') },
                                    closeAfter: true
                                },
                            );
                        }

                        const actions = [
                            ...approvalActions,
                            // {
                            //     icon: 'at',
                            //     label: tr('send_email'),
                            //     action: () => null,
                            //     closeAfter: true
                            // },
                            {
                                icon: 'envelope',
                                label: !row.email_sent ? tr('email_sent_manually') : tr('mark_as_unsent'),
                                action: () => this.handleEmailSent(row.id, !row.email_sent),
                                closeAfter: true
                            },
                            {
                                icon: 'clipboard-check',
                                label: tr('create_purchase_order'),
                                disabled: row.status != 'approved',
                                action: () => this.setState({selectFileForPo: true, selectedOffer: row}),
                                closeAfter: true
                            },
                        ]
                        return <ApDropdown
                            button={ <SvgIcon className="defaultRowActionIcon" icon="ellipsis-h" type="solid" /> }
                            actions={ actions }
                        />
                    }}
                />

                <ApModal 
                    className='narrow'
                    show={Boolean(this.state.selectedOffer && !this.state.selectFileForPo)}
                    handleClose={() => this.setState({selectedOffer: null})}
                    closeFromBg
                    header={this.renderFileAddHeader()}
                    body={this.renderFileAddBody()}
                    footer={this.renderFileAddFooter()}
                />
                
                {/* <ApModal 
                    className='narrow'
                    show={Boolean(this.state.selectFileForPo && this.state.selectedOffer)}
                    handleClose={() => this.setState({selectedOffer: null})}
                    closeFromBg
                    header={this.renderFileAddHeader()}
                    body={this.renderFileAddBody(true)}
                    footer={this.renderFileAddFooter()}
                /> */}
                
                <ApModal 
                    show={Boolean(this.state.selectFileForPo && this.state.selectedOffer)}
                    handleClose={() => this.setState({
                        selectedOffer: null, 
                        selectFileForPo: null, 
                        poModalData: null, 
                        poModalDataRows: [], 
                        poModalDataHeaders: []
                    })}
                    // closeFromBg
                    header={this.renderPoDataTableHeader()}
                    body={this.renderPoDataTableBody()}
                    footer={this.renderPoDataTableFooter()}
                />

                <NewPurchaseOrder
                    show={this.state.newPoShow}
                    onClose={() => { this.setState({ newPoShow: false }) }}
                    data={this.state.newPoData}
                />
            </div>
        )
    }
}

SentPurchaseOrderOffers.propTypes = {
    isOffer: PropTypes.bool,
    projectId: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
	apStorageSettings: state.apStorageSettings
});

export default connect(mapStateToProps)(SentPurchaseOrderOffers);