import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';
import { tr } from 'services/Helpers/Helpers.js';
import './UserPermissions.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import ApUserImage from 'common/ApUserImage/ApUserImage.js';


export default class UserPermissions extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            companies: [],
            modules: [],
            users: [],
            limited: [],
            company_name: null,
            company_id: null,
            onlyActiveUsers: true,
        };
        autoBind(this); 

        this.userColor = "#007E33";
        this.groupColor = "#4285F4";
        this.adminColor = "#aaa";
    }

    componentDidMount()
    {
        this.fetchData();

        if( auth.isGlobalAdmin() ) 
            this.getCompanyList();
    }

    getCompanyList()
    {
        api({
            method: 'get',
            url: 'management/companies',
        }).then((response) => {
            let companies = [];
            response.forEach( comp => {
                companies.push({ value: comp.id, label: comp.name });
            });
            this.setState({ companies });
        });
    }

    changeCompany( id )
    {
        this.setState({ company_id: id }, this.fetchData );
    }

    fetchData()
    {
        let company = this.state.company_id;

        if( !company )
            company = auth.getCompanyID();

        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'usermanagement/company/' + company + '/permissions',
        }).then((response) => {
            //console.log('fetchData', response );
            this.setState({
                modules: response.modules,
                users: response.users,
                limited: response.limited,
                company_name: response.company.name,
                company_id: response.company.id,
                loading: false,
            });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    setPermission( userId, moduleId, granted )
    {
        //console.log( 'TODO: toggle permissions (admin only)', 'user: ' + userId, 'module: ' + moduleId, granted );
    }

    render() 
    {
        return (
            <div className="apBox" id="pageUserPermissions">
                <div className="apBoxHeader">
                    <h1>{ tr('user_permissions') }</h1>
                    <p>{ tr('user_permissions_info') }</p>
                </div>
                <div className="padding">

                    { this.state.companies.length > 0 &&
                        <ApInput
                            type="select"
                            id="company_id"
                            name="company_id"
                            value={ this.state.company_id }
                            options={ this.state.companies }
                            onChange={ ( e ) => this.changeCompany( e.target.value ) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    }

                    <div className="guides">
                        <div className="guide">
                            <SvgIcon fill={ this.userColor } icon="check-circle" type="solid" /> { tr('user_permission') }
                        </div>
                        <div className="guide">
                            <SvgIcon fill={ this.groupColor } icon="check-circle" type="solid" /> { tr('user_group_permission') }
                        </div>
                        <div className="guide">
                            <SvgIcon fill={ this.adminColor } icon="check-circle" type="solid" /> { tr('administrator_permission') }
                        </div>
                        <div className="guide">
                            <SvgIcon fill="rgba(0,0,0,0.1)" icon="circle" /> { tr('no_permission') }
                        </div>
                        <div className="guide">
                            <ApTooltip position="topright" text={ tr('permission_not_possible_note') }>
                                <SvgIcon fill="rgba(0,0,0,0.1)" icon="times-circle" type="solid" /> { tr('not_possible') }
                            </ApTooltip>
                        </div>

                    </div>

                    { this.state.modules.length > 0 &&
                        <div className={ "permissions" + ( this.state.loading ? " loading" : "" ) + ( this.state.onlyActiveUsers ? " onlyActive" : "" ) }>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="permission">

                                            <label className="onlyActiveSwitch" htmlFor="only-active-switch">
                                                <ApSwitch
                                                small
                                                    id="only-active-switch"
                                                    on={ this.state.onlyActiveUsers }
                                                    onChange={ ( e ) => this.setState({ onlyActiveUsers: e.target.checked }) }
                                                    disabled={ this.state.loading }
                                                />
                                                { tr('only_active_users') }
                                            </label>
                                            
                                        </th>
                                        { this.state.users.map( ( user, uIndex ) => {

                                            let classes = [ "user" ];

                                            if( !user.is_active )
                                                classes.push( "notActive" );

                                            else if ( !user.is_loggable  )
                                                classes.push( "notLoggable")

                                            return (
                                                <th key={ uIndex } className={ classes.join(" ") }>
                                                    <ApTooltip position="bottom" text={ 
                                                        <div>
                                                            { user.name }<br />
                                                            { user.title && <small>{ user.title }</small> }

                                                            { !user.is_active && 
                                                                <div style={{ color: "#ef9a9a" }}><SvgIcon className="small-inline" icon="exclamation-circle" type="solid" />{ tr('inactive') }</div> 
                                                            }
                                                            { user.is_active && !user.is_loggable && 
                                                                <div style={{ color: "#ffe0b2" }}><SvgIcon className="small-inline" icon="exclamation-circle" type="solid" />{ tr('invalid') }</div> 
                                                            }
                                                        </div> }
                                                    >
                                                        <ApUserImage className="image" user={ user } block />
                                                        <div className="name">
                                                            { user.name }
                                                        </div>
                                                    </ApTooltip>
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                { this.state.modules.map( ( module, index ) => {
                                    return (
                                        <tr key={ index } className={ "level" + module.depth }>
                                            <td className="permission">
                                                <div style={{ paddingLeft: module.depth + "em" }}>{ tr(module.name).length > 0 ? tr(module.name) : module.name }</div>
                                            </td>
                                            { this.state.users.map( ( user, uIndex ) => {

                                                let classes = [ "user" ];
                                                if( !user.is_active ) classes.push( "notActive" );
                                                else if ( !user.is_loggable  ) classes.push( "notLoggable")

                                                const userHavePermission = user.modules.user.indexOf( module.id ) !== -1;
                                                const groupHavePermission = user.modules.group.indexOf( module.id ) !== -1;
                                                const permissionGranted = user.modules.is_admin || userHavePermission || groupHavePermission;
                                                const limitedCannotHave = ( user.is_limited && !this.state.limited.includes( module.slug_full ) )

                                                return (
                                                    <td key={ uIndex } className={ classes.join(" ") } onClick={ () => this.setPermission( user.id, module.id, !permissionGranted ) }>
                                                        { limitedCannotHave
                                                            ? <SvgIcon fill="rgba(0,0,0,0.1)" icon="times-circle" type="solid" />
                                                            :userHavePermission
                                                                ? <SvgIcon fill={ this.userColor } icon="check-circle" type="solid" />
                                                                : groupHavePermission
                                                                    ? <SvgIcon fill={ this.groupColor } icon="check-circle" type="solid" />
                                                                    : user.modules.is_admin
                                                                        ? <SvgIcon fill={ this.adminColor } icon="check-circle" type="solid" />
                                                                        : <SvgIcon fill="rgba(0,0,0,0.1)" icon="circle" />
                                                        }
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                                <tr className="level0">
                                    <td className="permission">
                                        <div style={{ paddingLeft: module.depth + "em" }}>{ tr('company_settings') }</div>
                                    </td>
                                    { this.state.users.map( ( user, uIndex ) => {

                                        let classes = [ "user" ];
                                        if( !user.is_active ) classes.push( "notActive" );
                                        else if ( !user.is_loggable  ) classes.push( "notLoggable")

                                        return <td key={ uIndex } className={ classes.join(" ") }>
                                            { user.is_admin
                                                ? <SvgIcon fill={ this.adminColor } icon="check-circle" type="solid" />
                                                : <SvgIcon fill="rgba(0,0,0,0.1)" icon="times-circle" type="solid" />
                                            }
                                        </td>
                                    })}
                                </tr>


                                </tbody>
                            </table>
                        </div>
                    }

                </div>
            </div>
        );
    }
}
