import ApButton from 'common/ApButton/ApButton';
import ApModal from 'common/ApModal/ApModal';
import ApReactTable from 'common/ApReactTable/ApReactTable';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import React from 'react';
import autobind from 'react-autobind';
import { errorPopper, tr } from 'services/Helpers/Helpers';
import api from 'services/Api/Api';
import _ from 'lodash'

class ExportProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            components: [],
        };
        autobind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.getData();
        }
    }

    getData() {
        this.setState({loading: true});
        api({
            method: 'get',
            url: 'woocommerce/get/export',
        }).then((response) => {
            // console.log('RES', response);
            this.setState({ components: response, loading: false });
        }).catch((error) => {
            this.setState({ loading: false, components: [] });
            errorPopper(error, tr('get_error'));
        });
    }

    saveExport() {
        const components = _.cloneDeep(this.state.components);

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'woocommerce/export/products',
            data: {
                stocks: components
            }
        }).then((response) => {
            // console.log('RES', response);
            this.setState({ loading: false });
            this.onClose(true);
        }).catch((error) => {
            this.setState({ loading: false });
            errorPopper(error, tr('get_error'));
        });
    }

    removeComponent(id) {
        const components = _.cloneDeep(this.state.components).filter(c => c.id !== id);

        this.setState({components});
    }

    renderBody() {
        return <div className='padding'>
            <ApReactTable
                loading ={this.state.loading}
                data={this.state.components}
                defaultPageSize={10}
                columns={[
                    {
                        Header: tr('name'),
                        accessor: 'component.name',
                        sortable: true,
                        filterable: false,
                        customizable: false,
                        showInitially: true,
                    },
                    {
                        Header: tr('storage_location'),
                        accessor: 'location.name',
                        Cell: props => {
                            return <>
                                {props.original.location.name} <br/>
                                <small>{props.original.location.code}</small>
                            </>
                        },
                        sortable: true,
                        filterable: false,
                        customizable: false,
                        showInitially: true,
                    },
                ]}
                rowActions={[
                    {
                        icon: "trash",
                        label: tr('delete'),
                        action: this.removeComponent,
                        closeAfter: true
                    }
                ]}

            />
        </div>
    }

    renderFooter() {
        return <div className='padding justify-space-between'>
            <ApButton onClick={this.onClose}>
                <SvgIcon type="solid" icon="times" /> {tr('back')}
            </ApButton>

            <ApButton color="blue" disabled={this.state.loading || !this.state.components.length} onClick={this.saveExport}>
                <SvgIcon type="solid" icon="save" /> {tr('save')}
            </ApButton>
        </div>
    }

    onClose(refresh) {
        this.setState({ components: [] });
        if (typeof this.props.onClose === 'function') {
            this.props.onClose(refresh);
        }
    }

    render() {
        return (
            <div id='exportModal'>
                <ApModal
                    className='narrow'
                    show={this.props.show}
                    onClose={this.onClose}
                    handleClose={this.onClose}
                    header={<h4 style={{ padding: '10px' }}>{tr('export_storage_components_online_store')}</h4>}
                    body={this.renderBody()}
                    footer={this.renderFooter()}
                />
            </div>
        )
    }
}

export default ExportProductModal;