import React from 'react';
import autobind from 'react-autobind';
import ApReactTable from "common/ApReactTable/ApReactTable";
import SvgIcon from "common/SvgIcon/SvgIcon";
import { errorPopper, keyExists, tr, formatCurrency } from "services/Helpers/Helpers";
import api from 'services/Api/Api';
import axios from "axios";
import ApFormPage from 'common/ApFormPage/ApFormPage';
import moment from 'moment';
import _ from 'lodash';

import './WooCommerceOrder.css';
import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit';
import ApModalInput from 'common/ApModalInput/ApModalInput';

const statusOptions = [
    { value: '', label: '' },
    { value: 'processing', label: tr('processing') },
    { value: 'on-hold', label: tr('on-hold') },
    { value: 'cancelled', label: tr('cancelled') },
    { value: 'refunded', label: tr('refunded') },
    { value: 'pending', label: tr('pending') },
    { value: 'failed', label: tr('failed') },
    { value: 'completed', label: tr('completed') },
].sort((a,b) => {
    const aLabel = a.label,
    bLabel = b.label;
    if (aLabel < bLabel) return -1;
    if (aLabel > bLabel) return 1;
    return 0;
});

class WooCommerceOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            unsaved: false,
            data: {},
            editStatus: false,
        }
        autobind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        const id = keyExists(this.props, 'match.params.id', true, null);
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'woocommerce/orders/' + id,
        }).then((response) => {
            // console.log('RES', response);
            this.setState({ data: response.data, loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
            errorPopper(error, tr('get_error'));
        });
    }

    editStatus(status) {
        const id = this.state.data.id
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'woocommerce/orders/editstatus',
            data: {status, id}
        }).then((response) => {
            // console.log('RES', response);
            const data = _.cloneDeep(this.state.data);
            data.status = status;
            this.setState({ loading: false, editStatus: false, data });
            errorPopper(null, tr('saved'), 4000, 'success');
        }).catch((error) => {
            this.setState({ loading: false });
            errorPopper(error, tr('get_error'));
        });
    }

    renderSummary() {
        return <div className="storageSummaryBox">
            <div className="apForm">
                <div className="apFormColumn">
                    <dl className="dl-horizontal">
                        <dt className={this.state.number ? '' : 'noNumber'}>{tr('number')}</dt>
                        <dd>{this.state.data.number}</dd>
                    </dl>

                    <dl className="dl-horizontal">
                        <dt>{tr('orderer')}</dt>
                        <dd>{keyExists(this.state, 'data.billing.company', true, '')}</dd>
                        <dd>{keyExists(this.state, 'data.billing.last_name', true, '')} {keyExists(this.state, 'data.billing.first_name', true, '')}</dd>
                    </dl>

                    <dl className="dl-horizontal">
                        <dt>{tr("billing_address")}</dt>
                        {this.state.data.billing &&
                            <>
                                <dd>{this.state.data.billing.address_1} {this.state.data.billing.address_2}</dd>
                                <dd>{this.state.data.billing.postcode}</dd>
                                <dd>{this.state.data.billing.city}</dd>
                                <dd>{this.state.data.billing.email}</dd>
                                <dd>{this.state.data.billing.phone && <>{tr('phone')}: {this.state.data.billing.phone}</>}</dd>
                            </>
                        }
                    </dl>

                    <dl className="dl-horizontal">
                        <dt>{tr("shipping_address")}</dt>
                        {this.state.data.shipping &&
                            <>
                                <dd>{this.state.data.shipping.address_1} {this.state.data.shipping.address_2}</dd>
                                <dd>{this.state.data.shipping.postcode}</dd>
                                <dd>{this.state.data.shipping.city}</dd>
                                <dd>{this.state.data.shipping.email}</dd>
                                <dd>{this.state.data.shipping.phone && <>{tr('phone')}: {this.state.data.shipping.phone}</>}</dd>
                            </>
                        }
                    </dl>
                </div>

                <div className="apFormColumn">
                    <dl className="dl-horizontal">
                        <dt>{tr('billing_method')}</dt>
                        <dd>{this.state.data.payment_method_title}</dd>
                    </dl>

                    <dl className="dl-horizontal">
                        <dt>{tr('date')}</dt>
                        <dd>{this.state.data.date_created ? moment(this.state.data.date_created).format("L") : null}</dd>
                    </dl>

                    <dl className="dl-horizontal">
                        <dt>{tr('price_total')}</dt>
                        <dd> {formatCurrency(this.state.data.total || 0)}</dd>
                    </dl>

                    <dl className="dl-horizontal">
                        <dt>{tr('additional_info')}</dt>
                        <dd>{this.state.data.customer_note}</dd>

                    </dl>
                    
                    <dl className="dl-horizontal pointer" onClick={() => {
                        if (this.state.loading) return;
                        this.setState({editStatus: true});
                    }}>
                        <dt>{tr('status')}</dt>
                        <dd>{this.state.data.status && tr(this.state.data.status)}</dd>
                    </dl>
                </div>
            </div>
        </div>
    }

    render() {
        const sortedComponents = _.sortBy(this.state.data.line_items, 'name');
        if (this.state.data.shipping_lines && this.state.data.shipping_lines.length) {
            sortedComponents.push({
                name: this.state.data.shipping_lines[0].method_title,
                quantity: 1,
                price: this.state.data.shipping_lines[0].total,
                type_name: 'shipping',
            })
        }
        return (
            <div id='woocommerce-order'>
                <div className="pageBackButton" onClick={() => window.emitter.emit('goTo', { pathname: '/storage/orders/online_store' })}>
                    <SvgIcon icon="arrow-left" type="solid" size="small" />
                    {tr('back')}
                </div>
                <ApFormPage
                    loading={this.state.loading}
                    unsaved={this.state.unsaved}
                    customFooter={() => null}
                >
                    <div className='apBoxHeader'>
                        <h1>
                            {tr('order')}
                        </h1>
                    </div>
                    <div className='padding'>
                        <div className="summaryBlock">
                            {this.renderSummary()}
                        </div>
                        <ComponentInstanceTable
                            componentsChange={ () => null }
                            loading={ this.state.loading }
                            components={ sortedComponents || [] }

                            instance={ 'woocommerce_order' }

                            removeComponentLocked={ true }
                            addComponentLocked={ true }
                            nameLocked={ true }
                        />
                    </div>
                </ApFormPage>
                <ApModalInput                     
                    show={ Boolean(this.state.editStatus) }
                    value={ this.state.data.status }
                    onSave={ ( value ) => this.editStatus( value ) }
                    onClose={ () => this.setState({ editStatus: false }) }
                    title={ tr('status') }
                    label={tr('status')}
                    type='select'
                    options={statusOptions}
                    required
                    doesSave
                />
            </div>
        )
    }
}

export default WooCommerceOrder;