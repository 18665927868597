import { ApAddon, ApInput, ApInputStack } from "common/ApInput/ApInput";
import ApOptionInput from "common/ApOptionInput/ApOptionInput";
import React from "react";
import autoBind from "react-autobind";
import { errorPopper, getExcel, tr } from "services/Helpers/Helpers";
import api from "services/Api/Api";
import ApButton from "common/ApButton/ApButton";
import SvgIcon from "common/SvgIcon/SvgIcon";
import ApReactTable, { colPreset } from "common/ApReactTable/ApReactTable";
import moment from "moment";
import PropTypes from "prop-types";
import ApSelect from "common/ApSelect/ApSelect";

class WorkloadReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            startDate: null,
            endDate: null,
            simpleReportData: [],
            fullReportData: [],
            users: [],
            type: localStorage.getItem('workload-report-type') || 'simple',
        };
        autoBind(this);
    }

    handleChange(e) {
        if (e.target.name === 'type') {
            // set value to localstorage
            localStorage.setItem('workload-report-type', e.target.value);
        }
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    getData() {
        if (!this.state.startDate) {
            errorPopper(null, tr('start_date_mandatory'));
            return;
        }
        if (!this.state.endDate) {
            errorPopper(null, tr('end_date_mandatory'));
            return;
        }

        let url = `project/report/workload/${this.state.startDate}/${this.state.endDate}`;
        let method = 'POST';
        if (this.props.currentUserReport) {
            url = `currentUser/reports/workload/${this.state.startDate}/${this.state.endDate}`;
            method = 'GET';
        }

        this.setState({ loading: true });
        api({
            method,
            url,
            data: {
                user_ids: this.state.users.map(user => user.id)
            }
        }).then(response => {
            const fullReportData = this.getFullReportData(response.data);
            this.setState({ loading: false, simpleReportData: response.data, fullReportData });
        }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            errorPopper(error, tr('get_error'));
        })
    }

    getFullReportData(data) {
        const workloadTasks = [];
        data.forEach(entry => {
            entry.workload_tasks.forEach(task => {
                workloadTasks.push({
                    last_name: entry.last_name,
                    first_name: entry.first_name,
                    ...task,
                });
            });
        });

        return workloadTasks;
    }

    getColumns() {
        const cols = [
            {
                Header: tr('person'),
                accessor: 'last_name',
                customFilter: {
                    type: 'text',
                    placeholder: tr('person'),
                    fields: ['_original.last_name', '_original.first_name']
                },
                Cell: row => <div>{row.original.last_name} {row.original.first_name}</div>,
                original_key: 'name',
            },
        ];

        if (this.state.type === 'full') {
            cols.push(
                {
                    Header: tr('project'),
                    accessor: 'project',
                    customFilter: {
                        type: 'text',
                        placeholder: tr('project'),
                        fields: ['_original.project.name', '_original.project.project_code']
                    },
                    Cell: row => <div>
                        {row.original.project ? row.original.project.name : ''}
                        <small style={{ display: 'block' }}>{row.original.project ? row.original.project.project_code : ''}</small>
                    </div>,
                    original_key: 'project',
                    customizable: true,
                    showInitially: true,
                },
                {
                    Header: tr('assignment'),
                    accessor: 'assignment.name',
                    customFilter: {
                        type: 'text',
                        placeholder: tr('assignment'),
                        fields: ['_original.assignment.name']
                    },
                    original_key: 'assignment',
                    customizable: true,
                    showInitially: true,
                },
                {
                    Header: tr('customer'),
                    accessor: 'customer.name',
                    customFilter: {
                        type: 'text',
                        placeholder: tr('customer'),
                        fields: ['_original.customer.name']
                    },
                    original_key: 'customer',
                    customizable: true,
                    showInitially: true,
                },
                colPreset({
                    type: 'datetime',
                    Header: tr('start_time'),
                    accessor: 'start',
                    filterable: false,
                    original_key: 'start',
                    customizable: true,
                    showInitially: true,
                }),
                colPreset({
                    type: 'datetime',
                    Header: tr('end_time'),
                    accessor: 'end',
                    filterable: false,
                    original_key: 'end',
                    customizable: true,
                    showInitially: true,
                }),
            );
        }

        if (this.state.type === 'simple') {
            cols.push(
                colPreset({
                    type: 'date',
                    Header: tr('time_period'),
                    accessor: 'time_period',
                    filterable: false,
                    original_key: 'time_period',
                }),
            );
        }

        cols.push(colPreset({
            type: 'numberNice',
            unit: 'h',
            decimals: 2,
            Header: tr('hours'),
            accessor: 'workload_hours',
            customFilter: {
                type: 'text',
                placeholder: tr('hours')
            },
            original_key: 'workload_hours',
        }));

        return cols;
    }

    async getExcelFile(selectedRowIndexes, createPdf = false) {
        const typeKey = this.state.type === 'simple' ? 'simpleReportData' : 'fullReportData';
        if (!this.state[typeKey].length) return;

        let data = [...this.state[typeKey]];
        if (selectedRowIndexes.length) {
            data = data.filter((row, index) => selectedRowIndexes.includes(index));
        }

        let ref = null;
        if (this.state.type === 'simple') {
            ref = this.reactTableSimple;
        } else if (this.state.type === 'full') {
            ref = this.reactTableFull;
        }

        const visibleColumns = ref.getVisibleColumns();

        const excelData = data.map(row => {
            const data = {};
            visibleColumns.forEach(col => {
                switch (col.original_key) {
                    case 'name':
                        data.name = `${row.last_name} ${row.first_name}`;
                        break;
                    case 'project':
                        data.project = row.project ? `${row.project.name} ${row.project.project_code}` : '';
                        break;
                    case 'assignment':
                        data.assignment = row.assignment ? row.assignment.name : '';
                        break;
                    case 'customer':
                        data.customer = row.customer ? row.customer.name : '';
                        break;
                    case 'start':
                        data.start_time = row.start ? moment(row.start).format('DD.MM.YYYY HH:mm:ss') : '';
                        break;
                    case 'end':
                        data.end_time = row.end ? moment(row.end).format('DD.MM.YYYY HH:mm:ss') : '';
                        break;
                    case 'time_period':
                        data.time_period = row.time_period;
                        break;
                    case 'workload_hours':
                        data.hours = row.workload_hours;
                        break;
                    default:
                        break;
                }
            });
            return data;
        });

        this.setState({ loading: true });
        await getExcel(
            excelData,
            `${tr('planned_workload')} ${moment(this.state.startDate).format('DD.MM.YYYY')} - ${moment(this.state.endDate).format('DD.MM.YYYY')}`,
            tr('planned_workload'),
            { createPdf, fileName: 'planned_workload' },
        );
        this.setState({ loading: false });
    }

    render() {
        const multiselectActions = [
            {
                icon: 'file-pdf',
                label: tr('create_pdf'),
                action: selectedRows => this.getExcelFile(selectedRows, true),
                unselectAfter: false,
                closeAfter: true,
                alwaysOn: true,
            },
            {
                icon: 'file-excel',
                label: tr('create_excel'),
                action: this.getExcelFile,
                unselectAfter: false,
                closeAfter: true,
                alwaysOn: true,
            },
        ];
        return <div className="padding" id="workload-report">
            {!this.props.currentUserReport && <p>{tr('planned_workload_info')}</p>}
            <ApInputStack gap="0">
                <ApAddon width="200px">{tr('type')}</ApAddon>
                <div style={{ width: "200px" }}>
                    <ApInput
                        type='select'
                        options={[
                            { label: tr('simple'), value: "simple" },
                            { label: tr('full'), value: "full" },
                        ]}
                        onChange={this.handleChange}
                        id="type"
                        name="type"
                        value={this.state.type}
                    />
                </div>
            </ApInputStack>
            <div className="date-selection">
                <ApInputStack collapseAt={400} gap="0">
                    <ApInput
                        id="startDate"
                        name="startDate"
                        type="datetimeV2"
                        label={tr('start_date')}
                        value={this.state.startDate}
                        onChange={e => this.handleChange({ target: { value: e, name: 'startDate' } })}
                        weekNumbers={true}
                    />
                    <ApAddon width="20" noLeftBorder noRightBorder> - </ApAddon>
                    <ApInput
                        id="endDate"
                        name="endDate"
                        type="datetimeV2"
                        label={tr('end_date')}
                        value={this.state.endDate}
                        onChange={e => this.handleChange({ target: { value: e, name: 'endDate' } })}
                        weekNumbers={true}
                    />
                </ApInputStack>
                <ApInputStack gap="0">
                    <ApAddon custom gapLeft="10">
                        <ApOptionInput
                            value={[this.state.startDate, this.state.endDate]}
                            onChange={(values) => { this.setState({ startDate: values[0], endDate: values[1] }, this.getEntries) }}
                            options={["month", "month+1", "month+2", "month+3"]}
                        />
                        <ApOptionInput
                            value={[this.state.startDate, this.state.endDate]}
                            onChange={(values) => { this.setState({ startDate: values[0], endDate: values[1] }, this.getEntries) }}
                            options={["week", "week+1", "week+2", "week+3"]}
                        />
                    </ApAddon>
                </ApInputStack>
            </div>
            {!this.props.currentUserReport &&
            <ApInputStack gap="0">
                <ApAddon width="200px">{tr('persons')}</ApAddon>
                <ApSelect
                    value={this.state.users}
                    multiselect={true}
                    onChange={users => this.setState({ users })}
                    apiUrl='search/user'
                    apiData={{
                        subordinates_only: true,
                        is_active: true,
                        active_contracts_only: true
                    }}
                    label={tr('persons')}
                    optionRenderer="user"
                    objKeyId="id"
                    objKeyValue="name"
                    clearable={true}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    filterNonActives={true}
                    valueRenderer="user"
                    objKeySearchable="user"
                    keepFocusOnSelect={true}
                />
            </ApInputStack>}
            <ApButton color='green' size="small" onClick={this.getData} disabled={this.state.loading}>
                <SvgIcon type="solid" icon="search" /> {tr('search')}
            </ApButton>
            {this.state.type === 'simple' &&
                <ApReactTable
                    ref={r => this.reactTableSimple = r}
                    data={this.state.simpleReportData}
                    columns={this.getColumns()}
                    rememberId="workload-report-simple"
                    loading={this.state.loading}
                    filterable
                    showFiltersInitially
                    multiselect={multiselectActions}
                />}
            {this.state.type === 'full' &&
                <ApReactTable
                    ref={r => this.reactTableFull = r}
                    data={this.state.fullReportData}
                    columns={this.getColumns()}
                    rememberId="workload-report-full"
                    loading={this.state.loading}
                    filterable
                    showFiltersInitially
                    multiselect={multiselectActions}
                />}
        </div>;
    }
}

WorkloadReport.propTypes = {
    currentUserReport: PropTypes.bool
};

export default WorkloadReport;