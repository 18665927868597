/* eslint-disable eqeqeq */
import React        from 'react';
import autoBind     from 'react-autobind';
import PropTypes    from 'prop-types';
import Dropzone     from 'react-dropzone'
import axios        from 'axios';

import api          from 'services/Api/Api.js';
import { getImageUrl, tr } from 'services/Helpers/Helpers.js';

import './ApImageUpload.css';

import SvgIcon      from 'common/SvgIcon/SvgIcon.js';

class ApImageUpload extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            loading: false,
            progress: false,
            error: false,
            cancelToken: axios.CancelToken.source()
        }

        autoBind(this); 
    } 

    onDrop( acceptedFiles, rejectedFiles )
    {
        if( this.state.loading )
            return false;

        if( acceptedFiles.length > 0 )
        {
            this.uploadStart( acceptedFiles[0] )
        }
        else 
        {
            this.setState({ error: "Virheellinen tiedostotyyppi" });
        }
    }

    uploadStart( file )
    {
        this.setState({ 
            loading: true,
            progress: 0
         });

        const onUploadProgress = (e) => {
            const progress = Math.round( ( e.loaded / e.total ) * 100 );
            this.setState({ progress });
        };

        let data = new FormData();
        data.append('filedata', file );

        if( this.props.description )
            data.append( 'description', this.props.description );

        if( this.props.keep )
            data.append( 'keep', true );

        api({
            cancelToken: this.state.cancelToken.token,
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'post',
            url: 'file/upload',
            data: data,
            onUploadProgress: onUploadProgress,
        }).then((response) => {
            
            if( typeof( this.props.onChange ) == "function" )
                this.props.onChange( response );

            this.setState({ 
                loading: false,
                progress: false
             });

        }).catch( error => {
            console.error('Upload error', error );
            this.setState({ 
                error: "Virhe tiedoston latauksessa",
                loading: false,
                progress: false
             });
        });
    }

    clickHandler()
    {
        if( !this.props.fileId && this.dropzoneRef )
            this.dropzoneRef.open();
    }


    render()
    {
        let classes = [ "apImageUpload" ];
        let previewStyle = {};

        if( this.state.loading )
            classes.push( "loading" );

        if( this.props.disabled )
            classes.push( "disabled" );

        if( ["error", "warning", "success"].indexOf( this.props.validationState ) != -1 )
            classes.push( "validator-" + this.props.validationState );
        
        if( this.props.fileId )
            previewStyle = {
                backgroundImage: "url('" + getImageUrl( this.props.fileId ) + "')"
            };

        return (
        	<div className={ classes.join(" ") }>
                
                { this.props.label &&
                    <div className="apLabel">{ this.props.label }</div>
                }

                <Dropzone 
                    ref={ node => this.dropzoneRef = node }
                    className="apDropzone"
                    activeClassName="active"
                    acceptClassName="accept"
                    rejectClassName="rejected"
                    disableClick
                    disablePreview={true}
                    accept={ this.props.accept ? this.props.accept : "image/jpeg, image/png" }
                    onDrop={ this.onDrop }
                    disabled={ this.props.disabled || this.state.loading || this.props.readOnly }
                >   

                    { this.state.loading && 
                        <div className="apLoader" />
                    }

                    <div className="uploadProgress" style={{ width: this.state.progress ? this.state.progress : 0 + "%" }}></div>                    

                    <div className="preview" style={ previewStyle } onClick={ this.clickHandler }>
                        { !this.props.fileId && 
                            <div className="noImage">
                                <SvgIcon icon="file-image" type="solid" />
                                { !this.props.readOnly ? tr('add_picture') : tr('no_picture') }<br />
                                { !this.props.readOnly && <small>{ tr('file_upload_note') }</small> }
                            </div>
                        }
                    </div>

                    { this.props.fileId && !this.props.readOnly && this.dropzoneRef &&
                        <div className="changeButton" onClick={ () => this.dropzoneRef.open() }>
                            <SvgIcon className="small-inline" icon="file-upload" type="solid" /> 
                            { tr('change_picture') }
                        </div>
                    }

                    { this.props.fileId && !this.props.readOnly && typeof( this.props.onClear ) == "function" && 
                        <div className="clearButton" onClick={ this.props.onClear }>
                            <SvgIcon className="small-inline" icon="trash-alt" type="solid" />
                            { tr('clear') }
                        </div>
                    }

                </Dropzone>
        	</div>
        );
    }
};


ApImageUpload.propTypes = {
    onChange:           PropTypes.func.isRequired,
    onClear:            PropTypes.func,
    fileId:             PropTypes.number,
    accept:             PropTypes.string,
    label:              PropTypes.string,
    keep:               PropTypes.bool,
    description:        PropTypes.string,
    disabled:           PropTypes.bool,
    readOnly:           PropTypes.bool,
    validationState:    PropTypes.string,
    /*
    
    
    files:              PropTypes.array,
    keep:               PropTypes.boolean,
    description:        PropTypes.string,
    maxFiles:           PropTypes.number,
    maxSize:            PropTypes.number,
    showRemoved:        PropTypes.boolean,
    returnRemoved:      PropTypes.boolean,
    disabled:           PropTypes.bool,
    hideTopbar:         PropTypes.bool,
    addButtonLabel:     PropTypes.string,
    noFilesLabel:       PropTypes.string,
    noFilesSubLabel:    PropTypes.string,
    noFilesTopMessage:  PropTypes.string,
    customNoFiles:      PropTypes.node,
    customFiles:        PropTypes.node,
    customAddButton:    PropTypes.node,
    validationState:    PropTypes.string,
    */
};

export default ApImageUpload;

