import ApButton from 'common/ApButton/ApButton';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';
import ApReactTable, { colPreset } from 'common/ApReactTable/ApReactTable';
import ApTooltip from 'common/ApTooltip/ApTooltip';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import { useEffect, useRef, useState } from 'react';
import api from 'services/Api/Api';
import { errorPopper, getExcel, mapTree, tr } from 'services/Helpers/Helpers';

const allowedTypes = ['storage_location', 'storage_component'];

const StorageValue = (props) => {
    const currentDate = new Date().toISOString().split('T')[0];
    let savedType = localStorage.getItem('storageValue_type');
    if (savedType && allowedTypes.indexOf(savedType) === -1) {
        savedType = null;
    }
    let savedCumulative = localStorage.getItem('storageValue_cumulative');
    if (savedCumulative !== 'true' && savedCumulative !== 'false') {
        savedCumulative = null;
    }
    const [date, setDate] = useState(currentDate);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [type, setType] = useState(savedType ? savedType : 'storage_location');
    const [parsedData, setParsedData] = useState([]);
    const [selectedDetailCumulative, setSelectedDetailCumulative] = useState(savedCumulative ? savedCumulative : true);
    const reactTableRef = useRef();

    const parseData = () => {
        const parsedData = [];
        let totalValue = 0;
        let totalWithBilling = 0;
        let totalWithoutBilling = 0;
        if (type === 'storage_location') {
            const key = selectedDetailCumulative ? 'all' : 'own';
            mapTree(data, (item) => {
                parsedData.push({
                    name: item.code + " - " + item.name,
                    value: item.calculated[key].stock_value,
                    value_total: item.calculated[key].not_billed_value + item.calculated[key].stock_value,
                    without_billing: item.calculated[key].not_billed_value,
                });
                totalValue += Number(item.calculated[key].stock_value);
                totalWithBilling += Number(item.calculated[key].not_billed_value) + Number(item.calculated[key].stock_value);
                totalWithoutBilling += Number(item.calculated[key].not_billed_value);
                return item;
            });
        } else if (type === 'storage_component') {
            // full report shows the value of every single stock
            mapTree(data, (item) => {
                item.stocks.forEach((stock) => {
                    totalValue += Number(stock.stock_value);
                    totalWithBilling += Number(stock.stock_value) + Number(stock.component_install_price) * Number(stock.installed_count);
                    totalWithoutBilling += Number(stock.component_install_price) * Number(stock.installed_count);
                    parsedData.push({
                        name: item.name,
                        value: stock.stock_value,
                        storage_component: stock.component_name,
                        balance: stock.balance_after,
                        installed_count: stock.installed_count,
                        component_install_price: stock.component_install_price,
                        unit: stock.unit,
                        value_total: Number(stock.stock_value) + Number(stock.component_install_price ?? 0) * Number(stock.installed_count ?? 0),
                        without_billing: Number(stock.component_install_price) * Number(stock.installed_count),
                    });
                });
                return item;
            });
        }
        if (parsedData.length > 0 && data[0].children?.length > 0 && (!selectedDetailCumulative || type === 'storage_component')) {
            parsedData.unshift({
                name: tr('in_total'),
                value: totalValue,
                storage_component: '',
                balance: '',
                unit: '',
                value_total: totalWithBilling,
                without_billing: totalWithoutBilling,
            });
        }
        setParsedData(parsedData);
    }

    // clear data if location changes
    useEffect(() => {
        setData([]);
    }, [props.locationId]);

    // parse data when report type changes
    useEffect(() => {
        parseData();
    }, [type]);

    // parse data when data changes or cumulative changes
    useEffect(() => {
        parseData();
    }, [data, selectedDetailCumulative]);

    const getColumns = () => {
        const columns = [{
            Header: tr('storage_location'),
            accessor: 'name',
        }];

        if (type === 'storage_component') {
            columns.push(
                {
                    Header: tr('storage_component'),
                    accessor: 'storage_component',
                    id: 'storage_component',
                },
                colPreset({
                    Header: tr('balance'),
                    accessor: 'balance',
                    type: 'number',
                    unit: r => r.unit,
                    customizable: true,
                    showInitially: true,
                    id: 'balance',
                })
            );
        }

        columns.push(colPreset({
            Header: tr('value'),
            accessor: 'value',
            type: 'currency',
            customizable: true,
            showInitially: true,
            id: 'value',
        }));

        if (type === 'storage_component') {
            columns.push(
                colPreset({
                    Header: tr('without_billing'),
                    accessor: 'installed_count',
                    type: 'numberNice',
                    unit: r => r.unit,
                    customizable: true,
                    showInitially: true,
                    id: 'installed_count',
                }),
            );
        }

        columns.push(
            colPreset({
                id: 'without_billing_value',
                Header: tr('without_billing') + ' (€)',
                // accessor: row => (row.installed_count ?? 0) * (row.component_install_price ?? 0),
                accessor: 'without_billing',
                type: 'currency',
                customizable: true,
                showInitially: true,
            }),
            colPreset({
                Header: tr('in_total'),
                accessor: 'value_total',
                type: 'currency',
                customizable: true,
                showInitially: true,
                id: 'value_total',
            })
        );

        return columns;
    }

    const calculateOwnAndAll = (response) => {
        const tree = mapTree(response, null, item => {
            let stockValue = 0;
            let notBilled = 0;

            item.stocks.forEach((stock) => {
                stockValue += parseFloat(stock.stock_value ?? 0);
                notBilled += parseFloat((stock.component_install_price ?? 0) * (stock.installed_count ?? 0));
            });

            let own = {
                stock_value: stockValue,
                not_billed_value: notBilled,
                value_total: stockValue + notBilled,
            };

            // Clone own stuff to total stuff
            let all = JSON.parse(JSON.stringify(own));

            item.children.forEach((child) => {
                if (child.calculated) {
                    all.stock_value += child.calculated.all.stock_value;
                    all.not_billed_value += child.calculated.all.not_billed_value;
                    all.value_total += child.calculated.all.value_total;
                }
            });

            item.calculated = {
                own: own,
                all: all,
            };
            return item;
        });
        return tree;
    }

    const getStorageValue = () => {
        if (isNaN(props.locationId)) return;
        setLoading(true);
        api({
            method: 'post',
            url: 'storage/locations/reports/storage-value',
            params: {
                date: date,
                location_id: props.locationId
            }
        }).then((response) => {
            setLoading(false);
            // console.log(response);
            if (response) {
                const tree = calculateOwnAndAll(response);
                setData(tree);
                // console.log(tree[0].calculated.all.stock_value);
            }
        }).catch((error) => {
            setLoading(false);
            console.error(error);
            errorPopper(error, tr('get_error'));
        })
    }

    const tableCellStyle = {
        border: "1px solid #000",
        padding: "5px",
    };
    const rows = [];

    mapTree(data, (item) => {
        rows.push(
            <tr key={item.id}>
                <td style={tableCellStyle}>{item.code} {item.name}</td>
                <td style={{ ...tableCellStyle, textAlign: 'right' }}>
                    {isNaN(item.calculated.all.stock_value)
                        ? '0.00'
                        : parseFloat(item.calculated.all.stock_value).toFixed(2)} €
                </td>
            </tr>
        );
        return item;
    });

    const handleTypeChange = (e) => {
        const type = e.target.value;
        setType(type);
        localStorage.setItem('storageValue_type', type);
    }

    const handleSelectedCumulative = () => {
        setSelectedDetailCumulative(!selectedDetailCumulative);
        localStorage.setItem('storageValue_cumulative', !selectedDetailCumulative);
    }

    const getExcelFile = async () => {
        const visibleColumns = reactTableRef.current.getVisibleColumns();

        const excelData = parsedData.map((row) => {
            if (type === 'storage_location') {
                const data = {};
                data.name = row.name; // Always included in excel
                visibleColumns.forEach((column) => {
                    switch (column.id) {
                        case 'value':
                            data[column.id] = parseFloat((row[column.id] || 0).toFixed(2));
                            break;
                        case 'value_total':
                            data['in_total'] = parseFloat((row[column.id] || 0).toFixed(2));
                            break;
                        default:
                            break;
                    }
                });
                return data;
            } else if (type === 'storage_component') {
                const data = {};
                data.name = row.name;
                data.storage_component = row.storage_component;
                visibleColumns.forEach((column) => {
                    switch (column.id) {
                        case 'balance':
                        case 'value':
                            data[column.id] = parseFloat((row[column.id] || 0));
                            break;
                        case 'installed_count':
                            data['without_billing'] = Number(row[column.id]);
                            break;
                        case 'without_billing_value':
                            data['without_billing_e'] = parseFloat((Number(row['installed_count']) || 0) * 
                                (Number(row['component_install_price'] || 0)).toFixed(2));
                            break;
                        case 'value_total':
                            data['in_total'] = parseFloat((row[column.id] || 0).toFixed(2));
                            break;
                        default:
                            break;
                    }
                });
                return data;
            }
        });
        setLoading(true);
        try {
            await getExcel(
                excelData,
                `${tr('storage_value')} ${date} - ${data[0].name}`,
                tr('storage_value'),
                { createPdf: false, fileName: 'storage_value' },
            );
        } catch (error) {
            console.error(error);
            errorPopper(error, tr('get_error'));
        }
        setLoading(false);
    }

    const multiselectActions = [{
        icon: 'file-excel',
        label: tr('create_excel'),
        action: getExcelFile,
        unselectAfter: false,
        closeAfter: true,
        alwaysOn: true,
    }];

    return (
        <div>
            <h4>{tr('components_price')}</h4>
            <div style={{ width: '350px' }}>
                <ApInputStack gap="0">
                    <ApAddon style={{ padding: '0' }} noRightBorder width="50px">
                        <ApTooltip
                            block
                            text={selectedDetailCumulative ? tr('include_sub_storages') : tr('not_include_sub_storages')}
                            position='topleft'
                        >
                            <div className="apSimpleButton" style={{ width: '50px', height: '42px' }} onClick={handleSelectedCumulative}>
                                <SvgIcon
                                    icon={selectedDetailCumulative ? "folder-open" : "folder"}
                                    type="solid"
                                />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                    <ApAddon>
                        {tr('type')}
                    </ApAddon>
                    <ApInput
                        type="select"
                        options={allowedTypes.map((type) => {
                            return { value: type, label: tr(type) };
                        })}
                        name="type"
                        id="type"
                        onChange={(e) => handleTypeChange(e)}
                        value={type}
                        width="250"
                        label={tr('type')}
                        loading={loading}
                    />
                </ApInputStack>
            </div>
            <ApInputStack gap="0">
                <ApInput
                    type="datetimeV2"
                    name="toDate"
                    id="toDate"
                    onChange={(selectedDate) => setDate(selectedDate)}
                    value={date}
                    weekNumbers={true}
                    width="250"
                    label={tr('to_date')}
                    loading={loading}
                />
                <ApButton
                    color="green"
                    onClick={() => {
                        getStorageValue();
                    }}
                    disabled={loading}
                >
                    <SvgIcon icon="search" type="solid" /> {tr('search')}
                </ApButton>
            </ApInputStack>
            <ApReactTable
                ref={reactTableRef}
                data={parsedData}
                columns={getColumns()}
                loading={loading}
                multiselect={multiselectActions}
                rememberId={"storageValueReport-" + type}
            />
        </div>
    );
};

export default StorageValue;
