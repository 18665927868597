import React from 'react';
import autoBind from 'react-autobind';

import SvgIcon       from 'common/SvgIcon/SvgIcon.js';
import ApButton      from 'common/ApButton/ApButton.js';
import ApTooltip     from 'common/ApTooltip/ApTooltip.js';
import { tr } from 'services/Helpers/Helpers.js';
import './SaveBox.css';

class SaveBox extends React.Component {

    constructor(props)
    {
        super(props);
        autoBind(this);
    }

    getButtons()
    {
        const  status = this.props.status;
        let buttons = [];

        const filesBtn = {
            action:'show_attachments',
            text: tr('attachments'),
            icon: 'paperclip',
            color: 'green',
        };
        const addFileBtn = () => {
            if (this.props.isImported) {
                buttons.unshift(filesBtn)
            }
        }

        if( this.props.archived )
        {
            buttons.push({
                action: 'create_order',
                text: tr('create_sales_order'),
                icon: 'paper-plane',
                color: 'green',
            });
            
            buttons.push({
                action: 'archive',
                text: tr('cancel_archive'),
                icon: 'box-open',
                color: 'red',
            });

        }
        else if( status === 'open' )
        {
            if( this.props.number )
            {
                buttons.push({
                    action: 'archive',
                    text: tr('archive'),
                    icon: 'archive',
                    color: 'red',
                });
            }
            else
            {
                buttons.push({
                    action: 'remove',
                    text: tr('delete'),
                    icon: 'trash',
                    color: 'red',
                });
            }

            buttons.push({
                action: 'save_draft',
                text: tr('save_changes'),
                icon: 'edit',
                color: 'white',
            });

            buttons.push({
                action: 'save',
                text: tr('create_purchase_order'),
                icon: 'save',
                color: 'blue',
                validation: true,
            });

        }
        else if( status === 'approval' )
        {
            if( this.props.userIsCurrentAcceptor )
            {
                buttons.push({
                    action: 'accept_cancel',
                    text: tr('reject'),
                    icon: 'times',
                    color: 'red',
                });

                buttons.push({
                    action: 'accept',
                    text: tr('accept'),
                    icon: 'check',
                    color: 'green',
                });
            }
            else
            {
                buttons.push({
                    action: 'accept_cancel',
                    text: tr('return_to_editing'),
                    icon: 'arrow-left',
                    color: 'red',
                });
            }
        }
        else if( status === 'order_send' )
        {
            buttons.push({
                action: 'send_cancel',
                text: tr('return_to_editing'),
                icon: 'arrow-left',
                color: 'red',
            });

            buttons.push({
                action: 'send',
                text: tr('send_to_supplier'),
                icon: 'envelope-open-text',
                color: 'green',
            });
        }
        else if( status === 'order_confirm' )
        {
            buttons.push({
                action: 'confirm_cancel',
                text: tr('return_to_editing'),
                icon: 'arrow-left',
                color: 'red',
            });

            buttons.push({
                action: 'confirm_save',
                text: tr('save_changes'),
                icon: 'edit',
                color: 'white',
                validation: true,
            });

            buttons.push({
                action: 'confirm',
                text: tr('confirm_order'),
                icon: 'clipboard-check',
                color: 'green',
                validation: true,
            });
        }
        else if( status === 'arrival' )
        {
            buttons.push({
                action: 'create_order',
                text: tr('create_sales_order'),
                icon: 'paper-plane',
                color: 'green',
            });

            buttons.push({
                action: 'arrival_edit',
                text: tr('save_changes'),
                icon: 'edit',
                color: 'white',
            });

            if( this.props.paid ) {
                buttons.push({
                    action: 'paid',
                    text: tr('cancel_payment'),
                    icon: 'money-bill-wave',
                    color: 'red',
                });
            }else{
                buttons.push({
                    action: 'paid',
                    text: tr('mark_as_paid'),
                    icon: 'money-bill-wave',
                    color: 'blue',
                });
            }

            buttons.push({
                action: 'arrival_add',
                text: tr('record_arrival'),
                icon: 'truck',
                color: 'green',
                validation: true
            });
        }
        else if( status === 'arrived' )
        {
            buttons.push({
                action: 'create_order',
                text: tr('create_sales_order'),
                icon: 'paper-plane',
                color: 'green',
            });

            buttons.push({
                action: 'arrived_edit',
                text: tr('save_changes'),
                icon: 'edit',
                color: 'white',
            });

            if( this.props.paid ) {
                buttons.push({
                    action: 'paid',
                    text: tr('cancel_payment'),
                    icon: 'money-bill-wave',
                    color: 'red',
                });

                buttons.push({
                    action: 'archive',
                    text: tr('archive'),
                    icon: 'archive',
                    color: 'green',
                });

            }else{
                buttons.push({
                    action: 'paid',
                    text: tr('mark_as_paid'),
                    icon: 'money-bill-wave',
                    color: 'blue',
                });
            }
        }
        addFileBtn();
        return buttons;
    }

    render()
    {
        const buttons = this.getButtons()
        if( !buttons.length ) return null;

        return <div id="po-savebox">
            { buttons.map( ( button, index ) => {
                const icon = ( button.icon ) ? <SvgIcon icon={ button.icon } type="solid" /> : null
                const buttonDom = <ApButton
                    key={ index }
                    className={"save-box-button" + ((this.props.edited && (button.action === "save_draft")) ? " highlight" : "")
            }
                    color={ button.color }
                    onClick={() => { this.props.onSubmit( button.action, button ) } }
                    disabled={ button.validation && Boolean( this.props.validatorMessage ) }

                > { icon } { button.text }</ApButton>

                if( button.validation )
                {
                    return <ApTooltip position="topright"  key={ index } text={ this.props.validatorMessage }>
                        { buttonDom }
                    </ApTooltip>
                }
                return buttonDom;
            } ) }
        </div>


    }
}

SaveBox.propTypes = {
};

export default SaveBox;
