import React from 'react';
import autoBind from 'react-autobind';

import { ApInput } from 'common/ApInput/ApInput.js';
import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';
import { tr } from 'services/Helpers/Helpers.js';
import api from 'services/Api/Api.js';

class EditStorageComponent extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            component_id: null,
            location_id: null,
            balance: null,
            limit_alarm: null,
            order_amount: null,
            balance_free: null,
            component_title: null,
            pc_id: null,

            original_balance: null,
            original_balance_free: null,
        }

        this.steps = [{
            name: tr('component_edit'),
            render: this.renderComponentEdit.bind( this ),
        }];
        autoBind(this);
    }

    resetState()
    {
        this.setState({
            component_id: null,
            location_id: null,
            balance: null,
            limit_alarm: null,
            order_amount: null,
            balance_free: null,
            component_title: null,
            pc_id: null,
        }, () => {
            if(this.props.component_id) {
                this.getComponentData();
            }
        });
    }

    getComponentData() {
        //Hae component stock

        api({
            method: 'get',
            url: `storage/balance/componentSearch/${this.props.component_id}`,
            errorPopper: tr('get_error'),
        }).then( ( resp ) => {

            this.setState({
                component_id: resp.component_id,
                location_id: resp.location_id,
                balance: resp.balance,
                limit_notification: resp.limit_notification,
                limit_alarm: resp.limit_alarm,
                order_amount: resp.order_amount,
                balance_free: resp.balance_free,
                id: resp.id,
                component_title: resp.component_title,
                unit: resp.unit,
                pc_id: resp.pc_id,
                original_moved: resp.delivered_count,
                moved: resp.delivered_count,

                original_balance: resp.balance,
                original_balance_free: resp.balance_free,
            });
            
        }).catch((error) => {
            console.error(`${tr('get_error')} ${error}`);
        });
    }


    save() { this.props.onSave(this.state); }

    formatToNum(input, unit, fixedNum = null) { 
        let retVal = String(input);
        retVal = retVal.replace(unit, '');
        retVal = parseFloat(retVal.replace(/\s/g, '').replace(',', '.'));
        if (fixedNum) { retVal = retVal.toFixed(fixedNum); }
        if (isNaN(retVal)) { retVal = 0; }
        return retVal; 
    }

    handleTitleChange(e) {
        if (this.state.component_id == null) {
            this.setState({component_title: e.target.value});
        }
    }

    handleChange(e, field, unit) {
        let amount = e.target.value;
        amount = this.formatToNum(amount, unit);
        if (field != 'moved') {
            //Laske arvot
            const diff = amount - this.state.original_balance;
            const balance_free = -parseFloat(this.state.original_balance_free) - diff;

            this.setState({ balance: amount, balance_free }); 
        } else { //Muokataan saapuneiden komponenttien määrää
            const diff = amount - this.state.original_moved;
            const balance_free = -parseFloat(this.state.original_balance_free) - diff;
            const balance = parseFloat(this.state.original_balance) + diff;

            this.setState({
                moved: amount,
                balance_free,
                balance
            });
        }

    }

    renderInputStatic(field, label, unit = null, inverse = false) {
        let val = this.state[field] != null ? this.state[field] : '';
        if (val.length > 0 && val != 0) { val = inverse ? val.substring(1) : val; }

        return <ApInput
            type="text"
            id={ field }
            name={ field }
            label={ label }
            autoComplete="off"
            value={ `${val} ${unit}`}
        />
    }

    renderInput( field, label, unit = null, inverse = false)
    {
        let val = this.state[field] != null ? this.state[field] : '';

        if (val.length > 0 && val != 0) { val = inverse ? val.substring(1) : val; }

        return <ApInput
            type="text"
            id={ field }
            name={ field }
            label={ label }
            autoComplete="off"
            onChange={ (e) => this.handleChange(e, field, unit)}
            value={ `${val} ${unit}`}
        />
    }

    renderComponentData()
    {
        return <div>
            
            <div className="componentDataContainer">
                <div className="col-container">
                    <div className="col-left">
                        { this.renderInputStatic( 'limit_notification', tr('initial_demand'), this.state.unit ) }
                    </div>
                    <div className="col-left">
                        { this.renderInputStatic( 'limit_alarm', tr('demand_now'), this.state.unit ) }
                    </div>
                </div>

                <div className="col-container">
                    <div className="col-left">
                        { this.renderInputStatic( 'balance_free', tr('shortage'), this.state.unit, true ) }
                    </div>
                </div>
                
                <div className="col-container" style={{ marginTop: '10%'}}>
                    <div className="col-left">
                        <h5>{ tr('quantity_delivered') }</h5>{ this.renderInput( 'moved', tr('quantity_delivered'), this.state.unit ) }
                    </div>
                    <div className="col-right">
                        <h5>{ tr('new_balance') }</h5>{ this.renderInput( 'balance', tr('balance'), this.state.unit ) }    
                    </div>
                </div>
            </div>
        </div>
    }

    renderComponentEdit()
    {
        let componentDom = null;

        componentDom = <div>
            <h3 className="apFormHeader">{ tr('component_values') }</h3>
            { this.renderComponentData() }
        </div>

        const componentTitleDom = <div>
            <ApInput
                id="id"
                name="component_name"
                label={ tr('component') }
                value={ this.state.component_title ? this.state.component_title : '' }
                onChange={ (e) => this.handleTitleChange(e)}
            />
            <h6 style={{ fontWeight: '700', marginLeft: '1em' }}>{this.state.component_id == null ? `[${tr('external_component')}]` : ''}</h6>
        </div>

        return <div className="stepOrder">
            <div className="related-select"> {componentTitleDom} </div>
            { componentDom }
        </div>
    }

    render()
    {
        let initialStep = 0;

        if( this.props.type )
            initialStep = 1;

        return <div id="newOrder">
            <ApModalWizard
                header={ tr('component_edit') }
                onOpen={ this.resetState }
                show={ this.props.show }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.save }
                activeStep={ initialStep }
                initialStep={ initialStep }

            />
        </div>
    }

}

export default EditStorageComponent;