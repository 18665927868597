import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';

class BugReports extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            bugs: []
        }
        autoBind(this);
    }

    componentDidMount()
    {
        this.getBugs();
    }

    getBugs()
    {
        this.setState({loading: true});
        api({
            method: 'get',
            url: 'management/bugs',
        }).then((response) => {
            //console.log( 'getBugs', response );
            this.setState({
                bugs: response,
                loading: false,
            })
        }).catch((error) => {
            this.setState({loading: false});
        });
    }

    render()
    {
        return (
            <div className="apBox">

                <div className="apBoxHeader">
                    <h1>Lähetetyt bugi-ilmoitukset</h1>
                    <p>TODO</p>
                </div>

                <div className="padding">
                    <ApButton size="small" color="green" onClick={ this.getBugs }>
                        <SvgIcon icon="redo" type="solid" />
                        Päivitä
                    </ApButton>
                

                { this.state.bugs.length > 0 && this.state.bugs.map( ( bug, index) => {
                    return (
                        <div style={{ margin: "0 0 1em", "borderBottom": "1px solid #ccc" }}>
                            <strong>Ilmoitettu:</strong> { bug.created_at }<br />
                            <strong>Käyttäjän kuvaus:</strong><br />
                            <p>{ bug.user_description }</p>
                            <strong>Meta data:</strong>
                            <pre>{ JSON.stringify( bug.meta_data ) }</pre>
                        </div>
                    );
                })}

                { this.state.bugs.length === 0 &&
                    <div className="apMsg">
                        Ei bugeja ilmoitettuna
                    </div>
                }

                </div>

            </div>
        );
    }
}

export default BugReports;
