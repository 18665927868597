/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import './DayEntries.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import { keyExists, roundToDecimals, ucfirst, truncate } from  'services/Helpers/Helpers.js';

class DayEntries extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            dayTotals:  false,
        };
        autoBind(this); 
    }

    componentDidMount()
    {
        this.getDayTotals();
    }

    renderProject( entry )
    {   
        let primary = "Ei projektia";
        let secondary = false;
 
        if( entry.project ) 
        {
            primary = entry.project.name;
            secondary = entry.project.project_code;
        }
        else if ( entry.type == "absence" )
        {
            primary = "Poissa koko päivän";
            secondary = entry.absence_reason.name;
        }

        return (
            <div>
                <span>{ primary }</span>
                { secondary && <small>{ secondary }</small> }
            </div>
        )
    }

    renderDescription( entry )
    {   
        let primary = "";
        let secondary = false;
 
        if( entry.type == "hour" ) 
        {
            primary = entry.description;
            secondary = entry.description_long;
        }
        else if ( entry.type == "travel" )
        {
            primary = entry.description;
            secondary = entry.route;
        }

        else if ( entry.type == "expense" )
        {
            primary = ( entry.event ? entry.event : "Kulukorvaus" );
            secondary = [];

            secondary.push( <div>Maksutapa: { entry.payment_method }</div> );

            for( let i = 0; i < entry.files.length; i++ )
                secondary.push( <div><SvgIcon icon="file" type="solid" className="size-small" /> Liitetiedosto { i + 1 }</div> );
            
        }

        else if ( entry.type == "allowance" )
        {
            primary = ( entry.allowance.abroad ? "Ulkomaan päiväraha" : entry.allowance.name );
            secondary = ( entry.allowance.abroad ? entry.allowance.name : false );
        }

        return (
            <div>
                <span>{ primary }</span>
                { secondary && <small>{ secondary }</small> }
            </div>
        )
    }

    renderAmount( entry )
    {
        let primary = "";
        let secondary = false;

        const parseNumber = ( number ) => {
            let value = parseFloat( number );
            if( !isNaN( value ) ) 
                return value;
            return 0;
        };

        if( entry.type == "hour" )
        {
            primary = <strong>{ entry.worktime ? parseNumber( entry.hours ) : 0 } h</strong>;
            secondary = <span>{ entry.hour_type.name }{ !entry.worktime && ": " + parseNumber( entry.hours ) + " h" }</span>;   
        }

        else if ( entry.type == "travel" )
        {
            primary = <strong>{ entry.distance } km</strong>;
            secondary = ( entry.hours ? "Työaika: " + entry.hours + " h" : false );
        }

        else if ( entry.type == "expense" )
        {   

            const calcTotalSum = ( rows ) => {
                if( Array.isArray( rows ) )
                {
                    let sum = 0;
                    for( let i = 0; i < rows.length; i++ )
                        sum += rows[i].cost / rows[i].currency_rate;

                    return sum;
                }
                return 0;
            };

            primary = <strong>Yhteensä: { roundToDecimals( calcTotalSum( entry.rows ), 2 ) } &euro;</strong>;
            secondary = [];

            for( let i = 0; i < entry.rows.length; i++ )
                secondary.push( 
                    <div>
                        { entry.rows[i].description } { entry.rows[i].cost } { entry.rows[i].currency }
                    </div> 
                );
        }

        else if ( entry.type == "allowance" )
        {
            const convertTimeRange = ( start, end ) => {
                if( start == "00:00:00" && end == "24:00:00" )
                    return "Koko päivä";
                else 
                    return "Klo " + start.slice(0, -3) + " - " + end.slice(0, -3);
            };

            primary = <strong>{ roundToDecimals( entry.daily_allowance_compensation, 2 ) } &euro;</strong>;
            secondary = convertTimeRange( entry.daily_allowance_start, entry.daily_allowance_end ); 
        }

        return (
            <div>
                <span>{ primary }</span>
                { secondary && <small>{ secondary }</small> }
            </div>
        )
    }

    renderStatus( status )
    {
        let className = "suggested";
        let icon = "question";
        let text = "Odottaa";

        if( status == 3 )
        {
            className = "rejected";
            icon = "times";
            text = "Hylätty";
        }
        if( status == 4 )
        {
            className = "approved";
            icon = "check";
            text = "Hyväksytty";
        }

        return (
            <div className={ "statusBox " + className }>
                <SvgIcon icon={ icon } type="solid" />
                { text }   
            </div>
        );
    }

    getDayTotals() 
    {
        this.setState({ loading: true });

        let users = [];
        this.props.entries.forEach( entry => {
            if( users.indexOf( entry.user_id ) == -1 )
                users.push( entry.user_id );
        });

        api({
            method: 'post',
            url: 'hour/approval/day/totals',
            data: {
                date: this.props.date,
                users: users,
            }
        }).then(( response ) => {

            //console.log('getDayTotals', response );
            this.setState({
                loading: false,
                dayTotals: response,
            });

        }).catch( ( error ) => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    render() 
    {
        return (
            <div className="DayEntries">

                <div className="header">
                    { ucfirst( moment( this.props.date ).format('dddd DD.MM.YYYY') ) }
                    <small> - Viikko { moment( this.props.date ).format('W') } </small> 
                </div>

                <table className="entries">
                    <tbody>
                    { this.props.entries.map( ( entry, index ) => {

                        const selected = ( this.props.selectedEntries.indexOf( entry.uniqueIndex ) != -1 );

                        let classes = ["entry"];
                        let icon = false;
                        
                        if( selected )
                            classes.push( "selected" );

                        classes.push( "status-" + entry.status_id );

                        if( entry.misentry )
                            classes.push( "misentry" );

                        if( entry.type == "hour" )
                        {
                            if( entry.absence_reason_id )
                                icon = "ban";
                            else 
                                icon = "clock";
                        }

                        else if( entry.type == "travel" )
                            icon = "car";

                        else if( entry.type == "expense" )
                            icon = "receipt";

                        else if( entry.type == "allowance" )
                            icon = "suitcase";

                        else if( entry.type == "absence" )
                            icon = "ban";

                        //console.log( 'FOOBAR*', entry );

                        return (
                            <tr key={ "entry" + index } className={ classes.join(" ") }>

                                <td className="select" onClick={ () => this.props.handeRowSelect( entry.uniqueIndex ) }>
                                    <input type="checkbox" checked={ selected } readOnly />
                                </td>
                                <td className="icon" onClick={ () => this.props.handeRowSelect( entry.uniqueIndex ) }>
                                    <SvgIcon icon={ icon } type="solid" />
                                </td>
                                <td className="user" onClick={ () => this.props.handeRowSelect( entry.uniqueIndex ) }>
                                    { keyExists( entry, "user.person_detail", true ) ? entry.user.person_detail.first_name + " " + entry.user.person_detail.last_name : "Tuntematon henkilö" }
                                </td>
                                <td className="project" onClick={ () => this.props.handeRowSelect( entry.uniqueIndex ) }>     
                                    
                                    { this.renderProject( entry ) } 

                                </td>
                                <td className="description" onClick={ () => this.props.handeRowSelect( entry.uniqueIndex ) }>
                                    
                                    { this.renderDescription( entry ) }

                                </td>
                                <td className="amount" onClick={ () => this.props.handeRowSelect( entry.uniqueIndex ) }>
                                    
                                    { this.renderAmount( entry ) }

                                </td>
                                <td className="status" onClick={ () => this.props.handleStatusChange( entry.uniqueIndex ) }>

                                    { this.renderStatus( entry.status_id ) }

                                </td>
                                <td className={ "comment" + ( !entry.approver_comment ? " missing" : "" ) } onClick={ () => { if( entry.status_id == 3 ) this.props.handleCommentClick( entry.uniqueIndex ) } }>
                                    { entry.status_id == 3 &&
                                        <ApTooltip text={ entry.approver_comment ? truncate( entry.approver_comment, 50 ) : "Lisää kommentti" } position="topright">
                                            <SvgIcon icon={ entry.approver_comment ? "comment-dots" : "comment" } type="solid" />
                                        </ApTooltip>
                                    }
                                </td>

                            </tr>
                        );
                    })}

                    </tbody>
                </table>

                <div className="dayTotals">
                    { this.state.loading &&
                        <div className="apInlineLoader" />
                    }
                    { this.state.dayTotals && this.state.dayTotals.map(( total, index ) => {
                        return (
                            <div className="user">
                                <strong>{ total.user }</strong> päivän kokonaispituus { total.hours } h. 
                            
                                { total.extras.map(( extra, extraIndex ) => {
                                    return (
                                        <span key={ extraIndex } className="salaryExtra">
                                            <strong>+{ extra.extra }</strong> { auth.getCurrencySign() } { extra.name }
                                        </span>
                                    );
                                })}
                                
                            </div>
                        );
                    })}
                </div>
        
            </div>
        );
    }
}


DayEntries.propTypes = {
    date:               PropTypes.string.isRequired,
    entries:            PropTypes.array.isRequired,
    selectedEntries:    PropTypes.array,
};


export default DayEntries;
