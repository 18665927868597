import React from 'react';
import autoBind from 'react-autobind';

import ApModal          from 'common/ApModal/ApModal.js';
import ApButton         from 'common/ApButton/ApButton.js';
import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import { tr } from 'services/Helpers/Helpers';

class LinkConfirm extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            linkModified: false,
        }
        autoBind(this);
    }

    close( data )
    {
        this.props.onClose( data, this.state.linkModified );
    }

    render()
    {
        let projectDom = null;
        if( this.props.project )
        {
            projectDom = <strong>
                {this.props.project.name} <small> {this.props.project.code } </small>
            </strong>
        }

        let unlinkedDom = null;
        if( this.props.hasUnLinked )
        {
            unlinkedDom = <div className="padding">
                <div className="apInfo small warning">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('mass_list_unlinked_rows_error') }
                </div>
            </div>
        }

        let noCountDom = null;
        if( this.props.noCount )
        {
            noCountDom = <div className="padding">
                <div className="apInfo small warning">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('storage_components_count_column_error') }
                </div>
            </div>
        }

        // if( this.props.project ) project.

        return <div id="ProjectMassListLinkConfirm" className="ApModalConfirm">
            <ApModal
                show={ this.props.show }
                handleClose={ () => this.props.onClose( false) }
                closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            { tr('confirm_import_mass_list') }
                        </h4>
                    </div>
                }
                body={
                    <div>
                        <div className="padding">
                            <div>{ tr('confirm_import_mass_list_info', [projectDom]) }</div>
                            { unlinkedDom }
                            { noCountDom }
                        </div>
                    </div>
                }
                footer={
                    <div className="padding footer">
                        <ApButton className="cancel" onClick={ () => this.close( false ) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('no') }
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ () =>  this.close( true ) }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('yes') }
                        </ApButton>

                    </div>
                }
            />
        </div>
    }

}

export default LinkConfirm;
