import React from 'react';
import autoBind from 'react-autobind';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import api from '../../services/Api/Api.js';
import CompanyEdit from './CompanyEdit.js';
import Moment from 'react-moment';
import ApConfirm from '../../common/ApConfirm/ApConfirm.js';
import {reactTableTranslate} from '../../services/Helpers/Helpers.js';

import {
    Container, Row, Col,
    Button
} from 'react-bootstrap';

class CompanyTable extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            pages: 0,
            companies: [],
            columns: [],
            loading: false,
            selectedCompanyID: null,
            companyEditModalShow: false,
            removeConfirmShow: false,
        }
        this.tableState = {};
        autoBind(this);
    }

    UNSAFE_componentWillMount()
    {
        this.getColumns();
    }

    getCompanies(state = null)
    {
        this.setState({loading: true});


        if(!state)
            state = this.tableState;
        this.tableState = state;

        api({
            method: 'post',
            url: 'management/companies/apt',
            data: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
            },
        }).then((response) => {
            this.setState({
                companies: response.data,
                pages: response.pages,
                loading: false,
            })
        }).catch((error) => {
            console.error(error);
            this.setState({loading: false});
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Error on getting companies </strong>,
            });
        });
    }

    handleEditClick(id)
    {
        this.setState({
            selectedCompanyID:id,
            companyEditModalShow: true
        });
    }

    handleNewClick()
    {
        this.setState({
            companyEditModalShow: true,
            selectedCompanyID: null
        });
    }

    editClosed()
    {
        this.getCompanies();
        this.setState({ companyEditModalShow: false });
    }

    showRemoveModal(id)
    {

        this.setState({
            selectedCompanyID: id,
            removeConfirmShow: true
        });
    }

    removeCompany(e)
    {
        api({
            method: 'delete',
            url: `management/company/remove/${this.state.selectedCompanyID}`,
        }).then((response) => {
            this.getCompanies();
        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Error removing company</strong>,
            });
        });
    }

    getColumns()
    {
        const columns = [
            {
                sortable: false,
                Header: '',
                Cell: props => <div>
                    <Button
                        size="sm"
                        onClick={() => {this.handleEditClick(props.original.id)}}>
                        Edit
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => {this.showRemoveModal(props.original.id)}}>
                        Remove
                    </Button>
                </div>
            },
            {
                Header: 'ID',
                accessor: 'id'
            },
            {
                sortable: false,
                Header: 'Active',
                Cell: props =>
                    props.original.is_active ? <span> True </span> : <span> False </span>
            },
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Suffix',
                accessor: 'suffix'
            },
            {
                sortable: false,
                Header: 'Global Admin',
                Cell: props =>
                    props.original.is_global_admin? <span> True </span> : <span> False </span>
            },
            {
                sortable: false,
                Header: 'Users',
                accessor: 'users_count'
            },
            {
                sortable: false,
                Header: 'Created at',
                accessor: 'created_ad',
                Cell: props =>
                    <Moment
                        parse="YYYY-MM-DD hh:mm.ss"
                        format="DD.MM.YYYY">
                        {props.original.created_at}
                    </Moment>
            },
            {
                sortable: false,
                Header: 'Updated at',
                accessor: 'updated_at',
                Cell: props =>
                    <Moment
                        parse="YYYY-MM-DD hh:mm.ss"
                        format="DD.MM.YYYY">
                        {props.original.updated_at}
                    </Moment>
            },
        ];
        this.setState({ columns: columns });
    }

    render()
    {
        return (
            <Container fluid={true} className="white" style={{paddingBottom: '2em'}}>
                <Row>
                    <Col sm={12}>
                        <Button
                            bsSize="xsmall"
                            onClick={this.handleNewClick}>
                            Create new
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <ReactTable
                            columns={this.state.columns}
                            data={this.state.companies}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            onFetchData={this.getCompanies}
                            defaultPageSize={10}
                            manual
                            {...reactTableTranslate() }
                        />
                    </Col>
                </Row>
                {this.state.companyEditModalShow && <CompanyEdit
                    show={this.state.companyEditModalShow}
                    onClose={this.editClosed}
                    companyID={this.state.selectedCompanyID}
                />}
                {this.state.removeConfirmShow && <ApConfirm
                    show={this.state.removeConfirmShow}
                    onClose={() => {this.setState({removeConfirmShow: false})}}
                    onConfirm={this.removeCompany}
                    header="Really remove the company?"
                    body="Please confirm the deletion of the selected company. Note: the process is likely to fail if any existing data still refers to it"
                />}
            </Container>
        );
    }
}

export default CompanyTable;
