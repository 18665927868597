/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment, { lang } from 'moment';
import { debounce } from 'throttle-debounce';
import api from 'services/Api/Api.js';
import { Collapse } from 'react-bootstrap';
import auth from 'services/Authed/Authed.js';
import ApReactTable from 'common/ApReactTable/ApReactTable';
import { connect } from 'react-redux';

import { keyExists, 
         validateEmail, 
         timeSince, 
         sqlToDateInput, 
         dateInputToSql,
         highlight,
         randomPassword,
         tr,
         currentLang } from 'services/Helpers/Helpers.js';

import './Company.css';

import ExtranetUserTable from './ExtranetUserTable.js';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
//import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApUserImage from 'common/ApUserImage/ApUserImage.js';
import LongerWorkingHoursCompensation from 'modules/WorkHours/LongerWorkingHoursCompensation/LongerWorkingHoursCompensation';
import Files from './Files/Files.js';
import ApCountrySelect from  'common/ApCountrySelect/ApCountrySelect.js';


class CrmCompany extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            settings: false,
            addressTypes: [],
            data: false,
            selectedCompany: null,
            unsavedChanges: false,
            errorMsg: false,

            showModal: false,
            modalType: null,
            modalData: null,

            eventsUsers: [],
            eventsFilterType: '',
            eventsFilterUser: '',
            eventsShowDetails: false,

            showPassword: true,
            changePassword: false,

            default_billing_method: null,
            default_tax: null,
            default_tax_code: null,
            storageCodeDiscounts: [],
            componentTypeDiscounts:[],
            languageId: 1,
            wholesale_id:null,
            languages: [],
            filesModalShow: false,
            workers:[],

        };
        autoBind(this); 

        this.newItemCounter = 0;

        this.tabIds = {
            0: "basic",
            1: "addresses",
            2: "contacts",
            3: "events",
            4: "discount",
            5: "extranet",
            6: "worker"
        };

        this.addressIcons = {
            'general': "globe",
            'mailing': "envelope", 
            'billing': "file-invoice", 
            'shipping': "truck", 
        };

        this.eventIcons = {
            "call":     "phone",
            "email":    "at",
            "visit":    "coffee",
            "-":        "question",
        }

        this.validateUsernameDebounced = debounce( 500, this.validateUsername );
    }

    componentDidMount()
    {
        this.setState({ loading: true });
        this.getSettings();
        
        this.getLanguages();
    }

    getSettings()
    {
        api({
            method: 'get',
            url: 'crm/company/settings',
        }).then((response) => {
            let billing_methods = []
            for (let key in response.billing_methods) {
                billing_methods.push({ id: response.billing_methods[key], name: key});
            }

            


            response.billing_methods = billing_methods;
            this.setState({ settings: response });


            //console.log('Settings', response);

            this.getCrmCompany(response);
        }).catch((error) => {
            console.log('ERROR while fetching settings!');
        });
    }

    getLanguages()
    {
        api({
            method: 'get',
            url: 'languages/all'
        }).then((response) => {
            const languageOptions = [];
            response.forEach(lang => {
                if (lang.id === 1 || lang.id === 2 || lang.id === 3) {
                    languageOptions.push({
                        value: lang.id,
                        label: lang.name
                    });
                }
            });
            this.setState({languages: languageOptions});
        }).catch((error) => {
            console.log('Error while fetching languages.')
        });
    }

    getTabId( param )
    {
        if( param in this.tabIds )
            return parseInt( param, 10 );

        return parseInt( Object.keys( this.tabIds ).find( key => this.tabIds[ key ] === param ), 10 );
    }

    onTabChange( tabId ) {
        this.props.history.replace( '/crm/company/' + this.state.data.id + '/' + this.tabIds[ tabId ] );   
    }

    toggleActiveSwitch()
    {
        let data = { ...this.state.data };
        data[ 'is_active' ] = !data[ 'is_active' ];
        this.setState({ 
            data: data,
            unsavedChanges: true,
        });
    }

    toggleExtranetSwitch(key)
    {
        let data = { ...this.state.data };
        data[key] = !data[key];
        this.setState({
            data: data,
            unsavedChanges: true
        });
    }

    handleChange( e )
    {
        let data = { ...this.state.data };
        
        if( e.target.name == "turnover" || e.target.name == "employs" )
            data[ e.target.name ] = this.thousandSeparate( e.target.value );
        else 
            data[ e.target.name ] = e.target.value;

        this.setState({ 
            data: data,
            unsavedChanges: true,
        });
    }

    changeAddressNames(){
        
    }

    thousandSeparate( value )
    {
        value = String( value ).replace(/\D/g, '');
        if( value.length < 4 )
            return value;

        const reverse = ( s ) => { return s.split("").reverse().join("") };
        value = reverse( value );
        let parts = [];
        for( let i = 0; i < value.length; i += 3 )
            parts.push( value.substr( i, 3 ) );

        return reverse( parts.join(" ") );
    }

    handleModalChange( name, value )
    {
        // if( name === "occurred_at" || name === "reminded_at")
        //     value = dateInputToSql( value, true );

        let modalData = { ...this.state.modalData };
        modalData[ name ] = value;
        modalData.unsaved = true;
        this.setState({ modalData }, () => {
            //console.log(this.state.modalData);
        });
    }

    handleUserNameChange( value )
    {
        this.setState({ 
            usernameError: "awaiting validation",
        });
        let modalData = { ...this.state.modalData };

        modalData[ 'extranet_username' ] = value;
        modalData.unsaved = true;
        this.setState({ modalData }, () => {
            console.log(this.state.modalData);
        });
        this.setState({ usernameLoading: true });
        this.validateUsernameDebounced( value, this.state.modalData.id );  
    }

    validateUsername( username, id )
    {   
        const data = {
            username: username,
            suffix: this.state.settings.extranet_suffix,
            userId: id
        };
        this.setState({ usernameLoading: true, usernameError: "loading", });
        if (this.state.modalData.extranet_active!=true && ( username=='' || username ==null)) {
            this.setState({ 
                usernameError: "",
                usernameLoading: false,
            });
        }
        else {
            api({
                method: 'post',
                url: 'crm/validate/extranet/username',
                data: data
            }).then(( errorMsg ) => {
                console.log(errorMsg);

                this.setState({ 
                    usernameError: errorMsg,
                    usernameLoading: false,
                });
            }).catch((error) => {
                console.log('Käyttäjätunnuksen validointi epäonnistui ', error);
                this.setState({ 
                    usernameError: "",
                    usernameLoading: false,
                });
            });
        }
    }
    

    handleSelectChange( name, value )
    {
        let data = { ...this.state.data };
        data[ name ] = value;
        data[ name + "_id" ] = ( value && value.id ? value.id : null );

        this.setState({ 
            data: data,
            unsavedChanges: true,
        });
    }

    handleActiveSwitchChange()
    {
        let data = this.state.modalData;
        data.unsaved = true;
        data.extranet_active = !data.extranet_active;
        this.setState({ modalData: data });
        this.validateUsername( this.state.modalData.extranet_username, this.state.modalData.id );
    }
    handleNoMailingSwitchChange() {
        let data = this.state.modalData;
        data.unsaved = true;
        data.no_mailing = !data.no_mailing;
        this.setState({ modalData: data });
    }

    // Get ID from URL parameter
    getCrmId() {
        return parseInt(this.props.match.params.id, 10);
    }

    getCrmCompany(settings)
    {
        // Get ID from URL parameter
        const id = parseInt( this.props.match.params.id, 10 );

        if( id )
        {
            this.setState({ loading: true });
            api({
                method: 'get',
                url: 'crm/company/get/' + id,
            }).then((response) => {
                //console.log(response);
                const parsed = this.parseCompanyData( response );
                let languageId = 1;
                if (response.language_id) {
                    languageId = response.language_id;
                }
                let default_billing_method;
                let default_tax;
                let default_tax_code;
                for (let row of settings.billing_methods) {
                    if (row.id === response.default_billing_method) {
                        default_billing_method = row;
                        break;
                    }
                }
                for (let row of settings.taxes) {
                    if (row.id === response.default_tax) {
                        default_tax = row;
                        break;
                    }
                }
                for (let row of Object.values(settings.tax_codes)) {
                    if (row.id === response.default_tax_code) {
                        default_tax_code = row;
                        break;
                    }
                }
                
                let storageCodeDiscounts = [];
                let componentTypeDiscounts = [];
                
                for (let row of response.discounts) {
                    if (row.storage_code_id) {
                        storageCodeDiscounts.push(row);
                        
                    }
                    else {
                        componentTypeDiscounts.push(row);
                    }
                }
                

                this.setState({
                    languageId,
                    data: parsed.data,
                    eventsUsers: parsed.users,
                    loading: false,
                    default_billing_method: default_billing_method,
                    default_tax: default_tax,
                    default_tax_code: default_tax_code,
                    selectedCompany: parsed.data.selected_parent_company,
                    wholesale_id: parsed.data.wholesale_id,
                    storageCodeDiscounts: storageCodeDiscounts,
                    componentTypeDiscounts: componentTypeDiscounts,
                    storage_codes: response.storage_codes,
                    component_types: response.component_types,
                    use_tax_groups: response.use_tax_groups,
                    cost_center_enabled: response.cost_center_enabled,
                    workers: parsed.data.workers,
                
                });

            }).catch((error) => {
                console.error(error);
                this.setState({
                    loading: false,
                });
            });
        }
        else 
            console.error('Company Id NOT available');
    }

    parseCompanyData( data )
    {
        // Pick all the different users form events (for filtering)
        let users = [];
        let contacts = [];
        data.events.forEach( event => {
            if( users.filter( function(e) { return e.value === event.user_id; }).length == 0 && event.user_id != auth.getID() )
                users.push({ value: event.user_id, label: event.user.name });
        })

        data.turnover = this.thousandSeparate( data.turnover );
        data.employs = this.thousandSeparate( data.employs );

        //console.log('Company data (parsed)', data );

        return {
            data: data,
            users: users
        };
    }

    newItem( type )
    {   
        let data = { new: true };

        // By default address type is 'general'
        if( type === "addresses" )
        {
            data.type = "general";
        }
        else if ( type === "events" )
        {
            const currentUser = auth.getUser();
            data.type = "-";
            data.user_id = currentUser.id;
            data.user = {
                first_name: currentUser.first_name,
                last_name: currentUser.last_name,
                image: currentUser.image,
            };
        }
        else if ( type == 'workers' )
        {
            data.type = "general";
        }

        this.openModal( type, data );
    }

    editItem( type, data )
    {
        this.openModal( type, data );
    }

    setDefaultAddress( type, id )
    {
        let data = { ...this.state.data };
        
        if( type == "shipping" )
            data[ "default_shipping_address_id" ] = id;

        else if ( type === "billing" )
            data["default_billing_address_id"] = id;
        else if (type === "supplier")
            data["default_supplier_address_id"] = id;
        
        this.setState({ 
            data: data,
            unsavedChanges: true,
        });
    }

    removeItem( type, id )
    {
        let items = this.state.data[ type ].slice();
        for( let i = 0; i < items.length; i++ )
        {
            if( items[i].id == id )
            {
                items[i].removed = !items[i].removed; // !undefined = true
                break;
            }
        }
        this.setState( prevData => ({ 
            ...prevData,
            [ type ]: items,
            unsavedChanges: true,
        }));
    }

    getLanguage( id )
    {
        if( !keyExists( this.state, "settings.languages" ) )
            return "";

        for( let i = 0; i < this.state.settings.languages.length; i++ )
            if( this.state.settings.languages[i].id === id )
                return this.state.settings.languages[i].label;

        return id; 
    }

    getLanguageOptions()
    {
        const languages = keyExists( this.state.settings, "languages", true );
        
        let options = [];
        if( languages )
        { 
            languages.forEach( lang => {
                options.push( { value: lang.id, label: lang.label });
            });
        }
        
        return options;
    }

    openModal( type, data )
    {
        if( this.state.showModal )
            return false;
            
        if (type=='contacts') {
            this.validateUsernameDebounced(data.extranet_username, data.id);
        }

        this.setState({ 
            showModal: true,
            modalType: type,
            modalData: data,
        });
    }

    validateModal()
    {
        let data = this.state.modalData;

        if( this.state.modalType === "addresses" )
        {
            if( !data.street )
                return false;
        }
        else if ( this.state.modalType === "contacts" )
        {
            if( !data.first_name || !data.last_name || (!this.state.usernameError=="" ))
                return false;
        }
        else if ( this.state.modalType === "events" )
        {
            if( !data.title || !data.occurred_at || !moment( data.occurred_at ).isValid() )
                return false;
        }
        return true;
    }

    closeModal( save = false )
    {
        let items = this.state.data[ this.state.modalType ];
        let unsavedChanges = this.state.unsavedChanges;

        if( save )
        {
            //console.log("save");
            let newData = this.state.modalData;

            if (!newData.name) {
                newData.name = this.state.data.name;
            }
          
            // Add new
            if( newData.new )
            {
                newData.new = false;
                newData.id = "new" + this.newItemCounter++;
                newData.unsaved = true;
                unsavedChanges = true;

                // TODO move item to right position
                if( this.state.modalType === "events" )
                    items.unshift( newData );
                else 
                    items.push( newData );
            }
            // Modify old
            else 
            {   
                items = items.map( item => {
                    if( item.id == newData.id )
                    {
                        if( newData.unsaved )
                            unsavedChanges = true;
                    
                        return newData;
                    }
                    return item;
                }); 
            }
        }
        this.setState( prevData => ({ 
            ...prevData,
            data: {
                ...prevData.data,
                [ this.state.modalType ]: items,
            },
            unsavedChanges: unsavedChanges,
            showModal: false,
        }));
    }

    toggleEventDetails( id ) 
    {
        if( this.state.eventsShowDetails )
            return false;

        let events = this.state.data.events.slice();
        for( let i = 0; i < events.length; i++ )
        {
            if( events[i].id == id )
            {
                events[i].showDetails = !events[i].showDetails; // !undefined = true
                break;
            }
        }
        this.setState( prevData => ({ 
            ...prevData,
            events: events,
        }));
    }

    sendEmail( address )
    {
        if( validateEmail( address ) )
            window.location.href = "mailto:" + address;
    }

    openLink( link )
    {
        if( link.length > 0 )
        {
            if( link.substr( 0, 7) !== "http://" || link.substr( 0, 8 ) !== "https://" )
                link = "http://" + link;

            window.open( link, '_blank' );
        }
    }

    verifyCompany()
    {
        if( !this.state.data.id || this.state.unsavedChanges )
            return false;
    
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'crm/company/verify/' + this.state.data.id,
        }).then((response) => {
            let data = { ...this.state.data };
            data.verified = response.verified;
            data.verifier_id = response.verifier_id;
            this.setState({ 
                data: data,
                loading: false,
            });
        }).catch((error) => {
            this.setState({
                loading: false,
            });
        });   
    }

    editUserInfo(params)
    {

        //console.log(params);

        this.state.data.contacts.forEach((c, index) => {
            if (c.id == params.contactId) this.editItem("contacts", c);
        });

    }

    saveCompany()
    {
        if( !this.state.unsavedChanges )
            return false;

        let data = { ...this.state.data };
        data.language_id = this.state.languageId;
        data.wholesale_id = this.state.wholesale_id
        data.addresses = data.addresses.map((address) => {
            if (address.type != 'billing') {
                address.name = data.name;
            }
            return address;
        });

        data.default_tax = this.state.default_tax ? this.state.default_tax.id : null;
        data.default_tax_code = this.state.default_tax_code ? this.state.default_tax_code.id : null;
        data.default_billing_method = this.state.default_billing_method ? this.state.default_billing_method.id : null;
        data.storageCodeDiscounts = this.state.storageCodeDiscounts ? this.state.storageCodeDiscounts : [];
        data.componentTypeDiscounts = this.state.componentTypeDiscounts ? this.state.componentTypeDiscounts : [];
        // data.workers = this.state.workers  ? this.state.workers : [];

        //console.log(data);

        // Strip all but numbers (thousand separators)
        data.turnover = String( data.turnover ).replace(/\D/g, '');
        data.employs = String( data.employs ).replace(/\D/g, '');

        //console.log( 'saving: ', data );
        this.setState({ loading: true, errorMsg: false });
        api({
            method: 'post',
            url: 'crm/company/save',
            data: data,
        }).then((response) => {

            const parsed = this.parseCompanyData( response );
            let languageId = 1;
            if (response.language_id) {
                languageId = response.language_id;
            }

            this.setState({
                languageId,
                data: parsed.data,
                eventsUsers: parsed.users,
                unsavedChanges: false,
                loading: false,                
            });

        }).catch((error) => {
            console.log( error );
            this.setState({
                errorMsg: keyExists( error, "response.data.message", true, tr('save_error') ) || "VIRHE",
                loading: false,
            });
        });
    }

    changeLanguage = (e) => {
        this.setState({
            languageId: e.target.value,
            unsavedChanges: true
        });
    };

    getWholesales() {
        let suppliers = [];
        let k = [];
        let sc = this.state.data.wholesales;

        sc.map((s) => {
            if (s.name !== tr('not_given')) {
                k = {
                    'value': s.id,
                    'label': s.name
                }
                suppliers.push(k);
            }

        })
        const vl =
        {
            value: '',
            label: `${tr('no_wholesale')}`
        }
        suppliers.unshift(vl);
        return suppliers;
    }

    setParentCompany(item) {
        //console.log(item);

        this.setState({
            data: { ...this.state.data, parent_company_id: item.id },
            selectedCompany: item,
            unsavedChanges:true,
        });
    }

    renderOrderSetting()
    {
        const fields = [
            { label: tr('default_payment_term'),    field: 'default_payment_condition', options: 'paymentConditions' },
            { label: tr('default_delivery_term'), field: 'default_delivery_term',     options: 'deliveryTerms' },
            { label: tr('default_delivery_method'), field: 'default_delivery_method',   options: 'deliveryMethods' },
        ];

        let doms = [];
        fields.forEach( f => {
            const options = keyExists( this.state.settings, f.options, true, [] );
            if( !options ) return null;

            doms.push( <ApSelect
                key={f.options}
                label={ f.label }
                value={ keyExists( this.state.data, f.field, true, '' ) }
                optionRenderer={ (item) => {
                    return <div>
                        { item.name }
                    </div>
                }}
                onChange={ ( item ) => this.handleSelectChange( f.field, item )  }
                objKeyId="id"
                objKeySearchable="name"
                objKeyValue="name"
                clearable

                options={ options }

                loading={ this.state.loading }
                disabled={ this.state.loading }
                readOnly={ !auth.hasModule( "clients.edit" ) }
            />)
        })

        return <div>
            { doms }
        </div>
    }
    getGroupOptions() {
        let options = keyExists(this.state.settings, "groups", true, [])
        options = options.filter(option => (option.is_customer === true && this.state.data.is_customer) ||
            (option.is_supplier === true && this.state.data.is_supplier));
        return options;
    }

    getContactGroupOptions() {
        let options = keyExists(this.state.settings, "contactGroups", true, [])
        options = options.filter(option => (option.is_customer === true && this.state.data.is_customer) ||
            (option.is_supplier === true && this.state.data.is_supplier));
        return options;
    }

    TypeColumns() {
        return [
            {
                Header: tr('storage_component_type'),
                // width: 50,
                // className: "overflowableOnHover",
                Cell: props => {
                    return props.original.name;
                    // if (props.original.saveComponent) {
                    //     return <div className="red">
                    //         <ApTooltip text={tr('link_to_previous')} position="topleft">
                    //             <SvgIcon icon="link" type="solid" />
                    //         </ApTooltip>
                    //     </div>
                    // }
                    // return <div className="red">
                    //     <ApTooltip block text={tr('new_storage_component')} position="topleft">
                    //         <SvgIcon icon="plus-circle" type="solid" />
                    //     </ApTooltip>
                    // </div>
                },
                onClick: this.openDiscountModalType,
                
            },
            {
                Header: tr('discount_percentage'),
                accessor: 'discount',
                onClick: this.openDiscountModalType,
                Cell: props => {
                    return props.original.discount.toString().replace(".",",") + '%';

                    
                },
                
            }
            // ,{
            //     Header: 'Koodi',
            //     accessor: 'saveCode',
            //     Cell: props => {
            //         if( props.original.saveComponent ) return props.original.saveComponent.code;
            //         return <div className="codeInput" onClick={ ()  => this.codeModalOpen( props.index) }>
            //         </div>
            //     },
            //     width: 100,
                
            // }, {
            //     Header: tr('unit'),
            //     accessor: 'saveUnit',
            //     Cell: props => {
            //         if (props.original.saveComponent) return props.original.saveComponent.unit;

            //         return <input
            //             className="textInput"
            //             value={props.value}
            //             onChange={(e) => { this.changeName(props.index, e.target.value) }}
            //         />
            //     },
            //     width: 100,
            // },
        ];

    }
    storagediscountColumns() {
        return [
            {
                Header: tr('component_code'),
                // width: 50,
                // className: "overflowableOnHover",
                onClick: this.openDiscountModalstorageCode,
                Cell: props => {
                    return props.original.name;
                    // if (props.original.saveComponent) {
                    //     return <div className="red">
                    //         <ApTooltip text={tr('link_to_previous')} position="topleft">
                    //             <SvgIcon icon="link" type="solid" />
                    //         </ApTooltip>
                    //     </div>
                    // }
                    // return <div className="red">
                    //     <ApTooltip block text={tr('new_storage_component')} position="topleft">
                    //         <SvgIcon icon="plus-circle" type="solid" />
                    //     </ApTooltip>
                    // </div>
                },
            },
            {
                Header: tr('discount_percentage'),
                accessor: 'discount',
                onClick: this.openDiscountModalstorageCode,
                Cell: props => {
                    return props.original.discount.toString().replace(".", ",") + '%';

                },

            }
            // ,{
            //     Header: 'Koodi',
            //     accessor: 'saveCode',
            //     Cell: props => {
            //         if( props.original.saveComponent ) return props.original.saveComponent.code;
            //         return <div className="codeInput" onClick={ ()  => this.codeModalOpen( props.index) }>
            //         </div>
            //     },
            //     width: 100,

            // }, {
            //     Header: tr('unit'),
            //     accessor: 'saveUnit',
            //     Cell: props => {
            //         if (props.original.saveComponent) return props.original.saveComponent.unit;

            //         return <input
            //             className="textInput"
            //             value={props.value}
            //             onChange={(e) => { this.changeName(props.index, e.target.value) }}
            //         />
            //     },
            //     width: 100,
            // },
        ];

    }

    workerColumns() {
        // let data = this.state.workers.filter(item => item.id  );
        return [
           
            {
                Header: tr('first_name'),
                accessor: 'first_name',
            },
            {
                Header: tr('last_name'),
                accessor: 'last_name',             
            },
            {
                Header: tr('nin'),
                accessor: 'nin',                         
            }
          
        ];

    }

    addNewDiscount(mode) {
        let selectData=[];
        if (mode === "type") {
            for (let type of this.state.component_types) {
                let add=true
                for (let selectedType of this.state.componentTypeDiscounts) {
                    if (type.id === selectedType.id) {
                        add = false;
                        break;
                    }
                }
                if (add) {
                    selectData.push(type); 
                }
            }
            
        }
        else {
            for (let code of this.state.storage_codes) {
                let add = true
                // for (let selectedcode of this.state.storageCodeDiscounts) {
                //     if (code.id === selectedcode.id) {
                //         add = false;
                //         break;
                //     }
                // }
                if (add) {
                    selectData.push(code);
                }
            }
        }
        this.setState({
            showEditDiscountModal: true,
            showEditDiscountModalData: { mode: mode ,selectData: selectData,new:true }
        },
        );
    }
   

    renderLabel() {
        return <span>{tr('cost_center')}</span>
    };

    renderLabelValue(code) {
        return code.full_name;
    };
    renderTaxGroupValue(taxGroup) {
        if (!taxGroup) {
            return "";
        }
        let name = taxGroup.name || "";
        let tax = taxGroup.vat ? (taxGroup.vat.value + "%") : "";
        let account = taxGroup.account ? ("(" + taxGroup.account + ")") : "";
        let text = name + " " + tax + " " + account;
        return text
    }
    changeTaxGroup(data, key) {

        let stateData = this.state.data;
        if (key === "component") {
            stateData.default_item_tax_group = data;
            stateData.default_item_tax_group_id = data.id || null;
        }
        else if (key === "work") {
            stateData.default_work_tax_group = data;
            stateData.default_work_tax_group_id = data.id || null;
        }
        else if (key === "expence") {
            stateData.default_expence_tax_group = data;
            stateData.default_expence_tax_group_id = data.id || null;
        }
        
        this.setState({ data: stateData, unsavedChanges: true });
    }

    changeCostCenter(cost_center) {
        const data = this.state.data;
        data.cost_center = cost_center ? cost_center : null;
        data.cost_center_id = cost_center ? cost_center.id : null;
        data.cost_center_group_id = cost_center ? cost_center.group_id : null;
        
        this.setState({ data: data ,unsavedChanges: true});
        
    }

    renderCompany()
    {
        const selectedTab = this.getTabId( this.props.match.params.tab );
        
        let verifiedClass = "unverified";
        let verifiedIcon = "question-circle";
        let verifiedText = tr('info_unverified');

        if( this.state.data.verified )
        {
            const daysAgo = moment().diff( moment( this.state.data.verified ) , "days" );
            if( daysAgo > 365 * 5 )
                verifiedClass = "verified over5Years";
            else if (daysAgo > 365 )
                verifiedClass = "verified overYear";
            else 
                verifiedClass = "verified";

            verifiedIcon = "check-circle";
            verifiedText = `${tr('info_verified_at')} ` + moment( this.state.data.verified ).format('DD.MM.YYYY');
        }

        let errorText = null;

        if (this.props.apCostCenterSettings?.is_required && this.state.data.cost_center == null) {
            errorText = tr('cost_center_is_required');
        }
        if (this.state.costCenterError) {
            errorText = tr('cost_center_end_not_reached');
        }

        return (
            <ApFormPage 
                onSave={ this.saveCompany } 
                loading={ this.state.loading } 
                unsaved={ this.state.unsavedChanges }
                error={ this.state.errorMsg || errorText }
                readOnly={!(auth.hasModule("clients.edit") || auth.hasModule("clients.events") || auth.hasModule("clients.extranet")) }
                noPrompt={ nextUrl => {
                    return nextUrl.startsWith( "/crm/company/" + this.state.data.id + "/" );
                }}
            >
                <div className="apBoxHeader">
                    <h1>{ this.state.data.name ? this.state.data.name : <span style={{ color: "#aaa" }}>{ tr('name_missing') }</span> }</h1>
                
                    <p className={ verifiedClass }>
                        <SvgIcon className="small-inline" icon={ verifiedIcon} />
                        { verifiedText }
                    </p>

                    { auth.hasModule( "clients.edit" ) &&
                        <div className="apBoxCorner padding-small">
                            <div style={{ float: "left", marginRight:10}} className="">
                                {this.getFileButton()}
                            </div>
                            <ApButton  className="verify " size="small" color="green" onClick={ this.verifyCompany } disabled={ this.state.loading || this.state.unsavedChanges } loading={ this.state.loading }>
                                <SvgIcon icon="check" type="solid" />
                                { tr('mark_info_verified') }
                            </ApButton>
                            
                        </div>
                    }
                </div>
                <ApTabs onChange={ this.onTabChange } selected={ selectedTab }>
                    <ApTab icon="building" label={ tr('basics') }>
                        <div className="padding">
                            
                            <div className="clear">

                                <div className="apColumn w50">
                                    <div className="padding">

                                        <ApInput
                                            type="text"
                                            id="name"
                                            name="name"
                                            label={ tr('name') }
                                            value={ this.state.data.name }
                                            onChange={this.handleChange}
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading }
                                            readOnly={ !auth.hasModule( "clients.edit" ) }
                                        />

                                        <ApInput
                                            type="text"
                                            id="taxnumber"
                                            name="taxnumber"
                                            label={ tr('business_id') }
                                            value={ this.state.data.taxnumber }
                                            onChange={ this.handleChange }
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading }
                                            readOnly={ !auth.hasModule( "clients.edit" ) }
                                        />

                                        <ApSelect
                                            label={ tr('responsible_person') }
                                            value={ keyExists( this.state.data, "account_manager.id" ) ? this.state.data.account_manager.name : "" }
                                            optionRenderer="user"
                                            onChange={ ( user ) => { this.handleSelectChange( "account_manager", user ) } }
                                            objKeyId="id"
                                            clearable
                                            apiUrl="crm/company/accountmanagers"
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading }
                                            readOnly={ !auth.hasModule( "clients.edit" ) }
                                            filterNonActives={true}
                                        />
                                        <ApSelect
                                            value={this.state.selectedCompany}
                                            label={tr("parent_company")}
                                            objKeyValue="name"
                                            optionRenderer={(item) => {
                                                if (item.type === "company")
                                                    return (
                                                        <div className="searchOption company">
                                                            <SvgIcon className="typeIcon" icon="building" type="solid" />
                                                            <strong>{item.name}</strong><br />
                                                            <small>{tr('business_id')}: {item.taxnumber}</small>
                                                        </div>
                                                    );
                                                else if (item.type === "contact")
                                                    return (
                                                        <div className="searchOption contact">
                                                            <SvgIcon className="typeIcon" icon="address-card" type="solid" />
                                                            <strong>{item.name}</strong><br />
                                                            <small>{item.title ? item.title : <span className="empty">{tr('no_title')}</span>}</small>
                                                            <span className="company">
                                                                <SvgIcon className="small-inline" icon="building" type="solid" />
                                                                {item.crm_company_name}
                                                            </span>
                                                        </div>
                                                    );
                                            }}
                                            onChange={(item) => { this.setParentCompany(item) }}
                                            loading={this.state.loading}
                                            disabled={this.state.loading || !auth.hasModule("clients.edit")}
                                            objKeyId="id"
                                            clearable
                                            apiUrl="search/crm"
                                        />

                                    </div>
                                    <div className="padding">

                                        <div className={"apFormGroup activeSwitch" + (this.state.data.is_active ? " success" : "")}>
                                            <div className="apSwitchBlock">
                                                <label htmlFor="crm-company-active-switch" className="info">
                                                    {tr('active')}
                                                    <small>{tr('company_active_info')}</small>
                                                </label>
                                                <ApSwitch
                                                    id="crm-company-active-switch"
                                                    on={this.state.data.is_active}
                                                    onChange={this.toggleActiveSwitch}
                                                    disabled={this.state.loading || !auth.hasModule("clients.edit")}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="apColumn w50">
                                    
                                    <div className="padding">
                                        <div className={"apFormGroup activeSwitch" + ( this.state.data.extranet_active ? " success" : "") }>
                                            <div className="apSwitchBlock">
                                                <label htmlFor="crm-company-extranet-active-switch" className="info">
                                                    { tr('extranet_active') }
                                                    <small>{ tr('extranet_active_info') }</small>
                                                </label>
                                                <ApSwitch
                                                    id="crm-company-extranet-active-switch"
                                                    on={ this.state.data.extranet_active }
                                                    onChange={() => this.toggleExtranetSwitch("extranet_active") }
                                                    disabled={ this.state.loading || !auth.hasModule( "clients.edit" ) }
                                                />
                                            </div>
                                        </div>
                                        <div className={"apFormGroup activeSwitch" + (this.state.data.is_customer ? " success" : "")}>
                                            <div className="apSwitchBlock">
                                                <label htmlFor="crm-company-extranet-customer-active-switch" className="info">
                                                    {tr('extranet_customer_active')}
                                                    <small>{tr('extranet_customer_active_info')}</small>
                                                </label>
                                                <ApSwitch
                                                    id="crm-company-extranet-customer-active-switch"
                                                    on={this.state.data.is_customer}
                                                    onChange={() => this.toggleExtranetSwitch("is_customer")}
                                                    disabled={this.state.loading || !auth.hasModule("clients.edit")}
                                                />
                                            </div>
                                        </div>
                                        <div className={"apFormGroup activeSwitch" + (this.state.data.is_supplier ? " success" : "")}>
                                            <div className="apSwitchBlock">
                                                <label htmlFor="crm-company-extranet-supplier-active-switch" className="info">
                                                    {tr('extranet_supplier_active')}
                                                    <small>{tr('extranet_supplier_active_info')}</small>
                                                </label>
                                                <ApSwitch
                                                    id="crm-company-extranet-supplier-active-switch"
                                                    on={this.state.data.is_supplier}
                                                    onChange={() => this.toggleExtranetSwitch("is_supplier")}
                                                    disabled={this.state.loading || !auth.hasModule("clients.edit")}
                                                />
                                            </div>

                                        </div>
                                        <div className={"apFormGroup activeSwitch" + (this.state.data.no_mailing ? " success" : "")}>
                                            <div className="apSwitchBlock">
                                                <label htmlFor="crm-company-no-mailing-active-switch" className="info">
                                                    {tr('no_customer_mailing')}
                                                    {/* <small>{tr('extranet_supplier_active_info')}</small> */}
                                                </label>
                                                <ApSwitch
                                                    id="crm-company-no-mailing-active-switch"
                                                    on={this.state.data.no_mailing}
                                                    onChange={() => this.toggleExtranetSwitch("no_mailing")}
                                                    disabled={this.state.loading || !auth.hasModule("clients.edit")}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="clear">

                                <div className="apColumn w50">
                                    <div className="padding">

                                        <h4 className="formHeader">{ tr('rating') }</h4>

                                        <ApNestedSelect 
                                            label={ tr('industry') }
                                            valueRenderer={ item => item.name }
                                            value={ keyExists( this.state.data, "industry", true ) }
                                            parentRenderer="code"
                                            parentTooltipRenderer="name"
                                            optionRenderer={ ( item, searchText ) => {
                                                return (
                                                    <div className="industryOption">
                                                        <span className="name">{ highlight( searchText, item.name ) }</span>
                                                        <span className="code">{ highlight( searchText, item.code ) }</span>
                                                    </div>
                                                );   
                                            }}
                                            onChange={ ( item ) => { this.handleSelectChange( "industry", item ) } }
                                            apiUrl="crm/company/industries"
                                            loading={ this.state.loading }
                                             disabled={ this.state.loading }
                                            readOnly={ !auth.hasModule( "clients.edit" ) }
                                        />

                                        <ApSelect
                                            label={ tr('groups') }
                                            value={ keyExists( this.state.data, "groups" ) ? this.state.data.groups : [] }
                                            valueRenderer={ (item) => {
                                                if ((item.is_customer === true && this.state.data.is_customer) ||
                                                    (item.is_supplier === true && this.state.data.is_supplier)) {
                                                        return (
                                                            <div className="selectedGroup">
                                                                <SvgIcon className="small-inline" icon="tag" type="solid" />
                                                                {item.name}
                                                            </div>
                                                        );
                                                }
                                                else {
                                                    console.log("selected group not show:",item);
                                                }
                                                  
                                            }}
                                            optionRenderer={ (item) => {
                                                return (
                                                    <div className="groupOption">
                                                        <SvgIcon className="small-inline" icon="tag" type="solid" />
                                                        { item.name }
                                                    </div>
                                                );   
                                            }}
                                            onChange={ ( groups ) => this.handleSelectChange( "groups", groups )  }
                                            objKeyId="id"
                                            objKeySearchable="name"
                                            multiselect
                                            options={ this.getGroupOptions(true) }
                                            //apiUrl="hour/projects"
                                            //apiData={{ exluded: [] }}
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading }
                                            readOnly={ !auth.hasModule( "clients.edit" ) }
                                        />

                                        <ApInputStack gap="0">
                                            <ApAddon noRightBorder width="150px">
                                                { tr('turnover') }
                                            </ApAddon>
                                            <ApInput
                                                type="text"
                                                id="turnover"
                                                name="turnover"
                                                align="right"
                                                value={ this.state.data.turnover }
                                                onChange={ this.handleChange }
                                                loading={ this.state.loading }
                                                disabled={ this.state.loading }
                                                readOnly={ !auth.hasModule( "clients.edit" ) }
                                            />
                                            <ApAddon noLeftBorder width="100px">
                                                EUR
                                            </ApAddon>
                                        </ApInputStack>

                                        <ApInputStack gap="0">
                                            <ApAddon noRightBorder width="150px">
                                                { tr('personnel') }
                                            </ApAddon>
                                            <ApInput
                                                type="text"
                                                id="employs"
                                                name="employs"
                                                align="right"
                                                value={ this.state.data.employs }
                                                onChange={ this.handleChange }
                                                loading={ this.state.loading }
                                                disabled={ this.state.loading }
                                                readOnly={ !auth.hasModule( "clients.edit" ) }
                                            />
                                            <ApAddon noLeftBorder width="100px">
                                                { tr('people_abbr') }
                                            </ApAddon>
                                        </ApInputStack>

                                    </div>
                                </div>

                                <div className="apColumn w50">
                                    <div className="padding">

                                        <h4 className="formHeader">{ tr('contact_details') }</h4>

                                        <ApInput
                                            type="text"
                                            id="phone"
                                            name="phone"
                                            label={ tr('phone') }
                                            value={ this.state.data.phone }
                                            onChange={ this.handleChange }
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading }
                                            readOnly={ !auth.hasModule( "clients.edit" ) }
                                        />

                                        <ApInputStack gap="0">
                                            <ApInput
                                                type="text"
                                                id="email"
                                                name="email"
                                                label={ tr('email_address') }
                                                value={ this.state.data.email }
                                                onChange={ this.handleChange }
                                                loading={ this.state.loading }
                                                disabled={ this.state.loading }
                                                readOnly={ !auth.hasModule( "clients.edit" ) }
                                            />
                                            <ApAddon noLeftBorder noPadding width="50px">
                                                <div className={ "apSimpleButton addon" + ( !validateEmail( this.state.data.email ) ? " disabled": "" ) } onClick={ () => this.sendEmail( this.state.data.email ) }>
                                                    <SvgIcon icon="envelope" type="solid" />
                                                </div>
                                            </ApAddon>
                                        </ApInputStack>
                                        

                                        <ApInputStack gap="0">
                                            <ApInput
                                                type="text"
                                                id="website"
                                                name="website"
                                                label={ tr('website') }
                                                value={ this.state.data.website }
                                                onChange={ this.handleChange }
                                                loading={ this.state.loading }
                                                disabled={ this.state.loading }
                                                readOnly={ !auth.hasModule( "clients.edit" ) }
                                            />
                                            <ApAddon noLeftBorder noPadding width="50px">
                                                <div className={ "apSimpleButton addon" + ( !this.state.data.website ? " disabled": "" ) } onClick={ () => this.openLink( this.state.data.website ) }>
                                                    <SvgIcon icon="external-link-alt" type="solid" />
                                                </div>
                                            </ApAddon>
                                        </ApInputStack>

                                        <ApInputStack gap="0">
                                            <ApAddon noRightBorder width="50%">
                                                { tr('business_language') }
                                            </ApAddon>
                                            <ApInput
                                                type="select"
                                                id="language_id"
                                                name="language_id"
                                                value={this.state.languageId}
                                                options={this.state.languages}
                                                onChange={this.changeLanguage}
                                                loading={this.state.loading}
                                                disabled={this.state.loading || !auth.hasModule("clients.edit")}
                                            />
                                        </ApInputStack>
                                        {this.state.data.wholesales && this.state.data.is_supplier&&<ApInputStack gap="0">
                                            <ApAddon noRightBorder width="50%">
                                                {tr('select_wholesale')}
                                            </ApAddon>
                                            <ApInput
                                                type="select"
                                                id="wholesale"
                                                name="wholesale"
                                                value={this.state.wholesale_id}
                                                label={`${tr('select_wholesale')} *`}
                                                autoComplete="off"
                                                onChange={(e) => this.setState({ wholesale_id: e.target.value, unsavedChanges: true })}
                                                options={this.getWholesales()}
                                                objKeyId="id"
                                                loading={this.state.loading}
                                                disabled={this.state.loading || !auth.hasModule("clients.edit")}
                                                clearable
                                            />
                                        </ApInputStack>}

                                    </div>
                                </div>
                            </div>
                            
                            <div className="clear">
                                
                                <div className="apColumn w50">
                                    <div className="padding">
                                        <h4 className="formHeader">{ tr('sales_order_info') }</h4>
                                        {this.renderOrderSetting()}
                                        <ApSelect
                                            label={tr("default_billing_method")}
                                            value={this.state.default_billing_method ? tr(this.state.default_billing_method.name) : ""}
                                            optionRenderer={(item) => {
                                                return <div>
                                                    {tr(item.name)}
                                                </div>
                                            }}
                                            onChange={(item) => this.setState({ default_billing_method: item, unsavedChanges:true, })}
                                            objKeyId="id"
                                            objKeySearchable="name"
                                            objKeyValue="name"
                                            clearable

                                            options={this.state.settings ? this.state.settings.billing_methods : []}

                                            loading={this.state.loading}
                                            disabled={this.state.loading}
                                            readOnly={!auth.hasModule("clients.edit")}
                                        />

                                        <ApSelect
                                            label={tr('order_grouping_type')}
                                            value={this.state.data.order_grouping_type ? this.state.data.order_grouping_type.label : ""}
                                            optionRenderer={(item) => {
                                                return <div>
                                                    {item.label}
                                                </div>
                                            }}
                                            onChange={(item) => this.handleChange({ target: { name: "order_grouping_type", value: item } })}
                                            objKeyId="value"
                                            objKeySearchable="label"
                                            clearable
                                            options={this.state.data.order_grouping_types ? this.state.data.order_grouping_types : []}
                                            loading={this.state.loading}
                                            disabled={this.state.loading}
                                            readOnly={!auth.hasModule("clients.edit")}
                                            infoText={tr("order_grouping_type_info")}
                                        />

                                        {!this.state.use_tax_groups &&
                                            <ApSelect
                                                label={tr("default_vat")}
                                                value={this.state.default_tax ? (this.state.default_tax.value + " %") : ""}
                                                optionRenderer={(item) => {
                                                    return <div>
                                                        {item.value + " %"}
                                                    </div>
                                                }}
                                                onChange={(item) => this.setState({ default_tax: item, unsavedChanges: true, })}
                                                objKeyId="id"
                                                objKeySearchable="value"
                                            
                                                clearable

                                                options={this.state.settings ? this.state.settings.taxes : []}

                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                readOnly={!auth.hasModule("clients.edit")}
                                            />
                                        }
                                        {!this.state.use_tax_groups &&
                                            <ApSelect
                                                label={tr("default_vat_code")}
                                                value={this.state.default_tax_code ? tr(this.state.default_tax_code.name) : ""}
                                                optionRenderer={(item) => {
                                                    return <div>
                                                        {tr(item.name)}
                                                    </div>
                                                }}
                                                onChange={(item) => this.setState({ default_tax_code: item, unsavedChanges: true, })}
                                                objKeyId="id"
                                                objKeySearchable="name"
                                            
                                                clearable

                                                options={this.state.settings ? Object.values(this.state.settings.tax_codes) : []}

                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                readOnly={!auth.hasModule("clients.edit")}
                                            />
                                        }
                                        

                                        {this.state.use_tax_groups &&
                                            <ApSelect
                                            label={tr('component_tax_group')}
                                            value={this.state.data ? this.renderTaxGroupValue(this.state.data.default_item_tax_group) : ''}
                                            optionRenderer="tax_group"
                                            onChange={(data) => this.changeTaxGroup(data, 'component')}
                                            objKeyId="id"
                                            clearable
                                            apiUrl="order/taxGroups/find"
                                            // apiData={{
                                            //     canReceive: true,
                                            //     projectStorage: false,
                                            // }}
                                            disabled={this.props.loading}
                                            />
                                        }
                                        {this.state.use_tax_groups &&
                                            <ApSelect
                                                label={tr('work_tax_group')}
                                                value={this.state.data ? this.renderTaxGroupValue(this.state.data.default_work_tax_group) : ''}
                                                optionRenderer="tax_group"
                                                onChange={(data) => this.changeTaxGroup(data, 'work')}
                                                objKeyId="id"
                                                clearable
                                                apiUrl="order/taxGroups/find"
                                                // apiData={{
                                                //     canReceive: true,
                                                //     projectStorage: false,
                                                // }}
                                                disabled={this.props.loading}
                                            />
                                        }
                                        {this.state.use_tax_groups &&
                                            <ApSelect
                                                label={tr('expense_tax_group')}
                                                value={this.state.data ? this.renderTaxGroupValue(this.state.data.default_expence_tax_group) : ''}
                                                optionRenderer="tax_group"
                                                onChange={(data) => this.changeTaxGroup(data, 'expence')}
                                                objKeyId="id"
                                                clearable
                                                apiUrl="order/taxGroups/find"
                                                // apiData={{
                                                //     canReceive: true,
                                                //     projectStorage: false,
                                                // }}
                                                disabled={this.props.loading}
                                            />
                                        }
                                        {this.state.cost_center_enabled &&
                                            <ApNestedSelect
                                                label={this.renderLabel}
                                                valueRenderer={this.renderLabelValue}
                                                value={this.state.data.cost_center}
                                                parentRenderer="value"
                                                parentTooltipRenderer="name"
                                                optionRenderer={(item) => {
                                                    return <div className="codeOption">
                                                        <span className="name">{item.nameTree}</span>
                                                        <span className="code">{item.code}</span>
                                                    </div>
                                                }}
                                                onChange={(value) => { this.changeCostCenter(value) }}
                                                apiUrl="costCenter/search"
                                                loading={this.props.loading}
                                                disabled={this.props.loading || this.props.skipped}
                                                validationState={(this.props.apCostCenterSettings?.is_required && this.state.data.cost_center == null) ? "error" : ""}
                                            //tailInput={tailInput}
                                                acceptOnlyLastLevel={true}
                                                isEndReached={endReached => this.setState({ costCenterError: !endReached })}
                                            />
                                        }
                                    </div>
                                </div>

                                {this.state.data.is_customer &&
                                    <div className="apColumn w50">
                                        <div className="padding">
                                            <h4 className="formHeader">{tr('online_billing_info')}</h4>

                                            <ApInput
                                                type="text"
                                                id="e_invoice_ovt"
                                                name="e_invoice_ovt"
                                                label={tr('edi_id')}
                                                value={this.state.data.e_invoice_ovt}
                                                onChange={this.handleChange}
                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                readOnly={!auth.hasModule("clients.edit")}
                                            />

                                            <ApInputStack gap="0">
                                                <ApInput
                                                    type="text"
                                                    id="e-invoice_address"
                                                    name="e_invoice_address"
                                                    label={tr('e_invoice_address')}
                                                    value={this.state.data.e_invoice_address}
                                                    onChange={this.handleChange}
                                                    loading={this.state.loading}
                                                    disabled={this.state.loading}
                                                    readOnly={!auth.hasModule("clients.edit")}
                                                />
                                            </ApInputStack>
                                    

                                            <ApInputStack gap="0">
                                                <ApInput
                                                    type="text"
                                                    id="e_invoice_operator"
                                                    name="e_invoice_operator"
                                                    label={tr('e_invoice_operator_id')}
                                                    value={this.state.data.e_invoice_operator}
                                                    onChange={this.handleChange}
                                                    loading={this.state.loading}
                                                    disabled={this.state.loading}
                                                    readOnly={!auth.hasModule("clients.edit")}
                                                />
                                            </ApInputStack>
                                        </div>
                                    </div>}
                                

                                
                                
                                
                                {this.state.data.show_ext_visio_number &&
                                    <div className="apColumn w50">
                                        <div className="padding">
                                            <h4 className="formHeader">{'Visio'}</h4>

                                            <ApInput
                                                type="text"
                                                id="ext_visio_number"
                                                name="ext_visio_number"
                                                label={tr('customer_number_in_visio')}
                                                value={this.state.data.ext_visio_number}
                                                onChange={this.handleChange}
                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                readOnly={!auth.hasModule("clients.edit")}
                                            />

                                        </div>
                                    </div>}
                                {this.state.data.show_ext_efina_number &&
                                    <div className="apColumn w50">
                                        <div className="padding">
                                            <h4 className="formHeader">{'eFina'}</h4>

                                            <ApInput
                                                type="text"
                                                id="ext_efina_number"
                                                name="ext_efina_number"
                                                label={tr('customer_number_in_efina')}
                                                value={this.state.data.ext_efina_number}
                                                onChange={this.handleChange}
                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                readOnly={!auth.hasModule("clients.edit")}
                                            />

                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </ApTab>
                    <ApTab icon="map-marked-alt" label={ tr('addresses') }>
                        <div className="padding">

                            { auth.hasModule( "clients.edit" ) &&
                                <ApButton color="green" size="small" onClick={ () => this.newItem( "addresses" ) }>
                                    <SvgIcon icon="plus" type="solid" />
                                    { tr('new_address') }
                                </ApButton>
                            }

                            { this.state.data.addresses.length == 0 &&
                                <div className="apMsg">
                                    <SvgIcon className="small-inline" icon="exclamation-triangle" type="solid" />
                                    { tr('no_addresses_added') }
                                </div>
                            }

                            { this.state.data.addresses.length > 0 &&
                                <div className="addresses">
                                    { this.state.data.addresses.map( ( address, key ) => { 
                                        let classes = ["address"];
                                        const defaultShipping = ( address.id == this.state.data.default_shipping_address_id );
                                        const defaultBilling = (address.id == this.state.data.default_billing_address_id);
                                        const defaultSupplier = (address.id == this.state.data.default_supplier_address_id);

                                        if( address.unsaved )
                                            classes.push( "unsaved" );

                                        if( address.removed )
                                            classes.push( "removed" );

                                        if (defaultShipping || defaultBilling || defaultSupplier)
                                            classes.push( "default" );

                                        return (
                                            <div className={ classes.join(" " ) } key={ key }>
                                                <div className="header">
                                                    <SvgIcon className="typeIcon" icon={ this.addressIcons[ address.type ] } type="solid" />
                                                    <strong>{ tr(keyExists( this.state.settings, "addressTypes." + address.type, true, tr('address') )) }</strong>

                                                    <ApDropdown
                                                        actionId={ address }
                                                        actions={[
                                                            {
                                                                label: tr('edit'),
                                                                icon: "edit",
                                                                disabled: address.removed,
                                                                action: ( item, closeFunc ) => {
                                                                    this.editItem( "addresses", item );
                                                                    closeFunc();
                                                                },
                                                            },
                                                            { "divider": `${tr('set_as_primary')}: ` },
                                                            {
                                                                label: tr('for_shipping'),
                                                                icon: "map-marker",
                                                                disabled: address.removed || ( address.id == this.state.data.default_shipping_address_id ),
                                                                action: ( item, closeFunc ) => {
                                                                    this.setDefaultAddress( "shipping", item.id );
                                                                    closeFunc();
                                                                },
                                                            },
                                                            {
                                                                label: tr('for_billing'),
                                                                icon: "star",
                                                                disabled: address.removed || ( address.id === this.state.data.default_billing_address_id),
                                                                action: ( item, closeFunc ) => {
                                                                    this.setDefaultAddress( "billing", item.id );
                                                                    closeFunc();
                                                                },
                                                            },
                                                            {
                                                                label: tr('for_supplier'),
                                                                icon: "boxes",
                                                                disabled: address.removed || (address.id === this.state.data.default_supplier_address_id),
                                                                action: (item, closeFunc) => {
                                                                    this.setDefaultAddress("supplier", item.id);
                                                                    closeFunc();
                                                                },
                                                            },
                                                            { "divider": true },
                                                            {
                                                                label: ( address.removed ? tr('cancel_delete') : tr('delete') ),
                                                                icon: "trash-alt",
                                                                action: ( item, closeFunc ) => {
                                                                    this.removeItem( "addresses", item.id );
                                                                    closeFunc();
                                                                }
                                                            }
                                                        ]}
                                                    />

                                                </div>
                                                <div className="content">
                                                    <strong>{ address.name }</strong><br />
                                                    { address.street }<br />
                                                    { address.postal_code } { address.city }<br />
                                                    { address.country_code_id ? address.country : null }

                                                    { ( defaultShipping || defaultBilling || defaultSupplier) && 
                                                        <div className="defaultIcon">
                                                            { defaultShipping && 
                                                                <ApTooltip text={ tr('primary_shipping_address') }>
                                                                    <SvgIcon icon="map-marker" type="solid" />
                                                                </ApTooltip>
                                                            }
                                                            { defaultBilling &&
                                                                <ApTooltip text={ tr('primary_billing_address') }>
                                                                    <SvgIcon icon="star" type="solid" />
                                                                </ApTooltip>
                                                            }
                                                            {defaultSupplier &&
                                                                <ApTooltip text={tr('primary_supplier_address')}>
                                                                    <SvgIcon icon="boxes" type="solid" />
                                                                </ApTooltip>
                                                            }
                                                        </div>
                                                    }

                                                    { address.unsaved && <SvgIcon className="unsavedIcon" icon="exclamation-circle" type="solid" /> }

                                                </div>
                                            </div>
                                        );        
                                    })}
                                </div>
                            }
                
                        </div>
                    </ApTab>
                    <ApTab icon="address-card" label={ tr('contact_persons') }>
                        <div className="padding">

                            {(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) &&
                                <ApButton color="green" size="small" onClick={ () => this.newItem( "contacts" ) }>
                                    <SvgIcon icon="plus" type="solid" />
                                    { tr('new_contact_person') }
                                </ApButton>
                            }

                            { this.state.data.contacts.length == 0 &&
                                <div className="apMsg">
                                    <SvgIcon className="small-inline" icon="exclamation-triangle" type="solid" />
                                    { tr('no_contact_persons_added') }
                                </div>
                            }

                            { this.state.data.contacts.length > 0 && 
                                <div className="contacts">
                                    { this.state.data.contacts.map( ( contact, key ) => {

                                        let classes = [ "contact" ];

                                        if( contact.id == parseInt( this.props.match.params.contact, 10 ) )
                                            classes.push( "highlighted" );

                                        if( contact.unsaved )
                                            classes.push( "unsaved" );

                                        if( contact.removed )
                                            classes.push( "removed" );

                                        return (
                                            <div className={ classes.join(" ") } key={ key }>
                                                <div className="header">
                                                    <strong>{ ( contact.first_name ? contact.first_name : "" ) + " " +  ( contact.last_name ? contact.last_name : "" ) }</strong><br />
                                                    <small>{ contact.title ? contact.title : <span className="empty">Titteli puuttuu</span> }</small>
                                                
                                                    <ApDropdown
                                                        actionId={ contact }
                                                        actions={[
                                                            {
                                                                label: ( auth.hasModule( "clients.edit.contacts" ) ? "Muokkaa" : "Näytä tiedot" ),
                                                                icon: ( auth.hasModule( "clients.edit.contacts" ) ? "edit" : "eye" ),
                                                                disabled: contact.removed,
                                                                action: ( item, closeFunc ) => {
                                                                    this.editItem( "contacts", item );
                                                                    closeFunc();
                                                                },
                                                            },
                                                            { "divider": true },
                                                            {
                                                                label: ( contact.removed ? "Peruuta poisto" : "Poista yhteystieto" ),
                                                                icon: "trash-alt",
                                                                action: ( item, closeFunc ) => {
                                                                    this.removeItem( "contacts", item.id );
                                                                    closeFunc();
                                                                }
                                                            }
                                                        ]}
                                                    />

                                                </div>
                                                <div className="content">
                                                    <div className="language">
                                                        <SvgIcon className={ contact.language == "-" ? "empty" : "" } icon="flag" type="solid" />
                                                        { contact.language != "-" ? this.getLanguage( contact.language ) : <small>Ei tiedossa</small> }
                                                    </div>
                                                    <div className="groups">
                                                        { contact.groups && contact.groups.map( group => <span className="group"><SvgIcon className="small-inline" icon="tag" type="solid" />{ group.name }</span> )}
                                                        { ( !contact.groups || contact.groups.length == 0 ) && <span><SvgIcon className="empty" icon="tag" type="solid" /><small>Ei ryhmiä</small></span> }
                                                    </div>
                                                    <div className="contactInfo">
                                                        <SvgIcon className={ !contact.phone1 ? "empty" : "" } icon="phone" type="solid" />
                                                        { contact.phone1 ? contact.phone1 : <small>Ei tiedossa</small> }
                                                    </div>
                                                    <div className="contactInfo">
                                                        <SvgIcon className={ !contact.phone2 ? "empty" : "" } icon="phone" type="solid" />
                                                        { contact.phone2 ? contact.phone2 : <small>Ei tiedossa</small> }
                                                    </div>
                                                    <div className="contactInfo">
                                                        <SvgIcon className={ !contact.email ? "empty" : "" } icon="at" type="solid" />
                                                        { contact.email ? contact.email : <small>Ei tiedossa</small> }
                                                    </div>
                                                    { contact.info &&
                                                        <div className="info" onClick={ () => this.editItem( "contacts", contact ) }>
                                                            <ApTooltip text="Sisältää lisätietoa" position="bottom">
                                                                <SvgIcon icon="info-circle" type="solid" />
                                                            </ApTooltip>
                                                        </div>
                                                    }

                                                    { contact.unsaved && <SvgIcon className="unsavedIcon" icon="exclamation-circle" type="solid" /> }

                                                </div>
                                            </div>
                                        ); 
                                    })}
                                </div>
                            }

                        </div>
                    </ApTab>
                    <ApTab icon="comments" label={ tr('events') }>
                        <div className="padding">
                            
                            { auth.hasModule( "clients.events" ) &&
                                <ApButton color="green" size="small" onClick={ () => this.newItem( "events" ) }>
                                    <SvgIcon icon="plus" type="solid" />
                                    { tr('new_event') }
                                </ApButton>
                            }

                            { this.state.data.events.length == 0 &&
                                <div className="apMsg">
                                    <SvgIcon className="small-inline" icon="exclamation-triangle" type="solid" />
                                    { tr('no_events_added') }
                                </div>
                            }

                            { this.state.data.events.length > 0 &&
                                <div>
                                    <div className="clear">
                                        <div className="apColumn w50">
                                            <div className="padding">

                                                <ApInput
                                                    type="select"
                                                    options={[
                                                        { value: '', label: tr('all_users_events') },
                                                        { value: auth.getID(), label: tr('my_events') },
                                                        { label: tr('other_users_events'), options: this.state.eventsUsers }
                                                    ]}
                                                    id="eventsFilterUser"
                                                    name="eventsFilterUser"
                                                    value={ this.state.eventsFilterUser }
                                                    onChange={ ( e ) => this.setState({ eventsFilterUser: e.target.value }) }
                                                    loading={ this.state.loading }
                                                    disabled={ this.state.loading }
                                                />

                                            </div>
                                        </div>
                                        <div className="apColumn w50">
                                            <div className="padding">

                                                <div className={"apFormGroup" + ( this.state.eventsShowDetails ? " success" : "" ) }>
                                                    <div className="apSwitchBlock small">
                                                        <label htmlFor="crm-company-show-event-details-switch" className="info">
                                                            <small>{ tr('show_all_details') }</small>  
                                                        </label>
                                                        <ApSwitch
                                                            small
                                                            id="crm-company-show-event-details-switch"
                                                            on={ this.state.eventsShowDetails }
                                                            onChange={ () => this.setState({ eventsShowDetails: !this.state.eventsShowDetails }) }
                                                            disabled={ this.state.loading }
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                            
                                    <div className="events">
                                        <div className="timeline"></div>

                                        { this.state.data.events.map( ( event, key ) => {
                                                
                                            let classes = [ "event", event.type ];

                                            if( event.unsaved )
                                                classes.push( "unsaved" );

                                            if( event.removed )
                                                classes.push( "removed" );

                                            if( event.showDetails || this.state.eventsShowDetails )
                                                classes.push('detailsOpen');
                                            
                                            if( this.state.eventsFilterUser !== '' )
                                                if( this.state.eventsFilterUser != event.user_id )
                                                    classes.push('filtered');

                                            return (
                                                <div className={ classes.join(" ") } key={ key }>
                                                    <SvgIcon className="eventIcon" icon={ this.eventIcons[ event.type ] } type="solid" />
                                                    <div className="header" onClick={ () => this.toggleEventDetails( event.id ) }>
                                                        <div className="user">
                                                            <ApUserImage user={ event.user } block size="small" tooltip={ event.user.name } />
                                                        </div>
                                                        <strong className="title">{ event.title }</strong><br />
                                                        <small className="time">{ moment( event.occurred_at ).locale(currentLang()).format('LLL') } ({ timeSince( event.occurred_at ) })</small>
                                                    
                                                        { event.unsaved && <SvgIcon className="unsavedIcon" icon="exclamation-circle" type="solid" /> }

                                                    </div>
                                                    <Collapse in={ event.showDetails || this.state.eventsShowDetails }>
                                                        <div>
                                                            <div className="content">
                                                                <p className="description">
                                                                    <strong>{ tr('description') }:</strong><br />
                                                                    { event.description ? event.description : <small>{ tr('description_missing') }</small> }
                                                                </p>
                                                                <p className="action">
                                                                    <strong>{ tr('actions') }:</strong><br />
                                                                    { event.action ? event.action : <small>{ tr('actions_missing') }</small> }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Collapse>

                                                    {/* TODO: Check user permissions for editing events (also other user events?) */}
                                                    { event.user_id == auth.getID() &&
                                                        <ApDropdown
                                                            actionId={ event }
                                                            actions={[
                                                                {
                                                                    label: tr('edit'),
                                                                    icon: "edit",
                                                                    disabled: event.removed,
                                                                    action: ( item, closeFunc ) => {
                                                                        this.editItem( "events", item );
                                                                        closeFunc();
                                                                    },
                                                                },
                                                                { "divider": true },
                                                                {
                                                                    label: ( event.removed ? tr('cancel_delete') : tr('delete') ),
                                                                    icon: "trash-alt",
                                                                    action: ( item, closeFunc ) => {
                                                                        this.removeItem( "events", item.id );
                                                                        closeFunc();
                                                                    }
                                                                }
                                                            ]}
                                                        />
                                                    }
                                                    
                                                </div>
                                            );
                                        })}
                                        <div className="message"> 
                                            <SvgIcon className="eventIcon" icon="building" type="solid" />
                                            { tr('customer_company_added', [this.state.data.name]) }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </ApTab>
                    <ApTab icon="tag" label={tr('discount_percentages')}>
                        <div className="padding">
                            
                            <ApInputStack gap="3">
                                <ApAddon noRightBorder width="340px">
                                    {tr("general_component_discount_percentage")}
                                </ApAddon>
                                <ApInput
                                    type="number"
                                    id="general-component-discount-percentage"
                                    name="general_component_discount_percentage"
                                    value={this.state.data ? this.state.data.general_component_discount :""}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        let data = this.state.data;
                                        data.general_component_discount = value;
                                        this.setState({ data: data, unsavedChanges: true });
                                        // console.log(data);
                                    }}
                                    //options={ this.getCompanyGroupOptions() }
                                    style={{ maxWidth: 100 }}
                                />
                                
                            </ApInputStack>
                            <ApInputStack gap="3">
                                <ApAddon noRightBorder width="340px">
                                    {tr("general_work_discount_percentage")}
                                </ApAddon>
                                <ApInput
                                    type="number"
                                    id="general-work-discount-percentage"
                                    name="general_work_discount_percentage"
                                    value={this.state.data ? this.state.data.general_work_discount : ""}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        let data = this.state.data;
                                        data.general_work_discount = value;
                                        this.setState({ data: data, unsavedChanges: true });
                                        // console.log(data);
                                    }}
                                    //options={ this.getCompanyGroupOptions() }
                                    style={{ maxWidth: 100 }}
                                />

                            </ApInputStack>
                            <ApInputStack gap="3">
                                <ApAddon noRightBorder width="340px">
                                    {tr("general_expense_discount_percentage")}
                                </ApAddon>
                                <ApInput
                                    type="number"
                                    id="general-expense-discount-percentage"
                                    name="general_expense_discount_percentage"
                                    value={this.state.data ? this.state.data.general_expense_discount : ""}
                                    onChange={(e) => {
                                        let value = e.target.value;
                                        let data = this.state.data;
                                        data.general_expense_discount = value;
                                        this.setState({ data: data, unsavedChanges: true });
                                        // console.log(data);
                                    }}
                                    //options={ this.getCompanyGroupOptions() }
                                    style={{ maxWidth: 100 }}
                                />

                            </ApInputStack>
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                {tr('general_discount_percentage_info')}
                            </div>
                            
                            <h4 className="formHeader">{tr("discount_percentage_by_component_type")}</h4>
                            <ApButton color="green" size="medium" onClick={() => this.addNewDiscount("type")}>
                                <SvgIcon icon="plus" type="solid" />
                                {tr('add_new_discount')}
                            </ApButton>
                            <ApReactTable
                                columns={this.TypeColumns()}
                                data={this.state.componentTypeDiscounts}
                                loading={this.state.loading}
                                defaultPageSize={10}

                                rowActions={[
                                    {
                                        label: tr('delete'), icon: "trash", action: (rowId) => {
                                            let data = this.state.componentTypeDiscounts.filter(item => item.id != rowId );
                                            this.setState({ componentTypeDiscounts: data, unsavedChanges: true });
                                    } },
                                ]}
                            />

                        </div>
            
                        <div className="padding">
                            <h4 className="formHeader">{tr("discount_percentage_by_storage_code")}</h4>
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                {tr('discount_percentage_by_storage_code_info')}
                            </div>
                            <ApButton color="green" size="medium" onClick={() => this.addNewDiscount("storage")}>
                                <SvgIcon icon="plus" type="solid" />
                                {tr('add_new_discount')}
                            </ApButton>

                            <ApReactTable
                                columns={this.storagediscountColumns()}
                                data={this.state.storageCodeDiscounts}
                                loading={this.state.loading}
                                defaultPageSize={10}

                                rowActions={[
                                    {
                                        label: tr('delete'), icon: "trash", action: (rowId) => {
                                            let data = this.state.storageCodeDiscounts.filter(item => item.id != rowId);
                                            this.setState({ storageCodeDiscounts: data,unsavedChanges: true });
                                        }
                                    },
                                ]}
                            />

                        </div>
                    </ApTab>
                    
                    {/*this.state.data.is_supplier &&
                    <ApTab icon="users" label={tr('workers')}>
                        <div className="padding">
                        <ApButton color="green" size="small" onClick={ () => this.newItem( "workers" ) }>
                            <SvgIcon icon="plus" type="solid" />
                                { tr('new_worker') }
                        </ApButton>

                        
                        { this.state.data.workers.length == 0 &&
                            <div className="apMsg">
                                <SvgIcon className="small-inline" icon="exclamation-triangle" type="solid" />
                                { tr('no_workers_added') }
                            </div>
                        }

                        { this.state.data.workers.length > 0 &&
                            <div className=' ="workers'>

                                <ApReactTable
                                    columns={this.workerColumns()}
                                    data={this.state.data.workers.filter(item => item.removed !== true)}
                                    loading={this.state.loading}
                                    // defaultPageSize={10}
                                    rowActions={[
                                        {
                                            label:  tr('edit'),
                                            icon: "edit",                                           
                                            action: ( rowId, closeFunc ) => {
                                                let data = this.state.data.workers.filter(item => item.id == rowId );
                                                this.editItem( "workers", data[0] );
                                                closeFunc();
                                            },
                                        },
                                        { "divider": true },
                                        {
                                            label: tr('delete'), 
                                            icon: "trash", 
                                            action: (rowId, closeFunc) => {
                                                this.removeItem( "workers", rowId );
                                               
                                                let data = this.state.workers.filter(item => item.id != rowId );
                                                
                                                this.setState({ workers: data, unsavedChanges: true });   
                                                var wk = {...this.state.data.workers };   
                                                wk=data;                                     
                                                this.setState({wk});
                                                closeFunc();
                                            } 
                                        },
                                    ]}
                                />
                            </div>
                        }
                        </div>
                    </ApTab>
                    */}

                    { auth.hasModule( "clients.extranet" ) &&
                        <ApTab icon="handshake" label="Extranet" align="right">
                            <div className="padding">
                                
                                <ExtranetUserTable 
                                    customerId={parseInt(this.props.match.params.id)} 
                                    editUser={(e) => {this.editUserInfo(e)}}
                                />

                            </div>
                        </ApTab>
                    }

                    </ApTabs>
           
            </ApFormPage>
        );
    }

    renderAddressModal()
    {
        let types = [];
        for( let key in this.state.settings.addressTypes )
            types.push({ 
                id: key, 
                name: this.state.settings.addressTypes[ key ],
                selected: keyExists( this.state.modalData, 'type', true ) == key, 
            });
        return (
            <div className="modalAddress">

                <div className="apOptionBar">
                    { types.map( ( type, index ) => 
                        <div key={ index } className={ "option" + ( type.selected ? " selected" : "" ) } onClick={ () => this.handleModalChange( "type", type.id ) }>
                            <SvgIcon icon={ this.addressIcons[ type.id ] } type="solid" />
                            { tr(type.name) }
                        </div>
                    )}
                </div>
                
                { this.state.modalData.type === "billing" ?
                <ApInput
                    type="text"
                    id="name"
                    name="name"
                    label={ tr('name') }
                    value={ keyExists( this.state.modalData, "name", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                    validationState={ !this.state.modalData.name ? "ostotilauksenerror" : "" }
                    inputRef={ node => this.modalFirstInput = node } 
                /> : null}

                <ApInput
                    type="text"
                    id="street"
                    name="street"
                    label={ tr('street_address') }
                    value={ keyExists( this.state.modalData, "street", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                    validationState={ !this.state.modalData.street ? "error" : "" }
                />

                <ApInputStack>
                    <ApInput
                        width="140px"
                        type="text"
                        id="postal_code"
                        name="postal_code"
                        label={ tr('postal_code') }
                        value={ keyExists( this.state.modalData, "postal_code", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                    />
                    <ApInput
                        type="text"
                        id="city"
                        name="city"
                        label={ tr('locality') }
                        value={ keyExists( this.state.modalData, "city", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                    />
                      <ApCountrySelect
                            country_code_id = { this.state.modalData.country_code_id}
                            ref={node => this.countrySelectRef = node }
                            onChange={ (item) => {
                                if (!item) {
                                    this.setState({ modalData: { ...this.state.modalData, country: null, country_code_id: null } });
                                } else {
                                    this.setState({ 
                                        modalData: {
                                            ...this.state.modalData,
                                            country: item.name,
                                            country_code_id: item.id,
                                            unsaved: true,
                                            selectedCountry: item
                                        }
                                    });
                                }
                            }}
                        /> 
                    {/* <ApInput
                        type="text"
                        id="country"
                        name="country"
                        label={ tr('country') }
                        value={ keyExists( this.state.modalData, "country", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                    /> */}
                </ApInputStack>

            </div>
        );
    }
    openDiscountModal(row,mode) {
        let showEditDiscountModalData = {};

        showEditDiscountModalData.selectValue = {name:row.name}; 
        showEditDiscountModalData.discount = row.discount;
        showEditDiscountModalData.id = row.id;
        showEditDiscountModalData.mode = mode;

        let selectData = [];
        if (mode === "type") {
            showEditDiscountModalData.selectValue.id = row.component_type_id;
            for (let type of this.state.component_types) {
                let add = true
                // tried to filter out used types

                // for (let selectedType of this.state.componentTypeDiscounts) {
                //     if (type.id === selectedType.component_type_id) {
                //         add = false;
                //         break;
                //     }
                // }
                if (add) {
                    selectData.push(type);
                }
            }

        }
        else {
            for (let code of this.state.storage_codes) {
                showEditDiscountModalData.selectValue.id = row.storage_code_id;
                let add = true
                // tried to filter out used codes

                // for (let selectedcode of this.state.storageCodeDiscounts) {
                //     if (code.id === selectedcode.storage_code_id) {
                //         add = false;
                //         break;
                //     }
                // }
                if (add) {
                    selectData.push(code);
                }
            }
        }

        showEditDiscountModalData.selectData = selectData;
        this.setState({
            showEditDiscountModal: true,
            showEditDiscountModalData: showEditDiscountModalData,
        })
    }


    openDiscountModalType(row) {
        this.openDiscountModal(row,"type");
        
    }
    openDiscountModalstorageCode(row) {
        this.openDiscountModal(row, "storage");

    }

    renderDiscountModal() {
        let data = this.state.showEditDiscountModalData ? this.state.showEditDiscountModalData:{};
        
        return (
            <ApModal
                show={this.state.showEditDiscountModal}
                handleClose={() => this.setState({ showEditDiscountModal: false })}
                className="narrow"
                onOpen={this.resetState}
                header={
                    <div className="padding-small">
                        <h4>
                            {data.new ? tr("add_new_discount") : tr("edit_discount")}
                        </h4><br />

                        <h6></h6>
                    </div>
                }
                body={
                    <div className="padding" style={{minHeight:500}}>
                        
                        <ApSelect
                            label={data.mode == "storage" ? tr("storage_code") : tr("storage_component_type")}
                            value={data.selectValue?data.selectValue.name:''}
                            objKeyId="id"
                            objKeySearchable="name"
                            // valueRenderer={(item) => {
                            //     return (
                            //         <div className="selectedGroup">
                            //             <SvgIcon className="small-inline" icon="tag" type="solid" />
                            //             {item.name}
                            //         </div>
                            //     );
                            // }}
                            optionRenderer={(item) => {

                                return (
                                    <div className="groupOption">
                                        <SvgIcon className="small-inline" icon="tag" type="solid" />
                                        {item.code ? (item.code + " " + item.name) : item.name}
                                    </div>
                                );
                            }}
                            onChange={(select) => {
                                let modalData = this.state.showEditDiscountModalData;
                                let name = select.code ? (select.code + " "+select.name):select.name;
                                let value = {...select};
                                value.name = name;
                                modalData.selectValue = value;
                                this.setState({
                                    showEditDiscountModalData: modalData,
                                })
                                
                            }}
                            
                            options={data.selectData ? data.selectData :[]}
                            
                        />
                        <ApInput
                            type="number"
                            id="discount"
                            name="discount"
                            label={tr('discount_percentage')}
                            value={data.discount ? data.discount : ''}
                            onChange={(e) => {
                                let value = e.target.value;
                                let modalData = this.state.showEditDiscountModalData;
                                modalData.discount = value;
                                this.setState({
                                    showEditDiscountModalData: modalData
                                })

                            }}
                            // validationState={!isset("days") ? "error" : ""}
                        //inputRef={ref => this.nameRef = ref}
                        />
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton onClick={() => this.setState({ showEditDiscountModal: false })}>
                            <SvgIcon icon="times" type="solid" />
                            {tr("cancel")}
                        </ApButton>

                        <ApButton color="blue"
                            onClick={this.saveDiscountModal}
                            disabled={false}
                            // className={"save" + (this.state.changes ? " highlight" : "")}
                        >
                            <SvgIcon icon="save" type="solid" />
                            {tr("save")}
                        </ApButton>
                    </div>
                }
            />

            // 
        );
    }
  

    getFileButton() {
        if (this.props.loading) return null;
        return <div className="fileButtonContainer" onClick={() => this.setState({ filesModalShow: true })}>
            <ApTooltip text={tr('files')}>
                <ApButton className="fileButton" color="white" size="small">
                    <SvgIcon icon="file-alt" type="solid" />
                </ApButton>
            </ApTooltip>
        </div>
    }


    saveDiscountModal() {
        let data = this.state.showEditDiscountModalData;
        let selected = data.selectValue;

        
        
        if (data.mode === "storage") {
            let storageCodeDiscounts=this.state.storageCodeDiscounts;
            if (data.new) {
                storageCodeDiscounts.push({ id: "new" + this.newItemCounter++, crm_id: this.state.data.id, name: selected.name, discount: data.discount, storage_code_id: selected.id });

            }
            else {
                let item=storageCodeDiscounts.find((i)=>i.id==data.id);
                item.storage_code_id = selected.id;
                item.discount = data.discount;
                item.name = selected.name;
            }
            this.setState({
                storageCodeDiscounts: storageCodeDiscounts,
                showEditDiscountModalData: {},
                showEditDiscountModal: false,
                unsavedChanges: true,
            })
        }
        else {
            let componentTypeDiscounts = this.state.componentTypeDiscounts;
            if (data.new) {
                componentTypeDiscounts.push({ id: "new" + this.newItemCounter++, crm_id: this.state.data.id, name: selected.name, discount: data.discount, component_type_id: selected.id });

            }
            else {
                let item = componentTypeDiscounts.find((i) => i.id == data.id);
                item.component_type_id = selected.id;
                item.discount = data.discount;
                item.name = selected.name;
            }
            this.setState({
                componentTypeDiscounts: componentTypeDiscounts,
                showEditDiscountModalData: {},
                showEditDiscountModal: false,
                unsavedChanges: true,
            })
            
        }
    }

    renderContactModal()
    {
        return (

            <div className="modalContact">

                <ApInputStack>
                    <ApInput
                        type="text"
                        id="first_name"
                        name="first_name"
                        label={ tr('first_name') }
                        value={ keyExists( this.state.modalData, "first_name", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                        validationState={ !this.state.modalData.first_name ? "error" : "" }
                        inputRef={ node => this.modalFirstInput = node } 
                        readOnly={ !(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                    />
                    <ApInput
                        type="text"
                        id="last_name"
                        name="last_name"
                        label={ tr('last_name') }
                        value={ keyExists( this.state.modalData, "last_name", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                        validationState={ !this.state.modalData.last_name ? "error" : "" }
                        readOnly={ !(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                    />
                </ApInputStack>

                <ApInput
                    type="text"
                    id="title"
                    name="title"
                    label={ tr('title') }
                    value={ keyExists( this.state.modalData, "title", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                    readOnly={ !(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                />

                <br />

                <ApInputStack>
                    <ApInput
                        type="text"
                        id="phone1"
                        name="phone1"
                        label={ tr('phone') }
                        value={ keyExists( this.state.modalData, "phone1", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                        readOnly={ !(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                    />
                    <ApInput
                        type="text"
                        id="phone2"
                        name="phone2"
                        label={ tr('backup_phone') }
                        value={ keyExists( this.state.modalData, "phone2", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                        readOnly={ !(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                    />
                </ApInputStack>

                <ApInput
                    type="text"
                    id="email"
                    name="email"
                    label={ tr('email_address') }
                    value={ keyExists( this.state.modalData, "email", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                    readOnly={!(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                />

                <br />

                <ApSelect
                    label={ tr('groups') }
                    value={ keyExists( this.state.modalData, "groups" ) ? this.state.modalData.groups : [] }
                    valueRenderer={ (item) => {
                        if ((item.is_customer === true && this.state.data.is_customer) ||
                            (item.is_supplier === true && this.state.data.is_supplier)) {
                                return (
                                    <div className="selectedGroup">
                                        <SvgIcon className="small-inline" icon="tag" type="solid" />
                                        {item.name}
                                    </div>
                                );
                        }
                        else {
                            console.log("selected contact group not show:", item);
                        }
                         
                    }}
                    optionRenderer={ (item) => {
                        return (
                            <div className="groupOption">
                                <SvgIcon className="small-inline" icon="tag" type="solid" />
                                { item.name }
                            </div>
                        );   
                    }}
                    onChange={ ( groups ) => this.handleModalChange( "groups", groups )  }
                    objKeyId="id"
                    objKeySearchable="name"
                    multiselect
                    options={ this.getContactGroupOptions() }
                    readOnly={!(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                />

                <ApInputStack gap="0">
                    <ApAddon 
                        noRightBorder
                        width="160px"
                    >
                        { tr('language') }
                    </ApAddon>
                    <ApInput
                        type="select"
                        options={ this.getLanguageOptions() }
                        id="language"
                        name="language"
                        value={ keyExists( this.state.modalData, "language", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                        readOnly={!(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                    />
                </ApInputStack>

                <br />

                <ApInputStack gap="0">
                    <ApAddon 
                        noRightBorder
                        labelFor="extranet_username"
                        width="160px"
                    >
                        <SvgIcon className="small-inline" icon="user" type="solid" />
                        { tr('extranet_account') }
                    </ApAddon>
                    <ApInput
                        type="text"
                        id="extranet_username"
                        name="extranet_username"
                        tooltip={ this.state.usernameError }
                        value={ keyExists( this.state.modalData, "extranet_username", true, '' ) }
                        onChange={ (e) => this.handleUserNameChange( e.target.value ) }
                        readOnly={!(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                        validationState={ this.state.usernameError!="" ? "error" : "" }
                    />
                    <ApAddon laberFor="extranet_username" noLeftBorder>
                        @{ keyExists( this.state.settings, "extranet_suffix", true, "..." ) }
                    </ApAddon>
                    {/*
                    <ApAddon
                        labelFor="extranet_username"
                        noLeftBorder
                    >
                        @extranet
                    </ApAddon>
                    */}
                </ApInputStack>

                <div className={ "apFormGroup" + ( this.state.modalData.extranet_active ? " success" : "" ) }>
                    <div className="apSwitchBlock small">
                        <label htmlFor="change-extranet-active-switch" className="info">
                            { tr('extranet_account_active') }
                            <small> { tr('extranet_account_active_info') } </small>
                        </label>
                        <ApSwitch
                            id="change-extranet-active-switch"
                            on={ this.state.modalData.extranet_active }
                            onChange={(e) => this.handleActiveSwitchChange()}
                            disabled={this.state.loading||!(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet"))}
                        />
                    </div>
                </div>

                <div className={ "apFormGroup" + ( this.state.changePassword ? " success" : "" ) }>

                    <div className="apSwitchBlock small">
                        <label htmlFor="change-password-switch" className="info">
                            { tr('change_password') }
                            <small>{ tr('change_password_info') }</small>
                        </label>
                        <ApSwitch
                            id="change-password-switch"
                            on={ this.state.changePassword }
                            onChange={ () => this.setState({ changePassword: !this.state.changePassword }) }
                            disabled={this.state.loading || !(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet"))}
                        />
                    </div>

                    <Collapse in={ this.state.changePassword }>

                        <div>

                            <ApInputStack gap="0">
                                <ApAddon
                                    noRightBorder
                                    width="160px"
                                    labelFor="extranet_password"
                                >
                                    <SvgIcon className="small-inline" icon="key" type="solid" />
                                    { tr('password') }
                                </ApAddon>
                                <ApInput
                                    type={ this.state.showPassword ? "text" : "password" }
                                    id="extranet_password"
                                    name="extranet_password"
                                    value={ this.state.modalData.extranet_password }
                                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                                    readOnly={!(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                                />
                                <ApAddon
                                    noLeftBorder
                                    width="50px"
                                    tooltip={ this.state.showPassword ? tr('hide_password') : tr('show_password') }
                                    onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                    disabled={!(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet"))}
                                >
                                    <SvgIcon icon={ this.state.showPassword ? "eye" : "eye-slash" } type="solid" />
                                </ApAddon>
                                <ApAddon  
                                    noLeftBorder 
                                    width="50px" 
                                    tooltip={ tr('random_password') }
                                    onClick={(e) => this.handleModalChange("extranet_password", randomPassword())}
                                    disabled={!(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet"))}
                                >
                                    <SvgIcon icon="dice" type="solid" />
                                </ApAddon>
                            </ApInputStack>

                            <div className="apSwitchBlock small">
                                <label htmlFor="email-password-switch" className="info">
                                    <small>{ tr('send_new_password') }<br />
                                        ({ this.state.modalData.email || tr('email_missing') })</small>
                                </label>
                                <ApSwitch
                                    small
                                    id="email-password-switch"
                                    on={ this.state.modalData.send_email && this.state.modalData.extranet_active }
                                    onChange={ () => this.handleModalChange( 'send_email', !this.state.modalData.send_email ) }
                                    disabled={!this.state.modalData.extranet_active || !validateEmail(this.state.modalData.email) || !(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                                />
                            </div>
                        </div>

                    </Collapse>

                </div>
                <div className={"apFormGroup" + (this.state.modalData.no_mailing ? " success" : "")}>
                    <div className="apSwitchBlock small">
                        <label htmlFor="crm-company-contact-no-mailing-active-switch" className="info">
                            {tr('no_customer_mailing')}
                            {/* <small>{tr('no_customer_mailing')}</small> */}
                        </label>
                        <ApSwitch
                            id="crm-company-contact-no-mailing-active-switch"
                            on={this.state.modalData.no_mailing}
                            onChange={(e) => this.handleNoMailingSwitchChange()}
                            disabled={this.state.loading || !(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet"))}
                        />
                    </div>
                </div>
                
                <br />

                <ApInput
                    type="textarea"
                    id="info"
                    name="info"
                    label={ tr('additional_info') }
                    rows={ 5 }
                    value={ keyExists( this.state.modalData, "info", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                    readOnly={!(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet")) }
                />

            </div>
        );
    }

    renderEventsModal()
    {

        let types = [];
        for( let key in this.state.settings.eventTypes )
            types.push({ 
                id: key, 
                name: this.state.settings.eventTypes[ key ],
                selected: keyExists( this.state.modalData, 'type', true ) == key, 
            });

        return (
            <div className="modalEvent">
                
                <div className="apOptionBar">
                    { types.map( ( type, index ) => 
                        <div key={ index } className={ "option" + ( type.selected ? " selected" : "" ) } onClick={ () => this.handleModalChange( "type", type.id ) }>
                            <SvgIcon icon={ this.eventIcons[ type.id ] } type="solid" />
                            { tr(type.name) }
                        </div>
                    )}
                </div>
                
                <ApInput
                    type="text"
                    id="title"
                    name="title"
                    label={ tr('header') }
                    value={ keyExists( this.state.modalData, "title", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                    validationState={ !this.state.modalData.title ? "error" : "" }
                    inputRef={ node => this.modalFirstInput = node } 
                />

                <ApInput
                    type="datetimeV2"
                    id="occurred_at"
                    name="occurred_at"
                    label={ tr('occurred_at') }
                    value={ keyExists( this.state.modalData, "occurred_at" ) ? this.state.modalData.occurred_at : '' }
                    validationState={ !this.state.modalData.occurred_at || !moment( this.state.modalData.occurred_at ).isValid() ? "error" : "" }
                    onChange={ (e) => this.handleModalChange( 'occurred_at', e ) }
                    enableTime
                />

                <ApInput
                    type="textarea"
                    id="description"
                    name="description"
                    label={ tr('description') }
                    rows={ 5 }
                    value={ keyExists( this.state.modalData, "description", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                />

                <ApInput
                    type="textarea"
                    id="action"
                    name="action"
                    label={ tr('actions') }
                    rows={ 5 }
                    value={ keyExists( this.state.modalData, "action", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                />

                <ApInput
                    type="datetimeV2"
                    id="reminded_at"
                    name="reminded_at"
                    label={ tr('reminder') }
                    value={ keyExists( this.state.modalData, "reminded_at" ) ? this.state.modalData.reminded_at : '' }
                    validationState={ !moment( this.state.modalData.reminded_at ).isValid() ? "error" : "" }
                    onChange={(e) => this.handleModalChange("reminded_at", e ) }
                    classes={['top']}
                    enableTime
                />

                <ApInput
                    type="textarea"
                    id="further_actions"
                    name="further_actions"
                    label={ tr('further_actions') }
                    rows={ 5 }
                    value={ keyExists( this.state.modalData, "further_actions", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                />

            </div>
        );
    }

    renderWorkersModal() {
        return (
            <div className="modalWorker">
                {/* <ApInputStack> */}
                    <ApInput
                        type="text"
                        id="company_name"
                        name="company_name"
                        label={ tr('company_name') }
                        value={ keyExists( this.state.modalData, "company_name", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                        validationState={ !this.state.modalData.company_name ? "error" : "" }
                        inputRef={ node => this.modalFirstInput = node }                        
                    />
                     <ApInput
                        type="text"
                        id="company_taxnumber"
                        name="company_taxnumber"
                        label={ tr('company_taxnumber') }
                        value={ keyExists( this.state.modalData, "company_taxnumber", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                        validationState={ !this.state.modalData.company_taxnumber ? "error" : "" }
                        inputRef={ node => this.modalFirstInput = node }                        
                    />
                    <ApInput
                        type="text"
                        id="first_name"
                        name="first_name"
                        label={ tr('first_name') }
                        value={ keyExists( this.state.modalData, "first_name", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                        validationState={ !this.state.modalData.first_name ? "error" : "" }
                        inputRef={ node => this.modalFirstInput = node }                        
                    />
                    <ApInput
                        type="text"
                        id="last_name"
                        name="last_name"
                        label={ tr('last_name') }
                        value={ keyExists( this.state.modalData, "last_name", true, '' ) }
                        onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }
                        validationState={ !this.state.modalData.last_name ? "error" : "" }                        
                    />
                {/* </ApInputStack> */}

                <ApInput
                    type="text"
                    id="nin"
                    name="nin"
                    label={ tr('nin') }
                    value={ keyExists( this.state.modalData, "nin", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }  
                    validationState={ !this.state.modalData.nin ? "error" : "" }                 
                />

              
                <ApInput
                    type="text"
                    id="birthday"
                    name="birthday"
                    label={ tr('date_of_birth') }
                    value={ keyExists( this.state.modalData, "birthday", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }  
                    // validationState={ !this.state.modalData.birthday ? "error" : "" }                 
                />
                   <ApInput
                    type="text"
                    id="taxnumber"
                    name="taxnumber"
                    label={ tr('tax_number') }
                    value={ keyExists( this.state.modalData, "taxnumber", true, '' ) }
                    onChange={ (e) => this.handleModalChange( e.target.name, e.target.value ) }  
                    // validationState={ !this.state.modalData.taxnumber ? "error" : "" }                 
                />
                 <ApSelect
                    label={ tr('employment_type') }
                    name="employment_type"                  
                    value = {!this.state.modalData.new ? tr(this.state.settings.employment_types[this.state.modalData.employment_type-1].description) :"" } 
                    onChange={(select) => {
                        let modalData = this.state.modalData;
                        let id = select.id ;                      
                        let value = {...select};
                        value.id = id;                      
                        modalData.employment_type = value.id;
                        this.setState({
                            modalData: modalData,
                            unsavedChanges:true,
                        })                        
                    }}                 
                   
                    objKeyId="id"
                    objKeyValue="description"
                    objKeySearchable ="description"
                    clearable        
                    options={keyExists(this.state.settings, "employment_types", true) || []}
                    optionRenderer="employment_types"
                    valueRenderer="employment_types"                                 
                  
                />
                            
            </div>
        );
    } 

    isNotRealNin(value) {
        if (!value) {
            return true;
        }
        var n = value.substr(0, (value.length ));
        var val = n.substr(-4, n.length);
        return val.charAt(0) == 9 || value.length <11 ;

    }

    render() 
    {
        const modalClasses = ['narrow'];
        if( this.state.modalType !== "addresses" ) {
            modalClasses.push( "scroll-y" );
        } else {
            modalClasses.push( "overflow-visible" );
        }

        return (
            <div id="pageCrmCompany">

                <div className="pageBackButton" onClick={() => this.props.history.goBack()  }>
                    <SvgIcon icon="arrow-left" type="solid" size="small" />
                    { tr('back') }
                </div>

                { this.state.loading &&
                    <div style={{ position: "relative", margin: "1em" }}>
                        <div className="apLoader"></div>
                    </div>
                }  

                { this.state.data && this.renderCompany() }

                { !this.state.loading && !this.state.data &&
                    <div className="apErrorMsg" style={{ marginTop: "2em" }}>
                        <h3>{ tr('company_not_found') }</h3>
                        <p>{ tr('company_not_found_info') }</p>
                    </div>
                }

                <ApModal
                    show={ this.state.showModal }
                    onOpen={ () => {
                        if( this.modalFirstInput )
                            this.modalFirstInput.select();
                    }}
                    handleClose={ () => this.closeModal() }
                    //closeFromBg
                    className={ modalClasses.join( " " ) }
                    header={ 
                        <div className="padding">
                            { this.state.modalType === "addresses" && <h4>{ this.state.modalData.new ? `${tr('address')} - ${tr('new')}` : `${tr('address')} - ${tr('edit')}` }</h4> }
                            { this.state.modalType === "contacts" && <h4>{ this.state.modalData.new ? `${tr('contact_person')} - ${tr('new')}` : `${tr('contact_person')} - ${tr('edit')}` }</h4> }
                            { this.state.modalType === "events" && <h4>{ this.state.modalData.new ? `${tr('event')} - ${tr('new')}` : `${tr('event')} - ${tr('edit')}` }</h4> }
                            { this.state.modalType === "workers" && <h4>{ this.state.modalData.new ? `${tr('workers')} - ${tr('new')}` : `${tr('workers')} - ${tr('edit')}` }</h4> }
                        </div>
                    }
                    body={ 
                        <div className="padding">
                            { this.state.modalType === "addresses" && this.renderAddressModal() }
                            { this.state.modalType === "contacts" && this.renderContactModal() }
                            { this.state.modalType === "events" && this.renderEventsModal() }
                            { this.state.modalType === "workers" && this.renderWorkersModal() }
                        </div>
                    }
                    footer={
                        <div className="modalFooter">
        
                            <ApButton className="cancel" onClick={ () => this.closeModal() }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>

                            <ApButton className="save" color="green" onClick={ () => this.closeModal( true ) } disabled={ !this.validateModal() }>
                                <SvgIcon icon="check" type="solid" />
                                { tr('continue') }
                            </ApButton>
                        
                        </div>
                    }
                />
                {this.renderDiscountModal()}
                <Files
                    show={this.state.filesModalShow}
                    onClose={() => { this.setState({ filesModalShow: false }) }}
                    crmId={this.getCrmId()}
                    locked={this.props.locked}
                />

            </div>
        );
    }
}


CrmCompany.propTypes = {
    // number:       PropTypes.number.isRequired,
    // string:       PropTypes.string,
    // multiple:     PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
    // bool:         PropTypes.bool,
};



const mapStateToProps = state => {
    return {
        apCostCenterSettings: state.apCostCenterSettings,
    }
}

export default connect(mapStateToProps)(CrmCompany);