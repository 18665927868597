import React from 'react';
import autobind from 'react-autobind';
import { Tree, TreeNode } from 'react-organizational-chart';
import PropTypes from 'prop-types';
import { errorPopper, keyExists, mapTree, tr } from 'services/Helpers/Helpers';
import './ApOrganizationChart.css';
import ApButton from 'common/ApButton/ApButton';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import ApUserImage from 'common/ApUserImage/ApUserImage';

class ApOrganizationChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        autobind(this);
    }

    chartIsValid() {
        if (!this.props.tree) return false;
        if (Array.isArray(this.props.tree) && !this.props.tree.length) return false;
        return true;
    }

    renderNodeLabel(node) {
        const handleCopy = value => {
            if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(value)
                errorPopper(null, tr('copied'), 2000, 'success');
            }
        }

        return <div className='node'>
            <h5>{node.name}</h5>
            <hr />
            {node.sorted_users.map((user, index) => {
                const imageUser = {
                    first_name: keyExists(user, 'person_detail.first_name', true, ''),
                    last_name: keyExists(user, 'person_detail.last_name', true, ''),
                    image: keyExists(user, 'person_detail.image_file_id', true, null),
                }

                return <div key={user.id}>
                    <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ApUserImage
                            user={imageUser}
                            size='small'
                            withShadow
                            imageElement
                        />
                        <small className='user-name'>{user.person_detail.full_name}</small>
                    </span>
                    {user.contract_detail.job_description
                        ? <small>{user.contract_detail.job_description}</small>
                        : null
                    }
                    {user.contact_info.email
                        ? <small><SvgIcon icon='at' type='solid' onClick={() => handleCopy(user.contact_info.email)} className='pointer copy-button' /> {user.contact_info.email}</small>
                        : null
                    }
                    {user.contact_info.email_2
                        ? <small><SvgIcon icon='at' type='solid' onClick={() => handleCopy(user.contact_info.email_2)} className='pointer copy-button' /> {user.contact_info.email_2}</small>
                        : null
                    }
                    {user.contact_info.phone
                        ? <small><SvgIcon icon='phone' type='solid' onClick={() => handleCopy(user.contact_info.phone)} className='pointer copy-button' /> {user.contact_info.phone}</small>
                        : null
                    }
                    {user.contact_info.phone_2
                        ? <small><SvgIcon icon='phone' type='solid' onClick={() => handleCopy(user.contact_info.phone_2)} className='pointer copy-button' /> {user.contact_info.phone_2}</small>
                        : null
                    }

                    {index < node.users.length - 1 ? <hr /> : null}
                </div>
            })}
        </div>
    }

    renderChart() {
        if (this.props.loading) {
            return <div style={{ position: 'relative' }}><div className='apLoader'></div></div>
        }
        if (!this.chartIsValid()) {
            return null;
        }

        const loopNodes = node => {
            return <TreeNode key={node.id} label={this.renderNodeLabel(node)}>
                {(node.children && node.children.length) ? node.children.map(child => loopNodes(child)) : null}
            </TreeNode>
        }
        return this.props.tree.map((item, index) => {
            return <div id={'chart-' + index} key={index} style={{overflow: 'auto'}}>
            {item.children.length 
                ?<>
                    <Tree
                        label={this.renderNodeLabel(item)}
                        lineWidth={'2px'}
                        lineColor={'#0099CC'}
                        lineBorderRadius={'10px'}
                    >
                        {item.children.map(node => loopNodes(node))}
                    </Tree>
                    {index < this.props.tree.length - 1 ? <hr /> : null}
                </>
                : null }
            </div>
        });
    }

    renderPrintButton() {
        if (!this.chartIsValid()) return null;

        return <div className='print-button-container justify-end'>
            <ApButton size='small' color='green' onClick={this.handlePrint}>
                <SvgIcon type='solid' icon='print' /> {tr('print')}
            </ApButton>
        </div>
    }

    handlePrint() {
        const printWindow = window.open(null, 'PRINT');
        const head = document.head.innerHTML;

        printWindow.document.write('<html>');
        printWindow.document.write(head);
        printWindow.document.write('<body >');
        printWindow.document.write(document.getElementById('user-organization-chart').outerHTML);
        printWindow.document.write('</body></html>');

        printWindow.document.close(); // necessary for IE >= 10
        printWindow.focus(); // necessary for IE >= 10*/

        // odotetaan että printti-ikkuna lataa sisällön ennen printtaamista
        printWindow.addEventListener("load", function () {
            setTimeout(() => {
                printWindow.print();
                printWindow.close();
            }, 1000);
        });
    }

    render() {
        return <div id='user-organization-chart'>
            {/* print button removed because styles are missing in print in demo and production */}
            {/* {this.renderPrintButton()} */}
            {this.renderChart()}
        </div>
    }
}

ApOrganizationChart.propTypes = {
    loading: PropTypes.bool,
    tree: PropTypes.array,
};

export default ApOrganizationChart;

