import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import moment from 'moment';

import { Container, Row, Col } from 'react-bootstrap';
import { tr, roundToDecimals, errorPopper } from 'services/Helpers/Helpers.js';
import { ApAddon, ApInputStack } from 'common/ApInput/ApInput.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import UserPermitModal from './UserPermitModal.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js'
import {matchSorter} from 'match-sorter';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';

import './UserWorkPermits.css';

let selecectedRows = [];

export default class UserWorkPermits extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {

			loading: false,

			companyHasPermits: false,
			permits: [],
			people: [],
			groups: [],
			selectedGroups: [],
			personFilter: null,
			selectedPermitId: null,
			selectedPersonId: null,
			selectedGroupName: null,
			arrayData: {},
			selectedKeys: [],
			showEmptyPermits:false,
		};

		autoBind(this);

		this.statusColors = {
			notRequired: "rgba(0,0,0,0.1)",
			required: "#6699FF",
			valid: "#65CE50",
			expiring: "#D7EF4F",
			expired: "#F96D6D",
			notAccepted: 'orange'
		};

	}

	UNSAFE_componentWillMount()
	{

		//this.getUserGroups();
		this.getUserWorkPermits();

	}

	getUserGroups()
	{
		this.setState({ loading: true })
		api({
			method: 'get',
			url: 'usermanagement/groups/getGroups'
		}).then((response) => {
			this.setState({ groups: response, loading: false });
		}).catch((error) => {
			console.log(error);
			this.setState({ loading: false })
		});

	}

	getUserWorkPermits()
	{

		this.setState({ loading: true })
		api({
			method: 'get',
			url: 'usermanagement/permits/getPeopleAndPermits',
			params: { selectedGroups: this.state.selectedGroups }
		}).then((response) => {
			
			
			for (let user of response.people) {
				user.fullName = user.person_detail.last_name + ' ' + user.person_detail.first_name;
				
				for (let permit of user.work_permits) {
					for (let permitName of response.permits) {
						if (permit.permit_id === permitName.id) {
							permit.name = permitName.name;
							permit.order = permitName.order;
							permit.note_before_expiry_dates = permitName.note_before_expiry_dates;
						}
					}
				}
			}
			
			
			this.setState({
				people: response.people,
				permits: response.permits,
				//companyHasPermits: companyHasPermits,
				
			});
			//tarvitaan, että saadaan päivitettyä taulukon järjestys datan lataamisen jälkeen!
			this.setState({ loading: false});

		}).catch((error) => {
			console.log(error);
			this.setState({ loading: false })
		});

	}

	getPermitStatus(permit)
	{
		if (permit == null) {
			return "notgranted";
		}
		const now = moment();
		const accepted = permit.is_accepted;
		const required = permit.is_required;
		const granted = permit.date_granted === null ? false : true;
		const expires = moment(permit.date_expires);
		const tmp = moment(permit.date_expires);
		const noteBefore = permit.note_before_expiry_dates === null ? 0 : permit.note_before_expiry_dates;
		const warning = now >= tmp.add(-noteBefore, 'days');
		const expired = permit.date_expires?now >= expires:true;

		//if (required) return "required";
		if (granted && !warning && !expired && accepted) return "valid";
		//if (granted && warning && !expired && accepted) return "expiring";
		if (granted && warning && !expired) return "expiring";
		if (granted && !accepted) return "notaccepted";
		if (!granted && !expired && !accepted) return "notaccepted";
		if (!granted && !expired && accepted) return "valid";
		if (!granted && expired) return "expired";
		if (granted && expired) return "expired";
		if (granted && expired && required) return "expired";
		if (!granted && expired && required) return "expired";
		if (!granted) return "notgranted";

		return "not available";

	}
	
	

	isPermitRequired(permit)
	{
		if (permit.is_required)
			return true;
		else 
			return false;

	}

	getPermitStatusIcon(permit)
	{

		let element = null;

		switch (this.getPermitStatus(permit))
		{

			case "notgranted":
				element = <div className="guide selection">
					<SvgIcon fill={ this.statusColors.notRequired } icon="circle" />
					</div>;
				break;

			case "valid":
				element = <div className="guide">
					<SvgIcon fill={ this.statusColors.valid } icon="check-circle" type="solid" />
					</div>;
				break;

			case "required":
				element = <div className="guide">
					<SvgIcon fill={ this.statusColors.required } icon="exclamation-circle" type="solid" />
					</div>;
				break;

			case "expiring":
				element = <div className="guide">
					<SvgIcon fill={ this.statusColors.expiring } icon="exclamation-circle" type="solid" />
					</div>;
				break;

			case "notaccepted":
				element = <div className="guide">
					<SvgIcon fill={ this.statusColors.notAccepted } icon="question-circle" type="solid" />
					</div>;
				break;

			case "expired":
				element = <div className="guide">
					<SvgIcon fill={ this.statusColors.expired } icon="times-circle" type="solid" />
					</div>;
				break;

			default:
				console.error("Unknown permit status");


		}

		return element;

	}	

	clickPerson(event, person, permit)
	{

		const personId = person !== null ? person.id : null;
		const permitId = permit !== null ? permit.id : null; 
		event.stopPropagation();

		if (permitId !== null)
			this.setState({ showPermitEditorModal: true, selectedPersonId: personId, selectedPermitId: permitId });

	}

	closePermitEditor()
	{

		this.setState({ 
			showPermitEditorModal: false, selectedPersonId: null, selectedPermitId: null 
		}, () => {
			this.getUserWorkPermits();
		});

	}

	getRequiredStatusColor(permit)
	{

		let color = "#fafafa";
		if (permit.is_required) color = "#6699ff";

		return color;

	}

	changeFilter(params)
	{
		this.setState({ selectedGroups: params.id, selectedGroupName: params.name }, () => { this.getUserWorkPermits() });
	}

	changeNameFilter(params)
	{
		this.setState({ 
			personFilter: params.id,
			personFilterName: params.fullName
		});
	}

	
	openPermitEditor(personId, permitId) {
		
	
		//const permitId = permit ? permit.id : null;
		if (permitId !== null)
			
			this.setState({ showPermitEditorModal: true, selectedPersonId: personId, selectedPermitId: permitId });
	}
	getDaysToExpiry(permit) {
		if (permit == null) return null;
		let now = moment();
		let expires = moment(permit.date_expires);
		let daysToExpiry = 0;

		let diff = roundToDecimals(moment.duration(expires.diff(now)).asDays(), 0);
		//if (diff >= 0) daysToExpiry = diff;
		daysToExpiry = diff;
		return daysToExpiry;
	}
	
	getColums() {
		//luodaan lupataulukon nimi sarake
		let listHeader = [{
			id: 'userName',
			Header: tr("name"),
			accessor: "fullName",
			align: "center",
			width: 150,
			
			className: "stickyColumn",
			headerClassName: "stickyColumn",
			Cell: (props) => {
				let person = props.original.person_detail;
				let tooltip = (
					<>
						<div className="tooltip-row">
							{(person.last_name ? person.last_name : "") + (person.first_name ? (" " + person.first_name) : "") + (person.midle_names ? (" " + person.midle_names):"")}
						</div>
						


					</>
				);
				return <div>
					<ApTooltip text={tooltip} >
						{props.original.fullName}
					</ApTooltip>
				</div >
			},
			
			filterMethod: (filter, row) => {
				
				return row.userName.toLowerCase().includes(filter.value.toLowerCase());
			}
			/*,
			Filter: ({ filter, onChange }) => { },
			*/
		},
			{
				id: "is_active",
				Header: tr('active'),
				accessor: "is_active",
				width: 70,
				customFilter: { type: "toggle" ,default:"true",},
				Cell: (props) => {
					if (props.value) {
						return <div className="text-center"><SvgIcon fill="#4c0" icon="check-circle" type="bold" /></div>
					}
					else {
						return <div className="text-center"><SvgIcon fill="#c40" icon="times-circle" type="bold" /></div>
					}	
				},
				customizable: true,
				showInitially: true,
			}
		];
// generate permits table permit columns
		for (let id in this.state.permits) {
			
			listHeader.push({
				id: this.state.permits[id].id,
				Header: <div title={this.state.permits[id].name} >{this.state.permits[id].name}</div>,
				accessor: (row) => {
					let permit = row.work_permits ? row.work_permits.filter(item => item.permit_id === this.state.permits[id].id)[0] : null;
					return permit ? permit.negativeDaysToExpiry : 9999;
				},
				align: "center",
				width: 150,
				Cell: (props) => {
					let tooltipText;
					let tooltipText2;
					let tooltipText3;
					let daysToExpiry;
					let permit = props.original.work_permits ? props.original.work_permits.filter(item => item.permit_id === this.state.permits[id].id)[0] : null;
				
					if (permit) {
						
						daysToExpiry = this.getDaysToExpiry(permit);
						//multiply -1 because then get first expiring permits to alphabetically order
						permit.negativeDaysToExpiry = daysToExpiry*-1;
						if (permit.date_expires != null) {
							tooltipText = <span>{tr("expiring:") + " " + moment(permit.date_expires).format("DD.MM.YYYY")}</span>
							if (daysToExpiry >= 0) {
								tooltipText2 = <span>{tr("permit_remaining:") + " " + daysToExpiry + " " + tr("days")}</span>;
								if (permit.is_required) {
									tooltipText3 = <span>{tr("permit_required")}</span>
								}
							}
							else {
								tooltipText2 = <span style={{ color: "orange" }}>{tr("permit_is_expired")}</span>;
								if (permit.is_required) {
									tooltipText3 = <span style={{ color: "orange" }}>{tr("permit_required")}</span>
								}
							}
							
						}
						else {
							tooltipText = <span> {tr("permit_is_not_valid")}</span>;
							if (permit.is_required) {
								tooltipText3 = <span style={{ color: "orange" }}>{tr("permit_required")}</span>;
							}
						}
						
					}
					else {
						tooltipText = tr("permit_is_not_valid")
					}
					
					let guideTooltip = (
						<>
							<div className="tooltip-row">
								{tooltipText}
							</div>
							<div className="tooltip-row" >
								{tooltipText2}
								
							</div>

							{tooltipText3 &&
								< div className="tooltip-row" >
							{tooltipText3}
							</div>}
							

						</>
					);
					//console.log(guideTooltip);
					let required = false;
					if (permit != null && permit.is_required) {
						required = permit.is_required
					}
					return <div>
						<ApTooltip text={guideTooltip} >
							<div>
								{this.getPermitStatusIcon(permit)}
								{required ? <SvgIcon fill={this.statusColors.required} icon="exclamation-circle" type="solid" style={{ height: 13 }} /> : ""}
							</div>

							
						</ApTooltip>
						
						
					</div >
					//return props.original.permits[id].name;
					
				},
				onClick: (row) => {
					//console.log(row);
					
					this.openPermitEditor(row.id, this.state.permits[id].id);
				},
				
				filterMethod: (filter, row) => {},
				Filter: ({ filter, onChange }) => { },
			});
			
		}
		
		
		let columns = [
			{
				Header: tr("permits"),
				columns: listHeader,
			}
		];
		//console.log(this.state);
		return columns
		
	}
	
	getSectionActions(reactTableData) {
		const actions = [{
			label: tr('print'),
			icon: "print",
			disabled: this.state.selectedKeys.length == 0,
			action: (rows) => this.selected(rows, reactTableData),
			closeAfter: true,
		}]


		return actions;
	}

	selected(rows, reactTableData) {
		
		this.selecectedRows = rows;
		//console.log("tulostus", this.selecectedRows);
		let data = this.getReactableData(rows, reactTableData);
		this.getPDFPermits(data);
	}

	getReactableData(selectedRows=[],data) {
		let returnData = [];
		//console.log(selectedRows);
		//console.log(selectedRows.includes(1));
		for (let row of selectedRows) {
			//console.log("row", row);
			//console.log("data[]", data[row]);
			let rowdata = data[row].work_permits;
			rowdata.sort(function (a, b) {
				let keyA = a.order;
				let keyB = b.order;
				
				if (keyA < keyB) return -1;
				if (keyA > keyB) return 1;
				return 0;
			});
			returnData.push(data[row]);
		}
		return returnData;
	}


	getPDFPermits(data) {
		if (data.length == 0) {
			return null;
		}
		//this use same method than get pdf for extranet
		
		let sendingData = [];
		// need add user under "user" because extranet use project
		for (let row of data) {
			sendingData.push({ user: row})
		}
		this.setState({ loading: true });
		let url = `usermanagement/permits/getPDFPermits`;

		api({
			method: 'post',
			url: url,
			responseType: 'blob',
			data: { rows: sendingData }
		}).then((response) => {

			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a');
			link.href = url;



			link.setAttribute('download', 'Henkilöiden työluvat ' + moment().format("DD.MM.YYYY")+'.pdf');

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			this.setState({ loading: false });

		}).catch((error) => {
			errorPopper(error, 'Tiedoston lataamisessa tapahtui virhe');
			this.setState({ loading: false });
		});
		//console.log("data",data);
	}

	handleShowEmptyPermits() {
		this.setState({ showEmptyPermits: !this.state.showEmptyPermits });
	}

	render()
	{
		let reactTableData =[] ;
		/*if (this.state.personFilter != null) {
			reactTableData = this.state.people.filter(user => user.id === this.state.personFilter);
		}
		*/
		if (!this.state.showEmptyPermits) {
			for (let row of this.state.people) {
				let empty = true;
				for (let p of row.work_permits) {
					if (p.date_expires !== null || p.date_granted!==null || p.is_required === true || p.is_accepted === true) {
						empty = false;
						break;
					}
				}
				if (!empty) {
					reactTableData.push(row);
				}
				//console.log(row);
			}
		}
		else {
			reactTableData = this.state.people;
		}
		
		return(

			<div id="PageUserWorkPermits" className="apBox">

				<div className="apBoxHeader">
					<h1>{ tr('personnel_work_permits') }</h1>
					<p>{ tr('personnel_work_permits_info') }</p>
				</div>

				<div className="padding">

					{ this.state.loading &&
					<div className="apLoader"></div>
					}
					
					
					<Container fluid={true}>
						<Row>
							<Col sm={5}>
								<ApInputStack>


									<ApAddon width="150px" labelFor="showEmptyPermits" >
										<ApSwitch
											small
											inline
											id="showEmptyPermits"
											on={this.state.showEmptyPermits}
											onChange={(e) => this.handleShowEmptyPermits()}
											disabled={this.state.loading}
										/>
										<span>{tr("show_users_who_not_have_permits")}</span>
									</ApAddon>
								</ApInputStack>
							</Col>
							
							{
								
							/*
							<Col sm={6}>

									<ApSelect
										options={this.state.groups}
										label={tr('filter_by_user_group')}
										objKeyId="id"
										objKeySearchable="name"
										value={keyExists(this.state, "selectedGroupName", true, [])}
										valueRenderer="usergroup"
										optionRenderer="usergroup"
										multiSelect
										clearable
										onChange={(params) => { this.changeFilter(params) }}
									/>

								</Col>
							
								<Col sm={6}>
									<ApSelect
										options={[this.state.people]}
										label={tr('enter_person_name')}
										objKeyId="id"
										objKeySearchable="fullName"
										value={keyExists(this.state, "personFilterName", true, [])}
										valueRenderer="personlist"
										optionRenderer="personlist"
										clearable
										onChange={(params) => { this.changeNameFilter(params) }}
									/>
							</Col>
							*/}
							</Row>
						</Container>
					

					<div className="guides">
						
						<div className="guide">
							<SvgIcon fill={ this.statusColors.valid } icon="check-circle" type="solid" /> { tr('valid') }
						</div>
						
						<div className="guide">
							<SvgIcon fill={ this.statusColors.expiring } icon="exclamation-circle" type="solid" /> { tr('expiring') }
						</div>
						<div className="guide">
							<SvgIcon fill={ this.statusColors.expired } icon="times-circle" type="solid" /> { tr('invalid') }
						</div>
						<div className="guide">
							<SvgIcon fill={ this.statusColors.required } icon="exclamation-circle" type="solid" /> { tr('required') }
						</div>
						<div className="guide">
							<SvgIcon fill={ this.statusColors.notAccepted } icon="question-circle" type="solid" /> { tr('waiting_for_approval1') }
						</div>
					</div>

					

					<ApReactTable

						//manual
						//onFetchData={ this.getUsersApt }
						//pages={ this.state.pages }

						loading={this.state.loading}
						data={reactTableData}
						columns={this.getColums()}
						filterable
						showFiltersInitially
						onSelectChange={(keys) => {
							this.setState({ selectedKeys: keys });
						}}
						multiselect={this.getSectionActions(reactTableData)}
							defaultSorted={[
								{ id: "userName", desc: false }
							]}
						/>

					

				</div>

				<UserPermitModal
					show={this.state.showPermitEditorModal}
					permitId={this.state.selectedPermitId}
					personId={this.state.selectedPersonId}
					onClose={this.closePermitEditor}
				/>

			</div>

		);

	}

}
