/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
//import auth from 'services/Authed/Authed.js';

import './Settings.css';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

import { keyExists, tr } from 'services/Helpers/Helpers.js';

import ProjectTypes from './ProjectTypes/ProjectTypes.js';
import OfferTemplates from './OfferTemplates/OfferTemplates.js';
import OfferDecline from './OfferDecline/OfferDecline.js';
import SubProjectSettings from './SubProjectSettings/SubProjectSettings.js';
import { ApInput, ApAddon, ApInputStack } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';

import FileUpload  from 'common/FileUpload/FileUpload.js';
import File        from 'common/File/File.js';
import SvgIcon     from 'common/SvgIcon/SvgIcon.js';
import ApDropdown       from 'common/ApDropdown/ApDropdown.js';
import ApTooltip   from 'common/ApTooltip/ApTooltip.js';
import ApButton    from 'common/ApButton/ApButton.js';
import ApModal from 'common/ApModal/ApModal.js';
import { Collapse } from 'react-bootstrap';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import { connect } from 'react-redux';
import moment from 'moment';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';

const templateField = {
        current_date: tr('current_date_form'),
        date: tr('assignment_creation_date'),
        name: tr('assignment_title'),
        description: tr('assignment_description'),
        number: tr('assignment_number'),
        manager: tr('assignment_creator'),
        
        worker: tr('assignment_worker'),

        customer_name: tr('customer_name'),
        customer_phone: tr('customer_phone'),
        customer_address: tr('customer_address'),
        customer_zipcode: tr('customer_postal_code'),
        customer_city: tr('customer_city'),
        customer_country: tr('customer_country'),

        customer_order_number: tr('customer_order_number'),

        project_name: tr('project_name'),
        project_code: tr('project_code'),
        
        date_begin: tr('work_begin_date'),
        date_end: tr('work_end_date'),
        hours: tr('estimated_workload'),
        
        
};

const templateRowField = {
    row_filename: tr('files'),
    row_comp_name: tr('storage_component_name'),
    row_comp_balance: tr('storage_component_amount'),
    row_comp_install: tr('installed'),
    pictures: tr('pictures'),
};


const templateRowPropertyFields = {
    row_comp_property_comp_name: tr('storage_component_name'),
    row_comp_property_name: tr('storage_component_property_name'),
    row_comp_property_value: tr('storage_component_property_value'),
    row_comp_property_unit: tr('storage_component_property_unit'),
};

const templateRowCalculatableFields = {
    row_comp_calculatable_comp_name: tr('storage_component_name'),
    row_comp_calculatable_name: tr('storage_component_calculatable_name'),
    row_comp_calculatable_value: tr('storage_component_calculatable_value'),
    row_comp_calculatable_unit: tr('storage_component_calculatable_unit'),
};

const templateRowCalculatableSumFields = {
    row_comp_calculatable_sum_name: tr('storage_component_calculatable_name'),
    row_comp_calculatable_sum_value: tr('storage_component_calculatable_value'),
    row_comp_calculatable_sum_unit: tr('storage_component_calculatable_unit'),
};


class ProjectSettings extends React.Component {
	


	
    constructor(props) 
    {
        super(props);
        this.state = {
            loading:            false,
            error:              false,
            unsavedChanges:     false,
            
            billing_margin_expense: null,
            billing_margin_allowance: null,
            billing_margin_travel: null,

            project_types:      null,
            offer_templates:    null,
            offer_default_end_date: null,

            subproject_code_default_length: null,

            eMailOtsikko:       '',
            eMailText:          '',

            priceType:          0,

            nextNewId:          1,

            decline_reason_enabled: false,
            decline_reasons: [],
            project_storage: [],

            project_storage_none: false,
            project_storage_enabled: false,
            project_storage_required: false,
            project_storage_check_balance_strict: false,
            work_progress_in_timetracking_enabled: false,
            work_progress_in_timetracking_required: false,
            assignment_startclock: false,
            assignment_time_from_assignment: false,
            assignment_date_from_assignment: false,
            assignment_show_assignment_buttons_on_front_page: false,

            work_tasks_retrospective_days: 0,
            automatic_project_enabled: false,
            automatic_project_type: null,
            automatic_project_work: null,
            automatic_project_create_subproject: false,
            allow_ext_assignments: false,
            notifyUsers: [],
            entries_only_if_in_personnel_groups:[],

            project_client_options: '',
            project_billing_options: '',
            project_offer_options: '',
            project_continuous_options: '',

            new_assignment_from_recurring: false,
            remove_on_own_portion_done: false,
            assignment_card_only: true,

            use_user_salary_in_calculations: false,
            group_users_in_income_report: false,
            projectPriceLocked: false,
            confirmLockProjectPriceShow:false,
        };
        autoBind(this); 
    }

  

    componentDidMount()
    {
        this.getSettings();
    }

    lockProjectPrices() {
        this.setState({ loading: true });
        if (!this.state.projectPriceLocked) {
            api({
                method: 'get',
                url: 'projects/settings/runProjectPriceLockCommand',
                
            }).then(response => {
                this.setState({ projectPriceLocked: true });
                this.setState({ loading: false });
    
            }).catch(error => {
                //console.log(error);
                this.setState({
                    error: keyExists(error, "response.data.message", true, tr('unexpected_error')) || "ERROR",
                    loading: false
                });
            });
        }
    }

    getSettings()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'projects/settings'
        }).then(response => {

            //console.log('GET PROJECT SETTINGS', response);
            this.setState({ 
                ...response,
                loading:        false,
                project_types:  response.project_types,
                offer_templates:     response.offer_templates,
                subproject_code_default_length: response.subproject_code_default_length,
                eMailOtsikko: response.eMailDefaultSubject,
                eMailText: response.eMailDefaultText,
                priceType: response.priceType,
                unsavedChanges: false,
                docxId: response.docxId,
                pdfExampleId: response.pdfExampleId,
                decline_reason_enabled: response.decline_reason_enabled,
                decline_reasons: response.decline_reason.data,
                offer_default_end_days: response.offer_default_end_after,

                billing_margin_expense: response.default_billing_margins.default_billing_margin_expense,
                billing_margin_allowance: response.default_billing_margins.default_billing_margin_allowance,
                billing_margin_travel: response.default_billing_margins.default_billing_margin_travel,
                project_storage_none: response.project_storage_none,
                project_storage_enabled: response.project_storage_enabled,
                project_storage_required: response.project_storage_required,
                project_storage_check_balance_strict: response.project_storage_check_balance_strict,
                assignment_startclock: response.assignment_startclock,
                assignment_time_from_assignment: response.assignment_time_from_assignment,
                assignment_date_from_assignment: response.assignment_date_from_assignment,
                assignment_show_assignment_buttons_on_front_page: response.show_assignment_buttons_on_front_page,
                entries_only_if_in_personnel: response.entries_only_if_in_personnel,
                project_ext_work_enabled:response.project_ext_work_enabled,
                work_progress_in_timetracking_enabled: response.work_progress_in_timetracking_enabled,
                work_progress_in_timetracking_required: response.work_progress_in_timetracking_required,
                work_tasks_retrospective_days: response.work_tasks_retrospective_days,
                new_assignment_from_recurring: response.new_assignment_from_recurring,
                remove_on_own_portion_done: response.remove_on_own_portion_done,
                assignment_card_only: response.assignment_card_only,
                use_user_salary_in_calculations: response.use_user_salary_in_calculations,
                group_users_in_income_report: response.group_users_in_income_report,
                assignment_generate_billing_row_after_save_enabled: response.assignment_generate_billing_row_after_save_enabled,
                
                notify_project_ending_enabled: response.notify_project_ending,
                notify_project_ending_before_day: response.notify_project_ending_before_day,
                notify_project_ending_between_day: response.notify_project_ending_between_day,
                notify_project_ending_notify_count: response.notify_project_ending_notify_count,

                lock_new_component_price_enabled: response.lock_new_component_price,
            });
            this.props.saveProjectSettings(response);

        }).catch(error => {
            //console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('unexpected_error') ) || "ERROR",
                loading: false 
            });
        });
    }

    getTemplate( current = null)
    {
        let url = 'projects/settings/template';
        if( current ) url += '/current';
        else url += '/default';

        this.setState({ loading: true });
        api({
            method: 'get',
            url: url,
            responseType: 'blob',
        }).then(( response ) => {
            let fileName = current ? `${tr('assignment_template')}.docx` : `${tr('assignment_template_example')}.docx`;
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName );
            document.body.appendChild(link);
            link.click();
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    onTypeChange( id, column, value )
    {
        let project_types = this.state.project_types.slice();
        const index = project_types.findIndex( p => p.id == id );
        if( index != -1 )
        {
            project_types[ index ][ column ] = value;
            this.setState({ 
                project_types: project_types,
                unsavedChanges: true, 
            });
        }
    }

    onToggle = (id, column) => {
        let project_types = this.state.project_types.slice();
        const index = project_types.findIndex( p => p.id == id );
        if( index != -1 )
        {
            project_types[ index ][ column ] = !project_types[ index ][ column ] || false;
            this.setState({ 
                project_types: project_types,
                unsavedChanges: true, 
            });
        }
    }

    onRemoveType( id )
    {
        let project_types = this.state.project_types.slice();
        const index = project_types.findIndex( p => p.id == id );
        if( index != -1 )
        {
            project_types[ index ].removed = !project_types[ index ].removed;
            this.setState({ 
                project_types: project_types,
                unsavedChanges: true, 
            });
        }
    }

    onSettingChange()
    {
        let project_types = this.state.project_types.slice();
        project_types.push({
            project_client_options: "",
            project_billing_options: "",
            project_offer_options: "",
            project_storage_options: "",
            project_storage_type: "",
            project_storage_selected: "",
        });
        this.setState({ 
            project_types: project_types,
            unsavedChanges: true, 
        });
    }

    getProjectClientOption()
    {
        return [
            { value: 'client_optional', label: tr('optional')},
            { value: 'customer_required', label: tr('customer_required')},
            { value: 'cannot_have_customer', label: tr('no_customer')},
        ]
    }

    getProjectBillingOption()
    {
        return [
            { value: 'client_optional',             label: tr('optional')},
            { value: 'hourly_billing_required',     label: tr('hourly_billed')},
            { value: 'cannot_be_hourly_billable',   label: tr('not_hourly_billed')},
        ]
    }

    getProjectOfferOption()
    {
        return [
            { value: 'offer_optional', label: tr('optional')},
            { value: 'offer_required', label: tr('offer_calculation_required')},
            { value: 'cannot_make_offer', label: tr('no_offer_calculation')},
        ]
    }

    getProjectContinuousOption()
    {
        return [
            { value: 'continuous_optional', label: tr('optional')},
            { value: 'continuous_required', label: tr('continuous_project')},
            { value: 'cannot_be_continuous', label: tr('not_continuous_project')},
        ]
    }

    getProjectStorageOption()
    {
        return [
            { value: 'storage_disabled', label: tr('inactive')},
            { value: 'storage_enabled', label: tr('storage_enabled')},
            { value: 'storage_required', label: tr('storage_required')},
            { value: 'storage_required_project', label: tr('storage_required_project')},
            { value: 'storage_required_normal', label: tr('storage_required_normal')},
        ]
    }


    handleProjectContinuousOptionChange( value )
    {
        this.setState({ 
            project_continuous_options: value
        });
    }

    handleProjectClientOptionChange( value )
    {
        this.setState({ 
            project_client_options: value
        });
    }

    handleProjectBillingOptionChange( value )
    {
        this.setState({ 
            project_billing_options: value
        });
    }

    handleProjectOfferOptionChange( value )
    {
        this.setState({ 
            project_offer_options: value
        });
    }

    onAddType()
    {
        let project_types = this.state.project_types.slice();
        project_types.push({
            id:                         "new" + this.state.nextNewId,
            name:                       "",
            project_client_options:     "client_optional",
            project_billing_options:    "billing_optional",
            project_offer_options:      "offer_optional",
            project_continuous_options: "continuous_optional",
            project_storage_options:    "storage_enabled",
            project_storage:            this.state.project_storage_enabled ? 'storage_enabled':'storage_disabled',
            code_prefix:                "",
            code_default_length:        "",
            projects_count:             0
        });
        this.setState({
            project_types: project_types,
            unsavedChanges: true,
            nextNewId: this.state.nextNewId + 1,
        });
    }

    addDeclineReason( data )
    {
        let decline_reasons = this.state.decline_reasons.slice();
        decline_reasons.push({
            id:                     "new" + this.state.nextNewId,
            decline_reason:         data.decline_reason
        });
        this.setState({
        	decline_reasons: decline_reasons,
            unsavedChanges: true,
            nextNewId: this.state.nextNewId + 1,
        });
    }

    removeDeclineReason( ids )
    {
    	
        let decline_reasons = this.state.decline_reasons.slice();
     
        ids.forEach(function(id){
	        	const index = decline_reasons.findIndex( p => p.id == id );
	            if( index != -1 )
	            {
	            	decline_reasons.splice(index, 1);
	            }
        	});
        	
        this.setState({ 
        	decline_reasons: decline_reasons,
            unsavedChanges: true, 
        });
    }
    
    onOfferChange( data )
    {
        this.setState({ 
            offer_templates: data,
            unsavedChanges: true,
        });
    }

    onRemoveOffer( ids )
    {
        //console.log('remove', ids );
    }

    onAddOffer()
    {
        //console.log('add');
    }

    saveSettings()
    {
        let offer_templates = JSON.parse( JSON.stringify( this.state.offer_templates ) );
        offer_templates = offer_templates.map( oft  => {
            return {
                id:             oft.id,
                name:           oft.name,
                file_id:        oft.file_id,
                description:    oft.description,
                keep_old:       oft.keep_old,
            }
        });

        //console.log('SAVING...', offer_templates );

        const billingMargins = {
            billing_margin_expense: Number(this.state.billing_margin_expense),
            billing_margin_allowance: Number(this.state.billing_margin_allowance),
            billing_margin_travel: Number(this.state.billing_margin_travel)
        }

        let project_storage_required = this.state.project_storage_required;
        if (this.state.project_storage_enabled==false) {
        	project_storage_required=false;
        }

        let project_storage_none = this.state.project_storage_none;
        if (this.state.project_storage_enabled==true) {
            project_storage_none=false;
        }
        let work_progress_in_timetracking_required = this.state.work_progress_in_timetracking_required;
        if (this.state.work_progress_in_timetracking_enabled==false) {
        	work_progress_in_timetracking_required=false;
        }
        //console.log("before save", this.state.project_types)
                
        this.setState({ loading: true, error: false });
        api({
            method: 'post',
            url: 'projects/settings/save',
            data: {
            	template_id : this.state.file?this.state.file.id:null,
                project_types: this.state.project_types,
                offer_templates: offer_templates,
                subproject_code_default_length: this.state.subproject_code_default_length,
                eMailSubject : this.state.eMailOtsikko,
                eMailText: this.state.eMailText,
                priceType: this.state.priceType,
                billing_margins: billingMargins,
                project_storage_none: project_storage_none,
                project_storage_enabled: this.state.project_storage_enabled,
                project_storage_required: project_storage_required,
                project_storage_check_balance_strict: this.state.project_storage_check_balance_strict,
                decline_reason_enabled: this.state.decline_reason_enabled,
                decline_reasons: this.state.decline_reasons,
                entries_only_if_in_personnel: this.state.entries_only_if_in_personnel,
                entries_only_if_in_personnel_groups: this.state.entries_only_if_in_personnel_groups,
                project_ext_work_enabled:this.state.project_ext_work_enabled,
                assignment_startclock: this.state.assignment_startclock,
                assignment_time_from_assignment: this.state.assignment_time_from_assignment,
                assignment_date_from_assignment: this.state.assignment_date_from_assignment,
                show_assignment_buttons_on_front_page: this.state.assignment_show_assignment_buttons_on_front_page,
                offer_default_end_days: this.state.offer_default_end_days,
                work_progress_in_timetracking_enabled: this.state.work_progress_in_timetracking_enabled,
                work_progress_in_timetracking_required: work_progress_in_timetracking_required,
                automatic_project_enabled: this.state.automatic_project_enabled,
                automatic_project_type: this.state.automatic_project_type ? this.state.automatic_project_type.id : null,
                automatic_project_work: this.state.automatic_project_work ? this.state.automatic_project_work.id: null,
                automatic_project_create_subproject: this.state.automatic_project_create_subproject,
                allow_ext_assignments: this.state.allow_ext_assignments,
                notifyUsers: this.state.notifyUsers,
                work_tasks_retrospective_days: this.state.work_tasks_retrospective_days,
                new_assignment_from_recurring: this.state.new_assignment_from_recurring,
                remove_on_own_portion_done: this.state.remove_on_own_portion_done,
                assignment_card_only: this.state.assignment_card_only,
                use_user_salary_in_calculations: this.state.use_user_salary_in_calculations,
                group_users_in_income_report: this.state.group_users_in_income_report,
                assignment_generate_billing_row_after_save_enabled: this.state.assignment_generate_billing_row_after_save_enabled,
                notify_project_ending: this.state.notify_project_ending_enabled,
                notify_project_ending_before_day: this.state.notify_project_ending_before_day,
                notify_project_ending_between_day: this.state.notify_project_ending_between_day,
                notify_project_ending_notify_count: this.state.notify_project_ending_notify_count,

                lock_new_component_price: this.state.lock_new_component_price_enabled,
            }
        }).then(response => {

            //console.log('SAVED', response );
            this.getSettings();

        }).catch(error => {
            //console.log( error );
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('unexpected_error') ) || "ERROR",
                loading: false 
            });
        });
    } 
    

    getPriceTypes()
    {
        return [
            { value: 0, label: ` -- ${tr('select')} -- `},
            { value: 1, label: tr('supplier_price')},
            { value: 2, label: tr('last_purchase_price')},
            { value: 3, label: tr('average_price') },
            { value: 4, label: tr('supplier_price_without_discounts') },
        ]
    }
    
    renderTemplateInfoModal()
    {
        return <div id="PoTemplateInfo">
            <ApModal
                show={ this.state.infoShow }
                handleClose={ () => this.setState({ infoShow: false }) }
                closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            { tr('template_specs') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <p>
                            { tr('template_specs_info', [<a href="https://en.wikipedia.org/wiki/Office_Open_XML" target="_blank" rel="noopener noreferrer">Microsoft Office Open XML</a>, <strong>.docx</strong>]) }
                        </p>
                        <p>
                            <strong>{ tr('assignment_template') }</strong>
                        </p>
                        <p>
                            { tr('listing_of_storage_components_info2') }
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th>{ tr('text') }</th>
                                    <th>{ tr('description') }</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderInfoModalRows() }
                            </tbody>
                        </table>

                        <table>
                            <thead>
                                <tr>
                                    <th>{tr('text')}</th>
                                    <th>{tr('description')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderInfoModalSpecificPropertyRows()}
                            </tbody>
                        </table>

                        <table>
                            <thead>
                                <tr>
                                    <th>{ tr('storage_component_text') }</th>
                                    <th>{ tr('description') }</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.renderInfoModalRows( true ) }
                            </tbody>
                        </table>

                        <table>
                            <thead>
                                <tr>
                                    <th>{tr('storage_component_properties')}</th>
                                    <th>{tr('description')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderInfoModalRowsProperty()}
                            </tbody>
                        </table>

                        <table>
                            <thead>
                                <tr>
                                    <th>{tr('storage_component_calculatables')}</th>
                                    <th>{tr('description')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderInfoModalRowsCalculatable()}
                            </tbody>
                        </table>

                        <table>
                            <thead>
                                <tr>
                                    <th>{tr('storage_component_sum_of_calculatables')}</th>
                                    <th>{tr('description')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderInfoModalRowsCalculatableSum()}
                            </tbody>
                        </table>

                        
                    </div>
                }
            />
        </div>
    }
    
    renderInfoModalRows( template = false )
    {
        const values = template ? templateRowField : templateField;

        let rows = [];
        for( let key in values )
        {
            rows.push(<tr key={ key }>
                <td className="text">{ '${'+key+'}' }</td>
                <td className="description">{ values[ key ] }</td>
            </tr>);
        }
        return rows;
    }

    renderInfoModalSpecificPropertyRows() {
        let rows = {};
        const properties = this.props.apStorageSettings?.properties || [];
        for (let property of properties) {
            // let key = "property_" + property.name.toLowerCase().replaceAll('ä', 'a').replaceAll('ö', 'o').replaceAll('å', 'a').replaceAll(new RegExp('/[^a-zA-Z0-9_-]/', 'g'), '');
            let key = "property_" + property.name.toLowerCase().replaceAll('ä', 'a').replaceAll('ö', 'o').replaceAll('å', 'a').replaceAll(' ', '').replace(/[^\x00-\x7F]/g, "");
            rows[key] = tr('value') + ": " + property.name;
        }
        
        
        return this.createTableRows(rows);
    }
    createTableRows(values = []) {
        let rows = [];
        for (let key in values) {
            rows.push(<tr key={key}>
                <td className="text">{'${' + key + '}'}</td>
                <td className="description">{values[key]}</td>
            </tr>);
        }
        return rows;
    }
    renderInfoModalRowsProperty() {
        return this.createTableRows(templateRowPropertyFields);
    }
    renderInfoModalRowsCalculatable() {
        return this.createTableRows(templateRowCalculatableFields);
    }
    renderInfoModalRowsCalculatableSum() {
        return this.createTableRows(templateRowCalculatableSumFields);
    }
    
    renderTemplateEdit()
    {
        let pdfDom = null;
        if( this.state.pdfExampleId )
        {
            const name = `${tr('po_example')}.pdf`;
            pdfDom = <div>
                <File fileId={ this.state.pdfExampleId } type="pdf" name={ name } />
                <File fileId={ this.state.pdfExampleId } name={ name }>{ tr('download') } { name }</File>
            </div>
        }

        let menuActions = [];

        if( this.state.docxId )
        {
            menuActions.push({
                label: tr('download_current_template'),
                icon: 'file-alt',
                action: ( item, closeFunc ) => {
                    this.getTemplate( 'current' );
                    closeFunc();
                },
            });
        }

        menuActions.push({
            label: tr('download_general_base_template'),
            icon: 'file',
            action: ( item, closeFunc ) => {
                this.getTemplate();
                closeFunc();
            },
        });


        let menuDom = <ApAddon noRightBorder width="40px">
            <ApDropdown
                actions={ menuActions }
                button={ <SvgIcon icon="ellipsis-h" type="solid" /> }
                position="right"
            />
        </ApAddon>

        const DOCX = <ApTooltip text={ tr('docx_edit_info') }>
            <span className="hasTooltip">docx</span>
        </ApTooltip>

        return <div className="poTemplateContainer">
            <h4 className="formHeader">
                <SvgIcon icon="file" type="solid" /> { tr('assignment_print_template') }
            </h4>
            <div className="poTemplateInfoButton">
                <ApButton size="tiny" color="blue" onClick={ () => this.setState({ infoShow: true }) }>
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('assignment_template_specs') }
                </ApButton>
            </div>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                	{ tr('assignment_template_info') } { tr('template_info', [<strong>.pdf</strong>, <strong>{ DOCX }</strong>]) }
            </div>
            <div className="apInfo small">
                <SvgIcon icon="pen-square" type="solid" />
                    { tr('template_edit_info', [<strong>.{ DOCX }</strong>]) }
            </div>

            <div className="apInfo small warning">
                <SvgIcon icon="exclamation-circle" type="solid" />
                { tr('po_template_font_info') }
            </div>

            <ApInputStack gap="0">
                { menuDom }
                <ApAddon width="200px" noRightBorder>
                    { tr('new_template') }
                </ApAddon>
                <FileUpload
                    onUpload={this.fileUpload }
                    description="po-template"
                    accept=".docx"
                    label={ `${tr('select_docx_file')}...` }
                    value={ this.state.file }
                    onClear={ () => { this.setState({ file: null }) }}
                />
            </ApInputStack>
            { pdfDom }
        </div>

    }
            
    fileUpload( file )
    {
        this.setState({
        	unsavedChanges: true,
            file: {
                name: file.original_name,
                id: file.id,
                extension: file.extension,
            }
        });
    }

    handlePriceChange( value )
    {
        this.setState({ priceType: value });
        this.setState({ unsavedChanges: true });
    }

    handleBillingMarginChange(e)
    {
        this.setState(
            {
                [e.target.id]: e.target.value,
                unsavedChanges: true
            }
        )
    }

    handleChange(e) {
        this.setState(
            {
                [e.target.id]: e.target.value,
                unsavedChanges: true
            }
        )
    }

    handleTypeChange(e) {
        const foundType = this.state.project_types.find(type => type.id === e.id);
        if (foundType && foundType.main_project_logging_disabled) {
            this.setState({automatic_project_create_subproject: true})
        }
        this.setState({automatic_project_type: e, unsavedChanges: true});
    }
    renderProjectPriceLockConfirm() {
        return <ApConfirm
            show={this.state.confirmLockProjectPriceShow}
            onClose={() => this.setState({ confirmLockProjectPriceShow: false })}
            onConfirm={() => this.lockProjectPrices()}
            header={tr('lock_new_component_price_by_default_in_project_confirm_header')}
            body={
                <div>
                    <p>
                        {tr('lock_new_component_price_by_default_in_project_confirm_info')}
                    </p>

                </div>
            }
        />;
    }

    render() 
    {        
        let autoProjectError = null;
        if (this.state.automatic_project_enabled) {
            if (!this.state.automatic_project_type)
                autoProjectError = tr('automatic_project_type_missing');
            else if (!this.state.automatic_project_work)
                autoProjectError = tr('automatic_project_work_missing');
        }
        const filteredProjectTypes = this.state.project_types && this.state.project_types.filter(type => {
            if (type.project_billing_options !== 'billing_optional'
            && type.project_offer_options === 'cannot_make_offer') {
                return true;
            }
            return false;
        });
        const getRelatedSwitch = ( args ) => {
            const sName = `${ args.related }_${ args.type }`;
            return <div className="apSwitchBlock">
                <label htmlFor={`${sName}-switch`} className="info">
                    { args.title }
                    <small> { args.sTitle }</small>                  
                    
                </label>
                <ApSwitch
                    id={`${sName}-switch`}
                    on={ this.state[ sName ] }
                    onChange={ () => { this.setState({ [ sName ]: !this.state[ sName ], unsavedChanges:true }) } }
                    disabled={ this.props.loading }
                />
            </div>
        }
        return (
            <div id="pageProjectSettings">

                <ApFormPage 
                    onSave={ this.saveSettings } 
                    loading={ this.state.loading } 
                    unsaved={ this.state.unsavedChanges }
                    error={ this.state.error || autoProjectError }
                >
                    <div className="apBoxHeader">
                        <h1>{ tr('project_settings') }</h1>
                        <p>{ tr('project_settings_info') }</p>
                    </div>

                    <ApTabs>

                        <ApTab icon="list" label={ tr('types_and_numbering') }>

                            <ProjectTypes
                                loading={ this.state.loading }
                                types={ this.state.project_types }
                                onChange={ this.onTypeChange }
                                onToggle={ this.onToggle }
                                onRemove={ this.onRemoveType }
                                onAdd={ this.onAddType }
                                getProjectClientOption={ this.getProjectClientOption}
                                getProjectBillingOption={ this.getProjectBillingOption}
                                getProjectOfferOption={ this.getProjectOfferOption}
                                getProjectContinuousOption={ this.getProjectContinuousOption}
                                getProjectStorageOption={ this.getProjectStorageOption}
                            />
                            <SubProjectSettings
                                loading={ this.state.loading }
                                onChange={ ( data ) => { this.setState( { ...data, unsavedChanges: true } ) } }
                                subproject_code_default_length={ this.state.subproject_code_default_length }
                            />
                        
                        </ApTab>

                        <ApTab icon="cogs" label={ tr('general_settings') }>
                            <div className="padding">
                                    <div className="apInfo small">
                                        <SvgIcon icon="info-circle" type="solid" />
                                        
                                        { tr('general_project_settings') }
                                    </div>
                                { getRelatedSwitch({
                                                related: 'entries_only_if_in',
                                                type: 'personnel',
                                                title: tr('entries_only_if_in_personnel'),
                                                sTitle: tr('entries_only_if_in_personnel_info'),
                                })}
                                
                                <Collapse in={this.state.entries_only_if_in_personnel}>
                                    <div className="indentleft">
                                        <div className="apInfo small">
                                            <SvgIcon icon="info-circle" type="solid" />
                                            {tr('personnel_group_filter_info')}
                                        </div>
                                        <ApSelect
                                            label={tr('personnel_group')}
                                            value={keyExists(this.state, "entries_only_if_in_personnel_groups", true, [])}
                                            valueRenderer={(item) => <div>{item.name}</div>}
                                            optionRenderer={(item) => <div>{item.name}</div>}
                                            onChange={(personnelGroups) => this.setState({ entries_only_if_in_personnel_groups: personnelGroups, unsavedChanges: true })}
                                            objKeyId="id"
                                            clearable
                                            apiUrl="usermanagement/personnelGroups"
                                            // apiData={{
                                            //     all_entries: this.state.all_entries
                                            // }}
                                            loading={this.state.loading}
                                            disabled={this.state.loading}
                                            multiselect
                                        />
                                    </div>
                                </Collapse>

                                { getRelatedSwitch({
                                        related: 'project_ext_work',
                                        type: 'enabled',
                                        title: tr('add_entries_after_ending'),
                                        sTitle: tr('add_entries_after_ending_info'),
                                    }) }

                                { getRelatedSwitch({
                                                related: 'work_progress_in_timetracking',
                                                type: 'enabled',
                                                title: tr('work_progress_in_timetracking'),
                                                sTitle: tr('work_progress_in_timetracking_info'),
                                            }) }

                                { this.state.work_progress_in_timetracking_enabled && getRelatedSwitch({
                                                related: 'work_progress_in_timetracking',
                                                type: 'required',
                                                title: tr('work_progress_in_timetracking_required'),
                                                sTitle: tr('work_progress_in_timetracking_required_info'),
                                            }) }

                                { getRelatedSwitch({
                                    related: 'use_user_salary_in',
                                    type: 'calculations',
                                    title: tr('use_user_salary_in_calculations'),
                                    sTitle: tr('use_user_salary_in_calculations_info'),
                                })}
                                { getRelatedSwitch({
                                    related: 'group_users_in_income',
                                    type: 'report',
                                    title: tr('group_users_in_income_report'),
                                    sTitle: tr('group_users_in_income_report_info'),
                                })}
                                    {getRelatedSwitch({
                                        related: 'lock_new_component_price',
                                        type: 'enabled',
                                        title: tr('lock_new_component_price_by_default_in_project'),
                                        sTitle: tr('lock_new_component_price_by_default_in_project_info'),
                                    })}
                                <Collapse in={this.state.lock_new_component_price_enabled}>
                                    
                                    <div className="apSettingRow" htmlFor="lock-project-prices-button" onClick={() => this.setState({ confirmLockProjectPriceShow :true})}>
                                        <label  className="info">
                                            {tr('lock_new_component_price_by_default_in_project_button')}
                                            <small> {tr('lock_new_component_price_by_default_in_project_confirm_info')}</small>

                                        </label>
                                        <ApButton id="lock-project-prices-button" size="small" color="grey" onClick={() => this.setState({ confirmLockProjectPriceShow: true })}
                                            disabled={this.state.projectPriceLocked}
                                        >
                                            {/* <SvgIcon icon="info-circle" type="solid" /> */}
                                            {tr('lock_new_component_price_by_default_in_project_button')}
                                        </ApButton>
                                        
                                    </div>
                                    

                                </Collapse>
                                
                                {getRelatedSwitch({
                                    related: 'notify_project_ending',
                                    type: 'enabled',
                                    title: tr('notify_project_ending'),
                                    sTitle: tr('notify_project_ending_info'),
                                    
                                })}

                                <Collapse in={this.state.notify_project_ending_enabled}>
                                    <div>
                                        <div className="apInfo small"  style={{margin: '0 0em 0 0em'}}>
                                            <SvgIcon icon="info-circle" type="solid" />   
                                            <label class="info"  >   
                                            <div>
                                                { tr('notify_project_ending_before_day_info') }
                                            </div>                                       
                                            </label>                                     
                                        </div>
                                      
                                    <ApInputStack gap="0">
                                        <ApAddon width="200" noRightBorder>
                                            {/* <SvgIcon className="small-inline" icon="suitcase" type="solid" /> */}
                                            {tr('notify_project_ending_before_day')}    
                                        </ApAddon>
                                        
                                        <ApInput
                                            width="100"
                                            type="number"
                                            name="notify_project_ending_before_day"
                                            id="notify_project_ending_before_day"
                                            align="right"
                                            placeholder={'-'}
                                            value={String(this.state.notify_project_ending_before_day)}
                                            onChange={(e) => { this.handleChange(e) }}
                                            loading={this.state.loading}
                                            disabled={this.state.loading}
                                        />
                                            <ApAddon noLeftBorder width="20">{tr('days')}</ApAddon>
                                    </ApInputStack>

                                    <div className="apInfo small"  style={{margin: '0 0em 0 0em'}}>
                                            <SvgIcon icon="info-circle" type="solid" />   
                                            <label class="info"  >   
                                            <div>
                                                { tr('notify_project_ending_between_day_info') }
                                            </div>                                       
                                            </label>                                     
                                        </div>
                                    <ApInputStack gap="0">
                                        <ApAddon width="200" noRightBorder>
                                            {/* <SvgIcon className="small-inline" icon="suitcase" type="solid" /> */}
                                            {tr('notify_project_ending_between_day')}                                          
                                            
                                        </ApAddon>
                                        <ApInput
                                            width="100"
                                            type="number"
                                            name="notify_project_ending_between_day"
                                            id="notify_project_ending_between_day"
                                            align="right"
                                            placeholder={'-'}
                                            value={String(this.state.notify_project_ending_between_day)}
                                            onChange={(e) => { this.handleChange(e) }}
                                            loading={this.state.loading}
                                            disabled={this.state.loading}
                                        />
                                            <ApAddon noLeftBorder width="20">{tr('days')}</ApAddon>
                                    </ApInputStack>

                                    <div className="apInfo small"  style={{margin: '0 0em 0 0em'}}>
                                            <SvgIcon icon="info-circle" type="solid" />   
                                            <label class="info"  >   
                                            <div>
                                                { tr('notify_project_ending_notify_count_info') }
                                            </div>                                       
                                            </label>                                     
                                        </div>
                                    <ApInputStack gap="0">
                                        <ApAddon width="200" noRightBorder>
                                            {/* <SvgIcon className="small-inline" icon="suitcase" type="solid" /> */}
                                            {tr('notify_project_ending_notify_count')}                                           
                                            
                                        </ApAddon>
                                        <ApInput
                                            width="100"
                                            type="number"
                                            name="notify_project_ending_notify_count"
                                            id="notify_project_ending_notify_count"
                                            align="right"
                                            placeholder={'-'}
                                            value={String(this.state.notify_project_ending_notify_count)}
                                            onChange={(e) => { this.handleChange(e) }}
                                            loading={this.state.loading}
                                            disabled={this.state.loading}
                                        />
                                        <ApAddon noLeftBorder width="20">{tr('days')}</ApAddon>
                                        </ApInputStack>
                                    </div>
                                </Collapse>
                            </div>
                        </ApTab>
                        
                        <ApTab icon="list" label={ tr('prices_to_be_used') }>
                            <div className="padding">
                                <h3>{ tr('offer_calculation') }</h3>
                                { tr('select_component_price_offer_calculation') }:
                                <ApInput
                                    type='select'
                                    options= { this.getPriceTypes() }
                                    onChange={ ( e ) => this.handlePriceChange( e.target.value ) }
                                    value={ this.state.priceType }
                                />

                                <h3>{ tr('project_billing_shares') }</h3>
                                { tr('project_billing_shares_info') }

                                <ApInputStack gap="0">
                                    <ApAddon width="200" noRightBorder>
                                        <SvgIcon className="small-inline" icon="money-bill-wave" type="solid" />
                                        { tr('expense_compensations') }
                                    </ApAddon>
                                    <ApInput
                                        width="100"
                                        type="number"
                                        name="billing_margin_expense"
                                        id="billing_margin_expense"
                                        align="right"
                                        placeholder={ 100 }
                                        value={ this.state.billing_margin_expense }
                                        onChange={ ( e ) => { this.handleBillingMarginChange(e) } } 
                                        loading={ this.state.loading }
                                        disabled={  this.state.loading  } 
                                    />
                                    <ApAddon noLeftBorder width="20">%</ApAddon>
                                </ApInputStack>

                                <ApInputStack gap="0">
                                    <ApAddon width="200" noRightBorder>
                                        <SvgIcon className="small-inline" icon="suitcase" type="solid" />
                                        { tr('daily_allowances') }
                                    </ApAddon>
                                    <ApInput
                                        width="100"
                                        type="number"
                                        name="billing_margin_allowance"
                                        id="billing_margin_allowance"
                                        align="right"
                                        placeholder={ '-' }
                                        value={ String(this.state.billing_margin_allowance) }
                                        onChange={ ( e ) => { this.handleBillingMarginChange(e) } } 
                                        loading={ this.state.loading }
                                        disabled={  this.state.loading  } 
                                    />
                                    <ApAddon noLeftBorder width="20">%</ApAddon>
                                </ApInputStack>

                                <ApInputStack gap="0">
                                    <ApAddon width="200" noRightBorder>
                                        <SvgIcon className="small-inline" icon="car" type="solid" />
                                        { tr('travel_expenses') }
                                    </ApAddon>
                                    <ApInput
                                        width="100"
                                        type="number"
                                        name="billing_margin_travel"
                                        id="billing_margin_travel"
                                        align="right"
                                        placeholder={ 100 }
                                        value={ this.state.billing_margin_travel }
                                        onChange={ ( e ) => { this.handleBillingMarginChange(e) } } 
                                        loading={ this.state.loading }
                                        disabled={  this.state.loading  } 
                                    />
                                    <ApAddon noLeftBorder width="20">%</ApAddon>
                                </ApInputStack>
                            </div>
                        </ApTab>

                        <ApTab icon="cubes" label={ tr('project_storage') }>
                            <div className="padding">
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                       { tr('project_storage_settings_info') }
                                </div>
                                { getRelatedSwitch({
                                    related: 'project_storage',
                                    type: 'enabled',
                                    title: tr('project_storage_in_use'),
                                    sTitle: tr('project_storage_in_use_info'),
                                }) }
                                <Collapse in={ this.state.project_storage_enabled }>
                                    <div className="indentleft">
                                        { getRelatedSwitch({
                                            related: 'project_storage',
                                            type: 'required',
                                            title: tr('project_storage_required'),
                                            sTitle: tr('project_storage_required_info'),
                                        }) }
                                        { getRelatedSwitch({
                                            related: 'project_storage',
                                            type: 'check_balance_strict',
                                            title: tr('project_storage_check_balance'),
                                            sTitle: tr('project_storage_check_balance_info'),
                                        }) }
                                    </div>
                                </Collapse>
                                <Collapse in={ !this.state.project_storage_enabled }>
                                <div className="indentleft">
                                    { getRelatedSwitch({
                                        related: 'project_storage',
                                        type: 'none',
                                        title: tr('project_no_storages'),
                                        sTitle: tr('project_no_storages_info'),
                                    }) }
                                </div>
                            </Collapse>
                            </div>
                        </ApTab>
                        
                        <ApTab icon="file-word" label={ tr('offer_file_templates') }>
                        
                            <OfferTemplates
                                loading={ this.state.loading }
                                rows={ this.state.offer_templates }
                                onChange={ this.onOfferChange }
                                onRemove={ this.onRemoveOffer }
                                onAdd={ this.onAddOffer }
                            />

                            <div style={{margin: '0 1em 0 1em'}}>
                                <ApInputStack key="uload" gap="0">
                                    <ApAddon width="320px"  noRightBorder>
                                        { tr('offer_message_default_title') }:
                                    </ApAddon>
                                    <ApInput
                                        label={ tr('offer_message_default_title') }
                                        type="text"
                                        id="sent_otsikko"
                                        name="sent_otsikko"
                                        value={ this.state.eMailOtsikko }
                                        onChange={ ( e ) => { 
                                            this.setState({ eMailOtsikko: e.target.value });
                                            this.setState({ unsavedChanges: true }); 
                                        } }
                                        //onChange={ this.onOfferChange }
                                        loading={ this.state.loading }
                                        disabled={ this.state.loading }
                                    />
                                </ApInputStack>

                                <ApInputStack key="mload" gap="0">
                                    <ApAddon width="320px" noRightBorder style={{height: '85px'}}>
                                        { tr('offer_message_default_text') }:
                                    </ApAddon>
                                    <ApInput
                                        label={ tr('offer_message_default_text') }
                                        type="textarea"
                                        id="sent_text"
                                        name="sent_text"
                                        value={ this.state.eMailText }
                                        onChange={ ( e ) => { 
                                            this.setState({ eMailText: e.target.value });
                                            this.setState({ unsavedChanges: true });
                                        } }
                                        loading={ this.state.loading }
                                        disabled={ this.state.loading }
                                    />
                                </ApInputStack>

                                <ApInputStack key="oload" gap="0">
                                    <ApAddon width="320px" noRightBorder>
                                        { tr('offer_valid_by_default') }
                                    </ApAddon>
                                    <ApInput
                                        type="number"
                                        id="offer_default_end_date"
                                        name="offer_default_end_date"
                                        //label="Päättymispäivä"
                                        value={ this.state.offer_default_end_days }
                                        onChange={ ( e ) => { 
                                            const days = Math.round(Number(e.target.value))
                                            this.setState({
                                                offer_default_end_days: days,
                                                unsavedChanges: true
                                            } )
                                        }}
                                        loading={ this.props.loading }
                                        disabled={ this.props.loading }
                                        clearable
                                    />
                                    <ApAddon width="250px" noLeftBorder>
                                        { tr('days_after_offer_was_made') }
                                    </ApAddon>
                                </ApInputStack>
                            </div>

                        </ApTab>
                            <ApTab icon="file-word" label={ tr('assignment_file_templates') }>
                            <div className="padding">
                            	{this.renderTemplateEdit()}
                            </div>
                        </ApTab>
                        <ApTab icon="times-circle" label={ tr('offer_decline_reasons') }>
                            <div className="padding">
                                <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('decline_reason_info') }
                            </div>
                            { getRelatedSwitch({
                                    related: 'decline_reason',
                                    type: 'enabled',
                                    title: tr('decline_reasons_in_use'),
                                    sTitle: tr('decline_reasons_in_use_info'),
                                }) }
                                <Collapse in={ this.state.decline_reason_enabled }>
                                    <div className="indentleft">
                                        <OfferDecline
                                        instance="decline_reason"
                                        loading={ this.state.loading }
                                        onRemove={ this.removeDeclineReason }
                                        onAdd={ this.addDeclineReason }
                                        rows={ this.state.decline_reasons }
                                        //onChange={ ( data ) => { this.setState( { ...data, unsavedChanges: true } ) } }
                                        />
                                    </div>                        
                                </Collapse>
                            </div>      
                        </ApTab>
                        <ApTab icon="tasks" label={ tr('assignment') }>
                            <div className="padding">
                                    <div className="apInfo small">
                                        <SvgIcon icon="info-circle" type="solid" />
                                        
                                        { tr('assignment_settings') }
                                    </div>
                                    <ApInputStack gap="0">
                                        <ApAddon>
                                            {tr('work_tasks_retrospective_days')}
                                        </ApAddon>
                                        <ApInput
                                            width="100"
                                            type="number"
                                            name="work_tasks_retrospective_days"
                                            id="work_tasks_retrospective_days"
                                            align="right"
                                            placeholder={ tr('day_abbr') }
                                            value={ String(this.state.work_tasks_retrospective_days) }
                                            onChange={ ( e ) => { this.setState({work_tasks_retrospective_days: e.target.value, unsavedChanges: true}) } } 
                                            loading={ this.state.loading }
                                            disabled={  this.state.loading  } 
                                        />
                                        <ApAddon width='100px'>
                                            {tr('days')}
                                        </ApAddon>
                                    </ApInputStack>
                                { getRelatedSwitch({
                                                related: 'assignment',
                                                type: 'startclock',
                                                title: tr('assignment_start_clock'),
                                                sTitle: tr('assignment_start_clock_info'),
                                })}
                                { getRelatedSwitch({
                                    related: 'assignment',
                                    type: 'time_from_assignment',
                                    title: tr('assignment_time_from_assignment'),
                                    sTitle: tr('assignment_time_from_assignment_info'),
                                })}
                                {getRelatedSwitch({
                                    related: 'assignment',
                                    type: 'date_from_assignment',
                                    title: tr('assignment_date_from_assignment'),
                                    sTitle: tr('assignment_date_from_assignment_info'),
                                })}
                                { getRelatedSwitch({
                                    related: 'remove',
                                    type: 'on_own_portion_done',
                                    title: tr('remove_on_own_portion_done_setting'),
                                    sTitle: tr('remove_on_own_portion_done_info'),
                                }) }
                                { getRelatedSwitch({
                                    related: 'automatic_project',
                                    type: 'enabled',
                                    title: tr('create_project_automatically_enabled'),
                                    sTitle: tr('create_project_automatically_enabled'),
                                }) }
                                <Collapse in={this.state.automatic_project_enabled}>
                                    <div style={{paddingLeft:'20px'}}>
                                        <small>
                                            <SvgIcon icon='info-circle' type='solid' className='size-small' /> {tr('automatic_project_type_info')}
                                        </small>
                                        <ApSelect 
                                            label={tr('project_type')}
                                            value={ this.state.automatic_project_type }
                                            options={filteredProjectTypes}
                                            optionRenderer={(item)=><div>{item.name}</div>}
                                            onChange={ (e) => this.handleTypeChange(e) }
                                            objKeyId="id"
                                            objKeyValue="name"
                                            objKeySearchable="name"
                                        />
                                        <div className="apSwitchBlock">
                                            <label htmlFor={'automatic_project_create_subproject-switch'} className="info">
                                                { tr('create_project_subproject') }
                                                <small>{ tr('create_project_subproject_subtitle') }</small>
                                            </label>
                                            <ApSwitch
                                                id={'automatic_project_create_subproject-switch'}
                                                on={ this.state.automatic_project_create_subproject }
                                                onChange={ () => { this.setState({ automatic_project_create_subproject: !this.state.automatic_project_create_subproject, unsavedChanges:true }) } }
                                                disabled={ 
                                                    this.props.loading || 
                                                    this.state.automatic_project_type && 
                                                    this.state.automatic_project_type.main_project_logging_disabled
                                                }
                                            />
                                        </div>
                                        <ApSelect
                                            label={tr('default_project_work')}
                                            value={this.state.automatic_project_work}
                                            optionRenderer="component_detail"
                                            onChange={ ( c ) => this.setState( {automatic_project_work: c, unsavedChanges: true} ) }
                                            objKeyId="id"
                                            objKeyValue="name"
                                            apiUrl="storage/components"
                                            apiData={{
                                                include: [ 'identifiers' ],
                                                formatter: 'offer_calculate',
                                                type: 'work',
                                            }}
                                        />
                                    </div>
                                </Collapse>
                                { getRelatedSwitch({
                                    related: 'allow_ext',
                                    type: 'assignments',
                                    title: tr('enable_ext_assignments'),
                                    sTitle: tr('enable_ext_assignments_info'),
                                }) }
                                {this.state.extAssignmentToken && this.state.allow_ext_assignments &&
                                <div style={{paddingLeft: '20px'}}>
                                    <small>
                                        <SvgIcon icon='info-circle' type='solid' className='size-small' /> 
                                        {tr('company_code_info', [moment(this.state.extAssignmentToken.valid_to).format('L')])}
                                    </small>
                                    <pre>{this.state.extAssignmentToken.auth_token}</pre>
                                    <small>
                                        <SvgIcon icon='info-circle' type='solid' className='size-small' /> {tr('ext_assignment_notification_info')}
                                    </small>
                                    <ApSelect
                                        label={ tr('person') }
                                        value={ keyExists( this.state, "notifyUsers", true, [] ) }
                                        valueRenderer="user"
                                        optionRenderer="user"
                                        onChange={ ( users ) => this.setState({ notifyUsers: users, unsavedChanges: true }) }
                                        objKeyId="id"
                                        clearable
                                        apiUrl="project/find/manager"
                                        apiData= {{withGroups: false}}
                                        filterNonActives={true}
                                        loading={ this.state.loading }
                                        disabled={ this.state.loading }
                                        multiselect
                                    />
                                </div>}
                                { getRelatedSwitch({
                                    related: 'new_assignment_from',
                                    type: 'recurring',
                                    title: tr('create_new_assignment_from_recurring'),
                                    sTitle: tr('create_new_assignment_from_recurring_info'),
                                }) }
                                { getRelatedSwitch({
                                    related: 'assignment',
                                    type: 'card_only',
                                    title: tr('assignment_card_only_setting'),
                                    sTitle: tr('assignment_card_only_setting_info'),
                                })}
                                {getRelatedSwitch({
                                    related: 'assignment',
                                    type: 'show_assignment_buttons_on_front_page',
                                    title: tr('show_assignment_buttons_on_front_page'),
                                    sTitle: tr('show_assignment_buttons_on_front_page_info'),
                                })}
                                {getRelatedSwitch({
                                    related: 'assignment_generate_billing_row_after_save',
                                    type: 'enabled',
                                    title: tr('assignment_generate_billing_row_after_save_enabled'),
                                    sTitle: tr('assignment_generate_billing_row_after_save_enabled_info'),
                                })}
                            </div>
                        </ApTab>
                    </ApTabs>

                </ApFormPage>
                {this.renderTemplateInfoModal()}
                {this.renderProjectPriceLockConfirm()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    apProjectSettings: state.apProjectSettings,
    apStorageSettings: state.apStorageSettings,
});

const mapDispatchToProps = dispatch => ({
    saveProjectSettings: (settings) => dispatch({type: 'SAVE_PROJECT_SETTINGS', payload: settings})
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
