import React from 'react';
import autoBind from 'react-autobind';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApAddon, ApInputStack } from 'common/ApInput/ApInput.js';
import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import { errorPopper, parseNumber, sortByField, tr } from 'services/Helpers/Helpers.js';
import moment from 'moment';
import ApSelect from 'common/ApSelect/ApSelect';

export default class PersonnelModal extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			projectId: null,
			workId: null,
			itemId: null,
			hours: null,
			minutes: null,
			title: '',
			saveDisabled: false,
			error: null,
			start: props.selectedItem ? moment(props.selectedItem.start_time).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
			end: props.selectedItem ? moment(props.selectedItem.end_time).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
			invalidDate: false,

			hoursWeekly: this.props.selectedItem ? this.props.selectedItem.user.contract_detail.hours_weekly : 40,
			hoursDaily: this.props.selectedItem ? this.props.selectedItem.user.contract_detail.hours_daily : 8,
			daysWeekly: this.props.selectedItem ? this.props.selectedItem.user.contract_detail.days_weekly : 5,
		};

		autoBind(this);

	}

	componentDidUpdate(prevProps, prevState)
	{
        if (this.props.selectedItem && prevProps.selectedItem !== this.props.selectedItem)
        {
			if (this.props.selectedItem && !this.props.selectedItem.isProjectSchedule) {
				const works = this.getWorkOptions(this.props.selectedItem.group)
	
				this.setState({ 
					projectId: this.props.selectedItem.group,
					workId: this.props.selectedItem.work ? this.props.selectedItem.work : (works[0] ? works[0].value : null),
					itemId: this.props.selectedItem.id,
					title: `${this.props.selectedItem.user.user.name} - ${Math.round((this.props.selectedItem.value) * 10) / 10}h`,
					hoursWeekly: this.props.selectedItem.user.contract_detail.hours_weekly ? this.props.selectedItem.user.contract_detail.hours_weekly : 40,
					hoursDaily: this.props.selectedItem.user.contract_detail.hours_daily ? this.props.selectedItem.user.contract_detail.hours_daily : 8,
					daysWeekly: this.props.selectedItem.user.contract_detail.days_weekly ? this.props.selectedItem.user.contract_detail.days_weekly : 5,
					error: null,
					start: this.props.selectedItem ? moment(this.props.selectedItem.start_time) : moment(),
					end: this.props.selectedItem ? moment(this.props.selectedItem.end_time).subtract(1, 'day') : moment(),
				})
				if (this.props.selectedItem && moment(this.props.selectedItem.end_time).subtract(1, 'day').isBefore(moment(this.props.selectedItem.start_time))) {
					this.setState({end: moment(this.props.selectedItem.start_time)})
				}
				this.setTime(this.props.selectedItem.value)
			}
        }
		if (prevState.projectId !== 'available' && this.state.projectId === 'available') {
			this.setState({
				start: this.props.selectedItem ? moment(this.props.selectedItem.start_time) : moment(),
				end: this.props.selectedItem ? moment(this.props.selectedItem.end_time).subtract(1, 'day') : moment(),
			})
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
        this.setState({
			start: props.selectedItem ? moment(props.selectedItem.start_time) : moment()
		});
    }

	onSave()
	{
		let itemId = undefined;
		let otherWeek = false; // valittu viikko on eri kuin valitun palikan viikko

		if (moment(this.state.start, 'YYYY-MM-DD HH.mm').isoWeek() == moment(this.props.selectedItem.start_time).isoWeek()) {
			itemId = this.state.itemId;
		} else {
			// Aseta työ toiselle viikolle kuin valitun palikan viikko
			otherWeek = true;
			const week = moment(this.state.start, "YYYY-MM-DD HH.mm").isoWeek();
			const year = moment(this.state.start, "YYYY-MM-DD HH.mm").year();
			const userId = this.props.selectedItem.user.id;

			const foundItem = this.props.items.find(
				item => item.id.startsWith(`${week}-${year}-${userId}`)
				&& item.group === 'available'
			);
			const availableHours = this.props.items
				.filter(item => item.id.startsWith(`${week}-${year}-${userId}`) && item.group === 'available')
				.reduce((total, item) => item.value + total, 0);

			const maxHours = this.convertToHours(this.state.hours, this.state.minutes) > Number(this.props.selectedItem.user.contract_detail.hours_weekly)
				? this.props.selectedItem.user.contract_detail.hours_weekly
				: this.convertToHours(this.state.hours, this.state.minutes);

			if (foundItem && availableHours >= maxHours) {
				this.props.handleDeletePersonnelItem(this.props.selectedItem)
				itemId = foundItem.id;
			} else {
				errorPopper(null, tr('user_not_enough_available_hours',  [this.props.selectedItem.user.user.name, moment(this.state.start, "YYYY-MM-DD HH.mm").isoWeek()]));
				return;
			}
		}
		const data = {
			projectId: this.state.projectId,
			workId: this.state.workId,
			itemId: itemId,
			hours: this.convertToHours(this.state.hours, this.state.minutes),
			start: moment(this.state.start, "YYYY-MM-DD HH.mm").format('x'),
			end: moment(this.state.end, "YYYY-MM-DD HH.mm").endOf('day').format('x'),
		}
		if (otherWeek) {
			this.props.handleSave(data, this.props.selectedItem)
		} else {
			this.props.handleSave(data)
		}
		this.props.onClose()
	}

	handleChange(e)
	{
		this.setState({ [e.target.name]: e.target.value });
    }

	handleDateChange(e,name) {
		console.log(e)
		if (moment(e, "YYYY-MM-DD HH.mm").format('x') === "Invalid date") {
			this.setState({
				invalidDate: true,
				error: tr("invalid_value"),
				saveDisabled: true
			});
		}
		else {
			if (name == 'start') {
				if (moment(e, 'YYYY-MM-DD').week() != this.props.selectedItem.week.split('-')[0]
				|| Math.abs(moment(e, 'YYYY-MM-DD').year() - moment().year()) > 1) {
					// this.setState({
					// 	saveDisabled: true, 
					// 	error: tr('select_start_day_from_week', [ this.props.selectedItem.week.split('-')[0] ])
					// });
					// return;
				}
				this.updateHours(e, this.state.end);
			}
			else if (name == 'end') {
				this.updateHours(this.state.start, e);
			}
			this.setState({
				[name]: e,
				error: null,
				invalidDate: false,
			});
		}
	}

	// calculate new hours when selecting dates with datepicker. start & end are 'YYYY-MM-DD' format
	updateHours(start, end) {
		if (!moment(start, 'YYYY-MM-DD').isValid() 
		&& !moment(end, 'YYYY-MM-DD').isValid() 
		|| moment.duration(moment(end, 'YYYY-MM-DD') - moment(start, 'YYYY-MM-DD')).asYears() > 1) {
			return;
		}

		let total = 0;

		let hoursThisWeek = 0;
		let startDate = parseInt(moment(start, 'YYYY-MM-DD').format('x'));
		while (startDate <= parseInt(moment(end, 'YYYY-MM-DD').format('x'))) {
			if ((moment(startDate).isoWeekday() < 6 
			|| (this.state.daysWeekly > 5 && moment(startDate).isoWeekday() <= this.state.daysWeekly)) 
			&& hoursThisWeek < parseFloat(this.state.hoursWeekly)) {
				total += parseFloat(this.state.hoursDaily);
				hoursThisWeek += parseFloat(this.state.hoursDaily);
			} else if (moment(startDate).isoWeekday() === 7) {
				hoursThisWeek = 0;
			}
			startDate = parseInt(moment(startDate).add(1, 'day').format('x'));
		}
		this.setTime(total);
	}
    
    getProjectOptions()
    {
        let options = [];
        this.props.projects.forEach(project => {
			if (project.id === "available" || project.id === "holiday" || project.id === 'absence')
				return;
			let code = project.project_code ? ` (${project.project_code})` : '';
            options.push({
                value: project.id,
                label: `${project.title}${code}${project.customerName ? ', ' + project.customerName : ""}`
            })
        })
		sortByField(options, "label");
		options.push(
			{value: "absence", label: tr("absences")},
			{value: "available", label: tr("available")}
		)
        return options;
    }

	getWorkOptions(projectId)
    {
        let options = [];
        this.props.projects.forEach(project => {
			if (project.works && project.id == projectId)
			{
				project.works.forEach(work => {
					options.push({
						value: work.id,
						label: work.name
					})
				})
			}
            
        })
        return options;
	}
	
	onChange(e)
	{
		const { name, value } = e.target;

		if (name === 'projectId')
		{
			const works = this.getWorkOptions(value);
			this.setState({ workId: works[0] ? parseInt(works[0].value) : null, error: null, saveDisabled: false })
			if (value === 'available')
			{
				this.setState({ projectId: 'available' })
				if (this.props.selectedItem.value > this.state.hoursWeekly)
				{
					this.setTime(this.state.hoursWeekly)
				}
				else
				{
					this.setTime(this.props.selectedItem.value)
				}
			}
			else
			{
				this.setState({ projectId: value })
				this.setTime(this.props.selectedItem.value)
			}
		}
		else if (name === 'hours' || name === 'minutes')
		{
			let hours = name === 'hours' ? parseNumber(value) : this.state.hours
			let minutes = name === 'minutes' ? (parseNumber(value) > 59 ? 59 : parseNumber(value)) : this.state.minutes
			let error = null
			let saveDisabled = false;

			if (hours < 1)
			{
				saveDisabled = true;
				error = tr('hours_min')
			}
			else if (hours > 240)
			{
				saveDisabled = true;
				error = tr('hours_max')
			}

			this.setState({ hours: hours, minutes: minutes, saveDisabled: saveDisabled, error: error })
		}
		else
		{
			this.setState({ [name]: parseInt(value) })
		}
	}

    setTime(value)
    {
        let decimalTime = parseFloat(value);
        decimalTime = decimalTime * 60 * 60;
        let hours = Math.floor((decimalTime / (60 * 60)));
        decimalTime = decimalTime - (hours * 60 * 60);
		let minutes = Math.round(decimalTime / 60);
		if (minutes === 60)
		{
			hours += 1;
			minutes = 0;
		}

        this.setState({ hours: hours, minutes: minutes, saveDisabled: hours > 0 ? false : true })

		if (moment(this.state.start, 'YYYY-MM-DD').week() != this.props.selectedItem.week.split('-')[0]) {
			this.setState({error: tr('select_start_day_from_week', [ this.props.selectedItem.week.split('-')[0] ])})
		}
	}
	
	convertToHours(hours, minutes)
	{
		let hourDecimals = minutes/60
		return parseFloat(hours+hourDecimals)
	}

	renderForm()
	{
		const selectedProject = this.findSelectedProject();
		let selectedProjectTitle = selectedProject
			? selectedProject.title
			: "";

		const foundGroup = this.props.groups.find(group => group.id == this.state.projectId);

		const customer = foundGroup && foundGroup.customerName
			? `, ${foundGroup.customerName}`
			: "";

		selectedProjectTitle += customer;
		const form = <div className={this.state.invalidDate ? "invalid" : ""}>
		<ApInputStack gap="0">
            <ApAddon noRightBorder width="80px">
                { tr('project') }
            </ApAddon>
            <ApSelect 
				value={ selectedProjectTitle }
				options={ this.getProjectOptions() }
				optionRenderer={ ( item ) => <div>{ item.label }</div> }
				valueRenderer={ ( item ) => <div>{ item.label }</div> }
				onChange={ (e) => this.setState({projectId: e.value, selectedProjectName: e.label}) }
				objKeyId="label"
				objKeyValue="value"
				objKeySearchable="label"
			/>
        </ApInputStack>

        <ApInputStack gap="0">
            <ApAddon noRightBorder width="80px">
                { tr('work') }
            </ApAddon>
            <ApInput 
				type="select" 
				id="workId" 
				name="workId"
				disabled={ !this.state.projectId || this.state.projectId === 'available' ||  this.state.projectId === 'absence' } 
				value={ this.state.workId }
				options={ this.getWorkOptions(this.state.projectId) }
				onChange={ (e) => this.onChange(e) }
			/>
        </ApInputStack>

		<ApInputStack gap="0">
            <ApInput
				id="hours"
				name="hours"
				disabled={ 
					!this.state.projectId || 
					this.state.projectId === 'available'
				} 
				value={this.state.hours}
				loading={this.state.loading}
				onChange={ (e) => this.onChange(e) }
			/>
			<ApAddon noRightBorder width="50px">
                h
            </ApAddon>
			<ApInput
				id="minutes"
				name="minutes"
				disabled={ 
					!this.state.projectId || 
					this.state.projectId === 'available'
				} 
				value={this.state.minutes}
				loading={this.state.loading}
				onChange={ (e) => this.onChange(e) }
			/>
			<ApAddon width="50px">
                min
            </ApAddon>
        </ApInputStack>

		<ApInputStack gap="0">
			<ApInput
				type="datetimeV2"
				// type="datetime" // for hourly view
				id="start"
				name="start"
				label={ tr('start_date') }
				weekNumbers
				value={ this.state.start ? this.state.start : '' }
				onChange={ (e) => this.handleDateChange( e,'start' ) }
				disabled={ !this.state.projectId || this.state.projectId === 'available'}
				isValidDate={ (current) => {
					// Allow selection of only days of a single week
					if (this.state.itemId && this.props.selectedItem) {
						// Add 1 second to fix issue if start time is same as start of the week
						const start_time =  moment(this.props.selectedItem.start_time).add(1, 'second');
						const startOfWeek = moment(current).startOf('isoWeek');
						const endOfWeek = moment(current).endOf('isoWeek');
						// return moment(start_time).isBetween(startOfWeek, endOfWeek);
						return current.isAfter(moment(this.props.selectedItem.start_time).subtract(1, 'week').startOf('isoWeek'), 'week');
					}
				}}
			/>
			<ApInput
				type="datetimeV2"
				// type="datetime" // for hourly view
				id="end"
				name="end"
				label={ tr('end_date') }
				weekNumbers
				value={ this.state.end ? this.state.end : '' }
				onChange={ (e) => this.handleDateChange( e ,'end') }
				disabled={ !this.state.projectId || this.state.projectId === 'available' }
				isValidDate={ (current) => {
					if (this.state.itemId && this.props.selectedItem) {
						return current.isAfter(moment(this.state.start, 'YYYY-MM-DD HH:mm').subtract(1, 'day'));
					}
				}}
			/>
		</ApInputStack>
		</div>;

		return form;

	}

	findSelectedProject() {
		return this.props.projects.find(project => project.id == this.state.projectId);
	}

	render()
	{
		const selectedProject = this.findSelectedProject();
		let workRemainingTotal = 0;
		
		const worksRemaining = selectedProject 
			&& selectedProject.works
			&& selectedProject.works.map(work => {
				const workRemaining = (work.alloc_count || 0) - (work.entry_count || 0);
				if (workRemaining > 0) {
					workRemainingTotal += workRemaining;
				}
				return (
					<p key={work.id}>
						{work.name}: <strong>{this.props.convertTime(workRemaining > 0 ? workRemaining : 0)} h</strong>
					</p>
				)
			}
		);

		let worksRemainingEl = "";
		if (selectedProject && (selectedProject.id !== "available" && selectedProject.id !== "holiday" && selectedProject.id !== 'absence')) {
			worksRemainingEl = (
				<div className="work-remaining-container">
					<p>{tr('work_remaining')}</p>
					<p>{tr('in_total')}: <strong>{workRemainingTotal > 0 ? this.props.convertTime(workRemainingTotal) : "00:00"} h</strong></p>
					{worksRemaining}
				</div>
			)
		}

		const form = this.renderForm();

		return(
			<div id="personnel-modal">
				<ApModal
					show={this.props.show}
					handleClose={ () => this.props.onClose() }
					// closeFromBg //TODO fix click background close
					backdrop="static"
					className="narrow"
					header={ 
						<div className="padding-small">
							<h4>{this.state.title}</h4>
						</div>
					}
					body={
						<div className="padding" style={{ marginTop: 10 }}>
						{form}
						{worksRemainingEl}
						</div>
					}
					footer={
						<div>
							<div style={{display: 'flex'}}>
							{ this.state.error && <div style={{color: 'red', margin: '0 1em 0 auto'}}>{this.state.error}</div> }
							</div>
						<div className="padding" style={{display: 'flex', flexDirection: 'row'}}>
						<ApButton className="cancel" onClick={() => this.props.onClose()}>
							<SvgIcon icon="times" type="solid" />
							{ tr('cancel') }
						</ApButton>

						<ApButton
							className="save"
							color="blue"
							onClick={ this.onSave }
							loading={ this.state.loading }
							style={{marginLeft: 'auto'}}
							disabled={this.state.saveDisabled}
						>
							<SvgIcon icon="save" type="solid" />
							{ tr('save') }
						</ApButton>
						
						</div>
						</div>
					}
				/>
			</div>
		);

	}

}