/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import auth from 'services/Authed/Authed.js';

import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';

import ApTree from 'common/ApTree/ApTree.js';
import { mapTree, tr } from 'services/Helpers/Helpers.js';

import ApSelect from 'common/ApSelect/ApSelect.js';
// import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import { ApInputStack } from 'common/ApInput/ApInput.js';
import { ApInputSmall } from 'common/ApInputSmall/ApInputSmall.js';

/*
import { Collapse } from 'react-bootstrap';

import api                from 'services/Api/Api.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import { dateInputToSql,
         sqlToDateInput } from 'services/Helpers/Helpers.js'

import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApSteps            from 'common/ApSteps/ApSteps.js';
import ApSwitch           from 'common/ApSwitch/ApSwitch.js';
import ApButton           from 'common/ApButton/ApButton.js';
import ApSelect           from 'common/ApSelect/ApSelect.js';




import { ApInput,
         ApInputStack,
         ApAddon }        from 'common/ApInput/ApInput.js';
import { keyExists
       , roundToDecimals } from 'services/Helpers/Helpers.js';

import './NewProject.css';
*/
import './AddMultipleComponents.css';

class AddMultipleComponents extends React.Component
{
    constructor(props)
    {

        super(props);
        this.state = {
            loading: false,
            projects: [],
            components: [],
            currencySign: auth.getCurrencySign(),
        }

            /*
        const customerValidator = ( value ) => {
            if( !this.state.has_customer ) return true;
            return Boolean( value );
        }

        const endDateValidator = ( value ) => {
            if( this.state.is_continuous ) return true;
            return Boolean( value );
        }

        const offerValidator = ( value ) => {
            if( this.state.do_offer ) return true;
            return Boolean( value );
        }


        this.validator = new ApValidate( this, {
            "name":         { filter: 'required', state: 'error', text: 'Nimi on pakollinen tieto'},
            "project_code": { filter: 'required', state: 'error', text: 'Projektikoodi on pakollinen'},
            "customer":     { filter: customerValidator, state: 'error', text: 'Asiakas on pakollinen tieto'},

            "begin_date":   { filter: 'required', state: 'error', text: 'Aloituspäivä on pakollinen tieto'},
            "end_date":     { filter: endDateValidator, state: 'error', text: 'Lopetuspäivä on pakollinen tieto'},

            "offer_received": { filter: offerValidator, state: 'error', text: 'Tarjous saatu päivä on pakollinen tieto'},
            "offer_deadline": { filter: offerValidator, state: 'error', text: 'Tarjouksen viimeinen jättöpäivä'},
        });

        const oneIsInvalid = ( test ) => {
            let text = null;
            const invalid = test.some( field  => {
                text = this.validator.getText( field )
                return text;
            });
            if( invalid ) return text;
            return null;
        }
        */

        this.steps = [{
            name: tr('select_components'),
            render: this.renderStepComponents.bind( this ),
            invalid:  () => {
                return ( this.state.components.length === 0 )
            },
        },{
            name: tr('select_projects'),
            render: this.renderStepProjects.bind( this ),
            invalid:  () => {
                return ( this.state.projects.length === 0 )
            }
        },{
            name: tr('select_quantities'),
            render: this.renderStepCounts.bind( this ),
            invalid:  () => {
                return true;
            }
        }];

        autoBind(this);
    }

    resetState()
    {
        this.setState({
            tree: this.props.tree,
            projects: [],
            components: [],
        });

    }

    onOpen()
    {
        this.resetState();
    }

    projectClick( project )
    {
        let selected = this.state.projects.slice( 0 );
        let tree = JSON.parse(JSON.stringify( this.state.tree ));
        tree = mapTree( tree, ( item, parent ) => {

            if( project.id === item.id )
            {
                const found = selected.find( p => p.id === item.id );
                if( found )
                {
                    item.__selected = false;
                    selected = selected.filter( s => s.id !== item.id );
                }
                else
                {
                    selected.push( item );
                    item.__selected = true;
                }
                if( item.children )
                    item.__open = true;
            }
            return item;
        });
        this.setState({
            tree: tree,
            projects: selected,
        });
    }

    renderStepProjects()
    {
        const tree = this.state.tree;
        if( !tree ) return null;

        const treeNode = ( project, level ) => {
            return <div className="project">
                <div className="checkboxContainer">
                    <input
                        type="checkbox"
                        checked={ project.__selected }
                        readOnly
                    />
                </div>
                <div className="nameContainer">
                    { project.name }<br />
                    <small>{ project.project_code }</small>
                </div>
            </div>
        };

        return <ApTree
            tree={ tree }
            onTreeChange={  ( tree ) => {  this.setState({ tree: tree }) } }
            nodeRenderer={ treeNode }
            onNodeClick={ this.projectClick }
        />
    }

    renderStepComponents()
    {
        let selectDom = <ApInputStack gap="0">
            <ApSelect
                label={ tr('add_storage_component') }
                loading={ this.state.loading }
                value=""
                optionRenderer="component_detail"
                onChange={ ( c ) => {
                    let components = this.state.components.slice(0);
                    components.push( c );
                    this.setState({ components: components });
                } }
                objKeyId="id"
                objKeyValue="name"
                apiUrl="storage/components"
                apiData={{
                    include: [ 'identifiers' ],
                    formatter: 'offer_calculate',
                }}
            />
        </ApInputStack>

        const componentRowDoms = this.state.components.map( c => {

            //console.log('C', c );

            return <tr>
                <td className="name">
                    <ApInputSmall
                        value={ c.name || '' }
                        onChange={ ( e ) => { /*console.log('foobar')*/ } }
                        onBlur={   ( e ) => { /*console.log('foobar2')*/ } }
                        disabled={ this.state.locked }
                        className="left"
                    />
                </td>
                <td className="fixed">
                    <ApInputSmall
                        value={ c.price || '' }
                        onChange={ ( e ) => { /*console.log('foobar')*/ } }
                        onBlur={   ( e ) => { /*console.log('foobar2')*/ } }
                        disabled={ this.state.locked }
                        unit={ this.state.currencySign }
                        noFocusDecimals={ 2 }
                        tooltip="Määrä"
                    />
                </td>
            </tr>
        });


        let cTableDom = <table className="componentTable">
            <thead>
                <tr className="componentHeader">
                    <th>{ tr('name') }</th>
                    <th>{ tr('allocated_unit_price') }</th>
                </tr>
            </thead>
            <tbody>
                { componentRowDoms }
            </tbody>
        </table>

        return <div>
            { selectDom }
            { cTableDom }
        </div>
    }

    renderStepCounts()
    {
        const projects = this.state.projects;
        // const components = this.state.components;

        const projectsDoms = projects.map( p => {

            // TODO

            const componentRowDoms = this.state.components.map( c => {
                return <tr>
                    <td className="name relative">
                        <ApInputSmall
                            value={ c.name || '' }
                            onChange={ ( e ) => { /*console.log('foobar')*/ } }
                            onBlur={   ( e ) => { /*console.log('foobar2')*/ } }
                            disabled={ this.state.locked }
                            className="left"
                            tooltip={ tr('name') }
                        />
                    </td>
                    <td className="fixed">
                        <ApInputSmall
                            value={ c.count || '' }
                            onChange={ ( e ) => { /*console.log('foobar')*/ } }
                            onBlur={   ( e ) => { /*console.log('foobar2')*/ } }
                            disabled={ this.state.locked }
                            unit={ c.unit || '' }
                            noFocusDecimals={ 2 }
                            tooltip={ tr('allocated_quantity') }
                        />
                    </td>
                </tr>
            });
            /*
                <thead>
                    <tr className="componentHeader">
                        <th>Nimi</th>
                        <th>Allokoitu yksikköhinta</th>
                    </tr>
                </thead>
                */

            const cTableDom = <table className="componentTable">
                <tbody>
                    { componentRowDoms }
                </tbody>
            </table>

            return <div key={ p.id }>
                <div>
                    { p.name } <small>{ p.project_code }</small>
                </div>
                { cTableDom }
            </div>
        })
        return <div>
            { projectsDoms }
        </div>
    }


    /*
    getProjectTypes()
    {
        this.setState({ loading: true });

        api({
            method: 'get',
            url: 'projects/management/types',
            errorPopper: 'Projektin haussa tapahtui virhe',
        }).then(( response ) => {
            this.setState({
                types: response,
                loading: false,
            }, this.resetState );
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    getSuggestedProjectCode()
    {
        if( !this.state.automaticCode )
            return null;

        this.setState({ codeLoading: true });
        api({
            method: 'get',
            url: 'project/type/' + this.state.type + '/nextcode',
            errorPopper: 'Automaattisen koodin haussa tapahtui virhe',
        }).then(response => {
            this.setState({
                project_code: response,
                codeLoading: false,
            }, this.validateStep );
        }).catch((error) => {
            this.setState({ codeLoading: false });
        });
    }

    getProbabilityOptions()
    {
        return [
            { value: 'null', label:  '' },
            { value: 0,    label:   '0%' },
            { value: 10,   label:  '10%' },
            { value: 20,   label:  '20%' },
            { value: 30,   label:  '30%' },
            { value: 40,   label:  '40%' },
            { value: 50,   label:  '50%' },
            { value: 60,   label:  '60%' },
            { value: 70,   label:  '70%' },
            { value: 80,   label:  '80%' },
            { value: 90,   label:  '90%' },
            { value: 100,  label: '100%' },
        ];
    }

    save()
    {
        const state = this.state;
        let data = {
            type_id: state.type,
            name: state.name,
            project_code: state.project_code,
            description: state.description,
        };

        if( state.has_customer )
        {
            data.customer_id = this.state.customer.id;
            if( state.customer_address )
                data.customer_address_id = this.state.customer_address.id;
            if( state.customer_contact )
                data.customer_contact_id = this.state.customer_contact.id;
        }

        data.billing_hourly = state.billing_hourly;

        if( state.manager )
            data.manager_id = state.manager.id;

        if( state.workhour_approver )
            data.workhour_approver_id = state.workhour_approver.id;

        data.begin_date = state.begin_date;
        if( !state.is_continuous )
            data.end_date = state.end_date;

        data.do_offer = state.do_offer;

        if( state.do_offer )
        {
            data.offer_received = state.offer_received;
            data.offer_deadline = state.offer_deadline;
            data.offer_success_probability = state.offer_success_probability;
            if( data.offer_success_probability  === 'null') data.offer_success_probability = null;
        }

        this.setState({ loading: true });

        api({
            method: 'post',
            url: 'project/new/save',
            data: data,
            errorPopper: 'Projektin tallennuksessa tapahtui virhe',
        }).then(( response ) => {
            this.props.onClose();
            this.props.onSave();
            this.setState({ loading: false } );
        }).catch((error) => {
            this.setState({ loading: false } );
        });


    }


    getCustomerStats( id )
    {
        this.setState({ statLoading: true });
        api({
            method: 'get',
            url: `project/new/offerstats/${ id }`,
            errorPopper: 'Toimittajan tilastojen haussa tapahtui virhe',
        }).then(response => {
            this.setState({ 
                statLoading: false,
                offer_stats: response,
            });
        }).catch((error) => {
            this.setState({ statLoading: false });
        });
    }

    handleAutomaticCodeChange()
    {
        this.setState({ automaticCode: !this.state.automaticCode }, () => {
            this.getSuggestedProjectCode();
        });
    }

    customerChange( customer )
    {
        if( customer )
            this.getCustomerStats( customer.id );
        else
            this.setState({ offer_stats: null });

        this.setState({
            customer: customer,
            customer_address: null,
            customer_contact: null,
            offer_stats: null,
        });
    }

    getTypeOptions()
    {
        let options = [];
        if( this.state.types )
        {
            this.state.types.forEach( type => {
                options.push({
                    value: type.id,
                    label: type.name,
                });
            });
        }
        return options;
    }

    typeChange( value )
    {
        this.setState({ type: value }, () => {
            this.getSuggestedProjectCode();
        });
    }

    renderSwitchbox( value, label, labelSmall = null, onChangeMore = null, disabled = false )
    {
        let onChange = null;

        let toggleValue = () => this.setState({ [ value ]: !this.state[ value ] })
        if( typeof onChangeMore === 'function' )
            onChange = () => { toggleValue(); onChangeMore(); };
        else 
            onChange = () => { toggleValue(); };

        return <div className={ "apFormGroup" + ( this.state[ value ] ? " success" : "" ) }>
            <div className="apSwitchBlock small">
                <label htmlFor={`newProject_${ value }`} className="info">
                    { label }
                    <small>{ labelSmall }</small>
                </label>
                <ApSwitch
                    id={`newProject_${ value }`}
                    on={ this.state[ value] }
                    onChange={ onChange }
                    disabled={ this.state.loading || disabled }
                />
            </div>
        </div>
    }

    renderStepBasic()
    {
        if( this.state.loading )
        {
            return <div className="text-center">
                <div className="apInlineLoader"></div>
            </div>
        }

        if( !this.state.types.length )
        {
            return <div className="apWarningMsg">
                <h2>Projektityyppejä ei löytynyt</h2>
                <p>Yhtään projektityyppiä ei ole määritetty. Määritä vähintään yksi projektin asetuksista.</p>
            </div>
        }

        return <div className="stepBasic">

            <ApInputStack gap="0">
                <ApAddon noRightBorder width="200px">
                    Projektityyppi
                </ApAddon>
                <ApInput
                    type="select"
                    id="newProject_type"
                    name="type"
                    value={ this.state.type }
                    options={ this.state.types.map( t => { return { value: t.id, label: t.name } }) }
                    onChange={ ( e ) => this.typeChange( e.target.value ) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </ApInputStack>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                Projekteja voidaan ryhmitellä projektiyyppeillä. Jokaiselle projektityypille voi määrittää oletetun projektikoodin.
            </div>

            <ApInput
                type="text"
                id="newProject_name"
                name="name"
                label="Nimi"
                value={ this.state.name ? this.state.name : '' }
                onChange={ ( e ) => this.setState({ name: e.target.value }) }
                validationState={ this.validator.getState('name') }
                tooltip={ this.validator.getText('name') }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />

            <ApInputStack gap="0">
                <ApInput
                    type="text"
                    id="newProject_project_code"
                    name="project_code"
                    label="Projektikoodi"
                    value={ this.state.project_code }
                    onChange={ ( e ) => this.setState({ project_code: e.target.value }) }


                    validationState={ this.validator.getState('project_code') }
                    tooltip={ this.validator.getText('project_code') }
                    loading={ this.state.loading || this.state.codeLoading }
                    disabled={ this.state.loading }
                    readOnly={ this.state.automaticCode }
                />
                <ApAddon noLeftBorder width="200px" labelFor="newProject_automatic-code-switch">
                    <span>Automaattinen</span>
                    <ApSwitch
                        small
                        inline
                        id="newProject_automatic-code-switch"
                        on={ this.state.automaticCode }
                        onChange={ this.handleAutomaticCodeChange }
                        disabled={ this.state.loading }
                    />
                </ApAddon>
            </ApInputStack>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                Automattinen projektikoodi määrittää seuraavan vapaan projektikoodin projektityypin perusteella.
            </div>

            <ApInput
                type="textarea"
                id="newProject_description"
                name="description"
                label="Kuvaus"
                value={ this.state.description }
                rows={ 4 }
                onChange={ ( e ) => this.setState({ description: e.target.value }) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
        </div>
    }

    selectChange( field, value )
    {
        this.setState({ [ field ]: value ? value: null });
    }

    renderStepCustomer()
    {
        const hasCustomerDom  = this.renderSwitchbox(
            'has_customer', 
            'Projektilla on asiakas',
            'Projekti liitettään johonkin asiakkaaseen',
        );
        let statsDom = null;

        if( this.state.customer )
        {
            const stats = this.state.offer_stats;
            if( !stats )
                statsDom = <div className="apInlineLoader"></div>
            else
            {
                if( stats.projects )
                {
                    statsDom = <table className="statsTable"><tbody>
                        <tr>
                            <td>Aikaisempia tarjouksia</td>
                            <td>{ stats.offers } kpl</td>
                        </tr>
                        <tr>
                            <td>Tarjouksia hyväksytty</td>
                            <td>{ stats.offers_success } kpl</td>
                            <td>{ roundToDecimals( stats.offers_success / stats.offers * 100, 0 ) } %</td>
                        </tr>
                        <tr>
                            <td>Projektit ilman tarjousta</td>
                            <td>{ stats.no_offers } kpl</td>
                        </tr>
                    </tbody></table>
                }
                else
                {
                    statsDom = <div>Ei aikaisempia projekteja yritykselle</div>
                }
            }
            statsDom = <div>
                <div className="statsTitle">Valitun asiakkaan tilastoja</div>
                { statsDom }
            </div>
        }

        const customerSelectDoms = <Collapse in={ this.state.has_customer }><div>
            <ApSelect
                label="Asiakas"
                value={ this.state.customer }
                optionRenderer={ ( item ) => {
                    return <div>
                        <strong>{ item.name }</strong><br />
                        <small>{ item.taxnumber }</small>
                    </div>
                }}
                onChange={ ( c ) => this.customerChange( c ? c : null ) }
                objKeyId="id"
                objKeyValue="name"
                clearable
                apiUrl="project/find/customer"
                loading={ this.state.loading }
                disabled={ this.state.loading }

                validationState={ this.validator.getState('customer') }
                tooltip={ this.validator.getText('customer') }
            />

            <ApSelect
                label="Laskutusosoite"
                value={ this.state.customer_address }
                optionRenderer={ (item) => {
                    return (
                        <div>
                            <strong>{ item.name }</strong><br />
                            { item.street }<br />
                            { item.postal_code + " " + item.city }<br />
                            { item.country }
                        </div>
                    );
                }}

                objKeyValue={ ( addr ) => { return `${ addr.name } - ${ addr.street }, ${ addr.city }` } }
                onChange={ ( v ) => this.selectChange( 'customer_address', v ) }
                objKeyId="id"
                clearable
                apiUrl="project/find/customer/address"
                apiData={{
                    crm_company: this.state.customer ? this.state.customer.id : false,
                }}
                loading={ this.state.loading }
                disabled={ this.state.loading || !this.state.customer }
            />
            <ApSelect
                label="Yhteyshenkilö"
                value={ this.state.customer_contact }
                optionRenderer={ (item) => <div>{ item.last_name } { item.first_name }</div> }
                onChange={ ( v ) => this.selectChange( 'customer_contact', v ) }
                objKeyValue={ ( contact ) => { return `${ contact.last_name } ${ contact.first_name }` } }
                objKeyId="id"
                clearable
                apiUrl="project/find/customer/contact"
                apiData={{
                    crm_company: this.state.customer ? this.state.customer.id : false,
                }}
                loading={ this.state.loading }
                disabled={ this.state.loading || !this.state.customer }
            />
            { statsDom }
        </div></Collapse>

        return <div className="stepCustomer">
            { hasCustomerDom }
            { customerSelectDoms }
        </div>
    }

    renderStepDetails()
    {
        const billingHourlyDom = this.renderSwitchbox(
            'billing_hourly', 
            'Projekti on tuntilaskutteinen',
            'Projektin laskutus tehdään tuntiperusteisesti',
            () => { this.setState({ is_continuous: false }) }
        );

        const continuousDom = this.renderSwitchbox(
            'is_continuous', 
            'Projekti on jatkuva',
            'Projektille ei ole määritetty päättymispäivää',
            null,
            ( !this.state.billing_hourly ),
        );



        return <div className="stepDetails">
            { billingHourlyDom }

            <ApSelect
                label="Projektipäällikkö"
                value={ this.state.manager }
                optionRenderer="user"
                onChange={ ( v ) => this.selectChange( 'manager', v ) }

                objKeyId="id"
                objKeyValue="name"
                clearable
                apiUrl="project/find/manager"
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />

            <ApSelect
                label="Tuntien hyväksyjä"
                value={ this.state.workhour_approver }
                optionRenderer="user"
                onChange={ ( v ) => this.selectChange( 'workhour_approver', v ) }
                objKeyId="id"
                objKeyValue="name"
                clearable
                apiUrl="project/find/workhourapprover"
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />

            { continuousDom }
            <ApInputStack>
                <ApInput
                    type="dateSafe"
                    id="newProject_begin_date"
                    name="begin_date"
                    label="Aloituspäivä"
                    value={ this.state.begin_date }
                    onChange={ ( e ) => { this.setState({ begin_date: e.target.value }); }}
                    clearable
                    loading={ this.state.loading }
                    disabled={ this.state.loading }

                    validationState={ this.validator.getState('begin_date') }
                    tooltip={ this.validator.getText('begin_date') }

                />
                <ApInput
                    type="dateSafe"
                    id="newProject_end_date"
                    name="end_date"
                    label="Lopetuspäivä"
                    value={ this.state.is_continuous ? '' : this.state.end_date }
                    onChange={ ( e ) => { this.setState({ end_date: e.target.value }); }}
                    clearable  
                    loading={ this.state.loading }
                    disabled={ this.state.loading || this.state.is_continuous }

                    validationState={ this.validator.getState('end_date') }
                    tooltip={ this.validator.getText('end_date') }

                />
            </ApInputStack>
        </div>
    }

    renderStepOffer()
    {
        const doOfferDom = this.renderSwitchbox(
            'do_offer', 
            'Projektille tehdään tarjouslaskenta',
            'Projekti luodaan tarjouslaskenta tilaan',
        );

        const offerInputsDom = <Collapse in={ this.state.do_offer }><div>

            <ApInput
                type="dateSafe"
                id="newProject_offer_received"
                name="offer_received"
                label="Tarjouskysely vastaanotettu"
                value={ this.state.offer_received ? sqlToDateInput( this.state.offer_received ) : '' }
                onChange={ ( e ) => { this.setState({ offer_received: e.target.value }); }}
                clearable  
                loading={ this.state.loading }
                disabled={ this.state.loading }

                validationState={ this.validator.getState('offer_received') }
                tooltip={ this.validator.getText('offer_received') }
            />

            <ApInput
                type="dateSafe"
                id="newProject_offer_daedline"
                name="offer_deadline"
                label="Tarjouksen viimeinen jättöpäivä"
                value={ this.state.offer_deadline ? sqlToDateInput( this.state.offer_deadline ) : '' }
                onChange={ ( e ) => { this.setState({ offer_deadline: e.target.value }); }}
                clearable  
                loading={ this.state.loading }
                disabled={ this.state.loading }

                validationState={ this.validator.getState('offer_deadline') }
                tooltip={ this.validator.getText('offer_deadline') }
            />

            <ApInputStack key="probability"  gap="0">
                <ApAddon noRightBorder width="320px">
                    Todennäköisyys hyväksymiseen
                </ApAddon>
                <ApInput
                    type="select"
                    options={ this.getProbabilityOptions() }
                    id="propability_select"
                    name="propability_select"
                    value={ this.state.offer_success_probability }
                    onChange={ ( e ) => { this.setState({ offer_success_probability: e.target.value }); }}
                    disabled={ this.state.loading }
                />
            </ApInputStack>


        </div></Collapse>

        return <div className="stepOffer">
            { doOfferDom }
            { offerInputsDom }
        </div>
    }
    */

    render()
    {
        return <div id="addMultipleModal">
            <ApModalWizard
                header={ tr('add_new_project') }
                onOpen={ this.onOpen }
                show={ this.props.show }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.save }
            />
        </div>
    }

}

export default AddMultipleComponents;
