import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { tr, capitalize, sortByField } from 'services/Helpers/Helpers.js';

import './AssignmentsTable.css';
import AssignmentPage from './AssignmentPage/AssignmentPage.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import moment from 'moment';
import LongerWorkingHoursCompensation from 'modules/WorkHours/LongerWorkingHoursCompensation/LongerWorkingHoursCompensation';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import ApTooltip from '../../common/ApTooltip/ApTooltip.js';

// const assignement_status = {
// 	'created': 1,
// 	'waiting_worker': 2,
// 	'ready': 3,
// 	'running': 4,
// 	'wait': 7,
// 	'failed': 8,
// 	'done': 9,
// 	'ended': 10
// };
const assignement_status = {
	1: 'created',
	2: 'waiting_for_doer',
	3: 'scheduled_or_starting',
	4: 'work_in_progress',
	7: 'waiting',
	8: 'failed',
	9: 'done',
	10: 'ended'
};
export default class AssignmentsTable extends React.Component {

	constructor(props) {

		super(props);
		this.state = {

			loading: false,
			tab: this.props.tabSelected,
			tableColumns: [],
			assignments: [],
			customerFilterOptions: [{ value: "", label: tr("all") }],
			projectNameFilterOptions: [{ value: "", label: tr("all") }],
			workerFilterOptions: [{ value: "", label: tr("all") }],
			workTypeFilterOptions: [{ value: "", label: tr("all") }],
			selectedIds: [],

		};
		this.multiselect = [
			{
				icon: "archive",
				label: this.props.tabSelected == 'archived' ? tr('cancel_archive') : tr('archive'),
				action: (indexes) => { this.confirmArchiveAssignment(indexes) },
				unselectAfter: true,
			},
			{
				icon: "trash",
				label: tr('delete_assignments'),
				action: (indexes) => { this.confirmDeleteAssignment(indexes) },
				unselectAfter: true,
			}

		];

		// this.assignmentPageReff = React.createRef();





		autoBind(this);

	}

	UNSAFE_componentWillMount() {

		this.getTableColumns();
		this.getAssignments();

	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.assignments !== this.state.assignments) {
			this.getTableColumns();
		}
	}

	getStatuses() {

		api({
			method: 'get',
			url: 'assignment/getStatuses'
		}).then((response) => {
			this.setState({ statuses: response });
		}).catch((error) => {
			console.log(error);
		});

	}

	getAssignments() {
		this.setState({ loading: true });
		api({
			method: 'get',
			url: `assignments/get/${this.state.tab}`
		}).then((response) => {
			response.forEach(res => {
				// 	res.hasFiles = res.files.length > 0;
				if (res.temp_changes) {
					if (res.temp_changes.begin_at) {
						res.begin_at = res.temp_changes.begin_at;
					}
					if (res.temp_changes.end_at) {
						res.end_at = res.temp_changes.end_at;
					}
					if (res.temp_changes.user_id) {
						res.user_id = res.temp_changes.user_id;
						res.user = res.temp_changes.user;
						res.team_id = null;
						res.team = null;
					}
					if (res.temp_changes.team_id) {
						res.team_id = res.temp_changes.team_id;
						res.team = res.temp_changes.team;
						res.user_id = null;
						res.user = null;
					}
				}
			})
			// this.getFilterOptions(response);
			this.setState({ assignments: response, loading: false });
			this.props.handleCount(this.props.tabName, response.length);
		}).catch((error) => {
			console.log(error);
			this.setState({ loading: false });
		});
	}

	ArchiveAssignments() {
		this.setState({ loading: true });
		const archive = this.state.tab != 'archived';
		api({
			method: 'post',
			url: 'assignment/mass/archive',
			data: { ids: this.state.selectedIds, archive: archive },
		}).then((response) => {
			this.setState({ loading: false });
			if (archive == false) {
				//TODO: convert to correct format
				window.location.reload();
			}
			this.getAssignments();
		}).catch((error) => {
			console.log(error);
			this.setState({ loading: false });
		});
	}

	DeleteAssignments() {
		this.setState({ loading: true });
		api({
			method: 'post',
			url: 'assignment/mass/delete',
			data: { ids: this.state.selectedIds },
		}).then((response) => {
			this.setState({ loading: false });
			this.getAssignments();
		}).catch((error) => {
			console.log(error);
			this.setState({ loading: false });
		});
	}

	getStatusOptions() {
		let options = [{ value: "", label: tr("all") }];
		for (const statusId in assignement_status) {
			options.push({ value: statusId, label: tr(assignement_status[statusId]) })
		}
		return options;
		// api({
		// 	method: 'get',
		// 	url: 'assignment/getStatuses'
		// }).then((response) => {
		// }).catch((error) => {
		// 	console.log(error);
		// });
	}

	// getFilterOptions = (data) => {
	// 	let customerOptions = [];
	// 	let projectOptions = [];
	// 	let workerOptions = [];
	// 	let workTypeOptions = [];

	// 	if (Array.isArray(data)) {
	// 		data.forEach(item => {
	// 			const customer = item.customer;
	// 			const project = item.project;
	// 			const worker = item.user;
	// 			let workType=null;
	// 			if (item.project && item.project.type) {
	// 				workType= item.project.type
	// 			}
	// 			if (!workType && item.root_project && item.root_project.type) {
	// 				workType= item.root_project.type
	// 			}
	// 			// const workType = item.project && item.project.type_id
	// 			// 	? item.project
	// 			// 	: null;
	// 			const team = item.team;
	// 			// console.log(item);

	// 			if (customer)
	// 				customerOptions.push({value: customer.id, label: customer.name});
	// 			if (project)
	// 				projectOptions.push({value: project.id, label: project.name});
	// 			if (worker)
	// 				workerOptions.push({
	// 					value: worker.id, label: worker.person_detail ? worker.person_detail.full_name : "",
	// 					filter: (row, filter) => row.user_id == filter

	// 				});
	// 			if (workType)
	// 				workTypeOptions.push({
	// 					value: workType.id, label: workType.name, filter: (row, filter) => {
	// 						let workType = {};
	// 						if (row.project && row.project.type) {
	// 							workType = row.project.type
	// 						}
	// 						if (!workType && row.root_project && row.root_project.type) {
	// 							workType = row.root_project.type
	// 						}
	// 						return workType.id == filter;
	// 					}
	// 				});
	// 			if (team) {
	// 				workerOptions.push({ value: team.id, label: team.name, filter: (row, filter) => row.team_id == filter});
	// 			}
	// 		});
	// 	}

	// 	let uniqueCustomerOptions = this.getUniqueObjectsInArray(customerOptions);
	// 	let uniqueProjectOptions = this.getUniqueObjectsInArray(projectOptions);
	// 	let uniqueWorkerOptions = this.getUniqueObjectsInArray(workerOptions);
	// 	let uniqueWorkTypeOptions = this.getUniqueObjectsInArray(workTypeOptions);

	// 	// console.log(data.length + " rows");
	// 	// console.log(uniqueCustomerOptions);
	// 	// console.log(uniqueProjectOptions);
	// 	// console.log(uniqueWorkerOptions);
	// 	// console.log(uniqueWorkTypeOptions);

	// 	sortByField(uniqueCustomerOptions, "label");
	// 	sortByField(uniqueProjectOptions, "label");
	// 	sortByField(uniqueWorkerOptions, "label");
	// 	sortByField(uniqueWorkTypeOptions, "label");

	// 	uniqueCustomerOptions.unshift({ value: "", label: tr("all") });
	// 	uniqueProjectOptions.unshift({ value: "", label: tr("all") });
	// 	uniqueWorkerOptions.unshift({ filter: (row) => row.user ? false : row.team ? false : true, value: ' ', label: tr("free") });
	// 	uniqueWorkerOptions.unshift({ value: "", label: tr("all") });
	// 	uniqueWorkTypeOptions.unshift({ value: "", label: tr("all") });

	// 	this.setState({
	// 		customerFilterOptions: uniqueCustomerOptions, 
	// 		projectNameFilterOptions:uniqueProjectOptions,
	// 		workerFilterOptions: uniqueWorkerOptions,
	// 		workTypeFilterOptions: uniqueWorkTypeOptions,
	// 	});
	// }

	// getUniqueObjectsInArray = (array) => {
	// 	return array.reduce((unique, o) => {
	// 		if(!unique.some(obj => obj.value === o.value)) {
	// 		  unique.push(o);
	// 		}
	// 		return unique;
	// 	},[]);
	// }

	getIdList(indexes) {
		// console.log(indexes);
		// console.log(this.state.assignments);

		let as = [...this.state.assignments]
		let idList = as.filter((row, index) => indexes.includes(index)).map((row, index) => row.id);

		// console.log(data);
		// console.log(this.state.assignments);
		// let idList = [];
		// for (let index in indexes) {
		// 	console.log(typeof(index));
		// 	console.log(this.state.assignments[Number(index)]);
		// 	idList.push(this.state.assignments[Number(index)].id);
		// }
		// console.log(idList);

		return idList;
	}


	confirmArchiveAssignment(indexes) {

		this.setState({ showConfirmArchiveAssignment: true, selectedIds: this.getIdList(indexes) });
	}

	confirmDeleteAssignment(indexes) {
		this.setState({ showConfirmDeleteAssignment: true, selectedIds: this.getIdList(indexes) });
	}

	getTableColumns() {

		const columns = [
			{
				id: 'name',
				Header: tr('header'),
				accessor: 'name',
				onClick: this.showAssignment,
				customizable: true,
				showInitially: true,
				customFilter: {
					type: "text",
					placeholder: tr('header'),
				},
			},
			{
				id: 'project_code',
				Header: tr('project_code'),
				accessor: 'project.project_code',
				onClick: this.showAssignment,
				customizable: true,
				showInitially: true,
				width: 150,
				customFilter: {
					type: "text",
					placeholder: tr('project_code'),
					fields: ["_original.project.project_code"],
				},
			},
			{
				id: 'project_id',
				Header: tr('project_name'),
				accessor: 'project.name',
				onClick: this.showAssignment,
				customizable: true,
				showInitially: false,
				customFilter: {
					// type: "select",
					// // options: this.getOptions("report/projects/find", "project"),
					// options: this.getUniqueObjectsInArray(this.state.projectNameFilterOptions),
					type: "text",
					placeholder: tr('type'),
					fields: ["_original.project.name"],
				},
			},
			{
				id: 'assignment_creator',
				Header: tr("assignment_creator"),
				accessor: 'manager.person_detail.full_name',
				onClick: this.showAssignment,
				customizable: true,
				showInitially: true,
				customFilter: {
					type: "text",
					placeholder: tr('assignment_creator'),
					fields: ["_original.manager.person_detail.full_name"],
				},
			},
			{
				id: 'type_id',
				Header: tr("type"),
				// accessor: 'project.work_type',
				accessor: (props) => {
					if (props.project && props.project.type) {
						return props.project.type.name
					}
					if (props.root_project && props.root_project.type) {
						return props.root_project.type.name
					}
					return null;
				},
				onClick: this.showAssignment,
				customizable: true,
				showInitially: true,
				customFilter: {
					// type: "select",
					// options: this.getUniqueObjectsInArray(this.state.workTypeFilterOptions),
					type: "text",
					placeholder: tr('type'),
					fields: ["_original.root_project.type.name"],
				},
			},
			{
				id: 'user_id',
				Header: tr('assignment_worker'),
				accessor: (props) => (props.user && props.user.person_detail) ? props.user.person_detail.full_name : props.team ? props.team.name : "",
				onClick: this.showAssignment,
				width: 150,
				customizable: true,
				showInitially: true,
				Cell: (props) => (
					<div>
						{props.value} {props.original.team && <SvgIcon icon="user-friends" type="solid" />}
					</div>
				),
				customFilter: {
					// type: "select",
					// options: this.getUniqueObjectsInArray(this.state.workerFilterOptions),
					type: "text",
					placeholder: tr('type'),
					fields: ["_original.user.person_detail.full_name", "_original.team.name"],
				},
			},
			{
				id: 'customer_id',
				Header: tr('customer'),
				accessor: 'customer.name',
				onClick: this.showAssignment,
				width: 150,
				customizable: true,
				showInitially: true,
				customFilter: {
					// type: "select",
					// // options: this.getOptions("project/find/customer", "customer"),
					// options: this.getUniqueObjectsInArray(this.state.customerFilterOptions),
					type: "text",
					placeholder: tr('type'),
					fields: ["_original.customer.name"],

				},
			},
			{
				id: 'responsible_person',
				Header: tr('responsible_person'),
				// accessor: (props) => (props.user && props.user.person_detail) ? props.user.person_detail.full_name : props.team ? props.team.name : "",
				width: 150,
				customizable: true,
				showInitially: true,
				className: "overflowableOnHover",
				onClick: this.showAssignment,
				Cell: (props) => {

					let nameList = [];
					const data = props.original;

					if (data.manager) {
						nameList.push(data.manager.person_detail.full_name);
					}

					if (data.user && data.user.supervisors) {
						for (const supervisor of data.user.supervisors) {
							nameList.push(supervisor.person_detail.full_name);
						}
					}

					if (data.root_project && data.root_project.manager) {
						nameList.push(data.root_project.manager.person_detail.full_name);
					}

					if (data.team && data.team.team_members) {
						for (const teamMember of data.team.team_members) {
							if (teamMember.user.supervisors) {
								for (const supervisor of teamMember.user.supervisors) {
									nameList.push(supervisor.person_detail.full_name);
								}
							}
						}
					}
					//filter unique names
					nameList = [...new Set(nameList)];


					if (nameList.length > 1) {
						let text = [nameList[0]];
						for (let i = 1; i < nameList.length; i++) {
							text.push(<React.Fragment key={i}><br /> {nameList[i]}</React.Fragment>);
							// text.push(nameList[i]);
						}
						return <ApTooltip text={text} position="bottom">
							{tr("persons_amount") + ": " + nameList.length}
						</ApTooltip>
					}
					return nameList.length > 0 ? nameList[0] : null;


				},
				customFilter: {
					// type: "select",
					// options: this.getUniqueObjectsInArray(this.state.workerFilterOptions),
					type: "text",
					placeholder: tr('responsible_person'),
					fields: [
						"_original.manager.person_detail.full_name",
						"_original.user.supervisors.*.person_detail.full_name",
						"_original.root_project.manager.person_detail.full_name",
						"_original.team.team_members.*.user.supervisors.*.person_detail.full_name",
					],
				},
			},
			{
				id: 'supervisor',
				Header: tr('supervisor'),
				// accessor: (props) => (props.user && props.user.person_detail) ? props.user.person_detail.full_name : props.team ? props.team.name : "",
				width: 150,
				customizable: true,
				showInitially: false,
				className: "overflowableOnHover",
				onClick: this.showAssignment,
				Cell: (props) => {

					let nameList = [];
					const data = props.original;

					if (data.user && data.user.supervisors) {
						for (const supervisor of data.user.supervisors) {
							nameList.push(supervisor.person_detail.full_name);
						}
					}

					if (data.team && data.team.team_members) {
						for (const teamMember of data.team.team_members) {
							if (teamMember.user.supervisors) {
								for (const supervisor of teamMember.user.supervisors) {
									nameList.push(supervisor.person_detail.full_name);
								}
							}
						}
					}
					//filter unique names
					nameList = [...new Set(nameList)];


					if (nameList.length > 1) {
						let text = [nameList[0]];
						for (let i = 1; i < nameList.length; i++) {
							text.push(<React.Fragment key={i}><br /> {nameList[i]}</React.Fragment>);
							// text.push(nameList[i]);
						}
						return <ApTooltip text={text} position="bottom">
							{tr("persons_amount") + ": " + nameList.length}
						</ApTooltip>
					}
					return nameList.length > 0 ? nameList[0] : null;


				},
				customFilter: {
					// type: "select",
					// options: this.getUniqueObjectsInArray(this.state.workerFilterOptions),
					type: "text",
					placeholder: tr('supervisor'),
					fields: [
						"_original.user.supervisors.*.person_detail.full_name",
						"_original.team.team_members.*.user.supervisors.*.person_detail.full_name",
					],
				},
			},
			{
				id: 'project_manager',
				Header: tr('project_manager'),
				// accessor: (props) => (props.user && props.user.person_detail) ? props.user.person_detail.full_name : props.team ? props.team.name : "",
				width: 150,
				customizable: true,
				showInitially: false,
				className: "overflowableOnHover",
				onClick: this.showAssignment,
				Cell: (props) => {
					const data = props.original;
					return (data.root_project && data.root_project.manager) ? data.root_project.manager.person_detail.full_name:null;
				},
				customFilter: {
					// type: "select",
					// options: this.getUniqueObjectsInArray(this.state.workerFilterOptions),
					type: "text",
					placeholder: tr('project_manager'),
					fields: [
						"_original.root_project.manager.person_detail.full_name",
					],
				},
			},

			{
				id: 'description',
				Header: tr('description'),
				accessor: 'description',
				onClick: this.showAssignment,
				customizable: true,
				showInitially: true,
				customFilter: {
					type: "text",
					placeholder: tr('description'),
				},
			},
			{
				id: 'file',
				Header: tr('file'),
				accessor: 'files_count',
				onClick: this.showAssignment,
				width: 80,
				customizable: true,
				showInitially: true,
				Cell: (props) => {
					if (props.value)
						return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
				},
				customFilter: {
					type: "toggle",
				},
			},
			{
				id: 'recurrence',
				Header: tr('recurrence'),
				accessor: acc => acc.recurrence ? tr(acc.recurrence) : tr('no_recurrence'),
				onClick: this.showAssignment,
				customizable: true,
				showInitially: false,
				customFilter: {
					type: "select",
					options: [
						{ label: tr('no_recurrence'), value: '' },
						{ label: tr('daily'), value: 'daily' },
						{ label: tr('weekly'), value: 'weekly' },
						{ label: tr('monthly'), value: 'monthly' },
					],
				},
			},
			{
				id: 'created_at',
				Header: tr('created_at'),
				accessor: 'created_at',
				onClick: this.showAssignment,
				width: 100,
				filterable: false,
				customizable: true,
				showInitially: false,
				Cell: (props) => props.value
					? moment(props.value).format("L HH:mm")
					: "",
			},
			{
				id: 'begins',
				Header: capitalize(tr('begins')),
				accessor: 'begin_at',
				onClick: this.showAssignment,
				filterable: false,
				customizable: true,
				showInitially: true,
				Cell: (props) => props.value
					? moment(props.value).format("L HH:mm")
					: "",
			},
			{
				id: 'ends',
				Header: capitalize(tr('ends')),
				accessor: 'end_at',
				onClick: this.showAssignment,
				filterable: false,
				customizable: true,
				showInitially: true,
				Cell: (props) => props.value
					? moment(props.value).format("L HH:mm")
					: "",
			},
			{
				id: 'status_id',
				Header: tr('status'),
				accessor: 'status_id',
				onClick: this.showAssignment,
				Cell: props => tr(assignement_status[props.original.status_id]),
				width: 100,
				customizable: true,
				showInitially: true,
				customFilter: {
					type: "select",
					options: this.getStatusOptions(),
				},
			}
		];

		this.setState({ tableColumns: columns });

	}

	getClassList() {

		let options = [{ value: '', label: ` - ${tr('all')} - ` }];

		/*
		let sampleOptions = [
			{
				class_id: 1,
				class_name: 'Ventus'
			},
			{
				class_id: 2,
				class_name: 'Portaali'
			},
			{
				class_id: 3,
				class_name: 'Atk-tukipyyntö'
			}
		];
		*/

		this.state.classes.forEach(cl => {
			options.push({ value: cl.class_id, label: cl.class_name });
		});

		//console.log(options);

		return options;

	}

	showAssignment(assignment) {

		if ('id' in assignment)
			window.emitter.emit(
				'goTo',
				{
					pathname: '/assignments/' + assignment.id + "/" + this.props.tabSelected
				}
			);

	}
	showAssignmentModal(assignment) {

		if (assignment.id) {
			this.setState({ showAssignmentModal: true, selectedAssignmentId: assignment.id });
		}


	}
	changeSelectedAssignmentModal(id) {
		this.setState({
			selectedAssignmentId: id,
		});
	}


	renderConfirmArchiveAssignment() {
		return <ApConfirm
			show={this.state.showConfirmArchiveAssignment}
			onClose={() => this.setState({ showConfirmArchiveAssignment: false, selectedIds: [] })}
			onConfirm={() => this.ArchiveAssignments()}
			header={tr('archive_assignments')}
			body={
				<div>
					<p>
						<strong>{tr('assignment_archive_confirm')}</strong>
					</p>

				</div>
			}
		/>
	}
	renderConfirmDeleteAssignment() {
		return <ApConfirm
			show={this.state.showConfirmDeleteAssignment}
			onClose={() => this.setState({ showConfirmDeleteAssignment: false, selectedIds: [] })}
			onConfirm={() => this.DeleteAssignments()}
			header={tr('delete_assignments')}
			body={
				<div>
					<p>
						<strong>{tr('assignment_delete_confirm')}</strong>
					</p>

				</div>
			}
		/>
	}

	renderAssigmentModal() {
		return <ApModal
			show={this.state.showAssignmentModal}
			handleClose={() => this.setState({ showAssignmentModal: false, selectedAssignmentId: null })}
			// closeFromBg
			// className="big"
			header={
				<div className="padding-small">
					<h1> {tr('assignment')} </h1>
					<p> {tr('assignment_info')} </p>
				</div>
			}
			body={
				<AssignmentPage
					ref={node => this.assignmentPageReff = node }
					id={this.state.selectedAssignmentId}
					tab={this.props.tabSelected}
					close={() => { this.setState({ showAssignmentModal: false, selectedAssignmentId: null }) }}
					changeAssignmentTo={(id) => this.changeSelectedAssignmentModal(id)}
					modalMode={true}
					changed={(value) => {
						this.setState({ assignmentPageChanged: value });
					}}
					updateList={(object) => {
						let assignments = [...this.state.assignments];
						assignments = assignments.map((assignment) => {
							if (assignment.id == object.id) {
								assignment.name = object.name;
								if (object.project) {
									if (!assignment.project) {
										assignment.project={};
									}
									assignment.project.code = object.project.code;
									assignment.project.name = object.project.name;
									assignment.project.id = object.project.id;
									assignment.project.type = object.project.type;
								}
								else {
									assignment.project = null;
								}

								if (object.customer) {
									if (!assignment.customer) {
										assignment.customer = {};
									}
									assignment.customer.name = object.customer.name;
									assignment.customer.id = object.customer.id;
								}
								else {
									assignment.customer = null;
								}

								//TODO Add names (supervisors,...)

								if (object.temp_changes) {
									if (!assignment.temp_changes) {
										assignment.temp_changes = {};
									}
									assignment.temp_changes.begin_at = object.temp_changes.begin_at;
									assignment.temp_changes.id = object.temp_changes.id;
									assignment.temp_changes.created_at = object.temp_changes.created_at;
									assignment.temp_changes.end_at = object.temp_changes.end_at;
									assignment.temp_changes.team = object.temp_changes.team;
									assignment.temp_changes.team_id = object.temp_changes.team_id;
								}
								else {
									assignment.temp_changes = null;
								}


								assignment.name = object.name; 
								assignment.description = object.description; 
								assignment.created_at = object.created_at;
								assignment.begin_at = object.begin_at;
								assignment.end_at = object.end_at;
								assignment.status_id = object.status_id;
								console.log(assignment);
								console.log(object);
							}

							return assignment
						});
						this.setState({
							assignments: assignments
						});
					}
					}
					history={this.props.history}
				/>
			}
			footer={
				<div className="footer padding">
					<div className="justify-space-between">
						<ApButton color="gray" onClick={() => {
							this.setState({ showAssignmentModal: false, selectedAssignmentId: null })
						}}

						>
							<SvgIcon icon="times" type="solid" />
							{tr('cancel')}
						</ApButton>
						<ApButton color="blue" onClick={() => {
							if (this.assignmentPageReff) {
								this.assignmentPageReff.saveChanges(true);
							}
						}}
							disabled={!this.state.assignmentPageChanged}
							className={this.state.assignmentPageChanged ? ("highlight") : ""}
						>
							<SvgIcon icon="save" type="solid" />
							{tr('save')}
						</ApButton>

					</div>

				</div>
			}
		/>
	}

	render() {
		return (

			<div id="assignmentsTable">
				<ApReactTable
					rememberId={`assignmentsTable_${this.props.tabSelected}`}
					loading={this.state.loading}
					data={this.state.assignments}
					columns={this.state.tableColumns}
					showFiltersInitially={true}
					filterable
					multiselect={this.multiselect}
					minRows={10}
					rowActions={(row) =>
						<div className="apSimpleButton" onClick={() => this.showAssignmentModal(row)}> <SvgIcon icon="share-square" type="solid" /> </div>
					}
				/>
				{this.renderConfirmArchiveAssignment()}
				{this.renderConfirmDeleteAssignment()}
				{this.renderAssigmentModal()}
			</div>

		);

	}

}