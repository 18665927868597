import React from 'react';

import ApReactTable from 'common/ApReactTable/ApReactTable';
import { capitalize, currentLang, formatCurrency, tr } from 'services/Helpers/Helpers';
import moment from 'moment';
import autoBind from 'react-autobind';
import ApOptionInput from 'common/ApOptionInput/ApOptionInput';
import Totals from '../Totals';
import ApSwitch from 'common/ApSwitch/ApSwitch';

class Period extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            view: 'week', // week or month
            rows: [],
            showEmptyRows: true,
        }
        autoBind(this);
    }

    componentDidMount() {
        if (window.localStorage.getItem('cashflowforecast-view')) {
            this.setState({view: window.localStorage.getItem('cashflowforecast-view')}, () => {
                this.state.view == 'week' ? this.renderWeekView() : this.renderMonthView();
            });
        }
        if (window.localStorage.getItem('empty-rows-view')) {
            this.setState({showEmptyRows: window.localStorage.getItem('empty-rows-view') == 'true' ? true : false}, () => {
                this.state.view == 'week' ? this.renderWeekView() : this.renderMonthView();
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.all_orders != this.props.all_orders) {
            this.renderView();
        }
        if (prevState.view != this.state.view) {
            this.renderView();
        }
        if (prevState.showEmptyRows != this.state.showEmptyRows) {
            this.renderView();
        }
        if(prevProps.openingBalance !== this.props.openingBalance) {
            this.renderView();
        }
    }

    renderView() {
        if (this.state.view == 'week') {
            this.renderWeekView();
        }
        else if (this.state.view == 'month') {
            this.renderMonthView();
        }
    }

    handleViewChange(value) {
        this.setState({view: value});
        window.localStorage.setItem('cashflowforecast-view', value);
    }

    handleEmptyRowsChange(event) {
        this.setState({showEmptyRows: !this.state.showEmptyRows}, () => {
            window.localStorage.setItem('empty-rows-view', this.state.showEmptyRows);
        });
    }

    renderMonthView() {
        let rows = [];

        this.props.all_orders.forEach(order => {
            const month = rows.find(row => row.month == moment(order.due_date).month() && row.year == moment(order.due_date).year());
            switch (order.type) {
                case 'sales_order':
                    if (month) {
                        if (month.sales) {
                            month.sales += parseFloat(order.price);
                        } else {
                            month.sales = parseFloat(order.price);
                        }
                    } else {
                        rows.push({
                            month: moment(order.due_date).month(),
                            year: moment(order.due_date).year(),
                            sales: parseFloat(order.price),
                        });
                    }
                    break;
                case 'purchase_order':
                    if (month) {
                        if (month.purchases) {
                            month.purchases += parseFloat(order.price);
                        } else {
                            month.purchases = parseFloat(order.price);
                        }
                    } else {
                        rows.push({
                            month: moment(order.due_date).month(),
                            year: moment(order.due_date).year(),
                            purchases: parseFloat(order.price),
                        });
                    }
                    break;
                
                case 'payment_post':
                    if (month) {
                        if (month.sales) {
                            month.sales += parseFloat(order.payment_amount);
                        } else {
                            month.sales = parseFloat(order.payment_amount);
                        }
                    } else {
                        rows.push({
                            month: moment(order.payment_date).month(),
                            year: moment(order.payment_date).year(),
                            sales: parseFloat(order.payment_amount),
                        });
                    }
                    break;

                default:
                    break;
            }
        });

        if (this.state.showEmptyRows) {
            let firstYear = null;
            let firstMonth = null;
    
            let lastYear = null;
            let lastMonth = null;
    
            if (rows.length > 0) {
                firstYear = this.props.start ? moment(this.props.start).year() : moment().year();
                firstMonth = this.props.start ? moment(this.props.start).month() : moment().month();
    
                lastYear = this.props.end ? moment(this.props.end).year() : rows[rows.length - 1].year;
                lastMonth = this.props.end ? moment(this.props.end).month() : rows[rows.length - 1].month;
    
                let currentMonth = firstMonth;
                let currentYear = firstYear;

                let isBefore = moment().year(currentYear).month(currentMonth).isBefore(moment().year(lastYear).month(lastMonth), 'month');
                let loops = 0;
    
                while (isBefore) {
                    if (loops > 200) break;
                    if (!rows.find(row => row.month == currentMonth && row.year == currentYear)) {
                        rows.push({
                            month: currentMonth,
                            year: currentYear
                        });
                    }
                    const now = moment().month(currentMonth).year(currentYear).add(1, 'month');
                    currentMonth = now.month();
                    currentYear = now.year();
                    isBefore = now.isBefore(moment().year(lastYear).month(lastMonth), 'month');
                    loops++;
                }
            }
        }

        rows.sort((a, b) => moment().month(a.month).year(a.year).valueOf() - moment().month(b.month).year(b.year).valueOf());
        rows.map((item, index) => {
            if (index === 0) {
                item.cumulative = (this.props.openingBalance ? parseFloat(this.props.openingBalance) : 0) + ((item.sales || 0) - (item.purchases || 0));
            } else {
                item.cumulative = (item.sales || 0) - (item.purchases || 0) + rows[index-1].cumulative;
            }
        });

        this.setState({rows});
    }

    renderWeekView() {
        let rows = [];

        this.props.all_orders.forEach(order => {
            const week = rows.find(row => row.week == moment(order.due_date).isoWeek() && row.year == moment(order.due_date).year());
            switch (order.type) {
                case 'sales_order':
                    if (week) {
                        if (week.sales) {
                            week.sales += parseFloat(order.price);
                        } else {
                            week.sales = parseFloat(order.price);
                        }
                    } else {
                        rows.push({
                            week: moment(order.due_date).isoWeek(),
                            year: moment(order.due_date).year(),
                            sales: parseFloat(order.price),
                        });
                    }
                    break;
                case 'purchase_order':
                    if (week) {
                        if (week.purchases) {
                            week.purchases += parseFloat(order.price);
                        } else {
                            week.purchases = parseFloat(order.price);
                        }
                    } else {
                        rows.push({
                            week: moment(order.due_date).isoWeek(),
                            year: moment(order.due_date).year(),
                            purchases: parseFloat(order.price),
                        });
                    }
                    break;

                case 'payment_post':
                    if (week) {
                        if (week.sales) {
                            week.sales += parseFloat(order.payment_amount);
                        } else {
                            week.sales = parseFloat(order.payment_amount);
                        }
                    } else {
                        rows.push({
                            week: moment(order.payment_date).isoWeek(),
                            year: moment(order.payment_date).year(),
                            sales: parseFloat(order.payment_amount),
                        });
                    }
                    break;

                default:
                    break;
            }
        });

        if (this.state.showEmptyRows) {
            let firstYear = null;
            let firstWeek = null;
    
            let lastYear = null;
            let lastWeek = null;
    
            if (rows.length > 0) {
                firstYear = this.props.start ? moment(this.props.start).year() : moment().year();
                firstWeek = this.props.start ? moment(this.props.start).isoWeek() : moment().isoWeek();
    
                lastYear = this.props.end ? moment(this.props.end).year() : rows[rows.length - 1].year;
                lastWeek = this.props.end ? moment(this.props.end).isoWeek() : rows[rows.length - 1].week;
    
                let currentWeek = firstWeek;
                let currentYear = firstYear;

                let isBefore = moment().year(currentYear).isoWeek(currentWeek).isBefore(moment().year(lastYear).isoWeek(lastWeek), 'isoWeek');
                let loops = 0;
    
                while (isBefore) {
                    if (loops > 200) break;
                    if (!rows.find(row => row.week == currentWeek && row.year == currentYear)) {
                        rows.push({
                            week: currentWeek,
                            year: currentYear
                        });
                    }
                    const now = moment().isoWeek(currentWeek).year(currentYear).add(1, 'week');
                    currentWeek = now.isoWeek();
                    currentYear = now.year();
                    isBefore = now.isBefore(moment().year(lastYear).isoWeek(lastWeek), 'isoWeek');
                    loops++;
                }
            }
        }

        rows.sort((a, b) => moment().isoWeek(a.week).year(a.year).valueOf() - moment().isoWeek(b.week).year(b.year).valueOf());
        rows.map((item, index) => {
            if (index === 0) {
                item.cumulative = (this.props.openingBalance ? parseFloat(this.props.openingBalance) : 0) + (item.sales || 0) - (item.purchases || 0);
            } else {
                item.cumulative = (item.sales || 0) - (item.purchases || 0) + rows[index-1].cumulative;
            }
        });
        this.setState({rows});
    }

    render() {
        return (
            <div className='padding'>
                <Totals 
                    in_total={this.props.in_total} 
                    orders_in_total={this.props.orders_in_total} 
                    po_in_total={this.props.po_in_total} 
                    openingBalance={this.props.openingBalance} 
                />
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <ApOptionInput
                        value={ this.state.view }
                        onChange={ this.handleViewChange }
                        options={[ 
                            {label: tr('monthly_view'), value: 'month'},
                            {label: tr('weekly_view'), value: 'week'}
                        ]}
                    />
                    <div>
                        <label htmlFor={'show-empty-rows-toggle'} className="info">
                            {tr('show_empty_rows')}
                        </label>
                        <ApSwitch 
                            id='show-empty-rows-toggle'
                            on={this.state.showEmptyRows}
                            onChange={this.handleEmptyRowsChange}
                            inline
                        />
                    </div>
                </div>
                <ApReactTable
                    rememberId='period-view'
                    loading={this.props.loading}
                    data={this.state.rows}
                    columns={[
                        {
                            id: 'view',
                            Header: this.state.view == 'week' ? capitalize(tr('week')) : tr('month'),
                            accessor: acc => this.state.view == 'week' 
                                ? `${capitalize(tr('week'))} ${acc.week} ${acc.year}`
                                : acc.month >= 0 
                                    ? capitalize(moment().locale(currentLang()).month(acc.month).format('MMMM')) + ' ' + acc.year 
                                    : '',
                            sortable: false,
                        },
                        {
                            id: 'revenue',
                            Header: tr('revenue'),
                            accessor: acc => acc.sales ? formatCurrency(acc.sales) : '0 €',
                            sortable: false,
                        },
                        {
                            id: 'expenses',
                            Header: tr('expenses_2'),
                            accessor: acc => acc.purchases ? formatCurrency(acc.purchases) : '0 €',
                            sortable: false,
                        },
                        {
                            id: 'in_total',
                            Header: tr('in_total'),
                            accessor: acc => {
                                const sales = acc.sales || 0;
                                const purchases = acc.purchases || 0;
                                const in_total  = sales - purchases;
                                return formatCurrency(in_total);
                            },
                            sortable: false,
                        },
                        {
                            id: 'balance',
                            Header: tr('balance'),
                            accessor: acc => {
                                return formatCurrency(acc.cumulative);
                            },
                            sortable: false,
                        },
                    ]}
                />
            </div>
        )
    }
}

export default Period;