/* eslint-disable eqeqeq */
import { tr } from  'services/Helpers/Helpers.js';

const ENTRY_ICONS = {
	hour: 		"clock",
	travel: 	"car",
	expense: 	"money-bill-wave",
	allowance: 	"suitcase",
	absence: 	"ban",
	notation: 	"trophy",
}

// This should match with DB table 'static_work_hour_statuses'
const WOKRHOUR_STATUSES = {
    suggested: 	tr('suggested'),
    open: 		tr('open'),
    submitted: 	tr('sent'),
    rejected: 	tr('rejected'),
    approved: 	tr('approved'),
    confirmed: 	tr('confirmed'),
    payrolled: 	tr('payrolled'),
    paid: 		tr('paid'),
};

const TIMETRACKING_ICONS = {
	open: "pen",
	submitted: "paper-plane",
	rejected: "times",
	approved: "check",
	confirmed: "check-double",
	payrolled: "hand-holding-usd",
	paid: "wallet",
};

const TIMETRACKING_ICON_COLORS = {
	open: "gray",
	submitted: "blue",
	rejected: "red",
	approved: "green",
	confirmed: "green",
	payrolled: "teal",
	paid: "teal",
};

export function entryIcon( entryType )
{
	if( entryType in ENTRY_ICONS )
		return ENTRY_ICONS[ entryType ];
	return false;
}

export function statusText( status )
{
	if( status in WOKRHOUR_STATUSES )
		return WOKRHOUR_STATUSES[ status ];
	return false;
}

export function timetrackingIcon( entryType )
{
	if( entryType in TIMETRACKING_ICONS )
		return TIMETRACKING_ICONS[ entryType ];
	return false;
}

export function timetrackingIconColor( entryType )
{
	if( entryType in TIMETRACKING_ICON_COLORS )
		return TIMETRACKING_ICON_COLORS[ entryType ];
	return false;
}

