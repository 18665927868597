import React from 'react';
//import autoBind from 'react-autobind';
//import PropTypes from 'prop-types';
import auth from 'services/Authed/Authed.js';

import UserManager from 'modules/UserManagement/UserManager.js';
import './Users.css';
import { tr } from 'services/Helpers/Helpers';

class UsersSettings extends React.Component {

    render() 
    {
        return (
            <div>
                <div className="apBoxHeader">
                    <h2>{ tr('customer_managers') }</h2>
                    <p>{ tr('customer_managers_info') }</p>
                </div>
                <div className="padding">

                    <UserManager
                        companyId={ auth.getCompanyID() }
                        companySuffix={ auth.getCompanySuffix() }
                    />

                </div>
            </div>
        );
    }
}

export default UsersSettings;