import React from 'react';
import autoBind from 'react-autobind';
import SvgIcon     from 'common/SvgIcon/SvgIcon.js';
import { Collapse } from 'react-bootstrap';

import ApButton         from 'common/ApButton/ApButton.js';
import { ApInput,
         ApInputStack,
         ApAddon }      from 'common/ApInput/ApInput.js';

import { keyExists, tr }    from 'services/Helpers/Helpers.js';

import './CodeFormat.css';

class CodeFormat extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            editOpen: false,

        };
        autoBind(this);
    }

    handleModeChange( id )
    {
        let selected = this.props.modeOptions.find( m => m.id === id );
        this.props.onChange({ mode: { ...selected } })
    }

    handleSeparatorChange( value )
    {
        this.props.onChange({ separator: value });
    }

    handleNameChange( value, index )
    {
        let levels = this.props.levels.slice( 0 );
        levels = levels.map( ( l, i ) => {
            if( i === index )
                l.name = value;
            return l;
        });
        this.props.onChange({ levels: levels });
    }

    handleLegthChange( value, index )
    {
        value = parseInt( value, 10 );
        if( isNaN( value ) ) value = null;

        if( value > 50) value = 50;

        if( index === 'tail' )
            this.props.onChange({ tail_length: value });
        else
        {
            let levels = this.props.levels.slice( 0 );
            levels = levels.map( ( l, i ) => {
                if( i === index )
                    l.length = value;
                return l;
            });
            this.props.onChange({ levels: levels });
        }
    }

    addLevel()
    {
        let levels = this.props.levels.slice( 0 );
        levels.unshift( {
            level:  levels.length + 1,
            name:   null,
            length: null,
        });
        this.props.onChange({ levels: levels });
    }

    removeLevel( index )
    {
        let levels = this.props.levels.slice( 0 );
        levels = levels.filter( ( l, i ) => i !== index );
        this.props.onChange({ levels: levels });
    }

    renderModeSelect()
    {
        const options = this.props.modeOptions.map( ( option ) => {
            return { value: option.id, label: tr(option.description) };
        });

        return <ApInputStack gap="0">
            <ApAddon noRightBorder width="200px">
                {this.props.isCostCenter ? tr('cost_center_format'): tr('storage_code_format') }
            </ApAddon>
            <ApInput
                type="select"
                options={ options }
                id="storageModeSelect"
                name="storageModeSelect"
                value={ keyExists( this.props.mode, "id", true, null ) }
                onChange={ ( e ) =>  this.handleModeChange( parseInt( e.target.value, 10 ) ) }
                loading={ this.props.loading }
                disabled={ this.props.loading }
            />
        </ApInputStack>
    }

    renderLevelRow( title, name, length, index )
    {
        let mode = this.props.mode;
        if( !mode ) return null;

        let nameInput = null;

        if( index !== 'tail' )
            nameInput = <ApInput
                type="text"
                id="separatorInput"
                name="separatorInput"
                label={ tr('level_name') }
                value={ name }
                onChange={ ( e ) =>  this.handleNameChange( e.target.value, index ) }

                loading={ this.props.loading }
                disabled={ this.props.loading }
            />

        let lengthInput = null;
        if( mode.need_length )
            lengthInput = <ApInput
                type="text"
                id="separatorInput"
                name="separatorInput"
                label={ tr('level_length') }
                value={ length }
                onChange={ ( e ) =>  this.handleLegthChange( e.target.value, index ) }
                loading={ this.props.loading }
                disabled={ this.props.loading }
            />

        let removeButton = null;
        if( index === 0 )
            removeButton = <ApButton size="small"
                color="white"
                onClick={ () => { this.removeLevel( index ) } }
                loading={ this.props.loading }
                disabled={ this.props.loading }
            >
                <SvgIcon icon="trash" type="solid" />
            </ApButton>

        return <tr key={ index } className="levelRow">
            <td className="titleCol">
                { title }
            </td>
            <td className="nameCol">
                { nameInput }
            </td>
            <td className="lengthCol">
                { lengthInput }
            </td>
            <td className="removeCol">
                { removeButton }
            </td>
        </tr>
    }

    renderEdit()
    {
        let mode = this.props.mode;
        if( !mode ) return null;


        let modeSelect = this.renderModeSelect();

        let table = null;
        if( !mode.is_free )
        {
            let separatorInput = null;
            if( mode.need_separator )
            {
                separatorInput = <ApInput
                    type="text"
                    id="separatorInput"
                    name="separatorInput"
                    label={ tr('delimiter_sign') }
                    value={ this.props.separator }
                    onChange={ ( e ) => this.handleSeparatorChange( e.target.value ) }
                    loading={ this.props.loading }
                    disabled={ this.props.loading }
                />
            }

            let rows = this.props.levels.map( ( l, i ) => {
                return this.renderLevelRow(`Taso ${ i + 1 }`,  l.name, l.length, i );
            });
            if (mode.need_length && !this.props.isCostCenter) {
                rows.push(this.renderLevelRow(tr('component_specific'), null, this.props.tailLength, 'tail'));
            }
            


            table = <table className="levelsTable">
                <thead>
                    <tr className="levelRow">
                        <th colSpan="2" className="levelsTableHeader">{ tr('code_levels') } </th>
                        <th colSpan="2">
                            { separatorInput }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { rows }
                    <tr>
                        <td colSpan="4" className="addCol">
                            <ApButton size="small"
                                color="white" 
                                onClick={ this.addLevel }
                                loading={ this.props.loading }
                                disabled={ this.props.loading }
                            >
                                <SvgIcon icon="plus" type="solid" />
                                { tr('add_level') }
                            </ApButton>
                        </td>
                    </tr>
                </tbody>
            </table>
        }

        let editOpen = null;
        let editClose = null;


        if( !this.props.loading )
        {
            if( !this.state.editOpen )
                editOpen = <div className="text-right">
                    <ApButton size="xtiny" color="white" onClick={ () => { this.setState({ editOpen: true }) } }>
                        <SvgIcon icon="edit" type="solid" />
                        { tr('edit') }
                    </ApButton>
                </div>
            else
            {
                editClose = <div className="text-right">
                    <ApButton size="xtiny" color="white" onClick={ () => { this.setState({ editOpen: false }) } }>
                        <SvgIcon icon="eye-slash" type="solid" />
                        { tr('hide') }
                    </ApButton>
                </div>
            }
        }

        return <div>
            <Collapse in={ this.state.editOpen }>
                <div className="editContainer">
                    { modeSelect }
                    { table }
                    { editClose }
                </div>
            </Collapse>
            { editOpen }
        </div>
    }

    renderPreview()
    {

        let mode = this.props.mode;
        if( !mode ) return null;

        let doms = [];

        let makeGroup = ( name, length, i ) => {

            if( !name ) name = `Taso ${ i + 1 }`;

            let x = null;
            if( mode.need_length )
                x = <span className="previewX">{ 'x'.repeat( length ) }</span>
            else
                x = <span className="previewX">x<SvgIcon icon="arrows-alt-h" type="solid" />x</span>

            doms.push(
                <div key={`g-${ i }`} className="previewGroup">
                    <div className="previewGroupName">{ name }</div>
                    <div>{ x }</div>
                </div>
            );
        };

        let makeSeparator = ( i ) => {
            if( mode.need_separator )
                doms.push(
                    <span key={`s-${ i }`}  className="previewSeparator">{ this.props.separator }</span>
                );
        };

        let totalLengthDom = null;

        if( this.props.loading )
        {
            doms = <div className="apLoader"></div>
        }
        else if ( mode.is_free )
        {
            doms = <div className="previewGroup"><div className="freeText">{ tr('code_format_unrestricted') }</div></div>
        }
        else
        {
            let totalLength = 0;

            this.props.levels.forEach( ( level, i ) => {
                totalLength += parseInt( level.length, 10 );
                makeGroup(level.name, level.length, i);
                if (this.props.isCostCenter ? (this.props.levels.length-1 > i) : this.props.levels.length > i) {
                    makeSeparator(i);
                }
                
            });

            totalLength += this.props.tailLength;
            if (this.props.tailLength) {
                makeGroup(tr('component_specific'), this.props.tailLength, 'tail');
            }
            

            if( mode.need_length )
            {
                if( isNaN( totalLength ) ) totalLength = null;
                totalLengthDom = <div className="top-right">{ tr('total_length') }: { totalLength }</div>
            }
        }


        return <div className="previewContainer">
            <div className="top-left">{ tr('format_of_codes') }</div>
            { totalLengthDom }
            { doms }
        </div>
    }

    render()
    {
        const edit = this.renderEdit();
        const preview = this.renderPreview();

        return <div id="codeFormat">
            { edit }
            { preview }
        </div>
    }
}

export default CodeFormat;
