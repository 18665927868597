import React from 'react';
import autoBind from 'react-autobind';

import auth from 'services/Authed/Authed.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import { formatMoney
       , sqlToDateInput
       , tr
       , capitalize } from 'services/Helpers/Helpers.js';
import { getTypeIcon }  from 'modules/Storage/common/StorageHelpers.js';

import './PurchaseOrderTooltip.css';

class PurchaseOrderTooltip extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            currencySign: auth.getCurrencySign(),
        }
        autoBind(this);
    }

    render()
    {
        const po= this.props.po;

        if( !po) return null;
        let supplierDom = null;
        if( po.supplier_id )
        {
            supplierDom = <div>
                <SvgIcon icon={ getTypeIcon( po.supplier_type ) } type="solid" />
                { po.supplier_title }
            </div>
        }

        let receiverDom = null;
        if( po.receiver_id )
        {
            receiverDom = <div>
                <SvgIcon icon={ getTypeIcon( po.receiver_type ) } type="solid" />
                { po.receiver_title }
            </div>
        }


        return <div className="poTooltip">
            <table className="infoTable">
                <thead>
                    <tr>
                        <th colSpan="2" className="header">{ tr('purchase_order') }</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="infoLabel">{ tr('number') }:</td>
                        <td className="valueText">{ po.number }</td>
                    </tr>
                    <tr>
                        <td className="infoLabel">{ tr('status') }:</td>
                        <td className="valueText">{ po.status_title }</td>
                    </tr>
                    <tr>
                        <td className="infoLabel">{ tr('date') }:</td>
                        <td className="valueText">{ sqlToDateInput( po.date ) }</td>
                    </tr>

                    <tr>
                        <td className="infoLabel">{ tr('original_creator') }:</td>
                        <td className="valueText">{ po.created_by }</td>
                    </tr>

                    <tr>
                        <td className="infoLabel">{ tr('price') }:</td>
                        <td className="valueNumber">{ formatMoney( po.price ) } { this.state.currencySign }</td>
                    </tr>

                    <tr>
                        <td className="infoLabel">{ tr('supplier') }:</td>
                        <td className="valueText">{ supplierDom }</td>
                    </tr>

                    <tr>
                        <td className="infoLabel">{ tr('recipient') }:</td>
                        <td className="valueText">{ receiverDom }</td>
                    </tr>

                    <tr>
                        <td className="infoLabel">{ tr('due_date') }:</td>
                        <td className="valueText">{ sqlToDateInput( po.due_date )}</td>
                    </tr>

                    <tr>
                        <td className="infoLabel">{ tr('delivery_date') }:</td>
                        <td className="valueText">{ sqlToDateInput( po.delivery_date ) }</td>
                    </tr>


                </tbody>
            </table>
        </div>
    }
}

export default PurchaseOrderTooltip;

