import React from 'react';
import autoBind from 'react-autobind';
import Measure from 'react-measure';

import auth                from 'services/Authed/Authed.js';
import ApStickyBar         from 'common/ApStickyBar/ApStickyBar.js';

import ApSwitch          from 'common/ApSwitch/ApSwitch.js';
import ApDropdown       from 'common/ApDropdown/ApDropdown.js';

import api                from 'services/Api/Api.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApTooltip          from 'common/ApTooltip/ApTooltip.js';
import ApButton           from 'common/ApButton/ApButton.js';
import { removeRoundOff
       , formatMoney
       , errorPopper
       , onlyNumber
       , tr }     from 'services/Helpers/Helpers.js';

import { ApInput
       , ApInputStack
       , ApAddon  }      from 'common/ApInput/ApInput.js';

import NewPurchaseOrder from 'modules/Storage/PurchaseOrders/NewPurchaseOrder/NewPurchaseOrder.js';
import OrderTooltip from 'modules/Storage/common/OrderTooltip/OrderTooltip.js';

import './Demands.css';

class Demands extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {

            newPoShow: false,
            newPoData: null,

            selectedMode: null,
            components: [],
            componentsOutside: [],

            groups: [],
            selectedGroupIndex: 'info',

            orders: [],

            projects: [],

            selectedComponents: [],

            showAllPrices: false,
            hideTargets: false,
            hideOutside: false,

            selectedComponentsVisible: false,

            selectedSupplier: null,


            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),
        };

        this.idField = {
            component: 'component_id',
            supplier: 'id',
            order: 'id',
            project: 'id',
        }

        autoBind(this);

    }

    componentDidMount()
    {
        const params = this.props.match.params;

        // select node based on params
        this.getComponents().then(() => {
            if( params )
            {
                let mode = ( params.mode ) ? params.mode : null;
                this.handleModeChange( mode, () => {

                    if( params.id )
                    {
                        let index = -1;
                        const groups = this.state.groups;

                        index = groups.findIndex( c => c[ this.idField[ mode ]] === parseInt( params.id, 10 ))
                        if( index !== -1 )
                            this.selectGroup( index );
                    }
                });
            }
        });
    }

    reload(hideOutside)
    {
        const params = this.props.match.params;

        // select node based on params
        this.getComponents(hideOutside).then(() => {
            if( params )
            {
                let mode = ( params.mode ) ? params.mode : null;
                this.handleModeChange( mode, () => {

                    if( params.id )
                    {
                        let index = -1;
                        const groups = this.state.groups;

                        index = groups.findIndex( c => c[ this.idField[ mode ]] === parseInt( params.id, 10 ))
                        if( index !== -1 )
                            this.selectGroup( index );
                    }
                });
            }
        });
    }

    getComponents(hideOutside=false)
    {
        let url= `storage/demands`;
        if (hideOutside) {
            url='storage/demands/noOutside'
        }
        return api({
            method: 'get',
            url: url,
        }).then((response) => {

            //console.log('storage/demands', response );

            this.setState({
                components: response.components,
                componentsOutside: response.components_outside,
                orders: response.orders,
                projects: response.projects,
            });
        }).catch((error) => {
            console.error(error);
            errorPopper( error, tr('get_error') );
        });
    }

    selectGroup( index )
    {
        const mode = this.state.selectedMode;
        const groups = this.state.groups;

        let id = groups[ index ][ this.idField[ mode ] ];

        this.setState( { selectedGroupIndex: index } );

        this.props.history.replace( `/storage/purchase/demands/${ mode }/${ id }` );
    }

    renderGroups()
    {
        const mode = this.state.selectedMode;

        const groups = this.state.groups.map( ( group, index ) => {

            let classes = [ 'supplier' ];

            if( this.state.selectedGroupIndex === index )
                classes.push( 'selected' );

            if( mode === 'order' )
                return <div
                    key={ index }
                    className={ classes.join(" ") }
                    onClick={ () => { this.selectGroup( index ) } }
                >
                    <SvgIcon className="icon" icon="receipt" type="solid" />
                    <div className="receiverType"> { tr('orderer') }: { tr('company') } </div>
                    <div className="receiverName"> { group.receiver_name }</div>
                    <div className="number">{ tr('number') }: { group.number }</div>

                    <div className="components"> { tr('amount') } { group.components.length }</div>
                    <div className="orderDate">{ tr('ordered_at') }: { group.created }</div>
                </div>
            else if( mode === 'project' )
                return <div
                    key={ index }
                    className={ classes.join(" ") }
                    onClick={ () => { this.selectGroup( index ) } }
                >
                    <SvgIcon className="icon" icon="project-diagram" type="solid" />
                    <div className="receiverType"> { tr('project') }: </div>
                    <div className="receiverName"> { group.receiver_name }</div>
                    <div className="number">{ tr('number') }: { group.number }</div>

                    <div className="components"> { tr('amount') } { group.components.length }</div>
                    <div className="orderDate">{ tr('created_at') }: { group.created }</div>
                </div>
            else if (mode === 'supplier') {
                classes.push("supplierSelected");
                return <div
                    key={ index }
                    className={ classes.join(" ") }
                    onClick={ () => { this.selectGroup( index ) } }
                >
                    { Boolean( group.id ) && <SvgIcon className="icon" icon="building" type="solid" /> }
                    {group.name}<br />
                    <div className="components"> { tr('amount') } { group.components.length }</div>
                </div>
            }

            else if( mode === 'component' )
            {
                let icon = ( group.component_id ) ? 'cube' : 'bookmark';
                return <div
                    key={ index }
                    className={ classes.join(" ") }
                    onClick={ () => { this.selectGroup( index ) } }
                >
                    <SvgIcon className="icon" icon={ icon } type="solid" />

                    <ApTooltip position="top" text={ group.name } block>
                        <div className="componentName">{ group.name }</div>
                    </ApTooltip>
                    <div className="componentCode">{ group.code }</div>
                    <div className="number"> { tr('suppliers_count') }{ group.suppliers.length }</div>

                    { ( group.targets.length > 1 ) && <div className="components"> { tr('targets_count') } { group.targets.length }</div> }
                </div>
            }

            return null;
        });

        if( mode && groups.length === 0 )
            return <div className="supplier">{ tr('not_found') }</div>

        return <div>
            { groups }
        </div>
    }

    selectTarget( component, targetIndex )
    {
        let sComponents = this.state.selectedComponents.slice( 0 );
        const scIndex = sComponents.findIndex( c => c.component_id === component.component_id );

        let target = component.targets[ targetIndex ];
        let newTarget = { index: targetIndex, target: target };

        if( scIndex === -1  )
        {
            sComponents.push({
                component_id: component.component_id,
                component: component,
                selectedTargets: [ newTarget ],
            });
        }
        else
        {
            const stIndex = sComponents[ scIndex ].selectedTargets.findIndex( t => t.index === targetIndex );
            if( stIndex === -1 )
                sComponents[ scIndex ].selectedTargets.push( newTarget );
            else
                sComponents[ scIndex ].selectedTargets.splice( stIndex, 1 );

            if( sComponents[ scIndex ].selectedTargets.length === 0 )
                sComponents.splice( scIndex, 1 );
        }
        this.setState({ selectedComponents: sComponents });
    }

    changeCount( value, component, targetIndex )
    {
        let components = this.state.components.slice( 0 );
        components = components.map( c => {
            if( c.component_id === component.component_id )
            {
                value = onlyNumber( value );
                c.targets[ targetIndex ].count = value;
            }
            return c;
        })
        this.setState({ components: components });
    }

    changeComment( value, component, targetIndex )
    {
        let components = this.state.components.slice( 0 );

        components = components.map( c => {
            if( c.component_id === component.component_id )
            {
                c.targets[ targetIndex ].demands_comment = value;
            }
            return c;
        })
        setTimeout(() => {
            this.saveComment( value, component, targetIndex)
          }, 1000);
        this.setState({ components: components });
    }

    saveComment(value, component, targetIndex) {
        let components = this.state.components.slice( 0 );
        components = components.map( c => {
            if( c.component_id === component.component_id )
            {
                if (c.targets[ targetIndex ].demands_comment == value) {
                    this.saveCommentPost(value, c.targets[ targetIndex ].target_id, component.component_id);
                }
            }
        })
    }

    saveCommentPost(value, location_id, component_id) {
        api({
            method: 'post',
            url: `storage/demands/updateComment`,
            data: {
                comment: value,
                location_id: location_id,
                component_id: component_id
            }
        }).then((response) => {
        }).catch((error) => {
            console.log(error);
            errorPopper(error, tr('save_error'));
            this.setState({loading: false});
        });
    }


    newPo()
    {
        const sComponents = this.state.selectedComponents;
        const sSupplier   = this.state.selectedSupplier;

        let components = [];
        sComponents.forEach( c => {

            let foundSupplier = null;
            if( sSupplier )
                foundSupplier = c.component.suppliers.find( s => s.id === sSupplier.id );

            c.selectedTargets.forEach( t => {
                const target = t.target;
                components.push({
                    component_id: c.component.component_id,
                    name: c.component.name,
                    type_id: c.component.type_id,
                    count:        target.count,
                    target_final_type: target.target_type,
                    target_final_id:   target.target_id,
                    purchase_order_price: foundSupplier ? foundSupplier.price : null,

                    // Used on direct items, so we can dig it up on EditPurchaseOrder
                    id: target.component_instance_id ? target.component_instance_id : null,
                });
            });
        });


        let data = {};
        if( this.state.selectedSupplier )
            data.supplier = this.state.selectedSupplier;

        if( components.length > 0)
            data.components = components;

        //console.log('data', data );

        this.setState({
            newPoShow: true,
            newPoData: data,
        })
    }

    renderInfo()
    {
        return <div className="noDemand">
            { this.renderInfoAboutAdditions() }

            <div className="infoTitle">{ tr('usage') }:</div>
            <div className="apInfo small">
                <SvgIcon icon="long-arrow-alt-left" type="solid" />
                { tr('demands_usage_note1') }
            </div>
            <div className="apInfo small">
                <SvgIcon icon="check-square" type="solid" />
                { tr('demands_usage_note2') }
            </div>
            <div className="apInfo small">
                <SvgIcon icon="long-arrow-alt-down" type="solid" />
                { tr('demands_usage_note3') }
            </div>
            <div className="apInfo small">
                <SvgIcon icon="long-arrow-alt-down" type="solid" />
                { tr('demands_usage_note4') }
            </div>
        </div>
    }

    renderView()
    {
        const mode = this.state.selectedMode;

        if( this.state.selectedGroupIndex !== 'info' )
        {
            if( mode === 'component' )
                return this.renderViewComponent();
            if( mode === 'supplier' )
                return this.renderViewSupplier();
            if ( mode === 'order' )
                return this.renderViewOrder();
            if ( mode === 'project' )
                return this.renderViewProject();
        }

        return this.renderInfo();
    }

    renderViewComponent()
    {
        const index = this.state.selectedGroupIndex;

        if( index === null ) return null;

        let sComponent = this.state.groups[ index ]

        let options = sComponent.suppliers.map( s => {
            return { value: s.id, label: s.name }
        });

        options.unshift({ value: null, label: ''})

        return <div>
            { this.renderViewSettings() }
            { this.renderComponent( sComponent ) }
        </div>
    }

    renderViewSupplier()
    {
        const index = this.state.selectedGroupIndex;
        if( index === null ) return null;

        let selected = this.state.groups[ index ];

        const rows = selected.components.map( c => {
            return this.renderComponent( c, selected.id  );
        })

        return <div>
            { this.renderViewSettings() }
            { rows }
        </div>
    }

    renderViewOrder()
    {
        const index = this.state.selectedGroupIndex;
        if( index === null ) return null;

        let selected = this.state.groups[ index ];

        const neededDom = selected.needed.map( c => {
            return this.renderComponent( c );
        })

        let notNeededDom = null;
        if( selected.notNeeded.length )
            notNeededDom = this.renderNotNeededComponent( selected.notNeeded );

        return <div>
            { this.renderViewSettings() }
            { neededDom }
            { notNeededDom }
        </div>
    }

    renderViewProject()
    {
        const index = this.state.selectedGroupIndex;
        if( index === null ) return null;

        let selected = this.state.groups[ index ];

        const neededDom = selected.needed.map( c => {
            return this.renderComponent( c );
        })

        let notNeededDom = null;
        if( selected.notNeeded.length )
            notNeededDom = this.renderNotNeededComponent( selected.notNeeded );

        return <div>
            { this.renderViewSettings() }
            { neededDom }
            { notNeededDom }
        </div>
    }

    renderComponent( c, supplierId = null )
    {
        let hideTargets = [];
        let supplier = null;
        if( c.suppliers ) supplier = c.suppliers.find( s => s.id === supplierId );

        let price = ( supplier ) ? <div className="headerPrice">{ supplier.price } { this.state.currencySign }</div> : null;

        return <div key={ c.component_id } className="componentContainer">
            <div className="name">{ c.name }</div>
            <div className="code">{ c.code }</div>
            { price }
            { this.renderTargets( c, hideTargets ) }
            { this.renderPrices( c ) }
        </div>
    }

    renderViewSettings()
    {
        return <div className="viewSettings">
            <div className="apSwitchBlock">
                <ApSwitch
                    id="show-target"
                    on={ this.state.hideTargets }
                    onChange={ () => { this.setState({ hideTargets: !this.state.hideTargets }) } }
                />
                <label htmlFor="show-targets">
                    { tr('hide_targets') }
                </label>
            </div>

            <div className="apSwitchBlock">
                <ApSwitch
                    id="show-all-prices"
                    on={ this.state.showAllPrices }
                    onChange={ () => { this.setState({ showAllPrices: !this.state.showAllPrices }) } }
                />
                <label htmlFor="show-all-prices">
                    { tr('show_prices_from_all_suppliers') }
                </label>
            </div>
        </div>
    }

    renderTargets( component, hideTargets = [] )
    {
        if( this.state.hideTargets )
            return null;

        if( !component.targets.length ) return tr('no_targets');

        let targetRows = component.targets.map( ( target, targetIndex ) => {

            // For orders we only want to show targets that the item is ordered from
            // Target hiding is made like this because we use the targetIndex for
            // target identifying so we just cannot remove them
            if( hideTargets.includes( targetIndex ) )
                return null;

            let countValue = target.count;

            // Findout if current target is checked
            let checked = false;
            const sComponents = this.state.selectedComponents.slice( 0 );
            const sComponent = sComponents.find( c => c.component_id === component.component_id );

            if( sComponent )
            {
                const sTarget = sComponent.selectedTargets.find( t => t.index === targetIndex );
                if( sTarget )
                    checked = true;
            }

            let targetDom = target.target_type;

            let balanceDom = '-';
            let newBalanceDom = '-';
            let notificationDom = '-';
            let alarmDom = '-';

            let countClasses = [ 'countInput' ];

            if( target.target_type === 'company' )
            {
                const orderTooltip = <OrderTooltip order={ target.order } />
                targetDom = <ApTooltip position="top" text={ orderTooltip }>
                    <SvgIcon className="targetIcon" icon="receipt" type="solid" />
                    { tr('company') }
                </ApTooltip>
            }
            else if( target.target_type === 'location' )
            {
                targetDom = <div>
                    <SvgIcon className="targetIcon" icon="archive" type="solid" />
                    { tr('storage') }
                </div>

                let count = parseFloat( countValue );
                if( !count ) count = 0;

                if ( checked && !Boolean( count ) )
                    countClasses.push( 'inputError' )

                let balance = parseFloat( target.balance );
                if( !balance ) balance = 0;

                let newBalance = balance + count;

                const balanceTooltip = <div>
                    <table className="tooltipTable">
                        <tbody>
                            <tr>
                                <td className="tText">{ tr('storage_balance') }:</td>
                                <td className="tValue">{ target.balance_current }</td>
                            </tr>
                            <tr>
                                <td className="tText">{ tr('balance_after_reservations') }:</td>
                                <td className="tValue">{ target.balance }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                balanceDom = <ApTooltip position="topright" text={ balanceTooltip }>
                    { balance }
                </ApTooltip>


                newBalanceDom = <ApTooltip position="topright" text={ tr('situation_after_order') }>
                    { removeRoundOff( newBalance ) }
                </ApTooltip>


                const notificationNeed = removeRoundOff( target.notification - balance );
                const notificationAfter = removeRoundOff( target.notification - newBalance );
                const notificationTooltip = <div>
                    <table className="tooltipTable">
                        <tbody>
                            <tr>
                                <td className="tText">{ tr('attention_limit') }:</td>
                                <td className="tValue">{ target.notification }</td>
                            </tr>
                            <tr>
                                <td className="tText">{ tr('required_amount_to_limit') }:</td>
                                <td className="tValue">{ notificationNeed }</td>
                            </tr>
                            <tr>
                                <td className="tText">{ tr('to_limit_after_order') }:</td>
                                <td className="tValue">{ notificationAfter }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                notificationDom = <ApTooltip position="topright" text={ notificationTooltip }>
                    <span className={ ( notificationAfter > 0 ) ? 'notification' : '' }>
                        { notificationAfter }
                    </span>
                </ApTooltip>

                const alarmNeed = removeRoundOff( target.alarm - balance );
                const alarmAfter = removeRoundOff( target.alarm - newBalance );
                const alarmTooltip = <div>
                    <table className="tooltipTable">
                        <tbody>
                            <tr>
                                <td className="tText">{ tr('alert_limit') }:</td>
                                <td className="tValue">{ target.alarm }</td>
                            </tr>
                            <tr>
                                <td className="tText">{ tr('required_amount_to_limit') }:</td>
                                <td className="tValue">{ alarmNeed }</td>
                            </tr>
                            <tr>
                                <td className="tText">{ tr('to_limit_after_order') }:</td>
                                <td className="tValue">{ alarmAfter }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                alarmDom = <ApTooltip position="topright" text={ alarmTooltip }>
                    <span className={ ( alarmAfter > 0 ) ? 'alarm' : '' }>
                        { alarmAfter }
                    </span>
                </ApTooltip>
            }

            return <tr key={ targetIndex }>
                <td className="">
                    <input
                        type="checkbox"
                        onClick={ ( e ) => { this.selectTarget( component, targetIndex ) } }
                        onChange={ () => {}}
                        checked={ checked }
                    />
                </td>
                <td className="">
                    <ApTooltip text={target.projects &&
                        target.projects.map((project, index) =>
                            <div key={index}>{`${project.project_code} ${project.name} - ${tr('not_installed')}: ${project.alloc_count - project.installed_count}`}</div>)}
                    >
                        {targetDom}
                    </ApTooltip>
                </td>
                <td className="">
                    <ApTooltip text={target.projects &&
                        target.projects.map((project, index) =>
                            <div key={index}>{`${project.project_code} ${project.name} - ${tr('not_installed')}: ${project.alloc_count - project.installed_count}`}</div>)}
                    >
                        { target.name }
                    </ApTooltip>
                </td>
                <td>
                    <div className="demandsComment" >
                        <input
                            className="comment_input"
                            type="text"
                            value={ target.demands_comment }
                            onChange={ ( e ) => {
                                this.changeComment( e.target.value, component, targetIndex );
                            } }
                            disabled={ target.target_type !== 'location' }
                        />
                        {target.demands_comment && <SvgIcon className="clearIcon" icon="times-circle" type="solid" onClick={ () => this.changeComment( '', component, targetIndex ) } /> }
                    </div>
                </td>
                <td>
                    <div className="countInputContainer" >
                        <input
                            className={ countClasses.join(' ') }
                            type="text"
                            value={ countValue ? countValue : '' }
                            onChange={ ( e ) => {
                                if( !checked ) this.selectTarget( component, targetIndex );
                                this.changeCount( e.target.value, component, targetIndex );
                            } }
                            disabled={ target.target_type !== 'location' }
                        />
                        <div className="unit" title={ component.unit }>
                            { component.unit }
                        </div>
                    </div>
                </td>
                <td className="numberTd">
                    { balanceDom }
                </td>
                <td className="numberTd">
                    { newBalanceDom }
                </td>
                <td className="numberTd">
                    { notificationDom }
                </td>
                <td className="numberTd">
                    { alarmDom }
                </td>
            </tr>
        });

        return <div className="targetContainer">
            <table className="componentTable">
                <thead>
                    <tr>
                        <th></th>
                        <th>{ tr('target') }</th>
                        <th>{ tr('name') }</th>
                        <th>{ tr('comment') }</th>
                        <th>{ tr('amount') }</th>
                        <th className="numberTh">
                            <ApTooltip position="topright" text={ tr('demands_balance_now_info') }>
                                { tr('balance_now') }
                            </ApTooltip>
                        </th>
                        <th className="numberTh">
                            <ApTooltip position="topright" text={ tr('situation_after_order') }>
                                { tr('after') }
                            </ApTooltip>
                        </th>
                        <th className="numberTh">
                            <ApTooltip position="topright" text={ tr('demands_attention_limit_req') }>
                                <SvgIcon icon="exclamation-triangle" type="solid" />
                            </ApTooltip>
                        </th>
                        <th className="numberTh">
                            <ApTooltip position="topright" text={ tr('demands_alert_limit_req') }>
                                <SvgIcon icon="exclamation-circle" type="solid" />
                            </ApTooltip>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { targetRows }
                </tbody>
            </table>
        </div>
    }

    renderPrices( component )
    {
        if( !this.state.showAllPrices )
            return null;

        let priceRows = component.suppliers.map( ( s, i ) => {
            return <tr key={ i }>
                <td className="primary">{ s.primary ? <SvgIcon icon="check-circle" type="solid" /> : null }</td>
                <td className="supplier">{ s.name }</td>
                <td className="price">{ formatMoney( s.price, 2 ) } { this.state.currencySign }</td>
            </tr>
        });

        priceRows.unshift(
            <tr key="value" className="storagePrice">
                <td className="primary"></td>
                <td className="supplier">{ tr('components_price') }</td>
                <td className="price">{ formatMoney( component.price, 2 ) } { this.state.currencySign }</td>

            </tr>
        );

        return <div className="priceContainer">
            <table className="priceTable">
                <thead>
                    <tr>
                        <th className="primary">{ tr('primary') }</th>
                        <th className="supplier">{ tr('supplier') }</th>
                        <th className="price">{ tr('price') }</th>
                    </tr>
                </thead>
                <tbody>
                    { priceRows }
                </tbody>
            </table>

        </div>
    }

    renderNotNeededComponent( components )
    {
        const rows = components.map( (c, i) => {
            return <div key={ i } className="componentContainer">
                <div className="name">{ c.name }</div>
                <div className="code">{ c.code }</div>
                <div className="notNeeded">{ tr('no_need_to_order') }</div>
            </div>
        });

        return <div>
            { rows }
        </div>
    }

    handleModeChange( mode, aFunc = null )
    {
        // const mode = e.target.value;

        this.setState({
            selectedGroupIndex: 'info',
            selectedMode: mode,
            groups: [],
        }, () => {
            if( mode )
                this.props.history.replace( `/storage/purchase/demands/${ mode }` );
            // else
            //     this.props.history.replace( `/storage/purchase/demands` );
    
            if( mode === 'component' )
                this.setGroupsFromComponent( aFunc );
            else if( mode === 'supplier' )
                this.setGroupsFromSupplier( aFunc );
            else if ( mode === 'order' )
                this.setGroupsFromOrder( aFunc );
            else if ( mode === 'project' )
                this.setGroupsFromProject( aFunc );
            else
                this.setGroupsFromNull( aFunc );
        });
    }

    setGroupsFromNull( aFunc = null )
    {
        this.setState({ groups: [] }, aFunc );
    }

    setGroupsFromComponent( aFunc = null)
    {
        let allComponents = [ ...this.state.components, ...this.state.componentsOutside ];
        this.setState({ groups: allComponents }, aFunc );

    }

    setGroupsFromSupplier( aFunc = null )
    {
        let tmp = {};
        this.state.components.forEach( c => {
            if( c.suppliers.length )
            {
                c.suppliers.forEach( s => {
                    if( typeof tmp[ s.id ] === 'undefined' )
                    {
                        tmp[ s.id ] = {
                            id: s.id,
                            name: s.name,
                            components: [],
                        };
                    }
                    tmp[ s.id ][ 'components' ].push( c );
                });
            }
            else
            {
                if( typeof tmp[ 'null' ] === 'undefined' )
                {
                    tmp[ 'null' ] = {
                        id: null,
                        name: tr('supplier_missing'),
                        components: [],
                    };
                }
                tmp[ 'null' ][ 'components' ].push( c );
            }

        });

        let tmp2 = Object.keys( tmp ).map(  i => tmp[ i ] );
        this.setState({ groups: tmp2 }, aFunc );

    }

    setGroupsFromOrder( aFunc = null )
    {
        let orders = this.state.orders.slice( 0 );
        orders = this.state.orders.map( o => {

            let needed = [];
            let notNeeded = [];

            o.components.forEach( oc => {

                //console.log('oc', oc);

                let found = null;

                if( oc.component_id )
                {
                    found = this.state.components.find( c => ( oc.component_id === c.component_id ));
                    if( found ) needed.push( found );

                }
                else
                {
                    found = this.state.componentsOutside.find( c => ( oc.component_instance_id === c.component_instance_id ));
                    if( found ) needed.push( found );
                }

                if( !found )
                {
                    notNeeded.push( oc.meta );
                }
            });

            o.needed = needed;
            o.notNeeded = notNeeded;
            return o;
        });
        this.setState({ groups: orders }, aFunc );
    }

    setGroupsFromProject( aFunc = null )
    {
        let projects = this.state.projects.slice( 0 );
        projects = this.state.projects.map( p => {

            let needed = [];
            let notNeeded = [];

            p.components.forEach( pc => {

                //console.log('oc', oc);

                let found = null;

                if( pc.component_id )
                {
                    found = this.state.components.find( c => ( pc.component_id === c.component_id ));
                    if( found ) needed.push( found );

                }
                else
                {
                    found = this.state.componentsOutside.find( c => ( pc.component_instance_id === c.component_instance_id ));
                    if( found ) needed.push( found );
                }

                if( !found )
                {
                    notNeeded.push( pc.meta );
                }
            });

            p.needed = needed;
            p.notNeeded = notNeeded;
            return p;
        });
        this.setState({ groups: projects }, aFunc );
    }


    renderModeSelect()
    {
        return <div className="detailPicker">
            <ApInputStack gap="0">
                <ApAddon noRightBorder >
                    { tr('grouping') }
                </ApAddon>
                <ApInput
                    type="select"
                    options={[
                        { value: null, label: "" },                       
                        { value: "component", label: tr('storage_components') },
                        { value: "supplier", label: tr('suppliers') },
                        { value: "order", label: tr('orders') },
                        { value: "project", label: tr('projects')},
                    ]}
                    id="locationTreeDetail"
                    name="locationTreeDetail"
                    value={ this.state.selectedMode }
                    onChange={ ( e ) => this.handleModeChange( e.target.value ) }
                />
            </ApInputStack>
        </div>
    }

    renderSelectedComponents()
    {
        const selectedComponents = this.state.selectedComponents;
        if( selectedComponents.length === 0 )
            return null;

        const sSupplier = this.state.selectedSupplier;

        let targetCount = 0;

        let rows = [];

        selectedComponents.forEach( ( c ) => {
            let component = c.component;
            let priceDom = null;
            let priceTotalDom = '-';
            let price = null;

            if( sSupplier )
            {
                let cSupplier = component.suppliers.find( s => s.id === sSupplier.id );
                if( cSupplier )
                {
                    price = cSupplier.price;
                    priceDom = <span>{ formatMoney( price, 2 ) } { this.state.currencySign }</span>
                }
            }


            let targetRows = [];
            let priceTotal = 0;

            c.selectedTargets.forEach( ( st, stIndex ) => {
                let targetIndex = st.index;
                let target      = component.targets[ targetIndex ];
                // rows.push ( targetRow( component, target, targetIndex, price ) );

                priceTotal += parseFloat( price ) * parseFloat( target.count );
                targetRows.push( this.renderSelectedTargetRow( component, target, targetIndex, price ) );
                targetCount++;
            });

            if( !isNaN( priceTotal ) )
                priceTotalDom = <span>{ formatMoney( priceTotal, 2 ) } { this.state.currencySign }</span>

            rows.push( <tr className="headerRow" key={ c.component_id }>
                <td colSpan="6">
                    <div className="name">{ c.component.name }</div>
                    <div className="code">{ c.component.code }</div>
                    <div className="price">{ priceDom }</div>
                    <div className="priceTotal">{ priceTotalDom }</div>
                </td>
            </tr>);

            rows.push( ...targetRows );

        });

        return (
            <div className="selectedRows">
                <div className="button" onClick={ () => this.setState({ selectedComponentsVisible: !this.state.selectedComponentsVisible }) }>
                    { tr('selected_components') } <strong>{ selectedComponents.length }</strong><br />
                    { tr('selected_targets') } <strong>{ targetCount }</strong>
                </div>
                <div className={ "list" + ( this.state.selectedComponentsVisible ? " show" : "" ) }>
                    <div className="scroller">
                        <table className="targetTable">
                            <tbody>
                                { rows }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    renderSelectedTargetRow( component, target, targetIndex, price )
    {
        // Total price for targetRow
        let totalPriceDom = <div className="tRowPrice"> { tr('not_available') } </div>
        if( price )
        {
            let priceTotal = parseFloat( price ) * parseFloat( target.count );
            if( isNaN( priceTotal ))
                priceTotal = '-'
            else
                priceTotal = <span>{ formatMoney(  priceTotal, 2 ) } { this.state.currencySign }</span>
            totalPriceDom = <div className="tRowPrice">{ priceTotal }</div>
        }


        // Order for target
        let targetDom = target.target_type;
        if( target.target_type === 'direct' )
            targetDom = <div className="tTarget"><SvgIcon className="targetIcon" icon="receipt" type="solid" /> { tr('order') } </div>
        else if( target.target_type === 'location' )
            targetDom = <div className="tTarget"><SvgIcon className="targetIcon" icon="archive" type="solid" /> { tr('storage') } </div>


        // Count input
        let countValue = target.count;
        let countClasses = [ 'countInput' ];
        if( !parseFloat( countValue ) )
            countClasses.push( 'inputError' )

        const countInput = <div className="countInputContainer">
            <input
                className={ countClasses.join(' ') }
                type="text"
                value={ countValue ? countValue : '' }
                onChange={ ( e ) => {
                    this.changeCount( e.target.value, component, targetIndex );
                } }
                disabled={ target.target_type !== 'location' }
            />
            <div className="unit" title={ component.unit }>
                { component.unit }
            </div>
        </div>


        return <tr className="targetRow" key={ `${ component.component_id }-${ targetIndex }` }>
            <td>
                <input
                    type="checkbox"
                    onClick={ ( e ) => { this.selectTarget( component, targetIndex ) } }
                    onChange={ () => {} }
                    checked={ true }
                />
            </td>
            <td>
                { targetDom }
            </td>
            <td>
                <div className="tName">{ target.name }</div>
            </td>
            <td className="countTd">
                { countInput }
            </td>
            <td>{ totalPriceDom }</td>
        </tr>
    }

    renderBarRight()
    {
        const selectedComponents = this.state.selectedComponents;
        let suppliers = [];
        selectedComponents.forEach( sc => {
            const c = sc.component;

            let count = 0;
            sc.selectedTargets.forEach( t => {
                count += parseFloat( t.target.count );
            });

            c.suppliers.forEach( supplier => {
                let found = suppliers.find( s => s.id === supplier.id );
                if( !found )
                {
                    suppliers.push( { ...supplier,
                        selectedCount: 1,
                        price: parseFloat( supplier.price ) * count,
                    });
                }
                else
                {
                    found.selectedCount++;
                    found.price += ( parseFloat( supplier.price ) * count );
                }
            });
        });

        let options = suppliers.map( ( supplier ) => {

            const missing = ( supplier.selectedCount < selectedComponents.length );

            let price = null;
            let tooltip = null;

            if( missing )
                tooltip = tr('selected_suppliers_price_not_specified');
            else
            {
                if( !isNaN( supplier.price ) )
                    price = <span>{ formatMoney( supplier.price, 2 ) } { this.state.currencySign }</span>
                else
                {
                    tooltip = tr('selected_components_quantity_missing');
                    price = '??';
                }
            }

            let dom = <ApTooltip position="left" text={ tooltip } block>
                <div className="sMenuRow">
                    <div className="name">{ supplier.name }</div>
                    <div className="count"> { price }</div>
                </div>
            </ApTooltip>

            return {
                label: dom,
                icon: "building",
                action: ( id, closeFunc ) => {
                    this.setState({
                        selectedSupplier: { ...supplier },
                    });
                    closeFunc();
                },
            }
        })

        if( options.length === 0 )
        {
            options.unshift({
                label: <div className="noSel">{ tr('selected_components_supplier_missing') }</div>,
                icon: null,
                action: ( id, closeFunc ) => {
                    closeFunc();
                },
            })

        }
        else
        {
            options.unshift({
                label: <div className="noSel">{ tr('not_selected') }</div>,
                icon: null,
                action: ( id, closeFunc ) => {
                    this.setState({
                        selectedSupplier: null,
                    });
                    closeFunc();
                },
            })
        }

        let suppliesAllComponents = false;
        let allCountsDefined = false;

        let selectedSupplier = this.state.selectedSupplier;
        let selectedSupplierPrice = null;
        let selectedSupplierName = <span>&nbsp;</span>
        if( selectedSupplier )
        {
            selectedSupplierName  = selectedSupplier.name;

            const found = suppliers.find( s => s.id === selectedSupplier.id );
            if( found )
            {
                suppliesAllComponents = ( found.selectedCount === selectedComponents.length );
                allCountsDefined      = !isNaN( found.price );

                if( suppliesAllComponents && allCountsDefined )
                    selectedSupplierPrice = <span>{ formatMoney( found.price, 2 ) } { this.state.currencySign }</span>
            }
        }

        let saveTooltip = null;
        let saveDisabled = false;

        if( !selectedSupplier )
        {
            // saveDisabled = true;
            saveTooltip = tr('supplier_not_selected');
        }
        else if( !allCountsDefined )
        {
            // saveDisabled = true;
            saveTooltip = tr('selected_components_quantity_missing');
        }
        else if( !suppliesAllComponents )
        {
            // saveDisabled = true;
            saveTooltip = tr('supplier_price_not_defined_for_all');
        }

        let supplierSelect = <div className="supplierSelect">
            <div className="input-menu-title">{ tr('supplier') }</div>
            { selectedSupplierName }
            <div className="input-menu-button"><SvgIcon icon="angle-down" type="solid" /></div>
            </div>


        return <div className="buttonContainer">

            <div className="buttonContainer">
                {  selectedSupplierPrice }
            </div>
            <div className="buttonContainer">
                <ApDropdown
                    position="top"
                    button={ supplierSelect }
                    actions={ options }
                />
            </div>
            <div className="buttonContainer">
                <ApTooltip position="topright" text={ saveTooltip }>
                     <ApButton
                        color="blue"
                        onClick={() => { this.newPo() } }
                        disabled={ saveDisabled }
                    >
                        <SvgIcon icon="clipboard-check" type="solid" /> { tr('create_purchase_order') }
                    </ApButton>
                </ApTooltip>
            </div>
        </div>
    }

    renderNoDemands()
    {
        return <div className="apBox noDemand">
            <div className="apForm">
                <h3 className="apFormHeader">{ tr('nothing_to_order') }</h3>
                <div className="apFormColumn fullColumn">
                    { this.renderInfoAboutAdditions() }
                </div>
            </div>
        </div>
    }

    renderInfoAboutAdditions()
    {
        return <div>
            <div className="infoTitle">{ tr('demands_info_title') }:</div>
            <div className="apInfo small">
                <span className="notification"><SvgIcon icon="exclamation-triangle" type="solid" /></span>
                { tr('demands_info1') }
            </div>

            <div className="apInfo small">
                <span className="alarm"><SvgIcon icon="exclamation-circle" type="solid" /></span>
                { tr('demands_info2') }
            </div>

            <div className="apInfo small">
                <SvgIcon icon="archive" type="solid" />
                { tr('demands_info3') }
            </div>

            <div className="apInfo small">
                <SvgIcon icon="receipt" type="solid" />
                { tr('demands_info4') }
            </div>
        </div>
    }

    render()
    {
        let body = null;

        if( this.state.components.length === 0 )

        {
            body = this.renderNoDemands();
        }
        else
        {
            body = <div className="marginBottom" style={{overflowX: 'auto'}}>
                <div className="splitView">
                    <div className="left">
                        <div className="supplierList ">
                            { this.renderModeSelect() }
                            { this.renderGroups() }

                        </div>
                    </div>
                    <div className="right">
                        <div className="details">
                            { this.renderView() }
                        </div>
                    </div>
                </div>
            </div>
        }

        return <div>
            <Measure
                onResize={ () => {
                    if( this.stickyBar )
                        this.stickyBar.updateBounds();
                }}
            >
            {({ measureRef }) => <div ref={ measureRef } id="pageDemands">
                    <div className="apBox">
                        <div className="apBoxHeader">
                            <h1>
                                { tr('order_requirements') }
                            </h1>
                            <p> { tr('order_requirements_info') } </p>
                            <div className="apSwitchBlock">
                                <ApSwitch
                                    id="show-outside"
                                    on={ this.state.hideOutside }
                                    onChange={ () => { this.setState({ hideOutside: !this.state.hideOutside }); this.reload(!this.state.hideOutside) } }
                                />
                                <label htmlFor="show-outside">
                                    { tr('hide_external_components') }
                                </label>
                            </div>
                        </div>
                    </div>
                    { body }
                    { Boolean( this.state.selectedComponents.length ) &&
                    <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar= node }>
                        <div className="saveBar">
                            { this.renderSelectedComponents() }
                            { this.renderBarRight() }
                        </div>
                    </ApStickyBar> }
                </div>
            }
            </Measure>
            <NewPurchaseOrder
                show={ this.state.newPoShow }
                onClose={ () => { this.setState({ newPoShow: false }) } }
                onSave={ () => { } }
                data={ this.state.newPoData }
            />
        </div>
    }
}

export default Demands;
