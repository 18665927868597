import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import File        from 'common/File/File.js';
import ApButton         from 'common/ApButton/ApButton.js';
import ApReactTable     from 'common/ApReactTable/ApReactTable.js';
import SvgIcon          from 'common/SvgIcon/SvgIcon.js';

import RowEdit from './RowEdit/RowEdit.js';
import ApModal from 'common/ApModal/ApModal.js';
import { tr } from 'services/Helpers/Helpers.js';

import './OfferTemplates.css';


const templateField = {
    date: tr('current_date_form'),
    project_name: tr('project_name'),
    project_code: tr('project_code'),
    project_description: tr('project_additional_info'),
    offer_version: tr('offer_version'),
    offer_price: tr('offer_price_of_offer'),
    offer_price_decimal: tr('offer_price_of_offer_two_decimals'),
    customer_name: tr('customer_name'),
    customer_contact_name: tr('customer_contact_name'),
    creator_name: tr('offer_creator_name'),
    creator_phone: tr('offer_creator_phone'),
    creator_work_phone: tr('offer_creator_work_phone'),
    creator_email: tr('offer_creator_email'),
    creator_work_email: tr('offer_creator_work_email'),
    creator_title: tr('offer_creator_title'),
    valid_till: tr('offer_expiration_date'),

    offer_price_vat255: tr('offer_price_of_offer_vat255'),
    offer_price_vat255_decimal: tr('offer_price_of_offer_vat255_two_decimals'),
    offer_price_vat24: tr('offer_price_of_offer_vat24'),
    offer_price_vat24_decimal: tr('offer_price_of_offer_vat24_two_decimals'),
    vat_price: tr('vat_price'),
    vat_price_decimal: tr('vat_price_two_decimals'),

        
};

class OfferTemplates extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            rowEditIndex: -1,
            rowEditData: {},
            rowEditOpen: false,

            infoShow: false,

        };
        autoBind(this);
    }

    getColumns()
    {
        return [
            {
                Header: tr('offer_file_templates'),
                columns: [
                    {
                        id: 'name',
                        Header: tr('name'),
                        accessor: "name"
                    }, {
                        id: 'description',
                        Header: tr('description'),
                        accessor: "description",
                    }, {
                        id: 'created_at',
                        Header: tr('created_at'),
                        accessor: "created_at",
                        width: 200,
                        sortable: false,
                        filterable: false,
                        Cell: props => {
                            return moment( props.value ).format( 'DD.MM.YYYY' )
                        },
                    }, {
                        id: 'file_id',
                        Header: tr('file'),
                        accessor: "file_id",
                        width: 50,
                        sortable: false,
                        filterable: false,
                        Cell: props => {
                            const data = props.original;
                            return <File  fileId={ data.file_id } name={`${tr('offer_template')}-${ data.created_at }.${ data.file_extension }`}>
                                <SvgIcon icon="file-word" type="solid" />
                            </File>
                        },
                    }
                ]
            },
         ]
    }

    addRow()
    {
        this.setState({
            rowEditIndex: -1,
            rowEditData: {},
            rowEditOpen: true,
        });
    }

    editRow( data, index )
    {
        this.setState({
            rowEditIndex: index,
            rowEditData: data,
            rowEditOpen: true,
        });
    }

    editRowClose( save )
    {
        if( save )
        {
            let data = this.props.rows.slice();

            if( this.state.rowEditIndex !== -1 )
                data[ this.state.rowEditIndex ] = save;
            else 
                data.push( save );

            this.props.onChange( data );
        }

        this.setState({ rowEditOpen: false });
    }

    removeRows( indexes )
    {
        let data = this.props.rows.slice();
        data = data.filter( ( d, i ) => !indexes.includes( i ) );
        this.props.onChange( data );
    }

    renderInfoModalRows()
    {
        let rows = [];
        for( let key in templateField )
        {
            rows.push(<tr key={ key }>
                <td className="text">{ '${'+key+'}' }</td>
                <td className="description">{ templateField[ key ] }</td>
            </tr>);
        }

        return rows;
    }

    render()
    {
        return <div id="OfferTemplates">
            <div className="padding offerTemplateContainer">

                <h4 className="formHeader">{ tr('offer_file_templates') }</h4>
                <p>{ tr('offer_file_templates_info') }</p>

                <div className="offerTemplateInfoButton">
                    <ApButton size="tiny" color="blue" onClick={ () => this.setState({ infoShow: true }) }>
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('offer_template_specs') }
                    </ApButton>
                </div>

                <ApReactTable
                    loading={ this.state.loading }
                    data={ this.props.rows || [] }
                    columns={ this.getColumns() }
                    filterable
                    multiselect={[
                        { icon: "trash-alt", label: tr('delete_selected'), action: ( ids ) => { this.removeRows( ids ) }, unselectAfter: true, closeAfter: true },
                    ]}
                    minRows={ 5 }
                    rowActions={ ( row, index ) =>
                        <div className="apSimpleButton" onClick={ () => this.editRow( row, index ) }><SvgIcon icon="edit" type="solid" /></div>
                    }
                />
                <ApButton size="small" color="green" onClick={ () => this.addRow() }>
                    <SvgIcon icon="plus-square" type="solid" />
                    { tr('add') }
                </ApButton>

            </div>
            <RowEdit
                show={ this.state.rowEditOpen }
                data={ this.state.rowEditData }
                type={ this.state.rowEditKey }
                onClose={ this.editRowClose }
            />

            <div id="OfferTemplateInfo">
                <ApModal
                    show={ this.state.infoShow }
                    handleClose={ () => this.setState({ infoShow: false }) }
                    closeFromBg
                    className="narrow"
                    header={
                        <div className="padding">
                            <h4>
                                { tr('offer_template_specs') }
                            </h4>
                        </div>
                    }
                    body={
                        <div className="padding">
                            <p>
                                { tr('template_specs_info', [<a href="https://en.wikipedia.org/wiki/Office_Open_XML" target="_blank" rel="noopener noreferrer">Microsoft Office Open XML</a>, <strong>.docx</strong>]) }
                            </p>
                            <p>
                                { tr('listing_of_storage_components_info2') }
                            </p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{ tr('text') }</th>
                                        <th>{ tr('description') }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.renderInfoModalRows() }
                                </tbody>
                            </table>
                        </div>
                    }
                />
            </div>
        </div>
    }
}

export default OfferTemplates;
