import React from 'react';
import autoBind from 'react-autobind';

import ApButton         from 'common/ApButton/ApButton.js';
import ApReactTable     from 'common/ApReactTable/ApReactTable.js';
import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import ApTooltip        from 'common/ApTooltip/ApTooltip.js';

import RowEdit from './RowEdit/RowEdit.js';

import './ComponentRelations.css';

import { getComponentStatusId } from 'modules/Storage/common/StorageHelpers.js';
import { keyExists, tr } from 'services/Helpers/Helpers.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import { ApInput } from 'common/ApInput/ApInput.js';

class ComponentRelations extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            rowEditIndex: null,
            rowEditData: null,
            rowEditOpen: false,
            showExtraCalculatablesModal: false,
        }
        autoBind(this);
    }

    goToComponents(id, operator)
    {
        window.emitter.emit('goTo', {
            pathname: '/storage/components',
            state: {filters: [
                { id: 'identifier.'+id, value: operator },
                { id: 'status_id', value: getComponentStatusId( 'active' ) },
            ]}
        });
    }

    addRow(modal='rowEditOpen')
    {
        this.setState({
            rowEditIndex: -1,
            rowEditData: {},
            [modal]: true,
        });
    }

    editRow( data, index, extraCalc = false )
    {
        const modalKey = extraCalc
            ? 'showExtraCalculatablesModal'
            : 'rowEditOpen';
        this.setState({
            rowEditIndex: index,
            rowEditData: data,
            [modalKey]: true,
        });
    }

    editRowClose( save, extraCalc = false )
    {
        if( save )
        {
            let data;
            if (extraCalc) {
                data = this.props.extraCalculatables.splice( 0 );
            } else {
                data = this.props.rows.splice( 0 );
            }

            if( this.state.rowEditIndex !== -1 )
                data[ this.state.rowEditIndex ] = save;
            else
                data.push( save );

            const instance = extraCalc ? 'extraCalculatables' : this.props.instance;
            this.props.onChange({ [ instance ]: data });
        }

        this.setState({ rowEditOpen: false, showExtraCalculatablesModal: false });
    }

    removeRows( indexes, extraCalc = false )
    {

        let data = extraCalc 
            ? this.props.extraCalculatables.splice(0)
            : this.props.rows.splice( 0 );

        data = data.filter( ( d, i ) => !indexes.includes( i ) );

        const instance = extraCalc
            ? 'extraCalculatables'
            : this.props.instance;

        this.props.onChange({ [ instance ]: data });
    }

    getColumns()
    {
        const instance = this.props.instance;

        if( instance === 'identifiers' )
            return this.getIdentifierColumns();
        if( instance === 'calculatables' )
            return this.getCalculatableColumns();
        if( instance === 'properties' )
            return this.getPropertiesColumns();
        if( instance === 'trackingunits' )
            return this.getTrackingUnitColumns();
        if (instance === 'types') {
            return this.getTypesColumns();
        }

        return [];
    }

    getIdentifierColumns()
    {
        return [
            {
                Header: tr('identifiers'),
                columns: [
                    {
                        id: 'name',
                        Header: tr('name'),
                        accessor: "name"
                    }, {
                        id: 'description',
                        Header: tr('description'),
                        accessor: "description",
                    }, {
                        id: 'count',
                        Header: tr('in_storage_components'),
                        Cell: props => <div className="text-right pointer" onClick={() => {this.goToComponents(props.original.id, '*')}}>{ props.value }</div>,
                        filterable: false,
                        accessor: 'components_count',
                        width: 150,
                    }, {
                        id: 'not_count',
                        headerClassName: "overflowableOnHover", 
                        Header: 
                            <div className="iconHeader">
                                <ApTooltip text={ tr('missing_from_storage_components') }>
                                    { tr('missing') }
                                </ApTooltip>
                            </div>, 
                        Cell: props => <div className="text-right pointer" onClick={() => {this.goToComponents(props.original.id, '!')}}>{ props.value }</div>,
                        filterable: false,
                        accessor: 'components_count_missing',
                        width: 150,
                    }
                ]
            },
         ]
    }

    getCalculatableColumns()
    {
        return [
            {
                Header: tr('meters'),
                columns: [
                    {
                        id: 'name',
                        Header: tr('name'),
                        accessor: "name"
                    }, {
                        id: 'description',
                        Header: tr('description'),
                        accessor: "description",
                    }, {
                        id: 'work_component',
                        Header: tr('related_work_component' ),
                        Cell: props => {
                            const component = props.original.work_component;
                            if( component )
                            {
                                return <div className="a">
                                    <div className="b">{ component.name }</div>
                                    <div className="c">{ component.code }</div>
                                </div>
                            }
                            return null;
                        }

                    }, {
                        id: 'unit',
                        Header: tr('unit'),
                        accessor: "unit",
                        width: 100,
                    }, {
                        id: 'count',
                        Header: tr('in_storage_components'),
                        Cell: props => <div className="text-right pointer" onClick={() => {this.goToComponents(props.original.id, '*')}}>{ props.value }</div>,
                        filterable: false,
                        accessor: 'components_count',
                        width: 150,
                    }, {
                        id: 'not_count',
                        headerClassName: "overflowableOnHover", 
                        Header: 
                            <div className="iconHeader">
                                <ApTooltip text={ tr('missing_from_storage_components') }>
                                    { tr('missing') }
                                </ApTooltip>
                            </div>, 

                        Cell: props => <div className="text-right pointer" onClick={() => {this.goToComponents(props.original.id, '!')}}>{ props.value }</div>,
                        filterable: false,
                        accessor: 'components_count_missing',
                        width: 150,
                    }
                ]
            },
         ]
    }

    getPropertiesColumns()
    {
        return [
            {
                Header: tr('properties'),
                columns: [
                    {
                        id: 'name',
                        Header: tr('name'),
                        accessor: "name"
                    }, {
                        id: 'description',
                        Header: tr('description'),
                        accessor: "description",
                    }, {
                        id: 'unit',
                        Header: tr('unit'),
                        accessor: "unit",
                        width: 100,
                        Cell: props => {

                            if( props.original.is_boolean )
                                return <strong>{ `${tr('yes')} / ${tr('no')}` }</strong>
                            return props.value;
                        }

                    }, {
                        id: 'count',
                        Header: tr('in_storage_components'),
                        Cell: props => <div className="text-right pointer" onClick={() => {this.goToComponents(props.original.id, '*')}}>{ props.value }</div>,
                        filterable: false,
                        accessor: 'components_count',
                        width: 150,
                    }, {
                        id: 'not_count',
                        headerClassName: "overflowableOnHover", 
                        Header: 
                            <div className="iconHeader">
                                <ApTooltip text={ tr('missing_from_storage_components') }>
                                    { tr('missing') }
                                </ApTooltip>
                            </div>, 
                        Cell: props => <div className="text-right pointer" onClick={() => {this.goToComponents(props.original.id, '!')}}>{ props.value }</div>,
                        filterable: false,
                        accessor: 'components_count_missing',
                        width: 150,
                    }
                ]
            },
         ]
    }


    getTrackingUnitColumns()
    {
        return [
            {
                Header: tr('properties'),
                columns: [
                    {
                        id: 'name',
                        Header: tr('name'),
                        accessor: "name"
                    }, {
                        id: 'unit',
                        Header: tr('unit'),
                        accessor: "unit",
                        width: 100,
                        Cell: props => {

                            if( props.original.is_boolean )
                                return <strong>{ `${tr('yes')} / ${tr('no')}` }</strong>
                            return props.value;
                        }

                    }, {
                        id: 'count',
                        Header: tr('in_storage_components'),
                        Cell: props => <div className="text-right pointer" onClick={() => {this.goToComponents(props.original.id, '*')}}>{ props.value }</div>,
                        filterable: false,
                        accessor: 'components_count',
                        width: 150,
                    },
                ]
            },
         ]
    }

    getTypesColumns() {
        return [
            {
                Header: tr('types'),
                columns: [
                    {
                        id: 'name',
                        Header: tr('name'),
                        accessor: "name"
                    },
                    {
                        id: 'description',
                        Header: tr('description'),
                        accessor: "description"
                    },
                ]
            },
        ]
    }

    handleExtraCalcChange(e, num) {
        const rowData = {...this.state.rowEditData};
        rowData[`calculatable_${num}_id`] = e.target.value;
        rowData[`calculatable_${num}`] = this.props.rows.find(row => row.id == e.target.value);

        const data = this.props.extraCalculatables.map(item => {
            if (item.id == rowData.id) {
                return rowData;
            }
            return item;
        });
        this.props.onChange({ extraCalculatables: [...data] });
    }

    renderExtraCalculatablesTable() {
        const options = this.props.rows.map(row => ({...row, label: row.name, value: row.id}))
        return (
            <div>
                <ApReactTable
                    loading={ this.props.loading }
                    data={ this.props.extraCalculatables }
                    columns={ [{
                        Header: tr('extra_meters'),
                        columns: [
                            {
                                id: 'name',
                                Header: tr('name'),
                                accessor: "name"
                            },
                            {
                                id: 'description',
                                Header: tr('description'),
                                accessor: 'description'
                            },
                            {
                                id: 'meter_1',
                                Header: tr('meter') + ' 1',
                                accessor: "calculatable_1.name"
                            },
                            {
                                id: 'operator',
                                Header: tr('operator'),
                                accessor: "operator"
                            },
                            {
                                id: 'meter_2',
                                Header: tr('meter') + ' 2',
                                accessor: "calculatable_2.name"
                            },
                            {
                                id: 'unit',
                                Header: tr('unit'),
                                accessor: "unit"
                            },
                        ]
                    }] }
                    filterable
                    multiselect={[
                        { icon: "trash-alt", label: tr('delete_selected'), action: ( ids ) => { this.removeRows( ids, true ) }, unselectAfter: true, closeAfter: true },
                    ]}
                    minRows={ 5 }
                    rowActions={ ( row, index ) =>
                        <div className="apSimpleButton" onClick={ () => this.editRow( row, index, true ) }><SvgIcon icon="edit" type="solid" /></div>
                    }
                />
                <div>
                    <small>
                        <SvgIcon icon='info-circle' type='solid' className='size-small' /> {tr('extra_meters_info')}
                    </small>
                </div>
                <ApButton size="small" color="green" onClick={ () => this.addRow('showExtraCalculatablesModal') }>
                    <SvgIcon icon="plus-square" type="solid" />
                    {tr('new_extra_meter')}
                </ApButton>

                <RowEdit
                    instance={ 'extraCalculatables' }
                    show={ this.state.showExtraCalculatablesModal }
                    data={ this.state.rowEditData }
                    type={ this.state.rowEditKey }
                    onClose={(data) => this.editRowClose(data, true) }
                    rows={this.props.rows}
                />
            </div>
        );
    }

    render()
    {
        let addButtonTitle = null;
        if( this.props.instance === 'identifiers' )
            addButtonTitle = tr('add_identifier');
        else if( this.props.instance === 'calculatables' )
            addButtonTitle = tr('add_meter');
        else if( this.props.instance === 'properties' )
            addButtonTitle = tr('add_property');
        else if( this.props.instance === 'trackingunits' )
            addButtonTitle = tr('add_monitoring_unit');
        else if (this.props.instance === 'types')
            addButtonTitle = tr('add_new_type');


        return <div className="ComponentRelations">
            <ApReactTable
                loading={ this.props.loading }
                data={ this.props.rows }
                columns={ this.getColumns() }
                filterable
                multiselect={[
                    { icon: "trash-alt", label: tr('delete_selected'), action: ( ids ) => { this.removeRows( ids ) }, unselectAfter: true, closeAfter: true },
                ]}
                minRows={ 5 }
                rowActions={ ( row, index ) =>
                    <div className="apSimpleButton" onClick={ () => this.editRow( row, index ) }><SvgIcon icon="edit" type="solid" /></div>
                }
            />
            <ApButton size="small" color="green" onClick={ () => this.addRow() }>
                <SvgIcon icon="plus-square" type="solid" />
                { addButtonTitle }
            </ApButton>

            {this.props.instance === 'calculatables' &&
            this.renderExtraCalculatablesTable()}

            <RowEdit
                instance={ this.props.instance }
                show={ this.state.rowEditOpen }
                data={ this.state.rowEditData }
                type={ this.state.rowEditKey }
                onClose={ this.editRowClose }
            />
        </div>
    }
}

export default ComponentRelations;
