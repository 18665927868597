import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';
import {matchSorter} from 'match-sorter';
import { tr, currentLang } from 'services/Helpers/Helpers.js';
import './Crm.css';
import SvgIcon 			from 'common/SvgIcon/SvgIcon.js';
import ApReactTable 	from 'common/ApReactTable/ApReactTable.js';

export default class CrmList extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
        	searchLoading: false,
        	loading: false,
        	pages: -1,
            eventTypes: null,		
			CrmEvents: null
        };
        autoBind(this); 
 
    }

    componentDidMount()
	{
		this.getSettings();
		this.getCrmData();
    }

	getSettings()
    {
        api({
            method: 'get',
            url: 'crm/company/settings',
        }).then((response) => {
            //console.log('Settings', response);
            this.setState({ 
                eventTypes: response.eventTypes,
            });
        }).catch((error) => {
            console.log('ERROR while fetching settings!');
        });
    }

	getCrmData()
    {
		this.setState({ loading: true });
        api({
            method: 'get',
            url: 'crm/events',
        }).then(( response ) => {

            this.setState({ CrmEvents: response.events, loading: false })

        }).catch((error) => {
			console.log( error );
			this.setState({ loading: false })
            // TODO retry or fail? 
        });
	}

	getCrmManagerList()
	{
		let options = [ <option key="all" value="all" style={{color: 'black'}}>- { tr('all') } -</option> ];
		this.state.CrmEvents.forEach( event => {
			let duplicate = options.find( item => item.props.value === event.user_id );
			if (!duplicate) {
				options.push( <option key={event.user_id} value={event.user_id} style={{color: 'black'}}>{event.client_user}</option> );
			}
		})
		return options;
	}

	getCrmEventList()
	{
        let options = [ { value: '', label: `- ${tr('all')} -` } ];
        for (let event in this.state.eventTypes) {
            options.push({ value: event, label: this.state.eventTypes[event] })
        }
		return options;
	}

	showCompanyCrm( company )
	{
		if( "crm_company_id" in company )
			window.emitter.emit( 'goTo', { pathname: '/crm/company/' + company.crm_company_id + '/events' } );
	}

    render() 
    { 
        return (
            <div className="padding">

                { !this.state.CrmEvents &&
                    <div className="apMsg">
                        <div className="apInlineLoader"></div>
                    </div>
		        }

                {this.state.CrmEvents && 
                <ApReactTable 
                    rememberId="crm_list"
                    showFiltersInitially
                    loading={ this.state.loading }
                    data={ this.state.CrmEvents } 
                    columns={[
                        {
                            customizable: true, 
                            columns: [
                                {
                                    id: 'crm_company.name', 
                                    Header: tr('name'), 
                                    onClick: this.showCompanyCrm,
                                    Cell: ( props ) => {
                                        return (
                                            <div>
                                                <strong>{ props.original.crm_company.name }</strong><br />
                                                <small>{ props.original.crm_company.taxnumber ? props.original.crm_company.taxnumber : <span style={{ color: "#aaa" }}>Ei Y-tunnusta</span> }</small>
                                            </div>
                                        );
                                    },
                                    accessor: ( props ) => {
                                        return props.crm_company.name;
                                    }, 
                                    filterMethod: ( filter, rows ) => matchSorter( rows, filter.value, { keys: [ 
                                        "_original.crm_company.name", 
                                        "_original.crm_company.taxnumber",
                                    ]}), 
                                    filterAll: true,
                                    Filter: ({ filter, onChange }) => {
                                    return (
                                        <input
                                            placeholder = { tr('enter_name_business_id') }
                                            onChange={event => onChange(event.target.value)}
                                            value={filter ? filter.value : ''}
                                            style={{
                                                width: '100%',
                                                backgroundColor: 'lightgray',
                                                color: 'black',
                                            }}
                                        />   
                                    )
                                    }
                                },
                                { 
                                    id: 'user_id', 
                                    Header: tr('responsible_person'), 
                                    Cell: ( props ) => {
                                        return (
                                            <span>{ props.original.client_user }</span>
                                        );
                                    },
                                    customizable: true,
                                    showInitially: true,
                                    accessor: ( props ) => {
                                        return props.user_id;
                                    },
                                    filterMethod: ( filter, row ) => {
                                        let userId = row._original.user_id;
                                        if( filter.value === "all" )
                                            return true;
                                        if( filter.value == userId ) {
                                            return ( row._original.user_id === userId );
                                        }
                                        return false;
                                    }, 
                                    Filter: ({ filter, onChange }) => {
                                        let options = this.getCrmManagerList();
                                    return (
                                        <select
                                            onChange={ e => onChange( e.target.value ) }
                                            onMouseDown={ e => onChange( e.target.value ) } // Fixes FireFox bug (https://github.com/facebook/react/issues/12584)
                                            style={{ width: "100%", backgroundColor: 'lightgray', color: 'black' }}
                                            value={ filter ? filter.value : "all" }
                                        >
                                            {options}
                                        </select>
                                    )
                                    }
                                },
                                { 
                                    id: 'event', 
                                    Header: tr('event'), 
                                    Cell: ( props ) => {
                                        let type;
                                        if (props.original.type === 'call') {
                                            type = tr('call');
                                        } else if (props.original.type === 'email') {
                                            type = tr('email');
                                        } else if (props.original.type === 'visit') {
                                            type = tr('meeting');
                                        } else if (props.original.type === '-') {
                                            type = tr('other');
                                        }
                                        
                                        return ( type
                                            ? <span>{ type }</span>
                                            : <span className="empty">-</span>
                                        );
                                    },
                                    accessor: ( props ) => {
                                        return props.type;
                                    },
                                    filterMethod: ( filter, row ) => {
                                        if( filter.value === "all" )
                                            return true;
                                        if( filter.value === "call" )
                                            return ( row._original.type === 'call' );
                                        if( filter.value === "email" )
                                            return ( row._original.type === 'email' );
                                        if( filter.value === "visit" )
                                            return ( row._original.type === 'visit' ); 
                                        if( filter.value === "-" )
                                            return ( row._original.type === '-' );
                                        return false;
                                    }, 
                                    Filter: ({ filter, onChange }) => 
                                        <select
                                            onChange={ e => onChange( e.target.value ) }
                                            onMouseDown={ e => onChange( e.target.value ) } // Fixes FireFox bug (https://github.com/facebook/react/issues/12584)
                                            style={{ width: "100%", backgroundColor: 'lightgray', color: 'black' }}
                                            value={ filter ? filter.value : "all" }
                                        >
                                            <option value="all" style={{color: 'black'}}>- { tr('all') } -</option>
                                            <option value="call" style={{color: 'black'}}>{ tr('call') }</option>
                                            <option value="email" style={{color: 'black'}}>{ tr('email') }</option>
                                            <option value="visit" style={{color: 'black'}}>{ tr('meeting') }</option>
                                            <option value="-" style={{color: 'black'}}>{ tr('other') }</option>
                                        </select>,
                                                          
                                    customizable: true,     
                                    showInitially: true,
                                },
                                { 
                                    id: 'occurred_at', 
                                    Header: tr('occurred_at'), 
                                    accessor: props => props.occurred_at,
                                    Cell: ( props ) => {
                                        return (
                                            <span>{ moment( props.original.occurred_at ).locale(currentLang()).format('LLL') }</span>
                                        );
                                    },                   
                                    customizable: true,     
                                    showInitially: true,
                                    filterable: false,
                                    showInitially: true,
                                },
                                { 
                                    id: 'title', 
                                    Header: tr('header'), 
                                    accessor: "title",
                                    Cell: ( props ) => {
                                        return (
                                            <span>{ props.original.title }</span>
                                        );
                                    },               
                                    customizable: true,    
                                    showInitially: true,
                                    filterable: false,
                                    sortable: false,
                                },
                                { 
                                    id: 'description', 
                                    Header: tr('description'), 
                                    accessor: "description",
                                    Cell: ( props ) => {
                                        return ( props.original.description ?
                                            <span>{ props.original.description }</span> :
                                            <span className="empty">-</span>
                                        );
                                    },               
                                    customizable: true, 
                                    sortable: false,    
                                    showInitially: true,
                                    filterable: false,
                                },
                                { 
                                    id: 'action', 
                                    Header: tr('actions'), 
                                    accessor: "action",
                                    Cell: ( props ) => {
                                        return ( props.original.action ?
                                            <span>{ props.original.action }</span> :
                                            <span className="empty">-</span>
                                        );
                                    },               
                                    customizable: true, 
                                    sortable: false,    
                                    showInitially: true,
                                    filterable: false,
                                },
                            ],
                        }
                    ]}
                        defaultSorted={[ 
                            { id: "occurred_at", desc: true }
                        ]}
                        filterable
                        rowActions={ (row) => 
                            <div className="apSimpleButton" onClick={ () => this.showCompanyCrm( row ) }><SvgIcon icon="arrow-right" type="solid" /></div>
                        }
                        minRows={5}
                    />
                    }
            </div>

        );
    }
}
