import React from 'react';
import autoBind from 'react-autobind';
// import moment from 'moment';
// import api from 'services/Api/Api.js';
// import auth from 'services/Authed/Authed.js';

// import { dateInputToSql, sqlToDateInput, formatMoney } from 'services/Helpers/Helpers.js';

import './SalaryExtras.css';

// import SvgIcon from 'common/SvgIcon/SvgIcon.js';
// import ApButton from 'common/ApButton/ApButton.js';
// import ApReactTable from 'common/ApReactTable/ApReactTable.js';
// import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
// import ApSelect from 'common/ApSelect/ApSelect.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

import ManualExtras from './ManualExtras/ManualExtras.js';
import AutomaticExtras from './AutomaticExtras/AutomaticExtras.js';
import ApprovedExtras from './ApprovedExtras/ApprovedExtras.js';
import { keyExists, validateDate, tr } from 'services/Helpers/Helpers';
import moment from 'moment';

export default class TimetrackingSalaryExtras extends React.Component {

	constructor( props ) 
	{
		super(props);
        autoBind(this);
	}

    parseStatus( extra )
    {
        let status = "";
        let text = tr('waiting_for_approval1');
        let icon = "question";

        let isPaid = false;
        if( validateDate( keyExists( extra, "payroll_batch.payment_date", true ) ) )
        {
            if( moment( extra.payroll_batch.payment_date ).isBefore() )
                isPaid = true;
        }

        if( extra.payroll_batch_id && isPaid )
        { 
            status = "success";
            text = tr('paid')
            icon = "check-circle";
        }
        else if ( extra.payroll_batch_id )
        {
            status = "warning";
            text = tr('waiting_for_payment');
            icon = "hand-holding-usd"
        }
        else if ( extra.confirmed_by )
        {
            status = "info";
            text = tr('payable');
            icon = "hand-holding-usd";
        }
        else if ( extra.approved_by )
        {
            status = "warning";
            text = tr('waiting_for_confirm');
            icon = "exclamation-circle"
        }

        return {
            status: status,
            text: text,
            icon: icon
        };
    }

    render() 
    {
        return (
            <div id="SalaryExras">
                <div className="apBox">
                
                    <div className="apBoxHeader">
                	   <h1>{ tr('fees_supplements') }</h1>
                       <p>{ tr('fees_supplements_info') }</p>
                    </div>

                    <ApTabs>
                        <ApTab icon="trophy" label={ tr('one_time') } mountOnSelect>
                            <div className="padding">
                                
                                <ManualExtras history={ this.props.history } />

                            </div>
                        </ApTab>
                        <ApTab icon="magic" label={ tr('automatic_count') } mountOnSelect>
                            <div className="padding">
                                
                                <AutomaticExtras statusParser={ this.parseStatus } />

                            </div>
                        </ApTab>
                        <ApTab icon="check-circle" label={ tr('approved_supplements') } mountOnSelect>
                            <div className="padding">
                                
                                <ApprovedExtras statusParser={ this.parseStatus } />

                            </div>
                        </ApTab>
                    </ApTabs>

                </div>

            </div>
        );

    }
}
