import React, { Component } from "react";
import { Link } from "react-router-dom";
import { tr, mapTree, formatCurrency, currentLang } from "services/Helpers/Helpers";
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import { ApInput } from "common/ApInput/ApInput";
import ApButton from "common/ApButton/ApButton";
import autobind from "react-autobind";
import NewPurchaseOrder from "modules/Storage/PurchaseOrders/NewPurchaseOrder/NewPurchaseOrder";
import ApTooltip from "common/ApTooltip/ApTooltip";
import api from "services/Api/Api";
import NewTransport from "modules/Storage/Transports/NewTransport/NewTransport";

class ComponentNeeds extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            componentNeeds: [],
            selectedKeys: [],

            purchaseOrders: [],

            newPoShow: false,
            newPoData: {},

            newTransportShow: false,
            newTransportData: {},

            locationOptions: [{ value: '', label: tr('all') }],
            componentOptions: [{ value: '', label: tr('all') }],
            supplierOptions: [{ value: '', label: tr('all') }],
            purchaseOrderStatusOptions: [{ value: '', label: tr('all') }],
            supplierFilter: null,
        };

        this.locationFilter = null;
        this.supplierFilter = null;

        autobind(this);
    }

    componentDidMount() {
        if (this.props.componentNeeds) {
            this.setState({ componentNeeds: this.props.componentNeeds });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.componentNeeds && prevProps.componentNeeds.length != this.props.componentNeeds.length) {
            this.setState({ componentNeeds: this.props.componentNeeds });
        }
    }
    handleChange(event, item) {
        const value = !event.target.value || event.target.value > 0 ? event.target.value : '0';
        let componentNeeds = [...this.state.componentNeeds];

        const foundComponent = componentNeeds.find(c => c.component_id == item.original.component_id && c.storageLocation && c.storageLocation.id == item.original.storageLocation.id);
        if (foundComponent) {
            foundComponent.count = value;
        }

        this.setState({ componentNeeds });
    }

    handleOrder() {
        if (!this.state.selectedKeys.length) return;

        let selectedComponents = this.state.componentNeeds.filter((component, index) => this.state.selectedKeys.includes(index));
        selectedComponents.forEach(component => {
            component.id = null;
            component.unit = component.component && component.component.unit;
            component.purchase_order_price = component.component && component.component.price;
            component.location_id = component.storageLocation ? component.storageLocation.id : null;
            component.target_final_type = 'location';
        });

        const data = {};
        data.components = selectedComponents;
        data.project = this.props.tree[0];
        data.project.storage_location_id = this.props.tree[0].storage_location ? this.props.tree[0].storage_location.id : null;

        for (let component of selectedComponents) {
            for (let supplier of component.component.suppliers) {
                if (this.supplierFilter) {
                    data.supplier = this.supplierFilter == supplier.id ? supplier : null;
                } else {
                    data.supplier = supplier;
                }
                if (data.supplier) break;
            }
            if (data.supplier) break;
        }

        if (this.locationFilter && this.props.locations) {
            const location = this.props.locations.find(location => Number(location.id) === Number(this.locationFilter));
            if (location) {
                let receiverProjectId = null;
                mapTree(this.props.tree, item => {
                    if (receiverProjectId) return item;
                    if (item.storage_location && Number(item.storage_location.id) === Number(this.locationFilter)) {
                        receiverProjectId = item.id;
                        data.project = item;
                    }
                    return item;
                });
                data.receiverType = location.type == 'S' ? 'location' : 'project';
                data.receiverTitle = location.name;
                data.receiverName = location.delivery_name;
                data.receiverAddress = location.delivery_address;
                data.receiverCity = location.delivery_city;
                data.receiverZipcode = location.delivery_zipcode;
                data.receiverContactName = location.delivery_contact;
                data.receiverCountry = location.delivery_country;
                data.receiverId = location.id;
                data.receiverProjectId = receiverProjectId;
            }
        }
        else if (this.props.locations && this.props.locations.length) {
            let location = this.props.locations[0];
            let component;

            for (let index of this.state.selectedKeys) {
                if (this.state.componentNeeds[index].storageLocation && this.state.componentNeeds[index].storageLocation.id) {
                    component = this.state.componentNeeds[index];
                    break;
                }
            }

            if (component) {
                location = this.props.locations.find(loc => loc.id === component.storageLocation.id);

                if (!location) {
                    location = this.props.locations[0];
                }
            }

            let receiverProjectId = null;
            mapTree(this.props.tree, item => {
                if (receiverProjectId) return item;
                if (item.storage_location && Number(item.storage_location.id) === Number(location.id)) {
                    receiverProjectId = item.id;
                    data.project = item;
                }
                return item;
            })

            data.receiverType = 'project';
            data.receiverTitle = location.name;
            data.receiverName = location.delivery_name;
            data.receiverAddress = location.delivery_address;
            data.receiverCity = location.delivery_city;
            data.receiverZipcode = location.delivery_zipcode;
            data.receiverContactName = location.delivery_contact;
            data.receiverCountry = location.delivery_country;
            data.receiverId = location.id;
            data.receiverProjectId = receiverProjectId;
        }

        this.setState({ newPoData: data, newPoShow: true });
    }

    handleTransfer() {
        let selectedComponents = this.state.componentNeeds.filter((component, index) => this.state.selectedKeys.includes(index));
        let data = {};

        for (let component of selectedComponents) {
            if (data.receivingStorage && data.sendingStorage) break;
            if (!data.receivingStorage && component.storageLocation) {
                data.receivingStorage = component.storageLocation;
            }
            if (!data.sendingStorage && Array.isArray(component.componentStocks)) {
                for (let stock of component.componentStocks) {
                    if (data.sendingStorage) break;
                    data.sendingStorage = stock;
                    data.sendingStorage.id = stock.location.id;
                    data.sendingStorage.type = stock.location.type;
                    data.sendingStorage.name = stock.location.name;
                }
            }
        }
        
        for (let component of selectedComponents) {
            component.delivery_from_id = data.sendingStorage && data.sendingStorage.id;
            component.delivery_from_type = data.sendingStorage && data.sendingStorage.type;

            component.delivery_count = component.count;
    
            component.delivery_to_id = data.receivingStorage && data.receivingStorage.id;
            component.delivery_to_type = data.receivingStorage && data.receivingStorage.type;
        }

        data.components = selectedComponents;

        this.setState({newTransportData: data, newTransportShow: true});
    }

    handleSupplierFilter(filter) {
        this.supplierFilter = filter ? filter.value : null;
    }
    handleLocationFilter(filter) {
        this.locationFilter = filter ? filter.value : null;
    }

    orderMaxAmount(action) {
        const componentNeeds = this.state.componentNeeds.map(component => {
            if (action == 'clear') {
                component.count = '0';
            } else {
                component.count = component.balance_available < 0
                    ? Math.abs(component.balance_available)
                    : '0';
            }
            return component;
        })

        this.setState({ componentNeeds });
    }

    getExcel(keys) {
        let selectedComponents = this.state.componentNeeds.filter((component, index) => keys.includes(index));

        const excelData = selectedComponents.map(component => {
            return {
                component_name: component.name,
                storage_component_types: component.componentTypes,
                storage_code: component.component.code,
                storage_location: component.storageName,
                suppliers: component.suppliers,
                allocated: Number(component.alloc_count),
                balance: Number(component.balance),
                incoming: Number(component.waitingArrival),
                unit: component.component.unit,
                in_other_storages: Number(component.stocksBalanceTotal),
                shortage: Number(component.balance_available)
            }
        });

        this.setState({ loading: true });
        api({
            url: 'excel/generate',
            method: 'POST',
            data: {
                rows: excelData,
                title: tr('order_requirements'),
                sheetTitle: tr('order_requirements'),
            },
            responseType: 'blob',
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${tr('order_requirements')}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                loading: false,
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                loading: false,
            });
        })
    }

    render() {
        let orderBtnTooltip = null;
        if (this.state.selectedKeys.length === 0 ) {
            orderBtnTooltip = tr('select_component_required');
        }
        if (this.state.selectedKeys.length) {
            const components = this.state.componentNeeds.filter((c,index) => this.state.selectedKeys.includes(index));
            if (components) {
                const locations = [];
                components.forEach(c => {
                    if (!c.storageLocation) return;
                    if (!locations.includes(c.storageLocation.id)) {
                        locations.push(c.storageLocation.id);
                    }
                });
                if (locations.length > 1) orderBtnTooltip = tr('multiple_storage_locations_selected');
            }
        }

        return (
            <div className="padding">
                <Link to={this.props.projectNeedsUrl}>{tr('to_demands')}</Link>
                <div className="justify-end">
                    <ApButton size='small' onClick={() => this.orderMaxAmount('clear')}>{tr('clear')}</ApButton>
                    <ApButton color='green' size='small' onClick={this.orderMaxAmount}>{tr('order_all')}</ApButton>
                </div>
                <ApReactTable
                    rememberId='PdProject_ComponentNeeds'
                    data={this.state.componentNeeds}
                    multiselect={[
                        {
                            icon: "shopping-cart",
                            label: tr('make_order'),
                            action: this.handleOrder,
                            unselectAfter: false,
                        },
                        {
                            icon: 'file-excel',
                            label: tr('create_excel'),
                            action: this.getExcel,
                            unselectAfter: false,
                        }
                    ]}
                    onSelectChange={(keys) => {
                        this.setState({ selectedKeys: keys })
                    }}
                    columns={[
                        {
                            id: 'name',
                            Header: tr('component'),
                            accessor: 'name',
                            customFilter: {
                                type: "text",
                                placeholder: tr('component'),
                                // options: this.props.componentOptions
                            },
                        },
                        {
                            id: 'component_code',
                            Header: tr('storage_code'),
                            accessor: 'component.code',
                            customFilter: {
                                type: "text",
                                placeholder: tr('storage_code'),
                            },
                            customizable: true,
                            showInitially: true,
                        },
                        {
                            id: 'component_types',
                            Header: tr('storage_component_types'),
                            accessor: 'componentTypes',
                            customFilter: {
                                type: "select",
                                options: this.props.componentTypeOptions
                            },
                            customizable: true,
                            showInitially: true,
                        },
                        {
                            id: 'storageId',
                            Header: tr('storage_location'),
                            accessor: 'storageName',
                            customFilter: {
                                type: "select",
                                placeholder: tr('storage_location'),
                                options: this.props.locationOptions
                            },
                            customizable: true,
                            showInitially: true,
                            onFilter: filter => this.handleLocationFilter(filter),
                        },
                        {
                            id: 'suppliers',
                            Header: tr('suppliers'),
                            accessor: 'suppliers',
                            customFilter: {
                                type: "select",
                                placeholder: tr('suppliers'),
                                options: this.props.supplierOptions,
                            },
                            className: "overflowableOnHover",
                            Cell: item => {
                                return (
                                    <ApTooltip
                                        position='topleft'
                                        text={item.original.component.suppliers.map(supplier =>
                                            <p key={supplier.id}>{supplier.name} {formatCurrency(supplier.pivot.orig_purchase_price)}</p>)}
                                    >
                                        <div>{item.original.suppliers}</div>
                                    </ApTooltip>
                                )
                            },
                            onFilter: filter => this.handleSupplierFilter(filter),
                            customizable: true,
                            showInitially: true,
                        },
                        {
                            id: 'alloc_count',
                            Header: tr('allocated'),
                            // accessor: 'alloc_count',
                            accessor: acc => Number(acc.alloc_count),
                            filterable: false,
                            width: 100,
                            customizable: true,
                            showInitially: true,
                            className: 'align-right'
                        },
                        {
                            id: 'balance',
                            Header: tr('balance'),
                            // accessor: 'balance',
                            accessor: acc => Number(acc.balance),
                            filterable: false,
                            className: "overflowableOnHover align-right",
                            Cell: item => {
                                return (
                                    <ApTooltip text={`${item.original.storageLocation ? item.original.storageLocation.name : ''} ${tr('storage_balance')}`}>
                                        <div>{new Intl.NumberFormat(currentLang()).format(item.original.balance)}</div>
                                    </ApTooltip>
                                )
                            },
                            width: 100,
                            customizable: true,
                            showInitially: true,
                        },
                        {
                            id: 'waitingArrival',
                            Header: tr('incoming'),
                            accessor: acc => Number(acc.waitingArrival) || 0,
                            filterable: false,
                            width: 100,
                            className: 'align-right',
                            customizable: true,
                            showInitially: true,
                        },
                        {
                            id: 'unit',
                            Header: tr('unit'),
                            accessor: 'component.unit',
                            filterable: false,
                            width: 100,
                            customizable: true,
                            showInitially: true,
                        },
                        {
                            id: 'stocksBalanceTotal',
                            Header: tr('in_other_storages'),
                            accessor: 'stocksBalanceTotal',
                            filterable: true,
                            width: 100,
                            customizable: true,
                            showInitially: true,
                            className: "overflowableOnHover align-right",
                            Cell: props => {
                                return (
                                    <ApTooltip text={props.original.componentStocks.map((stock, index) => (
                                        <p key={index}>{stock.location.name}: {new Intl.NumberFormat(currentLang()).format(stock.balance_available)} {props.original.component.unit}</p>
                                    ))}>
                                        <p>{new Intl.NumberFormat(currentLang()).format(props.original.stocksBalanceTotal)}</p>
                                    </ApTooltip>
                                )
                            },
                            customFilter: {
                                type: "toggle",
                                accessor: "stocksBalanceTotal"
                            },
                        },
                        {
                            id: 'available',
                            Header: tr('available'),
                            // accessor: 'balance_available',
                            accessor: acc => Number(acc.balance_available),
                            filterable: true,
                            Cell: item => (
                                <div style={{ color: item.original.balance_available < 0 ? 'var(--clr-error-light)' : 'var(--clr-success-light)' }}>
                                    {new Intl.NumberFormat(currentLang()).format(item.original.balance_available)}
                                </div>
                            ),
                            width: 100,
                            className: 'align-right',
                            customFilter: {
                                type: "toggle",
                                accessor: 'needFilled'
                            },
                        },
                        {
                            id: 'count',
                            Header: tr('order_amount'),
                            accessor: 'count',
                            filterable: false,
                            className: 'overflowableOnHover',
                            Cell: item => <ApTooltip text={!item.original.storageLocation ? tr('product_storage_location_missing') : null}>
                                <ApInput
                                    id='order_amount_input'
                                    name='order_amount_input'
                                    type='number'
                                    label={tr('amount')}
                                    value={item.original.count}
                                    onChange={(e) => this.handleChange(e, item)}
                                    disabled={!item.original.storageLocation}
                                />
                            </ApTooltip>,
                            width: 150
                        },
                    ]}
                    loading={this.props.loading || this.state.loading}
                    filterable
                    showFiltersInitially={true}
                />
                <div className="justify-end">
                    <ApTooltip position='topright' text={orderBtnTooltip}>
                        <ApButton
                            disabled={Boolean(orderBtnTooltip)}
                            onClick={this.handleTransfer}
                        >
                            {tr('storage_transfer')}
                        </ApButton>
                    </ApTooltip>
                    <ApTooltip position='topright' text={orderBtnTooltip}>
                        <ApButton
                            disabled={Boolean(orderBtnTooltip)}
                            onClick={this.handleOrder} color='green'
                        >
                            {tr('create_order')}
                        </ApButton>
                    </ApTooltip>
                </div>

                {/* <div>
                    <ProjectPurchaseOrders
                        history={this.props.history}
                        purchaseOrders={this.state.purchaseOrders}
                        loading={this.state.loading || this.props.loading}
                        tree={this.props.tree}
                        componentOptions={this.state.componentOptions}
                        purchaseOrderStatusOptions={this.state.purchaseOrderStatusOptions}
                    />
                </div> */}
                <NewPurchaseOrder
                    show={this.state.newPoShow}
                    onClose={() => { this.setState({ newPoShow: false }) }}
                    data={this.state.newPoData}
                    projectPurchases
                />

                <NewTransport
                    show={this.state.newTransportShow}
                    onClose={() => { this.setState({ newTransportShow: false }) }}
                    data={this.state.newTransportData}
                />
            </div>
        )
    }
}

export default ComponentNeeds;
