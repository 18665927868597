import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import { dateInputToSql, sqlToDateInput } from 'services/Helpers/Helpers.js';

import './SalaryExtras.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

import RuleEdit from './RuleEdit.js';

class SalaryExtras extends React.Component {

	constructor( props ) 
	{
		super(props);
        this.state = {
            loading: false,
            rules: false,
            ruleEditId: false,

            temp_date: '',
            temp_date2: '',
            temp_user: false,
            temp_result: false,
        };
        autoBind(this);
	}

    componentDidMount()
    {
        this.getRules();
    }

    getRules()
    {   
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'hour/salary_extras/rules',
            data: {
                filters: "TODO: maybe later",
            }
        }).then(( response ) => {

            //console.log('getRules', response );

            this.setState({
                loading: false,
                rules: response,
            });
            
        }).catch( ( error ) => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    removeRules( indexes )
    {
        let ids = [];
        for( let i = 0; i < this.state.rules.length; i++ )
            if( indexes.indexOf( i ) !== -1 )
                ids.push( this.state.rules[i].id );

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'hour/salary_extras/rules/delete',
            data: {
                ids: ids,
            }
        }).then(( response ) => {

            //console.log('removed', response );
            this.getRules();
            
        }).catch( ( error ) => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getSalaryExtras()
    {
        api({
            method: 'post',
            url: 'hour/salary_extras/find',
            data: {
                date_start: this.state.temp_date,
                date_end: this.state.temp_date2,
                user_id: this.state.temp_user ? this.state.temp_user.id : null,
            }
        }).then(( response ) => {

            //console.log('getSalaryExtras', response );
            this.setState({ temp_result: response });

        }).catch( ( error ) => {
            console.error(error);
        });
    }

    approveExtraRow( target )
    {
        api({
            method: 'post',
            url: 'hour/salary_extras/approve',
            data: {
                data: this.state.temp_result,
                target: target,
            }
        }).then(( response ) => {

            //console.log( 'approveExtraRow', response, target );

            this.setState({ temp_result: response }); 

        }).catch( ( error ) => {
            console.error(error);
        });
    }

    render() 
    {

        let totalQuantity = 0;
        let totalExtra = 0;
        let unacceptedRows = 0;

        return (
            <div id="SalaryExras">
                <div className="apBox">
                
                    <div className="apBoxHeader">
                	   <h1>Palkkiot ja lisät</h1>
                       <p>Hallinnoi palkkojen lisiä ja palkkioita tai seuraa jo maksettuja lisiä</p>
                    </div>

                    <ApTabs>
                        <ApTab icon="wrench" label="Automaattiset lisät">
                            <div className="padding">
                                
                                <ApButton onClick={ () => this.setState({ ruleEditId: "new" }) }>
                                    <SvgIcon icon="plus" type="solid" />
                                    Lisää uusi
                                </ApButton>

                                <ApReactTable 
                                    loading={ this.state.loading }
                                    data={ this.state.rules ? this.state.rules : [] } 
                                    columns={[
                                        {
                                            Header: "Automaattiset lisät",
                                            columns: [
                                                { width: 50, id: 'active', Header: "Tila", accessor: "active", Cell: ( props ) => {
                                                    return (
                                                        <div className="activityCell">
                                                            <SvgIcon icon={ props.original.active ? "check" : "times" } type="solid" />
                                                        </div>
                                                    );
                                                }},
                                                { id: 'name', Header: "Nimi ja kuvaus", accessor: "name", Cell: ( props ) => {
                                                    return (
                                                        <div className="nameCell">
                                                            <span>{ props.original.name }</span><br />
                                                            <small>{ props.original.description ? props.original.description : "ei kuvausta" }</small>
                                                        </div>
                                                    );
                                                }},
                                                { width: 150, id: 'salary_extra', Header: "Korvaus", filterable: false, sortable: false, Cell: ( props ) => {
                                                    return (
                                                        <div className="compensationCell">
                                                            <div className="block">{ props.original.salary_extra } { auth.getCurrencySign() }</div>
                                                            <div className="block">{ props.original.salary_extra_per_unit } { auth.getCurrencySign() } / kpl</div>
                                                            <div className="block">{ props.original.salary_extra_percent } %</div>
                                                        </div>
                                                    );
                                                }},
                                                { width: 150, id: 'start_date', Header: "Alkaa", accessor: "start_date", Cell: ( props ) => sqlToDateInput( props.original.start_date ) },
                                                { width: 150, id: 'end_date', Header: "Päättyy", accessor: "end_date", Cell: ( props ) => sqlToDateInput( props.original.end_date ) },
                                                { width: 150, id: 'target', Header: "Kohdistus", filterable: false, sortable: false, Cell: ( props ) => {
                                                    return (
                                                        <div className="targetCell">
                                                            <div className="block">
                                                                <SvgIcon icon="users" type="solid" />
                                                                <span>{ props.original.users.length > 0 ? props.original.users.length + " kpl" : "Kaikki" }</span>
                                                            </div>
                                                            <div className="block">
                                                                <SvgIcon icon="folder" type="solid" />
                                                                <span>{ props.original.projects.length > 0 ? props.original.projects.length + " kpl" : "Kaikki" }</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                                
                                            ]
                                        },      
                                     ]}
                                    filterable
                                    multiselect={[
                                        { icon: "trash-alt", label: "Poista valitut", action: ( ids ) => { this.removeRules( ids ) }, unselectAfter: true, closeAfter: true },
                                    ]}
                                    minRows={ 5 }
                                    rowActions={ (row) => 
                                        <div className="apSimpleButton" onClick={ () => this.setState({ ruleEditId: row.id }) }><SvgIcon icon="edit" type="solid" /></div>
                                    }
                                />

                                
                                <h3>Tarkista henkilön automaattiset lisät</h3>

                                <ApInputStack gap="0">

                                    <ApAddon width="80px" noRightBorder>
                                        Aikaväli
                                    </ApAddon>

                                    <ApInput 
                                        width="145px"
                                        noRightBorder
                                        type="date" 
                                        id="temp_date" 
                                        name="temp_date" 
                                        value={ sqlToDateInput( this.state.temp_date ) }
                                        onChange={ (e) => this.setState({ temp_date: dateInputToSql( e.target.value ) }) }
                                    />

                                    <ApAddon width="20px" noLeftBorder noRightBorder>
                                        -
                                    </ApAddon>

                                    <ApInput 
                                        width="145px"
                                        type="date" 
                                        id="temp_date2" 
                                        name="temp_date2" 
                                        value={ sqlToDateInput( this.state.temp_date2 ) }
                                        onChange={ (e) => this.setState({ temp_date2: dateInputToSql( e.target.value ) }) }
                                    />

                                    <ApSelect
                                        gapLeft={10}
                                        label="Henkilö"
                                        value={ this.state.temp_user ? this.state.temp_user.first_name + " " + this.state.temp_user.last_name : "" }
                                        valueRenderer="user"
                                        optionRenderer="user"
                                        onChange={ ( value ) => this.setState({ temp_user: value }) }
                                        clearable
                                        objKeyId="id"
                                        apiUrl="company/settings/users"
                                        apiData={{ exluded: [] }}
                                    />
                                    
                                    <ApAddon width="200px" custom gapLeft={10}>
                                        <ApButton onClick={ this.getSalaryExtras }>
                                            <SvgIcon icon="trophy" type="solid" />
                                            Näytä lisät
                                        </ApButton>
                                    </ApAddon>

                                </ApInputStack>

                                { this.state.temp_result &&
                                    <div>
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Pvm</th>
                                                    <th>Nimi</th>
                                                    <th>Määrä</th>
                                                    <th>Yhteensä</th>
                                                    <th>Hyväksyntä</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.state.temp_result.map( ( result, index ) => {

                                                    totalQuantity += result.quantity;
                                                    totalExtra += result.total_salary_extra;
                                                    unacceptedRows += result.id ? 0 : 1;

                                                    return (
                                                        <tr key={ index }>
                                                            <td>{ result.date }</td>
                                                            <td>{ result.name }</td>
                                                            <td>{ result.quantity } h</td>
                                                            <td>{ result.total_salary_extra } EUR</td>
                                                            <td>
                                                                { result.id && "Hyväksytty" }
                                                                { !result.id && 
                                                                    <ApButton size="tiny" onClick={ () => this.approveExtraRow( index ) }>Hyväksy</ApButton> 
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                { this.state.temp_result.length === 0 && 
                                                    <tr>
                                                        <td colSpan={ 4 }>Yhtään lisää ei löytynyt</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <td colSpan={ 2 }>Yhteensä</td>
                                                    <td>{ totalQuantity } h</td>
                                                    <td>{ totalExtra } EUR</td>
                                                    <td>
                                                        { unacceptedRows > 0 &&
                                                            <ApButton size="tiny" onClick={ () => this.approveExtraRow( 'all' ) }>Hyväksy kaikki ({ unacceptedRows } kpl)</ApButton>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        </ApTab>
                        <ApTab icon="check-circle" label="Hyväksytyt lisät">
                            <div className="padding">
                                
                                <p>
                                    TODO 
                                </p>

                            </div>
                        </ApTab>
                    </ApTabs>

                </div>

                <RuleEdit 
                    id={ this.state.ruleEditId }
                    onClose={ ( unsavedChanges, reloadNeeded ) => {
                        if( unsavedChanges )
                        {
                            if( window.confirm( "Tekemiäsi muutoksia ei ole vielä tallennettu. Haluatko varmasti peruuttaa? ") )
                                this.setState({ ruleEditId: false });
                        }
                        else 
                            this.setState({ ruleEditId: false }, () => {
                                if( reloadNeeded )
                                    this.getRules();
                            });
                    }}
                />

            </div>
        );

    }
}
export default SalaryExtras
