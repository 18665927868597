import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import moment from 'moment';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import './NotificationButton.css';
import ApModal from 'common/ApModal/ApModal';
import { tr } from 'services/Helpers/Helpers';
import ApButton from 'common/ApButton/ApButton';

class NotificationButton extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            count: 0,
            loading: true,
            CrmNotifications: null,
            showNotificationsModal: false,
        };

        this.timer = null;
        autoBind(this);
    }

    componentDidMount()
    {
        this.addListener()

        this.getNotificationsCount();
        this.timer = setInterval(this.getNotificationsCount, 60000 * 5 );
 
    }

    addListener()
    {
        window.emitter.removeAllListeners('notification');
        window.emitter.addListener('notification', args => {
            if( typeof args.count !== undefined )
                this.setState({ count: args.count });
        });
    }

    componentWillUnmount()
    {
        clearInterval( this.timer );
    }

    getNotificationsCount()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'currentUser/notifications/unreadcount',
            errorPopper: tr('get_notifications_error'),
        }).then((response) => {
            // Show modal if there are new notifications and modal is not already open and user is not on notifications page
            const showNotificationsModal = (response > 0 || this.state.showNotificationsModal) 
                && window.location.pathname !== '/notifications' 
                && this.state.count !== response;
            this.setState({
                count: response,
                loading: false,
                showNotificationsModal: showNotificationsModal,
            });

        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }

    goToNotifications()
    {
        window.emitter.emit( 'goTo', {
            pathname: `/notifications`,
        });
    }

    renderHeader() {
        return <div className="padding" style={{color: 'initial'}}>
            <h4><SvgIcon type='solid' icon='bell' fill='gray' /> {tr('new_notifications')} ({this.state.count})</h4>
        </div>
    }

    renderBody() {
        return '';
    }

    renderFooter() {
        return <div className="padding justify-space-between">
            <ApButton onClick={() => this.setState({showNotificationsModal: false})}><SvgIcon type='solid' icon='times' /> {tr('cancel')}</ApButton>
            <div>
                <ApButton 
                    onClick={() => {
                        this.setState({showNotificationsModal: false});
                        this.goToNotifications();
                    }}
                    type='solid'
                    color='green'
                >
                    {tr('notifications')} <SvgIcon type='solid' icon='arrow-right' />
                </ApButton>
            </div>
        </div>
    }

    render()
    {
        const count = this.state.count;

        let countDom = null;
        if( count )
            countDom = <div className="count apBadge red">{ count }</div>

        return <div className="block notifications" id="notificationButton">
            <div className="button" onClick={ this.goToNotifications }>
                <SvgIcon icon="bell" />
            </div>
            { countDom }
            <ApModal 
                className='narrow'

                header={this.renderHeader()}
                body={this.renderBody()}
                footer={this.renderFooter()}
                show={this.state.showNotificationsModal}
                onClose={() => this.setState({showNotificationsModal: false})}
                handleClose={() => this.setState({showNotificationsModal: false})}

            />
        </div>
    }
};


export default NotificationButton;

