import React from 'react';
import autoBind from 'react-autobind';

import auth              from 'services/Authed/Authed.js';
import api               from 'services/Api/Api.js';
import ApReactTable      from 'common/ApReactTable/ApReactTable.js';
import ApButton          from 'common/ApButton/ApButton.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import SvgIcon           from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import CommentDialog from './EditPurchaseOrder/CommentDialog/CommentDialog.js'
import { formatMoney
       , sqlToDateInput 
        , tr
        ,errorPopper,
        keyExists,
        hasPermissions} from 'services/Helpers/Helpers.js'
import { setPoList, 
         getCurrentPage,
         setCurrentPage,
         currentPageIsLocked,
         setPageLock,
         setComponentName } from './PurchaseOrderHelper.js';

import { poStatusBoxColor
       , getTypeName
       , getTypeIcon }  from 'modules/Storage/common/StorageHelpers.js';

import NewPurchaseOrder from './NewPurchaseOrder/NewPurchaseOrder.js';

import { ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';

import './PurchaseOrders.css';
import { arc } from 'd3-shape';
import request from 'services/Api/Api.js';

class PurchaseOrder extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            data: [],      // should default to []
            pages: -1,     // should default to -1 (which means we don't know how many pages we have)
            loading: true,

            columns: [],
            customColumns: [],
            deleteIndexes:[],
            statusOptions: [],

            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),

            newPoShow: false,
            externalFetchUpdated: false,

            commentDialogShow: false,
            
            import_count: 0,
            copyId: null,
            showDeleteConfirm: false,
        }
        this.state.columns = this.getColumns();

        this.getTimer = null;

        autoBind(this);
    }

    componentDidMount()
    {
        this.getColumns();
        this.getRelated();
        setComponentName('purchaseOrders')
    }

    componentDidUpdate( prevProps, prevState, snapshot )
    {
        if( JSON.stringify( this.props.instanceOptions ) !== JSON.stringify( prevProps.instanceOptions ) )
            this.getData();
    } 

    refresh()
    {
        this.getData();
    }

    goToEdit( id = null)
    {
        let path = `/storage/purchase/order/new`;
        if( id ) path = `/storage/purchase/order/id/${id}`;
        window.emitter.emit( 'goTo', { pathname: path } );
        setPageLock(true)
    }

    getColumns()
    {
        return [{
            id: 'number',
            Header: tr('number'),
            accessor: 'number',
            // filterable: true,
            sortable: true,
            showInitially: true,
            customizable: true,
            width: 70,
            onClick: row => this.goToEdit( row.id ),
        }, {
            id: 'date',
            Header: tr('date'),
            accessor: 'date',
            filterable: false,
            sortable: true,
            showInitially: true,
            customizable: true,
            width: 150,
            onClick: row => this.goToEdit( row.id ),
            Cell: props => {
                return sqlToDateInput( props.value );
            },
        }, {
            id: 'supplier',
            Header: tr('supplier'),
            className: "overflowableOnHover",
            onClick: row => this.goToEdit( row.id ),
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            width: 200,
            customFilter: {
                type: "text",
                placeholder: tr('enter_supplier_name')
            },
            Cell: props => {
                let icon = null;
                let typeName = null;
                let typeTitle = null;
                if( props.original.supplier_id )
                {
                    icon = <SvgIcon icon={ getTypeIcon( props.original.supplier_type ) } type="solid" />
                    typeName = getTypeName( props.original.supplier_type );
                    typeTitle = props.original.supplier_title;
                }
                return <div>
                    <ApTooltip position="top" text={ typeName }>
                        { icon }
                    </ApTooltip>
                    { typeTitle }
                </div>
            },
        }, {
            id: 'receiver',
            Header: tr('recipient'),
            className: "overflowableOnHover",
            onClick: row => this.goToEdit( row.id ),
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            width: 350,
            customFilter: {
                type: "text",
                placeholder: tr('enter_recipient_name')
            },
            Cell: props => {
                let icon = null;
                let typeName = null;
                let typeTitle = null;
                if( props.original.supplier_id )
                {
                    icon = <SvgIcon icon={ getTypeIcon( props.original.receiver_type ) } type="solid" />
                    typeName = getTypeName( props.original.receiver_type );
                    typeTitle = `${!props.original.receiver_title && props.original.receiver_type == 'project' ? 'Projekti ilman varastoa' : props.original.receiver_title }`;
                }
                return <div>
                    <ApTooltip position="top" text={ typeName }>
                        { icon }
                    </ApTooltip>
                    { typeTitle }
                </div>
            },

        }, {
            id: 'handler',
            Header: tr('original_creator'),
            accessor: 'created_by',
            onClick: row => this.goToEdit( row.id ),
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            width: 200,
            customFilter: {
                type: "text",
                placeholder: tr('enter_creator_name'),
            },
            
        }, {
            id: 'project.project_code',
            Header: tr('project_code'),
            accessor: 'project_code',
            onClick: row => this.goToEdit( row.id ),
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            width: 150,
            customFilter: {
                type: "text",
                placeholder: tr('enter_project_code'),
            },
        
        }, {
            id: 'status_id',
            Header: tr('status'),
            accessor: 'status_id',
            className: "overflowableOnHover",
            onClick: row => this.goToEdit( row.id ),
            Cell: props => {
                if( props.original.archived )
                    return <div className="apStatusBox">{ tr('archived') }</div>

                    if (props.original.status_name === 'late') {
                        const message = `${tr('delivery_late_or_not_received')}: ${sqlToDateInput(props.original.delivery_date)}`
    
                        return  <ApTooltip position="top" block text={ message }>
                                    <div className={`apStatusBox ${ poStatusBoxColor( props.original.status_name ) }`}>{ tr(props.original.status_title) }</div>
                                </ApTooltip> 
                    }
    
                    return <div className={`apStatusBox ${ poStatusBoxColor( props.original.status_name ) }`}> { tr(props.original.status_title) }</div>
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    onMouseDown={ e => onChange( e.target.value ) } // Fixes FireFox bug (https://github.com/facebook/react/issues/12584)
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                >
                    <option value=""> </option>
                    { this.state.statusOptions.map( s => {
                        return <option key={ s.id } value={ s.id }> { tr(s.text) } </option>
                    } ) }
                </select>,
        }, {
            id: 'delivery_date',
            Header: tr('date_of_arrival'),
            accessor: 'delivery_date',
            className: "overflowableOnHover",
            sortable: true,
            filterable: false,
            showInitially: true,
            customizable: true,
            onClick: row => this.goToEdit( row.id ),
            Cell: props => {
                if (props.original.status_name === 'late') {
                    return  <ApTooltip position="top" block text={ tr('delivery_late_or_not_received') }>
                                <SvgIcon className="small-inline" icon="calendar-times" fill="red" />
                                {sqlToDateInput( props.value )}{' '}
                            </ApTooltip>
                }

                return sqlToDateInput( props.value );
            },
        }, {
            id: 'due_date',
            Header: tr('due_date'),
            accessor: 'due_date',
            className: "overflowableOnHover",
            sortable: true,
            filterable: false,
            showInitially: true,
            customizable: true,
            onClick: row => this.goToEdit( row.id ),
            Cell: props => {
                return sqlToDateInput( props.value );
            },
        },{
            id: 'price',
            Header: tr('price'),
            accessor: 'price',
            sortable: true,
            // filterable: true,
            showInitially: true,
            customizable: true,
            customFilter: {
                type: "range",
                min: 0,
                step: 500,
                max: 1000000,
            },
            onClick: row => this.goToEdit( row.id ),
            Cell: props => {
                if( props.value )
                    return <div className="text-right"> { formatMoney( props.value ) } { this.state.currencySign }</div>
                return null;
            },

        },
        {
            id: 'paid',
            Header: tr('paid'),
            accessor: 'paid',
            className: "overflowableOnHover",
            onClick: row => this.goToEdit( row.id ),
            sortable: true,
            filterable: false,
            showInitially: true,
            customizable: true,
            Cell: props => {
                if( props.value )
                    return <div className="apStatusBox success">{ tr('paid') }</div>

                let statusClass = ['apStatusBox ']

                let dueDate = null;
                if( props.original.due_date )
                    dueDate = `${tr('due_date')}: ${ sqlToDateInput( props.original.due_date ) }`;

                if( props.original.due_today )
                    statusClass.push( 'info' );
                else if( props.original.paid_late )
                    statusClass.push( 'warning' );

                return <ApTooltip position="top" text={ dueDate } block>
                    <div className={ statusClass.join(' ') }>{ tr('not_paid') }</div>
                </ApTooltip>
            },
        },
        {
            id: 'allow_payment',
            Header: tr('payment_allowed'),
            accessor: 'allow_payment',
            className: "overflowableOnHover",
            onClick: row => this.goToEdit( row.id ),
            sortable: true,
            filterable: false,
            showInitially: true,
            customizable: true,
            Cell: props => {
                if( props.value )
                    return <div className="apStatusBox success">{ tr('accepted') }</div>

                // let statusClass = ['apStatusBox ']

                // let dueDate = null;
                // if( props.original.due_date )
                //     dueDate = `${tr('due_date')}: ${ sqlToDateInput( props.original.due_date ) }`;

                // if( props.original.due_today )
                //     statusClass.push( 'info' );
                // else if( props.original.paid_late )
                //     statusClass.push( 'warning' );

                // return <ApTooltip position="top" text={ dueDate } block>
                //     <div className={ statusClass.join(' ') }>{ tr('not_paid') }</div>
                // </ApTooltip>
                return null;
            },
        }
    ];

    }

    getRelated()
    {
        return api({
            method: 'get',
            url: 'storage/po/related',
        }).then((response) => {
            this.setState({
                loading: false,
                statusOptions: response.statuses,
            });
        }).catch((error) => {
            errorPopper(error);
            console.log(error);
        });
    }
    archive(indexes) {
        

        this.setState({
            commentDialogShow: true,
            commentDialogAcceptor: false,
            commentDialogMeta: {
                action: "archive",
                color: "green",
                icon: "archive",
                text: tr("archive"),
            },
            selectedRows: indexes,
        
        });
    }
    archivePurphaceOrders(action, comment, acceptor, formdata) {
        
        this.setState({
            commentDialogShow: false,
            commentDialogMeta: {},
        });
        let idList = [];
        let message = "";
        let varningMessage = "";
        for (let rowId of this.state.selectedRows) {
            
            let row = this.state.data[rowId];

            // only add to archive if paid and if all items arrived
            if (row.paid === true && row.status_title === 'arrived') {
                idList.push(row.id);
            }
            else {
                varningMessage += row.number + ": " + tr('archiving_canceled') + " " + tr("not_paid")+ ",";
            }
            
        }
        if (idList.length > 0) {
            let data = {
                latest_comment: comment,
                archived: true,
                ids: idList
            }


            api({
                method: 'post',
                url: `storage/po/id/archiveByList`,
                data: data,
            }).then((response) => {
                
                let ids = [];
                for (let r of response) {
                    if (r.archived) {
                        message += r.number + ": " + tr('archived') + ",";
                        ids.push(r.id);
                    }
                    else {
                        varningMessage += r.number + ": " + tr('archiving_canceled') + ",";
                    }
                    
                    
                }
                 

                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>{message}</strong>,
                    time:8000,
                });
                if (varningMessage !== "") {
                    window.emitter.emit('popper', {
                        type: 'alert',
                        content: <strong>{varningMessage}</strong>,
                        time:8000,
                    });
                }

                let tableData = this.state.data.filter(function (value, index, arr) {
                    return !ids.includes(value.id);
                    });
                
                this.setState({ data: tableData });
                
            }).catch((error) => {
                errorPopper(error, tr('save_error'));
            });
        }
        else {
            if (varningMessage!=="") {
                window.emitter.emit('popper', {
                    type: 'alert',
                    content: <strong>{varningMessage}</strong>,
                    time:8000,
                });
            }
        }
        

        
    }
    
    getData( state, archived = false, deleted=false )
    {
        const fetchPage = currentPageIsLocked() ? getCurrentPage() : state.page
        window.clearTimeout(this.getTimer);
        this.getTimer = window.setTimeout(() => {
            this.setState({loading: true})
            api({
                method: 'post',
                url: 'storage/pos/apt',
                data: {
                    archived: archived,
                    deleted: deleted,
                    page: fetchPage,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                    externalFetchUpdated: this.state.externalFetchUpdated,
                    minAndMax: this.state.minAndMaxSetted?null:["price"]
                },
            }).then((response) => {
                if (response.price_max) {
                    let columns = [...this.state.columns]
                    let priceCol = columns.find(item => item.id == "price");
                    priceCol.customFilter.min = Math.ceil(response.price_min);
                    priceCol.customFilter.max = Math.ceil(response.price_max);
                    priceCol.customFilter.step = Math.ceil(response.price_max / 100);
                    this.setState({
                        data: response.data,
                        pages: response.pages,
                        loading: false,
                        externalFetchUpdated: true,
                        minAndMaxSetted: true,
                        columns: columns,
                        import_count: response.import_count === 0 ? this.state.import_count : response.import_count
                    })
                }
                else {
                    this.setState({
                        data: response.data,
                        pages: response.pages,
                        loading: false,
                        externalFetchUpdated: true,
                        minAndMaxSetted: true,
                        import_count: response.import_count === 0 ? this.state.import_count : response.import_count
                    })
                }

                

                setPoList(response.data)
                if (response.pages > 1) {
                    const size = response.pages * state.pageSize

                    api({
                        method: 'post',
                        url: 'storage/pos/apt',
                        data: {
                            archived: archived,
                            pageSize: size,
                            sorted: state.sorted,
                            filtered: state.filtered,
                        },
                    }).then((response) => {
                        setPoList(response.data)
                    }).catch((error) => {
                        console.error(error)
                    })
                }
                setPageLock(false)
            }).catch((error) => {
                console.error(error);
                this.setState({
                    data: [],
                    pages: -1,
                    loading: false,
                    externalFetchUpdated: false
                })
            });
        }, 500);
    }
    removePurchaseOrders(indexes) {
        const Ids = indexes.map(index => this.state.data[index].id);
        this.setState({loading: true});
        api({
            method: 'post',
            url: `storage/po/deleteByIds`,
            data: {
                ids: Ids,
            },
        }).then((response) => {
            //setPoList(response.data)
            this.setState({loading: false});
            let reactTableRefState = keyExists(this.reactTableRef, 'reactTableRef.state', true);
            if (reactTableRefState) {
                let state = {
                    page: reactTableRefState.page,
                    pageSize: reactTableRefState.pageSize,
                    sorted: reactTableRefState.sorted,
                    filtered: reactTableRefState.filtered,
                }
                this.getData(state)
            }
        }).catch((error) => {
            this.setState({loading: false});
            console.error(error)
        })


    }

    render()
    {
        //console.log("state", this.state);
        let import_counter = '';
        if (this.state.import_count>0) {
            import_counter = ` (${this.state.import_count})`;
        }

        return (
            <div className="apBox" id="pagePos">
                <div className="apBoxHeader">
                    <h1>
                        { tr('purchase_orders') }
                    </h1>
                    <p>{ tr('purchase_orders_info') }</p>
                    <div className="apBoxCorner">
                        {hasPermissions('purchases.import')
                            ? <ApButton color="green"
                                onClick={() => { window.emitter.emit('goTo', { pathname: "/storage/purchase/orders/import" }) }}
                            >
                                {tr('import_pos')}{import_counter}
                            </ApButton>
                            : null}
                         &nbsp;
                        {hasPermissions('purchases.orders') 
                        ? <ApButton color="green"
                                onClick={ () => { this.setState({ newPoShow: true }) } }
                            > { tr('new_purchase_order') } </ApButton>
                        : null }
                    </div>
                </div>

                <ApInputStack gap="0">
		                	<ApAddon noRightBorder width="50px" labelFor="search">
		                		<SvgIcon icon="search" type="solid" />
		                	</ApAddon>
			                <ApSelect
                                value=""
                                optionRenderer={ (item) => {
                                	
                                		return (
                                			<div className="searchOption pos">
	                                			<SvgIcon className="typeIcon" icon="clipboard-check" type="solid" />
                                                <div className="itemnumber">
                                                    <strong>{ item.number }</strong>
                                                </div>
                                                <div className="iteminfo">
                                                    <strong> { item.name }</strong><br />
                                                    <small> { item.project_code } {sqlToDateInput(item.date)}</small>
                                                </div>
	                                		</div>
                                		);
                                }}
                                onChange={ ( item ) => { this.goToEdit( item.id ) } }
                                objKeyId="id"
                                clearable
                                noEmptySearch
                                debounce={500}
                                apiUrl="search/po"
                            />
		                </ApInputStack>

                <ApTabs>
                    <ApTab icon="list" label={ tr('list') } mountOnSelect>
                        <div className="padding">

                            <ApReactTable
                                rememberId="PurchaseOrders"
                                defaultSorted={[{id: 'id', desc: true}]}
                                defaultFiltered={ this.props.initialFilters }

                                manual
                                onFetchData={( state ) => {
                                    if (!currentPageIsLocked()) {  
                                        setCurrentPage(state.page)
                                    }

                                    this.getData(state)
                                } }
                                pages={ this.state.pages }
                                loading={ this.state.loading }
                                data={ this.state.data }
                                columns={this.state.columns }

                                filterable
                                forceCustomColumns={ true }
                                customColumns={ this.state.customColumns }
                                onCustomColumnChange={ ( customColumns ) => { this.setState({customColumns: customColumns }) }}
                                noDataText={ tr('no_orders_found') }
                                showPagination={ false }
                                minRows={ 4 }
                                showFiltersInitially={true}
                                multiselect={[
                                    {
                                        icon: "archive",
                                        label: tr("archive"),
                                        action: (indexes) => this.archive(indexes),
                                        closeAfter: true,
                                        disabled: !auth.hasModule('clients.edit'),
                                    },
                                    {
                                        divider: true
                                    },
                                    {
                                        icon: "copy",
                                        label: tr('copy'),
                                        action: (indexes) => { this.setState({
                                            copyId: this.state.data[indexes[0]].id,
                                            newPoShow: true,
                                        })},
                                        unselectAfter: true,
                                        maxSelectedCount:1,
                                    },
                                    {
                                        icon: "trash",
                                        label: tr('delete'),
                                        action: (indexes) => {
                                            this.setState({
                                                deleteIndexes: indexes,
                                                showDeleteConfirm: true,
                                            })
                                        },
                                        unselectAfter: true,
                                    }
                                    
                                ]}
                                ref={ref => this.reactTableRef = ref}
                            />

                        </div>
                    </ApTab>
                    <ApTab icon="trash" label={tr('deleted')} align="right" mountOnSelect>
                        <div className="padding">

                            <ApReactTable
                                rememberId="PurchaseOrdersTrash"
                                defaultSorted={[{ id: 'id', desc: true }]}
                                manual
                                onFetchData={(state) => { this.getData(state, false,true) }}
                                pages={this.state.pages}
                                loading={this.state.loading}
                                data={this.state.data}
                                columns={this.state.columns}
                                filterable
                                minRows={4}
                                showFiltersInitially={true}
                            />

                        </div>
                    </ApTab>
                    <ApTab icon="archive" label={ tr('archived_multiple') } align="right" mountOnSelect>
                        <div className="padding">

                            <ApReactTable
                                rememberId="PurchaseOrdersArchive"
                                defaultSorted={[{id: 'id', desc: true}]}
                                manual
                                onFetchData={( state ) => { this.getData( state, true ) } }
                                pages={ this.state.pages }
                                loading={ this.state.loading }
                                data={ this.state.data }
                                columns={ this.state.columns }
                                filterable
                                minRows={4}
                                showFiltersInitially={true}
                            />

                        </div>
                    </ApTab>
                    
                </ApTabs>

                <NewPurchaseOrder
                    show={ this.state.newPoShow }
                    onClose={ () => { this.setState({ newPoShow: false, copyId: null }) } }
                    onSave={ () => { } }
                    copyId={this.state.copyId}
                />
                <CommentDialog
                    show={this.state.commentDialogShow}
                    meta={this.state.commentDialogMeta}
                    selectAcceptor={this.state.commentDialogAcceptor}
                    onClose={() => this.setState({ commentDialogShow: false })}
                    onSave={this.archivePurphaceOrders}
                />
                <ApConfirm
                    show={this.state.showDeleteConfirm}
                    header={tr("purchase_order_delete_confirm")}
                    body={tr("purchase_order_delete_info")}
                    onConfirm={() => { this.removePurchaseOrders(this.state.deleteIndexes) }}
                    onClose={() => { this.setState({ showDeleteConfirm: false, deleteIndexes:[] }) }}
                />
            </div>
        );
    }
}

export default PurchaseOrder;
