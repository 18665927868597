/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import { Collapse } from 'react-bootstrap';
import auth from 'services/Authed/Authed.js';
        
import api from 'services/Api/Api.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import { roundToDecimals, onlyNumber, keyExists } from  'services/Helpers/Helpers.js';

import './EntryEdit.css';

import { statusCode, statusName, statusText } from  'modules/WorkHours/WorkHourHelpers.js';

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
// import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApRangeSlider from 'common/ApRangeSlider/ApRangeSlider.js';
import ApFileUpload from 'common/ApFileUpload/ApFileUpload.js';

class EntryEdit extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            locked: false,
            unsavedChanges: false,
            data: {},
            loadedFileIds: false,
            expenseFiles: [],
            expenseRows: [],
            //absenceChecked: false,
            expenseEventChecked: false,
            dailyAbsenceChecked: false,
            dailyAllowanceChecked: false,
            error: false,
        }

        this.validatorHour = new ApValidate( this, {
            "data.description": { 
                filter: 'required', 
                state: 'error', 
                textAll: 'Kuvaus' 
            },
            "data.hour_type_id": { 
                filter: 'required', 
                state: 'error', 
                textAll: 'Tuntityyppi' 
            },
            "data.hours": { 
                filter: ( value ) => { 
                    return ( value > 0 && value <= 24 ? true : false ); 
                }, 
                state: 'warning', 
                textAll: 'Tunnit' 
            },
            "data.project": {
                filter: ( value ) => { 
                    if( !this.props.settings.contract.hours_entry_project_required ) return true;
                    return ( this.state.data.project ? true : false ); 
                }, 
                state: 'error', 
                textAll: 'Projekti'
            },
            "data.project_work_id": {
                filter: ( value ) => {
                    if( !this.props.settings.contract.hours_entry_project_required ) return true;
                    return value ? true : false;
                },
                state: 'error',
                textAll: 'Projektin työtehtävä'
            }
        });
  
        this.validatorTravel = new ApValidate(this, {
            "data.description": { 
                filter: 'required', 
                state: 'error', 
                textAll: 'Kuvaus' 
            },
            "data.project": {
                filter: ( value ) => { 
                    if( !this.props.settings.contract.hours_entry_project_required ) return true;
                    return ( this.state.data.project ? true : false ); 
                }, 
                state: 'error', 
                textAll: 'Projekti'
            },
            "data.route": { 
                filter: 'required', 
                state: 'error', 
                textAll: 'Reitti' 
            },
            "data.distance": { 
                filter: (value) => {  
                    return ( value >= 0 ? true : false ); 
                }, 
                state: 'warning', 
                textAll: 'Matka-aika' 
            },
            "data.hours": { 
                filter: (value) => {  
                    return ( value >= 0 && value <= 24 ? true : false ); 
                }, 
                state: 'warning', 
                textAll: 'Matka-aika' 
            },
        });

        this.validatorExpense = new ApValidate(this, {
            "data.payment_method": { 
                filter: 'required', 
                state: 'error', 
                textAll: 'Maksutapa' 
            },
            "data.project": {
                filter: ( value ) => { 
                    if( !this.props.settings.contract.hours_entry_project_required ) return true;
                    return ( this.state.data.project ? true : false ); 
                }, 
                state: 'error', 
                textAll: 'Projekti'
            },
            "data.event": { 
                filter: (value) => {
                    if( !this.state.expenseEventChecked ) return true;
                    return ( value ? true : false );
                }, 
                state: 'error', 
                textAll: 'Tilaisuuden nimi' 
            },
            "expenseFiles": { 
                filter: () => {
                    let count = 0;
                    this.state.expenseFiles.forEach( file => {
                        if( !file.removed )
                            count++;
                    })
                    return ( count > 0 ? true : false );
                }, 
                state: 'error', 
                textAll: 'Liitetiedosto (kuitti)' 
            },
            expenseRows: {
                filters: {
                    description: { filter: 'required', state: 'error', text: '' },
                    cost: { 
                        filter: ( value ) => {
                            if( value < 0 ) return false;
                            return ( value ? true : false );
                        }, 
                        state: 'error', 
                        textAll: '' 
                    },
                }
            },
        });
 
        this.validatorDay = new ApValidate(this, {
            "data.project": { 
                filter: (value) => { 
                    if( !this.state.dailyAllowanceChecked ) return true;
                    if( !this.props.settings.contract.hours_entry_project_required ) return true;
                    return ( this.state.data.project ? true : false ); 
                }, 
                state: 'error', 
                textAll: 'Projekti'
            },
            "data.daily_allowance_id": { 
                filter: (value) => {
                    if( !this.state.dailyAllowanceChecked ) return true;
                    return ( value > 0 ? true : false );
                }, 
                state: 'error', 
                textAll: 'Korvaus' 
            },

            "data.absence_reason_id": { 
                filter: (value) => {
                    if( !this.state.dailyAbsenceChecked ) return true;
                    return ( value > 0 ? true : false );
                }, 
                state: 'error', 
                textAll: 'Poissaolon syy' 
            },

        });
    
        autoBind(this);
    }

    UNSAFE_componentWillMount()
    {
        this.fetchData();
    }

    componentDidUpdate( prevProps ) 
    {
        // We only need to fetch data again when popup opens
        if( this.props.show && !prevProps.show )
            this.fetchData();
    }

    getHourTypeOptions()
    {
        let options = [];
        let groups = {};

        this.props.settings.hourtypes.forEach( ( hourtype ) => {

            const groupName = hourtype.group ? hourtype.group : false;

            if( groupName )
            {
                if( !( groupName in groups ) )
                    groups[ groupName ] = [];

                groups[ groupName ].push({
                    id: hourtype.id,
                    value: hourtype.id,
                    label: hourtype.name,
                    description: hourtype.description,
                });
            }
            else 
            {
                options.push({
                    id: hourtype.id,
                    value: hourtype.id,
                    label: hourtype.name,
                    description: hourtype.description,
                });
            }
        });

        for( const name in groups )
        {
            options.push({
                label: name,
                options: groups[ name ]
            });
        }

        return options;
    }

    getAbsenceOptions( allDay )
    {
        if( typeof( allDay ) != "boolean" ) 
            allDay = false;

        let options = [{
            id: 0,
            value: 0,
            label: ' - Valitse poissaolo - '
        }];

        this.props.settings.absences.forEach( ( reason ) => {
            options.push({
                id: reason.id,
                value: reason.id,
                label: reason.name,
                description: reason.description,
            });
        });
        return options;
    }


    getAllowanceOptions( type ) 
    {
        let options = [];

        if( type === "vehicles" )
        {
            this.props.settings.vehicles.forEach( ( item ) => {
                options.push({
                    id: item.id,
                    value: item.id,
                    label: item.name,
                });
            });
        }
        else if ( type === "dailyAllowances" )
        {
            options.push({
                id: 0,
                value: 0,
                label: ' - Valitse korvaus -'
            });

            let abroad = {
                label: "Ulkomaan päiväraha: ",
                options: []
            };

            this.props.settings.allowances.forEach( ( item ) => {
                if( item.abroad )
                    abroad.options.push({
                        id: item.id,
                        value: item.id,
                        label: item.name,
                    });
            
                else
                    options.push({
                        id: item.id,
                        value: item.id,
                        label: item.name,
                    });
            });

            if( abroad.options.length > 0 )
                options.push( abroad );
        }

        return options;
    }


    getPaymentMethodOptions()
    {
        // TODO: get company/user credit cards from somewhere? 
        let options = [
            {
                value: '',
                label: ' - Valitse maksutapa - ',
            },
            {
                value: "company_credit_card",
                label: 'Yrityksen luottokortti',
                description: "Kaikki yrityksen korttitapahtumat vaativat kuluselvityksen. ",
            },
            {
                value: "own_money",
                label: 'Maksu omista rahoista',
                description: "Hyväksytyt korvaukset maksetaan palkan yhteydessä tilille " + this.props.settings.contract.iban,
            },
        ];
        return options;
    }

    getCurrencies()
    {
        let defaultCurrency = false;
        let currencies = [];

        if( !this.props.settings.currencies )
            return [];

        this.props.settings.currencies.forEach( currency => {
            if( currency.value == auth.getCurrency() )
                defaultCurrency = {
                    value: currency.value,
                    label: currency.value + " - " + currency.description,
                };
            else    
                currencies.push({
                    value: currency.value,
                    label: currency.value + " - " + currency.description,
                });
        });

        return [
            defaultCurrency, 
            { label: "Muut valuutat", options: currencies }
        ];
    }

    getCurrencyRate( rowIndex, currency, date = "latest" )
    {
        if( this.state.expenseRows[ rowIndex ].currency_loading )
            this.state.expenseRows[ rowIndex ].currency_cancel.cancel('Old operation canceled');

        if( currency == auth.getCurrency() )
            return false;


        let expenseRows = this.state.expenseRows.slice();
        expenseRows[ rowIndex ].currency_loading = true;
        expenseRows[ rowIndex ].currency_cancel = axios.CancelToken.source();

        this.setState({ expenseRows });

        api({
            cancelToken: expenseRows[ rowIndex ].currency_cancel.token,
            method: 'post',
            url: 'currencyrate',
            data: {
                date: date,
                currency: currency
            }
        }).then(( response ) => {

            if( response.rates[ currency ] )
                expenseRows[ rowIndex ].currency_rate = response.rates[ currency ];
            
            expenseRows[ rowIndex ].currency_loading = false;
            expenseRows[ rowIndex ].currency_info = <span>{ moment( response.date ).format('DD.MM.YYYY') } - <strong>{ currency }</strong> = { response.rates[ currency ] }<br /><small>Lähde: Euroopan keskuspankki</small></span>;
            
            this.setState({ expenseRows });

        }).catch(( error ) => {
            expenseRows[ rowIndex ].currency_loading = false;
            expenseRows[ rowIndex ].currency_info = false;
            this.setState({ expenseRows });
        });
    }

    costToDefaultCurrency( index )
    {
        const row = this.state.expenseRows[ index ];
        if( row.currency == auth.getCurrency() )
            return row.cost;

        const rate = row.currency_rate ? row.currency_rate : 1;
        return row.cost / rate;
    }

    totalExpenseCosts()
    {
        let total = 0;
        const defaultCurrency = auth.getCurrency();

        this.state.expenseRows.forEach( row  => {
            if( row.cost )
            {
                if( row.currency == defaultCurrency )
                {
                    total += parseFloat( row.cost );
                }
                else 
                {
                    const rate = row.currency_rate ? row.currency_rate : 1;
                    total += parseFloat( row.cost ) / rate; 
                }
            }
        });
        return total;
    }

    /**
     * Get data from DB (ID is available) or set 
     * default data based on props.data
     *
     **/
    fetchData()
    {

        const type = this.props.type;
        const data = this.props.data;
        let url = false;

        // Data is ID (get data from DB)
        if( typeof( data ) == "number" || type == "day" )
        {

            // Set correct URL based on type
            if      ( type == "hour" )      url = "hour/entry/hour/" + data;     // data = id
            else if ( type == "travel" )    url = "hour/entry/travel/" + data;   // data = id
            else if ( type == "expense" )   url = "hour/entry/expense/" + data;  // data = id
            else if ( type == "day" )       url = "hour/day/get/" + data;        // data = date (YYYY-MM-DD)
            else 
            {
                console.log('Ups.. incorrect type (' + type +')!');
                return false;
            }

            this.setState({ loading: true });
            api({
                method: 'get',
                url: url,
            }).then( ( response ) => {

                //console.log('DATA FETCHED FROM DB:', response );

                /*
                if( type == "hour" )
                    response.project_id += "_" + response.project_work_id;
                */            
    
                let expenseFileIds = [];
                let expenseRows = [];

                if( type == "expense" )
                {
                    if( response.files )
                        response.files.forEach( file => {
                            expenseFileIds.push( file.file_id );
                        }); 

                    if( response.rows )
                        expenseRows = response.rows;
                }

                if( type == "day" )
                {
                    if( !response.daily_allowance_start )
                        response.daily_allowance_start = "00:00:00";

                    if( !response.daily_allowance_end )
                        response.daily_allowance_end = "24:00:00";
                }

                this.setState({ 
                    data: response,
                    //absenceChecked: ( response.absence_reason_id && type == "hour" ? true : false ),
                    dailyAbsenceChecked: ( response.absence_reason_id && type == "day" ? true : false ),
                    dailyAllowanceChecked: ( response.daily_allowance_id && type == "day" ? true : false ),
                    expenseEventChecked: ( response.event && type == "expense" ? true : false ),
                    loadedFileIds: expenseFileIds,
                    expenseFiles: [],
                    expenseRows: expenseRows,
                    loading: false,
                    error: false,
                    locked: ( response.locked ? response.locked : false ),
                    unsavedChanges: false,
                });

            }).catch( ( error ) => {
                console.log( error );
                //console.log('URL', url );
                this.setState({ 
                    loading: false,
                    error: error.response
                });
            });
        }
        // Use data as default values 
        else if( typeof( data ) == "object" ) {

            if( type == "hour" && !data.hour_type_id )
                data.hour_type_id = this.props.settings.hourtypes[0].id;

            // By default use first item from vehicles list 
            // It's already selected anyway in DOM element
            if( type == "travel" && !data.vehicle_id )
            {
                data.vehicle_id             = this.props.settings.vehicles[0].id;
                data.vehicle_compensation   = this.props.settings.getVehicle( data.vehicle_id ).compensation;
            }

            // Set defaults for day
            if( type == "day" )
            {
                if( !data.daily_allowance_id )
                {
                    data.daily_allowance_id             = 0;
                    data.daily_allowance_compensation   = 0;
                }

                if( !data.daily_allowance_start )
                    data.daily_allowance_start = "00:00:00";

                if( !data.daily_allowance_end )
                    data.daily_allowance_end = "24:00:00";
            } 

            let expenseRows = [];
            if( type == "expense" )
                expenseRows.push({ 
                    description:        '', 
                    cost:               null ,
                    currency:           auth.getCurrency(),
                    currency_rate:      null,
                    currency_loading:   false,
                    currency_info:      false,
                    currency_cancel:    false, 
                });

            this.setState({ 
                data: data,
                //absenceChecked: ( data.absenceChecked ? true : false ),
                dailyAbsenceChecked: ( data.dailyAbsenceChecked ? true : false ),
                dailyAllowanceChecked: ( data.dailyAllowanceChecked ? true : false ),
                expenseEventChecked: ( data.expenseEventChecked ? true : false ),
                loadedFileIds: [],
                expenseFiles: [],
                expenseRows: expenseRows,
                error: false,
                locked: false,
                unsavedChanges: false,
            });

        }
        // Clean start
        // At the moment this should not happen ( always id or date provided )
        else {

            this.setState( {
                data: {},
                error: false,
                locked: false,
                unsavedChanges: false,
            })

        }
    }



    /**
     * Edit text fields
     *
     **/
    handleChange(e)
    {
        let data = { ...this.state.data };
     
        switch( e.target.name )
        {
            //case "hours_normal":
            //case "hours_50":
            //case "hours_100":
            //case "hours_200":
            //case "hours_public_holiday":
            //case "hours_weekly_rest_compensation":
            case "hours":
                let value = onlyNumber( e.target.value );
                if( value > 24 ) value = 24;
                data[ e.target.name ] = value;
                break;

            case "vehicle_id":
                data[ 'vehicle_compensation' ] = this.props.settings.getVehicle( e.target.value ).compensation;
                data[ e.target.name ] = e.target.value;
                break;

            case "daily_allowance_id":
                data[ 'daily_allowance_compensation' ] = this.props.settings.getAllowance( e.target.value ).compensation;
                data[ e.target.name ] = e.target.value;
                break;

            default: 
                data[ e.target.name ] = e.target.value;
                break;
        }

        this.setState({ 
            data: data, 
            unsavedChanges: true,
        });
    }

    handleProjectWorkChange( value )
    {
        //console.log( 'handleProjectWorkChange', value );
        let data = { ...this.state.data };
        data[ 'work' ] = value;
        this.setState({ 
            data: data, 
            unsavedChanges: true,
        });
    }

    handleProjectChange( value )
    {
        //console.log( 'handleProjectChange', value );
        let data = { ...this.state.data };
        data[ 'project' ] = value;
        this.setState({ 
            data: data, 
            unsavedChanges: true,
        });
    }

    handleWorkChange( item )
    {
        let data = { ...this.state.data };
        data[ 'project_work_id' ] = item.id;
        this.setState({ 
            data: data, 
            unsavedChanges: true,
        });
    }

    getWorkOptions()
    {
        const project = this.state.data.project;
        let options = [];
        if( project && 'works' in project )
        {
            project.works.forEach( work => {
                options.push({
                    id: work.id,
                    name: keyExists( work, "component.name", true, '-' ),
                });
            });
        }
        return options;
    }

    getSelectedWork()
    {
        const project = this.state.data.project;
        if( project && 'works' in project )
        {
            const selected = this.state.data.project.works.find( w => w.id == this.state.data.project_work_id );
            if( selected && 'component' in selected )
                return selected.component.name;
        }

        return "";
    }

    /**
     * Round hour inputs to nearest quarter of an hour
     **/
    roundHours(e) 
    {
        // Round hours to nearest 15 min (.25, .5, .75)
        const roundedValue = Math.round( e.target.value * 4 ) / 4;
        if( e.target.value != roundedValue )
        {
            let data = this.state.data;
            data[ e.target.name ] = roundedValue;
            this.setState({ data });
        }
    }

    /**
     * Handle switch change
     *
     **/
    handleSwitchChange( key, value )
    {   
        let newState = { 
            unsavedChanges: true 
        };
        newState[ key ] = value;
        this.setState( newState );
    }

    /**
     * Handle daily allowance time range change
     *
     **/
    handleAllowanceTimeChange( values )
    {
        if( values.length == 2 )
        {
            let data = { ...this.state.data };
            data.daily_allowance_start  = this.mins2Time( values[0], ":", true );
            data.daily_allowance_end    = this.mins2Time( values[1], ":", true ); 
            this.setState({ 
                data: data, 
                unsavedChanges: true 
            });
        }
    }

    /**
     * Edit expense row fields
     *
     **/
    handleExpenseRowChange( e )
    {
        let expenseRows = this.state.expenseRows.slice();

        // Extract index and name from input name (expenseRow-ID-NAME)
        //  - index = parts[1]
        //  - name  = parts[2]
        const parts = e.target.name.split('-');

        expenseRows[ parts[1] ][ parts[2] ] = e.target.value;

        if( parts[2] == "currency" )
        {
            this.getCurrencyRate( parts[1], e.target.value, this.state.data.date );
        }

        this.setState({ 
            expenseRows: expenseRows, 
            unsavedChanges: true,
        });
    }


    /**
     * Get total number of hours for hour entry
     *
     **/
     /*
    getHourTotal()
    {
        let total = 0;
        const add = (field) => {
            const value = parseFloat( this.state.data[field] );
            if( !isNaN( value ) )
                total = total + value;
        }
        add('hours_normal');
        add('hours_50');
        add('hours_100');
        add('hours_200');
        add('hours_public_holiday');
        add('hours_weekly_rest_compensation');
        return total;
    }
    */

    /**
     * Get daily trip lenght in hours and minutes
     **/
    getDailyAllowanceLength()
    {   
        let length = 24 * 60; // By default whole day in minutes
        const start = this.time2mins( this.state.data.daily_allowance_start );
        const end = this.time2mins( this.state.data.daily_allowance_end );

        if( typeof( start ) == "number" && typeof( end ) == "number" )
            length = end - start;
        
        const hours = Math.floor( length / 60 );
        const mins = length % 60;

        if( hours >= 24 )
            return "Koko päivän";

        return hours + " h" + ( mins ? " " + mins + " min" : "" );
    }

    /**
     * Converts total minutes to time (HH:MM or with custom delimiter or to DB format HH:MM:SS)
     **/
    mins2Time( min, customDelimiter, dbFormat )
    {
        const total = parseInt( min, 10 );
        if( !isNaN( total ) )
        {
            const hours = Math.floor( total / 60 );
            const minutes = total % 60;

            return ( hours < 10 ? "0" : "" ) + hours + ( customDelimiter ? customDelimiter : ":" ) + ( minutes < 10 ? "0" : "" ) + minutes + ( dbFormat === true ? ":00" : "");
        }
        return false;
    }

    /**
     * Converts time (HH:MM or HH.MM) to total minutes
     **/
    time2mins( time )
    {   
        if( typeof( time ) == "string" )
        {
            let parts = time.split( ( time.indexOf(":") > -1 ? ":" : "." ) );
            if( parts.length >= 2 )
            {
                const hours = parseInt( parts[0], 10 );
                const minutes = parseInt( parts[1], 10 );
                if( !isNaN( hours ) && !isNaN( minutes ) )
                    return ( hours * 60 ) + minutes;
            }
        }
        return false;
    }


    expenseFilesChange( files, userAction )
    {
        //console.log( 'expenseFilesChange', files, userAction );
        this.setState({
            expenseFiles: files,
            unsavedChanges: userAction ? true : false ,
        });
    }
    
    addExpenseRow()
    {
        let expenseRows = this.state.expenseRows.slice();
        expenseRows.push( { 
            description:        '', 
            cost:               null,
            currency:           auth.getCurrency(),
            currency_rate:      null,
            currency_loading:   false,
            currency_info:      false,
            currency_cancel:    false, 
        });
        this.setState({ 
            expenseRows: expenseRows,
            unsavedChanges: true
        });
    }

    removeExpenseRow( index )
    {
        let expenseRows = this.state.expenseRows.slice();
        if( index < expenseRows.length )
        {
            expenseRows.splice( index, 1 );
            this.setState({ 
                expenseRows: expenseRows,
                unsavedChanges: true
            });
        } 
    }

    /**
     * Save the data to backend and pass the
     * saved data to props.onSave
     *
     **/
    save()
    {
        this.setState({ loading: true, error: false });

        let apiData;

        const row = this.state.data;
        const fix = ( value ) => { return ( value ? value : null ) };

        // New row or editing day (date needed)
        if ( this.props.action == "add" || this.props.type == "day" ) 
            apiData = {
                date: row.date
            };
    
        // Modify old row (id needed)
        else if( this.props.action == "edit" )
        {
            apiData = {
                id: row.id
            };
        }

        apiData.type = this.props.type;

        switch( this.props.type )
        {   
            case "hour":

                apiData.description         = row.description;
                apiData.description_long    = fix( row.description_long );

                apiData.customer_work_number            = fix( row.customer_work_number );
                apiData.hours                           = fix( row.hours );
                apiData.hour_type_id                    = fix( row.hour_type_id );

                if( row.work )
                {
                    apiData.project_id                      = fix( row.work.project.id );
                    apiData.project_work_id                 = fix( row.work.id );
                }
                break; 


            case "travel":
                apiData.description             = row.description;
                apiData.project_id              = fix( row.project.id );
                apiData.customer_work_number    = fix( row.customer_work_number );
                apiData.vehicle_id              = fix( row.vehicle_id );
                apiData.route                   = row.route;
                apiData.distance                = fix( row.distance );
                apiData.hours                   = fix( row.hours );
                break;


            case "expense":
                apiData.payment_method          = fix( row.payment_method );
                apiData.project_id              = fix( row.project.id );
                apiData.customer_work_number    = fix( row.customer_work_number );
                apiData.eventChecked            = this.state.expenseEventChecked;

                if( this.state.expenseEventChecked )
                {
                    apiData.event               = row.event;
                    apiData.event_details       = fix( row.event_details );
                }

                apiData.files                   = this.state.expenseFiles;
                apiData.rows                    = this.state.expenseRows;

                break;


            case "day":

                apiData.absenceChecked     = this.state.dailyAbsenceChecked;
                apiData.allowanceChecked   = this.state.dailyAllowanceChecked;

                if( this.state.dailyAbsenceChecked )
                {
                    apiData.absence_reason_id = fix( row.absence_reason_id );
                }
                else if ( this.state.dailyAllowanceChecked )
                {
                    apiData.project_id              = fix( row.project_id );
                    apiData.customer_work_number    = fix( row.customer_work_number );
                    apiData.daily_allowance_start   = row.daily_allowance_start;
                    apiData.daily_allowance_end     = row.daily_allowance_end;
                    apiData.daily_allowance_id      = fix( row.daily_allowance_id );
                }
                break;

            default:
                console.log( 'ERROR! Invalid type(' + this.props.type + ')' );
                apiData = false;
                break;
        }

        if( apiData )
        {
            //console.log( apiData );
            api({
                method: 'post',
                url: ( this.props.type == "day" ? 'hour/day/save' : 'hour/entry/save' ),
                data: apiData
            }).then(( response ) => {
      
                this.props.onSave( response );
                this.setState({ 
                    loading: false, 
                    unsavedChanges: false 
                });

            }).catch(( error ) => {
                this.setState({ 
                    loading: false,
                    error: error.response
                });
            });
        }
    }

    getTitle()
    {    

        if( this.state.loading )
            return "Ladataan...";

        switch( this.props.type )
        {
            case "hour":
                if( this.props.action == "add" )
                    return "Lisää tuntikirjaus";

                else if ( this.state.locked )
                    return "Tuntikirjauksen tiedot";

                else 
                    return "Muokkaa tuntikirjausta";
       

            case "travel":
                if( this.props.action == "add" )
                    return "Lisää matka";

                else if ( this.state.locked )
                    return "Matkan tiedot";
            
                else 
                    return "Muokkaa matkaa";


            case "day":

                if ( this.state.locked )
                    return "Päivän tiedot";
            
                else 
                    return "Muokkaa päivää";

            default:
                return "Kirjauksen tiedot";
        }
    }

    getSubtitle()
    {   
        if( this.state.loading )
            return "Ladataan...";

        if( this.state.data.date )
            return moment( this.state.data.date ).format('dddd DD.MM.YYYY');

        return "Ups... jotain meni vikaan! " + JSON.stringify( this.state.data );
    }

    getInfoText()
    {
        if( this.state.loading )
            return "Ladataan...";

        if( this.state.error )
        {
            const msg = ( this.state.error.data && this.state.error.data.message ? this.state.error.data.message : false );
            return (
                <span style={{ color: "red" }}>
                    { msg && msg }
                    { !msg && "Virhe (" + this.state.error.status + ")" }
                </span>
            );
        }


        // Get validator result
        let validator = false;
        if( this.validatorHour && this.props.type == "hour" )
            validator = this.validatorHour.getStatusAll('array');

        else if( this.validatorTravel && this.props.type == "travel" )
            validator = this.validatorTravel.getStatusAll('array');

        else if( this.validatorExpense && this.props.type == "expense" )
            validator = this.validatorExpense.getStatusAll('array');

        else if( this.validatorDay && this.props.type == "day" )
            validator = this.validatorDay.getStatusAll('array');



        if( validator && validator.length > 0 )
        {
            return "Puutteelliset kentät: " + validator.join(", ");
        }

        if( this.state.unsavedChanges )
            return "Tallenna vielä tekemäsi muutokset";

        let savedTime = "";
        if( this.state.data.updated_at )
        {
            const time = moment( this.state.data.updated_at );
            const offset = moment().diff( time, 'days', true );
            if( offset > 2 )
                savedTime = "(" + time.format('DD.MM.YYYY [klo] HH.mm') + ")";
            else if ( offset > 1 )
                savedTime = "(eilen klo " + time.format('HH.mm') + ")";
            else 
                savedTime = "(tänään klo " + time.format('HH.mm') + ")";
        }


        if( this.state.locked )
            return "Et voi tehdä muutoksia, koska päivä on lukittu!";

        return "Tiedot tallennettu " + savedTime;
    }

    renderProjectInfo( data )
    {
        let levels = [];

        if( data )
        {
            if( data.ancestors )
            {
                data.ancestors.forEach( p => {
                    levels.push({
                        id: p.id,
                        name: p.name,
                    });
                });
            }
            levels.push({
                id: data.id,
                name: data.name,
            });
        }

        return (
            <div className="projectInfo">
                <SvgIcon className="small-inline" icon="folder" type="solid" />
                { levels.map( l =>
                    <span key={ l.id } className="level">{ l.name }</span>
                )}
                { levels.length == 0 && <span className="empty">Ei projektia valittuna</span> }
            </div>
        );
    }

    renderBody()
    {

        switch( this.props.type ) 
        {
            /**
             *  
             *   HOURS  
             * 
             **/    
            case "hour":

                const hourTypeInfo = this.props.settings.getHourType( this.state.data.hour_type_id );

                return (
                    <div>
                        <div className="apForm">
                            <div className="apFormColumn">

                                <ApInput 
                                    type="text" 
                                    id="description" 
                                    name="description" 
                                    label="Kuvaus"
                                    value={this.state.data.description}
                                    onChange={this.handleChange}
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading || this.state.locked }
                                    validationState={ this.validatorHour.getState('data.description') }
                                />

                                <div className="apFormGroup">

                                    <ApInputStack gap="0">
                                        <ApInput 
                                            type="select" 
                                            id="hour_type_id" 
                                            name="hour_type_id" 
                                            options={ this.getHourTypeOptions() }
                                            value={ this.state.data.hour_type_id }
                                            onChange={this.handleChange}
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading || this.state.locked }
                                            validationState={ this.validatorHour.getState('data.hour_type_id') }  
                                        />
                                        <ApInput 
                                            gapLeft={ 10 }
                                            width="150px"
                                            type="number" 
                                            id="hours" 
                                            name="hours" 
                                            align="right"
                                            value={ this.state.data.hours }
                                            onChange={ this.handleChange }
                                            onBlur={ this.roundHours }
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading || this.state.locked }
                                            validationState={ this.validatorHour.getState('data.hours') }
                                        />
                                        <ApAddon noLeftBorder width="50px">
                                            h
                                        </ApAddon>
                                    </ApInputStack>

                                    <Collapse in={ hourTypeInfo.description }>
                                        <div>
                                            <div className="apInfo">
                                                <SvgIcon icon="info-circle" type="solid" />
                                                { hourTypeInfo.description }
                                            </div>
                                            <hr />
                                        </div>
                                    </Collapse>

                                    <div className="apInfo small">
                                        <SvgIcon icon="clock" type="solid" />
                                        { hourTypeInfo.worktime ? "Kuuluu työaikaan" : "Ei kuulu työaikaa" }
                                    </div>

                                    <div className="apInfo small">
                                        <SvgIcon icon="hourglass-half" type="solid" />
                                        { hourTypeInfo.flexitime ? "Kerryttää liukumia" : "Ei kerrytä liukumia" }
                                    </div>

                                    <Collapse in={ hourTypeInfo.salary_extra || hourTypeInfo.salary_extra_percent || hourTypeInfo.salary_extra_per_unit }>
                                        <div>
                                            <div className="apInfo small">
                                                <SvgIcon icon="money-bill-wave" type="solid" />
                                                Vaikuttaa palkkaan
                                            </div>
                                        </div>
                                    </Collapse>
                                    
                                
                                </div>
                                
                            </div>
                            <div className="apFormColumn">
                                
                                {/* 
                                <ApNestedSelect 
                                    label="Projekti"
                                    valueRenderer={ ( item ) => {
                                        let value = item.name;

                                        // Show only first and last item of path
                                        if( 'ancestors' in item && item['ancestors'].length > 0 )
                                            value = item['ancestors'][0].name + ( item['ancestors'].length > 1 ? " ... " : "." ) + value;
                                        
                                        return value;
                                    }}
                                    value={ keyExists( this.state.data, "project", true ) }
                                    parentRenderer={ ( item ) => getLastCodePart( item.project_code ) }
                                    parentTooltipRenderer="name"
                                    optionRenderer={ (item, searchText ) => {

                                        const subprojects = item.children ? item.children.length : 0;

                                        return (
                                            <div className="projectOption">
                                                <span className="name">{ highlight( searchText, item.name ) }</span><br />
                                                <span className="code">{ highlight( searchText, item.project_code ) }</span>
                                                <div className="works">
                                                    { subprojects > 0 && 
                                                        <div>
                                                            <SvgIcon icon="folder" type="solid" /> { subprojects }
                                                        </div>
                                                    }
                                                    <div>
                                                        <SvgIcon icon="user-clock" type="solid" /> { item.works.length }
                                                    </div>
                                                </div>
                                            </div>
                                        );   
                                    }}
                                    onChange={ ( item ) => this.handleProjectChange( item, true ) }
                                    apiUrl="hour/projects"
                                    apiData={{
                                        date: this.state.data.date,
                                        withWorks: true,
                                    }}
                                    validationState={ this.validatorHour.getState('data.project') }
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading || this.state.locked }
                                />

                                <ApSelect
                                    label="Projektin työtehtävä"
                                    value={ this.getSelectedWork() }
                                    optionRenderer={ ( item ) => item.name }
                                    onChange={ this.handleWorkChange }
                                    objKeyId="id"
                                    objKeySearchable="name"
                                    clearable
                                    options={ this.getWorkOptions() }
                                    validationState={ this.validatorHour.getState('data.project_work_id') }
                                    disabled={ this.state.loading || this.state.locked || !this.state.data.project }
                                    loading={ this.state.loading }
                                />
                                */}

                                <div className="apFormGroup">
                            
                                    <ApSelect
                                        label="Projekti"
                                        loading={ this.state.loading }
                                        value={ this.state.data.work ? this.state.data.work.project.project_code + " (" + ( this.state.data.work.name || this.state.data.work.component.name ) + ")" : "" }
                                        optionRenderer="project_works"
                                        onChange={ this.handleProjectWorkChange }
                                        objKeyId="id"
                                        clearable
                                        apiUrl="hour/projects/works"
                                        apiData={{ 
                                            date: this.state.data.date 
                                        }}
                                        validationState={ this.validatorHour.getState('data.work') }
                                        disabled={ this.state.loading || this.state.locked }
                                    />

                                    { this.renderProjectInfo( this.state.data.work ? this.state.data.work.project : false ) }
                                    

                                    { keyExists( this.props.settings, "company.customer_work_number", true, false ) && 
                                        <ApInput 
                                            type="text" 
                                            id="customer_work_number" 
                                            name="customer_work_number" 
                                            label="Asiakastyönumero"
                                            value={this.state.data.customer_work_number}
                                            onChange={this.handleChange}
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading || this.state.locked }
                                        />
                                    }

                                </div>

                                <br />

                                <ApInput 
                                    type="textarea" 
                                    id="description_long" 
                                    name="description_long" 
                                    label="Lisätietoa"
                                    value={this.state.data.description_long}
                                    onChange={this.handleChange}
                                    charCounter
                                    rows={5}
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading || this.state.locked }
                                />
                                
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    Lisätiedot eivät näy asiakkaalle, vaan ne ovat pelkästään yrityksen sisäiseen käyttöön. 
                                </div>

                            </div>
                        </div>
                    </div>
                );


            /**
             *  
             *   TRAVEL 
             * 
             **/
            case "travel":

                const selectedVehicleDesc = this.props.settings.getVehicle( this.state.data.vehicle_id ).description;

                return (
                    <div>
                        <div className="apForm">
                            <div className="apFormColumn">

                                <ApInput 
                                    type="text" 
                                    id="description" 
                                    name="description" 
                                    label="Kuvaus"
                                    value={this.state.data.description}
                                    onChange={this.handleChange}
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading || this.state.locked }
                                    validationState={ this.validatorTravel.getState('data.description') }
                                />

                                <br />

                                { /*
                                <ApNestedSelect 
                                    label="Projekti"
                                    valueRenderer={ ( item ) => {
                                        let value = item.name;
                                        if( 'ancestors' in item && item['ancestors'].length > 0 )
                                            value = item['ancestors'][0].name + ( item['ancestors'].length > 1 ? " ... " : "." ) + value;

                                        return value;
                                    }}
                                    value={ keyExists( this.state.data, "project", true ) }
                                    parentRenderer={ ( item ) => getLastCodePart( item.project_code ) }
                                    parentTooltipRenderer="name"
                                    optionRenderer={ (item) => {
                                        return (
                                            <div className="projectOption">
                                                <span className="name">{ item.name }</span><br />
                                                <span className="code">{ getLastCodePart( item.project_code ) }</span>
                                            </div>
                                        );   
                                    }}
                                    onChange={ this.handleProjectChange }
                                    apiUrl="hour/projects"
                                    apiData={{
                                        date: this.state.data.date,
                                        type: "travel",
                                    }}
                                    validationState={ this.validatorHour.getState('data.project') }
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading || this.state.locked }
                                />
                                */ }

                                {/* 
                                <ApSelect
                                    label="Projekti"
                                    loading={ this.state.loading }
                                    value={ this.state.data.project ? this.state.data.project.name : "" }
                                    optionRenderer="project"
                                    onChange={ this.handleProjectChange }
                                    objKeyId="id"
                                    clearable
                                    apiUrl="hour/projects"
                                    apiData={{ 
                                        date: this.state.data.date,
                                        type: "travel"
                                    }}
                                    validationState={ this.validatorTravel.getState('data.project') }
                                    disabled={ this.state.loading || this.state.locked }
                                />
                                */}

                                <div className="apFormGroup">

                                    <ApSelect
                                        label="Projekti"
                                        loading={ this.state.loading }
                                        value={ this.state.data.project ? this.state.data.project.project_code : "" }
                                        optionRenderer="project"
                                        onChange={ this.handleProjectChange }
                                        objKeyId="id"
                                        clearable
                                        apiUrl="hour/projects"
                                        apiData={{ 
                                            date: this.state.data.date
                                        }}
                                        validationState={ this.validatorTravel.getState('data.project') }
                                        disabled={ this.state.loading || this.state.locked }
                                    />

                                    { this.renderProjectInfo( this.state.data.project ) }
                                        

                                    { keyExists( this.props.settings, "company.customer_work_number", true, false ) && 
                                        <ApInput 
                                            type="text" 
                                            id="customer_work_number" 
                                            name="customer_work_number" 
                                            label="Asiakastyönumero"
                                            value={ this.state.data.customer_work_number }
                                            onChange={ this.handleChange }
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading || this.state.locked }
                                        />
                                    }

                                </div>
                                
                            
                            </div>
                            <div className="apFormColumn">

                                <ApInputStack gap="0">
                                    <ApInput 
                                        type="select" 
                                        id="vehicle_id" 
                                        name="vehicle_id" 
                                        options={ this.getAllowanceOptions( "vehicles" ) }
                                        value={ this.state.data.vehicle_id }
                                        onChange={ this.handleChange }
                                        loading={ this.state.loading }
                                        disabled={ this.state.loading || this.state.locked }
                                    />
                                    <ApAddon noLeftBorder width="150px">
                                       { roundToDecimals( this.state.data.vehicle_compensation, 2 ) } { auth.getCurrencySign() }/km
                                    </ApAddon>
                                </ApInputStack>

                                { selectedVehicleDesc &&
                                    <div className="apInfo small">
                                        <SvgIcon icon="info-circle" type="solid" />
                                        { selectedVehicleDesc }
                                    </div>
                                }

                                <ApInput 
                                    type="text" 
                                    id="route" 
                                    name="route" 
                                    label="Reitti"
                                    value={ this.state.data.route }
                                    onChange={ this.handleChange }
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading || this.state.locked }
                                    validationState={ this.validatorTravel.getState('data.route') }
                                />

                                <br />

                                <ApInputStack gap="0">
                                    <ApAddon noRightBorder width="150px">
                                        Matkan pituus
                                    </ApAddon>
                                    <ApInput 
                                        type="number" 
                                        id="distance" 
                                        name="distance" 
                                        align="right"
                                        value={ this.state.data.distance }
                                        onChange={ this.handleChange }
                                        loading={ this.state.loading }
                                        disabled={ this.state.loading || this.state.locked }
                                        validationState={ this.validatorTravel.getState('data.distance') }
                                    />
                                    <ApAddon noLeftBorder width="50px">
                                        km
                                    </ApAddon>
                                </ApInputStack>

                                <ApInputStack gap="0">
                                    <ApAddon noRightBorder width="150px">
                                        Matka-aika
                                    </ApAddon>
                                    <ApInput 
                                        type="number" 
                                        id="hours" 
                                        name="hours" 
                                        align="right"
                                        value={ this.state.data.hours }
                                        onChange={ this.handleChange }
                                        onBlur={ this.roundHours }
                                        loading={ this.state.loading }
                                        disabled={ this.state.loading || this.state.locked }
                                        validationState={ this.validatorTravel.getState('data.hours') }
                                    />
                                    <ApAddon noLeftBorder width="50px">
                                        h
                                    </ApAddon>
                                </ApInputStack>

                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    Matkan pituus määrittää maksettavat kilometrikorvaukset ja matka-aika kerryttää päivittäistä työaikaa. Mikäli et halua kilometrikorvauksia, voit jättää matkan pituuden tyhjäksi ja mikäli matkaa ei lasketa työaikaan, jätä matka-aika tyhjäksi. 
                                </div>

                                


                            </div>
                        </div>

                    </div>

                );


             /**
             *  
             *   Expense 
             * 
             **/
            case "expense":

                const paymentOptions = this.getPaymentMethodOptions();
                
                let paymentMethodDesc = false;
                paymentOptions.forEach( option => {
                    if( option.value == this.state.data.payment_method )
                        paymentMethodDesc = option.description;
                });
                
                return (
                    <div>
                        <div className="apForm">
                            <div className="apFormColumn">

                                <ApInputStack gap="0">
                                    <ApAddon noRightBorder width="150px">
                                       Maksutapa
                                    </ApAddon>
                                    <ApInput 
                                        type="select" 
                                        id="payment_method" 
                                        name="payment_method" 
                                        options={ paymentOptions }
                                        value={ this.state.data.payment_method }
                                        onChange={ this.handleChange }
                                        loading={ this.state.loading }
                                        disabled={ ( this.state.loading || this.state.locked ) }
                                        validationState={ this.validatorExpense.getState('data.payment_method') }
                                    />
                                </ApInputStack>

                                { paymentMethodDesc &&
                                    <div className="apInfo small">
                                        <SvgIcon icon="info-circle" type="solid" />
                                        { paymentMethodDesc }
                                    </div>
                                }

                                <br />

                                <div className="apFormGroup">

                                    <ApSelect
                                        label="Projekti"
                                        loading={ this.state.loading }
                                        value={ this.state.data.project ? this.state.data.project.project_code : "" }
                                        optionRenderer="project"
                                        onChange={ this.handleProjectChange }
                                        objKeyId="id"
                                        clearable
                                        apiUrl="hour/projects"
                                        apiData={{ 
                                            date: this.state.data.date
                                        }}
                                        validationState={ this.validatorExpense.getState('data.project') }
                                        disabled={ this.state.loading || this.state.locked }
                                    />

                                    { this.renderProjectInfo( this.state.data.project ) }

                                    { keyExists( this.props.settings, "company.customer_work_number", true, false ) && 
                                        <ApInput 
                                            type="text" 
                                            id="customer_work_number" 
                                            name="customer_work_number" 
                                            label="Asiakastyönumero"
                                            value={ this.state.data.customer_work_number }
                                            onChange={ this.handleChange }
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading || this.state.locked }
                                        />
                                    }

                                </div>

                                <br />

                                <div className={"apFormGroup" + ( this.state.expenseEventChecked ? " success" : "" ) }>
                                
                                    <div className="apSwitchBlock small">
                                        <label htmlFor="expense-event-switch" className="info">
                                            Tilaisuus
                                            <small>Neuvottelu, asiakastapaaminen tai vastaava tilaisuus. </small>
                                        </label>
                                        <ApSwitch
                                            small
                                            id="expense-event-switch"
                                            on={ this.state.expenseEventChecked }
                                            onChange={ ( e ) => this.handleSwitchChange( 'expenseEventChecked', e.target.checked ) }
                                            disabled={ this.state.loading || this.state.locked }
                                        />
                                    </div>

                                    <Collapse in={ this.state.expenseEventChecked }>
                                        <div>
                                            
                                            <hr />
                                            
                                            <ApInput 
                                                type="text" 
                                                id="event" 
                                                name="event" 
                                                label="Tilaisuuden nimi"
                                                value={this.state.data.event}
                                                onChange={this.handleChange}
                                                loading={ this.state.loading }
                                                disabled={ this.state.loading || this.state.locked }
                                                validationState={ this.validatorExpense.getState('data.event') }
                                            />

                                            <ApInput 
                                                type="textarea" 
                                                id="event_details" 
                                                name="event_details" 
                                                label="Lisätietoa"
                                                value={this.state.data.event_details}
                                                onChange={this.handleChange}
                                                charCounter
                                                rows={4}
                                                loading={ this.state.loading }
                                                disabled={ this.state.loading || this.state.locked }
                                            />

                                            <div className="apInfo small">
                                                <SvgIcon icon="info-circle" type="solid" />
                                                Edustuskulujen tositteista tulee ilmetä edustuksen luonne ja selvitys siitä, kuinka edustuskulu on liittynyt liiketoimintaan. Lisäksi tositteella tulee näkyä niiden henkilöiden nimet, joita edustus on koskenut.
                                            </div>

                                        </div>
                                    </Collapse>

                                </div>
                                

                            </div>
                            <div className="apFormColumn">

                                <ApFileUpload 
                                    files={ this.state.loadedFileIds }
                                    accept="image/jpeg, image/png, application/pdf"
                                    description={ "Kuittiselvitys " + this.state.data.date }
                                    onChange={ this.expenseFilesChange }
                                    uploadedFiles={ [] }
                                    maxFiles={ 5 }
                                    showRemoved 
                                    returnRemoved
                                    maxSize={ 1024 * 1024 * 1 } // 1 Mt
                                    disabled={ this.state.loading || this.state.locked }
                                    noFilesLabel="Lisää kuva kuitista"
                                    noFilesSubLabel="Skannaa kuitti tai ota valokuva puhelimella"
                                    addButtonLabel="Lisää kuitti..."
                                    validationState={ this.validatorExpense.getState('expenseFiles') }
                                />

                                

                                <div className="apFormGroup">

                                    <h5>Kulurivit</h5>

                                    { this.state.expenseRows.map( ( row, index ) => 
                                        <div className="expenseRow">
                                            <ApInputStack gap="0">
                                                <ApInput 
                                                    type="text" 
                                                    id={ "expenseRow-" + index + "-description" } 
                                                    name={ "expenseRow-" + index + "-description" } 
                                                    label="Kuvaus"
                                                    value={this.state.expenseRows[ index ].description}
                                                    onChange={ this.handleExpenseRowChange }
                                                    loading={ this.state.loading }
                                                    disabled={ this.state.loading || this.state.locked }
                                                    validationState={ this.validatorExpense.getState('expenseRows', index, 'description') }
                                                />
                                                <ApInput 
                                                    gapLeft="10px"
                                                    width="120px"
                                                    noRightBorder
                                                    type="number" 
                                                    id={ "expenseRow-" + index + "-cost" } 
                                                    name={ "expenseRow-" + index + "-cost" } 
                                                    label="Hinta"
                                                    align="right"
                                                    value={ this.state.expenseRows[ index ].cost }
                                                    onChange={ this.handleExpenseRowChange }
                                                    loading={ this.state.loading }
                                                    disabled={ this.state.loading || this.state.locked }
                                                    validationState={ this.validatorExpense.getState('expenseRows', index, 'cost') }
                                                />
                                                <ApInput 
                                                    noLeftBorder
                                                    width="90px"
                                                    type="select" 
                                                    id={ "expenseRow-" + index + "-currency" } 
                                                    name={ "expenseRow-" + index + "-currency" } 
                                                    options={ this.getCurrencies() }
                                                    value={ this.state.expenseRows[ index ].currency }
                                                    onChange={ this.handleExpenseRowChange }
                                                    loading={ this.state.loading }
                                                    disabled={ ( this.state.loading || this.state.locked ) }
                                                />
                                                <ApAddon custom width="32px">
                                                    <div className={"removeButton" + (this.state.expenseRows.length <= 1 ? " disabled" : "" ) } onClick={ () => this.removeExpenseRow( index ) }>
                                                        <SvgIcon icon="trash-alt" type="solid" />
                                                    </div>
                                                </ApAddon>
                                            </ApInputStack>

                                            <Collapse in={ this.state.expenseRows[ index ].currency != auth.getCurrency() }>
                                                <div>
                                                    <div className="currencyRate">
                                                        <ApInputStack gap="0">
                                                            <ApAddon noRightBorder width="50px" align="right">
                                                                { this.state.expenseRows[ index ].currency }
                                                            </ApAddon>
                                                            <ApInput 
                                                                type="number" 
                                                                id={ "expenseRow-" + index + "-currency_rate" } 
                                                                name={ "expenseRow-" + index + "-currency_rate" } 
                                                                label="Valuutan kurssi"
                                                                value={ this.state.expenseRows[ index ].currency_rate }
                                                                onChange={ this.handleExpenseRowChange }
                                                                loading={ this.state.loading || this.state.expenseRows[ index ].currency_loading }
                                                                disabled={ this.state.loading || this.state.expenseRows[ index ].currency_loading || this.state.locked }
                                                                tooltip={ this.state.expenseRows[ index ].currency_info ? this.state.expenseRows[ index ].currency_info : "Ei kurssitietoja saatavilla" }
                                                            />
                                                            <ApAddon noLeftBorder width="200px">
                                                                = { roundToDecimals( this.costToDefaultCurrency( index ) , 2 ) } { auth.getCurrency() }
                                                            </ApAddon>
                                                        </ApInputStack>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            
                                        </div>
                                    )}

                                    <ApButton color="green" size="small" onClick={ this.addExpenseRow }>
                                        <SvgIcon icon="plus-square" type="regular" />
                                        Lisää kulurivi
                                    </ApButton>

                                    <div className="totalCosts">
                                        Yhteensä { roundToDecimals( this.totalExpenseCosts(), 2 ) } { auth.getCurrency() }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                );



            /**
             *  
             *   DAY 
             * 
             **/
            case "day":

                const allowancesAvailable = ( this.props.settings.allowances.length > 0 );
                const absenceReasonDesc = this.props.settings.getAbsence( this.state.data.absence_reason_id ).description;
                const allowanceReasonDesc = this.props.settings.getAllowance( this.state.data.daily_allowance_id ).description;

                return (
                    <div>
                        <div className="apForm">
                            <div className="apFormColumn">

                                 <div className={"apFormGroup" + ( this.state.dailyAllowanceChecked ? " success" : "" ) + ( this.state.dailyAbsenceChecked ? " disabled" : "" ) }>
                                
                                    <div className="apSwitchBlock small">
                                        <label htmlFor="daily-allowance-switch" className="info">
                                            Työmatkakorvaus
                                            <small>Työnantaja voi korvata tilapäisestä työmatkasta aiheutuneita kustannuksia</small>
                                        </label>
                                        <ApSwitch
                                            small
                                            id="daily-allowance-switch"
                                            on={ ( this.state.dailyAllowanceChecked && !this.state.dailyAbsenceChecked ) }
                                            onChange={ ( e ) => this.handleSwitchChange( 'dailyAllowanceChecked', e.target.checked ) }
                                            disabled={ this.state.loading || this.state.locked || this.state.dailyAbsenceChecked || !allowancesAvailable }
                                        />
                                    </div>

                                    <Collapse in={ this.state.dailyAllowanceChecked }>
                                        <div>
                                            
                                            <hr />

                                            <div className="apFormGroup">

                                                <ApSelect
                                                    label="Projekti"
                                                    loading={ this.state.loading }
                                                    value={ this.state.data.project ? this.state.data.project.project_code : "" }
                                                    optionRenderer="project"
                                                    onChange={ this.handleProjectChange }
                                                    objKeyId="id"
                                                    clearable
                                                    apiUrl="hour/projects"
                                                    apiData={{ 
                                                        date: this.state.data.date
                                                    }}
                                                    validationState={ this.validatorDay.getState('data.project') }
                                                    disabled={ this.state.loading || this.state.locked || this.state.dailyAbsenceChecked }
                                                />

                                                { this.renderProjectInfo( this.state.data.project ) }

                                                { keyExists( this.props.settings, "company.customer_work_number", true, false ) && 
                                                    <ApInput 
                                                        type="text" 
                                                        id="customer_work_number" 
                                                        name="customer_work_number" 
                                                        label="Asiakastyönumero"
                                                        value={ this.state.data.customer_work_number }
                                                        onChange={ this.handleChange }
                                                        loading={ this.state.loading }
                                                        disabled={ this.state.loading || this.state.locked || this.state.dailyAbsenceChecked }
                                                    />
                                                }

                                            </div>
                                            
                                            <br />

                                            <h6>Työmatkan kesto: <small>({ this.getDailyAllowanceLength() })</small></h6>
                                            <ApRangeSlider
                                                min={ 0 }
                                                max={ 1440 } // Minutes in day
                                                step={ 15 }
                                                marks={{
                                                    0: "00.00",
                                                    360: "06.00",
                                                    720: "12.00",
                                                    1080: "18.00",
                                                    1440: "24.00",
                                                }}
                                                defaultValue={[ 0, 1440 ]} 
                                                value={[
                                                    this.state.data.daily_allowance_start ? this.time2mins( this.state.data.daily_allowance_start ) : 0, 
                                                    this.state.data.daily_allowance_end ? this.time2mins( this.state.data.daily_allowance_end ) : 1440
                                                ]}
                                                onChange={ this.handleAllowanceTimeChange }
                                                tooltip
                                                tooltipRenderer={ (value) => <span>{ this.mins2Time( value, '.' ) }</span> }
                                                disabled={ ( this.state.loading || this.state.locked || this.state.dailyAbsenceChecked ) }
                                            />

                                            
                                            <ApInputStack gap="0">
                                                <ApAddon noRightBorder width="150px">
                                                   Korvaus
                                                </ApAddon>
                                                <ApInput 
                                                        type="select" 
                                                        id="daily_allowance_id" 
                                                        name="daily_allowance_id" 
                                                        options={ this.getAllowanceOptions( "dailyAllowances" ) }
                                                        value={ this.state.data.daily_allowance_id }
                                                        onChange={ this.handleChange }
                                                        loading={ this.state.loading }
                                                        disabled={ ( this.state.loading || this.state.locked || this.state.dailyAbsenceChecked ) }
                                                        validationState={ this.validatorDay.getState('data.daily_allowance_id') }
                                                    />
                                                <ApAddon noLeftBorder width="100px">
                                                   { this.state.data.daily_allowance_compensation ? roundToDecimals( this.state.data.daily_allowance_compensation, 2 ) : "0.00" } { auth.getCurrencySign() }
                                                </ApAddon>
                                            </ApInputStack>

                                            { allowanceReasonDesc &&
                                                <div className="apInfo small">
                                                    <SvgIcon icon="info-circle" type="solid" />
                                                    { allowanceReasonDesc }
                                                </div>
                                            }
                                        </div>
                                    </Collapse>
                                </div>

                            </div>
                            <div className="apFormColumn">

                                <div className={"apFormGroup" + ( this.state.dailyAbsenceChecked ? " success" : "" ) }>
                                
                                    <div className="apSwitchBlock small">
                                        <label htmlFor="day-absence-switch" className="info">
                                            Poissa koko päivän
                                            <small>Et voi kirjata mitään muuta kyseiselle päivälle</small>
                                        </label>
                                        <ApSwitch
                                            small
                                            id="day-absence-switch"
                                            on={ this.state.dailyAbsenceChecked }
                                            onChange={ ( e ) => this.handleSwitchChange( 'dailyAbsenceChecked', e.target.checked ) }
                                            disabled={ this.state.loading || this.state.locked }
                                        />
                                    </div>

                                    <Collapse in={ this.state.dailyAbsenceChecked }>
                                        <div>
                                            
                                            <hr />
                                            
                                            <ApInput 
                                                type="select" 
                                                id="absence_reason_id" 
                                                name="absence_reason_id" 
                                                options={ this.getAbsenceOptions( true ) }
                                                value={ this.state.data.absence_reason_id }
                                                onChange={ this.handleChange }
                                                loading={ this.state.loading }
                                                disabled={ this.state.loading || this.state.locked }
                                                validationState={ this.validatorDay.getState('data.absence_reason_id') }
                                            />
                            
                                            { absenceReasonDesc &&
                                                <div className="apInfo small">
                                                    <SvgIcon icon="info-circle" type="solid" />
                                                    { absenceReasonDesc }
                                                </div>
                                            }

                                        </div>
                                    </Collapse>

                                </div>

                            </div>
                        </div>
                    </div>
                );
            default:
                return <span>Virhe, ei vielä sisältöä kohteelle '{ this.props.type }'</span>;
        }
    }

    render()
    {      
        const renderStatus = () => {

            if( this.state.loading ) 
                return false;

            if( this.state.data.status_id != statusCode("submitted") && 
                this.state.data.status_id != statusCode("rejected") && 
                this.state.data.status_id != statusCode("approved")  )
                return false; 

            let icon = false;
            
            if( this.state.data.status_id == statusCode("submitted") )
                icon = "arrow-right";
            
            else if( this.state.data.status_id == statusCode("rejected") )
                icon = "times";

            else if( this.state.data.status_id == statusCode("approved") )
                icon = "check";

            return (
                <div className="padding">
                    <div className={ "entryStatus status-" + statusName( this.state.data.status_id ) }>
                    
                        { icon && <SvgIcon className="small-inline" icon={ icon } type="solid" /> }
                        <strong>{ statusText( this.state.data.status_id ) }: </strong> 
                        <span>{ moment( this.state.data.status_changed ).format( 'DD.MM.YYYY HH:mm') } / { this.state.data.approver_name } </span>
                     
                        { this.state.data.approver_comment && this.state.data.status_id == statusCode("rejected") && 
                            <div className="comment">{ this.state.data.approver_comment }</div>
                        }
                    </div>
                </div>
            );
        };

        return (
            <ApModal
                show={ this.props.show }
                handleClose={ () => this.props.onClose( this.state.unsavedChanges ) }
                closeFromBg
                header={ 
                    <div className="padding" id="EntryEditHeader">
                        <h3>{ this.getTitle() }</h3>
                        <p>{ this.getSubtitle() }</p>
                    </div>
                }
                body={ 
                    <div id="EntryEditBody">
                        { renderStatus() }
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="padding" id="EntryEditFooter">
    
                        <div className="validateMsg">
                            <SvgIcon icon="info-circle" type="solid" />
                            { this.getInfoText() }
                        </div>

                        <ApButton onClick={ () => this.props.onClose( this.state.unsavedChanges ) }>
                            <SvgIcon icon="times" type="solid" />
                            Peruuta
                        </ApButton>

                        <ApButton color="blue" onClick={ this.save } disabled={ !this.state.unsavedChanges }>
                            <SvgIcon icon="save" type="solid" />
                            Tallenna
                        </ApButton>

                    </div>
                }

            />
        );
    }
}

EntryEdit.propTypes = {
    show:       PropTypes.bool.isRequired,
    action:     PropTypes.string,
    type:       PropTypes.string,
    data:       PropTypes.oneOfType([ PropTypes.string, PropTypes.number]),
    settings:   PropTypes.object,
    onSave:     PropTypes.func,
    onClose:    PropTypes.func,
};

export default EntryEdit;
