/* eslint-disable eqeqeq */
import React        from 'react';
import { Link }     from 'react-router-dom'

import auth from   'services/Authed/Authed.js';
import './SideNavMenu.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';

class SideNavMenu extends React.Component {

    handleClick( e, index )
    {   
        var item = this.props.data[ index ];
        if( item.subMenu ) {
            this.props.toggleSubmenu( item.id );
            e.preventDefault();
        }
        else {
           this.props.handleSelect( item.id ); 
        }        
    }

    calcMaxHeight( item )
    {
        var total = 0;

        if( typeof(item.subMenu ) == "object" ) {
            if( item.subMenuOpen ) {
                for( let i = 0; i < item.subMenu.length; i++) {
                    total += this.calcMaxHeight( item.subMenu[i] );
                    total += 41; // Item height 40px + border bottom 1px (SideNavMenu.css);
                }
            }
            
        }
        return total;
    }


    // Takes array of permissions and checks that current user has these permissions
    // Permission can be required or optional (ends with '_?'). 
    // If there are optional permissions at least one of them is required
    checkPermissions(item)
    {
        if( item.globalAdminOnly )
            return auth.isGlobalAdmin();

        if( item.adminOnly )
            return auth.isAdmin();


        if( item.permissions )
        {
            // No permissions required
            if( item.permissions.length == 0 )
                return true;

            let optionalCount = 0;
            let optionalHavePermission = false;

            for( let i = 0; i < item.permissions.length; i++ )
            {
                let permission = item.permissions[i];
                const isOptional = permission.endsWith('_?');

                if( isOptional )
                {   
                    permission = permission.slice( 0, -2 );
                    optionalCount++;
                }

                // User has permission  
                if( auth.hasModule( permission ) )
                {
                    if( isOptional ) optionalHavePermission = true;
                }
                // User does NOT have optional permission 
                else if ( isOptional )
                {
                    continue;
                }
                // User does NOT have required permission 
                else 
                {
                    return false;
                }
            }

            // At this point we have checked that no required permissions 
            // are missing (or there are no required permissions)

            // Check if there are optional permissions and at least one is available
            return ( optionalCount > 0 && !optionalHavePermission ? false : true );
        }
        
        console.error('Permissions not set correctly!', item );
        return false;
    }


    render()
    {

        return (
            <ul className="nav-group" style={{ maxHeight: (typeof( this.props.maxHeight ) == "number" ? this.props.maxHeight + "px" : "auto" ) }}>
                {this.props.data.map((item, index ) => {
                    
                    if( !this.checkPermissions(item) )
                        return null;
                    if(item.id ==='online-store' && !this.props.onlineStoreEnabled) {
                        return null;
                    }

                    // Header / Divider row
                    if( !item.id && typeof( item.header ) === "string" ) {
                        return (
                            <li key={index} className="divider">{ item.header }</li>
                        );
                    }

                    let classes = ["item"];

                    if( item.id == this.props.selected )    classes.push("selected");
                    if( item.subMenuOpen )                  classes.push("subnav-open");
                    if( item.disabled )                     classes.push("disabled");

                    let badgeError = 0;
                    let badgeWarning = 0;
                    if( item.id in this.props.badges )
                    {
                        badgeError = this.props.badges[ item.id ].errors;
                        badgeWarning = this.props.badges[ item.id ].warnings;
                    }

                    if( badgeError > 0 && badgeWarning > 0 ) classes.push("doubleBadges");
                
                    //special case chat need unread message info
                    

                    // Regular menu item
                    return (
                        <li key={index} className={ classes.join(" ") }>
                            <Link 
                                className="link"
                                to={ (item.to ? item.to : "#" ) }  
                                onClick={(e) => { this.handleClick( e, index ) }}
                            >
                                <SvgIcon icon={ item.icon } type="solid" />
                                <span className="title">{item.name}</span>
                                {item.id === "chat" && this.props.unreadChatMessageCount> 0 &&
                                    <div className={"messageCount"}>{this.props.unreadChatMessageCount}</div>}
                                

                                { badgeWarning > 0 &&
                                    <span className="badge warning">{ badgeWarning }</span>
                                }
                                { badgeError > 0 &&
                                    <span className="badge error">{ badgeError }</span>
                                }
                            </Link>

                            { item.subMenu && <SvgIcon className="subnav-indicator" icon="chevron-left" type="solid" /> }
                            { item.subMenu && 
                                <SideNavMenu 
                                    data={item.subMenu} 
                                    selected={this.props.selected} 
                                    handleSelect={this.props.handleSelect}
                                    toggleSubmenu={ this.props.toggleSubmenu }
                                    maxHeight={ this.calcMaxHeight( item ) }
                                    badges={ this.props.badges }
                                    onlineStoreEnabled={ this.props.onlineStoreEnabled }
                                /> 
                            }

                        </li>
                    );
                })} 
            </ul>
        );
    }
};


export default SideNavMenu;

