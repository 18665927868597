/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

/*
import Datetime from 'react-datetime';
import moment from 'moment';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
*/

import { formatMoney } from 'services/Helpers/Helpers.js';


import './ApInputSmall.css';

class ApInputSmall extends React.Component
{

    constructor( props )
    {
        super( props );
        this.state = {
            focused: false,
        };
        autoBind( this );
    }

    changeHandler(e)
    {
        if( typeof( this.props.onChange ) == "function" )
        {
            this.props.onChange(e);
        }
    }

    keyPressHandler(e)
    {
        if( typeof( this.props.onKeyPress ) == "function" )
        {
            this.props.onKeyPress(e);
        }
    }

    inputFocusChanged( e, focused )
    {
        this.setState({ focused: focused });
        if( e )
        {
            if( typeof( this.props.onFocus ) == "function" && focused )
            {
                this.props.onFocus(e);
            }
            else if ( typeof( this.props.onBlur ) == "function" && !focused )
            {
                this.props.onBlur(e);
            }
        }
    }

    render()
    {

        let classes = ["apInputSmallBlock"];

        if( this.state.focused )  classes.push("focused");
        if( this.props.disabled ) classes.push("disabled");
        if( this.props.readOnly ) classes.push("readOnly");
        if( this.props.loading )  classes.push("loading");

        if( ["error", "warning", "loading", "success"].indexOf( this.props.validationState ) != -1 )
            classes.push( "validator-" + this.props.validationState );


        // Remove props that should not go to input element
        const {type, onChange, onFocus, onBlur,  loading, noFocusDecimals, tooltip, unit, validationState, ...otherProps } = this.props;

        let value = ( this.props.value ? this.props.value : "" );

        // Format unfocused number to show only few defined number of decimals when not focused
        if( typeof noFocusDecimals !== 'undefined' && !this.state.focused && !isNaN( parseFloat( value ) ))
            value = formatMoney(value, noFocusDecimals );

        const inputElement = <input
            { ...otherProps }
            type="text"
            value={ value }
            onChange={ this.changeHandler }
            onFocus={ (e) => { this.inputFocusChanged(e, true); }}
            onBlur={ (e) => { this.inputFocusChanged(e, false); }}
        />

        return <div className={ classes.join(" ") }>
            { inputElement }
            { this.props.tooltip && <div className="apTooltip">{ this.props.tooltip }</div> }
            { this.props.loading && <div className="apLoader"></div> }
            { this.props.unit && <div className="unit">{ this.props.unit }</div> }
        </div>


    }
};

export {
    ApInputSmall,
};
