/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
//import auth from 'services/Authed/Authed.js';

import './Groups.css';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';
//import ApReactTable from 'common/ApReactTable/ApReactTable.js';
//import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
//import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

import { keyExists, tr } from 'services/Helpers/Helpers.js';

export default class CrmGroupsSettings extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            error: false,
            groups: false,
            unsavedChanges: false,

            modalId: false,
            modalType: false,
            modalValue: '',
        };
        autoBind(this);

        this.nextId = 0; 
    }

    componentDidMount()
    {
        this.getGroups();
    }

    getGroups()
    {  
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'crm/groups'
        }).then((response) => {
            this.setState({ 
                groups: response,
                unsavedChanges: false,
                loading: false 
            });
        }).catch((error) => {
            console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('get_error') ) || tr('error'),
                loading: false 
            });
        });
    }

    getGroupName( type, id )
    {
        const items = this.state.groups[ type ].slice();
        for( let i = 0; i < items.length; i++ )
        {
            if( items[i].id == id )
                return items[i].name;
        }
        return "";
    }

    addItem( type )
    {
        this.setState({ 
            modalId: "new",
            modalType: type,
            modalValue: '',
        });
    }

    renameItem( type, id )
    {
        this.setState({ 
            modalId: id,
            modalType: type,
            modalValue: this.getGroupName( type, id ),
        });
    }

    saveModal( value )
    {
        const type = this.state.modalType;
        const id = this.state.modalId;

        let items = this.state.groups[ type ].slice();
        
        if( id == "new" )
        {
            items.push( { 
                name: value, 
                id: "new" + this.nextId++, 
                member_count: 0,
                unsaved: true 
            });
        }
        else 
        {
            for( let i = 0; i < items.length; i++ )
            {
                if( items[i].id == id )
                {
                    items[i].name = value;
                    items[i].unsaved = true;
                    break;
                }
            }
        }

        this.setState( prevData => ({ 
            ...prevData,
            groups: {
                ...prevData.groups,
                [ type ]: items,
            },
            unsavedChanges: true,
        }));
    }

    removeItem( type, id )
    {
        let items = this.state.groups[ type ].slice();
        for( let i = 0; i < items.length; i++ )
        {
            if( items[i].id == id )
            {
                items[i].removed = !items[i].removed; // !undefined = true
                items[i].unsaved = true;
                break;
            }
        }

        this.setState( prevData => ({ 
            ...prevData,
            groups: {
                ...prevData.groups,
                [ type ]: items,
            },
            unsavedChanges: true,
        }));
    }

    saveGroups()
    {
        this.setState({ 
            loading: true, 
            error: false 
        });
        api({
            method: 'post',
            url: 'crm/groups/save',
            data: this.state.groups
        }).then((response) => {
            this.getGroups();
        }).catch((error) => {
            console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('save_error') ) || tr('error'),
                loading: false 
            });
        });
    }

    renderItem( type, item, key )
    {
        let classes = ["item"];

        if( item.removed )
            classes.push( "removed" );

        if( item.unsaved )
            classes.push( "unsaved" );

        return (
            <div key={ key } className={ classes.join(" ") }>
                
                <div className="actions">
                    <ApDropdown
                        actionId={ item.id }
                        actions={[
                            {
                                label: tr('rename'),
                                icon: "edit",
                                disabled: item.removed,
                                action: ( item, closeFunc ) => {
                                    this.renameItem( type, item );
                                    closeFunc();
                                },
                            },
                            { "divider": true },
                            {
                                label: ( item.removed ? tr('cancel_delete') : tr('delete_group') ),
                                icon: "trash-alt",
                                action: ( item, closeFunc ) => {
                                    this.removeItem( type, item );
                                    closeFunc();
                                }
                            }
                        ]}
                    />
                </div>

                <SvgIcon className="icon" icon="tag" type="solid" />
                <strong>{ item.name }</strong><br />
                <small>{ tr('members_in_group') }: { item.member_count } { tr('pcs') }</small>

                { item.unsaved && <SvgIcon className="unsavedIcon" icon="exclamation-circle" type="solid" /> }

            </div>
        );
    }

    render() 
    {
        return (
            <div id="pageCrmGroups">

                <ApFormPage 
                    onSave={ this.saveGroups } 
                    loading={ this.state.loading } 
                    unsaved={ this.state.unsavedChanges }
                    error={ this.state.error }
                >
                    <div className="apBoxHeader">
                        <h2>{ tr('customer_groups_management') }</h2>
                        <p>{ tr('customer_groups_management_info') }</p>
                    </div>
                    <div className="padding">

                        <div className="clear">
                            <div className="apColumn w50">
                                <div className="padding">

                                    <h4>
                                        { tr('customer_groups') }
                                        { this.state.groups && <small> ({ this.state.groups.company.length } { tr('pcs') })</small> }
                                    </h4>

                                    <div className="list">
                                        { this.state.loading &&
                                            <div className="apLoader"></div>
                                        }

                                        { this.state.groups && this.state.groups.company.map( ( item, key ) => this.renderItem( "company", item, key ) ) }

                                        { this.state.groups && this.state.groups.company.length == 0 &&
                                            <div className="empty">
                                                { tr('no_groups_defined') }
                                            </div>
                                        }

                                    </div>

                                    <ApButton fullWidth onClick={ () => this.addItem( "company" ) }>
                                        <SvgIcon icon="plus" type="solid" />
                                        { tr('add_customer_group') }
                                    </ApButton>

                                </div>
                            </div>
                            <div className="apColumn w50">
                                <div className="padding">
                             
                                    <h4>
                                        { tr('contact_groups') }
                                        { this.state.groups && <small> ({ this.state.groups.contact.length } { tr('pcs') })</small> }
                                    </h4>

                                    <div className="list">
                                        { this.state.loading &&
                                            <div className="apLoader"></div>
                                        }

                                        { this.state.groups && this.state.groups.contact.map( ( item, key ) => this.renderItem( "contact", item, key ) ) }

                                        { this.state.groups && this.state.groups.contact.length == 0 &&
                                            <div className="empty">
                                                { tr('no_groups_defined') }
                                            </div>
                                        }
                                    </div>

                                    <ApButton fullWidth onClick={ () => this.addItem( "contact" ) }>
                                        <SvgIcon icon="plus" type="solid" />
                                        { tr('add_contact_group') }
                                    </ApButton>

                                </div>
                            </div>
                        </div>

                    </div>
                </ApFormPage>

                <ApModalInput 
                    show={ this.state.modalId !== false }
                    value={ this.state.modalValue }
                    onSave={ ( value ) => this.saveModal( value ) }
                    onClose={ () => this.setState({ modalId: false }) }
                    title={ this.state.modalType == "company" ? tr('customer_group') : tr('contact_group') }
                    label="Nimi"
                    required
                />

            </div>
        );
    }
}