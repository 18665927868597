import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import { 
	ApInput
} from 'common/ApInput/ApInput.js';
import ApButton           from 'common/ApButton/ApButton.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApModal            from 'common/ApModal/ApModal.js';
import { tr } from 'services/Helpers/Helpers.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import ApSelect from 'common/ApSelect/ApSelect';
import "./AddNotificationRecipient.css";


export default class AddNotificationRecipient extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {

			form: {
				email: ''
      },
      user: null,
      errorMsg: ''

    };
    
    this.validator = new ApValidate( this, {
      "form.email":           { filter: 'email', state: 'warning', text: tr('invalid_email')}
    });

		autoBind(this);
  }
  
  resetState() {
    this.setState({
      form: {
        email: ''
      },
      user: null,
      errorMsg: ''
    });
  }

	addRecipient()
	{

		api({
			method: 'post',
			url: 'crm/settings/addCrmNotificationRecipient',
			params: {
				user_id: this.state.user.id
			}
		}).then((response) => {
      this.closeEditor();
      this.props.refreshList();
		}).catch((error) => {
        this.setState({
          errorMsg: error.response.data.message
        });
		});

	}

	initializeForm(event)
	{

		let newForm = this.state.form;

		newForm.email = event.email;

		this.setState({ form: newForm });

	}

	closeEditor()
	{
		this.props.onClose();		
	}

	clickCancelButton()
	{
		this.closeEditor();
	}

	clickSaveButton()
	{
		this.addRecipient();
	}

	handleChange(e)
	{

		let newForm = this.state.form;
		newForm[e.target.name] = e.target.value ? e.target.value : null;
		this.setState({ form: newForm });

	}

  handleSelectChange(user) {
    this.setState({ user: user });
  }

	getValidationState()
	{

		return this.state.form.heading !== null;

	}

	renderForm()
	{
    let form = <ApSelect
      value={this.state.user ? this.state.user.name : ''}
      optionRenderer='user'
      onChange={this.handleSelectChange}
      objKeyId="id"
      clearable
      filterNonActives={true}
      apiUrl="search/user"
    />

		return form;

	}

	render()
	{

		let form = this.renderForm();
    
		return(

			<div id='AddNotificationRecipientModal'>

				<ApModal
					show={this.props.show}
					className="narrow"
          backdrop="static"
          onOpen={this.resetState}
          header={ 
            <div className="padding-small">
                <h4>{ tr('add_recipient') }</h4>
            </div>
          }
          body={
            <div className="padding recipient-form" style={{ marginTop: 30 }}>
              {form}
              { (this.state.errorMsg && this.state.errorMsg !== '') && 
                <div className="apErrorMsg">
                    { this.state.errorMsg }
                </div>
              }
            </div>
          }
          footer={
            <div id="newCrmCompanyFooter">

                <ApButton className="cancel" onClick={ this.clickCancelButton } disabled={ this.state.loading }>
                    <SvgIcon icon="times" type="solid" />
                    { tr('cancel') }
                </ApButton>

                <ApButton
                  className={ "save" + ( !this.validator.getState('form.email') ? " highlight" : "" ) }
                  color="blue"
                  onClick={ this.clickSaveButton }
                  disabled={ this.state.loading || !this.state.user}
                  loading={ this.state.loading }
                >
                    <SvgIcon icon="save" type="solid" />
                    { tr('save') }
                </ApButton>
            
            </div>
          }
				/>
			</div>
		);
  }
}
