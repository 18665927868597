import React from 'react';

import ApReactTable from 'common/ApReactTable/ApReactTable';
import { formatCurrency, keyExists, tr } from 'services/Helpers/Helpers';
import moment from 'moment';
import Totals from '../Totals';


class CashFlow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let data = this.props.all_orders || [];
        data.sort((a, b) => moment(a.due_date).valueOf() - moment(b.due_date).valueOf());

        return (
            <div className='padding'>
                <Totals 
                    in_total={this.props.in_total} 
                    orders_in_total={this.props.orders_in_total} 
                    po_in_total={this.props.po_in_total} 
                    openingBalance={this.props.openingBalance} 
                />
                <ApReactTable
                    rememberId='cashFlow'
                    loading={this.props.loading}
                    data={data}
                    columns={[
                        {
                            Header: tr('cash_flow'),
                            columns: [
                                {
                                    id: 'due_date',
                                    Header: tr('due_date'),
                                    accessor: acc => moment(acc.due_date).format('L'),
                                    sortable: false,
                                },
                                {
                                    id: 'company',
                                    Header: tr('company'),
                                    accessor: acc => {
                                        if (acc.type === 'purchase_order') {
                                            return keyExists(acc.supplier, 'crm_company.name', true, '');
                                        }
                                        if (acc.type === 'sales_order') {
                                            return keyExists(acc.company, 'name', true, '');
                                        }
                                        if (acc.type === 'payment_post') {
                                            return keyExists(acc.payment, 'project.customer.name', true, '');
                                        }
                                        return '';
                                    },
                                    sortable: false,
                                },
                                {
                                    id: 'type',
                                    Header: tr('type'),
                                    accessor: acc => tr(acc.type),
                                    sortable: false,
                                },
                                {
                                    id: 'price',
                                    Header: tr('price'),
                                    accessor: acc => {
                                        if (acc.type === 'purchase_order') {
                                            return formatCurrency(-1*acc.price);
                                        }
                                        else if (acc.type === 'payment_post') {
                                            return formatCurrency(acc.payment_amount);
                                        }
                                       else if (acc.type === 'sales_order') {
                                            return formatCurrency(acc.price);
                                        }
                                    },
                                    sortable: false,
                                },
                                {
                                    id: 'balance',
                                    Header: tr('balance'),
                                    accessor: acc => {
                                        return formatCurrency((this.props.openingBalance ? parseFloat(this.props.openingBalance) : 0) + parseFloat(acc.cumulative));
                                    },
                                    sortable: false,
                                }
                            ]
                        }
                    ]}
                />
            </div>
        )
    }
}

export default CashFlow;