import React from 'react'
import moment from 'moment';
import autoBind from 'react-autobind';

import auth from 'services/Authed/Authed.js';
//import File from 'common/File/File.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { formatMoney
       , timeSince
       , keyExists
       , sqlToDateInput
       , getImageUrl
       , tr
       , currentLang } from 'services/Helpers/Helpers.js';

import { Collapse } from 'react-bootstrap';
import './Audits.css';

const actions = {
    new: { color: 'green', icon: 'plus', title: tr('new_order') },
    edit: { color: '', icon: 'edit', title: tr('editing' ) },
    back_to_edit: { color: '', icon: 'edit', title: tr('returned_to_edit') },
    offer: { color: 'green', icon: 'arrow-right', title: tr('order_confirmation_or_offer_made') },
    edit_done: { color: 'green', icon: 'arrow-right', title: tr('editing_complete') },
    internal_comment: { color: '', icon: 'edit', title: tr('editing' ) },
    available_some: { color: '', icon: 'thermometer-half', title: tr('part_available') },
    available_none: { color: 'red', icon: 'thermometer-empty', title: tr('not_available') },
    available_all: { color: 'green', icon: 'thermometer-full', title: tr('all_available') },
    delivery: { color: 'green', icon: 'arrow-right', title: tr('shipment') },
    sent_all: { color: 'green', icon: 'arrow-right', title: tr('all_sent') },

    paid: { color: 'green', icon: 'arrow-right', title: tr('paid') },
    paid_cancel: { color: 'red', icon: 'arrow-left', title: tr('payment_canceled') },
    archived: { color: 'purple', icon: 'archive', title: tr('archived') },
    archived_cancel: { color: 'purple', icon: 'box-open', title: tr('archiving_canceled') },

    cancel_delivery: { color: 'red', icon: 'arrow-left', title: tr('cancellation') },
}

const fields = {
    number: { title: tr('number') },
    delivery_term_name: { title: tr('delivery_term') },
    delivery_method_name: { title: tr('delivery_method') },
    payment_condition_name: { title: tr('payment_term') },
    reference_our: { title: tr('reference_number_our') },
    reference_your: { title: tr('reference_number_your') },
    details: { title: tr('additional_info') },
    date: { title: tr('date'), formatter: v => sqlToDateInput ( v ) },
    need_date: { title: tr('day_of_need'), formatter: v => sqlToDateInput ( v ) },

    billing_name: { title: tr('billing_name') },
    billing_address: { title: tr('billing_address') },
    billing_city: { title: tr('billing_city') },
    billing_zipcode: { title: 'billing_postal_code' },
    billing_country: { title: 'billing_country' },
    receiver_name: { title: tr('delivery_name') },
    receiver_address: { title: tr('delivery_address') },
    receiver_city: { title: tr('delivery_city') },
    receiver_zipcode: { title: tr('delivery_postal_code') },
    receiver_country: { title: tr('delivery_country') },
    price: { title: tr('price'), formatter: ( value ) => { return <span>{ formatMoney( value ) } { auth.getCurrencySign() }</span> } },
    internal_comment: { title: tr('internal_comment') },
};

const componentFields = {
    name: { title: tr('name') },
    count: { title: tr('amount') },
    order_price: { title: tr('price'), formatter: ( value ) => { return <span>{ formatMoney( value ) } { auth.getCurrencySign() }</span> } },
    target_final_arrived: { title: tr('at_destination'), formatter: ( value ) => { return value ? tr('yes') : tr('no') } },
}

class Audits extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            open: [],
        }
        autoBind(this);
    }

    toggleAuditDetails( id )
    {
        let open = this.state.open.slice( 0 );
        if( open.includes( id ) ) open = open.filter( i => i !== id );
        else open.push( id );
        this.setState({ open: open });
    }

    render()
    {
        const getDetails = ( audit ) => {

            let changes = [];

            for (let key in audit.changes ) {
                if ( !( key in fields ) ) continue;

                let changeNew = audit.changes[ key ][ 'new' ];
                let changeOld = audit.changes[ key ][ 'old' ];
                if( fields[ key ].formatter )
                {
                    changeNew = fields[ key ].formatter( audit.changes[ key ][ 'new' ] );
                    changeOld = fields[ key ].formatter( audit.changes[ key ][ 'old' ] );
                }

                changes.push(
                    <div className="description" key={ key }>
                        <strong>{ fields[ key ].title }</strong><br />
                        <span className="detailRow">
                            { changeOld }
                            <SvgIcon className="changeArrow" icon="long-arrow-alt-right" type="solid" />
                            { changeNew }
                        </span>
                    </div>
                );
            }

            audit.components.forEach( ( c, i ) => {
                let cChanges = [];

                for (let key in c.changes ) {
                    if ( !( key in componentFields ) ) continue;
                    let changeNew = c.changes[ key ][ 'new' ];
                    let changeOld = c.changes[ key ][ 'old' ];
                    if( componentFields[ key ].formatter )
                    {
                        changeNew = componentFields[ key ].formatter( c.changes[ key ][ 'new' ] );
                        changeOld = componentFields[ key ].formatter( c.changes[ key ][ 'old' ] );
                    }
                    cChanges.push(
                        <div key={ key }>
                            <span className="cTitle">{ componentFields[ key ].title }</span>
                            <span className="detailRow">
                                { changeOld }
                                <SvgIcon className="changeArrow" icon="long-arrow-alt-right" type="solid" />
                                { changeNew }
                            </span>
                        </div>
                    );
                }
                changes.push(
                    <div className="description" key={ i }>
                        <strong>{ c.name }</strong><br />
                        { cChanges }
                    </div>
                )
            })
            return changes;
        }

        let auditDoms = this.props.audits.map( ( audit, key ) => {

            const image = ( audit.user.image_id ? getImageUrl( audit.user.image_id ) : '/img/user_default.jpg' );

            let newStatus = null;
            if( audit.new_status )
                newStatus = <div>{ tr('new_status') }: {audit.new_status} </div>

            let detailsOpen = this.state.open.includes( audit.id );

            let classes = [ "audit" ];
            if( detailsOpen ) classes.push('detailsOpen');

            let icon = 'question';
            let actionName = '???' ;

            let action = actions[ audit.action ];
            if( action )
            {
                classes.push( action.color );
                actionName = action.title;
                icon = action.icon;
            }

            let detailsDom = null;
            const details = getDetails( audit );
            if( details.length )
            {
                classes.push( 'hasDetails' );
                detailsDom = <Collapse in={ detailsOpen }>
                    <div className="content">
                        { details }
                    </div>
                </Collapse>
            }

            return <div className={ classes.join(" ") } key={ key }>
                <SvgIcon className="auditIcon" icon={ icon } type="solid" />
                <div className="header" onClick={ () => this.toggleAuditDetails( audit.id ) }>
                    <div className="user">
                        <ApTooltip position="right" text={ audit.user.name }>
                            <div className="image" style={{ backgroundImage: 'url(' + image + ')' }}></div>
                        </ApTooltip>
                    </div>
                    <strong className="title">{ actionName }</strong><br />
                    <small className="time"> { moment( audit.created_at ).locale(currentLang()).format('LLL') } ({ timeSince( audit.created_at ) })</small><br />
                    <small className="latestCommnet">{ keyExists( audit, 'changes.latest_comment.new', true, null ) }</small>
                    { newStatus }
                </div>
                { detailsDom }
            </div>
        });

        return <div className="audits">
            <div className="timeline"></div>
            { auditDoms }
        </div>
    }
}

export default Audits;
