import React from 'react';
import autoBind from 'react-autobind';

import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApReactTable       from 'common/ApReactTable/ApReactTable.js';
import auth               from 'services/Authed/Authed.js';
import api                from 'services/Api/Api.js';
import ApTooltip          from 'common/ApTooltip/ApTooltip.js';
import { errorPopper
       , formatMoney
       , sqlToDateInput
       , tr } from 'services/Helpers/Helpers.js'
import { getTypeName
       , getTypeIcon }    from 'modules/Storage/common/StorageHelpers.js';

import './Transports.css';
import ApButton from 'common/ApButton/ApButton';
import NewTransport from './NewTransport/NewTransport';
import { ApTab, ApTabs } from 'common/ApTabs/ApTabs';
import ArchivedTransports from './ArchivedTransports';
import ApConfirm from "common/ApConfirm/ApConfirm";

class Transport extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            data: [],
            loading: false,
            newTransportShow: false,
            deleteId: null,
        };
        //this.state.columns = this.getColumns();

        autoBind(this);
    }

    componentDidMount()
    {
        this.getTransports();
    }

    getTransports() {
        this.setState({loading: true});
        api({
            method: 'get',
            url: 'storage/delivery/transportList',
        }).then((response) => {
            //console.log('data: ', response);
            this.setState({
                data: response,
                loading: false
            })
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            console.error(error);
            this.setState({
                data: [],
                loading: false
            })
        });
    }

    getColumns()
    {
        return [
        // {
        //     id: 'id',
        //     Header: 'Id',
        //     accessor: 'id',
        //     filterable: false,
        //     sortable: false,
        //     onClick: row => this.viewTransport( row.id ),
        // },
        {
            id: 'number',
            Header: tr('number'),
            accessor: 'number',
            filterable: true,
            sortable: true,
            onClick: row => this.viewTransport( row.id ),
            customFilter: {
                type: "text",
                placeholder: tr('number'),
            },
            width: 75,
        },{
            id: 'date',
            Header: tr('date'),
            accessor: 'date',
            filterable: false,
            sortable: true,
            width: 150,
            onClick: row => this.viewTransport( row.id ),
            Cell: props => {
               return sqlToDateInput( props.value );
            },
        },{

            id: 'sender',
            Header: tr('sender'),
            className: "overflowableOnHover",
            accessor: 'sender',
            filterable: true,
            sortable: true,
            onClick: row => this.viewTransport( row.id ),
            customFilter: {
                type: "text",
                placeholder: tr('sender'),
            },
        },{
            id: 'receiver',
            Header: tr('recipient'),
            className: "overflowableOnHover",
            accessor: 'receiver',
            filterable: true,
            sortable: true,
            onClick: row => this.viewTransport( row.id ),
            customFilter: {
                type: "text",
                placeholder: tr('recipient'),
            },
        },{
            id: 'tila',
            Header: tr('status'),
            accessor: 'tila',
            filterable: true,
            sortable: true,
            onClick: row => this.viewTransport( row.id ),
            Cell: props => tr(props.value),
            customFilter: {
                type: "select",
                options: [
                    {value: '', label: tr('all')},
                    {value: 'waiting_for_approval1', label: tr('waiting_for_approval1')},
                    {value: 'approved_waiting_collection', label: tr('approved_waiting_collection')},
                    {value: 'collected', label: tr('collected')},
                    {value: 'sent', label: tr('sent')},
                    {value: 'arrived', label: tr('arrived')},
                ]
            }
        },{
            id: 'comment',
            Header: tr('comment'),
            accessor: 'comment',
            filterable: true,
            sortable: true,
            width: 200,
            onClick: row => this.viewTransport( row.id ),
            customFilter: {
                type: "text",
                placeholder: tr('comment'),
            },
        }];
    }

    viewTransport( id )
    {
        //console.log('data: ', this.state.data);
        //window.emitter.emit( 'goTo', { pathname: `/storage/transports/edit/${id}` } );
        sessionStorage.setItem('transportId',id);
        window.emitter.emit( 'goTo', { pathname: `/storage/transports/edit` } );
        //window.emitter.emit( 'goTo', { pathname: `/storage/logistic/delivery/show/${id}` } );
    }

    deleteTransport() {
        const id = this.state.deleteId;
        this.setState({loading:true});

        api({
            method: 'post',
            url: `storage/delivery/delete/${id}`,
        }).then((response) => {
            // console.log("RES", response);
            errorPopper(null, tr('deleted'), 4000, 'success');
            this.getTransports();
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({loading:false, changed: false});
        });
    }

    render()
    {
        return (
            <div className="apBox" id="TransmitList">
                <div className="apBoxHeader">
                    <h1>{ tr('storage_transfers') }</h1>
                    <p> { tr('manage_storage_transfers') } </p>
                    <div className="apBoxCorner">
                        <ApButton color="green"
                            onClick={ () => { this.setState({ newTransportShow: true }) } }
                        > { tr('new_storage_transfer') } </ApButton>
                    </div>
                </div>
            <ApTabs>
                <ApTab label={tr('storage_transfers')} icon='road'>
                    <div className='padding'>
                        <ApReactTable

                            //defaultSorted={[{id: 'id', desc: true}]}

                            //manual
                            //onFetchData={ this.getData }
                            //pages={ this.state.pages }

                            loading={ this.state.loading }
                            data={ this.state.data }
                            columns={ this.getColumns() }
                            rowActions={[
                                {
                                    icon: "eye",
                                    label: tr('show'),
                                    action: this.viewTransport,
                                },
                                {
                                    icon: "trash",
                                    label: tr('storage_transfer_delete'),
                                    action: id => this.setState({deleteId: id}),
                                    closeAfter: true,
                                },
                            ]}
                            filterable={true}
                            showFiltersInitially={true}
                            minRows={ 10 }
                        />
                    </div>
                </ApTab>
                <ApTab mountOnSelect label={tr('archived')} icon='archive'>
                    <div className='padding'>
                        <ArchivedTransports />
                    </div>
                </ApTab>
            </ApTabs>
            <NewTransport 
                show={this.state.newTransportShow}
                onClose={() => this.setState({newTransportShow:false})}
            />
            <ApConfirm
                show={Boolean(this.state.deleteId)}
	            header={tr('storage_transfer_delete')}
	            body={ tr('storage_transfer_delete_confirm') }
	            onConfirm={this.deleteTransport }
	            onClose={ () => this.setState({ deleteId: null })}
            />
        </div>
        )
    }
}

export default Transport;
