import React from 'react';
import api from 'services/Api/Api.js';
import autoBind from 'react-autobind';
import moment from 'moment';
import Personnel from './Personnel.js';
import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import { ApInput, ApAddon, ApInputStack } from 'common/ApInput/ApInput.js';
import ApButton from 'common/ApButton/ApButton.js';
import { errorPopper, tr }  from 'services/Helpers/Helpers.js'
import AddProjectsModal from './AddProjectsModal.js'
import HideConfirmModal from './HideConfirmModal.js'
// import { Item } from 'react-bootstrap/lib/Breadcrumb';
 
class PersonnelManagement extends React.Component {

    constructor( props )
    {
        super( props );

        this.state = {

            unsavedChanges: false,
            personnelGroups: [],
            personnelItems: [],
            filteredPersonnelItems: [],
            openPersonnelGroups: ['holiday', 'available', 'absence'],
            editedPersonnelItems: [],
            deletedPersonnelItems: [],
            editedPersonnelGroups: [],
            personnel: [],
            dateMarkers: [],
            continious: false,
            user: 0,
            showAddProjectsModal: false,
            showHideConfirmModal: false,
            selectedHiddenProject: null,
            assignments: false,

            projects: [],
            projectsStart: moment(),
            projectsEnd: moment(),
        }
        autoBind(this)
    }

    componentDidMount()
    {
        this.getData()
    }

    getData()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: `project/management/personneldata`,
        }).then((response) => {
            // console.log("response", response);
            // sort response personnel by name
            response.personnel.sort((a, b) => a.user.last_name.localeCompare(b.user.last_name));
            response.personnel.forEach(user => {
                if (user.holiday) {
                    user.holiday.forEach(holiday => {
                        user.allocations.push(...this.splitHoliday(holiday));
                    });
                }
            });

            response.assignments.forEach(assignment => {
                assignment['begin_date'] = moment(assignment.begin_at).format("YYYY-MM-DD");
                assignment['end_date'] = assignment.end_at ? moment(assignment.end_at).format("YYYY-MM-DD") : null;
            })
            
            const allGroups = response.projects.concat(response.assignments);
            const start = moment(allGroups.reduce((a, b) => (
                moment(a.begin_date, "YYYY-MM-DD").format("x") < moment(b.begin_date).format("x") 
                ? a : b)).begin_date).startOf('isoWeek');

            const end = allGroups.some(group => !group.end_date)
                ? moment().add(1, "years")
                : moment(allGroups.reduce((a, b) => (
                    moment(a.end_date, "YYYY-MM-DD").format("x") > moment(b.end_date).format("x") 
                    ? a : b)).end_date);

            this.setState({ 
                loading: false,
                unsavedChanges: false,
                personnel: response.personnel,
                projects: response.projects,
                projectsStart: start,
                projectsEnd: end
            }, () => {
                const dateMarkers = this.parseMarkers(allGroups)
                const groups = this.parseGroups(response.projects, response.assignments, dateMarkers)  
                this.parseItems(groups, dateMarkers);
            });

        }).catch((error) => {
            console.log(error);
            this.setState({ loading: false });
        });
    }

    save()
    {
        //koitetaan korjata random kellon aikoihin, random datalla sattuva CORS error
        // toistaiseksi tapahtuu vain capomaticilla
        let personnelItems = JSON.parse(JSON.stringify(this.state.personnelItems));
        let personnelGroups = JSON.parse(JSON.stringify(this.state.personnelGroups));
        let deletedPersonnelItems = JSON.parse(JSON.stringify(this.state.deletedPersonnelItems));

        const editedItems = personnelItems.filter(item => item.group !== 'available' && item.group !== "holiday" && !item.isEntry);
        let editedGroups = personnelGroups.filter(group => this.state.editedPersonnelGroups.some(edited => edited === group.id) && group.id !==  'available' || group.id !== "holiday");
        let deletedItems = [...deletedPersonnelItems];
        personnelItems.forEach(item => {
            if (item.db_id && item.group === 'available' && !deletedItems.includes(item.db_id) || item.group === "holiday") {
                deletedItems.push(item.db_id);
            }
        });

        let smallerEditedItems = editedItems.map(item => {
            let user = { id: item.user.id };
            item.user = user;
            return item;
        });

        this.setState({ loading: true });
        console.log(smallerEditedItems);
        console.log(deletedItems);
        console.log(editedGroups);
        api({
            method: 'post',
            url:"project/management/savepersonnelprojects",
            data: {
                editedItems: smallerEditedItems,
                deletedItems: deletedItems,
                editedGroups: editedGroups
            }
        })
        .then(() => {
            this.getData();
            this.setState({ editedPersonnelGroups: [], deletedPersonnelItems: [] })
        })
        .catch(err => {
            errorPopper('error', tr('save_error'));
            this.setState({loading: false });
        });

    }

    getUserOptions()
    {
        // let options = [{
        //     value: 0,
        //     label: tr('all')
        // }];
        let options = [];
        this.state.personnel.forEach(person => {
            options.push({
                value: person.id,
                label: person.user.name
            })
        })
        // sort options by label/name
        options.sort((a, b) => a.label?.localeCompare(b.label));
        options.unshift({
            value: 0,
            label: tr('all')
        });
        return options;
    }

    onUserChange(e)
    {
        let value;
        let openGroups = ['holiday', 'available', 'absence'];
        let newPersonnelItems;
        value = parseInt(e.target.value);
        this.state.personnel.forEach(person => {
            if (person.id === value)
            {
                person.allocations.forEach(allocation => {
                    if (this.state.personnelGroups.find(group => group.id === allocation.project_id))
                    {
                        if (allocation.project_id!==null) {
                            openGroups.push(allocation.project_id)
                        }
                    }

                    if (this.state.personnelGroups.find(group => group.id === 'assignment_'+allocation.assignment_id))
                    {
                        if (allocation.assignment_id!==null) {
                            openGroups.push('assignment_'+allocation.assignment_id)
                        }
                    }
                })                    
                newPersonnelItems = this.state.personnelItems.filter(item => item.user.id === person.id)
            }
            if (value === 0)
            {
                this.state.personnelGroups.forEach(item => {
                    if (!openGroups.includes(item.id))
                    {
                        openGroups.push(item.id)
                    }
                })
            }
        })
        this.setState({ user: value, openPersonnelGroups: openGroups, filteredPersonnelItems: newPersonnelItems })
    }
    
    parseGroups( projects, assignments ) 
    {
        let groups = [];
        let openGroups = ['holiday', 'available', 'absence'];

        projects.forEach(item => {
            let allocated = 0;
            item.works.forEach( work => {
                allocated += parseFloat(work.alloc_count);
            });
            allocated = Math.round(allocated * 10) / 10;

            groups.push({
                id: item.id,
                title: item.name,
                project_code: item.project_code,
                value: parseFloat(item.allocated_hours),
                allocated: allocated,
                works: item.works,
                begin_date: item.begin_date,
                end_date: item.end_date,
                stackItems: true,
                entryCount: item.entry_count,
                offer: item.offer,
                customerName: item.customer 
                    ? item.customer.name 
                    : item.main_project.customer 
                        ? item.main_project.customer.name 
                        : null,
            });

            if (this.state.user === 0)
            {
                openGroups.push(item.id)
            }
        })

        assignments.forEach(item => {
            let allocated = 0;
            item.project?.works.forEach( work => {
                allocated += parseFloat(work.alloc_count);
            });
            allocated = Math.round(allocated * 10) / 10;
            groups.push({
                id: 'assignment_' + item.id,
                title: item.name,
                project_code: item.project_code,
                project_id: item.project_id,
                value: 0,
                allocated: allocated,
                works: item.project?.works,
                begin_date: item.begin_at,
                end_date: item.end_at,
                stackItems: true,
                assignment_id: item.id,
                customerName: item.customer ? item.customer.name : null,
            });

            if (this.state.user === 0)
            {
                openGroups.push('assignment_' +item.id)
            }
        })

        groups.push(
            {
                id: 'absence',
                title: tr('absences'),
                disabled: true,
                begin_date: moment().startOf("week").format("YYYY-MM-DD"),
                end_date: this.state.projectsEnd.format("YYYY-MM-DD"),
            },
            {
                id: 'holiday',
                title: tr('holidays'),
                disabled: true,
                begin_date: moment().subtract(1, "week").format("YYYY-MM-DD"),
                end_date: moment().subtract(1, "week").endOf("isoWeek").format("YYYY-MM-DD"),
            },
            {
                id: 'available', 
                title: tr('available'),
                disabled: true
            }
        )

        this.setState({
            personnelGroups: groups,
            openPersonnelGroups: openGroups
        });

        return groups;
    }

    parseItems(groups, dateMarkerData = null)
    {
        let { dateMarkers } = this.state;
        if (dateMarkerData) {
            dateMarkers = dateMarkerData;
        }
        let continious = dateMarkers.some(mark => mark['ends'] == null) ? true : false;

        let start = moment(dateMarkers.reduce((a, b) => (a.date < b.date ? a : b)).date).startOf('isoWeek')
        let end = continious ? moment().add(1, 'years').startOf('week') : moment(dateMarkers.reduce((a, b) => (a.date > b.date ? a : b)).date).startOf('isoWeek')
        let diff = end.diff(start, 'week')
        let items = [];
        for (let i=0; i <= diff; i++)
        {
            let week = start.format('W');
            let year = start.format('YYYY')

            const weekNow = moment().isoWeek();
            const yearNow = moment().year();

            let isBeforeCurrWeek = false;
            // Only commented out in case we want to do something with weeks before current week
            // if (week < weekNow && year <= yearNow) 
            //     isBeforeCurrWeek = true;
            // else
            //     isBeforeCurrWeek = false;

            let weekNumber = `${week}-${year}`;
            this.state.personnel.forEach((user, index) => {
                const daysWeekly = user.contract_detail.days_weekly ? parseFloat(user.contract_detail.days_weekly) : 5;
                if (user.allocations && user.allocations.find(allocation => allocation.week === weekNumber))
                {
                    const hoursDaily = user.contract_detail.hours_daily ? parseFloat(user.contract_detail.hours_daily) : 8
                    const hoursWeekly = user.contract_detail.hours_weekly ? parseFloat(user.contract_detail.hours_weekly) : 40
                    let multiplier = parseFloat(24 / hoursDaily);
                    // let multiplier = 1;
                    let total = 0;
                    const allocatedTime = [];
                    user.allocations.forEach(allocation => {
                        let isHoliday = allocation.project_id && allocation.project_id === "holiday"
                            ? true
                            : false;

                        let group;
                        if (allocation.absence) {
                            group = groups.find(group => group.id == 'absence');
                        }
                        else if (allocation.project_id==null) {
                            group = groups.find(group => group.id == "assignment_"+allocation.assignment_id );
                        }
                        else {
                            group = groups.find(group => group.id == allocation.project_id);
                        }
                        if (allocation.week === weekNumber && group)
                        {
                            let timeDifference = (moment(allocation.end).format('x')-moment(allocation.start).format('x'))/60/60/1000
                            let value = (timeDifference / multiplier)
                            let end_time = parseInt(moment(allocation.end).format('x'));
                            if (parseInt(moment(allocation.end).format('x')) > parseInt(moment(allocation.start).add(hoursWeekly, "hour").format('x'))) {
                                end_time = parseInt(moment(allocation.start).add(hoursWeekly, "hour").format('x'))
                            }
                            allocatedTime.push({
                                start: parseInt(moment(allocation.start).format('x')), 
                                end: parseInt(moment(allocation.end).format('x')),
                            })
                            total += value;
                            items.push({
                                id: `${week}-${year}-${user.id}-${items.length}${isHoliday ? "-holiday":""}`,
                                db_id: allocation.id,
                                assignment: allocation.assignment,
                                user: user,
                                group: group.id,
                                value: value,
                                week: weekNumber,
                                work: allocation.work_id,
                                title: !isHoliday ? 
                                    `${user.user.name} ${this.convertTime(value)}`
                                    :`${allocation.name}`,
                                start_time: parseInt(moment(allocation.start).format('x')),
                                end_time: parseInt(moment(allocation.end).format('x')),
                                canMove: isBeforeCurrWeek || isHoliday ? false : true,
                                canResize: isBeforeCurrWeek || isHoliday ? false : true,
                                canChangeGroup: isBeforeCurrWeek || isHoliday ? false : true,
                                isHoliday: isHoliday
                            })
                        }
                    })
                    total = hoursWeekly - total;
                    let roundedTotal = total.toFixed(3);
                    if (roundedTotal > 0)
                    {
                        // get all allocations not visible on personnel management view
                        const filteredUserAllocations = user.allocations.filter(userItem => {
                            if (userItem.week == weekNumber) {
                                if (userItem.project_id) {
                                    return !this.state.openPersonnelGroups.includes(userItem.project_id);
                                } else if (userItem.assignment_id) {
                                    return !this.state.openPersonnelGroups.includes(`assignment_${userItem.assignment_id}`);
                                }
                            }
                        })
                        const hiddenAllocations = filteredUserAllocations.map(item => {
                            // subtract from total available hours the amount of allocated time not visible on page
                            total -= ((parseInt(moment(item.end, "YYYY-MM-DD hh:mm:ss").format("x")) - parseInt(moment(item.start, "YYYY-MM-DD hh:mm:ss").format("x")))/60/60/1000)/multiplier
                            return Object.assign({}, item, {
                                start: parseInt(moment(item.start, "YYYY-MM-DD hh:mm:ss").format("x")),
                                end: parseInt(moment(item.end, "YYYY-MM-DD hh:mm:ss").format("x"))
                            })
                        })
                        allocatedTime.push(...hiddenAllocations);

                        let userAvailableTime = [];
                        allocatedTime.sort((a,b) => a.start - b.start);
                        // set available time to day where user is available
                        allocatedTime.forEach((time, index) => {
                            if (total <= 0) return;
                            
                            const startOfWeek = parseInt(moment(start).startOf('isoWeek').format('x'))
                            const startAtBeginningOfWeek = time.start <= startOfWeek;

                            // set available time to beginning of week if allocated time does not start at beginning of week
                            if (index === 0 && !startAtBeginningOfWeek) {
                                let value = ((moment(time.start).format('x')-moment(startOfWeek).format('x'))/60/60/1000)/multiplier;
                                value = total < value ? total : value;

                                if (value <= 0) return; // 2 items next to each other

                                userAvailableTime.push({
                                    id: `${week}-${year}-${user.id}-${items.length}-${index}`,
                                    user: user,
                                    group: 'available',
                                    value: value,
                                    week: weekNumber,
                                    title: `${user.user.name} ${this.convertTime(value)}`,
                                    start_time: startOfWeek,
                                    end_time: parseInt(moment(startOfWeek).add(value * multiplier, "hours").format("x")),
                                    canMove: isBeforeCurrWeek ? false : true,
                                    canResize: isBeforeCurrWeek ? false : true,
                                    canChangeGroup: isBeforeCurrWeek ? false : true,
                                })
                                total -= value;
                            }

                            // next item starts before current item ends
                            if (allocatedTime[index + 1] && time.end > allocatedTime[index + 1].start) return;

                            let availableTimeStart = startAtBeginningOfWeek ? startOfWeek : time.end;
                            let availableTimeEnd = allocatedTime[index + 1] 
                                ? allocatedTime[index + 1].start
                                : parseInt(moment(availableTimeStart).add(total*multiplier, 'hours').format('x'));

                            // allocated time starts at beginning of week
                            if (total > 0 && startAtBeginningOfWeek) {
                                let value = ((moment(availableTimeEnd).format('x')-moment(time.end).format('x'))/60/60/1000)/multiplier;
                                value = total < value ? total : value;
                                value = allocatedTime[index + 1] ? value : total
                                if (value <= 0) return;

                                userAvailableTime.push({
                                    id: `${week}-${year}-${user.id}-${items.length}-${index+1}`,
                                    user: user,
                                    group: 'available',
                                    value: value,
                                    week: weekNumber,
                                    title: `${user.user.name} ${this.convertTime(value)}`,
                                    start_time: time.end,
                                    end_time: parseInt(moment(time.end).add(value*multiplier, 'hours').format('x')),
                                    canMove: isBeforeCurrWeek ? false : true,
                                    canResize: isBeforeCurrWeek ? false : true,
                                    canChangeGroup: isBeforeCurrWeek ? false : true,
                                })
                                total -= value;
                            }

                            else if(total > 0) {
                                let value = ((moment(availableTimeEnd).format('x')-moment(availableTimeStart).format('x'))/60/60/1000)/multiplier;
                                value = total < value ? total : value;

                                if (value <= 0) return;

                                userAvailableTime.push({
                                    id: `${week}-${year}-${user.id}-${items.length}-${index+1}`,
                                    user: user,
                                    group: 'available',
                                    value: value,
                                    week: weekNumber,
                                    title: `${user.user.name} ${this.convertTime(value)}`,
                                    start_time: availableTimeStart,
                                    end_time: parseInt(moment(availableTimeStart).add(value*multiplier, 'hours').format('x')),
                                    canMove: isBeforeCurrWeek ? false : true,
                                    canResize: isBeforeCurrWeek ? false : true,
                                    canChangeGroup: isBeforeCurrWeek ? false : true,
                                })
                                total -= value;
                            }
                        })
                        items = items.concat(userAvailableTime);
                    }
                }
                else if (user.allocations && user.allocations.find(allocation => allocation.week === weekNumber))
                {
                    const hoursDaily = user.contract_detail.hours_daily ? parseFloat(user.contract_detail.hours_daily) : 8
                    const hoursWeekly = user.contract_detail.hours_weekly ? parseFloat(user.contract_detail.hours_weekly) : 40
                    let multiplier = parseFloat(24 / hoursDaily);
                    let total = 0;
                    user.allocations.forEach(allocation => {
                        let timeDifference = (moment(allocation.end).format('x')-moment(allocation.start).format('x'))/60/60/1000
                        let value = (timeDifference / multiplier)
                        total += value;
                    })
                    total = hoursWeekly - total;
                    let roundedTotal = total.toFixed(3);
                    if (roundedTotal > 0)
                    {
                        items.push({
                            id: `${week}-${year}-${user.id}-${items.length}`,
                            user: user,
                            group: 'available',
                            value: total,
                            week: weekNumber,
                            title: `${user.user.name} ${this.convertTime(total)}`,
                            start_time: parseInt(moment(start).startOf('isoWeek').format('x')),
                            // end_time: parseInt(moment(start).startOf('isoWeek').add(total, 'hours').format('x'))
                            end_time: parseInt(moment(start).startOf('isoWeek').add(total*multiplier, 'hours').format('x')),
                            canMove: isBeforeCurrWeek ? false : true,
                            canResize: isBeforeCurrWeek ? false : true,
                            canChangeGroup: isBeforeCurrWeek ? false : true,
                        })
                    }
                }
                else
                {
                    const contractEnd = user.contract_detail.contract_end;
                    const contractEndYear = moment(contractEnd).year();
                    const contractEndWeek = moment(contractEnd).week();

                    const contractHasEnded = !!contractEnd
                        && (year > contractEndYear
                        || (year <= contractEndYear && week > contractEndWeek));

                    if (!contractHasEnded) {
                        items.push({
                            id: `${week}-${year}-${user.id}`,
                            user: user,
                            group: 'available',
                            value: user.contract_detail.hours_weekly ? parseFloat(user.contract_detail.hours_weekly) : 40,
                            week: weekNumber,
                            title: `${user.user.name} ${user.contract_detail.hours_weekly ? user.contract_detail.hours_weekly : 40} h`,
                            start_time: parseInt(moment(start).startOf('isoWeek').format('x')),
                            end_time: parseInt(moment(start).startOf('isoWeek').add(daysWeekly, 'days').format('x')),
                            canMove: isBeforeCurrWeek ? false : true,
                            canResize: isBeforeCurrWeek ? false : true,
                            canChangeGroup: isBeforeCurrWeek ? false : true,
                        })
                    }
                }
            })
            start = start.add(1, 'week')
        }

        const timeEntries = [];
        const entryIds = [];
        this.state.projects.forEach(project => {
            project.works.forEach(work => {
                work.time_entries.sort((a ,b) => {
                    return new Date(a.date) - new Date(b.date);
                });
                work.time_entries.forEach(entry => {
                    if (!entryIds.includes(entry.id)) entryIds.push(entry.id);
                    else return;

                    if (entry.status != "open") {
                        const foundUser = this.state.personnel.find(person =>
                            person.id == entry.user_id
                        );
                        if (!foundUser) return;
                        
                        const foundEntry = work.progress_entries.find(p_entry => p_entry.entry_id == entry.id);
                        const doneEstimate = foundEntry 
                            ? foundEntry.progress
                            : work.doneEstimate;

                        const readiness_degree_txt = doneEstimate
                            ? `${tr('degree_of_readiness_estimate')}: ${doneEstimate}%`
                            : "";

                        const week = moment(entry.date).week();
                        const year = moment(entry.date).year();
                        
                        const hoursDaily = foundUser.contract_detail.hours_daily ? parseFloat(foundUser.contract_detail.hours_daily) : 8;
                        const multiplier = parseFloat(24 / hoursDaily);
                        // check if the same work continues next day with no other project in between
                        let lastItem = timeEntries.filter(entry =>
                            entry.user.id === foundUser.id
                            && entry.workId === work.id
                            && entry.group === project.id
                        ).at(-1);

                        if (lastItem 
                        && (lastItem.end_time == parseInt(moment(entry.date).startOf('day').format('x')) 
                            || lastItem.start_time == parseInt(moment(entry.date).startOf('day').format('x')))
                        && lastItem.isEntry 
                        && lastItem.group == project.id
                        && lastItem.workId == work.id) {
                            const newTime = lastItem.value + parseFloat(entry.total_hours);
                            lastItem.end_time = parseInt(moment(lastItem.end_time, 'x')
                                .add(entry.total_hours * multiplier, 'hours')
                                .format('x'));
                            lastItem.value = newTime;
                            lastItem.title = `${foundUser.user.name} ${this.convertTime(newTime)}, ${lastItem.workName} ${readiness_degree_txt}`;
                            lastItem.doneEstimate = doneEstimate;
                        } else {
                            timeEntries.push({
                                id: `${week}-${year}-${entry.id}-entry`,
                                user: foundUser,
                                group: project.id,
                                value: parseFloat(entry.total_hours),
                                week: `${week}-${year}`,
                                title: `${foundUser.user.name} ${this.convertTime(entry.total_hours)}, ${work.name} ${readiness_degree_txt}`,
                                start_time: parseInt(moment(entry.date).startOf('day').format('x')),
                                end_time: parseInt(moment(entry.date).startOf('day').add(entry.total_hours * multiplier, 'hours').format('x')),
                                canMove: false,
                                canResize: false,
                                canChangeGroup: false,
                                isEntry: true,
                                description: entry.description,
                                doneEstimate: doneEstimate,
                                workId: work.id,
                                workName: work.name,
                            })
                        }
                    }
                })
            })
        })
        // put time entry items on top of other items in group
        items.unshift(...timeEntries)
        this.setState({ 
            personnelItems: items,
         });
    }

    splitHoliday(holiday) {
        let items = [];
        let start = moment(holiday.start_date).format("YYYY-MM-DD");
        let end = moment(holiday.end_date).add(1, "day").format("YYYY-MM-DD");

        while (parseInt(moment(start).format("W")) != parseInt(moment(end).format("W"))) {
            items.push({
                start: moment(start).startOf("isoWeek").format("YYYY-MM-DD"),
                end: moment(start).startOf("isoWeek").add(5, "days").format("YYYY-MM-DD"),
                project_id: "holiday",
                week: `${moment(start).isoWeek()}-${moment(start).year()}`,
                user_id: holiday.user_id,
                name: holiday.name,
                isHoliday: true
            });
            start = moment(start).add(1, "week").format("YYYY-MM-DD");
        }
        if (end != start) {
            items.push({
                start: moment(start).format("YYYY-MM-DD"),
                end: moment(end).format("YYYY-MM-DD"),
                project_id: "holiday",
                week: `${moment(start).isoWeek()}-${moment(start).year()}`,
                user_id: holiday.user_id,
                name: holiday.name,
                isHoliday: true
            });
        }

        return items;
    }

    parseMarkers(projects)
    {
        let dateMarkers = [];

        projects.forEach(project => {
            if (dateMarkers.some(mark => mark['date'] === project.begin_date && mark.begins))
            {
                dateMarkers = dateMarkers.map(marker => {
                    if (marker.date === project.begin_date && marker.begins)
                    {
                        let newBegins = marker.begins
                        newBegins.push(project.name)
                        return (
                            Object.assign({}, marker, {
                                begins: newBegins,
                            })
                        )
                    }
                    else
                    {
                        return marker
                    }
                })
            }
            else
            {
                dateMarkers.push({
                    id: dateMarkers.length,
                    date: project.begin_date,
                    begins: [project.name]
                })
            }


            if (dateMarkers.some(mark => mark['date'] === project.end_date && mark.ends))
            {
                dateMarkers = dateMarkers.map(marker => {
                    if (marker.date === project.end_date && marker.ends)
                    {
                        let newEnds = marker.ends
                        newEnds.push(project.name)
                        return (
                            Object.assign({}, marker, {
                                ends: newEnds,
                            })
                        )
                    }
                    else
                    {
                        return marker
                    }
                })
            }
            else if (project.end_date)
            {
                dateMarkers.push({
                    id: dateMarkers.length,
                    date: project.end_date,
                    ends: [project.name]
                })
            }
            else
            {
                // this.setState({ continious: true });
            }
        })

        
        this.setState({ dateMarkers: dateMarkers});
        return dateMarkers;
    }

    calculateGroupValues(items)
    {
        const { personnelGroups } = this.state
        let groupValues = []
        let filtered = items.filter(item => 
            item.group !== 'available' 
            && item.group !== "holiday"
            && !item.isEntry
            && !moment().startOf("week").isAfter(item.start_time));

        filtered.forEach(item => {
            let foundGroup = groupValues.find(group => group['id'] === item.group)
            if (foundGroup)
            {
                let works;

                let filteredByWork = items.filter(it => it.work === item.work);
                let newWorkValue = 0
                filteredByWork.forEach(fItem => {
                    newWorkValue += fItem.value
                })

                if (foundGroup.works.some(work => work['workId'] === item.work))
                {
                    works = foundGroup.works.map(work => {
                        if (work.id === item.work)
                        {
                            return (
                                Object.assign({}, work, {
                                    value: newWorkValue,
                                })
                            )
                        }
                        else
                        {
                            return work
                        }
                    })
                }
                else if (item.work)
                {
                    works = foundGroup.works
                    works.push({
                        workId: item.work,
                        value: newWorkValue
                    })
                }

                let filteredByGroup = filtered.filter(it => it.group === item.group);
                let newGroupValue = 0
                filteredByGroup.forEach(fItem => {
                    newGroupValue += fItem.value
                })

                groupValues = groupValues.map(group => {
                    if (works && group.id === item.group)
                    {
                        return (
                            Object.assign({}, group, {
                                value: newGroupValue,
                                works: works
                            })
                        )
                    }
                    else if (group.id === item.group)
                    {
                        return (
                            Object.assign({}, group, {
                                value: newGroupValue,
                            })
                        )
                    }
                    else
                    {
                        return group
                    }
               
                })
            }
            else
            {
                let newWorks = []
                if (item.work)
                {
                    let filteredByWork = items.filter(it => it.work === item.work);
                    let newWorkValue = 0
                    filteredByWork.forEach(fItem => {
                        newWorkValue += fItem.value
                    })
                    newWorks.push({
                        workId: item.work,
                        value: newWorkValue
                    })
                }
                groupValues.push({
                    id: item.group,
                    value: item.value,
                    works: newWorks
                })
            }
        })

        let newWorks;
        let newGroups = personnelGroups.map(group => {
            let foundGroup = groupValues.find(val => val.id === group.id)
            if (foundGroup)
            {
                newWorks = group.works && group.works.map(work => {
                    let foundWork = foundGroup.works.find(fwork => fwork.workId === work.id)
                    if (foundWork)
                    {
                        return (
                            Object.assign({}, work, {
                                alloc_personnel: foundWork.value
                            })
                        )
                    }
                    return (
                        Object.assign({}, work, {
                            alloc_personnel: 0
                        })
                    )
                })
                return (
                    Object.assign({}, group, {
                        value: foundGroup.value,
                        works: newWorks
                    })
                )
            }
            else 
            {
                if (group.works)
                {
                    newWorks = group.works.map(work => {
                        return (
                            Object.assign({}, work, {
                                alloc_personnel: 0
                            })
                        )
                    })
                }
                return (
                    Object.assign({}, group, {
                        value: 0,
                        works: newWorks
                    })
                )
            }
        })

        let newFilteredItems;
        if (this.state.user !== 0)
        {
            this.state.personnel.forEach(person => {
                if (person.id === this.state.user)
                {
                    newFilteredItems = items.filter(item => item.user.id === person.id)
                }
            })
        }

        this.setState({ personnelItems: items, personnelGroups: newGroups, filteredPersonnelItems: newFilteredItems })
    }

    convertTime(value)
    {
        let decimalTime = parseFloat(value);
        decimalTime = decimalTime * 60 * 60;
        let hours = Math.floor((decimalTime / (60 * 60)));
        decimalTime = decimalTime - (hours * 60 * 60);
        let minutes = Math.round(decimalTime / 60);
        if (minutes === 60){
            hours += 1;
            minutes = 0;
        }
        if(hours < 10)
        {
            hours = "0" + hours;
        }
        if(minutes < 10)
        {
            minutes = "0" + minutes;
        }

        return `${hours}:${minutes}`
    }

    handleEditPersonnelItem(item, newGroup = null)
    {
        let editedPersonnelItems = [...this.state.editedPersonnelItems]
        let editedGroups = [...this.state.editedPersonnelGroups]

        if (!editedPersonnelItems.includes(item.id))
        {
            editedPersonnelItems.push(item.id);
        }

        if (item.group !== 'available' && !editedGroups.includes(item.group))
        {
            editedGroups.push(item.group);
        }

        if (newGroup && newGroup !== 'available' && !editedGroups.includes(newGroup))
        {
            editedGroups.push(newGroup);
        }

        this.setState({ unsavedChanges: true, editedPersonnelItems: editedPersonnelItems, editedPersonnelGroups: editedGroups });
    }

    handleDeletePersonnelItem(item)
    {
        let deletedPersonnelItems = [...this.state.deletedPersonnelItems]
        let editedGroups = [...this.state.editedPersonnelGroups]

        if (!deletedPersonnelItems.includes(item.db_id))
        {
            deletedPersonnelItems.push(item.db_id);
        }

        if (!editedGroups.includes(item.group))
        {
            editedGroups.push(item.group);
        }

        this.setState({ unsavedChanges: true, deletedPersonnelItems: deletedPersonnelItems, editedPersonnelGroups: editedGroups });
    }

    renderError()
    {
        const { loading, openPersonnelGroups, user, personnel } = this.state;

        if (!loading && personnel.length === 0)
        {
            return <div style={{padding: 10}}>{ tr('no_people_found_info') }</div>
        }
        else if (!loading && openPersonnelGroups.length === 1 && user === 0)
        {
            return <div style={{padding: 10}}>{ tr('no_projects_found') }</div>
        }
        else if (!loading && openPersonnelGroups.length === 1 && user !== 0)
        {
            return <div style={{padding: 10}}>{ tr('person_not_added_to_projects') }</div>
        }
        else
        {
            return null
        }
    }

    openAddProjectsModal()
    {
        this.setState({ showAddProjectsModal: true, assignments:false })
    }

    openAddAssignmentsModal()
    {
        this.setState({ showAddProjectsModal: true, assignments:true })
    }

    closeAddProjectsModal()
    {
        this.setState({ showAddProjectsModal: false })
    }

    closeHideConfirmModal()
    {
        this.setState({ showHideConfirmModal: false })
    }

    handleConfirmModal(project)
    {
        this.setState({
            selectedProject: project,
            showHideConfirmModal: true
        })
    }

    addProjects(projectIds)
	{
		this.setState({ loading: true });
        api({
            method: 'post',
			url: `project/management/addprojects`,
			data: {
                assignments: this.state.assignments,
				projectIds: projectIds,
			},
        }).then((response) => {
            this.setState({loading: false, user: 0});
            this.getData();
            window.emitter.emit('popper', {
                content: <strong>{ tr('save_successful') }</strong>,
                type: 'success'
            });
        }).catch((error) => {
            this.setState({loading: false});
            errorPopper('error', tr('save_error'))
        });
    }

    hideProject(projectId)
	{
        if (this.state.unsavedChanges) {
            errorPopper('error', tr('save_changes'));
            return;
        }
        const currentWeek = moment().week();
        const currentYear = moment().year();
        let week, year = '';
        const foundItem = this.state.personnelItems.find(item => {
            const [itemWeek, itemYear] = item.week.split('-');
            if(item.group == projectId && !item.isEntry) {
                if ((parseInt(itemWeek) >= currentWeek 
                && parseInt(itemYear) === currentYear) 
                || parseInt(itemYear) > currentYear) {
                    week = itemWeek;
                    year = itemYear;
                    return true;
                }
            }
        })
        if (foundItem) {
            errorPopper('error', tr('has_personnel', [week, year]))
        }
        else {
            this.setState({ loading: true });
            api({
                method: 'post',
                url: `project/management/hideproject`,
                data: {
                    projectId: projectId,
                },
            }).then((response) => {
                this.setState({loading: false});
                this.getData();
                window.emitter.emit('popper', {
                    content: <strong>{ tr('save_successful') }</strong>,
                    type: 'success'
                });
            }).catch((error) => {
                this.setState({loading: false});
                errorPopper('error', tr('save_error'))
            });
        }
    }
 
    render()
    {
        const { personnelGroups, personnelItems, filteredPersonnelItems, personnel, openPersonnelGroups, user, dateMarkers } = this.state
        const items = user === 0 ? personnelItems : filteredPersonnelItems

        return (
            <ApFormPage 
                onSave={ this.save } 
                loading={ this.state.loading } 
                unsaved={ this.state.unsavedChanges }
            >
 				<div className="apBoxHeader">
					<h1>{ tr('production_management') }</h1>
					<p>{ tr('production_management_info') }</p>
				
                    <div className="apBoxCorner">
                        <ApButton
                            color="green"
                            loading={this.state.loading}
                            onClick={this.openAddProjectsModal}
                        >
                            { tr('add_projects') }
                        </ApButton>

                        <ApButton
                            color="green"
                            loading={this.state.loading}
                            onClick={this.openAddAssignmentsModal}
                        >
                            { tr('add_assignments') }
                        </ApButton>
                    </div>
                </div>

                <div style={{maxWidth: 400, padding: 10}}>
                    { tr('filter_by_people') }:
                    <ApInputStack gap="0">
                        <ApAddon noRightBorder width="80px">
                            { tr('person') }
                        </ApAddon>
                        <ApInput 
                            type="select" 
                            id="user"
                            name="user" 
                            value={ this.state.user }
                            options={ this.getUserOptions() }
                            onChange={ (e) => this.onUserChange(e) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                </div>
        
                { items.length > 0 && openPersonnelGroups.length > 1 &&
                <Personnel
                    summary={ true }
                    groups={ personnelGroups }
                    items={ items }
                    allItems={ personnelItems }
                    loading={ this.state.loading }
                    personnel={ personnel }
                    openGroups={ openPersonnelGroups }
                    handleEditPersonnelItem={this.handleEditPersonnelItem}
                    handleDeletePersonnelItem={this.handleDeletePersonnelItem}
                    calculateGroupValues={this.calculateGroupValues}
                    convertTime={this.convertTime}
                    handleConfirmModal={this.handleConfirmModal}
                    dateMarkers={ dateMarkers }
                    onHideProject={this.hideProject}
                    continuous={this.state.continious}
                    projectsStart={this.state.projectsStart}
                    projectsEnd={this.state.projectsEnd}
                    history={this.props.history}
                />}

                { this.renderError() }

                <AddProjectsModal
					show={this.state.showAddProjectsModal}
                    onClose={this.closeAddProjectsModal}
                    addProjects={this.addProjects}
                    assignments={this.state.assignments}
                    projects={personnelGroups}
				/>

                <HideConfirmModal
					show={this.state.showHideConfirmModal}
                    onClose={this.closeHideConfirmModal}
                    selectedProject={this.state.selectedProject}
                    hideProject={this.hideProject}
				/>

            </ApFormPage>
        )
    }

}
export default PersonnelManagement;