import ApReactTable from 'common/ApReactTable/ApReactTable';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import { poStatusBoxColor } from 'modules/Storage/common/StorageHelpers';
import moment from 'moment';
import React, { Component } from 'react'
import autobind from "react-autobind";
import { formatCurrency, mapTree, tr } from 'services/Helpers/Helpers';
import api from 'services/Api/Api';

class ProjectOrderedComponents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            poData: [],
        };

        autobind(this);
    }

    componentDidMount() {
        if (this.props.purchaseOrders && this.props.purchaseOrders.length) {
            this.parsePurchaseOrders();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.purchaseOrders && prevProps.purchaseOrders.length != this.props.purchaseOrders.length) {
            this.parsePurchaseOrders();
        }
    }

    parsePurchaseOrders() {
        const poData = [];

        this.props.purchaseOrders.forEach(po => {
            if (!po.components.length) {
                return;
                // Tyhjät ostotilaukset
                // poData.push({
                //     poId: po.id,
                //     poNumber: po.number,
                //     poPrice: po.price,
                //     poStatusName: po.statusName,
                //     poStatus: po.statusText,
                //     cId: null,
                //     cName: '-',
                //     cCount: null,
                //     cPOPrice: null,
                //     cPOPriceTotal: null,
                //     cUnit: null,
                // });
            }
            else {
                po.components.forEach(component => {
                    let allocated_price = null;
                    mapTree(this.props.tree, item => {
                        if (!allocated_price) {
                            const found = item.components.find(c => c.component_id === component.component_id);
                            if (found) {
                                allocated_price = found.alloc_price_single;
                            }
                        }
                        return item;
                    });
                    poData.push({
                        poId: po.id,
                        poNumber: po.number,
                        poDate: po.date,
                        poDueDate: po.due_date,
                        poPrice: po.price,
                        poSupplier: po.supplier.name,
                        poStatusName: po.statusName,
                        poStatusId: po.status_id,
                        poStatus: po.statusText,
                        cId: component.component_id,
                        cName: component.name,
                        cCount: component.count,
                        cPrice: component.component.avg_price,
                        cAvgPrice: component.component.avg_price,
                        cLastPrice: component.component.last_price,
                        cAllocPrice: allocated_price,
                        cPOPrice: component.purchase_order_price,
                        cPOPriceTotal: component.purchase_order_price * component.count,
                        cUnit: component.unit,
                    });
                })
            }
        })

        this.setState({ poData });
    }

    getExcel(keys) {
        let selectedComponents = [];
        if (!keys.length) {
            selectedComponents = [...this.state.poData];
        } else {
            selectedComponents = this.state.poData.filter((po, index) => keys.includes(index));
        }

        const excelData = selectedComponents.map(component => {
            return {
                order_number: component.poNumber,
                date: component.poDate,
                due_date: component.poDueDate,
                status: tr(component.poStatus),
                supplier: component.poSupplier,
                po_total_price: Number(component.poPrice),
                component_name: component.cName,
                amount: component.cCount,
                supplier_price: Number(component.cPrice),
                average_purchase_price: Number(component.cAvgPrice),
                last_purchase_price: Number(component.cPrice),
                allocated_price: Number(component.cAllocPrice),
                component_po_price: Number(component.cPOPrice),
                component_price_total: Number(component.cPOPriceTotal)
            }
        });

        if (excelData.length === 0) {
            return;
        }

        this.setState({ loading: true });
        let title = `${this.props.tree[0].name}-${tr('purchase_orders')}`;
        if (title.length > 31) {
            title = title.substring(0, 31);
        }
        api({
            url: 'excel/generate',
            method: 'POST',
            data: {
                rows: excelData,
                title: title,
                sheetTitle: title,
            },
            responseType: 'blob',
            fileTitle: `${this.props.tree[0].name}-${tr('purchase_orders')}.xlsx`
        }).then(response => {
            this.setState({
                loading: false,
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                loading: false,
            });
        })
    }

    render() {
        return (
            <div className='padding'>
                <ApReactTable
                    loading={this.props.loading}
                    rememberId='PdProject_OrderedComponents'
                    data={this.state.poData}
                    multiselect={[
                        {
                            icon: 'file-excel',
                            label: tr('create_excel'),
                            action: this.getExcel,
                            unselectAfter: false,
                            closeAfter: true,
                            alwaysOn: true,
                        }
                    ]}
                    columns={[
                        {
                            Header: tr('purchase_order'),
                            customizable: true,
                            columns: [
                                {
                                    id: 'poNumber',
                                    Header: tr('order_number'),
                                    accessor: acc => Number(acc.poNumber),
                                    filterable: true,
                                    customFilter: {
                                        type: "text",
                                        placeholder: tr('order_number'),
                                    },
                                    Cell: props => props.original.poNumber,
                                },
                                {
                                    id: 'poDate',
                                    Header: tr('date'),
                                    accessor: 'poDate',
                                    filterable: false,
                                    customizable: true,
                                    Cell: props => props.original.poDate ? moment(props.original.poDate).format('L') : null,
                                },
                                {
                                    id: 'poDueDate',
                                    Header: tr('due_date'),
                                    accessor: 'poDueDate',
                                    filterable: false,
                                    customizable: true,
                                    Cell: props => props.original.poDueDate ? moment(props.original.poDueDate).format('L') : null,
                                },
                                {
                                    id: 'poStatusId',
                                    Header: tr('status'),
                                    accessor: acc => tr(acc['poStatus']),
                                    width: 125,
                                    filterable: true,
                                    customFilter: {
                                        type: 'select',
                                        placeholder: tr('status'),
                                        options: this.props.purchaseOrderStatusOptions
                                    },
                                    Cell: (props) => {
                                        return <div className={`apStatusBox ${poStatusBoxColor(props.original.poStatusName)}`}> {tr(props.original.poStatus)}</div>
                                    },
                                    customizable: true,
                                    showInitially: true
                                },
                                {
                                    id: 'poSupplier',
                                    Header: tr('supplier'),
                                    accessor: 'poSupplier',
                                    filterable: true,
                                    customizable: true,
                                    customFilter: {
                                        type: 'text',
                                        placeholder: tr('supplier'),
                                    }
                                },
                                {
                                    id: 'poPrice',
                                    Header: tr('po_total_price'),
                                    accessor: acc => Number(acc.poPrice), // muunnos numeroksi, jotta sorttaus menee oikein
                                    width: 150,
                                    filterable: false,
                                    customizable: true,
                                    showInitially: true,
                                    Cell: (props) => formatCurrency(props.original.poPrice)
                                },
                            ]
                        },
                        {
                            Header: tr('component'),
                            customizable: true,
                            columns: [
                                {
                                    id: 'cName',
                                    Header: tr('component'),
                                    accessor: 'cName',
                                    filterable: true,
                                    customFilter: {
                                        type: "text",
                                        placeholder: tr('component'),
                                    },
                                    showInitially: true
                                },
                                {
                                    id: 'cCount',
                                    Header: tr('ordered_quantity'),
                                    accessor: acc => Number(acc.cCount),
                                    filterable: false,
                                    width: 150,
                                    Cell: props => props.original.cCount ? `${props.original.cCount} ${props.original.cUnit || ''}` : null,
                                    className: 'align-right',
                                    customizable: true,
                                    showInitially: true
                                },
                                {
                                    id: 'cPrice',
                                    Header: tr('supplier_price'),
                                    accessor: acc => Number(acc.cPrice),
                                    filterable: false,
                                    Cell: (props) => props.original.cPrice && props.original.cCount ? formatCurrency(props.original.cPrice) : null,
                                    className: 'align-right',
                                    customizable: true,
                                    showInitially: true
                                },
                                {
                                    id: 'cAvgPrice',
                                    Header: tr('average_purchase_price'),
                                    accessor: acc => Number(acc.cAvgPrice),
                                    filterable: false,
                                    Cell: (props) => props.original.cAvgPrice && props.original.cCount ? formatCurrency(props.original.cAvgPrice) : null,
                                    className: 'align-right',
                                    customizable: true,
                                    showInitially: true
                                },
                                {
                                    id: 'cLastPrice',
                                    Header: tr('last_purchase_price'),
                                    accessor: acc => Number(acc.cLastPrice),
                                    filterable: false,
                                    Cell: (props) => props.original.cLastPrice && props.original.cCount ? formatCurrency(props.original.cLastPrice) : null,
                                    className: 'align-right',
                                    customizable: true,
                                    showInitially: true
                                },
                                {
                                    id: 'cAllocPrice',
                                    Header: tr('allocated_price'),
                                    accessor: acc => Number(acc.cAllocPrice),
                                    filterable: false,
                                    Cell: (props) => props.original.cAllocPrice && props.original.cCount ? formatCurrency(props.original.cAllocPrice) : null,
                                    className: 'align-right',
                                    customizable: true,
                                    showInitially: true
                                },
                                {
                                    id: 'cPOPrice',
                                    Header: tr('component_po_price'),
                                    accessor: acc => Number(acc.cPOPrice),
                                    filterable: false,
                                    Cell: (props) => props.original.cPOPrice && props.original.cCount ? formatCurrency(props.original.cPOPrice) : null,
                                    className: 'align-right',
                                    customizable: true,
                                    showInitially: true
                                },
                                {
                                    id: 'cPOPriceTotal',
                                    Header: tr('component_price_total'),
                                    // accessor: 'cPOPriceTotal',
                                    accessor: acc => Number(acc.cPOPriceTotal),
                                    filterable: false,
                                    width: 175,
                                    Cell: (props) => props.original.cPOPrice && props.original.cCount ? formatCurrency(props.original.cPOPriceTotal) : null,
                                    className: 'align-right',
                                    customizable: true,
                                    showInitially: true
                                },
                            ]
                        },
                    ]}
                    filterable
                    showFiltersInitially={true}
                    rowActions={(row) =>
                        <div className="apSimpleButton" onClick={() => this.props.history.push(`/storage/purchase/order/${row.poNumber}`)}>
                            <SvgIcon icon="arrow-right" type="solid" />
                        </div>
                    }
                />
            </div>
        )
    }
}

export default ProjectOrderedComponents;