/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import { ApChat, ApRoomSelect, ApChatHeader } from 'common/ApChat/ApChat.js';
import { errorPopper, tr } from 'services/Helpers/Helpers.js';
import moment from 'moment';
import auth from 'services/Authed/Authed.js';
import "./MainChat.css"
import Echo from 'laravel-echo';
import { connect } from 'react-redux';
import ReceiptReports from 'modules/Timetracking/Payroll/tabs/ReceiptReports';
// import Pusher from 'pusher-js';



class MainChat extends React.Component {

    constructor(props) {
        super(props);

        const params = this.props.match.params;
        this.state = {
            selectedChatRoom: null,
            messageListElement: null,
            messages: [],
            newMessageCounter: 0,
            paramRoomId: (params.roomId) ? params.roomId : null,
            rooms: [],
            privateUserIds: [],
            minified:false
        };



        this.lockGetOlderMessages = false;
        autoBind(this);
    };

    componentDidMount() {

        this.getRooms();
        let userId = auth.getID();
        if (window.Echo) {
            window.Echo.private("user." + userId).listen("UserUpdateChatRooms", (e) => {

                this.getRooms();
            });
        }


    }


    changeChatRoom(room) {

        //console.log(room);
        let rooms = this.state.rooms;
        if (this.state.selectedChatRoom) {

            let oneroom = rooms.find(val => val.id === this.state.selectedChatRoom.id);
            if (this.state.selectedChatRoom.draftMessage != null) {
                oneroom.draftMessage = this.state.selectedChatRoom.draftMessage;
            }

        }

        let oneroom = rooms.find(val => val.id === room.id);
        oneroom.unread = 0;


        this.setState({ selectedChatRoom: room.originalData, rooms: rooms, });
        this.sendLastRead(room.originalData.id, true);
        if (this.state.messageListElement && this.state.messageListElement.current) {
            this.state.messageListElement.current.toBottom();
        }
        this.props.history.replace("/chat/" + room.id);


    }
    sendLastRead(roomId, updateMessageCount = false) {
        api({
            method: 'post',
            url: 'chat/read',
            data: { room_id: roomId, count: updateMessageCount },
        }).then((response) => {


            //this.setState({ data: response });
            //this.props.edited(response);

            if (updateMessageCount) {
                //console.log("update")
                if (typeof (window.updateUnreadMessageCount) == 'function') {
                    window.updateUnreadMessageCount(response.count);
                }
            }
            //this.close();


        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
            errorPopper(error, tr('get_error'));
        });
    }

    messageChange(message) {
        let room = this.state.selectedChatRoom;
        room.draftMessage = message;
        this.setState({ selectedChatRoom: room });


        //console.log(message);
    }
    needOlderMessages(scroll) {
        //console.log(this.state.messageListElement.current.mlistRef.scrollTop);
        if (this.state.messageListElement.current.mlistRef.scrollTop <= 200 && this.state.selectedChatRoom.messages.length > 0 && this.lockGetOlderMessages == false) {
            this.lockGetOlderMessages = true;
            api({
                method: 'post',
                url: 'chat/getOlderMessages',
                data: { room_id: this.state.selectedChatRoom.id, time: this.state.selectedChatRoom.messages[0].created_at },
            }).then((response) => {
                let userId = auth.getID();
                let rooms = [...this.state.rooms];
                let room = rooms.find(val => val.id === this.state.selectedChatRoom.id);
                if (room.users.length == 2) {
                    room.title = room.users.find((user) => { return user.id != userId }).name;
                }
                for (let message of response) {
                    message.position = userId === message.user_id ? 'right' : 'left';
                    message.title = message.sender_name;
                    message.titleColor = this.idToColour(message.user_id);
                    message.date = new Date(message.date);
                    message.replyButton = true;
                    message.removeButton = message.user_id == userId;
                    if (message.reply) {
                        message.reply.message = message.reply.text;
                        message.reply.title = message.reply.sender_name;
                        message.reply.titleColor = this.idToColour(message.reply.user_id);
                    }
                    room.messages.unshift(message);

                }

                let scroll = this.state.messageListElement.current.mlistRef;
                let scrollPos = scroll.scrollHeight - scroll.scrollTop;
                //console.log(scroll.scrollHeight);
                rooms=this.sortRooms(rooms);
                this.setState({ rooms: rooms });
                scroll.scrollTop = scroll.scrollHeight - scrollPos;

                this.lockGetOlderMessages = false

            }).catch((error) => {
                this.setState({ loading: false });
                console.error(error);
                errorPopper(error, tr('get_error'));
            });
        }
    }


    componentWillUnmount() {
        let userId = auth.getID();
        if (window.Echo) {
            for (let room of this.state.rooms) {
                //console.log("unmounting", "chatRoom." + room.id);

                window.Echo.leave("chatRoom." + room.id);
            }
            window.Echo.private("user." + userId).stopListening("UserUpdateChatRooms");
        }
    }

    getRooms() {
        api({
            method: 'get',
            url: `chat/getRooms`,
        }).then((response) => {
            let userId = auth.getID();
            let privateUserIds = [];
            let currentRoomFinded = false;
            let NotFindedRoomIds = [];
            for (let room of response) {
                if (this.state.selectedChatRoom && room.id === this.state.selectedChatRoom.id) {
                    currentRoomFinded = true;
                }
                if (room.private == true) {

                    let findUser = room.users.find((user) => { return user.id != userId });
                    room.title = findUser.name;
                    privateUserIds.push(findUser.id);
                }

                for (let message of room.messages) {
                    message.position = userId === message.user_id ? 'right' : 'left';
                    message.title = message.sender_name;
                    message.titleColor = this.idToColour(message.user_id);
                    message.date = new Date(message.date);
                    message.replyButton = true;
                    message.removeButton = message.user_id == userId;
                    if (message.reply) {
                        message.reply.message = message.reply.text;
                        message.reply.title = message.reply.sender_name;
                        message.reply.titleColor = this.idToColour(message.reply.user_id);
                    }

                }
                if (window.Echo) {
                    
                    window.Echo.private("chatRoom." + room.id)
                        .listen("SendMessage", (e) => {
                            this.updateRoomMessages(e);
                        })
                        .listen("DeleteMessage", (message) => {
                            //console.log("deleteMessage", message)
                            this.deleteMessage(message, true);
                        });
                }
                


            }
            if (window.Echo) {

                for (let findRoom of this.state.rooms) {
                    if (!response.find(r => r.id === findRoom.id)) {
                        window.Echo.leave("chatRoom." + findRoom.id);
                    }
                }
            }

            if (!currentRoomFinded) {

                this.setState({ selectedChatRoom: null });
                this.props.history.replace("/chat");
            }

            response=this.sortRooms(response);
            this.setState({ rooms: response, privateUserIds: privateUserIds });


            if (this.state.paramRoomId) {
                let findRoom = this.state.rooms.find(val => val.id == this.state.paramRoomId);
                if (findRoom) {
                    this.setState({ rooms: response, selectedChatRoom: findRoom });
                    this.sendLastRead(this.state.paramRoomId, true);
                    if (this.state.messageListElement && this.state.messageListElement.current) {
                        this.state.messageListElement.current.toBottom();
                    }
                }

            }



        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
            errorPopper(error, tr('get_error'));
        });
    }

    getRoomMessages(roomId) {



        api({
            method: 'get',
            url: `chat/getRoomMessages/` + roomId,

        }).then((response) => {
            let rooms = [...this.state.rooms];
            //let selectedRoom = [...this.state.selectedChatRoom];
            //console.log("response", response);

            let room = rooms.find(val => val.id === this.state.selectedChatRoom.id);
            rooms=this.sortRooms(rooms);
            this.setState({ rooms: rooms, selectedChatRoom: room });


        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
            errorPopper(error, tr('get_error'));
        });
    }

    createMessageToList(message) {
        let userId = auth.getID();
        //console.log("message format", message);
        let returnMessage = {
            position: userId === message.user_id ? 'right' : 'left',
            type: message.type,
            text: message.text,
            photoURL: '',
            title: message.sender_name,
            titleColor: this.idToColour(message.user_id),
            status: message.status,
            date: new Date(message.date),
            room_id: message.room_id,
            id: message.id,
            forwarded: false,
            replyButton: true,
            removeButton: message.user_id == userId,
        };
        if (message.reply) {
            returnMessage.reply = {};
            returnMessage.reply.message = message.reply.text;
            returnMessage.reply.title = message.reply.sender_name;
            returnMessage.reply.titleColor = this.idToColour(message.reply.user_id);
        }
        return returnMessage;

    }


    updateRoomMessages(message) {

        let userId = auth.getID();
        //console.log("messages received", message);

        let rooms = [...this.state.rooms];
        let selectedRoom = this.state.selectedChatRoom;

        // for (let message of messages) {

        if (message.user_id === userId) {
            return;
        }

        let room = rooms.find(val => val.id === message.room_id);
        //console.log(this.createMessageToList(message))
        room.messages.push(this.createMessageToList(message));
        room.subtitle = message.text;

        if (selectedRoom && selectedRoom.id === message.room_id) {

            //selectedRoom.messages.push(this.createMessageToList(message));
        }
        else {
            room.unread++;
            this.playSound("https://assets.mixkit.co/sfx/preview/mixkit-message-pop-alert-2354.mp3");
        }




        // }
        if (selectedRoom) {
            this.sendLastRead(selectedRoom.id)
        }

        //let room = rooms.find(val => val.id === this.state.selectedChatRoom.id);

        let messageList = this.state.messageListElement;

        let scrollDown = false;
        if (messageList && messageList.current.state.scrollBottom < 50) {
            scrollDown = true;
        }
        rooms=this.sortRooms(rooms);
        this.setState({ rooms: rooms }, () => {
            if (scrollDown) {
                messageList.current.toBottom();
            }
        });




    }
    sortRooms(rooms) {
        rooms.sort((a, b) => {

            let x, y=0;
            if (a.messages.length > 0) {
                 x = a.messages[a.messages.length-1].date;
            }
            else {
                x = new Date(a.created_at);
            }
            if (b.messages.length > 1) {
                y = b.messages[b.messages.length-1].date;
            }
            else {
                y = new Date(b.created_at);
            }
            if (x > y) { return -1; }
            if (x < y) { return 1; }
            return 0;
        });
        return rooms;
    }

    playSound(url) {
        const audio = new Audio(url);
        audio.play();
    }


    sendMessage(message, ref, reply) {
        if (message === "") {
            return null;
        }
        let room = this.state.rooms.find(val => val.id === this.state.selectedChatRoom.id);
        if (room.messages == null) {
            room.messages = [];
        }
        let newMessageId = "new" + this.state.newMessageCounter;
        let userName = auth.getName();
        let userId = auth.getID();


        let messageData = {
            position: 'right',
            type: 'text',
            text: message,
            //avatar:
            //photoURL: 'https://facebook.github.io/react/img/logo.svg',
            title: userName,
            sender_name: userName,
            titleColor: this.idToColour(userId),
            status: "waiting",
            date: new Date(),
            room_id: room.id,
            id: newMessageId,
            replyButton: true,
            removeButton: true,


        };
        if (reply) {
            reply.message = reply.text;
            messageData.reply = reply;
        }
        room.messages.push(messageData);
        room.subtitle = messageData.text;
        let messageList = this.state.messageListElement;
        //console.log(messageList.current);
        let scrollDown = false;
        if (messageList.current.state.scrollBottom < 50) {
            scrollDown = true;
        }
        this.setState({ rooms: this.state.rooms }, () => {
            if (scrollDown) {
                messageList.current.toBottom();
            }
        });
        api({
            method: 'post',
            url: `chat/sendMessage`,
            data: { message: messageData },
        }).then((response) => {
            //console.log("response", response);
            let stateRooms = [...this.state.rooms];
            let roomdata = stateRooms.find(val => val.id === this.state.selectedChatRoom.id);

            let msg = roomdata.messages.find(val => val.id === response.oldId);
            msg.id = response.id;
            msg.status = response.status;
            stateRooms=this.sortRooms(stateRooms);
            this.setState({ loading: false, rooms: stateRooms, newMessageCounter: this.state.newMessageCounter + 1 });

        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
            errorPopper(error, tr('get_error'));
        });

        ref.clear();
    }
    removeMessage(message) {
        api({
            method: 'post',
            url: `chat/deleteMessage`,
            data: message,
        }).then((response) => {
            this.deleteMessage(message, false);

        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
            errorPopper(error, tr('get_error'));
        });



    }
    leaveRoom(room) {
        api({
            method: 'post',
            url: `chat/leaveRoom`,
            data: room,
        }).then((response) => {
            let rooms = [...this.state.rooms]
            rooms = rooms.filter(function (item) {
                return item.id !== room.id;
            });
            this.setState({ rooms: rooms, selectedChatRoom: null });
            this.props.history.replace("/chat");
            if (window.Echo) {
                window.Echo.leave("chatRoom." + room.id);
            }


        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
            errorPopper(error, tr('get_error'));
        });
    }
    deleteMessage(message, event = true) {
        let rooms = [...this.state.rooms];
        let room = rooms.find(val => val.id === this.state.selectedChatRoom.id);
        if (event || true) {
            room.messages = room.messages.filter(function (item) {
                return item.id !== message.id;
            });
        }
        else {
            room.messages.find(val => val.id === message.id).retracted = true;
        }

        this.setState({ rooms: rooms, });
    }

    stringToColour(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
            colour += (value.toString(16)).substr(-2);
        }
        return colour;
    }
    idToColour(id) {
        let colors = [
            "#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177", "#0d5ac1",
            "#f205e6", "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0",
            "#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00",
            "#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e",
            "#2f3f94", "#2f7b99", "#da967d", "#34891f", "#b0d87b", "#ca4751", "#7e50a8",
            "#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1", "#2f1179",
            "#935b6d", "#916988", "#513d98", "#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
            "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
            "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
            "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",
            "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234",
            "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
            "#fb21a3", "#51aed9", "#5bb32d", "#807fb", "#21538e", "#89d534", "#d36647",
            "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
            "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
            "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
            "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
            "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
            "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
            "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
            "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
            "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
            "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
            "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
            "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
            "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
            "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
            "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
            "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
            "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
            "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
            "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
            "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
            "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
            "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
            "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
            "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
            "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
            "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
            "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca"];

        let color = id % colors.length;
        return colors[color];
    }
    randomColor() {
        var randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return randomColor;
    }
    roomUsersEdited(data) {

        if (!data.roomId) {
            this.getRooms();
        }
        else {
            
            let rooms = this.state.rooms;
    
            let room = rooms.find(val => val.id === this.state.selectedChatRoom.id);
            room.users = data["users"];
            this.setState({ rooms: rooms, selectedChatRoom: room })
        }


    }
    getRoomsWithOnlineStatus() {
        let rooms = this.state.rooms;
        for (let room of rooms) {
            if (room.private) {
                let onlineUsers = this.props.onlineUsers ? (this.props.onlineUsers.users ? this.props.onlineUsers.users:[]):[];
                let id = room.users.find(user => user.id !== auth.getID());
                id = id ? id.id : null;
                //console.log("props",this.props)
                room.online = onlineUsers.find(user => user.id == id) ? true : false;
            }
        }
        return rooms;
    }
    toggleMinify() {
        this.setState({ minified: !this.state.minified })
    }


    render() {


        return (

            <div id="Chat">
                <div className={"roomSelect"+ (this.state.minified ? " chatSideNavMinified" : "" )}>
                    <ApRoomSelect
                        rooms={this.getRoomsWithOnlineStatus()}
                        selectedRoom={this.state.selectedChatRoom}
                        onChange={this.changeChatRoom}
                        groupChange={this.getRooms}
                        privateUserIds={this.state.privateUserIds}
                        minified={this.state.minified}
                        toggleMinify={this.toggleMinify}
                    />
                </div>
                <div className="chatArea">
                    {this.state.selectedChatRoom && <ApChatHeader
                        data={this.state.selectedChatRoom}
                        leaveRoom={this.leaveRoom}
                        edited={this.roomUsersEdited}
                    />}
                    {this.state.selectedChatRoom && <ApChat
                        messages={this.state.selectedChatRoom ? this.state.selectedChatRoom.messages : []}
                        messageChange={this.messageChange}
                        sendMessage={this.sendMessage}
                        setChatElement={(element) => {
                            this.setState({ messageListElement: element });
                            element.current.toBottom();
                        }}
                        removeMessage={this.removeMessage}
                        needOlderMessages={this.needOlderMessages}
                    />}
                </div>


            </div>



        );
    }
}

const mapStateToProps = state => ({
    onlineUsers: state.apCompanyOnlineUsers
});

export default connect(mapStateToProps)(MainChat);

