import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import ApFileUpload from 'common/ApFileUpload/ApFileUpload.js';
import { tr } from 'services/Helpers/Helpers.js';

export default class ExtranetDocuments extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {

		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		api({
			method: 'get',
			url: 'crm/extranet/getFilesAndBrochures'
		}).then((response) => {
		}).catch((error) => {
			console.log(error);
		});

	}

	renderDocuments()
	{

		const addButton = <ApFileUpload
			ref={ ref => this.fileUploadRef = ref }
			files={ this.state.newDocuments }
			onChange={ this.documentsChange }
			description="ExtranetDocument"
			noFilesLabel={ tr('add_documents') }
			addButtonLabel={`${tr('add_file')}...`}
		/>

		return <div>
				{ addButton }
			</div>;

	}

	renderBrochures()
	{

		const addButton = <ApFileUpload
			ref={ ref => this.fileUploadRef = ref }
			files={ this.state.newBrochures }
			onChange={ this.brochuresChange }
			description="ExtranetBrochure"
			noFilesLabel={ tr('add_brochures') }
			addButtonLabel={`${tr('add_file')}...`}
		/>

		return <div>
				{ addButton }
			</div>;

	}

	render()
	{

		return(

			<div className="apBox">

				<div className="apBoxHeader">
					<h1>{ tr('files') }</h1>
					<p>{ tr('extranet_settings_files_info') }</p>
				</div>

				<div className="clear padding">

					<div className="apColumn w50">
						<div className="padding">
							<h4 className="formHeader">{ tr('documents') }</h4>
							{this.renderDocuments()}
						</div>

					</div>

					<div className="apColumn w50">
						<div className="padding">
							<h4 className="formHeader">{ tr('brochures') }</h4>
							{this.renderBrochures()}
						</div>
					</div>

				</div>

			</div>

		);

	}

}