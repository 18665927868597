/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import auth from 'services/Authed/Authed.js';

import { keyExists, formatMoney, tr, capitalize } from  'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import Day from './Day/Day.js';

export default class Week extends React.Component {

    constructor(props) 
    {
        super(props);
        autoBind(this); 
    }

    getSetting( path, column, id, idColumn = "id" )
    {
        if( !this.props.settings )
            return "";

        const item = keyExists( this.props.settings, path, true, [] ).find( s => s[ idColumn ] == id );
        if( !item )
            return "";

        if( !column )
            return item;

        if( column in item )
            return item[ column ];

        return "";
    }

    renderOverview( data, weekly = false,daily=false )
    {
        let counts = {
            total_hours:       0,
            total_money:       0,
            total_distance:    0,
            total_allowance:   0,
            hours_regular:     0,
            hours_overtime:    0,
            hours_notation:    0,
            hours_travel:      0,
            hours_absence:     0,
            money_travel:      0,
            money_allowance:   0,
            money_expense:     0,
            timeAttendanceTime:0,
        };

        let hours = [];
        let travels = [];
        let expenses = [];
        let allowances = [];

        let storage = {
            installations:  [],
            chargeables:    [],
        };

        const addEntry = ( entry ) => {

            if( !entry.is_misentry || !weekly )
            {
                Object.keys( counts ).forEach( key => {
                    if (key == 'money_allowance') {
                        // counts[ key ] += parseFloat( entry[ key ]*entry[ 'total_allowance' ]);
                        counts[ key ] += isNaN(entry['money_allowance']) ? 0 : parseFloat( entry[ key ] );
                    }
                    else {
                        counts[ key ] += parseFloat( entry[ key ] || 0 );
                    }
                });   
            }

            keyExists( entry, "installations", true, [] ).forEach( i => {
                const pComp = keyExists( entry, "project.components", true, [] ).find( c => c.id == i.id );
                if( pComp )
                    storage.installations.push({
                        ...i,
                        name: pComp.name,
                        unit: keyExists( pComp, "component.unit", true ),
                    });
            });

            keyExists( entry, "chargeables", true, [] ).forEach( c => {
                const pExp = keyExists( entry, "project.expenses", true, [] ).find( e => e.id == c.id );
                if( pExp )
                    storage.chargeables.push({
                        ...c,
                        name: pExp.name,
                        unit: keyExists( pExp, "component.unit", true ),
                    });
            });

            if (entry.type === "hour" && keyExists(entry, "detail.rows")) {
                hours = hours.concat( entry.detail.rows );
                // if (entry.timeAttendanceTime!=null) {
                //     counts["timeAttendanceTime"] += entry.timeAttendanceTime;
                // }
            }

            if( entry.type === "travel" && keyExists( entry, "detail" ) ) {
                let selectedDetail = entry.detail

               /* if (entry.project.billing_travel_distance) {
                    selectedDetail.billing_travel_distance = entry.project.billing_travel_distance
                }

                if (entry.project.billing_travel_hours) {
                    selectedDetail.billing_travel_hours = entry.project.billing_travel_hours
                }*/

                travels.push( selectedDetail );
            }

            if( entry.type === "expense" && keyExists( entry, "detail" ) )
                expenses.push({
                    rows: entry.detail.rows,
                    files: entry.detail.files,
                });

            if( entry.type === "allowance" && keyExists( entry, "detail" ) )
                allowances.push( entry.detail );

        };


        if( weekly )
            Object.keys( data ).forEach( date => {
                data[ date ].forEach( entries => addEntry( entries ) );
            });
        else 
            addEntry( data );

        storage.total = storage.installations.length + storage.chargeables.length;
        
        // Add absence hours to total hours 
        counts.total_hours += counts.hours_absence;


        //Rounding... 
        counts.total_hours = Number(parseFloat(counts.total_hours).toFixed(3));
        counts.total_money = Number(parseFloat(counts.total_money).toFixed(3));
        counts.total_distance = Number(parseFloat(counts.total_distance).toFixed(3));
        counts.total_allowance = Number(parseFloat(counts.total_allowance).toFixed(3));
        counts.hours_regular = Number(parseFloat(counts.hours_regular).toFixed(3));
        counts.hours_overtime = Number(parseFloat(counts.hours_overtime).toFixed(3));
        counts.hours_notation = Number(parseFloat(counts.hours_notation).toFixed(3));
        counts.hours_travel = Number(parseFloat(counts.hours_travel).toFixed(3));
        counts.hours_absence = Number(parseFloat(counts.hours_absence).toFixed(3));
        counts.money_travel = Number(parseFloat(counts.money_travel).toFixed(3));
        counts.money_allowance = Number(parseFloat(counts.money_allowance).toFixed(3));
        counts.money_expense = Number(parseFloat(counts.money_expense).toFixed(3));
        counts.timeAttendanceTime = Number(parseFloat(counts.timeAttendanceTime).toFixed(3));


        const renderHourRow = ( h ) => {
            return <div key={ h.id }>{ this.getSetting( "hour_types", "name", h.hour_type_id ) }: <strong>{ h.hours } h </strong></div>;
        };
        const tooltipText = ( type ) => {

            if( type === "hours" && weekly )
                return (
                    <div>
                        <strong>{daily ? tr('daily_hours'):tr('weekly_hours') }: { counts.total_hours } h</strong><br />
                        {counts.hours_regular > 0 && <div>- {tr('normal_hours')}: {counts.hours_regular} h </div>}
                        {counts.timeAttendanceTime > 0 && <div>- {tr('time_attendance')}: {counts.timeAttendanceTime} h </div>}
                        { counts.hours_overtime > 0 && <div>- { tr('overtime_hours') }: { counts.hours_overtime } h </div> }
                        { counts.hours_travel > 0 && <div>- { tr('travel_hours') }: { counts.hours_travel } h </div> }
                        { counts.hours_absence > 0 && <div>- { tr('absences') }: { counts.hours_absence } h </div> }
                        { counts.hours_notation > 0 && 
                            <div>
                                <br />
                                <strong>{ tr('other_hours') }: </strong><br />
                                - { tr('fees_supplements') }: { counts.hours_notation } h<br />
                            </div>
                        }
                    </div>
                );

            if( type === "hours" && hours.length > 0 )
            {
                const notations = hours.filter( h => h.is_notation );
                return (
                    <div>
                        <strong>{ tr('working_hours') }: </strong>
                        { hours.filter( h => !h.is_notation ).map( renderHourRow ) }
                        { notations.length > 0 && 
                            <div>
                                <br />
                                <strong>{ tr('fees_supplements') }: </strong>
                                { notations.map( renderHourRow ) }
                            </div>
                        }
                        {counts.timeAttendanceTime > 0 && <div>{tr('time_attendance')}: {counts.timeAttendanceTime} h </div>}

                    </div>
                );
            }

            if( type === "hours" && counts.hours_absence > 0 )
                return <strong>{ tr('absences') }: { counts.hours_absence } h</strong>;
            
            if( type === "hours" && counts.hours_travel > 0 )
                return <strong>{ tr('travel_hours') }: { counts.hours_travel } h</strong>;
                
            if( type === "overtime" && weekly )
                return <strong>{ tr('weekly_overtime') }: { counts.hours_overtime } h</strong>;

            if( type === "overtime" && hours.filter( h => h.is_overtime ).length > 0 )
                return (
                    <div>
                        <strong>{ tr('overtime_hours') }: </strong>
                        { hours.filter( h => h.is_overtime ).map( renderHourRow ) }
                    </div>
                );

            if( type === "travelhours" && weekly )
                return <strong>{ tr('weekly_travel_hours') }: { counts.hours_travel } h</strong>;

            if( type === "traveldistance" && weekly )
                return <strong>{ tr('weekly_km') }: { counts.total_distance } km</strong>;
            
            if( ( type === "travelhours" || type === "traveldistance" ) && travels.length > 0 ) {
                let compensation = travels[0].compensation
                
                if (type === 'travelhours' && travels[0].compensation_hours) {
                    compensation = travels[0].compensation_hours
                }

                return (
                    <div>
                        { tr('route') }: <strong>{ travels[0].route }</strong><br />
                        { tr('vehicle') }: <strong>{ this.getSetting( "vehicles", "name", travels[0].vehicle_id ) }</strong><br />
                        { tr('compensation') }: <strong>{ compensation } { auth.getCurrencySign() }/{type === 'travelhours' ? 'h' : 'km'}</strong>
                    </div>
                );
            }
            /*
            if( type === "travel" && weekly )
                return (
                    <div>
                        <strong>Viikon matkat: { counts.total_distance } km</strong><br />
                        - Korvaukset: { formatMoney( counts.money_travel ) } { auth.getCurrencySign() }<br />
                        - Matkatunnit: { counts.hours_travel } h 
                    </div>
                );
            */

            if( type === "money" && weekly )
                return (
                    <div>
                        <strong>{ tr('weekly_expense_compensation') }: { formatMoney( counts.total_money ) } { auth.getCurrencySign() }</strong><br />
                        - { tr('travel_allowances') }: { formatMoney( counts.money_travel ) } { auth.getCurrencySign() }<br />
                        - { tr('expense_compensation') }: { formatMoney( counts.money_expense ) } { auth.getCurrencySign() }<br />
                        - { tr('daily_allowance_compensations') }: { formatMoney( counts.money_allowance ) } { auth.getCurrencySign() }<br />
                    </div>
                );

            if( type === "money" && expenses.length > 0 )
                return (
                    <div>
                        <strong>{ tr('expense_compensation') }:</strong><br />
                        { expenses.map( ( e, i ) => 
                            <div key={ i }>
                                { e.rows.map( r => 
                                    <div key={ r.id }>
                                        - { r.description }: { formatMoney( r.cost ) } { r.currency }
                                        { r.currency != auth.getCurrency() &&
                                            <small><br />({ tr('exchange_rate') }: { r.currency_rate } = { formatMoney( parseFloat( r.cost ) / parseFloat( r.currency_rate ) ) } { auth.getCurrency() } )</small>
                                        }
                                    </div>
                                )}
                                { tr('receipts_count') }: { e.files.length } { tr('pcs') }
                            </div>
                        )}

                    </div>
                );

            if( type === "money" && counts.money_travel > 0 )
                return <strong>{tr('travel_allowance') }: { formatMoney( counts.money_travel ) } { auth.getCurrencySign() }</strong>;

            if( type === "money" && counts.money_allowance > 0 )
                return <strong>{ tr('project_share_daily_allowance') }: { formatMoney( counts.money_allowance ) } { auth.getCurrencySign() }</strong>;

            if( type === "allowance" && weekly )
                return (
                    <div>
                        <strong>{ tr('weekly_daily_allowance') }: { Number(parseFloat(counts.total_allowance).toFixed(2)) } { tr('pcs') }</strong><br />
                        - { tr('compensations') }: { formatMoney( counts.money_allowance ) } { auth.getCurrencySign() }<br />
                    </div>
                );

            if( type === "allowance" && allowances.length > 0 )
                return (
                    <div>
                        <strong>{ this.getSetting( "allowances", "name", allowances[0].allowance_id ) }</strong><br />
                        { tr('at_time') } { allowances[0].start_time } - { allowances[0].end_time }<br />
                        { tr('compensation') }: { formatMoney( counts.money_allowance ) } { auth.getCurrencySign() }
                    </div>
                );

            /*
            if( type === "absence" && weekly )
                return (
                    <div>
                        <strong>Viikon poissaolot: { counts.hours_absence } h</strong>
                    </div>
                );
            */

            if( type === "storage" && weekly )
                return (
                    <div>
                        <strong>{ tr('weekly_other') }: { storage.total } { tr('pcs') }</strong><br />
                        - { tr('installed_components') }: { storage.installations.length } { tr('pcs') }<br />
                        - { tr('billable_expenses') }: { storage.chargeables.length } { tr('pcs') } <br />
                    </div>
                );

            if( type === "storage" && storage.total > 0 )
                return (
                    <div>
                        { storage.installations.length > 0 && 
                            <div>
                                <strong>{ tr('installed_components') }: </strong><br />
                                { storage.installations.map( i =>
                                    <div key={ i.id }>
                                        - { i.name }: { i.value } { i.unit } <small>({ i.time } min)</small>
                                    </div>
                                )}
                            </div>
                        }
                        { storage.chargeables.length > 0 && 
                            <div>
                                <strong>{ tr('billable_expenses') }: </strong><br />
                                { storage.chargeables.map( c =>
                                    <div key={ c.id }>
                                        - { c.name }: { c.value } { c.unit } <small>({ c.time } min)</small>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                );

            return false;
        };

        return (
            <div className={"overview " + (weekly ? "weekly" : "daily") + " " + (daily ? "dailySum" : "")}>
                {!this.props.old && daily &&
                    <div className="selectDay" onClick={() => this.props.onDaySelect(data[0][0].date, !this.props.daySelected(data[0][0].date))}>
                        <input
                            type="checkbox"
                            checked={this.props.daySelected?this.props.daySelected(data[0][0].date):false}
                            readOnly
                        />
                        {tr('select_day')}
                    </div>
                }
                <div>
                    <div className={ "block hours" + ( !counts.total_hours ? " empty" : "" ) }>
                        <ApTooltip position={weekly ? 'left' : 'top'} text={ tooltipText( "hours" ) }>
                            {weekly && <SvgIcon icon="clock" type="solid"
                                fill={
                                    (this.props.settings.time_attendance_warning_time!=null 
                                    && counts.timeAttendanceTime
                                    && Math.abs(counts.hours_regular - counts.timeAttendanceTime) >= this.props.settings.time_attendance_warning_time) ?
                                        "orange":null
                                }/> }
                            <span className="text"
                                style={{
                                    color: (this.props.settings.time_attendance_warning_time!=null 
                                    && !weekly && !daily && data.type === "hour" 
                                    && counts.timeAttendanceTime
                                    && Math.abs(counts.hours_regular - counts.timeAttendanceTime) >= this.props.settings.time_attendance_warning_time) ?
                                    "orange" : null
                                }}
                            >{counts.total_hours} h</span>
                        </ApTooltip>
                    </div>
                    <div className={ "block overtime" + ( !counts.hours_overtime ? " empty" : "" ) }>
                        <ApTooltip position={weekly ? 'left' : 'top'} text={ tooltipText( "overtime" ) }>
                            { weekly && <SvgIcon icon="clock" /> }
                            <span className="text">{ counts.hours_overtime } h</span>
                        </ApTooltip>
                    </div>
                    {/* 
                {/* 
                <div className={ "block absence" + ( !counts.hours_absence ? " empty" : "" ) }>
                    <ApTooltip text={ tooltipText( "absence" ) }>
                        { weekly && <SvgIcon icon="ban" type="solid" /> }
                        <span className="text">{ counts.hours_absence } h</span>
                    </ApTooltip>
                </div>
                */}
                <div className={ "block travel" + ( !counts.hours_travel ? " empty" : "" ) }>
                    <ApTooltip position={weekly ? 'left' : 'top'} text={ tooltipText( "travelhours" ) }>
                        { weekly && <SvgIcon icon="car" type="solid" /> }
                        <span className="text">{ counts.hours_travel } h</span>
                    </ApTooltip>
                </div>
                <div className={ "block travel" + ( !counts.total_distance ? " empty" : "" ) }>
                    <ApTooltip position={weekly ? 'left' : 'top'} text={ tooltipText( "traveldistance" ) }>
                        { weekly && <SvgIcon icon="road" type="solid" /> }
                        <span className="text">{ counts.total_distance } km</span>
                    </ApTooltip>
                </div>
                <div className={ "block expense" + ( !counts.total_money ? " empty" : "" ) }>
                    <ApTooltip position={weekly ? 'left' : 'top'} text={ tooltipText( "money" ) }>
                        { weekly && <SvgIcon icon="money-bill-wave" type="solid" /> }
                        <span className="text">{ formatMoney( counts.total_money ) } { auth.getCurrencySign() }</span>
                    </ApTooltip>
                </div>
                <div className={ "block allowance" + ( !counts.total_allowance ? " empty" : "" ) }>
                    <ApTooltip position={weekly ? 'left' : 'top'} text={ tooltipText( "allowance" ) }>
                        { weekly && <SvgIcon icon="suitcase" type="solid" /> }
                        <span className="text">{ Number(parseFloat(counts.total_allowance).toFixed(2)) } { tr('pcs') }</span>
                    </ApTooltip>
                </div>
                <div className={ "block storage" + ( !storage.total ? " empty" : "" ) }>
                    <ApTooltip position={weekly ? 'left' : 'top'} text={ tooltipText( "storage" ) }>
                        { weekly && <SvgIcon icon="cubes" type="solid" /> }
                        <span className="text">{ storage.total } { tr('pcs') }</span>
                    </ApTooltip>
                </div>
                </div>
            </div>
        );
    }

    render() 
    {
        return (
            <div className="week" key={ this.props.week }>

                <div className="weekName" style={{minWidth: 1200, padding: '15px 0'}}>
                    { capitalize(tr('week')) } { this.props.week.split("-")[ 1 ] } { this.props.old ? `(${this.props.week.split("-")[ 0 ]})` : null }

                    { !this.props.old &&
                    <div className="selectWeek" onClick={ () => this.props.onWeekSelect( this.props.week, !this.props.weekSelected ) }>
                        <input 
                            type="checkbox" 
                            checked={ this.props.weekSelected }
                            readOnly
                        />
                        { tr('select_week') }
                    </div>
                    }

                    { this.renderOverview( this.props.days, true ) }

                </div>

                { Object.keys( this.props.days ).map( date => {
                    return (
                        <Day
                            key={ date }
                            date={ date }
                            entries={ this.props.days[ date ] }
                            settings={ this.props.settings }
                            renderOverview={ this.renderOverview }
                            selectedEntries={ this.props.selectedEntries }
                            changes={ this.props.changes }
                            showReadOnly={ this.props.showReadOnly }
                            showSalaryExtras={ this.props.showSalaryExtras }
                            salaryExtras={ this.props.salaryExtras[ date ] }
                            statuses={ this.props.statuses }
                            onToggleReadOnly={ this.props.onToggleReadOnly }
                            onToggleStatus={ this.props.onToggleStatus }
                            onEntrySelect={ this.props.onEntrySelect }
                            onEditComment={ this.props.onEditComment }
                            old={this.props.old}
                        />
                    );
                })}

            </div>
        );
    }
}
