import React from 'react'
import './ApRadioSwitch.css'

const ApRadioSwitch = (props) => {
    if (props.disabled) return null

    return (
        <div className='radio-switch-container'>
            {props.options.map((option) => {
                return (
                <div key={`radio-switch-${option}`} className='label-container' onClick={() => {props.onClick(option)}}>
                    <input type='radio'
                        className='radio-switch'
                        name={option}
                        value={option}
                        checked={props.checked === option}
                        //onChange={props.onChange}
                    />
                    <span className={props.checked === option ? 'radio-switch-checked' : 'radio-switch-check-button'}>
                        <span className={props.checked === option ? 'radio-switch-inner-checked' : null} />
                    </span>
                    <label 
                        htmlFor={option}
                        className={props.checked === option ? 'radio-switch-label-checked' : 'radio-switch-label'}
                    >{option}</label>
                </div>)
            })}
        </div>
    )
}

export default ApRadioSwitch