import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import ApReactTable	from 'common/ApReactTable/ApReactTable.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import Authed from 'services/Authed/Authed.js';
import config from 'env.js';
import { tr } from 'services/Helpers/Helpers.js';

export default class ExtranetUserTable extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {

			loading: false,
			contacts: [],
			redirect: false,
			redirectToContactId: null

		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		//console.log(this.props.customerId);

		this.getContacts();

	}

	getContacts()
	{

		this.setState({ loading: true });

		api({
			method: 'get',
			url: 'crm/extranet/getContacts',
			params: { customerId: this.props.customerId }
		}).then((response) => {
			//console.log(response);
			this.setState({ contacts: response, loading: false });
		}).catch((error) => {
			console.log(error);
			this.setState({ loading: false });
		});

	}

	showContact(contactId)
	{

		const params = {
			customerId: this.props.customerId,
			contactId: contactId
		}
		this.props.editUser(params);

	}	

	switchContactStatus(contactId)
	{

		this.setState({ loading: true });

		api({
			method: 'post',
			url: 'crm/extranet/switchContactStatus',
			data: { contactId: contactId }
		}).then((response) => {
			this.getContacts();
		}).catch((error) => {
			console.log(error);
		});

	}

	getStatusIcon(status, id, type)
	{

		let element = null;

		if (status)
		{
			element = 
				<div style={{textAlign: 'center'}}>
					<SvgIcon fill="#4c0" icon="check-circle" onClick={() => {this.switchContactStatus(id)}} />
				</div>
		}
		else
		{
			element = 
				<div style={{textAlign: 'center'}}>
					<SvgIcon fill="#c40" icon="times-circle" onClick={() => {this.switchContactStatus(id)}} />
				</div>
		}

		return element;

	}

	loginToExtranetAs(id)
	{

		if (config.api.extraUrl !== undefined)
		{

			api({
				method: 'post',
				url: 'crm/extranet/registerTempToken',
				data: {
					userId: id,
					token: Authed.getToken()
				},
                errorPopper: 'Kirjautuminen ei onnistunut',
			}).then((response) => {

				const userId = response.crm_contact_id;
				const token = response.token;

				let url = config.api.extraUrl + "/";
				url += "?userId=" + userId + "?token=" + token;

				window.open(url);

			}).catch((error) => {

			});
						
		}
        else
        {
            console.error('Extranet url not defined')
        }

	}

	render()
	{

		return(

			<div>

				<ApReactTable
					data={this.state.contacts}
					columns={[
						{
							Header: () => (
								<p> { tr('customers_extranet_accounts') } </p>
							),
							columns: [
								{
									id: 'name',
									Header: tr('name'),
									accessor: "first_name",
									Cell: (props) => {
										return (
											<div>
												{props.original.first_name + " " + props.original.last_name}
											</div>
										);
									}
								},
								{
									id: 'extranet_active',
									Header: tr('active'),
									accessor: 'extranet_active',
									Cell: (props) => {
										return (
											<div>
												{this.getStatusIcon(props.original.extranet_active, props.original.id, 'ifActive')}
											</div>
										);
									}
								},
								{
									id: 'username',
									Header: tr('username'),
									accessor: 'extranet_username',
									Cell: (props) => {
										return (
											<div>
												{props.original.extranet_username}
											</div>
										);
									}									
								}
							]
						}
					]}
					rowActions={[
						{
							icon: "arrow-right",
							label: tr('view_contact_info'),
							action: (id) => { this.showContact(id) }
						},
						{
							icon: "share-square",
							label: tr('login_extranet'),
							action: (id) => { this.loginToExtranetAs(id) }
						}
					]}
					
				/>

				{/*
				rowActions={ (row) => (
					<div className="apSimpleButton" onClick={ () => this.showContact(row) }>
						<SvgIcon icon="arrow-right" type="solid" />
					</div>
				)}
				*/}


			</div>

		);		

	}

}
