import React from 'react';
import autoBind from 'react-autobind';

import auth from 'services/Authed/Authed.js';

import { ApInput } from 'common/ApInput/ApInput.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import { removeRoundOff
       , profitPercentToSellPrice
       , priceToProfitPercent
       , onlyNumber
       , tr }   from 'services/Helpers/Helpers.js';

import './PriceInputs.css';
import { connect } from 'react-redux';

class PriceInputs extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            currencySign: auth.getCurrencySign(),
            percentSign: ' %',
            //discountprice: 0
        }

        autoBind(this);
    }

    componentDidMount()
    {
        let price_sell = profitPercentToSellPrice( this.props.price , this.props.profitPercent);
        this.props.onChange({
            priceSell: price_sell,
        });
    }

    handleOrderPriceChange(projectSellPrice =null) {
        if (!projectSellPrice) {
            projectSellPrice = this.props.price_sell;
        }
        
        let order_price = projectSellPrice;
        if (this.props.order_price_type==2) {
            order_price = this.getSupplierPriceWithoutDiscount();
        }
        if (this.props.order_price_type==3) {
            order_price = this.getSupplierPriceWithoutDiscount() / ( 1 - (this.props.profitPercent / 100 ));
        }
        if (this.props.order_price_type==4) {
            order_price=  this.props.price / ( 1 - (this.props.order_price_min_profit / 100 ));
        }
        if (this.props.order_price_type==5) {
            let minProfit=this.props.price*(this.props.order_price_min_profit/100);
            let buyPrice= (this.props.price_sell* ((100-this.props.profitPercent)/100));
            if (this.props.price >= (buyPrice+minProfit)) {
                order_price= this.props.price;
            }
            else {
                order_price= buyPrice / ( 1 - (this.props.order_price_min_profit / 100 ));
            }
        }
        if (this.props.order_price_type==6) {
            if (this.props.profitPercent>=this.props.order_price_min_profit) {
                order_price= projectSellPrice;
            }
            else {
                order_price= (projectSellPrice* ((100-this.props.profitPercent)/100)) / ( 1 - (this.props.order_price_min_profit / 100 ));
            } 
        }
        if (order_price==0) {
            order_price=this.props.price_sell;
        }
        return order_price;

    }
    
    renderOnlineStorePrice() {
        let order_price=this.props.price_sell;
        if (this.props.apOrderSettings.online_store_product_price==2) {
            order_price= this.props.price?this.props.price:'';
        }
        if (this.props.apOrderSettings.online_store_product_price==3) {
            order_price= this.props.price / ( 1 - (this.props.profitPercent / 100 ));
        }
        if (this.props.apOrderSettings.online_store_product_price==4) {
            order_price=  this.props.price / ( 1 - (this.props.apOrderSettings.online_store_profit_percent / 100 ));
        }
        if (this.props.apOrderSettings.online_store_product_price==5) {
            let minProfit=this.props.price*(this.props.apOrderSettings.online_store_profit_percent/100);
            let buyPrice= (this.props.price_sell* ((100-this.props.profitPercent)/100));
            if (this.props.price >= (buyPrice+minProfit)) {
                order_price= this.props.price;
            }
            else {
                order_price= buyPrice / ( 1 - (this.props.apOrderSettings.online_store_profit_percent / 100 ));
            }
        }
        if (this.props.apOrderSettings.online_store_product_price==6) {
            if (this.props.profitPercent>=this.props.apOrderSettings.online_store_profit_percent) {
                order_price= this.props.price_sell;
            }
            else {
                order_price= (this.props.price_sell* ((100-this.props.profitPercent)/100)) / ( 1 - (this.props.apOrderSettings.online_store_profit_percent / 100 ));
            } 
        }
        if (order_price==0) {
            order_price=this.props.price_sell;
        }
        return order_price;

    }

    handlePriceChange( value, blur = false )
    {
        let price = onlyNumber( value );
        let price_sell = this.props.price_sell;
        let profitPercent = this.props.profitPercent;

        if( !profitPercent ) profitPercent = 0;

        price_sell = profitPercentToSellPrice(price, profitPercent);
        
        this.props.changeState({
            price: price,
            price_sell: price_sell,
        }, () => {
            this.props.onChange({
                // price: price,
                // price_sell: price_sell,
                order_price: this.handleOrderPriceChange(),
                edited: true,
            //profitPercent: profitPercent,
            });
        });
        // this.props.onChange({
        //     price: price,
        //     price_sell: price_sell,
        //     order_price: this.handleOrderPriceChange(),
        //     edited:true,
        //     //profitPercent: profitPercent,
        // });
    }

    handleLastPriceChange( value, blur = false )
    {
        let lastprice = onlyNumber( value );

        this.props.onChange({
            lastprice: lastprice,
            edited: true,
        });
    }

    handleAvgPriceChange( value, blur = false )
    {
        let avgprice = onlyNumber( value );

        this.props.onChange({
            avgprice: avgprice,
            edited: true,
        });
    }

    getSupplierPriceWithoutDiscount() {
        let price = 0;
        if (this.props.supplier) {
            price = this.props.supplier.purchase_price;
        }
        else {
            price = this.props.price;
        }
        return price;
    }

    getPriceBySettings() {

        if (this.props.component_type_id !== 1) {
            return this.props.price;
        }
        let price = 0;
        if (this.props.project_price_type == 1) {
            price = this.props.price;
        }
        if (this.props.project_price_type == 2) {
            price = this.props.lastprice ? this.props.lastprice : this.props.price;
        }
        if (this.props.project_price_type == 3) {
            price = this.props.avgprice ? this.props.avgprice : this.props.price;
        }
        if (this.props.project_price_type == 4) {
            price = this.getSupplierPriceWithoutDiscount();

        }
        // console.log('price', price);
        return price;
    }

    handleProfitChange( value, blur = false )
    {
        let price = this.getPriceBySettings();
        let price_sell = this.props.price_sell;
        let profitPercent = onlyNumber( value, true );

        if( parseFloat( profitPercent ) > 99.999999 )
        {
            window.emitter.emit('popper', {
                type: 'warning',
                content: <strong>{ tr('gross_profit_over_99_warning') }</strong>
            });
            profitPercent = 99.999999;
        }

        if( price )
            //priceSell = removeRoundOff( profitPercentToSellPrice( price, profitPercent ));
            price_sell = profitPercentToSellPrice( price,  profitPercent);

        this.props.changeState({
            profitPercent: profitPercent,
            price_sell: price_sell,
        }, () => {
            this.props.onChange({
                //price: price,
                // price_sell: price_sell,
                // profitPercent: profitPercent,
                order_price: this.handleOrderPriceChange(),
                edited: true,
            });
        });
    }

    handlePriceSellChange( value, blur = false )
    {
        const price = this.props.price;
        let price_sell = onlyNumber( value, true );
        let profitPercent = this.props.profitPercent;

        if( price_sell && price )
        {
            profitPercent = removeRoundOff( priceToProfitPercent( this.props.price, price_sell ) );

            if( !profitPercent ) profitPercent = '0';
        }

        this.props.changeState({
            price_sell: price_sell,
            profitPercent: profitPercent,
        }, () => {
            this.props.onChange({
                // price_sell: price_sell,
                // profitPercent: profitPercent,
                order_price: this.handleOrderPriceChange(),
                edited: true,
            });
        });

        // this.props.onChange({
        //     price_sell: price_sell,
        //     profitPercent: profitPercent,
        //     order_price: this.handleOrderPriceChange(),
        //     edited: true,
        // });
    }

    render()
    {
        const type = this.props.typeName;

        const priceInput = {
            id: 'newComponentPrice',
            name: 'price',
            label: tr('cost_price'),
            value: this.props.price ? this.props.price : '',
            unit:  this.state.currencySign,
            noFocusDecimals: 2,
            onChange: ( e ) => this.handlePriceChange( e.target.value ),
            onBlur:   ( e ) => this.handlePriceChange( e.target.value, true ),
        };

        const lastInput = {
            id: 'newComponentLastPrice',
            name: 'lastprice',
            label: tr('last_purchase_price'),
            value: this.props.lastprice ? this.props.lastprice : '',
            unit: '€',
            noFocusDecimals: 2,
            disabled: true,
            //onChange: ( e ) => this.handleLastPriceChange( e.target.value ),
            //onBlur:   ( e ) => this.handleLastPricetChange( e.target.value, true ),
        };

        const avgInput = {
            id: 'newComponentAvgPrice',
            name: 'avgprice',
            label: tr('average_purchase_price'),
            value: this.props.avgprice ? this.props.avgprice : '',
            unit: '€',
            noFocusDecimals: 2,
            disabled: true,
            //onChange: ( e ) => this.handleAvgPriceChange( e.target.value ),
            //onBlur:   ( e ) => this.handleProfitChange( e.target.value, true ),
        };

        const profitInput = {
            id: 'newComponentProfit',
            name: 'profit',
            label: tr('gross_profit_percent'),
            value: this.props.profitPercent ? this.props.profitPercent : '',
            unit: '%',
            noFocusDecimals: 1,
            onChange: ( e ) => this.handleProfitChange( e.target.value ),
            onBlur:   ( e ) => this.handleProfitChange( e.target.value, true ),
        };
        let priceSellInput;
        if (this.props.order_price_type!=1) {
            priceSellInput = {
                id: 'newComponentSellPrice',
                name: 'price_sell',
                label: tr('project_selling_price'),
                value: this.props.price_sell ? this.props.price_sell : '',
                unit:  this.state.currencySign,
                noFocusDecimals: 2,
                onChange: ( e ) => this.handlePriceSellChange( e.target.value ),
                onBlur:   ( e ) => this.handlePriceSellChange( e.target.value, true ),
            };
        }
        else {
            priceSellInput = {
                id: 'newComponentSellPrice',
                name: 'price_sell',
                label: tr('selling_price'),
                value: this.props.price_sell ? this.props.price_sell : '',
                unit:  this.state.currencySign,
                noFocusDecimals: 2,
                onChange: ( e ) => this.handlePriceSellChange( e.target.value ),
                onBlur:   ( e ) => this.handlePriceSellChange( e.target.value, true ),
            };
        }


        const orderPriceInput = {
            id: 'newComponentOrderPrice',
            name: 'order_price',
            label: tr('selling_price_in_offers'),
            value: this.props.order_price ? this.props.order_price : '',
            unit:  this.state.currencySign,
            noFocusDecimals: 2,
            disabled: true,
            //onChange: ( e ) => this.handlePriceSellChange( e.target.value ),
            //onBlur:   ( e ) => this.handlePriceSellChange( e.target.value, true ),
        };

        let online_store_price = null;
        if (this.props.apOrderSettings.woocommerce_enabled) {
            online_store_price = {
                id: 'onlineStoreComponentOrderPrice',
                name: 'order_price',
                label: tr('selling_price_in_online_store'),
                value: this.renderOnlineStorePrice(),
                unit:  this.state.currencySign,
                noFocusDecimals: 2,
            };
        }

        let rows = [];
        if( type === 'item' )
        {
            if (this.props.order_price_type!=1) {
                if (this.props.new) {
                    rows = [{
                        input: { ...priceInput, label: tr('supplier_price') },
                        info: tr('supplier_price'),
                    } , {
                        input: profitInput,
                        info: tr('storage_component_item_profit_info'),
                    } , {
                        input: priceSellInput,
                        info: tr('storage_component_project_pricesell_info'),
                    }];
                } else {
                    rows = [{
                        input: { ...priceInput, label: tr('supplier_price') },
                        info: tr('supplier_price'),
                    } , {
                        input: lastInput,
                        info: tr('last_purchase_price'),
                    } , {
                        input: avgInput,
                        info: tr('storage_component_item_avg_info'),
                    } , {
                        input: profitInput,
                        info: tr('storage_component_item_profit_info'),
                    } , {
                        input: priceSellInput,
                        info: tr('storage_component_project_pricesell_info'),
                    } , {
                        input: orderPriceInput,
                        info: tr('storage_component_settings_pricesell_info'),
                    }];
                    if (auth.hasModule('sales.onlinestore') && online_store_price) {
                        rows.push({
                            input: online_store_price,
                            info: tr('online_store_price_sell_info'),
                        })
                    }
                }
            }
            else {
                rows = [{
                    input: { ...priceInput, label: tr('supplier_price') },
                    info: tr('supplier_price'),
                } , {
                    input: lastInput,
                    info: tr('last_purchase_price'),
                } , {
                    input: avgInput,
                    info: tr('storage_component_item_avg_info'),
                } , {
                    input: profitInput,
                    info: tr('storage_component_item_profit_info'),
                } , {
                    input: priceSellInput,
                    info: tr('storage_component_pricesell_info'),
                }];
            }
        }
        else if( type === 'work' )
        {
            rows = [{
                input: priceInput,
                info: tr('storage_component_work_price_info'),
            } , {
                input: profitInput,
                info: tr('storage_component_work_profit_info'),
            } , {
                input: priceSellInput,
                info: tr('storage_component_pricesell_info'),
            }];

        }
        else if( type === 'other' )
        {
            rows = [{
                input: priceInput,
                info: tr('storage_component_other_price_info'),
            } , {
                input: profitInput,
                info: tr('storage_component_other_profit_info'),
            } , {
                input: priceSellInput,
                info: tr('storage_component_pricesell_info'),
            }];

            // rows = [{
                // input: profitInput,
                // info: `Määrittää kulujen oletuskatteen tarjouslaskennassa ja myyntitilauksissa.`,
            // }];
        }

        const getInput = ( data ) => {

            let unitDom = null;

            if( data.value )
                unitDom = <div className="unit">{ data.unit }</div>
            else
                data.label = `${ data.label } ${ data.unit }`;

            return <div className="valueInputContainer">
                <ApInput
                    disabled={ this.props.loading }
                    {  ...data }
                    className="valueInput"
                    type="text"
                    loading={ this.props.loading }
                    autoComplete="off"
                />
                { unitDom }
            </div>
        };

        const inputDoms = rows.map( ( row, index ) => {
            return <div key={ index } className="clear">
                <div className="apColumn w30">
                    { getInput( row.input ) }
                </div>
                <div className="apColumn w70 infoCol">
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        { row.info }
                    </div>
                </div>
            </div>
        });

        return <div className="priceInputs">
            { inputDoms }
        </div>
    }
}

const mapStateToProps = (state) => ({
    apOrderSettings: state.apOrderSettings
});

export default connect(mapStateToProps)(PriceInputs);

