import React from 'react';
import autoBind from 'react-autobind';

import { ApTabs, ApTab } from '../../../common/ApTabs/ApTabs.js';
import api from 'services/Api/Api.js';
import auth from   'services/Authed/Authed.js';
import { hasPermissions, tr } from 'services/Helpers/Helpers.js';

import UserFlextime from './tabs/UserFlextime.js';
import OthersFlextime from './tabs/OthersFlextime.js';
import FlextimeReport from './tabs/FlextimeReport.js';

class Flextime extends React.Component {

	constructor( props ) 
	{
		super(props);
        this.state = {
            settings: [],
            loading: false
        };
        autoBind(this);
    }

    componentDidMount()
    {
        this.getSettings();
    }
    
    getSettings( )
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'timetracking/flextime/personalsettings',
        }).then((response) => {
            //console.log(response)
            this.setState({
                settings: response,
                loading: false
            });
        }).catch((error) => {
            console.log('Error', error);
            this.setState({ loading: false });
        });
    }

    render() 
    {
        return (
            <div className="apBox">
            
                <div className="apBoxHeader">
            	   <h1>{ tr('flexitime_count') }</h1>
                </div>

                <ApTabs>

                    <ApTab icon="user" label={ tr('own') }>
                        <UserFlextime 
                            loading={this.state.loading}
                            settings={this.state.settings}
                        />
                    </ApTab>
                    
                    { hasPermissions('timetracking.holiday.flextime.subordinates') && 
                    <ApTab icon="user-friends" label={ tr('subordinates') }>
                        <OthersFlextime
                            subordinates={true}
                            getSettings={this.getSettings}
                        />
                    </ApTab>
                    }
                    
                    { hasPermissions('timetracking.holiday.flextime.master') && 
                    <ApTab icon="users" label={ tr('personnel') }>
                        <OthersFlextime
                            getSettings={this.getSettings}
                        />
                    </ApTab>
                    }
                    
                    { hasPermissions('timetracking.holiday.flextime.master') && 
                    <ApTab icon="chart-pie" label={ tr('report') } mountOnSelect>
                        <FlextimeReport />
                    </ApTab>
                    }

                </ApTabs>

            </div>
        );

    }
}
export default Flextime
