import React from 'react';
import autoBind from 'react-autobind';

import auth                 from 'services/Authed/Authed.js';
import api                  from 'services/Api/Api.js';
import { keyExists, tr }        from 'services/Helpers/Helpers.js'

import SvgIcon              from 'common/SvgIcon/SvgIcon.js';
import ApSelect           from 'common/ApSelect/ApSelect.js';
import ModulesTree    		from 'common/ModulesTree/ModulesTree.js';

class Permissions extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
        	loading: false,
			userGroups: [],
        };
        autoBind(this);
    }

    componentDidMount()
    {
        this.getUsersGroups();
    }

    getUsersGroups()
    {
    	this.setState({ loading: true });
        api({
            method: 'get',
            url: 'usermanagement/groups/getGroups',
        }).then((response) => {
            this.setState({ 
            	userGroups: response,
            	loading: false
            });
        }).catch((error) => {
            //errorPopper( error, 'Käyttäjäryhmien hakeminen ei onnistunut' );
            this.setState({ error });
        });
    }

    render()
    {
    	const userModules = keyExists( this.props.data, 'module_ids', true, [] );
        
        let groupModules = [];
        if( keyExists( this.props.data, 'groups' ) )
        {
            this.props.data.groups.forEach( ( group ) => {
                const groupModuleIds = group.modules.map( m => m.id );
                groupModules = groupModules.concat( groupModuleIds ) 
            });

            // Only keep unique values 
            groupModules = [ ...new Set( groupModules ) ];
        }

        let lockedModules = [];
        if( keyExists( this.props.data, 'contract_detail.hour_entry_required', true ) )
            lockedModules = keyExists( this.props.data, 'hour_entry_module_ids', true );


        return (
            <div className="padding">

                <div className="clear">

                    <div className="apColumn w50">
                        <div className="padding">

                            <h4 className="formHeader">{ tr('user_groups') }</h4>

                            <ApSelect
                                options={ this.state.userGroups }
                                label={ tr('user_groups') }
                                value={ keyExists( this.props.data, "groups", true, [] ) }
                                valueRenderer="usergroup"
                                optionRenderer="usergroup"
                                onChange={ ( groups ) => { this.props.onChange( "groups", groups ) } }
                                objKeyId="id"
                                objKeySearchable="name"
                                multiselect
                                clearable
                                loading={ this.state.loading || this.state.loading }
                                disabled={ this.state.loading || this.state.loading || this.state.userGroups.length === 0 }
                            />

                            { this.state.userGroups.length === 0 &&
	                            <div className="apInfo small warning">
	                                <SvgIcon icon="exclamation-triangle" type="solid" />
	                              	{ tr('no_user_groups') }
	                            </div>
	                        }

                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('user_groups_info1') }
                            </div>

                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" fill="#4285F4" />
                                { tr('user_groups_info2') }
                            </div>

                        </div>
                    </div>

                    <div className="apColumn w50">
                        <div className="padding">

                        	<h4 className="formHeader">{ tr('permissions') }</h4>

                            { this.props.data.is_limited &&
                                <div className="apInfoMsg" style={{ paddingTop: '1em' }}>
                                    <h4>{ tr('basic_user_limited_permissions') }</h4>
                                    <p>{ tr('basic_user_increase_permissions') }</p>
                                </div>
                            }

                        	{ this.props.data.is_admin &&
                                <div className="apInfoMsg" style={{ marginTop: '2em', paddingTop: '1em' }}>
                                    <SvgIcon icon="info-circle" type="solid" className="size-huge block" />
                                    <h4>{ tr('administrator_full_permissions') }</h4>
                                    <p>{ tr('administrator_limit_permissions') }</p>
                                </div>
                            }

                        	{ !this.props.data.is_admin && !this.props.data.is_limited &&
	                            <ModulesTree
	                                id="user-module-tree"
	                                selectedIDs={ userModules }
	                                inheritedIDs={ groupModules }
	                                lockedIDs={ lockedModules }
	                                onChange={ ( ids ) => { this.props.onChange( "module_ids", ids ) } }
	                                isCurrentUser={ auth.getID() === this.props.userID }
	                                companyID={ this.props.companyID }
	                            />
	                        }
                           
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Permissions;
