import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import ApSteps  from 'common/ApSteps/ApSteps.js';
import ApModal  from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon  from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { tr } from 'services/Helpers/Helpers.js'

import './ApModalWizard.css';

class ApModalWizard extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            step: 0,
            savePressed: false,
        }
        autoBind(this);
    }

    resetState()
    {
        if( typeof this.props.onOpen === 'function' )
            this.props.onOpen()

        const initialStep = this.props.initialStep || 0;

        this.setState({
            step: initialStep,
            savePressed: false
        });
    }

    stepInvalid()
    {
        let currentStep = this.props.steps[ this.state.step ];
        if( !currentStep ) return 'Tuntematon vaihe';

        if( typeof currentStep.invalid === 'function' )
            return currentStep.invalid();
        return false;
    }

    prevStep()
    {
        const stepIndex = this.state.step;
        if( stepIndex > 0 )
            this.setState({ step: stepIndex - 1 } );
        else
            this.props.onClose();
    }

    nextStep()
    {
        const stepIndex = this.state.step;
        const isLastStep = ( stepIndex === this.props.steps.length - 1 );

        if( isLastStep ) {
            // this.setState({ savePressed: true });
            this.props.onSave();
        }
        else
            this.setState({ step: stepIndex + 1 } );
    }

    render()
    {
        const steps = this.props.steps;
        const stepIndex = this.state.step;
        const currentStep = steps[ this.state.step ];
        const isLastStep = ( stepIndex === steps.length - 1 );

        let saveButtonTitle = tr('next');
        let saveButtonIcon = 'arrow-right';
        if( isLastStep )
        {
            saveButtonTitle = this.props.saveButtonTitle ? this.props.saveButtonTitle : tr('save');
            saveButtonIcon = this.props.saveButtonIcon ? this.props.saveButtonIcon : 'save';
        }

        return <div className="ApModalWizard">
            <ApModal
                show={ this.props.show }
                onOpen={ this.resetState }
                handleClose={ this.props.onClose }
                className="narrow"
                header={
                    <div className="wizardHeader">
                        <h4>{ this.props.header }</h4>
                    </div>
                }
                body={
                    <div className="wizardBody">
                        <div className="padding">
                            <ApSteps fullWidth flatEnd steps={ this.props.steps.map( ( s, index ) => {
                                return { name: s.name, description: `${tr('step')} ${ index + 1 }`, selected: stepIndex === index, success: stepIndex > index }
                            })} />
                            { currentStep && currentStep.render() }
                        </div>
                    </div>
                }
                footer={
                    <div className="wizardFooter">
                        <div className="prev">
                            <ApButton onClick={ this.prevStep } disabled={ this.state.loading }>
                                <SvgIcon icon={ stepIndex === 0 ? "times" : "arrow-left" } type="solid" />
                                { stepIndex === 1 ? tr('cancel') : tr('back') }
                            </ApButton>
                        </div>

                        <div className="next">
                            <ApTooltip text={ this.stepInvalid() } block>
                                <ApButton color="blue" onClick={ this.nextStep } disabled={ Boolean( this.stepInvalid() ) || this.props.loading || this.state.savePressed } loading={ this.props.loading }>
                                    { saveButtonTitle }
                                    <SvgIcon icon={ saveButtonIcon } type="solid" />
                                </ApButton>
                                { this.props.error && <div className="apErrorMsg">
                                    { this.props.error }
                                </div> }
                            </ApTooltip>
                        </div>
                    </div>
                }
            />
        </div>
    }

}

ApModalWizard.propTypes = {
    show:      PropTypes.bool.isRequired,
    steps:     PropTypes.array.isRequired,
    header:    PropTypes.string,
    loading:   PropTypes.bool,
    error:     PropTypes.string,
    onClose:   PropTypes.func.isRequired,
    onSave:    PropTypes.func.isRequired,
};

export default ApModalWizard;
