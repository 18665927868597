/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { tr } from  'services/Helpers/Helpers.js';

import './ApList.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';


const SortableContainer = sortableContainer(({ children, ref }) => {
  return <div className="apList" ref={ ref }>{ children }</div>;
});

const SortableItem = sortableElement( ({ item, index, renderer }) => {
    return renderer( item, index );
});  

const DragHandle = sortableHandle( () => 
    <div className="dragHandle" tabIndex={0}>
        <SvgIcon className="wait" icon="grip-lines"  type="solid" />
        <SvgIcon className="dragging" icon="arrows-alt-v"  type="solid" />
    </div>
);


export default class ApList extends React.Component {

    constructor( props )
    {
        super( props );
        autoBind(this); 
        this.listRef = React.createRef();
    } 

    // When list have scrollbar, it some times scrolls back up on re-render.
    // Therefore this will keep track the list scroll position. 
    getSnapshotBeforeUpdate() {
        if( this.listRef )
            return this.listRef.current.scrollTop || null;
        return null;
    }

    // Get user back to right scroll position after re-render. 
    componentDidUpdate( prevProps, prevState, snapshot ) {
        if( snapshot && this.listRef )
            this.listRef.current.scrollTop = snapshot;
    }

    onSortEnd({ oldIndex, newIndex }) {

        let items = this.props.items.slice();
        if( items.length > 0 && typeof( this.props.onSort ) === "function" )
        {
            items.splice( newIndex, 0, items.splice( oldIndex, 1 )[ 0 ] );
            this.props.onSort( items );
        }        
    }

    renderContent( item, index )
    {
        if( typeof( this.props.itemRenderer ) === "function" )
            return (
                <div className="itemContent">
                    { this.props.itemRenderer( item, index ) }
                </div>
            );

        if( typeof( this.props.itemRenderer ) === "string" && this.props.itemRenderer in item )
            return (
                <div className="itemContent">
                    { item[ this.props.itemRenderer ] }
                </div>
            );

        return (
            <div className="itemContent">
                { JSON.stringify( item ) }
            </div>
        );
    }

    renderIcon( item )
    {
        if( typeof( this.props.icon ) == "function" )
            return (
                <div className="icon">
                    { this.props.icon( item ) }
                </div>
            );

        else if( typeof( this.props.icon ) == "string" )
            return (
                <div className="icon">
                    <SvgIcon icon={ this.props.icon } type="solid" />
                </div>
            );

        return false;
    }

    handleItemClick( e, item )
    {
        // onClick function is set and did NOT click on apDropdown element
        if( typeof( this.props.onClick ) === "function" && !e.target.closest('.apDropdown') )
            this.props.onClick( item );       
    }

    renderItem( item, index )
    {
        let classes = [ "item" ];
        let validationState = "";

        if( typeof( this.props.validationState ) === "function" )
            validationState = this.props.validationState( item );

        if( this.props.icon )
            classes.push( 'withIcon' );

        if( this.props.actions )
            classes.push( 'haveActions' );

        if( this.props.onClick )
            classes.push( 'clickable' );

        if( this.props.sortable )
            classes.push( 'sortable' );

        if( [ "warning", "error", "success", "removed", "disabled" ].indexOf( validationState ) != -1 )
            classes.push( validationState );

        return (
            <div className={ classes.join(" ") } onClick={ ( e ) => this.handleItemClick( e, item ) }>

                { this.props.sortable &&
                    <DragHandle />
                }
                { this.renderIcon( item ) }
                { this.renderContent( item, index ) }

                { this.props.columns && 
                    <div className="columns">
                        { this.props.columns.map(( col, cIndex ) => {
       
                            let value = col.value;                   
                            if( typeof( col.value ) === "function" )
                                value = col.value( item, index );

                            return (
                                <div className="column" key={ cIndex } style={ col.style || {} }>
                                    { value }
                                </div>
                            );
                        })}
                    </div>
                }
                
                { this.props.actions &&
                    <ApDropdown 
                        actionId={ item }
                        actions={ this.props.actions }
                    />
                }
            
            </div>
        );
    }

    render()
    {
        const items = this.props.items || [];

        return (
            <SortableContainer 
                ref={ this.listRef } 
                lockAxis="y"
                helperClass="apListDragHelper"
                useDragHandle
                onSortEnd={ this.onSortEnd }
                lockToContainerEdges
            >

                { this.props.loading &&
                    <div className="apLoader"></div>
                }

                { !this.props.loading && items.length == 0 &&
                    <div className="empty">{ tr('empty') }</div>
                }

                { typeof( this.props.addNewItem ) === "function" && items.length > 20 &&
                    <div className="newItem" onClick={ () => this.props.addNewItem( 0 ) }>
                        <SvgIcon icon="plus" type="solid" />
                        { `${tr('add_new')}...` }
                    </div>
                }

                { items.map( ( item, index ) => 
                    <SortableItem 
                        key={ index } 
                        index={ index } 
                        item={ item }
                        renderer={ this.renderItem }
                    >

                    </SortableItem>
                )}

                { typeof( this.props.addNewItem ) === "function" &&
                    <div className="newItem" onClick={ () => this.props.addNewItem( items.length ) }>
                        <SvgIcon icon="plus" type="solid" />
                        { `${tr('add_new')}...` }
                    </div>
                }

            </SortableContainer>

        );
    }
};


ApList.propTypes = {
    loading:            PropTypes.bool,
    items:              PropTypes.array.isRequired,
    itemRenderer:       PropTypes.oneOfType([ PropTypes.array, PropTypes.func, PropTypes.string ]),
    icon:               PropTypes.oneOfType([ PropTypes.func, PropTypes.string ]),

    sortable:           PropTypes.bool,
    onSort:             PropTypes.func,

    onClick:            PropTypes.func,
    actions:            PropTypes.array,

    validationState:    PropTypes.func,

    addNewItem:         PropTypes.func,
};

