import React from 'react';
import autoBind from 'react-autobind';

import { keyExists }  from 'services/Helpers/Helpers.js'

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';


import './RowEdit.css';

class RowEdit extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            data: null,
        }
        autoBind(this);
    }

    resetState()
    {
        this.setState({ data: this.props.data });

        // Auto focus to name field (also scrolls view up if nessessary)
        if( this.banknameRef )
            this.banknameRef.focus();
    }

    handleChange( key, value )
    {
        let data = { ...this.state.data };
        data[ key ] = value;
        this.setState({ data });
    }

    onWorkSelect( component )
    {
        let data = { ...this.state.data };

        if( component )
        {
            data.work_component = component;
            data.unit = component.unit;
        }
        else
        {
            data.work_component = null;
            data.unit = null;
        }

        this.setState({ data });
    }

    save()
    {
        this.props.onClose( this.state.data );
    }

    renderBody()
    {
        const isset = ( key ) => {
            if( !keyExists( this.state.data, key ) )
                return false;
            if( this.state.data[ key ] === null )
                return false;
            if( this.state.data[ key ].length === 0 )
                return false;
            return true;
        }

        return <div className="padding">
            <ApInput
                type="text"
                id="bankname"
                name="bankname"
                label="Bankinnimi"
                value={ keyExists( this.state.data, "bankname", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'bankname', e.target.value ) } }
                validationState={ !isset( "bankname" ) ? "error" : "" }
                inputRef={ ref => this.banknameRef = ref }
            />
            <ApInput
                type="text"
                id="iban"
                name="iban"
                label="IBAN"
                value={ keyExists( this.state.data, "iban", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'iban', e.target.value ) } }
				validationState={ !isset( "iban" ) ? "error" : "" }
            />
			<ApInput
                type="text"
                id="bic"
                name="bic"
                label="BIC"
                value={ keyExists( this.state.data, "bic", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'bic', e.target.value ) } }
				validationState={ !isset( "bic" ) ? "error" : "" }
            />
        </div>
    }

    render()
    {
        let headerName = null;
        if( this.props.instance === 'identifiers' ) headerName = 'Tunnisteen';
        if( this.props.instance === 'properties' ) headerName = 'Ominaisuuden';
        if( this.props.instance === 'calculatables' ) headerName = 'Mittarin';

        return (
            <div id="StorageSettingRowEdit">
            <ApModal
                show={ this.props.show }
                onOpen={ this.resetState }
                handleClose={ () => this.props.onClose( false) }
                //closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            { headerName } { Boolean( this.props.data ) && this.props.data.id ? " muokkaus" : " lisäys" }
                        </h4>
                    </div>
                }
                body={
                    <div id="StorageSettingsRowEditBody">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="padding" id="StorageSettingsRowEditFooter">
                        <ApButton className="cancel" onClick={ () => this.props.onClose( false ) }>
                            <SvgIcon icon="times" type="solid" />
                            Peruuta
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ this.save } disabled={ this.state.loading }>
                            <SvgIcon icon="check" type="solid" />
                            Jatka
                        </ApButton>
                    </div>
                }
            />
        </div>
        )

    }

}

export default RowEdit;
