/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import Highlighter from "react-highlight-words";

import ApSelect         from 'common/ApSelect/ApSelect.js';
import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import ApDropdown       from 'common/ApDropdown/ApDropdown.js';
import ApTooltip        from 'common/ApTooltip/ApTooltip.js';
import api, { apiurl }              from 'services/Api/Api.js';
import auth             from 'services/Authed/Authed.js';
import ApSwitch         from 'common/ApSwitch/ApSwitch.js';
import ApConfirm        from 'common/ApConfirm/ApConfirm.js';
import ComponentEdit from 'modules/Storage/Components/ComponentEdit/ComponentEdit.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';

import CitList from './CitList/CitList.js';
import CreateStock from '../../../Storage/PurchaseOrders/EditPurchaseOrder/CreateStock/CreateStock.js';

import { ApInputSmall } from 'common/ApInputSmall/ApInputSmall.js';

import { ApInputStack
       , ApAddon, 
       ApInput}    from 'common/ApInput/ApInput.js';

import { formatMoney
       , roundToDecimals
       , groupify
       , removeRoundOff
       , sqlToDateInput
       , onlyNumber
       , tr
       , capitalize, 
       keyExists,
       formatCurrency,
       errorPopper,
       getBillingEntryTypes}   from 'services/Helpers/Helpers.js';

import { getTypeIcon
       , componentTypes } from 'modules/Storage/common/StorageHelpers.js';

import './Cit.css';
import AllowanceEntry from 'modules/Timetracking/Entry/Modal/AllowanceEntry.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApModal from 'common/ApModal/ApModal.js';

// Show or hide columns and headers based on instance
const SHOW_SELECT_INSTANCES = ['order'];
const NO_GROUP_INSTANCES = ['assignment', 'arrival'];
const NO_MENU_INSTANCES = ['assignment'];
const NO_SAVE_AS_NEW_INSTANCES = ['arrival'];

class ComponentInstanceTable extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),

            componentEditShow: false,
            componentEditId: null,

            listSelectShow: false,

            filterSupplierPrice: true,

            confirmPoPriceShow: false,
            confirmPoPriceText: null,
            confirmPoPriceMeta: null,

            groups: [],

            selected: [],

            showCreate: false,
            selectedComponent: null,
            selectedRow: null,

            costCenterEditShow: false,

            selectedComponentFiles: null,

            columnSort: {
                column: localStorage.getItem(this.props.instance + '/cit_sort_column') || null,
                direction: localStorage.getItem(this.props.instance + '/cit_sort_direction') || null,
            }
        }

        this.newCounter = 0;

        autoBind(this);
    }

    onComponentEditSave()
    {
        // TODO need to figure out what parts should be updated and what to keep

        this.setState({
            componentEditShow: false,
            componentEditId: null,
        });
    }

    goToPurchaseOrder( id )
    {
        window.emitter.emit( 'goTo', { pathname: `/storage/purchase/order/id/${ id }` } );
    }

    setGroup( component, value )
    {
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.order_group_id = value;
            return c;
        });
        this.props.componentsChange( components );

    }

    setName( component, value )
    {
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.name = value;
            return c;
        });
        this.props.componentsChange( components );

    }

    setNamePOimport( component, value )
    {
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.fivaldi_id  === component.fivaldi_id && c.netvisor_id  === component.netvisor_id )
                c.name = value;
            return c;
        });
        this.props.componentsChange( components );

    }
    

    changeSaveAsNew(  component )
    {
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
            	 c.save_as_new = !c.save_as_new
            return c;
        });
        this.props.componentsChange( components );
    }
    
    setCount( component, value, blur = false )
    {
        value = onlyNumber( value, true );
        let gCount = 0;
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if (this.props.instance === 'assignment') {
                if (component.projectComponentId) {
                    if (c.projectComponentId === component.projectComponentId) {
                        c.count = value;
                    }
                }
                else if (component.installation_entry_id) {
                    if (c.installation_entry_id === component.installation_entry_id) {
                        c.count = value;
                    }
                } 
                else if (c.id === component.id) {
                    c.count = value;
                }
            }
            else if( c.id  === component.id )
                c.count = value;

            if(c.order_group_id  === component.order_group_id)
                gCount = (gCount+parseFloat(c.count));

            return c;
        });
        
        if (component.order_group_id!=null) {
            this.setGroupCount(component.order_group_id, gCount)
        }
        this.props.componentsChange( components );
    }

    handleRefChange = (event, component) => {
        const value = event.target.value;
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.dispatch_ref = value;
            return c;
        });

        this.props.componentsChange( components );
    };

    setArrivalCount( component, value, blur = false )
    {
        value = onlyNumber( value, true );

        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.arrival_count = value;
            return c;
        });
        this.props.componentsChange( components );
    }

    setDeliveryCount( component, value, blur = false )
    {
        value = onlyNumber( value );

        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.delivery_count = value;
            return c;
        });
        this.props.componentsChange( components );
    }

    setImportCount( component, value, blur = false )
    {
        value = onlyNumber( value );

        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.fivaldi_id  === component.fivaldi_id ) {
                c.count = value;
                c.price = c.orig_price/c.count;
            }
            return c;
        }).filter(c => c.fivaldi_id); // Do not include linked components from existing PO
        this.props.componentsChange( components );
    }

    setPOPrice( component, value, blur = false )
    {
        value = onlyNumber( value, true );
        /*if( blur )
        {
            if( value )
                value = roundToDecimals( value, 2 );
        }*/

        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.purchase_order_price = value;
            return c;
        });
        this.props.componentsChange( components );
    }

    setPrice(component, value, blur = false) {
        value = onlyNumber( value, true );
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.price = value;
            return c;
        });
        this.props.componentsChange( components );
    }
    
    setSellPrice(component, value, blur = false) {
        value = onlyNumber( value, true );
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.price_sell = value;
            return c;
        });
        this.props.componentsChange( components );
    }

    setOrderVat( component, value )
    {
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.order_vat = value;
            return c;
        });
        this.props.componentsChange( components );
    }
    setTaxGroup(component, taxGroup) {
        let components = this.props.components.slice(0);
        components = components.map(c => {
            if (c.id === component.id){
                c.tax_group_id = taxGroup ? taxGroup.id : null;
                c.tax_group = taxGroup ? taxGroup : null;
                c.order_vat = (taxGroup && taxGroup.vat) ? taxGroup.vat.value : null;
                c.order_netvisor_vat = taxGroup ? taxGroup.tax_code : "KOMY";
            }
            return c;
        });
        this.props.componentsChange(components);
    }
	
	setOrderNetvisorVat( component, value )
    {
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.order_netvisor_vat = value;
            return c;
        });
        this.props.componentsChange( components );
    }
    setOrderCostCenter(component, value) {
        let components = this.props.components.slice(0);
        components = components.map(c => {
            if (c.id === component.id)
                c.cost_center_id = value;
            return c;
        });
        this.props.componentsChange(components);
    }

    setOrderUnit( component, value )
    {
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.unit = value;
            return c;
        });
        this.props.componentsChange( components );
    }

    setOrderPrice( component, value, blur = false )
    {
        value = onlyNumber( value, true );
        // if( blur )
        // {
        //     if( value )
        //         value = roundToDecimals( value, 2 );
        // }

        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
                c.order_price = value;
            return c;
        });
        this.props.componentsChange( components );
    }

    setOrderDiscauntPercent(component, value, blur = false) {
        value = onlyNumber(value, true);
        // if( blur )
        // {
        //     if( value )
        //         value = roundToDecimals( value, 2 );
        // }


        let components = this.props.components.slice(0);
        components = components.map(c => {
            if (c.id === component.id)
                c.discaunt_percent = value;
            return c;
        });
        this.props.componentsChange(components);
    }

    setPOTarget( component, type = null, related = null, row = null, newComponents = null)
    {
        let components = newComponents ? newComponents : this.props.components.slice( 0 );

        if (row != null) {
            components = components.map(c => {
                if (c.id == row.row_id) {
                    c.target_final_type = type;
                    c.target_final_id = row.location_id;
                }
                return c;
            });
            
            this.props.componentsChange( components );
            return;
        }

        components = components.map( c => {
            if( c.id  === component.id )
            {
                if( type === null)
                {
                    this.newCounter++;
                    c.id = `new${ this.newCounter }`;
                    c.target_final_type = null;
                    c.target_final_id   = null;
                    c.count             = null;
                }
                else if( type === 'location' )
                {
                    this.newCounter++;
                    c.id = `new${ this.newCounter }`;

                    c.target_final_type = type;
                    c.target_final_id   = related.id;
                    //c.count             = null;
                }
                else if( type === 'S' )
                {
                    this.newCounter++;
                    c.id = `new${ this.newCounter }`;

                    c.target_final_type = 'location';
                    c.target_final_id   = related.id;
                    //c.count             = null;
                }
                else if( type === 'project' )
                {
                    this.newCounter++;
                    c.id = `new${ this.newCounter }`;

                    c.target_final_type = type;
                    c.target_final_id   = related.id;
                    //c.count             = null;
                }
                else if( type === 'P' )
                {
                    this.newCounter++;
                    c.id = `new${ this.newCounter }`;

                    c.target_final_type = 'location';
                    c.target_final_id   = related.id;
                    //c.count             = null;
                }
                else if( type === 'company' )
                {
                    c.id                = related.demand_id;
                    c.target_final_type = type;
                    c.target_final_id   = related.target_final_id;
                    //c.count             = related.count;
                }
                else
                {
                    console.error('Unknown type on setPOTarget', type );
                }
            }
            return c;
        });
        this.props.componentsChange( components );
    }

    setOrderTarget( component, type = null, related = null )
    {
        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( c.id  === component.id )
            {
                if( type === null )
                {
                    c.order_from_type   = null;
                    c.order_from_id     = null;
                }
                else if( type === 'location' )
                {
                    c.order_from_type   = type;
                    c.order_from_id     = related.id;

                }
                else if( type === 'direct' )
                {
                    c.order_from_type   = type;
                    c.order_from_id     = null;
                }
                else
                {
                    console.error('Unknown type on setOrderTarget', type );
                }
            }
            return c;
        });
        this.props.componentsChange( components );

    }

    setDeliveryFrom( component, type = null, related = null )
    {
        let components = this.props.components.slice( 0 );

        components = components.map( c => {
            if( c.id  === component.id )
            {
                if( type === null )
                {
                    this.newCounter++;
                    c.id = `new${ this.newCounter }`;
                    c.delivery_from_type = null;
                    c.delivery_from_id   = null;
                    c.delivery_count     = null;
                    // c.delivery_to_type   = null;
                    // c.delivery_to_id     = null;
                }
                else if( type === 'location' )
                {
                    this.newCounter++;
                    c.id = c.id ? c.id : `new${ this.newCounter }`;
                    c.delivery_from_type = 'location';
                    c.delivery_from_id   = related.id;

                    const foundLocation = c.locations.find(l => l.id == c.delivery_from_id);
                    if (!foundLocation || (foundLocation && foundLocation.balance == 0)) {
                        c.delivery_count     = null;
                    }

                    // c.delivery_to_type   = null;
                    // c.delivery_to_id     = null;

                }
                else if( type === 'direct' )
                {
                    c.id                 = related.instance_id;
                    c.delivery_from_type = 'direct';
                    c.delivery_from_id   = related.location_id;
                    c.delivery_count     = null;
                    c.delivery_to_type   = 'direct';
                    c.delivery_to_id     = this.props.deliveryReceiverId;
                }
                else
                {
                    console.error('Unknown type on setDeliveryFrom', type );
                }
            }
            return c;
        });
        this.props.componentsChange( components );
    }

    setDeliveryTo( component, type = null, related = null )
    {
        let components = this.props.components.slice( 0 );

        components = components.map( c => {
            if( c.id  === component.id )
            {
                if( type === null )
                {
                    c.delivery_to_type = null;
                    c.delivery_to_id   = null;
                }
                else if( type === 'location' )
                {
                    c.delivery_to_type = 'location';
                    c.delivery_to_id   = related.id;
                }
                else
                {
                    console.error('Unknown type on setDeliveryTo', type );
                }
            }
            return c;
        });
        this.props.componentsChange( components );
    }

    setNewStock(data) {
        let components = this.props.components.slice( 0 );

        let locationData = null;
        components = components.map( c => {
            if( c.id  === data.row_id ) {
                if (locationData == null) {
                    //Hae varastopaikan tiedot, id, nimi, koodi
                    api({
                        method: 'get',
                        url: `storage/location/get/${data.location_id}`,
                    }).then((response) => {
                        locationData = response;
                        c = this.addToLocations(c, locationData);
                        if (this.props.instance=="order") {
                            this.setOrderTarget( c, 'location', locationData );
                        }
                        if (this.props.instance=="po") {
                            this.setPOTarget(c, null, null, data);
                        }
                        if (this.props.instance == 'delivery') {
                            this.setDeliveryLocation(c);
                        }
                    }).catch((error) => {
                        console.error(error);
                    });
                } 
            }

            return c;
        });
    }

    setDeliveryLocation(c) {
        let components = this.props.components.slice( 0 );
        components = components.map(component => component.id === c.id ? c : component);

        this.props.componentsChange(components);
    }

    addToLocations(c, locationData) {
        const dataToPush = {
            id: locationData.id,
            code: locationData.code,
            name: locationData.name,
            balance: locationData.balance,
            balance_free: locationData.balance_free,
            type: locationData.type,
            delivery_locations: [{
                id: locationData.id,
                name: locationData.name, 
                code: locationData.code
            }]
        };

        c.locations.push(dataToPush);
        
        return c;
    }

    onCreateNew(component_id, row_id) {
        this.setState({ 
            showCreate: true,
            selectedComponent: component_id,
            selectedRow: row_id
        });
    }

    onNewStockSaved(data) { 
        this.setState({
            showCreate: false,
            loading: false 
        });

        this.setNewStock(data);
    }



    componentAddOutsideStorage( ct )
    {
        const recId = this.props.receiverId == undefined ? this.props.poTargetId : this.props.receiverId;
        api({
            method: 'post',
            url: `/storage/component/0/format`,
            data: {
                typeId: ct.id,
                instance: this.props.instance,
                supplierId: this.props.supplierId,
                receiver_type: this.props.receiverType,
                project_id: this.props.project_id,
                receiver_id: recId,
                poId: this.props.poId,
            }
        }).then((response) => {
            this.newCounter++;
            let components = this.props.components.slice( 0 );
            response.id = `new${this.newCounter}`;
            if (this.props.defaultCostCenters) {
                response.cost_center_id = this.props.defaultCostCenters;
            }
            if(this.props.defaultCrmTax){
                response.order_vat=this.props.vats.find(element => element.id=== this.props.defaultCrmTax).value;
            }
            if(this.props.defaultCrmTaxCode){
                let codes=this.props.TaxCodes;
                for(let key in codes){
                    if(codes[key].id=== this.props.defaultCrmTaxCode){
                        response.order_netvisor_vat=key;
                    }
                }
                
            }
            components.push( response );
            //console.log("lisätään",response);
            this.props.componentsChange( components );
            let newComponent = components.slice(-1)[0]
            if (newComponent.locations.length>=1) {
                this.setPOTarget(newComponent,newComponent.locations[0].type, newComponent.locations[0], null, components);
            }
            
            
        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('get_error') }</strong>,
            });
        });



    }

    addComponent( component )
    {
        const recId = this.props.receiverId == undefined ? this.props.poTargetId : this.props.receiverId;
        const data = {
            instance: this.props.instance,
            supplierId: this.props.supplierId,
            //receiverType: this.props.receiverType,
            receiver_type: this.props.receiverType,
            project_id: this.props.project_id,
            //receiverId: recId,
            receiver_id: recId,
            poId: this.props.poId,
            componentId: component.id,
            componentTitle: component.title,
            crm_id: this.props.crm_id,
        }

        api({
            method: 'post',
            url: `/storage/component/${ component.id }/format`,
            data: data
        }).then((response) => {
            if (response.purchase_order_price === null) {
                response.purchase_order_price = response.price
            }
            if (this.props.defaultCrmTax != null) {
                for (let tax of this.props.vats) {
                    if (tax.id === this.props.defaultCrmTax) {
                        response.order_vat = tax.value;
                        break;
                    }
                }
            }
            if (this.props.defaultCrmTaxCode !== null) {
                for (let code in this.props.TaxCodes) {
                    if (this.props.TaxCodes[code].id === this.props.defaultCrmTaxCode) {
                        response.order_netvisor_vat = code;
                        break;
                    }
                }
            }

            if (this.props.useTaxGroups) {
                const setDefaultTaxGroup = (key) => {
                    if (!this.props[key]) return;
                    if (!this.props[key].vat_id && !this.props[key].tax_code) return;

                    for (let tax of this.props.vats) {
                        if (tax.id === this.props[key].vat_id) {
                            response.order_vat = tax.value;
                            break;
                        }
                    }
                    for (let code in this.props.TaxCodes) {
                        if (this.props.TaxCodes[code].id === this.props[key].tax_code) {
                            response.order_netvisor_vat = code;
                            break;
                        }
                    }
                    response.tax_group = this.props[key];
                    response.tax_group_id = this.props[key].id;
                }
                switch (response.type_id) {
                    case 1:
                        setDefaultTaxGroup('defaultItemTaxGroup');
                        break;
                    case 2:
                        setDefaultTaxGroup('defaultWorkTaxGroup');
                        break;
                    case 3:
                        setDefaultTaxGroup('defaultExpenceTaxGroup');
                        break;
                
                    default:
                        break;
                }
            }

            this.newCounter++;
            response.id = `new${this.newCounter}`;
            response.discaunt_percent = component.discaunt_percent ? component.discaunt_percent : null;
            if (this.props.defaultCostCenters) {
                response.cost_center_id = this.props.defaultCostCenters;
            }

            if (response.conversion_enabled) {
                const key = response.purchase_order_price 
                    ? 'default_in_purchase_orders' 
                    : response.order_price 
                        ? 'default_in_orders' 
                        : null;
                if (key) {
                    // console.log(response);
                    const foundUnit = response.conversion_units.find(item => item[key] === true);
                    if (foundUnit) {
                        response.selectedUnit = foundUnit;
                        if (key === 'default_in_purchase_orders') {
                            response.purchase_order_price = parseFloat(response.purchase_order_price) / parseFloat(foundUnit.conversion_factor);
                        } else if ('default_in_orders') {
                            response.order_price = parseFloat(response.order_price) / parseFloat(foundUnit.conversion_factor);
                        }
                    }
                }
            }

            // Aseta vastaanottava varastopaikka id automaattisesti jos kyseessä on varastosiirto ja vastaanottaja on projektivarasto
            if (this.props.instance === 'delivery' && this.props.deliveryReceiverType === 'project_storage') {
                response.delivery_to_id = this.props.deliveryReceiverId;
                response.delivery_to_type = 'project';
            }
            // Aseta vastaanottava varastopaikka id automaattisesti jos kyseessä on varastosiirto ja lähettäjä on projektivarasto
            if (this.props.instance === 'delivery' && this.props.deliverySenderType === 'project_storage') {
                response.delivery_from_id = this.props.deliverySenderId;
                response.delivery_from_type = 'project';
            }
            // Aseta lähettävä ja vastaanottava varastopaikka kun kyseessä normaali varasto
            if (this.props.instance === 'delivery' && this.props.deliveryReceiverType === 'location') {
                const foundLocation = response.locations.find(location => {
                    if (location.delivery_locations) {
                        for (let dl of location.delivery_locations) {
                            if (this.props.deliveryReceiverId === dl.id) return true;
                        }
                    }
                    return false;
                })
                response.delivery_to_id = foundLocation ? foundLocation.id : null;
                response.delivery_to_type = foundLocation ? 'location' : null;
            }
            if (this.props.instance === 'delivery' && this.props.deliverySenderType === 'location') {
                const foundLocation = response.locations.find(location => {
                    if (location.delivery_locations) {
                        for (let dl of location.delivery_locations) {
                            if (this.props.deliverySenderId === dl.id) return true;
                        }
                    }
                    return false;
                })
                response.delivery_from_id = foundLocation ? foundLocation.id : null;
                response.delivery_from_type = foundLocation ? 'location' : null;
            }
            
            // Enable creating duplicate rows in assignment for components
            if (this.props.instance === 'assignment') {
                response.newRow = true;
            }

            if (this.props.instance === 'contract_billing') {
                if (response.default_vat) {
                    response.vat_amount = response.default_vat;
                    const vat = this.props.vats.find(v => v.value === response.default_vat);
                    if (vat) {
                        response.vat_id = vat.id;
                    }
                }
            }
            
                
            let components = this.props.components.slice( 0 );
            components.push(response);
            this.props.componentsChange( components );
            let newComponent = components.slice(-1)[0];
            if (newComponent.locations.length==1) {
            	this.setPOTarget(newComponent,'location',newComponent.locations[0], null ,components);
            } else if (this.props.receiverType == 'location' && this.props.receiverCode) {
                //Kokeile löytää lokaatioista varastopaikka joka löytyy varaston alta
                const recCode = this.props.receiverCode;
                const def = response.locations.find(l => l.code.toString().split('.')[0] == recCode);
                if (def) { this.setPOTarget(newComponent, 'location', def, null, components); }   
            } else if (this.props.receiverType == 'project') {
                this.setPOTarget(newComponent,newComponent.locations[0].type,newComponent.locations[0], null ,components);
            }  
        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('get_error') }</strong>,
            });
        });
    }

    removeComponent( component )
    {
        let selected = this.state.selected.slice( 0);
        selected = selected.filter( s => s !== component.id );
        this.setState({ selected: selected });

        let components = this.props.components.slice( 0 );
        if (this.props.instance === 'assignment' && component.installation_entry_id) {
            components = components.filter( c => c.installation_entry_id !== component.installation_entry_id );
        } else {
            components = components.filter( c => c.id !== component.id );
        }
        // this.props.componentsChange(components);
        this.props.componentsChange(components, () => {
            
            if (component.order_group_id!=null) {
                let groups = this.props.groups.slice(0);
                groups = groups.map(g => {
                    if (g.id === component.order_group_id) {
                        let groupComponents = components.filter(c => c.order_group_id === g.id);
                        let count = 0;
                        groupComponents.forEach(gc => count += parseFloat(gc.count));
                        g.count = count;
                    }
                        
                    return g;
                });

                this.props.groupsChange(groups);
                
            }
            
        });


    }

    syncPoPriceClick( c )
    {
        let newPrice = parseFloat( c.purchase_order_price );
        let oldPrice = null;
        let oldDiscount = null;
        let oldOrigPrice = null;
        let newOrigPrice = null;

        let found = c.suppliers.find( s => s.id === this.props.supplierId );
        if( found ) oldPrice = parseFloat( found.price );
        if( found ) oldDiscount = parseFloat( found.discount );
        if( oldDiscount>0 && oldDiscount<100) {

            oldOrigPrice=oldPrice/((100-oldDiscount)/100);
            newOrigPrice=newPrice/((100-oldDiscount)/100);
        }

        this.setState({
            confirmPoPriceShow: true,
            confirmPoPriceMeta: {
                component_id: c.component_id,
                price: newPrice,
                discount: oldDiscount!=null?oldDiscount:0
            },
            confirmPoPriceText: <div>
                <div>{ tr('cit_confirm') }:</div>
                <table id="confirmPoPriceModal">
                    <tr>
                        <td className="text">{ tr('storage_component') }</td>
                        <td className="value">{ c.name }</td>
                    </tr>
                    <tr>
                        <td className="text">{ tr('supplier') }</td>
                        <td className="value">{ this.props.supplierName }</td>
                    </tr>
                    {oldDiscount>0 ? (<tr>
                        <td className="text">{ tr('discount_percentage') }</td>
                        <td className="value">{oldDiscount} %</td>
                    </tr>
                    ):''}
                    <tr>
                        <td className="text">{ tr('new_price_per_supplier') }</td>
                        <td className="value">{ formatMoney( newPrice ) }  {newOrigPrice!=null? (<span>({formatMoney( newOrigPrice )})</span>):''} { this.state.currencySign }</td>
                    </tr>
                    <tr>
                        <td className="text">{ tr('old_price_per_supplier') }</td>
                        <td className="value">{ oldPrice && <div>{ formatMoney( oldPrice ) } {oldOrigPrice!=null? (<span>({formatMoney( oldOrigPrice )})</span>):''}  { this.state.currencySign }</div> }</td>
                    </tr>
                </table>
            </div>
        });
    }

    syncPoPrice( data )
    {
        api({
            method: 'post',
            url: `/storage/component/${ data.component_id }/supplier/save`,
            data: {
                supplier_id: this.props.supplierId,
                price: data.price,
                discount: data.discount,
            }
        }).then((response) => {
            let components = this.props.components.slice( 0 );
            components = components.map( c => {
                if( c.component_id === data.component_id )
                {
                    let newSupplier = true;
                    let suppliers = c.suppliers.slice( 0 )
                    c.suppliers = suppliers.map( s => {
                        if( s.id === this.props.supplierId  )
                        {
                            newSupplier = false;
                            s.price = data.price;
                        }
                        return s;
                    });
                    if( newSupplier )
                    {
                        c.suppliers.push( {
                            id: this.props.supplierId,
                            name: this.props.supplierName,
                            price: data.price,
                        });
                    }
                }
                return c;
            });
            this.props.componentsChange( components );
        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('save_error') }</strong>,
            });
        });

    }

    setGroupOVat(groupId, value )
    {
        let components = this.props.components.slice(0);
        components = this.props.components.map( c => {
            if( c.order_group_id === groupId  )
                c.order_vat = value;
            return c;
        });
        this.props.componentsChange( components );
    }
    
    setGroupTaxGroups(groupId, taxGroup) {
        let components = this.props.components.slice(0);
        components = this.props.components.map(c => {
            if (c.order_group_id === groupId) {
                c.tax_group_id = taxGroup.id;
                c.tax_group = taxGroup;
                c.order_vat = taxGroup.vat.value;
                c.order_netvisor_vat = taxGroup.tax_code;
            }
            return c;
        });
        this.props.componentsChange(components);
    }
    setGroupOrderCostCenter(groupId, value) {
        let components = this.props.components.slice(0);
        components = this.props.components.map(c => {
            if (c.order_group_id === groupId)
                c.cost_center_id = value;
            return c;
        });
        this.props.componentsChange(components);
    }

    getSelected() {
        return this.state.selected;
    }

    renderGroupVat( g )
    {
        let disabled = false;
        if( this.props.orderPriceLocked ) disabled = true;

        let selectClass = [ 'vat-input' ];

        const components = this.props.components.filter( c => c.order_group_id  === g.id );
        let vat = ( components.length ) ? components[ 0 ].order_vat : null;
        let validationState = null;
        
        if( !components.every( c => c.order_vat == vat ) ) vat = '';
        if( vat === '' ) selectClass.push( 'validator-error' );

        return <div className="groupVatContainer">
            <select
                className={ selectClass.join(' ') }
                value={ vat }
                onChange={ ( e ) => this.setGroupOVat( g.id, e.target.value ) }
                disabled={ disabled }
            >
                <option value=""></option>
                { this.props.vats.map( v => <option key={ v.value } value={ v.value }>{ v.value }</option> )}
            </select>
        </div>
    }
    renderGroupTaxGroup(g) {
        let disabled = false;
        if (this.props.orderPriceLocked) disabled = true;

        let selectClass = ['netvisor-vat-input'];

        const components = this.props.components.filter(c => c.order_group_id === g.id);
        let vat = (components.length) ? components[0].tax_group : null;
        let validationState = null;

        if (!components.every(c => {
            
            return c.tax_group ? c.tax_group.id == vat.id : false;
        })) {
            vat = null;  
        } 
        if (vat === null) selectClass.push('validator-error');

        return <ApSelect
                className={selectClass.join(' ')}
                label={tr('component_tax_group')}
                value={vat ? this.renderTaxGroupValue(vat) : ''}
                optionRenderer="tax_group"
                onChange={(data) => this.setGroupTaxGroups(g.id, data)}
                objKeyId="id"
                // clearable
                apiUrl="order/taxGroups/find"
                // apiData={{
                //     canReceive: true,
                //     projectStorage: false,
                // }}
                disabled={disabled}
            />
            {/* <select
                className={selectClass.join(' ')}
                value={vat}
                onChange={(e) => this.setGroupOVat(g.id, e.target.value)}
                disabled={disabled}
            >
                <option value=""></option>
                {this.props.vats.map(v => <option key={v.value} value={v.value}>{v.value}</option>)}
            </select> */}
       
    }
	
	renderOrderNetvisorVat( c )
    {
		let disabled = false;
        if (this.props.orderPriceLocked || this.props.poPriceLocked) disabled = true;
        let selectClass = [ 'netvisor-vat-input' ];

        return <select
				className={ selectClass.join(' ') }
				value={ c.order_netvisor_vat }
				onChange={ ( e ) => this.setOrderNetvisorVat( c, e.target.value ) }
				disabled={ disabled }
			>
				<option key="NONE" value="NONE">{ tr('netvisor_vat_option1') }</option>
				<option key="KOMY" value="KOMY">{ tr('netvisor_vat_option2') }</option>
				<option key="EUMY" value="EUMY">{ tr('netvisor_vat_option3') }</option>
				<option key="EUUM" value="EUUM">{ tr('netvisor_vat_option4') }</option>
				<option key="EUPM312" value="EUPM312">{ tr('netvisor_vat_option5') } (312)</option>
				<option key="EUPM309" value="EUPM309">{ tr('netvisor_vat_option5') } (309)</option>
				<option key="MUUL" value="MUUL">{ tr('netvisor_vat_option6') } (310)</option>
				<option key="RAMY" value="RAMY">{ tr('netvisor_vat_option7') }</option>
			</select>
    }

    renderGroupDiscountPercent(g) {
        let disabled = false;
        if (this.props.orderPriceLocked) disabled = true;
        let selectClass = ['cost-center-input'];

        const components = this.props.components.filter(c => c.order_group_id === g.id);
        

        if (components.every(c => c.discaunt_percent == null || c.discaunt_percent == '')) {
            return null;
        }
        // let discountPercent = 0;
        // let count = 0;
        let totalPriceWithoutDiscount = 0;
        let totalPrice = 0;
        for (let component of components) {
            if (component.count == null) {
                return null;
            }
            totalPrice += (component.count * component.order_price * (1 - (component.discaunt_percent ? (parseFloat(component.discaunt_percent) / 100) : 0)));
            totalPriceWithoutDiscount += component.count * component.order_price;
            
            //old
            // count += parseFloat(component.count);
            
            // discountPercent += (component.discaunt_percent == '' || component.discaunt_percent == null) ? 0 : parseFloat(component.discaunt_percent) * parseFloat(component.count);
        }
        
        return roundToDecimals(100-(totalPrice / totalPriceWithoutDiscount *100), 2) + " %";
    }

    renderGroupCostCenter(g) {
        let disabled = false;
        if (this.props.orderPriceLocked) disabled = true;
        let selectClass = ['cost-center-input'];

        const components = this.props.components.filter(c => c.order_group_id === g.id);
        let costCenterID = (components.length) ? components[0].cost_center_id : null;

        if (!components.every(c => c.cost_center_id == costCenterID)) costCenterID = null;
        if (costCenterID === null) selectClass.push('validator-error');
        if (this.props.costCenters) {
            
            return <div onClick={() => {
                if(!disabled)
                this.setState({
                    costCenterEditShow: true,
                    costCenterIsGroup: g.id,
                    editComponent: components.find((comp) => comp.cost_center_id),
                    editGroupComponents: components,
                })
            }}>{this.getCostenterText(costCenterID)}</div>;
        }
        return null;

    }
    renderCostCenter(c) {
        let disabled = false;
        if (this.props.orderPriceLocked) disabled = true;
        let selectClass = ['cost-center-input'];
        if (this.props.costCenters) {
            let options = this.props.costCenters.map((c) => {
                let option = <option key={c.id} value={c.id}>{c.name}</option>
                return option;
            });
            return <select
                className={selectClass.join(' ')}
                value={c.cost_center_id}
                onChange={(e) => this.setOrderCostCenter(c, parseInt(e.target.value))}
                disabled={disabled}
            >
                <option key="NONE" value="NONE">{tr('no_cost_center_selected')}</option>
                {options}
            </select>
        }
        return null;
        
    }

    setOrderNetvisorGroupVat(groupId, value) {
        let components = this.props.components.slice(0);
        components = this.props.components.map(c => {
            if (c.order_group_id === groupId)
                c.order_netvisor_vat = value;
            return c;
        });
        this.props.componentsChange(components);
    }
    renderOrderNetvisorGroupVat(g) {
        let disabled = false;
        if (this.props.orderPriceLocked) disabled = true;
        let selectClass = ['netvisor-vat-input'];

        const components = this.props.components.filter(c => c.order_group_id === g.id);
        let vat = (components.length) ? components[0].order_netvisor_vat : null;
        let validationState = null;

        if (!components.every(c => c.order_netvisor_vat == vat)) vat = "NONE";
        if (vat === "NONE") selectClass.push('validator-error');

        return <select
            className={selectClass.join(' ')}
            value={vat}
            onChange={(e) => this.setOrderNetvisorGroupVat(g.id, e.target.value)}
            disabled={disabled}
        >
            <option key="NONE" value="NONE">{tr('netvisor_vat_option1')}</option>
            <option key="KOMY" value="KOMY">{tr('netvisor_vat_option2')}</option>
            <option key="EUMY" value="EUMY">{tr('netvisor_vat_option3')}</option>
            <option key="EUUM" value="EUUM">{tr('netvisor_vat_option4')}</option>
            <option key="EUPM312" value="EUPM312">{tr('netvisor_vat_option5')} (312)</option>
            <option key="EUPM309" value="EUPM309">{tr('netvisor_vat_option5')} (309)</option>
            <option key="MUUL" value="MUUL">{tr('netvisor_vat_option6')} (310)</option>
            <option key="RAMY" value="RAMY">{tr('netvisor_vat_option7')}</option>
        </select>
    }

    renderGroup( g, rows, colSpan )
    {
        let disabled = false;
        if( this.props.orderPriceLocked ) disabled = true;

        let rowDoms = [];
        let totalPrice = 0;
        let totalPirceWithoutDiscount = 0;
        let countError = null;
        let priceError = null;

        rows.forEach( (r, key ) => {
            if( g.open )
                rowDoms.push( this.renderCTRow( r , ( rows.length === key+1 ) ) );

            totalPrice += (r.count * r.order_price * (1 - (r.discaunt_percent ? (parseFloat(r.discaunt_percent) / 100) : 0)));
            totalPirceWithoutDiscount += r.count * r.order_price;
            if( !countError && !r.count )
                countError = 'error';

            if( !priceError && !r.order_price )
                priceError = 'error';
        });

        let singlePrice = totalPirceWithoutDiscount/ g.count;
        let trClass = [ 'groupRow' ];
        if( g.open ) trClass.push( 'open' )
      
        if( !g.count) countError = 'error';

        let iconDom = null;
        let groupFunc = () => {};
        if( this.state.selected.length )
        {
            iconDom = <SvgIcon icon="plus-square" type="solid" />
            groupFunc = () => this.addToGroup( g );

            if( this.props.components.every( c => {
                if( this.state.selected.includes( c.id ))
                    return ( c.order_group_id === g.id )
                return true;
            } ) )
            {
                iconDom = <SvgIcon icon="minus-square" type="solid" />
                groupFunc = () => this.removeFromGroup( g );
            }
        }

        let statusTd = null
        if (this.props.orderType === 'delivery' )
            statusTd = <td></td>

        if (this.props.useTaxGroups) {
            return <React.Fragment key={g.id}>
                <tr className={trClass.join(' ')}>
                    <td className="selectTd" onClick={groupFunc}>
                        {iconDom}
                    </td>
                    <td className="groupTd" onClick={() => this.openGroup(g)}>
                        <SvgIcon icon="angle-right" type="solid" />
                    </td>
                    <td></td>
                    <td className="nameTd">
                        <ApInputSmall
                            type="text"
                            value={g.name ? g.name : ''}
                            onChange={(e) => { this.setGroupName(g, e.target.value) }}
                            disabled={disabled}
                            validationState={(g.name) ? null : 'error'}
                        />
                    </td>
                    <td>&nbsp;</td>
                    {(this.state.type === 'delivery') ? <td key="reserve" className="fixed c-target"></td> : <td key="entry" className="fixed c-entry"></td>}
                    <td className="fixed c-count">
                        <ApInputSmall
                            value={g.count}
                            noFocusDecimals={2}
                            disabled
                            validationState={countError}
                        />
                    </td>
                    <td className="fixed c-unit">
                        <ApInputSmall
                            type="text"
                            value={g.unit ? g.unit : ''}
                            onChange={(e) => { this.setGroupUnit(g, e.target.value) }}
                            disabled={disabled}
                        />
                    </td>
                    <td className="fixed">
                        <ApInputSmall
                            value={singlePrice}
                            disabled
                            noFocusDecimals={2}
                            validationState={priceError}
                        />
                    </td>
                    <td className="fixed">
                        {this.renderGroupDiscountPercent(g)}
                    </td>
                    <td key="vat" className="">
                        {this.renderGroupTaxGroup(g)}
                    </td>
                    
                    {this.props.costCenterEnabled &&
                        <td key="cost-center" className="fixed vat">
                            {this.renderGroupCostCenter(g)}
                        </td>}
                    <td key="tota_price" className="fixed total">
                        {formatMoney(totalPrice)} {this.state.currencySign}
                    </td>
                    <td key="dispatch_ref" className="fixed dispatch-ref">

                    </td>
                    {statusTd}
                    <td className="fixed actions">
                        {this.renderGroupMenu(g)}
                    </td>
                </tr>
                {rowDoms}
            </React.Fragment>
        }
        else {

            let vaihto = "angle-right" ;

            if (g.open) {
                vaihto = "angle-down" ;
            }
            return <React.Fragment key={g.id}>
                <tr className={trClass.join(' ')}>
                    <td className="selectTd" onClick={groupFunc}>
                        {iconDom}
                    </td>
                    {/* <td className="groupTd" onClick={() => this.openGroup(g)}>
                        <SvgIcon  icon="angle-right" type="solid" />
                    </td> */}
                    <td onClick={() => this.openGroup(g)}>                       
                        <SvgIcon  icon={ vaihto } type="solid" />
                    </td>
                   
                   
                    <td></td>
                    <td className="nameTd">
                        <ApInputSmall
                            type="text"
                            value={g.name ? g.name : ''}
                            onChange={(e) => { this.setGroupName(g, e.target.value) }}
                            disabled={disabled}
                            validationState={(g.name) ? null : 'error'}
                        />
                    </td>
                    <td>&nbsp;</td>
                    {(this.state.type === 'delivery') ? <td key="reserve" className="fixed c-target"></td> : <td key="entry" className="fixed c-entry"></td>}
                    <td className="fixed c-count">
                        <ApInputSmall
                            value={g.count}
                            noFocusDecimals={2}
                            disabled
                            validationState={countError}
                        />
                    </td>
                    <td className="fixed c-unit">
                        <ApInputSmall
                            type="text"
                            value={g.unit ? g.unit : ''}
                            onChange={(e) => { this.setGroupUnit(g, e.target.value) }}
                            disabled={disabled}
                        />
                    </td>
                    <td className="fixed">
                        <ApInputSmall
                            value={singlePrice}
                            disabled
                            noFocusDecimals={2}
                            validationState={priceError}
                        />
                    </td>
                    <td className="fixed">
                        {this.renderGroupDiscountPercent(g)}
                    </td>
                    <td key="vat" className="fixed vat">
                        {this.renderGroupVat(g)}
                    </td>
                    <td key="netvisor-vat" className="fixed vat">
                        {this.renderOrderNetvisorGroupVat(g)}
                    </td>
                    {this.props.costCenterEnabled &&
                        <td key="cost-center" className="fixed vat">
                            {this.renderGroupCostCenter(g)}
                        </td>}
                    <td key="tota_price" className="fixed total">
                        {formatMoney(totalPrice)} {this.state.currencySign}
                    </td>
                    <td key="dispatch_ref" className="fixed dispatch-ref">

                    </td>
                    {statusTd}
                    <td className="fixed actions">
                        {this.renderGroupMenu(g)}
                    </td>
                </tr>
                {rowDoms}
            </React.Fragment>
        }
        

                        /*
                        onChange={ ( e ) => { this[ o.funcName ]( c, e.target.value ) } }
                        onBlur={   ( e ) => { this[ o.funcName ]( c, e.target.value, true ) } }
                        disabled={ o.disabled || this.props.locked }
                        unit={ unit }
                        noFocusDecimals={ decimal }
                        tooltip= { tooltip }
                        */
    }

    renderGroupMenu( g )
    {
        let actions = [];

        let disabled = false;
        if( this.props.orderPriceLocked ) disabled = true;

        if( !disabled )
        {
            actions.push({
                label: tr('disassemble_group'),
                icon: "object-ungroup",
                action: ( id, closeFunc ) => {
                    this.breakGroup( g );
                    closeFunc();
                },
            });
        }

        if( actions.length )
            return <ApDropdown actions={ actions } />
        return null;
    }

    handleSortColumnClick(column) {
        // Order first by ascending, then by descending, then remove sorting
        let order = localStorage.getItem(this.props.instance + '/cit_sort_order');
        let col = localStorage.getItem(this.props.instance + '/cit_sort_column');
        if (col === column) {
            if (order === 'asc') {
                order = 'desc';
            } else if (order === 'desc') {
                order = null;
                col = null;
            } else {
                order = 'asc';
            }
        } else {
            order = 'asc';
        }
        if (order) {
            localStorage.setItem(this.props.instance + '/cit_sort_order', order);
        } else {
            localStorage.removeItem(this.props.instance + '/cit_sort_order');
        }
        localStorage.setItem(this.props.instance + '/cit_sort_column', column);

        this.setState({sortColumn: column, sortOrder: order});
    }

    handleSort(a,b) {
        const textCols = ['name', 'unit'];
        const numberCols = ['purchase_order_price', 'order_price', 'count', 'id'];
        let column = localStorage.getItem(this.props.instance + '/cit_sort_column');
        let order = localStorage.getItem(this.props.instance + '/cit_sort_order');
        if (!column || !order) {
            column = 'id';
            order = 'asc';
        }
        if (textCols.includes(column)) {
            if (!a[column]) {
                return order === 'asc' ? 1 : -1;
            }
            if (!b[column]) {
                return order === 'asc' ? -1 : 1;
            }
            const compare = a[column].localeCompare(b[column]);
            return order === 'asc'
                ? compare
                : -compare;
        }
        if (numberCols.includes(column)) {
            if (!a[column] || isNaN(a[column])) {
                return order === 'asc' ? 1 : -1;
            }
            if (!b[column] || isNaN(b[column])) {
                return order === 'asc' ? -1 : 1;
            }
            if (order === 'asc') {
                return parseFloat(a[column]) - parseFloat(b[column]);
            } else {
                return parseFloat(b[column]) - parseFloat(a[column]);
            }
        }
        if (a[column] < b[column]) {
            return order === 'asc' ? -1 : 1;
        } else if (a[column] > b[column]) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    }

    getSortIcon(colName) {
        if (localStorage.getItem(this.props.instance + '/cit_sort_column') === colName && localStorage.getItem(this.props.instance + '/cit_sort_order')) {
            return localStorage.getItem(this.props.instance + '/cit_sort_order') === 'asc' ? 'sort-up' : 'sort-down';
        }
        return 'sort';
    }

    renderCTHeader(objKey, label = null) {
        if (!label) label = objKey;
        return <span className='pointer' onClick={() => this.handleSortColumnClick(objKey)}>
            {tr(label)} <SvgIcon icon={this.getSortIcon(objKey)} type="solid" />
        </span>
    }

    renderCT()
    {
        let header = this.renderInstanceCTColumns( 'header' )
        // let rows = this.props.components.map( this.renderCTRow );

        // The plus 5 comes from shared columns
        let colSpan = 5 + header.length;

        let showSelect = false;
        if( SHOW_SELECT_INSTANCES.includes( this.props.instance ) ) {
            showSelect = true;
            colSpan++;
        }

        let showGroup = true;
        if( NO_GROUP_INSTANCES.includes( this.props.instance ) ) {
            showGroup = false;
            colSpan--;
        }

        let showMenu = true;
        if( NO_MENU_INSTANCES.includes( this.props.instance ) ) {
            showMenu = false;
            colSpan--;
        }

        let showSaveNew = true;
        if( NO_SAVE_AS_NEW_INSTANCES.includes( this.props.instance ) ) {
            showSaveNew = false;
            colSpan--;
        }

        let groups = [];
        let others = this.props.components;
        others.sort(this.handleSort);

        if( this.props.instance === 'order' )
        {

            let cGroups = groupify(this.props.components, 'order_group_id');
            let gIds = [];

            let sortedGroups = this.props.groups?.slice(0) || [];
            sortedGroups = sortedGroups.map(group => {
                group.count = null;
                group.order_price = null;
                if (cGroups[group.id]) {
                    let count = 0;
                    let prices = [];
                    cGroups[group.id].forEach(c => {
                        count += parseFloat(c.count)
                        prices.push(parseFloat(c.order_price))
                    });
                    group.count = count;
                    group.order_price = prices.reduce((a, b) => a + b, 0) / prices.length;
                }
                return group;
            });            
            sortedGroups.sort(this.handleSort);
            groups = sortedGroups.map( g => {
                gIds.push( g.id );
                return this.renderGroup( g, cGroups[ g.id ] || [], colSpan );
            });
            others = cGroups[ undefined ];
        }

        let rows = ( others ) ? others.map( c => this.renderCTRow( c, true ) ) : [];

        if( !rows.length && !groups.length )
            rows.push( this.renderCTRowEmpty( colSpan ) );

        const nameSortIcon = this.getSortIcon('name');

        if (this.props.instance === 'delivery') {
            return <div>
                <table className="componentTable">
                    <thead className="table-head">
                        <tr>
                            <th></th>
                            <th className='pointer' onClick={() => this.handleSortColumnClick('name')}>
                                {tr('name')} <SvgIcon icon={nameSortIcon} type='solid' />
                            </th>
                            { header }
                            <th style={{minWidth: '50px', width: '50px', maxWidth: '50px'}}></th> {/* Actions cell */}
                        </tr>
                    </thead>
                    <tbody>
                        { groups }
                        { rows }
                    </tbody>
                </table>
            </div>
        }

        return <div>
            <table className="componentTable">
                <thead className="table-head">
                    <tr>
                        {showSelect ? <th></th> : null} {/* Select */}
                        {showGroup ? <th></th> : null} {/* Group */}
                        <th></th> {/* Icon */}
                        <th className='pointer' onClick={() => this.handleSortColumnClick('name')}>
                            {tr('name')} <SvgIcon icon={nameSortIcon} type='solid' />
                        </th>
                        {showSaveNew ? <th></th> : null} {/* save component as new toggle */}
                        { header }
                        {showMenu ? <th></th> : null} {/* Actions */}
                    </tr>
                </thead>
                <tbody>
                    { groups }
                    { rows }
                </tbody>
            </table>
        </div>
    }

    renderCTRowEmpty( colSpan )
    {
        let content = <div>{ tr('no_storage_components_selected') }</div>
        if( this.props.loading ) content = <div className="apLoader"></div>

        return <tr key=" empty">
            <td colSpan={ colSpan } className="noSelect">
                { content }
            </td>
        </tr>
    }

    renderSelect( c, selected, showCheckBox )
    {
        // if( this.props.instance !== 'order' ) return null;

        let disabled = false;
        if (this.props.selectLocked) {
            disabled = true;
        }

        if (this.props.selectLocked == undefined && this.props.nameLocked ) disabled = true;

        if( disabled ) return null;
        return <input
            type={showCheckBox ? "checkbox" : "hidden"}
            checked={ selected }
            readOnly
        />
    }

    selectRow( c )
    {
        let selected = this.state.selected.slice(0);
        if( selected.includes( c.id ) )
            selected = selected.filter( s => s !== c.id );
        else
            selected.push( c.id );
        this.setState({ selected: selected });
    }

    createGroup()
    {
        this.newCounter++;
        const gId = `new${ this.newCounter }`;

        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( this.state.selected.includes( c.id ) )
                c.order_group_id = gId;
            return c;
        });
        this.setState({ selected: [] });

        this.props.componentsChange( components,() => {
            let groupComponents = components.filter( c => c.order_group_id === gId );

            let name = tr('new_group');
            if( groupComponents.every( gc => gc.name === groupComponents[0].name ) )
                name = groupComponents[0].name;

            let count = 0;
            groupComponents.forEach( gc =>  count += parseFloat( gc.count ) );

            let groups = this.props.groups.slice(0);
            groups.push({
                id: gId,
                name: name,
                count: count,
                open: true,
            })
            this.props.groupsChange( groups );
        });
    }

    breakGroup( g )
    {
        //console.log('break group', g );

        let components = this.props.components.slice( 0 );
        components = components.map( c => {
            if( g.id === c.order_group_id )
                c.order_group_id = null;
            return c;
        });
        this.props.componentsChange( components );
    }

    calculateGroupsCounts() {
        let groups = this.props.groups.slice(0);
        groups = groups.map(g => {
            let components = this.props.components.slice(0);
            let groupComponents = components.filter(c => c.order_group_id === g.id);
            let count = 0;
            groupComponents.forEach(gc => count += parseFloat(gc.count));
            g.count = count;

            return g;
        });

        this.props.groupsChange(groups);
    }

    addToGroup( g )
    {
        const  selected = this.state.selected.slice(0);
        let components = this.props.components.slice(0);
        let count = 0;
        components = components.map( c => {
            if (selected.includes(c.id)) {
                c.order_group_id = g.id;
                count += parseFloat(c.count);
            }
            return c;
        });
        // this.setGroupCount(g.id, parseFloat(g.count) + count);
        this.props.componentsChange(components);
        this.calculateGroupsCounts();
        this.setState({ selected: [] });
    }

    removeFromGroup( g )
    {
        const  selected = this.state.selected.slice(0);
        let components = this.props.components.slice(0);
        let count = 0;
        components = components.map( c => {
            if( selected.includes( c.id ) ){
                c.order_group_id = null;
                count += parseFloat(c.count);
            }
            return c;
        });
        // this.setGroupCount(g.id, parseFloat(g.count) - count);
        this.props.componentsChange(components);
        this.calculateGroupsCounts();
        this.setState({ selected: [] });
    }

    openGroup( group )
    {
        let groups = this.props.groups.slice(0);
        groups = groups.map( g => {
            if( g.id === group.id )
                g.open = !g.open;
            return g;
        });
        this.props.groupsChange( groups );
    }

    setGroupName( group, value )
    {
        let groups = this.props.groups.slice(0);
        groups = groups.map( g => {
            if( g.id === group.id )
                g.name = value;
            return g;
        });
        this.props.groupsChange( groups );
    }

    setGroupCount( group_id, value )
    {
        value = onlyNumber( value, true );

        let groups = this.props.groups.slice(0);
        groups = groups.map( g => {
            if( g.id === group_id )
                g.count = value;
            return g;
        });
        this.props.groupsChange( groups );
    }

    setGroupUnit( group, value )
    {
        let groups = this.props.groups.slice(0);
        groups = groups.map( g => {
            if( g.id === group.id )
                g.unit = value;
            return g;
        });
        this.props.groupsChange( groups );
    }


    renderCTRow( c, last = false )
    {

        let trClass = [];
        if( c.order_group_id ) trClass.push( 'inGroup' );
        if( last ) trClass.push( 'groupLast' );

        const selected = this.state.selected.includes( c.id );
        
        let saveAsNew = <td className='fixed'></td>
        if (this.props.instance != 'po-import' && c !== null) {
            if (c.save_as_new !== undefined) {
                saveAsNew = <td className="saveNew">
                    <ApTooltip text={tr('save_as_new_component')} block position="top">
                        <SvgIcon icon="save" type="solid" />
                        <ApSwitch
                            id={`save_as_new-${c.id}-switch`}
                            small
                            on={c.save_as_new}
                            onChange={() => { this.changeSaveAsNew(c) }}
                            disabled={this.props.loading || this.props.locked}
                        />

                    </ApTooltip>
                </td>
            }
        }

        let style = {};
        if (c.linkedComponent) {
            style.backgroundColor = 'hsl(123deg 40% 92%)';
        }

        if (this.props.instance === 'delivery') {
            return (
                <tr style={style} key={ c.fivaldi_id ? c.fivaldi_id : c.id } className={ trClass.join(' ') } >
                    <td className="fixed c-type" style={{minWidth: '50px', width: '50px', maxWidth: '50px'}}>
                        { this.renderType( c ) }
                    </td>
                    <td className="c-name">
                        { this.renderName( c ) }
                    </td>                    
                        { this.renderInstanceCTColumns( 'row', c ) }
                    <td className="fixed actions" style={{minWidth: '50px', width: '50px', maxWidth: '50px'}}>
                        { this.renderMenu( c ) }
                    </td>
                </tr>
            )
        }

        let key = c.id;
        if (c.fivaldi_id) key = c.fivaldi_id;
        if (c.projectComponentId) key = c.projectComponentId;
        if (c.installation_entry_id) key = c.installation_entry_id;

        let showSelect = false;

        if (SHOW_SELECT_INSTANCES.includes(this.props.instance)) {
            showSelect = true;
        }

        let showGroup = true;
        if (NO_GROUP_INSTANCES.includes(this.props.instance)) {
            showGroup = false;
        }

        let showMenu = true;
        if (NO_MENU_INSTANCES.includes(this.props.instance)) {
            showMenu = false;
        }

        let showSaveNew = true;
        if (NO_SAVE_AS_NEW_INSTANCES.includes(this.props.instance)) {
            showSaveNew = false;
        }

        return <tr style={style} key={ key } className={ trClass.join(' ') } >
            {showSelect 
                ? <td className="selectTd" onClick={ () => this.selectRow( c ) }> { this.renderSelect( c, selected, showSelect ) } </td>
                : null
            }
            {showGroup ? <td className="groupTd">
                {c.linkedComponent
                    ? <ApTooltip text={tr('defined_in_po')} block position="topleft">
                        <SvgIcon type='solid' icon='clipboard-check' fill='var(--clr-success-main)' />
                    </ApTooltip>
                    : null}
            </td> : null}

            <td className="fixed c-type">
                { this.renderType( c ) }
            </td>
            <td className="c-name">
                { this.renderName( c ) }
            </td>
            {showSaveNew ? saveAsNew : null}
            
            
            {/*
            <td className="fixed c-price">
                { this.renderValue( c ) }
            </td>
            <td className="fixed c-balance">
                { this.renderBalance( c ) }
            </td>
            */}
                { this.renderInstanceCTColumns( 'row', c ) }
            {showMenu 
            ? <td className="fixed actions">
                { this.renderMenu( c ) }
            </td> 
            : null}
        </tr>
    }

    renderName( c )
    {
        let disabled = false;
        let tooltip = "";
        if( this.props.nameLocked ) disabled = true;

        let className = [ 'componentNameInput' ];
        if (!c.name) {
            className.push('input-error');
        }
        const interfaces = this.props.externalInterfaces;
        if (interfaces) {
            if (interfaces.find(i => i === 'fivaldi_new')) {
                if (c.name?.length > 80) {
                    className.push('input-error');
                    tooltip = "Fivaldi: " + tr('name_length_error')
                }
            }
            else if (interfaces.find(i => i === 'netvisor')) {
                if (c.name?.length > 200) {
                    className.push('input-error');
                    tooltip = "Netvisor: " + tr('name_length_error')
                }
            }
        }

        // Check if id is string, if it is, it's a new component and should have an input field
        if (this.props.instance === 'assignment' && typeof c.id !== 'string') {
            return (
                <div style={{minHeight: '30px', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    <div className="">{ c.name }</div>
                    <div className="">{ c.code }</div>
                </div>
            );
        }

        if( c.component_id )
        {
            if (this.props.instance === 'po-import') {
                return (
                    <ApTooltip position='topleft' text={<div>{c.name} <br/> {c.code ? c.code : ""}</div>}>
                        <div>
                            <small className="noWordWrap">{ c.code }</small>
                            <input
                                className='componentNameInput noWordWrap'
                                type="text"
                                value={ c.name ? c.name : '' }
                                onChange={ ( e ) => { this.setNamePOimport( c, e.target.value ) } }
                                disabled={ disabled || c.linkedComponent }
                            />
                        </div>
                    </ApTooltip>
                );
            }

            if (this.props.editableName && this.props.showCodeInEditableName) {
                className.push("name noWordWrap");
                return<div>
                    <input
                        className={className.join(' ')}
                        type="text"
                        value={c.name ? c.name : ''}
                        onChange={(e) => { this.setName(c, e.target.value) }}
                        disabled={disabled}
                    />
                <small title={ c.code } className="noWordWrap">{ c.code }</small>
            </div>
                
            }

            if (this.props.editableName) {
                if (tooltip) {
                    return <ApTooltip position="topleft" text={ tooltip }>
                        <input
                            className={className.join(' ')}
                            type="text"
                            value={c.name ? c.name : ''}
                            onChange={(e) => { this.setName(c, e.target.value) }}
                            disabled={disabled}
                        />
                    </ApTooltip>
                }

                return <input
                    className={ className.join(' ') }
                    type="text"
                    value={ c.name ? c.name : '' }
                    onChange={ ( e ) => { this.setName( c, e.target.value ) } }
                    disabled={ disabled }
                />
            }

            return <div style={{minHeight: '30px'}}>
                <span title={ c.name } className="name noWordWrap">{ c.name }</span>
                <small title={ c.code } className="noWordWrap">{ c.code }</small>
            </div>
        }

        if( disabled ) return <div><span title={ c.name } className="noWordWrap">{ c.name }</span></div>

        if( this.props.instance == 'po-import' )
        {
            return <input
                        className={ className.join(' ') }
                        type="text"
                        value={ c.name ? c.name : '' }
                        onChange={ ( e ) => { this.setNamePOimport( c, e.target.value ) } }
                        disabled={ disabled }
                    />
        }

        return <input
            className={ className.join(' ') }
            type="text"
            value={ c.name ? c.name : '' }
            onChange={ ( e ) => { this.setName( c, e.target.value ) } }
            disabled={ disabled }
        />
    }

    renderMenu( c )
    {
        if (!c.component_id && this.props.instance!='po-import') {
            if (!this.props.removeComponentLocked && this.props.instance!='assignment') {
                return <SvgIcon
                    icon="trash-alt"
                    onClick={() => this.removeComponent(c)}
                    className="icon-button"
                />
            }
            return;
        }

        let actions = [];

        if( this.state.selected.includes( c.id ) )
            {
                actions.push({
                    label: tr('add_as_group'),
                    icon: "object-group",
                    action: ( id, closeFunc ) => {
                        this.createGroup()
                        closeFunc();
                    },
                });
            }

            if (this.props.instance!='assignment' && this.props.instance !== 'delivery' && c.component_id) {
                actions.push({
                    label: tr('edit'),
                    icon: "edit",
                    action: ( id, closeFunc ) => {
                        this.setState({
                            componentEditShow: true,
                            componentEditId: c.component_id,
                        });
                        closeFunc();
                    },
                });
        }
        

            if( !this.props.removeComponentLocked && !(this.props.instance=='assignment' && c.in_billing))
            {
                actions.push({
                    label: tr('delete'),
                    icon: "trash-alt",
                    action: ( id, closeFunc ) => {
                        this.removeComponent( c );
                        closeFunc();
                    },
                });
            }

            if(this.props.instance=='po-import')
            {
                if (c.import_name != null) {
                    actions.push({
                        label: tr('link_to_previous'),
                        icon: "link",
                        action: ( id, closeFunc ) => {
                            this.props.linkComponent( c );
                            closeFunc();
                        },
                    });
    
                    actions.push({
                            label: 'vaihda tyyppiä',
                            icon: "exchange-alt",
                            action: ( id, closeFunc ) => {
                                this.props.changeType( c );
                                closeFunc();
                            },
                        });
    
                    actions.push({
                        label: 'Lisää uutena komponenttina',
                        icon: "plus-circle",
                        action: ( id, closeFunc ) => {
                        this.props.saveAsNew( c );
                            closeFunc();
                        },
                    });
    
                    actions.push({
                        label: 'Lisää ulkopuolisena komponenttina',
                        icon: "plus",
                        action: ( id, closeFunc ) => {
                        this.props.saveAsOutside( c );
                            closeFunc();
                        },
                    });
                } else {
                    actions.push({
                        label: tr('delete'),
                        icon: "trash-alt",
                        action: ( id, closeFunc ) => {
                            this.props.removeComponent( c );
                            closeFunc();
                        },
                    });
                }
            }

            if (!actions.length) return null;

            return <ApDropdown
                actions={ actions }
            />

        }

        renderInstanceCTColumns( type, c = null )
        { 
            //console.log("TYPEEEE");
            //console.log(this.props.instance);
            const instance = this.props.instance;
            if( instance === 'po' )
                return this.renderCTPoColumns( c, ( type === 'header' ) )
            else if( instance === 'order' )
                return this.renderCTOrderColumns( c, ( type === 'header' ) )
            else if( instance === 'arrival' )
                return this.renderCTArrivalColumns( c, ( type === 'header' ) )
            else if( instance === 'delivery' )
                return this.renderCTDeliveryColumns( c, ( type === 'header' ) )
            else if( instance === 'assignment' )
                return this.renderAssignmentColumns( c, ( type === 'header' ) )
            else if( instance === 'po-import' )
                return this.renderPOimportColumns( c, ( type === 'header' ) )
            else if ( instance === 'supplier_offer' )
                return this.renderSupplierOfferColumns( c, (type === 'header' ) )
            else if ( instance === 'woocommerce_order' )
                return this.renderWooCommerceColumns( c, (type === 'header' ) )
            else if ( instance === 'contract_billing' )
                return this.renderContractBillingColumns( c, (type === 'header' ) )
        }

        renderFinalTargetSelected( c )
        {
        let title = <div> { tr('not_selected') } </div>
        
        if( c.target_final_type === 'location' )
        {
            let selected = c.locations.find( l => l.id === c.target_final_id );
            if( selected )
            {
                title = <div className="target-container">
                    <div>&nbsp;</div>
                    <div className="target-name"> { tr('storage') } </div>
                    <div className="target-code"> { selected.code } </div>
                    <div className="target-order"> { tr('balance') }: { selected.balance_free } { c.unit } </div>
                </div>
            }
            /*else
            {
                console.error('STORAGE LOCATION NOT FOUND' );
                //console.log('STORAGE LOCATION NOT FOUND' );
            }
            */
        }
        if( c.target_final_type === 'project' )
        {
            let selected = c.locations.find( l => l.id === c.target_final_id );
            if( selected )
            {
                title = <div className="target-container">
                    <div>&nbsp;</div>
                    <div className="target-name"> { tr('project') } </div>
                    <div className="target-code"> { selected.code } </div>
                    <div className="target-order"> { tr('balance') }: { selected.balance_free } { c.unit } </div>
                </div>
            }
            /*else
            {
                console.error('STORAGE LOCATION NOT FOUND' );
                //console.log('STORAGE LOCATION NOT FOUND' );
            }
            */
        }
        else if( c.target_final_type === 'company' )
        {
            let selected = c.demands.find( d => d.demand_id === c.id );

            if( selected )
            {
                title = <div className="target-container">
                    <div>&nbsp;</div>
                    <div className="target-name"> { tr('company') } </div>
                    <div className="target-code"> { selected.name } </div>
                    <div className="target-order"> { tr('order') }: { selected.order_number } </div>
                </div>
            }
            else
            {
                console.error('DEMAND NOT FOUND' );
                //console.log('DEMAND NOT FOUND' );
            }
        }
        return title;
    }

    renderType( c )
    {
        let icon = null;
        if( c.type_name === 'item' )
            icon = <SvgIcon icon="puzzle-piece" type="solid" />
        else if( c.type_name === 'work' )
            icon = <SvgIcon icon="user-clock" type="solid" />
        else if( c.type_name === 'other' )
            icon = <SvgIcon icon="money-bill-wave" type="solid" />
        else if( c.type_name === 'shipping' )
            icon = <SvgIcon icon="truck" type="solid" />

        let tooltip = c.type_text ? tr(c.type_text) : null;
        if(this.props.instance === 'order' || this.props.instance === 'po' || this.props.instance === 'assignment') {
            tooltip = <div>
                { c.type_text ? tr(c.type_text) : null }
                {c.identifiers && c.identifiers.length
                    ? <div style={{paddingTop: '1em', textAlign: 'left'}}>
                        <table className="calcTable">
                            <thead>
                                <tr>
                                    <th colSpan="2"  className="header">{ tr('identifiers') }</th>
                                </tr>
                            </thead>
                            <tbody>
                                {c.identifiers.map((i, index) => {
                                    return <tr key={ index }>
                                        <td className="infoLabel">{ i.name }: </td>
                                        <td className="infoUnit">{ i.pivot ? i.pivot.value : null } </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        </div>
                    : null
                }
            </div>
        }

        return <ApTooltip position="topleft" text={ tooltip }>
            { icon }
        </ApTooltip>

            // <span style={{ position: 'absolute', left: '0', top: '0'  }} >{ c.id }</span>
    }

    renderBalance( c )
    {
        if( !c.locations )
            return tr('storages_are_missing').toUpperCase();;

        if( c.locations.length === 0 )
            return <ApTooltip position="top" text={ tr('not_stored_in_storage') }>
                <div>-</div>
            </ApTooltip>

        let totalBalance = 0;
        let totalBalanceFree = 0;
        let tooltipRows = [];

        c.locations.forEach( ( l, i) => {
            totalBalance += parseFloat( l.balance );
            totalBalanceFree += parseFloat( l.balance_free );
            tooltipRows.push( <div key={ i }>
                <span className="location">{ tr('location') }: { l.code }</span>
                <small>{ tr('amount').toLowerCase() }: { l.balance } { c.unit } ( { tr('free').toLowerCase() }: { l.balance_free } { c.unit } )</small>
            </div> );
        });

        let tooltip = <div className="balanceTooltip"> { tooltipRows } </div>

        let helper1 = null;
        if( c.locations.length > 1 )
            helper1 = <small className="location-number"> { c.locations.length } { tr('in_different_location') }</small>

        let helper2 = null;
        if( totalBalance !== totalBalanceFree )
            helper2 = <small className="reserve"> { totalBalanceFree } { tr('free').toLowerCase() } </small>

        return <div>
            { helper1 }
            <ApTooltip position="top" text={ tooltip }> { totalBalance } { c.unit } </ApTooltip>
            { helper2 }
        </div>
    }

    renderValue( c )
    {
        if( !c.component_id )  return null;

        const tooltip = <div>
            { tr('storage_value') } <br />
            <small> { tr('sales_value') } </small>
        </div>

        return <div>
            <ApTooltip position="top" text={ tooltip }>
                { formatMoney( c.price ) } { this.state.currencySign }
            </ApTooltip>
            <small className="price"> { tr('sales') }: { formatMoney( c.price_sell ) } { this.state.currencySign } </small>
        </div>
    }

    // ----------------------------------------
    //  PurchaseOrder columns
    // ----------------------------------------

    renderCTPoColumns( c, header = false )
    {
        if( header )
        {
            const headers = [
                tr('storage_location'),
                this.renderCTHeader('count', 'amount'),
                this.renderCTHeader('unit'),
                this.renderCTHeader('purchase_order_price', 'purchase_price'),
            ];

            if (this.props.useTaxGroups) {
                headers.push(tr('tax_group'));
            }
            else {
                headers.push(tr('vat'));
                headers.push(tr('vat_code'));
            }
            headers.push(tr('total_price'));

            return headers.map((n, i) => <th key={i}>{n}</th>);
        }

        const data = [
            <td key="potarget" className="fixed c-target">
                {this.renderPOTarget(c)}
            </td>,

            <td key="count" className="fixed c-count">
                {this.renderPOCount(c)}
            </td>,

            <td key="unit" className="fixed c-unit">
                {this.renderPOUnit(c)}
            </td>,

            <td key="price" className="fixed">
                {this.renderPOPrice(c)}
            </td>,
        ];

        if (this.props.useTaxGroups) {
            data.push(
                <td key="vat" className="">
                    {this.renderPurchaseOrderTaxGroup(c)}
                </td>
            );
        }
        else {
            data.push(
                <td key="vat" className="fixed vat">
                    {this.renderPuchaseOrderVat(c)}
                </td>,
            );
            data.push(
                <td key="netvisor-vat" className="fixed netvisor-vat">
                    {this.renderOrderNetvisorVat(c)}
                </td>,
            );
        }


        data.push(
            <td key="tota_price" className="fixed">
                {formatMoney(parseFloat(c.count) * parseFloat(c.purchase_order_price))} {this.state.currencySign}
            </td>,
        );

        return data;
    }

    renderAssignmentColumns( c, header = false )
    {
        const hasFiles = this.props.components.find( comp => comp.files && comp.files.length > 0 );
        if( header )
        {
            return [
                '', tr('allocated'),tr('installed'),tr('balance'),tr('files')
            ].map( ( n, i ) => {
                let header = n;
                if (i === 4 && !hasFiles) return null;
                return <th key={ i } className={i === 0 ? "hideUnder900" : ""}>{ header }</th>
            });
        }

        const filesTd = hasFiles ? <td key="files" className="fixed c-count">
            { this.renderAssignmentFileIcon( c ) }
        </td> : null;

        return [
            <td key="from" className="fixed c-count hideUnder900">
                { this.renderFromProject( c ) }
            </td>,
            <td key="count" className="fixed c-count">
                {this.renderAssignmentAllocatedCount(c)}
            </td>,
            <td key="count" className="fixed c-count">
                { this.renderAssignmentCount( c ) }
            </td>,
            <td key="balance" className="fixed c-count">
                { this.renderAssignmentBalance( c ) }
            </td>,
            filesTd,
        ];
    }

    renderPOTarget( c )
    {
        //console.log(c);
        if( c.type_name === 'work' ) return null;
        if( c.type_name === 'other' ) return null;

        const title = this.renderFinalTargetSelected( c );
        let options = [];
        c.locations.forEach( l => {
            
            const typeOfStorage = l.type == 'P' ? tr('project_storage') : tr('storage');


            let dom = <div>
                {typeOfStorage}<small> { l.code } </small><br />
                <small> { tr('balance') }: { l.balance } { c.unit } ( { tr('free').toLowerCase() }: { l.balance_free } { c.unit } )</small>
            </div>

            options.push({
                label: dom,
                icon: "archive",
                action: ( id, closeFunc ) => {
                    this.setPOTarget( c, l.type, l );
                    closeFunc();
                },
            })
        });
        
        c.demands.forEach( d => {
            let icon = null;
            let dom  = null;

            // Check that current demand is not defined in order before this
            const found = this.props.components.find( c => c.id === d.demand_id );
            if( found ) return null;

            if( d.target_final_type === 'company' )
            {
                icon = 'building';
                dom = <div>
                    { tr('company') } <small> { d.name } </small><br />
                    <small> { tr('amount') }: { d.count } { c.unit } </small><br />
                    <small> { tr('order') }: { tr('number').toLowerCase() } { d.order_number }</small>
                </div>
            }
            else
            {
                console.error( 'UNKNOWN d.target_final_type' );
            }

            options.push({
                label: dom,
                icon: icon,
                action: ( id, closeFunc ) => {
                    this.setPOTarget( c, d.target_final_type, d );
                    closeFunc();
                },
            })
        });

        if( options.length === 0 && c.component_id != null ) //Jos ei ole ulkopuolinen komponentti
        {
            
            options.unshift( {
                label: <div className="no-select">{ tr('purchase_order_target_no_select') }</div>,
                action: ( id, closeFunc ) => {
                    closeFunc();
                },
            })
            options.push({
                label: <div >{tr('create_new')}</div>,
                icon: 'folder',
                action: ( id, closeFunc ) => {
                    this.onCreateNew(c.component_id, c.id);
                    closeFunc();
                },
            })

        }
        else
        {
            /*options.unshift( {
                    label: <div className="no-select">{ tr('not_selected') }</div>,
                    action: ( id, closeFunc ) => {
                        this.setPOTarget( c );
                        closeFunc();
                    },
            });*/
            if (this.props.receiverType!='project') {   
                options.push({
                    label: <div >{tr('create_new')}</div>,
                    icon: 'folder',
                    action: ( id, closeFunc ) => {
                        this.onCreateNew(c.component_id, c.id);
                        closeFunc();
                    },
                })
            }
        }

        let disabled = false;
        if( this.props.poTargetLocked ) disabled = true;
        if( c.events.length ) disabled = true;

        if( disabled )
            return <div className="input-menu disabled" disabled>{ title }</div>

        let className = [ 'input-menu' ];
        if( !c.target_final_type && c.component_id != null ) className.push( 'input-error' );
        if (!c.target_final_id) className.push('input-error');

        const dropdown  = <ApDropdown
            fullWidth
            button={ <div className={ className.join(' ') }> { title } <div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
            actions={ options }
        />

        return dropdown;
    }

    
    renderFromProject( c )

    {
        let from='';
        if (c.from_project) {
            from='Määritetty projektissa';
        }
        if (c.in_billing) {
            from=from+' '+ (c.billing ? tr('in_billing') : tr('not_billed'));
        }
        return <div className="from-container">{from}</div>
    }

    renderAssignmentAllocatedCount(c) {
        let input = <div className="count-container">
            {c.alloc_count ?? 0} {c.unit}
        </div>
        return input;
    }

    renderAssignmentCount( c )
    {
        let disabled = false;
        if (c.in_billing) {
            disabled=true;
        }

        let className = [ 'componentRowInput' ];
        if( !c.count && !c.from_project ) className.push( 'input-error' );
        let input = <div className="count-container">
                <input
                    className={ className.join(' ') }
                    type="text"
                    value={ c.count ? c.count : '' }
                    onChange={ ( e ) => { this.setCount( c, e.target.value ) } }
                    onBlur={   ( e ) => { this.setCount( c, e.target.value, true ) } }
                    disabled={ disabled }
                />
                <div className="unit" style={{textAlign: 'center'}}>{ c.unit }</div>
            </div>
        return input;
    }

    renderAssignmentBalance( c )
    {

        let input = <div className="count-container">
                {c.balance} { c.unit }
            </div>
        return input;
    }

    renderAssignmentFileIcon( c ) {
        let element = null;
        if (Array.isArray(c.files) && c.files.length) {
            element = <ApButton size="small" onClick={() => this.setState({selectedComponentFiles: c.files})}>
                <SvgIcon type="solid" icon="file" />
            </ApButton>;
        }

        return element;
    }

    renderAssignmentComponentFilesModal() {
        if (this.props.instance !== 'assignment') return null;
        let body = <div className='padding'>{tr('no_files_added')}</div>;
        if (Array.isArray(this.state.selectedComponentFiles) && this.state.selectedComponentFiles.length) {
            body = <div className='padding'>
                {this.state.selectedComponentFiles.map(i => {
                    return <div key={ i.id } className="files listStack">
                        <ApInputStack gap="0">
                            <ApAddon onClick={() => this.getFile(i.id, i.original_name)}>
                                <div className="pointer">
                                    <SvgIcon 
                                        className="showImageBtn pointer"
                                        icon="file" 
                                        type="solid"
                                    />
                                    { i.original_name }
                                </div>
                            </ApAddon>
                        </ApInputStack>
                    </div>
                })}
            </div>
        }

        const footer = <div className='padding justify-end'>
            <ApButton onClick={() => this.setState({selectedComponentFiles: null})}>{tr('cancel')}</ApButton>
        </div>

        return <ApModal
            show={Boolean(this.state.selectedComponentFiles)}
            className="narrow"
            handleClose={() => this.setState({selectedComponentFiles: null})}
            onClose={() => this.setState({selectedComponentFiles: null})}
            closeFromBg
            header={<div className='padding'><h3>{tr('files')}</h3></div>}
            body={body}
            footer={footer}
        />
    }

    getFile(id, name)
	{
		return api({
			method: 'get',
			url: `${ apiurl }file/id/${ id }`,
			responseType: 'blob',
		}).then(( response ) => {
			const url = window.URL.createObjectURL( new Blob([ response ]) );
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', name );
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			this.setState({ loading: false, showImageModal: false });
		}).catch( ( error ) => {
			errorPopper(error, tr('file_download_error'));
			this.setState({ loading: false, showImageModal: false });
		});

	}


    renderPOCount( c )
    {
        let disabled = false;
        if( this.props.poCountLocked ) disabled = true;

        if( c.type_name !== 'other' )
            if( c.target_final_type !== 'location' ) disabled = true;

        let className = [ 'componentRowInput' ];
        if( !c.count ) className.push( 'input-error' );

        return <div className="count-container">
            <input
                className={ className.join(' ') }
                type="text"
                value={ c.count ? c.count : '' }
                onChange={ ( e ) => { this.setCount( c, e.target.value ) } }
                onBlur={   ( e ) => { this.setCount( c, e.target.value, true ) } }
                disabled={ disabled }
            />
            {/* <div className="unit" style={{textAlign: 'center'}}>{ c.unit }</div> */}
            { Boolean( c.purchase_order_arrived ) && <div className="count-helper-top">{ tr('arrived') }: { Number((c.purchase_order_arrived * (c.selectedUnit ? c.selectedUnit.conversion_factor : 1)).toFixed(5)) }</div> }
        </div>
    }

    renderPOUnit(c) {
        let disabled = false;
        if (this.props.poPriceLocked) disabled = true;

        if (!c.component_id || (!c.unit && (c.conversion_units && c.conversion_units.length == 0) ) || !c.conversion_enabled) { // Varaston ulkopuolinen tai muuntoyksikkö ei käytössä
            return <ApInputSmall
                type="text"
                value={ c.unit ? c.unit : '' }
                onChange={ ( e ) => { this.setOrderUnit( c, e.target.value ) } }
                onBlur={(e) => { this.setOrderUnit(c, e.target.value) }}
                disabled={disabled}
            />
        }
        const title = c.selectedUnit ? c.selectedUnit.unit : c.unit || tr('empty');
        const options = [{
            label: c.unit ? c.unit : <p></p>,
            icon: 'puzzle-piece',
            action: ( id, closeFunc ) => {
                this.props.handleUnitSelect(c, null);
                closeFunc();
            },
        }];
        c.conversion_units.forEach(item => {
            options.push({
                label: item.unit ? (
                    <div>
                        <div>
                            {item.unit}
                        </div>
                        <div>
                            {tr('conversion_factor')}: {item.conversion_factor}
                        </div>
                    </div>
                ) : <p></p>,
                icon: 'exchange-alt',
                action: ( id, closeFunc ) => {
                    this.props.handleUnitSelect(c, item.id)
                    closeFunc();
                },
            })
        })
        return <ApDropdown
            fullWidth
            button={ <div className='input-menu'> { title } <div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
            actions={options}
            disabled={disabled}
        />
    }

    renderPOPrice( c )
    {
        let disabled = false;
        if( this.props.poPriceLocked ) disabled = true;

        let updatePoPriceButton = null;
        if( c.type_name === 'item' && c.purchase_order_price && this.props.supplierId )
        {
            let priceMatches = false;
            if( this.props.supplierId )
            {
                let found = c.suppliers.find( s => s.id === this.props.supplierId );
                if( found && parseFloat( found.price ) === parseFloat( c.purchase_order_price ) )
                    priceMatches = true;
            }

            if( !priceMatches && !disabled && !c.selectedUnit )
                updatePoPriceButton = <div className="updatePoPriceButton" onClick={ () => { this.syncPoPriceClick( c ) } }>
                    <ApTooltip position="topright" text={ tr('update_supplier_price') }>
                        <SvgIcon icon="save" type="solid" />
                    </ApTooltip>
                </div>
        }

        return <div>
            <ApInputSmall
                type="text"
                value={ c.purchase_order_price ? c.purchase_order_price : '' }
                onChange={ ( e ) => { this.setPOPrice( c, e.target.value ) } }
                onBlur={   ( e ) => { this.setPOPrice( c, e.target.value, true ) } }
                disabled={ disabled }
                unit={ this.state.currencySign }
                noFocusDecimals="2"
                validationState={ ( c.purchase_order_price ) ? null : 'error' }
            />
            { updatePoPriceButton }
        </div>
    }

    // ----------------------------------------
    //  Order columns
    // ----------------------------------------


    renderOrderEntry( c )
    {
        if( !c.order_entry_id ) return null;
        const tooltip = this.renderOrderEntryTooltip( c );
        const icon = <SvgIcon icon="user-edit" type="solid" />
        return <ApTooltip position="top" text={ tooltip }>{ icon }</ApTooltip>
    }


    renderOrderUnit( c )
    {
        let disabled = false;
        if( this.props.orderPriceLocked ) disabled = true;

        if (!c.component_id || (!c.unit && (c.conversion_units && c.conversion_units.length == 0) ) || !c.conversion_enabled) { // Varaston ulkopuolinen tai muuntoyksikkö ei käytössä
            return <ApInputSmall
                type="text"
                value={ c.unit ? c.unit : '' }
                onChange={ ( e ) => { this.setOrderUnit( c, e.target.value ) } }
                onBlur={   ( e ) => { this.setOrderUnit( c, e.target.value ) } }
            />
        }
        const title = c.selectedUnit ? c.selectedUnit.unit : c.unit || '';
        const options = [{
            label: c.unit ? c.unit : '',
            icon: 'puzzle-piece',
            action: ( id, closeFunc ) => {
                this.props.handleUnitSelect(c, null);
                closeFunc();
            },
        }];
        c.conversion_units.forEach(item => {
            options.push({
                label: c.unit ? (
                    <div>
                        <div>
                            {item.unit}
                        </div>
                        <div>
                            {tr('conversion_factor')}: {item.conversion_factor}
                        </div>
                    </div>
                ) : '',
                icon: 'exchange-alt',
                action: ( id, closeFunc ) => {
                    this.props.handleUnitSelect(c, item.id)
                    closeFunc();
                },
            })
        })
        return <ApDropdown
            fullWidth
            button={ <div className='input-menu'> { title } <div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
            actions={ options }
            disabled={disabled}
        />
    }
    getCostenterText(id) {
        let costCenter = this.props.costCenters.find(c => c.id === id);
        //console.log(id, costCenter)
        return costCenter ? (<div>{costCenter.code}<br />{costCenter.name}</div>) : <div style={{color:"red"}}>{tr("not_selected")}</div>;
    }

    renderCTOrderColumns( c, header = false  )
    {
    	
        let saveAsNew=<td className='fixed'></td>
        if (this.props.instance!='po-import' && c!== null) {
        if (c.save_as_new!==undefined) {
           saveAsNew = <td className="saveNew">
    	    	<ApTooltip text={ tr('save_as_new_component') } block position="top">
    	    		<SvgIcon icon="save" type="solid" />
    	                <ApSwitch
    		                id={ `save_as_new-${ c.id }-switch` }
    		                small
    		                on={ c.save_as_new }
    		                onChange={ ( ) => { this.changeSaveAsNew( c ) } }
    		                disabled={ this.props.loading || this.props.locked }
    		            />
    	                
    	              </ApTooltip>
    		        </td>
            }
        }
        if( header )
        {
            let relatedHeader = ( this.props.orderType === 'delivery' ) ? tr('reserve_from_target') : capitalize(tr('pluralize_entry'));
            let tmp;
            if (this.props.useTaxGroups) {
                if (this.props.costCenterEnabled) {
                    tmp = [
                        relatedHeader,
                        this.renderCTHeader('count', 'amount'),
                        this.renderCTHeader('unit'),
                        this.renderCTHeader('order_price', 'unit_price'),
                        tr('discount_percentage'), 
                        tr('tax_group'),
                        tr('cost_center'),
                        tr('in_total'),
                        tr('reference_number_your')
                    ].map((n, i) => <th key={i}>{n}</th>);
                }
                else {
                    tmp = [
                        relatedHeader,
                        this.renderCTHeader('count', 'amount'),
                        this.renderCTHeader('unit'),
                        this.renderCTHeader('order_price', 'unit_price'),
                        tr('discount_percentage'),
                        tr('tax_group'),
                        tr('in_total'),
                        tr('reference_number_your')
                    ].map((n, i) => <th key={i}>{n}</th>);
                }
            }
            else {
                if (this.props.costCenterEnabled) {
                    tmp = [
                        relatedHeader,
                        this.renderCTHeader('count', 'amount'),
                        this.renderCTHeader('unit'),
                        this.renderCTHeader('order_price', 'unit_price'),
                        tr('discount_percentage'),
                        tr('vat'),
                        tr('vat_code'),
                        tr('cost_center'),
                        tr('in_total'),
                        tr('reference_number_your')
                    ].map((n, i) => <th key={i}>{n}</th>);
                }
                else {
                    tmp = [
                        relatedHeader,
                        this.renderCTHeader('count', 'amount'),
                        this.renderCTHeader('unit'),
                        this.renderCTHeader('order_price', 'unit_price'),
                        tr('discount_percentage'),
                        tr('vat'),
                        tr('vat_code'),
                        tr('in_total'),
                        tr('reference_number_your')
                    ].map((n, i) => <th key={i}>{n}</th>);
                }
            }
            if (this.props.orderType === 'delivery' )
                tmp.push(<th key="status">{ tr('status') }</th>)
            return tmp;
        }

        let relatedTD = null;
        if (this.props.orderType === 'delivery' )
            relatedTD = <td key="reserve" className="fixed c-target">
                { this.renderOrderReserveFrom( c ) }
            </td>
        else
            relatedTD = <td key="entry" className="fixed c-entry">
                { this.renderOrderEntry( c ) }
            </td>
            
        let tmp;
        if (this.props.useTaxGroups) {
            if (this.props.costCenterEnabled) {
                tmp = [
                    relatedTD,
                    <td key="count" className="fixed c-count">
                        {this.renderOrderCount(c)}
                    </td>,

                    <td key="unit" className="fixed c-unit">
                        {this.renderOrderUnit(c)}
                    </td>,

                    <td key="price" className="fixed">
                        {this.renderOrderPrice(c)}
                    </td>,

                    <td key="price" className="fixed">
                        {this.renderOrderDiscauntPercent(c)}
                    </td>,

                    <td key="vat" className="">
                        {this.renderOrderTaxGroup(c)}
                    </td>,

                    <td key="cost-center" className="fixed cost-center-vat"
                    >
                        <p onClick={() => {
                            if (!this.props.orderPriceLocked)
                                this.setState({
                                    costCenterEditShow: true,
                                    costCenterIsGroup: false,
                                    editComponent: c,
                                })
                        }}>{this.getCostenterText(c.cost_center_id)}</p>
                    </td>,

                    <td key="tota_price" className="fixed total">
                        
                        {formatMoney(parseFloat(c.count) * parseFloat(c.order_price) * ((1 - (c.discaunt_percent ? (parseFloat(c.discaunt_percent) / 100) : 0))))} {this.state.currencySign}<br />
                        {c.discaunt_percent && <small style={{fontSize:10.5}}>
                            ({formatMoney(parseFloat(c.count) * parseFloat(c.order_price))}{this.state.currencySign})
                        </small>}
                    </td>,
                    <td key="dispatch_ref" className="fixed dispatch-ref">
                        {this.renderRefInput(c)}
                    </td>,
                ];
            }
            else {
                tmp = [
                    relatedTD,
                    <td key="count" className="fixed c-count">
                        {this.renderOrderCount(c)}
                    </td>,

                    <td key="unit" className="fixed c-unit">
                        {this.renderOrderUnit(c)}
                    </td>,

                    <td key="price" className="fixed">
                        {this.renderOrderPrice(c)}
                    </td>,

                    <td key="price" className="fixed">
                        {this.renderOrderDiscauntPercent(c)}
                    </td>,

                    <td key="vat" className="">
                        {this.renderOrderTaxGroup(c)}
                    </td>,

                    

                    <td key="tota_price" className="fixed total">
                        {formatMoney(parseFloat(c.count) * parseFloat(c.order_price) * ((1 - (c.discaunt_percent ? (parseFloat(c.discaunt_percent) / 100) : 0))))} {this.state.currencySign}<br />
                        {c.discaunt_percent && <small style={{ fontSize: 10.5 }}>
                            ({formatMoney(parseFloat(c.count) * parseFloat(c.order_price))}{this.state.currencySign})
                        </small>}
                    </td>,
                    <td key="dispatch_ref" className="fixed dispatch-ref">
                        {this.renderRefInput(c)}
                    </td>,
                ];
            }
        }
        else {
            if (this.props.costCenterEnabled) {
                tmp = [
                    relatedTD,
                    <td key="count" className="fixed c-count">
                        {this.renderOrderCount(c)}
                    </td>,

                    <td key="unit" className="fixed c-unit">
                        {this.renderOrderUnit(c)}
                    </td>,

                    <td key="price" className="fixed">
                        {this.renderOrderPrice(c)}
                    </td>,

                    <td key="price" className="fixed">
                        {this.renderOrderDiscauntPercent(c)}
                    </td>,

                    <td key="vat" className="fixed vat">
                        {this.renderOrderVat(c)}
                    </td>,

                    <td key="netvisor-vat" className="fixed netvisor-vat">
                        {this.renderOrderNetvisorVat(c)}
                    </td>,
                    <td key="cost-center" className="fixed cost-center-vat"
                    >
                        <p onClick={() => {
                            if (!this.props.orderPriceLocked)
                                this.setState({
                                    costCenterEditShow: true,
                                    costCenterIsGroup: false,
                                    editComponent: c,
                                })
                        }}>{this.getCostenterText(c.cost_center_id)}</p>
                    </td>,

                    <td key="tota_price" className="fixed total">
                        {formatMoney(parseFloat(c.count) * parseFloat(c.order_price) * ((1 - (c.discaunt_percent ? (parseFloat(c.discaunt_percent) / 100) : 0))))} {this.state.currencySign}<br />
                        {c.discaunt_percent && <small style={{ fontSize: 10.5 }}>
                            ({formatMoney(parseFloat(c.count) * parseFloat(c.order_price))}{this.state.currencySign})
                        </small>}
                    </td>,
                    <td key="dispatch_ref" className="fixed dispatch-ref">
                        {this.renderRefInput(c)}
                    </td>,
                ];
            }
            else {
                tmp = [
                    relatedTD,
                    <td key="count" className="fixed c-count">
                        {this.renderOrderCount(c)}
                    </td>,

                    <td key="unit" className="fixed c-unit">
                        {this.renderOrderUnit(c)}
                    </td>,

                    <td key="price" className="fixed">
                        {this.renderOrderPrice(c)}
                    </td>,
                   
                    <td key="price" className="fixed">
                        {this.renderOrderDiscauntPercent(c)}
                    </td>,

                    <td key="vat" className="fixed vat">
                        {this.renderOrderVat(c)}
                    </td>,

                    <td key="netvisor-vat" className="fixed netvisor-vat">
                        {this.renderOrderNetvisorVat(c)}
                    </td>,

                    <td key="tota_price" className="fixed total">
                        {formatMoney(parseFloat(c.count) * parseFloat(c.order_price) * ((1 - (c.discaunt_percent ? (parseFloat(c.discaunt_percent) / 100) : 0))))} {this.state.currencySign}<br />
                        {c.discaunt_percent && <small style={{ fontSize: 10.5 }}>
                            ({formatMoney(parseFloat(c.count) * parseFloat(c.order_price))}{this.state.currencySign})
                        </small>}
                    </td>,
                    <td key="dispatch_ref" className="fixed dispatch-ref">
                        {this.renderRefInput(c)}
                    </td>,
                ];
            }
        }
        

        if (this.props.orderType === 'delivery' )
        {
            tmp.push( <td key="order_available" className="fixed">
                { this.renderOrderAvailable( c ) }
            </td> );
        }
        return tmp;
    }

    renderPuchaseOrderVat(c) {
        return this.renderOrderVat(c,true);
    }

    renderOrderVat( c , noValidation=false)
    {
        let disabled = false;
        if (this.props.orderPriceLocked || this.props.poPriceLocked) disabled = true;
        

        if( !c.order_vat && c.default_vat!==null) 
        	c.order_vat = c.default_vat;
        	
        let selectClass = [ 'vat-input' ];
        
        if (!c.order_vat && !noValidation)
            selectClass.push( 'validator-error' );
        
        return <select
            className={ selectClass.join(' ') }
            value={ c.order_vat }
            onChange={ ( e ) => this.setOrderVat( c, e.target.value ) }
            disabled={ disabled }
        >
        	<option value=""></option>
            { this.props.vats.map( v => <option key={ v.value } value={ v.value }>{ v.value } </option> )}
        </select>
    }
    renderTaxGroupValue(taxGroup) {
        if (!taxGroup) {
            return "";
        }
        let name = taxGroup.name || "";
        let tax = taxGroup.vat ? (taxGroup.vat.value + "%") : "";
        let account = taxGroup.account ? ("(" + taxGroup.account + ")") : "";
        let text = name + " " + tax + " " + account;
        return text
    }
    renderPurchaseOrderTaxGroup(c) {
        return this.renderOrderTaxGroup(c,true);
    }
    renderOrderTaxGroup(c, clearable=false) {
        let disabled = false;
        if (this.props.orderPriceLocked || this.props.poPriceLocked) disabled = true;


        // if (!c.order_vat && c.default_vat !== null)
        //     c.order_vat = c.default_vat;

        let selectClass = ['vat-input'];

        if (!c.tax_group)
            selectClass.push('validator-error');

        return <ApSelect
                    className={selectClass.join(' ')}
                    label={tr('component_tax_group')}
                    value={c.tax_group ? this.renderTaxGroupValue(c.tax_group) : ''}
                    optionRenderer="tax_group"
                    onChange={(data) => this.setTaxGroup(c,data)}
                    objKeyId="id"
                    clearable={clearable}
                    apiUrl="order/taxGroups/find"
                    // apiData={{
                    //     canReceive: true,
                    //     projectStorage: false,
                    // }}
                    disabled={disabled}
                />
        
        
        // <select
        //     className={selectClass.join(' ')}
        //     value={c.order_vat}
        //     onChange={(e) => this.setOrderVat(c, e.target.value)}
        //     disabled={disabled}
        // >
        //     <option value=""></option>
        //     {this.props.vats.map(v => <option key={v.value} value={v.value}>{v.value} </option>)}
        // </select>
    }

    renderOrderReserveFrom( c )
    {
        if( c.type_name === 'work' ) return null;
        if( c.type_name === 'other' ) return null;

        if( !c.locations )
            return tr('storages_are_missing').toUpperCase();

        let title = <div> { tr('not_selected') } </div>

        if( c.order_from_type === 'direct' )
            title = <div>{ tr('is_ordered_directly') }</div>

        if( c.order_from_type === 'location' )
        {
            let selected = c.locations.find( l => l.id === c.order_from_id );

            if (selected) {
                title = <div className="target-container">
                    <div>&nbsp;</div>
                    <div className="target-name"> { tr('storage') } </div>
                    <div className="target-code"> { selected.code } </div>
                    <div className="target-order"> { tr('balance') }: { selected.balance } { c.unit } </div>
                </div>
            } else {
                console.log(`Lokaatiota ei voitu löytää komponentin lokaatioista IDllä: ${c.order_from_id}`);
                console.log(`Komponentille määritellyt paikat: ${JSON.stringify(c.locations, null, 4)}`);
            }
            
        }

        let options = [];
        c.locations.forEach( l => {

            let dom = <div>
                { tr('storage') } <small> { l.code } </small><br />
                <small> { tr('balance') }: { l.balance } { c.unit } ( { tr('free').toLowerCase() }: { l.balance_free } { c.unit } )</small>

            </div>

            options.push({
                label: dom,
                icon: "archive",
                action: ( id, closeFunc ) => {
                    this.setOrderTarget( c, 'location', l );
                    closeFunc();
                },
            })
        });

        options.push({
            label: <div className="no-select">Luo uusi</div>,
            icon: 'folder',
            action: ( id, closeFunc ) => {
                this.onCreateNew(c.component_id, c.id);
                closeFunc();
            },
        })

        options.unshift( {
                label: <ApTooltip position="right" text={ tr('is_ordered_directly_tooltip') }>
                    { tr('is_ordered_directly') }
                </ApTooltip>,
                action: ( id, closeFunc ) => {
                    this.setOrderTarget( c, 'direct' );
                    closeFunc();
                },
        })

        options.unshift( {
                label: <div className="no-select">{ tr('not_selected') }</div>,
                action: ( id, closeFunc ) => {
                    this.setOrderTarget( c );
                    closeFunc();
                },
        })

        let disabled = false;
        if( this.props.orderReserveFromLocked ) disabled = true;

        if( disabled )
            return <div className="input-menu disabled" disabled>{ title }</div>

        let className = [ 'input-menu' ];
        if( !c.order_from_type ) className.push( 'input-error' );

        const dropdown  = <ApDropdown
            fullWidth
            button={ <div className={ className.join(' ') }>{ title } <div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
            actions={ options }
        />
        return dropdown;
    }

    renderOrderCount( c )
    {
        let disabled = false;
        if( this.props.orderPriceLocked ) disabled = true;

        let className = [ 'componentRowInput' ];
        if( !c.count ) className.push( 'input-error' );

        let helperTop = null;
        let helperBottom  = null;
        let tooltip = null;

        const unit = c.selectedUnit ? c.selectedUnit.unit : c.unit;
        const order_missing = c.selectedUnit && c.order_missing 
            ? Number((c.order_missing * parseFloat(c.selectedUnit.conversion_factor)).toFixed(5))
            : Number(parseFloat(c.order_missing).toFixed(5));
        const order_available = c.selectedUnit && c.order_available 
            ? Number((c.order_available * parseFloat(c.selectedUnit.conversion_factor)).toFixed(5))
            : Number(parseFloat(c.order_available).toFixed(5));

        if( this.props.orderType === 'delivery' )
        {
            const tooltipMssing = ( c.order_missing ) ? <div>{ tr('unsent_quantity') }: { order_missing } { unit }</div> : null;
            tooltip = <div>
                <div>{ tr('ordered_quantity') }: { c.count } { unit }</div>
                { tooltipMssing }
            </div>

            if( c.order_missing && c.type_name === 'item' )
                helperTop = <div className="count-helper-top">{ tr('not_sent') }: { order_missing }</div>

            if( c.order_available && c.type_name === 'item' )
                helperBottom = <div className="count-helper-bottom">{ tr('available') }: { ( order_available ) }</div>
        }

        return <ApTooltip position="topright" text={ tooltip }>
            <div className="count-container">
                <input
                    className={ className.join(' ') }
                    type="text"
                    value={ c.count ? c.count : '' }
                    onChange={ ( e ) => { this.setCount( c, e.target.value ) } }
                    onBlur={   ( e ) => { this.setCount( c, e.target.value, true ) } }
                    disabled={ disabled }
                />
                { helperTop }
                { helperBottom }
            </div>
        </ApTooltip>
    }

    renderRefInput = (c) => {
        let className = [ 'componentRowInput' ];
        return <div>
            <input
                className={ className.join(' ') }
                type="text"
                value={c.dispatch_ref ? c.dispatch_ref : ''}
                onChange={e => this.handleRefChange(e, c)}
                onBlur={e => this.handleRefChange(e, c)}
            />
        </div>
    };

    renderOrderPrice( c )
    {
        let disabled = false;
        if( this.props.orderPriceLocked ) disabled = true;

        let className = [ 'componentRowInput', 'fixed-price-input' ];
        if( !c.order_price ) className.push( 'input-error' );

        return <div>
            <ApInputSmall
                type="text"
                value={ c.order_price ? c.order_price : '' }
                onChange={ ( e ) => { this.setOrderPrice( c, e.target.value ) } }
                onBlur={   ( e ) => { this.setOrderPrice( c, e.target.value, true ) } }
                disabled={ disabled }
                noFocusDecimals="2"
                validationState={ ( c.order_price ) ? null : 'error' }
            />
            <div className="unit">{ this.state.currencySign }</div>
        </div>
    }

    renderOrderDiscauntPercent(c) {
        let disabled = false;
        if (this.props.orderPriceLocked) disabled = true;

        let className = ['componentRowInput', 'fixed-price-input'];
        if (!c.discaunt_percent) className.push('input-error');

        return <div>
            <ApInputSmall
                type="text"
                value={c.discaunt_percent ? c.discaunt_percent : ''}
                onChange={(e) => { this.setOrderDiscauntPercent(c, e.target.value) }}
                onBlur={(e) => { this.setOrderDiscauntPercent(c, e.target.value, true) }}
                disabled={disabled}
                noFocusDecimals="2"
                // validationState={(c.discaunt_percent) ? null : 'error'}
            />
            <div className="unit">%</div>
        </div>
    }

    renderOrderAvailable( c )
    {
        if( !c.locations )
            return tr('storages_are_missing').toUpperCase();;

        // Dont show notification unless target and count have been selected
        if( !c.order_from_type || !c.count )
            return null;

        if( parseFloat( c.order_missing ) === 0 )
        {
            return <ApTooltip position="topright" text={ tr('delivered') }>
                <div className="apStatusBox success">{ tr('delivered') }</div>
            </ApTooltip>
        }

        if( c.order_from_type === 'location' )
        {
            let selected = c.locations.find( l  => l.id === c.order_from_id );
            if( selected )
            {

                const balance = parseFloat( selected.balance );
                const balanceFree = parseFloat( selected.balance_free );
                const countMissing = ( typeof c.order_missing === 'undefined' ) ? parseFloat( c.count ) : parseFloat( c.order_missing );

                if( balance >= countMissing )
                {
                    return <ApTooltip position="topright" text={ tr('cit_location_available_tooltip') }>
                        <div className="apStatusBox info">{ tr('available') }</div>
                    </ApTooltip>
                }

                if( balanceFree >= 0 )
                {
                    if( balance > 0 )
                    {
                        return <ApTooltip position="topright" text={ tr('cit_location_part_available_tooltip') }>
                            <div className="apStatusBox info">{ tr('part_available') }</div>
                        </ApTooltip>
                    }

                    return <ApTooltip position="topright" text={ tr('cit_location_not_available_tooltip') }>
                        <div className="apStatusBox warning">{ tr('not_available') }</div>
                    </ApTooltip>
                }
            }

            return <ApTooltip position="topright" text={ tr('cit_location_must_be_ordered_tooltip') }>
                <div className="apStatusBox error">{ tr('must_be_ordered') }</div>
            </ApTooltip>
        }
        else if( c.order_from_type === 'direct' )
        {
            if( c.purchase_order )
            {
                const po = c.purchase_order;

                let poTooltip = ( message ) =>
                {
                    return <div className="text-left">
                        <div> { message }</div>
                        <div><strong>{ tr('purchase_order') }: { po.number }</strong></div>
                        <div>{ tr('supplier') }: { po.supplier_name }</div>
                        <div>{ tr('status') }: { po.status_name }</div>
                        <div><strong>{ tr('click_to_open_po') }</strong></div>
                    </div>
                }

                if( c.purchase_order_missing === 0 )
                {
                    // All have arrived

                    return <ApTooltip position="topright" text={ tr('cit_direct_available_tooltip') }>
                        <div className="apStatusBox info">{ tr('available') }</div>
                    </ApTooltip>
                }
                if( c.purchase_order_missing > 0 && ( c.purchase_order_arrived - c.order_delivered ) > 0 )
                {
                    return <ApTooltip position="topright" text={ poTooltip( tr('cit_direct_part_available_tooltip') ) }>
                        <div onClick={ () => this.goToPurchaseOrder( po.id )} className="apStatusBox info">{ tr('part_available') }</div>
                    </ApTooltip>
                }

                return <ApTooltip position="topright" text={ poTooltip( tr('cit_direct_not_available_tooltip') ) }>
                    <div onClick={ () => this.goToPurchaseOrder( po.id )} className="apStatusBox warning">{ tr('not_available') }</div>
                </ApTooltip>

            }

            return <ApTooltip position="topright" text={ tr('cit_direct_must_be_ordered_tooltip') }>
                <div className="apStatusBox error">{ tr('must_be_ordered') }</div>
            </ApTooltip>
        }

        return null;

    }

    renderOrderEntryTooltip(c )
    {
        const data = c.order_entry;
        if( !data ) return null;

        const typeOptions = getBillingEntryTypes();

        let type = data.type;
        if ( type in typeOptions )
            type = typeOptions[ type ];

        return <div className="orderEntryTooltip">
            <table className="infoTable">
                <thead>
                    <tr>
                        <th colSpan="2" className="header">{ capitalize(tr('pluralize_entry')) }</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="infoLabel">{ tr('type') }:</td>
                        <td className="valueText">{ type }</td>
                    </tr>
                    {c.job_description ? 
                        <tr>
                            <td className="infoLabel">{ tr('description') }:</td>
                            <td className="valueText">{ c.job_description }</td>
                        </tr> : null 
                    }
                    <tr>
                        <td className="infoLabel">{ tr('recorded') }:</td>
                        <td className="valueText">{ sqlToDateInput( data.date ) }</td>
                    </tr>
                    <tr>
                        <td className="infoLabel">{ tr('person') }:</td>
                        <td className="valueText">{ data.user_name }</td>
                    </tr>
                    <tr>
                        <td className="infoLabel">{ tr('project') }:</td>
                        <td className="valueText">{ data.project_name }</td>
                    </tr>
                    <tr>
                        <td className="infoLabel"></td>
                        <td className="valueText">{ data.project_code }</td>
                    </tr>
                    <tr>
                        <td className="infoLabel">{ tr('amount') }:</td>
                        <td className="valueText">{ data.count }</td>
                    </tr>
                </tbody>
            </table>
        </div>
    }



    // ----------------------------------------
    //  Arrival columns
    // ----------------------------------------

    renderCTArrivalColumns( c, header = false )
    {
        if( header )
        {
            return [
                tr('arrived_quantity'),
                tr('unit'),
                tr('target'),
                tr('at_destination'),
            ].map( ( n, i ) => <th key={ i }>{ n }</th>);
        }

        return [
            <td key="count" className="fixed c-count">
                { this.renderArrivalCount( c ) }
            </td>,
            <td key="unit" className="fixed c-unit">
                { this.renderArrivalUnit( c ) }
            </td>,
            <td key="target" className="fixed c-target">
                { this.renderArrivalTarget( c ) }
            </td>,
            <td key="forward" className="fixed c-final-destination">
                { this.renderArrivalFinal( c ) }
            </td>,
        ];
    }

    renderArrivalUnit( c ) {
        if (!c.component_id || (!c.unit && (c.conversion_units && c.conversion_units.length == 0) ) || !c.conversion_enabled) { // Varaston ulkopuolinen tai muuntoyksikkö ei käytössä
            return <ApInputSmall
                type="text"
                value={ c.unit ? c.unit : '' }
                disabled={true}
            />
        }
        const title = c.selectedUnit ? c.selectedUnit.unit : c.unit || '';
        const options = [{
            label: c.unit ? c.unit : '',
            icon: 'puzzle-piece',
            action: ( id, closeFunc ) => {
                this.props.handleUnitSelect(c, null);
                closeFunc();
            },
        }];
        c.conversion_units.forEach(item => {
            options.push({
                label: c.unit ? (
                    <div>
                        <div>
                            {item.unit}
                        </div>
                        <div>
                            {tr('conversion_factor')}: {item.conversion_factor}
                        </div>
                    </div>
                ) : '',
                icon: 'exchange-alt',
                action: ( id, closeFunc ) => {
                    this.props.handleUnitSelect(c, item.id)
                    closeFunc();
                },
            })
        })
        return <ApDropdown
            fullWidth
            button={ <div className='input-menu'> { title } <div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
            actions={ options }
        />
    }

    renderArrivalCount( c )
    {
        let disabled = false;
        if( this.props.arrivalCountLocked ) disabled = true;

        const countMissing = c.selectedUnit 
            ? Number((parseFloat(c.selectedUnit.conversion_factor) * parseFloat(c.purchase_order_missing)).toFixed(5))
            : c.purchase_order_missing;
        let tooltipMessage = null;

        let className = [ 'componentRowInput' ];
        if( !c.arrival_count )
        {
            className.push( 'input-error' );
            tooltipMessage = tr('arrived_quantity_error1');

        }
        if( parseFloat( c.arrival_count ) > parseFloat( c.count ) )
        {
            className.push( 'input-error' );
            tooltipMessage = tr('arrived_quantity_error2');
        }

        let tooltip = <div>
            <div><strong>{ tooltipMessage }</strong></div>
            <div>{ tr('ordered_quantity') }: { c.count } { c.selectedUnit ? c.selectedUnit.unit : c.unit }</div>
            <div>{ tr('missing_quantity') }: { Number(parseFloat(countMissing).toFixed(5)) } { c.selectedUnit ? c.selectedUnit.unit : c.unit }</div>
        </div>

        return <ApTooltip position="top" text={ tooltip }>
            <div className="count-container">
                <input
                    className={ className.join(' ') }
                    type="text"
                    value={ c.arrival_count ? c.arrival_count : '' }
                    onChange={ ( e ) => { this.setArrivalCount( c, e.target.value ) } }
                    onBlur={   ( e ) => { this.setArrivalCount( c, e.target.value, true ) } }
                    disabled={ disabled }
                />
                {/* <div className="unit">{ c.unit }</div> */}
                <div className="count-helper-top">{ tr('missing') }: { Number(parseFloat(countMissing).toFixed(5)) }</div>
                <div className="count-helper-bottom"></div>
            </div>
        </ApTooltip>
    }

    renderArrivalTarget( c )
    {
        const title = this.renderFinalTargetSelected( c );
        return <div className="input-menu disabled">{ title }</div>
    }

    renderArrivalFinal( c )
    {
        if( !c.locations )
            return tr('storages_are_missing').toUpperCase();;

        const receiverType = this.props.arrivalReceiverType;
        const receiverId   = this.props.arrivalReceiverId;

        let arrivingToFinalDestination = false;
        if( receiverType === c.target_final_type )
        {
            if( receiverType === 'location' )
            {
                // Find the location that this component is ment to be delivered
                let selected = c.locations.find( l  => l.id === c.target_final_id );

                // Check if any of the selected locations delivery_locations is one that this thing is arriving
                if( selected )
                {
                    let found = selected.delivery_locations.find( dl => receiverId === dl.id );
                    if( found ) arrivingToFinalDestination = true;
                }
            }
        }

        if( arrivingToFinalDestination )
            return <ApTooltip position="topright" text={ tr('storage_component_arrival_info') }>
                <div className=""><SvgIcon icon="check-circle" type="solid" /></div>
            </ApTooltip>

        return null;
    }

    // ----------------------------------------
    //  Delivery columns
    // ----------------------------------------

    renderCTDeliveryColumns( c, header = false )
    {
        let receiverType = this.props.deliveryReceiverType;
        let senderType = this.props.deliverySenderType;

        if( header )
        {
            let data = [
                tr('quantity_to_be_sent'),
            ];

            data.unshift(tr('from_target'));

            if (receiverType !== 'company') {
                data.push(tr('to_target'));
            }

            return data.map( ( n, i ) => <th key={ i }>{ n }</th>);
        }

        let data = [
            <td key="from" className="fixed c-target">
                { this.renderDeliveryFrom( c ) }
            </td>,
            <td key="count" className="fixed c-count">
                { this.renderDeliveryCount( c ) }
            </td>,
        ];

        if( (receiverType === 'location' || this.props.instance === 'delivery') && receiverType !== 'company' )
        {
            data.push( <td key="to" className="fixed c-target">
                { this.renderDeliveryTo( c ) }
            </td> );
        }
        return data;


    }

    renderPOimportColumns( c, header = false )
    {
        //console.log(c);
        let receiverType = this.props.deliveryReceiverType;

        if( header )
        {
            let data = [
                tr('name_in_po'),
                tr('status'),
                tr('amount'),
                tr('in_other_storages'),
                tr('price'),
                tr('total_price'),
            ];

            return data.map( ( n, i ) => <th key={ i }>{ n }</th>);
        }
        let componentExists = <td key="componentExists" className="fixed c-target">
             {c.save_as_new?tr('create_new_component'):tr('external_component')}
        </td>;

        if (c.component_id != undefined && c.import_name!=undefined) {
            componentExists = <td key="existing_component" className="fixed c-target">
                {tr('existing_component')}
            </td>
        }
        else if ( c.component_id != undefined && c.import_name==undefined) {
            componentExists = <td key="existing_component" className="fixed c-target">
                {tr('existing_component')}<br/>{tr('defined_in_po')}
            </td>
        }

        let className = [ 'componentRowInput' ];
        let disabled = true;

        if ( c.import_type == 'fivaldi-po' ) {
            disabled = false;
        }

        let vatPercent = c.vat_percent || 0;
        let vat = (vatPercent / 100);
        const stockBalance = c.stocks && c.stocks.reduce((total, stock) => total + Number(stock.balance_available), 0) || 0;
        let componentPrice = c.linkedComponent ? c.purchase_order_price : c.price;
        let componentPriceIncludeVat = componentPrice * (vat + 1);
        let componentVatPrice = componentPrice * vat;
        let componentCount = c.count;
        let data = [
            <td key="import_name" className="fixed c-target">
                {c.import_name}
            </td>,
            componentExists,
            <td key="count" className="fixed c-target">
                <input
                    className={className.join(" ")}
                    type="text"
                    value={c.count ? c.count : ""}
                    onChange={(e) => {
                        this.setImportCount(c, e.target.value);
                    }}
                    onBlur={(e) => {
                        this.setImportCount(c, e.target.value, true);
                    }}
                    disabled={disabled}
                />
                <div className="unit">{c.unit}</div>
            </td>,
            <td
                className="fixed"
                style={{
                    color: stockBalance > 0 ? "var(--clr-warning-main)" : "inherit",
                }}
            >
                <ApTooltip
                    text={
                        c.stocks &&
                        c.stocks.map((stock, index) => (
                            <div key={index}>
                                {stock.location.code} {stock.location.name}:{" "}
                                {stock.balance_available} {c.unit}
                            </div>
                        ))
                    }
                >
                    {stockBalance} {c.unit}
                </ApTooltip>
            </td>,
            
            <td key="price_single" className="fixed">
                {formatMoney(componentPrice)} {this.state.currencySign}
                <br />
                <small>
                    {formatMoney(componentPriceIncludeVat)} {this.state.currencySign}{" "}
                    ({tr("inc_vat")} {vatPercent}% {formatMoney(componentVatPrice)}{" "}
                    {this.state.currencySign})
                </small>
            </td>,

            <td key="price_total" className="fixed">
                {formatMoney(componentPrice * componentCount)}{" "}
                {this.state.currencySign}
                <br />
                <small>
                    {formatMoney(componentPriceIncludeVat * componentCount)}{" "}
                    {this.state.currencySign} ({tr("inc_vat")} {vatPercent}%{" "}
                    {formatMoney(componentVatPrice * componentCount)}{" "}
                    {this.state.currencySign})
                </small>
            </td>,
        ];

        return data;


    }

    renderSupplierOfferColumns(c, header = false) {
        if (header) {
            let data = [
                tr('amount'),
                tr('price'),
                tr('total_price'),
                tr('supplier')
            ];

            return data.map( ( n, i ) => <th key={ i }>{ n }</th>);
        }

        let supplierHasItem = false;
        const suppliers = new Set();
        if (!this.props.suppliers.length) supplierHasItem = false;
        else if (keyExists(c, 'suppliers')) {
            const supplierIds = this.props.suppliers.map(supplier => supplier.id);
            const found = c.suppliers.find(supplier => supplierIds.includes(supplier.id));
            if (found) {
                supplierHasItem = true;
                c.suppliers.forEach(supplier => {
                    if (supplierIds.includes(supplier.id)) {
                        suppliers.add(supplier.name);
                    }
                })
            }
        }

        let data = [
            <td key="count" className="fixed">
                <ApInputSmall
                    style={{width: '75px'}}
                    type="text"
                    value={c.count ? c.count : ''}
                    onChange={e => this.setCount(c, e.target.value)}
                    onBlur={e => this.setCount(c, e.target.value, true)}
                    unit={c.unit ? c.unit : ''}
                    validationState={ ( c.count ) ? null : 'error' }
                />
            </td>,

            <td key="price_single" className="fixed">
                <ApInputSmall
                    type="text"
                    value={ c.price ? c.price : '' }
                    onChange={ ( e ) => { this.setPrice( c, e.target.value ) } }
                    onBlur={   ( e ) => { this.setPrice( c, e.target.value, true ) } }
                    // disabled={ disabled }
                    unit={ this.state.currencySign }
                    noFocusDecimals="2"
                    validationState={ ( c.price ) ? null : 'error' }
                />
            </td>,

            <td key="price_total" className="fixed">
                {formatMoney(c.price * c.count)}{" "}
                {this.state.currencySign}
            </td>,

            <td key='supplier_has_item' style={{textAlign: 'center'}}>
                {
                    c.type_id!=1?null:
                        !this.props.suppliers.length ? '' : supplierHasItem
                            ? <ApTooltip text={Array.from(suppliers).map((supplier, index) => <div key={index}>{supplier}</div>)}><SvgIcon type='solid' icon='check-circle' fill='var(--clr-success-main)' /></ApTooltip>
                            : <ApTooltip text={tr('supplier_does_not_provide_component')}><SvgIcon type='solid' icon='times-circle' fill='var(--clr-error-main)' /></ApTooltip>
                }
            </td>
        ];

        return data;
    }

    renderWooCommerceColumns( c, header = false ) {
        if (header) {
            let data = [
                tr('amount'),
                tr('price'),
                tr('total_price'),
            ];

            return data.map( ( n, i ) => <th key={ i }>{ n }</th>);
        }

        let data = [
            <td key="count" className="fixed">
                <ApInputSmall
                    style={{width: '75px'}}
                    type="text"
                    value={c.quantity ? c.quantity : ''}
                    unit={c.unit ? c.unit : ''}
                    disabled={true}
                    loading={this.props.loading}
                />
            </td>,

            <td key="price_single" className="fixed">
                <ApInputSmall
                    type="text"
                    value={ c.price ? c.price : '' }
                    unit={ this.state.currencySign }
                    noFocusDecimals="2"
                    disabled={true}
                    loading={this.props.loading}
                />
            </td>,

            <td key="price_total" className="fixed">
                {formatCurrency(c.price * c.quantity)}
            </td>,
        ];

        return data;
    }

    renderContractBillingColumns( c, header = false ) {
        if (header) {
            let data = []
            if (this.props.useTaxGroups) {
                data = [
                    tr('amount'),
                    tr('unit_price'),
                    tr('discount_percentage'),
                    tr('tax_group'),
                    tr('in_total'),
                ]
            } else {
                data = [
                    tr('amount'),
                    tr('unit_price'),
                    tr('discount_percentage'),
                    tr('vat'),
                    tr('vat_code'),
                    tr('in_total'),
                ];
            }
            return data.map( ( n, i ) => <th key={ i }>{ n }</th>);
        }

        let data = [
            <td key="count" className="fixed">
                <ApInputSmall
                    style={{width: '75px'}}
                    type="number"
                    value={ c.count ? c.count : '' }
                    unit={ c.unit || '' }
                    noFocusDecimals="2"
                    onChange={e => this.setCount(c, e.target.value)}
                    onBlur={e => this.setCount(c, e.target.value, true)}
                    // disabled={true}
                    loading={this.props.loading}
                    validationState={ ( c.count ) ? null : 'error' }
                />
            </td>,
            <td key="price" className="fixed">
                <ApInputSmall
                    type="number"
                    value={ c.price_sell ? c.price_sell : '' }
                    unit={ this.state.currencySign }
                    noFocusDecimals="2"
                    onChange={e => this.setSellPrice(c, e.target.value)}
                    onBlur={e => this.setSellPrice(c, e.target.value, true)}
                    // disabled={true}
                    loading={this.props.loading}
                    validationState={ ( c.price_sell ) ? null : 'error' }
                />
            </td>,
            <td key="discount_percent" className="fixed">
                <div>
                    <ApInputSmall
                        type="text"
                        value={c.discount_percent ? c.discount_percent : ''}
                        onChange={(e) => { this.setDiscountPercent(c, e.target.value) }}
                        onBlur={(e) => { this.setDiscountPercent(c, e.target.value, true) }}
                        noFocusDecimals="2"
                    />
                    <div className="unit">%</div>
                </div>
            </td>,
            <td key="total_price" className="fixed total">
                {formatMoney(parseFloat(c.count) * parseFloat(c.price_sell) * ((1 - (c.discount_percent ? (parseFloat(c.discount_percent) / 100) : 0))))} {this.state.currencySign}<br />
                {c.discount_percent && <small style={{ fontSize: 10.5 }}>
                    ({formatMoney(parseFloat(c.count) * parseFloat(c.price_sell))}{this.state.currencySign})
                </small>}
            </td>,
        ];

        if (this.props.useTaxGroups) {
            let selectClass = ['vat-input'];
            if (!c.tax_group)
                selectClass.push('validator-error');

            const taxGroupEl = <td key="vat" className="fixed" style={{minWidth: '150px'}}>
                    <ApSelect
                        className={selectClass.join(' ')}
                        label={tr('component_tax_group')}
                        value={c.tax_group ? this.renderTaxGroupValue(c.tax_group) : ''}
                        optionRenderer="tax_group"
                        onChange={(data) => this.setTaxGroup(c,data)}
                        objKeyId="id"
                        // clearable
                        apiUrl="order/taxGroups/find"
                    />
                </td>
            data.splice(3, 0, taxGroupEl);
        } else {
            const vatsEl = <td key="vat" className="fixed vat" style={{width: '60px'}}>
                <select
                    className={ 'vat-input' }
                    value={ c.vat_amount }
                    onChange={ ( e ) => this.setVat( c, e ) }
                >
                    <option value=""></option>
                    { this.props.vats.map( v => <option data-id={v.id} key={ v.value } value={ v.value }>{ v.value } </option> )}
                </select>
            </td>
            
            const vatCodeEl = <td key="netvisor-vat" className="fixed netvisor-vat">
                    {this.renderOrderNetvisorVat(c)}
                </td>;
            data.splice(3, 0, vatsEl);
            data.splice(4, 0, vatCodeEl);
        }

        return data;
    }

    setDiscountPercent(component, value) {
        value = onlyNumber(value, true);

        let components = this.props.components.slice(0);
        components = components.map(c => {
            if (c.id === component.id)
                c.discount_percent = value;
            return c;
        });
        this.props.componentsChange(components);
    }

    setVat(component, event) {
        let vatId = event.target.options[event.target.selectedIndex].getAttribute('data-id');
        let components = this.props.components.slice(0);
        components = components.map(c => {
            if (c.id === component.id) {
                c.vat_amount = event.target.value;
                c.vat_id = vatId;
            }
            return c;
        });
        this.props.componentsChange(components);
    }

    renderDeliveryCount( c )
    {
        const forOrder   = this.props.deliveryForOrder;
        const senderId   = this.props.deliverySenderId;

        let selectedLocation = null;

        if( forOrder )
        {
            if( !this.deliveryIsAvailableForOrder( c ) )
                return null;
        }

        if( c.delivery_from_type === 'location' )
        {
            selectedLocation = c.locations.find( l => l.id === c.delivery_from_id );
            if( selectedLocation && parseFloat( selectedLocation.balance )  <= 0 )
                return <ApTooltip position="topright" text={ tr('storage_empty_info') }>
                    <div>{ tr('storage_empty') }</div>
                </ApTooltip>
        }

        let disabled = false;
        if( this.props.deliveryCountLocked ) disabled = true;

        const countMissing = c.order_missing;
        let className = [ 'componentRowInput' ];

        let tooltipMessage = null;
        let tooltipCount   = null;
        let tooltipMissing = null;
        let helperTop = null;
        let helperBottom = null;

        if( !disabled )
        {

            if( forOrder )
            {
                tooltipCount   = <div>{ tr('ordered_quantity') }: { c.count } { c.unit }</div>
                tooltipMissing = <div>{ tr('unsent_quantity') }: { countMissing } { c.unit }</div>
                helperTop      = <span>{ tr('not_sent') }: { countMissing }</span>
            }

                if( c.delivery_from_type === 'direct' )
                {
                    c.tmp_components.forEach( d => {
                        const found = d.locations.find( dl => dl.id === senderId );

                        if( found )
                        {
                            if( parseFloat( c.delivery_count ) > parseFloat( found.balance ) )
                            {
                                className.push( 'input-error' );
                                tooltipMessage = tr('quantity_to_be_sent_info');
                            }

                            tooltipCount = <div>{ tr('storage_balance') }: { found.balance } { c.unit }</div>
                            helperBottom = <span>{ tr('balance') }: { found.balance }</span>
                        }
                    });
                }

            if( !c.delivery_count )
            {
                className.push( 'input-error' );
                tooltipMessage = tr('quantity_to_be_sent_error1');
            }
            else if( forOrder && parseFloat( c.delivery_count ) > parseFloat( countMissing ) )
            {
                className.push( 'input-error' );
                tooltipMessage = tr('quantity_to_be_sent_error2');
            }
            else if( c.delivery_from_type === 'location' )
            {
                if( parseFloat( c.delivery_count ) > parseFloat( selectedLocation.balance ) )
                {
                    className.push( 'input-error' );
                    tooltipMessage = tr('quantity_to_be_sent_error3');
                }
            }
        }

        let tooltip = tooltipMessage || tooltipCount || tooltipMissing
            ? <div>
                <div><strong>{ tooltipMessage }</strong></div>
                { tooltipCount }
                { tooltipMissing }
                </div>
            : null;

        return <div className="count-container">
                <ApInput
                    className={ className.join(' ') }
                    type="text"
                    value={ c.delivery_count ? c.delivery_count : '' }
                    onChange={ ( e ) => { this.setDeliveryCount( c, e.target.value ) } }
                    onBlur={   ( e ) => { this.setDeliveryCount( c, e.target.value, true ) } }
                    disabled={ disabled }
                    tooltip={tooltip ? tooltip : null}
                />
                {/* <input
                    className={ className.join(' ') }
                    type="text"
                    value={ c.delivery_count ? c.delivery_count : '' }
                    onChange={ ( e ) => { this.setDeliveryCount( c, e.target.value ) } }
                    onBlur={   ( e ) => { this.setDeliveryCount( c, e.target.value, true ) } }
                    disabled={ disabled }
                /> */}
                <div className="unit">{ c.unit }</div>
                <div className="count-helper-top">{ helperTop }</div>
                <div className="count-helper-bottom">{ helperBottom }</div>
            </div>
    }

    renderDeliveryMenuSelected( direction, c )
    {
        let title = <div> { tr('not_selected') } </div>

        let dType = c[`delivery_${ direction }_type`];
        let dId   = c[`delivery_${ direction }_id`];

        if( dType  === 'location' )
        {
            let selected = c.locations.find( l => l.id === dId );

            if( selected )
            {
                title = <div className="target-container">
                    <div>&nbsp;</div>
                    <div className="target-name"> { tr('storage') } </div>
                    <div className="target-code"> { selected.code } </div>
                    <div className="target-order"> { tr('balance') }: { selected.balance } { c.unit } </div>
                </div>
            }
            else
                console.error('STORAGE LOCATION NOT FOUND' );
        }

        else if( dType === 'direct' )
        {
            let d = c.tmp_components.find( t => t.instance_id === c.id );

            if( d )
            {
                let targetDom = null;
                if( d.target_final_type === 'location' )
                    targetDom = <span> { tr('storage') } { d.target_final_name } </span>
                if( d.target_final_type === 'company' )
                    targetDom = <span> { tr('company') } { d.target_final_name } </span>

                let orderDom = null;
                if( d.order_id )
                    orderDom = <span> { tr('order') }: { d.order_number } </span>

                title = <div className="target-container">
                    <div>&nbsp;</div>
                    <div className="target-name"> { tr('temporary') } </div>
                    <div className="target-code"> { tr('destination') }: { targetDom } </div>
                    <div className="target-order"> { orderDom } </div>
                </div>
            }
            else
                title = <div> { tr('ordered_directly') } </div>
        }
        return title;
    }

    getDeliveryMenuOptions( direction, c )
    {
        let dType = null;
        let dId   = null;

        if( direction === 'from' )
        {
            dType = this.props.deliverySenderType;
            dId   = this.props.deliverySenderId;
        }
        else if( direction === 'to' )
        {
            dType = this.props.deliveryReceiverType;
            dId   = this.props.deliveryReceiverId;
        }

        let options = [];

        c.locations && c.locations.forEach( l => {
            if( dType !== 'location' )
                return null;
            if (l.id === this.props.senderId) return null;

            const inLocation = l.delivery_locations.some( dl =>  dl.id === dId );
            if( inLocation )
            {
                let dom = <div>
                    { tr('storage') } <small> { l.code } </small><br />
                    <small> { tr('balance') }: { l.balance } { c.unit } ( { tr('free').toLowerCase() }: { l.balance_free } { c.unit } )</small>
                </div>

                options.push({
                    label: dom,
                    icon: "archive",
                    action: ( id, closeFunc ) => {
                        if( direction === 'from' )
                            this.setDeliveryFrom( c, 'location', l );
                        else if( direction === 'to' )
                            this.setDeliveryTo( c, 'location', l );
                        closeFunc();
                    },
                })
            }
        });

        if( direction === 'from' )
        {
            Array.isArray(c.tmp_components) && c.tmp_components.forEach( d => {
                const found = d.locations.find( dl =>  dl.id === dId );
                if( found )
                {
                    let targetDom = d.target_final_type;

                    if( d.target_final_type === 'location' )
                        targetDom = <span> { tr('storage') } { d.target_final_name } </span>

                    if( d.target_final_type === 'company' )
                        targetDom = <span> { tr('company') } { d.target_final_name } </span>

                    let orderDom = null;
                    if( d.order_id )
                        orderDom = <small> { tr('order_number') }: { d.order_number } </small>

                    let icon = 'stop';
                    let dom = <div>
                         { tr('temporary_stores') }<br />
                        <small> { tr('amount') }: { found.balance } { c.unit } </small><br />
                        <small> { tr('destination') }: { targetDom } </small><br />
                        { orderDom }
                    </div>

                    options.push({
                        label: dom,
                        icon: icon,
                        action: ( id, closeFunc ) => {
                            this.setDeliveryFrom( c, 'direct', {...d, balance: found.balance, location_id: found.id } );
                            closeFunc();
                        },
                    });
                }
            });
        }

        if (direction === 'to') {
            options.push({
                label: <div >{tr('create_new')}</div>,
                icon: 'folder',
                action: ( id, closeFunc ) => {
                    this.onCreateNew(c.component_id, c.id);
                    closeFunc();
                },
            })
        }

        options.unshift( {
            label: <div className="no-select">{ tr('not_selected') }</div>,
            action: ( id, closeFunc ) => {
                if( direction === 'from' )
                    this.setDeliveryFrom( c );
                else if( direction === 'to' )
                    this.setDeliveryTo( c );
                closeFunc();
            },
        })

        return options;
    }

    deliveryIsAvailableForOrder( c )
    {
        if( c.type_name !== 'item' ) return false;

        const senderId   = this.props.deliverySenderId;
        if( c.order_from_type === 'location' )
        {
            let found = c.locations.find( l => l.id === c.order_from_id );
            if( found )
            {
                const inLocation = found.delivery_locations.some( dl =>  dl.id === senderId );
                if( inLocation )
                    return true;
            }
        }
        else if( c.order_from_type === 'direct' )
        {
            return c.tmp_components.find( d => {
                return d.locations.find( dl =>  dl.id === senderId );
            });
        }
        else
        {
            console.error('UNKNOW ORDER FROM TYPE');
            console.log('UNKNOW ORDER FROM TYPE', c );
        }
        return false;
    }

    renderDeliveryFrom( c )
    {
        const senderType = this.props.deliverySenderType;
        if (senderType != 'location') {
            const senderLocation = c.locations.find(location => location.id === this.props.deliverySenderId);
            return (
                <div>
                    <div>
                        <strong>
                            {senderLocation && senderLocation.name}
                        </strong>
                    </div>
                    <div>
                        {tr('balance')}: {senderLocation && senderLocation.balance}
                    </div>
                </div>
            );
        }

        const forOrder = this.props.deliveryForOrder;
        let title = null;

        let options = [];
        if( forOrder )
        {
            if( this.deliveryIsAvailableForOrder( c  ) )
            {
                title = this.renderDeliveryMenuSelected( 'from', c );
                return <div className="input-menu disabled">{ title }</div>
            }
            else
            {
                let tooltip = <div>
                    <div><strong>{ tr('storage_component_not_available') }</strong></div>
                    <div>{ tr('ordered_quantity') }: { c.count } { c.unit }</div>
                    <div>{ tr('unsent_quantity') }: { c.order_missing } { c.unit }</div>
                </div>

                return <ApTooltip position="topright" text={ tooltip } block>
                    <div>{ tr('not_available') }</div>
                </ApTooltip>
            }
        }
        else
        {
            options = this.getDeliveryMenuOptions( 'from', c );
            title = this.renderDeliveryMenuSelected( 'from', c );
        }

        let disabled = false;
        if( this.props.deliveryFromLocked ) disabled = true;
        if( disabled )
            return <div className="input-menu disabled" disabled>{ title }</div>

        let className = [ 'input-menu' ];
        if( !c.delivery_from_type ) className.push( 'input-error' );

        return <ApDropdown
            fullWidth
            button={ <div className={ className.join(' ') }> { title } <div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
            actions={ options }
        />
    }

    renderDeliveryTo( c )
    {
        let receiverType = this.props.deliveryReceiverType;

        if (receiverType != 'location') {
            const receiverLocation = c.locations.find(location => location.id === this.props.deliveryReceiverId);
            return (
                <div>
                    <div>
                        <strong>
                            {receiverLocation && receiverLocation.name}
                            {!receiverLocation && this.props.deliveryReceiverName}
                        </strong>
                    </div>
                    <div>
                        {tr('balance')}: {receiverLocation ? receiverLocation.balance : '0'}
                    </div>
                </div>
            );
        }

        let className = [ 'input-menu' ];

        if( c.delivery_from_type === 'direct' )
        {
            let title = this.renderDeliveryMenuSelected( 'from', c );
            return <div className="input-menu disabled">{ title }</div>
        }


        let title = this.renderDeliveryMenuSelected( 'to', c );
        let options = this.getDeliveryMenuOptions( 'to', c );

        let disabled = false;
        if( this.props.deliveryToLocked ) disabled = true;
        if( disabled )
            return <div className="input-menu disabled" disabled>{ title }</div>

        if( !c.delivery_to_type ) className.push( 'input-error' );

        return <ApDropdown
            disabled={disabled}
            fullWidth
            button={ <div className={ className.join(' ') }> { title } <div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
            actions={ options }
        />
    }

    // ----------------------------------------
    //  Seelect stuff
    // ----------------------------------------
    //
    toggleListSelect()
    {
        this.setState({ listSelectShow: !this.state.listSelectShow });
    }

    selectFromTable( id )
    {
        this.addComponent( { id: id } );
    }

    dropDownOption( item, search )
    {
        const searches = search.toLowerCase().split( ' ' ).filter( s => s.length > 0 );

        let identifiers = [];
        item.identifiers.forEach( ( i ) => {
            if ( typeof i.value !== 'string' || i.value.length === 0 )
                return null;

            const found = searches.some( s => i.value.toLowerCase().includes( s.toLowerCase()) );
            if( found )
            {
                identifiers.push(
                    <div className="sIdentifier" key={ i.id }>
                        <span className="name">{ i.name }:</span>
                        <span className="value">
                            <Highlighter
                                searchWords={ searches }
                                autoEscape={true}
                                textToHighlight={ i.value }
                            />
                        </span>
                    </div>
                );
            }
        });

        let lCount = item.locations.length;
        let balance = 0;
        let balanceFree = 0;
        item.locations.forEach( l => {
            balance += parseFloat( l.balance );
            balanceFree += parseFloat( l.balance_free );
        });

        let locationsDom   = null;
        let balanceDom     = null;
        let balanceFreeDom = null;

        if( lCount > 1 )
            locationsDom = <span className="balanceLocations"> { tr('storages_count') }: { lCount } </span>;

        if( lCount > 0 )
            balanceDom   = <span className="balance"> { tr('balance') }: { removeRoundOff( balance ) }{ item.unit } </span>;

        if( balance !== balanceFree )
            balanceFreeDom = <span className="balanceFree"> { tr('free') }:{ removeRoundOff( balanceFree ) }{ item.unit } </span>;

        return <div className="sComponent">
            <strong>
                <Highlighter
                    searchWords={ searches }
                    autoEscape={true}
                    textToHighlight={ item.name }
                />
            </strong><br />
            <small>
                <Highlighter
                    searchWords={ searches }
                    autoEscape={true}
                    textToHighlight={ item.code }
                />
            </small><br />
            { locationsDom }
            { balanceDom }
            { balanceFreeDom }
            { identifiers }
        </div>
    }


    // ----------------------------------------
    //  Component select
    // ----------------------------------------

    renderComponentSelect()
    {
        let supplierPriceToggle = null;

        if( this.props.instance === 'po' || this.props.instance === 'supplier_offer' )
        {
            supplierPriceToggle = <ApAddon width="180px" className="only-current-sypplier">
                <ApTooltip position="topright" text={ tr('show_storage_components_with_supplier') }>
                    <div className="text-right">
                        { tr('supplier') }
                        <ApSwitch
                            id="supplier-price-toggle"
                            on={ this.state.filterSupplierPrice }
                            onChange={ () => { this.setState({ filterSupplierPrice: !this.state.filterSupplierPrice }) } }
                            inline
                            small
                        />
                    </div>
                </ApTooltip>
            </ApAddon>
        }

        let menuActions = [];
        if( this.props.instance !== 'assignment' ) {
            menuActions.push({
                label: this.state.listSelectShow ? tr('hide_selection_list') : tr('show_selection_list'),
                icon: 'list',
                action: ( item, closeFunc ) => {
                    this.toggleListSelect()
                    closeFunc();
                },
            });
        }
        let outsideStorageTypes = [];
        if( this.props.instance === 'po' )
            outsideStorageTypes = ['item', 'other', 'work' ];
        else if( this.props.instance === 'order' )
            outsideStorageTypes = ['item', 'other', 'work' ];
        else if( this.props.instance === 'assignment' )
            outsideStorageTypes = [ 'item' ];
        else if( this.props.instance === 'supplier_offer' )
            outsideStorageTypes = [ 'item' ];

        outsideStorageTypes = componentTypes.filter( ct => {
            return outsideStorageTypes.includes( ct.name );
        });
        if( outsideStorageTypes.length > 0 )
        {
            menuActions.push({ "divider": tr('add_external_component') });
            outsideStorageTypes.forEach( ct => {
                menuActions.push({
                    label: ct.text,
                    icon: getTypeIcon( ct.name ),
                    action: ( item, closeFunc ) => {
                        this.componentAddOutsideStorage( ct );
                        closeFunc();
                    },
                });
            });
        }

        let menuDom = <ApAddon noLeftBorder width="40px">
            <ApDropdown
                actions={ menuActions }
                button={ <SvgIcon icon="ellipsis-h" type="solid" /> }
            />
        </ApAddon>

        let instanceOptions = {};
        if( this.state.filterSupplierPrice )
            instanceOptions.supplierId  = this.props.supplierId;
        if( this.state.filterSupplierPrice && this.props.suppliers && Array.isArray(this.props.suppliers) )
            instanceOptions.supplierIds  = this.props.suppliers.map(supplier => supplier.id);

        if( this.state.listSelectShow )
        {
            return <div>
                <ApInputStack gap="0">
                    <ApAddon><div>&nbsp;</div></ApAddon>
                    { supplierPriceToggle }
                    { menuDom }
                </ApInputStack>
                <CitList
                    ref={ node => this.citList = node }
                    onSelect={ this.selectFromTable }
                    instance={ this.props.instance }
                    instanceOptions={ instanceOptions }
                />
            </div>

        }
        if(this.props.instance === 'po')
        {

            return <ApInputStack gap="0">
                <ApSelect
                    label={ tr('search_storage_component') }
                    loading={ this.state.loading }
                    value=""
                    // optionRenderer={ this.dropDownOption }
                    optionRenderer="component_detail_price_po"
                    onChange={ this.addComponent }
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="storage/components"
                    apiData={{
                        instance: this.props.instance,
                        instanceOptions: instanceOptions,
                        include: [ 'locations', 'identifiers' ],
                        formatter: 'instance',
                        type: this.props.receiverType,
                    }}
                />
                { supplierPriceToggle }
                { menuDom }
            </ApInputStack>
        }

        if(this.props.instance === 'order')
        {

            return <ApInputStack gap="0">
                <ApSelect
                    label={ tr('search_storage_component') }
                    loading={ this.state.loading }
                    value=""
                    // optionRenderer={ this.dropDownOption }
                    optionRenderer="component_detail_price"
                    onChange={ this.addComponent }
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="storage/components"
                    apiData={{
                        instance: this.props.instance,
                        instanceOptions: instanceOptions,
                        include: [ 'locations', 'identifiers' ],
                        formatter: 'instance',
                        type: this.props.receiverType,
                        crmId: this.props.crmId ? this.props.crmId : null,
                    }}
                />
                { supplierPriceToggle }
                { menuDom }
            </ApInputStack>
        }

        else if (this.props.instance === 'delivery') {
            const exludeIds = this.props.components.map(c => c.component_id);
            return <ApInputStack gap="0">
            <ApSelect
                label={ tr('search_storage_component') }
                loading={ this.state.loading }
                value=""
                // optionRenderer={ this.dropDownOption }
                optionRenderer="component_detail"
                onChange={ this.addComponent }
                objKeyId="id"
                objKeyValue="name"
                apiUrl="storage/components"
                apiData={{
                    instance: this.props.instance,
                    instanceOptions: instanceOptions,
                    include: [ 'locations', 'identifiers' ],
                    formatter: 'instance',
                    type: this.props.receiverType,
                    filter_by_location_id: this.props.deliverySenderId,
                    exludeIds: exludeIds,
                }}
                disabled={this.props.deliveryComponentsDisabled}
            />
            {/* { menuDom } */}
        </ApInputStack>
        }
        else{
            return <ApInputStack gap="0">
            <ApSelect
                label={ tr('search_storage_component') }
                loading={ this.state.loading }
                value=""
                // optionRenderer={ this.dropDownOption }
                optionRenderer="component_detail"
                onChange={ this.addComponent }
                objKeyId="id"
                objKeyValue="name"
                apiUrl="storage/components"
                apiData={{
                    instance: this.props.instance,
                    instanceOptions: instanceOptions,
                    include: [ 'locations', 'identifiers' ],
                    formatter: 'instance',
                    type: this.props.receiverType,
                    getStorageBalance: this.props.instance === 'assignment',
                    storageId: this.props.storageId,
                }}
                showIdentifiers={this.props.instance === 'assignment'}
            />
            { supplierPriceToggle }
            { menuDom }
        </ApInputStack>
        }
    }


    // ----------------------------------------
    //  Render
    // ----------------------------------------

    render()
    {
        const table = this.renderCT();

        let select = null;

        if( !this.props.addComponentLocked )
        {
            select = this.renderComponentSelect();
        }

        return <div id="componentInstanceTable">
            { table }
            { select }

            <ComponentEdit
                show={ this.state.componentEditShow }
                id={ this.state.componentEditId }
                onClose={ () => { this.setState({ componentEditShow: false }) } }
                onSave={ this.onComponentEditSave }
            />

            <ApConfirm
                show={ this.state.confirmPoPriceShow }
                onClose={ () => { this.setState( { confirmPoPriceShow: false } ) } }
                onConfirm={this.syncPoPrice }
                header={ tr('update_supplier_price_header') }
                body={ this.state.confirmPoPriceText }
                meta={ this.state.confirmPoPriceMeta }
            />

            <CreateStock
                show={this.state.showCreate}
                header="Luodaanko uusi varastopaikka?"
                body="Oletko varma että haluat poistaa lupatyypin käytöstä?"
                onSave={(data) => {this.onNewStockSaved(data)}}
                onClose={() => {this.setState({ showCreate: false })}}
                component_id={this.state.selectedComponent}
                row_id={this.state.selectedRow}
            />
            {this.props.instance != "po-import" &&
                <div className="fieldEdit">
                    <ApModalInput
                        show={this.state.costCenterEditShow}
                        title={tr("edit_cost_center")}
                        value={this.state.editComponent ? this.props.costCenters.find(costCenter => costCenter.id == this.state.editComponent.cost_center_id) : null}
                        //options={this.props.costCenters}
                        //label={"label"}
                        required={this.props.costCenterIsRequired}
                        type={"costCenter"}
                        onSave={(value) => {
                            if (this.state.costCenterIsGroup) {
                                for (let component of this.state.editGroupComponents) {
                                    component.cost_center_id = value ? value.id : null;
                                }
                            }
                            else {
                                let components = this.props.components.slice(0);
                                for (let component of components) {
                                    if (component.id === this.state.editComponent.id) {
                                        component.cost_center_id = value ? value.id : null;
                                        break;
                                    }
                                }

                                this.props.componentsChange(components);
                            }



                        }}
                        onClose={() => this.setState({
                            costCenterEditShow: false,
                            costCenterIsGroup: null,
                            editComponent: null,
                            editGroupComponents: null,
                        })}
                    />
                </div>
            }
            {this.renderAssignmentComponentFilesModal()}
                

        </div>
    }
}

export default ComponentInstanceTable;
