import React from 'react';
import autoBind from 'react-autobind';

import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import ApModal from 'common/ApModal/ApModal.js';

import { ApInput
       , ApInputStack
       , ApAddon }     from 'common/ApInput/ApInput.js';
import ApSelect        from 'common/ApSelect/ApSelect.js';

import api                  from 'services/Api/Api.js';
import '../../common/RelatedSelectModal/RelatedSelectModal.css'
import { tr } from 'services/Helpers/Helpers';

class EditOrderCode extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            value: null,
            options: null,
        };

        this.validator = new ApValidate(this, {
        });

        autoBind(this);
    }

    resetState()
    {
        this.setState({
            value: this.props.value,
        });
    }

    close()
    {
        this.props.handleClose({})
    }

    save()
    {
        const project = this.state.value;

        this.props.handleClose({
            [ `${ this.props.field }` ]: project ? project : null,
        });
    }

    renderBody()
    {
        const field = this.props.field;
        let title = this.props.title;
        let validationState = null;

        return <ApInputStack gap="0">
            <ApAddon
                noRightBorder width="200px"
                validationState={ validationState }
            >
                { title }
            </ApAddon>

            
            <ApSelect
                label={tr('project')}
                value={ this.state.value }
                optionRenderer="project"
                valueRenderer="project"
                onChange={ ( project ) => this.setState({value: project}) }
                objKeyId="id"
                objKeyValue="name"
                clearable
                apiUrl="storage/order/projects/find"
                apiData={{
                    formatter: 'select',
                    withParents : 'true',
                    customer: this.props.customer_id,
                    withOffers: false,
                    withoutClosed: true,
                }}
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
        </ApInputStack>

    }

    render()
    {
        const saveDisabled = ( !this.state.value );

        return <div className="poRelatedEdit">
            <ApModal
                show={ this.props.show }
                handleClose={ this.props.handleClose }
                className="narrow"
                onOpen={ this.resetState }
                header={
                    <div className="padding-small">
                        <h4>
                            Linkitä projektikoodi
                        </h4><br/>

                        {/* <h6>Ainoastaan valitun asiakkaan projektit listataan!</h6> */}
                    </div>
                }
                body={
                    <div className="padding">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton onClick={ this.close }>
                            <SvgIcon icon="times" type="solid" />
                            {tr('cancel')}
                        </ApButton>

                        <ApButton color="green" onClick={ this.save } disabled={ saveDisabled }>
                            <SvgIcon icon="check" type="solid" />
                            {tr('continue')}
                        </ApButton>
                    </div>
                }
            />
        </div>

    }
}

export default EditOrderCode;