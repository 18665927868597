import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import { Container, Row, Col, Modal } from 'react-bootstrap';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';

export default class EditNewsModal extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			item: null,
			header: null,
			content: null,
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		//console.log(this.props.itemId);

		if (this.props.itemId !== null) this.getNewsitem(this.props.itemId);

	}

	getNewsitem(id)
	{

		api({
			method: 'get',
			url: 'crm/extranet/getNewsitem',
			params: { id: id }
		}).then((response) => {
			//console.log(response);
			this.setState({ 
				item: response,
				header: response.header,
				content: response.content
			});
		}).catch((error) => {
			console.log(error);
		});

	}

	saveItem()
	{

		api({
			method: 'post',
			url: 'crm/extranet/saveNewsitem',
			data: {
				id: this.props.itemId,
				header: this.state.header,
				content: this.state.content
			}
		}).then((response) => {
			//console.log("save successful");
			this.props.onClose();
		}).catch((error) => {
			console.log(error);
		});

	}

	clickSaveButton()
	{
		this.saveItem();
	}

	clickCancelButton()
	{
		this.props.onClose();
	}

	handleChange(e)
	{
		this.setState({ [e.target.name]: e.target.value });
	}

	renderForm()
	{

		const form = <Container fluid={true}>
				<Row>
					<Col>
						<ApInput
							name="header"
							id="header"
							label="Otsikko"
							value={this.state.header}
							type="text"
							onChange={this.handleChange}
						/>
						<ApInput
							name="content"
							id="content"
							label="Sisältö"
							value={this.state.content}
							type="textarea"
							onChange={this.handleChange}
						/>
					</Col>
				</Row>
			</Container>;

		return form;

	}

	render()
	{

		const form = this.renderForm();

		return(

			<Modal
				show={this.props.show}
				backdrop="static"
				bsSize="large"
			>

				<Modal.Header>
					<Modal.Title> {this.props.itemId === null ? "Uusi uutinen" : "Muokkaa uutista"} </Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{form}
				</Modal.Body>

				<Modal.Footer>
					<ApButton color="red" onClick={() => {this.clickCancelButton(null)}}>
						Peruuta
					</ApButton>
					<ApButton color="blue" onClick={this.clickSaveButton}>
						Tallenna
					</ApButton>
				</Modal.Footer>

			</Modal>

		);

	}

}