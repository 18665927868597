import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import ApDateInterval from 'common/ApDateInterval/ApDateInterval.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApReactTable, { colPreset } from 'common/ApReactTable/ApReactTable.js';
import ApModal from 'common/ApModal/ApModal.js';

import './BillOfUse.css';

class BillOfUse extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,

            data: [],

            start: null,
            end: null,

            usersModal: {},
            changesModal: {},
        };
        autoBind(this);
    }

    componentDidMount()
    {
        // this.fetchData();
    }

    fetchData()
    {

        this.setState({ loading: true });

        let data = {
            start: this.state.start,
            end: this.state.end,
        };

        api({
            method: 'post',
            url: `management/billofuse`,
            errorPopper: 'Käyttäjätietojen hakemisessa tapahtui virhe',
            data: data,
        }).then(( response ) => {

            //console.log( 'Data', response );
            this.setState({
                loading: false,
                data: response.companies,
            });

        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    renderTable()
    {
        return <div>
            <ApReactTable
                defaultSorted={ [ { id: 'name', desc: true } ] }
                columns={[{
                        id: 'name',
                        Header: 'Yritys',
                        accessor: 'name',

                    }, colPreset({
                        type: "numberNice",
                        decimals: 0,
                        id: 'admins',
                        Header: 'Pääkäytäjiä',
                        accessor: p => p.admin_users.length,
                        onClick: ( p ) => {
                            this.setState({
                                usersModal: {
                                    show: true,
                                    type: 'admin',
                                    company: p,
                                },
                            });
                        }

                    }), colPreset({
                        type: "numberNice",
                        decimals: 0,
                        id: 'advanced',
                        Header: 'Toimihenkilöitä',
                        accessor: p => p.advanced_users.length,
                        onClick: ( p ) => {
                            this.setState({
                                usersModal: {
                                    show: true,
                                    type: 'advanced',
                                    company: p,
                                },
                            });
                        }

                    }), colPreset({
                        type: "numberNice",
                        decimals: 0,
                        id: 'limited',
                        Header: 'Normikäyttäjiä',
                        accessor: p => p.limited_users.length,
                        onClick: ( p ) => {
                            this.setState({
                                usersModal: {
                                    show: true,
                                    type: 'limited',
                                    company: p,
                                },
                            });
                        }

                    }), colPreset({
                        type: "numberNice",
                        decimals: 0,
                        id: 'not_active',
                        Header: 'Ei aktiivisia',
                        accessor: p => p.not_active_users.length,
                        onClick: ( p ) => {
                            this.setState({
                                usersModal: {
                                    show: true,
                                    type: 'not_active',
                                    company: p,
                                },
                            });
                        }

                    }),
                ]}
                data={ this.state.data }
                loading={ this.state.loading }
            />
        </div>
    }

    setInterval( data )
    {
        this.setState({
            start: data.start,
            end: data.end,
        });
    }

    renderUsersModal()
    {
        let data = [];
        if( this.state.usersModal.company )
            data = this.state.usersModal.company[ `${ this.state.usersModal.type }_users` ]

        return <ApModal
            show={ Boolean( this.state.usersModal.show ) }
            handleClose={ () => this.setState({ usersModal: {} }) }
            closeFromBg
            className="narrow"
            header={<div className="padding">Käyttäjät</div>}
            body={ <div className="padding">
                <ApReactTable
                    defaultSorted={ [ { id: 'name', desc: true } ] }
                    columns={[{
                            id: 'name',
                            Header: 'Henkilö',
                            accessor: 'name',

                        }, colPreset({
                            type: "numberNice",
                            decimals: 0,
                            id: 'changes',
                            Header: 'Tila muutoksia',
                            width: 200,
                            accessor: p => p.changes.length,
                            onClick: ( p ) => {
                                //console.log('Changes', p );
                                this.setState({
                                    changesModal: {
                                        show: true,
                                        user: p,
                                    },
                                });
                            }
                        }),
                    ]}
                    data={ data }
                    loading={ this.state.loading }
                />
            </div>}
        />

    }

    renderChangesModal()
    {
        let data = [];
        if( this.state.changesModal.user )
            data = this.state.changesModal.user.changes;

        return <ApModal
            show={ Boolean( this.state.changesModal.show ) }
            handleClose={ () => this.setState({ changesModal: {} }) }
            closeFromBg
            className="narrow"
            header={<div className="padding">Muutokset</div>}
            body={ <div className="padding">
                <ApReactTable
                    defaultSorted={ [ { id: 'name', desc: true } ] }
                    columns={[colPreset({
                            id: 'date',
                            Header: 'Päivä',
                            accessor: 'date',
                            type: 'date',
                        }),{
                            id:         "is_active", 
                            Header:     "Aktiivinen",
                            accessor:   "is_active",
                            width: 70,
                            Cell: ( props ) => {
                                if( props.value )
                                    return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                            },
                        },{

                            id: 'status',
                            Header: 'Tila',
                            accessor: 'status',
                            Cell: props => {
                                switch( props.value ) {
                                    case 'admin':
                                        return <div className="apStatusBox info">Pääkäyttäjä</div>
                                    case 'advanced':
                                        return <div className="apStatusBox success">Toimihenkilö</div>
                                    case 'limited':
                                        return <div className="apStatusBox warning">Peruskäyttäjä</div>
                                    default:
                                        return null;
                                }
                            },
                        },

                    ]}
                    data={ data }
                    loading={ this.state.loading }
                />
            </div> }
        />

    }

    render()
    {
        return <div className="apBox" id="pageBillForUsage">
            <div className="apBoxHeader">
                <h1>EASY-PRO käytöstä laskutettavat</h1>
                <p>Lista laskutettavista käyttäjistä</p>
            </div>
            <div className="padding">
                <div className="padding">
                    <ApDateInterval
                        start={ this.state.start }
                        end={this.state.end }
                        onChange={ this.setInterval }
                    />

                    <ApButton color="blue" size="small" onClick={ () => this.fetchData() }>
                        Hae tiedot laskutukseen
                        <SvgIcon icon="receipt" type="solid" />
                    </ApButton>
                </div>
                { this.renderTable() }
                <div className="padding">
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        Aikavälin sisällä tehdyt muutokset vaikuttavat ainoastaan hintaa korottavasti. Esimerkiksi jos aikaväliksi valitaan kuukausi, tämän kuun aikana käyttäjä kohotetaan normikäyttäjästä toimihenkilöksi, lasketaan käyttäjä olleen kuukauden aikana toimihenkilö. Jos käyttäjä alennetaan kuukauden aikana toimihenkilöstä normikäyttäjäksi, katsotaan hänet edelleen ollen tämän kuukauden toimihenkilö, kuitenkin seuraavana kuukaudesta eteenpäin hänet lasketaan normikäyttäjäksi.
                    </div>
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        Käyttäjään tehdyt muutokset katsotaan päiväkohtaisesti. Mikäli toimihenkilö merkitään aamulla pääkäyttäjäksi, mutta merkitään illalla takaisin toimihenkilöksi. Hänen katsotaan olleen toimihenkilö. Mikäli käyttäjä merkitään aamulla pääkäyttäjksi mutta vasta seuraavana päivänä takaisin toimihenkilöksi katsotaan hänen olleen pääkäyttäjä.
                    </div>
                </div>
            </div>

            { this.renderUsersModal() }
            { this.renderChangesModal() }

        </div>
    }
}

export default BillOfUse;
