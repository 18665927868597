import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import { Grid, Row, Col, Modal } from 'react-bootstrap';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import ApTooltip            from 'common/ApTooltip/ApTooltip.js';
import auth                 from 'services/Authed/Authed.js';
import { tr } from 'services/Helpers/Helpers';

export default class EditNewsModal extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			item: null,
			header: null,
			content: null,
			limited: false
		};

		this.validator = new ApValidate( this, {
			"header":           { filter: 'required', state: 'warning', text: 'Otsikko ei voi olla tyhjä'}
		  });

		autoBind(this);

	}

	componentDidUpdate(prevProps)
	{

		if (this.props.itemId && prevProps.itemId !== this.props.itemId) this.getNewsitem(this.props.itemId);

	}

	getNewsitem(id)
	{

		api({
			method: 'get',
			url: 'management/news/getNewsItem',
			params: { id: id }
		}).then((response) => {
			//console.log(response);
			this.setState({ 
				item: response,
				header: response.header,
				content: response.content,
				limited: response.limited
			});
		}).catch((error) => {
			console.log(error);
		});

	}

	saveItem()
	{

		api({
			method: 'post',
			url: 'management/news/saveNewsItem',
			data: {
				id: this.props.itemId,
				header: this.state.header,
				content: this.state.content,
				limited: this.state.limited
			}
		}).then((response) => {
			//console.log("save successful");
			this.props.onClose();
			this.clear();
		}).catch((error) => {
			console.log(error);
		});

	}

	deleteItem()
	{

		api({
			method: 'post',
			url: 'management/news/deleteNewsItem',
			data: {
				id: this.props.itemId,
			}
		}).then((response) => {
			//console.log("delete successful");
			this.props.onClose();
		}).catch((error) => {
			console.log(error);
		});

	}

	clickSaveButton()
	{
		this.saveItem();
	}

	clickCancelButton()
	{
		this.props.onClose();
		this.clear();
	}

	clear()
	{
		this.setState({
			item: null,
			header: null,
			content: null,
			limited: false
		})
	}

	handleChange(e)
	{
		this.setState({ [e.target.name]: e.target.value });
	}

	renderFoobar()
    {
        let types = [{
            name: 'normal',
			title: tr('all'),
            onClick: () => {
                this.setState({limited: false });
            },
        },{
            name: 'limited',
			title: tr('only_administrator'),
            onClick: () => {
                this.setState({ limited: true });
            }
        }];

        let selected = this.state.limited ? 'limited' : 'normal';

        let doms = types.map( t => {
            let disabled = false;

            let className = [ 'option' ];
            if( t.name === selected ) className.push( 'selected' );
            if( disabled ) className.push( 'disabled' );

            return <div key={ t.name }
                className={ className.join(' ') }
                onClick={ ( disabled ) ? null : t.onClick }
            >
                { t.title }
            </div>
        })

        return <div className={`apOptionBar count-${ types.length } userType`}>
            { doms }
        </div>

    }

	renderForm()
	{

		const form = <div>
						<ApInput
							name="header"
							id="header"
							label="Otsikko"
							value={this.state.header}
							type="text"
							onChange={this.handleChange}
							validationState={ this.validator.getState('header') }
						/>
						<ApInput
							name="content"
							id="content"
							label="Sisältö"
							value={this.state.content}
							type="textarea"
							rows={ 5 }
							onChange={this.handleChange}
						/>
						<strong>Näkyvissä:</strong>
						{this.renderFoobar()}
					</div>;

		return form;

	}

	render()
	{

		const form = this.renderForm();

		return(

			<ApModal
				show={this.props.show}
				backdrop="static"
				className="narrow"
				header={ 
					<div className="padding-small">
						<h4>{this.props.itemId === null ? "Uusi uutinen" : "Muokkaa uutista"}</h4>
					</div>
				}
				body={
					<div className="padding" style={{ marginTop: 30 }}>
					  {form}
					</div>
				}
				footer={
					<div className="padding" style={{display: 'flex', flexDirection: 'row'}}>
		
					<ApButton className="cancel" onClick={() => {this.clickCancelButton(null)}}>
						<SvgIcon icon="times" type="solid" />
						Peruuta
					</ApButton>

					{ this.props.itemId && 
						<ApButton className="remove" color="red" style={{marginLeft: 20}} onClick={ this.deleteItem }>
						<SvgIcon icon="trash" type="solid" />
						Poista
						</ApButton>
					}
		
					<ApButton
						className="save"
						color="blue"
						onClick={ this.clickSaveButton }
						loading={ this.state.loading }
						disabled={ Boolean(this.validator.getState('header')) }
						style={{marginLeft: 'auto'}}
					>
						<SvgIcon icon="save" type="solid" />
						Tallenna
					</ApButton>
					
					</div>
				  }
			/>

		);

	}

}