import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import { tr } from 'services/Helpers/Helpers.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import EditNewsModal from './EditNewsModal.js';

export default class CompanyNews extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			showEditModal: false,
			newsitems: [],
			selectedItemId: null
		}

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		this.getColumns();

	}

	UNSAFE_componentWillReceiveProps(props)
	{

		this.setState({ newsitems: props.data });
		//this.getNewsitems();

	}

	getNewsitems()
	{

		api({
			method: 'get',
			url: 'company/settings/getNewsItems'
		}).then((response) => {
			this.setState({ newsitems: response });
		}).catch((error) => {
			console.log(error);
		});

	}

	getColumns()
	{

		const columns = [
			{
				Header: tr('header'),
				accessor: 'header',
				onClick: this.openItem
			},
			{
				Header: tr('content'),
				accessor: 'content'
			},
			{
				Header: tr('creator'),
				accessor: 'full_name'
			},
			{
				Header: tr('visibility'),
				accessor: 'role.name',
				Cell: (props) => {
					return (
						<div>
							{props.original.role_id ? props.original.role.name : tr('all')}
						</div>
					);
				}
			},
			{
				Header: tr('displayed'),
				accessor: 'hidden',
				Cell: (props) => {
					return (
						<div>
							{this.getStatusIcon(props.original)}
						</div>
					);
				}
			}
		];

		this.setState({ columns });

	}

	createNewItem()
	{

		this.setState({ showEditModal: true });

	}

	openItem(item)
	{

		this.setState({ selectedItemId: item.id, showEditModal: true });

	}

	closeEditModal()
	{

		this.setState({ selectedItemId: null, showEditModal: false }, () => {
			this.getNewsitems();
		});

	}

	switchVisibility(id)
	{	
		api({
			method: 'post',
			url: 'company/settings/switchNewsitemVisibility',
			data: { id }
		}).then((response) => {
			this.getNewsitems();
		}).catch((error) => {
			console.log(error);
		});

	}

	getStatusIcon(item)
	{

		return (
				<div 
					style={{cursor: 'pointer', textAlign: 'center'}}
					onClick={() => this.switchVisibility(item.id)}
				>
					{ item.hidden ?
						<SvgIcon fill="#c40" icon="times-circle" />
					:
						<SvgIcon fill="#4c0" icon="check-circle" />
					}
				</div>		
		)
	}

	render()
	{

		return(

			<div className="apBox" id="extranetNewsSettings">

				<div className="apBoxHeader">
					<h1> { tr('news') } </h1>
				</div>

				<div className="padding">
					<ApButton
						color="green"
						onClick={() => {this.createNewItem()}}
					>
            <SvgIcon icon="plus" type="solid" />
						{ tr('add_news') }
					</ApButton>
				</div>

				<div className="padding">
					<ApReactTable
						data={this.state.newsitems}
						columns={this.state.columns}
						rowActions={ (row) => (
							<div className="apSimpleButton" onClick={ () => this.openItem(row) }>
								<SvgIcon icon="arrow-right" type="solid" />
							</div>
						)}
					/>
				</div>

				<EditNewsModal 
					show={this.state.showEditModal}
					onClose={this.closeEditModal}
					itemId={this.state.selectedItemId}
				/>
               

			</div>



		);

	}

}
