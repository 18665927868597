/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Collapse } from 'react-bootstrap';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import { dateInputToSql, sqlToDateInput } from 'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
//import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';


class RuleEdit extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            data: false,
            unsavedChanges: false,
        };
        autoBind(this); 
    }

    fetchData()
    {
        const id = this.props.id;

        // Id is not number so adding new rule
        if( typeof( id ) != "number" ) 
        {
            this.setState({ 
                data: {
                    active:                 true,
                    start_date:             moment().format('YYYY-MM-DD'),
                    end_date:               null,
                    name:                   '',
                    description:            '',
                    users:                  [],
                    projects:               [],
                    salary_extra:           null,
                    salary_extra_per_unit:  null,
                    salary_extra_percent:   null,
                    salary_category:        null,
                    limit_available:        false,
                    limit:                  null,
                    limit_type:             null
                },
                unsavedChanges: false,
            });
        }
        // Id isset so we are editing old rule
        else 
        {
            this.setState({ loading: true });
            api({
                method: 'get',
                url: 'hour/salary_extras/rule/' + id,
            }).then(( response ) => {

                //console.log('fetchData', response );

                // Add limit availablility based on limit/limit_type values 
                response.limit_available = ( response.limit && response.limit_type );

                this.setState({
                    loading: false,
                    data: response,
                    unsavedChanges: false,
                });
                
            }).catch( ( error ) => {
                console.error(error);
                this.setState({ loading: false });
            });
        }
    }

    handleChange( e )
    {
        let data = { ...this.state.data };
     
        switch( e.target.name )
        {   
            case "start_date":
            case "end_date":
                data[ e.target.name ] = dateInputToSql( e.target.value );
                break;

            default: 
                data[ e.target.name ] = e.target.value;
                break;
        }

        this.setState({ 
            data: data, 
            unsavedChanges: true,
        });
    }

    handleSwitchChange( name, checked )
    {
        let data = { ...this.state.data }; 
        data[ name ] = checked;
        this.setState({ 
            data: data, 
            unsavedChanges: true,
        });
    }

    handleListChange( values, name ) 
    {
        let data = { ...this.state.data }; 
        data[ name ] = values;
        this.setState({ 
            data: data, 
            unsavedChanges: true,
        });
    }

    save()
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'hour/salary_extras/rule/save',
            data: this.state.data,
        }).then(( response ) => {
            
            //console.log('save success: ', response );

            if( typeof( this.props.onClose ) == "function" )
                this.props.onClose( false, true );

            this.setState({
                loading: false,
                unsavedChanges: false,
            });
            
        }).catch( ( error ) => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    renderBody()
    {
        return (
            <div id="RuleEditBody">
                <div className="apForm">
                    <div className="apFormColumn">

                        <ApInput 
                            type="text" 
                            id="name" 
                            name="name" 
                            label="Nimi"
                            value={ this.state.data.name }
                            onChange={ this.handleChange }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            //validationState={ this.validatorHour.getState('data.description') }
                        />

                        <ApInput 
                            type="textarea" 
                            id="description" 
                            name="description" 
                            label="Kuvaus"
                            value={ this.state.data.description }
                            onChange={ this.handleChange }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            //validationState={ this.validatorHour.getState('data.description') }
                        />

                        <ApInput 
                            type="text" 
                            id="salary_category" 
                            name="salary_category" 
                            label="Palkkaluokka"
                            value={ this.state.data.salary_category }
                            onChange={ this.handleChange }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            //validationState={ this.validatorHour.getState('data.description') }
                        />

                        <br />

                        <ApInputStack gap="0">
                            <ApAddon noRightBorder>
                                Kiinteä korvaus
                            </ApAddon>
                            <ApInput 
                                width="100px"
                                type="number" 
                                id="salary_extra" 
                                name="salary_extra" 
                                align="right"
                                value={ this.state.data.salary_extra }
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                //validationState={ this.validatorHour.getState('data.description') }
                            />
                            <ApAddon noLeftBorder width="100px">
                                { auth.getCurrency() }
                            </ApAddon>
                        </ApInputStack>

                        <ApInputStack gap="0">
                            <ApAddon noRightBorder>
                                Tuntimäärään perustuva korvaus
                            </ApAddon>
                            <ApInput 
                                width="100px"
                                type="number" 
                                id="salary_extra_per_unit" 
                                name="salary_extra_per_unit" 
                                align="right"
                                value={ this.state.data.salary_extra_per_unit }
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                //validationState={ this.validatorHour.getState('data.description') }
                            />
                            <ApAddon noLeftBorder width="100px">
                                { auth.getCurrency() } / h
                            </ApAddon>
                        </ApInputStack>

                        <ApInputStack gap="0">
                            <ApAddon noRightBorder>
                                Tuntipalkkaan perustuva korvaus
                            </ApAddon>
                            <ApInput 
                                width="100px"
                                type="number" 
                                id="salary_extra_percent" 
                                name="salary_extra_percent" 
                                align="right"
                                value={ this.state.data.salary_extra_percent }
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                //validationState={ this.validatorHour.getState('data.description') }
                            />
                            <ApAddon noLeftBorder width="100px">
                                %
                            </ApAddon>
                        </ApInputStack>

                        <div className={ "apFormGroup" + ( this.state.data.limit_available ? " success" : "" ) }>
                            <div className="apSwitchBlock small">
                                <label htmlFor="limit-switch" className="info">
                                    Rajoita korvauksen määrää
                                    <small>Voit asettaa maksimi summan tietylle aikavälille per käyttäjä</small>
                                </label>
                                <ApSwitch
                                    small
                                    id="limit-switch"
                                    on={ this.state.data.limit_available }
                                    onChange={ ( e ) => this.handleSwitchChange( 'limit_available', e.target.checked ) }
                                    disabled={ this.state.loading }
                                />
                            </div>
                            
                            <Collapse in={ this.state.data.limit_available }>
                                <div>
                                    <hr />

                                    <ApInputStack gap="0">
                                        <ApInput 
                                            gapRight={ 10 }
                                            type="select" 
                                            id="limit_type" 
                                            name="limit_type" 
                                            value={ this.state.data.limit_type }
                                            options={[
                                                { value: "period", label: "Voimassaolon aikana" },
                                                { value: "daily", label: "Pävässä" },
                                                { value: "weekly", label: "Viikossa" },
                                                { value: "montly", label: "Kuukaudessa" },
                                                { value: "yearly", label: "Vuodessa" },
                                            ]}
                                            onChange={ this.handleChange }
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading }
                                            //validationState={ this.validatorHour.getState('data.description') }
                                        />
                                        <ApInput 
                                            width="100px"
                                            type="number" 
                                            id="limit" 
                                            name="limit" 
                                            align="right"
                                            value={ this.state.data.limit }
                                            onChange={ this.handleChange }
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading }
                                            //validationState={ this.validatorHour.getState('data.description') }
                                        />
                                        <ApAddon width="100px">
                                            { auth.getCurrency() }
                                            { this.state.data.limit_type == "daily" && "/pv" }
                                            { this.state.data.limit_type == "weekly" && "/vk" }
                                            { this.state.data.limit_type == "montly" && "/kk" }
                                            { this.state.data.limit_type == "yearly" && "/v" }
                                        </ApAddon>
                                        
                                    </ApInputStack>
                                </div>
                            </Collapse>
                        </div>


                    </div>
                    <div className="apFormColumn">
                        
                        <div className={ "apFormGroup" + ( this.state.data.active ? " success" : "" ) }>
                            <div className="apSwitchBlock small">
                                <label htmlFor="activity-switch" className="info">
                                    Aktiivinen
                                    <small>Voit kytkeä säännön pois voimasta väliaikaisesti</small>
                                </label>
                                <ApSwitch
                                    small
                                    id="activity-switch"
                                    on={ this.state.data.active }
                                    onChange={ ( e ) => this.handleSwitchChange( 'active', e.target.checked ) }
                                    disabled={ this.state.loading }
                                />
                            </div>
                        </div>

                        <ApInputStack>
                            <ApInput 
                                type="date" 
                                id="start_date" 
                                name="start_date" 
                                label="Voimassa alkaen"
                                value={ sqlToDateInput( this.state.data.start_date ) }
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                //validationState={ this.validatorHour.getState('data.description') }
                            />

                            <ApInput 
                                type="date" 
                                id="end_date" 
                                name="end_date" 
                                label="Voimassa asti"
                                value={ sqlToDateInput( this.state.data.end_date ) }
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                //validationState={ this.validatorHour.getState('data.description') }
                            />
                        </ApInputStack>

                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { !this.state.data.active && "Korvaus ei ole aktiivisena, joten yllä olevilla päivämäärillä ei ole merkitystä" }
                            { this.state.data.active && !this.state.data.end_date && "Korvaus on toistaiseksi voimassa" }
                            { this.state.data.active && this.state.data.end_date && "Korvaus on yhteensä voimassa XX päivää" }
                        </div>

                        <br />

                        <ApSelect
                            label="Voimassa henkilöille"
                            loading={ this.state.loading }
                            value={ this.state.data ? this.state.data.users : [] }
                            valueRenderer="user"
                            optionRenderer="user"
                            onChange={ ( values ) => this.handleListChange( values, "users" ) }
                            objKeyId="id"
                            multiselect
                            apiUrl="company/settings/users"
                            apiData={{ exluded: [] }}
                            //validationState={ this.validator.getState('settings.workhour.confirmation_users') }
                        />
                        <ApSelect
                            label="Voimassa projekteille"
                            loading={ this.state.loading }
                            value={ this.state.data ? this.state.data.projects : [] }
                            valueRenderer="project"
                            optionRenderer="project"
                            onChange={ ( values ) => this.handleListChange( values, "projects" )  }
                            objKeyId="id"
                            multiselect
                            apiUrl="hour/projects"
                            apiData={{ exluded: [] }}
                            //validationState={ this.validator.getState('settings.workhour.confirmation_users') }
                        />
                    
                    </div>
                </div>
            </div>

        )
    }

    render() 
    {
        return (
            
            <ApModal
                show={ typeof( this.props.id ) == "number" || this.props.id == "new" }
                handleClose={ () => this.props.onClose( this.state.unsavedChanges, false ) }
                onOpen={ this.fetchData }
                closeFromBg
                header={ 
                    <div className="padding" id="RuleEditHeader">
                        <h3>{ this.props.id == "new" ? "Lisää automaattinen palkanlisä" : "Muokkaa automaattista palkanlisää" }</h3>
                    </div>
                }
                body={ this.renderBody() }
                footer={
                    <div className="padding" id="RuleEditFooter">
    
                        <div className="validateMsg">
                            <SvgIcon icon="info-circle" type="solid" />
                            TODO: INFO TEXT HERE
                        </div>

                        <ApButton onClick={ () => this.props.onClose( this.state.unsavedChanges, false ) }>
                            <SvgIcon icon="times" type="solid" />
                            Peruuta
                        </ApButton>

                        <ApButton color="blue" onClick={ this.save } disabled={ !this.state.unsavedChanges }>
                            <SvgIcon icon="save" type="solid" />
                            Tallenna
                        </ApButton>

                    </div>
                }

            />


        );
    }
}


RuleEdit.propTypes = {
    id:         PropTypes.any.isRequired,
    onClose:    PropTypes.func,
    // number:       PropTypes.number.isRequired,
    // string:       PropTypes.string,
    // multiple:     PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
    // bool:         PropTypes.bool,
};


export default RuleEdit;
