
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import 'moment/locale/fi';

import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

// import './Reports.css';

const dateModeOptions = [
    { value: 'none', label: 'Ei aikarajausta' },
    { value: 'dates', label: 'Aikaväli' },
    { value: 'week', label: 'Viikko' },
    { value: 'month', label: 'Kuukausi' },
    { value: 'year', label: 'Vuosi' },
];

class ApDateInterval extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            mode: dateModeOptions[0].value,
            year: null,
            value: null,
        }

        this.currentYear = moment().year();
        this.startYear = this.currentYear - 5;
        this.endYear = this.currentYear + 1;

        autoBind(this);
    }

    // ----------------------------------------
    //  Date options
    // ----------------------------------------

    onChange( data )
    {
        // Set default values
        data = {
            start: this.props.start,
            end: this.props.end,
            mode: this.state.mode,
            year: this.state.year,
            value: this.state.value,
            ...data,
        };

        this.setState({
            mode: data.mode,
            year: data.year,
            value: data.value,
        });

        this.props.onChange({
            start: data.start,
            end: data.end,
            data: {
                mode: data.mode,
                year: data.year,
                value: data.value,
            }
        });

    }

    setDateMode( mode )
    {
        let year = null;
        let value = null;
        let start = null;
        let end = null;

        if( mode === 'week' )
        {
            year = moment().get('year');
            value = moment().year( year ).get('week');

            start = moment().startOf('week').format('YYYY-MM-DD');
            end = moment().endOf('week').format('YYYY-MM-DD');
        }
        else if( mode === 'month' )
        {
            year = moment().get('year');
            value = moment().year( year ).get('month');

            start = moment().startOf('month').format('YYYY-MM-DD');
            end = moment().endOf('month').format('YYYY-MM-DD');
        }
        else if( mode === 'year' )
        {
            year = moment().get('year');

            start = moment().startOf('year').format('YYYY-MM-DD');
            end = moment().endOf('year').format('YYYY-MM-DD');
        }

        this.onChange({
            mode: mode,
            year: year,
            value: value,
            start: start,
            end: end,
        });
    }



    setDateYear( year )
    {
        const mode = this.state.mode;
        const yMoment = moment().year( year );

        let start = null;
        let end = null;
        let value = null;

        if( mode === 'week' )
        {
            if( parseInt( year, 10 ) === this.currentYear )
                value = yMoment.get('week');
            else
                value = 1;

            start = yMoment.week( value ).startOf('week').format('YYYY-MM-DD');
            end = yMoment.week( value ).endOf('week').format('YYYY-MM-DD');

        }
        else if( mode === 'month' )
        {
            if( parseInt( year, 10 ) === this.currentYear )
                value = yMoment.get('month');
            else
                value = 0;

            start = yMoment.month( value ).startOf('month').format('YYYY-MM-DD');
            end = yMoment.month( value ).endOf('month').format('YYYY-MM-DD');
        }
        else if( mode === 'year' )
        {
            start = yMoment.startOf('year').format('YYYY-MM-DD');
            end = yMoment.endOf('year').format('YYYY-MM-DD');
        }

        this.onChange({
            mode: mode,
            year: year,
            value: value,
            start: start,
            end: end,
        });
    }

    setDateValue( value )
    {
        const mode = this.state.mode;
        const yMoment = moment().year( this.state.year );

        let start = null;
        let end = null;

        if( mode === 'week' )
        {
            start = yMoment.week( value ).startOf('week').format('YYYY-MM-DD');
            end = yMoment.week( value ).endOf('week').format('YYYY-MM-DD');

        }
        else if( mode === 'month' )
        {
            start = yMoment.month( value ).startOf('month').format('YYYY-MM-DD');
            end = yMoment.month( value ).endOf('month').format('YYYY-MM-DD');
        }

        this.onChange({
            mode: mode,
            value: value,
            start: start,
            end: end,
        });
    }

    getYearsList()
    {
        let data = [];
        for (let i = this.startYear; i <= this.endYear; i++)
        {
            let label = i;

            if( i === this.currentYear )
                label = `${ label } *`;

            data.push( { value: i, label: label } );
        }
        return data;
    }

    getMonthList()
    {
        let now = moment();
        let yMoment = moment().year( this.state.year );
        const data = [];
        for (var i = 0; i < 12; i++)
        {
            let month = yMoment.month( i );
            let start = month.startOf('month').format('D.M.');
            let end = month.endOf('month').format('D.M.');

            let label = `${ month.format('MMMM') } ( ${ start } - ${ end })`;
            if( month.isSame(now, 'month') )
                label = `${ label } *`;

            data.push({ value: i, label: label });
        }
        return data;
    }

    getWeekList()
    {
        let now = moment();
        let yMoment = moment().year( this.state.year );
        const data = [];
        for (var i = 1; i <= yMoment.weeksInYear() ; i++)
        {
            let week = yMoment.week( i );
            let start = week.startOf('week').format('D.M.');
            let end = week.endOf('week').format('D.M.');

            let label = `Viikko ${ i } ( ${ start } - ${ end })`;
            if( week.isSame(now, 'week') )
                label = `${ label } *`;

            data.push({ value: i, label: label });
        }
        return data;
    }

    render()
    {
        let dateModeDom = <ApInput
            className="dateMode"
            type="select"
            id="dateMode"
            name="dateMode"
            value={ this.state.mode }
            options={ dateModeOptions }
            onChange={ ( e ) => { this.setDateMode( e.target.value ) } }
            loading={ this.props.loading }
            disabled={ this.props.loading }
            // width="300px"
        />

        const yearOptions = this.getYearsList();
        let dateYearDom  = <ApInput
            type="select"
            id="dateYearSelect"
            name="dateYear"
            value={ this.state.year }
            options={ yearOptions }
            onChange={ ( e ) =>{ this.setDateYear( e.target.value ) } }
            loading={ this.props.loading }
            disabled={ this.props.loading }
        />

        switch ( this.state.mode ) {
            case 'dates':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        <ApInput
                            type="dateSafe"
                            name="start"
                            id="start"
                            value={ this.props.start }
                            label="Alkaen"
                            onChange={ ( e ) => { this.onChange({ start: e.target.value }) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            clearable
                        />
                        <ApAddon width="20" noLeftBorder noRightBorder> - </ApAddon>
                        <ApInput
                            type="dateSafe"
                            name="end"
                            id="end"
                            value={ this.props.end }
                            label="Päättyen"
                            onChange={ ( e ) => { this.onChange({ end: e.target.value }) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            clearable
                        />
                    </ApInputStack>
                </div>

            case 'week':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        { dateYearDom }
                        <ApInput
                            type="select"
                            id="dateWeek"
                            name="dateWeek"
                            value={ this.state.value }
                            options={ this.getWeekList() }
                            onChange={ ( e ) =>{ this.setDateValue( e.target.value ) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                </div>

            case 'month':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        { dateYearDom }
                        <ApInput
                            type="select"
                            id="dateMonth"
                            name="dateMonth"
                            value={ this.state.value }
                            options={ this.getMonthList() }
                            onChange={ ( e ) =>{ this.setDateValue( e.target.value ) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                </div>
            case 'year':
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                        { dateYearDom }
                    </ApInputStack>
                </div>
            default:
                return <div className="reportDates" key="dateFilter">
                    <ApInputStack gap="0">
                        { dateModeDom }
                    </ApInputStack>
                </div>
        }
    }
}

export default ApDateInterval;
