import React from 'react';
import autoBind from 'react-autobind';
import { tr } from 'services/Helpers/Helpers.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

export default class SubProjectSettings extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
        };
        autoBind(this); 
    }
    render()
    {
        return <div className="padding">
            <ApInputStack gap="0">
                <ApAddon noRightBorder>
                    { tr('numbering_of_subprojects') }
                </ApAddon>
                <ApInput
                    type="number"
                    id={ "subproject-length" }
                    name={ "subproject-length" }
                    label={ tr('length') }
                    value={ this.props.subproject_code_default_length }
                    onChange={ ( e ) => { this.props.onChange({ subproject_code_default_length: e.target.value }) } }
                    loading={ this.props.loading }
                    disabled={ this.props.loading }
                />
            </ApInputStack>
        </div>
    }
}
