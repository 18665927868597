/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import { debounce } from 'throttle-debounce';
import { Collapse } from 'react-bootstrap';

import ApTooltip            from 'common/ApTooltip/ApTooltip.js';
import auth                 from 'services/Authed/Authed.js';
import api                  from 'services/Api/Api.js';
import { keyExists,
         validateDate,
         randomPassword,
         tr,
         currentLang }   from 'services/Helpers/Helpers.js'

import { ApInput,
         ApInputStack,
         ApAddon }          from 'common/ApInput/ApInput.js';
import ApSwitch             from 'common/ApSwitch/ApSwitch.js';
import SvgIcon              from 'common/SvgIcon/SvgIcon.js';

import './Account.css';

class Account extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            usernameLoading: false,
            usernameError: "",
            showPassword: false,
        };

        this.validateUsernameDebounced = debounce( 500, this.validateUsername );

        autoBind(this);
    }

    componentDidUpdate( prevProps ) {
        if( prevProps.data.username != this.props.data.username )
            this.validateUsername( this.props.data.username );
    }

    validateUsername( username )
    {   
        const data = {
            username: username,
            suffix: keyExists( this.props.data, "company.suffix", true ),
            userId: keyExists( this.props.data, "id", true )
        };

        this.setState({ usernameLoading: true });
        api({
            method: 'post',
            url: 'usermanagement/validate/username',
            data: data
        }).then(( errorMsg ) => {
            this.setState({ 
                usernameError: errorMsg,
                usernameLoading: false,
            });
        }).catch((error) => {
            this.setState({ 
                usernameError: "",
                usernameLoading: false,
            });
        });
    }

    usernameChanged( e )
    {
        this.props.onChange( "username", e.target.value );

        this.setState({ usernameLoading: true });
        this.validateUsernameDebounced( e.target.value );  
    }

    randomizePassword()
    {
        this.props.onChange( "password", randomPassword() );
        this.setState({ showPassword: true })
    }
    
    renderFoobar()
    {
        let types = [{
            icon: 'user-clock',
            name: 'limited',
            title: tr('basic_user'),
            description: tr('basic_user_info'),
            onClick: () => {
                //console.log('Foobar limited')
                this.props.onChange( "type", 'limited');
            },
        },{
            icon: 'user',
            name: 'normal',
            title: tr('official'),
            description: tr('official_info'),
            onClick: () => {
                //console.log('Foobar normal')
                this.props.onChange( "type", 'normal');
            },


        },{
            icon: 'user-tie',
            name: 'admin',
            title: tr('administrator'),
            description: tr('administrator_info'),
            onClick: () => {
                //console.log('Foobar admin')
                this.props.onChange( "type", 'admin');
            },

        }];

        let selected = 'normal';
        if( this.props.data.is_admin ) selected = 'admin';
        if( this.props.data.is_limited ) selected = 'limited';

        let doms = types.map( t => {
            let disabled = false;

            if( !auth.isAdmin() )
            {
                if( t.name === 'admin' ) disabled = true;
                if( this.props.data.is_admin ) disabled = true;
            }
            if( this.props.data.id === auth.getID() ) disabled = true;


            let className = [ 'option' ];
            if( t.name === selected ) className.push( 'selected' );
            if( disabled ) className.push( 'disabled' );

            return <div key={ t.name }
                className={ className.join(' ') }
                onClick={ ( disabled ) ? null : t.onClick }
            >
                <ApTooltip text={ t.description || null } block position="topright">
                    { t.title }
                </ApTooltip>
            </div>
        })

        return <div className={`apOptionBar count-${ types.length } userType`}>
            { doms }
        </div>

    }

    getColumnWidth()
    {
        return document.getElementsByClassName("apColumn").length > 0 ? document.getElementsByClassName("apColumn")[0].clientWidth : 0;
    }

    render()
    {

        const isCurrentUser = ( auth.getID() === this.props.data.id );

        let contractValid = true;
        let start = keyExists( this.props.data, "contract_detail.contract_start", true );
        let end = keyExists( this.props.data, "contract_detail.contract_end", true );

        if( validateDate( start ) && moment().isBefore( moment( start, "DD.MM.YYYY" ), 'day' ) )
            contractValid = false;

        if( validateDate( end ) && moment().isAfter( moment( end, "DD.MM.YYYY" ), 'day' ) )
            contractValid = false;

        if( moment().isSameOrBefore( moment( this.props.data.active_end, "DD.MM.YYYY" ), 'day' ) )
            contractValid = true;


        return (
            <div className="padding" id="userEditAccount">
                <div className="clear">

                    <div className="apColumn w50">
                        <div className="padding">

                            <h4 className="formHeader">{ tr('login_title') }</h4>

                            <ApInputStack gap="0">
                                { this.getColumnWidth() > 500 ?
                                <ApAddon
                                    noRightBorder
                                    width="200px"
                                    labelFor="username"
                                >
                                    <SvgIcon className="small-inline" icon="user" type="solid" />
                                    { tr('username') }
                                </ApAddon>
                                : 
                                <ApAddon
                                    noRightBorder
                                    width="40px"
                                    labelFor="username"
                                >
                                    <SvgIcon className="small-inline" icon="user" type="solid" />
                                </ApAddon> }
                                <ApInput
                                    type="text"
                                    id="username"
                                    name="username"
                                    value={ this.props.data.username }
                                    onChange={ this.usernameChanged }
                                    tooltip={ this.state.usernameError }
                                    validationState={ this.state.usernameError ? "error" : "" }
                                    loading={ this.props.loading || this.state.usernameLoading }
                                    disabled={ this.props.loading }
                                />
                                <ApAddon laberFor="username" noLeftBorder>
                                    @{ keyExists( this.props.data, "company.suffix", true, "..." ) }
                                </ApAddon>
                            </ApInputStack>

                            <div className={"apFormGroup" + ( this.props.data.change_password ? " success" : "" ) }>
                                <div className="apSwitchBlock small">
                                    <label htmlFor="reset-password-switch" className="info">
                                        { tr('change_password') }
                                        <small>{ tr('change_password_info') }</small>
                                    </label>
                                    <ApSwitch
                                        id="reset-password-switch"
                                        on={ this.props.data.change_password }
                                        onChange={ () => { this.props.onChange( "change_password", !this.props.data.change_password ) } }
                                        disabled={ this.props.loading }
                                    />
                                </div>

                                <Collapse in={ this.props.data.change_password }>
                                    <div>
                                        
                                        <hr />

                                        <ApInputStack gap="0">
                                            <ApAddon
                                                noRightBorder
                                                width="200px"
                                                labelFor="password"
                                            >
                                                <SvgIcon className="small-inline" icon="key" type="solid" />
                                                { tr('password') }
                                            </ApAddon>
                                            <ApInput
                                                type={ this.state.showPassword ? "text" : "password" }
                                                id="password"
                                                name="password"
                                                value={ this.props.data.password }
                                                onChange={ ( e ) => { this.props.onChange( "password", e.target.value ) } }
                                                tooltip={ this.props.validator.getText( "data.password" ) }
                                                validationState={ this.props.validator.getState( "data.password" ) }
                                                loading={ this.props.loading }
                                                disabled={ this.props.loading }
                                            />
                                            <ApAddon 
                                                noLeftBorder 
                                                width="50px" 
                                                tooltip={ this.state.showPassword ? tr('hide_password') : tr('show_password') }
                                                onClick={ () => this.setState({ showPassword: !this.state.showPassword }) }
                                            >
                                                <SvgIcon icon={ this.state.showPassword ? "eye" : "eye-slash" } type="solid" />
                                            </ApAddon>
                                            <ApAddon  
                                                noLeftBorder 
                                                width="50px" 
                                                tooltip={ tr('random_password') }
                                                onClick={ this.randomizePassword }
                                            >
                                                <SvgIcon icon="dice" type="solid" />
                                            </ApAddon>
                                        </ApInputStack>

                                        <div className="apSwitchBlock small">
                                            <label htmlFor="email-password-switch" className="info">
                                                <small>{ tr('send_new_password') }<br />({ keyExists( this.props.data, "contact_info.email" ) && this.props.data.contact_info.email ? this.props.data.contact_info.email : "Sähköposti puuttuu" })</small>
                                            </label>
                                            <ApSwitch
                                                small
                                                id="email-password-switch"
                                                on={ this.props.data.email_new_password && this.props.data.is_active }
                                                onChange={ () => { this.props.onChange( "email_new_password", !this.props.data.email_new_password ) } }
                                                disabled={ this.props.loading || !this.props.data.is_active }
                                            />
                                        </div>

                                    </div>
                                </Collapse>
                            </div>

                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { this.props.data.last_login ?
                                     `${tr('user_last_login')} ` + moment( this.props.data.last_login, 'YYYY-MM-DD HH:mm:ss' ).locale(currentLang()).format('LLL') :
                                     tr('user_has_not_logged_in')
                                }
                            </div>

                        </div>
                    </div>

                    <div className="apColumn w50">
                        <div className="padding">

                            <h4 className="formHeader">{ tr('account_status') }</h4>

                            { this.renderFoobar() }

                            <div className={"apFormGroup" + ( this.props.data.is_active ? ( contractValid ? " success" : " warning" ) : "" ) }>

                                <div className="apSwitchBlock small">
                                    <label htmlFor="is-active-switch" className="info">
                                        { tr('active') }
                                        <small>{ tr('user_active_note') }</small>
                                    </label>
                                    <ApSwitch
                                        id="is-active-switch"
                                        on={ this.props.data.is_active }
                                        onChange={ () => { this.props.onChange( "is_active", !this.props.data.is_active ) } }
                                        disabled={ isCurrentUser || this.props.loading }
                                    />
                                </div>

                                <Collapse in={ this.props.data.is_active && !contractValid }>
                                    <div>
                                        <hr />
                                        <div className="apInfo small warning">
                                            <SvgIcon icon="exclamation-triangle" type="solid" />
                                            { tr('contract_not_valid') }
                                        </div>
                                    </div>
                                </Collapse>
                                <Collapse in={ this.props.data.is_active && end!==null }>
                                    <div>
                                        <ApInput
                                                    type="date"
                                                    id="active_end"
                                                    name="active_end"
                                                    label={ tr('login_allowed_until') }
                                                    value={ keyExists( this.props.data, "active_end", true ) }
                                                    onChange={ ( e ) => { this.props.onChange( "active_end", e.target.value ) } }
                                                    tooltip={ this.props.validator.getText( "data.active_end" ) }
                                                    validationState={ this.props.validator.getState( "data.active_end" ) }
                                                    loading={ this.props.loading }
                                                    disabled={ this.props.loading }
                                                    clearable
                                                />
                                    </div>
                                </Collapse>
                            </div>
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('user_created_at') } { this.props.data.created_at ? moment( this.props.data.created_at, 'YYYY-MM-DD HH:mm:ss' ).locale(currentLang()).format('LLL') : "-" }<br />
                                { tr('user_edited_at') } { this.props.data.updated_at ? moment( this.props.data.updated_at, 'YYYY-MM-DD HH:mm:ss' ).locale(currentLang()).format('LLL') : "-" }
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Account;
