import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
//import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import moment from 'moment';

import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import { dateInputToSql
       , errorPopper
       , tr } from 'services/Helpers/Helpers.js';

import './NewPaymentPost.css';

class NewPaymentPost extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
           full_proj_alloc_sum: null,
           unallocated_sum: null,

           description: '',
           lump_sum: null,
           lump_sum_perc: null,
           invalidSum: false,

           billing_date: null,
           payment_date: null,
           billing_permission: false,

           valid_billing_date: true,
           billing_date_exists: false,
        }

        
        let rfilter = {};
        ['dm', 'dt', 'pc'].forEach( r => {
            rfilter[ r ] = ( value ) => {
                if( !this.state[ `${ r }_enabled` ]) return true;
                if( !this.state[ `${ r }_required` ]) return true;
                return Boolean( value );
            }
        })


        

        //Datan validaatio
        this.validator = new ApValidate( this, {
            //description: { filter: 'required', state: 'error', text: 'Selite pakollinen' }, //Jos haluaa selitteen pakolliseksi kentäksi
            lump_sum: { filter: 'required', text: tr('mandatory') },
            lump_sum_perc: { filter: 'required', text: tr('mandatory') },
            billing_date_valid: (value) => {return (this.state.billing_date_exists);},
        });

        const oneIsInvalid = ( test ) => {
            let text = null;
            const invalid = test.some( field  => {
                text = this.validator.getText( field )
                return text;
            });
            if( invalid ) return text;
            return null;
        }

        this.steps = [{
            name: tr('payment_info'),
            render: this.renderProjectPaymentInfo.bind( this ),
            invalid:  () => {
                return oneIsInvalid([
                    'lump_sum',
                    //'component_amount_valid',
                    'billing_date_valid',
                    //'billing_date_exists',
                    //'payment_date_exists'
                ]);
            }
        }];

        autoBind(this);
    }

    resetState()
    {   
        let totalSum = this.formatSum(this.props.data.total_sum, true);
        let unAlloc = String(this.props.data.unalloc);
        unAlloc = this.formatSum(unAlloc, true);

        this.setState({
            full_proj_alloc_sum: totalSum,
            unallocated_sum: unAlloc,

            description: '',
            lump_sum: null,
            lump_sum_perc: null,
            invalidSum: false,

            billing_date: null,
            payment_date: null,
            billing_permission: false,

            valid_billing_date: true,
            billing_date_exists: false,
        });

        const data = this.props.data.total_sum;
        if( !data ) return null;

    }

    
    UNSAFE_componentWillMount()
    {
        //this.getRelated();
    }

    componentDidUpdate( prevProps )
    {
        
        // When popup opens
        if( this.props.show && !prevProps.show )
            this.resetState();
    }

    save()
    {
        let data = {};
        data.payment_id = this.props.payment_id;
        data.description = this.state.description;
        data.payment_amount = this.formatSum(this.state.lump_sum);
        data.percentage = this.formatSum(this.state.lump_sum_perc);
        data.billing_date = dateInputToSql( this.state.billing_date );
        data.payment_date = dateInputToSql( this.state.payment_date );
        
        return api({
            method: 'post',
            url: 'projects/payments/paymentPosts/add',
            data: data,
        }).then( ( response ) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });

            if( typeof this.props.onSave === 'function')
                this.props.onSave( response );
        }).catch((error) => {
            console.error(`Virhe maksupostin tallennuksessa: ${error}`);
            errorPopper(error, tr('save_error'));
        });
    }


    handleAllocatedSum(sumOrig, perc) {
        //Käsittelee tapaukset joissa maksupostin hinta muuttuu

        let sum;

        if (sumOrig != null && sumOrig.includes(`${this.props.data.currency_sign}`)) {
            sum = sumOrig.replace(`${this.props.data.currency_sign}`, ''); //Poista valuuttamerkki
        } else { sum = sumOrig; }
        
        let unAlloc = this.formatSum(this.state.unallocated_sum);
        let totalSum = this.state.full_proj_alloc_sum;
    
        let percFormat;

        //Jos summaa muutettu
        if (sum !== null) {
            let newSum = parseFloat(sum);
            percFormat = String(((newSum / totalSum) * 100).toFixed(1)) + '%';
            percFormat = percFormat != 'NaN%' ? percFormat : 0;

            this.setState({
                lump_sum: sum,
                lump_sum_perc: percFormat,
            });

            newSum <= unAlloc ? this.setState({invalidSum: false}) : this.setState({invalidSum: true});
        } else { //Jos prosenttimäärää muutettu
            let added_sum = totalSum / 100 * this.formatSum(perc);
            added_sum = this.formatSum(added_sum, true);

            if (isNaN(added_sum)) { added_sum = 0; }
            this.setState({
                lump_sum: added_sum,
                lump_sum_perc: perc
            })

            added_sum <= unAlloc ? this.setState({invalidSum: false}) : this.setState({invalidSum: true});
        }
    }

    handleBillingDateChange(e) {
        //Varmista että laskutuspäivämäärä on ennen maksupäivää

        const billingDate = moment(e.target.value, "DD.MM.YYYY");
        let paymentDate = moment(billingDate, "DD.MM.YYYY");
        paymentDate = paymentDate.add(this.props.data.paydays, 'days');

        this.setState({
            payment_date: paymentDate,
            billing_date: billingDate,
            billing_date_exists: true,
        });

    }

    handlePaymentDateChange(e) {

        const paymentDate = moment(e.target.value, "DD.MM.YYYY");
        const billingDate = this.state.billing_date;

        if (billingDate <= paymentDate) {
            this.setState({ 
                payment_date: e.target.value,
                valid_billing_date: true, 
            });
        } else {
            this.setState({ 
                payment_date: e.target.value,
                valid_billing_date: false, 
            });
        }

    }

    renderProjectPaymentInfo()
    {

        const label = `${tr('contract_sum')} - MAX ${this.state.unallocated_sum}`;
        return <div className="stepSupplier">
            <div className="supplier-select">

                <ApInput
                type="textarea"
                id="description_field"
                name="description"
                label={ tr('description') }
                autoComplete="off"
                maxLength={255}
                charCounter 
                rows="2"
                value={ this.state.description ? this.state.description : '' }
                onChange={ ( e ) => this.setState({ description: e.target.value }) }
            />
                
            </div>
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('payment_description_info' ) }
            </div>

            <ApInput
                type="date"
                id="billing_date"
                name="billing_date"
                label={ tr('date_of_billing') }
                value={ this.state.billing_date ? this.state.billing_date : '' }
                onChange={(e) => this.handleBillingDateChange(e)}
                //validationState={ this.validator.getState('billing_date_exists') }
            />

            <ApInput
                type="date"
                id="payment_date"
                name="payment_date"
                label={ tr('date_of_payment') }
                value={ this.state.payment_date ? this.state.payment_date : '' }
                onChange={(e) => this.handlePaymentDateChange(e)}
                //validationState={ this.validator.getState('payment_date_exists') }
            />

            {!this.state.valid_billing_date ? <h6>{ tr('payment_date_error') }</h6> : ''}

            <ApInput
                type="textarea"
                id="payment_sum"
                name="payment_sum"
                label={label}
                autoComplete="off"
                rows="1"
                value={`${this.state.lump_sum ? this.state.lump_sum : ''}`}
                //onChange={ ( e ) => this.setState({ lump_sum: e.target.value }) }
                onChange={(e) => this.handleAllocatedSum(e.target.value, null)}
                validationState={ this.validator.getState('lump_sum') }
            />

            <ApInput
                type="textarea"
                id="payment_sum"
                name="payment_sum"
                label={ tr('contract_sum_as_percentage') }
                autoComplete="off"
                rows="1"
                value={ this.state.lump_sum_perc ? this.state.lump_sum_perc : '' }
                //onChange={ ( e ) => this.setState({ lump_sum_perc: e.target.value }) }
                onChange={(e) => this.handleAllocatedSum(null, e.target.value)}
                validationState={ this.validator.getState('lump_sum_perc') }
            />

            {/*Näytä virheviesti jos summa liian pieni*/}
            {this.state.invalidSum ? <div> 
                <h5>{ tr('payment_sum_too_high_error') }</h5>
                <h6>Max: {this.state.unallocated_sum}</h6>
            </div> : null}

            <div className="stepOther">

        </div>

        </div>
    }

    formatSum(sum, toFixed) {
        if (sum == null) { return; }
        let sumToFormat = sum.toString();

        sumToFormat = sumToFormat.replace(/\s/g, '').replace(',', '.');
        if (toFixed) {
            let ret = parseFloat(sumToFormat).toFixed(2);
            return ret;
        }
        return parseFloat(sumToFormat);
    }

    renderComponentSelection() {

        //Renderöi osion jossa on komponentin valinta ja komponentin määrän valinta.
        return <div>
            <ApInputStack gap="0">
                <ApAddon noRightBorder width="200px">
                    { tr('component') }
                </ApAddon>
                <ApInput
                    type="select"
                    id="component_type"
                    name="component_type"
                    value={ this.state.selected_component_name ? this.state.selected_component_name : '' }
                    options={ this.state.components.map( c => { 
                        return { value: c.id, label: c.component_name 
                        } }) }
                    onChange={(e) => this.handleComponentTypeChange(e.target.value)}
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </ApInputStack>

            <ApInput
                type="textarea"
                id="component_amount"
                name="component_amount"
                label={ tr('number_of_components') }
                autoComplete="off"
                rows="1"
                value={ this.state.component_amount ? this.state.component_amount : null }
                placeholder={this.state.component_amount ? '' : tr('not_defined')}
                onChange={(e) => this.handleComponentAmountChange(e.target.value)}
            />

            {
                //Näytä käytettävissä oleva saldo
                this.state.selected_component_balance ? <h6>{ tr('balance') }: {this.state.selected_component_balance}</h6> : ''
            }
            

            {/*Näytä virheviesti jos summa liian pieni*/}
            {this.state.invalidComponentAmount ? <div> 
                <h5>{ tr('incorrect_info') }</h5>
            </div> : null}
        </div> 
    }


    render()
    {
        return <div id="newPo">
            <ApModalWizard
                header={ tr('new_payment_post') }
                //onOpen={ this.resetState } //Kutsutaan myös componentDidUpdate funktiossa.
                show={ this.props.show  }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.state.billing_date_exists && !this.state.invalidSum ? this.save : () => {
                        window.emitter.emit('popper', {
                            type: 'warning',
                            content: <strong>{ tr('new_payment_error') }</strong>,
                        });
                        this.setState({loading: false});
                    }   
                }
            />
        </div>
    }

}

export default NewPaymentPost;
