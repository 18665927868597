
import React from 'react';
import autoBind from 'react-autobind';
import "./GDPR.css"


import moment from 'moment';



class GDPR extends React.Component {
    constructor(props) {
        super(props);



        this.state = {

        }
        autoBind(this);
    }


    componentDidMount() {

    }
    componentWillUnmount() {

    }
    



    render() {
        return (
            <div id='GDPR'>
                <h1>AmiPro service</h1>
                <p>Laatimispäivä 15.5.2019</p>

                <h2>HENKILÖTIETOLAIN (523/1999) 10 §:N MUKAINEN REKISTERISELOSTE</h2>

                <p>Tätä tietosuojailmoitusta sovelletaan kaikkiin AmiPro Service Oy:n (myöhemmin AmiPro) liiketoimintaprosesseihin ja
                    sivustoihin, toimialueisiin, mobiiliratkaisuihin, pilvipalveluihin ja kolmannen osapuolen sosiaalisiin verkostoihin.</p>
                
                <p>Tässä ilmoituksessa kerrotaan AmiPron tekemästä tietojen käsittelystä, kun AmiPro on rekisterinpitäjä ja asiakkaiden
                    toimeksiannosta tehtävästä tietojenkäsittelystä heidän ohjeitaan noudattaen, kun AmiPro on tietojen käsittelijä.</p>
                
                <p>Antamalla meille henkilötietosi hyväksyt tämän yksityisyydensuojakäytännön menettelytavat ja ehdot.</p>
                
                <p>AmiPro Service Oy:n (y-tunnus: 2242792–0) henkilötietolain (10 ja 24 §) ja EU:n yleisen tietosuoja-asetuksen (GDPR)
                    mukainen rekisteri- ja tietosuojaseloste.</p>

               
                <h5>1. Rekisterinpitäjä</h5>
                <p>
                    AmiPro Service Oy (jäljempänä ”AmiPro”)<br/>
                    Vihtorinkatu 10, 23800 Laitila<br/>
                    AmiPro Service Oy toimii tilitoimistona, ohjelmistotalona sekä markkinointi- ja it palveluita myyvänä yrityksenä
                </p>

                <h5>2. Rekistereistä vastaava yhteyshenkilö</h5>
                <p>
                    Anne Kuusisto<br />
                    puh: 0440 255 723<br />
                </p>

                <h5>3. Rekisterit</h5>
                <p>
                    AmiPron työntekijä- ja rekrytointirekisteri<br />
                    AmiPron asiakasrekisteri<br />
                    AmiPron asiakkuuteen perustuva yritysten ja yritysten päättäjien asiakasrekisteri<br />
                </p>

                <h5>4. Henkilötietojen käsittelyn tarkoitus</h5>
                <p>
                    Asiakasrekisteri: Henkilötietojen käsittelyn tarkoitus on AmiPron asiakassuhteiden hoitaminen, analysointi,
                    kehittäminen, laskutus, maksusaatavien perintä, yhteydenpito asiakkaisiin, etämyyntiin, mielipide- ja
                    markkinatutkimuksiin, yhteistyökumppanien sekä omien palveluiden markkinointi ja uusasiakashankinta sekä
                    rekisterinpitäjän tai kolmannen osapuolen oikeutettujen etujen suojaaminen ja lakisääteisten velvoitteiden
                    hoitaminen.
                </p>
                <p>
                    Työntekijärekisteri: Käsittely perustuu työsopimuslakiin 2001/55, erit. 2. luku)
                </p>
                <p>
                    Rekrytointirekisteri: Työnhakijoiden henkilötietoja käsitellään rekrytointiprosessiin liittyvissä arvioinneissa
                </p>

                <h5>5. Mitä henkilötietoja käsittelemme ja keräämme sekä tietojen käsittelyn oikeusperusteet</h5>
                <h5>Asiakasrekisteri:</h5>
                <ul>
                    <li>
                        yhteystiedot (etu- ja sukunimi, henkilötunnus, puhelinnumero ja sähköpostiosoite) sekä mahdolliset
                        suoramarkkinoinnin kieltävät tiedot, väestötiedot
                    </li>
                    <li>
                        Viestinnässä käytettävä kieli
                    </li>
                    <li>
                        Asiakkuuden alkamis- ja päättymisajankohta sekä -tapa.
                    </li>
                    <li>
                        Suoramarkkinointiluvat ja -kiellot
                    </li>
                    <li>
                        Markkinointiin ja myynninedistämiseen liittyvät tiedot.
                    </li>
                    <li>
                        henkilön asema (henkilö on rekisterinpitäjän asiakkaana olevan yrityksen tai yhteisön yhteyshenkilö,
                        vastuuhenkilö, osakas, omistaja, edunsaaja tai edustaja)
                    </li>
                    <li>
                        organisaatiossa ja ko. organisaation nimi ja osoite sekä muut yhteystiedot, y-tunnus
                    </li>
                    <li>
                        Tuotteita / palveluita ostaneilta ostoon liittyvät tiedot ostetuista tuotteista sekä tilauskohtaiset
                        laskutustiedot.
                    </li>
                    <li>
                        Asiakkaan itse antamat personoivat tiedot
                    </li>
                    <li>
                        Käyttäjä- ja verkkoliikennetiedot, kuten kirjautumistunnus, käyttäjänimi ja IP-osoite
                    </li>
                    <li>
                        Sähköisten palveluiden ja sisältöjen hyödyntämiseen liittyvät asiat. Esim. Lataamasi tai toimittamasi
                        sisältö, kuten valokuvat, kommentit, artikkelit, uutiskirjeen tilaaminen, asiakastiedotteet ja videot.
                    </li>
                    <li>
                        Tilitoimiston asiakkaan tunnistamiseen ja toiminnan arviointiin liittyvät tiedot sen mukaisesti kuin
                        edellytetään laissa rahanpesun ja terrorismin rahoittamisen estämisestä (laki 444/2017 eli ns.
                        rahanpesulaki).
                    </li>
                </ul>

                <h5>Työntekijärekisteri:</h5>
                <ul>
                    <li>
                        Yhteystiedot (etu- ja sukunimi, kotiosoite, puhelinnumero ja sähköpostiosoite)
                    </li>
                    <li>
                        Mikäli henkilöllä ei ole suomalaista henkilötunnusta, tallennetaan syntymäaika ja -paikka sekä
                        kansallisuus. 
                    </li>
                    <li>
                        Henkilötunnus, syntymäaika
                    </li>
                    <li>
                        Työsuhteeseen liittyvät tiedot, työ -tai palvelussuhteen laatua koskevat tiedot
                    </li>
                    <li>
                        Työsuhteen alkamis- ja päättymisajankohta sekä -tapa
                    </li>
                    <li>
                        Henkilön verotukseen liittyvät asiat, myös veronumero
                    </li>
                    <li>
                        Tilastoihin liittyvät tiedot
                    </li>
                    <li>
                        Viestinnässä käytettävä kieli.
                    </li>
                </ul>

                <h5>Rekrytointirekisteri:</h5>
                <ul>
                    <li>
                        Henkilön ansioluettelossa ja hakemuksessa ilmoittamat tiedot.
                    </li>
                    <li>
                        Työsopimus, yhteystiedot, valokuva, videot, työntekijöiden irtisanomisilmoitukset ja
                        palkkatukihakemukset, sekä rekrytointiin liittyen kandidaattien yhteystiedot, suositukset ja työhistoria.
                    </li>
                    <li>
                        Henkilöarvioinneissa antamasi tietojen perusteella syntyvät yhteenvedot.
                    </li>
                </ul>

                <h5>6. Kuinka keräämme henkilötietoja</h5>
                <p>
                    Rekisteriin tallennettavat tiedot saadaan asiakkaalta mm. www-lomakkeilla lähetetyistä viesteistä, sähköpostitse,
                    puhelimitse, sopimuksista, asiakastapaamisista ja muista tilanteista, joissa asiakas luovuttaa tietojaan. 
                </p>
                <p>
                    Käytämme myös evästeitä ja muita seurantatekniikoita, kun käytät AmiPro Service Oy:n -verkkosivustoja ja olet
                    vuorovaikutuksessa kanssamme sähköpostitse, optimoidaksemme kokemuksesi AmiPro Service Oy:n tuotteista ja
                    palveluista sekä EASY-PRO® toiminannanohjausjärjestelmästä ja sivustoistamme.
                </p>
                <p>
                    Joissakin tapauksissa voimme kerätä tietoja sinusta myös muista lähteistä. Nämä lähteet voivat olla kolmansien
                    osapuolten tietojen kerääjiä, AmiPro Service Oy:n markkinointikumppaneita, julkisia lähteitä tai kolmansien
                    osapuolten sosiaalisia verkostoja, palveluja tarjoavilta viranomaisilta ja yrityksiltä.
                </p>

                <h5>7. Miksi käsittelemme henkilötietoja</h5>
                <p>
                    Tätä AmiPro:n yksityisyydensuojaa sovelletaan, kun käsittelemme henkilötietojasi eri tarkoituksiin, kun olet
                    tekemisissä kanssamme, kuten:
                </p>
                <h5>Ostot ja toimitukset</h5>
                <ul>
                    <li>
                        Helpotetaan asiakkaiden tilauksia, sopimuksia ja maksuja
                    </li>
                    <li>
                        Tarjotaan suoraan sinulle palveluja, kuten e-oppiminen, verkkoseminaarit ja raportit jne.
                    </li>
                    <li>
                        Tarjotaan asiakkaille pyydettyjä tuotteita ja palveluja
                    </li>
                    <li>
                        Luodaan ja toimitetaan käyttäjätilejä palveluiden käyttäjille
                    </li>
                    <li>
                        Rekisterinpitäjän lakisääteisten velvoitteiden noudattaminen:
                        Tilitoimistolla on mm. rahanpesulain vaatimusten takia velvollisuus tunnistaa yrityksen edustajat ja
                        edunsaajat sekä rekisteröidä asiakasyrityksen henkilötiedot ja säilyttää niitä laissa säädetyllä tavalla.
                    </li>
                    <li>
                        Rekisterinpitäjän tai kolmannen osapuolen oikeutettu etu:
                        Oikeutettu etu voi perustua asiakassuhteeseen, velkasuhteeseen, maksusaamiseen, kanteeseen tai
                        vaatimukseen, jossa rekisterinpitäjä tai rekisterinpitäjän sopimuskumppani on kohteena tai osallisena.
                    </li>
                </ul>

                <h5>Tuki ja palvelun parantaminen</h5>
                <ul>
                    <li>
                        Tuotteiden, palveluiden ja AmiPro:n -sivustojen laatua, toiminnallisuutta ja käyttökokemusta koskeva
                        kehittäminen ja parantaminen
                    </li>
                    <li>
                        TTuotteidemme ja palvelujemme asiakastuki
                    </li>
                    <li>
                        Käyttäjäyhteisö ja koulutukset sekä käyttäjien ja AmiPro:n välinen vuorovaikutus
                    </li>
                </ul>

                <h5>Tietoturva</h5>
                <ul>
                    <li>
                        Tunnistetaan, lievennetään ja ehkäistään turvallisuusuhkia ja väärinkäytöksiä sekä suoritetaan ylläpito- ja
                        virheenkorjaustoimenpiteitä
                    </li>
                </ul>

                <h5>Markkinointi</h5>
                <ul>
                    <li>
                        Markkinointiasetusten ja sisällön hallinta ja lähettäminen
                    </li>
                    <li>
                        Luodaan profiileja relevanttien tuotteiden ja palveluiden mainostamiseksi (profilointi)
                    </li>
                </ul>


                <h5>8. Henkilötietojen luovuttaminen</h5>
                <p>
                    Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja voidaan julkaista siltä osin kuin niin on sovittu
                    asiakkaan kanssa. Henkilötietoja voidaan luovuttaa viranomaisille lakiin perustuvan velvollisuuden tai valtuutuksen
                    perusteella. Henkilötietoja voidaan luovuttaa perintäyhtiölle myöhästyneen laskusaamisen tai muun velkasaamisen
                    perimiseksi. Tietoja voidaan luovuttaa yritysjärjestelyiden yhteydessä ostajille, mikäli AmiPro myy tai muutoin
                    järjestelee liiketoimintaansa. Tietoja voidaan siirtää rekisterinpitäjän valikoimille yhteistyökumppaneille, jotka
                    käsittelevät tietoja rekisterinpitäjän lukuun, osapuolten välisen yhteistyösopimuksen perusteella. Tällöin tietojen
                    käsittelijällä ei ole oikeutta käsitellä siirrettyjä tietoja omaan lukuunsa, omissa henkilörekistereissään.

                </p>
                
                <h5>9. Henkilötietojen siirrot kolmansiin maihin</h5>
                <p>
                    Henkilötietoja siirretään EU:n ja Euroopan talousalueen ulkopuolelle ainoastaan EU:n komission antaman tietosuojan
                    riittävyyttä koskevan päätöksen mukaisesti.
                </p>

                <h5>10. Rekisterin suojauksen periaatteet</h5>
                <p>
                    Rekisterin tiedot on tallennettu järjestelmään, jonka käyttämiseen vaaditaan henkilökohtaiset käyttäjätunnukset.
                    Järjestelmä on suojattu palomuurein ja muiden teknisten keinojen avulla. Rekisterin tiedot sijaitsevat lukituissa ja
                    vartioiduissa tiloissa. Järjestelmään tarvittavat käyttäjätunnukset myönnetään vain rekisterin pitäjän työntekijöille,
                    joiden työnkuvaan se kuuluu. Alihankkijoiden kanssa on tehty sopimukset henkilötietojen suojaamisesta. Kaikki
                    alihankkijat ovat sitoutuneet noudattamaan EU:n tietosuoja-asetuksen mukaista henkilötietojen suojaamista.
                </p>

                <h5>11. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h5>
                <p>
                    Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa rekisteriin tallennetut tietonsa.
                    Mikäli henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia niihin oikaisua, pyyntö tulee lähettää kirjallisesti
                    rekisterinpitäjälle, yhteystiedot tietosuojaselosteen alussa. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää
                    todistamaan henkilöllisyytensä. Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa säädetyssä ajassa.
                </p>

                <h5>12. Muut henkilötietojen käsittelyyn liittyvät oikeudet</h5>
                <p>
                    Rekisteröidyllä on oikeus kieltää rekisterinpitäjää käsittelemästä häntä itseään koskevia tietoja suoramainontaa,
                    etämyyntiä ja muuta suoramarkkinointia sekä markkina- ja mielipidetutkimusta varten. Pyynnöt tulee lähettää
                    kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää tarvittaessa pyynnön esittäjää todistamaan
                    henkilöllisyytensä. Jokaisessa AmiPron lähettämässä suorasähköpostissa on lainmukainen linkki, josta voit halutessasi
                    estää suorapostitukset sähköpostiisi jatkossa.
                </p>

                <h5>13. Muut AmiPro:ssa käsiteltävät henkilötiedot</h5>
                <p>
                    Yllä oleva seloste koskee AmiPro:n omaa asiakasrekisteriä ja sinne tallennettuja henkilötietoja.
                    Asiakkaan kanssa tehdyn sopimuksen perusteella AmiPro käsittelee henkilötietoja myös asiakkaansa puolesta ja
                    asiakkaan lukuun. Käsittelyn tarkoituksen määrittävät asiakkaamme. Tällaiset tiedot eivät ole tilitoimiston
                    asiakasrekisterissä vaan asiakkaan lukuun pidettävissä tiedostoissa. Niiden tietojen osalta rekisterinpitäjänä on asiakas
                    ja AmiPro tietojen käsittelijä.
                </p>

                <h5>Henkilötietojen käsittely EASY-PRO® toiminnanohjausjärjestelmässä</h5>
                <p>
                    Käsittelemme henkilötietoja EASY-RPO® toiminnanohjausjärjestelmässä henkilötietojen käsittelijänä
                    asiakasyritystemme toimiessa rekisterinpitäjänä.
                </p>

                <h5>14. Automaattinen tietojenkeruu</h5>
                <p>
                    AmiPro käyttää digitaalisen markkinoinnin menetelmiä, kun keräämme tietoja vierailuistasi web-sivuillamme. Tietoja
                    kerätään evästeiden avulla, sivustojemme käytettävyyden ja sisällön parantamiseksi. Tietoja ei voi yhdistää
                    henkilöön.
                </p>

                <h5>15. AmiPro:n käyttämät evästeet</h5>
                <p>
                    Keräämme seuraavia tietoja: sivut joilla on vierailtu, vierailun kesto, kellonaika, IP-osoite, kävijäliikenteen lähde,
                    selaintyyppi sekä onko vierailija käynyt aiemmin.
                </p>
                <p>
                    Käytämme evästeitä verkkosivuillamme, jotta ymmärrämme paremmin millaisesta sisällöstä sivustomme kävijät ovat
                    kiinnostuneita. Evästeet auttavat meitä myös parantamaan sivuston käyttäjäkokemusta. Analytiikan avulla tietoja
                    voidaan käyttää myös mainostemme parantamiseen.
                </p>
                <p>
                    Emme tunnista kävijöiden henkilöllisyyttä verkkosivujemme evästeiden perusteella, sillä emme kerää evästeillä
                    henkilökohtaista tietoa, kuten käyttäjän nimeä, osoitetta tai puhelinnumeroa. Sivustomme ei myöskään vaadi
                    rekisteröitymistä, emmekä kerää asiakkaidemme osalta tietoa sisäänkirjautumisesta AmiPro:n kotisivujemme kautta.
                </p>

                <h5>16. Tietosuojaselosteen muuttaminen</h5>
                <p>
                    AmiPro kehittää palveluitaan jatkuvasti ja pidätämme siksi oikeuden muuttaa tätä tietosuojaselostetta ilmoittamalla
                    siitä tällä sivulla. Muutokset voivat perustua myös lainsäädännön muuttumiseen.
                </p>



                
            </div>

        );

    }
}
export default GDPR



