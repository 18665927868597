/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import auth from 'services/Authed/Authed.js';
import { groupify, tr } from 'services/Helpers/Helpers.js';
import VerticalScroller from 'common/VerticalScroller/VerticalScroller.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import SideNavMenu from './SideNavMenu/SideNavMenu.js';
// this is where actual menuitems are constructed
import sideNavItems from '../NavLinks.js';
import './MobileNav.css';

export default class MobileNav extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            search: '',
            navItems: this.setParents( sideNavItems ),
            badges: [],
        }
        autoBind(this);
    }  

    componentDidMount()
    {
        this.updateBadges();
    }

    UNSAFE_componentWillReceiveProps( nextProps )
    {
        this.openSelectedPath( nextProps.selected );
    }

    updateBadges()
    {
        const items = groupify( auth.getSettings().company.errors, "navId" );
        let badges = {};
        const addBadges = ( key, errors, warnings ) => {
            if( !( key in badges ) )
                badges[ key ] = { errors: 0, warnings: 0 };
            badges[ key ].errors += errors ? errors : 0;
            badges[ key ].warnings += warnings ? warnings : 0;
        }

        for( var navId in items )
        {
            let e = 0;
            let w = 0;
            items[ navId ].forEach( item => {
                if( item.type == "error" )          e++;
                else if ( item.type == "warning" )  w++;
            });

            // If nav item contains "." we assume it have parents 
            // and will iterate count to them aswell
            if( navId.indexOf('.') > -1 ) {
                let levels = navId.split('.');
                let key = "";
                levels.forEach( (level, i) => {
                    key += ( i > 0 ? "." : "" ) + level;
                    addBadges( key, e, w );
                });
            }
            else 
                addBadges( navId, e, w );
        }
        this.setState({ badges });
    }

    // Add parents object (array of ids) to each nav item
    setParents( items, parents )
    {
        if( typeof( parents ) != "object" ) parents = [];
        for( let i = 0; i < items.length; i++ )
        {
            items[i].parents = parents;
            if( items[i].subMenu )
            {
                items[i].subMenu = this.setParents( items[i].subMenu, parents.concat( items[i].id ) );
            }
        }
        return items;
    }   

    // Get single navItem data by id
    getNavItem( id )
    {
        let data = false;
        const loop = function( items ) {
            for( let i = 0; i < items.length; i++ )
            {
                if( items[i].id === id )
                {
                    data = items[i];
                    break;
                }
                else if ( items[i].subMenu )
                {
                    loop( items[i].subMenu );
                }
            }
        };
        loop( this.state.navItems.slice(0) );
        return data;
    }

    // Modify recursively nav items by id(s)
    // Parameters: 
    //  - data:             Object. Original data to modify
    //  - ids:              String (single id) or array of strings.
    //  - modifications:    Object. Example: { subMenuOpen: true }
    //  - reverse:          Boolean. TRUE = modify all other items than ids provided
    // returns modified object (data parameter)
    modifyNavItem( data, ids, modifications, reverse )
    {
        // Convert string (assumed as single id) to array
        if( typeof( ids ) == "string" ) ids = [ids];
        if( typeof( ids ) == "undefined") ids = [];

        if( typeof( reverse ) != "boolean" ) reverse = false;

        const loop = function( items ) {
            for( let i = 0; i < items.length; i++ )
            {
                if( ( ids.indexOf( items[i].id ) != -1 && !reverse ) ||
                    ( ids.indexOf( items[i].id ) == -1 && reverse ) )
                {
                    for(let prop in modifications)
                    {
                        items[i][ prop ] = modifications[ prop ];
                    }
                }
                if( items[i].subMenu )
                {
                    items[i].subMenu = loop( items[i].subMenu );
                }
            } 
            return items;
        }
        return loop( data );
    }
  

    // Close all (if no exceptions available) submenus
    //  - exceptions: array of ids (strings) 
    //  - iterate: boolean (TRUE = close all levels, FALSE = close only first level)
    closeSubmenus( exceptions, iterate )
    {
        if( typeof( exceptions ) != "object" ) exceptions = []; 

        let navItems = this.state.navItems.slice(0);
        navItems = this.modifyNavItem( navItems, exceptions, { subMenuOpen: false }, true );
        this.setState({ navItems });
    }


    toggleSubmenu( id )
    {
        let currentPath;
        let opened;

        const loopThrough = function(items, id, path ) {
            if( typeof( path ) != "object" ) path = [];
            for( let index = 0; index < items.length; index++ ) {
                const item = items[index];
                if( item.subMenu ) {
                    path.push( item.id );
                    if( item.id === id ) {
                        items[index].subMenuOpen = !items[index].subMenuOpen;
                        currentPath = path;
                        opened = items[index].subMenuOpen;
                        break;
                    }
                    else {
                        items[index].subMenu = loopThrough( items[index].subMenu, id, path );
                        path = [];
                    }
                }
            }
            return items;
        };

        let navItems = this.state.navItems.slice(0);
        navItems = loopThrough(navItems, id );
        this.setState({ navItems }, function() 
        {
             // In minified mode close other menus when new menu is opened
            if( this.props.minified && opened ) 
            {
                /*Quick bug fix to handle closing all items when trying to open Ostot or Logistiikka in mobile with minified menu*/
                if (currentPath.includes('purchases') || currentPath.includes('logistic')) {
                    currentPath.push('storage')
                }

                this.closeSubmenus( currentPath, true );
            }
        });
    }

    itemSelect( id )
    {
        this.props.handleSelect( id );
        this.props.onClose();
    }

    // Make sure that path to submenu is open
    openSelectedPath( itemId )
    {
        if( !this.props.minified ) 
        {
            let navItems = this.state.navItems.slice(0);
            const data = this.getNavItem( itemId );
            navItems = this.modifyNavItem( navItems, data.parents, { subMenuOpen: true } );
            this.setState({ navItems });
        }
    }

    handleSearchChange( e )
    {
        this.setState({ search: e.target.value });
    }

    render() {
        return (
            <nav id="mobileNav" className={ (this.props.open ? "open" : "" ) }>
                <div className="top">
                    
                    {/* <div className="searchField">
                        <input type="text" value={ this.state.search } onChange={ this.handleSearchChange } placeholder={`${tr('enter_search_word')}...`} />
                        <SvgIcon icon="search" type="solid" />
                    </div> */}
                
                </div>
                <div className="middle">
                    <VerticalScroller 
                        offsetTop={51} 
                        offsetBottom={51} 
                        srcollShadows 
                        shadowHeight={20} 
                        hideScrollbar 
                        disabled={this.props.minified}
                    >
                        <SideNavMenu 
                            data={ this.state.navItems } 
                            selected={ ( this.props.selected ? this.props.selected : null ) } 
                            handleSelect={ this.itemSelect }
                            toggleSubmenu={ this.toggleSubmenu }
                            badges={ this.state.badges }
                        />
                    </VerticalScroller>
                </div>
            </nav>
        );
    }
}