import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import { Grid, Row, Col, Modal } from 'react-bootstrap';

import './EditAssignmentClass.css';

export default class EditAssignmentClass extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			data: {
				name: null,
				description: null,
				subclasses: []
			},
			dataChanged: false,
			removeSubclasses: []
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		if (this.props.classId !== undefined && this.props.classId !== null)
		{
			this.getClass();
		}

	}

	getClass()
	{

		api({
			method: 'get',
			url: 'assignment/getClass',
			params: { classId: this.props.classId }
		}).then((response) => {
			let data = response;
			//console.log(data);
			if (data.subclasses !== null)
			{
				data.subclasses = JSON.parse(response.subclasses);	
			} 
			else
			{
				data.subclasses = [];
			}
			data.hasChanged = false;
			if (data.subclasses !== null)
			{
				for (var j = 0; j < data.subclasses.length; j++)
				{
					data.subclasses[j].hasChanged = false;
				}
			}
			this.setState({ data });
		}).catch((error) => {
			console.log(error);
		});

	}

	clickCancelButton()
	{
		this.props.onClose();
	}

	clickSaveButton()
	{

		api({ 
			method: 'post',
			url: 'assignment/saveClass',
			data: {
				data: this.state.data,
				remove: this.state.removeSubclasses,
				dataChanged: this.state.dataChanged
			}
		}).then(response => {
			this.props.onClose();
		}).catch(error => {
			console.log(error);
		});

	}

	handleChange(e)
	{

		let data = this.state.data;
		data[e.target.name] = e.target.value;
		data.hasChanged = true;
		this.setState({ data, dataChanged: true });

	}

	handleSubclassChange(e)
	{

		let data = this.state.data;
		data.subclasses[e.target.id][e.target.name] = e.target.value;
		data.subclasses[e.target.id].hasChanged = true;

		this.setState({ data, dataChanged: true });

	}

	addSubclass()
	{

		let newSubclass = { name: "Uusi alaluokka", description: "", hasChanged: true };
		let data = this.state.data;
		data.subclasses.push(newSubclass);

		this.setState({ data, dataChanged: true }, () => {
			//console.log(this.state.data);
		});

	}

	removeClass()
	{

		if (this.props.classId === undefined || this.props.classId === null)
		{
			this.props.onClose();
		}
		else
		{
			api({
				method: 'post',
				url: 'assignment/removeClass',
				data: { classId: this.props.classId }
			}).then((response) => {
				this.props.onClose();
			}).catch((error) => {
				console.log(error);
			});
		}

	}

	removeSubclass(index)
	{

		let data = this.state.data;
		let removeSubclasses = this.state.removeSubclasses;

		if (data.subclasses[index].id !== undefined)
			removeSubclasses.push(data.subclasses[index].id);
		
		data.subclasses.splice(index, 1);
		this.setState({ data, removeSubclasses, dataChanged: true });

	}

	renderSubclasses()
	{

		return(
			<div id="editAssignmentClass" style={{marginTop: "16px"}}>
				<h3 style={{fontSize: '1.0em'}}> Alaluokat </h3>
				{
				this.state.data.subclasses !== undefined &&
				this.state.data.subclasses !== null &&
				this.state.data.subclasses.map((sc, index) => (
					<div className="subclass" key={index}>
						<ApInputStack gap="0">
							<ApAddon
								noRightBorder
								width="200px"
								labelFor="name"
							>
								Nimi
							</ApAddon>
							<ApInput
								type="text"
								name="name"
								id={index}
								label="Nimi"
								value={this.state.data.subclasses[index].name}
								onChange={this.handleSubclassChange}
							/>
						</ApInputStack>
						<ApInputStack gap="0">
							<ApAddon
								noRightBorder
								width="200px"
								labelFor="description"
							>
								Kuvaus
							</ApAddon>
							<ApInput
								type="text"
								name="description"
								id={index}
								label="Kuvaus"
								value={this.state.data.subclasses[index].description}
								onChange={this.handleSubclassChange}
							/>
						</ApInputStack>
						<div className="toolbar">
							<ApButton
								size="small"
								color="red"
								onClick={() => this.removeSubclass(index)}
								disabled={this.state.data.subclasses[index].count > 0}
							>
								Poista
							</ApButton>
						</div>
					</div>
				))
				}
				<div className="toolbar">
					<ApButton
						size="small"
						color="green"
						onClick={() => this.addSubclass()}
					>
						Lisää alaluokka
					</ApButton>
				</div>
			</div>
		);

	}

	render()
	{

		let title = "";
		this.props.classId === undefined ? 
			title = "Luo uusi toimeksiantoluokka" : 
			title = "Muokkaa toimeksiantoluokkaa";

		return(
			<div id="editAssignmentClass">

			<Modal
				show={this.props.show}
				backdrop="static"
				bsSize="large"
			>

				<Modal.Header>
					<Modal.Title> 
						{title}
					</Modal.Title>
				</Modal.Header>				

				<Modal.Body>

					<ApInput
						type="text"
						id="name"
						name="name"
						label="Luokan nimi"
						onChange={this.handleChange}
						value={this.state.data.name}
					/>
					<ApInput
						type="text"
						id="description"
						name="description"
						label="Kuvaus"
						onChange={this.handleChange}
						value={this.state.data.description}
					/>

					{this.renderSubclasses()}

				</Modal.Body>

				<Modal.Footer>
					{this.props.classId !== undefined && this.props.classId !== null &&
					<ApButton 
						color="red" 
						onClick={() => {this.removeClass()}}
						disabled={this.state.data.count > 0}
					>
						Poista toimeksiantoluokka
					</ApButton>
					}
					<ApButton 
						color="red" 
						onClick={() => {this.clickCancelButton()}}>
						Peruuta
					</ApButton>
					<ApButton 
						color="blue" 
						onClick={() => {this.clickSaveButton()}}
						disabled={this.state.dataChanged === false || this.state.data.name === "" || this.state.data.name === null}
					>
						Tallenna
					</ApButton>
				</Modal.Footer>

			</Modal>


			</div>
		);

	}

}
