import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack } from 'common/ApInput/ApInput.js';
import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import { tr } from 'services/Helpers/Helpers.js';

export default class EditScheduleModal extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
            loading: false,
			name: null,
			start_date: null,
			end_date: null,
		};

        this.validator = new ApValidate(this, {
            name: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
            start_date: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
            end_date: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
        });

		autoBind(this);

	}

	componentDidUpdate(prevProps)
	{

		if (prevProps.itemId !== this.props.itemId) this.getSchedule(this.props.itemId);

	}

	getSchedule(id)
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'timetracking/holiday/getschedule',
            params: { id: id },
        }).then((response) => {
            this.setState({ 
                name: response.name,
                start_date: response.start_date,
                end_date: response.end_date,
                loading: false,
            });
        }).catch((error) => {
            this.setState({ loading: false });
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('get_error') }</strong>,
            });
        });
    }

	handleSave()
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/holiday/saveschedule',
            data: {
                id: this.props.itemId,
                name: this.state.name,
                start: this.state.start_date,
                end: this.state.end_date
            },
        }).then((response) => {
            this.props.onClose();
            this.clear();
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });
        }).catch((error) => {
            this.props.onClose();
            this.clear();
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('save_error') }</strong>,
            });
        });
    }

	handleScheduleRemove()
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/holiday/removeschedule',
            data: {
                id: this.props.itemId
            },
        }).then((response) => {
            this.props.onClose();
            this.clear();
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('deleted') }</strong>,
            });
        }).catch((error) => {
            this.props.onClose();
            this.clear();
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('delete_error') }</strong>,
            });
        });
    }

	clickSaveButton()
	{
		this.saveItem();
	}

	clickCancelButton()
	{
		this.props.onClose();
		this.clear();
	}

	clear()
	{
		this.setState({
			name: null,
			start_date: null,
            end_date: null,
            loading: false
		})
	}

	renderForm()
    {   
        return (
            <div className="padding">
            <ApInput
                name="name"
                id="name"
                label={ tr('name') }
                value={ this.state.name }
                type="text"
                onChange={ (e) => this.setState({ name: e.target.value }) }
                validationState={ this.validator.getState('name') }
            />
            <ApInputStack>
                <ApInput 
                    type="dateSafe" 
                    id="start_date" 
                    name="start_date" 
                    label={ tr('start_date') }
                    value={ this.state.start_date }
                    onChange={ (e) => this.setState({ start_date: e.target.value }) }
                    validationState={ this.validator.getState('start_date') }
                />
                <ApInput 
                    type="dateSafe" 
                    id="end_date" 
                    name="end_date" 
                    label={ tr('end_date') }
                    value={ this.state.end_date }
                    onChange={ (e) => this.setState({ end_date: e.target.value }) }
                    validationState={ this.validator.getState('end_date') }
                />
            </ApInputStack>
            </div>
        )
    }

	render()
	{
		return(
            <ApModal
                closeFromBg
                show={this.props.show}
                className="narrow"
                handleClose={this.props.onCancel}
                header={ 
                <div className="padding-small">
                    <h4>{this.props.itemId === null ? tr('new') : tr('edit')}</h4>
                </div>
                }
                body={ this.renderForm() }
                footer={
                    <div style={{padding: '1em'}}>
                        <ApButton
                        style={{float: 'right'}}
                        className="save"
                        color="blue"
                        onClick={this.handleSave}
                        loading={this.state.loading}
                        disabled={!(!this.validator.getState('name') && !this.validator.getState('start_date') && !this.validator.getState('end_date')) || this.state.loading}
                        >
                            <SvgIcon icon="check" type="solid" />
                            { tr('save') }
                        </ApButton>

                        { this.props.itemId && 
                        <ApButton 
                            className="remove" 
                            color="red" 
                            style={{marginLeft: 20}} 
                            onClick={ this.handleScheduleRemove }
                            loading={this.state.loading}
                            disabled={this.state.loading}
                        >
                            <SvgIcon icon="trash" type="solid" />
                            { tr('delete') }
						</ApButton>
					    }
                        <ApButton
                        style={{float: 'left'}}
                        className="cancel"
                        onClick={this.props.onCancel}
                        >
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>
                    
                    </div>
                }
            />
		);
	}

}