import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { tr } from 'services/Helpers/Helpers.js';
import './ProjectSections.css';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

class ProjectSections extends React.Component {

    constructor(props)
    {
        super(props);
        autoBind(this);
    }

    goTo( form )
    {
        window.emitter.emit( 'goTo', {
            pathname: `/projects/${ this.props.projectId }/${ form }`,
        });
    }

    renderSection( data )
    {
        let classes = ["section"];
        let onClick = () => this.goTo( data.form );

        if( data.form === this.props.form )
        {
            classes.push( "selected" );
            onClick = null;
        } else if( data.disabled )
        {
            classes.push( "disabled" );
            onClick = null;
        }

        return <div className={ classes.join(" ") } onClick={ onClick }>
            <SvgIcon icon={ data.icon } type="solid" />
            <div className="padding">
                <strong>{ data.name }</strong>
                <small>{ data.desc }</small>
            </div>
        </div>
    }

    render()
    {
        return <div className="projectSections">
            { this.renderSection({
                icon: 'calculator',
                name: tr('offer_calculation'),
                desc: 'Budjetoi ja aikatauluta projekti',
                form: 'offer',
                disabled: ( this.props.hasOffer === false ),
            })}
            { this.renderSection({
                icon: 'wrench',
                name: tr('management'),
                desc: tr('management_info'),
                form: 'management',
                disabled: this.props.inOffer,
            })}
            { this.renderSection({
                icon: 'compass',
                name: tr('tracking'),
                desc: tr('tracking_info'),
                form: 'tracking',
                disabled: this.props.inOffer,
            })}
        </div>
    }
}


ProjectSections.propTypes = {
    projectId:      PropTypes.number.isRequired,
    status:         PropTypes.string,
};


export default ProjectSections;
