import React from 'react';
import autoBind from 'react-autobind';

import auth from 'services/Authed/Authed.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import { formatMoney
       , sqlToDateInput
       , tr } from 'services/Helpers/Helpers.js';
import { getTypeIcon }  from 'modules/Storage/common/StorageHelpers.js';

import './OrderTooltip.css';

class OrderTooltip extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            currencySign: auth.getCurrencySign(),
        }
        autoBind(this);
    }

    render()
    {
        const order = this.props.order;

        if( !order ) return null;
        let relatedDom = null;
        if( order.related_id )
        {
            relatedDom = <div>
                <SvgIcon icon={ getTypeIcon( order.related_type ) } type="solid" />
                { order.related_title }
            </div>
        }

        return <div className="orderTooltip">
            <table className="infoTable">
                <thead>
                    <tr>
                        <th colSpan="2" className="header">{ tr('order') }</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="infoLabel">{ tr('number') }:</td>
                        <td className="valueText">{ order.number }</td>
                    </tr>
                    <tr>
                        <td className="infoLabel">{ tr('status') }:</td>
                        <td className="valueText">{ order.status_title }</td>
                    </tr>
                    <tr>
                        <td className="infoLabel">{ tr('date') }:</td>
                        <td className="valueText">{ sqlToDateInput( order.date ) }</td>
                    </tr>

                    <tr>
                        <td className="infoLabel">{ tr('original_creator') }:</td>
                        <td className="valueText">{ order.created_by }</td>
                    </tr>

                    <tr>
                        <td className="infoLabel">{ tr('price') }:</td>
                        <td className="valueNumber">{ formatMoney( order.price ) } { this.state.currencySign }</td>
                    </tr>

                    <tr>
                        <td className="infoLabel">{ tr('orderer') }:</td>
                        <td className="valueText">{ relatedDom }</td>
                    </tr>

                    <tr>
                        <td className="infoLabel">{ tr('day_of_need') }:</td>
                        <td className="valueText">{ sqlToDateInput( order.need_date )}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}

export default OrderTooltip;

