import ApButton from "common/ApButton/ApButton";
import { ApAddon, ApInput, ApInputStack } from "common/ApInput/ApInput";
import ApModal from "common/ApModal/ApModal";
import ApSelect from "common/ApSelect/ApSelect";
import SvgIcon from "common/SvgIcon/SvgIcon";
import React from "react";
import autobind from "react-autobind";
import { errorPopper, keyExists, tr } from "services/Helpers/Helpers";
import moment from "moment";
import api from 'services/Api/Api';

import "./ContractBilling.css";
import ComponentInstanceTable from "modules/Storage/common/ComponentInstanceTable/Cit";
import ApSwitch from "common/ApSwitch/ApSwitch";
import { connect } from "react-redux";
import ApTooltip from "common/ApTooltip/ApTooltip";

class NewContractBillingModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            components: [],
            name: '',
            description: '',
            project: null,
            recurrence: 'monthly',
            recurrence_period: 1,
            last_day_of_month: false,
            next_billing_date: moment().format('YYYY-MM-DD'),
            is_active: true,
        };

        autobind(this);
    }

    getData() {
        if (this.props.selectedRowId) {
            this.setState({ loading: true });
            api({
                method: 'get',
                url: 'project/management/getContractBillingRow/' + this.props.selectedRowId,
            }).then(response => {

                this.setState({
                    loading: false,
                    name: response.data.name,
                    description: response.data.description,
                    project: response.data.project,
                    recurrence: response.data.recurrence,
                    recurrence_period: response.data.recurrence_period,
                    last_day_of_month: response.data.rule_id === 1,
                    next_billing_date: response.data.next_billing_date,
                    components: response.data.billings,
                    is_active: response.data.is_active,
                    create_order: response.data.create_order,
                    payment_condition_id: response.data.payment_condition_id,
                });
            }).catch(error => {
                console.error(error);
                errorPopper(error, tr('get_error'));
                this.setState({ loading: false });
            })
        }
    }

    save() {
        const data = {
            name: this.state.name,
            description: this.state.description,
            project_id: this.state.project ? this.state.project.id : null,
            recurrence: this.state.recurrence,
            recurrence_period: this.state.recurrence_period,
            last_day_of_month: this.state.last_day_of_month,
            next_billing_date: this.state.next_billing_date,
            components: this.state.components,
            is_active: this.state.is_active,
            create_order: this.state.create_order,
            payment_condition_id: this.state.payment_condition_id,
        };
        if (this.props.selectedRowId) {
            data.id = this.props.selectedRowId;
        }

        // validate data
        const errors = [];
        if (!data.name) {
            errors.push(tr('name_missing'));
        }
        if (!data.project_id) {
            errors.push(tr('project_missing'));
        }
        if (!data.next_billing_date) {
            errors.push(tr('next_billing_date_missing'));
        }

        errors.forEach(error => {
            errorPopper(null, error);
        });
        if (errors.length > 0) {
            return;
        }

        this.setState({ loading: true });
        api({
            method: 'post',
            url: this.props.project ? 'project/management/saveContractBilling' : 'storage/order/contractbilling/save',
            data: data,
        }).then(response => {
            this.setState({ loading: false });
            this.resetStateAndClose(true);
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        })
    }

    delete() {
        if (!window.confirm(tr('confirm_delete'))) {
            return;
        }

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'project/management/deleteContractBilling',
            data: {
                id: this.props.selectedRowId
            },
        }).then(response => {
            this.setState({ loading: false });
            this.resetStateAndClose(true);
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        })
    }

    onOpen() {
        if (this.props.project) {
            this.setState({ project: this.props.project });
        }
        if (this.props.selectedRowId) {
            this.getData();
        }
    }

    resetStateAndClose(refresh = false) {
        this.setState({
            name: '',
            description: '',
            project: null,
            recurrence: 'monthly',
            recurrence_period: 1,
            next_billing_date: moment().format('YYYY-MM-DD'),
            components: [],
            is_active: true,
            create_order: false,
        });
        if (typeof this.props.onClose == 'function') {
            this.props.onClose(refresh);
        }
    }

    handleChange(event) {
        if (event.target.name === 'last_day_of_month') {
            // change next_billing_date to last day of month
            if (event.target.value === true) {
                const next_billing_date = this.state.next_billing_date
                    ? moment(this.state.next_billing_date).endOf('month').format('YYYY-MM-DD')
                    : moment().endOf('month').format('YYYY-MM-DD')
                this.setState({ next_billing_date });
            }
        }

        if (event.target.name === 'recurrence_period') {
            if (event.target.value < 1) {
                event.target.value = 1;
            }
        }

        if (event.target.name === 'next_billing_date') {
            // change last_day_of_month to false if value is not last day of month
            const last_day_of_month = this.state.last_day_of_month
                ? moment(event.target.value).endOf('month').format('YYYY-MM-DD') === event.target.value
                : false;
            this.setState({ last_day_of_month });
        }
        this.setState({ [event.target.name]: event.target.value });
    }

    handleProject(project) {
        this.setState({ project: project });
    }

    renderHeader() {
        return (
            <div className="padding">
                <h3>{this.props.selectedRowId ? tr('contract_billing') : tr('new_contract_billing')}</h3>
            </div>
        )
    }

    renderBody() {
        let recurrenceLabel = null;
        if (this.state.recurrence) {
            if (this.state.recurrence == 'weekly') {
                recurrenceLabel = tr('recur_weeks', [keyExists(this.state, 'recurrence_period', true, 1) || 1]);
            }
            else if (this.state.recurrence == 'monthly') {
                recurrenceLabel = tr('recur_months', [keyExists(this.state, 'recurrence_period', true, 1) || 1]);
            }
            else if (this.state.recurrence == 'daily') {
                recurrenceLabel = tr('recur_days', [keyExists(this.state, 'recurrence_period', true, 1) || 1]);
            }
        }
        let payment_condition_options = [{ value: null, label: ''}];
        if (this.props.apOrderSettings && this.props.apOrderSettings.pc) {
            this.props.apOrderSettings.pc.forEach(pc => {
                payment_condition_options.push({ value: pc.id, label: pc.name });
            });
        }

        let vatOptions = [];
        if (this.props.apOrderSettings && this.props.apOrderSettings.vats) {
            vatOptions = this.props.apOrderSettings.vats;
        }

        let customer_id = null;
        if (this.state.project) {
            if (this.state.project.root_customer) {
                customer_id = this.state.project.root_customer.id;
            }
            else if (this.state.project.customer) {
                customer_id = this.state.project.customer.id;
            }
            else if (this.state.project.customer_parent) {
                customer_id = this.state.project.customer_parent.id;
            }
        }

        return (
            <div className="padding">
                <ApInputStack collapseAt={600}>
                    <ApInput
                        id="name"
                        name="name"
                        label={tr('name')}
                        value={this.state.name}
                        onChange={this.handleChange}
                        loading={this.state.loading}
                        validationState={this.state.name ? null : 'error'}
                    />
                    <div>
                        <ApSelect
                            id="project"
                            name="project"
                            label={tr('project')}
                            value={this.state.project}
                            onChange={this.handleProject}
                            apiUrl="report/projects/find"
                            apiData={{
                                selectedProjectId: this.props.project ? this.props.project.id : null,
                                withoutClosed: true,
                                hasRootCustomer: true,
                            }}
                            optionRenderer="project"
                            objKeyValue="name"
                            loading={this.state.loading}
                            validationState={this.state.project?.root_customer ? null : 'error'}
                        />
                        {/* Show warning if project has no root_customer */}
                        {this.state.project && this.state.project.root_customer === null &&
                            <div className="warning" style={{color: 'var(--clr-error-main)', fontWeight: 'bold'}}>{tr('no_customer')}</div>
                        }
                    </div>
                </ApInputStack>
                <ApInput
                    type="textarea"
                    id="description"
                    name="description"
                    label={tr('description')}
                    value={this.state.description}
                    onChange={this.handleChange}
                    loading={this.state.loading}
                />
                <ApInputStack collapseAt={600}>
                    <ApInput
                        type="select"
                        id="recurrence"
                        name="recurrence"
                        label={tr('recurrence')}
                        value={this.state.recurrence}
                        onChange={this.handleChange}
                        options={[
                            { value: 'monthly', label: tr('monthly') },
                            { value: 'weekly', label: tr('weekly') },
                            { value: 'daily', label: tr('daily') },
                        ]}
                        loading={this.state.loading}
                    />
                    <ApInput
                        type="number"
                        id="recurrence_period"
                        name="recurrence_period"
                        label={recurrenceLabel}
                        value={this.state.recurrence_period}
                        onChange={this.handleChange}
                        loading={this.state.loading}
                        validationState={this.state.recurrence_period > 0 ? null : 'error'}
                    />
                    <ApInput
                        type="datetimeV2"
                        id="next_billing_date"
                        name="next_billing_date"
                        label={tr('next_billing_date')}
                        value={this.state.next_billing_date}
                        onChange={e => this.handleChange({ target: { name: 'next_billing_date', value: e } })}
                        loading={this.state.loading}
                        validationState={this.state.next_billing_date ? null : 'error'}
                    />
                </ApInputStack>
                {this.state.recurrence === 'monthly' &&
                    <div className={"apFormGroup" + (this.state.last_day_of_month ? " success" : "")}>
                        <div className="apSwitchBlock small">
                            <label htmlFor="last_day_of_month" className="info">
                                {tr('billing_last_day_of_month')}
                            </label>
                            <ApSwitch
                                id="last_day_of_month"
                                name="last_day_of_month"
                                on={this.state.last_day_of_month}
                                onChange={e => this.handleChange({ target: { name: 'last_day_of_month', value: e.target.checked } })}
                                loading={this.state.loading}
                            />
                        </div>
                    </div>
                }
                <ApInputStack gap="0">
                    <ApAddon width={150}>
                        {tr('payment_term')}
                    </ApAddon>
                    <ApInput 
                        type="select"
                        id="payment_condition_id"
                        name="payment_condition_id"
                        label={tr('payment_term')}
                        value={this.state.payment_condition_id}
                        onChange={this.handleChange}
                        options={payment_condition_options}
                        loading={this.state.loading}
                        validationState={(!this.state.payment_condition_id && this.props.apOrderSettings.pc_required) ? 'error' : null}
                    />
                </ApInputStack>
                <ComponentInstanceTable
                    components={this.state.components}
                    componentsChange={components => this.setState({ components: components })}
                    instance="contract_billing"
                    useTaxGroups={this.props.apOrderSettings.use_tax_groups}
                    vats={vatOptions}
                    project_id={this.state.project ? this.state.project.id : null}
                    crm_id={customer_id}
                />
                <div className={"apFormGroup" + (this.state.is_active ? " success" : "")}>
                    <div className="apSwitchBlock small">
                        <label htmlFor="active" className="info">
                            {tr('active')}
                        </label>
                        <ApSwitch
                            id="active"
                            name="active"
                            on={this.state.is_active}
                            onChange={e => this.handleChange({ target: { name: 'is_active', value: e.target.checked } })}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
                <div className={"apFormGroup" + (this.state.create_order ? " success" : "")}>
                    <div className="apSwitchBlock">
                        <label htmlFor="create_order" className="info">
                            {tr('create_new_order')}
                            <small>{tr('create_new_order_info')}</small>
                        </label>
                        <ApSwitch
                            id="create_order"
                            name="create_order"
                            on={this.state.create_order}
                            onChange={e => this.handleChange({ target: { name: 'create_order', value: e.target.checked } })}
                            disabled={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderFooter() {
        let saveButtonDisabled = false;
        const errors = [];
        if (!this.state.name) {
            saveButtonDisabled = true;
            errors.push(tr('name_missing'));
        }
        if (!this.state.project) {
            saveButtonDisabled = true;
            errors.push(tr('project_missing'));
        }
        if (!this.state.recurrence_period || this.state.recurrence_period < 1) {
            saveButtonDisabled = true;
        }
        if (!this.state.next_billing_date) {
            saveButtonDisabled = true;
            errors.push(tr('next_billing_date_missing'));
        }
        if (!this.state.payment_condition_id && this.props.apOrderSettings.pc_required) {
            saveButtonDisabled = true;
            errors.push(tr('payment_term_selection_mandatory'));
        }
        return (
            <div className="justify-space-between padding">
                <ApButton disabled={this.state.loading} onClick={this.resetStateAndClose}><SvgIcon type="solid" icon="times" /> {tr('cancel')}</ApButton>
                <div style={{ display: 'flex', gap: '10px' }}>
                    {this.props.selectedRowId
                        && <ApButton disabled={this.state.loading} onClick={this.delete} color="red"><SvgIcon type="solid" icon="trash" /> {tr('delete')}</ApButton>}
                        <ApTooltip position='topright' text={errors.map((e, index) => <div key={index}>{e}</div>)}>
                            <ApButton disabled={this.state.loading || saveButtonDisabled} onClick={this.save} color="blue"><SvgIcon type="solid" icon="save" /> {tr('save')}</ApButton>
                        </ApTooltip>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div id="contract-billing-modal">
                <ApModal
                    handleClose={this.resetStateAndClose}
                    show={this.props.show}
                    onOpen={this.onOpen}
                    header={this.renderHeader()}
                    body={this.renderBody()}
                    footer={this.renderFooter()}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
	apOrderSettings: state.apOrderSettings
});

export default connect(mapStateToProps)(NewContractBillingModal);