import React from 'react';
import './InfoCard.css';

class InfoCard extends React.Component {
  
  render() {
    return (
      <div className="col-xs-12">
        <div className="col-sm-4 card-icon-background border" style={{'backgroundColor' : this.props.bgColor}}>
            <span className={"card-icon glyphicon glyphicon-" + this.props.bootstrapIcon}></span>
        </div>
        <div className="col-sm-8 card border">
          <p className="card-title"> {this.props.title} </p>
          <p className="card-text"> {this.props.text} </p>
        </div>
      </div>
    );
  }
}

export default InfoCard;
