import React from 'react';
import autoBind from 'react-autobind';
import { ApInput
       , ApInputStack
       , ApAddon }     from 'common/ApInput/ApInput.js';
import { tr } from 'services/Helpers/Helpers.js';

import ApSelect        from 'common/ApSelect/ApSelect.js';

import './ReceiverDataSelect.css';

class ReceiverDataSelect extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
        };

        this.findTimer = null;
        autoBind(this);
    }

    handleReceiverTypeChange( e )
    {
        this.props.onChange( this.nullReceiver() );
        this.props.onChange({[e.target.name]: e.target.value});
    }

    nullReceiver()
    {
        return {
            receiverTitle:   null,
            receiverId:      null,
        };
    }

    setReceiverStorage( selected )
    {
        if( selected )
        {
            this.props.onChange({
                receiverTitle:   selected.name,
                receiverId:      selected.id,
            });
        }
        else
        {
            this.props.onChange( this.nullReceiver() );
        }
    }

    setReceiverProject( selected )
    {
        if( selected )
        {
            this.props.onChange({
                receiverTitle:   selected.name,
                receiverId:      selected.id,
            });
        }
        else
        {
            this.props.onChange( this.nullReceiver() );
        }
    }

    renderReceiverType()
    {
        return <ApInputStack gap="0">
            <ApAddon
                noRightBorder width="200px"
            >
                { tr('recipient') } *
            </ApAddon>
            <ApInput
                type="select"
                options={ [
                    { value: null,      label: '' },
                    { value: 'location', label: tr('own_storage') },
                    { value: 'project', label: tr('project'), disabled: true },
                ] }
                id="receiverType"
                name="receiverType"
                value={ this.props.receiverType }
                onChange={ this.handleReceiverTypeChange }
                validationState={ this.props.validator.getState('receiverType') }

                disabled={ this.props.receiverLocked }
            />
        </ApInputStack>
    }

    renderReceiverSelect()
    {
        if( this.props.receiverType === 'location' )
        {
            return <ApSelect
                label={ `${tr('recipient_storage')} *` }
                value={ this.props.receiverTitle ? this.props.receiverTitle : '' }
                optionRenderer="storage_location_address"
                onChange={ this.setReceiverStorage }
                objKeyId="id"
                clearable
                apiUrl="storage/locations/find"
                apiData={{ 
                    canReceive: true,
                }}
                validationState={ this.props.validator.getState('receiverId') }
                disabled={ this.props.receiverLocked }
            />
        }
        else if( this.props.receiverType === 'project' )
        {
            return <ApSelect
                label={ `${tr('recipient_project')} *` }
                value={ this.props.receiverTitle ? this.props.receiverTitle : '' }
                optionRenderer="project"
                onChange={ this.setReceiverProject }
                objKeyId="id"
                clearable
                apiUrl="storage/delivery/findprojects"
                validationState={ this.props.validator.getState('receiverId') }
                disabled={ this.props.receiverLocked }
            />
        }
        return null;
    }

    render()
    {
        let receiverType    = this.renderReceiverType();
        let receiverSelect  = this.renderReceiverSelect();

        return <div id="arrivalReceiverDataSelect">
            { receiverType }
            { receiverSelect }
        </div>
    }
}

export default ReceiverDataSelect;
