import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

import './ApListTable.css';
import SvgIcon from '../SvgIcon/SvgIcon.js';
import ApDropdown from '../ApDropdown/ApDropdown.js';
import ApTooltip from '../ApTooltip/ApTooltip.js';

class ApListTable extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            columns: [],
            selectedRows: [],
            selectedGroup: 0,

            parentWidth: window.innerWidth,
            scrollOffset: 0,
            maxScrollOffset: 0,
        }

        autoBind(this); 
        this.updateScrollPositionDebounced = debounce( 100, this.updateScrollPosition );
    } 

    UNSAFE_componentWillMount()
    {
        this.updateParentWidth();
    }

    componentDidMount() 
    {
        window.addEventListener("resize", this.updateParentWidth);
        window.addEventListener("scroll", this.updateScrollPositionDebounced);

        this.updateScrollPosition();
    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.updateParentWidth);
        window.removeEventListener("scroll", this.updateScrollPositionDebounced);
    }

    UNSAFE_componentWillReceiveProps( nextProps )
    {
        this.setColumns( this.props.columns );
    }

    updateParentWidth()
    {
        let width = window.innerWidth;

        if( this.parent ) 
            width = this.parent.getBoundingClientRect().width;
        
        this.setState( {parentWidth: width }, this.setColumns );
    }

    updateScrollPosition()
    {
        if( this.parent && this.props.multiselect )
        {
            const parentBounds = this.parent.getBoundingClientRect();
            const boxHeight = this.multiselect.getBoundingClientRect().height;
            const offset = ( parentBounds.top - boxHeight + ( this.props.scrollOffset ? this.props.scrollOffset : 0 ) ) * -1;
            
            this.setState({ 
                scrollOffset: offset, 
                maxScrollOffset: parentBounds.height, 
            });
        }
    }


    setColumns( newCols )
    {
        let allColumns = newCols ? newCols : this.props.columns;
        let columns = [];

        let grouped = [];
        for( let i = 0; i < allColumns.length; i++ ) 
        {
            // Skip hidden cols 
            if( allColumns[i].hideUnder && allColumns[i].hideUnder >= this.state.parentWidth ) 
                continue;

            // Show as a group 
            if( allColumns[i].groupUnder && allColumns[i].groupUnder >= this.state.parentWidth )
            {
                grouped.push( allColumns[i] );
                continue;
            }

            columns.push( allColumns[i] );
        }

        if( grouped.length > 0 )
        {
            if( grouped.length === 1 ) 
                console.log('ApListTable warning: You should group more than one column at once!')

            for( let i = 0; i < grouped.length; i++ )
                grouped[i].width = this.props.groupWidth ? this.props.groupWidth : "250px";

            columns.push( {
                id: "group",
                dataKey: false,
                columns: grouped,
            });
        }


        if( this.props.multiselect ) 
        {
            columns.unshift( { 
                id: "multiselect",
                dataKey: false,
                width: "50px",
                align: "center",
            });
        }

        if( this.props.rowActions )
        {
            columns.push( {
                id: "actions",
                dataKey: false,
                width: "80px",
                align: "right",
            })
        }

        this.setState({ columns });
    }

    prevGroup( total )
    {
        let selectedGroup = this.state.selectedGroup;
        selectedGroup--;
        if( selectedGroup < 0 ) selectedGroup = ( total - 1 );
        this.setState({ selectedGroup: selectedGroup });
    }

    nextGroup( total )
    {
        let selectedGroup = this.state.selectedGroup;
        selectedGroup++;
        if( selectedGroup >= total ) selectedGroup = 0;
        this.setState({ selectedGroup: selectedGroup });
    }

    getSelectedGroupIndex( groupLength )
    {
        let selectedGroup = this.state.selectedGroup;

        // In case where group size have changed (page resized) and index is out of bounds
        if( selectedGroup >= groupLength ) 
        {
            selectedGroup = 0;
            this.setState({ selectedGroup });
        }
        return selectedGroup;
    }

    toggleSelectedRow( rowId ) 
    {
        let selectedRows = this.state.selectedRows.slice();
        const index = selectedRows.indexOf( rowId );
        if( index === -1 ) 
            selectedRows.push( rowId );
        else 
            selectedRows.splice( index, 1 );

        this.setState({ selectedRows });
    }

    toggleSelectAllRows( forceClear )
    {
        let selectedRows = [];
        
        if( typeof( forceClear ) !== "boolean" || !forceClear ) 
        {
            if( this.state.selectedRows.length < this.props.data.length )
            {
                for( let i = 0; i < this.props.data.length; i++ )
                {
                    selectedRows.push( this.props.data[i][ this.props.rowIdKey ] );
                }
            }
        }

        this.setState({ selectedRows });
    }

    getCellClassNames( colData ) 
    {
        let classes = ["cell"];

        if( colData.align ) 
            classes.push( 'align-' + colData.align );

        if ( colData.multiline ) 
            classes.push( 'multiline' );

        return classes;
    }

    headerCellRenderer( col ) 
    {
        const colData = this.state.columns[ col ];
        const width = colData.width ? colData.width : "auto";
        let classes = this.getCellClassNames( colData );

        if( colData.dataKey ) 
        {
            let onClickAction = () => null;
            if( colData.sortable ) 
            {
                classes.push("sortable")
                onClickAction = () => this.sortBy( colData.dataKey );
            }

            if( this.props.sortBy === colData.dataKey )
                classes.push("sorted");

            return (
                <th style={{ width: width }} className={ classes.join(" ") } onClick={ onClickAction }>
                    { colData.label }
                    { colData.dataKey === this.props.sortBy &&
                        <SvgIcon className={"sortArrow" + ( this.props.sortDirection.toLowerCase() === "desc" ? " reverse" : "" )} icon="chevron-down" type="solid" />
                    }
                </th>
            );
        }

        if( colData.id === "multiselect" )
        {
            classes.push("multiselect");
            const allSelected = this.state.selectedRows.length >= this.props.data.length;
            return (
                <th style={{ width: width }} className={ classes.join(" ") } onClick={ () => this.toggleSelectAllRows() }>
                    <input type="checkbox" checked={ allSelected } readOnly />
                </th>
            );
        }
        else if ( colData.id === "actions" ) 
        {
            return (
                <th style={{ width: width }} className={ classes.join(" ") }></th>
            );
        }

        else if ( colData.id === "group" ) 
        {
            classes.push("group");

            const selectedGroup = this.getSelectedGroupIndex( colData.columns.length );
            const current = colData.columns[ selectedGroup ];

            if( this.props.sortBy === current.dataKey )
                classes.push("sorted");

            return (
                <th style={{ width: this.props.groupWidth ? this.props.groupWidth : "250px" }} className={ classes.join(" ") }>
                    <SvgIcon className="colGroupArrow prev" icon="chevron-left" type="solid" onClick={ () => this.prevGroup( colData.columns.length ) } />
                    <SvgIcon className="colGroupArrow next" icon="chevron-right" type="solid" onClick={ () => this.nextGroup( colData.columns.length ) } />
                    <div className="colGroupLabel" onClick={ () => this.nextGroup( colData.columns.length ) }>
                        { current.label }
                    </div>
                    <div className="colGroupList">
                        { colData.columns.map( ( col, index ) => 
                            <div className={ "item" + ( index === selectedGroup ? " selected" : "" ) }></div>
                        )} 
                    </div>
                </th>
            );
        }
    }

    cellRenderer( row, col ) 
    {
        const rowData = this.props.data[ row ];
        let colData = this.state.columns[ col ];

        if( colData.id === "group" )
        {
            const selectedGroup = this.getSelectedGroupIndex( colData.columns.length );
            colData = colData.columns[ selectedGroup ];
            colData.align = "center";
        }

        const width = colData.width ? colData.width : "auto";
        const rowId = rowData[ this.props.rowIdKey ];
        const rowSelected = this.state.selectedRows.indexOf( rowId ) !== -1;
        let classes = this.getCellClassNames( colData );

        if( colData.dataKey )
        {
            let onClickAction = () => null;
            
            if( this.props.onRowClick ) 
            {
                classes.push("clickableRow")
                onClickAction = () => this.props.onRowClick( rowId );
            }
            else if( colData.onClick )
            {
                classes.push("clickable")
                onClickAction = () => colData.onClick( rowId );
            }

            return (
                <td style={{ width: width }} className={ classes.join(" ") } onClick={ onClickAction }>
                    { rowData[ colData.dataKey ] }
                </td>
            );
        }
        
        if( colData.id === "multiselect" )
        {
            classes.push("multiselect");
            return (
                <td style={{ width: width }} className={ classes.join(" ") } onClick={ () => this.toggleSelectedRow( rowId ) }>
                    <input type="checkbox" checked={ rowSelected } readOnly />
                </td>
            );
        }
        else if ( colData.id === "actions" ) 
        {
            return (
                <td style={{ width: width }} className={ classes.join(" ") }>
                    { this.renderRowActions( rowId ) }
                </td>
            );
        }
    }

    renderRowActions( rowId )
    {
        if( this.props.rowActions.length === 1 ) 
        {
            return (
                <ApTooltip text={ this.props.rowActions[0].label }>
                    <div className="rowAction" onClick={ () => this.props.rowActions[0].action( rowId ) }>
                        <SvgIcon icon={ this.props.rowActions[0].icon } type="solid" />
                    </div>
                </ApTooltip>
            );
        }
        else {
            return (
                <ApDropdown passOnCloseFunc>
                    {( closeDropdownFunc ) => 
                        <div className="rowActions">
                            { this.props.rowActions.map( ( action, index ) => {
                                return (
                                    <div 
                                        key={index} 
                                        className="action" 
                                        onClick={ () => { action.action( rowId ); closeDropdownFunc(); } }
                                    >
                                        <SvgIcon icon={ action.icon } type="solid" />
                                        { action.label }
                                    </div>
                                );
                            })}
                        </div>
                    }
                </ApDropdown>    
            );
        }
    }

    sortBy( columnKey )
    {
        if( typeof( this.props.handleSort ) === "function" )
        {
            // New column
            if( columnKey !== this.props.sortBy )
            {
                this.props.handleSort( columnKey, "asc" );
            }
            // Same column -> change direction
            else 
            {  
                this.props.handleSort( columnKey, ( this.props.sortDirection.toLowerCase() === "asc" ? "desc" : "asc" ) );
            }
        }
        else 
        {
            console.error('ApListTable error: You should define handleSort prop as a function to sort table columns!');
        }
    }

    render()
    {
        let classes = ["apListTable"];
        let multiselectStyle = {};
        const selectedCount = this.state.selectedRows.length;

        if( selectedCount > 0 )
            classes.push("rowsSelected");

        if( this.state.scrollOffset > 0 ) 
        {
            classes.push("scrolled");
            multiselectStyle = { top: Math.min( this.state.scrollOffset, this.state.maxScrollOffset ) };
        }

        return (
            <div className={ classes.join(" ") } ref={ node => this.parent = node }>

                { this.props.multiselect && 
                    <div className="multiselectActions" style={ multiselectStyle } ref={ node => this.multiselect = node }>
                        <div className="padding">
                            <div className="text">
                                Valittuna { selectedCount }/{ this.props.data.length }:
                            </div>
                            <div className="cancel" onClick={ () => this.toggleSelectAllRows(true) }>
                                <SvgIcon icon="times" type="solid" />
                            </div>
                            { this.props.multiselect.map( ( action, index ) => {
                                return (
                                    <div 
                                        key={index} 
                                        className="action" 
                                        onClick={ () => action.action( this.state.selectedRows ) }
                                    >
                                        <SvgIcon icon={ action.icon } type="solid" />
                                        { action.label }
                                    </div>
                                );
                            })}
                        </div>
                    
                    </div>
                }


                <table className="list">
                    <thead>
                        <tr>
                            { this.state.columns.map( (col, colIndex ) => this.headerCellRenderer( colIndex ) )}
                        </tr>
                    </thead>
                    <tbody>
                        { this.props.data.map( ( row, rowIndex ) => {
                            let rowClasses = [];
                            
                            if( this.state.selectedRows.indexOf( row[ this.props.rowIdKey ] ) !== -1 )
                                rowClasses.push( "selected" );

                            if( this.props.rowStatusKey )
                                rowClasses.push( "status-" + row[ this.props.rowStatusKey ] );

                            return (
                                <tr className={ rowClasses.join(" ") }>
                                    { this.state.columns.map( ( col, colIndex ) => this.cellRenderer( rowIndex, colIndex ) )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                { this.props.mobileLayout && 
                    <div className={"mobileList" + ( this.props.multiselect ? " multiselectAvailable" : "" ) + ( this.props.rowActions ? " rowActionsAvailable" : "" ) }>
                        { this.props.data.map( ( row, rowIndex ) => {
                            
                            const rowId = row[ this.props.rowIdKey ];
                            const rowSelected = this.state.selectedRows.indexOf( rowId ) !== -1;

                            let onClickAction = () => null;
                            if( this.props.onRowClick ) 
                            {
                                onClickAction = () => this.props.onRowClick( rowId );
                            }
                        
                            return (
                                <div className={"cell" + ( rowSelected ? " selected" : "" ) } onClick={ onClickAction } >

                                    { this.props.multiselect && 
                                        <div className="multiselect" onClick={ () => this.toggleSelectedRow( rowId ) }>
                                            <input type="checkbox" checked={ rowSelected } readOnly />
                                        </div>
                                    }

                                    { this.props.rowActions && 
                                        <div className="actions">
                                            { this.renderRowActions( rowId ) }
                                        </div>
                                    }

                                    { this.props.mobileLayout( row ) }

                                </div>
                            );
                        })}
                    </div>
                }

            </div>
        );
    }
};

ApListTable.propTypes = {
    data:               PropTypes.array.isRequired,
    columns:            PropTypes.array.isRequired,
    rowIdKey:           PropTypes.string.isRequired,
    rowStatusKey:       PropTypes.string,
    rowActions:         PropTypes.array,
    multiselect:        PropTypes.array,
    groupWidth:         PropTypes.string,
    mobileLayout:       PropTypes.func, 
    onRowClick:         PropTypes.func,
    sortBy:             PropTypes.string,
    sortDirection:      PropTypes.string, // 'ASC' | 'DESC'
    handleSort:         PropTypes.func,
    /*
    visibleCols:        PropTypes.array,
    height:             PropTypes.number,
    rowHeight:          PropTypes.oneOfType([ PropTypes.number, PropTypes.func ]), 
    multiselect:        PropTypes.array,
    onRowClick:         PropTypes.func,
    
    */
};

export default ApListTable;

