/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import auth from 'services/Authed/Authed.js';
import ApSelect from 'common/ApSelect/ApSelect.js';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';
//import ApReactTable from 'common/ApReactTable/ApReactTable.js';
//import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
//import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

import { keyExists, tr } from 'services/Helpers/Helpers.js';
import './Viestisuora.css';

export default class CrmViestisuora extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            error: false,
            groups: false,
            unsavedChanges: false,

            modalId: false,
            modalType: false,
            modalValue: '',

            selected: [],
        };
        autoBind(this);

        this.nextId = 0; 
    }

    componentDidMount()
    {
        this.getGroups();
    }

    getGroups()
    {  
        this.setState({ loading: true });
        let selected = [];
        api({
            method: 'get',
            url: 'viestisuora/groups'
        }).then((response) => {
            let selected=[];
            selected.groups=response.selected;
            this.setState({ 
                groups: response.groups,
                selected: selected,
                unsavedChanges: false,
                loading: false 
            });

        }).catch((error) => {
            console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('get_error') ) || tr('error'),
                loading: false 
            });
        });
    }


    saveGroups()
    {
        this.setState({ 
            loading: true, 
            error: false 
        });
        api({
            method: 'post',
            url: 'viestisuora/groups/save',
            data: this.state.selected
        }).then((response) => {
            this.getGroups();
        }).catch((error) => {
            console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('get_error') ) || tr('error'),
                loading: false 
            });
        });
    }


    handleSelectChange( name, value )
    {
        let data = { ...this.state.selected };
        data[ name ] = value;
        data[ name + "_id" ] = ( value && value.id ? value.id : null );

        this.setState({ 
            selected: data,
            unsavedChanges: true,
        });
    }

    getToken( )
	{    

        api({
                method: 'get',
                url: 'viestisuora/create/token',
                data: {	}
            }).then((response) => {
                let link = "https://www.amiproservice.fi/apps/easypro.php/"+JSON.parse(JSON.stringify(response));
                window.open(link, "_blank");
                //window.location.href = link;
            }).catch((error) => {
                console.log(error);
            });
        
	}

    render() 
    {
        return (
            <div id="pageCrmViestisuora">

                <ApFormPage 
                    onSave={ this.saveGroups } 
                    loading={ this.state.loading } 
                    unsaved={ this.state.unsavedChanges }
                    error={ this.state.error }
                >
                    <div className="viestisuora-float-right">                 
                        <ApButton
                            size="small"
                            color="blue"
                            onClick={ this.getToken }
                        > 
                            { tr('login_to_viestisuora') }
                        </ApButton>
                    </div>
                    <div className="apBoxHeader">
                        <h1>{ tr('viestisuora_header') }</h1>
                        <p>{ tr('viestisuora_info') }</p>

                    </div>

                    <div className="padding">

                        <div className="clear">
                            <div className="apColumn w50">
	                            <ApSelect
		                            label={ tr('customer_groups') }
		                            value={ keyExists( this.state.selected, "groups" ) ? this.state.selected.groups : [] }
		                            valueRenderer={ (item) => {
		                                return (
		                                    <div className="selectedGroup">
		                                        <SvgIcon className="small-inline" icon="tag" type="solid" />
		                                        { item.name }
		                                    </div>
		                                );   
		                            }}
		                            optionRenderer={ (item) => {
		                                return (
		                                    <div className="groupOption">
		                                        <SvgIcon className="small-inline" icon="tag" type="solid" />
		                                        { item.name }
		                                    </div>
		                                );   
		                            }}
		                            onChange={ ( groups ) => this.handleSelectChange( "groups", groups )  }
		                            objKeyId="id"
		                            objKeySearchable="name"
		                            multiselect
		                            options={ keyExists( this.state, "groups", true, [] ) }
		                            //apiUrl="hour/projects"
		                            //apiData={{ exluded: [] }}
		                            loading={ this.state.loading }
		                            disabled={ this.state.loading }
		                            readOnly={ !auth.hasModule( "clients.edit" ) }
		                        />
                                
                            </div>
                            
                        </div>

                    </div>
                </ApFormPage>

                
                <ApModalInput 
                    show={ this.state.modalId !== false }
                    value={ this.state.modalValue }
                    onSave={ ( value ) => this.saveModal( value ) }
                    onClose={ () => this.setState({ modalId: false }) }
                    title={ this.state.modalType == "company" ? tr('customer_group') : tr('contact_group') }
                    label={ tr('name') }
                    required
                />

            </div>
        );
    }
}
