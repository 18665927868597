import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import { errorPopper }  from 'services/Helpers/Helpers.js'

class ComponentsMoved extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
           
        }

        this.steps = [{
            name: "Siirretyt komponentit",
            render: this.renderInfo.bind( this ),
        }];

        autoBind(this);
    }

    renderInfo()
    {

        const someMoved = this.props.data != null ? this.props.data[1].length > 0 : false;

        //console.log(`renderInfo DATA: ${JSON.stringify(this.props, null, 4)}`);
        return <div className="stepSupplier">
            <div className="supplier-select">
                <h6>Siirretyt komponentit</h6> 
                <p>{someMoved ? 'Komponentteja siirretty' : 'Komponentteja ei siirretty'}</p>
            </div>

            <div style={{borderTop: '2px solid black' }}>
                {this.renderComponentView()}
            </div>
        </div>
    }

    renderComponentView() {

        
        if (this.props.data != null) {

            if (this.props.data[0] == true) {
                const mapped = this.mapComponentsToMove();
                return <div className="treeContainer">
                <div className="left">

                <table className="componentTable">
                <thead><tr>                          
                        <td><h5>Komponentit</h5></td>
                        </tr>
                    </thead>
                        <thead>
                            <tr>
                                <th className="fixed">Id</th>
                                <th className="fixed">Nimi</th>
                                <th className="fixed">Saapunut</th>
                                <th className="fixed" style={{paddingLeft: '1em'}}>Vielä tilauksessa</th>
                            </tr>
                        </thead>
                        <tbody>
                        { mapped }
                        </tbody>                                      
                </table>
                </div>
            </div>
            } else {
                return <div>
                    <h5>Yhtään komponenttia ei voitu siirtää.</h5>
                    <p>Syynä voi olla:</p>
                    <ul>
                        <li>Komponentteja ei ole/ei ole tarpeeksi alkuperäisessä varastossa.</li>
                        <li>Ostotilauksen komponentit olivat kirjattu väliaikaisiksi, eikä niitä säilytetä varastossa.</li>
                    </ul></div>
                
            }
            
        }
    }

    mapComponentsToMove() {
        let mappedData = [];

        this.props.data[1].map(( c ) => { 
                mappedData.push(<tr key={ c.id } className={ "c" }>
                    <td width= "60" className="id">
                        {  c.component_id != null ? c.component_id : 'U' }
                    </td>

                    <td width= "200" className="name">
                    {  c.name  }
                    </td>

                    <td width= "60" align="right" style={{ marginRight: '1em' }} className="arrived">
                        { c.balance }
                    </td>
                    
                    <td width= "60" align="right" style={{ marginRight: '1em' }}className="missing">
                        { c.balance_free }
                    </td>
                </tr>);
                
            });

            return mappedData;
    }

    mapComponentsNeeded() {
        let mappedData = [];

        this.props.data.components.map(( c ) => {
            mappedData.push({
                component_id: c.component_id,
                name: c.name,
                purchase_order_arrived: c.purchase_order_arrived,
                purchase_order_missing: c.purchase_order_missing
            })
        });

        return mappedData;
    }
    render()
    {
        return <div id="newPo">
            <ApModalWizard
                header="Siirretyt komponentit"
                //onOpen={ this.resetState } //Kutsutaan myös componentDidUpdate funktiossa.
                show={ this.props.show  }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.props.onSave }
            />
        </div>
    }

}

export default ComponentsMoved;