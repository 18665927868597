/* eslint-disable eqeqeq */
import React        from 'react';
import autoBind     from 'react-autobind';
import PropTypes    from 'prop-types';
import Dropzone     from 'react-dropzone'
import axios        from 'axios';

import api                  from 'services/Api/Api.js';
import { readableFileSize, tr } from 'services/Helpers/Helpers.js';

import './ApFileUpload.css';

import SvgIcon      from 'common/SvgIcon/SvgIcon.js';


class ApFileUpload extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            loading: false,
            files: [],
            uploading: false,
            uploadingId: false,
        }

        autoBind(this); 
    } 

    UNSAFE_componentWillReceiveProps( nextProps )
    {
        if( nextProps.files != this.props.files )
            this.loadPreloadedFiles( nextProps.files );
    }

    resetFiles()
    {
        this.setState({ files: [] });
    }

    filesChanged( userAction = false )
    {
        let files = [];
        this.state.files.forEach( file => {
            if( !file.removed || this.props.returnRemoved )
                files.push( file );
        });
     
        if( typeof( this.props.onChange ) == "function" )
                this.props.onChange( files, userAction );
    }
    

    loadPreloadedFiles( fileIds )
    {
        if( fileIds.length == 0 )
        {
            this.setState({ files: [] });
            return false;
        }

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'file/get',
            data: { ids: fileIds },
        }).then(( response ) => {

            let files = [];
            response.forEach( file => {
                files.push({
                    id: file.id,
                    name: file.original_name,
                    size: file.size,
                    progress: 100,
                    preloaded: true,
                    removed: false,
                });
            });

            this.setState({ 
                files: files,
                loading: false 
            }, this.filesChanged );

        }).catch(( error ) => {
            console.log( 'Error while loading preloaded files', error );
            this.setState({ files: [], loading: false });
        });
    }


    onDrop( acceptedFiles, rejectedFiles )
    {
        if( this.state.uploading )
            return false;

        let files = this.state.files.slice();
        let newFilesAvailable = false;
        
        if( acceptedFiles.length > 0 )
        {
            acceptedFiles.forEach( file => {

                file.preloaded = false;
                file.removed = false;
                file.progress = 0;
                file.cancelToken = axios.CancelToken.source();

                //if( this.props.maxSize && file.size > this.props.maxSize )
                    // TODO

                if( !this.props.maxFiles || this.getFileCount() < this.props.maxFiles )
                {
                    files.push( file );
                    newFilesAvailable = true;
                }
            });

            if( newFilesAvailable )
                this.setState({ files }, this.uploadStart );
        }  
    }


    handleRemove( index )
    {   
        let files = this.state.files.slice();
        let proceedToUpload = false;

        if( index < files.length )
        {
            files[ index ].removed = !files[ index ].removed;

            if( files[ index ].removed )
            {
                if( files[ index ].progress > 0 && files[ index ].progress < 100 )
                {
                    files[ index ].cancelToken.cancel('Operation canceled by the user.');
                    files[ index ].cancelToken = axios.CancelToken.source();
                    files[ index ].progress = 0;
                    proceedToUpload = true;
                }
            }
            else {
                if( files[ index ].progress < 100 )
                    proceedToUpload = true;
            }

            this.setState({ files: files }, () => {

                if( proceedToUpload )
                    this.uploadStart( ( this.state.uploadingId != index ? true : false ) );

                this.filesChanged( true );
            
            });
        }
    }

    uploadEnded()
    {
        this.setState({ uploading: false });

        this.filesChanged( true );
    }

    uploadStart( userAction )
    {

        if( this.state.uploading && userAction )
        {
            console.log('Wait for it... already uploading.')
            return false;
        }

        let files = this.state.files.slice();
        let selected = false;

        for( let i = 0; i < files.length; i++ )
        {   
            if( files[i].progress == 0 && !files[i].removed )
            {
                selected = i;
                break;
            }
        }

        this.setState({ 
            uploading: true,
            uploadingId: selected,
        });

        // No more files to upload so we are ready
        if( selected === false )
        {
            this.uploadEnded();
            return false;
        }

        const onUploadProgress = ( e ) => {
            let progress = Math.round( ( e.loaded / e.total ) * 100 );
            
            // Limit progress to 1 - 99 %. Then we can assume:
            //  0%   = upload not started
            //  100% = upload ready
            if( progress < 1 ) progress = 1; 
            if( progress > 99 ) progress = 99; 

            if( files[ selected ].removed ) 
                return false;
    
            files[ selected ].progress = progress;
            this.setState({ files });
        };

        let data = new FormData();
        data.append('filedata', files[ selected ] );

        if( this.props.description )
            data.append( 'description', this.props.description );

        if( this.props.keep )
            data.append( 'keep', true );

        api({
            cancelToken: files[ selected ].cancelToken.token,
            headers: { 'Content-Type': 'multipart/form-data' },
            method: 'post',
            url: 'file/upload',
            data: data,
            onUploadProgress: onUploadProgress,
        }).then((response) => {
            
            files[ selected ].id = response.id;
            files[ selected ].progress = 100;

            this.setState({ files }, this.uploadStart );

        }).catch( error => {
            console.error('Upload error', error );
            this.setState({
                uploading: false,
            });
        });
    
    }

    getFileCount( type )
    {
        let counter = 0;
        this.state.files.forEach( file => {
            if( !type )
            {
                if ( !file.removed )
                    counter++;
            }
            else if ( type == "all" )
            {
                counter++;
            }
            else if ( type == "ready" )
            {
                if( file.progress == 100 )
                    counter++;
            }
            else if ( type == "waiting" )
            {
                if( file.progress == 0 )
                    counter++;
            }
            else if ( type == "removed" )
            {
                if( file.removed )
                    counter++;
            }
        });
        return counter;
    }

    renderNoFiles()
    {
        if( this.props.customNoFiles )
            return this.props.customNoFiles;

        return (
            <div className="noFiles">
                <strong>{ ( this.props.noFilesLabel ? this.props.noFilesLabel : tr('no_files_added') ) }</strong><br />
                { this.props.noFilesSubLabel && 
                    <small>{ this.props.noFilesSubLabel }</small> 
                }
            </div>
        );
    }

    renderFiles()
    {   
        if( this.props.customFiles )
            return this.props.customFiles;

        return (
            <div className="files">                                        
                { this.state.files.map( ( file, index ) => {
                    
                    let classes = ["file"];
                    let icon = "arrow-alt-circle-up";

                    if( file.progress == 0 )
                    {
                        classes.push("waiting")
                        icon = "pause-circle";
                    }

                    if( file.progress == 100 )
                    {
                        classes.push("uploaded")
                        icon = "check-circle";
                    }

                    if( file.removed )
                    {
                        classes.push("removed");
                        icon = "times-circle";
                    }

                    return (
                        <div key={ index } className={ classes.join(" ") }>

                            <SvgIcon className="icon" icon={ icon } type="solid" />

                            <div className="info">
                                <strong>{ file.name } </strong>
                                <small>{ readableFileSize( file.size ) }</small>
                                <small> - { file.progress } %</small>
                            </div>

                            <div className="uploadProgress">
                                <div style={{ width: file.progress + "%" }}></div>
                            </div>

                            <SvgIcon className="removeButton" icon={ file.removed ? "ban" : "trash-alt" } type="solid" onClick={ () => this.handleRemove( index ) } />
                        </div>
                    );
                })}
            </div>
        );
    }

    renderAddButton()
    {
        if( this.props.customAddButton )
            return this.props.customAddButton;
        
        const disabled = ( this.state.uploading || ( this.props.maxFiles && this.state.files.length >= this.props.maxFiles ) ? true : false );
        const onClick = ( disabled ? () => false : () => this.dropzoneRef.open() );

        return (
            <div className={ "addButton" + ( disabled ? " disabled" : "") } onClick={ onClick }>
                <SvgIcon icon="file-upload" type="solid" />
                { ( this.props.addButtonLabel ? this.props.addButtonLabel : `${tr('add_file')}...` ) }
            </div>
        );
    }

    render()
    {
        let classes = [ "apFileUpload" ];

        if( this.state.loading )
            classes.push( "loading" );

        if( this.props.showRemoved )
            classes.push( "showRemoved" );

        if( ["error", "warning", "success"].indexOf( this.props.validationState ) != -1 )
            classes.push( "validator-" + this.props.validationState );
    
        let message = ( this.props.noFilesTopMessage ? this.props.noFilesTopMessage : tr('file_upload_note') );
        const fileSuffix = ( count ) => {
            return count + tr('files_count');
        };

        if( this.state.uploading )
            message = `${tr('loading')}`;

        else if( this.state.files.length > 0 )
        {
            if( this.props.maxFiles )
                message = `${tr('in_total')} ` + this.getFileCount() + "/" + fileSuffix( this.props.maxFiles );
            else 
                message = `${tr('in_total')} ` + fileSuffix( this.getFileCount() );
        }

    
        return (
        	<div className={ classes.join(" ") }>
                
                <Dropzone 
                    ref={ node => this.dropzoneRef = node }
                    className="apDropzone"
                    activeClassName="active"
                    acceptClassName="accept"
                    rejectClassName="rejected"
                    disableClick
                    disablePreview
                    accept={ this.props.accept }
                    onDrop={ this.onDrop }
                    disabled={ this.props.disabled || this.state.loading }
                >

                    
                    { !this.props.hideTopbar && 
                        <div className="topbar">
                            <div className="message">
                                <SvgIcon icon="info-circle" type="solid" />
                                <span>{ message }</span>
                            </div>
                        </div>
                    }
                    
                    { this.state.files.length == 0 &&
                        this.renderNoFiles()
                    }

                    { this.state.files.length > 0 &&
                        this.renderFiles()
                    }
                    
                    { this.renderAddButton() }
                    
                </Dropzone>
        	</div>
        );
    }
};


ApFileUpload.propTypes = {
    accept:             PropTypes.string,
    onChange:           PropTypes.func.isRequired,
    files:              PropTypes.array,
    keep:               PropTypes.bool,
    description:        PropTypes.string,
    maxFiles:           PropTypes.number,
    maxSize:            PropTypes.number,
    showRemoved:        PropTypes.bool,
    returnRemoved:      PropTypes.bool,
    disabled:           PropTypes.bool,
    hideTopbar:         PropTypes.bool,
    addButtonLabel:     PropTypes.string,
    noFilesLabel:       PropTypes.string,
    noFilesSubLabel:    PropTypes.string,
    noFilesTopMessage:  PropTypes.string,
    customNoFiles:      PropTypes.node,
    customFiles:        PropTypes.node,
    customAddButton:    PropTypes.node,
    validationState:    PropTypes.string,
};

export default ApFileUpload;

