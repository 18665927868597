/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import { tr, currentLang } from  'services/Helpers/Helpers.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import {
    ApInput, 
    ApInputStack, 
    ApAddon
} from 'common/ApInput/ApInput.js';
    

const hourOptions = [
    { id: 8, label: '8:00' },
    { id: 9, label: '9:00' },
    { id: 10, label: '10:00' },
    { id: 11, label: '11:00' },
    { id: 12, label: '12:00' },
    { id: 13, label: '13:00' },
    { id: 14, label: '14:00' },
    { id: 15, label: '15:00' },
    { id: 16, label: '16:00' },
    { id: 17, label: '17:00' },
    { id: 18, label: '18:00' },
];

let dayOptions = []
for (let i = 1; i <= 7; i++)
{
    dayOptions.push({
        id: i,
        label: moment().locale(currentLang()).day(i).format('dddd')
    })
}

const typeOptions = [
    { id: 'timetracking_submit', label: tr('time_tracking_submit_late') },
    { id: 'timetracking_approve', label: tr('time_tracking_approve_late') },
];

const sendBy = [
    { id: 'email', label: tr('email') },
    { id: 'sms', label: tr('sms') },
];



export default class Notifications extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
        };

        this.newCounter = 0;

        autoBind(this);
    }

    addNotification()
    {
        let notifications = this.props.notifications.slice(0);

        this.newCounter++;
        notifications.push({
            id: `new${ this.newCounter }`,
            day: null,
            active: true, // TODO
            title: null,
            description: null,
            send_type: null,
            send_hour: null
        });
        this.props.handleChange( 'notifications', notifications );
    }

    editNotification( row, data )
    {
        let notifications = this.props.notifications.slice(0);

        notifications = notifications.map(n => {
            if( n.id === row.id )
                n = { ...n, ...data };
            return n;
        })
        this.props.handleChange( 'notifications', notifications );
    }

    removeNotification( row )
    {
        let notifications = this.props.notifications.slice(0);
        notifications = notifications.filter(n => n.id !== row.id )
        this.props.handleChange( 'notifications', notifications );
    }



    renderRow( row )
    {
        const send = sendBy.find( t => t.id === row.send_type );
        const type = typeOptions.find( t => t.id === row.type );

        const day  = dayOptions.find( t => t.id === row.day );
        const hour = hourOptions.find( t => t.id === row.send_hour );

        // Type, Day
        let sendTime  = <div className="apInfo small">
        <SvgIcon icon="info-circle" type="solid" />
        { tr('time_tracking_notifications_settings_info3') }</div>;
        if (send!==undefined && send.id=='sms') {
            sendTime=<ApInputStack gap="0">
                        <ApAddon width="10px" rounded={false}>
                        <div><bold>{ tr('sending_time' ) }</bold></div>
                        </ApAddon>
                        <ApSelect
                            rounded="left"
                            type="select"
                            options={ hourOptions } 
                            value={ hour }							
                            onChange={ ( v ) => this.editNotification( row, { send_hour: v.id } ) }
                            loading={ this.props.loading }
                            disabled={ this.props.loading }
                            optionRenderer="label"
                            objKeyId="id"
                            objKeySearchable="label"
                            objKeyValue="label"
                            validationState={ row.send_hour ? null : 'error' }
                        />
                    </ApInputStack>
        }

        return <div className="notificationRow">

            <div className="clear">
                <div className="apColumn w50">
                        <ApSwitch
                            id={`activeswitch_${ row.id }`}
                            on={ row.active }
                            onChange={ ( v ) => this.editNotification( row, { active: !row.active} ) }
                            disabled={ this.props.loading }
                            title={ tr('in_use') }
                        />
                </div>
                <div className="apColumn w50">
                    <div className="text-right">
                        <ApButton color="red" size="small" onClick={ () => this.removeNotification( row ) }>
                            <SvgIcon icon="trash" type="solid" />
                            { tr('delete') }
                        </ApButton>
                    </div>
                </div>
            </div>
            <div className="clear">
                <div className="apColumn w50">
                    <ApSelect
                        label={ tr('notification_type') }
                        loading={ this.props.loading }
                        disabled={ this.props.loading }
                        value={ type }
                        onChange={ ( v ) => this.editNotification( row, { type:  v.id } ) }
                        options={ typeOptions }
                        optionRenderer="label"
                        objKeyId="id"
                        objKeySearchable="label"
                        objKeyValue="label"
                        validationState={ row.type ? null : 'error' }
                    />
                </div>
                <div className="apColumn w50">
                    <ApSelect
                        label={ tr('day_of_week_when_sent') }
                        loading={ this.props.loading }
                        disabled={ this.props.loading }
                        value={ day }
                        onChange={ ( v ) => this.editNotification( row, { day: v.id } ) }
                        options={ dayOptions }
                        optionRenderer="label"
                        objKeyId="id"
                        objKeySearchable={["id", 'label']}
                        objKeyValue="label"
                        validationState={ row.day ? null : 'error' }
                    />
                </div>
            </div>
            <div className="clear">
                <div className="apColumn w50">
                    <ApSelect
                        label={ tr('outgoing_message') }
                        loading={ this.props.loading }
                        disabled={ this.props.loading }
                        value={ send }
                        onChange={ ( v ) => this.editNotification( row, { send_type: v.id } ) }
                        options={ sendBy }
                        optionRenderer="label"
                        objKeyId="id"
                        objKeySearchable="label"
                        objKeyValue="label"
                        validationState={ row.send_type ? null : 'error' }
                    />
                </div>
                <div className="apColumn w50">
                    { sendTime }
                </div>
            </div>
            <ApInput
                type="text"
                id="notification_name"
                name="title"
                label={ tr('header') }
                value={ row.title ? row.title : '' }
                onChange={ ( e ) => this.editNotification( row, { title: e.target.value }) }
                loading={ this.props.loading }
                disabled={ this.props.loading }
                validationState={ row.title ? null : 'error' }
            />

            <ApInput
                type="textarea"
                id="notification_description"
                name="description"
                label={ tr('message') }
                value={ row.description ? row.description : '' }
                rows={ 4 }
                onChange={ ( e ) => this.editNotification( row, { description: e.target.value }) }
                loading={ this.props.loading }
                disabled={ this.props.loading }
            />



        </div>
    }

    render()
    {
        return <div className="padding">
            <h3>{ tr('notifications') }</h3>
                <p>
                    { tr('time_tracking_notifications_settings_info1') }
                </p>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('time_tracking_notifications_settings_info2') }
            </div>


            <ApButton color="white" size="small" onClick={ this.addNotification }>
                <SvgIcon icon="plus-circle" type="solid" />
                { tr('add_automatic_notification') }
            </ApButton>

            { this.props.notifications.map( n => this.renderRow( n ) ) }


        </div>
    }

}
