import React from 'react';
import autoBind from 'react-autobind';
import { ApInput
       , ApInputStack
       , ApAddon }     from 'common/ApInput/ApInput.js';
import { tr } from 'services/Helpers/Helpers.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';

import ApSelect        from 'common/ApSelect/ApSelect.js';
import api from 'services/Api/Api.js';

import './ReceiverDataSelect.css';

class ReceiverDataSelect extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            suggests: [],
        };

        this.findTimer = null;
        autoBind(this);
    }

    componentDidMount() {
        this.setReceiverStorage_project( this.props.selectedProject, true )
    }

    handleChange(e)
    {
        this.props.onChange({[e.target.name]: e.target.value});
    }

    handleReceiverTypeChange( e )
    {
        this.props.onChange( this.nullReceiver() );
        this.props.onChange({[e.target.name]: e.target.value});
    }

    nullReceiver()
    {
        return {
            receiverProjectId: null,
            receiverTitle:   null,
            receiverId:      null,
            receiverName:    null,
            receiverAddress: null,
            receiverZipcode: null,
            receiverCity:    null,
            receiverCountry: null,
            receiverContactName: null,
        };
    }

    nullSubProject() {
        return {
            receiverSubProjectId: null,
            receiverSubProjectName: null,
            receiverSubProjectCode: null,
        }
    }

    setReceiverStorage( selected )
    {
        if( selected )
        {
            this.props.onChange({
                receiverTitle:   selected.name,
                receiverId:      selected.id,
                receiverName:    selected.delivery_name,
                receiverAddress: selected.delivery_address,
                receiverZipcode: selected.delivery_zipcode,
                receiverCity:    selected.delivery_city,
                receiverCountry: selected.delivery_country,
                receiverContactName: selected.delivery_contact,
                receiverCode: selected.code,
                receiverSubProjectId: null,
                receiverSubProjectName: null,
                receiverSubProjectCode: null,
            });
            if (selected.delivery_address == null && selected.delivery_name == null) { 
                this.setDefaultStorage(selected.name, selected.id);
            }
        }
        else
        {
            this.props.onChange( this.nullReceiver() );
        }
    }

    setReceiverStorage_project( selected, init = false )
    {
        this.props.onChange(this.nullSubProject());
        if (selected) {
            //console.log("selected", selected);
            let locId = selected && selected.storage_location_id;

            if (this.props.projectPurchases && init) {
                locId = this.props.receiverId;
            }

            this.props.onChange({
                receiverTitle: selected.name,
                receiverProjectId: selected.id,
                costCenter:selected.cost_center,
            });

            if (locId == null) {
                this.props.onChange({ receiverId: 'no_receiver' });
                return;
            }
            api({
                method: 'get',
                url: `storage/location/get/${locId}`,
            }).then((response) => {
                //console.log("selected response", response);
                if (response != 0) {
                    this.props.onChange({
                        receiverId:      locId,
                        receiverName:    response.delivery_name,
                        receiverAddress: response.delivery_address,
                        receiverZipcode: response.delivery_zipcode,
                        receiverCity:    response.delivery_city,
                        receiverCountry: response.delivery_country,
                        receiverContactName: response.delivery_contact,
                        receiverCode: response.code,   
                    });
                }
                if (response.delivery_address == null && response.delivery_name == null) { 
                    this.setDefaultStorage(selected.name, selected.id);
                }
            }).catch(( error ) => {
                console.log(`Ongelma tietojen haussa ${error}`);
            });
        } else {
            this.props.onChange( this.nullReceiver() );
        }
    }

    setSubProject(selected) {
        if (selected) {
            this.props.onChange({
                receiverSubProjectId: selected.id,
                receiverSubProjectName: selected.name,
                receiverSubProjectCode: selected.project_code,
            })
        } else {
            this.props.onChange( this.nullSubProject() );
        }
    }

    setDefaultStorage(title, locId) {
        if (this.props.receiverType !== 'location') {
            api({
                method: 'get',
                url: `crm/address/findByLoc/${locId}`,
            }).then(( response ) => {
                if (response != 0) {
                    this.props.onChange({
                        receiverTitle:   title,
                        receiverName: response.name,
                        receiverAddress: response.street,
                        receiverZipcode: response.postal_code,
                        receiverCity: response.city,
                        receiverCountry: response.country,
                        receiverContactName: null,
                    })
                }
            }).catch(( error ) => {
                console.log(`Ongelma asiakkaan oletustietojen haussa ${error}`);
            });
        }
    } 
    

    setReceiverProject( selected )
    {
        if( selected )
        {
            this.props.onChange({
                receiverTitle:   selected.name,
                receiverId:      selected.id,
            });
        }
        else
        {
            this.props.onChange( this.nullReceiver() );
        }
    }

    setReceiverCompany( selected )
    {
        if( selected )
        {
            this.props.onChange({
                receiverTitle:   selected.name,
                receiverId:      selected.id,
                receiverName:    selected.name,
                receiverAddress: selected.address_supply_street,
                receiverZipcode: selected.address_supply_postal_code,
                receiverCity:    selected.address_supply_city,
                receiverCountry: selected.address_supply_country,
                receiverContactName: null,
            });
        }
        else
        {
            this.props.onChange( this.nullReceiver() );
        }
    }

    renderReceiverType()
    {
        return <ApInputStack gap="0">
            <ApAddon
                noRightBorder width="200px"
            >
                { tr('recipient') } *
            </ApAddon>
            <ApInput
                type="select"
                options={ [
                    { value: null,      label: '' },
                    { value: 'location', label: tr('storage') },
                    { value: 'project', label: tr('project') },
                ] }
                id="receiverType"
                name="receiverType"
                value={ this.props.receiverType }
                onChange={ this.handleReceiverTypeChange }
                validationState={ this.props.validator.getState('receiverType') }
            />
        </ApInputStack>
    }

    renderReceiverSelect()
    {
        if( this.props.receiverType === 'location' )
        {
            return <ApSelect
                label={ `${tr('recipient_storage')} *` }
                value={ this.props.receiverTitle ? this.props.receiverTitle : '' }
                optionRenderer="storage_location_address"
                onChange={ this.setReceiverStorage }
                objKeyId="id"
                clearable
                apiUrl="storage/locations/find"
                apiData={{ 
                    canReceive: true,
                    projectStorage: false,
                }}
                validationState={ this.props.validator.getState('receiverId') }
            />
        }
        else if( this.props.receiverType === 'project' )
        {
            return <div>
                <ApSelect
                label={ `${tr('recipient_project')} *` }
                loading={ this.state.loading }
                value={ this.props.receiverTitle ? this.props.receiverTitle : '' }
                optionRenderer="project"
                onChange={ this.setReceiverStorage_project }
                //objKeyId="id"
                clearable
                apiUrl="report/projects/find" 
                apiData={{
                	formatter: 'select',
                	withoutOffers : 'true',
                    hasStorageLocation: this.props.storageRequired?this.props.storageRequired:'true',
                	// onlyRoot : this.props.storageRequired,
                    withoutClosed: true
                }}
                validationState={ this.props.validator.getState('receiverId') }
            />           
            </div>
            
        }
        return null;
    }
    

    renderFields()
    {
        return <div>

            

            <ApInput
                type="text"
                id="receiverName"
                name="receiverName"
                label={`${tr('recipient')} ${this.props.detailsRequired ? '*' : ''}`}
                autoComplete="off"
                onChange={ this.handleChange }
                value={ this.props.receiverName }
                validationState={ this.props.validator.getState('receiverName') }
            />

            <div className="receiver-indent">
                <ApInput
                    type="text"
                    id="receiverAddress"
                    name="receiverAddress"
                    label={`${tr('address')} ${this.props.detailsRequired ? '*' : ''}`}
                    autoComplete="off"
                    onChange={ this.handleChange }
                    value={ this.props.receiverAddress }
                    validationState={ this.props.validator.getState('receiverAddress') }
                />

                <div className="col-container">
                    <div className="col-left">
                        <ApInput
                            type="text"
                            id="receiverZipcode"
                            name="receiverZipcode"
                            label={`${tr('postal_code')} ${this.props.detailsRequired ? '*' : ''}`}
                            autoComplete="off"
                            onChange={ this.handleChange }
                            value={ this.props.receiverZipcode }
                            validationState={ this.props.validator.getState('receiverZipcode') }
                        />
                    </div>
                    <div className="col-right">
                        <ApInput
                            type="text"
                            id="receiverCity"
                            name="receiverCity"
                            label={`${tr('post_office')} ${this.props.detailsRequired ? '*' : ''}`}
                            autoComplete="off"
                            onChange={ this.handleChange }
                            value={ this.props.receiverCity }
                            validationState={ this.props.validator.getState('receiverCity') }
                        />
                    </div>
                </div>
                <div className="col-container">
                    <div className="col-left">
                        <ApInput
                            type="text"
                            id="receiverCountry"
                            name="receiverCountry"
                            label={tr('country')}
                            autoComplete="off"
                            onChange={this.handleChange}
                            value={this.props.receiverCountry}
                        />
                    </div>
                    {this.props.saveAddressWhenSave != null &&
                    <div className="col-right">
                        <label htmlFor="saveAddressWhenSave-switch" className="info">
                            {tr("save_address")}

                        </label>
                        <ApSwitch
                            id="saveAddressWhenSave-switch"
                            name="saveAddressWhenSave"
                            label={tr('country')}
                            onChange={(e) => {
                                this.props.onChange({ saveAddressWhenSave: !this.props.saveAddressWhenSave });
                            }}
                            on={this.props.saveAddressWhenSave}
                            inline
                        />
                        
                    </div>}
                </div>
                
                
                <ApInput
                    type="text"
                    id="receiverContactName"
                    name="receiverContactName"
                    label={ tr('contact_person') }
                    autoComplete="off"
                    onChange={ this.handleChange }
                    value={ this.props.receiverContactName }
                />
                
            </div>
        </div>
    }

    render()
    {
        let receiverType    = this.renderReceiverType();
        let receiverSelect  = this.renderReceiverSelect();

        let receiverFields = null;
        if( this.props.receiverId || this.props.receiverType === 'other' )
           receiverFields = this.renderFields();

        return <div id="receiverDataSelect">

            { receiverType }
            { receiverSelect }
            { receiverFields }
        </div>
    }
}

export default ReceiverDataSelect;
