import React, { useState, useEffect } from 'react'
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs'
import Collect from './Collect'
import CollectionsList from './CollectionsList'
import { tr }  from 'services/Helpers/Helpers.js'

const Collections = () => {
    const [collectionId, setCollectionId] = useState(null)
    const [refetchCollectionList, setRefetchCollectionList] = useState(false)
    const [forceTabChangeIndex, setForceTabChangeIndex] = useState(null)

    useEffect(() => {
        setForceTabChangeIndex(0)
    }, [collectionId])

    return (
        <div id='pageCheckout'>
            <div className="apBox">
                <div className="apBoxHeader">
                    <h1>{ tr('collection') }</h1>
                </div>
                <ApTabs 
                    parentTabControl={forceTabChangeIndex} 
                    clearParentTabControl={() => setForceTabChangeIndex(null)}>
                    <ApTab key='collections-1' label={ tr('collection') }>
                        <Collect collection={collectionId} 
                            refresh={refetchCollectionList}
                            clearCollection={() => setCollectionId(null)} 
                            refreshList={setRefetchCollectionList}/>
                    </ApTab>
                    <ApTab key='collections-2' label={ tr('saved_collections') }>
                        <CollectionsList type='checkout' 
                            selectCollection={setCollectionId}
                            refetch={refetchCollectionList}/>
                    </ApTab>
                </ApTabs>
            </div>
        </div>
    )
}

export default Collections