import React from 'react';
import autoBind from 'react-autobind';

import api              from 'services/Api/Api.js';

import { errorPopper, tr }  from 'services/Helpers/Helpers.js'

import ApModal          from 'common/ApModal/ApModal.js';
import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import ApButton         from 'common/ApButton/ApButton.js';
import ApTooltip        from 'common/ApTooltip/ApTooltip.js';
import ApSelect         from 'common/ApSelect/ApSelect.js';
import ApDropdown       from 'common/ApDropdown/ApDropdown.js';
import ComponentEdit       from 'modules/Storage/Components/ComponentEdit/ComponentEdit.js';
import ComponentTooltip from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';

import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import LinkConfirm from './LinkConfirm/LinkConfirm.js';
import SaveConfirm from './SaveConfirm/SaveConfirm.js';
import './MassList.css';

// Exclude defines indexes that cannot appear at the same time
const headerOptions = {
    count:         { icon: 'asterisk',        label: tr('amount') },
    project_name:  { icon: 'sitemap',         label: tr('subproject_name') },
    project_code:  { icon: 'sitemap',         label: tr('subproject_code') },
    price:         { icon: 'money-bill',      label: tr('unit_price') },
    price_sell:    { icon: 'money-bill-wave', label: tr('unit_offer_price'), exclude: [ 'balance_percent' ] },
    balance_percent: { icon: 'percent',       label: tr('gross_profit_percent'),       exclude: [ 'price_sell' ] },
}

const typeIcons = {
    item: 'puzzle-piece',
    work: 'user-clock',
    other: 'money-bill-wave',
};

class MassList extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            rows: [],
            columnSettings: [],
            selectedProjectId: null,

            linkConfirmShow: false,
            saveConfirmShow: false,

            componentEditShow: false,
            componentEditRowIndex: null,
            componentEditId: null,
        }
        autoBind(this);
    }

    resetState()
    {
        let projectId = this.state.selectedProjectId;
        if( !projectId )
        {
            this.setState({
                selectedProjectId: this.props.projectList[ 0 ].id,
            })
        }
    }

    export()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'project/offers/masslist/xlsx',
            responseType: 'blob',
        }).then(( response ) => {
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${tr('mass_list')}_${tr('template')}.xlsx`); 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                loading: false,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('file_download_error'));
            this.setState({ loading: false });
        });
    }

    import()
    {
        this.setState({
            loading: true,
            rows: [],
        });

        let data = new FormData();
        data.append( 'file', this.state.selected_file );

        api({
            method: 'post',
            url: 'project/offers/masslist/xlsx',
            data: data,
        }).then(( response ) => {
            this.setState({
                loading: false,
                rows: response
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('file_read_error'));
            this.setState({
                loading: false,
            });
        });
    }

    handleComponentSelect( rowIndex, id )
    {
        if( !id )
        {
            let rows = this.state.rows.slice( 0 );
            rows[ rowIndex ].component = null;
            rows[ rowIndex ].outside   = null;
            this.setState({ rows: rows } );
            return null;
        }

        this.setState({ loading: true, });

        api({
            method: 'get',
            url: `project/offer/component/${ id }/format`,
        }).then(( response ) => {
            let rows = this.state.rows.slice( 0 );

            rows[ rowIndex ].component = response;
            rows[ rowIndex ].outside   = null;

            this.setState({
                loading: false,
                rows: rows,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('get_error') );
            this.setState({
                loading: false,
            });
        });

    }

    markRowAsOutsideStorage( rowIndex, cType )
    {
        let rows = this.state.rows.slice( 0 );
        rows[ rowIndex ].component = null;
        rows[ rowIndex ].outside   = cType;
        this.setState({ rows: rows } );
    }

    markUnlinkedAsOutsideStorage( cType )
    {
        let rows = this.state.rows.slice( 0 );
        rows = rows.map( row => {
            if( !row.component && !row.outside )
                row.outside = cType;
            return row;
        });
        this.setState({ rows: rows } );
    }

    setOtherColValue( value, rowIndex, colIndex )
    {
        window.emitter.emit('popper', {
            type: 'info',
            content: <div>
                { tr('make_corrections_to_excel_error') }
            </div>
        });

        /*
        let rows = this.state.rows.slice( 0 );
        rows = rows.map( ( row, ri ) => {
            if( rowIndex === ri )
            {
                row.other = row.other.map( ( col, ci ) => {
                    if( colIndex === ci )
                        return value;
                    return col;
                })
            }
            return row;
        });
        this.setState({ rows: rows } );
        */
    }

    linkClick()
    {
        this.setState({
            linkConfirmShow: true,
        });
    }

    linkConfirmClose( action, alsoLinkModified = false )
    {
        this.setState({ linkConfirmShow: false });
        if( action === 'yes' )
            this.link( alsoLinkModified );
    }

    link( alsoLinkModified = false )
    {
        let cancel = false;
        let links = {};

        let addToLinks = ( row, rNumber, modified = false ) => {
            let link = links[ row.name ];
            if( link && link.component_id !== row.component.id )
            {
                const previousNumber = link.rNumber;

                window.emitter.emit('popper', {
                    type: 'danger',
                    time: 5000,
                    content: <strong>{ tr('storage_components_link_error', [previousNumber, rNumber]) }</strong>,
                });
                cancel = true;
            }
            links[ row.name ] = { component_id: row.component.id, modified: modified, rNumber: rNumber };
        };

        this.state.rows.forEach( ( row, index ) => {
            if( row.component )
            {
                if( alsoLinkModified && row.linked && row.linked !== row.component.id )
                    addToLinks( row, index+1, true );
                else if( !row.linked )
                    addToLinks( row, index+1 );
            }
        });

        if( cancel ) return null;

        let data = [];
        for (var key in links )
        {
            data.push({
                component_id: links[ key ].component_id,
                value: key,
                modified: links[ key ].modified,
            })
        }

        this.setState({ loading: true });

        api({
            method: 'post',
            url: 'project/offers/masslist/link',
            data: { links :data },
        }).then(( response ) => {

            // is this a good thing?

            this.import();
            this.setState({ loading: false });
        }).catch( ( error ) => {
            errorPopper(error, tr('save_error') );
            this.setState({ loading: false });
        });
    }

    saveClicked()
    {
        this.setState({ saveConfirmShow: true });
    }

    saveConfirmClose( doSave = false )
    {
        this.setState({ saveConfirmShow: false });
        if( doSave )
            this.save();
    }

    save()
    {
        this.setState({ loading: true });

        let columnSettings = this.state.columnSettings;

        let countIndex        = columnSettings.findIndex( value => value === 'count' );
        let projectCodeIndex  = columnSettings.findIndex( value => value === 'project_code' );
        let projectNameIndex  = columnSettings.findIndex( value => value === 'project_name' );
        let priceIndex        = columnSettings.findIndex( value => value === 'price' );
        let priceSellIndex    = columnSettings.findIndex( value => value === 'price_sell' );
        let balancePercentIndex = columnSettings.findIndex( value => value === 'balance_percent' );

        let stop = false;

        const makeSubProjects = ( projectCodeIndex !== -1 || projectNameIndex !== -1 );
        let subProjects = [];
        let components = [];

        this.state.rows.forEach( ( row, rowIndex ) => {
            if( stop ) return null;

            let newComponent = {};

            if( row.component )
                newComponent.component = row.component;
            else if( row.outside )
            {
                newComponent.name = row.name;
                newComponent.type_id   = row.outside.id;
                newComponent.type_name = row.outside.name;
                newComponent.type_text = row.outside.text;
            }

            if( countIndex !== -1 )
            {
                let tmp = parseFloat( row.other[ countIndex ] );
                if( !isNaN( tmp ) )
                    newComponent.count = tmp;
            }

            if( priceIndex !== -1 )
            {
                let tmp = parseFloat( row.other[ priceIndex ] );
                if( !isNaN( tmp ) )
                    newComponent.price_single = tmp;
            }

            if( priceSellIndex !== -1 )
            {
                let tmp = parseFloat( row.other[ priceSellIndex ] );
                if( !isNaN( tmp ) )
                    newComponent.price_sell_single = tmp;
            }

            if( balancePercentIndex !== -1 )
            {
                let tmp = parseFloat( row.other[ balancePercentIndex ] );

                if( tmp >= 100 ) tmp = 99.9;
                if( tmp < 0) tmp = 0.0;

                if( !isNaN( tmp ) )
                    newComponent.profit_percent = tmp;
            }

            // Divide components to subprojects
            if( makeSubProjects )
            {
                let projectIndex = null;
                let code = `${subProjects.length  + 1}`;
                let name = `${tr('subproject')} ${ subProjects.length + 1 }`;

                if( projectCodeIndex !== -1 )
                {
                    code = row.other[projectCodeIndex];
                    projectIndex = subProjects.findIndex( sp => sp.code == code );
                }
                if( projectNameIndex !== -1 )
                {
                    name = row.other[ projectNameIndex ];
                    if( projectCodeIndex === -1 )
                        projectIndex = subProjects.findIndex( sp => sp.name == name );
                }

                if( projectIndex === -1 )
                {
                    let project = {
                        code: code,
                        name: name,
                        components: [ newComponent ],
                    };
                    subProjects.push( project );
                }
                else
                {
                    if( projectNameIndex !== -1 && projectCodeIndex !== -1 )
                    {
                        if( name !== subProjects[ projectIndex ].name || code !== subProjects[ projectIndex ].code )
                        {
                            stop = true;
                            window.emitter.emit('popper', {
                                type: 'danger',
                                time: 5000,
                                content: <strong>{ tr('subproject_match_error', [rowIndex + 1]) }</strong>
                            });
                        }
                    }
                    subProjects[ projectIndex ][ 'components' ].push( newComponent );
                }
            }
            else
            {
                components.push( newComponent );
            }
        })

        this.setState({ loading: false });

        if( stop )
        {
            this.setState({ loading: false });
            return null;
        }


        let data = {
            projectId: this.state.selectedProjectId,
            components: components,
            subProjects: subProjects,
        }

        this.props.onSave( data );
        this.setState({
            loading: false,
            rows: [],
            columnSettings: [],
        });
    }

    openComponentEdit( rowIndex, id)
    {
        this.setState({
            componentEditShow: true,
            componentEditRowIndex: rowIndex,
            componentEditId: id,
        });
    }

    onComponentEditSave( component )
    {
        const rowIndex = this.state.componentEditRowIndex;

        this.setState({ loading: true });

        api({
            method: 'get',
            url: `project/offers/component/${ component.id }/format`,
        }).then(( response ) => {
            let rows = this.state.rows.slice( 0 );
            rows = rows.map( ( row, ri ) => {
                if( row.component && row.component.id === response.id )
                    row.component = response;

                if( rowIndex === ri )
                    row.component = response;
                return row;
            });

            this.setState({
                loading: false,
                rows: rows,
            });
        }).catch( ( error ) => {
            errorPopper(error, tr('get_error') );
            this.setState({
                loading: false,
            });
        });

        this.setState({
            componentEditShow: false,
            componentEditRowIndex: null,
            componentEditId: null,
        });

    }

    getHeaderOptions( index )
    {
        let setColumn = ( value ) => {
            let columnSettings = this.state.columnSettings.slice( 0 );
            columnSettings[ index ] = value;
            this.setState({ columnSettings: columnSettings });
        };

        let options = [{
            label: tr('not_used'),
            action: ( id, closeFunc ) => {
                setColumn ( null );
                closeFunc();
            },
        }];

        for ( let key in headerOptions )
        {
            let excludes = [ key ];

            const otherExclude = headerOptions[ key ].exclude;
            if( otherExclude )
                excludes = [ ...excludes, ...otherExclude ];

            let skip = excludes.some( e => {
                return this.state.columnSettings.includes( e );
            });

            if( skip ) continue;

            options.push( {
                label: headerOptions[ key ].label,
                icon:  headerOptions[ key ].icon,
                action: ( id, closeFunc ) => {
                    setColumn ( key );
                    closeFunc();
                },
            });
        }
        return options;
    }

    renderHeaderSelected( index )
    {
        let columnSetting = this.state.columnSettings[ index ];

        if( columnSetting )
            return <div className="headerSelected">
                <SvgIcon icon={ headerOptions[ columnSetting ].icon } type="solid" />
                <span className="headerSelectedText">{ headerOptions[ columnSetting ].label }</span>
            </div>

        return <div>{ tr('not_used') }</div>
    }

    renderHeader( otherColumnCount = 0 )
    {
        let otherColumns = [];
        for ( let i = 0; i < otherColumnCount; i++)
        {
            let title = this.renderHeaderSelected( i );
            otherColumns.push( <th key={ i } className="fixed">
                <ApDropdown
                    fullWidth
                    button={ <div className="input-menu"> { title } <div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
                    actions={ this.getHeaderOptions( i ) }
                />
            </th>);
        }

        return <thead>
            <tr>
                <th>#</th>
                <th>{ tr('status') }</th>
                <th colSpan="2">{ tr('storage_component') }</th>
                { otherColumns }
            </tr>
        </thead>
    }

    renderLinkStatus( row, rowIndex )
    {
        let tooltip = null;
        let title = tr('link_missing');
        let color = 'error';
        if( row.component )
        {
            if( row.linked === row.component.id )
            {
                title = tr('link_exists');
                color = 'success';
            }
            else if( row.linked )
            {
                tooltip = tr('different_storage_component_info');
                title = tr('different_storage_component');
                color = 'warning';
            }
            else
            {
                title = tr('new_link');
                color = 'info';
            }
        }
        else if( row.outside )
        {
            tooltip = tr('external_storage_component_info');
            title = tr('external');
            color = 'warning';

        }

        return <ApTooltip block text={ tooltip } position="topleft">
            <div className={`apStatusBox ${ color }`}> { title }</div>
        </ApTooltip>
    }

    renderComponentIcon( row, rowIndex )
    {
        let component = row.component;
        let outside = row.outside;

        let tooltip = <div>{ tr('storage_component_not_linked') }</div>;
        let icon = 'question';

        let menuActions = [];
        if( component )
        {
            tooltip =  <ComponentTooltip component={ component } /> 

            icon = typeIcons[ component.type_name ];

            if( row.linked && row.linked !== component.id )
            {
                menuActions.push({
                    label: tr('restore_previous_link'),
                    icon: 'link',
                    action: ( item, closeFunc ) => {
                        this.handleComponentSelect( rowIndex, row.linked );
                        closeFunc();
                    },
                });
            }

            menuActions.push({
                label: tr('edit_storage_component'),
                icon: 'edit',
                action: ( item, closeFunc ) => {
                    this.openComponentEdit( rowIndex, component.id );
                    closeFunc();
                },
            });
        }
        else if( outside )
        {
            icon = typeIcons[ outside.name ];
            tooltip = <div>{ tr('external_storage_component') }</div>

            if( row.linked )
            {
                menuActions.push({
                    label: tr('restore_previous_link'),
                    icon: 'link',
                    action: ( item, closeFunc ) => {
                        this.handleComponentSelect( rowIndex, row.linked );
                        closeFunc();
                    },
                });
            }

            menuActions.push({
                label: tr('mark_as_unlinked'),
                icon: 'unlink',
                action: ( item, closeFunc ) => {
                    this.handleComponentSelect( rowIndex, null );
                    closeFunc();
                },
            });

        }
        else
        {
            if( row.linked )
            {
                menuActions.push({
                    label: tr('restore_previous_link'),
                    icon: 'link',
                    action: ( item, closeFunc ) => {
                        this.handleComponentSelect( rowIndex, row.linked );
                        closeFunc();
                    },
                });
            }

            menuActions.push({ "divider": tr('external_storage_component_alt') });

            this.props.componentTypes.forEach( ct => {
                menuActions.push({
                    label: ct.text,
                    icon: typeIcons[ ct.name ],
                    action: ( item, closeFunc ) => {
                        this.markRowAsOutsideStorage( rowIndex, ct );
                        closeFunc();
                    },
                });
            });
        }

        return <ApTooltip block text={ tooltip } position="topleft">
            <ApDropdown
                position="right"
                actions={ menuActions }
                button={ <SvgIcon icon={ icon } type="solid" /> }
            />
        </ApTooltip>
    }

    renderComponentInput( row, rowIndex )
    {
        let component = row.component;

        // Label for input will be row name, if not connected
        let label = ( component ) ? null : row.name;

        return <ApSelect
            loading={ this.state.loading }

            label={ label }
            value={ component }
            onChange={ ( c ) => this.handleComponentSelect( rowIndex, c ? c.id : null ) }
            clearable
            // disabled={ row.linked }

            optionRenderer="component_detail"

            objKeyId="id"
            objKeyValue="name"
            apiUrl="storage/components"
            apiData={{
                formatter: 'masslist',
                include: [ 'identifiers' ],
                // status: [
                    // 2, // Active
                    // 5, // Temporary
                // ],

            }}
        />
    }

    renderOtherColumn( value, row, rowIndex, colIndex )
    {
        let selectedOption = this.state.columnSettings[ colIndex ];
        let label = ( selectedOption ) ? headerOptions[ selectedOption ].label : null;

        return <div>
            <ApInput
                type="text"
                id={`o-${ rowIndex }-${ colIndex }`}
                name={`o-${ rowIndex }-${ colIndex }`}
                label={ label }
                value={ value ? value : '' }
                onChange={ ( e ) => { this.setOtherColValue( e.target.value, rowIndex, colIndex ) } }
                disabled={ !selectedOption }
            />
        </div>
    }

    renderRow( row, rowIndex )
    {
        const status = this.renderLinkStatus( row, rowIndex );
        const relatedComponentInput = this.renderComponentInput( row, rowIndex );
        const relatedComponentMenu = this.renderComponentIcon( row, rowIndex );

        return <tr key={ rowIndex }>
            <td className="rowNumber">
                { rowIndex + 1 }.
            </td>
            <td className="status">
                { status }
            </td>
            <td className="relatedComponentMenu">
                { relatedComponentMenu }
            </td>
            <td className="relatedComponent">
                { relatedComponentInput }
            </td>
            { row.other.map( ( value, colIndex ) => (
                <td className="otherTd" key={ colIndex }>
                    { this.renderOtherColumn( value, row, rowIndex, colIndex ) }
                </td>
            ))}
        </tr>
    }

    renderTable()
    {
        let rows = this.state.rows;
        if( rows.length === 0) return null;

        let headerDom = this.renderHeader( rows[ 0 ].other.length );
        let rowDoms = rows.map( ( r, i ) => this.renderRow( r, i ))

        return <table className="componentsTable">
            { headerDom }
            <tbody>
                { rowDoms }
            </tbody>
        </table>
    }

    renderProjectSelect()
    {
        let rows = this.state.rows;
        if( rows.length === 0) return null;

        return <div className="projectSelectContainer">
            <ApInputStack gap="0">
                <ApAddon noRightBorder width="200px">
                    { tr('add_to_project') }
                </ApAddon>
                <ApInput
                    type="select"
                    options={ this.props.projectList.map( p => {
                        return {value: p.id, label: `${p.code} ${p.name}`}
                    }) }
                    id="project_select"
                    name="project_select"
                    value={ this.state.selectedProjectId }
                    onChange={ (e) => { this.setState({ selectedProjectId: e.target.value }) } }
                />
            </ApInputStack>
        </div>
    }

    renderFileSelect()
    {
        return <div className="fileSelectContainer">
            <ApInputStack gap="0">
                <ApAddon custom width="250px">
                    <ApButton style={{ margin: "5px" }} size="small" color="blue" onClick={ this.export }>
                        <SvgIcon icon="download" type="solid" />
                        { tr('download_excel_template') }
                    </ApButton>
                </ApAddon>

                <ApAddon width="300px" noRightBorder>
                    { tr('select_excel_file') }
                </ApAddon>
                <ApInput
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    id="selected_file"
                    name="selected_file"
                    label={ `${tr('select_excel_file')}...` }
                    value={ this.state.selected_file }
                    onChange={ ( file ) => { this.setState({ selected_file: file }) } }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
                <ApAddon noLeftBorder width="150px">
                    <ApTooltip block text={ tr('read_data_excel') } position="bottom">
                        <div className="apSimpleButton" onClick={ this.import }>
                            { tr('read_file') }
                        </div>
                    </ApTooltip>
                </ApAddon>
            </ApInputStack>
        </div>
    }

    renderBody()
    {
        let fileSelect    = this.renderFileSelect();
        let projectSelect = this.renderProjectSelect()
        let table         = this.renderTable();

        return <div className="padding">
            { fileSelect }
            { projectSelect }
            { table }
        </div>
    }

    countLinks()
    {
        let total    = this.state.rows.length;
        let missing  = 0;
        let created  = 0;
        let modified = 0;
        let outside  = 0;


        this.state.rows.forEach( ( row ) => {
            if( row.component )
            {
                if( row.linked && row.linked !== row.component.id )
                    modified++;
                else if( !row.linked )
                    created++;
            }
            else if( row.outside )
                outside++;
            else
                missing++;
        });

        return {
            total: total,
            missing: missing,
            created: created,
            modified: modified,
            outside: outside,
        }
    }

    renderFooterInfo( counts )
    {
        return <div className="footerInfo">
            <table>
                <tbody>
                    <tr>
                        <td className="footerInfoTitle"></td>
                        <td className="footerInfoValue"></td>

                        <td className="footerInfoTitle">{ tr('components_count') }:</td>
                        <td className="footerInfoValue">{ counts.total }</td>

                        <td className="footerInfoTitle">{ tr('unlinked_count') }:</td>
                        <td className="footerInfoValue red">{ counts.missing }</td>
                    </tr>
                    <tr>
                        <td className="footerInfoTitle">{ tr('external_components_count') }:</td>
                        <td className="footerInfoValue orange">{ counts.outside }</td>

                        <td className="footerInfoTitle">{ tr('changed_links_count') }:</td>
                        <td className="footerInfoValue orange">{ counts.modified }</td>

                        <td className="footerInfoTitle">{ tr('new_links_count') }:</td>
                        <td className="footerInfoValue blue">{ counts.created }</td>
                    </tr>
                </tbody>
            </table>
        </div>
    }

    render()
    {
        const counts = this.countLinks();

        let footerRight =  null;
        if( counts.total > 0 )
        {
            let menuActions = [];

            const linkingDisabled = ( this.state.loading || ( counts.created === 0 && counts.modified === 0 ) );

            menuActions.push({
                label: tr('connect'),
                icon: 'link',
                disabled: linkingDisabled,
                action: ( item, closeFunc ) => {
                    this.linkClick();
                    closeFunc();
                },
            });

            if( counts.missing )
            {
                menuActions.push({ "divider": tr('mark_unlinked_ones') });
                this.props.componentTypes.forEach( ct => {
                    menuActions.push({
                        label: `${tr('external_storage_component_alt')} ${ ct.text.toLowerCase() }`,
                        icon: typeIcons[ ct.name ],
                        disabled: this.state.loading,
                        action: ( item, closeFunc ) => {
                            this.markUnlinkedAsOutsideStorage( ct );
                            closeFunc();
                        },
                    });
                });
            }

            footerRight = <div className="right">
                { this.renderFooterInfo( counts ) }

                <ApButton color="white" onClick={ this.linkClick } disabled={ linkingDisabled }>
                    <SvgIcon icon="link" type="solid" />
                    { tr('connect') }
                </ApButton>

                <ApDropdown
                    position="top"
                    actions={ menuActions }
                    button={ <ApButton className="footerMenu" color="white">
                        <SvgIcon icon="ellipsis-h" type="solid" />
                    </ApButton> }
                />

                <ApButton color="green" onClick={ this.saveClicked } disabled={ this.state.loading || counts.missing > 0 }>
                    <SvgIcon icon="check" type="solid" />
                    { tr('continue') }
                </ApButton>
            </div>
        }

        return <div id="ProjectMassList">
            <ApModal
                show={ this.props.show }
                onOpen={ this.resetState }
                handleClose={ () => this.props.onClose( false) }
                header={
                    <div className="padding">
                        <h4>
                            { tr('reading_mass_list_to_offer') }
                        </h4>
                    </div>
                }
                body={
                    <div id="ProjectMassListBody">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="padding footer">
                        <div className="left">
                            <ApButton onClick={ () => this.props.onClose( false ) }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>
                        </div>
                        { footerRight }
                    </div>
                }
            />

            <LinkConfirm
                show={ this.state.linkConfirmShow }
                onClose={ this.linkConfirmClose }

                counts={ counts }
            />

            <SaveConfirm
                show={ this.state.saveConfirmShow }
                onClose={ this.saveConfirmClose }

                project={ this.props.projectList.find( p => p.id === this.state.selectedProjectId ) }
                hasUnLinked={ ( counts.created > 0 || counts.modified > 0 ) }
                noCount={ !Boolean( this.state.columnSettings.find( v => v === 'count') ) }
            />

            <ComponentEdit
                id={   this.state.componentEditId }
                show={ this.state.componentEditShow }
                onClose={ () => { this.setState({ componentEditShow: false }) } }
                onSave={ this.onComponentEditSave }
            />

        </div>
    }

}

export default MassList;
