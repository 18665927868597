import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import ApTimeline from '../../../../common/ApTimeline/ApTimeline.js';
import ApModal            from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import api from 'services/Api/Api.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import { tr, currentLang } from 'services/Helpers/Helpers.js';
import EditScheduleModal from './EditScheduleModal.js';
import 'modules/WorkHours/Modal.css';

class UserHolidays extends React.Component {

	constructor( props ) 
	{
		super(props);
        this.state = {
            showAddScheduleModal: false,
            name: null,
            start_date: null,
            end_date: null,
            showScheduleEditModal: false,
            selectedSchedule: null,
            loading: false
        };

        this.validator = new ApValidate(this, {
            name: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
            start_date: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
            end_date: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
        });

        autoBind(this);
    }

    selectItem(item)
    {
        this.setState({ selectedSchedule: item.item_id, showScheduleEditModal: true });
    }

    renderApprovedSchedulesColumns()
	{
        return (
		[
			{
				Header: tr('name'),
                accessor: 'name',
                id: 'name'
			},
			{
				Header: tr('start_date'),
                accessor: 'start_date',
                id: 'start_date',
                Cell: (props) => {
					return (
						<div>
							{moment(props.original.start_date).format('DD.MM.YYYY')}
						</div>
					);
				}
			},
			{
				Header: tr('end_date'),
                accessor: 'end_date',
                id: 'end_date',
                Cell: (props) => {
					return (
						<div>
							{moment(props.original.end_date).format('DD.MM.YYYY')}
						</div>
					);
				}
            },
            {
				Header: tr('approved'),
                accessor: 'updated_at',
                id: 'updated_at',
                Cell: (props) => {
					return (
						<div>
							{moment(props.original.updated_at).locale(currentLang()).format('LLL')}
						</div>
					);
				}
            },
            {
                Header: tr('approver'),
                accessor: 'creator',
                id: 'creator',
                Cell: (props) => {
					return (
						<div>
							{`${props.original.creator.first_name} ${props.original.creator.last_name}`}
						</div>
					);
				}
			},
        ]
        );

    }

    renderChangesColumns()
	{
        return (
		[
			{
				Header: 'Muutos (pv)',
                accessor: 'change',
                id: 'change',
                Cell: (props) => {
                    return (
                        <div>
                            {props.original.change > 0 ? `+${props.original.change}` : props.original.change}
                        </div>
                    )
                }
			},
			{
				Header: tr('description'),
                accessor: 'description',
                id: 'description'
			},
			{
				Header: tr('date'),
                accessor: 'date',
                id: 'date',
                Cell: (props) => {
					return (
						<div>
							{moment(props.original.date).format('DD.MM.YYYY')}
						</div>
					);
				}
            },
            {
				Header: tr('responsible_person'),
                accessor: 'creator',
                id: 'creator',
                Cell: (props) => {
					return (
						<div>
							{`${props.original.creator.first_name} ${props.original.creator.last_name}`}
						</div>
					);
				}
			},
        ]
        );
    }

    cancelScheduleEdit()
    {
        this.setState({ showScheduleEditModal: false, selectedSchedule: null });
    }

    closeScheduleEdit()
    {
        this.props.getSettings();
        this.setState({ showScheduleEditModal: false, selectedSchedule: null });
    }

    renderContent()
    {
        if (!this.props.settings.current_balance && !this.props.loading)
        {
            return(
                <h4>{ tr('opening_balance_not_set') }</h4>
            )
        }
        else if (this.props.settings.current_balance && !this.props.loading)
        {
            return(
                <div>
                
                    <h4>{ tr('current_balance') }: {this.props.settings.current_balance.current_balance > 0 ? `+${this.props.settings.current_balance.current_balance}` : this.props.settings.current_balance.current_balance} pv</h4>
                    <strong>{ tr('updated_at') }: </strong>{moment(this.props.settings.current_balance.updated_at).locale(currentLang()).format('LLL')}

                    <br/><br/>

                    <h3>{ tr('balance_changes') }</h3>
                    { this.props.settings.changes && this.props.settings.changes.length > 0 ?
                    <ApReactTable
                        data={this.props.settings.changes}
                        columns={this.renderChangesColumns()}
                        defaultSorted={[ 
                            { id: "date", desc: true }
                        ]}
                        defaultPageSize={10}
                    />
                    :
                    <div>{ tr('no_balance_changes') }</div>
                    }

                    <br/>

                    <h3>{ tr('planned_holidays') }</h3>
                    <ApButton 
                        color='green'
                        onClick={() => this.setState({ showScheduleEditModal: true })}
                    >
                        { tr('add_plan') }
                    </ApButton>
                    {this.props.open_schedules && this.props.open_schedules.length > 0 ?
                    <ApTimeline
                        daysVisible={365}
                        padding={ 5 }
                        items={this.props.open_schedules}
                        holidayTimeline={true}
                        handleItemEdit={this.selectItem}
                    />
                    :
                    <div>{ tr('no_planned_holidays') }</div>
                    }

                    <br/>

                    <h3>{ tr('accepted_holidays') }</h3>
                    {this.props.settings.approved_schedules && this.props.settings.approved_schedules.length > 0 ?
                    <ApReactTable
                        data={this.props.settings.approved_schedules}
                        columns={this.renderApprovedSchedulesColumns()}
                        defaultSorted={[ 
                            { id: "updated_at", desc: true }
                        ]}
                        defaultPageSize={5}
                    />
                    :
                    <div>{ tr('no_accepted_holidays') }</div>
                    }

                </div>
            )
        } 
        else
        {
            return (
            <div className="padding">
                <div className="apLoader"></div>
            </div>
            )
        }
    }

    render() 
    {
        return (
            <div className="padding">

                { this.renderContent() }

                <div id="modal">
                <EditScheduleModal 
                    show={this.state.showScheduleEditModal}
                    onClose={this.closeScheduleEdit}
                    onCancel={this.cancelScheduleEdit}
					itemId={this.state.selectedSchedule}
                />
                </div>

            </div>
        );

        

    }
}
export default UserHolidays
