import React from 'react';
//import autoBind from 'react-autobind';
//import PropTypes from 'prop-types';
import auth from 'services/Authed/Authed.js';

import UserTable from 'modules/UserManagement/UserTable.js';
import './Users.css';
import { tr } from 'services/Helpers/Helpers.js';

class Users extends React.Component {

    render() 
    {
        return (
            <div className="apBox" id="pageUsers">
                <div className="apBoxHeader">
                    <h1>{ tr('personnel') }</h1>
                    <p>{ tr('personnel_management_info') }</p>
                </div>
                <div className="padding">

                    <UserTable
                        companyId={ auth.getCompanyID() }
                        companySuffix={ auth.getCompanySuffix() }
                    />

                </div>
            </div>
        );
    }
}

export default Users;