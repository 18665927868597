import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import './ApDropdown.css';

import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import SvgIcon from '../SvgIcon/SvgIcon.js';
import { Collapse } from 'react-bootstrap';

class ApDropdown extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            open: false,
            actions: null,
        }

        autoBind(this); 
    } 

    componentDidMount() {
        document.addEventListener('mousedown', this.clickOutsideHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutsideHandler);
    }

    clickOutsideHandler(event) {
        if( !this.node.contains(event.target) && this.state.open )
            this.close();
    }

    toggleDropDown()
    {
        if( this.props.disabled )
            return true;

        this.setState({ open: !this.state.open });
    }

    close()
    {
        this.setState( { open: false });
    }

    renderActions()
    {
        if( !this.props.actions ) 
            return null;

        let actions = [];

        this.props.actions.forEach( (action, index ) => {
            if( action.action && action.label ) 
            {
                let onClick = () => { 
                    action.action( this.props.actionId, this.close )
                    if( action.closeAfter )
                        this.close();
                };
                let classes = [ "action" ];
                
                if( ( typeof( action.disabled ) === "function" && action.disabled( this.props.actionId ) ) || 
                    ( typeof( action.disabled ) != "function" && action.disabled ) )
                {
                    classes.push( "disabled" );
                    onClick = null;
                }

                if( action.className )
                    classes.push( " " + action.className );

                let tooltipPosition = action.tooltipPosition || 'left';

                actions.push( 
                    <div key={index} className={ classes.join(" ") } onClick={ onClick }>
                        { action.icon && <SvgIcon icon={ typeof( action.icon ) === "function" ? action.icon( this.props.actionId ) : action.icon } type="solid" /> }
                        <ApTooltip text={ action.tooltip } position={ tooltipPosition }>
                            <span>{ typeof( action.label ) === "function" ? action.label( this.props.actionId ) : action.label }</span>
                        </ApTooltip>
                    </div>
                );
            }
            else if ( action.divider ) 
            {
                actions.push(
                    <div key={index} className={ "divider" + ( action.divider.length ? " withLabel" : "" ) }>
                        { action.divider.length > 0 &&
                            <span>{ action.divider }</span>
                        }
                    </div>
                )
            }
        });

        if (this.props.saveActions && !this.state.actions) {
            this.setState( { actions: actions });
        }

        return actions;
    }   

    render()
    {
        let classes = ["apDropdown"];
        if( this.state.open ) classes.push("open");
        if( this.props.fullWidth ) classes.push("fullWidth");

        let menuClasses = [ "apDropdownMenu" ];
        let buttonClasses = [ "apDropdownButton"];
        let timeOut = 400; //Default arvo

        const pos = this.props.position;
        if (pos=='up') {
            menuClasses.push( "openTop" )
        }
        if( pos === 'right' || pos === 'topright' )
            menuClasses.push( "openRight" )
        if( pos === 'top' || pos === 'topright')
            menuClasses.push( "openTop" )
        if (this.props.dropdownPos == 'relative') {
            menuClasses.push('relativePositioning');
            buttonClasses.push('widthSet');
            timeOut = 50;
        }

        if( this.props.disabled )
            classes.push( "disabled" );

        const dropDownButton = <div className={ buttonClasses.join(" ")} onClick={ this.toggleDropDown }>
            { this.props.button && this.props.button }
            { !this.props.button && 
                <div className="defaultButton">
                    <SvgIcon icon="ellipsis-h" type="solid" /> 
                </div>
            }
        </div>


        const dropDownElements = <div className={ menuClasses.join(" ") }>
            <Collapse in={ this.state.open } timeout={timeOut}>
            <div>
                { this.state.actions ? this.state.actions : this.renderActions() }

                { this.props.passOnCloseFunc && this.props.children( this.close ) }
                { !this.props.passOnCloseFunc && this.props.children }
            </div>
            </Collapse>
        </div>

        return (
        	<div className={ classes.join(" ") } ref={node => this.node = node}>
                {dropDownButton}
                {dropDownElements}
        	</div>
        );
    }
};


ApDropdown.propTypes = {
    button:             PropTypes.oneOfType([ PropTypes.node, PropTypes.element ]), 
    passOnCloseFunc:    PropTypes.bool,
    actions:            PropTypes.array,
    actionId:           PropTypes.any,
    disabled:           PropTypes.bool,
    fullWidth:          PropTypes.bool,
    position:           PropTypes.string,
    saveActions:        PropTypes.bool, // Save actions to state to avoid re-rendering
};

export default ApDropdown;

