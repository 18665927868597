import React from 'react';
import autoBind from 'react-autobind';

import './Login.css';
import config from 'env.js';
import auth from 'services/Authed/Authed.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import { keyExists, tr } from 'services/Helpers/Helpers.js';
import LangMenu from 'layout/Layouts/IntraLayout/NavTop/LangMenu/LangMenu.js';
import {
    FormGroup,
    FormControl
} from 'react-bootstrap';


// Get test users from .env.js file
const testUsers = config.api.users;
const isDev = config.dev;

class Login extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            username: '',
            password: '',
            loading: false,
            errorMsg: false,
            lang: null,
            resetMode: false,
            reseted: false,
            langOpen: false
        };
        autoBind(this);
    }

    componentDidMount()
    {
        this.getLanguage();
    }

    handleChange(e)
    {
        this.setState({ [e.target.name]: e.target.value, errorMsg: false });
    }

    handleLogin()
    {
        if( this.state.username.length > 0 && this.state.password.length > 0 ) {

            this.setState({
                errorMsg: false,
                loading: true,
                reseted: false,
                resetMode: false
            });

            auth.login(
                this.state.username,
                this.state.password
            ).then((response) => {
                 
                auth.reloadSettings( () => {
                    // Use location replace because if we use history.push the "would you like to save the password" popup keeps popping up on every page redirect
                    // this.props.history.push('/')
                    window.location.replace('/');
                });

            }).catch((error) => {
                this.setState({
                    errorMsg: tr('login_failed'),
                    loading: false,
                });
            });
        }
    }

    handleLoginTestUser(e)
    {
        let user = testUsers[e.target.value];
        // Notice this will call handle login only after setStates are done
        this.setState({
            username:    user['username'],
            password: user['password'],
        }, this.handleLogin);
    }

    handleAlertDismiss()
    {
        this.setState({errorMsg: false});
    }

    handleKeyPress(e)
    {
        if(e.key === 'Enter'){
            if( !this.state.resetMode ) {
                this.handleLogin();
            }
            else {
                this.resetPassword();
            }
        }
    }

    changeLanguage( newLang )
    {
        window.sessionStorage.setItem("language", newLang );
        this.getLanguage();
    }

    getLanguage()
    {
        let lang = window.sessionStorage.getItem("language");
        if( !lang ) lang = "fi";
        this.setState({ lang });
    }

    toggleResetMode()
    {
        this.setState({ resetMode: !this.state.resetMode });
    }

    resetPassword()
    {
        if( this.state.username.length > 0 ) {

            this.setState({
                reseted: false,
                errorMsg: false,
                loading: true,
            });

            auth.resetPassword( this.state.username ).then((response) => {
                console.log( response );
                this.setState({
                    reseted: true,
                    loading: false,
                    resetMode: false,
                });
            }).catch((error) => {
                let message = tr('forgot_password_failed')
                if( keyExists( error, 'response.data.message' ) && error.response.data.message )
                    message = error.response.data.message;
                this.setState({
                    errorMsg: message,
                    loading: false,
                    resetMode: false,
                });
            });
        }
    }

    toggleModal( block ) {
        switch( block ) {
            case "language":
                this.setState(prevState => ({
					langOpen: !prevState.langOpen
				}));
                break;

            default:
                break;
        }
    }

    render()
    {

        let buttonDisabled = false;
        if( this.state.loading ) buttonDisabled = true;
        if( this.state.username.length === 0 ) buttonDisabled = true;
        if( !this.state.resetMode && this.state.password.length === 0 ) buttonDisabled = true;

        return (
            <div id="login">

                <div id="navTop">
					<div className="blocks" ref={node => this.node = node}>
					<LangMenu open={ this.state.langOpen } toggleModal={ this.toggleModal } />
					</div>
				</div>

                <div className="login-box">

                    <div className="padding">
                        <h3 className="header">
                            <SvgIcon icon="lock" type="solid" /> { tr('login_title') }
                        </h3>


                         <ApInputStack gap="0">
                            <ApAddon
                                noRightBorder 
                                width="48px" 
                                labelFor="username">
                                <SvgIcon icon="user" type="solid" />
                            </ApAddon>
                            <ApInput 
                                type="text" 
                                id="username" 
                                name="username" 
                                label={ tr('username') }
                                onChange={this.handleChange}
                                onKeyPress={this.handleKeyPress}
                                value={this.state.username}
                                error={ this.state.errorMsg ? true : false }
                            />
                        </ApInputStack>

                        { !this.state.resetMode &&
                            <div>

                                <ApInputStack gap="0">
                                    <ApAddon
                                        noRightBorder 
                                        width="48px" 
                                        labelFor="password">
                                        <SvgIcon icon="key" type="solid" />
                                    </ApAddon>
                                    <ApInput 
                                        type="password" 
                                        id="password" 
                                        name="password" 
                                        label={ tr('password') }
                                        onChange={this.handleChange}
                                        onKeyPress={this.handleKeyPress}
                                        value={this.state.password}
                                        error={ this.state.errorMsg ? true : false }
                                    />
                                </ApInputStack>

                                <p className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    { tr('login_note') }
                                </p>

                                {this.state.reseted &&
                                    <div className="apSuccessMsg">{ tr('forgot_password_success') }</div>
                                }
                                {this.state.errorMsg &&
                                    <div className="apErrorMsg">{this.state.errorMsg}</div>
                                }
                                <ApButton
                                    fullWidth
                                    color="blue"
                                    disabled={ buttonDisabled }
                                    loading={ this.state.loading }
                                    onClick={ this.handleLogin }
                                >
                                    <SvgIcon icon="key" type="solid" />
                                    <span> { tr('login') } </span>
                                </ApButton>

                                <ApButton ghost size="small" fullWidth onClick={this.toggleResetMode}>{ tr('forgot_password') }</ApButton>
                            </div>
                        }
                        { this.state.resetMode &&
                            <div>
                                <p className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    { tr('forgot_password_note') }
                                </p>
                                <ApButton
                                    fullWidth
                                    color="green"
                                    disabled={ buttonDisabled }
                                    loading={ this.state.loading }
                                    onClick={ this.resetPassword }
                                >
                                    <SvgIcon icon="paper-plane" type="solid" />
                                    <span> { tr('send') } </span>
                                </ApButton>

                                <ApButton ghost size="small" fullWidth onClick={this.toggleResetMode}>{ tr('cancel') }</ApButton>
                            </div>
                        }

                    </div>

                </div>

                <img className="bgLogo" src="/img/logo.svg" alt="EASY-PRO Intra" />

                <div className="footer">
                    Easy-Pro - Copyright © { new Date().getFullYear() } <a href="https://www.amiproservice.fi/" target="_blank" rel="noopener noreferrer">AmiPro Service Oy</a>
                </div>

                { isDev && Array.isArray(testUsers) && (
                    <div className="devArea">
                        <SvgIcon icon="user-secret" type="solid" />
                        <FormGroup controlId="formControlsSelect" onChange={this.handleLoginTestUser} className="testuser-login">
                            <FormControl as="select" defaultValue="select">
                                <option value="select" disabled>Valitse testikäyttäjä</option>
                                {testUsers.map((user, index) => (
                                <option key={index} value={index}>{user.comment}</option>
                                ))}
                            </FormControl>
                        </FormGroup>
                    </div>
                )}
            </div>
        );
    }
}

export default Login;
