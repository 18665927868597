import ApButton from 'common/ApButton/ApButton';
import { ApInput } from 'common/ApInput/ApInput';
import ApModal from 'common/ApModal/ApModal';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import React, { useEffect, useState } from 'react';
import { priceToProfitPercent, tr } from 'services/Helpers/Helpers';
import styles from './ProjectMeters.module.css';

const ProjectMeters = (props) => {
    const [showProjectMeterModal, setShowProjectMeterModal] = useState(false);
    const [target, setTarget] = useState('price_sell_all');
    const [value, setValue] = useState(0);
    const [unit, setUnit] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);

    const resetAndClose = () => {
        setTarget('price_sell_all');
        setValue(0);
        setUnit('');
        setSelectedItem(null);
        setShowProjectMeterModal(false);
    }

    const save = () => {
        props.handleProjectMeter({
            id: selectedItem ? selectedItem.id : null,
            index: selectedItem ? selectedItem.index : null,
            target,
            value,
            unit
        }, !!selectedItem)
        resetAndClose();
    }

    const deleteItem = index => {
        props.deleteProjectMeter(index);
        resetAndClose();
    }

    const getOptionValue = target => {
        const found = props.budgetDetailOptions.find( o => o.value === target );
        let value = null;
        if( found ) {
            if (found.value === "work_percent") {
                value = 100 -
                    priceToProfitPercent(
                        parseFloat(props.selectedProject.calculated.all.work.price_sell_all),
                        parseFloat(props.selectedProject.calculated.all.total.price_sell_all)
                    );
            } else if(found.value === "items_percent") {
                value = 100 -
                    priceToProfitPercent(
                        parseFloat(props.selectedProject.calculated.all.item.price_sell_all),
                        parseFloat(props.selectedProject.calculated.all.total.price_sell_all)
                    );
            } else {
                value = props.selectedProject.calculated && props.selectedProject.calculated.all.total[ found.value ];
            }
        }
        return parseFloat(value);
    }

    useEffect(() => {
        if (selectedItem && typeof selectedItem.index === 'number') {
            setTarget(selectedItem.target);
            setValue(selectedItem.value);
            setUnit(selectedItem.unit);
            setShowProjectMeterModal(true);
        }
    }, [selectedItem]);

    return (
        <div className={styles['project-meters-container']}>
            <h5>{tr('project_meters')}</h5>
            <table className="componentTable">
                <thead>
                    <tr className="componentHeader">
                        <th>{tr('target')}</th>
                        <th>{tr('comparison')}</th>
                        <th>{tr('value')}</th>
                        <th>{tr('edit')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.mainProject && props.mainProject.project_meters.map((meter, index) => {
                        const foundOption = props.budgetDetailOptions.find(item => item.value == meter.target)
                        const optionValue = getOptionValue(meter.target)
                        if (!foundOption) {
                            return <tr></tr>;
                        }
                        return (
                            <tr key={index}>
                                <td>{foundOption.label}<br/>{optionValue.toFixed(2)} {foundOption.unit}</td>
                                <td>{meter.value} {meter.unit}</td>
                                <td>{(optionValue/meter.value).toFixed(2)} {foundOption.unit}/{meter.unit}</td>
                                <td><SvgIcon type='solid' icon='edit' className='pointer' onClick={()=>setSelectedItem({...meter, index})} /></td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {/* Näytä nappi vain pääprojektin sivulla */}
            {
                !props.selectedProject.parent_id &&
                <ApButton size='small' color='green' onClick={() => setShowProjectMeterModal(true)} >{tr('new_project_meter')}</ApButton>
            }
            {
                props.selectedProject.parent_id &&
                <div>
                    <small><SvgIcon icon='info-circle' type='solid' className='size-small' /> {tr('project_meter_add_info')}</small>
                </div>
            }
            <ApModal
                show={showProjectMeterModal}
                className='narrow'
                handleClose={resetAndClose}
                closeFromBg
                header={<div className='padding'><h3>{tr('project_meter')}</h3></div>}
                body={
                    <div className='padding'>
                        <div>
                            <small><SvgIcon icon="info-circle" type="solid" className='size-small' /> {tr('comparison_project_statistic')}</small>
                        </div>
                        <ApInput
                            type='select'
                            options={props.budgetDetailOptions}
                            value={target}
                            onChange={(e) => setTarget(e.target.value)}
                        />
                        <div><small><SvgIcon icon="info-circle" type="solid" className='size-small' /> {tr('comparison_value_unit')}</small></div>
                        <ApInput
                            label={tr('value')}
                            type='number'
                            value={value}
                            onChange={e => setValue(e.target.value)}
                            validationState={!value ? 'error' : ''}
                        />
                        <ApInput
                            label={tr('unit')}
                            value={unit}
                            onChange={e => setUnit(e.target.value)}
                            validationState={!unit ? 'error' : ''}
                        />
                    </div>
                }
                footer={
                    <div className='padding' style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <ApButton onClick={resetAndClose}>{tr('cancel')}</ApButton>
                            {selectedItem && typeof selectedItem.index === 'number' && 
                                <ApButton
                                    onClick={() => deleteItem(selectedItem.index)}
                                    color='red'
                                >
                                    <SvgIcon type='solid' icon='trash' /> {tr('delete')}
                                </ApButton>
                            }
                        </div>
                        <ApButton 
                            onClick={save} 
                            color='green'
                            disabled={!unit || !value}
                        >
                            <SvgIcon type='solid' icon='check' /> {tr('continue')}
                        </ApButton>
                    </div>
                }
            />
        </div>
    )
}

export default ProjectMeters;