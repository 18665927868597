import React from 'react';
import autoBind from 'react-autobind';
import api from '../../services/Api/Api.js';
import UserTable from '../UserManagement/UserTable.js';
import { errorPopper } from '../../services/Helpers/Helpers.js';
import ModulesTree from '../../common/ModulesTree/ModulesTree.js';
import ApSwitch from '../../common/ApSwitch/ApSwitch.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';

import {
    Container, Row, Col,
    Modal,
    Button,
    FormGroup,
    FormLabel,
    FormControl,
    InputGroup,
} from 'react-bootstrap';

class CompanyEdit extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            originalData: {},
            is_active: true,
            name: '',
            manager: false,
            suffix: '',
            moduleIDs: [],
            showSuffixWarning: false,
            loading: false,
        }
        autoBind(this);
    }

    componentDidMount()
    {
        if(this.props.companyID)
            this.getCompanyInfo();
        else
            this.getModuleIds();
    }

    getCompanyInfo()
    {
        this.setState({loading: true});
        api({
            method: 'get',
            url: `management/company/get/${this.props.companyID}`,
        }).then((response) => {
            this.setState({
                originalData: response,
                is_active: response.is_active,
                name: response.name,
                manager: response.manager,
                suffix: (response.suffix) ? response.suffix : '',
                moduleIDs: response.module_ids,
                loading: false,
            });
        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Error getting company</strong>,
            });
            this.setState({loading: false});
        });
    }

    getModuleIds()
    {
        this.setState({loading: true});
        api({
            method: 'get',
            url: `management/modules/getids`,
        }).then((response) => {
            this.setState({
                moduleIDs: response,
                loading: false,
            });
        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Error getting moduleids</strong>,
            });
            this.setState({loading: false});
        });
    }

    saveCompany()
    {
        this.setState({loading: true});

        api({
            method: 'post',
            url: '/management/company/save',
            data: {
                id: this.props.companyID,
                is_active: this.state.is_active,
                name: this.state.name,
                suffix: this.state.suffix,
                module_ids: this.state.moduleIDs,
            }
        }).then((response) => {
            console.log('Response', response);
            // this.setState({loading: false});
            this.props.onClose();

        }).catch((error) => {
            errorPopper (error, 'Virhe yritystä tallentaessa');
            this.setState({loading: false});
            this.props.onClose();
        });

    }

    cancel()
    {
        this.props.onClose();
    }

    handleChange(e)
    {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleModuleChange(ids)
    {
        this.setState({
            moduleIDs: ids,
        });
    }

    render()
    {
        let body = <div>lataa</div>
        if(!this.state.loading)
        {
            body = <Container fluid={true}>
                <Row>
                    <Col sm={2}>
                        <FormGroup>
                            <FormLabel>Active</FormLabel>
                            <ApSwitch
                                id="is-company-active-switch"
                                on={this.state.is_active}
                                onChange={() => {this.setState({is_active: !this.state.is_active})}}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={4}>
                        <FormGroup>
                            <FormLabel> Name </FormLabel>
                            <FormControl
                                size="sm"
                                type="text"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}>
                            </FormControl>
                        </FormGroup>
                    </Col>
                    <Col sm={4}>
                        <FormGroup>
                            <FormLabel> User ID suffix </FormLabel>
                            <InputGroup>
                                <InputGroup.Text>@</InputGroup.Text>
                                <FormControl
                                    disabled={this.props.companyID ? (this.state.allowSuffixEdit?false:true) : false }
                                    size="sm"
                                    type="text"
                                    name="suffix"
                                    value={this.state.suffix}
                                    onChange={this.handleChange}>
                                </FormControl>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                        <FormLabel> Manager </FormLabel>
                        <ApSwitch
                            id="is-company-manager-switch"
                            on={this.state.manager}
                            disabled={true}
                        />
                    </Col>
                </Row>
                {Boolean(this.props.companyID) && <Row>
                    <Col sm={10}>
                        
                    </Col>
                    <Col sm={2}>
                        Muokkaa Suffixiä
                        <ApSwitch
                            id="allowSuffixSwitch"
                            on={this.state.allowSuffixEdit}
                            disabled={false}
                            onChange={() => {
                                if (this.state.allowSuffixEdit) {
                                    this.setState({ allowSuffixEdit :false});
                                }
                                else {
                                    
                                    this.setState({ showSuffixWarning: true });
                                }
                            }}
                        />
                    </Col>
                </Row>}
                {Boolean(this.props.companyID) && <Row>
                    <Col sm={12}>
                        <h4 className="text-primary">Käyttäjät</h4>
                        <UserTable
                            companyId={this.props.companyID}
                            companySuffix={this.state.originalData.suffix}
                            defaultPageSize={10}
                        />
                    </Col>
                </Row>}
                <Row>
                    <Col sm={12}>
                        <h4 className="text-primary">Moduulit <small style={{display: 'block'}}>Valitse moduulit jotka yritksellä on käytössä</small></h4>
                        <ModulesTree
                            id="company-module-tree"
                            forCompany={ true }
                            selectedIDs={this.state.moduleIDs}
                            onChange={this.handleModuleChange}
                        />
                    </Col>
                </Row>
            </Container>
        }

        return (
            <Modal size="lg" show={this.props.show} onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Company info </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {body}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.saveCompany}> Save </Button>
                </Modal.Footer>
                <ApConfirm
                    show={this.state.showSuffixWarning}
                    onClose={() => this.setState({ showSuffixWarning: false })}
                    onConfirm={()=>this.setState({allowSuffixEdit:true})}
                    header="Oletko varma?"
                    body={
                        <div>
                            <p>
                                <strong>Oletko varma että haluat sallia muokkaamisen</strong>
                            </p>
                            
                        </div>
                    }
                />
            </Modal>
        );
    }

}

export default CompanyEdit;
