/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
//import auth from 'services/Authed/Authed.js';

import './UserGroups.css';

import { keyExists, tr } from 'services/Helpers/Helpers.js';

import ApConfirm 	from 'common/ApConfirm/ApConfirm.js';
import SvgIcon 		from 'common/SvgIcon/SvgIcon.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApButton 	from 'common/ApButton/ApButton.js';
import ApSwitch     from 'common/ApSwitch/ApSwitch.js';
import ApModal      from 'common/ApModal/ApModal.js';
import ModulesTree  from 'common/ModulesTree/ModulesTree.js';
import { ApInput  } from 'common/ApInput/ApInput.js';
//import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';


class UserGroups extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
        	loading: false,
        	groups: [],
        	listPermissions: false,

        	modalOpen: false, // false = closed, number = edit, true = add new
        	modalLoading: false,
        	modalData: [],

        	maxMembers: 1,
        	filterModules: [],

			confirmDeleteIds: false,
			unsavedChanges:false,
        };
        autoBind(this); 
    }

    componentDidMount()
    {
    	this.getGroupList();
    }

    getGroupList()
    {
    	this.setState({ loading: true });
    	api({
            method: 'get',
            url: 'usermanagement/groups',
        }).then((response) => {
        	//console.log('fetchData', response );

        	let maxMembers = this.state.maxMembers;
        	let filterModules = [];
        	response.forEach( group => {

        		if( group.members_count > maxMembers )
        			maxMembers = group.members_count;

        		group.modules.forEach( module => {
        			if( filterModules.find( m => m.id === module.id ) === undefined )
        				filterModules.push( module );
        		});
        		
        	})

            this.setState({
              	groups: response,
              	maxMembers: maxMembers,
              	filterModules: filterModules,
                loading: false,
            });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    addGroup()
    {
    	this.setState({ modalOpen: true });
    }

    editGroup( id )
    {
    	this.setState({ modalOpen: id });
    }

    onModalOpen()
    {
    	if( typeof( this.state.modalOpen ) === "number" )
    	{
	    	this.setState({ modalLoading: true });
	    	api({
	            method: 'get',
	            url: 'usermanagement/group/' + this.state.modalOpen,
	        }).then((response) => {
	        	//console.log('group data', response );
	            this.setState({
	              	modalData: response,
					modalLoading: false,
					unsavedChanges:false,
	            });
	        }).catch((error) => {
	            this.setState({ modalLoading: false });
	        });
	    }
	    else 
	    {
			this.setState({
				modalData: {
					name: "",
					description: "",
					modules: [],
				},
				unsavedChanges:false,
			});
	    }
    }

    closeModal( save = false )
    {
    	if( save )
    	{
    		api({
				method: 'post',
				url: 'usermanagement/group/save',
				data: this.state.modalData
			}).then((response) => {

				this.setState({ modalOpen: false });
				this.getGroupList();
				
			}).catch((error) => {
				console.log(error);
			});
    	}
    	else 
    		this.setState({ modalOpen: false });
    }

    handleModalInputChange( e )
    {
    	let modalData = { ...this.state.modalData };
    	modalData[ e.target.name ] = e.target.value;
		this.setState({ modalData, unsavedChanges: true });
    }

    handleModalModuleChange( ids )
    {
    	let modalData = { ...this.state.modalData };
    	modalData['modules'] = ids;
		this.setState({ modalData, unsavedChanges: true });
    }

    confirmDelete( indexes ) 
    {
    	let ids = [];
    	indexes.forEach( index => {
    		ids.push( this.state.groups[ index ].id );
    	});

    	if( ids.length > 0 )
    		this.setState({ confirmDeleteIds: ids });
    }

    deleteGroups()
    {
    	const ids = this.state.confirmDeleteIds;

    	if( !ids || ids.length == 0 )
    		return false;

    	api({
			method: 'post',
			url: 'usermanagement/groups/remove',
			data: ids,
		}).then((response) => {
			this.getGroupList();
		}).catch((error) => {
			console.log(error);
		});
    }

    getModuleFilterOptions()
    {
    	const modules = this.state.filterModules;

    	let options = [{ value: '', label: ` - ${tr('all')} - ` }]

    	modules.forEach( module => {
    		options.push( {
    			value: module.id,
    			label: module.name, 
    			filter: ( row, id ) => { 
    				return ( row.modules.find( m => m.id == id ) !== undefined ? true : false );
    			}
    		})
    	});

    	return options;
    }

    render() 
    {
        return (
            <div className="apBox" id="pageUserGroups">
                <div className="apBoxHeader">
                    <h1>{ tr('user_groups_management') }</h1>
                    <p>{ tr('user_groups_management_info') }</p>
                </div>
                <div className="padding">

                	<ApButton size="small" color="green" onClick={ this.addGroup }>
                		<SvgIcon icon="plus" type="solid" />
                		{ tr('add_user_group') }
                	</ApButton>

                	<ApReactTable 

	                    loading={ this.state.loading }
	                    data={ this.state.groups } 
	                    columns={[
	                    	{
			                    Header: () => {
			                    	return (
			                    		<div className="tableHeader">
			                    			{ tr('user_groups') }

			                    			<div className="toggleSwitch">
			                    				<label className="switchLabel" htmlFor="list-permissions-switch">
						                            { tr('show_permissions') }
						                        </label>
				                    			<ApSwitch
						                        	small
						                            id="list-permissions-switch"
						                            on={ this.state.listPermissions }
						                            onChange={ ( e ) => this.setState({ listPermissions: e.target.checked }) }
						                            disabled={ this.state.loading }
						                        />
					                        </div>
			                    		</div>
			                    	);
			                    },
			                    columns: [
			                        { 
			                            id: 'name', 
			                            Header: tr('name_description'), 
			                            accessor: "name", 
			                            onClick: (row) => this.editGroup( row.id ),
			                            Cell: ( props ) => {
			                                return (
			                                    <div className="nameCell">
			                                        <strong>{ props.original.name }</strong><br />
			                                        <small>{ props.original.description ? props.original.description : <span style={{ color: "#aaa" }}>{ tr('no_description') }</span> }</small>
			                                    </div>
			                                );
			                            }, 
			                            customFilter: {
			                            	type: "text",
			                            	fields: [ "name", "_original.description" ]
			                            },
			                        },
			                        { 
			                            id: 'modules', 
			                            Header: tr('permissions'), 
			                            accessor: "modules",
			                            onClick: (row) => this.editGroup( row.id ),
			                            Cell: ( props ) => {
			                            	return (
			                            		<div className="moduleCell">
			                            			{ props.original.modules.length } { tr('pcs') }<br />
			                            			{ this.state.listPermissions &&
				                            			<div className="modules">
				                            				{ props.original.modules.map( ( module, i ) => <small key={ i } className="module">{ module.name }</small> )}
				                            			</div>
				                            		}
			                            		</div>
			                            	)
			                            },
			                            customFilter: {
			                            	type: "select",
			                            	options: this.getModuleFilterOptions(),
			                            },	                           
			                        },
			                        {
			                        	width: 100,
			                        	id: 'members', 
			                            Header: tr('stats_users'), 
			                            accessor: "members_count",
			                            onClick: (row) => this.editGroup( row.id ),
			                            customFilter: {
			                            	type: "range",
			                            	max: this.state.maxMembers,
			                            }
			                        }
			                    ]
			                }
	                    ]}
	                    filterable
	                    multiselect={[
	                        { icon: "trash-alt", label: "Poista valitut", action: ( indexes ) => this.confirmDelete( indexes ), closeAfter: true },
	                    ]}
	                    rowActions={ (row) => 
	                        <div className="apSimpleButton" onClick={ () => this.editGroup( row.id ) }><SvgIcon icon="cog" type="solid" /></div>
	                    }
	                    minRows={10}
	                />

	                <ApModal
		                show={ typeof( this.state.modalOpen ) == "number" || this.state.modalOpen === true }
		                onOpen={ this.onModalOpen }
		                handleClose={ this.closeModal }
		                //closeFromBg
		                header={ 
		                    <div id="UserGroupModalHeader">
		                        <h4>{ typeof( this.state.modalOpen ) == "number" ? tr('edit') : tr('new') }</h4>
		                    </div>
		                }
		                body={ 
		                    <div id="UserGroupModalBody">
		                        
		                    	
		                    	<div className="apColumn w50">
			                        <div className="padding">

			                            <ApInput
			                                type="text"
			                                id="name"
			                                name="name"
			                                label={ tr('name') }
			                                value={ keyExists( this.state.modalData, "name", true, '' ) }
			                                onChange={ this.handleModalInputChange }
			                                loading={ this.state.modalLoading }
			                                disabled={ this.state.modalLoading }
			                            />

			                            <ApInput
			                                type="textarea"
			                                id="description"
			                                name="description"
			                                label={ tr('description') }
			                                value={ keyExists( this.state.modalData, "description", true, '' ) }
			                                onChange={ this.handleModalInputChange }
			                                loading={ this.state.modalLoading }
			                                disabled={ this.state.modalLoading }
			                            />

			                        </div>
			                    </div>

			                    <div className="apColumn w50">
			                        <div className="padding">

			                            <ModulesTree
			                                id="user-module-tree"
			                                selectedIDs={ keyExists( this.state.modalData, "modules", true, [] )}
			                                onChange={ this.handleModalModuleChange }
			                            />
 
			                        </div>
			                    </div>

		                    </div>
		                }
		                footer={
		                    <div id="UserGroupModalFooter">
		    
		                        <ApButton className="cancel" onClick={ () => this.closeModal() } disabled={ this.state.modalLoading }>
		                            <SvgIcon icon="times" type="solid" />
		                            { tr('cancel') }
		                        </ApButton>
										                            
								<ApButton className={"save" + (this.state.unsavedChanges ? " highlight" : "" ) } color="blue" onClick={ () => this.closeModal( true ) } disabled={ this.state.modalLoading } loading={ this.state.modalLoading }>
									{ tr('save') }
		                            <SvgIcon icon="save" type="solid" />
		                        </ApButton>
		                    
		                    </div>
		                }

		            />

                    <ApConfirm
                        show={ this.state.confirmDeleteIds }
                        onClose={ () => this.setState({ confirmDeleteIds: false }) }
                        onConfirm={ this.deleteGroups }
                        header={ tr('confirm_delete') }
                        body={ 
                            <div>
                                <p>
                                    <strong>{ `${tr('delete_sure')} ${this.state.confirmDeleteIds.length} ${tr('pcs')}?` }</strong>
                                </p>
                                <p>{ tr('delete_user_groups_note') }</p>
                            </div>
                        }
                    />
                    

                </div>
            </div>
        );
    }
}

export default UserGroups;
