import React from 'react';
import autoBind from 'react-autobind';
import ApButton  from 'common/ApButton/ApButton.js';
import SvgIcon   from 'common/SvgIcon/SvgIcon.js';
import { tr } from 'services/Helpers/Helpers.js'
import './DataSummary.css';

class DataSummary extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
        }
        autoBind(this);
    }

    goToOrder()
    {
        window.emitter.emit('goTo', {
            pathname: `/storage/order/id/${ this.props.relatedId }`
        });
    }

    render()
    {
        let editButton = null;
        if( this.props.editable )
        {
            editButton = <ApButton
                size="xtiny"
                color="white"
                className="editButton"
                onClick={ this.props.onEditClick }
            >{ tr('edit') }</ApButton>
        }

        let numberElement = null
        if( this.props.numberReserved )
        {
            numberElement = <div>
                <dt>{ tr('number') }</dt>
                <dd>{this.props.number}</dd>
            </div>
        }
        else
        {
            numberElement = <div title={ tr('next_available_number_purchase_order') } style={{color: 'gray'}}>
                <dt>{ tr('number') }</dt>
                <dd>{this.props.number}</dd>
            </div>
        }

        let orderDetails = <dl className="dl-horizontal">
            <dt>{ tr('reference_number_your') }</dt>
            <dd>{this.props.referenceYour}</dd>

            <dt>{ tr('reference_number_our') }</dt>
            <dd>{this.props.referenceOur}</dd>
        </dl>

        let related = null;
      

        let senderType = '';
        if( this.props.senderType === 'location' )
            senderType = <span><SvgIcon className="type-icon" icon="archive" type="solid" /> { tr('storage') } </span>

        let receiverType = '';
        if( this.props.receiverType === 'location' )
            receiverType = <span><SvgIcon className="type-icon" icon="archive" type="solid" /> { tr('storage') } </span>
        else if( this.props.receiverType === 'project' )
            receiverType = <span><SvgIcon className="type-icon" icon="folder" type="solid" /> { tr('project') } </span>
        else if( this.props.receiverType === 'company' )
            receiverType = <span><SvgIcon className="type-icon" icon="building" type="solid" /> { tr('company') } </span>
        else if( this.props.receiverType === 'other' )
            receiverType = <span><SvgIcon className="type-icon" icon="dot-circle" type="solid" />{ tr('other') }</span>


            if( this.props.origin_id==null )
                {
                    editButton = <ApButton
                        
                        color="white"
                        className="editButton"
                        onClick={ this.props.onEditClick }
                    >{ tr("cancel_delivery") }</ApButton>
                }    

        return <div id="deliverySummary">
            <div>
                { editButton }
            </div>
            <div className="summaryBox">
                <div className="apForm">
                    <div className="apFormColumn">
                        <dl className="dl-horizontal">
                            {numberElement}
                            <dt>{ tr('order_creator') }</dt>
                            <dd>{ this.props.creatorName }</dd>
                        </dl>

                        <dl className="dl-horizontal">

                            <dt> { tr('sender') } </dt>
                            <dd> { senderType } </dd>

                            <dt></dt>
                            <dd> { this.props.senderTitle } </dd>
                        </dl>

                        <dl className="dl-horizontal">

                            <dt>{ tr('recipient') }</dt>
                            <dd> { receiverType } </dd>

                            <dt></dt>
                            <dd> { this.props.receiverTitle } </dd>
                        </dl>

                        <dl className="dl-horizontal">
                            <dt>{ tr('shipping_address') }</dt>
                            <dd>{this.props.receiverName}</dd>

                            <dt></dt>
                            <dd>{this.props.receiverAddress}</dd>

                            <dt></dt>
                            <dd>{this.props.receiverZipcode} {this.props.receiverCity}</dd>

                            <dt></dt>
                            <dd>{this.props.receiverCountry}</dd>

                            { Boolean(this.props.receiverContactName) && <dt>{ tr('contact_person') }</dt> }
                            { Boolean(this.props.receiverContactName) && <dd>{this.props.receiverContactName}</dd> }
                        </dl>

                    </div>
                    <div className="apFormColumn">
                        { related }
                        { orderDetails }
                        <dl className="dl-horizontal">
                            <dt>{ tr('comment') }</dt>
                            <dd>{this.props.comment}</dd>
                        </dl>
                    </div>                
                </div>
            </div>
        </div>
    }
}

export default DataSummary;
