export const apReackTableReducer = (state = {
    defaults: {
        // archived: false,
        // checkoutArchived: false,
        filtered: [],
        sorted: [{ id: "id", desc: true }],
    }
}, action) => {
    switch (action.type) {
        case 'UPDATE':
            // console.log(action.payload);
            if (!action.props.rememberId){
                return state; 
            }
            
            const table = { ...(state[action.props.rememberId] || state["defaults"]) };
            
            //list acceptable values
            if (action.payload.archived) {
                table.archived = action.payload.archived;
            }
            if (action.payload.checkoutArchived) {
                table.checkoutArchived = action.payload.checkoutArchived;
            }
            if (action.payload.filtered) {
                table.filtered = action.payload.filtered;
            }
            if (action.payload.sorted) {
                table.sorted = action.payload.sorted;
            }

            state[action.props.rememberId] = table;

            return {
                ...state,
            }
        
        default:
            return state;
    }
}