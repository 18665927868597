let poList = []
let currentPage = 0
let firstPageRefresh = false
let pageLock = false
let state = {}

/*
Global helper for orders and purchase orders for
ApReactTable to render the remembered page correctly 
*/
let currentComponent = ''

export const getPoList = () => {
    return poList
}

export const setPoList = (list) => {
    poList = list
}

export const getCurrentPage = () => {
    return currentPage
}

export const setCurrentPage = (page) => {
    currentPage = page
}

export const currentPageIsLocked = () => {
    return pageLock
}

export const setPageLock = (lock) => {
    pageLock = lock
    firstPageRefresh = true
}

export const setHelperState = (newState) => {
    state = newState
}

export const getHelperState = () => {
    return state
}

export const setComponentName = (name) => {
    currentComponent = name
}

export const getComponentName = () => {
    return currentComponent
}

export const getFirstPageRefresh = () => {
    return firstPageRefresh
}

export const setFirstPageRefresh = (value) => {
    firstPageRefresh = value
}