/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';

import {
    Collapse
} from 'react-bootstrap';

import './ApListTree.css';
import { addParentIds, editItemById } from '../../services/Helpers/Helpers.js';
import SvgIcon from '../SvgIcon/SvgIcon.js';

class ApListTree extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            items: []
        };
        this.toggleItem = this.toggleItem.bind(this);
        this.prepareItems = this.prepareItems.bind(this);
        this.renderLevel = this.renderLevel.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps)
    {
        this.setState({
            items: this.prepareItems( nextProps.items )
        });
    }

    prepareItems( items )
    {
        // Add parents to each child
        items = addParentIds( items, this.props.childsName );

        // Add 'childsOpen' to each item if not yet provided (defaults to false)
        const initItems = (data) => {
            for( let i = 0; i < data.length; i++ ) {
                if( !('childsOpen' in data[i]) ) data[i].childsOpen = false;
                if( data[i][ this.props.childsName ] ) {
                    data[i][ this.props.childsName ] = initItems( data[i][ this.props.childsName ] );
                }
            }
            return data;
        };
        items = initItems( items );
        return items;
    }

    toggleItem( id )
    {
        let items = this.state.items.slice();
        items = editItemById( items, id, { childsOpen: "!" }, this.props.childsName );
        this.setState({ items });
    }

    renderLevel( items, parentId )
    {
        if( !parentId ) parentId = "root";

        const childs = this.props.childsName;
        return (
            <ul className="apListTree">
                { items.map( (item, index) =>
                    <li key={index} className={ item.childsOpen ? "open" : "closed" }>
                        <div className={"apListTreeItem" + ( item.className ? " " + item.className : "" ) }>
                            { (Array.isArray(item[ childs ]) && (item[ childs ].length > 0)) && <SvgIcon className="childIndicator" icon="chevron-right" type="solid" onClick={ () => { this.toggleItem( item.id ) }} /> }
                            { typeof( this.props.content ) == "function" && this.props.content( item, () => { this.toggleItem( item.id); } ) }
                            { typeof( this.props.content ) == "string" && this.props.content }
                        </div>
                        { (Array.isArray(item[ childs ]) && (item[ childs ].length > 0)) &&
                            <Collapse in={item.childsOpen} timeout={400}>
                                <div>
                                { this.renderLevel( item[ childs ], item.id ) }
                                </div>
                            </Collapse>
                        }
                    </li>
                )}
            </ul>
        );
    }

    render()
    {
        return (
            <div className="ApListTreeContainer">
            { this.renderLevel( this.state.items ) }
            </div>
        );
    }
};

ApListTree.propTypes = {
    items:      PropTypes.array.isRequired,
    content:    PropTypes.oneOfType([ PropTypes.string, PropTypes.func ] ).isRequired,
    childsName: PropTypes.string,
};

ApListTree.defaultProps = {
    items: [],
    content: "You should provide 'content' prop!",
    childsName: "childs",
};


export default ApListTree;

