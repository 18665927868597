/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApButton from 'common/ApButton/ApButton.js';
// import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApModal from 'common/ApModal/ApModal.js';

import { keyExists, groupify, tr } from  'services/Helpers/Helpers.js';

// import { Collapse } from 'react-bootstrap';


export default class SalaryCategories extends React.Component {
    
    constructor(props) 
    {
        super(props);
        this.state = {
            modalShow: false,
            modalData: null,

            nextId: 1,
        };
        autoBind(this);
    }

    handleCompanyCategoryChange( e )
    {
        let data = this.props.company;
        data[ e.target.name ] = e.target.value;
        this.props.handleChange( "company", data );
    }

    handleChange( type, index, key, value )
    {
        let data = this.props[ type ].slice();
        data[ index ][ key ] = value;
        this.props.handleChange( type, data );
    }

    getGroupifyOptions()
    {
        let options = [];
        const groups = keyExists( this.props, "company.salary_category_parser_groups", true, [] );
        for (var key in groups )
        {
            options.push({
                value: key,
                label: tr('salary_category_'+key ),
            });
        }
        return options;
    }

    getConditionOptions( disabled = [] )
    {
        let options = [
            { value: 'choose', label: ` - ${tr('select_condition')} - ` },
            { value: '', label: tr('always'), disabled: disabled.length > 0 }
        ];
        const conditions = keyExists( this.props, "company.salary_category_parser_conditions", true, [] );
        for (var key in conditions )
        {
            options.push({
                value: key,
                label: tr(conditions[ key ]),
                disabled: disabled.indexOf( key ) != -1,
            });
        }
        return options;
    }

    getConditionName( id )
    {
        const conditions = keyExists( this.props, "company.salary_category_parser_conditions", true, [] );
        if( id in conditions )
            return tr( conditions[ id ] );

        // Id == null 
        return tr('always');
    }

    getParsers( salaryCategory = false )
    {
        const list = keyExists( this.props, "company.salary_category_parsers", true, [] ).filter( p => p.removed != true );
        const parsers = groupify( list, "salary_category" );
        
        if( salaryCategory )
            return salaryCategory in parsers ? parsers[ salaryCategory ] : [];
        
        return parsers;
    }

    getUsageCount( salaryCategory )
    {
        let count = 0;

        //if( keyExists( this.props, "company.expense_salary_category", true, "" ) == salaryCategory )
        //    count++;

        this.props.hour_types.forEach( h => {
            if( h.salary_category == salaryCategory )
                count++;
        });

        this.props.vehicles.forEach( v => {
            if( v.salary_category == salaryCategory )
                count++;
            if( v.salary_category_hours == salaryCategory )
                count++;
        });

        this.props.allowances.forEach( a => {
            if( a.salary_category == salaryCategory )
                count++;
        });

        this.props.absences.forEach( a => {
            if( a.salary_category == salaryCategory )
                count++;
        });

        return count;
    }

    addParser()
    {
        this.setState({
            modalShow: true,
            modalData: {
                salary_category: '',
                rules: [],
            }
        });
    }

    editParsers( salaryCategory )
    {
        if( !salaryCategory )
            return false;

        //console.log( 'edit parsers', salaryCategory );
        this.setState({
            modalShow: true,
            modalData: {
                salary_category: salaryCategory,
                rules: this.getParsers( salaryCategory ),
            }
        });
    }

    onModalChange( e, id = false )
    {
        const name = e.target.name;
        const value = e.target.value;

        let modalData = JSON.parse( JSON.stringify( { ...this.state.modalData } ) );
        
        if( id && modalData.rules.findIndex( i => i.id === id ) != -1 )
        {
            const index = modalData.rules.findIndex( i => i.id === id );
            modalData.rules[ index ][ name ] = value;
        }
        else if( name == "salary_category" )
        {
            modalData.salary_category = value;
            modalData.rules.map( r => r[ name ] = value );
        }
        this.setState({ modalData });
    }

    toggleModalType( id )
    {
        let modalData = JSON.parse( JSON.stringify( { ...this.state.modalData } ) );
        const index = modalData.rules.findIndex( i => i.id === id );
        if( index != -1 )
        {
            modalData.rules[ index ]['is_new'] = !modalData.rules[ index ].is_new;
            this.setState({ modalData });
        }
    }

    addModalRow( condition, groupifyRule = false )
    {
        const groupifyItems = keyExists( this.props, "company.salary_category_parser_groups", true, [] );

        let modalData = JSON.parse( JSON.stringify( { ...this.state.modalData } ) );
        modalData.rules.push({
            id: "new" + this.state.nextId,
            salary_category: modalData.salary_category,
            condition: condition,
            result: '',
            is_new: true,
            groupify_by: groupifyRule ? Object.keys( groupifyItems )[0] : null,
        });

        this.setState({ 
            modalData: modalData,
            nextId: this.state.nextId + 1 
        });
    }

    removeModalRow( id )
    {
        let modalData = JSON.parse( JSON.stringify( { ...this.state.modalData } ) );
        const index = modalData.rules.findIndex( i => i.id === id );
        if( index != -1 )
        {
            modalData.rules[ index ].removed = true;
            this.setState({ modalData });
        }
    }

    closeModal()
    {
        this.setState({ modalShow: false });
    }

    applyModal()
    {
        //console.log('modal apply', this.state.modalData );

        let data = this.props.company;
        let companyParsers = data.salary_category_parsers;

        this.state.modalData.rules.forEach( r => {
            if( r.id && companyParsers.findIndex( c => c.id === r.id ) != -1 )
            {
                const index = companyParsers.findIndex( c => c.id === r.id );
                companyParsers[ index ] = r;
            }
            else 
                companyParsers.push( r ); 
        });
        this.props.handleChange( "company", data );

        this.closeModal();
    }

    validateModal()
    {
        if( !keyExists( this.state, "modalData.salary_category", true ) )
            return false;

        if( keyExists( this.state, "modalData.rules", true, [] ).filter( r => r.result.length == 0 && r.removed != true ).length > 0 )
            return false;

        return true;
    }

    renderInput( type, data )
    {
        return (
            <div>
                { data && data.length > 0 && data.map( ( row, index ) => {

                    if( row.removed )
                        return false;

                    const parsers = this.getParsers( row.salary_category );
                    const parsersHours = type === "vehicles" ? this.getParsers( row.salary_category_hours ) : false;

                    return (
                        <ApInputStack gap="0" key={ index }>
                            <ApAddon noRightBorder>
                                { row.name }
                            </ApAddon>
                            <ApInput
                                width={ type === "vehicles" ? "150" : "350" }
                                type="text"
                                id={ "salary_category_" + type + index }
                                name={ "salary_category_" + type + index }
                                label={ type === "vehicles" ? tr('for_kilometers') : undefined }
                                value={ row.salary_category }
                                onChange={ ( e ) => { this.handleChange( type, index, "salary_category", e.target.value ) } }
                                loading={ this.props.loading }
                                disabled={ this.props.loading }
                                validationState={ !row.salary_category ? "warning" : "" }
                            />
                            <ApAddon 
                                noLeftBorder 
                                width="50" 
                                className="parsers" 
                                onClick={ () => this.editParsers( row.salary_category ) } 
                                tooltip={ tr('edit_special_rules') }
                                tooltipPosition="topright"
                            >
                                <SvgIcon icon="random" type="solid" />
                                { parsers.length > 0 &&
                                    <span className="apBadge blue">{ parsers.length }</span>
                                }
                            </ApAddon>
                            { type === "vehicles" &&
                                <ApInput
                                    gapLeft="10"
                                    width="150"
                                    type="text"
                                    id={ "salary_category_hour_" + type + index }
                                    name={ "salary_category_hour_" + type + index }
                                    label={ tr('for_travel_hours') }
                                    value={ row.salary_category_hours }
                                    onChange={ ( e ) => { this.handleChange( type, index, "salary_category_hours", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                    validationState={ !row.salary_category_hours ? "warning" : "" }
                                />
                            }
                            { type === "vehicles" &&
                                <ApAddon 
                                    noLeftBorder 
                                    width="50" 
                                    className="parsers" 
                                    onClick={ () => this.editParsers( row.salary_category_hours ) } 
                                    tooltip={ tr('edit_special_rules') }
                                    tooltipPosition="topright"
                                >
                                    <SvgIcon icon="random" type="solid" />
                                    { parsersHours.length > 0 &&
                                        <span className="apBadge blue">{ parsersHours.length }</span>
                                    }
                                </ApAddon>
                            }
                            
                        </ApInputStack>
                    );
                })}
                
            </div>
        );
    }

    renderRules( rules, groupifyRule = false )
    {
        const conditions = groupify( rules, "condition" ); 

        return (
            <div className="apFormGroup">
                <h3>{ groupifyRule ? tr('grouping') : tr('special_rules') }</h3>
                            
                <ApInputStack gap="0">
                    <ApAddon width="200" noRightBorder>
                        { groupifyRule ? tr('add_grouping_rule') : `${tr('add_new_rule')}:` } 
                    </ApAddon>
                    <ApInput 
                        type="select" 
                        id="condition" 
                        name="condition"
                        value={ 'choose' }
                        options={ this.getConditionOptions( groupifyRule ? rules.map( r => r.condition ) : [] ) }
                        onChange={ ( e ) => this.addModalRow( e.target.value, groupifyRule ) }
                    /> 
                </ApInputStack>

                { rules.length > 0 &&
                    <div className="rules">
                        { Object.keys( conditions ).map( ( condition, index ) => {
                            return (
                                <div key={ index } className="rule">
                                    <div className="condition">
                                        { this.getConditionName( condition ) }
                                    </div>
                                    <div className="items">
                                        { conditions[ condition ].map( ( item, rowIndex ) => {
                                            return (
                                                <div>
                                                    <ApInputStack key={ rowIndex } gap="0">
                                                        <ApAddon 
                                                            width="50"
                                                            noRightBorder
                                                            onClick={ () => this.toggleModalType( item.id ) }
                                                            tooltip={ item.is_new ? <span>{ tr('add') }<small>{ ` / ${tr('replace')}` }</small></span> : <span><small>{ `${tr('add')} / ` }</small>{ tr('replace') }</span> }
                                                            className={ "type " + ( item.is_new ? "new" : "replace" ) }
                                                        >
                                                            <SvgIcon icon={ item.is_new ? "plus" : "equals" } type="solid" />
                                                        </ApAddon>
                                                        <ApInput
                                                            type="text" 
                                                            id="result" 
                                                            name="result" 
                                                            value={ keyExists( item, "result", true, '' ) }
                                                            onChange={ ( e ) => this.onModalChange( e, item.id ) }
                                                            validationState={ keyExists( item, "result", true, '' ) ? "" : "error" }
                                                        />
                                                        <ApAddon 
                                                            width="50" 
                                                            noLeftBorder
                                                            onClick={ () => this.removeModalRow( item.id ) }
                                                        >
                                                            <SvgIcon icon="times-circle" type="solid" />
                                                        </ApAddon>
                                                    </ApInputStack>
                                                    { groupifyRule &&
                                                        <ApInput 
                                                            type="select" 
                                                            id="groupify_by" 
                                                            name="groupify_by"
                                                            value={ item.groupify_by }
                                                            options={ this.getGroupifyOptions() }
                                                            onChange={ ( e ) => this.onModalChange( e, item.id ) }
                                                        />
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }

                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { groupifyRule 
                        ? tr('grouping_rule_info')
                        : tr('special_rule_info') 
                    }
                </div>

            </div>
        );
    }

    renderRuleItem( item, index )
    {
        return (
            <ApInputStack key={ index }>
                <ApAddon custom width="30">
                    { item.id }
                </ApAddon>
                { item.groupify_by &&
                    <ApInput 
                        type="select" 
                        id="groupify_by" 
                        name="groupify_by"
                        value={ item.groupify_by }
                        options={ this.getGroupifyOptions() }
                        onChange={ ( e ) => this.onModalChange( e, item.id ) }
                    />
                }
                <ApInput 
                    type="select" 
                    id="condition" 
                    name="condition"
                    value={ keyExists( item, "condition", true, null ) }
                    options={ this.getConditionOptions() }
                    onChange={ ( e ) => this.onModalChange( e, item.id ) }
                />
                <ApInput 
                    width="120"
                    type="select" 
                    id="is_new" 
                    name="is_new"
                    value={ keyExists( item, "is_new", true, false ) }
                    options={[
                        { value: false, label: tr('replace') },
                        { value: true, label: tr('add') },
                    ]}
                    onChange={ ( e ) => this.onModalChange( e, item.id ) }
                />
                <ApInput
                    width="100" 
                    type="text" 
                    id="result" 
                    name="result" 
                    value={ keyExists( item, "result", true, '' ) }
                    onChange={ ( e ) => this.onModalChange( e, item.id ) }
                    validationState={ keyExists( item, "result", true, '' ) ? "" : "error" }
                />
                <ApAddon custom width="30">
                    <SvgIcon 
                        className="removeRow" 
                        icon="times-circle" 
                        type="solid" 
                        onClick={ () => this.removeModalRow( item.id ) } 
                    />
                </ApAddon>
            </ApInputStack>
        );
    }

    render()
    {
        const parsers = this.getParsers();

        const allRules = keyExists( this.state, "modalData.rules", true, [] ).filter( r => !r.removed );
        const rules = allRules.filter( r => !r.groupify_by );
        const groupifyRules = allRules.filter( r => r.groupify_by );

    	return (
    		<div className="padding salaryCategories">

                <p>{ tr('salary_types_info1') }</p>
                <p>{ tr('salary_types_info2') }</p>
                
                {/*
                <ApInputStack gap="0">
                    <ApAddon noRightBorder>
                        Kulukorvaus
                    </ApAddon>
                    <ApInput
                        width="40%"
                        type="text"
                        id="expense_salary_category"
                        name="expense_salary_category"
                        value={ keyExists( this.props, "company.expense_salary_category", true, "" ) }
                        onChange={ this.handleCompanyCategoryChange }
                        loading={ this.props.loading }
                        disabled={ this.props.loading }
                        validationState={ keyExists( this.props, "company.expense_salary_category", true ) ? "" : "warning" }
                    />
                </ApInputStack>
                */}

                { keyExists( this.props, "hour_types", true ) &&
                    <div>
            			<h3>{ tr('hour_rating_salary_types') }</h3>
                        { this.renderInput( "hour_types", this.props.hour_types ) }
                    </div>
                }

                { keyExists( this.props, "absences", true ) &&
                    <div>
                        <h3>{ tr('absence_salary_types') }</h3>
                        { this.renderInput( "absences", this.props.absences ) }
                    </div>
                }

                { keyExists( this.props, "vehicles", true ) &&
                    <div>
                        <h3>{ tr('kilometer_allowance_salary_types') }</h3>
                        <p>{ tr('kilometer_allowance_salary_types_info') }</p>
                        { this.renderInput( "vehicles", this.props.vehicles ) }
                    </div>
                }

                { keyExists( this.props, "allowances", true ) &&
                    <div>
                        <h3>{ tr('daily_allowance_salary_types') }</h3>
                        <p>{ tr('daily_allowance_salary_types_info') }</p>
                        { this.renderInput( "allowances", this.props.allowances ) }
                    </div>
                }

                <hr />

                <h2>{ tr('special_rules') }</h2>
                <p>{ tr('special_rules_info') }</p>

                <ApButton color="white" size="small" onClick={ this.addParser }>
                    <SvgIcon icon="plus-circle" type="solid" />
                    { tr('add_special_rule') }
                </ApButton>

                <div className="apItemList">
                    { Object.keys( parsers ).map( salaryCategory => {
                        const count = this.getUsageCount( salaryCategory );
                        return (
                            <div className="item withIcon clickable" key={ salaryCategory }>
                                <SvgIcon icon="random" type="solid" />
                                <div className="name" onClick={ () => this.editParsers( salaryCategory ) }>
                                    <strong>{ tr('salary_type') }: { salaryCategory }</strong><br />
                                    <small>{ parsers[ salaryCategory ].length } { tr('rules_count') }</small>
                                </div>
                                <div className="info" onClick={ () => this.editParsers( salaryCategory ) }>
                                    { tr('in_use') }: { count }
                                </div>
                            </div>
                        );
                    })}
                </div>

                <ApModal
                    className="narrow"
                    show={ this.state.modalShow }
                    handleClose={ this.closeModal }
                    closeFromBg
                    header={ 
                        <div className="padding">
                            <h3>{ tr('edit') }</h3>
                        </div>
                    }
                    body={ 
                        <div className="padding">
                            
                            <ApInput 
                                type="text" 
                                id="salary_category" 
                                name="salary_category" 
                                label={ tr('salary_type') }
                                value={ keyExists( this.state, "modalData.salary_category", true, '' ) }
                                onChange={ this.onModalChange }
                                validationState={ keyExists( this.state, "modalData.salary_category", true, '' ) ? "" : "error" }
                            />
                            
                            { this.renderRules( rules ) }

                            { this.renderRules( groupifyRules, true ) }
                            
                            {/* 
                            <div className="apFormGroup">
                                <h6>Erikoissäännöt</h6>

                                { rules.map( this.renderRuleItem ) }

                                { rules.length == 0 &&
                                    <div className="apMsg">
                                        Ei erikoissääntöjä saatavilla
                                    </div>
                                }

                                <ApButton color="white" size="small" onClick={ () => this.addModalRow( false ) }>
                                    <SvgIcon icon="plus" type="solid" />
                                    Lisää sääntö
                                </ApButton>

                            </div>

                            <div className="apFormGroup">
                                <h6>Yhdistäminen</h6>

                                { groupifyRules.map( this.renderRuleItem ) }

                                { groupifyRules.length == 0 &&
                                    <div className="apMsg">
                                        Ei yhdistämissääntöjä saatavilla
                                    </div>
                                }

                                <ApButton color="white" size="small" onClick={ () => this.addModalRow( true ) }>
                                    <SvgIcon icon="plus" type="solid" />
                                    Lisää yhdistämissääntö
                                </ApButton>

                            </div>
                            */}

                        </div>
                    }
                    footer={
                        <div className="padding">
                            
                            <ApButton onClick={ this.closeModal }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>

                            <ApButton className="applyButton" color="green" onClick={ this.applyModal } disabled={ !this.validateModal() }>
                                <SvgIcon icon="check" type="solid" />
                                { tr('continue') }
                            </ApButton>

                        </div>
                    }
                />

    		</div>
    	);
    }

}
