import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import { ApInput } from 'common/ApInput/ApInput.js';

import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import api from 'services/Api/Api.js';

import { dateInputToSql, errorPopper, keyExists, tr } from 'services/Helpers/Helpers.js';
import { setCompanyId, setFrom } from '../../../Billable/billableHelper';
import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect.js';
import ApCountrySelect from  'common/ApCountrySelect/ApCountrySelect.js';

import './NewOrder.css';


class NewOrder extends React.Component
{
    constructor(props)
    {
       
        super(props);
        this.state = {

            related: null,

            dm: [],
            dt: [],
            pc: [],

            project: null,
        }
      

        const dmFilter = ( value ) => {
            if( this.state.type !== 'delivery' ) return true;
            if( !this.state[ 'dm_enabled' ]) return true;
            if( !this.state[ 'dm_required' ]) return true;
            return Boolean( value );
        }
        const dtFilter = ( value ) => {
            if( this.state.type !== 'delivery' ) return true;
            if( !this.state[ 'dt_enabled' ]) return true;
            if( !this.state[ 'dt_required' ]) return true;
            return Boolean( value );
        }
        const pcFilter = ( value ) => {
            if( !this.state[ 'pc_enabled' ]) return true;
            if( !this.state[ 'pc_required' ]) return true;
            return Boolean( value );
        }

        this.validator = new ApValidate( this, {
            related: { filter: 'required', state: 'error', text: tr('orderer_required') },

            billing_name: { filter: 'required',    text: tr('billing_name_not_defined') },
            billing_address: { filter: 'required', text: tr('billing_address_not_defined') },
            billing_zipcode: { filter: 'required', text: tr('billing_postal_code_not_defined') },
            billing_city: { filter: 'required',    text: tr('billing_city_not_defined') },

            receiver_name: { filter: 'required',    text: tr('delivery_name_not_defined') },
            receiver_address: { filter: 'required', text: tr('delivery_address_not_defined') },
            receiver_zipcode: { filter: 'required', text: tr('delivery_postal_code_not_defined') },
            receiver_city: { filter: 'required',    text: tr('delivery_country_not_defined') },

            dm_selected: { filter: dmFilter, text: tr('delivery_method_not_selected') },
            dt_selected: { filter: dtFilter, text: tr('delivery_terms_not_selected') },
            pc_selected: { filter: pcFilter, text: tr('payment_term_not_selected') },

        });

        const oneIsInvalid = ( test ) => {
            let text = null;
            const invalid = test.some( field  => {
                text = this.validator.getText( field )
                return text;
            });
            if( invalid ) return text;
            return null;
        }

        this.steps = [{
            name: tr('type'),
            render: this.renderStepType.bind( this ),
            invalid:  () => {
                return !this.state.type;
            }
        },{
            name: tr('orderer'),
            render: this.renderStepOrderer.bind( this ),
            invalid:  () => {

                let invalid = oneIsInvalid([
                    'related',
                    'billing_name',
                    'billing_address',
                    'billing_zipcode',
                    'billing_city',
                ]);

                if( !invalid && this.state.type === 'delivery' )
                {
                    return oneIsInvalid([
                        'receiver_name',
                        'receiver_address',
                        'receiver_zipcode',
                        'receiver_city',
                    ]);
                }
                return invalid;
            }
        },{
            name: tr('other_info'),
            render: this.renderStepOther.bind( this ),
            invalid:  () => {
                return oneIsInvalid([
                    'dm_selected',
                    'dt_selected',
                    'pc_selected',
                ]);
            }
        }];
       
        autoBind(this);
    }

    resetState()
    {

        this.setState({

            type: null,

            related: null,

            billing_name: null,
            billing_address: null,
            billing_zipcode: null,
            billing_city: null,
            billing_country: null,
            billing_country_code: null,
            billing_country_code_id: null,

            receiver_name: null,
            receiver_address: null,
            receiver_zipcode: null,
            receiver_city: null,
            receiver_country: null,
            receiver_country_code: null,
            receiver_country_code_id: null,

            dm_selected: null,
            dt_selected: null,
            pc_selected: null,


            date: moment().format('YYYY-MM-DD'),
            reference_your: null,
            reference_our: null,
            need_date: null,
            delivery_date:null,
            details: null,
            contact: null,

        }, () => {
            if( this.props.customerId )
                this.getInitialCustomer();
            if( this.props.type )
            {
                this.setState({
                    type: this.props.type,
                });
            }
            if( this.props.date )
            {
                this.setState({
                    date: this.props.date,
                });
            }
            if( this.props.due_date )
            {
                this.setState({
                    due_date: this.props.due_date,
                });
            }
            if (this.props.reference_our) {
                this.setState({
                    reference_our: this.props.reference_our,
                });
            }
            if (this.props.reference_your) {
                this.setState({
                    reference_your: this.props.reference_your,
                });
            }
            
        });
    }

    componentDidMount()
    {
        this.getRelated();
    }

    componentDidUpdate( prevProps )
    {
        if (this.props.show && !prevProps.show && this.props.copyId) {
            api({
                method: 'get',
                url: `storage/order/id/${this.props.copyId}`,
            }).then( ( response ) => {
                // console.log("RES", response);
                const billingMethods = response.crm_billing_methods
                    ? Object.keys(response.crm_billing_methods).map((item, index) => {
                        return {id: response.crm_billing_methods[item], name: item}
                    })
                    : [];
                const pc_selected = Array.isArray(response.pc)
                    ? response.pc.find(item => item.id == response.pc_id)
                    : null;
                const dt_selected = Array.isArray(response.dt)
                    ? response.dt.find(item => item.id == response.dt_id)
                    : null;
                const dm_selected = Array.isArray(response.dm)
                    ? response.dm.find(item => item.id == response.dm_id)
                    : null;
                const due_date = pc_selected
                    ? dateInputToSql(moment().add(pc_selected.days, 'days'))
                    : null
                this.setState({
                    type: response.type,
                    related: {
                        id: response.related_id,
                        name: response.related_title,
                        addresses: response.address_options,
                    },

                    billing_address: response.billing_address,
                    billing_city: response.billing_city,
                    billing_country: response.billing_country,
                    billing_name: response.billing_name,
                    billing_zipcode: response.billing_zipcode,
                    billingMethod: response.billing_method,
                    billingMethods,
                    billing_country_code: response.billing_country_code,
                    billing_country_code_id: response.billing_country_code_id,

                    receiver_address: response.receiver_address,
                    receiver_city: response.receiver_city,
                    receiver_country: response.receiver_country,
                    receiver_name: response.receiver_name,
                    receiver_zipcode: response.receiver_zipcode,
                    receiver_country_code: response.receiver_country_code,
                    receiver_country_code_id: response.receiver_country_code_id,

                    project: response.project,

                    contact: {
                        ...response.contact_person, 
                        fullName: response.contact_person
                            ? `${response.contact_person.first_name} ${response.contact_person.last_name}`
                            : null,
                    },

                    pc_selected,
                    dt_selected,
                    dm_selected,
                    due_date,

                    components: response.components,
                    price: response.price,
                    details: response.details,
                });
            }).catch((error) => {
                errorPopper(error, tr('get_error'));
            });
        }
        else if (this.props.show && !prevProps.show && this.props.project) {
            this.setState({ project: this.props.project });
        }
        
    }

    getInitialCustomer()
    {
        this.setState({ loading: true });

        //console.log('customerId', this.props.customerId );
        //console.log('Get related');

        let url = `storage/order/new/customer/${ this.props.customerId }`;

        if (this.props.is_payment_post) {
            url = `projects/payments/new/customer/${this.props.customerId}`;
        }

        return api({
            method: 'get',
            url: url,
            errorPopper: tr('get_error'),
        }).then((response) => {
            //console.log('Get initial customer', response);
            this.setRelated( response );
            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    getRelated()
    {
        //console.log('Get related');
        return api({
            method: 'get',
            url: 'storage/order/new/related',
            errorPopper: tr('get_error'),
        }).then( ( response ) => {
            //console.log('Get related', response);
            this.setState({
                dm: response.dm,
                dt: response.dt,
                pc: response.pc,
                dm_enabled: response.dm_enabled,
                dt_enabled: response.dt_enabled,
                pc_enabled: response.pc_enabled,
                dm_required: response.dm_required,
                dt_required: response.dt_required,
                pc_required: response.pc_required,
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    save()
    {
       
        let data = {};
        data.type = this.state.type;

        data.related_type = 'company';
        data.related_id = this.state.related.id;
       

        data.billing_name = this.state.billing_name;
        data.billing_address = this.state.billing_address;
        data.billing_zipcode = this.state.billing_zipcode;
        data.billing_city = this.state.billing_city;
        data.billing_country = this.state.billing_country;
        data.billing_country_code_id = this.state.billing_country_code_id;
        data.billing_method = this.state.billingMethod?this.state.billingMethod.id:null;

        if (this.state.project) {
            data.project_code = this.state.project.project_code;
            data.project_id = this.state.project.id;
        }
        if (this.state.contact && this.state.contact!=="") { data.contact_person = this.state.contact.id; }

        if( this.state.type === 'delivery' )
        {
            data.receiver_name = this.state.receiver_name;
            data.receiver_address = this.state.receiver_address;
            data.receiver_zipcode = this.state.receiver_zipcode;
            data.receiver_city = this.state.receiver_city;
            data.receiver_country = this.state.receiver_country;
            data.receiver_country_code_id = this.state.receiver_country_code_id;

            if( this.state.dm_enabled )
                data.dm_id = keyExists( this.state, 'dm_selected.id', true, null);
            if( this.state.dt_enabled )
                data.dt_id = keyExists( this.state, 'dt_selected.id', true, null);

            data.need_date = this.state.need_date;
            data.delivery_date = this.state.delivery_date||null;
        }

        if( this.state.pc_enabled )
            data.pc_id = keyExists( this.state, 'pc_selected.id', true, null);

        data.date = this.state.date;
        data.reference_your = this.state.reference_your;
        data.reference_our = this.state.reference_our;
        data.due_date = this.state.due_date;
        data.details = this.state.details;
        data.cost_center_id = this.state.costCenter;

        //console.log('pp', this.props );
        //console.log('Saving', data);

        if( this.props.components )
            data.components = this.props.components;

        if( this.props.payment_post_id )
            data.payment_post_id = this.props.payment_post_id;

        if (this.props.comesfromBillable) {
            setFrom(true)
            setCompanyId(this.props.customerId)

            data.require_project = true
        }

        data.copyId = this.props.copyId;
        data.componentsList = this.state.components;
        data.is_credit_note = this.props.isCreditNote;
        if (this.props.isCreditNote && this.state.price) {
            data.price = -1 * parseFloat(this.state.price);
        }
        data.import_po_id = this.props.import_po_id;
        if (this.props.import_po_components) {
            data.import_po_components = this.props.import_po_components;
        }
        this.setState({ loading: true });
        // console.log("myyntitilaus luodaan, myyntitilauksen lähtevä data:", data)
        return api({
            method: 'post',
            url: this.props.copyId ? 'storage/order/copy' : this.props.whoUse ? (this.props.whoUse+'/storage/order/new') : 'storage/order/new',
            data: data,
            errorPopper: tr('save_error'),
        }).then( ( response ) => {
            window.emitter.emit( 'goTo', { pathname: `/storage/order/id/${ response.id }` } );
        }).catch((error) => {
            this.setState({ loading: false });
        });

    }

    setRelated( selected )
    {
        let billing_name     = null;
        let billing_address  = null;
        let billing_zipcode  = null;
        let billing_city     = null;
        let billing_country  = null;
        let billing_country_code  = null;
        let billing_country_code_id  = null;
        let receiver_name    = null;
        let receiver_address = null;
        let receiver_zipcode = null;
        let receiver_city    = null;
        let receiver_country = null;       
        let receiver_country_code = null;
        let receiver_country_code_id = null;
        let billingMethods = null;



        //console.log('selected', selected );


        if( selected )
        {
            if( selected.default_billing_address_id )
            {
                let addr = selected.addresses.find( s => s.id === selected.default_billing_address_id );
                if( addr )
                {
                    billing_name     = addr.name;
                    billing_address  = addr.street;
                    billing_zipcode  = addr.postal_code;
                    billing_city     = addr.city;
                    billing_country  = addr.country;
                    billing_country_code  = addr.country_code;
                    billing_country_code_id  = addr.country_code_id;
                }

            }

            if( selected.default_shipping_address_id )
            {
                let addr = selected.addresses.find( s => s.id === selected.default_shipping_address_id);
                if( addr )
                {
                    receiver_name    = addr.name;
                    receiver_address = addr.street;
                    receiver_zipcode = addr.postal_code;
                    receiver_city    = addr.city;
                    receiver_country = addr.country;
                    receiver_country_code = addr.country_code;
                    receiver_country_code_id = addr.country_code_id;
                }
            }

            if( selected.default_delivery_method_id )
            {
                let found = this.state.dm.find( f => f.id === selected.default_delivery_method_id );
                if( found )
                    this.setState({ dm_selected: found });
            }
            if( selected.default_delivery_term_id )
            {
                let found = this.state.dt.find( f => f.id === selected.default_delivery_term_id );
                if( found )
                    this.setState({ dt_selected: found });
            }
            if (selected.default_payment_condition_id || this.props.paymentCondition )
            {
                let found;
                if (this.props.paymentCondition) {
                    found = this.props.paymentCondition;
                }
                else {
                    
                    found = this.state.pc.find(f => f.id === selected.default_payment_condition_id);
                }
                if( found ){
                    this.setState({ pc_selected: found });
                    if (found.days !== null) {
                        //console.log(e);
                        let dueDate = moment(this.state.date).add(found.days, 'days').format("YYYY-MM-DD");
                        this.setState({ due_date: dueDate });
                    }
                    
                }
            }
            if (selected.billing_methods) {
                let billing_methods = []
                for (let key in selected.billing_methods) {
                    billing_methods.push({ id: selected.billing_methods[key], name: key });
                    if (selected.billing_methods[key] === selected.default_billing_method) {
                        this.setState({ billingMethod: { id: selected.billing_methods[key], name: key } });
                    }
                }
                billingMethods = billing_methods;
                
            }
        }

        this.setState({ 
            related: selected,
            billing_name:     billing_name,
            billing_address:  billing_address,
            billing_zipcode:  billing_zipcode,
            billing_city:     billing_city,
            billing_country_code:    billing_country_code,
            billing_country_code_id:    billing_country_code_id,
            billing_country:    billing_country,
            billingMethods: billingMethods,
            receiver_name:    receiver_name,
            receiver_address: receiver_address,
            receiver_zipcode: receiver_zipcode,
            receiver_city:    receiver_city,
            receiver_country: receiver_country,
            receiver_country_code_id:    receiver_country_code_id,
            receiver_country_code:    receiver_country_code,
        });
    }

    renderInput( field, label, required = false )
    {
        let vState = null;
        if( required )
        {
            label = label + " *";
            vState = this.validator.getState( field );
        }

        return <ApInput
            type="text"
            id={ field }
            name={ field }
            label={ label }
            autoComplete="off"
            onChange={ ( e ) => { this.setState({ [ field ]: e.target.value })} }
            value={ this.state[ field ] ? this.state[ field ] : '' }
            validationState={ vState }
        />

    }

    changeCountryselect(country, type) {
        // this.setState({selectedCountry : country});
        if (type === 'billing') {
            this.setState({ 
                billing_country_code_id: country?.id,
                billing_country_code: country?.code,
                billing_country: country?.name
            });
        }
        else {
            this.setState({ 
                receiver_country_code_id: country?.id, 
                receiver_country_code: country?.code,
                receiver_country: country?.name
            });
        }
    }

    renderSelectMenu( type )
    {
        let related = this.state.related;
        //console.log("state", this.state);
        if( !related ) return null;
        if( !related.addresses.length ) return null;

        let actions  = [
            { "divider": `${tr('set_address')} ` },
        ]

        let icons = {
            'general': "globe",
            'mailing': "envelope", 
            'billing': "file-invoice", 
            'shipping': "truck", 
        };

        related.addresses.forEach( a => {
            let label = <div className="addressRow">
                { a.name }<br />
                { a.street }<br />
                { a.postal_code } { a.city }<br />
                { a.country }
            </div>
            actions.push({
                label: label,
                icon: icons[ a.type ],
                action: ( item, closeFunc ) => {
                    this.setState({
                        [`${ type }_name`]: a.name,
                        [`${ type }_address`]: a.street,
                        [`${ type }_zipcode`]: a.postal_code,
                        [`${ type }_city`]: a.city,
                        [`${ type }_country`]: a.country,
                        [`${ type }_country_code_id`]: a.country_code_id,
                    });
                    closeFunc();
                },
            });
        })
        return <ApDropdown position="right"
            actions={ actions }
            button={ <div className="defaultButton">
                <SvgIcon icon="book" type="solid" /> 
            </div>}
        />
    }

    renderBilling()
    {
        return <div>
            <div className="addressTitleContainer">
                <div className="addressMenu">
                    { this.renderSelectMenu( 'billing') }
                </div>
                <div className="addressTitle">
                    { this.renderInput( 'billing_name', tr('name'), true ) }
                </div>
            </div>

            <div className="indent">
                { this.renderInput( 'billing_address', tr('address'), true ) }
                <div className="col-container">
                    <div className="col-left">
                        { this.renderInput( 'billing_zipcode', tr('postal_code'), true ) }
                    </div>
                    <div className="col-right">
                        { this.renderInput( 'billing_city', tr('post_office'), true ) }
                    </div>
                </div>
                <div className="col-left">
                    {/* { this.renderInput( 'billing_country', tr('country') ) } */}
                    <ApCountrySelect
                      
                    selectedCountry = {this.state.selectedBillingCountry}  
                    country_code_id = {this.state.billing_country_code_id}
                    onChange= {item => this.changeCountryselect(item, 'billing')}                    
                       
                    /> 
                </div>
            </div>
        </div>

    }
    
    renderBillingMethod() {
        return <div>
            <ApSelect
                label={tr("default_billing_method")}
                value={this.state.billingMethod && this.state.billingMethod.name ? tr(this.state.billingMethod.name) : ""}
                optionRenderer={(item) => {
                    return <div>
                        {tr(item.name)}
                    </div>
                }}
                onChange={(item) => this.setState({ billingMethod: item, })}
                objKeyId="id"
                objKeySearchable="name"
                objKeyValue="name"
                clearable

                options={this.state.billingMethods ? this.state.billingMethods : []}

                loading={this.state.loading}
                disabled={this.state.loading}
            />
        </div>
    }
    
    renderProject() {
        return <div>
            <ApSelect
                label={ tr('project') }
                loading={ this.state.loading }
                value={ this.state.project }
                optionRenderer="project"
                onChange={ ( p ) => this.setState({project: p})}
                objKeyId="id"
                objKeyValue='name'
                clearable
                apiUrl="report/projects/find"
                apiData={{
                    formatter: 'select',
                    customer: this.state.related?this.state.related.id:null,
                    withOffers: true,
                    withoutClosed: true,
                }}
            />
            
            
        </div>
    }

    renderCompanyContact() {
        let related = this.state.related
        for (let contact of related.contacts) {
            contact.fullName = contact.first_name + " "+contact.last_name
        }
        
        //console.log(this.state.related.contacts);
        return <div>
            <ApSelect
                label={tr('contact_person')}
                value={this.state.contact}
                options={related.contacts}
                optionRenderer={(item) => {
                    //console.log(item);
                    return <div>
                        <strong>{item.fullName}</strong>
                    </div>

                }}
                onChange={(c) => this.setState({ contact: c })}
                objKeyId="id"
                objKeyValue="fullName"
                objKeySearchable="fullName"
                clearable

            />
        </div>
    }

    renderShipping()
    {
        return <div>
            <div className="addressTitleContainer">
                <div className="addressTitle">
                    { this.renderInput( 'receiver_name', tr('name'), true ) }
                </div>
                <div className="addressMenu">
                    { this.renderSelectMenu( 'receiver') }
                </div>
            </div>

            <div className="indent">
                { this.renderInput( 'receiver_address', tr('address'), true ) }
                <div className="col-container">
                    <div className="col-left">
                        { this.renderInput( 'receiver_zipcode', tr('postal_code'), true ) }
                    </div>
                    <div className="col-right">
                        { this.renderInput( 'receiver_city', tr('post_office'), true ) }
                    </div>
                </div>
                <div className="col-left">
                    {/* { this.renderInput( 'receiver_country', tr('country') ) } */}
                    <ApCountrySelect
                        selectedCountry = {this.state.selectedReceiverCountry}
                        country_code_id = {this.state.receiver_country_code_id}
                        onChange= {item => this.changeCountryselect(item, 'receiver')}
                    />
                </div>
            </div>
        </div>
    }


    renderStepType()
    {
        const typeOptions = [
            {
                type: 'no_delivery',
                name: tr('invoice'),
                description: tr('order_invoice_info'),
                icon: 'receipt',
            },{
                type: 'delivery',
                name: tr('invoice_delivery_of_goods'),
                description: tr('invoice_delivery_of_goods_info'),
                icon: 'shipping-fast'
            },
        ];

        const selectedType = this.state.type

        const typeDoms = typeOptions.map( type => {
            const optionClass = ['option'];
            if( selectedType === type.type )
                optionClass.push( 'selected' );

            return <div key={ type.type } className={ optionClass.join(' ') } onClick={ () => { this.setState({ type: type.type })}}>
                <div className="iconContainer">
                    <SvgIcon icon={ type.icon } type="solid" />
                </div>
                <div className="textContainer">
                    <div className="header">
                        { type.name }
                    </div>
                    <div className="text">
                        { type.description }
                    </div>
                </div>
            </div>
        });

        return <div className="stepType">
            { typeDoms }
        </div>
    }


    renderStepOrderer()
    {
        let billingDom = null;
        let shippingDom = null;
        let projectDom = null;
        let companyContact = null;
        let billingMethod = null;

        if(  this.state.related )
        {
            billingDom = <div>
                <h3 className="apFormHeader">{ tr('billing_address') }</h3>
                { this.renderBilling() }
            </div>
            billingMethod = <div>
                <h3 className="apFormHeader">{tr('billing_method')}</h3>
                {this.renderBillingMethod()}
            </div>

            projectDom = <div>
                <h3 className="apFormHeader">{ tr('project') }</h3>
                { this.renderProject() }
            </div>
            companyContact = this.state.related.contacts ? <div>
                <h3 className="apFormHeader">{tr('contact_person')}</h3>
                {this.renderCompanyContact()}
            </div> : null;

            if( this.state.type === 'delivery' )
            {
                shippingDom = <div>
                    <h3 className="apFormHeader">{ tr('shipping_address') }</h3>
                    { this.renderShipping() }
                </div>
            }
        }
                    // validationState={ this.props.validator.getState('relatedId') }
        return <div className="stepOrder">
            <div className="related-select">
                <ApSelect
                    label={ `${tr('orderer_company')} *` }
                    value={ this.state.related }
                    optionRenderer="crm_company"
                    onChange={ this.setRelated }
                    objKeyId="id"
                    objKeyValue="name"
                    clearable
                    apiUrl="storage/order/new/customer/find"
                />
            </div>
            {/* Karita special   */ }
            {this.state.related && this.state.related.cost_center && <small style={{ marginLeft: 5 }}>{tr('cost_center')}: {this.state.related.cost_center.full_name} </small>}
            {billingDom}
            {billingMethod}
            {this.props.selectProject ? projectDom : ''}
            {companyContact}
            { shippingDom }
        </div>
    }

    renderRelated()
    {
        const fields = [
            { label: tr('payment_term'), field: 'pc' },
            { label: tr('delivery_term'), field: 'dt' },
            { label: tr('delivery_method'), field: 'dm' },
        ];

        let doms = [];
        fields.forEach( f => {

            if( !this.state[`${ f.field }_enabled`]  ) return null;

            // skip for delivery
            if( this.state.type !== 'delivery' )
            {
                if(  f.field === 'dm' || f.field === 'dt' )
                    return null;
            }

            let label = f.label;
            const sel = `${ f.field }_selected`;

            const options = this.state[ f.field ];

            let validationState = null;
            if( this.state[ `${ f.field }_required` ] )
            {
                validationState = this.validator.getState( sel );
                label = `${ label } *`;
            }
            doms.push( <ApSelect
                label={ label }

                value={ this.state[ sel ] }

                optionRenderer={ ( item ) => {
                    return <div>
                        { item.name }
                    </div>
                }}
                onChange={(item) => {
                    if (f.field === 'pc' && item.days !== null) {
                        this.updateDueDate(item.days,"pc");
                    }
                    this.setState({ [sel]: item })
                }}
                objKeyId="id"
                objKeySearchable="name"
                objKeyValue="name"
                clearable

                options={ options }
                loading={ this.state.loading }
                disabled={ this.state.loading }

                validationState={ validationState }

            />)
        });

        return <div>
            { doms }
        </div>
    }

    updateDueDate(value,type) {
        if (type == "pc") {
            let date = this.state.delivery_date ? this.state.delivery_date : this.state.date;
            let dueDate = moment(date).add(value, 'days').format("YYYY-MM-DD");
            this.setState({ due_date: dueDate });
        }
        else {
            if (!value) {
                value = this.state.date;
            }
            if (this.state.pc_selected) {
                if (type == "date") {
                    let dueDate;
                    if (this.state.delivery_date) {
                        dueDate = moment(this.state.delivery_date).add(this.state.pc_selected.days, 'days').format("YYYY-MM-DD");
                    }
                    else {
                        dueDate = moment(value).add(this.state.pc_selected.days, 'days').format("YYYY-MM-DD");
                    }
                    this.setState({ due_date: dueDate });
                }
                else if (type == "delivery_date") {
                    let dueDate = moment(value).add(this.state.pc_selected.days, 'days').format("YYYY-MM-DD");
                    this.setState({ due_date: dueDate });
                }
            }
            else {
                this.setState({ due_date: value });
            }
            
        }
    }

    renderStepOther()
    {
        return <div className="stepOther">

            <ApInput
                type="dateSafe"
                id="date"
                name="date"
                label={ tr('date') }
                value={ this.state.date ? this.state.date : '' }
                onChange={(e) => {
                    this.updateDueDate(e.target.value, "date");
                    this.setState({ date: e.target.value });
                }}
            />

            { this.renderRelated() }

            { this.renderInput( 'reference_your', tr('reference_number_your') ) }
            { this.renderInput( 'reference_our', tr('reference_number_our') ) }

            { this.state.type === 'delivery' && <ApInput
                type="dateSafe"
                id="need_date"
                name="need_date"
                label={ tr('day_of_need') }
                value={this.state.need_date ? this.state.need_date : ''}
                clearable
                onChange={(e) => this.setState({ need_date: e.target.value })}
            />}
            
            {this.state.type === 'delivery' && <ApInput
                type="dateSafe"
                id="delivery_date"
                name="delivery_date"
                label={tr('delivery_date')}
                value={this.state.delivery_date ? this.state.delivery_date : ''}
                clearable
                onChange={(e) => {
                    this.updateDueDate(e.target.value, "delivery_date");
                    this.setState({ delivery_date: e.target.value });
                }}
            />}

            <ApInput
                type="dateSafe"
                id="due_date"
                name="due_date"
                label={ tr('due_date') }
                value={ this.state.due_date ? this.state.due_date : '' }
                onChange={ ( e ) => this.setState({ due_date: e.target.value }) }
            />

            <ApInput
                type="textarea"
                id="details"
                name="details"
                label={ tr('additional_info') }
                autoComplete="off"
                value={ this.state.details ? this.state.details : '' }
                onChange={ ( e ) => this.setState({ details: e.target.value }) }
            />
        </div>
    }

    render()
    {
        //console.log("state", this.state);
        let initialStep = 0;

        if( this.props.type )
            initialStep = 1;

        return <div id="newOrder">
            <ApModalWizard
                header={ tr('new_order') }
                onOpen={ this.resetState }
                show={ this.props.show }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.save }
                loading={this.state.loading }
                activeStep={ initialStep }
                initialStep={ initialStep }

            />
        </div>
    }

}

export default NewOrder;
