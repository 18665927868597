import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';
import { debounce } from 'throttle-debounce';
import auth from 'services/Authed/Authed.js';

import { timeSince, formatMoney, keyExists, tr } from 'services/Helpers/Helpers.js';

import './Crm.css';
import ApConfirm 		from 'common/ApConfirm/ApConfirm.js';
import SvgIcon 			from 'common/SvgIcon/SvgIcon.js';
import ApReactTable 	from 'common/ApReactTable/ApReactTable.js';
import ApButton 		from 'common/ApButton/ApButton.js';
import ApTooltip 		from 'common/ApTooltip/ApTooltip.js';
import ApSelect			from 'common/ApSelect/ApSelect.js';
import ApSwitch			from 'common/ApSwitch/ApSwitch.js';
import { ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApModal            from 'common/ApModal/ApModal.js';

import NewCrmCompany from 'modules/Crm/NewCompany/NewCrmCompany.js';

export default class Crm extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
        	search: '',
        	searchLoading: false,

        	settingsLoaded: false,
        	loading: false,
        	customers: [],
        	pages: -1,
        	groups: [],
        	managers: [],
        	maxTurnover: 0,
			maxEmploys: 0,

        	showPassive: false,

        	confirmDeleteIds: false,

			newCompanyShow: false,
			
			showCRM: false,
			CrmEvents: null,
			addToGroup: false,
        };
        autoBind(this); 

        this.eventIcons = {
            "call":     "phone",
            "email":    "at",
            "visit":    "coffee",
            "-": 		"question",
        }

        this.getCustomerDataDebounced = debounce( 500 , this.getCustomerData );
 
    }

    componentDidMount()
	{
		this.getSettings();

		if( this.searchInput )
			this.searchInput.select();
	}

	getSettings()
    {
        api({
            method: 'get',
            url: 'crm/company/settings',
        }).then((response) => {
            //console.log('Settings', response);
            this.setState({ 
            	settingsLoaded: true,
            	groups: response.groups,
                managers: response.managers,
				maxTurnover: response.max.turnover,
				maxEmploys: response.max.employs,
            });
        }).catch((error) => {
            console.log('ERROR while fetching settings!');
        });
    }

	getCustomerData( state )
	{
		this.setState({ loading: true });
		api({
            method: 'post',
            url: 'crm/companies/apt',
            data: {
            	page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filterable ? state.filtered : [],
                showPassive: this.state.showPassive,
            },
        }).then((response) => {
            //console.log( 'getCustomerData', response );
            this.setState({
                customers: response.data,
                pages: response.pages,
                loading: false,
            });

        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('get_error') }</strong>,
            });
            this.setState({
                customers: [],
                pages: -1,
                loading: false,
            })
        });
	}

	getUserInfo(id)
    {
        api({
            method: 'get',
            url: 'usermanagement/user/get/' + id,
        }).then((response) => {
			return response;
		}).catch((error) => {
			console.log( error );
		});
	}
	

	togglePassiveSwitch()
	{
		this.setState({ showPassive: !this.state.showPassive }, () => {
			// Reload table
			if( this.tableRef && this.tableRef.reactTableRef )
				this.tableRef.reactTableRef.fireFetchData();
		});
	}

	getGroupList()
	{
		let options = [ { value: '', label: `- ${tr('all')} -` } ];
		this.state.groups.forEach( group => {
			options.push({ value: group.id, label: group.name });
		})
		return options;
	}

	getManagerList()
	{	
		let options = [ { value: '', label: `- ${tr('all')} -` }, { value: "null", label: tr('no_responsible_person') } ];
		this.state.managers.forEach( manager => {
			if( manager.active || this.state.showPassive )
				options.push({ value: manager.id, label: manager.name });
		})
		//console.log('managerlist', options)
		return options;
	}

	setActive( activity, indexes )
	{
		let ids = [];
    	indexes.forEach( index => {
    		ids.push( this.state.customers[ index ].id );
    	});
    	api({
			method: 'post',
			url: 'crm/companies/massedit',
			data: {
				ids: ids,
				column: "is_active",
				value: activity ? "true" : "false",
			}
		}).then((response) => {

			// Reload table
			if( this.tableRef && this.tableRef.reactTableRef )
				this.tableRef.reactTableRef.fireFetchData();

		}).catch((error) => {
			console.log(error);
		});
	}

	addToGroup( indexes )
	{
		let ids = [];
    	indexes.forEach( index => {
    		ids.push( this.state.customers[ index ].id );
    	});
        this.setState({
        	addToGroup: true,addToGroupSelected: ids,
        });
	}
	
	/*
	// TODO multiselect functions
	setAccountManager( indexes )
	{
		console.log( 'set account_manager_id...', indexes );
	}

	// TODO multiselect functions
	setIndustry( indexes )
	{
		console.log( 'set industry_id...', indexes );
	}

	// TODO multiselect functions
	addToGroups( indexes )
	{
		console.log( 'add to groups...', indexes );
	}
	*/

	confirmDelete( indexes ) 
    {
    	let ids = [];
    	indexes.forEach( index => {
    		ids.push( this.state.customers[ index ].id );
    	});

    	if( ids.length > 0 )
    		this.setState({ confirmDeleteIds: ids });
    }

    deleteCompanies()
    {
    	const ids = this.state.confirmDeleteIds;

    	if( !ids || ids.length === 0 )
    		return false;

    	//console.log('deleting...', ids );
    	api({
			method: 'post',
			url: 'crm/companies/remove',
			data: ids,
		}).then((response) => {
			//console.log('deleted!', response );
			
			// Reload table
			if( this.tableRef && this.tableRef.reactTableRef )
				this.tableRef.reactTableRef.fireFetchData();

		}).catch((error) => {
			console.log(error);
		});
    }

	showCompany( company )
	{
		if( ( "contact_id" in company ) && ( "id" in company ) )
			window.emitter.emit( 'goTo', { pathname: '/crm/company/' + company.id + '/contacts/' + company.contact_id } );

		else if( "id" in company )
			window.emitter.emit( 'goTo', { pathname: '/crm/company/' + company.id } );
	}

	handleCRM(e)
	{
		if ((this.state.showCRM === false) && (e.target.id === 'crm')) {
			this.setState({ showCRM: true });
		} else if ((this.state.showCRM === true) && (e.target.id === 'customers')){
			this.setState({ showCRM: false })
		}
	}
	
	downloadFile( file, name, type )
    {
        this.setState({loading: true });
        return api({
            method: 'get',
            url: `crm/companies/getfile/file/${ file }/${ type }`,
            responseType: 'blob',
            errorPopper: 'Tiedoston lataamisessa tapahtui virhe',
        }).then(( response ) => {
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${ name }.${ type }` );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
	}
	
	handleGroupSelect ( group ) {
		this.setState({ group: group });
	}
	
	addToGroups () {
		this.setState({ loading: true });
    	api({
			method: 'post',
			url: 'crm/companies/massedit',
			data: {
				ids: this.state.addToGroupSelected,
				column: "groups",
				value: this.state.group
			}
		}).then((response) => {
			this.setState({ group: [], loading: false, addToGroup:false, addToGroupSelected: []});
			// Reload table
			if( this.tableRef && this.tableRef.reactTableRef )
				this.tableRef.reactTableRef.fireFetchData();

		}).catch((error) => {
			console.log(error);
			this.setState({ loading: false });
		});
    	
	}

	onClose() {
		this.setState({ addToGroup: false, addToGroupSelected: [] });
	}
	
    renderAddToGroup()
    {
    	//console.log(this.state.groups);
        return (
            <ApModal
                show={ this.state.addToGroup }
                onOpen={ this.resetState }
                handleClose={ this.onClose }
                //closeFromBg
                className="addGroup addGroup-narrow"
                header={ 
                    <div className="padding-small">
						<h4>{tr("add_customers_to_group")}</h4>
                    </div>
                }
                body={ 
                    <div className="addGroup">
	
	                    <ApSelect
							label={tr("customer_groups")}
	                    value={ keyExists( this.state, "group" ) ? this.state.group : [] }
	                    valueRenderer={ (item) => {
	                        return (
	                            <div className="selectedGroup">
	                                <SvgIcon className="small-inline" icon="tag" type="solid" />
	                                { item.name }
	                            </div>
	                        );   
	                    }}
	                    optionRenderer={ (item) => {
	                        return (
	                            <div className="groupOption">
	                                <SvgIcon className="small-inline" icon="tag" type="solid" />
	                                { item.name }
	                            </div>
	                        );   
	                    }}
	                    onChange={ ( group ) => this.handleGroupSelect( group )  }
	                    objKeyId="id"
	                    objKeySearchable="name"
	                    multiselect
	                    options={ keyExists( this.state, "groups", true, [] ) }
	                    //apiUrl="hour/projects"
	                    //apiData={{ exluded: [] }}
	                    loading={ this.state.loading }
	                    disabled={ this.state.loading }
	                    readOnly={ !auth.hasModule( "clients.edit" ) }
	                />
		
	                    { this.state.error && 
	                        <div className="apErrorMsg">
	                            { this.state.error }
	                        </div>
	                    }

                    </div>
                }
                footer={
                    <div id="newCrmCompanyFooter">
    
                        <ApButton className="cancel" onClick={ this.onClose } disabled={ this.state.loading }>
                            <SvgIcon icon="times" type="solid" />
                            {tr("cancel")}
                        </ApButton>

                        <ApButton className={ "save" + ( !this.state.loading ? " highlight" : "" ) } color="blue" onClick={ this.addToGroups } disabled={ this.state.loading  } loading={ this.state.loading }>
                            <SvgIcon icon="save" type="solid" />
							{tr("save")}
                        </ApButton>
                    
                    </div>
                }

            />
        )

    }

    render() 
	{
		const getButton = ( file, title, type ) => {
                const icon = ( type === 'xml' ) ? 'file' : 'file';
                return <ApButton loading={ this.state.loading }
                    disabled={ this.state.loading }
                    onClick={ () => this.downloadFile( file, title, type ) }
					color="green" size="small"
                >
                    <SvgIcon icon={ icon } type="solid" />
					{tr("xml_material_to_fivaldi")}
                </ApButton>
        }
		
		var filedate = new Date();
		
        return (
        	<div id="pageCrm">
        		{this.renderAddToGroup()}
	            <div className="apBox">
						{ !this.state.showCRM && auth.hasModule( "clients.edit.add" ) &&
						<div style={{height: 50, float: 'right'}}>
		                    <div style={{marginRight: 16}}>
								{/* getButton( 'companies', 'Fivaldi_Companies_'+`${ filedate.getFullYear() }`+'_'+`${ filedate.getMonth() }`+'_'+`${ filedate.getDate() }`+'_'+`${ filedate.getHours() }`+'_'+`${ filedate.getMinutes() }`+'_'+`${ filedate.getSeconds() }`, 'xml' ) */}
		                    	<ApButton color="green" size="small" onClick={ () => this.setState({ newCompanyShow: true }) }>
			                		<SvgIcon icon="plus" type="solid" />
			                		{ tr('add_customer') }
			                	</ApButton>
		                    </div>
						</div>
		                }
	                <div className="padding">
		                <ApInputStack gap="0">
		                	<ApAddon noRightBorder width="50px" labelFor="search">
		                		<SvgIcon icon="search" type="solid" />
		                	</ApAddon>
			                <ApSelect
                                value=""
                                optionRenderer={ (item) => {
                                	if( item.type === "company" )
                                		return (
                                			<div className="searchOption company">
	                                			<SvgIcon className="typeIcon" icon="building" type="solid" />
	                                			<strong>{ item.name } &nbsp;
													{item.is_active === false && 
													<small style={{color: 'var(--clr-error-main)'}}>
														{`(${tr('inactive_2')})`}
													</small>}
												</strong><br />
	                                			<small>{ tr('business_id') }: { item.taxnumber }</small>
	                                		</div>
                                		);
                                	else if( item.type === "contact" )
                                		return (
                                			<div className="searchOption contact">
	                                			<SvgIcon className="typeIcon" icon="address-card" type="solid" />
	                                			<strong>{ item.name }</strong><br />
	                                			<small>{ item.title ? item.title : <span className="empty">{ tr('no_title') }</span> }</small>
	                                			<span className="company">
	                                				<SvgIcon className="small-inline" icon="building" type="solid" />
	                                				{ item.crm_company_name }
	                                			</span>
	                                		</div>
                                		);
                                }}
                                onChange={ ( item ) => { this.showCompany( item ) } }
                                objKeyId="id"
                                clearable
                                apiUrl="search/crm"
                            />
		                </ApInputStack>

		                <hr />
		                
		                { !this.state.settingsLoaded &&
		                	<div className="apMsg">
		                		<div className="apInlineLoader"></div>
		                	</div>
		                }

		                { this.state.settingsLoaded && 
			                <ApReactTable 
			                	ref={ node => this.tableRef = node }
			                	rememberId="crm_companies"
			                	manual
			                	onFetchData={ this.getCustomerDataDebounced }
			                	pages={ this.state.pages }

			                	showFiltersInitially
			                    loading={ this.state.loading }
			                    data={ this.state.customers } 
			                    columns={[
			                    	{
			                    		Header: () => {
                                            return (
                                                <div>
                                                    <label className="optionSwitch">
                                                        { tr('show_inactive') }
                                                        <ApSwitch
                                                            id="show-passive-switch"
                                                            on={ this.state.showPassive }
                                                            onChange={ this.togglePassiveSwitch }
                                                            small
                                                        />
                                                    </label>
                                                </div>
                                            );
                                        },
                                        customizable: true, 
                                        columns: [
	                                        {
					                            id: 'name', 
					                            Header: tr('name'), 
					                            accessor: "name",
					                            onClick: this.showCompany,
					                            Cell: ( props ) => {
					                                return (
					                                    <div className={ "nameCell" + ( !props.original.is_active ? " passive" : "" ) }>
					                                        <strong>{ props.original.name }</strong><br />
					                                        <small>{ props.original.taxnumber ? props.original.taxnumber : <span style={{ color: "#aaa" }}>{ tr('no_business_id') }</span> }</small>
					                                    </div>
					                                );
					                            }, 
					                            customFilter: {
					                            	type: "text",
					                            	placeholder: tr('enter_name_business_id'),
					                            	// This is not used when manual (backend) mode is available
					                            	//fields: [ "name", "_original.taxnumber" ]
					                            },
					                        },
					                        /*
					                        { 
					                            id: 'is_active', 
					                            Header: "Tila", 
					                            accessor: "is_active",
					                            align: "center",
					                            width: 150, 
					                            Cell: ( props ) => {
					                            	return (
					                            		<div className={ "apStatusBox " + ( props.original.is_active ? "success" : "error" ) }>
					                            			{ props.original.is_active ? "Aktiivinen" : "Ei käytössä" }
					                            		</div>
					                            	)
					                            },                   
					                            customizable: true,     
					                            showInitially: true, 
					                            customFilter: { 
					                            	type: "select",
					                            	options: [
					                            		{ value: '', label: " - Kaikki - " },
					                            		{ value: true, label: "Aktiivinen", filter: ( row ) => { return row.is_active } },
					                            		{ value: false, label: "Ei käytössä", filter: ( row ) => { return !row.is_active } },
					                            	] 
					                            },
					                        },
					                        */
					                        { 
					                            id: 'account_manager_id', 
					                            Header: tr('responsible_person'), 
					                            accessor: "account_manager_id",
					                            Cell: ( props ) => {
					                            	return ( props.original.account_manager_id 
					                            		? <span>{ props.original.account_manager.name }</span>
					                            		: <span className="empty">-</span>
					                            	);
					                            },
					                            customizable: true,
					                            customFilter: {
					                            	type: "select",
					                            	options: this.getManagerList()
												}       
					                        },
					                        { 
					                            id: 'groups', 
					                            Header: tr('customer_group'), 
					                            accessor: "groups",
					                            Cell: ( props ) => {
					                            	return (
					                            		<div>
					                            			{ props.original.groups.map( ( group, index ) => {
					                            				return (
					                            					<div className="group">{ group.name }</div>
					                            				);
					                            			})}
					                            		</div>
					                            	);
					                            },                   
					                            customizable: true, 
					                            sortable: false,    
					                            showInitially: true, 
					                            customFilter: { 
					                            	type: "select",
					                            	options: this.getGroupList()
					                            },
					                        },
					                        { 
					                            id: 'industry', 
					                            Header: tr('main_business'), 
					                            accessor: "industry_id",
					                            Cell: ( props ) => {
					                            	if( props.original.industry )
						                            	return (
						                            		<div>
						                            			{ props.original.industry.code }:<br />
						                            			<small>{ props.original.industry.name }</small>
						                            		</div>
						                            	);
						                            return <span className="empty">-</span>;
					                            },                   
					                            customizable: true,     
					                            showInitially: true, 
					                            customFilter: { 
					                            	type: "text",
					                            },
					                        },
					                        { 
					                            id: 'turnover', 
					                            Header: tr('turnover'), 
					                            accessor: "turnover",
					                            align: "right", 
					                            Cell: ( props ) => {
					                            	return ( props.original.turnover 
					                            		? formatMoney( props.original.turnover, 0 ) + " €" 
					                            		: <span className="empty">-</span>
					                            	);
					                            },  
					                            customizable: true,
					                            showInitially: true,
					                            customFilter: {
					                            	type: "range",
					                            	min: 0,
					                            	step: this.state.maxTurnover > 1000000 ? 10000 : this.state.maxTurnover > 100000 ? 1000 : this.state.maxTurnover > 10000 ? 100 : 1,
					                            	max: this.state.maxTurnover,
					                            	valueRenderer: ( value ) => { return formatMoney( value[0], 0 ) + " - " + formatMoney( value[1], 0 ) + " €" }
					                            }             
					                        },
					                        { 
					                            id: 'employs', 
					                            Header: tr('personnel'), 
					                            accessor: "employs", 
					                            align: "right",
					                            customizable: true,
					                            customFilter: {
					                            	type: "range",
					                            	min: 0,
					                            	step: this.state.maxEmploys > 1000 ? 10 : 1,
					                            	max: this.state.maxEmploys,
					                            	valueRenderer: ( value ) => { return formatMoney( value[0], 0 ) + " - " + formatMoney( value[1], 0 ) + ` ${tr('people_abbr')}` }
					                            }                            
					                        },
					                        { 
					                            id: 'lastEvent', 
					                            Header: tr('latest_event'), 
					                            accessor: "last_event.occurred_at", 
					                            align: "center",
					                            className: "overflowableOnHover",
					                            Cell: ( props ) => {

					                            	if( !props.original.last_event )
					                            		return <span className="empty">-</span>;

					                            	return (
					                            		<div>
					                            			<ApTooltip text={ 
					                            				<div>
					                            					<span>{ moment( props.original.last_event.occurred_at ).format( 'DD.MM.YYYY' ) }:</span><br />
					                            					<strong>
					                            						<SvgIcon className="small-inline" icon={ this.eventIcons[ props.original.last_event.type ] } type="solid" />
					                            						{ props.original.last_event.title }
					                            					</strong>
					                            				</div> }>
					                            				{ timeSince( props.original.last_event.occurred_at ) }
					                            			</ApTooltip>
					                            		</div>
					                            	);
					                            }, 
					                            customizable: true,
					                            showInitially: true,
					                            filterable: false,                   
					                        },
					                        { 
					                            id: 'verified', 
					                            Header: tr('verified'), 
					                            accessor: "verified", 
					                            width: 100,
					                            className: "overflowableOnHover",
					                            Cell: ( props ) => {
					                            	let icon = "question-circle";
					                            	let tooltip = tr('unverified');
					                            	let className = "unverified";
					                            	if( props.original.verified )
					                            	{
					                            		const daysAgo = moment().diff( moment( props.original.verified) , "days" );
					                            		if( daysAgo > 365 * 5 )
					                            			className = "verified over5Years";
					                            		else if (daysAgo > 365 )
					                            			className = "verified overYear";
					                            		else 
					                            			className = "verified";

					                            		icon = "check-circle";
					                            		tooltip = timeSince( props.original.verified );
					                            	}
					                            	return (
						                            	<div className={ "iconCell " + className }>
						                            		<ApTooltip text={ tooltip }>
						                            			<SvgIcon icon={ icon } />
						                            		</ApTooltip>
						                            	</div>
					                            	);
					                            }, 
					                            customizable: true,
					                            customFilter: { 
					                            	type: "select",
					                            	options: [
					                            		{ value: "", label: `- ${tr('all')} -` },
					                            		{ value: true, label: tr('verified'), filter: ( row ) => { return row.verified } },
					                            		{ value: false, label: tr('unverified'), filter: ( row ) => { return !row.verified } },
					                            		{ 
					                            			value: moment().subtract( 1, 'week' ).format('YYYY-MM-DD'), 
					                            			label: tr('over_week_ago'), 
					                            			filter: ( row, value ) => { return moment( value ).isSameOrAfter( moment( row.verified ) ); }  
					                            		},
					                            		{ 
					                            			value: moment().subtract( 1, 'month' ).format('YYYY-MM-DD'), 
					                            			label: tr('over_month_ago'), 
					                            			filter: ( row, value ) => { return moment( value ).isSameOrAfter( moment( row.verified ) ); }  
					                            		},
					                            		{ 
					                            			value: moment().subtract( 3, 'month' ).format('YYYY-MM-DD'), 
					                            			label: tr('over_3month_ago'), 
					                            			filter: ( row, value ) => { return moment( value ).isSameOrAfter( moment( row.verified ) ); }  
					                            		},
					                            		{ 
					                            			value: moment().subtract( 6, 'month' ).format('YYYY-MM-DD'), 
					                            			label: tr('over_6month_ago'), 
					                            			filter: ( row, value ) => { return moment( value ).isSameOrAfter( moment( row.verified ) ); }  
					                            		},
					                            		{ 
					                            			value: moment().subtract( 1, 'year' ).format('YYYY-MM-DD'), 
					                            			label: tr('over_year_ago'), 
					                            			filter: ( row, value ) => { return moment( value ).isSameOrAfter( moment( row.verified ) ); }  
					                            		},
					                            		{ 
					                            			value: moment().subtract( 2, 'year' ).format('YYYY-MM-DD'), 
					                            			label: tr('over_2year_ago'), 
					                            			filter: ( row, value ) => { return moment( value ).isSameOrAfter( moment( row.verified ) ); }  
					                            		},
					                            		{ 
					                            			value: moment().subtract( 5, 'year' ).format('YYYY-MM-DD'), 
					                            			label: tr('over_5year_ago'), 
					                            			filter: ( row, value ) => { return moment( value ).isSameOrAfter( moment( row.verified ) ); }  
					                            		},
					                            	] 
					                            },                   
					                        },
					                        { 
					                            id: 'projects', 
					                            Header: tr('stats_projects'), 
					                            accessor: "projects_count", 
					                            width: 100,
					                            align: "right",
					                            customizable: true,  
					                            filterable: false,                           
					                        },
					                        { 
					                            id: 'updated_at', 
					                            Header: tr('last_edited'), 
					                            accessor: acc => acc.updated_at ? moment(acc.updated_at).format('L HH:mm') : null, 
					                            width: 100,
					                            customizable: true,  
					                            filterable: false,                           
					                        },
					                   ],
					                }
			                    ]}
			                    defaultSorted={[ 
			                    	{ id: "lastEvent", desc: true }
					          	]}
			                    filterable
			                    multiselect={[
			                        { 
			                        	icon: "tags", 
			                        	label: "Lisää ryhmään", 
			                        	action: ( indexes ) => this.addToGroup( indexes ), 
			                        	closeAfter: true,
			                        	disabled: !auth.hasModule('clients.edit'),
			                        },
			                        {
			                        	icon: "toggle-on", 
			                        	label: tr('activate_selected'), 
			                        	action: ( indexes ) => this.setActive( true, indexes ), 
			                        	closeAfter: true,
			                        	disabled: !auth.hasModule('clients.edit'),
			                       	},
			                        { 
			                        	icon: "toggle-off", 
			                        	label: tr('disable_selected'), 
			                        	action: ( indexes ) => this.setActive( false, indexes ), 
			                        	closeAfter: true,
			                        	disabled: !auth.hasModule('clients.edit'),
			                        },
			                        //{ divider: true },
			                        //{ icon: "user-tie", label: "Aseta vastuuhenkilö...", action: ( indexes ) => this.setAccountManager( indexes ), closeAfter: true },
			                        //{ icon: "industry", label: "Aseta päätoimiala...", action: ( indexes ) => this.setIndustry( indexes ), closeAfter: true },
			                        //{ icon: "tag", label: "Lisää asiakasryhmään...", action: ( indexes ) => this.addToGroups( indexes ), closeAfter: true },
			                        { 
			                        	divider: true 
			                        },
			                        { 
			                        	icon: "trash-alt", 
			                        	label: tr('delete_selected'), 
			                        	action: ( indexes ) => this.confirmDelete( indexes ), 
			                        	closeAfter: true, 
			                        	unselectAfter: true,
			                        	disabled: !auth.hasModule('clients.edit.remove'),
			                        },
			                    ]}
			                    rowActions={ (row) => 
			                        <div className="apSimpleButton" onClick={ () => this.showCompany( row ) }><SvgIcon icon="arrow-right" type="solid" /></div>
			                    }
			                    customRowClass={ ( row ) => {
			                    	return ( keyExists( row, "original.is_active", true, true ) ? "active" : "notActive" );
			                    }}
			                    minRows={10}
			                />
			            }
	                </div>
	            </div>

            	<ApConfirm
                    show={ this.state.confirmDeleteIds }
                    onClose={ () => this.setState({ confirmDeleteIds: false }) }
                    onConfirm={ this.deleteCompanies }
                    header={ tr('delete_customer_confirm') }
                    body={ 
                        <div>
                            <p>
                                <strong>{ tr('delete_sure') } { this.state.confirmDeleteIds.length === 1 ? ` ${tr('one_customer')}` : this.state.confirmDeleteIds.length + ` ${tr('multiple_customers')}` }?</strong>
                            </p>
                            <p>{ tr('delete_customer_note') }</p>
                        </div>
                    }
                />

                <NewCrmCompany
                    show={ this.state.newCompanyShow }
                    onClose={ () => this.setState({ newCompanyShow: false }) }
                    onSave={ ( newId ) => this.showCompany( { id: newId } ) }
                />

            </div>

        );
    }
}
