import React from 'react';
import autoBind from 'react-autobind';

import auth from 'services/Authed/Authed.js';
import api from 'services/Api/Api.js';
import { keyExists, dateInputToSql, sqlToDateInput, tr, currentLang, availableLanguages, translateLangCode } from 'services/Helpers/Helpers.js';
import BasicInfo from './tabs/BasicInfo.js';
import Permits from './tabs/Permits.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import ApFormPage 		from 'common/ApFormPage/ApFormPage.js'
import LayoutSettings from './tabs/LayoutSettings.js';
import OrganizationalChart from './tabs/OrganizationalChart.js';
import UserReports from './tabs/UserReports.js';
export default class UserPages extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			loading: false,
			unsavedChanges: false,
			errorMsg: false,
			data: false,
			permits: [],
			layoutChanged: false,
			blocks: [],
			languageOptions: [],
		};
		autoBind(this);
	}

	componentDidMount()
    {
			this.getProfile();
			this.getPermits();
			this.getLanguages();
    }

	getProfile()
	{
		this.setState({ loading: true });
		api({
            method: 'get',
            url: 'currentUser',
        }).then((response) => {

            if( keyExists( response, "person_detail.birthday" ) )
            	response.person_detail.birthday = sqlToDateInput( response.person_detail.birthday );

            this.setState({ 
            	data: response,
            	loading: false,
            	unsavedChanges: false,
            });
        }).catch((error) => {
        	console.log( error );
        	this.setState({ 
        		errorMsg: keyExists( error, "response.data.message", true, "Odottamaton virhe, tietoja ei voitu tallentaa" ),
        		loading: false
        	});
        });
	}

	getPermits()
	{
		this.setState({ loading: true });
		api({
            method: 'get',
            url: 'currentUser/getpermits',
        }).then((response) => {
            this.setState({ 
				permits: response,
            	loading: false,
            });
        }).catch((error) => {
        	console.log( error );
        	this.setState({ 
        		loading: false
        	});
        });
	}

	getLanguages()
	{
		api({
			method: 'get',
			url: 'languages/all'
		}).then((response) => {
			const languageOptions = [];
			response.forEach(lang => {
				if (availableLanguages().some(item => item.code === lang.code)) {
					languageOptions.push({
						id: lang.id,
						value: lang.id,
						label: translateLangCode(lang.code)
					});
				}
			});
			this.setState({languageOptions: languageOptions});
		}).catch((error) => {
			console.log('Error while fetching languages.');
		})
	}

	handleChange( table, column, value )
	{
		const data = { ...this.state.data };

		if( !keyExists( data, table + "." + column ) )
		{
			return false;
		}

		data[ table ][ column ] = value;

		this.setState({ 
			data: data,
			unsavedChanges: true 
		});
	}
	
	saveProfile()
	{
		if (this.state.layoutChanged) {
			this.setState({loading: true})
			
			api({
				method: 'post',
                url: 'currentUser/updateblocksorder',
                data: {
                    blocks: this.state.blocks
                }
			}).then((response) => {
				this.setState({
					loading: false, 
					layoutChanged: false})
			}).catch((error) => {
				this.setState({
					loading: false, 
					errorMsg: error.message})
			})

			if (!this.state.unsavedChanges) {
				return
			}
		}
		
		let data = { ...this.state.data };
		data.person_detail.birthday = dateInputToSql( data.person_detail.birthday );

		this.setState({ loading: true });
		api({
            method: 'post',
            url: 'currentUser/save',
            data: data,
        }).then((response) => {
        	auth.reloadSettings( this.getProfile );
        }).catch((error) => {
        	console.log( error );
        	this.setState({ 
        		errorMsg: keyExists( error, "response.data.message", true, "Odottamaton virhe, tietoja ei voitu tallentaa" ),
        		loading: false
        	});
        });
	}

	handleLoading(loading) {
		this.setState({loading: loading})
	}

	handleLayoutChange(layoutChange) {
		this.setState({layoutChanged: layoutChange})
	}

	setBlocks(blocks)
	{
		this.setState({blocks: blocks})
	}

	render()
	{
		return(
			<ApFormPage 
				onSave={ this.saveProfile } 
				loading={ this.state.loading } 
				unsaved={ this.state.unsavedChanges || this.state.layoutChanged }
				error={ this.state.errorMsg }
			>

			<div className="apBoxHeader">
				<h1> { tr('user_page') } </h1>
				<p> { tr('user_page_info') } </p>
			</div>
		
				<ApTabs>

					<ApTab icon="user" label={ tr('basics') }>
						<BasicInfo 
							data={this.state.data}
							loading={this.state.loading}
							unsavedChanges={this.state.unsavedChanges}
							errorMsg={this.state.errorMsg}
							handleChange={this.handleChange}
							languageOptions={this.state.languageOptions}
						/>
					</ApTab>

					<ApTab icon="id-card" label={ tr('permits') }>
						<Permits 
							permits={this.state.permits}
							getPermits={this.getPermits}
						/>
					</ApTab>

					<ApTab icon="brush" label={ tr('layout_settings') }>
						<LayoutSettings 
							handleLoading={this.handleLoading}
							handleLayoutChange={this.handleLayoutChange}
							setBlocks={this.setBlocks}
							blocks={this.state.blocks}
							onSave={this.saveProfile}
							stateLoading={ this.state.loading } 
							stateUnsavedChanges={ this.state.unsavedChanges }
						/>
					</ApTab>

					<ApTab icon="chart-pie" label={ tr('reports') } mountOnSelect>
						<UserReports />
					</ApTab>
					
					{(this.state.data && this.state.data.show_organization_chart) &&
					<ApTab icon="sitemap" label={ tr('organization') } mountOnSelect>
						<OrganizationalChart />
					</ApTab>}

				</ApTabs>

			</ApFormPage>
		);

	}

}