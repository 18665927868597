/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import { keyExists, tr } from 'services/Helpers/Helpers.js';

import ApButton 		from 'common/ApButton/ApButton.js';
import SvgIcon 			from 'common/SvgIcon/SvgIcon.js';
import { ApInput } 		from 'common/ApInput/ApInput.js';

export default class UserProfile extends React.Component
{
	constructor( props )
	{
		super( props );
		this.state = {
			loading: false,
			errorMsg: false,
			changed: false,
			
			currentPassword: '',
			newPassword: '',
			newPassword2: '',
		};
		autoBind(this); 
	}

	buttonDisabled()
	{
		if( this.state.currentPassword.length == 0 || 
			this.state.newPassword.length == 0 ||
			this.state.newPassword !== this.state.newPassword2 )
			return true;

		return false;
	}

	changePassword()
	{
		let data = {
			current: this.state.currentPassword,
			new: this.state.newPassword,
		};
		
		this.setState({ loading: true, changed: false });
		api({
            method: 'post',
            url: 'currentUser/changepassword',
            data: data,
        }).then((response) => {

        	this.setState({ 
        		loading: false,
				errorMsg: false,
				changed: true,
				currentPassword: '',
				newPassword: '',
				newPassword2: '',
        	});

        }).catch((error) => {
        	console.log( error );
        	this.setState({ 
        		errorMsg: keyExists( error, "response.data.message", true, "Odottamaton virhe, salasanaa ei voitu vaihtaa" ),
        		loading: false
        	});
        });
	}

	render()
	{

		return(
			<div className="apBox">
					
				<div className="apBoxHeader">
					<h1>{ tr('change_password') }</h1>
				</div>
				
				<div className="padding">

					{ this.state.changed &&
						<div className="apSuccessMsg"> { tr('password_changed') } </div>
					}

					{ this.state.errorMsg &&
						<div className="apErrorMsg">{ this.state.errorMsg }</div>
					}

					<div className="clear">
						<div className="apColumn w50">
							<div className="padding">
							
								<ApInput
									type="password"
									label={ tr('current_password') }
									id="currentPassword"
									name="currentPassword"
									value={ this.state.currentPassword }
									onChange={ (e) => this.setState({ currentPassword: e.target.value }) }
									validationState={ this.state.currentPassword.length == 0 ? "error" : "" }
									loading={ this.state.loading }
									disabled={ this.state.loading }
								/>
								
								<div className="apInfo small">
	                                <SvgIcon icon="info-circle" type="solid" />
	                                { tr('change_password_note1') }
	                            </div>

							</div>
						</div>
						<div className="apColumn w50">
							<div className="padding">

								<ApInput
									type="password"
									label={ tr('new_password') }
									id="newPassword"
									name="newPassword"
									value={ this.state.newPassword }
									onChange={ (e) => this.setState({ newPassword: e.target.value }) }
									validationState={ this.state.newPassword.length == 0 ? "error" : "" }
									loading={ this.state.loading }
									disabled={ this.state.loading }
								/>

								<ApInput
									type="password"
									label={ tr('confirm_new_password') }
									id="newPassword2"
									name="newPassword2"
									value={ this.state.newPassword2 }
									onChange={ (e) => this.setState({ newPassword2: e.target.value }) }
									validationState={ this.state.newPassword2.length == 0 || this.state.newPassword != this.state.newPassword2 ? "error" : "" }
									loading={ this.state.loading }
									disabled={ this.state.loading }
								/>

								<div className="apInfo small">
	                                <SvgIcon icon="info-circle" type="solid" />
	                                { tr('change_password_note2') }
	                            </div>

							</div>
						</div>
					</div>

					<ApButton color="blue" onClick={ this.changePassword } disabled={ this.buttonDisabled() }>
						<SvgIcon icon="key" type="solid" />
						{ tr('change_password') }
					</ApButton>
				</div>

			</div>
		);

	}

}
