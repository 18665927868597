/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import nextId from 'services/NextId/NextId.js';
import api from 'services/Api/Api.js';
import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { debounce } from 'throttle-debounce';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';

import { errorPopper, tr } from 'services/Helpers/Helpers.js';

import './CodeSelect.css';

class CodeSelect extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            loading: false,
            tailExists: false,
            nextFree: null,
        };

        this.checkTailExistsDebounced = debounce( 200 , this.checkTailExists );

        this.inputId = nextId('CodeSelect');

        autoBind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if( this.props.tail !== prevProps.tail )
            this.checkTailExistsDebounced( this.props.tail );
    }

    onChange( code )
    {
        let tail = this.props.tail;

        if( code
        && code.ancestors
        && ( code.ancestors.length + 1 === this.props.codeLevelCount )
        && !this.state.nextFree
        ){
            tail = ''; // stupid workaround to get the nextfree value
        }

        this.props.onChange( code, tail )
    }

    onTailChange( value )
    {
        this.props.onChange( this.props.code, value );
    }

    checkTailExists( value )
    {
        let data = {
            code_id: this.props.code ? this.props.code.id  : null,
            code_tail: value,
        };
        if( this.props.componentId )
            data.exclude_ids = [ this.props.componentId ];

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'storage/component/code/exists',
            data: data,
        }).then(( response ) => {
            if( typeof this.props.afterTailCheck )
                this.props.afterTailCheck( response.exists );

            this.setState({
                loading: false,
                tailExists: response.exists,
                nextFree: response.next,
            });
        }).catch((error) => {
            this.setState({
                loading: false,
                tailExists: true,
            });
        });
    }

    currentlyInputingTail()
    {
        // When code format is free, we only input the tail
        if( this.props.codeLimit && this.props.codeLimit.is_free )
            return true;
        if( !this.props.code ) return false;
        return ( this.props.code.ancestors.length + 1 === this.props.codeLevelCount );
    }

    renderLabel()
    {
        let tooltip = this.props.skipped ? tr('storage_code_skipped') : tr('storage_code_in_use');
        const skipToggleDom = <ApTooltip position="topleft" text={ tooltip }>
            <ApSwitch
                small
                id={`code-skipped-toggle-${ this.inputId }`}
                disabled={ this.props.loading || this.props.locked }
                on={ !this.props.skipped }
                onChange={ ( e ) => { this.props.onSkippedChange( e.target.checked ) } }
            />
        </ApTooltip>
        return <span>{ skipToggleDom } { tr('storage_code') }</span>
    };

    renderLabelValue( code )
    {
        if( !this.currentlyInputingTail() ) return code.code;
        const separator = this.props.codeSeparator ? this.props.codeSeparator : '';
        const tail = this.props.tail ? this.props.tail : ''
        return `${ code.code }${ separator }${ tail }`;
    };

    render()
    {
        let validationState = this.props.validationState;
        let validationText = this.props.validationText;
        let tailInput = null;

        if( this.currentlyInputingTail() )
        {
            if( this.props.tailValidationState )
            {
                validationState = this.props.tailValidationState;
                validationText = this.props.tailValidationText;
            }
            if( this.state.tailExists ) validationState = 'error';

            tailInput = {
                placeholder: tr('component_specific'),
                value: this.props.tail,
                onChange: ( e ) =>  this.onTailChange( e ? e.target.value : null ),
            }
        }

        let value = this.props.code;
        if( this.props.skipped )
        {
            validationState = null;
            value = null;
            tailInput = {
                placeholder: tr('skipped'),
                value: '',
                onChange: ( e ) =>  this.onTailChange( e.target.value ),
            }
        }

        let errorDom = null;
        let nextFreeDom = null;
        if( validationText )
        {
            errorDom = <div className="apErrorMsg">{ validationText }</div>
            if( this.state.nextFree && this.currentlyInputingTail() )
                nextFreeDom = <div className="nextFree">
                    <div className="title">{ tr('next_available') }</div>
                    <div className="code">{ this.state.nextFree }</div>
                </div>
        }

        return <div className="codeSelect">
            <ApNestedSelect
                label={ this.renderLabel }
                valueRenderer={ this.renderLabelValue }
                value={ value }
                parentRenderer="value"
                parentTooltipRenderer="name"
                optionRenderer={ (item) => {
                    return <div className="codeOption">
                        <span className="name">{ item.nameTree }</span>
                        <span className="code">{ item.code }</span>
                    </div>
                }}
                onChange={ this.onChange }
                apiUrl="storage/component/code/search"
                loading={ this.props.loading }
                disabled={ this.props.loading || this.props.skipped }
                validationState={ validationState }
                tailInput={ tailInput }
            />
            { errorDom }
            { nextFreeDom }
        </div>
    }
}

export default CodeSelect;
