import ApReactTable from "common/ApReactTable/ApReactTable";
import React from "react";
import autobind from "react-autobind";
import { getExcel, mapTree, tr } from "services/Helpers/Helpers";
import _ from "lodash";
import { ApAddon, ApInput, ApInputStack } from "common/ApInput/ApInput";
import moment from "moment";

class TrackingReportsProgress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedType: localStorage.getItem('progressReportType') ? localStorage.getItem('progressReportType') : "sum",
            data: [],
            loading: false,
        };
        autobind(this);
    }

    componentDidMount() {
        this.parseData();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.project && prevProps.project.id) != (this.props.project && this.props.project.id)) {
            this.parseData();
        }
    }

    parseData() {
        const type = this.state.selectedType;
        localStorage.setItem('progressReportType', type);
        let data=[];

        switch (type) {
            case "sum":
                mapTree([this.props.project], item => {
                    const projectComponents = _.cloneDeep(item.components);
                    let sum = projectComponents.reduce((total, projectComponent) => {
                        if (!projectComponent.alloc_count) projectComponent.alloc_count = 0;
                        if (!projectComponent.installed_count) projectComponent.installed_count = 0;

                        total.alloc_count = Number(total.alloc_count) + Number(projectComponent.alloc_count);
                        total.installed_count = Number(total.installed_count) + Number(projectComponent.installed_count);

                        return total;
                    }, {alloc_count: 0, installed_count: 0, project: item.name, project_code: item.project_code});
                    data.push(sum);
                    return item;
                });

                data = data.map(row => {
                    return this.calculateReadiness(row);
                });
                data = _.orderBy(data, 'project_code', 'asc');
                break;

            case "all":
            case "single":
                mapTree([this.props.project], item => {
                    if (this.state.selectedType === "single" && item.id !== this.props.project.id) return item;
                    const projectComponents = _.cloneDeep(item.components);
                    projectComponents.forEach(projectComponent => {
                        projectComponent.project_id = item.id;
                        projectComponent.project = item.name;
                        projectComponent.project_code = item.project_code;
                        if (!projectComponent.alloc_count) projectComponent.alloc_count = 0;
                        if (!projectComponent.installed_count) projectComponent.installed_count = 0;

                        const foundIndex = data.findIndex(c => c.component.id == projectComponent.component.id && c.project_id == item.id);

                        if (foundIndex === -1) {
                            data.push(projectComponent);
                        } else {
                            data[foundIndex].alloc_count = Number(data[foundIndex].alloc_count) + Number(projectComponent.alloc_count);
                            data[foundIndex].installed_count = Number(data[foundIndex].installed_count) + Number(projectComponent.installed_count);
                        }
                    })
                    let totalRow = projectComponents.reduce((total, row) => {
                        if (!row.alloc_count) row.alloc_count = 0;
                        if (!row.installed_count) row.installed_count = 0;

                        total.alloc_count += Number(row.alloc_count);
                        total.installed_count += Number(row.installed_count);

                        return total;
                    }, {
                        project_id: item.id,
                        project: `${item.name} ${tr('in_total')}`,
                        project_code: item.project_code,
                        name: "-",
                        alloc_count: 0,
                        installed_count: 0,
                        component: {},
                        totalRow: true,
                    })
                    totalRow = this.calculateReadiness(totalRow);
                    data.push(totalRow);

                    

                    return item;
                });

                data = data.map(item => {
                    return this.calculateReadiness(item);
                })
                data = _.orderBy(data, 'project_code', 'asc');
                if (type === 'all') {
                    let allTotal = data.reduce((total, row) => {
                        if (row.totalRow) return total;

                        if (!row.alloc_count) row.alloc_count = 0;
                        if (!row.installed_count) row.installed_count = 0;

                        total.alloc_count += Number(row.alloc_count);
                        total.installed_count += Number(row.installed_count);

                        return total;
                    }, {
                        project_id: data[0].id,
                        project: tr('in_total'),
                        project_code: "-",
                        name: "-",
                        alloc_count: 0,
                        installed_count: 0,
                        component: {},
                    });
                    allTotal = this.calculateReadiness(allTotal);
                    data.push(allTotal);
                }
                break;
        
            default:
                break;
        }

        this.setState({data});
        return data;
    }

    calculateReadiness(item) {
        item.degree_of_readiness_estimate = item.alloc_count === 0 || item.installed_count === 0 
            ? 0 + "%"
            : (Number(item.installed_count) / Number(item.alloc_count) * 100).toFixed(2) + "%";

        return item;
    }

    getColumns() {
        switch (this.state.selectedType) {
            case "sum":
                return this.renderProgressReportSumColumns();
            case "single":
            case "all":
                return this.renderProgressReportColumns();
        
            default:
                return [{
                    id: 'name',
                    Header: tr('project'),
                    accessor: 'name',
                    customizable: false,
                    showInitially: true,
                    customFilter: {
                        type: "text",
                        placeholder: tr('name'),
                    }
                }];
        }
    }

    renderProgressReportSumColumns() {
        const columns = [
            {
				id: 'project',
				Header: tr('project'),
				accessor: 'project',
				customizable: false,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('project'),
                },
			},
            {
				id: 'project_code',
				Header: tr('project_code'),
				accessor: 'project_code',
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('project_code'),
                },
			},
            {
				id: 'alloc_count',
				Header: tr('allocated_quantity'),
				accessor: 'alloc_count',
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('allocated_quantity'),
                },
			},
            {
				id: 'installed_count',
				Header: tr('installed'),
				accessor: 'installed_count',
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('installed'),
                },
			},
            {
				id: 'degree_of_readiness_estimate',
				Header: tr('degree_of_readiness_estimate'),
				accessor: 'degree_of_readiness_estimate',
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('degree_of_readiness_estimate'),
                },
			},
        ];

        return columns;
    }

    renderProgressReportColumns() {
        const columns = [
            {
				id: 'project',
				Header: tr('project'),
				accessor: 'project',
				customizable: false,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('project'),
                },
			},
            {
				id: 'project_code',
				Header: tr('project_code'),
				accessor: 'project_code',
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('project_code'),
                },
			},
            {
				id: 'name',
				Header: tr('storage_component'),
				accessor: 'name',
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('storage_component'),
                },
			},
            {
				id: 'alloc_count',
				Header: tr('allocated_quantity'),
				accessor: props => props.alloc_count ? Number(props.alloc_count) : 0,
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('allocated_quantity'),
                },
			},
            {
				id: 'installed_count',
				Header: tr('installed'),
				accessor: props => props.installed_count ? Number(props.installed_count) : 0,
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('installed'),
                },
			},
            {
				id: 'degree_of_readiness_estimate',
				Header: tr('degree_of_readiness_estimate'),
				accessor: 'degree_of_readiness_estimate',
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('degree_of_readiness_estimate'),
                },
			},
        ];

        return columns;
    }

    async getExcel() {
        if (!this.state.data.length) return;
        const data = _.cloneDeep(this.state.data);
        const excelData = this.handleExcelData(data);

        this.setState({ loading: true });
        await getExcel(excelData, `${tr('progress_report')} ${this.props.project.name} ${moment().format("DD.MM.YYYY")}`, tr('progress_report'));
        this.setState({ loading: false });
    }

    handleExcelData(data) {
        const type = this.state.selectedType;

        switch (type) {
            case "sum":
                const parsedData = data.map(row => {
                    return {
                        project: row.project,
                        project_code: row.project_code,
                        allocated_quantity: row.alloc_count,
                        installed: row.installed_count,
                        degree_of_readiness_estimate: row.degree_of_readiness_estimate
                    }
                });
                const inTotal = parsedData.reduce((total, item) => {
                    total.allocated_quantity += (item.allocated_quantity && !isNaN(item.allocated_quantity)) ? Number(item.allocated_quantity) : 0;
                    total.installed += (item.installed && !isNaN(item.installed)) ? Number(item.installed) : 0;
                    return total;
                }, {
                    project: tr('in_total'),
                    project_code: '-',
                    allocated_quantity: 0,
                    installed: 0,
                    degree_of_readiness_estimate: 0
                });
                inTotal.degree_of_readiness_estimate = inTotal.allocated_quantity === 0 || inTotal.installed === 0 
                    ? 0 + "%"
                    : (Number(inTotal.installed) / Number(inTotal.allocated_quantity) * 100).toFixed(2) + "%";
                parsedData.push(inTotal);
                return parsedData;
        
            case "all":
            case"single":
                return this.handleProjectProgress(type, data);
                

            default:
                return [];
        }
    }

    handleProjectProgress(type, data) {
        const parsedData = data.map(row => {
            return {
                project: row.project,
                project_code: row.project_code,
                storage_component: row.name,
                allocated_quantity: row.alloc_count ? Number(row.alloc_count) : 0,
                installed: row.installed_count ? Number(row.installed_count) : 0,
                degree_of_readiness_estimate: row.degree_of_readiness_estimate,
            }
        });

        return parsedData;
    }

    render() {
        const actions = [
            {
                icon: 'file-excel',
                label: tr('create_excel'),
                action: this.getExcel,
                unselectAfter: false,
                closeAfter: true,
                alwaysOn: true,
            }
        ];
        return (
            <>
                <div style={{marginTop: "1rem"}}>
                    <ApInputStack gap="0">
                        <ApAddon width="200">{tr('type')}</ApAddon>
                        <ApInput 
                            type='select'
                            options={[
                                {label: tr('get_progress_report_sum'), value: "sum"},
                                {label: tr('get_progress_report_single'), value: "single"},
                                {label: tr('get_progress_report_all'), value: "all"},
                            ]}
                            onChange={(e) => this.setState({selectedType: e.target.value}, this.parseData)}
                            id="selectedType"
                            name="selectedType"
                            value={ this.state.selectedType }
                        />
                    </ApInputStack>
                </div>
                {this.state.selectedType == "sum" ? <ApReactTable 
                    data={this.state.data}
                    columns={this.getColumns()}
                    rememberId="TrackingReportsSum"
                    multiselect={actions}
                    showFiltersInitially={true}
                    filterable
                /> : null}
                {['all', 'single'].includes(this.state.selectedType) ? <ApReactTable 
                    data={this.state.data}
                    columns={this.getColumns()}
                    rememberId="TrackingReports"
                    multiselect={actions}
                    showFiltersInitially={true}
                    filterable
                /> : null}
            </>
        );
    }
}

export default TrackingReportsProgress;