import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';

import AssignmentsTable from './AssignmentsTable.js';
import ExtAssignments from './ExtAssignments/ExtAssignments.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import EditAssignment from './EditAssignment/EditAssignment.js';
import './AssignmentsTable.css';
import { tr } from 'services/Helpers/Helpers.js';
import { connect } from 'react-redux';

const tabs = [
    {
        name: 'upcoming',
        icon: 'calculator',
        label: tr('drafts'),
        description: tr('upcoming_assignments'),
    },
    {
        name: 'current',
        icon: 'folder-open',
        label: tr('active_count'),
        description: tr('active_assignments'),
    },
    {
        name: 'ended',
        icon: 'folder-minus',
        label: tr('closed_count'),
        description: tr('closed_assignments'),
    },
    {
        name: 'all',
        icon: 'list',
        label: tr('all'),
        description: tr('all'),
    },
    {
        name: 'archived',
        icon: 'archive',
        label: tr('archived_multiple'),
        description: tr('waiting_for_approval1')
    },
    {
        name: 'waiting_approval',
        icon: 'hourglass-half',
        label: tr('waiting_for_approval1'),
        description: tr('waiting_for_approval1')
    },
];

class Assignments extends React.Component {

    constructor(props) {

        super(props);
        const params = this.props.match.params;
        let tab = (params.tab) ? this.getTabId(params.tab) : 1;
        this.state = {
            selectedTab: tab,
            showNewModal: this.props.match.params.newAssignment ? this.props.match.params.newAssignment : false,
            addToProject: null,
            allowExtAssignments: false,

            counts: {
                upcoming: 0,
                current: 0,
                ended: 0,
                all: 0,
                waitingApproval: 0,
            }
        };
        // this.selectedTab = this.props.match.params.tab 
        //     && parseInt(this.props.match.params.tab) < tabs.length ?
        //         parseInt(this.props.match.params.tab)
        //         : 1;

        autoBind(this);

    }

    UNSAFE_componentWillMount() {
        const id = parseInt(this.props.match.params.id, 10);
        if (!isNaN(id)) {
            this.setState({ addToProject: id, showNewModal: true });

        }
        //this.getClasses();
        //this.getPriorities();
    }

    getAssignments(name = null) {
        if (!name) name = tabs[this.selectedTab].name;
        this.setState({ loading: true });
        api({
            method: 'get',
            url: `assignments/get/`,
            errorPopper: tr('get_error'),
        }).then((response) => {
            this.setState({
                assignments: response,
                loading: false
            });


        }).catch((error) => {
            this.setState({
                loading: false
            });
        })
    }
    getTabId(param) {

        // if (param in tabIds)
        //     return parseInt(param, 10);
        // tabs.forEach((value,key) => {
        //     if()
        // });
        // console.log(param);
        // console.log(Object.keys(tabs).find(key => tabs[key].name === param));
        return parseInt(Object.keys(tabs).find(key => tabs[key].name == param));
    }

    handleCount(tab, val) {
        this.setState({ counts: { ...this.state.counts, [tab]: val } });
    }

    renderTab(tab) {
        let filterBar = null;
        let offerFilter;


        if (tab.name === 'current') {
            return <ApTab key={tab.name} icon={tab.icon} label={tab.label} badge={this.state.counts.current == 0 ? false : this.state.counts.current}>
                <div className="padding">
                    <AssignmentsTable tabSelected='1' handleCount={this.handleCount} tabName='current' history={this.props.history} />
                </div>
            </ApTab>
        }
        if (tab.name === 'upcoming') {
            return <ApTab key={tab.name} icon={tab.icon} label={tab.label} badge={this.state.counts.upcoming == 0 ? false : this.state.counts.upcoming}>
                <div className="padding">
                    <AssignmentsTable tabSelected='0' handleCount={this.handleCount} tabName='upcoming' history={this.props.history} />
                </div>
            </ApTab>
        }
        if (tab.name === 'ended') {
            return <ApTab key={tab.name} icon={tab.icon} label={tab.label}>
                <div className="padding">
                    <AssignmentsTable tabSelected='2' handleCount={this.handleCount} tabName={'ended'} history={this.props.history} />
                </div>
            </ApTab>
        }
        if (tab.name === 'all') {
            return <ApTab key={tab.name} icon={tab.icon} label={tab.label}>
                <div className="padding">
                    <AssignmentsTable tabSelected='3' handleCount={this.handleCount} tabName={'all'} history={this.props.history} />
                </div>
            </ApTab>
        }
        if (tab.name === 'waiting_approval' && this.props.apProjectSettings.allow_ext_assignments) {
            return <ApTab key={tab.name} icon={tab.icon} label={tab.label} badge={this.state.counts.waitingApproval == 0 ? false : this.state.counts.waitingApproval}>
                <div className="padding">
                    <ExtAssignments tabSelected='4' handleCount={this.handleCount} tabName='waitingApproval' history={this.props.history} />
                </div>
            </ApTab>
        }
        if (tab.name === 'archived') {
            return <ApTab key={tab.name} icon={tab.icon} label={tab.label} align="right" mountOnSelect>
                <div className="padding">
                    <AssignmentsTable tabSelected='archived' tabName={'archived'} history={this.props.history} />
                </div>
            </ApTab>
        }
    }

    clickEditAssignment() {

        this.setState({ showNewModal: true });

    }

    closeEditAssignment() {

        this.setState({ showNewModal: false }, () => { });

    }

    tabChange(index) {
        const tab = tabs[index];
        if (tab) {
            // this.selectedTab = index;
            this.setState({ selectedTab: index });
            this.props.history.replace(`/assignments/${tabs[index].name}`);
        }
    }

    renderTabi(num) {
        return <div className="padding">
            {this.selectedTab}

        </div>
    }

    render() {

        return (

            <div id="assignments" className="apBox">

                <div className="apBoxHeader">
                    <h1> {tr('assignments')} </h1>
                    <p> {tr('assignments_info')} </p>
                    <div className="apBoxCorner">

                        <ApButton
                            color="green"
                            onClick={() => this.clickEditAssignment()}
                        >
                            {tr('new_assignment')}
                        </ApButton>
                    </div>
                </div>
                <ApTabs selected={this.state.selectedTab} onChange={this.tabChange} >

                    {tabs.map(t => { return this.renderTab(t); })}

                </ApTabs>






                {this.state.showNewModal &&
                    <EditAssignment
                        show={this.state.showNewModal}
                        addToProject={this.state.addToProject}
                        onClose={this.closeEditAssignment}
                        edit={(id) => this.clickEditAssignment(id)}
                    />
                }

            </div>

        );

    }

}

const mapStateToProps = state => ({
    apProjectSettings: state.apProjectSettings
});

export default connect(mapStateToProps)(Assignments);
