/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import './WorkPermits.css';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import PermitModalForm from './PermitModalForm.js';
import ApList from 'common/ApList/ApList.js';
import ApButton from 'common/ApButton/ApButton.js';
import { tr } from 'services/Helpers/Helpers.js';


export default class WorkPermits extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			permits: [],
			showRemoveConfirmDialog: false,
			showPermitModal: false,
			selectedPermitId: null,
			changes:false,
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		this.getWorkPermits();

	}

	getWorkPermits()
	{

		api({
			method: 'get',
			url: 'company/permits/get'
		}).then((response) => {
			let permits = response;
			this.setState({ permits: permits });
		}).catch((error) => {
			console.log(error);
		});

	}
	
	clickEditPermit(id)
	{

		this.setState({
			showPermitModal: true,
			selectedPermitId: id
		});

	}

	clickDeletePermit(id)
	{

		//e.stopPropagation();
		this.setState({
			showRemoveConfirmDialog: true,
			selectedPermitId: id
		});

	}

	deletePermit(id)
	{

		api({
			method: 'delete',
			url: 'company/permits/delete',
			params: { permitId: id }
		}).then((response) => {
			for (var i = 0; i < this.state.permits.length; i++)
			{
				if (this.state.permits[i].id === id)
				{
					let permits = this.state.permits;
					permits.splice(i, 1);
					this.setState({ permits: permits });
				}
			}
		}).catch((error) => {
			console.log(error);
		});

	}

	onSavePermit()
	{

		this.setState({ showPermitModal: false, selectedPermitId: null });
		this.getWorkPermits();

	}

	createPermit(pos)
	{
		
		this.setState({
			showPermitModal: true,
			newPermitPos:pos,
		});

	}
	renderItem(permit) {
		return (
			<div>
				<strong>{permit.name}</strong><br />
				{permit.groups.length > 0 &&
					<small>{tr('visible_to_groups') + ": "}
					{permit.groups.map((group, index) => {
							let nameString = group.name;
						if (index < permit.groups.length - 1)
								nameString += ', ';
							return (<strong>{nameString}</strong>);
						})}
						<br /></small>}
				{permit.users.length > 0 &&
					<small>{tr('visible_to_users') + ": "}
					{permit.users.map((user, index) => {
						let nameString = user.full_name;
						if (index < permit.users.length - 1)
							nameString += ', ';
						return (<strong>{nameString}</strong>);
					})}
						<br /></small>}
				{permit.groups.length === 0 && permit.users.length === 0 &&
					<small> {tr('visible_to_everyone')} </small>
				}
			</div>
			
			
		);
	}
	editItem(item) {
		this.clickEditPermit(item.id);
	}
	getListActions() {
		return [
			{
				label: tr('delete'),
				icon: "trash",
				disabled: false,
				action: (item, closeFunc) => {
					this.clickDeletePermit(item.id);
					closeFunc();
				}
			},
		]
	}

	handleChange(value, afterFunc = false) {
		//console.log(value);
		this.setState({
			permits:value,
			changes: true,
		});
		
		//let newState = {};
		//newState[key] = value;
		//newState.unsavedChanges = true;
		//this.setState(newState, () => {
			//if (typeof (afterFunc) === "function")
			//	afterFunc();
		//});
	}

	saveOrder() {
		api({
			method: 'post',
			url: 'company/permits/savePermitOrder',
			data: {
				permits: this.state.permits,
			}
		}).then((response) => {
			this.setState({
				changes:false,
			})
			window.emitter.emit('popper', {
				type: 'success',
				content: <strong>{tr('saved')}</strong>,
			});
		}).catch((error) => {
			console.log(error);
		});
	}



	

	render()
	{

		return(

			<div id="WorkPermits">
				<div className="apBox">
                    <div className="apBoxHeader">
                        <h1>{ tr('work_permit_settings') }</h1>
                        <small>{ tr('work_permits_info') }</small>
                    </div>	
					<div className="list">
						<ApList
							loading={this.props.loading}
							items={this.state.permits || []}
							itemRenderer={this.renderItem}
							columns={[
								{
									style: { width: "250px",padding:"5px" }, value: (item) => <div>
										{item.note_before_expiry_dates != null  &&
											<small>
												{tr("reminder") + " " + tr("to_supervisor").toLowerCase() + ": "}<strong>{item.note_before_expiry_dates + " " + tr("days")}</strong>
											<br /></small>}
										{item.note_before_expiry_dates_to_user!=null &&
										<small>
											{tr("reminder") + " " + tr("to_user").toLowerCase() + ": "}<strong>{item.note_before_expiry_dates_to_user+" " + tr("days")}</strong>
												<br /></small>}
										{item.note_before_expiry_dates == null && item.note_before_expiry_dates_to_user == null &&
											<small>
											{tr("no_notifications")}
											</small>}
									</div>
								},
							]}
							onClick={(item) => this.editItem(item)}
							actions={this.getListActions("hour_types")}
							validationState={(item) =>
								item.removed ? "removed" :
									item.unsaved ? "warning" :
										""
							}
							icon="id-card"
							sortable
							onSort={(items) => this.handleChange(items)}
							addNewItem={(pos) => this.createPermit(pos)}
						/>
                    	
                    	
                    </div>				
				</div>

				{this.state.showRemoveConfirmDialog &&
					<ApConfirm
						show={this.state.showRemoveConfirmDialog}
						header={ `${tr('delete_confirm')}?` }
						body={ `${tr('delete_sure')}?` }
						onConfirm={() => {this.deletePermit(this.state.selectedPermitId)}}
						onClose={() => {this.setState({ showRemoveConfirmDialog: false, selectedPermitId: null })}}
					/>
				}

				{this.state.showPermitModal &&
					<PermitModalForm 
						show={this.state.showPermitModal}
						permitId={this.state.selectedPermitId}
						onClose={() => {this.setState({ showPermitModal: false, selectedPermitId: null })}}
						onSave={() => { this.onSavePermit() }}
						newPermitPos={this.state.newPermitPos}
					/>
				}

				<div className="padding">
					<ApButton className={"save" + (this.state.changes ? " highlight" : "")}
						color="blue"
						onClick={this.saveOrder}
						//disabled={!this.state.formValid}
						style={{ float: "right" }}>
						<SvgIcon icon="save" type="solid" />
						{tr('save')}
					</ApButton>
				</div>

			</div>

		);

	}

}
