import React from 'react';
import autoBind from 'react-autobind';
import Highlighter from "react-highlight-words";

import ApSelect from 'common/ApSelect/ApSelect.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import api, { apiurl } from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import ComponentEdit from 'modules/Storage/Components/ComponentEdit/ComponentEdit.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';

import CitList from 'modules/Storage/common/ComponentInstanceTable/CitList/CitList.js';
import CreateStock from '../../../Storage/PurchaseOrders/EditPurchaseOrder/CreateStock/CreateStock.js';

import { ApInputSmall } from 'common/ApInputSmall/ApInputSmall.js';

import {
    ApInputStack
    , ApAddon,
    ApInput
} from 'common/ApInput/ApInput.js';

import {
    formatMoney
    , roundToDecimals
    , groupify
    , removeRoundOff
    , sqlToDateInput
    , onlyNumber
    , tr
    , capitalize,
    keyExists,
    formatCurrency,
    errorPopper
} from 'services/Helpers/Helpers.js';

import {
    getTypeIcon
    , componentTypes
} from 'modules/Storage/common/StorageHelpers.js';

import 'modules/Storage/common/ComponentInstanceTable/Cit.css';
import AllowanceEntry from 'modules/Timetracking/Entry/Modal/AllowanceEntry.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApModal from 'common/ApModal/ApModal.js';


class ComponentSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),

            componentEditShow: false,
            componentEditId: null,

            listSelectShow: false,

            filterSupplierPrice: true,

            confirmPoPriceShow: false,
            confirmPoPriceText: null,
            confirmPoPriceMeta: null,

            groups: [],

            selected: [],

            showCreate: false,
            selectedComponent: null,
            selectedRow: null,

            costCenterEditShow: false,

            selectedComponentFiles: null,
        }

        this.newCounter = 0;

        autoBind(this);
    }

    onComponentEditSave() {
        // TODO need to figure out what parts should be updated and what to keep

        this.setState({
            componentEditShow: false,
            componentEditId: null,
        });
    }

    goToPurchaseOrder(id) {
        window.emitter.emit('goTo', { pathname: `/storage/purchase/order/id/${id}` });
    }

    setGroup(component, value) {
        let components = this.props.components.slice(0);
        components = components.map(c => {
            if (c.id === component.id)
                c.order_group_id = value;
            return c;
        });
        this.props.componentsChange(components);

    }

    // ---------------------------------------
    // Add Component
    // ---------------------------------------
    //
    addComponent(component) {
        const recId = this.props.receiverId == undefined ? this.props.poTargetId : this.props.receiverId;
        const data = {
            instance: this.props.instance,
            supplierId: this.props.supplierId,
            //receiverType: this.props.receiverType,
            receiver_type: this.props.receiverType,
            project_id: this.props.project_id,
            //receiverId: recId,
            receiver_id: recId,
            poId: this.props.poId,
            componentId: component.id,
            componentTitle: component.title,
        }

        api({
            method: 'post',
            url: `/storage/component/${component.id}/format`,
            data: data
        }).then((response) => {
            
            this.props.addComponent(response,this.props.id);
            
            // if (response.purchase_order_price === null) {
            //     response.purchase_order_price = response.price
            // }
            // if (this.props.defaultCrmTax != null) {
            //     for (let tax of this.props.vats) {
            //         if (tax.id === this.props.defaultCrmTax) {
            //             response.order_vat = tax.value;
            //             break;
            //         }
            //     }
            // }
            // if (this.props.defaultCrmTaxCode !== null) {
            //     for (let code in this.props.TaxCodes) {
            //         if (this.props.TaxCodes[code].id === this.props.defaultCrmTaxCode) {
            //             response.order_netvisor_vat = code;
            //             break;
            //         }
            //     }
            // }

            // if (this.props.useTaxGroups) {
            //     const setDefaultTaxGroup = (key) => {
            //         if (!this.props[key]) return;
            //         if (!this.props[key].vat_id && !this.props[key].tax_code) return;

            //         for (let tax of this.props.vats) {
            //             if (tax.id === this.props[key].vat_id) {
            //                 response.order_vat = tax.value;
            //                 break;
            //             }
            //         }
            //         for (let code in this.props.TaxCodes) {
            //             if (this.props.TaxCodes[code].id === this.props[key].tax_code) {
            //                 response.order_netvisor_vat = code;
            //                 break;
            //             }
            //         }
            //         response.tax_group = this.props[key];
            //         response.tax_group_id = this.props[key].id;
            //     }
            //     switch (response.type_id) {
            //         case 1:
            //             setDefaultTaxGroup('defaultItemTaxGroup');
            //             break;
            //         case 2:
            //             setDefaultTaxGroup('defaultWorkTaxGroup');
            //             break;
            //         case 3:
            //             setDefaultTaxGroup('defaultExpenceTaxGroup');
            //             break;

            //         default:
            //             break;
            //     }
            // }

            // this.newCounter++;
            // response.id = `new${this.newCounter}`;
            // response.discaunt_percent = component.discaunt_percent ? component.discaunt_percent : null;
            // if (this.props.defaultCostCenters) {
            //     response.cost_center_id = this.props.defaultCostCenters;
            // }

            // if (response.conversion_enabled) {
            //     const key = response.purchase_order_price
            //         ? 'default_in_purchase_orders'
            //         : response.order_price
            //             ? 'default_in_orders'
            //             : null;
            //     if (key) {
            //         console.log(response);
            //         const foundUnit = response.conversion_units.find(item => item[key] === true);
            //         if (foundUnit) {
            //             response.selectedUnit = foundUnit;
            //             if (key === 'default_in_purchase_orders') {
            //                 response.purchase_order_price = parseFloat(response.purchase_order_price) / parseFloat(foundUnit.conversion_factor);
            //             } else if ('default_in_orders') {
            //                 response.order_price = parseFloat(response.order_price) / parseFloat(foundUnit.conversion_factor);
            //             }
            //         }
            //     }
            // }

            // // Aseta vastaanottava varastopaikka id automaattisesti jos kyseessä on varastosiirto ja vastaanottaja on projektivarasto
            // if (this.props.instance === 'delivery' && this.props.deliveryReceiverType === 'project_storage') {
            //     response.delivery_to_id = this.props.deliveryReceiverId;
            //     response.delivery_to_type = 'project';
            // }
            // // Aseta vastaanottava varastopaikka id automaattisesti jos kyseessä on varastosiirto ja lähettäjä on projektivarasto
            // if (this.props.instance === 'delivery' && this.props.deliverySenderType === 'project_storage') {
            //     response.delivery_from_id = this.props.deliverySenderId;
            //     response.delivery_from_type = 'project';
            // }
            // // Aseta lähettävä ja vastaanottava varastopaikka kun kyseessä normaali varasto
            // if (this.props.instance === 'delivery' && this.props.deliveryReceiverType === 'location') {
            //     const foundLocation = response.locations.find(location => {
            //         if (location.delivery_locations) {
            //             for (let dl of location.delivery_locations) {
            //                 if (this.props.deliveryReceiverId === dl.id) return true;
            //             }
            //         }
            //         return false;
            //     })
            //     response.delivery_to_id = foundLocation ? foundLocation.id : null;
            //     response.delivery_to_type = foundLocation ? 'location' : null;
            // }
            // if (this.props.instance === 'delivery' && this.props.deliverySenderType === 'location') {
            //     const foundLocation = response.locations.find(location => {
            //         if (location.delivery_locations) {
            //             for (let dl of location.delivery_locations) {
            //                 if (this.props.deliverySenderId === dl.id) return true;
            //             }
            //         }
            //         return false;
            //     })
            //     response.delivery_from_id = foundLocation ? foundLocation.id : null;
            //     response.delivery_from_type = foundLocation ? 'location' : null;
            // }

            // // Enable creating duplicate rows in assignment for components
            // if (this.props.instance === 'assignment') {
            //     response.newRow = true;
            // }


            // let components = this.props.components.slice(0);
            // components.push(response);
            // this.props.componentsChange(components);
            // let newComponent = components.slice(-1)[0];
            // if (newComponent.locations.length == 1) {
            //     this.setPOTarget(newComponent, 'location', newComponent.locations[0]);
            // } else if (this.props.receiverType == 'location' && this.props.receiverCode) {
            //     //Kokeile löytää lokaatioista varastopaikka joka löytyy varaston alta
            //     const recCode = this.props.receiverCode;
            //     const def = response.locations.find(l => l.code.toString().split('.')[0] == recCode);
            //     if (def) { this.setPOTarget(newComponent, 'location', def); }
            // } else if (this.props.receiverType == 'project') {
            //     this.setPOTarget(newComponent, newComponent.locations[0].type, newComponent.locations[0]);
            // }


        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{tr('get_error')}</strong>,
            });
        });
    }

    componentAddOutsideStorage(ct) {
        const recId = this.props.receiverId == undefined ? this.props.poTargetId : this.props.receiverId;
        api({
            method: 'post',
            url: `/storage/component/0/format`,
            data: {
                typeId: ct.id,
                instance: this.props.instance,
                supplierId: this.props.supplierId,
                receiver_type: this.props.receiverType,
                project_id: this.props.project_id,
                receiver_id: recId,
                poId: this.props.poId,
            }
        }).then((response) => {
            // this.newCounter++;
            // let components = this.props.components.slice(0);
            // response.id = this.props.getNextId();
            this.props.addComponent(response, this.props.id,true);
            // if (this.props.defaultCostCenters) {
            //     response.cost_center_id = this.props.defaultCostCenters;
            // }
            // if (this.props.defaultCrmTax) {
            //     response.order_vat = this.props.vats.find(element => element.id === this.props.defaultCrmTax).value;
            // }
            // if (this.props.defaultCrmTaxCode) {
            //     let codes = this.props.TaxCodes;
            //     for (let key in codes) {
            //         if (codes[key].id === this.props.defaultCrmTaxCode) {
            //             response.order_netvisor_vat = key;
            //         }
            //     }

            // }
            // components.push(response);
            //console.log("lisätään",response);
            // this.props.componentsChange(components);
            // let newComponent = components.slice(-1)[0]
            // if (newComponent.locations.length >= 1) {
            //     this.setPOTarget(newComponent, newComponent.locations[0].type, newComponent.locations[0]);
            // }


        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{tr('get_error')}</strong>,
            });
        });



    }


    // ----------------------------------------
    //  Seelect stuff
    // ----------------------------------------
    //
    toggleListSelect() {
        this.setState({ listSelectShow: !this.state.listSelectShow });
    }

    selectFromTable(id) {
        this.addComponent({ id: id });
    }

    dropDownOption(item, search) {
        const searches = search.toLowerCase().split(' ').filter(s => s.length > 0);

        let identifiers = [];
        item.identifiers.forEach((i) => {
            if (typeof i.value !== 'string' || i.value.length === 0)
                return null;

            const found = searches.some(s => i.value.toLowerCase().includes(s.toLowerCase()));
            if (found) {
                identifiers.push(
                    <div className="sIdentifier" key={i.id}>
                        <span className="name">{i.name}:</span>
                        <span className="value">
                            <Highlighter
                                searchWords={searches}
                                autoEscape={true}
                                textToHighlight={i.value}
                            />
                        </span>
                    </div>
                );
            }
        });

        let lCount = item.locations.length;
        let balance = 0;
        let balanceFree = 0;
        item.locations.forEach(l => {
            balance += parseFloat(l.balance);
            balanceFree += parseFloat(l.balance_free);
        });

        let locationsDom = null;
        let balanceDom = null;
        let balanceFreeDom = null;

        if (lCount > 1)
            locationsDom = <span className="balanceLocations"> {tr('storages_count')}: {lCount} </span>;

        if (lCount > 0)
            balanceDom = <span className="balance"> {tr('balance')}: {removeRoundOff(balance)}{item.unit} </span>;

        if (balance !== balanceFree)
            balanceFreeDom = <span className="balanceFree"> {tr('free')}:{removeRoundOff(balanceFree)}{item.unit} </span>;

        return <div className="sComponent">
            <strong>
                <Highlighter
                    searchWords={searches}
                    autoEscape={true}
                    textToHighlight={item.name}
                />
            </strong><br />
            <small>
                <Highlighter
                    searchWords={searches}
                    autoEscape={true}
                    textToHighlight={item.code}
                />
            </small><br />
            {locationsDom}
            {balanceDom}
            {balanceFreeDom}
            {identifiers}
        </div>
    }


    // ----------------------------------------
    //  Component select
    // ----------------------------------------

    renderComponentSelect() {
        let supplierPriceToggle = null;

        if (this.props.instance === 'po' || this.props.instance === 'supplier_offer') {
            supplierPriceToggle = <ApAddon width="180px" className="only-current-sypplier">
                <ApTooltip position="topright" text={tr('show_storage_components_with_supplier')}>
                    <div className="text-right">
                        {tr('supplier')}
                        <ApSwitch
                            id={"supplier-price-toggle-" + this.props.id}
                            on={this.state.filterSupplierPrice}
                            onChange={() => { this.setState({ filterSupplierPrice: !this.state.filterSupplierPrice }) }}
                            inline
                            small
                            disabled={this.props.disabled}
                        />
                    </div>
                </ApTooltip>
            </ApAddon>
        }

        let menuActions = [];
        if (this.props.instance !== 'assignment') {
            menuActions.push({
                label: this.state.listSelectShow ? tr('hide_selection_list') : tr('show_selection_list'),
                icon: 'list',
                action: (item, closeFunc) => {
                    this.toggleListSelect()
                    closeFunc();
                },
            });
        }
        let outsideStorageTypes = [];
        if (this.props.instance === 'po')
            outsideStorageTypes = ['item', 'other', 'work'];
        else if (this.props.instance === 'order')
            outsideStorageTypes = ['item', 'other', 'work'];
        else if (this.props.instance === 'assignment')
            outsideStorageTypes = ['item'];
        else if (this.props.instance === 'supplier_offer')
            outsideStorageTypes = ['item'];

        outsideStorageTypes = componentTypes.filter(ct => {
            return outsideStorageTypes.includes(ct.name);
        });
        if (outsideStorageTypes.length > 0) {
            menuActions.push({ "divider": tr('add_external_component') });
            outsideStorageTypes.forEach(ct => {
                menuActions.push({
                    label: ct.text,
                    icon: getTypeIcon(ct.name),
                    action: (item, closeFunc) => {
                        this.componentAddOutsideStorage(ct);
                        closeFunc();
                    },
                });
            });
        }

        let menuDom = <ApAddon noLeftBorder width="40px">
            <ApDropdown
                actions={menuActions}
                button={<SvgIcon icon="ellipsis-h" type="solid" />}
                disabled={this.props.disabled}
            />
        </ApAddon>

        let instanceOptions = {};
        if (this.state.filterSupplierPrice)
            instanceOptions.supplierId = this.props.supplierId;
        if (this.state.filterSupplierPrice && this.props.suppliers && Array.isArray(this.props.suppliers))
            instanceOptions.supplierIds = this.props.suppliers.map(supplier => supplier.id);

        if (this.state.listSelectShow) {
            return <div>
                <ApInputStack gap="0">
                    <ApAddon><div>&nbsp;</div></ApAddon>
                    {supplierPriceToggle}
                    {menuDom}
                </ApInputStack>
                <CitList
                    ref={node => this.citList = node}
                    onSelect={this.selectFromTable}
                    instance={this.props.instance}
                    instanceOptions={instanceOptions}
                />
            </div>

        }
        if (this.props.instance === 'po') {

            return <ApInputStack gap="0">
                <ApSelect
                    label={tr('search_storage_component')}
                    loading={this.state.loading}
                    value=""
                    // optionRenderer={ this.dropDownOption }
                    optionRenderer="component_detail_price_po"
                    onChange={this.addComponent}
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="storage/components"
                    apiData={{
                        instance: this.props.instance,
                        instanceOptions: instanceOptions,
                        include: ['locations', 'identifiers'],
                        formatter: 'instance',
                        type: this.props.receiverType,
                    }}
                    disabled={this.props.disabled}
                />
                {supplierPriceToggle}
                {menuDom}
            </ApInputStack>
        }

        if (this.props.instance === 'order') {

            return <ApInputStack gap="0">
                <ApSelect
                    label={tr('search_storage_component')}
                    loading={this.state.loading}
                    value=""
                    // optionRenderer={ this.dropDownOption }
                    optionRenderer="component_detail_price"
                    onChange={this.addComponent}
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="storage/components"
                    apiData={{
                        instance: this.props.instance,
                        instanceOptions: instanceOptions,
                        include: ['locations', 'identifiers'],
                        formatter: 'instance',
                        type: this.props.receiverType,
                        crmId: this.props.crmId ? this.props.crmId : null,
                    }}
                />
                {supplierPriceToggle}
                {menuDom}
            </ApInputStack>
        }

        else if (this.props.instance === 'delivery') {
            const exludeIds = this.props.components.map(c => c.component_id);
            return <ApInputStack gap="0">
                <ApSelect
                    label={tr('search_storage_component')}
                    loading={this.state.loading}
                    value=""
                    // optionRenderer={ this.dropDownOption }
                    optionRenderer="component_detail"
                    onChange={this.addComponent}
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="storage/components"
                    apiData={{
                        instance: this.props.instance,
                        instanceOptions: instanceOptions,
                        include: ['locations', 'identifiers'],
                        formatter: 'instance',
                        type: this.props.receiverType,
                        filter_by_location_id: this.props.deliverySenderId,
                        exludeIds: exludeIds,
                    }}
                    disabled={this.props.deliveryComponentsDisabled}
                />
                {/* { menuDom } */}
            </ApInputStack>
        }
        else {
            return <ApInputStack gap="0">
                <ApSelect
                    label={tr('search_storage_component')}
                    loading={this.state.loading}
                    value=""
                    // optionRenderer={ this.dropDownOption }
                    optionRenderer="component_detail"
                    onChange={this.addComponent}
                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="storage/components"
                    apiData={{
                        instance: this.props.instance,
                        instanceOptions: instanceOptions,
                        include: ['locations', 'identifiers'],
                        formatter: 'instance',
                        type: this.props.receiverType,
                    }}
                />
                {supplierPriceToggle}
                {menuDom}
            </ApInputStack>
        }
    }

    render() {
        return this.renderComponentSelect();
    }   
}

export default ComponentSelector;