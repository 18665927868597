
import React from 'react';

class PublicInfo extends React.Component {

    // This page is an example how to make public pages
    render() 
    {
        return (
            <div>
                Yleistä tietoa
            </div>
        );

    }
}
export default PublicInfo
