import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import auth from   'services/Authed/Authed.js';
import { hasPermissions, tr } from 'services/Helpers/Helpers.js';

import './HolidaySections.css';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

class HolidaySections extends React.Component {

    constructor(props)
    {
        super(props);
        autoBind(this);
    }

    goTo( form )
    {
        window.emitter.emit( 'goTo', {
            pathname: `/workhours/holidays/${ form }`,
        });
    }

    renderSection( data )
    {
        let classes = ["section"];
        let onClick = () => this.goTo( data.form );

        if( data.form === this.props.form )
        {
            classes.push( "selected" );
            onClick = null;
        } else if( data.disabled )
        {
            classes.push( "disabled" );
            onClick = null;
        }

        return <div className={ classes.join(" ") } onClick={ onClick }>
            <SvgIcon icon={ data.icon } type="solid" />
            <div className="padding" style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                <strong>{ data.name }</strong>
                <small>{ data.desc }</small>
            </div>
        </div>
    }

    render()
    {    
        return <div className="holidaySections">

            { this.renderSection({
                icon: 'suitcase',
                name: tr('annual_leave'),
                desc: tr('planning_management_review'),
                form: 'annualholidays',
                disabled: !hasPermissions('timetracking.holiday.annualholidays')
            })}

            { this.renderSection({
                icon: 'user-clock',
                name: tr('flexitime_count'),
                desc: tr('management_review'),
                form: 'flextime',
                disabled: !hasPermissions('timetracking.holiday.flextime')
            })}

            { this.renderSection({
                icon: 'hourglass-half',
                name: tr('unpaid_leave'),
                desc: tr('management_review'),
                form: 'longerworkinghourscompensation',
                disabled: !hasPermissions('timetracking.holiday.longerworkinghourscompensation')
            })}

        </div>
    }
}


export default HolidaySections;
