import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import React from 'react';
import autoBind from 'react-autobind';
import { keyExists, tr } from 'services/Helpers/Helpers';

class UserNetvisorInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newId: 1,
        };

        autoBind(this);
    }

    handleParameterValueChange(parameter, value) {
        const salaryParameters = JSON.parse(JSON.stringify(keyExists(this.props, 'data.netvisor_salary_parameters', true, false)));
        const foundIndex = salaryParameters.findIndex(item => item.rationumber === parameter.rationumber);
        salaryParameters[foundIndex].value = value;

        this.props.onChange( "netvisor_salary_parameters_changes", true );
        this.props.onChange( "netvisor_salary_parameters", salaryParameters );
    }

    renderSalaryParameters() {
        const salaryParameters = keyExists(this.props, 'data.netvisor_salary_parameters', true, false);
        return !salaryParameters 
            ? <p></p>
            : this.props.data.netvisor_salary_parameters.map((parameter, index) => {
                return (
                    <ApInputStack gap="0" key={index}>
                        <ApAddon noRightBorder>
                            {parameter.name ? parameter.name : `${tr('salary_type')} ${parameter.rationumber}`}
                        </ApAddon>
                        <ApInput
                            width="50%"
                            type="number"
                            id="netvisor_salary_parameters"
                            name="netvisor_salary_parameters"
                            label={ tr('value') }
                            value={ parameter.value }
                            onChange={ ( e ) => { this.handleParameterValueChange(parameter, e.target.value) } }
                            loading={ this.props.loading }
                            disabled={ this.props.loading }
                        />
                    </ApInputStack>
                )
            })
    }

    renderSettlementPoint(item) {
        let options = [{value: "", label: ""}];
        const userSettlementPoints = keyExists(this.props, 'data.netvisor_settlement_points', true, []);
        const foundItem = userSettlementPoints.find(settlementpoint => settlementpoint.netvisor_settlement_point_id == item.id);
        const value = foundItem ? foundItem.handling : null;
        switch (item.type) {
            case "employeeworkpensioninsurance":
                options.push(...[
                    {value: "nohandling", label: tr('no_handling')},
                    {value: "automatichandling", label: tr('automatic_handling')},
                    {value: "under17yearsold", label: tr('under_set_years_old', ['17'])},
                    {value: "17to52yearsold", label: tr('range_years_old', ['17-52'])},
                    {value: "53to62yearsold", label: tr('range_years_old', ['53-62'])},
                    {value: "63to67yearsold", label: tr('range_years_old', ['63-67'])},
                    {value: "over68yearsold", label: tr('over_set_years_old', ['68'])},
                ])
                return <ApInput
                    width="50%"
                    options={options}
                    type="select"
                    id={item.name}
                    name={item.name}
                    label={ tr('settlement_point') }
                    value={ value }
                    onChange={(e) => this.handleSettlementPointChange(e, item) }
                    // tooltip={ this.props.validator.getText( "data.contract_detail.netvisor_payslip_delivery_method" ) }
                    // validationState={ this.props.validator.getState( "data.contract_detail.netvisor_payslip_delivery_method" ) }
                    loading={ this.props.loading }
                    disabled={ this.props.loading }
                />
            
            case "employeeunemploymentinsurance":
                options.push(...[
                    {value: "nohandling", label: tr('no_handling')},
                    {value: "automatichandling", label: tr('automatic_handling')},
                    {value: "underage", label: tr('under_set_years_old', ['18'])},
                    {value: "agebetweenunderageandoverage", label: tr('range_years_old', ['18-64'])},
                    {value: "overage", label: tr('over_set_years_old', ['64'])},
                    {value: "partowner", label: tr("partowner")},
                ])
                return <ApInput
                    width="50%"
                    options={options}
                    type="select"
                    id={item.name}
                    name={item.name}
                    label={ tr('settlement_point') }
                    value={ value }
                    onChange={(e) => this.handleSettlementPointChange(e, item) }
                    // tooltip={ this.props.validator.getText( "data.contract_detail.netvisor_payslip_delivery_method" ) }
                    // validationState={ this.props.validator.getState( "data.contract_detail.netvisor_payslip_delivery_method" ) }
                    loading={ this.props.loading }
                    disabled={ this.props.loading }
                />
        
            default:
                options.push(...[
                    {value: "nohandling", label: tr('no_handling')},
                    {value: "attachedtosettlementpoint", label: tr('attached_to_settlement_point')},
                ]);
                return (
                    <ApInput
                        width="50%"
                        options={options}
                        type="select"
                        id={item.name}
                        name={item.name}
                        label={ tr('settlement_point') }
                        value={ value }
                        onChange={(e) => this.handleSettlementPointChange(e, item) }
                        // tooltip={ this.props.validator.getText( "data.contract_detail.netvisor_payslip_delivery_method" ) }
                        // validationState={ this.props.validator.getState( "data.contract_detail.netvisor_payslip_delivery_method" ) }
                        loading={ this.props.loading }
                        disabled={ this.props.loading }
                    />
                )
        }
    }

    handleSettlementPointChange(event, item) {
        const {name, value} = event.target;
        const userSettlementPoints = keyExists(this.props, 'data.netvisor_settlement_points', true, []).slice();
        const foundItem = userSettlementPoints.find(settlementpoint => settlementpoint.netvisor_settlement_point_id == item.id);

        if (foundItem) {
            foundItem.handling = value;
        } else {
            userSettlementPoints.push({
                user_id: this.props.data.id,
                netvisor_settlement_point_id: item.id,
                handling: value,
                id: 'new'+this.state.newId,
            });
            this.setState({newId: this.state.newId+1});
        }

        this.props.onChange( "netvisor_settlement_points", userSettlementPoints );
    }

    render() {
        return (
            <div className='padding'>
                <div className='clear'>
                    <div className='apColumn w50'>
                        <div className='padding'>
                            <h4 className="formHeader">{tr('salary_models')}</h4>
                            <ApInputStack gap="0">
                                <ApAddon noRightBorder>
                                    {tr('salary_model')}
                                </ApAddon>
                                <ApInput
                                    width="50%"
                                    options={ keyExists( this.props, "data.netvisor_salary_models", true, [] ) }
                                    type="select"
                                    id="netvisor_salary_models"
                                    name="netvisor_salary_models"
                                    label={ tr('salary_model') }
                                    value={ keyExists( this.props.data, "contract_detail.netvisor_salary_model_id", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "contract_detail.netvisor_salary_model_id", e.target.value ) } }
                                    tooltip={ this.props.validator.getText( "data.contract_detail.netvisor_salary_model_id" ) }
                                    validationState={ this.props.validator.getState( "data.contract_detail.netvisor_salary_model_id" ) }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />
                            </ApInputStack>
                            {/* <ApInput
                                width="50%"
                                type="number"
                                id="netvisor_advance_debt_account"
                                name="netvisor_advance_debt_account"
                                label={ tr('advance_debt_account') }
                                value={ keyExists( this.props.data, "contract_detail.netvisor_advance_debt_account", true ) }
                                onChange={ ( e ) => { this.props.onChange( "contract_detail.netvisor_advance_debt_account", e.target.value ) } }
                                tooltip={tr('advance_debt_account_info')}
                                // validationState={ this.props.validator.getState( "data.contract_detail.netvisor_advance_debt_account" ) }
                                loading={ this.props.loading }
                                disabled={ this.props.loading }
                            /> */}
                            <ApInputStack gap="0">
                                <ApAddon noRightBorder>
                                    {tr('payslip_delivery_method')}
                                </ApAddon>
                                <ApInput
                                    width="50%"
                                    options={[
                                        {value: null, label: null},
                                        {value: 1, label:"Paikallinen tulostus"},
                                        {value: 2, label:"Tulostuspalvelu"},
                                        {value: 3, label:"Verkkopalkka"}
                                    ]}
                                    type="select"
                                    id="netvisor_payslip_delivery_method"
                                    name="netvisor_payslip_delivery_method"
                                    label={ tr('payslip_delivery_method') }
                                    value={ keyExists( this.props.data, "contract_detail.netvisor_payslip_delivery_method", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "contract_detail.netvisor_payslip_delivery_method", e.target.value ) } }
                                    tooltip={ this.props.validator.getText( "data.contract_detail.netvisor_payslip_delivery_method" ) }
                                    validationState={ this.props.validator.getState( "data.contract_detail.netvisor_payslip_delivery_method" ) }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />
                            </ApInputStack>

                        </div>
                        <div className='padding'>
                            <h4 className='formHeader'>{tr('settlement_points')}</h4>
                            <p className='apInfo small'><SvgIcon icon='info-circle' type='solid' />{tr('accountant_info')}</p>
                            {this.props.data.company_netvisor_settlement_points.map((item, index) => (
                                <ApInputStack gap="0" key={index}>
                                    <ApAddon noRightBorder>
                                        {item.name}
                                    </ApAddon>
                                    {this.renderSettlementPoint(item)}
                                </ApInputStack>
                            ))}
                        </div>
                    </div>

                    <div className='apColumn w50'>
                        <div className='padding'>
                            {keyExists(this.props.data, 'netvisor_salary_parameters', true, []).length
                                ? <h4 className="formHeader">{tr('salary_parameters')}</h4>
                                : ''
                            }
                            {this.renderSalaryParameters()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserNetvisorInfo;
