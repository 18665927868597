import ApButton from 'common/ApButton/ApButton';
import ApModal from 'common/ApModal/ApModal';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import React, { useState } from 'react';
import { errorPopper, tr } from 'services/Helpers/Helpers';
import api from 'services/Api/Api.js';
import ApReactTable, { colPreset } from 'common/ApReactTable/ApReactTable';
import axios from 'axios';
import { getFromDescription } from './StockHistoryHelper';

function StockHistoryModal(props) {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pages, setPages] = useState(-1);
    const [cancelToken, setCancelToken] = useState(null);

    const getData = (state = {}) => {
        if (props.locationID && props.show) {
            setLoading(true);
            if (cancelToken) {
                cancelToken.cancel('Operation canceled by the user.');
            }
            if (!state.sorted || state.sorted.length === 0) {
                state.sorted = [{ id: 'created_at', desc: true }];
            }
            // if state has no pagesize, get from local storage
            if (!state.pageSize) {
                state.pageSize = parseInt(localStorage.getItem('ApReacTable_pageSize_stockHistoryTable') ?? 10);
            }
            const source = axios.CancelToken.source();
            setCancelToken(source);
            api({
                method: 'post',
                url: `storage/location/stock/history`,
                data: {
                    location_id: props.locationID,
                    component_id: props.componentID,

                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                },
                cancelToken: source.token,
            }).then(response => {
                setHistory(response.data);
                setPages(response.pages ?? 1);
            }).catch(error => {
                if (axios.isCancel(error)) { return null };
                console.log(error);
                errorPopper(error, tr('get_error'));
            }).finally(() => {
                setLoading(false);
                setCancelToken(null);
            });
        }
    }

    // useEffect(() => {
    //     if (props.locationID && props.show) {
    //         getData();
    //     }
    // }, [props.componentID, props.locationID, props.show]);

    const onClose = () => {
        setHistory([]);
        setPages(-1);
        if (props.onClose && typeof props.onClose === 'function')
            props.onClose();
    }

    const getColumns = () => {
        const cols = [];
        cols.push(
            colPreset({
                id: 'created_at',
                type: 'datetime',
                Header: tr('date'),
                accessor: 'created_at',
                filterable: false,
            }),
            {
                id: 'person',
                Header: tr('person'),
                accessor: row => (row.last_name || '') + ' ' + (row.first_name || ''),
                customizable: true,
                showInitially: true
            },
            {
                Header: tr('storage_name'),
                accessor: 'location_code',
                customizable: true,
                showInitially: true,
                Cell: props => {
                    return <div>{props.original.location.name}<br/><small>{props.original.location.code}</small></div>
                }
            },
            {
                Header: tr('component_name'),
                accessor: 'component_name',
                customizable: true,
                showInitially: true,
            },
            {
                Header: tr('change'),
                accessor: 'from',
                customizable: true,
                showInitially: true,
                filterable: false,
                Cell: props => getFromDescription(props.value)
            },
            colPreset({
                type: 'numberNice',
                unit: row => row.unit,
                Header: tr('balance_change'),
                accessor: 'balance_change',
                customizable: true,
                showInitially: true,
                filterable: false,
            }),
            colPreset({
                type: 'numberNice',
                unit: row => row.unit,
                Header: tr('balance_after'),
                accessor: 'balance_after',
                customizable: true,
                showInitially: true,
                filterable: false,
            }),
            colPreset({
                type: 'currency',
                id: 'price',
                Header: tr('supplier_price'),
                accessor: 'price',
                customizable: true,
                showInitially: true,
                filterable: false,
            }),
            colPreset({
                type: 'currency',
                id: 'avg_price',
                Header: tr('average_purchase_price'),
                accessor: 'avg_price',
                customizable: true,
                showInitially: true,
                filterable: false,
            }),
            colPreset({
                type: 'currency',
                id: 'last_price',
                Header: tr('last_purchase_price'),
                accessor: 'last_price',
                customizable: true,
                showInitially: true,
                filterable: false,
            })
        );

        return cols;
    }

    const renderHeader = () => {
        return (
            <div className='modal-header'>
                <h3 className='modal-title'>{tr('history')}</h3>
            </div>
        );
    }

    const renderBody = () => {
        return (
            <div className='modal-body'>
                <ApReactTable
                    rememberId='stockHistoryTable'
                    loading={loading}
                    data={history}
                    columns={getColumns()}

                    onFetchData={getData}
                    pages={pages}
                    manual

                    showFiltersInitially={true}
                    filterable
                />
            </div>
        );
    }

    const renderFooter = () => {
        return (
            <div className='modal-footer'>
                <ApButton onClick={onClose}>
                    <SvgIcon icon='times' type='solid' />
                    {tr('back')}
                </ApButton>
            </div>
        );
    }

    return (
        <div id='stockHistoryModal'>
            <ApModal
                show={props.show}
                header={renderHeader()}
                body={renderBody()}
                footer={renderFooter()}
                closeFromBg={true}
                handleClose={onClose}
                onOpen={getData}
            />
        </div>
    );
}

export default StockHistoryModal;