import React from 'react';
import autoBind from 'react-autobind';

import { keyExists, tr } from 'services/Helpers/Helpers.js';

import api                from 'services/Api/Api.js';

import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApButton           from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import { debounce } from 'throttle-debounce';
import './NewCrmCompany.css';

class NewCrmCompany extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            error: false,
            data: {
                name: '',
                taxnumber: '',
                is_supplier: false,
                is_customer: true,
                parent_company_id: null,
                selectedCompany:"",
            },
            showSuggestedParentMenu: false,
            foundedCompany:null,
        }
        this.isCompanyNameAvailableDebounced = debounce(200, this.isCompanyNameAvailable);
        autoBind(this);
    }

    UNSAFE_componentWillMount()
    {
        this.resetState();
    }

    resetState()
    {
        this.setState({ data: {
            name: '',
            taxnumber: '',
            is_supplier: false,
            is_customer: true,
            parent_company_id: null,
            selectedCompany:"",
        },
            showSuggestedParentMenu: false,
            foundedCompany: null,
        });
    }

    
    handleChange( e )
    {
        const name = e.target.name;
        let data = { ...this.state.data };
        data[ name ] = e.target.value;
        
        this.setState({ data }, this.isCompanyNameAvailableDebounced(data.name));
        
    }


    addCompany()
    {
        //console.log('addCompany', this.state.data );
        if (this.props.supplier) {
            let stateData = this.state.data;
            stateData.is_customer = false;
            stateData.is_supplier = true;
            this.setState({
                data: stateData,
            });
        }
        this.setState({ 
            loading: true,
            error: false
        });
        api({
            method: 'post',
            url: 'crm/company/add',
            data: this.state.data,
        }).then((newId) => {
            //console.log(newId);
            
            if (typeof (this.props.onSave) === "function")
                this.props.onSave(newId);

            this.props.onClose();
            
   
        }).catch((error) => {
            //console.error( "palautettu virhe",error.response );
            this.setState({ 
                loading: false,
                error: keyExists(error, "response.data.message", true, tr('save_error')) || "VIRHE",
            });
        });

    }
    isCompanyNameAvailable(name) {
        //console.log('addCompany', this.state.data );
        
        
        api({
            method: 'post',
            url: 'crm/company/isCompanyNameAvailable',
            data: { name: name},
        }).then((data) => {
            //console.log(data);
            
            this.setState({
                foundedCompany: data,
            });


        }).catch((error) => {
            //console.error( "palautettu virhe",error.response );
            this.setState({
                error: keyExists(error, "response.data.message", true, tr('save_error')) || "VIRHE",
            });
        });

    }
    setParentCompany(item) {
        //console.log(item);
        
        this.setState({
            data: { ...this.state.data, parent_company_id: item.id },
            selectedCompany:item,
        });
    }

    renderBody()
    {
        return (
            <div className="padding">

                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('add_customer_note') }
                </div>
                {this.state.foundedCompany && <h5>
                    {tr("linking_existing_customer")+": " + this.state.foundedCompany.name + ", "} <br />
                    {tr("business_id")+": " +this.state.foundedCompany.taxnumber}</h5>}
                <ApInput
                    type="text"
                    id="newCrmCompany_name"
                    name="name"
                    label={ tr('name') }
                    value={ this.state.data.name }
                    onChange={ this.handleChange }
                    validationState={ !this.state.data.name ? "error" : "" }
                    tooltip={ !this.state.data.name ? tr('name_required') : false }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />

                <ApInput
                    type="text"
                    id="newCrmCompany_taxnumber"
                    name="taxnumber"
                    label={ tr('business_id') }
                    value={ this.state.data.taxnumber }
                    onChange={ this.handleChange }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />

                <ApSelect
                    value={this.state.selectedCompany}
                    label={tr("parent_company")}
                    objKeyValue="name"
                    optionRenderer={(item) => {
                        if (item.type === "company")
                            return (
                                <div className="searchOption company">
                                    <SvgIcon className="typeIcon" icon="building" type="solid" />
                                    <strong>{item.name}</strong><br />
                                    <small>{tr('business_id')}: {item.taxnumber}</small>
                                </div>
                            );
                        else if (item.type === "contact")
                            return (
                                <div className="searchOption contact">
                                    <SvgIcon className="typeIcon" icon="address-card" type="solid" />
                                    <strong>{item.name}</strong><br />
                                    <small>{item.title ? item.title : <span className="empty">{tr('no_title')}</span>}</small>
                                    <span className="company">
                                        <SvgIcon className="small-inline" icon="building" type="solid" />
                                        {item.crm_company_name}
                                    </span>
                                </div>
                            );
                    }}
                    onChange={(item) => { this.setParentCompany(item) }}
                    objKeyId="id"
                    clearable
                    apiUrl="search/crm"
                    loading={this.state.loading}
                    disabled={this.state.loading}
                />

                { this.state.error && 
                    <div className="apErrorMsg">
                        { this.state.error }
                    </div>
                }

            </div>
        );
    }

    render()
    {
    
        return (<div>
            <ApModal
            show={ this.props.show }
            onOpen={ this.resetState }
            handleClose={ this.props.onClose }
            //closeFromBg
                className="narrow overflow"
            header={ 
                <div className="padding-small">
                    <h4>{ tr('add_customer') }</h4>
                </div>
            }
            body={ 
                <div>
                    { this.renderBody() }
                </div>
            }
            footer={
                <div id="newCrmCompanyFooter">

                    <ApButton className="cancel" onClick={ this.props.onClose } disabled={ this.state.loading }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>

                    <ApButton className={ "save" + ( this.state.data.name && !this.state.loading ? " highlight" : "" ) } color="blue" onClick={ this.addCompany } disabled={ this.state.loading || !this.state.data.name } loading={ this.state.loading }>
                        <SvgIcon icon="save" type="solid" />
                        { tr('save') }
                    </ApButton>
                
                </div>
            }

        />
            <ApModal
                show={this.state.error && this.props.supplier &&false }
                onOpen={ this.resetState }
                handleClose={ ()=>{this.setState({error:false})} }
                //closeFromBg
                className="narrow"
                header={ 
                    <div className="padding-small">
                        <h4>{ tr('add_customer') }</h4>
                    </div>
                }
                body={ 
                    <div>
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div id="newCrmCompanyFooter">
    
                        <ApButton className="cancel" onClick={ ()=>{this.setState({error:false})} } disabled={ this.state.loading }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton className={ "save" + ( this.state.data.name && !this.state.loading ? " highlight" : "" ) } color="blue" onClick={ this.addCompany } disabled={ this.state.loading || !this.state.data.name } loading={ this.state.loading }>
                            <SvgIcon icon="save" type="solid" />
                            { tr('save') }
                        </ApButton>
                    
                    </div>
                }

            />
        </div>
        )

    }

}

export default NewCrmCompany;
