import React from 'react';
import autoBind from 'react-autobind';
import _ from 'lodash';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import api from 'services/Api/Api.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApList from 'common/ApList/ApList.js';
import {
    Container, Row, Col,
} from 'react-bootstrap';

import {
    ApTabs,
    ApTab
} from 'common/ApTabs/ApTabs.js';
import {
    mapTree,
    keyExists,
    errorPopper, tr
} from 'services/Helpers/Helpers.js';

import { getComponentStatusId } from 'modules/Storage/common/StorageHelpers.js';
import ApValidate from 'services/ApValidate/ApValidate.js';

import { ApInput } from 'common/ApInput/ApInput.js';

import Code from 'modules/Storage/Settings/Code/Code.js';
import ApFormPage from 'common/ApFormPage/ApFormPage';
import ApSwitch from 'common/ApSwitch/ApSwitch';

class OrganizationalChartSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            enabled: false,

            modeOptions: [],
            codes: [],
            format: {},
            formatSaved: {},
            confirmFormatShow: false,
            groups: [],
            deleteGroups: [],

            showGroupModal: false,
            selectedGroupId: null,
            selectedGroupName: null,
            selectedGroupDescription: null,

            changes: false,
        }
        this.codeTreeRef = null;
        this.validator = new ApValidate(this, {
            "selectedGroupName": { filter: 'required', state: 'error', text: tr('name_missing') },

        });

        autoBind(this);
    }

    componentDidMount() {
        this.getSettings();

    }

    getSettings() {
        this.setState({ loading: true });

        return api({
            method: 'get',
            url: 'company/settings/organizationchart/get',
        }).then((response) => {
            //console.log("get Settings", response);
            this.setSettings(response);
            this.setState({
                loading: false,
                changes: false,
            });
        }).catch((error) => {
            console.log(error);

            errorPopper(error, tr('get_error'));
            this.setState({ loading: false });
        });
    }

    setSettings(data) {
        this.setState({
            formatSaved: JSON.stringify(data.format),
            format: data.format,
            modeOptions: data.mode_options,

            groups: data.groups,
            enabled: Boolean(data.enabled)
        }, () => {
            this.calculateCodeTree(data.codes);
        });
    }

    saveClicked() {
        if (this.formatIsChanged())
            this.setState({ confirmFormatShow: true });
        else
            this.saveSettings();
    }

    saveSettings() {
        this.setState({ confirmFormatShow: false });

        let data = {enabled: this.state.enabled};

        if (this.formatIsChanged()) {
            let format = {
                mode_id: this.state.format.mode.id,
                levels: this.state.format.levels,
                separator: this.state.format.separator,
            };
            data.format = format;
        }
        else {
            if (this.codeTreeRef) {
                this.codeTreeRef.updateSelectedCodeSettings();
            }
            let tree = JSON.parse(JSON.stringify(this.state.codes));

            data.codes = mapTree(tree, null, (item) => {
                return {
                    id: isNaN(parseInt(item.id, 10)) ? null : item.id,
                    name: item.name,
                    value: item.value,
                    code: item.code,
                    description: item.description,
                    children: item.children,
                    groups: item.groups ? item.groups : [],
                    users: item.users ? item.users : [],
                };
            });
        }

        data.groups = this.state.groups;
        data.deleteGroups = this.state.deleteGroups;

        this.setState({ loading: true });

        return api({
            method: 'post',
            url: 'company/settings/organizationchart/save',
            data: data,
        }).then((response) => {

            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{tr('saved')}</strong>,
            });

            this.setState({ loading: false, changes: false, });
            this.getSettings();
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });
    }

    renderConfirmFormatChange() {
        return <div id="confirmFormatChange" className="ApModalConfirm">
            <ApModal
                show={this.state.confirmFormatShow}
                handleClose={() => this.setState({ confirmFormatShow: false })}
                closeFromBg
                className="narrow"
                header={
                    <div className="padding-small">
                        <h4>
                            {tr('edit_cost_center_code_format_confirm')}
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <div>{tr('edit_cost_center_code_format_confirm_info')}</div>
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton className="cancel" onClick={() => this.setState({ confirmFormatShow: false })}>
                            <SvgIcon icon="times" type="solid" />
                            {tr('no')}
                        </ApButton>

                        <ApButton className="save" color="green" onClick={this.saveSettings}>
                            <SvgIcon icon="check" type="solid" />
                            {tr('yes')}
                        </ApButton>
                    </div>
                }
            />
        </div>
    }

    renderGroupModal(data) {
        return <div id="confirmFormatChange" className="ApModalConfirm">
            <ApModal
                show={this.state.showGroupModal}
                handleClose={() => this.setState({ showGroupModal: false })}
                closeFromBg
                className="narrow"
                header={
                    <div className="padding-small">
                        <h4>
                            {tr('edit_group')}
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <Container fluid={true} className="padding">
                            <Row>
                                <Col sm={12}>
                                    <ApInput
                                        type="text"
                                        id="code"
                                        name="code"
                                        label={tr("name")}
                                        validationState={this.validator.getState('selectedGroupName')}
                                        tooltip={this.validator.getText('selectedGroupName')}
                                        autoComplete="off"
                                        onChange={(val) => this.setState({ selectedGroupName: val.target.value })}
                                        value={this.state.selectedGroupName}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <ApInput
                                        type="textarea"
                                        id="name"
                                        name="name"
                                        label={tr("description")}
                                        autoComplete="off"
                                        onChange={(val) => this.setState({ selectedGroupDescription: val.target.value })}
                                        value={this.state.selectedGroupDescription}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton className="cancel" onClick={() => this.setState({ showGroupModal: false })}>
                            <SvgIcon icon="times" type="solid" />
                            {tr('cancel')}
                        </ApButton>

                        <ApButton className="save" color="blue" onClick={this.saveGroup} disabled={this.state.selectedGroupName === "" || this.state.selectedGroupName == null}>
                            <SvgIcon icon="save" type="solid" />
                            {tr('save')}
                        </ApButton>
                    </div>
                }
            />
        </div>
    }

    saveGroup() {
        let groups = [...this.state.groups];
        let found = false;
        for (let group of groups) {
            if (group.id === this.state.selectedGroupId) {
                group.name = this.state.selectedGroupName;
                group.description = this.state.selectedGroupDescription;
                found = true;
                break;
            }
        }
        if (!found) {
            let group = {
                id: "new_" + Math.random(),
                name: this.state.selectedGroupName,
                description: this.state.selectedGroupDescription,
            };
            groups.push(group);
        }
        this.setState({
            showGroupModal: false,
            groups: groups,
            selectedGroupId: null,
            selectedGroupName: null,
            selectedGroupDescription: null,
            changes: true
        });


    }


    calculateCodeTree(tree = null) {
        if (tree === null)
            tree = this.state.codes.slice(0);

        tree = mapTree(tree, null, (item) => {

            item.invalid = false;
            item.child_invalid = false;
            item.missing_children = false;
            item.code_invalid = false;

            let own = {
                components_count: parseFloat(item.components_count),
            };

            // Clone own stuff to total stuff
            let all = JSON.parse(JSON.stringify(own));

            item.children.forEach((child) => {
                if (child.calculated)
                    all.components_count += child.calculated.all.components_count;
                if (child.invalid)
                    item.child_invalid = true;
            });

            item.calculated = {
                own: own,
                all: all,
            }

            item.missing_children = false;
            if (item.level < this.state.format.levels.length && item.children.length === 0)
                item.missing_children = true;

            item.code_invalid = this.codeTreeNodeCodeInvalid(item)

            if (item.child_invalid
                || item.missing_children
                || item.code_invalid
            ) {
                item.invalid = true;
            }

            return item;
        });

        this.setState({ codes: tree });
    }

    renderCount() {
        if (this.state.loading)
            return null;

        if (!keyExists(this.state, 'format.mode'))
            return null;

        if (this.state.format.mode.is_free) {
            const count = this.state.componentsCount;
            return <ApTooltip position="top" text={tr('show_in_storage_component_management')} block>
                <div className="withoutCodeContainer" onClick={() => this.goToComponents([{ id: 'status_id', value: getComponentStatusId('active') }])}>
                    <SvgIcon className="headerSvg" icon="cubes" type="solid" />
                    {tr('storage_component_count')}: <strong>{count}</strong>
                </div>
            </ApTooltip>
        }
        else {
            const count = this.state.componentsWithoutCodeCount;
            return <ApTooltip position="top" text={tr('show_in_storage_component_management')} block>
                <div className="withoutCodeContainer" onClick={() => this.goToComponents([{ id: 'code', value: '!' }, { id: 'status_id', value: getComponentStatusId('active') }])}>
                    <SvgIcon className="headerSvg" icon="ban" type="solid" />
                    {tr('storage_component_without_code_count')}: <strong>{count}</strong>
                </div>
            </ApTooltip>
        }
    }

    formatIsChanged() {
        return (this.state.formatSaved !== JSON.stringify(this.state.format));
    }

    codeTreeNodeCodeInvalid(item) {
        if (!item.value) return tr('code_missing');
        if (this.state.format.mode.need_length) {
            const level = this.state.format.levels.find(l => l.level === item.level)
            if (level) {
                if (item.value.length !== level.length)
                    return tr('code_min_length', [level.length]);
            }
        }
        return false;
    }

    formatChange(data) {
        let format = { ...this.state.format, ...data };
        this.setState({ format: format, changes: true });
    }

    renderCodeTree() {
        if (this.state.loading)
            return null;
        if (!keyExists(this.state, 'format.mode'))
            return null;
        if (this.state.format.mode.is_free)
            return null;

        return <Code
            ref={ref => this.codeTreeRef = ref}
            loading={this.state.loading}
            tree={this.state.codes}

            separator={(this.state.format.separator) ? this.state.format.separator : '.'}
            mode={this.state.format.mode}
            levels={this.state.format.levels}
            //tailLength={this.state.format.tail_length}
            onTreeChange={(tree) => this.calculateCodeTree(tree)}
            formatChanged={(this.formatIsChanged())}
            isCodeInvalid={this.codeTreeNodeCodeInvalid}
            changeSaveButton={() => this.setState({ changes: true })}
            isOrgChart={true}
            groups={this.state.groups}
        />
    }

    CreateItem(pos) {

        this.setState({
            showGroupModal: true,
        });

    }

    renderItem(group) {
        return (
            <div>
                <strong>{group.name}</strong><br />
                {group.description ? <small>{group.description}</small> : null}
            </div>
        );
    }
    editItem(item) {
        this.setState({
            showGroupModal: true,
            selectedGroupId: item.id,
            selectedGroupName: item.name,
            selectedGroupDescription: item.description,
        })
    }

    getListActions() {
        return [
            {
                label: tr('delete'),
                icon: "trash",
                disabled: false,
                action: (item, closeFunc) => {
                    this.clickDelete(item.id);
                    closeFunc();
                }
            },
        ]
    }

    clickDelete(id) {
        let groups = _.cloneDeep(this.state.groups);
        let deleteGroups = _.cloneDeep(this.state.deleteGroups);
        const foundIndex = this.state.groups.findIndex(g => g.id === id);
        if (foundIndex != -1) {
            if (isNaN(groups[foundIndex].id)) {
                groups = groups.filter(g => g.id !== id);
            } else {
                groups[foundIndex].removed = !groups[foundIndex].removed;
                if (groups[foundIndex].removed) {
                    deleteGroups.push(id);
                } else {
                    deleteGroups = deleteGroups.filter(deleteId => deleteId !== id);
                }
            }
        }

        this.setState({ groups, deleteGroups, changes: true })
    }

    handleChange(value, afterFunc = false) {
        //console.log(value);
        this.setState({
            groups: value,
            changes: true,
        });
    }

    render() {
        return (
            <div className="">
                <ApFormPage
                    unsaved={this.state.changes}
                    onSave={this.saveSettings}
                    loading={this.state.loading}
                >
                    <div className="" id="organizational-chart-settings">
                        <ApTabs>
                            <ApTab icon="cog" label={tr('settings')}>
                                <div className='padding'>
                                    <div className="apSwitchBlock small">
                                        <label htmlFor={'enabled_switch'} className="info">
                                            {tr('organizational_chart_enabled')}
                                            <small>{tr('organizational_chart_enabled_info')}</small>
                                        </label>
                                        <ApSwitch
                                            id={'enabled_switch'}
                                            on={this.state.enabled}
                                            onChange={() => this.setState({enabled: !this.state.enabled, changes: true})}
                                            disabled={this.state.loading}
                                        />
                                    </div>
                                </div>
                            </ApTab>

                            <ApTab icon="list" label={tr('types_and_numbering')} disabled={!this.state.enabled}>
                                {/* <CodeFormat
                                    loading={this.state.loading}
                                    mode={this.state.format.mode}
                                    modeOptions={this.state.modeOptions}
                                    levels={this.state.format.levels}
                                    separator={this.state.format.separator}
                                    //tailLength={this.state.format.tail_length}
                                    onChange={(data) => this.formatChange(data)}
                                    isOrgChart={true}
                                /> */}
                                {this.renderCodeTree()}
                            </ApTab>

                            <ApTab icon="cubes" label={tr('groups')} disabled={!this.state.enabled}>
                                <div className="apBoxHeader"></div>
                                <div className="list">
                                    <ApList
                                        loading={this.props.loading}
                                        items={this.state.groups}
                                        itemRenderer={this.renderItem}
                                        onClick={(item) => this.editItem(item)}
                                        actions={this.getListActions()}
                                        validationState={(item) =>
                                            item.removed ? "removed" :
                                                item.unsaved ? "warning" :
                                                    ""
                                        }
                                        icon="tag"
                                        sortable
                                        onSort={(items) => this.handleChange(items)}
                                        addNewItem={(pos) => this.CreateItem(pos)}
                                    />
                                </div>
                            </ApTab>
                        </ApTabs>
                    </div>
                    {this.renderGroupModal()}
                </ApFormPage>
            </div>);
    }
}


export default OrganizationalChartSetting;