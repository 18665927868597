import { useEffect, useRef, useState } from "react";
import api from 'services/Api/Api.js';
import moment from 'moment';
import { errorPopper, getExcel, tr } from 'services/Helpers/Helpers.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApTooltip from "common/ApTooltip/ApTooltip";
import PropTypes from 'prop-types';
import './TimeAttendance.css';

function TimeAttendanceSavePoints(props) {
    const [savePoints, setSavePoints] = useState([]);
    const [loading, setLoading] = useState(false);
    const reactTableRef = useRef(null);

    // Fetch savepoints
    useEffect(() => {
        if (!props.userReport) return;
        setLoading(true);
        api({
            method: 'get',
            url: 'currentUser/reports/timeattendance',
        }).then(response => {
            // console.log(response);
            setSavePoints(response.savePoints);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            errorPopper(error, tr('get_error'));
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (props.data) {
            setSavePoints(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (props.loading !== undefined && typeof props.loading === 'boolean') {
            setLoading(props.loading);
        }
    }, [props.loading]);

    const getExcelFile = async (selectedRowIndexes, createPdf = false) => {
        if (!savePoints.length) return;

        let data = [...savePoints];
        if (selectedRowIndexes.length) {
            data = data.filter((row, index) => selectedRowIndexes.includes(index));
        }

        const visibleColumns = reactTableRef.current.getVisibleColumns();

        const excelData = data.map(row => {
            const data = {};
            visibleColumns.forEach(col => {
                switch (col.original_key) {
                    case 'user':
                        data.person = row.person_detail.full_name;
                        break;
                    case 'date':
                        data.date = moment(row.date).format("L");
                        break;
                    case 'timestamp_start':
                        let start = row.timestamp_start;
                        if (row.last_edit && row.last_edit.timestamp_start) {
                            start = row.last_edit.timestamp_start;
                        }
                        data.timestamp_start = start ? moment(start).format("HH:mm:ss") : "-";
                        break;
                    case 'timestamp_end':
                        let end = row.timestamp_end;
                        if (row.last_edit && row.last_edit.timestamp_end) {
                            end = row.last_edit.timestamp_end;
                        }
                        data.timestamp_end = end ? moment(end).format("HH:mm:ss") : "-";
                        break;
                    case 'duration':
                        let startPoint = row.timestamp_start;
                        if (row.last_edit && row.last_edit.timestamp_start) {
                            startPoint = row.last_edit.timestamp_start;
                        }
                        let endPoint = row.timestamp_end;
                        if (row.last_edit && row.last_edit.timestamp_end) {
                            endPoint = row.last_edit.timestamp_end;
                        }
                        if (!endPoint || !startPoint) {
                            data.duration = 0;
                        }
                        else {
                            data.duration = moment(endPoint).diff(moment(startPoint), 'hours', true);
                        }
                        break;
                    case 'project_name':
                        data.project_name = row.project_name;
                        break;
                    case 'work_name':
                        data.work_name = row.work_name;
                        break;
                    default:
                        break;
                }
            });
            return data;
        });

        setLoading(true);
        await getExcel(
            excelData,
            `${tr('time_attendance_save_points')} ${moment().format('DD.MM.YYYY')}`,
            tr('timetracking_entries'),
            { createPdf, fileName: 'user_timetracking_report' },
        );
        setLoading(false);
    }

    const isEnded = props => {
        return props.original.last_edit && props.original.last_edit.timestamp_end || props.original.timestamp_end;
    }

    const getRowClickFunc = () => {
        return props.rowClickFunc && typeof props.rowClickFunc === 'function' ? props.rowClickFunc : null;
    }

    const getTimePointColumns = () => {
        let cols = [];

        cols.push({
            Header: tr("name"),
            id: 'name',
            onClick: getRowClickFunc(),
            sortable: true,
            accessor: 'person_detail.full_name',
            Cell: props => {
                if (!(isEnded(props))) {
                    props.classes.push("inprogress");
                }
                return props.original.person_detail.full_name;
            },
            customFilter: {
                type: "text",
                placeholder: tr('enter_name'),
                fields: ["_original.person_detail.full_name"]
            },
            original_key: 'user',
        });
        cols.push({
            Header: tr("date"),
            id: 'date',
            onClick: getRowClickFunc(),
            sortable: true,
            filterable: false,
            accessor: 'date',
            Cell: props => {
                let value = "";
                if (!(isEnded(props))) {
                    props.classes.push("inprogress");
                }
                if (props.original.last_edit && props.original.last_edit.date) {
                    value = props.original.last_edit.date;
                    props.classes.push("ValueEdited");
                    let tooltip = <div>
                        {tr('original_time')}<br />
                        {moment(props.original.date).format("L")}
                    </div>;
                    return <ApTooltip block text={tooltip} position="topleft">
                        {value}
                    </ApTooltip>
                }
                else {
                    return moment(props.original.date).format("L");
                }
            },
            original_key: 'date',
        });
        cols.push({
            Header: tr("timestamp_start"),
            id: 'timestamp_start',
            onClick: getRowClickFunc(),
            sortable: true,
            filterable: false,
            // accessor: 'timestamp_start',
            accessor: row => {
                let start = row.timestamp_start;
                if (row.last_edit && row.last_edit.timestamp_start) {
                    start = row.last_edit.timestamp_start;
                }
                return start;
            },
            className: "overflowableOnHover",
            Cell: props => {
                let value = "";
                if (!(isEnded(props))) {
                    props.classes.push("inprogress");
                }
                if (props.original.last_edit && props.original.last_edit.timestamp_start) {
                    value = props.original.last_edit.timestamp_start;
                    props.classes.push("ValueEdited");
                    let tooltip = <div>
                        {tr('original_time')}<br />
                        {props.original.timestamp_start ? moment(props.original.timestamp_start).format("HH:mm:ss") : "-"
                        }</div>;
                    return <ApTooltip block text={tooltip} position="topleft">
                        {moment(value).format("HH:mm:ss")}
                    </ApTooltip>
                }
                else {
                    return props.original.timestamp_start ? moment(props.original.timestamp_start).format("HH:mm:ss") : "-";
                }
            },
            original_key: 'timestamp_start',
        });
        cols.push({
            Header: tr("timestamp_end"),
            id: 'timestamp_end',
            onClick: getRowClickFunc(),
            sortable: true,
            filterable: false,
            // accessor: 'timestamp_end',
            accessor: row => {
                let end = row.timestamp_end;
                if (row.last_edit && row.last_edit.timestamp_end) {
                    end = row.last_edit.timestamp_end;
                }
                return end;
            },
            className: "overflowableOnHover",
            Cell: props => {
                let value = "";
                if (!(isEnded(props))) {
                    props.classes.push("inprogress");
                }
                if (props.original.last_edit && props.original.last_edit.timestamp_end) {
                    value = props.original.last_edit.timestamp_end;
                    props.classes.push("ValueEdited");
                    let tooltip = <div>
                        {tr('original_time')}<br />
                        {props.original.timestamp_end ? moment(props.original.timestamp_end).format("HH:mm:ss") : "-"
                        }</div>;
                    return <ApTooltip block text={tooltip} position="topleft">
                        {moment(value).format("HH:mm:ss")}
                    </ApTooltip>
                }
                else {
                    return props.original.timestamp_end ? moment(props.original.timestamp_end).format("HH:mm:ss") : "-";
                }
            },
            original_key: 'timestamp_end',
        });
        cols.push({
            Header: tr("duration"),
            id: 'duration',
            sortable: true,
            filterable: false,
            accessor: row => {
                let start = row.timestamp_start;
                if (row.last_edit && row.last_edit.timestamp_start) {
                    start = row.last_edit.timestamp_start;
                }
                let end = row.timestamp_end;
                if (row.last_edit && row.last_edit.timestamp_end) {
                    end = row.last_edit.timestamp_end;
                }
                if (!end || !start) {
                    return 0;
                }
                return moment(end).diff(moment(start), 'hours', true);
            },
            Cell: props => {
                if (!(isEnded(props))) {
                    props.classes.push("inprogress");
                }
                let start = props.original.timestamp_start;
                if (props.original.last_edit && props.original.last_edit.timestamp_start) {
                    start = props.original.last_edit.timestamp_start;
                }
                let end = props.original.timestamp_end;
                if (props.original.last_edit && props.original.last_edit.timestamp_end) {
                    end = props.original.last_edit.timestamp_end;
                }
                if (!end || !start) {
                    return '-';
                }
                let duration = moment(end).diff(moment(start), 'hours', true);
                return duration.toFixed(2) + " h";
            },
            original_key: 'duration',
        });
        cols.push({
            Header: tr("project_name"),
            id: 'project_name',
            sortable: true,
            accessor: 'project_name',
            Cell: props => {
                if (!(isEnded(props))) {
                    props.classes.push("inprogress");
                }
                return props.original.project_name;
            },
            customFilter: {
                type: "text",
                placeholder: tr('enter_name'),
            },
            original_key: 'project_name',
        });
        cols.push({
            Header: tr("work_name"),
            id: 'work_name',
            sortable: true,
            accessor: 'work_name',
            Cell: props => {
                if (!(isEnded(props))) {
                    props.classes.push("inprogress");
                }
                return props.original.work_name;
            },
            customFilter: {
                type: "text",
                placeholder: tr('enter_name'),
            },
            original_key: 'work_name',
        });

        return cols;
    }

    return (
        <div id="pageTimetrackingTimeAttendance" className="padding">
            <ApReactTable
                rememberId="userReportsTimeAttendanceSavePoints"
                columns={getTimePointColumns()}
                data={savePoints}
                loading={loading}
                ref={reactTableRef}
                defaultPageSize={10}
                showFiltersInitially
                filterable
                multiselect={[
                    {
                        icon: 'file-pdf',
                        label: tr('create_pdf'),
                        action: selectedRows => getExcelFile(selectedRows, true),
                        unselectAfter: false,
                        closeAfter: true,
                        alwaysOn: true,
                    },
                    {
                        icon: 'file-excel',
                        label: tr('create_excel'),
                        action: getExcelFile,
                        unselectAfter: false,
                        closeAfter: true,
                        alwaysOn: true,
                    },
                ]}
            />
        </div>
    );
}

TimeAttendanceSavePoints.propTypes = {
    userReport: PropTypes.bool, // If true, fetches data from currentUser/reports/timeattendance, otherwise uses data prop
    data: PropTypes.array, // Array of savepoints
    loading: PropTypes.bool,
    rowClickFunc: PropTypes.func,
};

export default TimeAttendanceSavePoints;