import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import { errorPopper, tr } from 'services/Helpers/Helpers.js';

class EditStorageType extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            confirmed: false,
            errorShow: false,
        }

        this.steps = [{
            name: tr('verification_of_data'),
            render: this.renderStepInformation.bind( this ),
        },{
            name: tr('confirm'),
            render: this.renderStepConfirmation.bind( this ),
        }];

        autoBind(this);
    }

    resetState()
    {
        this.setState({
            confirmed: false,
            errorShow: false,
        } );

        const data = this.props.data;
        if( !data ) return null;
    }

    save()
    {
        if (!this.state.confirmed) {
            this.setState({errorShow: true}); 
            return; 
        }
        
        let data = {};

        data.id = this.props.location.id;
        data.type = this.props.location.type;
        
        return api({
            method: 'post',
            url: `storage/location/editType`,
            data: data,
        }).then( ( response ) => {
            if (response == 0) {
                alert(tr('project_storage_already_exists_code'));

                //TODO: Mietin että olisi mahdollista että jos projektille on jo luotu projektivarasto, voi tehdä toisen api-pyynnön joka taas
                //tekee niin että siirtää normivarastosta tuotteet projektivarastoon, ja sen jälkeen poistaa normivaraston
                //Pitää kysyä onko kannattavaa koska se vaatisi sen että projektivaraston toiminnallisuutta muutetaan; projektivarasto säilyttää ainoastaan komponentit joille sillä tarve.

                return;
            } else if (response == 1) {
                window.location.reload();
            }
            alert(tr('type_changed'));
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });
        
        
    }


    renderStepInformation()
    {
        return <div className="stepSupplier">

            <div className="supplier-select">
                <ApSelect
                    label={ tr('storage') }
                    value={ `${this.props.location.code} - ${this.props.location.name}` }
                    objKeyId="id"
                    objKeyValue="name"
                    clearable
                />
            </div>
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                {`${tr('confirm_storage_type_change_note1')}
                ${tr('confirm_storage_type_change_note2')}`}
            </div>
            <div>
                <h5 style={{fontWeight: 600}}>{ tr('confirm_storage_type_change_note3') }</h5>
                <ul>
                    <li>
                        { tr('confirm_storage_type_change_note4') }
                        <ul>
                            <li>{ tr('confirm_storage_type_change_note5') }</li>
                            <li>{ tr('confirm_storage_type_change_note6') }</li>
                        </ul>
                    </li>
                </ul>
            </div>

        </div>

    }

    renderStepConfirmation()
    {

        return <div>

            <h4>{ tr('last_check') }</h4>
            <tr className="confirmation">
                <td>
                    <input
                        type="checkbox"
                        onClick={ (e) => { this.setState({confirmed: e.target.checked}) } }
                        checked={ this.state.confirmed }
                        style={{ marginRight: '2em' }}
                    />
                </td>
                <td>
                    <h6>{ tr('confirm_storage_change') }</h6>
                </td>

                {this.state.errorShow ? 
                <div style={{ position: 'relative', bottom: '-10em', right: '0', fontWeight: '600', fontSize: '1.1em', color: 'red' }}>
                    { tr('confirm_changes') }
                </div>: ''}
            </tr>
        </div>
    }


    render()
    {
        return <div id="newPo">
            <ApModalWizard
                header={ tr('change_of_storage_type') }
                onOpen={ this.resetState }
                show={ this.props.show }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.save }
            />
        </div>
    }

}

export default EditStorageType;