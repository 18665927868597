import React from 'react';
import autoBind from 'react-autobind';

import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import ApModal from 'common/ApModal/ApModal.js';

import { ApInput, ApInputStack, ApAddon} from 'common/ApInput/ApInput.js';
import {tr }  from 'services/Helpers/Helpers.js'
import ApSelect        from 'common/ApSelect/ApSelect.js';

import api                  from 'services/Api/Api.js';
import '../../common/RelatedSelectModal/RelatedSelectModal.css'

class EditOrderContactPerson extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            value: null,
            options: null,
            startValue: null,
            changes:false,
        };

        this.validator = new ApValidate(this, {
        });

        autoBind(this);
    }

    resetState()
    {
        this.setState({
            value: this.props.value,
            startValue: this.props.value,
            changes:false,
        });
    }

    close()
    {
        if (this.state.startValue != null && this.state.value != null && this.state.startValue.id != this.state.value.id) {
            const person = this.state.startValue;

            this.props.handleClose({
                [`${this.props.field}`]: person ? person : null,
            });
        }
        else {
            this.save();
        }
        
        //this.props.handleClose({})
    }

    save()
    {
        const person = this.state.value;

        this.props.handleClose({
            [`${this.props.field}`]: person ? person : null,
        });
    }

    renderBody()
    {
        const field = this.props.field;
        let title = this.props.title;
        let validationState = null;

        return <ApInputStack gap="0">
            <ApAddon
                noRightBorder width="200px"
                validationState={ validationState }
            >
                { title }
            </ApAddon>

            
            <ApSelect
                label={tr("contact_person")}
                value={ this.state.value }
                optionRenderer={ ( item ) => {
                    return <div>
                        <strong>{ item.fullName}</strong>
                    </div>
                }}
                onChange={(contact) => this.setState({ value: contact, changes:true}) }
                objKeyId="id"
                objKeyValue="fullName"
                clearable
                apiUrl={"storage/order/getContactPersons/"+this.props.customer_id}
                
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
        </ApInputStack>

    }

    render()
    {
        const saveDisabled = ( !this.state.value );

        return <div className="poRelatedEdit">
            <ApModal
                show={ this.props.show }
                handleClose={ this.props.handleClose }
                className="narrow"
                onOpen={ this.resetState }
                header={
                    <div className="padding-small">
                        <h4>
                            {tr("add_contact_person")}
                        </h4><br/>

                        <h6></h6>
                    </div>
                }
                body={
                    <div className="padding">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton onClick={ this.close }>
                            <SvgIcon icon="times" type="solid" />
                            {tr("cancel")}
                        </ApButton>

                        <ApButton color="blue"
                            onClick={this.save}
                            disabled={false}
                            className={"save" + (this.state.changes ? " highlight" : "")}>
                            <SvgIcon icon="save" type="solid" />
                            {tr("save")}
                        </ApButton>
                    </div>
                }
            />
        </div>

    }
}

export default EditOrderContactPerson;