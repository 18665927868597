import React from 'react';
import autoBind from 'react-autobind';

import auth              from 'services/Authed/Authed.js';
import api               from 'services/Api/Api.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApButton          from 'common/ApButton/ApButton.js';

import SvgIcon         from 'common/SvgIcon/SvgIcon.js';

import { errorPopper, formatMoney
       , sqlToDateInput 
        , tr} from 'services/Helpers/Helpers.js'
import { getCurrentPage,
         setCurrentPage,
         currentPageIsLocked,
         setPageLock,
         setComponentName } from '../PurchaseOrderHelper.js';
import _ from 'lodash';

import '../PurchaseOrders.css';
import moment from 'moment';


class ImportPOlist extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            data: [],      // should default to []
            approvalData: [],      // should default to []
            pages: -1,     // should default to -1 (which means we don't know how many pages we have)
            loading: true,

            columns: [],
            customColumns: [],

            statusOptions: [],

            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),

            newPoShow: false,
            externalFetchUpdated: false,

            commentDialogShow: false,

            updated: false,
            updating:false,
            
            responsiblePersonOptions: [
                {label: tr('all'), value: ''},
                {label: tr('no_responsible_person'), value: 'no_responsible_person'},
            ],
            responsibleAproverPersonOptions:[
                { label: tr('all'), value: '' },
                { label: tr('no_responsible_person'), value: 'no_responsible_person' },
            ],
            selectedTab:0,

        }
        this.state.columns = this.getColumns();

        this.getTimer = null;

        autoBind(this);
    }

    componentDidMount()
    {
        this.getColumns();
        this.getRelated();
        setComponentName('purchaseOrders')
    }

    componentDidUpdate( prevProps, prevState, snapshot )
    {
        if( JSON.stringify( this.props.instanceOptions ) !== JSON.stringify( prevProps.instanceOptions ) )
            this.getData();
        if (_.isEqual(prevState.responsiblePersonOptions, this.state.responsiblePersonOptions) === false) {
            this.setState({
                columns: this.getColumns(),
            });
        }
        if (prevState.selectedTab !== this.state.selectedTab) {
            this.setState({
                columns: this.getColumns(),
            });
        }
    } 

    refresh()
    {
        this.getData();
    }

    goToImport( id = null )
    {
        let path = `/storage/purchase/order/import/${id}`;
        window.emitter.emit( 'goTo', { pathname: path } );
        setPageLock(true)
    }

    getColumns() {
        return [{
            id: 'po_number',
            Header: tr('po_number'),
            className: "overflowableOnHover",
            onClick: row => this.goToImport(row.id),
            accessor: 'po_number',
            sortable: true,
            filterable: true,
            showInitially: false,
            customizable: true,
        }, {
            id: 'date',
            Header: tr('date'),
            accessor: 'delivery_date',
            filterable: false,
            sortable: true,
            showInitially: false,
            customizable: true,
            width: 150,
            onClick: row => this.goToImport(row.id),
            Cell: props => {
                return sqlToDateInput(props.value);
            },
        }, {
            id: 'due_date',
            Header: tr('due_date'),
            className: "overflowableOnHover",
            onClick: row => this.goToImport(row.id),
            accessor: 'due_date',
            sortable: true,
            filterable: true,
            showInitially: true,
            customizable: true,
            width: 150,
            Cell: props => {
                return <div style={{color: moment().isAfter(props.value) ? 'red': 'inherit'}}>{sqlToDateInput(props.value)}</div>;
            },
        }, {
            id: 'supplier',
            Header: tr('supplier'),
            className: "overflowableOnHover",
            onClick: row => this.goToImport(row.id),
            accessor: 'supplier',
            sortable: true,
            filterable: true,
            showInitially: true,
            customizable: true,
            customFilter: {
                type: "text",
                placeholder: tr('enter_supplier_name')
            },
        }, {
            id: 'reference_our',
            Header: tr('reference_number_our'),
            className: "overflowableOnHover",
            onClick: row => this.goToImport(row.id),
            accessor: 'reference_our',
            sortable: true,
            filterable: false,
            showInitially: false,
            customizable: true,
        }, {
            id: 'reference_your',
            Header: tr('reference_number_your'),
            className: "overflowableOnHover",
            onClick: row => this.goToImport(row.id),
            accessor: 'reference_your',
            sortable: true,
            filterable: false,
            showInitially: false,
            customizable: true,
        }, {
            id: 'description',
            Header: tr('description'),
            className: "overflowableOnHover",
            onClick: row => this.goToImport(row.id),
            accessor: 'description',
            sortable: false,
            filterable: false,
            showInitially: false,
            customizable: true,
            width: 200,

        }, {
            id: 'row_count',
            Header: tr('rows'),
            accessor: 'row_count',
            sortable: true,
            showInitially: true,
            filterable: false,
            customizable: true,
            onClick: row => this.goToImport(row.id),

        }, {
            id: 'price',
            Header: tr('price'),
            accessor: 'sum',
            sortable: true,
            filterable: false,
            showInitially: true,
            customizable: true,
            onClick: row => this.goToImport(row.id),
            Cell: props => {
                if (props.value)
                    return <div className="text-right"> {formatMoney(props.value)} {this.state.currencySign}</div>
                return null;
            },

        },
        {
            id: 'responsible_person_id',
            Header: tr('responsible_person'),
            accessor: 'responsible_person_name',
            sortable: true,
            filterable: true,
            showInitially: false,
            customizable: true,
            onClick: row => this.goToImport(row.id),
            customFilter: {
                type: "select",
                options: this.state.selectedTab == 0 ? this.state.responsiblePersonOptions : this.state.responsibleAproverPersonOptions,
            }
        },
        {
            id: 'status',
            Header: tr('status'),
            accessor: 'status',
            sortable: true,
            filterable: true,
            showInitially: false,
            customizable: true,
            className: "overflowableOnHover",
            onClick: row => this.goToImport(row.id),
            // customFilter: {
            //     type: "select",
            //     options: this.state.selectedTab == 0 ? this.state.responsiblePersonOptions : this.state.responsibleAproverPersonOptions,
            // }
            Cell: props => {
                if (props.value == 'accepted') {
                    return <div className="apStatusBox success">{tr('accepted')}</div>
                }
                if (props.value == 'rejected') {
                    return <div className="apStatusBox warning">{tr('rejected')}</div>
                }
                if (props.value == 'approval') {
                    const approversEl = props.original.approvers && props.original.approvers.map(approver =>
                        <p style={{ margin: '2px' }}>
                            <SvgIcon type="solid" icon={approver.approved ? "check" : "times"} fill={approver.approved ? "green" : "gray"} />
                            {approver.person_detail
                                ? `${approver.person_detail.last_name} ${approver.person_detail.first_name}`
                                : null}
                        </p>);
                    return <ApTooltip block text={approversEl}><div className="apStatusBox info">{tr('further_approver')}</div></ApTooltip>
                }
                if (props.value == 'imported') {
                    return <div className="apStatusBox success">{tr('imported')}</div>
                }
                

                return <div className='apStatusBox'>{tr('new')}</div>
                
            },
        }
        ];

    }

    updateImports() {
        this.setState({ loading: true, updating: true})

       api({
            method: 'get',
            url: 'storage/po/import/update',
        }).then((response) => {
            this.setState({ updated: true, updating :false,})
            this.getData(this.state);
        }).catch((error) => {
            this.setState({ loading: false, updating: false, });
            errorPopper(error, tr('get_error'));
        });
        
    }

    getRelated()
    {
        return api({
            method: 'get',
            url: 'storage/po/related',
        }).then((response) => {
            this.setState({
                loading: false,
                statusOptions: response.statuses,
            });
        }).catch((error) => {
            this.setState({loading:false});
            errorPopper(error, tr('get_error'));
        });
    }
    

    getData( state )
    {
        const fetchPage = currentPageIsLocked() ? getCurrentPage() : state.page
        window.clearTimeout(this.getTimer);
        this.getTimer = window.setTimeout(() => {
            this.setState({loading: true})
            api({
                method: 'get',
                url: 'storage/po/import/list',
                data: {
                    page: fetchPage,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                    externalFetchUpdated: this.state.externalFetchUpdated
                },
            }).then((response) => {
                const responsiblePersonOptions = this.getResponsiblePersonOptions(response);
                this.setState({
                    data: response,
                    loading: false,
                    responsiblePersonOptions: responsiblePersonOptions,
                });
                
                // console.log(response);
            }).catch((error) => {
                console.error(error);
                this.setState({
                    data: [],
                    pages: -1,
                    loading: false,
                    externalFetchUpdated: false
                })
                errorPopper(error, tr('get_error'));
            });
        }, 500);
    }

    getData( state )
    {
        const fetchPage = currentPageIsLocked() ? getCurrentPage() : state.page
        window.clearTimeout(this.getTimer);
        this.getTimer = window.setTimeout(() => {
            this.setState({loading: true})
            api({
                method: 'get',
                url: 'storage/po/import/list',
                data: {
                    page: fetchPage,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                    externalFetchUpdated: this.state.externalFetchUpdated
                },
            }).then((response) => {
                const responsibleAproverPersonOptions = this.getResponsiblePersonOptions(response);
                this.setState({
                    data: response,
                    loading: false,
                    responsiblePersonOptions: responsibleAproverPersonOptions,
                });
                
                // console.log(response);
            }).catch((error) => {
                console.error(error);
                this.setState({
                    data: [],
                    pages: -1,
                    loading: false,
                    externalFetchUpdated: false
                })
                errorPopper(error, tr('get_error'));
            });

            api({
                method: 'get',
                url: 'storage/po/import/approv/list',
                data: {
                    page: fetchPage,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                    externalFetchUpdated: this.state.externalFetchUpdated
                },
            }).then((response) => {
                const responsiblePersonOptions = this.getResponsiblePersonOptions(response);
                this.setState({
                    approvalData: response,
                    loading: false,
                    responsibleAproverPersonOptions: responsiblePersonOptions,
                });

                // console.log(response);
            }).catch((error) => {
                console.error(error);
                this.setState({
                    approvalData: [],
                    pages: -1,
                    loading: false,
                    externalFetchUpdated: false
                })
                errorPopper(error, tr('get_error'));
            });
        }, 500);
    
    }

    getResponsiblePersonOptions(data) {
        let options = [
            {label: tr('all'), value: ''}, 
            {label: tr('no_responsible_person'), value: 'no_responsible_person'}
        ];
        const userOptions = [];
        if (!Array.isArray(data)) return options;
        data.forEach((item) => {
            if (item.responsible_person_id && item.responsible_person_name) {
                if (userOptions.findIndex((option) => option.value === item.responsible_person_id) === -1) {
                    userOptions.push({label: item.responsible_person_name, value: item.responsible_person_id});
                }
            }
        });
        options.push(..._.sortBy(userOptions, ['label']));
        return options;
    }

    render()
    {
        //console.log("state", this.state);
        return (
            <div className="apBox" id="pagePos">
                <div className="pageBackButton" onClick={ () => this.props.history.push( '/storage/purchase/orders' ) }>
                    <SvgIcon icon="arrow-left" type="solid" size="small" />
                        { tr('back') }
                </div>
                <div className="apBoxHeader">
                    <h1>
                       {tr('import_pos')}
                    </h1>
                    <div className="apBoxCorner">
                        <ApButton color="green" disabled={this.state.updated || this.state.updating}
                            onClick={ () => { this.updateImports() } }
                        >{tr('update_pos')}</ApButton> 
                    </div>
                </div>
                <div className="padding">
                    <ApTabs onChange={(change) => { this.setState({selectedTab:change})}}>
                        <ApTab icon="list" label={tr('list')} >
                            <div className="padding">
                                <ApReactTable
                                    rememberId="PurchaseOrdersImport"

                                    onFetchData={(state) => {
                                        if (!currentPageIsLocked()) {
                                            setCurrentPage(state.page)
                                        }

                                        this.getData(state)
                                    }}

                                    loading={this.state.loading}
                                    data={this.state.data}
                                    columns={this.state.columns}

                                    filterable

                                    noDataText={tr('no_orders_found')}
                                    showPagination={false}
                                    minRows={20}
                                    showFiltersInitially={true}
                                />
                            </div>
                            
                        </ApTab>
                        <ApTab icon="list" label={tr('waiting_for_approval1')} badgeColor='orange' badge={this.state.approvalData.length} >
                            <div className="padding">
                                <ApReactTable
                                    rememberId="PurchaseOrdersImportApproval"

                                    onFetchData={(state) => {
                                        if (!currentPageIsLocked()) {
                                            setCurrentPage(state.page)
                                        }

                                        this.getData(state)
                                    }}

                                    loading={this.state.loading}
                                    data={this.state.approvalData}
                                    columns={this.state.columns}

                                    filterable

                                    noDataText={tr('no_orders_found')}
                                    showPagination={false}
                                    minRows={20}
                                    showFiltersInitially={true}
                                />
                            </div>
                            
                        </ApTab>
                        {/* <ApTab icon="list" label={tr('imported')} align="right">
                            <div className="padding">
                                <ApReactTable
                                    rememberId="PurchaseOrdersImport"

                                    onFetchData={(state) => {
                                        if (!currentPageIsLocked()) {
                                            setCurrentPage(state.page)
                                        }

                                        this.getData(state)
                                    }}

                                    loading={this.state.loading}
                                    data={this.state.data}
                                    columns={this.state.columns}

                                    filterable

                                    noDataText={tr('no_orders_found')}
                                    showPagination={false}
                                    minRows={20}
                                    showFiltersInitially={true}
                                />
                            </div>

                        </ApTab> */}
                        
                    </ApTabs>
                                    
                           

                        </div>
            </div>
        );
    }
}

export default ImportPOlist;
