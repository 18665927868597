
import React from 'react';
import autoBind from 'react-autobind';
import "./publicTimeAttendance.css"
import api from 'services/Api/Api.js';
import { tr } from 'services/Helpers/Helpers';
import PropTypes from 'prop-types';
import ApModal from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import moment from 'moment';



class PublicTimeAttendance extends React.Component {
    constructor(props) {
        super(props);

        this.cardInput = null;
        
        this.state = {
            card: "",
            showInOut: false,
            token: this.props.match.params.token,
        }
        autoBind(this);
    }
    handleSubmit(event) {
        console.log(event.target[0].value)
        let card = event.target[0].value;
        
        
        if (card != "") {
            this.setState({ card, showInOut :true});
            this.cardInput.value = "";
        }
        
        event.preventDefault();
    }
    handleChange(event) {
        this.setState({ card: event.target.value });
    }
    
    componentDidMount() {
        this.focusCardInput();
        this.updateStatusTimer = setInterval(() => this.updateStatus(), 1*60*60*1000);
        this.updateStatus();
    }
    componentWillUnmount() {
        this.updateStatusTimer = null;
    }
    updateStatus() {
        let data = {
            token: this.state.token,
            timestamps:[],
        }
        api({
            method: 'post',
            url: `timetrackin/external/timeattendance/statusupdate`,
            errorPopper: 'Lähetys ei onnistunut',
            data: data
        }).then((response) => {
            this.focusCardInput();
            this.setState({ showInOut: false, loading: false });

        }).catch((error) => {
            this.setState({
                loading: false
            });
        })
    }

    focusCardInput() {
        this.cardInput.focus();
    }

    sendTimeStamp(mode) {
        let data = {
            id: null,
            token: this.state.token,
            card: this.state.card,
            timestamp: new Date().toISOString(),
            mode:mode,
        }
        api({
            method: 'post',
            url: `timetrackin/external/timeattendance/submit`,
            errorPopper: 'Lähetys ei onnistunut',
            data: data
        }).then((response) => {
            this.focusCardInput();
            this.setState({ showInOut:false,loading: false });
            
        }).catch((error) => {
            this.setState({
                loading: false
            });
        })
    }

    




    render() 
    {
        return (
            <div id='publicTimeAttendance'>
            <div className='fullScreen' onClick={this.focusCardInput}>
                <h1>{tr("time_attendance")}</h1>
                <Clock size={400} timeFormat="24hour" hourFormat="standard" />
                <form onSubmit={this.handleSubmit}>

                        <input
                            className='inputBox'
                        ref={(input) => { this.cardInput = input; }}
                        // defaultValue=""
                        // value={this.state.card}
                        // onChange={this.handleChange}
                    />
  
                </form>

                </div>
                <ApModal
                    show={this.state.showInOut}
                    backdrop="static"
                    className=""
                    header={
                        <div className="padding-small">
                            <h1>{tr("login_or_logout")}</h1>
                        </div>
                    }
                    body={
                        <div>
                            <div className='clockTime'>{ moment().format("hh:mm:ss")}</div>
                            <div className="padding" style={{ marginTop: 30 }}>
                                <ApButton className="out  size-XXXgiant padding" onClick={() => { this.sendTimeStamp("out") }}>
                                    <SvgIcon icon="arrow-right-from-bracket" type="solid" />
                                    {tr("logout")}
                                </ApButton>
                                <ApButton className="in color-blue size-XXXgiant" onClick={() => { this.sendTimeStamp("in") }}>
                                    <SvgIcon icon="right-to-bracket" type="solid" />
                                    {tr("login")}

                                </ApButton>
                            </div>
                        </div>
                        
                    }
                    footer={
                        <div className='back '>
                            <ApButton onClick={() => {
                                this.setState({ showInOut: false });
                                this.focusCardInput();
                                }}
                                style={{ width: "100%", height: "100%" }}>
                                <SvgIcon icon="times" type="solid" />
                                {tr("cancel")}
                            </ApButton>
                        </div>
                        
                    }
                    //     <div className="padding" style={{ display: 'flex', flexDirection: 'row' }}>

                    //         

                    //         {this.props.itemId &&
                    //             <ApButton className="remove" color="red" style={{ marginLeft: 20 }} onClick={this.deleteItem}>
                    //                 <SvgIcon icon="trash" type="solid" />
                    //                 Poista
                    //             </ApButton>
                    //         }

                    //         <ApButton
                    //             className="save"
                    //             color="blue"
                    //             onClick={this.clickSaveButton}
                    //             loading={this.state.loading}
                    //             disabled={}
                    //             style={{ marginLeft: 'auto' }}
                    //         >
                    //             <SvgIcon icon="save" type="solid" />
                    //             Tallenna
                    //         </ApButton>

                    //     </div>
                    // }
                />

            </div>
            
        );

    }
}
export default PublicTimeAttendance


class Clock extends React.Component {
    constructor(props) {
        super(props);

        this.state = { time: new Date() };
        this.radius = this.props.size / 2;
        this.drawingContext = null;
        this.draw24hour = this.props.timeFormat.toLowerCase().trim() === "24hour";
        this.drawRoman = !this.draw24hour && this.props.hourFormat.toLowerCase().trim() === "roman";

    }

    componentDidMount() {
        this.getDrawingContext();
        this.timerId = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    getDrawingContext() {
        this.drawingContext = this.refs.clockCanvas.getContext('2d');
        this.drawingContext.translate(this.radius, this.radius);
        this.radius *= 0.9;
    }

    tick() {
        this.setState({ time: new Date() });
        const radius = this.radius;
        let ctx = this.drawingContext;
        this.drawFace(ctx, radius);
        this.drawNumbers(ctx, radius);
        this.drawTicks(ctx, radius);
        this.drawTime(ctx, radius);
    }

    drawFace(ctx, radius) {
        ctx.beginPath();
        ctx.arc(0, 0, radius, 0, 2 * Math.PI);
        ctx.fillStyle = "white";
        ctx.fill();

        const grad = ctx.createRadialGradient(0, 0, radius * 0.95, 0, 0, radius * 1.05);
        grad.addColorStop(0, "#333");
        grad.addColorStop(0.5, "white");
        grad.addColorStop(1, "#333");
        ctx.strokeStyle = grad;
        ctx.lineWidth = radius * 0.1;
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(0, 0, radius * 0.05, 0, 2 * Math.PI);
        ctx.fillStyle = "#333";
        ctx.fill();
    }

    drawNumbers(ctx, radius) {
        const romans = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"];
        const fontBig = radius * 0.15 + "px Arial";
        const fontSmall = radius * 0.075 + "px Arial";
        let ang, num;

        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
        for (num = 1; num < 13; num++) {
            ang = num * Math.PI / 6;
            ctx.rotate(ang);
            ctx.translate(0, -radius * 0.78);
            ctx.rotate(-ang);
            ctx.font = fontBig;
            ctx.fillStyle = "black";
            ctx.fillText(this.drawRoman ? romans[num - 1] : num.toString(), 0, 0);
            ctx.rotate(ang);
            ctx.translate(0, radius * 0.78);
            ctx.rotate(-ang);

            // Draw inner numerals for 24 hour time format
            if (this.draw24hour) {
                ctx.rotate(ang);
                ctx.translate(0, -radius * 0.60);
                ctx.rotate(-ang);
                ctx.font = fontSmall;
                ctx.fillStyle = "red";
                ctx.fillText((num + 12).toString(), 0, 0);
                ctx.rotate(ang);
                ctx.translate(0, radius * 0.60);
                ctx.rotate(-ang);
            }
        }

        // Write author text
        // ctx.font = fontSmall;
        // ctx.fillStyle = "#3D3B3D";
        // ctx.translate(0, radius * 0.30);
        // ctx.fillText("@AlanMunsonTech", 0, 0);
        // ctx.translate(0, -radius * 0.30);
    }

    drawTicks(ctx, radius) {
        let numTicks, tickAng, tickX, tickY;

        for (numTicks = 0; numTicks < 60; numTicks++) {

            tickAng = (numTicks * Math.PI / 30);
            tickX = radius * Math.sin(tickAng);
            tickY = -radius * Math.cos(tickAng);

            ctx.beginPath();
            ctx.lineWidth = radius * 0.010;
            ctx.moveTo(tickX, tickY);
            if (numTicks % 5 === 0) {
                ctx.lineTo(tickX * 0.88, tickY * 0.88);
            } else {
                ctx.lineTo(tickX * 0.92, tickY * 0.92);
            }
            ctx.stroke();
        }
    }

    drawTime(ctx, radius) {
        const now = this.state.time;
        let hour = now.getHours();
        let minute = now.getMinutes();
        let second = now.getSeconds();

        // hour
        hour %= 12;
        hour = (hour * Math.PI / 6) + (minute * Math.PI / (6 * 60)) + (second * Math.PI / (360 * 60));
        this.drawHand(ctx, hour, radius * 0.5, radius * 0.05);
        // minute
        minute = (minute * Math.PI / 30) + (second * Math.PI / (30 * 60));
        this.drawHand(ctx, minute, radius * 0.8, radius * 0.05);
        // second
        second = (second * Math.PI / 30);
        this.drawHand(ctx, second, radius * 0.9, radius * 0.02, "red");
    }

    drawHand(ctx, position, length, width, color) {
        color = color || "black";
        ctx.beginPath();
        ctx.lineWidth = width;
        ctx.lineCap = "round";
        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        ctx.moveTo(0, 0);
        ctx.rotate(position);
        ctx.lineTo(0, -length);
        ctx.stroke();
        ctx.rotate(-position);
    }

    render() {
        return (
            <div className="Clock" style={{ width: String(this.props.size) + 'px' }}>
                <canvas width={this.props.size} height={this.props.size} ref="clockCanvas" />
            </div>
        );
    }
}

Clock.defaultProps = {
    size: 400, // size in pixels => size is length & width
    timeFormat: "24hour", // {standard | 24hour} => if '24hour', hourFormat must be 'standard'
    hourFormat: "standard" // {standard | roman}
};

Clock.propTypes = {
    size: PropTypes.number,
    timeFormat: PropTypes.string,
    hourFormat: PropTypes.string
};
