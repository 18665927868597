/* eslint-disable eqeqeq */
import { isObject, validateEmail, validateDate } from '../Helpers/Helpers.js';
import moment from 'moment';

/**
 * Purpose of this class is to provide some coherence to form validation.
 * I have many differrent solutions on different components. I hape using
 * this class I dont need to repeat certain things so often.
 * This is no way best solution but hopefully it will
 * provide some help.
 *
 * Usage:
 * Define new ApValidate on component constructor,
 *   first argument needs to be the component that contains the state that validated data is saved in so basically "this"
 *   second argument is the object of validators where the key is the name of the state object to be validated
 *
 *  Validator can be a string if it is defined in this class with prefix "filter_" for example see required
 *  Validator can be function that returns true or false (example2)
 *  Validator can be object with following keys (example3)
 *    filter:  the string of function for filtering
 *    state:   what to return for input state
 *    text:    what to return for input text
 *    textAll:  what return for getStatusAll, if not defined will return text
 *    stateValid: boolan if state should return 'success' on valid (dafault null)
 *
 *  If value to be validated is an array of objects Validator should be object with single value filters" the filters
 *  should be object where each key is name of the key to be validated and the value should be a validator (example4)
 *
 *  Validator can also be array containinig multiple validators (example5)
 *
 *  Validation state can also be set manually for instance when running async function
 *
 *   arguments to setInvalid:
 *     state: what will the state return
 *     text: what will the text return
 *     key: value to validate from key
 *     index: (optional) if key is array of object the index to set validation
 *     objectkey: (optional) if key is array of object the key to set validation
 *
 *   arguments to setValid:
 *     key: value to validate from key
 *     index: (optional) if key is array of object the index to set validation
 *     objectkey: (optional) if key is array of object the key to set validation
 *
 *  this.validator = new ApValidate(this, {
 *    example1: 'required',
 *    example2: (value) => {return (value === '');},
 *    example3: { filter: 'required', state: 'warning', text: 'Example3 can not be empty!!'},
 *    example4: { filters: {
 *       theObject1: 'required',
 *       theObject2: (value) => {return (value === '');},
 *    }}
 *    example5: ['required', { filter: 'required', state: 'warning', text: 'Example5 can not be empty!!'}]
 *
 *  setInvalid('warning', 'example6 is manually set to invalid', example6, index, 'theObject1')
 *  setValid('example7', index, 'theObject1');
 *
 **/
class ApValidate {
    constructor(rObject, validators, hideErrors = false)
    {
        this.rObject = rObject;
        this.validators = validators;
        this.manual = {};
        this.hideErrors = hideErrors;
    }

    //-------------------------
    // Common filters
    //-------------------------

    filter_required_state = 'error';
    filter_required_text  = 'Valinta pakollinen';
    filter_required(value)
    {
        return Boolean(value);
    }

    filter_moment_state = 'error';
    filter_moment_text  = 'Virheellinen päivämäärä';
    filter_moment(value)
    {
        return (moment.isMoment(value));
    }

    filter_email_state = 'error';
    filter_email_text  = 'Ei ole sähköpostiosoite';
    filter_email(value)
    {
        return validateEmail(value);
    }

    filter_emailOptional_state = 'error';
    filter_emailOptional_text  = 'Ei ole sähköpostiosoite';
    filter_emailOptional(value)
    {
        if( !value || value.length == 0 ) return true;
        return validateEmail(value);
    }

    filter_date_state = 'error';
    filter_date_text  = 'Ei ole päivämäärä';
    filter_date(value)
    {
        return validateDate(value);
    }

    filter_dateOptional_state = 'error';
    filter_dateOptional_text  = 'Ei ole päivämäärä';
    filter_dateOptional(value)
    {
        if( !value || value.length == 0 ) return true;
        return validateDate(value);
    }

    //-------------------------
    // Error hiding
    //-------------------------
    setErrorsHidden()
    {
        this.hideErrors = true;
    }
    setErrorsVisible()
    {
        this.hideErrors = false;
    }

    //-------------------------
    // Getting message methods
    //-------------------------

    getState(name, index = null, prop = null)
    {
        if(this.hideErrors)
            return null;
        return this.getError('state', name, index, prop);
    }

    getText(name, index = null, prop = null)
    {
        if(this.hideErrors)
            return null;
        return this.getError('text', name, index, prop);
    }

    getError(type, name, index, prop)
    {
        const manual = this.getManualError(type, name, index, prop);
        if(manual)
            return manual;
        const value = this.getValueToCheck(name, index, prop);
        const validators = this.getValidators(name, prop);
        return this.getCalcError(type, value, validators, index);
    }

    getManualError(type, name, index = null, prop = null)
    {
        // Text all is not defined in manual errors
        if(type === 'textAll')
            type = 'text';

        if(index !== null)
        {
            if(this.manual[name] && this.manual[name][index] && this.manual[name][index][prop])
            {
                return this.manual[name][index][prop][type];
            }
        }
        else if(this.manual[name])
        {
            return this.manual[name][type];
        }
        return false;
    }

    getCalcError(type, value, validators, index = null)
    {
        if(validators === null)
        {
            return false;
        }

        let message = null;
        validators.every(v => {

            let data = null;
            if(isObject(v))
            {
                data = v;
                v = v.filter;
                if(type === 'state' && data.stateValid)
                    message = 'success';
            }
            if(typeof v === 'function')
            {
                if(!v(value, index))
                {
                    message = this.getResponseMessage(type, v, data);
                    return false;
                }
                return true;
            }
            else if(typeof v === 'string')
            {
                if (typeof this['filter_'+v] !== 'function')
                {
                    console.error(`ApValidate: Validation "${v}" not defined`);
                }
                else
                {
                    if(!this['filter_'+v](value))
                    {
                        message = this.getResponseMessage(type, v, data);
                        return false;
                    }
                }
                return true;
            }
            else
            {
                console.error(`ApValidate: Invalid filter "${v}"`);
            }
            return true;
        });
        return message;
    }

    getResponseMessage(type, filter, data)
    {
        // Set default value for message
        let message = 'Virheellinen syöte';
        if(type === 'state')
        {
            message = 'error';
        }
        if(typeof filter === 'string')
        {
            if(this['filter_'+filter+'_'+type])
            {
                message = this['filter_'+filter+'_'+type];
            }
            else if(type === 'textAll' && this['filter_'+filter+'_text'])
            {
                message = this['filter_'+filter+'_text'];
            }
        }
        if(isObject(data))
        {
            if(type in data)
            {
                message = data[type];
            }
            else if(type === 'textAll' && 'text' in data)
            {
                // If textAll is not defined use text instead
                message = data['text'];
            }
        }
        return message;
    }

    getValueToCheck(name, index = null, prop = null)
    {
        let nameStack = name.split('.');
        name = nameStack.splice(-1);
        let theObject = this.rObject.state;
        if(nameStack)
        {
            nameStack.forEach(item => {
                theObject = theObject[item];
            });
        }

        if(typeof theObject === 'undefined')
        {
            // console.error(`ApValidate: theObject not defined`);
            return null;
        }

        if(!(name in theObject))
        {
            //console.error(`ApValidate: "${name}" not defined in object state`);
            return null;
        }
        if(index !== null)
        {
            return theObject[name][index][prop];
        }
        else
        {
            return theObject[name];
        }
    }

    getValidators(name, prop = null)
    {
        if (!(name in this.validators))
        {
            console.error(`ApValidate: Validation for "${name}" not defined`);
            return null;
        }

        let validators = null;
        if(prop !== null)
        {
            validators = this.validators[name]['filters'][prop];
        }
        else
        {
            validators = this.validators[name];
        }

        if(!Array.isArray(validators))
        {
            validators = [validators];
        }
        return validators;
    }

    getStatusAll(type = null)
    {
        let valid = true;
        let messages = [];
        const names = Object.keys(this.validators);
        // Ok so how we do this on arrays we just go thru them all
        names.forEach((name) => {
            const values = this.rObject.state[name];
            const validators = this.getValidators(name);
            validators.forEach(val => {
                if (val.optional) return
                if(val.filters)
                {
                    const props = Object.keys(val.filters);
                    props.forEach(prop => {
                        values.forEach((value, index) => {
                            const error = this.getError('textAll', name, index, prop)
                            if(error)
                            {
                                messages.push(error);
                                valid = false;
                            }
                        });
                    });
                }
                else
                {
                    const error = this.getError('textAll', name)
                    if(error)
                    {
                        messages.push(error);
                        valid = false;
                    }
                }
            });
        });
        if(type === 'boolean')
        {
            return valid;
        }
        else if( type === 'array' )
        {
            return messages;
        }
        if(messages.length > 0)
        {
            return messages[0];
        }
        return null;
    }

    //-------------------------
    // Manual set validation
    //-------------------------

    setValid(name, index = null, prop = null)
    {
        if(index !== null && this.manual[name] && this.manual[name][index] && this.manual[name][index][prop])
            delete this.manual[name][index][prop];
        else
            delete this.manual[name];
        this.rObject.forceUpdate();
    }

    setInvalid(state, text, name, index = null, prop = null)
    {
        const data = {state: state, text: text}
        if(index !== null)
        {
            if(!this.manual[name])
            {
                this.manual[name] = [];
            }
            if(!this.manual[name][index])
            {
                this.manual[name][index] = {}
            }
            this.manual[name][index][prop] = data;
        }
        else
            this.manual[name] = data;
        this.rObject.forceUpdate();
    }
}

export default ApValidate;
