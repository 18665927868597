import React from 'react';
import autoBind from 'react-autobind';

import ApReactTable       from 'common/ApReactTable/ApReactTable.js';
import api                from 'services/Api/Api.js';
import auth               from 'services/Authed/Authed.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApTooltip          from 'common/ApTooltip/ApTooltip.js';
import ApDropdown         from 'common/ApDropdown/ApDropdown.js';
import { errorPopper
       , formatMoney
       , sqlToDateInput
       , tr } from 'services/Helpers/Helpers.js'
import { getTypeName
       , getTypeIcon }    from 'modules/Storage/common/StorageHelpers.js';

import './Tracking.css';

class Tracking extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            data: [],
            columns: this.getColumns(),
            loading: true,

            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),
        };
        autoBind(this);
    }

    componentDidMount()
    {
        this.getData()
    }

    getData()
    {
        //console.log( 'Getting data');
        this.setState({ loading: true });

        api({
            method: 'get',
            url: 'storage/tracking',
        }).then((response) => {
            //console.log( 'tracking', response );

            this.setState({
                data: response,
                loading: false,
            });

        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });
    }

    goTo( type, id )
    {
        if( type === 'po' )
            window.emitter.emit( 'goTo', { pathname: `/storage/purchase/order/id/${ id }`} );
        else if( type === 'delivery' )
            window.emitter.emit( 'goTo', { pathname: `/storage/logistic/delivery/show/${ id }`} );
    }

    goToArrival( type, id )
    {
        if( type === 'po' )
            window.emitter.emit( 'goTo', { pathname: `/storage/logistic/arrival/po/${ id }`} );
        else if( type === 'delivery' )
            window.emitter.emit( 'goTo', { pathname: `/storage/logistic/arrival/delivery/${ id }`} );
    }

    getColumns()
    {
        return [{
            id: 'date',
            Header: tr('date'),
            accessor: 'date',
            filterable: false,
            sortable: true,
            width: 150,
            onClick: row => this.goTo( row.type, row.related_id ),
            Cell: props => {
                return sqlToDateInput( props.value );
            },
        },{
            id: 'type',
            Header: tr('related'),
            className: "overflowableOnHover",
            accessor: 'type',
            filterable: false,
            sortable: true,
            onClick: row => this.goTo( row.type, row.related_id ),
            Cell: props => {
                let icon = <SvgIcon icon={ getTypeIcon( props.original.related_type ) } type="solid" />
                let name = getTypeName( props.original.related_type );
                let typeTitle =  props.original.origin_id == null ? tr('shipment') : tr("restore");
                // let typeTitle =  props.original.related_title;
                return <div>
                    <ApTooltip position="top" text={ name }>
                        { icon }
                    </ApTooltip>
                    { name } { typeTitle }
                </div>
            },
        },{
            id: 'sender',
            Header: tr('sender'),
            className: "overflowableOnHover",
            accessor: 'sender_title',
            filterable: false,
            sortable: true,
            onClick: row => this.goTo( row.type, row.related_id ),
            Cell: props => {
                let icon = <SvgIcon icon={ getTypeIcon( props.original.sender_type ) } type="solid" />
                let name = getTypeName( props.original.sender_type );
                let typeTitle = props.original.sender_title;
                return <div>
                    <ApTooltip position="top" text={ name }>
                        { icon }
                    </ApTooltip>
                    { typeTitle }
                </div>
            },
        },{
            id: 'receiver',
            Header: tr('recipient'),
            className: "overflowableOnHover",
            accessor: 'receiver_title',
            filterable: false,
            sortable: true,
            onClick: row => this.goTo( row.type, row.related_id ),
            Cell: props => {
                let icon = <SvgIcon icon={ getTypeIcon( props.original.receiver_type ) } type="solid" />
                let name = getTypeName( props.original.receiver_type );
                let typeTitle = props.original.receiver_title;
                return <div>
                    <ApTooltip position="top" text={ name }>
                        { icon }
                    </ApTooltip>
                    { typeTitle }
                </div>
            },
        },{
            id: 'price',
            Header: tr('value'),
            // accessor: 'price',
            accessor: row => Number(row.price) ? Number(row.price) : '0',
            filterable: false,
            sortable: true,
            onClick: row => this.goTo( row.type, row.related_id ),
            Cell: props => {
                if( props.value )
                    return <div className="text-right"> { formatMoney( props.value ) } { this.state.currencySign }</div>
                return null;
            },

        },{
            id: 'ccount',
            Header: tr('storage_component_count'),
            accessor: 'components_count',
            filterable: false,
            sortable: true,
            width: 80,
            onClick: row => this.goTo( row.type, row.related_id ),
            Cell: props => {
                return <div className="text-right">{ props.value }</div>
            }
        }];
    }

    rowActions( row )
    {
        const actions = [
            {
                icon: "eye",
                label: tr('show'),
                action: ( item ) => { this.goTo( item.type, item.related_id ) },
            },{
                icon: "sign-in-alt",
                label: tr('record_reception'),
                action: ( item ) => { this.goToArrival( item.type, item.related_id ) },
            },
        ];

        return (
            <ApDropdown
                button={ <SvgIcon className="defaultRowActionIcon" icon="ellipsis-h" type="solid" /> }
                actions={ actions }
                actionId={ row }
            />
        );
    }

    render()
    {
        return (
            <div className="apBox" id="pageTracking">
                <div className="apBoxHeader">
                    <h1>
                        { tr('logistics_tracking') }
                    </h1>
                    <p>{ tr('logistics_tracking_info') }</p>
                </div>

                <div className="padding">
                    <div className="apForm">
                        <div className="apFormColumn fullColumn">
                            <ApReactTable
                                loading={ this.state.loading }
                                data={ this.state.data }
                                columns={ this.state.columns }
                                filterable
                                minRows={ 10 }
                                rowActions={ this.rowActions }
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tracking;
