/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import { keyExists, setNested, groupify } from 'services/Helpers/Helpers.js';

import './UserTracking.css';

export default class UserTracking extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            selectedCompany: null,
            selectedUser: null,
            companies: [],
            users: [],
            data: false,
            unsavedChanges: false,
            replaceAllTxt: '',
            error: false,
        }
        autoBind(this);
    }

    componentDidMount()
    {
        this.getUserData();
    }

    getUserData()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'management/trackuser/list',
        }).then(( response ) => {

            //console.log('response', response );

            let companies = [ { value: false, label: " - Kaikki yritykset - " }];
            if( response.companies )
                response.companies.forEach( company => {
                    companies.push( {
                        value: company.id,
                        label: company.name,
                    });
                });

            let users = [];
            if( response.users )
                response.users.forEach( user => {
                    users.push({
                        value: user.id,
                        label: user.username + ( user.is_admin ? " (Pääkäyttäjä)" : "" ),
                        company_id: user.company_id,
                    });
                });

            this.setState({ 
                loading: false,
                companies: companies,
                users: users,
                error: false,
            });
            
        }).catch( ( error ) => {
            this.setState({ 
                loading: false,
                error: "Käyttäjien ja yritysten haku epäonnistui!" 
            });
        });
    }

    getCompanyName( id )
    {
        for( let i = 0; i < this.state.companies.length; i++ )
            if( this.state.companies[ i ].value == id )
                return this.state.companies[ i ].label;

        return "Nimetön yritys";
    }

    getUsers()
    {
        let users = [{ value: false, label: " - Valitse käyttäjä - " }];

        if( this.state.selectedCompany > 0 )
        {
            this.state.users.forEach( user => {
                if( user.company_id == this.state.selectedCompany )
                    users.push( user );
            });
        }
        else 
        {
            let groups = groupify( this.state.users, "company_id" );

            for( const groupId in groups )
            {
                users.push({
                    label: this.getCompanyName( groupId ),
                    options: groups[ groupId ]
                });
            }
        }
        return users;
    }

    trackUser()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'management/trackuser/' + this.state.selectedUser,
        }).then(( response ) => {
            //console.log('track user data', response );
            this.setState({ 
                loading: false,
                data: response,
                unsavedChanges: false,
                error: false,
            });
        }).catch( ( error ) => {
            this.setState({ 
                loading: false,
                error: "Käyttäjän tietojen hakeminen epäonnistui!",
            });
        });
    }

    parseData()
    {
        if( !this.state.data )
            return false;

        let data = [];

        if( keyExists( this.state.data, "timetracking.entries" ) )
            data.push({
                key: "timetracking.entries",
                id: "id",
                title: "Tuntikirjaukset (kirjaaja)",
                rows: this.state.data.timetracking.entries,
                columns: [ "id", "date", "user_id", "description", "status", "type", "is_misentry", "approver_id" ],
                replaceColumn: "user_id",
            });
        
        if( keyExists( this.state.data, "timetracking.approver" ) )
            data.push({
                key: "timetracking.approver",
                id: "id",
                title: "Tuntikirjaukset (hyväksyjä)",
                rows: this.state.data.timetracking.approver,
                columns: [ "id", "date", "user_id", "description", "status", "type", "is_misentry", "approver_id" ],
                replaceColumn: "approver_id",
            }); 

        if( keyExists( this.state.data, "users.suborindates" ) )
            data.push({
                key: "users.suborindates",
                id: "user_id",
                title: "Käyttäjät (esimiehenä alaisille)",
                rows: this.state.data.users.suborindates,
                columns: [ "user_id", "supervisor_id" ],
                replaceColumn: "supervisor_id",
            });

        if( keyExists( this.state.data, "users.supervisors" ) )
            data.push({
                key: "users.supervisors",
                id: "supervisor_id",
                title: "Käyttäjät (alaisena esimiehelle)",
                rows: this.state.data.users.supervisors,
                columns: [ "supervisor_id", "user_id" ],
                replaceColumn: "user_id",
            }); 

        if( keyExists( this.state.data, "users.hour_approvables" ) )
            data.push({
                key: "users.hour_approvables",
                id: "user_id",
                title: "Tuntien hyväksyjä",
                rows: this.state.data.users.hour_approvables,
                columns: [ "user_id", "job_description", "hour_entry_approver_id" ],
                replaceColumn: "hour_entry_approver_id",
            }); 

        if( keyExists( this.state.data, "crm.company_account_manager" ) )
            data.push({
                key: "crm.company_account_manager",
                id: "id",
                title: "CRM Yritys (vastuuhenkilö)",
                rows: this.state.data.crm.company_account_manager,
                columns: [ "id", "name", "is_active", "account_manager_id" ],
                replaceColumn: "account_manager_id",
            }); 

        if( keyExists( this.state.data, "crm.company_creator" ) )
            data.push({
                key: "crm.company_creator",
                id: "id",
                title: "CRM Yritys (luoja)",
                rows: this.state.data.crm.company_creator,
                columns: [ "id", "name", "is_active", "creator_id" ],
                replaceColumn: "creator_id",
            }); 

        if( keyExists( this.state.data, "crm.address_creator" ) )
            data.push({
                key: "crm.address_creator",
                id: "id",
                title: "CRM Yritysosoite (luoja)",
                rows: this.state.data.crm.address_creator,
                columns: [ "id", "crm_company_id", "type", "name", "street", "city", "creator_id" ],
                replaceColumn: "creator_id",
            }); 

        if( keyExists( this.state.data, "crm.contact_creator" ) )
            data.push({
                key: "crm.contact_creator",
                id: "id",
                title: "CRM Yhteystieto (luoja)",
                rows: this.state.data.crm.contact_creator,
                columns: [ "id", "crm_company_id", "first_name", "last_name", "extranet_active", "extranet_username", "creator_id" ],
                replaceColumn: "creator_id",
            });

        if( keyExists( this.state.data, "crm.event" ) )
            data.push({
                key: "crm.event",
                id: "id",
                title: "CRM Tapahtuma",
                rows: this.state.data.crm.event,
                columns: [ "id", "crm_company_id", "type", "occurred_at", "title", "user_id" ],
                replaceColumn: "user_id",
            }); 

        if( keyExists( this.state.data, "project.manager" ) )
            data.push({
                key: "project.manager",
                id: "id",
                title: "Projekti (projektipäällikkö)",
                rows: this.state.data.project.manager,
                columns: [ "id", "project_code", "name", "begin_date", "end_date", "manager_id" ],
                replaceColumn: "manager_id",
            }); 

        if( keyExists( this.state.data, "project.creator" ) )
            data.push({
                key: "project.creator",
                id: "id",
                title: "Projekti (luoja)",
                rows: this.state.data.project.creator,
                columns: [ "id", "project_code", "name", "begin_date", "end_date", "creator_id" ],
                replaceColumn: "creator_id",
            }); 

        if( keyExists( this.state.data, "project.hour_approver" ) )
            data.push({
                key: "project.hour_approver",
                id: "id",
                title: "Projekti (tuntihyväksyjä)",
                rows: this.state.data.project.hour_approver,
                columns: [ "id", "project_code", "name", "begin_date", "end_date", "workhour_approver_id" ],
                replaceColumn: "workhour_approver_id",
            });

        return data;
    }

    handleDataChange( key, value )
    {
        let data = { ...this.state.data };
        data = setNested( data, key, value );
        this.setState({ 
            data: data,
            unsavedChanges: true,
            replaceAllTxt: '',
        });
    }

    handleReplaceAll( e, blocks )
    {
        const value = e.target.value;
        let data = { ...this.state.data };

        blocks.forEach( b => {
            b.rows.forEach( ( r, rIndex ) => {
                const key = b.key + "[" + rIndex + "]." + b.replaceColumn;
                data = setNested( data, key, value );
            });
        });

        this.setState({
            data: data,
            replaceAllTxt: value,
            unsavedChanges: true,
        });
    }

    save() 
    {
        const data = this.parseData();
        const changes = [];
        data.forEach( d => {
            if( d.rows.length > 0 )
            {
                d.rows.forEach( r => {
                    if( this.state.selectedUser != r[ d.replaceColumn ] )
                        changes.push({ 
                            key: d.key,
                            idColumn: d.id,
                            id: r[ d.id ],
                            valueColumn: d.replaceColumn,
                            value: r[ d.replaceColumn ]
                        });
                })
            }
        });

        this.setState({ loading: true, error: false });
        api({
            method: 'post',
            url: 'management/trackuser/replace',
            data: {
                user_id: this.state.selectedUser,
                changes: changes,
            }
        }).then(( response ) => {

            //console.log('replace done!', response );

            // Reload results
            this.trackUser();
            
        }).catch( ( error ) => {
            this.setState({ 
                loading: false,
                error: "Ups.. jotain meni pieleen, muutoksia ei tallennettu!", 
            });
        });
    }

    render()
    {

        const data = this.parseData();

        return (
            <div className="apBox" id="pageManagementUserTracking">

                <div className="apBoxHeader">
                    <h1>Käyttäjän jäljitys</h1>
                    <p>Voit tarkistaa yksittäisen käyttäjän kaikki toimet tämän työkalun avulla ja tarvittaessa korvata osan toisella käyttäjällä</p>
                </div>

                <div className="padding">
                 
                    <ApInputStack>
                        <ApInput
                            width="30%"
                            type="select"
                            id="company"
                            name="company"
                            value={ this.state.selectedCompany }
                            onChange={ (e) => { this.setState({ selectedCompany: e.target.value, selectedUser: null }) } }
                            options={ this.state.companies }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                        <ApInput
                            width="30%"
                            type="select"
                            id="user"
                            name="user"
                            value={ this.state.selectedUser }
                            onChange={ (e) => { this.setState({ selectedUser: e.target.value }) } }
                            options={ this.getUsers() }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                    <ApInputStack>
                        <ApAddon custom width="250px">
                            <ApButton color="blue" onClick={ this.trackUser } disabled={ !this.state.selectedUser || this.state.loading }>
                                <SvgIcon icon="fingerprint" type="solid" />
                                Jäljitä käyttäjä
                            </ApButton>
                        </ApAddon>
                    </ApInputStack>

                    { data && data.map( ( d, index ) => {
                        return (
                            <div key={ index } className="block">

                                <h3>{ d.title } <small> - { d.rows.length } rivi(ä)</small></h3>
                                { d.rows.length == 0 &&
                                    <div className="apMsg">Ei merkintöjä löytynyt</div>
                                }
                                { d.rows.length > 0 && 
                                    <div className="rows">
                                        <table>
                                            <thead>
                                                <tr>
                                                { d.columns.map( ( c, cIndex ) => 
                                                    <th key={ cIndex } className={ d.id == c ? "idColumn" : "" }>{ c || "???" }</th>
                                                )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { d.rows.map( ( r, rIndex ) => 
                                                    <tr key={ rIndex }>
                                                        { d.columns.map( ( c, cIndex ) => { 
                                                            let cell = r[ c ];
                                                            if( d.replaceColumn == c ) 
                                                                cell = <input type="text" value={ r[ c ] || "" } onChange={ (e) => this.handleDataChange( d.key + "[" + rIndex + "]." + d.replaceColumn, e.target.value ) } />
                                                            return (
                                                                <td key={ cIndex }>{ cell }</td>
                                                            );
                                                        })}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                }

                            </div>
                        );
                    })}

                    { data &&
                        <div>
                            <hr />
                            <input type="text" value={ this.state.replaceAllTxt || '' } placeholder="Korvaa kaikki" onChange={ ( e ) => this.handleReplaceAll( e, data ) } />
                            <hr />
                        </div>
                    }

                    { data && 
                        <ApButton color="blue" onClick={ this.save } disabled={ !this.state.unsavedChanges || this.state.loading } loading={ this.state.loading }>
                            <SvgIcon icon="save" type="solid" />
                            Tallenna
                        </ApButton>
                    }

                    { this.state.error &&
                        <div className="apErrorMsg">{ this.state.error }</div>
                    }

                </div>

            </div>
        );
    }
}
