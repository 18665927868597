import React from 'react';
import autoBind from 'react-autobind';

import { keyExists, tr }  from 'services/Helpers/Helpers.js'

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';


import './RowEdit.css';

class RowEdit extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            data: {},
        }
        autoBind(this);
    }

    resetState()
    {
        this.setState({ data: this.props.data });

        // Auto focus to name field (also scrolls view up if nessessary)
        if( this.nameRef )
            this.nameRef.focus();
    }

    handleChange( key, value )
    {
        let data = { ...this.state.data };
        data[ key ] = value;
        this.setState({ data });
    }

    onWorkSelect( component )
    {
        let data = { ...this.state.data };

        if( component )
        {
            data.work_component = component;
            data.unit = component.unit;
        }
        else
        {
            data.work_component = null;
            data.unit = null;
        }

        this.setState({ data });
    }

    save()
    {
        this.props.onClose( this.state.data );
    }

    renderBody()
    {
        const isset = ( key ) => {
            if( !keyExists( this.state.data, key ) )
                return false;
            if( this.state.data[ key ] === null )
                return false;
            if( this.state.data[ key ].length === 0 )
                return false;
            return true;
        }

        return <div className="padding">
            <ApInput
                type="text"
                id="name"
                name="name"
                label={ tr('name') }
                value={ keyExists( this.state.data, "name", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'name', e.target.value ) } }
                validationState={ !isset( "name" ) ? "error" : "" }
                inputRef={ ref => this.nameRef = ref }
            />
            {this.props.instance === 'pc' && 
                <ApInput
                    type="number"
                    id="days"
                    name="days"
                    label={tr('days')}
                    value={keyExists(this.state.data, "days", true, '')}
                    onChange={(e) => { this.handleChange('days', e.target.value) }}
                    validationState={!isset("days") ? "error" : ""}
                    //inputRef={ref => this.nameRef = ref}
                />}
            <ApInput
                type="textarea"
                id="description"
                name="description"
                label={ tr('description') }
                value={ keyExists( this.state.data, "description", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'description', e.target.value ) } }
            />
        </div>
    }

    render()
    {
        let headerName = null;
        if( this.props.instance === 'identifiers' ) headerName = tr('identifier');
        if( this.props.instance === 'properties' ) headerName = tr('property');
        if( this.props.instance === 'calculatables' ) headerName = tr('meter');

        let saveBtnDisabled = false;
        if (!this.state.data.name) {
            saveBtnDisabled = true;
        }
        if (this.props.instance === 'pc' && !this.state.data.days) {
            saveBtnDisabled = true;
        }

        return (
            <div id="StorageSettingRowEdit">
            <ApModal
                show={ this.props.show }
                onOpen={ this.resetState }
                handleClose={ () => this.props.onClose( false) }
                //closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            { Boolean( this.props.data ) && this.props.data.id ? tr('edit') : tr('new') } { headerName && (headerName)}
                        </h4>
                    </div>
                }
                body={
                    <div id="StorageSettingsRowEditBody">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="padding" id="StorageSettingsRowEditFooter">
                        <ApButton className="cancel" onClick={ () => this.props.onClose( false ) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton className="save" color="green" onClick={this.save} disabled={ saveBtnDisabled }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('continue') }
                        </ApButton>
                    </div>
                }
            />
        </div>
        )

    }

}

export default RowEdit;
