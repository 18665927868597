/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import auth               from 'services/Authed/Authed.js';
import api                from 'services/Api/Api.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';

import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import { ApInput,
         ApInputStack,
         ApAddon }        from 'common/ApInput/ApInput.js';

import { getComponentStatusColor
       , getTypeIcon }  from 'modules/Storage/common/StorageHelpers.js';

import './NewComponent.css';

import { errorPopper, tr } from 'services/Helpers/Helpers.js';

import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';

import PriceInputs from 'modules/Storage/Components/ComponentEdit/PriceInputs/PriceInputs.js'
import CodeSelect from 'modules/Storage/Components/ComponentEdit/CodeSelect/CodeSelect.js'

import { debounce } from 'throttle-debounce';

class NewComponent extends React.Component
{
    //kommentti 2.4
    constructor(props)
    {
        super(props);
        this.state = {
            types: [],

            selectedType: null,
            name: '',
            description: '',
            unit: '',

            nameExists: false,

            codeTailExists: false,
            codeSkipped: true,
            codeTail: null,
            code: null,

            codeTailLength: null,
            codeLimit: null,
            codeLevelCount: null,
            codeSeparator: null,


            price: null,
            discount: null,
            discount_price: null,
            last_price: null,
            avg_price: null,
            price_sell: null,
            profitPercent: null,

            currencySign: auth.getCurrencySign(),
        }

        const nameExistsValidator = ( value ) => {
            return !this.state.nameExists;
        }

        const codeValidator = ( value ) => {
            if( this.state.codeSkipped ) return true;
            if( !this.state.codeLimit ) return false;
            if( this.state.codeLimit.is_free ) return true;

            if( !value ) return false;
            if( !value.ancestors ) return false;
            return ( ( value.ancestors.length + 1 ) === this.state.codeLevelCount );
        }

        const codeTailLengthValidator = ( value ) => {
            if( this.state.codeSkipped ) return true;
            if( !this.state.codeLimit ) return false;
            if( this.state.codeLimit.is_free ) return true;
            if( !this.state.codeLimit.need_length ) return true;
            if( typeof value === 'string')
                return ( value.length === this.state.codeTailLength );
            return false;
        }

        const codeTailSeparatorValidator = ( value ) => {
            if( !this.state.codeLimit ) return false;
            if( this.state.codeLimit.is_free ) return true;
            if( !this.state.codeLimit.need_separator ) return true;
            if( typeof value === 'string' )
                return !( value.includes( this.state.codeSeparator) );
            return false;
        }

        const codeTailExistsValidator = ( value ) => {
            return !this.state.codeTailExists;
        }

        this.validator = new ApValidate( this, {
            selectedType: { filter: 'required', state: 'error', text: tr('storage_component_type_not_selected') },
            name: [
                { filter: nameExistsValidator, state: 'error', text: tr('name_already_in_use') },
                { filter: 'required', state: 'error', text: tr('name_required') },
            ],
            code: { filter:  codeValidator, state: 'error', text: tr('code_not_selected') },
            codeTail: [
                { filter: 'required', text: tr('component_code_tail_not_specified') },
                { filter: codeTailSeparatorValidator, text: tr('component_code_tail_delimiter') },
                { filter: codeTailLengthValidator, text: tr('component_code_tail_length') },
                { filter: codeTailExistsValidator, text: tr('component_code_already_in_use') },
            ],
        });

        this.steps = [{
            name: tr('select_type'),
            render: this.renderStepType.bind( this ),
            invalid:  () => {
                return this.validator.getText( 'selectedType' );
            }
        },{
            name: tr('basics'),
            render: this.renderStepDetail.bind( this ),
            invalid:  () => {
                let nameText = this.validator.getText( 'name' );
                if( nameText ) return nameText;

                if( !this.state.codeSkipped )
                {
                    let codeText = this.validator.getText( 'code' );
                    if( codeText ) return codeText;

                    let codeTailText = this.validator.getText( 'codeTail' );
                    if( codeTailText ) return codeTailText;
                }
                return false;
            }
        },{
            name: tr('value_price'),
            render: this.renderStepValue.bind( this ),
        },{
            name: tr('confirm'),
            render: this.renderStepConfirm.bind( this ),
        }];

        this.checkNameExistsDebounced = debounce( 200, this.checkNameExists );
        autoBind(this);
    }

    resetState()
    {
        this.setState({

            selectedType: null,
            name: '',
            description: '',

            nameExists: false,

            price: null,
            price_sell: null,
            profitPercent: null,

            codeSkipped: true,
            codeTail: null,
            code: null,

        } );
    }

    UNSAFE_componentWillMount()
    {
        this.getRelated();
    }

    getRelated()
    {
        return api({
            method: 'get',
            url: 'storage/component/new/related',
        }).then( ( response ) => {
            this.setState({
                types: response.types,

                codeTailLength: response.code_tail_length,
                codeLimit: response.code_limit,
                codeLevelCount: response.code_level_count,
                codeSeparator: response.code_separator,
            });
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
        });
    }

    saveComponent()
    {
        const type = this.state.selectedType;
        let data = {
            name: this.state.name,
            unit: this.state.unit,
            type_id: type.id,
            description: this.state.description,
            profit_percent: this.state.profitPercent,

            price: this.state.price,
            discount: this.state.discount,
            last_price: this.state.last_price,
            avg_price: this.state.avg_price,
            pricesell: this.state.price_sell,
            profitPercent: this.state.profitPercent, 
             
        };

        if( type.name !== 'other' )
            data.price = this.state.price;

        if( !this.state.codeSkipped )
        {
            data.code_id = this.state.code ? this.state.code.id : null;
            data.code_tail = this.state.codeTail;
        }

        this.setState({ loading: true });

        return api({
            method: 'post',
            url: 'storage/component/new',
            data: data,
        }).then( ( response ) => {
            this.props.onSave( response );
            this.setState({ loading: false });
        }).catch((error) => {
            errorPopper(error, tr('save_error') );
            this.setState({ loading: false });
        });
    }

    checkNameExists( value )
    {
        return api({
            method: 'post',
            url: 'storage/component/name/exists',
            data: { name: value },
        }).then( ( response ) => {
            this.setState({ nameExists: response.exists });
        }).catch((error) => {
            errorPopper(error, tr('get_error') );
        });
    }

    nameChange( value )
    {
        this.checkNameExistsDebounced( value );
        this.setState({ name: value });
    }

    codeChange( code, tail )
    {
        this.setState({
            code: code,
            codeTail: tail,
        })
    }

    renderStepType()
    {
        const types = this.state.types;
        const selectedType = this.state.selectedType

        const descriptions = {
            item: tr('storage_component_item_desc'),
            work: tr('storage_component_work_desc'),
            other: tr('storage_component_other_desc'),
        };

        const typeDoms = types.map( type => {
            const icon = getTypeIcon( type.name );
            const optionClass = ['option'];
            if( selectedType && selectedType.id === type.id )
                optionClass.push( 'selected' );

            return <div key={ type.id } className={ optionClass.join(' ') } onClick={ () => { this.setState({ selectedType: type })}}>
                <div className="iconContainer">
                    <SvgIcon icon={ icon } type="solid" />
                </div>
                <div className="textContainer">
                    <div className="header">
                        { tr(type.text) }
                    </div>
                    <div className="text">
                        { descriptions[ type.name ] }
                    </div>
                </div>
            </div>
        });

        return <div className="stepType">
            { typeDoms }
        </div>
    }

    renderStepDetail()
    {
        let unitContainer = null;
        if( this.state.selectedType.name !== 'work' )
        {
            unitContainer = <div>
                <ApInput
                    type="text"
                    id="ncUnit"
                    name="unit"
                    label={ tr('unit') }
                    value={ this.state.unit }
                    onChange={ ( e ) => this.setState({ unit: e.target.value }) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    autoComplete="off"
                />

                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('storage_component_unit_note') }
                </div>
            </div>
        }

        return <div className="stepDetail">

            <ApInputStack gap="0">
                <ApAddon noRightBorder width="50px">
                    <SvgIcon icon={ getTypeIcon( this.state.selectedType.name ) } type="solid" />
                </ApAddon>
                <ApAddon>
                    { tr(this.state.selectedType.text) }
                </ApAddon>
            </ApInputStack>

            <ApInput
                type="text"
                id="ncName"
                name="name"
                label={ tr('name') }
                value={ this.state.name }
                onChange={  ( e ) => this.nameChange(e.target.value ) }
                validationState={ this.validator.getState( 'name' ) }
                tooltip={ this.validator.getText( 'name' ) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
                autoComplete="off"
            />

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('storage_component_name_note') }
            </div>

            <CodeSelect
                componentId={ this.props.id }
                skipped={ this.state.codeSkipped }
                tail={ this.state.codeTail }
                code={ this.state.code  }

                codeLimit={ this.state.codeLimit  }
                codeLevelCount={ this.state.codeLevelCount  }
                codeSeparator={ this.state.codeSeparator }

                onChange={ this.codeChange }

                onSkippedChange={ () => this.setState({ codeSkipped: !this.state.codeSkipped }) }
                afterTailCheck={ ( exists ) => { this.setState({ codeTailExists: exists }) } }

                validationState={ this.validator.getState( 'code' ) }
                tailValidationState={ this.validator.getState( 'codeTail' ) }
                validationText={ this.validator.getText( 'code' ) }
                tailValidationText={ this.validator.getText( 'codeTail' ) }

            />

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('storage_component_code_note1') }
            </div>

            { unitContainer }

            <ApInput
                label={ tr('free_text') }
                type="textarea"
                id="ncComment"
                name="description"
                value={ this.state.description }
                onChange={ ( e ) => this.setState({ description: e.target.value }) }
            />
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('storage_component_free_text_note') }
            </div>
        </div>
    }
    changeState(data, afterFunction) {
        this.setState(data, () => {
            if (typeof afterFunction === 'function')
                afterFunction();
        });
    }

    renderStepValue()
    {
        return <div className="stepValue">
            <PriceInputs
                new={true}
                typeName={ this.state.selectedType.name }
                price={ this.state.price }
                discount={ this.state.discount }
                discount_price = {(100 - this.state.discount) * this.state.price / 100}
                lastprice = {this.state.last_price ? this.state.last_price : ''}
                avgprice = {this.state.avg_price ? this.state.avg_price : ''}
                profitPercent={ this.state.profitPercent }
                price_sell={ this.state.price_sell }
                loading={ this.state.loading }
                onChange={ ( data ) => this.setState( data ) }
                changeState={(data, afterFunction) => this.changeState(data, afterFunction)}

            />
        </div>
    }

    renderStepConfirm()
    {
        return <div className="stepConfirm">
            <div className="apInfo">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('new_storage_component_plan_note') }
            </div>
            <div className="apInfo">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('new_storage_component_active_note') }
            </div>
        </div>
    }

    render()
    {
        return <div id="NewComponent">
            <ApModalWizard
                header={ tr('add_new_storage_component') }
                onOpen={ this.resetState }
                show={ this.props.show }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.saveComponent }
            />
        </div>
    }

}

export default NewComponent;
