import React from 'react';
import autoBind from 'react-autobind';
import { tr } from  'services/Helpers/Helpers.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import './DepthInput.css';

class DepthInput extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            open: false,
        }
        autoBind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.clickOutsideHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutsideHandler);
    }

    clickOutsideHandler(event) {
        if( !this.node.contains(event.target) )
            this.setState({ open: false });
    }

    setDepth( mod )
    {
        let depth = this.props.depth;
        if( mod === 'inc' ) depth++;
        if( mod === 'dec' && depth > 0 ) depth--;

        this.props.onChange( depth );
    }

    renderDialog()
    {
        let depth = this.props.depth;
        let dClass = ['depthInput'];
        const isGeneral = this.props.type === 'general';
        if( this.state.open ) dClass.push('open');

        let infoText = isGeneral ? tr('group_all_levels_separately') : tr('group_all_subprojects_separately');
        if( depth  === 1 )
            infoText = isGeneral ? tr('grouped_at_main_level') : tr('grouped_at_main_project_level');
        else if( depth > 1 )
            infoText = isGeneral ? tr('combine_levels_info', [depth]) : tr('combine_subprojects_info', [depth]);

        return <div className={ dClass.join( ' ' ) }>
            <div className="apInfo">
                <SvgIcon icon="info-circle" type="solid" />
                { infoText }
            </div>

            <ApButton color="white" size="small" onClick={ () => this.setDepth( 'dec' )}>
                <SvgIcon icon="minus" type="solid" />
            </ApButton>
            <ApButton color="white" size="small" onClick={ () => this.setDepth( 'inc' )}>
                <SvgIcon icon="plus" type="solid" />
            </ApButton>
        </div>
    }

    renderNumber()
    {
        if( this.props.depth )
            return <div className="buttonNumber">{ this.props.depth }</div>
        return <SvgIcon icon="infinity" type="solid" />
    }

    render()
    {
        return <div className="depthInputModal"  ref={node => this.node = node}>
            <ApTooltip block text={ tr('grouping_depth') } position="topright">
                <div className="apSimpleButton" onClick={ () => { this.setState({ open: !this.state.open }) } }>
                    {  this.renderNumber() }
                </div>
            </ApTooltip>
            { this.renderDialog() }
        </div>
    }
}

export default DepthInput;
