import React from 'react';
import autoBind from 'react-autobind';

import ApButton       from 'common/ApButton/ApButton.js';
import { ApInput }    from 'common/ApInput/ApInput.js';
import ReceiverDataSelect from './ReceiverDataSelect/ReceiverDataSelect.js';
import ApSelect        from 'common/ApSelect/ApSelect.js';
import { tr } from 'services/Helpers/Helpers.js';

import './DataEdit.css';

class DataEdit extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
        };

        autoBind(this);
    }

    handleChange(e)
    {
        this.props.onChange({[e.target.name]: e.target.value});
    }

    setSenderStorage( selected )
    {
        if( selected )
        {
            this.props.onChange({
                senderType:    'location',
                senderTitle:   selected.name,
                senderId:      selected.id,
            });
        }
        else
        {
            this.props.onChange({
                senderType:    null,
                senderTitle:   null,
                senderId:      null,
            });

        }
    }

    renderSender()
    {
        return <div>
            <h3 className="apFormHeader"> { tr('sender') } </h3>
            <ApSelect
                label={ `${tr('sending_storage')} *` }
                value={ this.props.senderTitle ? this.props.senderTitle : '' }
                optionRenderer="storage_location_address"
                onChange={ this.setSenderStorage }
                objKeyId="id"
                clearable
                apiUrl="storage/locations/find"
                apiData={{ 
                    canReceive: true,
                }}
                validationState={ this.props.validator.getState('senderId') }
            />
        </div>
    }

    handleReceiverTypeChange( e )
    {
        this.props.onChange( this.nullReceiver() );
        this.props.onChange({[e.target.name]: e.target.value});
    }

    renderReceiver()
    {
        return <div>
            <h3 className="apFormHeader">{ tr('recipient') }</h3>

            <ReceiverDataSelect
                validator={ this.props.validator }
                onChange={  this.props.onChange }

                receiverLocked={ this.props.receiverLocked }
                receiverTitle={ this.props.receiverTitle }
                receiverType={  this.props.receiverType }
                receiverId={    this.props.receiverId }

                receiverName={        this.props.receiverName }
                receiverAddress={     this.props.receiverAddress }
                receiverZipcode={     this.props.receiverZipcode }
                receiverCity={        this.props.receiverCity }
                receiverCountry={     this.props.receiverCountry }
                receiverContactName={ this.props.receiverContactName }
            />
        </div>
    }

    renderDetails()
    {
        return <div>
            <h3 className="apFormHeader">{ tr('other_info') }</h3>
            <ApInput
                type="text"
                id="number"
                name="number"
                label={ tr('number') }
                disabled={ true }
                value={ this.props.number }
                tooltip={ ( !this.props.numberReserved ) ? tr('next_available_number_purchase_order') : '' }
            />
            <ApInput
                type="text"
                id="referenceYour"
                name="referenceYour"
                label={ tr('reference_number_your' ) }
                autoComplete="off"
                value={ this.props.referenceYour }
                onChange={ this.handleChange }
            />
            <ApInput
                type="text"
                id="referenceOur"
                name="referenceOur"
                label={ tr('reference_number_our' ) }
                autoComplete="off"
                value={ this.props.referenceOur }
                onChange={ this.handleChange }
            />
            <ApInput
                type="textarea"
                id="comment"
                name="comment"
                label={ tr('comment') }
                autoComplete="off"
                value={ this.props.comment }
                onChange={ this.handleChange }
            />
        </div>
    }

    render()
    {
        let summaryButton = null;
        if( this.props.summary )
        {
            summaryButton = <div className="summary-button-container">
                <ApButton
                    size="xtiny"
                    color="white"
                    className="summary-button"
                    onClick={ this.props.onSummaryClick }
                >{ tr('summary') }</ApButton>
            </div>
        }

        let sender = this.renderSender();
        let receiver = this.renderReceiver();
        let details  = this.renderDetails();

        return <div id="po-data-edit">
            { summaryButton }
            <div className="apForm">
                <div className="apFormColumn">
                    { sender }
                    { receiver }
                </div>
                <div className="apFormColumn">
                    { details }
                </div>
            </div>
        </div>
    }
}

export default DataEdit;
