import React, {Component} from 'react';

import ApButton from 'common/ApButton/ApButton';
import { errorPopper, tr } from 'services/Helpers/Helpers';
import api from 'services/Api/Api';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';

const languages = {
    all: tr('show_all'),
    fi: tr('finnish'),
    en: tr('english'),
    de: tr('german'),
    et: tr('estonian'),
}

class Translations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initial: true,

            source: 'fi',
            target: 'fi',

            missingTranslations: {}
        }
    }

    checkMissingTranslations = () => {
        this.setState({missingTranslations: {}, loading: true, initial: false});
        api({
            method: 'post',
            url: 'management/translations/findMissing',
            data: {source: this.state.source, target: this.state.target}
        }).then((response) => {
            // console.log("res", response);
            this.setState({missingTranslations: response, loading: false});
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            console.log(error);
            this.setState({loading: false});
        });
    }

    handleLangChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    copyToClipboard() {
        const translationEls = document.querySelectorAll('.translation');
        const text = Array.from(translationEls).reduce((fullText, currElement) => {
            const text = currElement.textContent.split('=>');
            return fullText += `'${text[0].trim()}' => '${text[1].trim()}',\n`;
        }, '');

        navigator.clipboard.writeText(text);
        window.emitter.emit('popper', {
            type: 'success',
            content: <strong>{tr('copied')}</strong>,
            time: 2000
        });
    }

    render() {
        const sourceLanguages = Object.entries(languages)
        sourceLanguages.shift();
        return (
            <div id="translations">
                <div className="apBox">
                    <div className="apBoxHeader">
                        <h1>{tr('translations')}</h1>
                        <p>{tr('translations_subtitle')}</p>
                    </div>
                    <div className='padding'>
                        <ApInputStack gap='0'>
                            <ApAddon noRightMargin labelFor='source'>{tr('source')}</ApAddon>
                            <ApInput
                                type='select' 
                                id='source'
                                name='source'
                                value={this.state.source}
                                options={sourceLanguages.map((lang) => ({value: lang[0], label: lang[1]}))}
                                onChange={(e) => this.handleLangChange(e)}
                            />

                            <ApAddon noRightMargin>{tr('target')}</ApAddon>
                            <ApInput
                                type='select'
                                id='target'
                                name='target'
                                value={this.state.target}
                                options={Object.entries(languages).map((lang) => ({value: lang[0], label: lang[1]}))}
                                onChange={(e) => this.handleLangChange(e)}
                            />
                            <ApButton 
                                onClick={this.checkMissingTranslations}
                                color="green"
                                style={{display: 'flex', marginLeft: 'auto'}}
                            >
                                {tr('check')}
                            </ApButton>
                        </ApInputStack>
                        {Object.keys(this.state.missingTranslations).length > 0 && 
                        <div>
                            <div style={{float: 'right'}} className="copy">
                                <ApButton 
                                    onClick={this.copyToClipboard}
                                    // color="green"
                                >
                                    <SvgIcon icon='clipboard' type='solid' />
                                    {tr('copy')}
                                </ApButton>
                            </div>
                            {Object.entries(this.state.missingTranslations).map((translation, i) => (
                                <div key={i} className='translation'>
                                    <b>{translation[0]}</b> ={'>'} {translation[1]}
                                </div>
                            ))}
                        </div>}
                        {this.state.loading && tr('loading')}
                        {Object.keys(this.state.missingTranslations).length === 0 
                        && !this.state.initial 
                        && !this.state.loading
                        && <div>{tr('no_missing_translations')}</div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default Translations;
