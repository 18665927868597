/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import { formatMoney, keyExists, tr } from 'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInputStack, ApInput, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';


export default class ManualExtras extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            unpaid: [],
            paid: [],
            paidPages: -1,
            loadingPaid: false,

            modalShow: false,
            modalData: null,

            nextId: 0,
        };
        autoBind(this); 
    }

    componentDidMount()
    {
        this.getManualExtras();
    }

    getManualExtras()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'timetracking/salary_extras/manual',
        }).then(( response ) => {

            //console.log('manual extras', response );

            this.setState({
                loading: false,
                unpaid: response.filter( r => !r.payroll_batch_id )
            });
            
        }).catch( ( error ) => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    getPaidManualExtras = (state) => {
        const fetchPage = state.page;
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/salary_extras/manual/paid',
            data: {
                page: fetchPage,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        }).then(( response ) => {

            //console.log('manual extras', response );

            this.setState({
                loadingPaid: false,
                paid: response.data,
                paidPages: response.pages
            });
            
        }).catch( ( error ) => {
            console.error(error);
            this.setState({ loading: false });
        });
    };

    removeSelected( indexes )
    {
        let ids = [];
        indexes.forEach( i => ids.push( this.state.unpaid[ i ].id ) );

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/salary_extras/remove',
            data: { ids: ids },
            errorPopper: tr('delete_error'),
        }).then(( response ) => {
            //console.log('remove', response );
            this.getManualExtras();
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    copySelected( indexes )
    {
        let ids = [];
        indexes.forEach( i => ids.push( this.state.unpaid[ i ].id ) );
        this.copyIds( ids );
    }

    copyIds( ids )
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/salary_extras/copy',
            data: { ids: ids },
            errorPopper: "Kopioimisessa tapahtui virhe",
        }).then(( response ) => {
            //console.log('copy', response );
            this.getManualExtras();
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    editExtra( data )
    {
        this.setState({
            modalShow: true,
            modalData: data,
        });

        //console.log('edit', data );
    }

    addManualExtra()
    {
        const nextId = this.state.nextId + 1;
        this.setState({
            modalShow: true,
            modalData: {
                id:                 "new" + nextId,
                user_id:            null,
                date:               null,
                name:               "",
                salary_category:    "",
                amount:             1,
                unit_price:         null,
            },
            nextId: nextId
        });
    }

    onModalChange( e )
    {
        const name = e.target.name;
        let value = e.target.value;
        let modalData = { ...this.state.modalData };
  
        if( name in modalData )
            modalData[ name ] = value;

        this.setState({ modalData });
    }

    onUserChange( user )
    {
        let modalData = { ...this.state.modalData };
        modalData.user = user;
        modalData.user_id = user.id;
        this.setState({ modalData });
    }

    closeModal()
    {
        this.setState({ modalShow: false });
    }

    saveModal()
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/salary_extras/save',
            data: this.state.modalData,
            errorPopper: "Tallentamisessa tapahtui virhe",
        }).then(( response ) => {

            //console.log('save', response );
            this.getManualExtras();
            this.closeModal();
            
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });

    }

    validateModal()
    {
        // TODO
        return true;
    }

    getModalTotal()
    {
        if( !this.state.modalData )
            return null;
            
        const amount = this.state.modalData.amount || 0;
        const price = this.state.modalData.unit_price || 0;
        
        return formatMoney( amount * price );
    }

    render() 
    {
        // console.log(this.state.modalData)
        return (
            <div id="TimetrackingSalaryExtraManual">

                <h3>{ tr('one_time_supplements') }</h3>
                <p>{ tr('one_time_supplements_info') }</p>

                <ApButton color="green" onClick={ this.addManualExtra }>
                    <SvgIcon icon="plus" type="solid" />
                    { tr('new_one_time_supplement') }
                </ApButton>

                <ApReactTable 
                    loading={ this.state.loading }
                    data={ this.state.unpaid ? this.state.unpaid : [] } 
                    columns={[
                        {
                            Header: tr('one_time_supplement_waiting_payment'),
                            columns: [
                                { id: 'date', Header: tr('date'), accessor: 'date', Cell: (props) => {
                                    return <span>{ moment( props.original.date ).format('DD.MM.YYYY') }</span>;
                                }},
                                { id: 'user_id', Header: tr('recipient'), accessor: 'user_id', Cell: (props) => props.original.user.name },
                                { id: 'name', Header: tr('name'), accessor: 'name' },
                                { id: 'sum', Header: tr('sum'), accessor: ( row ) => ( row.amount * row.unit_price ), Cell: (props) => { 
                                    return <span>{ formatMoney( props.value ) } { auth.getCurrencySign() }</span>;
                                }},
                                { id: 'salary_category', Header: tr('salary_type'), accessor: 'salary_category' },
                                { id: 'creator_user_id', Header: tr('creator'), accessor: 'creator_user_id', Cell: (props) => props.original.creator.name },
                            ]
                        },      
                     ]}
                    filterable
                    multiselect={[
                        { icon: "copy", label: tr('copy_selected'), action: ( indexes ) => { this.copySelected( indexes ) }, unselectAfter: true, closeAfter: true },
                        { divider: true },
                        { icon: "trash-alt", label: tr('delete_selected'), action: ( indexes ) => { this.removeSelected( indexes ) }, unselectAfter: true, closeAfter: true },
                    ]}
                    minRows={ 5 }
                    rowActions={ (row) => 
                        <div className="apSimpleButton" onClick={ () => this.editExtra( row ) }><SvgIcon icon="edit" type="solid" /></div>
                    }
                />

                <br />

                <h3>{ tr('one_time_supplements_transferred_payroll') }</h3>
                <p>{ tr('one_time_supplements_transferred_payroll_info') }</p>
                
                <ApReactTable 
                    loading={ this.state.loadingPaid }
                    data={ this.state.paid ? this.state.paid : [] }
                    onFetchData={this.getPaidManualExtras}
                    pages={this.state.paidPages}
                    manual
                    columns={[
                        {
                            Header: tr('one_time_supplements_transferred_payroll'),
                            columns: [
                                { id: 'date', Header: tr('date'), accessor: 'date', Cell: (props) => {
                                    return <span>{ moment( props.value ).format('DD.MM.YYYY') }</span>;
                                }},
                                { id: 'user_id', Header: tr('recipient'), accessor: 'user_id', Cell: (props) => props.original.user.name },
                                { id: 'name', Header: tr('name'), accessor: 'name' },
                                { id: 'sum', Header: tr('sum'), accessor: ( row ) => ( row.amount * row.unit_price ), Cell: (props) => { 
                                    return ( 
                                        <span>
                                            { formatMoney( props.value ) } { auth.getCurrencySign() }<br />
                                            <small>{ tr('amount') }: { props.original.amount }, { tr('price') }: { formatMoney( props.original.unit_price ) } { auth.getCurrencySign() }</small>
                                        </span>
                                    );
                                }},
                                { id: 'batch_created', Header: tr('transferred'), accessor: 'payroll_batch.created_at', Cell: (props) => {
                                    return ( 
                                        <span>
                                            { moment( props.value ).format('DD.MM.YYYY') }<br />
                                            <small>{ props.original.payroll_batch.name }</small>
                                        </span>
                                    );
                                }},
                                { id: 'salary_category', Header: tr('salary_type'), accessor: 'salary_category' },
                                { id: 'creator_user_id', Header: tr('creator'), accessor: 'creator_user_id', Cell: (props) => props.original.creator.name },
                            ]
                        }
                    ]}
                    filterable
                    minRows={ 5 }
                    rowActions={[
                        {
                            icon: "copy",
                            label: `${tr('copy_to_new')}...`,
                            action: ( id ) => this.copyIds([ id ]),
                        },
                        {
                            icon: "file-code",
                            label: tr('payroll_batch'),
                            action: ( id ) => {
                                const batchId = this.state.paid.find( p => p.id === id ).payroll_batch_id;
                                this.props.history.push('/timetracking/payroll/batches/' + batchId );
                            }
                        },
                    ]}
                />



                <ApModal
                    className="narrow"
                    show={ this.state.modalShow }
                    handleClose={ this.closeModal }
                    closeFromBg
                    header={ 
                        <div className="padding">
                            <h3>{ this.state.modalData && isNaN(this.state.modalData.id) ? tr('new') : tr('edit') }</h3>
                        </div>
                    }
                    body={ 
                        <div className="padding">
                            
                            { keyExists( this.state.modalData, "creator_user_id" ) && keyExists( this.state.modalData, "creator_user_id", true ) !== auth.getID() && 
                                <div className="apWarningMsg">
                                    { tr('edit_supplement_note') }
                                </div>
                            }

                            <ApInputStack>
                                <ApInput 
                                    width="300"
                                    type="dateSafe" 
                                    id="date" 
                                    name="date" 
                                    label={ tr('date') }
                                    value={ keyExists( this.state.modalData, "date", true, '' ) }
                                    onChange={ this.onModalChange }
                                    validationState={ keyExists( this.state.modalData, "name", true, '' ) ? "" : "error" }
                                />
                                <ApAddon custom></ApAddon>
                            </ApInputStack>

                            <ApSelect
                                label={ tr('recipient') }
                                name="user"
                                value={ keyExists( this.state.modalData, "user.name", true, '' ) }
                                optionRenderer="user"
                                onChange={ this.onUserChange }
                                objKeyId="id"
                                objKeyValue="name"
                                apiUrl="timetracking/salary_extras/users"
                                validationState={ keyExists( this.state.modalData, "user.id" ) ? "" : "error" }
                                filterNonActives={true}
                            />

                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('one_time_supplement_payment_note') }
                            </div>

                            <ApInput 
                                type="text" 
                                id="name" 
                                name="name" 
                                label={ tr('name') }
                                value={ keyExists( this.state.modalData, "name", true, '' ) }
                                onChange={ this.onModalChange }
                                validationState={ keyExists( this.state.modalData, "name", true, '' ) ? "" : "error" }
                            />

                            <br />

                            <ApInput 
                                type="text" 
                                id="salary_category" 
                                name="salary_category" 
                                label={ tr('salary_type') }
                                value={ keyExists( this.state.modalData, "salary_category", true, '' ) }
                                onChange={ this.onModalChange }
                                validationState={ keyExists( this.state.modalData, "salary_category", true, '' ) ? "" : "error" }
                            />

                            <ApInputStack gap="0">
                                <ApAddon noRightBorder width="100">{ tr('amount') }</ApAddon>
                                <ApInput 
                                    width="80"
                                    type="number" 
                                    id="amount" 
                                    name="amount" 
                                    value={ keyExists( this.state.modalData, "amount", true, '' ) }
                                    onChange={ this.onModalChange }
                                    validationState={ keyExists( this.state.modalData, "amount", true, 0 ) != 0 ? "" : "error" }
                                />
                                <ApAddon noRightBorder gapLeft="10" width="100">{ tr('price') }</ApAddon>
                                <ApInput 
                                    width="120"
                                    type="number" 
                                    id="unit_price" 
                                    name="unit_price" 
                                    value={ keyExists( this.state.modalData, "unit_price", true, '' ) }
                                    onChange={ this.onModalChange }
                                    validationState={ keyExists( this.state.modalData, "unit_price", true, 0 ) != 0 ? "" : "error" }
                                />
                                <ApAddon gapLeft="10">= { this.getModalTotal() } { auth.getCurrencySign() }</ApAddon>
                            </ApInputStack>

                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('amount_note') }
                            </div>

                        </div>
                    }
                    footer={
                        <div className="padding">
                            
                            <ApButton onClick={ this.closeModal }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>
                                                            
                            <ApButton className={ "applyButton" + ( keyExists( this.state.modalData, "user.name", true, '' ) ? " highlight" : "" ) } color="blue" onClick={ this.saveModal } disabled={ !this.validateModal() }>
                                <SvgIcon icon="save" type="solid" />
                                { tr('save') }
                            </ApButton>

                        </div>
                    }
                />

            </div>
        );
    }
}

