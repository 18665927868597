let orderList = []
let currentPage = 0
let pageLock = false
let firstPageRefresh = false

export const getOrderList = () => {
    return orderList
}

export const setOrderList = (list) => {
    orderList = list
}

export const getOrderCurrentPage = () => {
    return currentPage
}

export const setOrderCurrentPage = (page) => {
    currentPage = page
}

export const orderCurrentPageIsLocked = () => {
    return pageLock
}

export const setOrderPageLock = (lock) => {
    pageLock = lock
    firstPageRefresh = true
}

export const getOrderFirstPageRefresh = () => {
    return firstPageRefresh
}

export const setOrderFirstPageRefresh = (value) => {
    firstPageRefresh = value
}