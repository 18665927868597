import React from 'react';
import autoBind from 'react-autobind';

// import ApTimeline from 'common/ApTimeline/ApTimeline.js';
// import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

class Payroll extends React.Component {

	constructor( props ) 
	{
		super(props);
        this.state = {
 
        };
        autoBind(this);
	}

    render() 
    {
        return (
            <div className="apBox">
            
                <div className="apBoxHeader">
            	   <h1>Palkanmaksu</h1>
                   <p>TODO...</p>
                </div>

            </div>
        );

    }
}
export default Payroll
