import React from 'react';
import autoBind from 'react-autobind';

import { keyExists
       , onlyNumber
       , tr }     from 'services/Helpers/Helpers.js';

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import { ApInput } from 'common/ApInput/ApInput.js';


import './VatEdit.css';

class VatEdit extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            data: null,
        }
        autoBind(this);
    }

    resetState()
    {
        this.setState({ data: this.props.data });


        // Auto focus to name field (also scrolls view up if nessessary)
        if( this.valueRef )
            this.valueRef.focus();
    }

    handleChange( key, value )
    {
        if( key === 'value' )
            value = onlyNumber( value );

        let data = { ...this.state.data };
        data[ key ] = value;
        this.setState({ data });
    }

    onWorkSelect( component )
    {
        let data = { ...this.state.data };

        if( component )
        {
            data.work_component = component;
            data.unit = component.unit;
        }
        else
        {
            data.work_component = null;
            data.unit = null;
        }

        this.setState({ data });
    }

    save()
    {
        this.props.onClose( this.state.data );
    }

    renderBody()
    {
        const isset = ( key ) => {
            if( !keyExists( this.state.data, key ) )
                return false;
            if( this.state.data[ key ] === null )
                return false;
            if( this.state.data[ key ].length === 0 )
                return false;
            return true;
        }

        //console.log( this.state.data, keyExists( this.state.data, "default", true, false ) );

        return <div className="padding">
            <ApInput
                type="text"
                id="value"
                name="value"
                label={ tr('percentage') }
                value={ keyExists( this.state.data, "value", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'value', e.target.value ) } }
                validationState={ !isset( "value" ) ? "error" : "" }
                inputRef={ ref => this.valueRef = ref }
            />

            <div className="apSwitchBlock">
                <label htmlFor="default-vat-switch" className="info">
                    { tr('default_vat') }
                </label>
                <ApSwitch
                    id="default-vat-switch"
                    on={ keyExists( this.state.data, "default", true, false ) }
                    onChange={ () => { this.handleChange( 'default', !this.state.data.default ) } }
                    disabled={ this.state.loading }
                />
            </div>

        </div>
    }

    render()
    {
        let headerName = null;
        if( this.props.instance === 'identifiers' ) headerName = tr('identifier');
        if( this.props.instance === 'properties' ) headerName = tr('property');
        if( this.props.instance === 'calculatables' ) headerName = tr('meter');

        return (
            <div id="StorageSettingVatEdit">
            <ApModal
                show={ this.props.show }
                onOpen={ this.resetState }
                handleClose={ () => this.props.onClose( false) }
                //closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            { Boolean( this.props.data ) && this.props.data.id ? tr('edit') : tr('new') } { headerName && (headerName)}
                        </h4>
                    </div>
                }
                body={
                    <div id="StorageSettingsVatEditBody">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="padding" id="StorageSettingsVatEditFooter">
                        <ApButton className="cancel" onClick={ () => this.props.onClose( false ) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ this.save } disabled={ this.state.loading }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('continue') }
                        </ApButton>
                    </div>
                }
            />
        </div>
        )

    }

}

export default VatEdit;
