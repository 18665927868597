import React from 'react';
import autoBind from 'react-autobind';
import api, { apiurl } from 'services/Api/Api.js';

import './AssignmentPage.css';

import ApButton from 'common/ApButton/ApButton.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { Collapse } from 'react-bootstrap';

import {
	keyExists,
	sqlToDateInput,
	dateInputToSql, getImageUrl, tr, currentLang, errorPopper, getWeekdays, getMonths, hasPermissions
} from 'services/Helpers/Helpers.js';

import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApFileUpload from 'common/ApFileUpload/ApFileUpload.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import auth from 'services/Authed/Authed.js';

import moment from 'moment';

import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';
import WorkgroupModal from 'modules/Workgroups/WorkgroupModal';
import { ApEditGroup } from 'common/ApChat/ApChat.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import _ from 'lodash';
import { connect } from 'react-redux';


const tabs = {
	0: 'upcoming',
	1: 'current',
	2: 'ended',
	3: 'all',
	4: 'waiting_approval',
	archived: 'archived',
};
class AssignmentPage extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			customerModal: {},
			messageModal: {},
			phoneModal: {},
			projectModal: {},
			addressModal: {},
			userModal: {},
			datesModal: {},
			loading: false,
			assignment: null,
			priorities: null,
			statuses: null,
			new_status: null,
			noChanged: true,
			comment: null,
			newFileIds: [],
			customer: null,
			customerWorkNumberModalShow: false,
			customer_work_number: null,
			project: null,
			timedSend: true,
			send_at: null,
			send_day: 1,
			send_number: null,
			sendHours: 8,
			sendMinutes: '00',
			message: null,
			times: {},
			components: [],
			groups: [],
			next_recurrence: null,
			projectWorks: [],
			projectWork:{},

			showImageModal: false,
			selectedImage: null,
			selectedImageName: "",

			assignmentsList: [],
			groupModalOpen: false,
			current_user: null,

			has_date_temp_changes: false,
			has_user_temp_changes: false,
			begin_at: null,
			end_at: null,
			temp_begin_at: null,
			temp_end_at: null,
			chatShow: false,
			showCopyAssignmentconfinm: false,
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount() {
		this.setState({ send_at: this.getNextDayOfWeek(this.state.send_day, this.state.sendHours, this.state.sendMinutes) });
		this.getAssignment();
		this.getStatuses();

	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.match || this.props.id) {
			const prevId = this.props.id ? prevProps.id : prevProps.match.params.id;
			const currId = this.props.id ? this.props.id : this.props.match.params.id;
			if (prevId != currId) {
				this.getAssignment();
			}

			if (prevState.has_date_temp_changes && !this.state.has_date_temp_changes) {
				this.setState({ temp_begin_at: null, temp_end_at: null });
			}
			if (prevState.has_user_temp_changes && !this.state.has_user_temp_changes) {
				this.setState({ temp_user: null });
			}
		}

	}

	getAssignment(setChanges=false) {
		if (!(this.props.match || this.props.id)) {
			return null;
		}
		this.setState({ loading: true });
		let id = this.props.id ? this.props.id : this.props.match.params.id;
		let tab = this.props.tab ? this.props.tab : this.props.match.params.tab;
		api({
			method: 'get',
			url: 'assignment/get/' + id + '/' + tab,
		}).then((response) => {
			if (response.assignment.team_id) {
				response.assignment.user = response.assignment.team;
			}
			if (response.assignment.temp_changes) {
				response.assignment.temp_user = response.assignment.temp_changes.user || response.assignment.temp_changes.team;
			}
			if (response.assignment.exclude_days && response.assignment.exclude_days.length > 0) {
				const exclude_days = response.assignment.exclude_days.map(day => {
					return {
						value: day,
						label: moment().locale(currentLang()).day(day).format('dddd')
					}
				});
				const orderedDays = this.handleExcludeDaysOrder(exclude_days);
				response.assignment.exclude_days = orderedDays;
			}
			const recur_reset_worker = response.assignment ? response.assignment.recur_reset_worker : false;
			this.setState({ assignment: response.assignment, assignmentsList: response.assignments_list, current_user: response.current_user, recur_reset_worker, projectWork: response.assignment.project_work, projectWorks: response.assignment?.project?.works });
			const projectComponents = this.project_componentsSet(response.project_components);
			const components = this.componentsSet(response.installations);
			this.setState({ components: [...projectComponents, ...components] });

			this.setState({ loading: false });
		}).catch((error) => {
			console.log(error);
			errorPopper(error, tr('get_error'));
		});


	}

	getHourOptions() {
		let hours = []
		for (let i = 8; i < 18; i++) {
			hours.push({
				id: i,
				label: i < 10 ? '0' + i : i
			})
		}
		return hours
	}

	getMinuteOptions() {
		let minutes = []
		for (let i = 0; i < 60; i = i + 15) {
			minutes.push({
				id: i,
				label: i < 10 ? '0' + i : i
			})
		}
		return minutes
	}

	getWeekdayOptions() {
		let weekdays = []
		for (let i = 1; i < 6; i++) {
			weekdays.push({
				id: i,
				value: i,
				label: moment().locale(currentLang()).day(i).format('dddd')
			})
		}
		return weekdays
	}


	formatWorkingHours(hours, minutes) {
		return `${hours}:${minutes}`;
	}

	getNextDayOfWeek(dayOfWeek, hour, minutes) {
		let time = moment().day(dayOfWeek);
		time.set("hour", hour).set("minute", minutes);
		if (time.isBefore()) {
			time.add(7, 'd');
		}
		return time;
	}

	handleChangeWeekday(e) {
		let data = this.state;
		data['send_day'] = e.target.value;
		data['send_at'] = this.getNextDayOfWeek(e.target.value, this.state.sendHours, this.state.sendMinutes);
		this.setState({ data });
	}

	handleChangeTime(e) {
		let data = this.state;
		data[e.target.name] = e.target.value;
		data['send_at'] = this.getNextDayOfWeek(this.state.send_day, this.state.sendHours, this.state.sendMinutes);
		this.setState({ data });
	}

	getStatuses() {

		api({
			method: 'get',
			url: 'assignment/getStatuses'
		}).then((response) => {
			this.setState({ statuses: response });
		}).catch((error) => {
			console.log(error);
		});

	}


	saveNewComment() {

		api({
			method: 'post',
			url: 'assignment/saveComment',
			data: {
				assignment_id: this.state.assignment.id,
				comment: this.state.comment
			}
		}).then((response) => {
			let comments = this.state.assignment.comments;
			this.setState({ comment: null });
			comments.unshift(response);
			this.setState({ comments: comments });

		}).catch((error) => {
			console.log(error);
		});

	}

	async saveChanges(setChanges=false) {
		let fileIds = [];
		if (this.state.newFileIds.length > 0) {
			fileIds = this.state.assignment.files.map(file => file.id);
			fileIds.push(...this.state.newFileIds);
		}
		const assignment = this.state.assignment;
		assignment.recur_reset_worker = this.state.recur_reset_worker;
		assignment.customer_work_number = this.state.customer_work_number;
		assignment.project_work_id = this.state.projectWork?.id ?? null;
		this.setState({ loading: true });
		return api({
			method: 'post',
			url: 'assignment/save',
			data: {
				assignment: assignment,
				newFileIds: fileIds,
				components: this.state.components,
			}
		}).then((response) => {
			if (response.team_id) {
				response.user = response.team;
			}
			if (this.props.modalMode) {
				this.props.changed(false);
			}
			this.setState({
				noChanged: true,
				assignment: response,
				newFileIds: {},
				newFiles: new Array,
				loading: false
			}, () => {
				this.getAssignment(setChanges);
			});
			if (setChanges && 'updateList' in this.props && typeof this.props.updateList === 'function') {
				this.props.updateList(assignment);
				
			}
		}).catch((error) => {
			console.error(error);
			errorPopper(error, tr('save_error'));
			this.setState({ loading: false });
		});

	}

	copyAssignment() {
		this.setState({ loading: true });
		api({
			method: 'post',
			url: 'assignment/copy',
			data: {
				assignment_id: this.state.assignment.id,
			}
		}).then((response) => {
			this.props.history.push(`/assignments/${response['id']}/0`)

		}).catch((error) => {
			console.error(error);
			errorPopper(error, tr('save_error'));
			this.setState({ loading: false });
		});

	}


	renderSwitchbox(value, label, labelSmall = null, onChangeMore = null, disabled = false) {
		let onChange = null;
		let toggleValue = () => this.setState({ [value]: !this.state[value] })
		if (typeof onChangeMore === 'function')
			onChange = () => { toggleValue(); onChangeMore(); };
		else
			onChange = () => { toggleValue(); };

		return <div className={"apFormGroup" + (this.state[value] ? " success" : "")}>
			<div className="apSwitchBlock small">
				<label htmlFor={`newProject_${value}`} className="info">
					{label}
					<small>{labelSmall}</small>
				</label>
				<ApSwitch
					id={`newProject_${value}`}
					on={this.state[value]}
					onChange={onChange}
					disabled={this.state.loading || disabled}
				/>
			</div>
		</div>
	}

	async markStatus(status) {
		if (!this.state.noChanged) {
			await this.saveChanges();
		}
		this.setState({ loading: true });
		return api({
			method: 'post',
			url: 'assignment/status',
			data: {
				assignment_id: this.state.assignment.id,
				status: status
			}
		}).then((response) => {
			let comments = this.state.assignment.comments;
			let assignment = this.state.assignment;
			comments.unshift(response);
			this.setState({ comments: comments });
			assignment['status_id'] = status;
			if (status == 3 && this.state.assignment.user_id == null) {
				let user = auth.getUser();
				assignment['user_id'] = user.id;
				assignment['user'] = user;
			}
			if (status == 2 && this.state.assignment.user_id != null) {
				assignment['user_id'] = null;
				assignment['user'] = null;
			}
			window.emitter.emit('popper', {
				type: 'success',
				content: tr('status_changed'),
			});
			this.setState({ assignment: assignment, loading: false });
			this.props.timerUpdate();

		}).catch((error) => {
			this.setState({ loading: false });
			console.error(error);
			this.setState({ loading: false });
			errorPopper(error, tr('save_error'));
		});

	}

	handleChange(e) {

		let data = this.state.assignment;
		data[e.target.name] = e.target.value;
		this.setState({ data });
		this.setChanged();

	}

	preset_customer(type, item) {
		let customer = tr('not_defined');
		if (this.state.assignment.customer != null) {
			customer = this.state.assignment.customer.name;
		}
		return <div className="pointer" onClick={() => { this.setState({ customerModal: { show: true } }) }}>
			{customer}

		</div>
	}

	preset_customer_work_number() {
		let value = tr('not_defined');
		if (this.state.assignment.customer_work_number !== null) {
			value = this.state.assignment.customer_work_number;
		}
		return <div className="pointer" onClick={() => { this.setState({ customerWorkNumberModalShow: true }) }}>
			{value}
		</div>
	}

	preset_phone(type, item) {
		let phone = tr('not_defined');
		if (this.state.assignment.phone !== null) {
			phone = this.state.assignment.phone;
		}
		return <div><div className="pointer" onClick={() => { this.setState({ phoneModal: { show: true } }) }}>
			{phone}
		</div><a className="pointer" onClick={() => this.setState({ messageModal: { show: true } })}>{tr('send_message')} <SvgIcon icon="envelope" type="solid" /></a>
		</div>
	}

	setCustomer(customer) {

		this.setState({ customer: customer });

	}

	setPhone(phone) {

		this.setState({ phone: phone });

	}


	customerModalOpen() {
		this.setState({
			customer: this.state.assignment.customer
		});
	}

	phoneModalOpen() {
		this.setState({
			phone: this.state.assignment.phone
		});
	}

	customerSave() {
		let data = this.state.assignment;
		data['customer'] = this.state.customer;


		if (this.state.customer != null) {
			data['customer_id'] = this.state.customer.id;
		}
		else {
			data['customer_id'] = null;
		}
		this.setState({ data });
		this.setChanged();
		this.setState({ customerModal: {} });
	}

	phoneSave() {
		let data = this.state.assignment;
		data['phone'] = this.state.phone;
		this.setState({ data });
		this.setChanged();
		this.setState({ phoneModal: {} });
	}


	renderCustomerModal() {
		return <ApModal
			closeFromBg
			show={Boolean(this.state.customerModal.show)}
			handleClose={() => this.setState({ customerModal: {} })}
			className="narrow overflow"

			onOpen={this.customerModalOpen}
			header={
				<div className="padding">
					{tr('edit_customer')}
				</div>
			}
			body={<div className="padding">
				<ApSelect
					label={tr('customer')}
					value={this.state.customer != null ? this.state.customer : null}
					optionRenderer={(item) => {
						return <div>
							<strong>{item.name}</strong><br />
							<small>{item.taxnumber}</small>
						</div>
					}}
					onChange={(c) => this.setCustomer(c ? c : null)}
					objKeyId="id"
					objKeyValue="name"
					clearable
					apiUrl="project/find/customer"
					loading={this.state.loading}
					disabled={this.state.loading}
				/>


			</div>}
			footer={<div className="padding text-right">

				<ApButton onClick={() => this.setState({ customerModal: {} })}>
					<SvgIcon icon="times" type="solid" />
					{tr('cancel')}
				</ApButton>

				<ApButton color="green" onClick={this.customerSave}>
					<SvgIcon icon="check" type="solid" />
					{tr('continue')}
				</ApButton>

			</div>}
		/>
	}

	renderPhoneModal() {
		return <ApModal
			closeFromBg
			show={Boolean(this.state.phoneModal.show)}
			handleClose={() => this.setState({ phoneModal: {} })}
			className="narrow overflow"

			onOpen={this.phoneModalOpen}
			header={
				<div className="padding">
					{tr('edit_phone')}
				</div>
			}
			body={<div className="padding">
				<ApInput
					type="text"
					id="phone"
					name="phone"
					label={tr('phone')}
					value={this.state.phone ? this.state.phone : ''}
					onChange={(e) => this.setState({ phone: e.target.value })}
				/>
			</div>}
			footer={<div className="padding text-right">

				<ApButton onClick={() => this.setState({ phoneModal: {} })}>
					<SvgIcon icon="times" type="solid" />
					{tr('cancel')}
				</ApButton>

				<ApButton color="green" onClick={this.phoneSave}>
					<SvgIcon icon="check" type="solid" />
					{tr('continue')}
				</ApButton>

			</div>}
		/>
	}

	renderCustomerWorkNumberModal() {
		return <ApModal
			closeFromBg
			show={Boolean(this.state.customerWorkNumberModalShow)}
			handleClose={() => this.setState({ customerWorkNumberModalShow: false })}
			className="narrow overflow"

			onOpen={() => this.setState({ customer_work_number: this.state.assignment.customer_work_number })}
			header={
				<div className="padding">
					{tr('customer_work_number')}
				</div>
			}
			body={<div className="padding">
				<ApInput
					type="text"
					id="customer_work_number"
					name="customer_work_number"
					label={tr('customer_work_number')}
					value={this.state.customer_work_number ? this.state.customer_work_number : ''}
					onChange={(e) => this.setState({ customer_work_number: e.target.value })}
				/>
			</div>}
			footer={<div className="padding text-right">

				<ApButton onClick={() => this.setState({ customerWorkNumberModalShow: false })}>
					<SvgIcon icon="times" type="solid" />
					{tr('cancel')}
				</ApButton>

				<ApButton color="green" onClick={this.customerWorkNumberSave}>
					<SvgIcon icon="check" type="solid" />
					{tr('continue')}
				</ApButton>

			</div>}
		/>
	}

	customerWorkNumberSave() {
		let data = this.state.assignment;
		data['customer_work_number'] = this.state.customer_work_number;
		this.setState({ data });
		this.setChanged();
		this.setState({ customerWorkNumberModalShow: false });
	}

	preset_dates(type, item) {
		let begin = tr('not_defined');
		let end = tr('not_defined');
		let hours = "";
		let deadline = "";
		if (this.state.assignment.work_lenght_sec > 0) {
			hours = this.state.assignment.work_lenght_sec / 3600;
			hours = hours + "h";
		}
		if (this.state.assignment.begin_at !== null) {
			begin = moment(this.state.assignment.begin_at).format("L HH:mm");
		}
		if (this.state.assignment.end_at !== null) {
			end = moment(this.state.assignment.end_at).format("L HH:mm");
		}
		if (this.state.assignment.has_date_temp_changes && this.state.assignment.temp_changes) {
			begin = this.state.assignment.temp_changes.begin_at ? moment(this.state.assignment.temp_changes.begin_at).format("L HH:mm") : tr('not_defined');
			end = this.state.assignment.temp_changes.end_at ? moment(this.state.assignment.temp_changes.end_at).format("L HH:mm") : tr('not_defined');
		}
		if (this.state.assignment.deadline == true) {
			deadline = ` ${tr('completion_deadline')}`;
		}
		return <div className="pointer" onClick={() => { this.setState({ datesModal: { show: true } }) }}>
			{tr('estimated_duration')}: {hours}<br />
			{tr('start_date')}: {begin}<br />
			{tr('end_date')}: {end} {deadline} <br />
			{keyExists(this.state.assignment, 'recurrence', true, false)
				&& <>{tr('recurrence')}: {tr(this.state.assignment.recurrence)} <br /> </>}
			{keyExists(this.state.assignment, 'recurrence', true, false) && keyExists(this.state.assignment, 'next_recurrence', true, false)
				&& <>{tr('next_recurrence')}: {moment(this.state.assignment.next_recurrence, 'YYYY-MM-DD').format('DD.MM.YYYY')} <br /> </>}
		</div>
	}
	handleDateChange(name, value, temp_change = false) {
		if (name === "begin_at") {
			if (temp_change) {
				this.setState({ temp_begin_at: value });
			} else {
				this.setState({ begin_at: value });
			}
		}
		if (name === "end_at") {
			if (temp_change) {
				this.setState({ temp_end_at: value });
			} else {
				this.setState({ end_at: value });
			}
		}
		if (name === "occurred_at") {
			value = dateInputToSql(value, true);
			this.setState({ occurred_at: value });
		}
		if (name === "send_at") {
			value = dateInputToSql(value, true);
			this.setState({ send_at: value });
		}
		if (name === "next_recurrence") {
			this.setState({ next_recurrence: value });
		}

	}

	handleChangeDates(e) {
		let data = this.state;
		data[e.target.name] = e.target.value;
		this.setState({ data });
	}

	datesModalOpen() {
		let hours = 0;
		if (this.state.assignment.work_lenght_sec > 0) {
			hours = this.state.assignment.work_lenght_sec / 3600;
		}
		this.setState({
			begin_at: this.state.assignment.begin_at,
			end_at: this.state.assignment.end_at,
			has_deadline: this.state.assignment.deadline,
			hours: hours,
			recurrence: this.state.assignment.recurrence,
			next_recurrence: this.state.assignment.next_recurrence,
			recurrence_period: this.state.assignment.recurrence_period,
			exclude_days: this.state.assignment.exclude_days || [],
			has_date_temp_changes: !!this.state.assignment.has_date_temp_changes,
			temp_begin_at: this.state.assignment.temp_changes && this.state.assignment.temp_changes.begin_at,
			temp_end_at: this.state.assignment.temp_changes && this.state.assignment.temp_changes.end_at,
		});
	}

	datesSave() {
		let data = this.state.assignment;
		if (this.state.hours > 0) {
			data['work_lenght_sec'] = 3600 * this.state.hours;
		} else {
			data['work_lenght_sec'] = null;
		}
		if (this.state.has_date_temp_changes) {
			data['temp_changes'] = data['temp_changes']
				? { ...data['temp_changes'], begin_at: this.state.temp_begin_at, end_at: this.state.temp_end_at }
				: { begin_at: this.state.temp_begin_at, end_at: this.state.temp_end_at };
		} else {
			data['begin_at'] = this.state.begin_at;
			data['end_at'] = this.state.end_at;
		}
		data['deadline'] = this.state.has_deadline;
		data['recurrence'] = this.state.recurrence;
		data['next_recurrence'] = this.state.next_recurrence;
		data['recurrence_period'] = this.state.recurrence_period;
		data['exclude_days'] = this.state.exclude_days;
		data['has_date_temp_changes'] = this.state.has_date_temp_changes;
		this.setState({ data });
		this.setChanged();
		this.setState({ datesModal: {} });
	}

	renderDatesModal() {
		const hasDeadlineDom = this.renderSwitchbox(
			'has_deadline',
			tr('completion_deadline'),
			tr('completion_deadline_info'),
		);
		const recurrenceOptions = [
			{ label: tr('no_recurrence'), value: '' },
			{ label: tr('daily'), value: 'daily' },
			{ label: tr('weekly'), value: 'weekly' },
			{ label: tr('monthly'), value: 'monthly' },
		];

		const daysOptions = getWeekdays();

		let recurrenceLabel = null;
		if (this.state.recurrence) {
			if (this.state.recurrence == 'weekly') {
				recurrenceLabel = tr('recur_weeks', [keyExists(this.state, 'recurrence_period', true, 1) || 1]);
			}
			else if (this.state.recurrence == 'monthly') {
				recurrenceLabel = tr('recur_months', [keyExists(this.state, 'recurrence_period', true, 1) || 1]);
			}
			else if (this.state.recurrence == 'daily') {
				recurrenceLabel = tr('recur_days', [keyExists(this.state, 'recurrence_period', true, 1) || 1]);
			}
		}

		const begin_at = this.state.has_date_temp_changes
			? this.state.temp_begin_at
			: this.state.begin_at;
		const end_at = this.state.has_date_temp_changes
			? this.state.temp_end_at
			: this.state.end_at;

		return <ApModal
			// closeFromBg
			show={Boolean(this.state.datesModal.show)}
			handleClose={() => this.setState({ datesModal: {} })}
			className="narrow"

			onOpen={this.datesModalOpen}
			header={
				<div className="padding">
					{tr('edit_schedules')}
				</div>
			}
			body={<div className="padding" style={{ overflow: 'auto' }}>
				<ApInputStack collapseAt={600}>
					<ApInput
						width="350px"
						type="datetimeV2"
						id="begin_at"
						name="begin_at"
						label={tr('start_date')}
						value={begin_at}
						onChange={(e) => this.handleDateChange('begin_at', e, this.state.has_date_temp_changes)}
						enableTime
					/>
					<ApInput
						type="number"
						id="hours"
						name="hours"
						label={`${tr('duration')} (h)`}
						value={this.state.hours}
						onChange={this.handleChangeDates}

						loading={this.state.loading}
						disabled={this.state.loading}
					/>
				</ApInputStack>
				<ApInputStack collapseAt={600}>
					<ApInput
						width="350px"
						type="datetimeV2"
						id="end_at"
						name="end_at"
						label={tr('end_date')}
						value={end_at}
						onChange={(e) => this.handleDateChange('end_at', e, this.state.has_date_temp_changes)}
						enableTime
					/>
					{hasDeadlineDom}
				</ApInputStack>
				{this.state.recurrence && this.renderSwitchbox(
					'has_date_temp_changes',
					tr('temp_date_change'),
					tr('temp_change_info'),
					() => {
						if (this.state.begin_at && !this.state.temp_begin_at) {
							this.setState({ temp_begin_at: this.state.begin_at });
						}
						if (this.state.end_at && !this.state.temp_end_at) {
							this.setState({ temp_end_at: this.state.end_at });
						}
					},
				)}
				<ApSelect
					label={tr('recurrence')}
					value={this.state.recurrence ? tr(this.state.recurrence) : tr('no_recurrence')}
					options={recurrenceOptions}
					optionRenderer='label'
					objKeyId="value"
					objKeySearchable="label"
					valueRenderer="label"
					onChange={item => this.setState({ recurrence: item.value }, () => {
						if (this.state.recurrence_period > 6) {
							this.setState({ recurrence_period: 6 });
						}
					})}
					clearable
					loading={this.state.loading}
					disabled={this.state.loading}
				/>
				{this.state.recurrence &&
					<>
						<ApInput
							label={recurrenceLabel}
							type='number'
							id='recurrence_period'
							name='recurrence_period'
							value={this.state.recurrence_period || 1}
							onChange={this.handleRecurrencePeriodChange}
						/>
						{this.renderSwitchbox(
							'recur_reset_worker',
							tr('recur_reset_worker'),
							tr('recur_reset_worker_info'),
						)}
						<ApInput
							width="350px"
							type="datetimeV2"
							id="next_recurrence"
							name="next_recurrence"
							label={tr('next_recurrence')}
							value={this.state.next_recurrence}
							onChange={(e) => this.handleDateChange('next_recurrence', e)}
						/>
						<div><small>{this.renderNextDate('begin')}</small></div>
						<div><small>{this.renderNextDate('end')}</small></div>
						{this.state.recurrence === "daily" &&
							<>
								<ApSelect
									multiselect
									clearable
									options={daysOptions}
									optionRenderer="label"
									objKeyId="value"
									objKeySearchable="label"
									valueRenderer="label"
									value={this.state.exclude_days || []}
									onChange={this.handleExcludeDaysChange}
									loading={this.state.loading}
									disabled={this.state.loading}
									label={tr('exclude_days')}
								/>
								<div className="apInfo small">
									<SvgIcon icon="info-circle" type="solid" />
									{tr('exclude_days_info')}
								</div>
							</>}
					</>}
			</div>}
			footer={<div className="padding text-right">

				<ApButton onClick={() => this.setState({ datesModal: {} })}>
					<SvgIcon icon="times" type="solid" />
					{tr('cancel')}
				</ApButton>

				<ApButton color="green" onClick={this.datesSave}>
					<SvgIcon icon="check" type="solid" />
					{tr('continue')}
				</ApButton>

			</div>}
		/>
	}

	renderNextDate(type) {
		const getAddDaysAmount = (type, duration, excludeWeekDayNums) => {
			let day = moment(this.state[type], 'YYYY-MM-DD HH:mm').add(duration);
			let addDays = 0;
			while (excludeWeekDayNums.includes(day.day())) {
				day.add(1, 'd');
				addDays++;
				if (addDays > 7) {
					break;
				}
			}
			return addDays;
		}

		const { recurrence, next_recurrence, recurrence_period, begin_at, end_at, exclude_days } = this.state;
		if (recurrence && next_recurrence) {
			const duration = {};
			if (recurrence == 'weekly') {
				duration['weeks'] = recurrence_period || 1;
			} else if (recurrence == 'monthly') {
				duration['months'] = recurrence_period || 1;
			} else if (recurrence == 'daily') {
				duration['days'] = recurrence_period || 1;
			}
			let nextDate = null;
			if (type == 'begin' && begin_at) {
				nextDate = moment(begin_at, 'YYYY-MM-DD HH:mm').add(duration);
			} else if (type == 'end' && end_at) {
				nextDate = moment(end_at, 'YYYY-MM-DD HH:mm').add(duration);
			}

			const excludeWeekDays = exclude_days && exclude_days.map(day => day.value);
			let addDays = 0;
			if (nextDate && excludeWeekDays && excludeWeekDays.length > 0) {
				if (begin_at) {
					addDays = getAddDaysAmount('begin_at', duration, excludeWeekDays);
				} else if (end_at) {
					addDays = getAddDaysAmount('end_at', duration, excludeWeekDays);
				}
				nextDate.add(addDays, 'd');
			}

			if (nextDate) {
				const text = type == 'begin' ? tr('next_start_date') : tr('next_end_date');
				return `${text}: ${nextDate.format('DD.MM.YYYY HH:mm')}`;
			}
		}
		return null;
	}

	handleExcludeDaysChange(days) {
		if (days.length >= 7) {
			errorPopper(null, tr('too_many_days_selected'));
		} else {
			const orderedDays = this.handleExcludeDaysOrder(days);
			this.setState({ exclude_days: orderedDays });
		}
	}

	handleExcludeDaysOrder(days) {
		let sunday = null
		const sundayIndex = days.findIndex(day => day.value === 0);
		if (sundayIndex > -1) {
			sunday = days.splice(sundayIndex, 1)[0];
		}
		const orderedDays = _.orderBy(days, ['value'], ['asc']);
		if (sunday) {
			orderedDays.push(sunday);
		}
		return orderedDays;
	}

	handleRecurrencePeriodChange(e) {
		let { value } = e.target;
		if (value < 1) {
			value = 1;
		}
		let duration = null;

		let recurrence_period = this.state.recurrence_period;
		const recurrence = this.state.recurrence;

		if (recurrence === 'daily' && value > 6) {
			value = 6;
		}

		recurrence_period = Math.round(value);
		if (recurrence == 'weekly') {
			duration = {
				'weeks': value
			}
		}
		else if (recurrence == 'monthly') {
			duration = {
				'months': value
			}
		}
		else if (recurrence == 'daily') {
			duration = {
				'days': value
			}
		}
		const next_recurrence = moment().add(duration).format('YYYY-MM-DD');
		this.setState({ next_recurrence, recurrence_period });
	}



	preset_user(type, item) {
		let user = tr('not_defined');
		const key = this.state.assignment.has_user_temp_changes ? 'temp_user' : 'user';
		if (this.state.assignment[key] !== null) {
			if (this.state.assignment[key].team_members) {
				const members = this.state.assignment[key].team_members.reduce((membersList, member) => {
					if (member.user.is_active && !member.user.deleted_at) {
						const newMember = (
							<div key={member.user_id}>
								{`${member.user.person_detail.last_name} ${member.user.person_detail.first_name}`}
								{this.state.assignment.teamMemberPortionDone && this.state.assignment.teamMemberPortionDone.includes(member.user_id) &&
									<SvgIcon icon="check" type="solid" className="size-small" fill='#007E33' />}
							</div>
						);
						return [...membersList, newMember]
					} else {
						return membersList;
					}
				}, []);
				const filteredMembers = this.state.assignment[key].team_members.filter(member => member.user.is_active && !member.user.deleted_at);
				const allTeamMembersDone = filteredMembers.every(member => this.state.assignment.teamMemberPortionDone && this.state.assignment.teamMemberPortionDone.includes(member.user_id));
				user = (
					<React.Fragment>
						<ApTooltip text={members}>{this.state.assignment[key].name}
							<SvgIcon icon="user-friends" type="solid" />
						</ApTooltip>
						{allTeamMembersDone && <SvgIcon icon="check" type="solid" fill='#007E33' />}
					</React.Fragment>
				)
			} else {
				user = this.state.assignment[key].name;
			}
		}
		return <div className="pointer" onClick={() => { this.setState({ userModal: { show: true } }) }}>
			{user}
		</div>
	}

	setUser(user, temp_change = false) {

		if (temp_change) {
			this.setState({ temp_user: user });
		} else {
			this.setState({ user: user });
		}

	}

	userModalOpen() {
		this.setState({
			user: this.state.assignment.user,
			temp_user: this.state.assignment.temp_user,
			has_user_temp_changes: !!this.state.assignment.has_user_temp_changes,
		});
	}

	userSave() {
		let data = this.state.assignment;
		data.has_user_temp_changes = this.state.has_user_temp_changes;

		if (!this.state.has_user_temp_changes) {
			data['user'] = this.state.user;
			if (data.temp_changes) {
				data.temp_changes['user_id'] = null;
				data.temp_changes['team_id'] = null;
			}
			if (this.state.user != null) {
				if (data.user.is_group || data.user.team_members) {
					data['user_id'] = null;
					data['team_id'] = this.state.user.id;
				} else {
					data['team_id'] = null;
					data['user_id'] = this.state.user.id;
				}
			}
			else {
				data['user_id'] = null;
				data['team_id'] = null;
			}
		} else {
			data['temp_user'] = this.state.temp_user;
			if (this.state.has_user_temp_changes && this.state.temp_user) {
				if (this.state.temp_user.is_group || this.state.temp_user.team_members) {
					data.temp_changes = data.temp_changes
						? { ...data.temp_changes, team_id: this.state.temp_user.id, user_id: null }
						: { team_id: this.state.temp_user.id, user_id: null };
				} else {
					data.temp_changes = data.temp_changes
						? { ...data.temp_changes, user_id: this.state.temp_user.id, team_id: null }
						: { user_id: this.state.temp_user.id, team_id: null };
				}
			} else {
				data.temp_changes = data.temp_changes
					? { ...data.temp_changes, user_id: null, team_id: null }
					: { user_id: null, team_id: null };
				data.has_user_temp_changes = false;
			}
		}

		this.setState({ data });
		this.setChanged();
		this.setState({ userModal: {} });
	}

	renderUserModal() {

		return <ApModal
			closeFromBg
			show={Boolean(this.state.userModal.show)}
			handleClose={() => this.setState({ userModal: {} })}
			className="narrow overflow"

			onOpen={this.userModalOpen}
			header={
				<div className="padding">
					{tr('edit_assignment_worker')}
				</div>
			}
			body={<div className="padding">
				<ApSelect
					label={tr('assignment_worker')}
					value={this.state.has_user_temp_changes ? this.state.temp_user : this.state.user}
					optionRenderer="user"
					onChange={(c) => this.setUser(c ? c : null, this.state.has_user_temp_changes)}
					objKeyId="id"
					objKeyValue="name"
					clearable
					onlyActive
					apiUrl="assignment/find/worker"
					apiData={{ withGroups: true }}
					loading={this.state.loading}
					disabled={this.state.loading}
					filterNonActives={true}
				/>
				{this.state.assignment && this.state.assignment.recurrence && this.renderSwitchbox(
					'has_user_temp_changes',
					tr('temp_user_change'),
					tr('temp_change_info'),
					() => {
						if (this.state.user && !this.state.temp_user) {
							this.setState({ temp_user: this.state.user });
						}
					},
				)}
				{!this.props.modalMode &&
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<ApButton onClick={() => this.setState({ groupModalOpen: true, userModal: {} })} color='green'>{tr('new_workgroup')}</ApButton>
					</div>
				}

			</div>}
			footer={<div className="padding text-right">

				<ApButton onClick={() => this.setState({ userModal: {} })}>
					<SvgIcon icon="times" type="solid" />
					{tr('cancel')}
				</ApButton>

				<ApButton color="green" onClick={this.userSave}>
					<SvgIcon icon="check" type="solid" />
					{tr('continue')}
				</ApButton>

			</div>}
		/>
	}

	handleNewGroup(newGroup) {
		this.setState({
			assignment: {
				...this.state.assignment,
				team: newGroup[0],
				team_id: newGroup[0].id,
				user: newGroup[0],
				user_id: null,
			},

		});
		this.setChanged();
	}

	closeGroupModal() {
		this.setState({ groupModalOpen: false });
	}

	renderWorkgroupModal() {
		if (this.props.modalMode) {
			return null;
		}
		return (
			<WorkgroupModal
				show={this.state.groupModalOpen}
				closeModal={this.closeGroupModal}
				onSave={this.handleNewGroup}
			/>
		);
	}
	renderChat() {
		if (!this.state.assignment) {
			return null;
		}
		return (
			<ApEditGroup
				show={this.state.chatShow}
				//users={[]}

				assignmentId={this.state.assignment.id}
				overRideEditPermission={true}
				onClose={() => this.setState({ chatShow: false })}
				edited={this.chatEdited}
				editMode={true}
			/>
		);
	}
	chatEdited(data) {
		if (this.state.assignment) {
			let assignment = { ...this.state.assignment };
			assignment.chatUserCount = data.users.length;
			this.setState({ assignment: assignment });
		}
	}

	downloadFile(file, name, type) {
		this.setState({ loading: true });
		return api({
			method: 'get',
			url: `assignment/id/${this.state.assignment.id}/file/${file}/${type}`,
			responseType: 'blob',
			errorPopper: tr('file_download_error'),
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a');
			link.href = url;
			const assignmentName = this.state.assignment.name.replace(' ', '_');
            link.setAttribute('download', `${ name }_${ assignmentName }.${ type }` );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }
    
    preset_project(type, item )
    {
    	let project = tr('not_defined');
    	let projectCode="";
		let projectWorksWarning = null;
		let projectWork = tr('project_work')+": ";
    	if ( this.state.assignment.project!==null ) {
    		project=this.state.assignment.project.name;
    		projectCode=this.state.assignment.project.project_code;
			const worksCount = parseInt(this.state.assignment.project.works_count);
			if (!worksCount) {
				projectWorksWarning = <div className="apInfo small" style={{color:'var(--clr-warning-main)'}}>
					<SvgIcon icon="exclamation-triangle" type="solid" />
					{tr('project_no_works_added')}
				</div>
			}
		}
		if (this.state.projectWork != null) {
			projectWork += this.state.projectWork.name;
		}
		else {
			projectWork += "-";
		}
        return <div className="pointer" onClick={() => { this.setState({ projectModal: { show: true } })}}>
            {project}<br />
			{projectCode}<br />
			{projectWork}
			{projectWorksWarning}
        </div>
    }
    
    setProject( project )
    {
		this.setState({ project: project, projectWork: null, projectWorks: project?.works});
        if (project!==null) {
	        if (project.root_customer!==null) {
	        	this.setCustomer( project.root_customer );
	        }
        }
		
	}
	projectWorkChange(projectWork) {
		this.setState({
			projectWork: projectWork,
		});

	}
    
    projectModalOpen()
    {
        this.setState({
        	project: this.state.assignment.project,
        	customer: this.state.assignment.customer
        });
        
    }
    
    projectSave()
    {
		let data = this.state.assignment;
		data['project'] = this.state.project;

		if (this.state.project != null) {
			data['project_id'] = this.state.project.id;
		}
		else {
			data['project_id'] = null;
		}
		this.setState({ data });
		this.setChanged();
		this.setState({ projectModal: {} });

		if (this.state.project !== null) {
			if (this.state.project.customer !== null) {
				this.setCustomer(this.state.project.customer);
				this.customerSave();
			}
		}
	}

	renderProjectModal() {
		return <ApModal
			closeFromBg
			show={Boolean(this.state.projectModal.show)}
			handleClose={() => this.setState({ projectModal: {} })}
			className="narrow overflow"

            onOpen={ this.projectModalOpen }
            header={
                <div className="padding">
                    { tr('edit_project') }
                </div>
            }
            body={ <div className="padding">
                <ApSelect
                    label={ tr('project') }
		    		value={ keyExists( this.state.project, "name", true, '' ) }
	        		optionRenderer="project"
                    onChange={ ( c ) => this.setProject( c ? c : null) }
                    objKeyId="id"
                    objKeyValue="name"
                    clearable
                    apiUrl="report/projects/find"
			            apiData={{
			                formatter: 'select',
							customer: this.state.customer?this.state.customer.id:null,
							withOffers: true,
			                withoutClosed: true,
							withWorksCount: true,
							withWorks: true,
			            }}
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
				/>
				<ApSelect
					label={tr('work')}
					loading={this.state.loading}
					value={keyExists(this.state.projectWork, "name", true, '')}
					options={this.state.projectWorks}
					optionRenderer="work"
					onChange={(c) => this.projectWorkChange(c ? c : null)}
					objKeyId="id"
					objKeySearchable="name"
					objKeyValue="name"
					clearable

				/>


			</div>}
			footer={<div className="padding text-right">

				<ApButton onClick={() => this.setState({ projectModal: {} })}>
					<SvgIcon icon="times" type="solid" />
					{tr('cancel')}
				</ApButton>

				<ApButton color="green" onClick={this.projectSave}>
					<SvgIcon icon="check" type="solid" />
					{tr('continue')}
				</ApButton>

			</div>}
		/>
	}

	preset_address(type, item) {
		let address_street = tr('not_defined');
		if (this.state.assignment.address_street !== null) {
			address_street = this.state.assignment.address_street;
		}
		return <div className="pointer" onClick={() => { this.setState({ addressModal: { show: true } }) }}>
			{address_street}<br />{this.state.assignment.address_postal_code} {this.state.assignment.address_city} {this.state.assignment.address_country}
		</div>
	}
	preset_chat(type, item) {

		return <div className="pointer" onClick={() => { this.setState({ chatShow: true }) }}>
			{this.state.assignment.chatUserCount > 0 ? tr("users") + ": " + this.state.assignment.chatUserCount : tr("add_chat")}<br />
		</div>
	}
	handleChangeAddress(e) {
		let data = this.state;
		data[e.target.name] = e.target.value;
		this.setState({ data });
	}

	addressModalOpen() {
		this.setState({
			address_city: this.state.assignment.address_city,
			address_country: this.state.assignment.address_country,
			address_postal_code: this.state.assignment.address_postal_code,
			address_street: this.state.assignment.address_street,
		});
	}

	addressSave() {
		let data = this.state.assignment;
		data['address_city'] = this.state.address_city;
		data['address_country'] = this.state.address_country;
		data['address_postal_code'] = this.state.address_postal_code;
		data['address_street'] = this.state.address_street;
		this.setState({ data });
		this.setChanged();
		this.setState({ addressModal: {} });
	}

	renderAddressModal() {
		return <ApModal
			closeFromBg
			show={Boolean(this.state.addressModal.show)}
			handleClose={() => this.setState({ addressModal: {} })}
			className="narrow overflow"

			onOpen={this.addressModalOpen}
			header={
				<div className="padding">
					{tr('edit_address')}
				</div>
			}
			body={<div className="padding">
				<ApInput
					type="text"
					id="address_street"
					name="street"
					label={tr('street_address')}
					value={this.state.address_street ? this.state.address_street : ''}
					onChange={(e) => this.setState({ address_street: e.target.value })}
				/>

				<ApInputStack>
					<ApInput
						width="140px"
						type="text"
						id="address_postal_code"
						name="postal_code"
						label={tr('postal_code')}
						value={this.state.address_postal_code ? this.state.address_postal_code : ''}
						onChange={(e) => this.setState({ address_postal_code: e.target.value })}
					/>
					<ApInput
						type="text"
						id="address_city"
						name="city"
						label={tr('post_office')}
						value={this.state.address_city ? this.state.address_city : ''}
						onChange={(e) => this.setState({ address_city: e.target.value })}
					/>
					<ApInput
						type="text"
						id="address_country"
						name="country"
						label={tr('country')}
						value={this.state.address_country ? this.state.address_country : ''}
						onChange={(e) => this.setState({ address_country: e.target.value })}
					/>
				</ApInputStack>


			</div>}
			footer={<div className="padding text-right">

				<ApButton onClick={() => this.setState({ addressModal: {} })}>
					<SvgIcon icon="times" type="solid" />
					{tr('cancel')}
				</ApButton>

				<ApButton color="green" onClick={this.addressSave}>
					<SvgIcon icon="check" type="solid" />
					{tr('continue')}
				</ApButton>

			</div>}
		/>
	}


	filesChange(files, userAction) {
		let newFileIds = files.map(f => f.id)
		this.setState({ newFileIds: newFileIds });
		this.setChanged();
	}

	componentsChange(components, func) {

		let groupIds = components.map(c => c.order_group_id);
		let groups = this.state.groups.slice(0);
		groups = groups.filter(g => groupIds.includes(g.id));
		this.setChanged();
		this.setState({
			components: components,
			groups: groups,
		}, func);
	}

	sortComponents(components, project = false) {
		if (!components) return [];

		if (project) {
			return components.sort((a, b) => a.name && typeof a.name === 'string'
				? a.name.localeCompare(b.name)
				: 1
			);
		}

		return components.sort((a, b) => a.component && a.component.name && typeof a.component.name === 'string'
			? a.component.name.localeCompare(b.component.name)
			: 1
		);
	}

	componentsSet(components) {
		let installed = [];
		components = this.sortComponents(components);
		components.map(f => {
			f.component.type_name = "item";
			f.component.type_text = 'component';
			f.component.count = f.quantity;
			f.component.balance = f.balance;
			f.component.unit = f.component?.component?.unit;
			f.component.from_project = false;
			f.component.in_billing = f.billing_entry_id != null;
			f.component.installation_entry_id = f.id;
			f.component.identifiers = (f.component && f.component.component) ? f.component.component.identifiers : [];
			installed.push(f.component)
		});
		return installed;
	}

	project_componentsSet(components) {
		components = this.sortComponents(components, true);
		let installed = components.map(f => {
			f.count = null;
			f.from_project = true;
			return f;
		});
		return installed;
	}

	sendMessage() {
		this.setState({ loading: true });
		api({
			method: 'post',
			url: 'assignment/message/send',
			data: {
				sendSMS: this.state.sendSMS,
				sendEmail: this.state.sendEmail,
				send_email: this.state.send_email,
				send_number: this.state.send_number,
				timed_send: this.state.timedSend,
				send_message: this.state.message,
				send_date: moment(this.state.send_at).format('YYYY-MM-DD HH:mm:ss'),
				assignment_id: this.state.assignment.id,
				language: currentLang()
			}
		}).then((response) => {
			if (response) {
				let comments = this.state.assignment.comments;
				response.forEach(function (comment) {
					comments.push(comment);
				});

				this.setState({ comments: comments, messageModal: {} });
				this.setState({ loading: false });
			}
		}).catch((error) => {
			this.setState({ loading: false });
			console.log(error);
		});
	}

	renderComments() {
		if (this.state.assignment.comments !== null)

			return (

				this.state.assignment.comments.map((comm, index) => (
					<div key={comm.id} style={{ margin: 10, borderBottom: '1px solid #ccc' }}>
						<div className="user" style={{ float: 'left', marginRight: 10 }}>
							<ApTooltip position="right" text={comm.user.person_detail.first_name + " " + comm.user.person_detail.last_name}>
								<div className="image" style={{ backgroundImage: 'url(' + (comm.user.person_detail.image_file_id ? getImageUrl(comm.user.person_detail.image_file_id) : '/img/user_default.jpg') + ')' }}></div>
							</ApTooltip>
						</div>
						{comm.status_id == 1
							? comm.comment
							: tr('status_change') + (Object.keys(this.state.statuses || {}).length > 0
								? ": " + tr(this.state.statuses[comm.status_id])
								: '')
						}
						<br />
						{moment(comm.updated_at).format('L HH:mm')}
					</div>
				))
			)

	}
	renderFiles() {
		const addButton = <ApFileUpload
			ref={ref => this.fileUploadRef = ref}
			files={this.state.newFiles}
			onChange={this.filesChange}
			description={"File for assignment " + this.props.id}
			maxSize={1024 * 1024 * 20} // 20 Mt
			disabled={this.state.loading}
			noFilesLabel={tr('add_files')}
			noFilesSubLabel={tr('add_assignment_files')}
			addButtonLabel={`${tr('add_file')}...`}
		/>

		const listDoms = this.state.assignment.files.map((i, index) => {
			return <div key={i.id} className="files listStack">
				<ApInputStack gap="0">
					<ApAddon>
						<div
							onClick={() => {
								this.setState({
									selectedImage: i.id,
									showImageModal: true,
									selectedImageName: i.original_name,
									selectedImageExtension: i.extension,
								})
							}}
							className="pointer"
						>
							<SvgIcon
								className="showImageBtn pointer"
								icon="file"
								type="solid"
							/>
							{i.original_name}
						</div>
					</ApAddon>
					<ApAddon noLeftBorder width="50px">
						<ApTooltip block text={tr('delete')}>
							<SvgIcon
								className="removeFileBtn pointer"
								icon="trash"
								type="solid"
								onClick={() => { this.removeFile(i) }}
							/>
						</ApTooltip>
					</ApAddon>
				</ApInputStack>
			</div>
		});
		return <div>
			<h4 className="formHeader">
				<SvgIcon icon="file" type="solid" /> {tr('files')}
			</h4>

			{listDoms}
			{addButton}
		</div>
	}

	getFile(id, name) {
		return api({
			method: 'get',
			url: `${apiurl}file/id/${id}`,
			responseType: 'blob',
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', name);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			this.setState({ loading: false, showImageModal: false });
		}).catch((error) => {
			errorPopper(error, tr('file_download_error'));
			this.setState({ loading: false, showImageModal: false });
		});

	}

	removeFile(file) {
		let assignment = Object.assign({}, this.state.assignment);
		const index = assignment.files.findIndex(i => i.id === file.id);
		if (index !== -1) {
			const deletedFile = assignment.files[index];
			assignment.files = assignment.files.filter(i => i.id !== assignment.files[index].id)
			this.filesChange(assignment.files);
			this.setState({
				assignment,
			});
			this.setChanged();
		}
	}

	renderComment() {

		return <div>
			<ApInput
				type="textarea"
				id="comment"
				name="comment"
				label={tr('add_comment')}
				autoComplete="off"
				value={this.state.comment ? this.state.comment : ""}
				onChange={(e) => this.setState({ comment: e.target.value })}
			/>
			<div className="commentSave">
				<ApButton
					size="tiny"
					color="blue"
					disabled={this.state.comment === null || this.state.comment === ""}
					onClick={this.saveNewComment}
				>
					{tr('save')}
				</ApButton>
			</div>
		</div>;

	}

	renderMessageModal() {
		if (this.state.send_number == null && this.state.assignment != null) {
			if (this.state.assignment.phone !== null) {
				this.setState({ send_number: this.state.assignment.phone });
			}

		}
		if (this.state.send_number !== null && this.state.sendSMS == null) {
			this.setState({ sendSMS: true });
		}
		if (this.state.assignment != null) {
			if (this.state.assignment.customer != null)
				if (this.state.assignment.customer.email != null && this.state.send_email == null) {
					this.setState({ send_email: this.state.assignment.customer.email });
				}

		}
		const hourOptions = this.getHourOptions()
		const minuteOptions = this.getMinuteOptions()
		const weekdayOptions = this.getWeekdayOptions()

		const sendSMS = this.renderSwitchbox(
			'sendSMS',
			tr('send_sms'),
			tr('send_sms_info'),
		);
		const sendEmail = this.renderSwitchbox(
			'sendEmail',
			tr('send_email'),
			tr('send_email_info'),
		);
		const timedSend = this.renderSwitchbox(
			'timedSend',
			tr('send_immediately'),
			tr('send_immediately_info'),
		);
		const sendDate = <Collapse in={!this.state.timedSend}><div>
			<h4><strong>{tr('sms_sending_time')}</strong></h4>
			<ApInputStack gap="0">
				<ApInput
					rounded="left"
					type="select"
					options={weekdayOptions}
					value={this.state.send_day}
					id="sendDay"
					name="sendDay"
					onChange={(e) => this.handleChangeWeekday(e)}
				/>
				<div>&nbsp;&nbsp;</div>
				<ApInput
					rounded="left"
					type="select"
					options={hourOptions}
					value={this.state.sendHours}
					id="sendHours"
					name="sendHours"
					onChange={(e) => this.handleChangeTime(e)}
				/>
				<ApAddon width="10px" rounded={false}>
					<span>:</span>
				</ApAddon>
				<ApInput
					type="select"
					options={minuteOptions}
					value={this.state.sendMinutes}
					id="sendMinutes"
					name="sendMinutes"
					onChange={(e) => this.handleChangeTime(e)}
				/>
			</ApInputStack>
			{moment(this.state.send_at).format("DD.MM.YYYY HH:mm")}
		</div>
		</Collapse>

		return <ApModal
			closeFromBg
			show={Boolean(this.state.messageModal.show)}
			handleClose={() => this.setState({ messageModal: {} })}
			className="narrow"

			onOpen={this.addressModalOpen}
			header={
				<div className="padding">
					{tr('send_message')}
				</div>
			}
			body={<div className="padding">
				<ApInput
					label={tr('message')}
					type="textarea"
					name="message"
					id="message"
					value={this.state.message}
					onChange={(e) => this.setState({ message: e.target.value })}
				/>
				{sendSMS}
				<Collapse in={this.state.sendSMS}><div>
					<ApInput
						label={tr('phone')}
						type="text"
						name="send_number"
						id="send_number"
						value={this.state.send_number}
						onChange={(e) => this.setState({ send_number: e.target.value })}
					/>
				</div>
				</Collapse>
				{sendEmail}
				<Collapse in={this.state.sendEmail}>
					<div>
						<ApInput
							label={tr('email_address')}
							type="text"
							name="send_email"
							id="send_email"
							value={this.state.send_email}
							onChange={(e) => this.setState({ send_email: e.target.value })}
						/></div>
				</Collapse>
				{timedSend}
				{sendDate}
			</div>}
			footer={<div className="padding text-right">

				<ApButton onClick={() => this.setState({ messageModal: {} })}>
					<SvgIcon icon="times" type="solid" />
					{tr('cancel')}
				</ApButton>

				<ApButton color="green" onClick={this.sendMessage} disabled={(!this.state.sendEmail && !this.state.sendSMS) || this.state.loading}>

					<SvgIcon icon="envelope" type="solid" />
					{tr('send')}
				</ApButton>

			</div>}
		/>
	}

	renderComponentTable(type) {
		let locked = false;

		return <ComponentInstanceTable
			type={type}
			componentsChange={this.componentsChange}
			components={this.state.components}

			instance={'assignment'}
			storageId={this.state.assignment?.project?.storage_location_id}

			loading={this.state.loading}
			removeComponentLocked={this.state.assignment.status_id >= 7}
			addComponentLocked={this.state.assignment.status_id >= 7}

		/>
	}
	setChanged() {
		this.setState({ noChanged: false });
		if (this.props.changed) {
			this.props.changed(true);
		}
	}

	renderControlPanel() {
		// status 4, running, tooltip
		let runningBtnWarning = null;
		if (this.props.apTimer.dayIsLocked) {
			runningBtnWarning = tr('day_locked');
		} else if (this.props.apTimer.status !== 'stopped') {
			runningBtnWarning = tr('timer_playing_warning');
		} else if (
			this.props.apTimetrackingSettings?.company?.timer_time_attendance_required 
			&& this.props.apTimer?.isSignedInTimeAttendance === false
			&& this.props.apProjectSettings?.assignment_startclock
		) {
			runningBtnWarning = tr('timer_time_attendance_required');
		}

		const addButtonWaiting_worker = <div><ApButton
			// size="small"
			color="blue"
			onClick={() => this.markStatus(3)}
			disabled={this.state.assignment.status_id >= 8 || this.state.loading}
			fullWidth
		>
			{tr('assignment_status_control_3')}
		</ApButton></div>

		const addButtonRunning = <div><ApTooltip block text={runningBtnWarning}><ApButton
			// size="small"
			color="blue"
			onClick={() => this.markStatus(4)}
			disabled={
				this.state.assignment.status_id == 4 ||
				(!this.state.assignment.user_id &&
					!this.state.assignment.team_id)
				|| this.state.loading
				|| runningBtnWarning
			}
			fullWidth
		>
			{tr('assignment_status_control_4')}
		</ApButton></ApTooltip></div>
		const addButtonFree = <div><ApButton
			// size="small"
			color="blue"
			onClick={() => this.markStatus(2)}
			disabled={this.state.assignment.status_id == 2 || this.state.assignment.status_id >= 8 || this.state.loading}
			fullWidth
		>
			{tr('assignment_status_control_2')}
		</ApButton></div>


		const addButtonWait = <div><ApButton
			// size="small"
			color="blue"
			onClick={() => this.markStatus(7)}
			disabled={this.state.assignment.status_id >= 7 || this.state.loading}
			fullWidth
		>
			{tr('assignment_status_control_7')}
		</ApButton></div>

		const addButtonEnded = <div><ApButton
			// size="small"
			color="blue"
			onClick={() => this.markStatus(10)}
			disabled={this.state.assignment.status_id > 7 || this.state.loading}
			fullWidth
		>
			{tr('assignment_status_control_10')}
		</ApButton></div>

		const addButtonDone = <div><ApButton
			// size="small"
			color="blue"
			onClick={() => this.markStatus(9)}
			disabled={this.state.assignment.status_id <= 2 || this.state.loading}
			fullWidth
		>
			{tr('assignment_status_control_9')}
		</ApButton></div>

		const addButtonFailed = <div><ApButton
			// size="small"
			color="red"
			onClick={() => this.markStatus(8)}
			disabled={this.state.assignment.status_id > 7 || this.state.loading}
			fullWidth
		>
			{tr('assignment_status_control_8')}
		</ApButton></div>

		const dropdown = (
			<div className="dropdown-container">
				<ApDropdown
					button={<ApButton><SvgIcon icon="ellipsis-h" type="solid" /></ApButton>}
					actions={[
						{
							label: tr('assignment_status_control_2'),
							action: () => this.markStatus(2),
							disabled: this.state.assignment.status_id == 2 || this.state.assignment.status_id >= 8 || this.state.loading,
							closeAfter: true,
						},
						{
							label: tr('assignment_status_control_3'),
							action: () => this.markStatus(3),
							disabled: this.state.assignment.status_id >= 8 || this.state.loading,
							closeAfter: true,
						},
						{
							label: tr('assignment_status_control_4'),
							action: () => this.markStatus(4),
							disabled:
								this.state.assignment.status_id == 4 ||
								(!this.state.assignment.user_id &&
									!this.state.assignment.team_id)
								|| this.state.loading
								|| runningBtnWarning,
							closeAfter: true,
							tooltip: runningBtnWarning,
						},
						{
							label: tr('assignment_status_control_7'),
							action: () => this.markStatus(7),
							disabled: this.state.assignment.status_id >= 7 || this.state.loading,
							closeAfter: true,
						},
						{
							label: tr('assignment_status_control_8'),
							action: () => this.markStatus(8),
							disabled: this.state.loading,
							icon: "ban",
							closeAfter: true,
						},
						{
							label: tr('assignment_status_control_9'),
							action: () => this.markStatus(9),
							disabled: this.state.assignment.status_id <= 2 || this.state.loading,
							icon: "check",
							closeAfter: true,
						},
						{
							label: tr('assignment_status_control_10'),
							action: () => this.markStatus(10),
							disabled: this.state.loading,
							closeAfter: true,
						},
					]}
				/>
			</div>
		)

		const addButtonSave = (
			<div className="saveChanges">
				<ApButton
					// size="small"
					color="blue"
					onClick={this.saveChanges}
					disabled={this.state.noChanged}
					className={!this.state.noChanged ? "highlight" : ""}
				>
					<SvgIcon icon="save" type="solid" />
					{tr('save')}
				</ApButton>
			</div>
		);

		const saveBox = <ApStickyBar zIndex={10} bottomMode ref={node => this.stickyBar = node} classes={['width100']}>
			<div className="saveBar">
				<div className="left">
				</div>
				<div className="right">
					<ApButton
						color="blue"
						onClick={this.saveChanges}
						disabled={this.state.noChanged || this.state.loading}
						className={!this.state.noChanged ? "highlight" : ""}
					>
						<SvgIcon icon="save" type="solid" />
						{tr('save')}
					</ApButton>
				</div>
			</div>
		</ApStickyBar>

		return (
			<div className="apColumn padding control-panel">
				<div className="buttons-container">
					{(!this.state.assignment.user_id && !this.state.assignment.team_id) && addButtonWaiting_worker}
					{(this.state.assignment.user_id || this.state.assignment.team_id) && addButtonRunning}
					{addButtonFree}
					{this.state.assignment.status_id < 7 && addButtonWait}
					{addButtonDone}
					{/* {addButtonFailed} */}
					{/* {addButtonEnded} */}
					{dropdown}
				</div>
				<div className="save-button-container">
					{/* {addButtonSave} */}
				</div>
				{this.props.modalMode ? null : saveBox}
			</div>
		);
	}

	renderHeader() {

		return (
			<div className="clear">
				<div className="apColumn w50"><strong>{tr('assignment_creator')}:</strong> {this.state.assignment.manager.name}</div>
				<div className="apColumn w50"><strong>{tr('assignment_creation_date')}:</strong> {moment(this.state.assignment.created_at).format("L")}</div>

				<div className="apColumn w25 top-margin">
					<strong>{tr('schedule')}:</strong>
					{this.preset_dates()}
				</div>
				<div className="apColumn w25 top-margin">
					<strong>{tr('customer')}:</strong>{this.preset_customer()}
					<strong>{tr('customer_work_number')}:</strong>{this.preset_customer_work_number()}
					<strong>{tr('phone')}:</strong>{this.preset_phone()}
				</div>
				<div className="apColumn w25 top-margin">
					<strong>{tr('project')}:</strong>{this.preset_project()}
					<strong>{tr('chat')}:</strong>{this.preset_chat()}
				</div>
				<div className="apColumn w25 top-margin">
					<strong>{tr('location_alt')}:</strong>{this.preset_address()}
				</div>

			</div>

		);

	}

	renderSummary() {
		const userInSelectedGroup = this.state.assignment.user && this.state.assignment.user.team_members
			? this.state.assignment.user.team_members.find(member => member.user_id === this.state.current_user)
			: false;

		return (

			<div className="clear">

				<div className="padding">

					{this.renderHeader()}

					<div><strong>{tr('status')}:</strong> {this.state.statuses != null &&
						tr(this.state.statuses[this.state.assignment.status_id])}</div>
					<div><strong>{tr('assignment_worker')}:</strong> {this.preset_user()}</div>
					{userInSelectedGroup && <div>
						{this.state.assignment.teamMemberPortionDone && !this.state.assignment.teamMemberPortionDone.includes(this.state.current_user) && <div>
							<ApButton
								onClick={() => this.handlePortionDoneStatusChange('done')}
								size="small"
								color="green"
								disabled={this.state.loading}
							>
								<SvgIcon icon="check" type="solid" /> {tr('assignment_own_portion_done')}
							</ApButton>
						</div>}

						{this.state.assignment.teamMemberPortionDone && this.state.assignment.teamMemberPortionDone.includes(this.state.current_user) && <div>
							<ApButton
								onClick={() => this.handlePortionDoneStatusChange('delete')}
								size="small"
								disabled={this.state.loading}
							>
								<SvgIcon icon="undo" type="solid" /> {tr('assignment_own_portion_revert')}
							</ApButton>
						</div>}
					</div>}

				</div>
				<div className="apColumn padding">
					<div className="apColumn w50">
						<div className="description-container">

							<ApInput
								type="text"
								id="name"
								name="name"
								label={tr('header')}
								value={this.state.assignment.name}
								onChange={this.handleChange}
								loading={this.state.loading}
								disabled={this.state.loading}
							/>

							<ApInput
								type="textarea"
								rows="4"
								id="description"
								name="description"
								label={tr('description')}
								value={this.state.assignment.description}
								onChange={this.handleChange}
								loading={this.state.loading}
								disabled={this.state.loading}
							/>

						</div>
					</div>
					<div className="apColumn w50 files-container">
						{this.renderFiles()}
					</div>
				</div>

				<div className="apColumn">

					{this.state.assignment.project != null && <div className="padding">{this.renderComponentTable('item')}</div>}

					{/* { this.state.assignment.project==null && <div className="padding">{this.renderFiles()}</div> } */}

				</div>
				{this.renderControlPanel()}
			</div>
		);

	}

	handlePortionDoneStatusChange(status) {
		let assignment = { ...this.state.assignment };
		if (status === 'done') {
			assignment.teamMemberPortionDone.push(this.state.current_user);
		} else if (status === 'delete') {
			assignment.teamMemberPortionDone = assignment.teamMemberPortionDone.filter(memberId => memberId !== this.state.current_user);
		}

		this.setState({ loading: true });
		api({
			method: 'get',
			url: `assignment/${this.state.assignment.id}/portion/${status}`
		}).then((response) => {
			if (response === true) {
				assignment.status_id = 9;
			}
			else if (response === false && assignment.status_id >= 8) {
				assignment.status_id = 4;
			}
			this.setState({ loading: false, assignment: assignment });
		}).catch((error) => {
			console.error(error);
			this.setState({ loading: false });
		});
	}

	handleNavigationButtonClick(direction) {
		const currentId = this.props.id ? this.props.id : this.props.match.params.id;
		const currentTab = this.props.tab ? this.props.tab : this.props.match.params.tab;
		if (direction === "next") {
			const nextIndex = parseInt(this.state.assignmentsList.findIndex(id => id == currentId)) + 1;
			if (nextIndex >= this.state.assignmentsList.length) return;
			if (this.props.id) {
				this.props.changeAssignmentTo(this.state.assignmentsList[nextIndex]);
			}
			else {
				this.props.history.push(`/assignments/${this.state.assignmentsList[nextIndex]}/${currentTab}`)
			}
		}

		if (direction === "prev") {
			const prevIndex = parseInt(this.state.assignmentsList.findIndex(id => id == currentId)) - 1;
			if (prevIndex < 0) return;
			if (this.props.id) {
				this.props.changeAssignmentTo(this.state.assignmentsList[prevIndex]);
			}
			else {
				this.props.history.push(`/assignments/${this.state.assignmentsList[prevIndex]}/${currentTab}`)
			}
		}
	}
	renderCopyAssignmentconfinm() {
		return <ApConfirm
			show={this.state.showCopyAssignmentconfinm}
			onClose={() => this.setState({ showCopyAssignmentconfinm: false })}
			onConfirm={() => this.copyAssignment()}
			header={tr('copy_assignment')}
			body={
				<div>
					<p>
						<strong>{tr('copy_current_assignment_confirm')}</strong>
					</p>

				</div>
			}
		/>
	}

	showCopyAssignmentconfinm() {
		this.setState({
			showCopyAssignmentconfinm: true,
		});
	}

	render() {
		const getButton = (file, title, type) => {
			const icon = (type === 'docx') ? 'file-word' : 'file-pdf';
			return <ApButton color="white"
				loading={this.state.loading}
				disabled={this.state.loading}
				onClick={() => this.downloadFile(file, title, type)}
				size="tiny"
			>
				<SvgIcon icon={icon} type="solid" />
			</ApButton>
		}

		const isFirstIndex = parseInt(this.state.assignmentsList.findIndex(id => {
			if (!(this.props.id || this.props.match)) {
				return false;
			}
			const assignmentId = this.props.id ? this.props.id : this.props.match.params.id;
			return id == assignmentId;
		}
		)) == 0;
		const isLastIndex = parseInt(this.state.assignmentsList.findIndex(id => {
			if (!(this.props.id || this.props.match)) {
				return false;
			}
			const assignmentId = this.props.id ? this.props.id : this.props.match.params.id;
			return id == assignmentId;
		}
		)) >= this.state.assignmentsList.length - 1;

		const fileIsPdf = this.state.selectedImageExtension
			&& this.state.selectedImageExtension === 'pdf';

		return (
			<div className="apBox" id="AssignmentPage">
				{hasPermissions('assignments.manage') &&
					<div className="pageBackButton" onClick={() => {
						if (this.props.id) {
							this.props.close();
						}
						else {
							this.props.history.push(`/assignments/${tabs[this.props.match.params.tab]}`)
						}
					}
					}>
						<SvgIcon icon="arrow-left" type="solid" size="small" />
						{tr('all_assignments')}
					</div>}
				{hasPermissions('assignments.manage') &&
					<ApButton
						// size="small"
						color="green"
						onClick={() => this.showCopyAssignmentconfinm()}

						style={{ float: 'right', margin: 10, zIndex: 10 }}
					>
						{tr('copy')}
					</ApButton>}

				{this.renderCustomerModal()}
				{this.renderCustomerWorkNumberModal()}
				{this.renderProjectModal()}
				{this.renderAddressModal()}
				{this.renderUserModal()}
				{this.renderDatesModal()}
				{this.renderMessageModal()}
				{this.renderPhoneModal()}
				{this.renderWorkgroupModal()}
				{this.renderCopyAssignmentconfinm()}
				{this.renderChat()}
				<div className="apBoxHeader">
					{!this.props.modalMode && <h1> {tr('assignment')} </h1>}
					{!this.props.modalMode && <p> {tr('assignment_info')} </p>}
					{tr('download_assignment')} {getButton('assignment', 'Työmääräys', 'pdf')} {getButton('assignment', 'Työmääräys', 'docx')}
					{hasPermissions('assignments.manage') &&
						<div className='navigation-btns-container'>
							<ApButton
								disabled={isFirstIndex}
								onClick={() => this.handleNavigationButtonClick("prev")}
								color='blue'
								className='navigation-btn'>
								<SvgIcon icon="arrow-left" type="solid" size="small" />
								{tr('previous')}
							</ApButton>

							<ApButton
								disabled={isLastIndex}
								onClick={() => this.handleNavigationButtonClick("next")}
								color='blue'
								className='navigation-btn'>
								{tr('next')}
								<SvgIcon icon="arrow-right" type="solid" size="small" />
							</ApButton>
						</div>}
				</div>

				{this.state.assignment !== null &&
					<div className="padding">
						{this.renderSummary()}

						<div className='padding'>
							{this.renderComment()}
							<h2>{tr('comments')}:</h2>
							{this.renderComments()}
						</div>

					</div>
				}
				<br />
				<ApModal
					show={this.state.showImageModal}
					onClose={() => this.setState({ showImageModal: false, selectedImage: null })}
					handleClose={() => this.setState({ showImageModal: false, selectedImage: null })}
					closeFromBg
					className="narrow overflow"

					header={
						<div className="padding">
							<h3>{tr('file')}</h3>
						</div>
					}

					body={
						<div className="padding">
							{this.state.selectedImage
								&& !fileIsPdf
								&& <img src={getImageUrl(this.state.selectedImage)} className="assignment-image" />}
							{this.state.selectedImage
								&& fileIsPdf
								&& <embed src={getImageUrl(this.state.selectedImage)} className="assignment-file" />}
						</div>
					}
					footer={
						<div className="padding">
							<ApButton
								onClick={() => { this.setState({ showImageModal: false }) }}
								className="cancel"
							>
								{tr('cancel')}
							</ApButton>

							<ApButton
								onClick={() => this.getFile(this.state.selectedImage, this.state.selectedImageName)}
								style={{ float: "right" }}
								className="applyButton"
								color="green"
							>
								<SvgIcon icon="file" type="solid" />
								{tr('download')}
							</ApButton>
						</div>
					}
				/>
			</div>

		);

	}

}

const mapStateToProps = ( state ) => {
    return {
        apTimer: state.apTimer,
		apTimetrackingSettings: state.apTimetrackingSettings,
		apProjectSettings: state.apProjectSettings,
    };
};

export default connect(mapStateToProps,null,null, { forwardRef: true })(AssignmentPage);