import React from 'react';
import PropTypes from 'prop-types';

import './ApUserImage.css';

import { getImageUrl } from  'services/Helpers/Helpers.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import SvgIcon from 'common/SvgIcon/SvgIcon';

/*

Examples:

// You propably want to use "getBasicInfoById"-function 
// from ClientUser.php to provide user data, but you can
// also manually create user data object: 

const user = { 
    image: 123,
    initials: "AB",

    // You can also provide "first_name" and "last_name" instead of initials
    first_name: "foo",  
    last_name: "bar",   
};

// Basic usage
<ApUserImage user={ user } />
<ApUserImage user={ user } withShadow />

// Render as block node (by default display: inline-block is used)
<ApUserImage user={ user } block />

// Different sizes 
<ApUserImage user={ user } size="tiny" />
<ApUserImage user={ user } size="small" />
<ApUserImage user={ user } size="large" />
<ApUserImage user={ user } size="huge" />

// Tooltipping 
<ApUserImage user={ user } tooltip={ "User image tooltip" } />
<ApUserImage user={ user } tooltip={ "User image tooltip" } tooltipPosition="left" } />

// Customizing
<ApUserImage user={ user } className="customUserImage" />
<ApUserImage user={ user } style={{ borderColor: "#f00", backgroundColor: "#fed" }} />

*/

class ApUserImage extends React.Component {

    render()
    {
        if( !this.props.user )
            return false;

        const user = this.props.user;
        const image = user.image ? getImageUrl( user.image ) : false;
        let initials = user.initials ? user.initials : false;
        let style = { ...this.props.style };

        if( image )
            style.backgroundImage = 'url(' + image + ')';

        // If no image or initials found, try to use user "first_name" and "last_name" to create initials 
        if( !image && !initials && user.first_name && user.last_name )
            initials = user.first_name.charAt(0).toUpperCase() + user.last_name.charAt(0).toUpperCase();

        let classes = [ "ApUserImage" ];

        if( this.props.block )
            classes.push( "block" );

        if( this.props.withShadow )
            classes.push( "withShadow" )

        if( [ "tiny", "small", "large", "huge" ].indexOf( this.props.size ) !== -1 )
            classes.push( "size-" + this.props.size );
        
        if( this.props.className )
            classes.push( this.props.className );
        if(user.is_group)
            classes.push('group');

        if( !image && !initials )
            console.error( 'You should provide "image" (number) or "initials" (string) for ApUserImage!' );

        if (this.props.imageElement) {
            return (
                <ApTooltip text={ this.props.tooltip } position={ this.props.tooltipPosition }>
                    <div className={ classes.join(" ") }>
                        {image ? <img src={image} className={ classes.join(" ") } /> : null}
                        { !image && initials && <span className="initials">{ initials }</span> }
                    </div>
                </ApTooltip>
            );
        }
        return (
            <ApTooltip text={ this.props.tooltip } position={ this.props.tooltipPosition }>
                <div className={ classes.join(" ") } style={ style }>
                    {user.is_group && <SvgIcon icon='user-friends' type='solid' className='small-inline' />}
                    { !image && initials && <span className="initials">{ initials }</span> }
                </div>
            </ApTooltip>
        );
    }
};

ApUserImage.propTypes = {
    user:               PropTypes.object.isRequired,
    className:          PropTypes.string,
    block:              PropTypes.bool, 
    withShadow:         PropTypes.bool,
    size:               PropTypes.string,
    tooltip:            PropTypes.node,
    tooltipPosition:    PropTypes.string,
    style:              PropTypes.object,
    imageElement:       PropTypes.bool, // if true, image will be rendered as img element, useful for printing
};

export default ApUserImage;

