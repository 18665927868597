/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import axios from 'axios';
import moment from 'moment';
import {matchSorter} from 'match-sorter';

import './ReceiptReports.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ReceiptModal from './ReceiptModal.js';
import { statusText, timetrackingIcon, timetrackingIconColor } from  'modules/Timetracking/TimetrackingHelpers.js';
import { tr, currentLang } from 'services/Helpers/Helpers.js';
import UserBrowser from '../../Approve/UserBrowser/UserBrowser.js';

export default class ReceiptReports extends React.Component {

    constructor( props ) 
    {
        super(props);
        this.state = {
            loading:            false,  
            users:              [],
            selectedUser:       null,
            entries:            [],
            showModal:          false,
			columns:            [],
			selectedItemId:     null,
        };
        autoBind(this);
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers( user = null )
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'timetracking/expenses/users',
        }).then(( response ) => {

            //console.log('USERS', response.users );
         
            this.setState({
                loading: false,
                users: response.users,
            }, () => {

                if( response.users.length > 0 )
                {
                    if( !user || response.users.findIndex( r => r.id == user ) == -1 ) {
                        const foundUser = response.users.find(u => {
                            if (!u.is_active) return false;

                            if (moment(u.contract_start).isAfter(moment())) return false;
                            if (u.contract_end && moment(u.contract_end).isBefore(moment())) return false;

                            return true;
                        })
                        if (foundUser) {
                            user = foundUser.id;
                        } else {
                            user = response.users[0].id;
                        }
                    }
                  
                    this.changeUser( user );
                }
                else 
                    this.setState({ 
                        selectedUser:       null,
                        entries:            [],
                    });

            });

        }).catch( ( error ) => {
            console.log( 'getUsers ERROR', error );
            this.setState({ loading: false });
        });
    }

    changeUser( id )
    {
        //console.log('changeUser', id );

        // Cancel possible old query 
        if( this.state.cancelToken )
            this.state.cancelToken.cancel('Operation canceled by the user.');
           
        this.setState({ 
            selectedUser: id,
            loading: true,
            cancelToken: axios.CancelToken.source(),
        }, () => {
            api({
                cancelToken: this.state.cancelToken.token,
                method: 'post',
                url: 'timetracking/expenses/entries',
                data: { user: id }
            }).then(( response ) => {

                response.forEach(res => {
                    res.translated_status = statusText(res.status);
                    res.total_money = parseFloat(res.total_money);
                })

                this.setState({
                    loading: false,
                    entries: response,
                    cancelToken: null,
                });

            }).catch( ( error ) => {
                if( axios.isCancel( error ) ) return null;
                console.log('ERROR', error );
            });
        });
    }

    openItem(item)
	{
		this.setState({ selectedItemId: item.id, showModal: true });
	}
    
    getColumns()
	{

		return [
            {
                Header: tr('project'),
                id: 'project.name', 
                onClick: this.openItem,
                filterAll: true,
                Cell: (props) => {
					return (
						<div>
							{/* {`${props.original.project.name} (${props.original.project.project_code})`} */}
                            <strong>{ props.original.project.name }</strong><br />
					        <small>{ props.original.project.project_code }</small>
						</div>
					);
                },
                accessor: ( props ) => {
                    return props.project.name;
                }, 
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />   
                    )
                },
                filterMethod: ( filter, rows ) => matchSorter( rows, filter.value, { keys: [ 
                    "_original.project.name",
                    "_original.project.project_code"
                ]}),     
            },
			{
                Header: tr('description'),
                accessor: 'description',
                filterable: false,
            },
            {
                Header: tr('expenses'),
                id: 'total_money',
                filterable: false,
				Cell: (props) => {
					return (
						<div>
							{`${props.original.total_money} ${props.original.money_currency}`}
						</div>
					);
                },
                accessor: ( props ) => {
                    return props.total_money;
                }, 
            },
            {
                Header: tr('status'),
                id: 'project.translated_status',
                filterAll: true,
				Cell: (props) => {
					return (
						<div>
							{`${props.original.translated_status} `} 
                            <span className={ `apBadge ${timetrackingIconColor(props.original.status)}` } style={{marginRight: 5}}>
							    <SvgIcon className="small-inline" icon={timetrackingIcon(props.original.status)} type="solid" />
						    </span>
						</div>
					);
                },
                accessor: ( props ) => {
                    return props.translated_status;
                }, 
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />   
                    )
                },
                filterMethod: ( filter, rows ) => matchSorter( rows, filter.value, { keys: [ 
                    "_original.translated_status"
                ]}), 
			},
			{
                Header: tr('date'),
                id: 'date',
                filterable: false,
				accessor: 'date',
				Cell: (props) => {
					return (
						<div>
							{moment( props.original.date ).format( 'D.M.YYYY' )}
						</div>
					);
				}
            },
            {
                Header: tr('updated_at'),
                id: 'updated_at',
                filterable: false,
				accessor: 'updated_at',
				Cell: (props) => {
					return (
						<div>
							{moment( props.original.updated_at ).locale(currentLang()).format('LLL')}
						</div>
					);
				}
			},
			
		];

    }

    closeModal()
	{
		this.setState({ selectedItemId: null, showModal: false });
    }
    
    onSave()
	{
		this.setState({ selectedItemId: null, showModal: false }, () => {
			this.changeUser( this.state.selectedUser );
		});
	}

    render()
    {

      
        return (
            <div className="apBox">

                <UserBrowser 
                    users={ this.state.users } 
                    selectedUser={ this.state.selectedUser }
                    onChangeUser={ this.changeUser }
                />

                {this.state.entries && !this.state.loading ?
                    <div className="padding">
                        <ApReactTable
                            data={this.state.entries}
                            columns={this.getColumns()}
                            rowActions={ (row) => (
                                <div className="apSimpleButton" onClick={ () => this.openItem(row) }>
                                    <SvgIcon icon="arrow-right" type="solid" />
                                </div>
                            )}
                            defaultSorted={[ 
                                { id: "updated_at", desc: true }
                            ]}
                            showFiltersInitially
                            filterable
                        />
                    </div>
                    :
                    <div className="apMsg">
                        <div className="apInlineLoader"></div>
                    </div>
				}

                <ReceiptModal 
					show={this.state.showModal}
                    onClose={this.closeModal}
                    onSave={this.onSave}
                    itemId={this.state.selectedItemId}
				/>

            </div>
        );
    }
}
