/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';
import { connect } from 'react-redux';

import auth                from 'services/Authed/Authed.js';
import api                 from 'services/Api/Api.js';
import ApValidate          from 'services/ApValidate/ApValidate.js';
import {
    errorPopper,
    formatMoney,
    dateInputToSql,
    keyExists,
    sqlToDateInput,
    tr }  from 'services/Helpers/Helpers.js'

import { ApInput }         from 'common/ApInput/ApInput.js';

import Steps               from './Steps/Steps.js';

import SaveBox             from './SaveBox/SaveBox.js';
import ApButton from 'common/ApButton/ApButton.js';
import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit.js';
import NewProject from 'modules/Projects/NewProject/NewProject.js';
import CommentDialog from './CommentDialog/CommentDialog.js'

import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import { getTypeIcon
       , getTypeName } from 'modules/Storage/common/StorageHelpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import RelatedSelectModal from 'modules/Storage/common/RelatedSelectModal/RelatedSelectModal.js';
import Audits from './Audits/Audits.js';

import AddressModal from './AddressModal/AddressModal.js';

import { getOrderList } from '../OrderHelper.js';
import { getFrom, getCompanyId, setContinueWithCompany, setFrom, getStartDate, getEndDate } from 'modules/Billable/billableHelper'
import moment from 'moment';
import './EditOrder.css';
import EditOrderCode from './EditOrderCode';
import EditOrderContactPerson from './EditOrderContactPerson';
// import Files from './Files/Files.js';
import Files from 'common/File/Files.js';

import ApSelect from 'common/ApSelect/ApSelect.js';

class OrderEdit extends React.Component {

    constructor(props)
    {
        super(props);
        this.componentInstanceTableRef = null;

        this.state = {

            unsaved: false,

            loading: false,

            audits: [],
            components: [],
            groups:[],
            vats:[],

            previousItem: null,
            nextItem: null,

			company_bank_accounts:'[{"bankname":"","iban":"","bic":""}]',
			
            addressEditShow: false,
            address_options: [],

            fieldEditShow: false,
            relatedEditShow: false,
            projectCodeEditShow: false,
            contactPersonEditShow: false,
            commentDialogShow: false,

            currencySign: auth.getCurrencySign(),
            project: null,
            orgProject: null,
            ext_interfaces: [],
            externalInterfaces:[],// eg. netvisor: true,
            ext_imports: {},
            edited: false,
            use_tax_groups:false,

            default_item_tax_group: null,
            default_work_tax_group: null,
            default_expence_tax_group: null,

            default_cost_center_id: null,

            showNewProjectModal: false,
            newProjectTree:{},
            filesModalShow: false,

            arrowButtonsData:[],
         
        }

        const componentsExistFilter = () => {
            if ( this.state.components.length > 0 )
                return this.state.components.some( c => parseFloat( c.count ) );
            return false;
        }

        const reserveRequiredFilter = ( value, index ) => {
            if( this.state.type === 'no_delivery') return true;
            const c = this.state.components[ index ];
            if( c.type_name === 'work' ) return true;
            if( c.type_name === 'other' ) return true;
            return Boolean( value );
        };

        const groupVatValidation = () => {
            let vatGroups = {}
            return this.state.components.every( c => {
                if (c.order_group_id == null) {
                    return true;
                }
                
                if (!vatGroups[c.order_group_id])
                {
                    vatGroups[ c.order_group_id ] = c.order_vat;
                    return true;
                }
                return (  vatGroups[ c.order_group_id ] == c.order_vat )
            })
        };

        this.validator = new ApValidate(this, {
            components_needed: {filter:  componentsExistFilter, textAll: tr('number_of_storage_components_zero') },
            components: {
                filters: {
                    order_from_type: { filter: reserveRequiredFilter, state: 'error', text: tr('component_reservation_not_selected') },
                    count: { filter: 'required', state: 'error', textAll: tr('errors_in_quantity_of_storage_components') },
                    order_price: { filter: 'required', state: 'error', textAll: tr('storage_component_price_not_specified') },
                    order_vat: { filter: 'required', state: 'error', textAll: tr('storage_component_vat_not_specified') },
                    cost_center_id: {
                        filter: (value) => {
                            if (!keyExists(this.state, 'cost_center_is_required', true)) return true;
                            return (value != null ? true : false);
                        },
                        state: 'error',
                        text: tr('mandatory'),
                        textAll: tr('cost_center')
                    },
                }
            },
            groups: {
                filters: {
                    count: { filter: 'required', state: 'error', textAll: tr('errors_in_quantity_of_storage_components') },
                }
            },
            vat_groups: { filter: groupVatValidation, textAll: tr('component_group_different_vat_categories') },
            project: {
                filter: (value) => {
                    if (!this.props?.apOrderSettings?.is_project_mandatory) return true;
                    return (value != null ? true : false);
                },
                state: 'error',
                textAll: tr('project_is_required')
            },
        });
        autoBind(this);
    }

    componentDidMount()
    {
        const params = this.props.match.params;
        if( params.type === 'id' )
            this.getOrder('id', params.id );
        else
            this.getOrder('number', params.type);

    }
    


    //helping find url query
    //url: 'storage/order/number/
    //url: 'storage/order/id/
    getOrder( type, id )
    {
        this.setState({ loading: true });

        return api({
            method: 'get',
            url: `storage/order/${ type }/${ id }`,
        }).then((response) => {
            //console.log("response", response);
            let crm_billing_methods=[];
            for (let key in response.crm_billing_methods) {
                crm_billing_methods.push({ id: response.crm_billing_methods[key],name:tr(key)});
            }
            
            response.crm_billing_methods = crm_billing_methods;
            response.components.map(component => {
                if (component.conversion_unit_id) {
                    component.selectedUnit = component.conversion_units.find(unit => unit.id === component.conversion_unit_id);
                    component.count = Number((parseFloat(component.count) * parseFloat(component.selectedUnit.conversion_factor)).toFixed(5));
                    component.order_price = parseFloat(component.order_price) / parseFloat(component.selectedUnit.conversion_factor);
                }
            });
            let externalInterfaces = [];
            if (response.ext_interfaces?.length > 0) {
                for (let key of response.ext_interfaces) {
                    externalInterfaces.push(key.type);
                }
            }

            const sorted = this.sortComponents(response)
            this.setOrder( sorted );
            this.setState({
                loading: false, 
                ext_interfaces: response.ext_interfaces,
                ext_imports: response.external_imports,
                externalInterfaces: externalInterfaces,
                
                billing_method_name: response.billing_method.name?(tr(response.billing_method.name)):null,
                billing_method_id: response.billing_method.id ? response.billing_method.id : null,
                cost_center_enabled: response.cost_center_enabled,
                cost_center_is_required: response.cost_center_is_required,

                default_item_tax_group: response.default_item_tax_group,
                default_work_tax_group: response.default_work_tax_group,
                default_expence_tax_group: response.default_expence_tax_group,

                default_cost_center_id: response.default_cost_center_id,
            }, () => { this.getNavigationsIds(); });
            // this.getNavigationsIds()
        }).catch((error) => {
            const message = errorPopper(error, tr('get_error'));
            console.error(error);
            this.setState({
                error: message,
                loading: false,
            });
        });
    }

    sortComponents (data)
    {
        //sort groups
        const groups = data.groups

        const timetracking_hours = []
        const installations = []
        const others = []

        groups.forEach((entry) => {
            if (entry.type = 'timetracking_hour') {
                timetracking_hours.push(entry) 
                return
            }
            if (entry.type = 'project_installation') {
                installations.push(entry)
                return
            }

            others.push(entry)
        })

        const sortedGroups = timetracking_hours.concat(installations, others)

        data.groups = sortedGroups

        //sort components
        const components = data.components
        const components_timetracking_hours = []
        const components_installations = []
        const components_others = []
        const components_discounts = []

        components.forEach((component) => {

            if (Number(component.price_sell) <= 0) {
                components_discounts.push (component)
                return
            }

            if (component.type_name === 'work') {
                components_timetracking_hours.push (component)
                return
            }

            if (component.type_name === 'item') {
                components_installations.push (component)
                return
            }

            components_others.push(component)
        })

        data.components = components_timetracking_hours.concat(components_installations, components_others, components_discounts)
        
        return data
    }

    setOrder( data )
    {
        //console.log('data', data );

        const dateFields = [ 'date', 'need_date', 'due_date','delivery_date' ];
        dateFields.forEach( df => {
            data[ df ] = sqlToDateInput( data[ df ] );
        });

        this.setState( data );
        this.calculateTotalPrice( data.components );
        this.sortComponents(data);

        if( data.number )
            this.props.history.replace(`/storage/order/${ data.number }`);

        // this.attachToProject(data);
    }


    // TODO: need optimization. Not needed to get 9000 rows from database
    // can use postgresql lead and lag functions instead
    // https://www.postgresqltutorial.com/postgresql-window-function/postgresql-lag-function/
    // https://www.postgresqltutorial.com/postgresql-window-function/postgresql-lead-function/
    getNavigationsIds()
    {   
        // const data = getOrderList()
        const data = this.state.arrowButtonsData;

        if (data.length === 0) {
            api({
                method: 'post',
                url: 'storage/orders/apt',
                data: {
                    // sorted: [{ id: "id", desc: true }],
                    ...this.props.apReackTable,
                    pageSize: 9000,
                },
            }).then((response) => {
                this.setState({ arrowButtonsData: response.data });
                this.setButtons(response.data)
            }).catch((error) => {
                console.error(error)
            })
            return
        }

        this.setButtons(data)   
    }

    setButtons(data)
    {
        const id = this.state.number

        for (let i = 0; i < data.length; i++) {
            if (data[i].number === id) {
                if (i !== data.length -1) {
                    this.setState({previousItem: data[i + 1].number });
                } else {
                    this.setState({previousItem: null });
                }

                if (i !== 0) {
                    this.setState({ nextItem: data[i - 1].number });
                } else {
                    this.setState({ nextItem: null });
                }

                break
            }
        }
    }

    goToNextItem()
    {
        setFrom(false)

        if (!this.state.nextItem) {
            window.emitter.emit( 'goTo', { pathname: '/storage/orders' } )
            return
        }

        this.setState({loading: true})
        this.getOrder('number', this.state.nextItem);

        const path = `/storage/order/${this.state.nextItem}`;
        window.emitter.emit( 'goTo', { pathname: path } );  
    }

    goToPreviousItem()
    {
        setFrom(false)

        if (!this.state.previousItem) {
            window.emitter.emit( 'goTo', { pathname: '/storage/orders' } )
            return
        }

        this.setState({loading: true})
        this.getOrder('number', this.state.previousItem);

        const path = `/storage/order/${this.state.previousItem}`;
        window.emitter.emit( 'goTo', { pathname: path } );
    }

    goBackToBillables()
    {
        setFrom(false)
        setContinueWithCompany(true)
        let date = null

        if (getStartDate() && getEndDate()) {
            date = `${getStartDate()}_${getEndDate()}`
        }

        if (getStartDate() && !getEndDate()) {
            date = `${getStartDate()}_`
        }

        if (!getStartDate() && getEndDate()) {
            date = `_${getEndDate()}`
        }

        let path = `/billable/${getCompanyId()}`;

        if (date) {
            path = `${path}/${date}`
        }
        
        window.emitter.emit( 'goTo', { pathname: path } );
    }

    parseComponents()
    {
        return this.state.components.map( ( c ) => {
            return {
                id: isNaN( parseInt( c.id, 10 ) ) ? null : c.id,
                type_id: c.type_id,
                name: c.name,
                unit: c.unit,
                order_group_id: c.order_group_id,
                save_as_new: c.save_as_new,
                component_id: c.component_id,
                count: c.selectedUnit 
                    ? Number((parseFloat(c.count) / parseFloat(c.selectedUnit.conversion_factor)).toFixed(5))
                    : c.count,
                order_price: c.selectedUnit 
                    ? (parseFloat(c.order_price) * parseFloat(c.selectedUnit.conversion_factor))
                    : c.order_price,
                dispatch_ref: c.dispatch_ref,
                order_from_type: c.order_from_type,
                order_from_id:   c.order_from_id,
                cost_center_id: c.cost_center_id,
                conversion_unit_id: c.selectedUnit ? c.selectedUnit.id : null,
                order_vat: this.state.use_tax_groups ? (c.tax_group ? (c.tax_group.vat ? c.tax_group.vat.value : c.order_vat) : c.order_vat): c.order_vat,
                order_netvisor_vat: this.state.use_tax_groups ? (c.tax_group ? c.tax_group.tax_code : c.order_netvisor_vat) :c.order_netvisor_vat,
                tax_group_id: c.tax_group ? c.tax_group.id : null,
                discount_percent: c.discaunt_percent ? c.discaunt_percent:null,
            }
        });
    }

    downloadFile( file, name, type )
    {
        this.setState({loading: true });
        return api({
            method: 'get',
            url: `storage/order/id/${ this.state.id }/file/${ file }/${ type }`,
            responseType: 'blob',
            errorPopper: tr('file_download_error'),
        }).then(( response ) => {
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${ name }_${ this.state.number }.${ type }` );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    dataChange( data )
    {
        this.setState( data );
    }

    calculateTotalPrice( components )
    {
        let total = 0;
        components.forEach( c => {
            const cPrice = (c.count * c.order_price *(1 - c.discaunt_percent / 100) * (1 + (c.order_vat / 100))) ;
            if( !isNaN( cPrice ) )
                total += cPrice;
        });
        this.setState({ totalPrice: total });
    }

    componentsChange( components, func )
    {
        this.calculateTotalPrice( components );

        let groupIds = components.map( c => c.order_group_id );
        let groups = this.state.groups.slice(0);
        groups = groups.filter( g => groupIds.includes( g.id ));

        this.setState({
            components: components,
            groups: groups,
            edited:true,
        }, func );
    }
    groupsChange( groups )
    {
        this.setState({ groups: groups});
    }

    saveBoxSubmit( action, options )
    {
        const openCommentDialogFor = [
            'save_draft',
            'save',
            'paid',
            'archive',
            'offer',
            'back_to_edit',
            'cancelDeliveries',
        ];

        if( openCommentDialogFor.includes( action ) )
        {
            this.setState({
                commentDialogShow: true,
                commentDialogAcceptor: (action === 'save' || action === 'accept' ),
                commentDialogMeta: options,
            });
        }
        else if( action === 'demand_goto' )
            this.demandGoTo();
        else if( action === 'delivery_goto' )
            this.deliveryGoTo();
        else if( action === 'remove' )
            this.remove();
        else if (action === 'newProject') {
            this.openNewProjectModal();
        }
        else
            console.error( 'saveBoxSubmit unknown action', action );
    }

    commentDialogOnSubmit( action, comment, acceptor )
    {
        this.setState({
            commentDialogShow: false,
            commentDialogMeta: {},
        });

        if(action === 'save')            this.save( false, comment);
        else if(action === 'offer')     this.save( false, comment, true );
        else if(action === 'back_to_edit') this.backToEdit( comment );
        else if(action === 'save_draft') this.save( true, comment);
        else if(action === 'paid')       this.savePaid( comment);
        else if(action === 'archive')       this.archive( comment);
        else if(action === 'cancelDeliveries')       this.cancelDeliveries( comment);
        else console.error('commentDialogOnSubmit unknown action', action);
    }

    createReserve()
    {
        const components = this.parseReserveComponents();

        let data = {
            receiver_name:         this.state.receiverName,
            receiver_address:      this.state.receiverAddress,
            receiver_city:         this.state.receiverCity,
            receiver_zipcode:      this.state.receiverZipcode,
            receiver_country:      this.state.receiverCountry,
            receiver_contact_name: this.state.receiverContactName,

            components: components,
        }

        this.setState({loading: true });

        return api({
            method: 'post',
            url: `storage/order/${ this.state.id }/reserve`,
            data: data,
            errorPopper: tr('save_error'),
        }).then((response) => {
            this.setOrder( response );
            this.setState({loading: false });
        }).catch((error) => {
            this.setState({loading: false });
        });
    }

    openNewProjectModal() {
        let selectedrows = this.componentInstanceTableRef.getSelected();
        let components = this.state.components.filter(comp => selectedrows.includes(comp.id));
        let tree = components.map(comp => {
            let component = {}; 
            component.id = comp.component_id;
            component.name = comp.name;
            component.count = comp.count;
            component.unit = comp.unit;
            return component;
        });
        // console.log(tree);
        this.setState({ showNewProjectModal: true, newProjectTree:tree });
    }

    formatSaveData(offer=false)
    {
        let data = {};

        data.id = this.state.id;
		
		data.company_name = this.state.company_name;
        data.company_address = this.state.company_address;
        data.company_zipcode = this.state.company_zipcode;
        data.company_city = this.state.company_city;
        data.company_country = this.state.company_country;

        data.receiver_name = this.state.receiver_name;
        data.receiver_address = this.state.receiver_address;
        data.receiver_zipcode = this.state.receiver_zipcode;
        data.receiver_city = this.state.receiver_city;
        data.receiver_country = this.state.receiver_country;
        data.receiver_country_code = this.state.receiver_country_code;
        data.receiver_country_code_id = this.state.receiver_country_code_id;
        data.billing_name = this.state.billing_name;
        data.billing_address = this.state.billing_address;
        data.billing_zipcode = this.state.billing_zipcode;
        data.billing_city = this.state.billing_city;
        data.billing_country = this.state.billing_country;
        data.billing_country_code = this.state.billing_country_code;
        data.billing_country_code_id = this.state.billing_country_code_id;
        data.billing_method = this.state.billing_method_id;
        data.project_code = this.state.project ? this.state.project.project_code : null;
        data.project_id = this.state.project ? this.state.project.id : null;

        data.dm_id = this.state.dm_id;
        data.dt_id = this.state.dt_id;
        data.pc_id = this.state.pc_id;

        data.offer = offer;
        data.date = dateInputToSql( this.state.date );
        data.reference_your = this.state.reference_your;
        data.reference_our = this.state.reference_our;
        data.need_date = dateInputToSql(this.state.need_date);
        data.delivery_date = dateInputToSql(this.state.delivery_date);
        data.due_date = dateInputToSql( this.state.due_date );
        data.details = this.state.details;
        data.internal_comment = this.state.internal_comment;
        data.price = this.state.totalPrice;
        data.components = this.parseComponents();
        data.contact_person = this.state.contact_person?this.state.contact_person.id:null;
        data.sended_to_ext_interface = this.state.sended_to_ext_interface;

        data.groups = this.state.groups.map( g => {
            return {
                id: g.id,
                name: g.name,
                count: g.count,
                unit: g.unit,
            }
        });

        return data;

    }

    saveInternalComment()
    {
        this.setState({ loading: true });

        return api({
            method: 'post',
            url: `storage/order/id/${ this.state.id }/internalcomment`,
            data: { internal_comment: this.state.internal_comment },
            errorPopper: tr('save_error'),
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('save_successful') }</strong>,
            });
            this.setState({
                audits: response.audits,
                loading: false,
            })
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    save( draft, comment, offer=false )
    {
        this.setState({ loading: true });

        draft = (draft) ? '_draft' : '';
        let data = this.formatSaveData(offer);
        data.latest_comment = comment;
        //console.log("data",data);

        //urls
        //storage/order/id/{id}/save
        //storage/order/id/{id}/save_draft
        return api({
            method: 'post',
            url: `storage/order/id/${ this.state.id }/save${ draft }`,
            data: data,
            errorPopper: tr('save_error'),
        }).then((response) => {
            //console.log("response", response);
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });
            
            if (response.crm_billing_methods) {
                let crm_billing_methods = [];
                for (let key in response.crm_billing_methods) {
                    crm_billing_methods.push({ id: response.crm_billing_methods[key], name: tr(key) });
                }
                response.crm_billing_methods = crm_billing_methods;
            }

            response.components.map(component => {
                if (component.conversion_unit_id) {
                    component.selectedUnit = component.conversion_units.find(unit => unit.id === component.conversion_unit_id);
                    component.count = Number((parseFloat(component.count) * parseFloat(component.selectedUnit.conversion_factor)).toFixed(5));
                    component.order_price = parseFloat(component.order_price) / parseFloat(component.selectedUnit.conversion_factor);
                }
            });
            
            this.setOrder( response );
            this.setState({
                loading: false,
                ext_interfaces: response.ext_interfaces,
                ext_imports: response.external_imports,
                edited:false,
                });

        }).catch((error) => {

            console.log('Error', error );
            this.setState({ loading: false });
        });
    }

    savePaid( comment )
    {
        this.setState({ loading: true });
        let data = this.formatSaveData()
        data.latest_comment = comment;
        data.paid = !this.state.paid;

        api({
            method: 'post',
            url: `storage/order/id/${ this.state.id }/paid`,
            data: data,
            errorPopper: tr('save_error'),
        }).then((response) => {
            const message = response.paid ? tr('paid') : tr('payment_canceled');
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ message }</strong>,
            });

            response.components.map(component => {
                if (component.conversion_unit_id) {
                    component.selectedUnit = component.conversion_units.find(unit => unit.id === component.conversion_unit_id);
                    component.count = Number((parseFloat(component.count) * parseFloat(component.selectedUnit.conversion_factor)).toFixed(5));
                    component.order_price = parseFloat(component.order_price) / parseFloat(component.selectedUnit.conversion_factor);
                }
            });

            this.setOrder( response );
            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    backToEdit( comment )
    {
        this.setState({ loading: true });
        let data = this.formatSaveData()
        data.latest_comment = comment;
        data.paid = !this.state.paid;

        api({
            method: 'post',
            url: `storage/order/id/${ this.state.id }/backtoedit`,
            data: data,
            errorPopper: 'Tallennuksessa tapahtui virhe',
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>Palattu muokkaamaan</strong>,
            });

            response.components.map(component => {
                if (component.conversion_unit_id) {
                    component.selectedUnit = component.conversion_units.find(unit => unit.id === component.conversion_unit_id);
                    component.count = Number((parseFloat(component.count) * parseFloat(component.selectedUnit.conversion_factor)).toFixed(5));
                    component.order_price = parseFloat(component.order_price) / parseFloat(component.selectedUnit.conversion_factor);
                }
            });

            this.setOrder( response );
            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }
    archive( comment )
    {
        this.setState({ loading: true });
        let data = {
            latest_comment: comment,
            archived: !this.state.archived,
        }

        api({
            method: 'post',
            url: `storage/order/id/${ this.state.id }/archive`,
            data: data,
            errorPopper: tr('save_error'),
        }).then((response) => {
            const message = response.archived ? tr('archived') : tr('archiving_canceled')

            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ message }</strong>,
            });
            this.setOrder( response );
            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    remove( comment = null )
    {
        //console.log('TODO');
        this.setState({ loading: true });

        api({
            method: 'delete',
            url: `storage/order/id/${this.state.id}/delete`,
            data: {latest_comment: comment},
            errorPopper: tr('delete_error'),
        }).then((response) => {
            if(response.removed)
            {
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>{ tr('deleted') }</strong>,
                });
                this.props.history.replace('/storage/orders');
            }
            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    
    cancelDeliveries(comment =null) {
        // const components = response.components;
        const components = this.state.components;
        this.setState({loadingCounter: this.state.loadingCounter + 1});
        let data = {

            related_id: this.state.relatedId,
            related_type: this.state.type,
            comment: this.state.comment,           
            receiver_type: this.state.senderType,
            receiver_id: this.state.senderId,
            sender_type: this.state.receiverType=='project_storage'?'location':this.state.receiverType,
            sender_id: this.state.receiverId,
            components: components,
        };

        api({          
            method: 'post',
            url: `storage/delivery/cancelDeliveries/${ this.state.id }`,
            data: data,
        }).then((response) => {
            this.backToEdit();
           
            this.setState({loadingCounter: this.state.loadingCounter - 1});
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({loadingCounter: this.state.loadingCounter - 1});
        });


    }


    demandGoTo()
    {
        window.emitter.emit('goTo', { pathname: `/storage/purchase/demands/order/${ this.state.id }` });
    }

    deliveryGoTo()
    {
        window.emitter.emit('goTo', { pathname: `/storage/logistic/delivery/order/${ this.state.id }` });
    }

    isLocked()
    {
        return ![ 'open' ].includes( this.state.status_name );
        // return [ 'archived' ].includes( this.state.status_name );  
    }
    isProjectLocked()
    {       
        return [ 'archived' ].includes( this.state.status_name ) ||  this.state.archived;  
    }
    isSelectLocked() {
        return !['open','offer'].includes(this.state.status_name);
    }

    showRelatedEdit( field, title )
    {
        let options = null;
        if (this.isLocked()) return null;
        if (field === "billing_method") {
            options = this.state.crm_billing_methods;
        }

        this.setState({
            relatedEditShow: true,
            relatedEditField: field,
            relatedEditTitle: title,
            relatedEditValue: this.state[ `${ field }_id` ],
            relatedEditOptions: options!==null ?options:this.state[ field ],
            relatedEditRequired: this.state[ `${ field }_required` ],
        })
    }

    renderFieldEdit()
    {
        return <div className="fieldEdit">
            <ApModalInput
                show={ this.state.fieldEditShow }
                title={ this.state.fieldEditTitle }
                value={ this.state.fieldEditValue }
                label={ this.state.fieldEditLabel }
                required={ this.state.fieldEditRequired }
                type={ this.state.fieldEditType }
                onSave={ ( value ) => this.fieldEdit( this.state.fieldEditField, value ) }
                onClose={ () => this.setState({ fieldEditShow: false }) }
            />
        </div>
    }

    fieldEdit( field, value )
    {
        //console.log(this.state);
        
        let days = this.state.pc.find(pc => pc.id === this.state.pc_id);
        days = days ? days.days : null;
        if (field === "date" && this.state.delivery_date == null && days!==null) {
            this.setState({
                [field]: value,
                due_date: moment(value, "DD.MM.YYYY").add(days, "days").format("DD.MM.YYYY"),
                edited: true 
            });
        }
        else if (field === "delivery_date" && days !== null) {
            this.setState({
                [field]: value,
                due_date: moment(value, "DD.MM.YYYY").add(days, "days").format("DD.MM.YYYY"),
                edited: true 
            });
        }
        else {
            this.setState({ [field]: value, edited: true });
        }
        
    }

    showAddressEdit( type )
    {
        if( this.isLocked() ) return null;

        this.setState({
            addressEditShow: true,
            addressEditType: type,
        })
    }

    renderAddressModal()
    {
        return <AddressModal
            show={ this.state.addressEditShow }
            handleClose={ this.addressModalSave }
            options={ this.state.address_options }

            type={ this.state.addressEditType }
            name={ this.state[ `${ this.state.addressEditType}_name` ] }
            address={ this.state[ `${ this.state.addressEditType}_address` ] }
            zipcode={ this.state[ `${ this.state.addressEditType}_zipcode` ] }
            city={ this.state[ `${ this.state.addressEditType}_city` ] }
            country={ this.state[ `${ this.state.addressEditType}_country` ] }

            country_code={ this.state[ `${ this.state.addressEditType}_country_code` ] }
            country_code_id={ this.state[ `${ this.state.addressEditType}_country_code_id` ] }


            // country_code_id={ this.state.receiver_country_code_id }
            // country_code={ this.state.receiver_country_code }
        />
    }

    addressModalSave( data )
    {
        if( data ) this.setState( data );
        this.setState({ addressEditShow: false, edited:true });
    }

    renderRelatedEdit()
    {
        return <RelatedSelectModal
            show={ this.state.relatedEditShow }
            handleClose={ this.relatedEditSave }

            field={ this.state.relatedEditField }
            title={ this.state.relatedEditTitle }
            value={ this.state.relatedEditValue }
            options={ this.state.relatedEditOptions }
            required={ this.state.relatedEditRequired }
        />
    }

    renderCodeEdit() {
        if (this.isLocked() && this.isProjectLocked() ) return null;      
        this.state.orgProject =  typeof  this.state.project!='undefined' ? this.state.project :this.state.orgProject;        
        this.state.project = typeof this.state.project == 'undefined' ? this.state.orgProject : this.state.project;

        return <EditOrderCode
            show={ this.state.projectCodeEditShow }
            handleClose={ this.orderCodeSave }

            field={ 'project' }
            title={ tr('project') }
            value={ this.state.project }
            customer_id={ this.state.related_id }
        />
    }
    renderContactPersonEdit() {
        if (this.isLocked()) return null;

        if (this.state.contact_person != null && this.state.contact_person.fullName == null) {
            let data = this.state.contact_person;
            data.fullName = this.state.contact_person.first_name + " " + this.state.contact_person.last_name;
            this.setState({ contact_person: data});
            
        }
        return <EditOrderContactPerson
            show={this.state.contactPersonEditShow}
            handleClose={this.orderContactPersonSave}

            field={'contact_person'}
            title={tr('contact_person')}
            value={this.state.contact_person}
            customer_id={this.state.related_id}
        />
    }

    orderContactPersonSave(data = null) {
        if (data) {
            this.state.contact_person = data.contact_person;
        }
        //console.log("asd", this.state);
        this.setState({ contactPersonEditShow: false });
    }

    orderCodeSave( data = null )
    {
        if( data ) {
            this.state.project = data.project;
            this.state.project_code = data.proejct ? data.project.project_code : null;
        }       
        if (typeof this.state.project != 'undefined') {   
            this.setState({  edited:true });
        }     

        this.setState({ projectCodeEditShow: false }, () => {
            this.attachToProject();
        });  
    }

    relatedEditSave( data = null )
    {
        if (data) {
            this.setState(data);
            if (data.pc_id) {
                let days = this.state.pc.find(pc => pc.id === data.pc_id);
                days = days ? days.days : null;
                if (days !== null) {
                    let date = this.state.delivery_date || this.state.date;
                    this.setState({
                        due_date: moment(date, "DD.MM.YYYY").add(days, "days").format("DD.MM.YYYY")
                    });
                }
            }
        } 

        //console.log(data);
        this.setState({ relatedEditShow: false, edited: true });
    }

    showEdit( field )
    {
        if( this.isLocked() ) return null;

        const fields = {
            details: { label: tr('additional_info'), title: tr('edit_additional_info'), type: 'textarea', required: true },
            need_date: { label: tr('day_of_need'), title: tr('edit_day_of_need'), type: 'datetime' },
            delivery_date: { label: tr('delivery_date'), title: tr('edit_delivery_date'), type: 'datetime' },
            due_date: { label: tr('due_date'), title: tr('edit_due_date'), type: 'datetime' },
            date: { label: tr('date'), title: tr('edit_date'), type: 'datetime' },
            reference_our: { label: tr('reference_number_our'), title: tr('edit_reference_number_our'), type: 'text' },
            reference_your: { label: tr('reference_number_your'), title: tr('edit_reference_number_your'), type: 'text' },
            supplier_details: { label: tr('supplier_additional_info'), title: tr('edit_supplier_additional_info'), type: 'textarea' },
        };

        this.setState({
            fieldEditShow: true,
            fieldEditField: field,
            fieldEditLabel: fields[ field ].label,
            fieldEditType: fields[ field ].type,
            fieldEditTitle: fields[ field ].title,
            fieldEditValue: this.state[ field ],
            fieldEditRequired: Boolean( fields[ field ].required ),
        });
    }

    sendToExternal(type)
    {
        if (this.state.loading) {
            return
        }

        this.setState({loading: true})
        api({
            method: 'get',
            url: `storage/order/external/${type}/${this.state.id}`,
        }).then((response) => {
            //console.log(response)

            const params = this.props.match.params;
            if( params.type === 'id' )
                this.getOrder('id', params.id );
            else
                this.getOrder('number', params.type);
            
        }).catch((error) => {
            console.log('error', error)
            errorPopper(error);
            this.setState({loading: false})
        })
    }

    renderExternalExport()
    {
        const locked = this.isLocked();
        const editClass = locked ? '' : 'edit';



        const externals = [];
        
        this.state.ext_interfaces.forEach((item) => {
            if (item.type === 'fivaldi_new' && this.state.status_id >= 3) {
                const imported = this.state.ext_imports[item.type]

                let dd = imported ? 
                    <div>Viety</div>
                    : <a style={{cursor: 'pointer'}} onClick={() => this.sendToExternal(item.type)}>
                        <SvgIcon icon="check" type="solid" />
                        Vie Fivaldiin
                    </a>

                if (this.state.loading) dd = <div className="apLoader" />

                externals.push(
                    <dl className="dl-horizontal">
                        <dt>Fivaldi</dt>
                        <dd className={ editClass }>
                            {dd}
                        </dd>
                    </dl>
                )
            }

            if (item.type === 'netvisor' && (this.state.status_id >= 3 && this.state.status_id != 10 )) {
                const imported = this.state.ext_imports[item.type]

                let dd = imported ? 
                    <div>{tr('exported')}</div>
                    : <a style={{cursor: 'pointer'}} onClick={() => this.sendToExternal(item.type)}>
                        <SvgIcon icon="check" type="solid" />
                        {tr('export_to_netvisor')}
                    </a>

                if (this.state.loading) dd = <div className="apLoader" />

                externals.push(
                    <dl className="dl-horizontal">
                        <dt>Netvisor</dt>
                        <dd className={ editClass }>
                            {dd}
                        </dd>
                    </dl>
                )
            }
            
        })

        return externals;
    }

    renderSummary()
    {
        const locked = this.isLocked();
        // const projectlocked = this.isProjectLocked() ;
        

        let numberDom = this.state.number;
        if( !this.state.number )
            numberDom = <ApTooltip text={ tr('next_available_number_order') }>
                <span className="noNumber">{ this.state.next_number }</span>
        </ApTooltip>
		
		let referencenumberDom = this.state.reference_number;
        if( !this.state.number )
            referencenumberDom = <ApTooltip text="Seuraava vapaa viitenumero jota ei ole vielä varattu tilaukselle">
                <span className="noNumber">{ this.state.reference_number }</span>
        </ApTooltip>

        const editClass = locked ? '' : 'edit';
        // const editProjectClass = projectlocked ? '' : 'edit';

        let relatedDoms = [];
        const relatedFields = {
            pc: tr('payment_term'),
            dt: tr('delivery_term'),
            dm: tr('delivery_method'),
        };
        for (let field in relatedFields ) {
            if( !this.state[ `${ field }_enabled` ]) continue;
            if( this.state.type !== 'delivery' && ['dt', 'dm'].includes( field ) ) continue;

            relatedDoms.push( <dt key={ `${ field }-title` } className={ 'pointer' } onClick={() => {this.showRelatedEdit( field, relatedFields[ field ] ) }}>{ relatedFields[ field ] }</dt> );
            relatedDoms.push( <dd key={ `${ field }-value` } className={ editClass } onClick={() => {this.showRelatedEdit( field, relatedFields[ field ] ) }}>
                { this.state[ `${ field }_name` ] }
            </dd> );
        }

        const getEditDd = ( field, fieldName ) => {
            return <>
                    <dt className='pointer' onClick={() => this.showEdit( field ) }>{ tr(fieldName) }</dt>
                    <dd className={ editClass } onClick={() => this.showEdit( field ) }>
                        <span>{ this.state[ field ] }</span>
                    </dd>
                </>
        }

        const editAddress = ( type, field ) => {
            return <dd className={ editClass } onClick={ () => this.showAddressEdit( type ) }>{ this.state[ `${ type }_${ field }` ] }</dd>
        }
      
        const editCountry = (type, field ) => {
            let countryCode =  this.state[ `${ type }_${ field }` ];
            if (countryCode =="") {
                return editAddress( type, field );
            }
            else {
                let country = typeof  countryCode!='undefined' ? tr(countryCode): "";        
                return <dd className={ editClass } onClick={ () => this.showAddressEdit( type ) }>{ country}</dd>
            }
        }
        const editBillingMethod = (type, field) => {
            return <dd className={editClass}
                onClick={() => { this.showRelatedEdit("billing_method", tr('billing_method')) } }>
                {this.state[`billing_method_name`] ? this.state[`billing_method_name`] :tr('not_defined')}</dd>
        }
		
		let deliveryDom = null;
        let needDom = null;
        if( this.state.type === 'delivery' )
        {
            deliveryDom = <dl className="dl-horizontal">
                <dt>{ tr('shipping_address') }</dt>
                <dt></dt>{ editAddress( 'receiver', 'name' ) }
                <dt></dt>{ editAddress( 'receiver', 'address' ) }
                <dt></dt>{ editAddress( 'receiver', 'zipcode' ) }
                <dt></dt>{ editAddress( 'receiver', 'city' ) }
                <dt></dt>{ editCountry( 'receiver', 'country_code' ) }
            </dl>

            needDom = <React.Fragment>
                {getEditDd('need_date', 'day_of_need')}
                {getEditDd('delivery_date', 'delivery_date')}
            </React.Fragment>
        }
		
		const bankAcconuts = JSON.parse(this.state.company_bank_accounts);
		const bankAccontsItems = bankAcconuts.map((account) =>
			<dd>{account.bankname} {account.iban} {account.bic}</dd>
        );

        return <div className="storageSummaryBox">
            <div className="apForm">
                <div className="apFormColumn">
                    <dl className="dl-horizontal">
                        <dt className={ this.state.number ? '' : 'noNumber' }>{ tr('number') }</dt>
                        <dd>{ numberDom }</dd>
                        

                        <dt>{ tr('creator') }</dt>
                        <dd> { this.state.creator_name } </dd>
                    </dl>
					
					<dl className="dl-horizontal">
                        <dt>{tr("biller_address")}</dt>
                        <dt></dt>{ editAddress( 'company', 'name' ) }
                        <dt></dt>{ editAddress( 'company', 'address' ) }
                        <dt></dt>{ editAddress( 'company', 'zipcode' ) }
                        <dt></dt>{ editAddress( 'company', 'city' ) }
                        <dt></dt>{ editAddress( 'company', 'country' ) }
                    </dl>

                    <dl className="dl-horizontal pointer" onClick={() => {
                        if (this.state.related_type === 'company') {
                            window.emitter.emit('goTo', {pathname: `/crm/company/${this.state.related_id}`});
                        }
                    }}>
                        <dt>{ tr('orderer') }</dt>
                        <dd>
                            { Boolean( this.state.related_type ) && <ApTooltip text={ getTypeName( this.state.related_type ) }>
                                <SvgIcon icon={ getTypeIcon( this.state.related_type ) } type="solid" />
                            </ApTooltip> }
                            <ApTooltip inline text={this.state.related_type === 'company' ? tr('go_to_customer') : 'a'}>
                            { this.state.related_title }
                            </ApTooltip>
                        </dd>

                    </dl>
                    <dl className="dl-horizontal">
                        <dt className={this.state.billing_method_name ? '' : 'noNumber'}>{tr('billing_method')}</dt>
                        <dt></dt>{editBillingMethod("billing", "method")}
                    </dl>

                    <dl className="dl-horizontal">                       
                        <dt>{ tr('billing_address') }</dt>
                        <dt></dt>{ editAddress( 'billing', 'name' ) }
                        <dt></dt>{ editAddress( 'billing', 'address' ) }
                        <dt></dt>{ editAddress( 'billing', 'zipcode' ) }
                        <dt></dt>{ editAddress( 'billing', 'city' ) }
                        <dt></dt>{ editCountry( 'billing', 'country_code' ) }
                                      
                    </dl>

                    {deliveryDom}
                    <dl className="dl-horizontal">
                        <dt>{tr('contact_person')}</dt>

                        {this.state.contact_person != null ?
                            <dd className={editClass} onClick={() => this.setState({ contactPersonEditShow: true })}>
                                <div>
                                    {this.state.contact_person.first_name + " " + this.state.contact_person.last_name}
                                </div>
                                <div>
                                    {this.state.contact_person.phone1}
                                </div>
                                <div>
                                    {this.state.contact_person.email}
                                </div>
                            </dd>
                            : <dd className={editClass}><a
                                onClick={() => this.setState({ contactPersonEditShow: true })}>
                                {tr('add_contact_person')}
                            </a></dd>
                        }
                    </dl>

                </div>
                <div className="apFormColumn">
                    <dl className="dl-horizontal">
                        { getEditDd( 'date', 'date' ) }

                        <dt>{ tr('price_total') }</dt>
                        <dd> { formatMoney( this.state.totalPrice ) } { this.state.currencySign }</dd>
                    </dl>

                    <dl className="dl-horizontal">

                        { relatedDoms }
                        <dt className={ this.state.reference_number ? '' : 'noNumber' }>{ tr('reference_number') }</dt>
                        <dd>{ this.state.reference_number }</dd>

                        { getEditDd( 'reference_your', 'reference_number_your' ) }

                        { getEditDd( 'reference_our', 'reference_number_our' ) }

                        { needDom }

                        { getEditDd( 'due_date', 'due_date' ) }

                    </dl>
                    <dl className="dl-horizontal">
                        {getEditDd('details', 'additional_info')}
                        {/* <dt>{ tr('additional_info') }</dt>

                        <dd className={ editClass } onClick={() => this.showEdit('details') }><div className="pre-wrap">{ this.state.details }</div></dd> */}
                    </dl>

                    <dl className="dl-horizontal">
                    
                        { editClass != ""  && this.state.project_count> 0  ? 
                            <dt
                                className={ editClass }>
                                <a style={{cursor: 'pointer'}}
                                    onClick={() => this.setState({projectCodeEditShow: true})}>
                                        <ApTooltip inline text={tr('link_to_project')}>
                                    <SvgIcon icon="edit" type="solid" />        
                                    </ApTooltip>  
                                    { tr('project') }                
                                </a>      
                            </dt>    
                            :                         
                            <dt>
                                { tr('project') }
                            </dt>
                        }
                      

                        {this.state.project != null ? 
                        <dd className={ "pointer " + editClass } onClick={() => this.redirectToProject() }>
                            <div>
                                <ApTooltip inline text={tr('go_to_project')}>
                                    { `${this.state.project ? `${this.state.project.project_code} - ${this.state.project.name}`  : ''}` }
                                </ApTooltip>
                            </div>
                        </dd>
                        : <dd className={ editClass }>
                             {/* <SvgIcon icon="check" type="solid" /> */}
                            {/* <a 
                            style={{cursor: 'pointer'}}
                            onClick={() => this.setState({projectCodeEditShow: true})}>
                            <SvgIcon icon="check" type="solid" />
                            { tr('link_to_project') }
                        </a> */}
                        </dd>
                        }
                    </dl>

                    {/* <dl className="dl-horizontal">
                        <dt>                          
                            {this.getFileButton()}
                        </dt>
                    </dl> */}

                    {this.renderExternalExport()}
                </div>
            </div>
			{/*<dl className="dl-horizontal">
				<dt>Pankkiyhteydet</dt>
				{ bankAccontsItems }
                    </dl>*/}
        </div>

    }

    redirectToProject() {   
        //Ohjaa projektin sivulle
        const projectId = this.state.project.id;
        window.emitter.emit('goTo', {pathname: `../../../projects/${projectId}/management/sell`});
    }

    toggleProject() {
       
    }

    attachToProject(data) {
        const project = data ? data.project : this.state.project;
        console.log(data);
        console.log(project);
        if (!project) return;
        api({
            method: 'get',
            url: `storage/order/projects/id/${project.id}`,
            errorPopper: tr('get_error'),
        }).then((response) => {
            /*
            if (response.cost_center_id && (this.state.project == null || this.state.project == 0)) {
                let components = this.state.components;
                for (let component of components) {
                    component.cost_center_id = response.cost_center_id;
                }
                this.setState({
                    project: response,
                    components: components,
                });
            }
            else {
                this.setState({
                    project: response,
                });
            }*/
            this.setState({
                project: response,
            });
            
            
        }).catch((error) => {
            console.warn(`Ongelma esiintyi: ${error}`);
        });
    }

    renderInternalComment()
    {
        return <div className="commentContainer">
            <ApInput
                type="textarea"
                id="comment"
                name="comment"
                label={ tr('internal_comment') }
                autoComplete="off"
                value={ this.state.internal_comment ? this.state.internal_comment : '' }
                onChange={  ( e ) => this.setState( { internal_comment: e.target.value } ) }
                loading={ this.state.loading }
            />
            <div className="internalCommentSave" onClick={ this.saveInternalComment } >
                <ApTooltip text={ tr('save_internal_comment') } block>
                    <SvgIcon icon="save" type="solid" fill="white" />
                </ApTooltip>
            </div>
        </div>
    }

    renderVatTable()
    {
        let vats = {};

        this.state.components.forEach( c => {
            let vat = c.order_vat;
            if( !vat ) return null;
            if( !vats[ vat ] ) vats[ vat ] = 0 ;

            if( parseFloat( c.order_price ) &&  parseFloat( c.count ) )
                vats[vat] += parseFloat(c.order_price) * parseFloat(c.count) *  (1 - c.discaunt_percent / 100) ;
        });

        let rows = [];
        for (let vat in vats )
        {
            const total = vats[ vat ];
            const vatValue = total * (vat / 100 );
            const totalWithVat = total + vatValue
            rows.push(<tr key={ vat }>
                <td>{ vat } %</td>
                <td>{ formatMoney( total ) } { this.state.currencySign }</td>
                <td>{ formatMoney( vatValue ) } { this.state.currencySign }</td>
                <td>{ formatMoney( totalWithVat ) } { this.state.currencySign }</td>
            </tr>)
        }

        if( !rows.length ) return null;


        return <div>
            <table className="vatTable">
                <thead>
                    <tr>
                        <th>{ `${tr('vat')} %` }</th>
                        <th>{ tr('reason') }</th>
                        <th>{ tr('tax') }</th>
                        <th>{ tr('in_total') }</th>
                    </tr>
                </thead>
                <tbody>
                    { rows }
                </tbody>
            </table>
        </div>
    }


    renderTotal()
    {
        let rows = this.state.groups.length;
        rows += this.state.components.filter( c => !c.order_group_id ).length;

        return <div>
            { tr('price_total') }: { formatMoney( this.state.totalPrice ) } { this.state.currencySign }<br />
            { tr('rows_count') }: { rows }<br />
            { tr('storage_component_count') }: { this.state.components.length }
        </div>
    }

    renderFooter()
    {
        const validatorMessage = this.validator.getStatusAll();

        return <div className="saveBar">
            <div className="left">
                { this.renderTotal() }
            </div>
            <div className="right">
            <div style={{display: 'inline-flex'}}>
          
            {this.getFileButton()}
                <SaveBox
                    loading={ this.state.loading }
                    number={ this.state.number }
                    paid={ this.state.paid }
                    archived={ this.state.archived }
                    status={ this.state.status_name }
                    onSubmit={ this.saveBoxSubmit }
                    validatorMessage={validatorMessage}
                    edited={this.state.edited}
                    sendedToExt={this.state.sended_to_ext_interface}
                    
                />

             
            </div>
            </div>
          
        </div>
    }

    handleUnitSelect(c, unitId) {
        const components = this.state.components.slice();
        const foundComponent = components.find(component => component.id == c.id);
        if (foundComponent) {
            let prevConversionFactor = null;
            if (foundComponent.selectedUnit) {
                prevConversionFactor = foundComponent.selectedUnit.conversion_factor;
            }
            foundComponent.selectedUnit = foundComponent.conversion_units.find(item => item.id == unitId);
            if (prevConversionFactor) {
                foundComponent.count = foundComponent.count 
                    ? Number((parseFloat(foundComponent.count) / parseFloat(prevConversionFactor)).toFixed(5))
                    : '';
                foundComponent.order_price = foundComponent.order_price 
                    ? parseFloat(foundComponent.order_price) * parseFloat(prevConversionFactor)
                    : '';
            }
            if (foundComponent.selectedUnit) {
                foundComponent.count = foundComponent.count 
                    ? Number((parseFloat(foundComponent.count) * parseFloat(foundComponent.selectedUnit.conversion_factor)).toFixed(5))
                    : '';
                foundComponent.order_price = foundComponent.order_price 
                    ? parseFloat(foundComponent.order_price) / parseFloat(foundComponent.selectedUnit.conversion_factor)
                    : '';
            }
            this.setState({components});
        }
    }
    onNewProjectSave(project) {
        if (project.offer) {
            this.openProject(project.id, 'offer')
        }
        else {
            this.openProject(project.id, 'management')
        }
    }
    openProject(id, page) {
        this.props.history.push(`/projects/${id}/${page}`);
    }

    renderNewProject() {
        return <NewProject
            show={this.state.showNewProjectModal}
            onClose={() => this.setState({ showNewProjectModal: false })}
            onSave={this.onNewProjectSave}
            placeholders={{
                customer: {
                    id: this.state.related_id,
                },
                tree: this.state.newProjectTree,
            }}
            // copyProjectId={this.state.copyProjectId}
            // copyProject={this.state.copyProject}

        />
    }

    render()
    {
        //console.log("state", this.state);
        // console.log("apOrdersReactTableSettings", this.props.apOrdersReactTableSettings);
        if( this.state.error )
            return <div  className="apErrorMsg">{ this.state.error }</div>

        let locked = this.isLocked();
        let selectLocked = this.isSelectLocked();

        let downloadDom = null;
        if (true)// if (this.state.status_name !== 'open')
        {
            const getButton = ( file, title, type ) => {
                const icon = ( type === 'docx' ) ? 'file-word' : 'file-pdf';
                return <ApButton color="white"
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    onClick={ () => this.downloadFile( file, title, type ) }
                    size="tiny"
                >
                    <SvgIcon icon={ icon } type="solid" />
                </ApButton>
            }

            let files = null;

            if (this.state.type === 'no_delivery' && this.state.status_name  === 'offer' )
            {
                files = <React.Fragment>
                    <tr>
                        <td>{tr("download_order_confirmation_or_offer")}</td>
                        <td> { getButton( 'o', 'Tilausvahvistus', 'pdf' ) } </td>
                        <td> { getButton( 'o', 'Tilausvahvistus', 'docx' ) } </td>
                    </tr>
                </React.Fragment>
            }
            else if (this.state.type === 'no_delivery' && this.state.status_name === 'open') {
                files = <React.Fragment>
                    <tr>
                        <td>{tr('preview')}</td>
                        <td> {getButton('bill', tr('preview'), 'pdf')} </td>
                        <td> {getButton('bill', tr('preview'), 'docx')} </td>
                    </tr>
                </React.Fragment>
            }
            else if (this.state.type === 'no_delivery' && this.state.status_name !== 'open')
            {
                files = <React.Fragment>
                    <tr>
                        <td>{ tr('download_invoice') }</td>
                        <td> { getButton( 'bill', tr('invoice'), 'pdf' ) } </td>
                        <td> { getButton( 'bill', tr('invoice'), 'docx' ) } </td>
                    </tr>
                </React.Fragment>
            }
            else if (this.state.type === 'delivery' && this.state.status_name !== 'open' )
            {
                files = <React.Fragment>
                    <tr>
                        <td>{ tr('download_invoice') }</td>
                        <td> { getButton( 'bill', tr('invoice'), 'pdf' ) } </td>
                        <td> { getButton( 'bill', tr('invoice'), 'docx' ) } </td>
                    </tr><tr>
                        <td>{ tr('download_order_confirmation') }</td>
                        <td> { getButton( 'o', tr('order_confirmation'), 'pdf' ) } </td>
                        <td> { getButton( 'o', tr('order_confirmation'), 'docx' ) } </td>
                    </tr><tr>
                        <td>{ tr('download_collection_list') }</td>
                        <td> { getButton( 'cl', tr('collection_list'), 'pdf' ) } </td>
                        <td> { getButton( 'cl', tr('collection_list'), 'docx' ) } </td>
                    </tr><tr>
                        <td>{ tr('download_referral') }</td>
                        <td>{getButton('dispatch_note', tr('referral'), 'pdf')}</td>
                        <td>{getButton('dispatch_note', tr('referral'), 'docx')}</td>
                    </tr>
                </React.Fragment>
            }

            downloadDom = <div className="filesContainer">
                <table><tbody>{ files }</tbody></table>
            </div>
        }



        return <div  id="pageOrderEdit">
					<div className="pageBackButton" onClick={ () => window.emitter.emit( 'goTo', { pathname: '/storage/orders' } ) }>
                            <SvgIcon icon="arrow-left" type="solid" size="small" />
                            { tr('all_orders') }
                        </div>
                        
                        {getFrom() ? <div><br />
                        <div className="pageBackButton" onClick={this.goBackToBillables}>
                            <SvgIcon icon="arrow-left" type="solid" size="small" />
                            { tr('continue_with_same_customer') }
                        </div></div> : null}
            <ApFormPage
                loading={ this.state.loading }
                unsaved={ this.state.unsaved }
                customFooter={ () => this.renderFooter() }
            >
				<div className="apBoxHeader">
                    <h1>
                       { tr('order') }
                    </h1>
                    {this.state.loading ? <p>{ tr('loading') }</p> : <p>{ tr('manage_order') }</p>}
                    <div className='edit-po-navigation-buttons'>
                        {this.state.previousItem ? 
                            <ApButton onClick={this.goToPreviousItem} color='blue' className='previous-button'>
                                <SvgIcon icon="arrow-left" type="solid" size="small" />
                                { tr('previous') }
                            </ApButton> : null}
                        {this.state.nextItem ? 
                            <ApButton onClick={this.goToNextItem} color='blue' className='next-button'>
                                { tr('next') }
                                <SvgIcon icon="arrow-right" type="solid" size="small" />
                            </ApButton>: null
                        }
                    </div>
					</div>
                <div className="padding">
                    <div style={{overflowX: 'auto'}}>
                    <div style={{minWidth: 950}}>
                    <Steps
                        type={ this.state.type }
                        status={ this.state.status_name }
                        deliveries={ this.state.deliveries }
                        paid={ this.state.paid }
                        archived={ this.state.archived }
                    />
                    </div>
                    </div>

                    <div style={{overflowX: 'auto'}}>
                    <div className="storageSummaryAuditContainer">
                        <div className="summaryBlock">
                            { this.renderSummary() }
                        </div>
                        <div className="auditBlock">
                            <Audits audits={ this.state.audits } />
                        </div>
                    </div>
                    </div>

                    <div style={{overflowX: 'hidden'}}>
                    <div className="commentFilesContainer">
                        { this.renderInternalComment() }
                        { downloadDom }
                    </div>
                    </div>

                    <ComponentInstanceTable
                        componentsChange={ this.componentsChange }
                        components={ this.state.components }

                        groupsChange={ this.groupsChange }
                        groups={ this.state.groups }

                        instance={ 'order' }

                        nameLocked={ locked }
                        orderReserveFromLocked={ locked }
                        orderCountLocked={ locked }
                        orderPriceLocked={locked}
                        selectLocked={selectLocked}

                        removeComponentLocked={ locked }
                        addComponentLocked={ locked }

                        vats={this.state.vats}
                        TaxCodes={this.state.tax_codes}
                        defaultCrmTax={this.state.default_crm_tax}
                        defaultCrmTaxCode={this.state.default_crm_tax_code}

                        defaultItemTaxGroup={this.state.default_item_tax_group}
                        defaultExpenceTaxGroup={this.state.default_expence_tax_group}
                        defaultWorkTaxGroup={this.state.default_work_tax_group}

                        loading={ this.state.loading }
                        costCenters={this.state.cost_centers}
                        defaultCostCenters={this.state.project 
                            ? this.state.project.cost_center_id 
                            : this.state.default_cost_center_id 
                                ? this.state.default_cost_center_id 
                                : null}
                        costCenterEnabled={this.state.cost_center_enabled}
                        costCenterIsRequired={this.state.cost_center_is_required}
                        orderType={ this.state.type }
                        handleUnitSelect={this.handleUnitSelect}
                        useTaxGroups={this.state.use_tax_groups}
                        crmId={this.state.related_id}
                        editableName={true}
                        ref={ref => this.componentInstanceTableRef = ref}
                        externalInterfaces={this.state.externalInterfaces}
                    />
                    { this.renderVatTable() }
                </div>
            </ApFormPage>
            <CommentDialog
                show={ this.state.commentDialogShow }
                meta={this.state.commentDialogMeta}
                dates={{
                    due_date: this.state.due_date,
                    date: this.state.date,
                }}
                askDates={moment().add(4, "days") < moment(this.state.date, "DD.MM.YYYY")
                    || moment().add(-3, "days") > moment(this.state.date, "DD.MM.YYYY")}
                onClose={ () => this.setState({ commentDialogShow: false }) }
                onSave={ this.commentDialogOnSubmit }
            />
            { this.renderFieldEdit() }
            { this.renderRelatedEdit() }
            {this.renderCodeEdit()}
            {this.renderContactPersonEdit()}
            {this.renderAddressModal()}
            {this.renderNewProject()}

            <Files
                    show={this.state.filesModalShow}
                    onClose={(count) => { this.setState({ filesModalShow: false }) ; this.updateFilesCount(count) }}                  
                    locked={this.props.locked}
                    class={"Orders"}              
                    caption = {tr("order_files")} 
                    id = {this.state.id} 
                    saveUrl = {'storage/order/saveRelatedFiles'}
                    getUrl = {'storage/order/getAllFiles'}  
                    info ={this.fileExtText() }
                    allowed = {["pdf", "doc", "xls", "xlsx", "tif" , "jpg" , "gif", "txt", "xsl", "html", "csv"]}
                />
        </div>
    }

    fileExtText() {  
        let ret="";      
        this.state.ext_interfaces.forEach((item)  => {
            if (item.type === 'netvisor')  {
                ret =  tr('netVisor_file');
            }
        });
       
        return ret;
       
    }

    updateFilesCount(count) {
        // alert("WW" + count);
        this.setState({ files_count: count  });
       
    }

        
    getFileButton() {
        if (this.props.loading) return null;       
       
        let filesCountEl = null;
        if (this.state?.files_count) {
            let badgeClasses = [ "apBadge", "apBadgeCorner" ];            
            filesCountEl = <span className={ badgeClasses.join(" ") }>{ this.state.files_count }</span>
        }
        return <div className="fileButtonContainer" onClick={() => this.setState({ filesModalShow: true, info: this.fileExtText() })}>
            <ApTooltip text={tr('files')}>
                <ApButton className="fileButton" color="white">
                    <SvgIcon icon="file-alt" type="solid" /> {filesCountEl}
                </ApButton>
            </ApTooltip>
        </div>
    }
    
}


const mapStateToProps = (state) => ({
    apOrderSettings: state.apOrderSettings,
    apReackTable: state.apReackTable['Orders'] || state.apReackTable["defaults"],

})

export default connect(mapStateToProps)(OrderEdit);