/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import './ApModalInput.css';

import ApModal from 'common/ApModal/ApModal.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect.js';
import ApSelect from 'common/ApSelect/ApSelect.js';

import { validateDate, tr, dateInputToSql, sqlToDateInput } from 'services/Helpers/Helpers.js';
import ApSwitch from 'common/ApSwitch/ApSwitch';
/*
 *  Basic example:
 *  
 *    <ApModalInput 
 *        show={ this.state.showing }
 *        value={ this.state.valueNow }
 *        onSave={ ( value ) => this.saveValue( value ) }
 *        onCancel={ () => console.log('no change') }
 *        onClose={ () => this.setState({ showing: false }) }
 *        title="Popup title here"
 *        label="Input label here"
 *        info="Info text here (will be shown bellow input field)"
 *        required
 *    />
 *
 *  Available props:
 *    show:     is popup modal showing (boolean)
 *    value:    default input value when popup is opened (string)
 *    onSave:   function that triggers when save is clicked (or enter key pressed). 
 *    onCancel: function that triggers when cancel is clicked (or esc key pressed or clicked outside the popup). 
 *    onClose:  function that triggers when popup closes (anyway after onSave or onCancel)
 *    title:    if available, shows title text at the top of the popup box
 *    label:    if available, shows label text inside input element
 *    info:     if available, shows info text bellow input element (with info icon)
 *    required: if true, save button (and function) is disabled when input is empty
 *
 *
 *  If you need multiple inputs, you can pass array to value prop. Then onSave function
 *  will return array of values (in same order). Remember that also label and
 *  required props should to be arrays if used!
 *
 *  Multi input example: 
 *
 *     <ApModalInput 
 *        show={ this.state.showing }
 *        value={[ 
 *            "Value A",
 *            "Value B",
 *            "Value C",
 *        ]}
 *        label={[ 
 *            "First (*)",
 *            "Second",
 *            "Third",
 *        ]}
 *        required={[
 *            true,
 *            false,
 *            false,
 *        ]}
 *        onSave={ ( values ) => this.save( values[0], values[1], values[2] ) }
 *        onClose={ () => this.setState({ showing: false }) }
 *    />
 *
 */

class ApModalInput extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            value: ( this.props.value ? this.props.value : '' )
        };
        autoBind(this); 
    } 

    UNSAFE_componentWillReceiveProps( nextProps )
    {    
        // Each time popup opened    
        if( nextProps.show && !this.props.show )
        {
            this.setState({ value: ( nextProps.value ? nextProps.value : '' ) });

            if( this.input )
                this.input.select();
        }
    }

    close()
    {
        if( typeof( this.props.onCancel ) == "function" )
            this.props.onCancel();

        if( typeof( this.props.onClose ) == "function" )
            this.props.onClose();
    }
    
    save()
    {
        if( !this.isValid() )
            return false;

        if( typeof( this.props.onSave ) == "function" )
            this.props.onSave( this.state.value );

        if( typeof( this.props.onClose ) == "function" )
            this.props.onClose();
    }

    handleKeyUp( e )
    {
        if( e.key == 'Enter' )
            this.save();

        else if ( e.key == 'Escape' )
            this.close();
    }

    handleChange( newValue, index )
    {

        let value = newValue;

        if( Array.isArray( this.state.value ) )
        {
            value = this.state.value.slice();
            value[ index ] = newValue;
        }

        this.setState({ value });
    }

    validateDate( value, index )
    {
        if( Array.isArray( this.props.required ) )
        {
            if( this.props.required[ index ] )
                return validateDate( value );
        }
        else if( this.props.required )
        {
            return validateDate( value );
        }

        if( !value || value.length == 0 ) return true;
        return validateDate( value );
    }

    isValid()
    {
        const value = this.state.value;
        const type = this.props.type;

        if( Array.isArray( type ) )
        {
            for( let i = 0; i < type.length; i++ )
                if( type[i] === 'datetime' )
                    if( !this.validateDate( value[i], i ) )
                        return false;
        }
        else if( this.props.type === 'datetime' )
            return this.validateDate( value );
        else if (type === 'costCenter' && this.state.costCenterError)
            return false;

        if( !this.props.required )
            return true;

        if( Array.isArray( value ) )
        {
            for( let i = 0; i < value.length; i++ )
                if( Array.isArray( this.props.required ) && this.props.required[i] && value[i].length == 0 )
                    return false;
        }
        else if( !value )
        {
            return false;
        }

        return true;
    }
    renderLabel() {
        return <span>{tr('cost_center')}</span>
    };

    renderLabelValue(code) {
        return code.full_name;
        if (!this.currentlyInputingTail()) return code.code + ": " + code.name;
        const separator = this.props.codeSeparator ? this.props.codeSeparator : '';
        const tail = this.props.tail ? this.props.tail : ''
        return `${code.code}${separator}${tail}`;
    };
    currentlyInputingTail() {
        // When code format is free, we only input the tail
        if (this.props.codeLimit && this.props.codeLimit.is_free)
            return true;
        if (!this.props.code) return false;
        return (this.props.code.ancestors.length + 1 === this.props.codeLevelCount);
    }

    renderInput( index )
    {
        const value = ( Array.isArray( this.state.value ) ? this.state.value[ index ] : this.state.value );
        const label = ( Array.isArray( this.props.label ) ? this.props.label[ index ] : this.props.label );
        const labelDescription = ( Array.isArray( this.props.labelDescription ) ? this.props.labelDescription[ index ] : this.props.labelDescription );
        const type = (Array.isArray(this.props.type) ? this.props.type[index] : this.props.type);
        const disabled = (Array.isArray(this.props.disabled) ? this.props.disabled[index] : this.props.disabled);
        const step = (Array.isArray(this.props.step) ? this.props.step[index] : this.props.disabled);

        if( type === 'textarea' )
        {
            return <ApInput 
                key={ index }
                type="textarea"
                id="apModalInput" 
                name="apModalInput" 
                label={ label }
                value={ value }
                rows={10}
                disabled={disabled}

                onChange={ (e) => this.handleChange( e.target.value, index ) }
                inputRef={ index == 0 ? node => this.input = node : undefined } 
            />
        }
        if( type === 'datetime' )
        {
            return <ApInput 
                key={ index }
                type="date" 
                id="apModalInput" 
                name="apModalInput" 
                label={ label }
                value={ value }
                onChange={ (e) => this.handleChange( e.target.value, index ) }
                onKeyUp={ this.handleKeyUp }
                inputRef={index == 0 ? node => this.input = node : undefined} 
                disabled={disabled}
            />
        }
        
        if( type === 'datetimeV2' || type === 'datetimeV2ToSql' )
        {
            return <ApInput 
                key={ index }
                type="datetimeV2" 
                id="apModalInput" 
                name="apModalInput" 
                label={ label }
                value={ type === 'datetimeV2ToSql' ? dateInputToSql(value) : value }
                onChange={ (val) => this.handleChange( type === 'datetimeV2ToSql' ? sqlToDateInput(val) : val, index ) }
                onKeyUp={ this.handleKeyUp }
                inputRef={index == 0 ? node => this.input = node : undefined} 
                disabled={disabled}
                clearable
            />
        }
        if( type === "select" && this.props.options )
        {
            return <ApInput 
                key={ index }
                type="select" 
                id="apModalInput" 
                name="apModalInput"
                value={ value }
                options={ this.props.options }
                onChange={ (e) => this.handleChange( e.target.value, index ) }
                onKeyUp={this.handleKeyUp}
                disabled={disabled}
            />
        }
        
        if (type === "costCenter") {
            return <ApNestedSelect
                key={index}
                label={this.renderLabel}
                valueRenderer={this.renderLabelValue}
                value={value}
                parentRenderer="value"
                parentTooltipRenderer="name"
                optionRenderer={(item) => {
                    return <div className="codeOption">
                        <span className="name">{item.nameTree}</span>
                        <span className="code">{item.code}</span>
                    </div>
                }}
                onChange={(e) => { this.handleChange(e, index) }}
                apiUrl="costCenter/search"
                loading={this.props.loading}
                disabled={this.props.loading || this.props.skipped || disabled}
                validationState={false}
                acceptOnlyLastLevel={true}
                isEndReached={endReached => this.setState({ costCenterError: !endReached })}
            //tailInput={tailInput}
            />
        }
        if (type === "element" && this.props.element!==null) {
            return this.props.element;
        }
        if (type === "selectUser") {
            return <ApSelect
                key={index}
                value={value ? value.name : ""}
                label={label}
                optionRenderer="user"
                onChange={(e) => { this.handleChange(e, index) }}
                objKeyId="id"
                clearable
                loading={this.props.loading}
                disabled={this.props.loading || this.props.skipped || disabled}
                filterNonActives={true}
                apiUrl="search/user"
                apiData={this.props.filteredIDs}
            />;
        }
        if (type === "selectUsers") {
            return <ApSelect
                    key={index}
                    value={value ? value : []}
                    valueRenderer="user"
                    optionRenderer="user"
                    multiselect
                    label={label}
                    onChange={(e) => { this.handleChange(e, index) }}
                    objKeyId="id"
                    objKeySearchable="name"
                
                    clearable
                    loading={this.props.loading}
                    disabled={this.props.loading || this.props.skipped || disabled}
                    readOnly={disabled}
                    filterNonActives={true}
                    apiUrl="search/user"
                    apiData={this.props.filteredIDs}
                />
        }
        if (type === "selectUsersAndSelectModifiers") {
            let usersWhoCanModify = value ? value[0] : [];
            return <div>
                <ApSelect
                    key={index+"a"}
                    value={value ? value[0] : []}
                    valueRenderer="user"
                    optionRenderer="user"
                    multiselect
                    label={label[0]}
                    onChange={(e) => { this.handleChange([e, value[1]], index) }}
                    objKeyId="id"
                    objKeySearchable="name"

                    clearable
                    loading={this.props.loading}
                    disabled={this.props.loading || this.props.skipped || disabled[0]}
                    readOnly={disabled[0]}
                    filterNonActives={true}
                    apiUrl="search/user"
                    apiData={this.props.filteredIDs}
                />
                <ApSelect
                    key={index+"b"}
                    value={value ? value[1] : []}
                    valueRenderer="user"
                    optionRenderer="user"
                    multiselect
                    label={label[1]}
                    onChange={(e) => { this.handleChange([value[0],e], index) }}
                    objKeyId="id"
                    objKeySearchable="name"

                    clearable
                    loading={this.props.loading}
                    disabled={this.props.loading || this.props.skipped || disabled[1]}
                    readOnly={disabled[1]}
                    options={value ? value[0] : []}
                    //filterNonActives={true}
                    //apiUrl="search/user"
                    //apiData={this.props.filteredIDs}
                />
            </div>
        }
        if (type === "text") {
            return <ApInput
                key={index}
                type="text"
                id="apModalInput"
                name="apModalInput"
                label={label}
                value={value}
                onChange={(e) => this.handleChange(e.target.value, index)}
                onKeyUp={this.handleKeyUp}
                inputRef={index == 0 ? node => this.input = node : undefined}
                disabled={disabled}
            />
        }
        
        if (type === "number") {
            return <ApInput
                key={index}
                type="number"
                id="apModalInput"
                name="apModalInput"
                label={label}
                value={value}
                onChange={(e) => this.handleChange(e.target.value, index)}
                onKeyUp={this.handleKeyUp}
                inputRef={index == 0 ? node => this.input = node : undefined}
                disabled={disabled}
                step={step ? step : 1}
            />
        }

        if (type === "selectStock") {
            return <ApSelect
                key={index}
                value={value ? value.name : ""}
                label={label}
                optionRenderer="component"
                onChange={(e) => { this.handleChange(e, index) }}
                objKeyId="id"
                clearable
                loading={this.props.loading}
                disabled={this.props.loading || this.props.skipped || disabled}
                apiUrl="woocommerce/search/stocks"
            />;
        }

        if (type === 'switch') {
            return (
                <div className='apSwitchBlock'>
                    <ApSwitch
                        key={index}
                        id={`modal-switch-${index}`}
                        label={label}
                        on={value}
                        onChange={(e) => { this.handleChange(!Boolean(value), index) }}
                        disabled={disabled}
                    />
                    <label htmlFor={`modal-switch-${index}`} style={{paddingRight: '60px'}}>
                        {label}<br />
                        <small>{labelDescription ? labelDescription : ''}</small>
                    </label>
                </div>
            )
        }
        

        return <ApInput 
            key={ index }
            type="text" 
            id="apModalInput" 
            name="apModalInput" 
            label={ label }
            value={ value }
            onChange={ (e) => this.handleChange( e.target.value, index ) }
            onKeyUp={ this.handleKeyUp }
            inputRef={index == 0 ? node => this.input = node : undefined} 
            disabled={disabled}
        />
    }

    render()
    {
        let header = null;
        if( this.props.title )
        {
            header = <div className="header">
                <h4>{ this.props.title }</h4>
            </div>
        }

        const inputs = ( Array.isArray( this.props.value ) ? this.props.value : [ this.props.value ] );

        let closeFromBg = true;
        if (Array.isArray(this.props.type) && (this.props.type.includes('datetimeV2') || this.props.type.includes('datetimeV2ToSql')))
            closeFromBg = false;
        if (!Array.isArray(this.props.type) && (this.props.type === 'datetimeV2' || this.props.type === 'datetimeV2ToSql'))
            closeFromBg = false;

        return (
            <ApModal
                className="apInputModal"
                show={ this.props.show }
                handleClose={ this.close }
                hideCross
                closeFromBg={closeFromBg}
                header={ header }
                body={ 
                    <div className="padding">
                        
                        { inputs.map( (input, index ) =>
                            this.renderInput( index )
                        )}

                        { this.props.info && 
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { this.props.info }
                            </div>
                        }

                    </div>
                }
                footer={
                    <div className="footer">

                        <ApButton onClick={ this.close }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton color={this.props.doesSave ? 'blue' : 'green'} onClick={ this.save } disabled={ !this.isValid() }>
                            <SvgIcon icon={this.props.doesSave ? 'save' : "check"} type="solid" />
                            { this.props.doesSave ? tr('save') : tr('continue') }
                        </ApButton>

                    </div>
                }

            />
        );
    }
};


ApModalInput.propTypes = {
    show:       PropTypes.bool.isRequired,
    value:      PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
    label:      PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
    required:   PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    disabled:   PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    title:      PropTypes.string,
    info:       PropTypes.string,
    type:       PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
    options:    PropTypes.array, // Only when type = "select"
    onClose:    PropTypes.func,
    onCancel:   PropTypes.func,
    onSave:     PropTypes.func,
    doesSave:   PropTypes.bool, // If true, display "Save" button, otherwise "Continue"
};

export default ApModalInput;

