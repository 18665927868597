import React from 'react';
import autoBind from 'react-autobind';

import auth               from 'services/Authed/Authed.js';
//import api                from 'services/Api/Api.js';
//import ApValidate         from 'services/ApValidate/ApValidate.js';

import { keyExists }  from 'services/Helpers/Helpers.js'

import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApSwitch           from 'common/ApSwitch/ApSwitch.js';
import ApButton           from 'common/ApButton/ApButton.js';
import { ApInput,
         ApInputStack,
         ApAddon }        from 'common/ApInput/ApInput.js';

import './RowEdit.css';

class RowEdit extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            data: null,
        }
        autoBind(this);
    }

    resetState()
    {
        this.setState({ data: this.props.data });

        // Auto focus to name field (also scrolls view up if nessessary)
        if( this.nameRef )
            this.nameRef.focus();
    }

    handleChange( key, value )
    {
        let data = { ...this.state.data };
        data[ key ] = value;
        this.setState({ data });
    }

    unlinkAllowance()
    {
        if( keyExists( this.state.data, "global_allowance_id" ) )
        {
            let data = { ...this.state.data };
            data[ "global_allowance_id" ] = null;
            this.setState({ data });
        }
    }

    save() 
    {
        this.props.onClose( this.state.data );
    }

    renderBody()
    {
        const isset = ( key ) => {
            
            if( !keyExists( this.state.data, key ) )
                return false;

            if( !this.state.data[ key ] )
                return false;
            
            if( this.state.data[ key ].length === 0 )
                return false;
            
            return true;
        }

        return (
            <div className="padding">

                <ApInput
                    type="text"
                    id="rowEdit_name"
                    name="rowEdit_name"
                    label="Nimi"
                    value={ keyExists( this.state.data, "name", true, '' ) }
                    onChange={ ( e ) => { this.handleChange( 'name', e.target.value ) } }
                    validationState={ !isset( "name" ) ? "error" : "" }
                    inputRef={ ref => this.nameRef = ref }
                />

                <ApInput
                    type="textarea"
                    id="rowEdit_description"
                    name="rowEdit_description"
                    label="Kuvaus"
                    value={ keyExists( this.state.data, "description", true, '' ) }
                    onChange={ ( e ) => { this.handleChange( 'description', e.target.value ) } }
                />

                <ApInput
                    type="text"
                    id="rowEdit_group"
                    name="rowEdit_group"
                    label="Ryhmä"
                    value={ keyExists( this.state.data, "group", true, '' ) }
                    onChange={ ( e ) => { this.handleChange( 'group', e.target.value ) } }
                />

                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    Mikäli rivejä on monta, kannattaa ne ryhmittää, jolloin käyttäjän on helpompi löytää haluamansa rivi. 
                </div>

                <br />

                { ( this.props.type === "hour_types" || this.props.type === "absence_reasons" ) &&
                    <div className="apFormGroup">

                        <div className="apSwitchBlock small">
                            <label htmlFor="rowEdit-worktime-switch" className="info">
                                <small>
                                    <SvgIcon className="small-inline" icon="clock" type="solid" /> { this.props.type === "hour_types" ? "Tunnit" : "Päivä" } lasketaan työaikaan
                                </small>
                            </label>
                            <ApSwitch
                                id="rowEdit-worktime-switch"
                                on={ keyExists( this.state.data, "worktime", true, false ) }
                                onChange={ () => this.handleChange( 'worktime', !this.state.data.worktime ) }
                                small
                            />
                        </div>

                        { this.props.type === "hour_types" &&
                            <div>  
                                <hr />

                                <div className="apSwitchBlock small">
                                    <label htmlFor="rowEdit-overtime-switch" className="info">
                                        <small>
                                            <SvgIcon className="small-inline" icon="moon" type="solid" /> Tunnit ovat ylitöitä
                                        </small>
                                    </label>
                                    <ApSwitch
                                        id="rowEdit-overtime-switch"
                                        on={ keyExists( this.state.data, "overtime", true, false ) }
                                        onChange={ () => this.handleChange( 'overtime', !this.state.data.overtime ) }
                                        small
                                    />
                                </div>

                                <hr />

                                <div className="apSwitchBlock small">
                                    <label htmlFor="rowEdit-flexitime-switch" className="info">
                                        <small>
                                            <SvgIcon className="small-inline" icon="hourglass-half" type="solid" /> Tunnit kerryttävät liukumia
                                        </small>
                                    </label>
                                    <ApSwitch
                                        id="rowEdit-flexitime-switch"
                                        on={ keyExists( this.state.data, "flexitime", true, false ) }
                                        onChange={ () => this.handleChange( 'flexitime', !this.state.data.flexitime ) }
                                        small
                                    />
                                </div>
                            </div>
                        }
                        
                        { this.props.type === "absence_reasons" &&
                            <div>  
                                <hr />

                                <div className="apSwitchBlock small">
                                    <label htmlFor="rowEdit-public_holiday-switch" className="info">
                                        <small>
                                            <SvgIcon className="small-inline" icon="couch" type="solid" /> Yleinen vapaapäivä
                                        </small>
                                    </label>
                                    <ApSwitch
                                        id="rowEdit-public_holiday-switch"
                                        on={ keyExists( this.state.data, "public_holiday", true, false ) }
                                        onChange={ () => this.handleChange( 'public_holiday', !this.state.data.public_holiday ) }
                                        small
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }

                { this.props.type === "hour_types" &&
                    <div className="apFormGroup">
                        
                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            Tuntiluokitukselle voidaan määrittää lisäkorvauksia, jotka maksetaan normaalin palkan lisäksi. 
                        </div>

                        <div className="apInfo small error">
                            <SvgIcon icon="exclamation-triangle" type="solid" />
                            Korvaukset eivät vaikuta palkanmaksuun, vaan pelkästään kulujen seurantaan
                        </div>

                        <ApInputStack gap="0">
                            <ApAddon noRightBorder>
                                Kiinteä korvaus
                            </ApAddon>
                            <ApInput 
                                width="100px"
                                type="number" 
                                id="rowEdit_salary_extra" 
                                name="rowEdit_salary_extra" 
                                align="right"
                                value={ keyExists( this.state.data, "salary_extra", true, '' ) }
                                onChange={ ( e ) => { this.handleChange( 'salary_extra', e.target.value ) } }
                            />
                            <ApAddon noLeftBorder width="100px">
                                { auth.getCurrency() }
                            </ApAddon>
                        </ApInputStack>

                        <ApInputStack gap="0">
                            <ApAddon noRightBorder>
                                Tuntimäärään perustuva korvaus
                            </ApAddon>
                            <ApInput 
                                width="100px"
                                type="number" 
                                id="rowEdit_salary_extra_per_unit" 
                                name="rowEdit_salary_extra_per_unit" 
                                align="right"
                                value={ keyExists( this.state.data, "salary_extra_per_unit", true, '' ) }
                                onChange={ ( e ) => { this.handleChange( 'salary_extra_per_unit', e.target.value ) } }
                            />
                            <ApAddon noLeftBorder width="100px">
                                { auth.getCurrency() } / h
                            </ApAddon>
                        </ApInputStack>

                        <ApInputStack gap="0">
                            <ApAddon noRightBorder>
                                Tuntipalkkaan perustuva korvaus
                            </ApAddon>
                            <ApInput 
                                width="100px"
                                type="number" 
                                id="rowEdit_salary_extra_percent" 
                                name="rowEdit_salary_extra_percent" 
                                align="right"
                                value={ keyExists( this.state.data, "salary_extra_percent", true, '' ) }
                                onChange={ ( e ) => { this.handleChange( 'salary_extra_percent', e.target.value ) } }
                            />
                            <ApAddon noLeftBorder width="100px">
                                %
                            </ApAddon>
                        </ApInputStack>

                    </div>
                }


                { ( this.props.type === "travel_vehicles" || this.props.type === "daily_allowances" ) &&
                    <ApInputStack gap="0">
                        <ApAddon noRightBorder>
                            { this.props.type === "travel_vehicles" ? "Kilometrikorvaus" : "Päivittäinen korvaus" }
                        </ApAddon>
                        <ApInput
                            width="100px"
                            type="number" 
                            id="rowEdit_compensation"
                            name="rowEdit_compensation"
                            align="right"
                            value={ keyExists( this.state.data, "compensation", true, '' ) }
                            onChange={ ( e ) => { this.handleChange( 'compensation', e.target.value ) } }
                        />
                        <ApAddon noLeftBorder width="100px">
                            { auth.getCurrency() } / { this.props.type === "travel_vehicles" ? "km" : "pv" }
                        </ApAddon>
                    </ApInputStack>
                }

                <br />

                <ApInput
                    type="text"
                    id="rowEdit_salary_category"
                    name="rowEdit_salary_category"
                    label={ this.props.type === "travel_vehicles" ? "Palkkalaji (km)" : "Palkkalaji" }
                    value={ keyExists( this.state.data, "salary_category", true, '' ) }
                    onChange={ ( e ) => { this.handleChange( 'salary_category', e.target.value ) } }
                    validationState={ !isset( "salary_category" ) ? "error" : "" }
                />
                
                { this.props.type === "travel_vehicles" &&
                    <ApInput
                        type="text"
                        id="rowEdit_salary_category_hours"
                        name="rowEdit_salary_category_hours"
                        label="Palkkalaji (tunnit)"
                        value={ keyExists( this.state.data, "salary_category_hours", true, '' ) }
                        onChange={ ( e ) => { this.handleChange( 'salary_category_hours', e.target.value ) } }
                        validationState={ !isset( "salary_category_hours" ) ? "error" : "" }
                    />
                }

                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    Käytössä oleva palkanlaskentaohjelmisto, määrittää käytettävät palkkalajit. 
                </div>


                { this.props.type === "daily_allowances" && keyExists( this.state.data, "global_allowance_id", true, false ) &&
                    <div className="globalAllowanceLink">
                        <SvgIcon className="small-inline" icon="book" type="solid" />
                        Linkitetty lakisääteisiin <small>(tiedot päivittyvät tuonnissa)</small>

                        <ApButton size="tiny" color="red" onClick={ this.unlinkAllowance }>
                            Poista linkitys
                        </ApButton>
                    </div>
                }

            </div>
        );
    }

    render()
    {
        return (
            <ApModal
                show={ this.props.show }
                onOpen={ this.resetState }
                handleClose={ () => this.props.onClose( false) }
                //closeFromBg
                className="narrow"
                header={ 
                    <div className="padding">
                        <h4>
                            { this.props.type === "hour_types" && "Tuntiluokituksen" } 
                            { this.props.type === "travel_vehicles" && "Poissaolon" } 
                            { this.props.type === "absence_reasons" && "Kilometrikorvauksen" } 
                            { this.props.type === "daily_allowances" && "Päivärahan" } 

                            { this.props.data.id ? " muokkaus" : " lisäys" }
                        </h4>
                    </div>
                }
                body={ 
                    <div id="WorkHourSettingsRowEditBody">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="padding" id="WorkHourSettingsRowEditFooter">
                        
                        <ApButton className="cancel" onClick={ () => this.props.onClose( false ) } disabled={ this.state.loading }>
                            <SvgIcon icon="times" type="solid" />
                            Peruuta
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ this.save } disabled={ this.state.loading } loading={ this.state.loading }>
                            <SvgIcon icon="check" type="solid" />
                            Jatka
                        </ApButton>

                        {/* 
                        <ApButton className="prev" onClick={ this.prevStep } disabled={ this.state.loading }>
                            <SvgIcon icon={ this.state.step == 1 ? "times" : "arrow-left" } type="solid" />
                            { this.state.step == 1 ? "Peruuta" : "Takaisin" }
                        </ApButton>

                        <ApButton className="next" color="blue" onClick={ this.nextStep } disabled={ !this.state.stepValid || this.state.loading } loading={ this.state.loading }>
                            { this.state.step == 3 ? "Tallenna" : "Seuraava" }
                            <SvgIcon icon={ this.state.step == 3 ? "save" : "arrow-right" } type="solid" />
                        </ApButton>

                        { this.state.error && 
                            <div className="apErrorMsg">
                                { this.state.error }
                            </div>
                        }
                        */}
                    
                    </div>
                }

            />
        )

    }

}

export default RowEdit;
