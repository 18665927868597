/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import Notfound  from 'layout/Notfound/Notfound';
import auth from 'services/Authed/Authed.js';
import { debounce } from 'throttle-debounce';
import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit.js';
import moment from 'moment';

import SvgIcon           from 'common/SvgIcon/SvgIcon.js';
import ApButton          from 'common/ApButton/ApButton.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import ApTimeline        from 'common/ApTimeline/ApTimeline.js';
import ApTooltip   from 'common/ApTooltip/ApTooltip.js';
import ApReactTable, { colPreset }     from 'common/ApReactTable/ApReactTable.js';
import { keyExists, hasPermissions, dateInputToSql, tr, errorPopper } from 'services/Helpers/Helpers.js';

import { formatMoney }  from 'services/Helpers/Helpers.js'

import NewProject        from 'modules/Projects/NewProject/NewProject.js';

import OfferCalculation from './OfferCalculation/OfferCalculation.js';
import Management from './Management/Management.js';
import Tracking from './Tracking/Tracking.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';

import './Projects.css';
import ProjectReports from './Reports/Reports.js';

const permissions = 'projects';

const tabs = [
    {
        name: 'offers',
        icon: 'calculator',
        label: tr('offers'),
        description: tr('offers'),
        openTab: 'offer',
    },
    {
        name: 'projects',
        icon: 'folder-open',
        label: tr('projects'),
        description: tr('active_projects'),
        openTab: 'management',
    },
    {
        name: 'ended',
        icon: 'folder-minus',
        label: tr('ended_projects'),
        description: tr('ended_projects'),
        openTab: 'management',
    },
    {
        name: 'declined',
        icon: 'folder-minus',
        label: tr('declined_offers'),
        description: tr('declined_offers'),
        openTab: 'offer',
    },
];

class Projects extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {

            loading: false,
            projects: [],
            //statics: [],
            //offerstatics: [],

            showAll: true,
            projectTypeFilterValue:null,
            showNewProjectModal: false,
            offerSelectedFilter: 'active',
            copyProjectId:null,
            copyProject: null,

            confirmDeleteIds: false,
            selectProjectTypeIconColor: "white",
            decline_reason_enabled: false,
            projectTypes: [],
            dataLoaded:false,
            lastResult: 0,
            projectTimelines:[],
        };
        this.selectedTab = 1;
        autoBind(this);

        this.getCustomerDataDebounced = debounce( 500 , this.getCustomerData );
    }

    getSettings()
    {
        // this.setState({ loading: true });
        api({
            method: 'get',
            url: 'projects/settings'
        }).then(response => {

            //console.log('GET PROJECT SETTINGS', response);
            this.setState({ 
                // loading:        false,
                decline_reason_enabled: response.decline_reason_enabled,
                
            });

        }).catch(error => {
            //console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('unexpected_error') ) || "ERROR",
                loading: false 
            });
        });
    }

    getProjectTimelines() {
        // this.setState({ loading: true });
        api({
            method: 'get',
            url: 'projects/getTimelines'
        }).then(response => {

            //console.log('GET PROJECT SETTINGS', response);
            this.setState({
                // loading:        false,
                projectTimelines: response,

            });

        }).catch(error => {
            //console.log(error);
            this.setState({
                error: keyExists(error, "response.data.message", true, tr('unexpected_error')) || "ERROR",
                loading: false
            });
        });
    }
    getProjectTypeFilter() {
        
        let cookieName = auth.getID() + "/" + auth.getCompanyID() + "/ApReacTable_settings_selectProjectType"
        let projectTypeFilterCookieData = window.localStorage.getItem(cookieName);
        let projectTypeFilterCookie = projectTypeFilterCookieData != "undefined" ? JSON.parse(projectTypeFilterCookieData) : null;
        this.setState({
            projectTypeFilterCookie: projectTypeFilterCookie
        });
    }

    componentDidMount()
    {  
        if( !hasPermissions( permissions ) ) return null;
        this.getSettings();
        this.getProjectTypeFilter();
        this.getProjects();
        this.getProjectTimelines();
        //this.getUserProfile();
        
        
    }

    getProjects( name = null )
    {

        if( !name ) name = tabs[ this.selectedTab ].name;

        this.setState({ loading: true });
        //console.log('nimi: ', name);
        api({
            method: 'get',
            url: `projects/getWithTimestamp/${ name }`,
            //url: `projects/get/withBudget/${ name }`,
            errorPopper: tr('get_error'),
        }).then((response) => {
            if (response.request_time>this.state.lastResult) {       
                //console.log('projects', response);
                let uniqueTypes = [];
                for (let p of response.data) {
                    if (uniqueTypes.includes(p.type)) {
                        continue;
                    }
                    if (p.type != null) {
                        uniqueTypes.push(p.type);
                    }
                    
                }
                //console.log('uniqueTypes', uniqueTypes);
                uniqueTypes.filter((item, index) => uniqueTypes.indexOf(item) === index);
                this.setState({                
                    projects: response.data,
                    loading: false,
                    projectTypes: uniqueTypes,
                    lastResult: response.request_time
                });
            }

        }).catch((error) => {
            this.setState({
                loading: false
            });
        })
        
    }

    getAProjectForList( id, name = null )
    {
        if( !name ) name = tabs[ this.selectedTab ].name;
        this.setState({ loading: true });
        api({
            method: 'get',
            url: `projects/get/${ name }/${ id }`,
            errorPopper: tr('get_error'),
        }).then((response) => {
            let projects = this.state.projects.slice(0);
            if( response )
            {
                projects = projects.map( p => {
                    if( p.id === response.id )
                        return response;
                    return p;
                })
            }
            else {
                projects = projects.filter( p => p.id != id )
            }
            this.setState({
                projects: projects,
                loading: false
            });

        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }
    getUserProfile() {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'currentUser',
        }).then((response) => {
            this.setState({
                companyID: response.company_id,
                userID: response.id,
                dataLoaded:true,
                loading: false,
                
            });
        }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false
            });
        });
    }

    CopyProjectToNewProject(id) {
        //console.log("lähetetty id", id);
        const project = this.state.projects.find(project => project.id === id);
        let data = { project_id: id }
        this.setState({ copyProjectId: id,showNewProjectModal:true, copyProject: project, });

        /*api({
            method: 'post',
            url: `project/management/CopyProjectToNewProject`,
            data: data,
        }).then((response) => {

            console.log("projekti kopioitu", response);
            this.setState({

                loading: false,
            })
        }).catch((error) => {
            console.log(error, tr('get_error'));
            this.setState({ loading: false });
        });*/
    }

    downloadProjectsListExcel(projects, ids,offer) {
        
        let data = ids.map(id => {
            let project = projects[id];
            let row = {
                project: project.name,
                project_code: project.project_code,
                customer: project.customer ? project.customer.name : "",
                project_type: project.type,
                project_creator: project.creator ? project.creator.name : "",
                project_manager: project.manager ? project.manager.name : "",
                approval_of_hours: project.workhour_approver ? project.workhour_approver.name : "",
                customer_order_number: project.customer_order_number,
                total_budget: project.totalbudget,
                hourly_billed: project.billing_hourly ? 1 : 0,
                cost_center: project.cost_center ? project.cost_center.full_name : "",
                start_date: project.start,
                end_date: project.end,

                
            };
            if (offer) {
                row.offer_received_at= project.offer ? project.offer.date_received : "";
                row.offer_deadline= project.offer ? project.offer.date_deadline : "";
                row.offer_submitted= project.offer ? project.offer.date_submitted : "";
                row.decline_reason= project.offer ? project.offer.decline_description : "";
                row.calculation_versions_count= project.offer ? project.offer.sheet_count : "";
                row.status= project.offer ? project.offer.status : "";
                row.probability= project.offer ? project.offer.success_probability : "";
            }
            return row;
        });
        //console.log("lähetetty data", data);
        api({
            method: 'post',
            data: data,
            url: 'project/downloadProjectsListExcel',
            responseType: 'blob',
        }).then((response) => {
            //console.log(response);

            let day = moment().format("DD.MM.YYYY");
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', tr("projects") + "_" + day + ".xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({
                loading: false,
            });
        }).catch((error) => {
            errorPopper(error, 'Massalistan lataamisessa tapahtui virhe');
        });
    }

    newProjectAdded()
    {
        this.projectList.refresh();
    }

    getTimelineItems()
    {
        let projects = [];

        const addProject = ( project ) => {
            if (project.begin_date && project.end_date )
                projects.push( {
                    id: project.id,
                    name: project.name,
                    start: project.begin_date,
                    end: project.end_date,
                });
        };

        if (this.state.projectTimelines)
            this.state.projectTimelines.map(addProject);

        // if( this.state.projects.offers )
        //     this.state.projects.offers.map( addProject );

        // if( this.state.projects.paused )
        //     this.state.projects.paused.map( addProject );

        // if( this.state.projects.running )
        //     this.state.projects.running.map( addProject );

        return projects;
    }

    openProject( id, page)
    {
        this.props.history.push( `/projects/${ id }/${ page }` );
    }

    doProjectStorage(pId)
    {
        const data = { project_id: pId }
        api({
            method: 'post',
            url: `project/management/doProjectStorage`,
            data: data
        }).then((response) => {
            //console.log('Projektivaraston luonti onnistui!');
            this.updateStorageBalance(pId);
        });
    }

    updateStorageBalance(pId) {
        api({
            method: 'get',
            url: `project/management/${pId}/updateProjectStorage`,
        }).then((response) => {
            if (response != 0) {
                //console.log('Projektivaraston saldojen päivitys onnistui!');
            }
        }).catch((error) => {
            console.error(`Projektivaraston saldojen päivitys ei onnistunut! ${error}`);
            this.setState({ loading: false });
        });
    }
    /*getSumColumns( tab )
    {
        let data = this.state.projects;

        let tot=0;
        projects.forEach(d => {
        tot = tot + d.totalbudget;
        }

        let $tot=0;
        data.forEach(d => {
            $tot = $tot + d.totalbudget;
        })

        let columns = [{
                Header:     "Yhteissumma",
                name:       "name",
                accessor:   $tot,
        },
        {
                Header:     $tot, //"Yhteissumma",
                name:       "tot",
                accessor:   $tot,
            }, 
        ]    
        return [
            {
                Header: () => {
                    return (
                        <div>
                            { tab.description }
                        </div>
                    );
                },
                columns: columns,
                //customizable: true,
            },
        ]
    }      */

    getColumns( tab )
    {
        const onClick = ( row ) => { this.openProject( row.id, tab.openTab ) };
        let data = this.state.projects;
        let tot=0;
        data.forEach(d => {
        tot = tot + d.totalbudget;
        })
        
        let columns = [

            colPreset({
                type:       "nameDesc",
                Header:     tr('project'),
                name:       "name",
                accessor:   "name",
                desc:       "project_code",
                onClick: onClick,
            }),            
            {
                id:         "customer",
                Header:     tr('customer'),
                accessor:   "customer.name",
                Cell: ( props ) => {
                    const customer = keyExists( props, 'original.customer.name', true, null);
                    const contact = keyExists( props, 'original.customer_contact.name', true, null);
                    return (
                        <div className="customerCell">
                            { customer }<br />
                            { customer && contact && <small><SvgIcon clssName="small-inline" icon="address-card" type="solid" /> { contact }</small> }
                        </div>
                    );
                },
                customFilter: {
                    type: "text",
                    fields: [ "_original.customer.name", "_original.customer_contact.name" ],
                },
                customizable: true,
            },
            {
                id: "projectType",
                Header: tr("project_type"),
                accessor: "type",
                align: "left",
                customizable: true,
                showInitially: true,
                width: 210,
                Cell: (props) => {
                    //console.log(props);
                    return (props.value

                    );
                },
                multiselect: true,
                customFilter: {
                    type: "apSelectProjectType",
                    placeholder: tr("project_type"),
                    /*changeColor: (filter) => {
                        if (filter === "cookie") {
                            this.setState({ selectProjectTypeIconColor: "green" })
                        }
                        let filterValue = filter ? filter.value : undefined;
                        if (filterValue != this.state.projectTypeFilterValue) {
                            this.setState({ selectProjectTypeIconColor: "white" })
                        }
                        this.state.projectTypeFilterValue = filterValue;
                        
                        //console.log(filter);
                    },*/
                    
                    click: (filter) => {
                        window.localStorage.setItem(auth.getID() + "/" + auth.getCompanyID()+"/ApReacTable_settings_selectProjectType", JSON.stringify(filter));
                        window.emitter.emit('popper', {
                            type: 'success',
                            content: <strong>{tr('project_type_saved')}</strong>,
                        });
                    },
                    
                    optionRenderer: (item) => {
                        return <div className="projectType">
                            <strong>{item}</strong>
                        </div>;
                    },
                    

                    options: this.state.projectTypes,
                    //objKeyValue: 'type',
                    //multiselect:true,
                },
            },
            {
                id:         "creator",
                Header:     tr('project_creator'),
                accessor:   "creator.name",
                customizable: true,
                showInitially: false,
                customFilter: {
                    type: "text",
                    fields: ["_original.creator.name"],
                },
            },
            {
                id:         "manager",
                Header:     tr('project_manager'),
                accessor:   "manager.name",
                customizable: true,
                showInitially: false,
                customFilter: {
                    type: "text",
                    fields: ["_original.manager.name"],
                },
            },
            {
                id:         "workhour_approver",
                Header:     tr('approver_of_hours'),
                accessor:   "workhour_approver.name",
                className:  "overflowableOnHover",
                customizable: true,
                showInitially: false,
                Cell: ( props ) => {
                    let approver = null;
                    let approversCount = null;
                    let approverTooltip = null;
                    if (props.original.hour_approvers.length > 0) {
                        approver = props.original.hour_approvers[0];
                        if(typeof approver === "object") {
                            approver = approver.name;
                        }
                        if (props.original.hour_approvers.length > 1) {
                            approversCount = props.original.hour_approvers.length + " " + tr('approvers');
                            approverTooltip = props.original.hour_approvers.map((hourApprover, index) => {
                                return (
                                    <div key={index}>
                                        {typeof hourApprover === 'object' ? hourApprover.name : hourApprover}
                                    </div>
                                )
                            });
                        }
                    }

                    return (
                        <ApTooltip text={approverTooltip}>
                            <div>
                                <div>{approver}</div>
                                <small>{approversCount}</small>
                            </div>
                        </ApTooltip>
                    )
                },
                filterMethod: (filter, row) => {
                    if (Array.isArray(row._original.hour_approvers) && row._original.hour_approvers.length > 0) {
                        const approversStr = row._original.hour_approvers.join(' ');
                        return approversStr.toLowerCase().includes(filter.value.toLowerCase());
                    }
                    return false;
                },
                customFilter: {
                    type: "text",
                    fields: ["_original.workhour_approver.name"],
                },
            },
			{
                id:         "Customer_order_number",
                Header:     tr('customer_order_number'),
                accessor:   "customer_order_number",
                customizable: true,
                showInitially: false,
            },
           
        ]   

        
        if( tab.name === 'projects' )
        {

            columns.push({
                id:         "bud",
                Header:     tr('total_budget'),
                accessor:   "totalbudget",
                align:      "right",
                customizable: true,
                showInitially: true,      
                Cell: ( props ) => {
                    return ( props.value
                        ? formatMoney( props.value, 2 ) + " €" 
                        : <span className="empty">-</span>
                    );
                },           
            })

            columns.push({
                id:         "billing_hourly",
                Header:     tr('hourly_billing'),
                accessor:   "billing_hourly",
                width: 70,
                customFilter: { type: "toggle" },
                Cell: ( props ) => {
                    if( props.value )
                        return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                },
                customizable: true,
                showInitially: true,

            })

            columns.push({
                id: "costCenters",
                Header: tr('cost_center'),
                className: "overflowableOnHover",
                accessor: (props) => {
                    
                    if (props.cost_centers.length > 1) {
                        
                        
                        return props.cost_centers.length
                    }
                    
                    return props.cost_centers.length == 0 ? null : props.cost_centers[0]
                },
                width: 145,
                filterable: true,
                Cell: (props) => {
                    let text = [];
                    let hownamyCharShow = 20;
                    
                    let needTooltip = false;
                    for (let value of props.original.cost_centers) {
                        let textElement=null;
                        if (!value.name) {
                            return null;
                        }
                        text.push([value.name, value.full_name]);
                        
                        
                        
                    }
                    if (text.length != 0) {
                        if (text.length > 1) {
                            return (< ApTooltip text={text.map(t => <div>
                                {t[0]}<br />
                                <small>{t[1]}</small>
                            </div>)} block position={"top"} >
                                {tr("amount")+": "+ text.length }
                            </ApTooltip >);
                        }
                        else{
                            return (< ApTooltip text={text[0][1]} block position={"top"} >
                                <div style={{ textOverflow: "ellipsis",overflow:"hidden"}}>
                                    {text[0][0]}<br />
                                    <small>{text[0][1]}</small>
                                </div>
                            </ApTooltip >);
                        }
                        
                    }
                    return null
                    
                },
                customizable: true,
                showInitially: false,
                filterMethod : (filter, rows) => {
                    
                    if (!rows._original.cost_centers) {
                        return false;
                    }
                    for (let costCenter of rows._original.cost_centers) {
                        if (String(costCenter.name).toLowerCase().indexOf(filter.value.toLowerCase())!=-1) {
                            return true
                        }
                        
                    }
                    if (filter.value == rows._original.cost_centers.length) {
                        return true;
                    }
                    return false;
                }

            })

            columns.push(
                colPreset({
                    type:       "dateAndDiff",
                    id:         "start",
                    Header:     tr('start_date'),
                    accessor:   "start",
                    width: 200,
                    filterable: false,
                    customizable: true,
                    showInitially: true,
                })
            )

            columns.push(
                colPreset({
                    type:       "dateAndDiff",
                    id:         "end",
                    Header:     tr('end_date'),
                    accessor:   "end",
                    width: 200,
                    customFilter: { type: "toggle" },
                    customizable: true,
                    showInitially: true,
                })
            )
        }
        if( tab.name === 'ended' )
        {
            columns.push({
                id:         "done",
                Header:     tr('completed'),
                accessor:   "done",
                width: 70,
                customFilter: { type: "toggle" },
                Cell: ( props ) => {
                    if( props.value )
                        return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                },
                customizable: true,
                showInitially: true,
            })

            columns.push(
                colPreset({
                    type:       "dateAndDiff",
                    id:         "ended",
                    Header:     tr('project_ended_at'),
                    accessor:   "ended",
                    width: 200,
                    filterable: false,
                    customizable: true,
                    showInitially: true,
                })
            )
        }
        if( tab.name === 'offers' )
        {
            columns.push(
                colPreset({
                id:         "offer_status",
                Header:     tr('status'),
                accessor:   "offer.status",
                width: 70,
                customizable: true,
                showInitially: false,
                Cell: ( props ) => {
                    switch ( props.value ) {
                        case 'active': return <div className="apStatusBox info">{ tr('active') }</div>;
                        case 'sent': return <div className="apStatusBox">{ tr('open2') }</div>;
                        case 'done': return <div className="apStatusBox success">{ tr('completed') }</div>;
                        default: return props.value;
                    }
                }
                })
            )

            columns.push(
                colPreset({
                    id:         "ikoni",
                    type:       "icon",
                    tooltip:    tr('calculation_versions_count'),
                    icon:       "layer-group",
                    accessor:   "offer.sheet_count",
                    filterable: false,
                    customizable: true,
                    showInitially: true,
                    HeaderText: tr('calculation_version')
                })
            )

            columns.push(
                colPreset({
                    type:       "dateAndDiff",
                    id:         "received",
                    Header:     tr('offer_received_at'),
                    accessor:   "offer.date_received",
                    //filterable: false,
                    customFilter: { type: "toggle" },
                    customizable: true,
                    showInitially: true,
                })
            )

            columns.push(
                colPreset({
                    type:       "dateAndDiff",
                    id:         "deadline",
                    Header:     tr('offer_deadline'),
                    accessor:   "offer.date_deadline",
                    //filterable: false,
                    customFilter: { type: "toggle" },
                    customizable: true,
                    showInitially: true,
                })
            )

            columns.push(
                colPreset({
                    type:       "totbud",
                    id:         "totbud",
                    Header:     tr('price'),
                    accessor:   "totalbudget",
                    align:      "right",
                    Cell: ( props ) => {
                        return ( props.value
                            ? formatMoney( props.value, 2 ) + " €" 
                            : <span className="empty">-</span>
                        );
                    },
                    customizable: true,
                    showInitially: true,
                })
            )

            columns.push(
                colPreset({
                    type:       "number",
                    id:         "success_probability",
                    Header:     tr('probability'),
                    accessor:   "offer.success_probability",
                    unit:       "%",
                    width:      150,
                    align:      "center",
                    customFilter: {
                        type: "range",
                        min: 0,
                        max: 100,
                        step: 10,
                        valueRenderer: ( values ) => <span>{ values[0] } - { values[1] } %</span>
                    },
                    customizable: true,
                    showInitially: true,
                })
                
            )
        }
        if( tab.name === 'declined' )
        {
            columns.push(
                colPreset({
                id:         "offer_status",
                Header:     tr('status'),
                accessor:   "offer.status",
                width: 70,
                customizable: true,
                showInitially: false,
                Cell: ( props ) => {
                    switch ( props.value ) {
                        case 'active': return <div className="apStatusBox info">{ tr('active') }</div>;
                        case 'sent': return <div className="apStatusBox">{ tr('open2') }</div>;
                        case 'declined': return <div className="apStatusBox error">{ tr('rejected') }</div>;
                        case 'done': return <div className="apStatusBox success">{ tr('completed') }</div>;
                        default: return props.value;
                    }
                }
                })
            )

            columns.push(
                colPreset({
                    id:         "ikoni",
                    type:       "icon",
                    tooltip:    tr('calculation_versions_count'),
                    icon:       "layer-group",
                    accessor:   "offer.sheet_count",
                    filterable: false,
                    customizable: true,
                    showInitially: true,
                    HeaderText: tr('calculation_version')
                })
            )

            columns.push(
                colPreset({
                    type:       "dateAndDiff",
                    id:         "received",
                    Header:     tr('offer_received_at'),
                    accessor:   "offer.date_received",
                    //filterable: false,
                    customFilter: { type: "toggle" },
                    customizable: true,
                    showInitially: true,
                })
            )

            columns.push(
                colPreset({
                    type:       "dateAndDiff",
                    id:         "deadline",
                    Header:     tr('offer_deadline'),
                    accessor:   "offer.date_deadline",
                    //filterable: false,
                    customFilter: { type: "toggle" },
                    customizable: true,
                    showInitially: true,
                })
            )

            columns.push(
                colPreset({
                    type:       "totbud",
                    id:         "totbud",
                    Header:     tr('price'),
                    accessor:   "totalbudget",
                    align:      "right",
                    Cell: ( props ) => {
                        return ( props.value
                            ? formatMoney( props.value, 2 ) + " €" 
                            : <span className="empty">-</span>
                        );
                    },
                    customizable: true,
                    showInitially: true,
                })
            )

            columns.push(
                colPreset({
                    type:       "number",
                    id:         "success_probability",
                    Header:     tr('probability'),
                    accessor:   "offer.success_probability",
                    unit:       "%",
                    width:      150,
                    align:      "center",
                    customFilter: {
                        type: "range",
                        min: 0,
                        max: 100,
                        step: 10,
                        valueRenderer: ( values ) => <span>{ values[0] } - { values[1] } %</span>
                    },
                    customizable: true,
                    showInitially: true,
                })

            )

            if (this.state.decline_reason_enabled === true)
            {
                columns.push(
                    colPreset({
                        type:       "decline_reason",
                        id:         "decline_reason",
                        Header:     tr('decline_reason'),
                        accessor:   "decline_reason",
                        //filterable: false,
                        customizable: true,
                        showInitially: true,
                    })
            )
            
        }


        
    }

        return [
            {
                Header: () => {
                    return (
                        <div>
                            { tab.description }
                        </div>
                    );
                },
                columns: columns,
                customizable: true,
            },
        ]
    }

    renderSumRow(pros)
    {
        let ptot=0; 
        let ptod=0;

        pros.forEach(d =>
        { 
            ptot += d.totalbudget;
            if(d.offer)
            {
                ptod += (d.totalbudget*d.offer.success_probability)/100;
            }
        })
        if(ptot==0)
            return;
     
        if (ptod>0)
        {
            return(
                <div>
                   <table align="right" width="60%">
                       <tbody>
                           <tr>
                               <td className="larger" ><h1>{ tr('in_total') }</h1></td>
                               <td><h1>{  formatMoney(ptot, 2) + " €" } </h1></td>
                               <td className="larger" ><h1>{ tr('probability') }</h1></td>
                               <td><h1>{  formatMoney(ptod, 2) + " €" } </h1></td>
                           </tr>
                           <tr height="40px">
       
                           </tr>
                       </tbody>
                   </table>
               </div>
        )}
        else
        {
        return(
         <div>
            <table align="right" width="40%">
                <tbody>
                    <tr>
                        <td className="larger" ><h1>{ tr('in_total') }</h1></td>
                        <td><h1>{  formatMoney(ptot, 2) + " €" } </h1></td>
                    </tr>
                    <tr height="40px">

                    </tr>
                </tbody>
            </table>
        </div>
        )
        }
    }

    renderTab( tab )
    {
        let projects = this.state.projects;
        let filterBar = null;
        let offerFilter;
        if( tab.name === 'offers' )
        {
            offerFilter = this.state.offerSelectedFilter;

            const offerGroups = [
                {
                    value: 'active',
                    title: tr('in_calculation'),
                    info : tr('offers_in_calculation'),
                },{
                    value: 'sent',
                    title: tr('open_count'),
                    info : tr('waiting_offers'),
                },/*{
                    value: 'declined',
                    title: tr('rejected_count'),
                    info : tr('rejected_offers'),
                },*/{
                    value: 'all',
                    title: tr('all'),
                    info : tr('all_offers'),
                }
            ]

            const filterOptions = offerGroups.map( (o, index )  => {
                const className = ( offerFilter === o.value ) ? 'option selected' : 'option';
                return <div key={ index } className={ className } onClick={ () => {  this.setState({ offerSelectedFilter: o.value }) }}>
                    <ApTooltip text={ o.info } block position="topright">
                        { o.title }
                    </ApTooltip>
                </div>
            })

            filterBar = <div className="padding">
                <div className={`apOptionBar count-${ offerGroups.length }`}>
                    { filterOptions }
                </div>
            </div>

            if( offerFilter !== 'all' ) {
                projects = projects.filter(p => p.offer != null);
                projects = projects.filter( p => {
                    return ( p.offer.status === offerFilter )
                });
            }

        }
        
        let multiselect=[];
        if( auth.hasModule( 'projects.edit.remove' ) )
        {
            multiselect = [{
                icon: "plus-square",
                label: tr('delete_projects'),
                action: ( indexes ) => { this.confirmDeleteProjects( indexes.map( i => projects[ i ].id ) ) },
                unselectAfter: true,
            }];
        }
        
        if(tab.name === 'projects' || tab.name === 'ended'){
            multiselect.push({
                icon: "copy",
                label: tr('copy_project'),
                action: (indexes) => { this.CopyProjectToNewProject(projects[indexes[0]].id) },
                unselectAfter: true,
                maxSelectedCount:1,
            });
        }
        multiselect.push({
            icon: "copy",
            label: tr('create_excel'),
            action: (indexes) => { this.downloadProjectsListExcel(projects, indexes, tab.name === 'offers') },
            unselectAfter: true,
        });
        if(multiselect.length==0){
            multiselect=null;
        }
        
        
       
        if( tab.name === 'projects' )
        {
            return <ApTab key={ tab.name } icon={ tab.icon } label={ tab.label }>
                <div className="padding">
                    { filterBar }
                    <ApReactTable
			            rememberId="projectsTable"
                        showFiltersInitially={ true }
                        loading={ this.state.loading }
                        data={ projects}
                        filterable                        
                        columns={ this.getColumns( tab) }
                        minRows={ 4 }
                        rowActions={ (row) =>
                         <div className="apSimpleButton" onClick={ () => this.openProject( row.id, tab.openTab ) }><SvgIcon icon="arrow-right" type="solid" /></div>
                        }
                        multiselect={multiselect}
                    
                        defaultFiltered={this.state.projectTypeFilterCookie?[this.state.projectTypeFilterCookie]:[]}
                    />
                </div>
                { this.renderSumRow(projects) }
            </ApTab>
        }
        if( tab.name === 'offers' )
        {
            return <ApTab key={ tab.name } icon={ tab.icon } label={ tab.label }>
                <div className="padding">
                    { filterBar }
                    <ApReactTable
			            rememberId="offersTable"
                        showFiltersInitially={ true }
                        loading={ this.state.loading }
                        data={ projects }
                        filterable
                        columns={ this.getColumns( tab) }
                        minRows={ 4 }
                        rowActions={ (row) =>
                        <div className="apSimpleButton" onClick={ () => this.openProject( row.id, tab.openTab ) }>
                             <SvgIcon icon="arrow-right" type="solid" />
                        </div>
                        }
                        multiselect={multiselect}
                        defaultFiltered={this.state.projectTypeFilterCookie ? [this.state.projectTypeFilterCookie] : []}
                        
                    />
                </div>
                { this.renderSumRow( projects ) }
            </ApTab>
        }
        if( tab.name === 'ended' )
        {
            return <ApTab key={ tab.name } icon={ tab.icon } label={ tab.label }>
                
                <div className="padding">
                    { filterBar }
                  <ApReactTable
			            rememberId="endedTable"
                        showFiltersInitially={ true }
                        loading={ this.state.loading }
                        data={ projects }
                        filterable
                        columns={ this.getColumns( tab) }
                        minRows={ 4 }
                        rowActions={ (row) =>
                         <div className="apSimpleButton" onClick={ () => this.openProject( row.id, tab.openTab ) }><SvgIcon icon="arrow-right" type="solid" /></div>
                        }
                        multiselect={multiselect}
                        defaultFiltered={this.state.projectTypeFilterCookie ? [this.state.projectTypeFilterCookie] : []}
                        
                    />
                </div>
                { this.renderSumRow(projects) }
            </ApTab>
        }
        if( tab.name === 'declined' )
        {
            return <ApTab key={ tab.name } icon={ tab.icon } label={ tab.label }>
                
                <div className="padding">
                    { filterBar }
                  <ApReactTable
			            rememberId="declinedTable"
                        showFiltersInitially={ true }
                        loading={ this.state.loading }
                        data={ projects }
                        filterable
                        columns={ this.getColumns( tab) }
                        minRows={ 4 }
                        rowActions={ (row) =>
                         <div className="apSimpleButton" onClick={ () => this.openProject( row.id, tab.openTab ) }><SvgIcon icon="arrow-right" type="solid" /></div>
                        }
                        multiselect={multiselect}
                        defaultFiltered={this.state.projectTypeFilterCookie ? [this.state.projectTypeFilterCookie] : []}
                        
                    />
                </div>
                { this.renderSumRow(projects) }
            </ApTab>
        }
    }

    confirmDeleteProjects( ids )
    {
        this.setState({ confirmDeleteIds: ids });
    }

    deleteProjects()
    {
        const ids = this.state.confirmDeleteIds;

        this.setState({ loading: true });
        api({
            method: 'delete',
            url: `projects/delete`,
            data: { ids: ids },
            errorPopper: tr('delete_error'),
        }).then((response) => {
            if( response.not_deleted.length === ids.length )
            {
                window.emitter.emit('popper', {
                    type: 'warning',
                    content: <strong>{ tr('no_projects_deleted') }</strong>,
                });
            }
            else if( response.deleted.length === ids.length )
            {
                window.emitter.emit('popper', {
                    type: 'info',
                    content: <strong>{ tr('projects_deleted') }</strong>,
                });
            }
            else
            {
                if( response.not_deleted.length )
                {
                    window.emitter.emit('popper', {
                        type: 'warning',
                        content: <strong>{ tr('projects_were_not_deleted', [response.not_deleted.length]) }</strong>,
                    });
                }
                if( response.deleted.length )
                {
                    window.emitter.emit('popper', {
                        type: 'info',
                        content: <strong>{ tr('projects_were_deleted', [response.deleted.length]) }</strong>,
                    });
                }
            }
            this.getProjects();

        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    tabChange( index )
    {
        const tab = tabs[ index ];
        if( tab )
        {
            this.selectedTab = index;
            this.getProjects();

        }
    }

    renderFront()
    {
        const timelineProjects = this.getTimelineItems();

        let newProjectButton = null;
        if( auth.hasModule( 'projects.edit.add' ) )
        {
            newProjectButton = <ApButton color="green" onClick={ () => { this.setState({ showNewProjectModal: true }) } }>
                { tr('new_project_or_offer') }
            </ApButton>
        }

        return <div className="apBox" id="pageProjects">
            <div className="apBoxHeader">
                <h1>
                    { tr('projects') }
                </h1>
                <p>{ tr('project_management_info') }</p>
                <div className="apBoxCorner">
                    { newProjectButton }
                </div>
            </div>
            <ApTabs selected={ this.selectedTab } onChange={ this.tabChange } >

                {tabs.map(t => { return this.renderTab(t); })} 

                <ApTab icon="chart-area" label={ tr('timeline') } align="right">
                    <div className="padding">
                        { timelineProjects.length > 0 &&
                            <ApTimeline
                                //centerTo="2018-08-29"
                                //daysVisible="365"
                                items={timelineProjects}
                                readOnly
                            />
                        }
                    </div>
                </ApTab>
                <ApTab icon="chart-pie" label={ tr('reports') } align="right" mountOnSelect>
                    <ProjectReports />
                </ApTab>
            </ApTabs>
        </div>
    }

    onNewProjectSave( project )
    {
        //console.log('onNewProjectSave()', project );

        if( project.offer )
        {
            this.openProject( project.id, 'offer' )
        }
        else {
            this.openProject( project.id, 'management' )
        }
        this.getProjects();
    }

    render()
    {
        if (!hasPermissions(permissions)) return <Notfound />
        
        const params = this.props.match.params;


        let form = null;
        if( params.form )
        {
            if( params.form === 'offer' )
                form = <OfferCalculation projectId={ params.id } tab={ params.tab } history={ this.props.history } onSave={ this.getAProjectForList }  />

            if( params.form === 'management' )
                form = <Management projectId={ params.id } tab={ params.tab } history={ this.props.history } onSave={ this.getAProjectForList } />

            if( params.form === 'tracking' )
                form = <Tracking projectId={ params.id } tab={ params.tab } history={ this.props.history } />

            form = <div>
                <div id="pageProjectsForm">
                    <div className="pageBackButton" onClick={ () => this.props.history.push( `/projects` ) }>
                        <SvgIcon icon="arrow-left" type="solid" size="small" />
                        { tr('projects_overseer_module') }
                    </div>
                    { form }
                </div>
            </div>
        }

        let placeholders = null;
        if (this.state.copyProject) {
            placeholders = {};
            placeholders.customer = { id: this.state.copyProject?.customer?.id }; // not all all needed fields that reason not pass name
            placeholders.customer_address = this.state.copyProject?.customer_address;
            placeholders.name = this.state.copyProject?.name;
            placeholders.customer_contact = this.state.copyProject?.customer_contact;
            placeholders.customer_order_number = this.state.copyProject?.customer_order_number;
            placeholders.billing_hourly = this.state.copyProject?.billing_hourly;
            placeholders.is_continuous = this.state.copyProject?.is_continuous;
            placeholders.start = this.state.copyProject?.start;
            placeholders.end = this.state.copyProject?.end;
            placeholders.end_work = this.state.copyProject?.end_work;
            placeholders.manager = this.state.copyProject?.no_formatting_manager;
            placeholders.hour_approvers = this.state.copyProject?.no_formatting_hour_approvers;
            placeholders.billers = this.state.copyProject?.no_formatting_billers;
            placeholders.cost_center = this.state.copyProject?.cost_center;
            placeholders.user_add_progress = this.state.copyProject?.user_add_progress;
            placeholders.description = this.state.copyProject?.description;
        }

        //{ this.renderProjectList() }

        return <div id="pageProjects">
            <div className={ form ? 'listHidden' : '' }>
                { this.renderFront() }
                <NewProject
                    show={ this.state.showNewProjectModal }
                    onClose={() => this.setState({ showNewProjectModal: false, copyProjectId:null, copyProject: null }) }
                    onSave={this.onNewProjectSave}
                    copyProjectId={this.state.copyProjectId}
                    copyProject={this.state.copyProject}
                    placeholders={placeholders}
                />
            </div>
            { form }

            <ApConfirm
                show={ Boolean( this.state.confirmDeleteIds ) }
                onClose={ () => this.setState({ confirmDeleteIds: false }) }
                onConfirm={ this.deleteProjects }
                header={ tr('confirm_projects_deletion') }
                body={ 
                    <div>
                        <div className="apInfo">
                            <SvgIcon icon="question-circle" type="solid" />
                            {`${tr('confirm_projects_deletion_q')} (${this.state.confirmDeleteIds.length} ${tr('pcs')})` }
                        </div>
                        <div className="apInfo error small">
                            <SvgIcon icon="exclamation-triangle" type="solid" />
                            { tr('project_deletion_note1') }
                        </div>
                        <div className="apInfo warning  small">
                            <SvgIcon icon="exclamation-triangle" type="solid" />
                            { tr('project_deletion_note2') }
                        </div>
                    </div>
                }
            />
        </div>
    }
}

export default Projects;
