import React from 'react';
import autoBind from 'react-autobind';
import ApButton  from 'common/ApButton/ApButton.js';
import SvgIcon   from 'common/SvgIcon/SvgIcon.js';
import './DataSummary.css';

class DataSummary extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
        }
        autoBind(this);
    }

    goToDelivery( id )
    {
        window.emitter.emit('goTo', {
            pathname: `/storage/logistic/delivery/show/${ id }`
        });

    }
    goToPurchaseOrder( id )
    {
        window.emitter.emit('goTo', {
            pathname: `/storage/purchase/order/id/${ id }`
        });

    }
    goToOrder( id )
    {
        window.emitter.emit('goTo', {
            pathname: `/storage/order/id/${ id }`
        });
    }

    render()
    {
        let editButton = null;
        if( this.props.editable )
        {
            editButton = <ApButton
                size="xtiny"
                color="white"
                className="editButton"
                onClick={ this.props.onEditClick }
            >Muokkaa</ApButton>
        }

        let numberElement = null
            /*
        if( this.props.numberReserved )
        {
            numberElement = <div>
                <dt>Numero</dt>
                <dd>{this.props.number}</dd>
            </div>
        }
        else
        {
            numberElement = <div title="Seuraava vapaa numero jota ei ole vielä varattu ostotilaukselle" style={{color: 'gray'}}>
                <dt>Numero</dt>
                <dd>{this.props.number}</dd>
            </div>
        }
        */

        let orderDetails = <dl className="dl-horizontal">
            <dt>Vastaanotettu</dt>
            <dd>{this.props.date}</dd>

            {/*
            <dt>Viitteenne</dt>
            <dd>{this.props.referenceYour}</dd>

            <dt>Viitteemme</dt>
            <dd>{this.props.referenceOur}</dd>
            */}
        </dl>

        let related = null;
        if( this.props.relatedType === 'delivery' )
        {
            related = <dl className="dl-horizontal">
                <dt>Lähetyksen numero</dt>
                <dd>
                    { this.props.relatedNumber }
                    <SvgIcon onClick={ () => { this.goToDelivery( this.props.relatedId ) } } className="related-icon" icon="edit" type="solid" />
                </dd>
            </dl>
        }
        else if( this.props.relatedType === 'po' )
        {
            related = <dl className="dl-horizontal">
                <dt>Ostotilauksen numero</dt>
                <dd>
                    { this.props.relatedNumber }
                    <SvgIcon onClick={ () => { this.goToPurchaseOrder( this.props.relatedId ) } } className="related-icon" icon="edit" type="solid" />
                </dd>
            </dl>
        }

        let secondRelated = null;
        if( this.props.secondRelatedType === 'order' )
        {
            secondRelated = <dl className="dl-horizontal">
                <dt>Tilauksen numero</dt>
                <dd>
                    { this.props.secondRelatedNumber }
                    <SvgIcon onClick={ () => { this.goToOrder( this.props.secondRelatedId ) } } className="related-icon" icon="edit" type="solid" />
                </dd>
            </dl>
        }

        let senderType = '';
        if( this.props.senderType === 'location' )
            senderType = <span><SvgIcon className="type-icon" icon="archive" type="solid" /> Varasto </span>
        else if( this.props.senderType === 'project' )
            senderType = <span><SvgIcon className="type-icon" icon="folder" type="solid" /> Projekti </span>
        else if( this.props.senderType === 'supplier' )
            senderType = <span><SvgIcon className="type-icon" icon="building" type="solid" /> Yritys </span>
        else if( this.props.senderType === 'other' )
            senderType = <span><SvgIcon className="type-icon" icon="dot-circle" type="solid" /> Muu</span>

        let receiverType = '';
        if( this.props.receiverType === 'location' )
            receiverType = <span><SvgIcon className="type-icon" icon="archive" type="solid" /> Varasto </span>
        else if( this.props.receiverType === 'project' )
            receiverType = <span><SvgIcon className="type-icon" icon="folder" type="solid" /> Projekti </span>
        else if( this.props.receiverType === 'company' )
            receiverType = <span><SvgIcon className="type-icon" icon="building" type="solid" /> Yritys </span>
        else if( this.props.receiverType === 'other' )
            receiverType = <span><SvgIcon className="type-icon" icon="dot-circle" type="solid" /> Muu</span>


        return <div id="arrivalSummary">
            <div>
                { editButton }
            </div>
            <div className="summaryBox">
                <div className="apForm">
                    <div className="apFormColumn">
                        <dl className="dl-horizontal">
                            {numberElement}
                            <dt>Tavaran vastaanottaja</dt>
                            <dd>{ this.props.creatorName }</dd>
                        </dl>

                        <dl className="dl-horizontal">

                            <dt> Lähettäjä </dt>
                            <dd> { senderType } </dd>

                            <dt></dt>
                            <dd> { this.props.senderTitle } </dd>
                        </dl>

                        <dl className="dl-horizontal">

                            <dt>Vastaanottaja</dt>
                            <dd> { receiverType } </dd>

                            <dt></dt>
                            <dd> { this.props.receiverTitle } </dd>
                        </dl>

                    </div>
                    <div className="apFormColumn">
                        { related }
                        { secondRelated }
                        { orderDetails }
                        <dl className="dl-horizontal">
                            <dt>Kommentti</dt>
                            <dd>{this.props.comment}</dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default DataSummary;
