import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import './CompanySettings.css';

import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApRadioSwitch from 'common/ApRadioSwitch/ApRadioSwitch.js'
import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import ApButton         from 'common/ApButton/ApButton.js';
import { tr } from 'services/Helpers/Helpers.js';
import { ApInputStack } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import { connect } from 'react-redux';



class CompanyDefaults extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            hasChanges: false,
            settings: false,

            default_reader: tr('barcode_scanner'),
            default_senderStorage_type: tr('storage'),
            default_receiverStorage_type: tr('storage'),

            default_senderStorage: '',

        };

        autoBind(this); 
    }

    componentDidMount()
    {
        this.getCompanySettings();
    }

    getCompanySettings()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'company/settings/defaults',
        }).then(( response ) => {
            let defaultReader = tr('qr_code_scanner')

            if (response.default_reader === 0) {
                defaultReader = tr('barcode_scanner')
            }

            if (response.default_reader === 2) {
                defaultReader = tr('collection')
            }

            this.setState({ 
                settings: response,
                loading: false,
                
                default_reader: defaultReader,
                default_senderStorage_type: response.default_sender_type === 0 ? tr('storage') : tr('project_storage'),
                default_receiverStorage_type: response.default_receiver_type === 0 ? tr('storage') : tr('project_storage'),
                default_senderStorage: response.default_sender_storage === 0 ? '' : response.default_sender_storage
            });
            this.props.saveCompanySettings({
                ...response,
                default_senderStorage_type: response.default_sender_type === 0 ? tr('storage') : tr('project_storage'),
                default_receiverStorage_type: response.default_receiver_type === 0 ? tr('storage') : tr('project_storage'),
                default_senderStorage: response.default_sender_storage === 0 ? '' : response.default_sender_storage
            });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    saveSettings() 
    {
        let defaultReader = 1

        if (this.state.default_reader === tr('barcode_scanner')) {
            defaultReader = 0
        }

        if (this.state.default_reader === tr('collection')) {
            defaultReader = 2
        }


        const settings = {
            ...this.state.settings,
            default_reader: defaultReader,
            default_sender_type: this.state.default_senderStorage_type === tr('storage') ? 0 : 1,
            default_receiver_type: this.state.default_receiverStorage_type === tr('storage') ? 0 : 1,
            default_sender_storage: this.state.default_senderStorage ? this.state.default_senderStorage.id : 0,
        }

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'company/settings/defaults/save',
            data: settings,
        }).then(( response ) => {
            auth.reloadSettings();

            this.setState({ 
                loading: false,
                hasChanges: false,
            });
            
            if (response.default_sender_storage === 0) {
                this.setState({default_senderStorage: ''})
            }
            
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    handleChange( key, value )
    {
        let settings = { ...this.state.settings };
        settings[ key ] = value;
        this.setState({ settings, hasChanges: true });
    }

    handleChangeSwitch( key )
    {
        let settings = { ...this.state.settings };
        settings[ key ] = !settings[ key ];
        this.setState({ settings, hasChanges: true });
    }

    handleRadioChange(option, type)
    {
        this.setState({[type]: option, hasChanges: true})
    }


    render() 
    {
        const getRelatedSwitch = ( args ) => {
            const sName = `${ args.related }_${ args.type }`;
            return <div className="apSwitchBlock">
                <label htmlFor={`${sName}-switch`} className="info">
                    { args.title }
                    <small> { args.sTitle }</small>
                </label>
                <ApSwitch
                    id={`${sName}-switch`}
                    on={ !!this.state.settings[ sName ] }
                    onChange={ () => { this.handleChangeSwitch(sName) } }
                    disabled={ this.props.loading }
                />
            </div>
        }

        const getRelatedRadioSwitch = ( props ) => {
            if (props.disabled && this.state.settings.project_storage_enabled === false) return null

            return <div className='apSwitchBlock'>
                <label style={{display: 'flex', flexDirection: 'column', marginTop: '1em'}}>
                    { props.title }
                    <small> { props.sTitle }</small>
                </label>

                <ApRadioSwitch
                    options={props.options}
                    checked={this.state[props.type]}
                    onClick={(e) => { this.handleRadioChange(e, props.type)} }
                />
            </div>
        }
        
        return (
            <div className="apBox" id="pageCompanySettings">
                
                
                <div className="clear">
                	<div className="padding">
                	<h2><SvgIcon icon="cubes" type="solid" /> { tr('storage_components') } </h2>
                    { tr('storage_components_settings') }
                   </div>
                   <div className="apColumn w50">
                   <div className="padding">
                   <h3>{ tr('default_settings') }</h3>
                   { tr('default_settings_info') }
                    { getRelatedSwitch({
                        related: 'component',
                        type: 'save_as_new_default',
                        title: tr('save_external_component'),
                        sTitle: tr('save_external_component_info'),
                    }) }
                    { getRelatedSwitch({
                        related: 'component',
                        type: 'in_timetracking_default',
                        title: tr('by_default_in_timetracking'),
                        sTitle: tr('storage_component_added_to_project_note'),
                    }) }
                    </div>
                    </div>
                    <div className="apColumn w50">
                    <div className="padding">
                    <h3>{ tr('specifications') }</h3>
                    { tr('specifications_info') }
                    { getRelatedSwitch({
                        related: 'component',
                        type: 'in_timetracking_override',
                        title: tr('timetracking_always_on'),
                        sTitle: tr('timetracking_always_on_info'),
                    }) }
                    </div>
                    </div>
                </div>


                <div className="clear">
                	<div className="padding">
                	<h2><SvgIcon icon="barcode" type="solid" /> { tr('storage_scanner') }</h2>
                   { tr('qr_barcode_scanner_settings') }
                   </div>
                   <div className="apColumn w50">
                   <div className='padding'>
                   <h3>{ tr('default_settings') }</h3>
                   { tr('select_default_settings') }
                    {getRelatedRadioSwitch({
                        related: 'codereader',
                        type: 'default_reader',
                        title: tr('default_reader'),
                        sTitle: tr('default_reader_info'),
                        options: [tr('barcode_scanner'), tr('qr_code_scanner'), tr('collection')],

                    })}


                    {getRelatedRadioSwitch({ 
                        related: 'codereader',
                        type: 'default_senderStorage_type',
                        title: tr('sender_storage_type'),
                        sTitle: tr('sender_storage_type_info'),
                        disabled: true,
                        options: [tr('storage'), tr('project_storage')],

                    })}
                    {getRelatedRadioSwitch({ 
                        related: 'codereader',
                        type: 'default_receiverStorage_type',
                        title: tr('receiver_storage_type'),
                        sTitle: tr('receiver_storage_type_info'),
                        disabled: true,
                        options: [tr('storage'), tr('project_storage')],

                    })}
                    </div>
                    </div>
                    <div className="apColumn w50">
                    <div className="padding">
                    <h3>{ tr('specifications') }</h3>
                    { tr('specifications_info2') }

                    {this.state.settings.project_storage_enabled === true ? 
                        <div>
                            <label style={{display: 'flex', flexDirection: 'column', marginTop: '1em'}}>
                                { tr('default_sender_storage') }
                                <small>{ tr('default_sender_storage_info') }</small>
                            </label>

                            <ApInputStack gap="0">
                                <ApSelect
                                    label={ tr('sender_storage') }
                                    value={ this.state.default_senderStorage.name ? this.state.default_senderStorage.name : '' }
                                    optionRenderer={ this.state.default_senderStorage_type === tr('storage') ? 'storage_location_address' : 'project'}
                                    onChange={ e => this.setState({default_senderStorage: e, hasChanges: true}) }
                                    objKeyId="id"
                                    clearable
                                    apiUrl={this.state.default_senderStorage_type === tr('storage') ? 'storage/locations/find' : 'storage/delivery/findprojects'}
                                    apiData={{
                                        //projectStorage: true, 
                                        canSend: true,
                                    }}
                                />
                            </ApInputStack>
                        </div> : null}

                    </div>
                    </div>
                </div>


                <div className="padding">
                    <ApButton color="blue" onClick={ this.saveSettings } disabled={ this.state.loading || !this.state.hasChanges } loading={ this.state.loading }>
                        <SvgIcon icon="save" type="solid" />
                        { tr('save') }
                    </ApButton>
                </div>

            </div>
        );
    }
}


CompanyDefaults.propTypes = {
    // number:       PropTypes.number.isRequired,
    // string:       PropTypes.string,
    // multiple:     PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
    // bool:         PropTypes.bool,
};

const mapStateToProps = state => ({
	apGeneralSettings: state.apGeneralSettings
});

const mapDispatchToProps = dispatch => ({
    saveCompanySettings: (settings) => dispatch({type: 'SAVE_GENERAL_SETTINGS', payload: settings})
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDefaults);
