import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import moment from 'moment';

import './ReceiptModal.css';

import ApButton from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import File from 'common/File/File.js';
import { statusText, timetrackingIcon, timetrackingIconColor } from  'modules/Timetracking/TimetrackingHelpers.js';
import { tr, currentLang } from 'services/Helpers/Helpers';

const statusOptions = [
    { id: 'open', label: tr('open') },
    { id: 'submitted', label: tr('sent') },
    { id: 'rejected', label: tr('rejected') },
    { id: 'approved', label: tr('approved') },
    { id: 'confirmed', label: tr('confirmed') },
    { id: 'payrolled', label: tr('payrolled') },
    { id: 'paid', label: tr('paid') },
];

export default class ReceiptModal extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			loading: false,
			person: null,
            project: null,
            date: null,
            details: null,
            files: [],
            expenseRows: [],
            total_money: null,
			currency: null,
			status: null,
			newStatus: null,
			updated_at: null,
			edited:false,
		};

		autoBind(this);

	}

	componentDidUpdate(prevProps)
	{

        if ((prevProps.itemId !== this.props.itemId) && this.props.itemId) {
            this.getReceiptDetails(this.props.itemId);
        }
        // this.getNewsitem(this.props.itemId);

	}

	getReceiptDetails(id)
	{

		this.setState({ loading: true })
		api({
			method: 'post',
            url: 'timetracking/expenses/details',
            data: {
                id: id
            }
		}).then((response) => {
			this.setState({ 
				person: response.person.first_name + " " + response.person.last_name,
                project: response.project.name + " (" + response.project.project_code + ")",
                expenseRows: response.rows,
                total_money: response.total_money,
                currency: response.currency,
                currency_rate: response.currency_rate,
                date: moment( response.date ).format( 'D.M.YYYY' ),
                details: response.details,
				files: response.files,
				status: response.status,
				newStatus: response.status,
				updated_at: response.updated_at,
				loading: false,
				edited:false,
			});
		}).catch((error) => {
			console.log(error);
			this.setState({
				loading: false
			})
		});

	}

	saveItem()
	{

		api({
			method: 'post',
			url: 'management/news/saveNewsItem',
			data: {
				id: this.props.itemId,
				header: this.state.header,
				content: this.state.content,
				limited: this.state.limited
			}
		}).then((response) => {
			//console.log("save successful");
			this.setState({ edited: false });
			this.props.onClose();
			this.clear();
		}).catch((error) => {
			console.log(error);
		});

	}

	deleteItem()
	{

		api({
			method: 'post',
			url: 'management/news/deleteNewsItem',
			data: {
				id: this.props.itemId,
			}
		}).then((response) => {
			//console.log("delete successful");
			this.props.onClose();
		}).catch((error) => {
			console.log(error);
		});

	}

	clickSaveButton()
	{
		this.saveItem();
	}

	clickCancelButton()
	{
		this.props.onClose();
		this.clear();
	}

	clear()
	{
		this.setState({
			loading: false,
			person: null,
            project: null,
            date: null,
            details: null,
            files: [],
            expenseRows: [],
            total_money: null,
			currency: null,
			status: null,
			newStatus: null,
			updated_at: null
		})
	}

	handleChange(e)
	{
		this.setState({ [e.target.name]: e.target.value });
	}

	handleSave()
	{
		api({
			method: 'post',
			url: 'timetracking/expenses/savestatus',
			data: {
				id: this.props.itemId,
				status: this.state.newStatus
			}
		}).then((response) => {
			//console.log("save successful");
			this.props.onSave();
		}).catch((error) => {
			console.log(error);
		});

	}

	render()
	{

        const fileRows = this.state.files.map( ( i, index ) => {
            return <tr key={ i.id }>
                <td>
                    <File  fileId={ i.id } name={ i.original_name }>
                        <SvgIcon icon="file" type="solid" /> { i.name ? i.name : i.original_name }
                    </File>
                </td>
            </tr>
        });

        const expenseRows = this.state.expenseRows.map( (row, index) => {
            return(
            <tr style={{borderBottom: '1px solid #ccc'}} key={`${row}-${index}`}>
                <td>{row.description}</td>
                <td>{row.cost}</td>
            </tr>)
		})
		
		const status  = statusOptions.find( t => t.id === this.state.newStatus );

		return(


			<ApModal
				show={this.props.show}
				backdrop="static"
				className="narrow"
				header={ 
					<div className="padding-small">
						{ !this.state.loading ?
						<h4>{ tr('receipt_report') } - {this.state.person}</h4>
						:
						<div className="apLoader"></div>
						}
					</div>
				}
				body={
					<div className="padding">
						{ !this.state.loading ?
						<div>
                        <div><b>{ tr('project') }:</b> {this.state.project}</div>
                        <div><b>{ tr('date') }:</b> {this.state.date}</div>

                        <div className="receipt-divider"></div>

                        <table style={{width: '100%'}}>
							<tbody>
                            <tr style={{borderBottom: '1px solid #ccc'}}>
                                <th><b>{ tr('description') }</b></th>
                                <th style={{width: '80px'}}><b>{ tr('price') }</b></th>
                            </tr>
                            {expenseRows}
							<tr>
								<td style={{textAlign: 'right'}}><b>{ tr('in_total') }:&nbsp;</b></td>
								<td><b>{`${this.state.total_money} ${this.state.currency}`}</b></td>
							</tr>
							</tbody>
                        </table>

                        <div className="receipt-divider"></div>

                        { this.state.details && this.state.details.event && 
                        <div>
                        <div><b>{ tr('occasion') }:</b> {this.state.details.event}</div>
						<div><b>{ tr('additional_info') }:</b> {this.state.details.event_details ? this.state.details.event_details : "-"}</div>
						<div className="receipt-divider"></div>
                        </div>
                        }
                        <div><b>{ tr('account_number') }:</b> {this.state.details && this.state.details.iban}</div>

						<div className="receipt-divider"></div>

                        <b>{ tr('attachments') }</b> 
						<table>
							<tbody>
								{fileRows}
							</tbody>
						</table>
						

						<div className="receipt-divider"></div>

						<b>{ tr('status') }:</b> {` ${statusText(this.state.status)} `}
						{this.state.status &&
						<span className={ `apBadge ${timetrackingIconColor(this.state.status)}` } style={{marginRight: 5}}>
							<SvgIcon className="small-inline" icon={timetrackingIcon(this.state.status)} type="solid" />
						</span>
						}
						<div><b>{ tr('updated_at') }:</b> { moment( this.state.updated_at ).locale(currentLang()).format('LLL')}</div>

						<div className="receipt-divider"></div>

						<ApSelect
							label={ tr('change_status') }
							loading={ this.props.loading }
							disabled={ this.props.loading }
							value={ status }
							onChange={ ( option ) => this.setState({ newStatus: option.id ,edited:true})}
							options={ statusOptions }
							optionRenderer="label"
							objKeyId="id"
							objKeySearchable="label"
							objKeyValue="label"
						/>
						</div>
						:
						<div className="apLoader"></div>
					}
					</div>
				}
				footer={
					<div className="padding" style={{display: 'flex', flexDirection: 'row'}}>
		
					<ApButton 
						className="cancel" 
						onClick={() => {this.props.onClose()}}
						loading={ this.state.loading }
					>
						<SvgIcon icon="times" type="solid" />
						{ tr('cancel') }
					</ApButton>
		
					<ApButton
						className={"save" + (this.state.edited && !this.state.loading ? " highlight" : "")}
						color="blue"
						onClick={ this.handleSave }
						loading={ this.state.loading }
						style={{marginLeft: 'auto'}}
					>
						<SvgIcon icon="save" type="solid" />
						{ tr('save') }
					</ApButton>
					
					</div>
				  }
			/>

		);

	}

}