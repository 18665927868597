import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import { errorPopper }  from 'services/Helpers/Helpers.js'

class ComponentMove extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
           
        }

        this.steps = [{
            name: "Tilauksen komponenttien siirto",
            render: this.renderInfo.bind( this ),
        }];

        autoBind(this);
    }

    save()
    {
        const comps = this.mapComponentsNeeded();
        
        let data = {
            components: comps,
            prev_receiver_id: this.props.data.previous_rec.id,
            poId: this.props.data.poId,

            receiver_id: this.props.data.receiver_id,
            receiver_type: this.props.data.receiver_type,
            receiver_name: this.props.data.receiver_name,
            receiver_address: this.props.data.receiver_address,
            receiver_zipcode: this.props.data.receiver_zipcode,
            receiver_city: this.props.data.receiver_city,
            receiver_country: this.props.data.receiver_country,
        };
    
        
        return api({
            method: 'post',
            url: 'storage/po/move',
            data: data,
        }).then( ( response ) => {
            console.log(`Response after ComponentSave was moved: ${JSON.stringify(response, null, 4)}`);

            if (response == 0) {
                window.emitter.emit('popper', {
                    type: 'warning',
                    content: <strong>Varastoa ei löytynyt</strong>,
                });
                return;
            } else if (response == 1) {
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>Komponentit siirretty</strong>,
                });
            } else if (response == 2) {
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>Osa komponenteista siirretty</strong>,
                });
            }
            if( typeof this.props.onSave === 'function')
                this.props.onSave( response );
        }).catch((error) => {
            errorPopper(error, 'Komponenttien siirtämisen kanssa esiintyi ongelmia.');
        });
        
    }

    renderInfo()
    {
        //console.log(`renderInfo DATA: ${JSON.stringify(this.props, null, 4)}`);
        const prevRec = this.props.data.previous_rec;
        const currRec = this.props.data.current_rec;
        if (prevRec && currRec) {
            return <div className="stepSupplier">
            <div className="supplier-select">
                <h6>Huomio: Tämä toimenpide varmistaa että komponentit siirretään aiemmin säädetystä varastosta uuteen.</h6> 
                <p>Aikaisempi {prevRec.type == 'P' ? 'Projekti' : ''}varasto: [{prevRec.code} - {prevRec.name}]</p> 
                <p>Uusi {prevRec.type == 'P' ? 'Projekti' : ''}varasto: [{currRec.code} - {currRec.name}]</p>
            </div>

            <div style={{borderTop: '2px solid black' }}>
                {this.renderComponentView()}
            </div>
        </div>
        } else {
            return '';
        }
    }

    formatSum(sum, toFixed) {
        if (toFixed) {
            return parseFloat(sum.replace(/\s/g, '').replace(',', '.')).toFixed(2);
        }
        return parseFloat(sum.replace(/\s/g, '').replace(',', '.'));
    }

    renderComponentView() {

        const mapped = this.mapComponentsToMove();

        return <div className="treeContainer">
            <div className="left">

            <table className="componentTable">
            <thead><tr>                          
                    <td><h5>Komponentit</h5></td>
                    </tr>
                </thead>
                    <thead>
                        <tr>
                            <th className="fixed">Id</th>
                            <th className="fixed">Nimi</th>
                            <th className="fixed">Saapunut</th>
                            <th className="fixed" style={{paddingLeft: '1em'}}>Vielä tilauksessa</th>
                        </tr>
                    </thead>
                    <tbody>
                    { mapped }
                    </tbody>                                      
            </table>
            </div>
        </div>
    }

    mapComponentsToMove() {
        let mappedData = [];

        //console.log(`Components: ${JSON.stringify(this.props.data.components, null, 4)}`);

        this.props.data.components.map(( c ) => { 
                mappedData.push(<tr key={ c.id } className={ "c" }>
                    <td width= "60" className="id">
                        {  c.component_id != null ? c.component_id : 'U' }
                    </td>

                    <td width= "200" className="name">
                        {  c.name  }
                    </td>

                    <td width= "60" align="right" style={{ marginRight: '1em' }} className="arrived">
                        { c.purchase_order_arrived }
                    </td>
                    
                    <td width= "60" align="right" style={{ marginRight: '1em' }}className="missing">
                        { c.purchase_order_missing }
                    </td>
                </tr>);
                
            });

            return mappedData;
    }

    mapComponentsNeeded() {
        let mappedData = [];

        this.props.data.components.map(( c ) => {
            mappedData.push({
                component_id: c.component_id,
                name: c.name,
                purchase_order_arrived: c.purchase_order_arrived,
                purchase_order_missing: c.purchase_order_missing,
                location_id: c.target_final_id,
                instance_id: c.id
            })
        });

        return mappedData;
    }
    render()
    {
        return <div id="newPo">
            <ApModalWizard
                header="Tilauksen komponenttien siirto"
                show={ this.props.show  }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.save }
            />
        </div>
    }

}

export default ComponentMove;