import ApReactTable from "common/ApReactTable/ApReactTable";
import React from "react";
import autoBind from "react-autobind";
import api  from 'services/Api/Api.js';
import { errorPopper, sqlToDateInput, tr } from "services/Helpers/Helpers";

class ArchivedTransports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: [],
        }

        autoBind(this);
    }

    componentDidMount() {
        this.setState({loading: true});

        api({
            method: 'get',
            url: 'storage/delivery/archivedTransportList',
        }).then((response) => {
            //console.log('data: ', response);
            this.setState({
                data: response,
                loading: false
            })
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            console.error(error);
            this.setState({
                data: [],
                loading: false
            })
        });
    }

    getColumns()
    {
        return [
        {
            id: 'number',
            Header: tr('number'),
            accessor: 'number',
            filterable: true,
            sortable: true,
            onClick: row => this.viewTransport( row.id ),
            customFilter: {
                type: "text",
                placeholder: tr('number'),
            },
            width: 75,
        },
        {
            id: 'date',
            Header: tr('date'),
            accessor: 'created_at',
            filterable: false,
            sortable: false,
            width: 150,
            onClick: row => this.viewTransport( row.id ),
            Cell: props => {
               return sqlToDateInput( props.value );
            },
        },
        {

            id: 'sender_name',
            Header: tr('sender'),
            className: "overflowableOnHover",
            accessor: 'sender_name',
            filterable: true,
            sortable: true,
            onClick: row => this.viewTransport( row.id ),
            customFilter: {
                type: "text",
                placeholder: tr('sender'),
            },

        },
        {
            id: 'receiver_name',
            Header: tr('recipient'),
            className: "overflowableOnHover",
            accessor: 'receiver_name',
            filterable: true,
            sortable: true,
            onClick: row => this.viewTransport( row.id ),
            customFilter: {
                type: "text",
                placeholder: tr('recipient'),
            },
        },
        {
            id: 'comment',
            Header: tr('comment'),
            accessor: 'comment',
            filterable: true,
            sortable: true,
            width: 200,
            onClick: row => this.viewTransport( row.id ),
            customFilter: {
                type: "text",
                placeholder: tr('comment'),
            },
        }];
    }

    viewTransport( id )
    {
        sessionStorage.setItem('transportId',id);
        window.emitter.emit( 'goTo', { pathname: `/storage/transports/edit` } );
    }

    render() {
        return (
            <ApReactTable
                loading={ this.state.loading }
                data={ this.state.data }
                columns={ this.getColumns() }
                showFiltersInitially={true}
                filterable={true}
                // rowActions={[
                //     {
                //         icon: "eye",
                //         label: tr('show'),
                //         action: this.viewTransport,
                //     },
                // ]}
            />
        )
    }
}

export default ArchivedTransports;