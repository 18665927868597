import { tr } from 'services/Helpers/Helpers';

/**
 * Get "from" from storage_component_stock_history and get a human readable description
 * 
 * @param {String} from 
 * @returns {String}
 */
export function getFromDescription(from) {
    const translations = {
        'arrival': tr('logistics_arrivals'),
        'arrival-fixed': tr('arrival_fixed'), // Duplicate Arrivals fixed from console command
        'assignment-billing': tr('assignment_installation'),
        'assignment-installation': tr('assignment_installation'),
        'component_edit': tr('component_manual_edit'),
        'consumables': tr('consumables_use'),
        'delivery-from-location': tr('logistics_deliveries'),
        'delivery-from-project': tr('logistics_deliveries'),
        'excel_import': tr('excel_import'),
        'inventory': tr('inventory'),
        'project_management': tr('project_installation'),
        'project_management-add-storage': tr('project_added_component'),
        'project_management-production': tr('component_manufacturing'),
        'project_management_update': tr('project_installation'),
        'puchase_order-arrival': tr('logistics_arrivals'),
        'reverse_delivery-to-company': tr('reverse_delivery_to_company'),
        'stock_balance_fix': tr('stock_balance_fix'), // Automatic stock balance fix from console command
        'storage_transport': tr('storage_transfer'),
        'storage_transport_revert': tr('storage_transfer_revert'),
        'timetracking-add': tr('component_installation'),
        'timetracking-delete': tr('timetracking_delete'),
        'timetracking-update': tr('component_installation'),
    }
    return translations[from] || from;
}
