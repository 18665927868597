/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import api from 'services/Api/Api.js';

import './TimeAttendance.css';
import {
    Container, Row, Col
} from 'react-bootstrap';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import _ from 'lodash';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApModal from 'common/ApModal/ApModal.js';


import { tr } from 'services/Helpers/Helpers.js';
import ApButton from 'common/ApButton/ApButton';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import TimeAttendanceSavePoints from './TimeAttendanceSavePoints';

export default class TimetrackingTimeAttendance extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            
            savePoints: [],
            devices:[],
            showEditRowData:[],


        };
        autoBind(this); 
    }

    componentDidMount() {

        // const params = this.props.match.params;
        
        // if( params.date_start || params.date_end )
        //     this.setState({
        //         date_start: params.date_start || null,
        //         date_end: params.date_end || null
        //     }, this.getEntries );
        
        this.getSavePointsAndDevices();
    }

    getSavePointsAndDevices()
    {
        
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'timetrackin/timeAttendance/getTimeAttendanceSavePointsAndDevices',
            errorPopper: tr('get_error')
        }).then(( response ) => {
            
            this.setState({
                loading: false,
                savePoints: response.savePoints,
                devices: response.devices,
            });

        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    
    showEditRow(row) {
        let rowdata = _.cloneDeep(row);
        this.setState({ showEditRow: true, showEditRowData: rowdata });
    }

    getDeviceColumns()
    {
        let cols = [];
        cols.push({
            Header: tr("name"),
            id: 'name',
            //width: 50,
            // onClick: this.showEditRow,
            filterable: true,
            sortable: true,
            accessor: 'device.name',
            //className: "overflowableOnHover",
            Cell: props => {
                return props.original.device.name;
            },
            customFilter: {
                type: "text",
                placeholder: tr('enter_name'),
                fields: ["_original.device.name"]
            },
        });
        cols.push({
            Header: tr("device_updated_at"),
            id: 'device_updated_at',
            //width: 50,
            // onClick: this.showEditRow,
            filterable: true,
            sortable: true,
            accessor: 'updated_at',
            //className: "overflowableOnHover",
            Cell: props => {
                return props.original.updated_at;
            },
            customFilter: {
                type: "text",
                placeholder: tr('enter_name'),
                fields: ["_original.device.name"]
            },
        });
        cols.push({
            Header: tr("ip_address"),
            id: 'ip_address',
            //width: 50,
            // onClick: this.showEditRow,
            filterable: true,
            sortable: true,
            accessor: 'ip_address',
            //className: "overflowableOnHover",
            Cell: props => {
                return props.original.ip_address;
            },
            customFilter: {
                type: "text",
                placeholder: tr('enter_name'),
                fields: ["_original.ip_address"]
            },
        });

        return cols;
    }

    editRowData(value,key) {
        let data = {...this.state.showEditRowData};
        if (data.last_edit == null) {
            data.last_edit = {};
        }
        data.last_edit[key] = value;
        this.setState({
            showEditRowData: data
        });
    }

    editModalSave() {
        let data = this.state.showEditRowData.last_edit;
        data.save_point_id = this.state.showEditRowData.id;

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetrackin/timeAttendance/timeAttendanceSavePointsEdit',
            data:data,
            errorPopper: tr('get_error')
        }).then((response) => {
            let rows = this.state.savePoints;
            let row = rows.find(r => r.id == response.save_point_id);
            row.last_edit = response;
            this.setState({
                loading: false,
                savePoints: rows,
                showEditRow:false,
            });

        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    renderEditModal() {
        let data = {...this.state.showEditRowData};
        return <ApModal
            show={this.state.showEditRow}
            handleClose={() => this.setState({ showEditRow: false, })}
            className="editModal overflow"
            header={
                <div className="padding">
                    <h1>
                        {tr('edit_time_attendance_row')}
                    </h1>
                    {tr("date") + ": " + moment(this.state.showEditRowData.date).format("L")}
                </div>
            }
            body={<Container fluid={true} className="padding">

                <Row>
                    <Col sm={6}>
                        <ApInput
                            type="datetimeV2"
                            label={tr('timestamp_start')}
                            //className="text-right"
                            value={data.last_edit && data.last_edit.timestamp_start ? moment(data.last_edit.timestamp_start).toISOString() : moment(data.timestamp_start).toISOString()}
                            clearable={Boolean(data.last_edit && data.last_edit.timestamp_start)}
                            onChange={(e, r, t) => {
                                let data = t ? t.toISOString() : null;
                                this.editRowData(data, "timestamp_start")
                            }}
                            // onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                            enableTime
                        />
                    </Col>
                    <Col sm={6}>
                        <ApInput
                            type="datetimeV2"
                            label={tr('timestamp_end')}
                            //className="text-right"
                            value={data.last_edit && data.last_edit.timestamp_end ? moment(data.last_edit.timestamp_end).toISOString() : moment(data.timestamp_end).toISOString()}
                            clearable={data.last_edit && data.last_edit.timestamp_end}
                            onChange={(e, r, t) => {
                                let data = t ? t.toISOString() : null;
                                this.editRowData(data, "timestamp_end")
                            }}
                            // onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                            enableTime
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <ApInput
                            type="text"
                            label={tr('device_name')}
                            
                            value={data.last_edit && data.last_edit.device_name? data.last_edit.device_name : data.device_name}
                            clearable
                            onChange={(e) => this.editRowData(e.target.value, "device_name")}
                            // onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                        />
                        
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <ApInput
                            type="textarea"
                            label={tr('comment')}

                            value={data.last_edit && data.last_edit.comment ? data.last_edit.comment : ""}
                            clearable
                            onChange={(e) => this.editRowData(e.target.value, "comment")}
                        // onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                        />

                    </Col>
                </Row>
            </Container> }
            footer={
                <div className="padding">
                    <ApButton color="blue" onClick={this.editModalSave} loading={this.state.loading} style={{ float: 'right' }}>
                        <SvgIcon icon="save" type="solid" />
                        {tr("save")}
                    </ApButton>
                </div>
            }
        />
    }

    render() 
    {
        
        return (
            <div className="apBox" id="pageTimetrackingTimeAttendance">
                <div className="apBoxHeader">
                    <h1>{ tr('working_hours_overview') }</h1>
                    <p>{ tr('working_hours_overview_info') }</p>
                </div>
               
                <ApTabs>
                    <ApTab icon="cogs" label={tr('timestamps')}>
                        <div className="padding">
                            <TimeAttendanceSavePoints 
                                data={this.state.savePoints} 
                                userReport={false} 
                                rowClickFunc={this.showEditRow} 
                                loading={this.state.loading} 
                            />
                        </div>
                    </ApTab>
                    <ApTab icon="cogs" label={tr('devices')}>
                        <div className="padding">

                            <ApReactTable
                                columns={this.getDeviceColumns()}
                                data={this.state.devices}
                                loading={this.state.loading}

                                defaultPageSize={10}
                                noDataText={tr('no_rows')}

                            //multiselect={[]}
                            />
                        </div>

                    </ApTab>
                </ApTabs>
                    
               
                    
               
                {this.renderEditModal()}
            </div>
        );
    }
}
