/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import ApSteps from 'common/ApSteps/ApSteps.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';

import Files from './Files/Files.js';
import InfoBar from './InfoBar/InfoBar.js';
import ProjectSections from './ProjectSections/ProjectSections.js';
import { tr } from 'services/Helpers/Helpers.js';

import './Head.css';

class Head extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            filesModalShow: false,
        };
        autoBind(this);
    }

    getSteps()
    {
        const project = this.props.project;
        if( !project ) return [];

        let steps = [];
        if( project.ancestors )
        {
            project.ancestors.forEach(( item ) => {
                steps.push({
                    id: item.id,
                    name: item.name,
                    description: item.project_code,
                    onClick: () => {
                        window.emitter.emit( 'goTo', {
                            pathname: `/projects/${ item.id }/${ this.props.form }`,
                        });
                    },
                })
            });
        }
        steps.push({
            id: project.id,
            name: project.name,
            description: project.project_code,
            children: project.children,
            selected: true,

            customRenderer: this.withDropdown,

        });
        return steps;
    }

    getFileButton()
    {
        if( this.props.loading ) return null;
        let filesCountEl = null;
        if (this.props.project?.files_count) {
            let badgeClasses = [ "apBadge", "apBadgeCorner" ];
            filesCountEl = <span className={ badgeClasses.join(" ") }>{ this.props.project.files_count }</span>
        }
        return <div className="fileButtonContainer" onClick={ () => this.setState({ filesModalShow: true }) }>
            <ApTooltip text={ tr('files') }>
                <ApButton className="fileButton" color="white">
                    <SvgIcon icon="file-alt" type="solid" /> {filesCountEl}
                </ApButton>
            </ApTooltip>
        </div>
    }

    getContent()
    {
        const form = this.props.form;
        const project = this.props.project;

        if( !project )
            return <div  className="loaderContainer">
                <div className="apInlineLoader"></div>
            </div>

        if( form === 'offer' && !project.has_offer )
            return <div className="padding">
                <div className="apWarningMsg">
                    <h2>{ tr('no_offer_calculation') }</h2>
                    <p>{ tr('no_offer_calculation_info') }</p>
                </div>
            </div>

        if( form !== 'offer' && project.in_offer )
            return <div className="padding">
                <div className="apWarningMsg">
                    <h2>{ tr('in_offer_calculation') }</h2>
                    <p>{ tr('in_offer_calculation_info') }</p>
                </div>
            </div>

        return this.props.content;
    }



    withDropdown( item )
    {
        let dropdown = null;


        let classes = [ 'inner', 'current' ];

        if( item.children && item.children.length > 0 )
        {
            classes.push( 'withdropdown' );

            let actions = item.children.map( p => {
                return {
                    label: <div className="selectSubPorject">
                        <div className="name">{ p.name }</div>
                        <div className="code">{ p.project_code }</div>
                    </div>,
                    tooltip: tr('select_subproject'),
                    icon: "external-link-square-alt",
                    disabled: this.props.loading,
                    action: ( item, closeFunc ) => {
                        window.emitter.emit( 'goTo', {
                            pathname: `/projects/${ p.id }/${ this.props.form }`,
                        });
                        closeFunc();
                    },
                }
            });
            dropdown = <ApDropdown
                position="right"
                actions={ actions }
                button= {<div className="defaultButton">
                    <SvgIcon icon="angle-double-right" type="solid" /> 
                </div>}
            />
        }

        return <div className={ classes.join(' ')}>
            <strong>{ item.name }</strong>
            <small>{ item.description }</small>
            { dropdown }
        </div>
    };

    render()
    {
        const project = this.props.project;


        let filesDom = null;
        if( project)
        {
            let rootProjectId = project.id;

            if( project.ancestors && project.ancestors.length > 0 )
                rootProjectId = project.ancestors[ 0 ].id;

            filesDom = <Files
                show={ this.state.filesModalShow }
                onClose={ () => { this.setState({ filesModalShow: false } ) } }
                projectId={ rootProjectId }
                locked={ this.props.locked }
            />
        }

        return <div id="projectContainer">
            <ProjectSections
                form={ this.props.form }
                projectId={ parseInt( this.props.projectId, 10 ) }

                inOffer={ project ? project.in_offer : null}
                hasOffer={ project ? project.has_offer : null }
            />
            <div className="apBox">
                <div className="padding relative">
                    <ApSteps
                        className="mainProjectPath"
                        steps={ this.getSteps() }
                    />
                    <InfoBar
                        locked={ this.props.locked }
                        project={ project }
                        onChange={ this.props.onChange }
                        form={this.props.form}
                        chatUserCount={this.props.chatUserCount}
                        customer_mandatory={this.props.customer_mandatory}
                    />
                    { this.getFileButton() }
                </div>
                { this.getContent() }
            </div>
            { filesDom }
        </div>
    }

}

export default Head;
