import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import api from 'services/Api/Api.js';
import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApValidate from 'services/ApValidate/ApValidate.js';

import { ApInput
       , ApInputStack
       , ApAddon } from 'common/ApInput/ApInput.js';

import { dateInputToSql
       , errorPopper
       , tr } from 'services/Helpers/Helpers.js';

import ReceiverDataSelect from './ReceiverDataSelect/ReceiverDataSelect.js';
import './NewPurchaseOrder.css';

class NewPurchaseOrder extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {

            dm: [],
            dt: [],
            pc: [],
            dm_enabled: false,
            dt_enabled: false,
            pc_enabled: false,
            dm_required: false,
            dt_required: false,
            pc_required: false,
            recipient_required: true,
            storage_required: true,

            dm_selected_id: null,
            dt_selected_id: null,
            pc_selected_id: null,

            supplier: null,
            supplierDetails: null,
            company_id: null,


            receiver: null,
            receiverType: null,
            receiverId: null,
            receiverName: null,
            receiverAddress: null,
            receiverZipcode: null,
            receiverCity: null,
            receiverCountry: null,
            receiverContactName: null,
            saveAddressWhenSave:false,

            date: null,
            referenceYour: null,
            referenceOur: null,
            needDate: null,
            details: null,

            components: [],
        }

        let rfilter = {};
        ['dm', 'dt', 'pc'].forEach( r => {
            rfilter[ r ] = ( value ) => {
                if( !this.state[ `${ r }_enabled` ]) return true;
                if( !this.state[ `${ r }_required` ]) return true;
                return Boolean( value );
            }
        })

        this.validator = new ApValidate( this, {
            supplier: { filter: 'required', state: 'error', text: tr('supplier_required') },

            receiverType:    { filter: 'required', text: tr('no_recipient_selected') },
            receiverId:      { filter: ( value ) =>{
                const recValidation = (!this.state.storage_required && value == 'no_receiver');
                return (recValidation || value) ? true : false;
            }, text: tr('no_recipient_selected') },
            receiverName: { filter: ( value ) => {
                return (!this.state.storage_required || !this.state.recipient_required || value) ? true : false;
                }, text: tr('no_recipient_selected') },
            receiverAddress: { filter: ( value ) => {
                return (!this.state.storage_required || !this.state.recipient_required || value) ? true : false;
                }, text: tr('recipient_address_missing') },
            receiverZipcode: { filter: ( value ) => {
                return (!this.state.storage_required || !this.state.recipient_required || value) ? true : false;
                }, text: tr('recipient_postal_code_missing') },
            receiverCity: { filter: ( value ) => {
                return (!this.state.storage_required || !this.state.recipient_required || value) ? true : false;
                }, text: tr('recipient_city_missing') },

            dm_selected_id: { filter: rfilter['dm'], text: tr('delivery_method_not_selected') },
            dt_selected_id: { filter: rfilter['dt'], text: tr('delivery_terms_not_selected') },
            pc_selected_id: { filter: rfilter['pc'], text: tr('payment_term_not_selected') },

        });

        const oneIsInvalid = ( test ) => {
            let text = null;
            const invalid = test.some( field  => {
                text = this.validator.getText( field )
                return text;
            });
            if( invalid ) return text;
            return null;
        }

        this.steps = [{
            name: tr('supplier'),
            render: this.renderStepSupplier.bind( this ),
            invalid:  () => {
                return this.validator.getText( 'supplier' );
            }
        },{
            name: tr('recipient'),
            render: this.renderStepReceiver.bind( this ),
            invalid:  () => {
                return oneIsInvalid([
                    'receiverType',
                    'receiverId',
                    'receiverName',
                    'receiverAddress',
                    'receiverZipcode',
                    'receiverCity',
                ]);
            }
        },{
            name: tr('other_info'),
            render: this.renderStepOther.bind( this ),
            invalid:  () => {
                return oneIsInvalid([
                    'dm_selected_id',
                    'dt_selected_id',
                    'pc_selected_id',
                ]);
            }
        }];


        // this.checkNameExistsDebounced = debounce( 200, this.checkNameExists );
        autoBind(this);
    }

    resetState()
    {
        this.setState({
            dm_selected_id: null,
            dt_selected_id: null,
            pc_selected_id: null,

            supplier: null,
            supplierDetails: null,

            receiver: null,
            receiverType: null,
            receiverId: null,
            receiverName: null,
            receiverAddress: null,
            receiverZipcode: null,
            receiverCity: null,
            saveAddressWhenSave:false,

            date: moment().format('DD.MM.YYYY'),
            referenceYour: null,
            referenceOur: null,
            needDate: null,
            details: null,
            components: [],
        } );

        this.setDefaults();
        
        const data = this.props.data;
        if( !data ) return null;

        //console.log('dd', data );

        if( data.supplier )
        {
            this.setState({
                supplier: data.supplier,
                supplierDetails: data.supplier.purchase_order_details,
            });
        }

        if( data.components )
        {
            this.setState({
                components: data.components,
            });

        }

        if (data.receiverType) {
            this.setState({
                receiverType: data.receiverType,
                receiverTitle: data.receiverTitle,
                receiverName: data.receiverName,
                receiverAddress: data.receiverAddress,
                receiverCity: data.receiverCity,
                receiverZipcode: data.receiverZipcode,
                receiverContactName: data.receiverContactName,
                receiverCountry: data.receiverCountry,
                receiverId: data.receiverId,
                receiverProjectId: data.receiverProjectId,
            });
        }

    }

    UNSAFE_componentWillMount()
    {
        this.getRelated();
    }

    componentDidUpdate( prevProps )
    {
        /*
        // When popup opens
        if( this.props.show && !prevProps.show )
            this.resetState();
            */
        if (this.props.show && !prevProps.show && this.props.copyId) {
            api({
                method: 'get',
                url: `storage/po/id/${this.props.copyId}`,
            }).then( ( response ) => {
                // console.log("RES", response);
                this.setState({
                    supplierDetails: response.supplier_details,
                    supplier: {
                        id: response.supplier_id,
                        name: response.supplier_name
                    },
                    receiverType: response.receiver_type,
                    receiverId: response.receiver_id,
                    receiverName: response.receiver_name,
                    receiverAddress: response.receiver_address,
                    receiverZipcode: response.receiver_zipcode,
                    receiverCity: response.receiver_city,
                    receiverCountry: response.receiver_country,
                    receiverContactName: response.receiver_contact_name,
                    receiverTitle: response.receiver_title,
                    pc_selected_id: response.pc_id,
                    dt_selected_id: response.dt_id,
                    dm_selected_id: response.dm_id,
                    components: response.components,
                    details: response.details,
                });
            }).catch((error) => {
                errorPopper(error, tr('get_error'));
            });
        }
    }

    getRelated()
    {
        return api({
            method: 'get',
            url: 'storage/po/new/related',
        }).then( ( response ) => {
            this.setState({
                dm: response.dm,
                dt: response.dt,
                pc: response.pc,
                dm_enabled: response.dm_enabled,
                dt_enabled: response.dt_enabled,
                pc_enabled: response.pc_enabled,
                dm_required: response.dm_required,
                dt_required: response.dt_required,
                pc_required: response.pc_required,
                recipient_required: response.recipient_required,
                storage_required: response.storage_required,
                company_id: response.company_id,
            });

            this.setDefaults();
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
        });
    }

    setDefaults() {
        if (this.state.dm.length >= 1) { this.setState({dm_selected_id: 1}); }
        if (this.state.dt.length >= 1) { this.setState({dt_selected_id: 1}); }
        if (this.state.pc.length >= 1) { this.setState({pc_selected_id: 1}); }
    }

    save()
    {
        let data = {};

        data.supplier_id = this.state.supplier.id;
        data.supplier_details = this.state.supplierDetails;

        data.receiver_type = this.state.receiverType;
        data.receiver_id = this.state.receiverId;
        data.receiver_name = this.state.receiverName;
        data.receiver_address = this.state.receiverAddress;
        data.receiver_zipcode = this.state.receiverZipcode;
        data.receiver_city = this.state.receiverCity;
        data.receiver_country = this.state.receiverCountry;
        data.receiver_contact_name = this.state.receiverContactName;
        data.saveAddressWhenSave = this.state.saveAddressWhenSave

        if (this.state.receiverProjectId) {
            data.project_id = this.state.receiverProjectId;

            //TODO: Poista käytöstä
            data.receiver_code = this.state.receiverCode;
            if (this.state.receiverSubProjectCode) { data.sub_project_code = this.state.receiverSubProjectCode }
        }
        
        data.dm_id = this.state.dm_selected_id;
        data.dt_id = this.state.dt_selected_id;
        data.pc_id = this.state.pc_selected_id;

        data.date = dateInputToSql( this.state.date );
        data.reference_your = this.state.referenceYour;
        data.reference_our = this.state.referenceOur;
        data.need_date = dateInputToSql( this.state.needDate );
        data.details = this.state.details;

        if (this.props.copyId) {
            data.componentsList = this.state.components;
            data.components = [];
        } else {
            data.components = this.state.components;
        }

        data.copyId = this.props.copyId;

        const url = this.props.copyId
            ? 'storage/po/copy'
            : 'storage/po/new';

        return api({
            method: 'post',
            url: url,
            data: data,
        }).then( ( response ) => {
            window.emitter.emit( 'goTo', { pathname: `/storage/purchase/order/id/${ response.id }` } );
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });


    }

    supplierChange( selected )
    {
        if (selected) {
            let supplierDetailsText = selected.purchase_order_details;
            if (selected.crmAddress) {
                
                supplierDetailsText = (selected.crmAddress.street!==null ? selected.crmAddress.street: "") + "\n"
                    + (selected.crmAddress.postal_code !== null ? selected.crmAddress.postal_code: "") + "\n"
                    + (selected.crmAddress.city !== null ? selected.crmAddress.city: "") + "\n"
                    + (selected.crmAddress.country !== null ? selected.crmAddress.country :"");
            }
            
            this.setState({ supplierDetails: supplierDetailsText });
        }
            
        this.setState({
            supplier: selected,
            dm_selected_id: selected.default_dm,
            dt_selected_id: selected.default_dt,
            pc_selected_id: selected.default_pc,
        });
    }

    renderStepSupplier()
    {
        return <div className="stepSupplier">

            <div className="supplier-select">
                <ApSelect
                    label={`${tr('supplier')} *`}
                    value={ this.state.supplier }
                    optionRenderer="storage_supplier"
                    onChange={ this.supplierChange }
                    objKeyId="id"
                    objKeyValue="name"
                    clearable
                    apiUrl="storage/po/supplier/find"
                    validationState={ this.validator.getState('supplier') }
                />
            </div>
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('select_supplier_info') }
            </div>
            <ApInput
                type="textarea"
                id="supplier_details"
                name="supplier_details"
                label={ tr('supplier_info') }
                autoComplete="off"
                rows="6"
                value={ this.state.supplierDetails ? this.state.supplierDetails : '' }
                onChange={ ( e ) => this.setState({ supplierDetails: e.target.value }) }
            />
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('supplier_info_note') }
            </div>

        </div>

    }

    renderStepReceiver()
    {
        const skipAllowed = this.state.receiverType == 'project';
        return <div className="stepReceiver">
            <ReceiverDataSelect
                validator={ this.validator }
                onChange={ ( data ) => this.setState( data ) }

                receiver={ this.state.receiver }
                receiverTitle={ this.state.receiverTitle }
                receiverType={ this.state.receiverType }
                receiverId={ this.state.receiverId }
                receiverName={ this.state.receiverName }
                receiverAddress={ this.state.receiverAddress }
                receiverZipcode={ this.state.receiverZipcode }
                receiverCity={ this.state.receiverCity }
                receiverCountry={ this.state.receiverCountry }
                receiverContactName={ this.state.receiverContactName }
                company_id={this.state.company_id}
                receiverProjectId={this.state.receiverProjectId}
                receiverSubProjectId={this.state.receiverSubProjectId}
                receiverSubProjectName={this.state.receiverSubProjectName}
                receiverSubProjectCode={this.state.receiverSubProjectCode}


                saveAddressWhenSave={this.state.saveAddressWhenSave}
                allowAddressSkip={skipAllowed}
                detailsRequired={this.state.recipient_required}
                storageRequired={this.state.storage_required}

                costCenter={this.state.costCenter}
                selectedProject={this.props.data && this.props.data.project}

                projectPurchases
            />
        </div>
    }


    renderStepOther()
    {
        const relateds = [
            { name: tr('payment_term'), value: 'pc' },
            { name: tr('delivery_term'), value: 'dt' },
            { name: tr('delivery_method'), value: 'dm' },
        ];

        let doms = [];
        relateds.forEach( related => {
            if( !this.state[`${ related.value }_enabled`]  ) return null;

            const options = this.state[ related.value ].map( item => {
                return {value: item.id, label: item.name };
            });
            options.unshift( { value: null, label: ''} );

            let name = related.name;
            const field = `${ related.value }_selected_id`;

            let validationState = null;
            if( this.state[ `${ related.value }_required` ] )
            {
                validationState = this.validator.getState( field );
                name = name + '*';
            }

            doms.push( <ApInputStack gap="0" key={ related.value }>
                <ApAddon
                    noRightBorder width="200px"
                >
                    { name }
                </ApAddon>
                <ApInput
                    type="select"
                    options={ options }
                    id={ `${related.value}-select` }
                    name={ `${related.value}-select` }

                    value={ this.state[ field ] }
                    onChange={ ( e ) =>  this.setState({ [ field ]: e.target.value } ) }
                    validationState={ validationState }
                    autoComplete="off"
                />
            </ApInputStack>);
        })

        let componentRowDoms = null;
        const cCount = this.state.components.length
        if( cCount > 0 )
        {
            componentRowDoms = <div>
                <h4 className="formHeader">
                    { tr('storage_components') }
                </h4>
                { tr('purchase_order_storage_components_count', [cCount]) }
            </div>
        }


        return <div className="stepOther">
            <ApInput
                type="date"
                id="date"
                name="date"
                label={ tr('date') }
                value={ this.state.date ? this.state.date : '' }
                onChange={ ( e ) => this.setState({ date: e.target.value }) }
            />

            { doms }

            <ApInput
                type="text"
                id="referenceYour"
                name="referenceYour"
                label={ tr('reference_number_your') }
                autoComplete="off"
                value={ this.state.referenceYour ? this.state.referenceYour : '' }
                onChange={ ( e ) => this.setState({ referenceYour: e.target.value }) }
            />

            <ApInput
                type="text"
                id="referenceOur"
                name="referenceOur"
                label={ tr('reference_number_our') }
                autoComplete="off"
                value={ this.state.referenceOur ? this.state.referenceOur : '' }
                onChange={ ( e ) => this.setState({ referenceOur: e.target.value }) }
            />

            <ApInput
                type="date"
                id="needDate"
                name="needDate"
                label={ tr('day_of_need') }
                value={ this.state.needDate ? this.state.needDate : '' }
                onChange={ ( e ) => this.setState({ needDate: e.target.value }) }
                classes={["top"]}
            />

            <ApInput
                type="textarea"
                id="details"
                name="details"
                label={ tr('additional_info') }
                autoComplete="off"
                value={ this.state.details ? this.state.details : '' }
                onChange={ ( e ) => this.setState({ details: e.target.value }) }
            />
            { componentRowDoms }
        </div>
    }


    render()
    {
        
        return <div id="newPo">
            <ApModalWizard
                header={ tr('new_purchase_order') }
                onOpen={ this.resetState }
                show={ this.props.show }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.save }
            />
        </div>
    }

}

export default NewPurchaseOrder;
