/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import Measure from 'react-measure';
import moment from 'moment';

import ApStickyBar       from 'common/ApStickyBar/ApStickyBar.js';
import auth              from 'services/Authed/Authed.js';
import api               from 'services/Api/Api.js';
import SvgIcon           from 'common/SvgIcon/SvgIcon.js';
import ApTooltip         from 'common/ApTooltip/ApTooltip.js';
import ApButton          from 'common/ApButton/ApButton.js';
import ApSwitch          from 'common/ApSwitch/ApSwitch.js';
import ApSteps           from 'common/ApSteps/ApSteps.js';
import ApDropdown        from 'common/ApDropdown/ApDropdown.js';
import ApConfirm         from 'common/ApConfirm/ApConfirm.js';
import ApModal           from 'common/ApModal/ApModal.js';
import ComponentEdit     from 'modules/Storage/Components/ComponentEdit/ComponentEdit.js';
import ComponentTooltip  from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';
import PurchaseOrderTooltip  from 'modules/Storage/common/PurchaseOrderTooltip/PurchaseOrderTooltip.js';
import OrderTooltip from 'modules/Storage/common/OrderTooltip/OrderTooltip.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import Collect from '../../Checkout/Collections/Collect'


import ApTree from 'common/ApTree/ApTree.js';

import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import { ApInput
       , ApInputStack
       , ApAddon  }      from 'common/ApInput/ApInput.js';

import { formatMoney
       , findItemById
       , onlyNumber
       , errorPopper
       , sqlToDateInput
       , mapTree
       , tr }       from 'services/Helpers/Helpers.js';

import { getTypeIcon } from 'modules/Storage/common/StorageHelpers.js';

import './Consumables.css';




class Consumables extends React.Component {
    constructor(props)
    {
        super(props);

        this.newCounter = 0;

        this.state = {
            loading: false,
            selectedStorage:{},
            receivingStorage: {},
            storageTransfer:{},

            showInventoryModal: false,
        };
        this.storageSelect=[];

        autoBind(this);
    }

    componentDidMount()
    {
        //  this.getLocations();
    }

    // ----------------------------------------
    //  Common functions
    // ----------------------------------------

    

    // getLocations()
    // {
    //     this.setState({ loading: true });
    //     return api({
    //         method: 'get',
    //         url: 'storage/location/tree',
    //     }).then((response) => {
    //         //console.log('response: ', response);
    //         this.calculateTree( response, () => {
    //             this.setState({ loading: false });
    //         });
    //     }).catch((error) => {
    //         let message = tr('get_error');
    //         this.setState({
    //             error: message,
    //             loading: false,
    //         });
    //     });
    // }

    // getStorageLocations()
    // {
    //     // ei käytössä
    //     this.setState({ loading: true });
    //     return api({
    //         method: 'get',
    //         url: 'storage/location/storageLocations',
    //     }).then((response) => {
    //         //console.log('response: ', response);
    //         this.calculateTree( response, () => {
    //         this.setState({ loading: false });
    //         });
    //     }).catch((error) => {
    //         let message = tr('get_error');

    //         this.setState({
    //             error: message,
    //             loading: false,
    //         });
    //     });
    // }

    

    // getComponents( locationId )
    // {
    //     this.setState({
    //         componentsLoading: true,
    //         components: [],
    //     });

    //     return api({
    //         method: 'get',
    //         url: `storage/location/${ locationId }/components`,
    //     }).then((response) => {
    //         this.setState({
    //             componentsLoading: false,
    //             components: response.components,
    //             tempComponents: response.temp,
    //         });
    //     }).catch((error) => {
    //         errorPopper(error, tr('get_error'));
    //         console.error(error);
    //         this.setState({ componentsLoading: false });
    //     });

    // }

    

    

    // closeInventoryModal(refresh = false) {
    //     this.setState({showInventoryModal: false});

    //     if (refresh) {
    //         this.getLocations();
    //         this.getComponents(this.state.selectedLocation.id);
    //     }
    // }

    renderSelecter() {
       return <>
            <h5>{tr('collection_storage')}</h5>
            <ApSelect
                label={tr('collection_storage')}
               value={this.state.selectedStorage.name ? this.state.selectedStorage.name : ''}
                optionRenderer={'storage_location_address' }
                objKeyId="id"
                clearable
               onChange={e => { this.setState({ selectedStorage: e }) }}
                apiUrl={'storage/locations/find'}
                apiData={{
                    canSend: true,
                    // componentId: transferItem ? transferItem.id : null
               }}
            //    onFocus={() => { this.setState({ allowWrite: true }) }}
            //    onBlur={() => { this.setState({ allowWrite: false }) }}
               ref={(ref) => { this.storageSelect[0] =ref?ref.inputRef:null; }}
                // disabled={sendingStorage.name ? true : false}
            />

            <br />
            {/* <h5>{tr('receiving_or_returning_storage')}</h5>

            <ApInputStack gap="0">
                <ApInput
                    type="select"
                    id="receivingtype"
                    name="receivingtype"
                    value={receivingType}
                    options={locations}
                    onChange={(e) => handleTypeChange(e.target.value)}
                />
                <ApSelect
                    label={tr('receiving_storage')}
                    value={receivingStorage.name ? receivingStorage.name : ''}
                    optionRenderer={receivingType === tr('storage') ? 'storage_location_address' : 'project'}
                    onChange={(e) => {
                        setReceivingStorage(e)
                        setReceiverAddress({
                            delivery_address: null,
                            delivery_zipcode: null,
                            delivery_city: null,
                            delivery_country: null
                        })
                        setReceiverSelected(true)
                    }}
                    objKeyId="id"
                    clearable
                    apiUrl={receivingType === tr('storage') ? 'storage/locations/find' : 'storage/delivery/findprojects'}
                    apiData={{
                        //projectStorage: true, 
                        canReceive: true,
                    }}
                    disabled={loading}
                /> 
            </ApInputStack> */}

            {this.collectionsRenderer()}
        </>
    }
    collectionsRenderer() {
        return (
            <div id='pageCheckout'>
                <div className="apBox">
                    
                    <Collect
                        manualStorageLocation={this.state.selectedStorage}
                        type='consumables'
                        receivingStorage={this.state.receivingStorage}
                        storageTransfer={this.state.saveMultiple}
                        notCountInputs={this.storageSelect}
                        // allowWrite={this.state.allowWrite}
                        clearCollection={() => this.setState({ CollectionId :null})}
                        // collection={this.state.collectionId}
                        // refresh={this.state.refetchCollectionList}
                        refreshList={this.setRefetchCollectionList}
                        

                    />
                        
                </div>
            </div>
        )
    }
    setRefetchCollectionList() {
        
    }
    

    render()
    {
        let body = null;

        
            body = <div>
                {this.renderSelecter()}
            </div>
        

        return <div id="pageLocations">
            <Measure
                onResize={ () => {
                    if( this.stickyBar )
                        this.stickyBar.updateBounds();
                }}
            >
            {({ measureRef }) => <div ref={ measureRef }>
                    <div className="apBox">
                        <div className="apBoxHeader">
                            <h1>
                                { tr('consumables') }
                            </h1>
                            {/* <p> { tr('storages_storage_locations_info') } </p> */}
                        </div>
                    </div>
                    {/* <ApTabs onChange={ this.changeType }>
                        <ApTab  icon={ getTypeIcon( 'work' ) } label={ tr('storages') }>
                        </ApTab>
                        <ApTab  icon={ getTypeIcon( 'item' ) } label={ tr('project_storages') }>
                        </ApTab>
                    </ApTabs> */}
                    { body }
                    {/* <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar = node }>
                        <div className="saveBar">
                            <ApButton color="blue" onClick={ this.saveTree }>
                                <SvgIcon icon="save" type="solid" />
                                { tr('save') }
                            </ApButton>
                        </div>
                    </ApStickyBar> */}
                </div>
            }
            </Measure>

            {/* { this.renderChangesModal() } */}

            

           

        </div>


    }
}

export default Consumables;
