/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
//import auth from 'services/Authed/Authed.js';

import './Groups.css';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
//import ApReactTable from 'common/ApReactTable/ApReactTable.js';
//import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import { hasPermissions, keyExists, tr } from 'services/Helpers/Helpers.js';

const tabIds = {
    0: "customer",
    1: "supplier",
    
}

export default class CrmGroups extends React.Component {

    constructor(props) 
    {
        super(props);

        const params = this.props.match.params;
        let tab = (params.tab) ? this.getTabId(params.tab) : 0;

        this.state = {
            selectedTab: tab,
            loading: false,
            error: false,
            groups: false,
            unsavedChanges: false,

            modalId: false,
            modalType: false,
            modalValue: '',
            modalIsCustomer:true,
        };
        autoBind(this);

        this.nextId = 0; 
    }

    componentDidMount()
    {
        this.getGroups();
    }

    getGroups()
    {  
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'crm/groups'
        }).then((response) => {
            //console.log('getGroups', response );
            this.setState({ 
                groups: response,
                unsavedChanges: false,
                loading: false 
            });
        }).catch((error) => {
            console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, "Odottamaton virhe ryhmien haussa" ) || "VIRHE",
                loading: false 
            });
        });
    }
    onTabChange(tabId) {
        this.props.history.replace(`/crm/groups/${tabIds[tabId]}`);
    }
    getTabId(param) {

        if (param in tabIds)
            return parseInt(param, 10);
        return parseInt(Object.keys(tabIds).find(key => tabIds[key] === param), 10);
    }

    getGroupName( type, id )
    {
        const items = this.state.groups[ type ].slice();
        for( let i = 0; i < items.length; i++ )
        {
            if( items[i].id == id )
                return items[i].name;
        }
        return "";
    }

    addItem(type, isCustomer=true)
    {
        this.setState({ 
            modalId: "new",
            modalType: type,
            modalValue: '',
            modalIsCustomer: isCustomer
        });
    }

    renameItem( type, id )
    {
        this.setState({ 
            modalId: id,
            modalType: type,
            modalValue: this.getGroupName( type, id ),
        });
    }

    saveModal( value )
    {
        const type = this.state.modalType;
        const id = this.state.modalId;
        let isCustomer = this.state.modalIsCustomer;

        let items = this.state.groups[ type ].slice();
        
        if( id == "new" )
        {
            items.push( { 
                name: value, 
                id: "new" + this.nextId++, 
                companies_count: 0,
                unsaved: true,
                is_customer: isCustomer,
                is_supplier: !isCustomer,
            });
        }
        else 
        {
            for( let i = 0; i < items.length; i++ )
            {
                if( items[i].id == id )
                {
                    items[i].name = value;
                    items[i].unsaved = true;
                    break;
                }
            }
        }

        this.setState( prevData => ({ 
            ...prevData,
            groups: {
                ...prevData.groups,
                [ type ]: items,
            },
            unsavedChanges: true,
        }));
    }

    removeItem( type, id )
    {
        let items = this.state.groups[ type ].slice();
        for( let i = 0; i < items.length; i++ )
        {
            if( items[i].id == id )
            {
                items[i].removed = !items[i].removed; // !undefined = true
                items[i].unsaved = true;
                break;
            }
        }

        this.setState( prevData => ({ 
            ...prevData,
            groups: {
                ...prevData.groups,
                [ type ]: items,
            },
            unsavedChanges: true,
        }));
    }

    saveGroups()
    {
        //console.log( "saveGroups...", this.state.groups );
        this.setState({ 
            loading: true, 
            error: false 
        });
        api({
            method: 'post',
            url: 'crm/groups/save',
            data: this.state.groups
        }).then((response) => {
            //console.log('saved', response );
            this.getGroups();
        }).catch((error) => {
            console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('save_error') ) || "VIRHE",
                loading: false 
            });
        });
    }

    renderItem(type, item, key, isCustomer )
    {
        let classes = ["item"];

        if( item.removed )
            classes.push( "removed" );

        if( item.unsaved )
            classes.push( "unsaved" );
        if (isCustomer) {
            if (!item.is_customer) {
                return null;
            }
        }
        else {
            if (!item.is_supplier) {
                return null;
            }
        }
        return (
            <div key={ key } className={ classes.join(" ") }>
                
                <div className="actions">
                    <ApDropdown
                        actionId={ item.id }
                        actions={[
                            {
                                label: tr('rename'),
                                icon: "edit",
                                disabled: item.removed,
                                action: ( item, closeFunc ) => {
                                    this.renameItem( type, item );
                                    closeFunc();
                                },
                            },
                            { "divider": true },
                            {
                                label: ( item.removed ? tr('cancel_delete') : tr('delete') ),
                                icon: "trash-alt",
                                action: ( item, closeFunc ) => {
                                    this.removeItem( type, item );
                                    closeFunc();
                                }
                            }
                        ]}
                    />
                </div>

                <SvgIcon className="icon" icon="tag" type="solid" />
                <strong>{ item.name }</strong><br />
                <small>{tr('members_in_group')}: {type == "contact" ? item.member_count : item.companies_count} { tr('pcs') }</small>

                { item.unsaved && <SvgIcon className="unsavedIcon" icon="exclamation-circle" type="solid" /> }

            </div>
        );
    }

    getGroupElement(isCustomer = true) {
        let companiesAmount = 0;
        let contactAmaont = 0;
       
        if (isCustomer) {
            companiesAmount = this.state.groups ? this.state.groups.company.filter(option => (option.is_customer === true)).length : 0;
            contactAmaont = this.state.groups ? this.state.groups.contact.filter(option => (option.is_customer === true)).length : 0;
        }
        else {
            companiesAmount = this.state.groups ? this.state.groups.company.filter(option => (option.is_supplier === true)).length : 0;
            contactAmaont = this.state.groups ? this.state.groups.contact.filter(option => (option.is_supplier === true)).length : 0;
        }
        
        return (<div className="clear">
            <div className="apColumn w50">
                <div className="padding">

                    <h4>
                        {tr(isCustomer?'customer_groups':'supplier_groups')}
                        {this.state.groups && <small> ({companiesAmount} {tr('pcs')})</small>}
                    </h4>

                    <div className="list">
                        {this.state.loading &&
                            <div className="apLoader"></div>
                        }

                        {this.state.groups && this.state.groups.company.map((item, key) => this.renderItem("company", item, key, isCustomer))}

                        {this.state.groups && this.state.groups.company.length == 0 &&
                            <div className="empty">
                                {tr('no_groups_defined')}
                            </div>
                        }

                    </div>

                    <ApButton
                        fullWidth
                        onClick={() => this.addItem("company", isCustomer)}
                        disabled={isCustomer ? !hasPermissions('clients.edit') : !hasPermissions('purchases.suppliers.edit')}
                    >
                        <SvgIcon icon="plus" type="solid" />
                        {tr(isCustomer ? 'add_customer_group' :'add_supplier_group')}
                    </ApButton>

                </div>
            </div>
            <div className="apColumn w50">
                <div className="padding">

                    <h4>
                        {tr('contact_groups')}
                        {this.state.groups && <small> ({contactAmaont} {tr('pcs')})</small>}
                    </h4>

                    <div className="list">
                        {this.state.loading &&
                            <div className="apLoader"></div>
                        }

                        {this.state.groups && this.state.groups.contact.map((item, key) => this.renderItem("contact", item, key, isCustomer))}

                        {this.state.groups && this.state.groups.contact.length == 0 &&
                            <div className="empty">
                                {tr('no_groups_defined')}
                            </div>
                        }
                    </div>

                    <ApButton fullWidth
                        onClick={() => this.addItem("contact", isCustomer)}
                        disabled={isCustomer ? !hasPermissions('clients.edit') : !hasPermissions('purchases.suppliers.edit')}
                    >
                        <SvgIcon icon="plus" type="solid" />
                        {tr('add_contact_group')}
                    </ApButton>

                </div>
            </div>
        </div>);
    }

    render() 
    {
        return (
            <div id="pageCrmGroups">
                
                        <ApFormPage
                            onSave={this.saveGroups}
                            loading={this.state.loading}
                            unsaved={this.state.unsavedChanges}
                            error={this.state.error}
                        >
                            <div className="apBoxHeader">
                                <h1>{tr('groups_management')}</h1>
                                <p>{tr('customer_groups_management_info')}</p>
                            </div>
                            <div className="padding">
                        <ApTabs selected={this.state.selectedTab} onChange={this.onTabChange} >
                            {hasPermissions('clients') &&
                                <ApTab icon="tags" label={tr('customer_groups')} mountOnSelect>
                                {this.getGroupElement(true)}
                            </ApTab>}
                            {hasPermissions('purchases.suppliers.edit') &&
                                <ApTab icon="tags" label={tr('supplier_groups')} mountOnSelect>
                                {this.getGroupElement(false)}
                            </ApTab>}

                        </ApTabs>

                            </div>
                        </ApFormPage>
                    
                

                <ApModalInput 
                    show={ this.state.modalId !== false }
                    value={ this.state.modalValue }
                    onSave={ ( value ) => this.saveModal( value ) }
                    onClose={ () => this.setState({ modalId: false }) }
                    title={ this.state.modalType == "company" ? tr('customer_group'): tr('contact_group') }
                    label={ tr('name') }
                    required
                />

            </div>
        );
    }
}
