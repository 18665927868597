import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

import auth from 'services/Authed/Authed.js';
import api from 'services/Api/Api.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';

import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApButton from 'common/ApButton/ApButton.js';

import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import { ApInput,
         ApInputStack,
         ApAddon } from 'common/ApInput/ApInput.js';

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import { errorPopper
    , keyExists
    , onlyNumber
    , ucfirst
    , tr, 
    hasPermissions} from 'services/Helpers/Helpers.js';
import ApImageUpload from 'common/ApImageUpload/ApImageUpload.js';

import { getComponentStatusColor
       , getComponentStatusId
       , getTypeIcon }  from 'modules/Storage/common/StorageHelpers.js';

import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import CodeSelect from './CodeSelect/CodeSelect.js'
import './ComponentEdit.css';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ComponentTooltip  from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';

import PriceInputs from './PriceInputs/PriceInputs.js'

import ApSelect from 'common/ApSelect/ApSelect.js';
import ApFileUpload from 'common/ApFileUpload/ApFileUpload.js';
import File        from 'common/File/File.js';
import { SortableElement } from 'react-sortable-hoc';

import { profitPercentToSellPrice }  from 'services/Helpers/Helpers.js';

class ComponentEdit extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            selectedTab: 0,

            confirmSaveShow: false,
            confirmSaveText: null,
            confirmSaveAction: null,
            locationsRemoved: [],
            showConfirmDeletion: false,
            status_name: null,

            type_id: null,
            type_name: '',
            type_text: '',

            status_id: null,
            status_name: '',
            status_text: '',

            temporary: false,

            name: '',
            image_id: null,
            unit: null,
            description: null,

            codeTail: '',
            code: null,
            codeLimit: null,
            codeLevelCount: null,
            codeSeparator: null,
            codeTailLength: null,
            codeTailExists: false,
            codeSkipped: true,

            price: null,
            lastprice: 0,
            avgprice: 0,
            //discount: 0,
            price_sell: 0,
            profitPercent: null,
            conversionEnabled: false,
            conversionFactor: null,
            conversionUnit: '',
            conversionUnits: [],
            project_price_type: null,

            in_timetracking_default: false,
            component_is_produced: false,
            
            identifiers: [],
            identifierOptions: [],
            properties: [],
            propertyOptions: [],
            calculatables: [],
            calculatableOptions: [],
            trackingunits: [],
            trackingunitOptions: [],
            altNames: [],
            locations: [],
            suppliers: [],

            newFileIds: [],
            files: [],
            edited:false,
            billing: false,
            hourTypes: [],

            component_types: [],
            component_type_options: [],

            currencySign: auth.getCurrencySign(),
        };

        this.newConversionUnitCounter = 0;

        this.newAltNameCounter = 0;

        this.newComponentTypeCounter = 0;

        const propertyValidator = ( value, index ) => {
            const isBoolean = this.state.properties[ index ].is_boolean;
            if( isBoolean ) return true;
            return Boolean( value );
        }
        // kommentti
        const locationOptionalValidator = ( value, index ) => {
            return ( value !== '');
        }

        const nameExistsValidator = ( value ) => {
            return !this.state.nameExists;
        }

        const codeValidator = ( value ) => {
            if( this.state.codeSkipped ) return true;
            if( !this.state.codeLimit ) return false;
            if( this.state.codeLimit.is_free ) return true;

            if( !value ) return false;
            if( !value.ancestors ) return false;
            return ( ( value.ancestors.length + 1 ) === this.state.codeLevelCount );
        }

        const codeTailSetValidator = ( value ) => {
            if( this.state.codeSkipped ) return true;
            return Boolean( value );
        }

        const codeTailLengthValidator = ( value ) => {
            if( this.state.codeSkipped ) return true;
            if( !this.state.codeLimit ) return false;
            if( this.state.codeLimit.is_free ) return true;
            if( !this.state.codeLimit.need_length ) return true;
            if( typeof value === 'string')
                return ( value.length === this.state.codeTailLength );
            return false;
        }

        const codeTailSeparatorValidator = ( value ) => {
            if( this.state.codeSkipped ) return true;
            if( !this.state.codeLimit ) return false;
            if( this.state.codeLimit.is_free ) return true;
            if( !this.state.codeLimit.need_separator ) return true;
            if( typeof value === 'string' )
                return !( value.includes( this.state.codeSeparator) );
            return false;
        }

        const codeTailExistsValidator = ( value ) => {
            if( this.state.codeSkipped ) return true;
            return !this.state.codeTailExists;
        }

        // const conversionFactorValidator = ( value ) => {
        //     if( !this.state.conversionEnabled ) return true;
        //     return Boolean( value );
        // }

        // const conversionUnitValidator = ( value ) => {
        //     if( !this.state.conversionEnabled ) return true;
        //     return Boolean( value );
        // }

        const conversionUnitsValidator = ( value ) => {
            if( !this.state.conversionEnabled ) return true;
            return Boolean( value );
        }

        const hourTypeValidator = ( value, index ) => {
            if( !this.state.billing ) return true;
            if( this.state.hourTypes[ index ].component_no_billing ) return true;
            return Boolean( value );
        }

        const calculatableValidator = ( value ) => {
            if (!value || value <= 0) return false;
            return true
        }

        this.validator = new ApValidate(this, {
            name: [
                { filter: nameExistsValidator, state: 'error', text: tr('name_already_in_use') },
                { filter: 'required', state: 'error', text: tr('name_required') },
            ],
            code: { filter: codeValidator, state: 'error', text: tr('code_not_selected') },
            codeTail: [
                { filter: codeTailSetValidator, text: tr('component_code_tail_not_specified') },
                { filter: codeTailSeparatorValidator, text: tr('component_code_tail_delimiter') },
                { filter: codeTailLengthValidator, text: tr('component_code_tail_length') },
                { filter: codeTailExistsValidator, text: tr('component_code_already_in_use') },
            ],

            // conversionFactor: { filter: conversionFactorValidator, state: 'error', text: tr('conversion_unit_factor_not_defined') },
            // conversionUnit: { filter: conversionUnitValidator, state: 'error', text: tr('conversion_unit_not_defined') },
            conversionUnits: {filter: conversionUnitsValidator, state: 'error', text: tr('error') }, // TODO käännös

            identifiers: {
                filters: {
                    value: { filter: 'required', state: 'error', text: tr('must_not_be_empty'), textAll: tr('invalid_value')},
                }
            },
            properties: {
                filters: {
                    value: { filter: propertyValidator, state: 'error', text: tr('must_not_be_empty'), textAll: tr('invalid_value')},
                }
            },
            calculatables: {
                filters: {
                    value: { filter: calculatableValidator, state: 'error', text: tr('must_not_be_empty'), textAll: tr('invalid_value_calculatable')},
                }
            },
            altNames: {
                filters: {
                    name: { filter: 'required', state: 'error', text: tr('must_not_be_empty'), textAll: tr('invalid_value')},
                }
            },
            locations: {
                filters: {
                    id: { filter: 'required', state: 'error', text: tr('storage_not_selected')},
                    balance: { filter: 'required', state: 'error', text: tr('not_defined'), textAll: tr('invalid_value') },
                    limit_alarm: { filter: locationOptionalValidator, state: 'error', text: tr('not_defined'), textAll: tr('invalid_value') },
                    limit_notification: { filter: locationOptionalValidator, state: 'error', text: tr('not_defined'), textAll: tr('invalid_value') },
                    order_amount: { filter: locationOptionalValidator, state: 'error', text: tr('not_defined'), textAll: tr('invalid_value') },
                }
            },
            suppliers: {
                filters: {
                    id: { filter: 'required', state: 'error', text: tr('supplier_not_selected'), textAll: tr('invalid_value') },
                    //purchase_price: { filter: 'required', state: 'error', text: tr('must_not_be_empty'),  textAll: tr('invalid_value') },
                }
            },

            hourTypes: {
                filters: {
                    component_multiplier: { filter: hourTypeValidator, state: 'error', text: tr('must_not_be_empty'), textAll: tr('invalid_value')},
                }
            },

        });

        this.checkNameExistsDebounced = debounce( 200, this.checkNameExists );

        autoBind(this);
    }

    remove()
    {
        return api({
            method: 'post',
            url: `storage/component/remove/${ this.props.id }`,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('deleted') }</strong>,
            });

            this.setState({ loading: false }, () => {
                if( typeof this.props.onSave === 'function')
                    this.props.onSave( null );
            });

        }).catch((error) => {
            console.error(error);
            errorPopper( error, tr('delete_error') );
            this.setState({ loading: false });
        });
    }

    changeToComponent()
    {
        return api({
            method: 'post',
            url: `storage/component/changetocomponent/${ this.props.id }`,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{tr('type_change_success')}</strong>,
            });

            this.setState({ loading: false }, () => {
                if( typeof this.props.onSave === 'function')
                    this.props.onSave( null );
            });

        }).catch((error) => {
            console.error(error);
            errorPopper( error, tr('type_change_error') );
            this.setState({ loading: false });
        });
    }
    changeComponentTemporarity() {
        return api({
            method: 'post',
            url: 'storage/component/changeComponentTemporarity',
            data: { id: this.props.id, temporary: !this.state.temporary }
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{tr('type_change_success')}</strong>,
            });

            this.setState({ loading: false }, () => {
                if (typeof this.props.onSave === 'function')
                    this.props.onSave(null);
            });

        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('type_change_error'));
            this.setState({ loading: false });
        });
    }


    save( status = null )
    {
        this.setState({ confirmSaveShow: false });

        if( status === 'DELETE' )
        {
            this.remove();
            return null;
        }
        if( status === 'changeTo' )
        {
            this.changeToComponent();
            return null;
        }
        if (status === 'changeTemporarity') {
            this.changeComponentTemporarity();
            return null;
        }

        const statusId = getComponentStatusId( status );

        let data = {};

        data.id = this.props.id;
        data.status_id = statusId;
        data.name = this.state.name;
        data.image_id = this.state.image_id;
        data.unit = this.state.unit ? this.state.unit : null;
        data.description = this.state.description ? this.state.description : null;
        data.in_timetracking_default = this.state.in_timetracking_default;
        data.component_is_produced = this.state.component_is_produced;

        if( this.state.codeSkipped )
        {
            data.code_id = null;
            data.code_tail = null;
        }
        else
        {
            data.code_id = this.state.code ? this.state.code.id : null;
            data.code_tail = this.state.codeTail;
        }

        data.price = this.state.price;
        data.lastprice = this.state.lastprice;
        data.avgprice = this.state.avgprice;
        //data.discount = this.state.discount;
        data.price_sell = this.state.price_sell;
        data.profit_percent = this.state.profitPercent;

        data.conversion_enabled = this.state.conversionEnabled;
        if( this.state.conversionEnabled )
        {
            data.conversion_factor = this.state.conversionFactor;
            data.conversion_unit = this.state.conversionUnit;
        }
        else
        {
            data.conversion_factor = null;
            data.conversion_unit = null
        }

        data.identifiers = this.state.identifiers.map( i => {
            return { id: i.id, value: i.value };
        });

        data.properties = this.state.properties.map( i => {
            return { id: i.id, value: i.value }
        });

        data.calculatables = this.state.calculatables.map( i => {
            return { id: i.id, value: i.value }
        });

        data.extra_calculatables = this.state.component_extra_calculatables.map(i => {
            return {
                component_extra_calculatable_id: i.id,
                extra_calculatable_id: i.extra_calculatable.id,
                component_id: this.props.id,
            }
        })

        data.trackingunits = this.state.trackingunits.map( i => i.id );

        data.locations = this.state.locations.map( i => {
            return {
                id: i.id,
                balance: i.balance,
                limit_notification: i.limit_notification,
                limit_alarm: i.limit_alarm,
                order_amount: i.order_amount,
            };
        });

        data.suppliers = this.state.suppliers.map( i => {
            return {
                id: i.id,
                primary: i.primary,
                purchase_price: i.purchase_price,
                discount_percent: i.discount_percent,
                visible_to_supplier: i.visible_to_supplier,
                delivery_time: i.delivery_time,
            };
        });

        data.alt_names = this.state.altNames.map( i => {
            return {
                id: isNaN( parseInt( i.id, 10 ) ) ? null : i.id,
                name: i.name,
            }
        })
        if (this.state.wholesale_components!==false) {
            data.wholesale_components = this.state.wholesale_components.map( i => {
                return { id: i.wholesale_component_id }
            })
            data.wholesale_update_id = this.state.wholesaleUpdateId;
        }
        else {
            data.wholesale_components = [];
        }
        let oldFiles = this.state.files.map( i => i.id );
        data.file_ids = [...oldFiles, ...this.state.newFileIds ];


        data.billing = this.state.billing;

        if( data.billing )
        {
            let tmp = [];
            this.state.hourTypes.forEach( i => {
                if( parseFloat( i.component_multiplier ) !== 1 || i.component_no_billing )
                    tmp.push( { id: i.id, multiplier: i.component_multiplier, no_billing: i.component_no_billing });
            });
            data.hour_type_values = tmp;
        }


        let conversionUnitError = false;
        if (data.conversion_enabled) {
            data.conversionUnits = this.state.conversionUnits;
            data.conversionUnits.forEach(item => {
                if (conversionUnitError) return;

                if (!item.unit) {
                    conversionUnitError = tr('conversion_unit_missing');
                }
                else if (!item.conversion_factor) {
                    conversionUnitError = tr('conversion_unit_factor_missing');
                }
            })
        }
        if (conversionUnitError) {
            errorPopper(null, conversionUnitError);
            return;
        }

        data.component_types = this.state.component_types.map(type => ({ type_id: type.type_id, id: type.id }));

        //console.log('saving...', data );

        this.setState({loading: true });
        return api({
            method: 'post',
            url: 'storage/component/save',
            data: data,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('save_successful') }</strong>,
            });

            this.setState({ loading: false, edited: false, }, () => {
                if( typeof this.props.onSave === 'function')
                    this.props.onSave( response );
            });

        }).catch((error) => {
            console.error(error);
            errorPopper( error, tr('save_error') );
            this.setState({ loading: false });
        });

    }

    onOpen()
    {
        if( this.fileUploadRef )
            this.fileUploadRef.resetFiles();

        this.setState({ selectedTab: 0, edited: false });
        this.getComponent();
    }

    getComponent()
    {
        this.setState({loading: true });
        return api({
            method: 'get',
            url: `storage/component/get/${ this.props.id }`,
        }).then((response) => {

            //console.log('Response', response );

            this.setState({
                loading: false,

                type_id: response.type_id,
                type_name: response.type_name,
                type_text: response.type_text,

                status_id: response.status_id,
                status_name: response.status_name,
                status_text: response.status_text,

                temporary: response.temporary,

                name: response.name,
                unit: response.unit,
                image_id: response.image_id,
                description: response.description,

                codeSkipped: !Boolean( response.code_tail ),
                code: response.code,
                codeLimit: response.code_limit,
                codeLevelCount: response.code_level_count,
                codeTail: response.code_tail,
                codeSeparator: response.code_separator,
                codeTailLength: response.code_tail_length,


                price: response.price,
                lastprice: response.lastprice,
                avgprice: response.avgprice,
                //discount: response.discount,
                price_sell: response.price_sell,
                profitPercent: response.profit_percent,

                order_price: response.order_price,
                order_price_type: response.order_price_type,
                order_price_min_profit: response.order_price_min_profit,
                project_price_type: response.project_price_type,

                conversionEnabled: Boolean( response.conversion_enabled ),
                conversionFactor: response.conversion_factor,
                conversionUnit: response.conversion_unit,
                conversionUnits: response.conversion_units,

                identifiers: response.identifiers,
                identifierOptions: response.identifier_options,

                properties: response.properties,
                propertyOptions: response.property_options,

                calculatables: response.calculatables,
                calculatableOptions: response.calculatable_options,

                component_extra_calculatables: response.component_extra_calculatables,
                extra_calculatable_options: response.extra_calculatable_options,

                trackingunits: response.trackingunits,
                trackingunitOptions: response.trackingunit_options,

                altNames: response.altnames,
                locations: response.locations,
                suppliers: response.suppliers,

                files: response.files,
                newFileIds: [],

                wholesale_components: response.wholesale_components ? response.wholesale_components : [],
                wholesaleUpdateId: response.wholesale_update_id,


                in_timetracking_default: response.in_timetracking_default,
                component_is_produced: response.component_is_produced,
                
                billing: response.billing,
                hourTypes: response.hour_types,

                component_types: response.component_types,
                component_type_options: response.component_type_options,

                edited: false,

            }, () => {
                this.hourTypesUpdateValue();
            });

        }).catch((error) => {
            console.error(error);

            const message = errorPopper( error, tr('get_error') );
            this.setState({
                loading: false,
                error: message,
            });
        });
    }

    checkNameExists( value )
    {
        return api({
            method: 'post',
            url: 'storage/component/name/exists',
            data: { name: value, exclude_ids: [ this.props.id ] },
        }).then( ( response ) => {
            this.setState({ nameExists: response.exists });
        }).catch((error) => {
            errorPopper(error, tr('get_error') );
        });
    }

    handleNameChange( value )
    {
        this.checkNameExistsDebounced( value );
        this.setState({ name: value, edited:true });
    }

    codeChange( code, tail )
    {
        this.setState({
            code: code,
            codeTail: tail,
            edited:true,
        })
    }

    renderUnitInfo()
    {
        let info = tr('storage_component_unit_note');
        const isWork = ( this.state.type_name === 'work' );
        let toggle = <ApAddon noRightBorder width="200px" className="conversionToggle">
            <div>
                { tr('conversion_unit') }
                <ApSwitch
                    id="conversion-enabled"
                    on={ this.state.conversionEnabled }
                    onChange={ () => { this.setState({ conversionEnabled: !this.state.conversionEnabled, edited:true }) } }
                    inline
                    small

                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </div>
        </ApAddon>

        let conversionInput = null;
        let conversionUnitsDom = null;
        let newConversionUnitBtn = null;

        if( isWork )
        {
            info = tr('work_always_in_hours');
        }
        else if( this.state.conversionEnabled )
        {
            conversionInput = <ApInputStack gap="0">
                { toggle }
                {/* <ApInput
                    className="text-right"
                    label={ tr('conversion_factor') }
                    type="text"
                    id="conversion-factor"
                    name="conversion-factor"
                    value={ this.state.conversionFactor ? this.state.conversionFactor : '' }
                    onChange={ ( e ) => this.setState({ conversionFactor: onlyNumber( e.target.value ) }) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }

                    validationState={ this.validator.getState( 'conversionFactor' ) }
                    tooltip={ this.validator.getText( 'conversionFactor' ) }
                />
                <ApInput
                    label={ tr('conversion_unit') }
                    type="text"
                    id="conversion-factor"
                    name="nconversion-factor"
                    value={ this.state.conversionUnit ? this.state.conversionUnit : '' }
                    onChange={ ( e ) =>  this.setState({ conversionUnit: e.target.value }) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }

                    validationState={ this.validator.getState( 'conversionUnit' ) }
                    tooltip={ this.validator.getText( 'conversionUnit' ) }
                /> */}
                <ApAddon >
                    { tr('active') }
                </ApAddon>
            </ApInputStack>

            conversionUnitsDom = this.state.conversionUnits.map((unit, index) => (
                <ApInputStack key={index} gap={'0'}>
                    <ApInput
                        label={ tr('conversion_factor') }
                        type="number"
                        step='0.1'
                        id={`conversion_factor-${unit.id}`}
                        name="conversion_factor"
                        value={ unit.conversion_factor ? unit.conversion_factor : '' }
                        onChange={ ( e ) =>  this.handleConversionUnitEdit(unit.id, e) }
                        autoComplete="off"
                        loading={ this.state.loading }
                        disabled={ this.state.loading || isWork }
                    />
                    <ApInput
                        label={ tr('unit') }
                        type="text"
                        id={`unit-${unit.id}`}
                        name="unit"
                        value={ unit.unit ? unit.unit : '' }
                        onChange={ ( e ) =>  this.handleConversionUnitEdit(unit.id, e) }
                        autoComplete="off"
                        loading={ this.state.loading }
                        disabled={ this.state.loading || isWork }
                    />
                    <ApAddon width='80px'>
                        <ApTooltip text={tr('default_in_orders')}>
                            <ApSwitch
                                id={`default-in-orders-${unit.id}`}
                                name="default_in_orders"
                                on={ unit.default_in_orders }
                                onChange={ (e) => this.handleConversionUnitEdit(unit.id, e) }
                                inline
                                small
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                            />
                        </ApTooltip>
                    </ApAddon>
                    <ApAddon width='80px'>
                        <ApTooltip text={tr('default_in_purchase_orders')}>
                            <ApSwitch
                                id={`default-in-purchase_orders-${unit.id}`}
                                name="default_in_purchase_orders"
                                on={ unit.default_in_purchase_orders }
                                onChange={ (e) => this.handleConversionUnitEdit(unit.id, e) }
                                inline
                                small
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                            />
                        </ApTooltip>
                    </ApAddon>
                    <ApAddon width='50px'>
                        <SvgIcon
                            icon='trash'
                            type='solid'
                            className='pointer removeBtn'
                            onClick={()=>this.deleteConversionUnit(unit.id)}
                        />
                    </ApAddon>
                </ApInputStack>
            ));

            newConversionUnitBtn = (
                <ApButton
                    color='green'
                    size='small'
                    onClick={this.handleNewConversionUnit}
                >
                    {tr('new')}
                </ApButton>
            );
        }
        else
        {
            conversionInput = <ApInputStack gap="0">
                { toggle }
                <ApAddon >
                    { tr('inactive') }
                </ApAddon>
            </ApInputStack>
        }


        return <div className="padding">
            <h4 className="formHeader">{ tr('unit') }</h4>

            <ApInput
                label={ tr('unit') }
                type="text"
                id="cname"
                name="name"
                value={ this.state.unit ? this.state.unit: '' }
                onChange={(e) => this.setState({ unit: e.target.value, edited: true }) }

                autoComplete="off"

                loading={ this.state.loading }
                disabled={ this.state.loading || isWork }
            />
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { info }
            </div>
            { conversionInput }
            { this.state.conversionEnabled && this.state.conversionUnits.length > 0 && 
                <div
                    style={{
                        display: 'flex', 
                        justifyContent: 'flex-end',
                        marginRight: '50px'
                    }}
                >
                    <div style={{width: '80px', textAlign:'center'}}>{tr('sales')}</div>
                    <div style={{width: '80px', textAlign:'center'}}>{tr('purchases')}</div>
                </div>
            }
            { conversionUnitsDom }
            { newConversionUnitBtn }
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('conversion_unit_info_long') }
            </div>

        </div>
    }

    handleNewConversionUnit() {
        let conversionUnits = this.state.conversionUnits.slice();
        this.newConversionUnitCounter++;
        conversionUnits.push({
            component_id: this.props.id,
            conversion_factor: '', 
            unit: '', 
            id: `new${this.newConversionUnitCounter}`, 
            changed: true,
            default_in_orders: false,
            default_in_purchase_orders: false,
        });
        this.setState({ conversionUnits, edited: true });
    }

    handleConversionUnitEdit(id, event) {
        const {name:key, value} = event.target;
        const conversionUnits = this.state.conversionUnits.slice();
        const foundIndex = conversionUnits.findIndex(item => item.id == id);
        if (foundIndex === -1) {
            errorPopper(null, 'Virhe muokatessa muuntoyksikköä');
            return;
        }
        if (key === 'default_in_purchase_orders' ||key === 'default_in_orders') {
            if (value === 'on') {
                conversionUnits.map(item => {
                    if (item.id == conversionUnits[foundIndex]['id']) return;
                    item[key] = false;
                    item.changed = true;
                });
            }
            conversionUnits[foundIndex][key] = !conversionUnits[foundIndex][key];
        } else {
            if (key === 'conversion_factor' && value < 0) {
                errorPopper(null, tr('negative_number'));
                return;
            }
            conversionUnits[foundIndex][key] = value;
        }
        conversionUnits[foundIndex]['changed'] = true;
        this.setState({ conversionUnits, edited: true });
    }

    deleteConversionUnit(id) {
        let conversionUnits = this.state.conversionUnits.filter(item => item.id != id);
        this.setState({ conversionUnits, edited: true })
    }

    changeState(data, afterFunction) {
        this.setState(data, () => {
            if (typeof afterFunction === 'function')
                afterFunction();
        });
    }


    renderBasicInfoTab()
    {
        let supplier = this.state.suppliers.find(s => s.primary == true);
        if (!supplier && this.state.suppliers.length>0) {
            supplier = this.state.suppliers[0];
        }
        
        return <ApTab key="basicInfo" icon="info-circle" label={ tr('basics') }>
            <div className="clear">
                <div className="apColumn w50">
                    <div className="padding">
                        <ApInput
                            label={ tr('name') }
                            type="text"
                            id="cname"
                            name="name"
                            value={ this.state.name ? this.state.name : '' }
                            onChange={ ( e ) =>  this.handleNameChange( e.target.value ) }

                            validationState={ this.validator.getState( 'name' ) }
                            tooltip={ this.validator.getText( 'name' ) }

                            autoComplete="off"

                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />

                        <CodeSelect
                            componentId={ this.props.id }
                            skipped={ this.state.codeSkipped }
                            tail={ this.state.codeTail }
                            code={ this.state.code  }

                            codeLimit={ this.state.codeLimit  }
                            codeLevelCount={ this.state.codeLevelCount  }
                            codeSeparator={ this.state.codeSeparator }

                            onSkippedChange={ () => this.setState({ codeSkipped: !this.state.codeSkipped }) }
                            onChange={ this.codeChange }
                            afterTailCheck={ ( exists ) => { this.setState({ codeTailExists: exists }) } }

                            validationState={ this.validator.getState( 'code' ) }
                            tailValidationState={ this.validator.getState( 'codeTail' ) }
                            validationText={ this.validator.getText( 'code' ) }
                            tailValidationText={ this.validator.getText( 'codeTail' ) }
                        />

                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { tr('storage_component_code_note1') } { tr('storage_component_code_note2') }.
                        </div>

                        <ApInput
                            label={ tr('free_text') }
                            type="textarea"
                            id="ncComment"
                            name="description"
                            value={ this.state.description }
                            onChange={ ( e ) => this.setState({ description: e.target.value, edited :true }) }
                            autoComplete="off"

                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                     </div>
                </div>  

                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">{ tr('price_info') }</h4>
                        <PriceInputs
                            typeName={this.state.type_name}
                            price={this.state.price}
                            lastprice={this.state.lastprice}
                            avgprice={this.state.avgprice}
                            //discount={ this.state.discount }
                            price_sell={this.state.price_sell}
                            profitPercent={this.state.profitPercent}
                            order_price={this.state.order_price}
                            order_price_type={this.state.order_price_type}
                            order_price_min_profit={this.state.order_price_min_profit}
                            project_price_type={this.state.project_price_type}
                            supplier={supplier}
                            component_type_id={this.state.type_id}
                            loading={this.state.loading}
                            onChange={(data) => this.setState(data)}
                            changeState={(data, afterFunction) => this.changeState(data, afterFunction)}
                        />
                    </div>
                </div>
                
            </div>         
            <div className="clear">
                <div className="apColumn w50">
                    { this.renderUnitInfo() }
                </div>

                <div className="apColumn w50">
                    <div className="padding">
                        <ApImageUpload
                            label={ tr('picture') }
                            description="component image"
                            fileId={ this.state.image_id }
                            onChange={(file) => this.setState({ image_id: file.id, edited: true }) }
                            onClear={(file) => this.setState({ image_id: null, edited: true }) }

                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </div>
                </div> 

                
            </div>
        </ApTab>
    }

    setIdentifierValue( index, value )
    {
        let identifiers = this.state.identifiers.slice( 0 );
        identifiers[ index ].value = value;
        this.setState({ identifiers: identifiers, edited: true });
    }

    removeIdentifier( id )
    {
        let identifiers = this.state.identifiers.slice( 0 );
        identifiers = identifiers.filter( i => i.id !== id )
        this.setState({ identifiers: identifiers, edited: true });
    }

    renderIdentifiers()
    {
        let options = [];

        this.state.identifierOptions.forEach( o => {
            if( !this.state.identifiers.find( i => i.id === o.id ) )
            {
                options.push({
                    label: o.name,
                    icon: getTypeIcon( 'identifier' ),
                    action: ( id, closeFunc ) => {
                        let index = this.state.identifiers.findIndex( i => ( i.name > o.name ) );
                        if( index === -1 ) index = this.state.identifiers.length;

                        let identifiers = this.state.identifiers.slice( 0 );
                        identifiers.splice( index, 0,  {
                            id: o.id,
                            description: o.description,
                            name: o.name,
                            value: null,
                        });
                        this.setState({ identifiers: identifiers, edited: true });
                        closeFunc();
                    },
                })
            }
        });

        let addButton = null;
        if( options.length )
        {
            addButton = <div className="addButton">
                <ApDropdown
                    position="bottom"
                    fullWidth
                    button={ <ApButton className="save" color="white">
                        <SvgIcon icon="plus" type="solid" />
                        { tr('add_new_identifier') }
                    </ApButton> }
                    actions={ options }
                />
            </div>
        }

        const listDoms = this.state.identifiers.map( ( i, index ) => {
            return <div key={ i.id } className="identifier listStack">
                <ApInputStack gap="0">
                    <ApAddon noRightBorder width="200px" className="titleAddon">
                        <ApTooltip block text={ i.description }>
                            <div className="title">{ i.name }</div>
                        </ApTooltip>
                    </ApAddon>
                    <ApInput
                        className="text-right"
                        label={ tr('value') }
                        type="text"
                        id={ `identifier-value-${ i.id }` }
                        name={ `identifier-value-${ i.id }` }
                        value={ i.value ? i.value : '' }
                        onChange={ ( e ) => this.setIdentifierValue( index, e.target.value ) }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }

                        autoComplete="off"

                        validationState={ this.validator.getState('identifiers', index, 'value') }
                        tooltip={ this.validator.getText('identifiers', index, 'value') }

                    />
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete') }>
                            <div className="apSimpleButton" onClick={ () => { this.removeIdentifier( i.id ) }}>
                                <SvgIcon icon="trash" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                </ApInputStack>
            </div>
        });

        return <div>
            { addButton }
            { listDoms }
        </div>
    }

    setPropertyValue( index, value )
    {
        let properties = this.state.properties.slice( 0 );
        properties[ index ].value = value;
        this.setState({ properties: properties, edited: true });
    }

    setPropertyBoolean( index )
    {
        let properties = this.state.properties.slice( 0 );
        properties[ index ].value = !Boolean( properties[ index ].value );
        this.setState({ properties: properties, edited: true });
    }

    removeProperty( id )
    {
        let properties = this.state.properties.slice( 0 );
        properties = properties.filter( i => i.id !== id )
        this.setState({ properties: properties, edited: true });
    }

    renderProperties()
    {
        let options = [];

        this.state.propertyOptions.forEach( o => {
            if( !this.state.properties.find( i => i.id === o.id ) )
            {
                options.push({
                    label: o.name,
                    icon: getTypeIcon( 'property' ),
                    action: ( id, closeFunc ) => {
                        let index = this.state.properties.findIndex( i => ( i.name > o.name ) );
                        if( index === -1 ) index = this.state.properties.length;

                        let properties = this.state.properties.slice( 0 );
                        properties.splice( index, 0,  {
                            id: o.id,
                            description: o.description,
                            name: o.name,
                            value: null,
                            unit: o.unit,
                            is_boolean : o.is_boolean,
                        });
                        this.setState({ properties: properties, edited: true });
                        closeFunc();
                    },
                })
            }
        });

        let addButton = null;
        if( options.length )
        {
            addButton = <div className="addButton">
                <ApDropdown
                    position="bottom"
                    fullWidth
                    button={ <ApButton className="save" color="white">
                        <SvgIcon icon="plus" type="solid" />
                        { tr('add_new_property') }
                    </ApButton> }
                    actions={ options }
                />
            </div>
        }

        const listDoms = this.state.properties.map( ( i, index ) => {
            let inputContainer = null;
            if( i.is_boolean )
            {
                inputContainer = <ApAddon className="isBooleanContainer">
                    <ApSwitch
                        id="supplier-price-toggle"
                        on={ Boolean( i.value ) }
                        onChange={ ( ) => this.setPropertyBoolean( index ) }
                        inline
                        small
                    />
                </ApAddon>
            }
            else
            {
                const unit = i.unit ? i.unit : '';
                let unitDom = null;
                let label = tr('value');

                if( i.value ) unitDom = <div className="unit">{ unit }</div>
                    else label = <span>{ tr('value') } <small>{ unit }</small></span>;

                inputContainer = <div className="unitInputContainer">
                    <ApInput
                        className="text-right"
                        label={ label }
                        type="text"
                        id={ `property-value-${ i.id }` }
                        name={ `property-value-${ i.id }` }
                        value={ i.value ? i.value : '' }
                        onChange={ ( e ) => this.setPropertyValue( index, e.target.value ) }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }

                        autoComplete="off"

                        validationState={ this.validator.getState('properties', index, 'value') }
                        tooltip={ this.validator.getText('properties', index, 'value') }

                    />
                    { unitDom }
                </div>
            }

            return <div key={ i.id } className="property listStack">
                <ApInputStack gap="0">
                    <ApAddon noRightBorder width="200px" className="titleAddon">
                        <ApTooltip block text={ i.description }>
                            <div className="title">{ i.name }</div>
                        </ApTooltip>
                    </ApAddon>
                    { inputContainer }
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete') }>
                            <div className="apSimpleButton" onClick={ () => { this.removeProperty( i.id ) }}>
                                <SvgIcon icon="trash" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                </ApInputStack>
            </div>
        });

        return <div>
            { addButton }
            { listDoms }
        </div>
    }

    removeComponentType(id) {
        let component_types = this.state.component_types.slice( 0 );
        component_types = component_types.filter( i => i.id !== id )
        this.setState({ component_types, edited: true });
    }

    renderTypes() {
        let options = [];
        this.state.component_type_options.forEach( o => {
            if( !this.state.component_types.find( i => i.type_id === o.id ) )
            {
                options.push({
                    label: o.name,
                    icon: getTypeIcon( 'types' ),
                    action: ( id, closeFunc ) => {
                        let index = this.state.component_types.findIndex( i => ( i.name > o.name ) );
                        if( index === -1 ) index = this.state.component_types.length;
                        this.newComponentTypeCounter++;
                        let component_types = this.state.component_types.slice( 0 );
                        component_types.splice( index, 0,  {
                            id: 'new'+this.newComponentTypeCounter,
                            type_id: o.id,
                            description: o.description,
                            name: o.name,
                        });
                        this.setState({ component_types, edited: true });
                        closeFunc();
                    },
                })
            }
        });

        let addButton = null;
        if( options.length )
        {
            addButton = <div className="addButton">
                <ApDropdown
                    position="bottom"
                    fullWidth
                    button={ <ApButton className="save" color="white">
                        <SvgIcon icon="plus" type="solid" />
                        { tr('add_new_type') }
                    </ApButton> }
                    actions={ options }
                />
            </div>
        }

        const listDoms = this.state.component_types.map( ( i, index ) => {
            return <div key={ i.id } className="component_type listStack">
                <ApInputStack gap="0">
                    <ApAddon noRightBorder width="100%">
                        <ApTooltip block text={i.description}>
                            <div className="title">{ i.name }</div>
                        </ApTooltip>
                    </ApAddon>
                    <ApAddon width="50px">
                        <ApTooltip block text={ tr('delete') }>
                            <div className="apSimpleButton" onClick={ () => { this.removeComponentType( i.id ) }}>
                                <SvgIcon icon="trash" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                </ApInputStack>
            </div>
        });

        return <div>
            {addButton}
            {listDoms}
        </div>;
    }

    renderIdentifyingTab()
    {
        return <ApTab key="identify" icon="fingerprint" label={ tr('identification') }>
            <div className="clear">
                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon={ getTypeIcon( 'identifier' ) } type="solid" /> { tr('identifiers') }
                        </h4>
                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            <ApTooltip text={ tr('identification_examples') }>
                                { tr('identifiers_info') }
                            </ApTooltip>
                        </div>
                        { this.renderIdentifiers() }
                    </div>
                </div>

                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon="link" type="solid" /> { tr('unifying_names') }
                        </h4>

                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            <ApTooltip text="">
                                { tr('unifying_names_info') }
                            </ApTooltip>
                        </div>
                        { this.renderAltNames() }
                    </div>
                </div>
            </div>
        </ApTab>
    }

    setCalculatableValue( index, value )
    {
        value = onlyNumber( value );
        let calculatables = this.state.calculatables.slice( 0 );
        calculatables[ index ].value = value;
        this.setState({ calculatables: calculatables, edited: true });
    }

    removeCalculatable( id )
    {
        let calculatables = this.state.calculatables.slice( 0 );
        calculatables = calculatables.filter( i => i.id !== id )
        this.setState({ calculatables: calculatables });
        const removeIds = this.state.component_extra_calculatables.reduce( (prevVal, item) => {
            if (item.extra_calculatable.calculatable_1_id == id || item.extra_calculatable.calculatable_2_id == id) {
                return [...prevVal, item.extra_calculatable.id]
            } else {
                return [...prevVal];
            }
        }, [])
        this.removeExtraCalculatable(removeIds);
    }

    removeExtraCalculatable( id )
    {
        let component_extra_calculatables = this.state.component_extra_calculatables.slice( 0 );
        component_extra_calculatables = component_extra_calculatables.filter( i => !id.includes(i.extra_calculatable.id) )
        this.setState({ component_extra_calculatables, edited: true });
    }

    handleMissingCalculatables(extraCalculatableId1, extraCalculatableId2) {
        const calculatables = this.state.calculatables.slice(0);
        const calculatable_ids = calculatables.reduce( (prevVal, item) => [...prevVal,item.id], []);

        if (!calculatable_ids.includes(extraCalculatableId1)) {
            const foundItem = this.state.calculatableOptions.find(item => item.id === extraCalculatableId1);
            
            let calcIndex = calculatables.findIndex( i => ( i.name > foundItem.name ) );
            if( calcIndex === -1 ) calcIndex = calculatables.length;
            
            calculatables.splice( calcIndex, 0,  {
                id: foundItem.id,
                description: foundItem.description,
                name: foundItem.name,
                value: null,
                unit: foundItem.unit,
                is_boolean : foundItem.is_boolean,
                work_component: foundItem.work_component,
            });
        }

        if (!calculatable_ids.includes(extraCalculatableId2)) {
            const foundItem = this.state.calculatableOptions.find(item => item.id === extraCalculatableId2);
            
            let calcIndex = calculatables.findIndex( i => ( i.name > foundItem.name ) );
            if( calcIndex === -1 ) calcIndex = calculatables.length;
            
            calculatables.splice( calcIndex, 0,  {
                id: foundItem.id,
                description: foundItem.description,
                name: foundItem.name,
                value: null,
                unit: foundItem.unit,
                is_boolean : foundItem.is_boolean,
                work_component: foundItem.work_component,
            });
        }
        
        this.setState({ calculatables, edited: true });
    }

    renderCalculatables()
    {
        let options = [];

        this.state.calculatableOptions.forEach( o => {
            if( !this.state.calculatables.find( i => i.id === o.id ) )
            {
                let unitDom = null;

                if( o.work_component )
                    unitDom = <SvgIcon icon={ getTypeIcon( 'work' ) } type="solid" />
                else
                    unitDom = o.unit;

                options.push({
                    label: <div>
                        { o.name }
                        <div className="dropDownUnit"> { unitDom }</div>
                    </div>,
                    icon: getTypeIcon( 'calculatable' ),
                    action: ( id, closeFunc ) => {
                        let index = this.state.calculatables.findIndex( i => ( i.name > o.name ) );
                        if( index === -1 ) index = this.state.calculatables.length;

                        let calculatables = this.state.calculatables.slice( 0 );
                        calculatables.splice( index, 0,  {
                            id: o.id,
                            description: o.description,
                            name: o.name,
                            value: null,
                            unit: o.unit,
                            is_boolean : o.is_boolean,
                            work_component: o.work_component,
                        });
                        this.setState({ calculatables: calculatables, edited: true });
                        closeFunc();
                    },
                })
            }
        });

        this.state.extra_calculatable_options.forEach(option => {
            // const calculatablesFound = 
            //     this.state.calculatables.find(item => item.id === option.extra_calculatable.calculatable_1_id)
            //     || this.state.calculatables.find(item => item.id === option.extra_calculatable.calculatable_2_id);
            if (!this.state.component_extra_calculatables.find(item => item.extra_calculatable.id === option.extra_calculatable.id)) {
                options.push({
                    label: <div>
                        { option.extra_calculatable.name }
                        <div className="dropDownUnit"> { option.extra_calculatable.unit }</div>
                    </div>,
                    icon: getTypeIcon( 'extra_calculatable' ),
                    action: ( id, closeFunc ) => {
                        let index = this.state.component_extra_calculatables.findIndex( item => ( item.name > option.extra_calculatable.name ) );
                        if( index === -1 ) index = this.state.component_extra_calculatables.length;

                        this.handleMissingCalculatables(option.extra_calculatable.calculatable_1_id, option.extra_calculatable.calculatable_2_id);

                        let component_extra_calculatables = this.state.component_extra_calculatables.slice( 0 );
                        component_extra_calculatables.splice( index, 0,  {
                            extra_calculatable:option.extra_calculatable
                        });
                        this.setState({ component_extra_calculatables, edited: true });
                        closeFunc();
                    },
                })
            }
        })

        let addButton = null;
        if( options.length )
        {
            addButton = <div className="addButton">
                <ApDropdown
                    position="bottom"
                    fullWidth
                    button={ <ApButton color="white">
                        <SvgIcon icon="plus" type="solid" />
                        { tr('add_new_meter') }
                    </ApButton> }
                    actions={ options }
                />
            </div>
        }

        const listDoms = this.state.calculatables.map( ( i, index ) => {

            const unit = i.unit ? i.unit : '';
            let unitDom = null;
            let label = tr('value');

            if( i.value ) unitDom = <div className="unit">{ unit }</div>
                else label = <span>{ tr('value') } <small>{ unit }</small></span>;

            let inputContainer = <div className="unitInputContainer">
                <ApInput
                    className="text-right"
                    label={ label }
                    type="text"
                    id={ `calculatable-value-${ i.id }` }
                    name={ `calculatable-value-${ i.id }` }
                    value={ i.value ? i.value : '' }
                    onChange={ ( e ) => this.setCalculatableValue( index, e.target.value ) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }

                    autoComplete="off"

                    validationState={ this.validator.getState('calculatables', index, 'value') }
                    tooltip={ this.validator.getText('calculatables', index, 'value') }

                />
                { unitDom }
            </div>

            let workComponent = null;
            if( i.work_component )
            {
                const tooltip = <ComponentTooltip component={ i.work_component } />
                workComponent = <ApAddon noLeftBorder width="50px">
                    <ApTooltip block text={ tooltip }>
                        <div className="workIcon">
                            <SvgIcon icon={ getTypeIcon( 'work' ) } type="solid" />
                        </div>
                    </ApTooltip>
                </ApAddon>
            }

            return <div key={ i.id } className="calculatable listStack">
                <ApInputStack gap="0">
                    <ApAddon noRightBorder width="200px" className="titleAddon">
                        <ApTooltip block text={ i.description }>
                            <div className="title">{ i.name }</div>
                        </ApTooltip>
                    </ApAddon>
                    { inputContainer }
                    { workComponent }
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete') }>
                            <div className="apSimpleButton" onClick={ () => { this.removeCalculatable( i.id ) }}>
                                <SvgIcon icon="trash" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                </ApInputStack>
            </div>
        });

        this.state.component_extra_calculatables.forEach( (i, index) => {
            const id1 = i.extra_calculatable.calculatable_1_id;
            const id2 = i.extra_calculatable.calculatable_2_id;
            const calculatable_1 = this.state.calculatables.find(item => item.id === id1);
            const calculatable_2 = this.state.calculatables.find(item => item.id === id2);
            if (!calculatable_1 || !calculatable_2) return;
            let value = null;
            if (calculatable_1.value && calculatable_2.value) {
                switch (i.extra_calculatable.operator) {
                    case '/':
                        value = parseFloat(calculatable_1.value) / parseFloat(calculatable_2.value);
                        break;
                    case '*':
                        value = parseFloat(calculatable_1.value) * parseFloat(calculatable_2.value);
                        break;
                    case '+':
                        value = parseFloat(calculatable_1.value) + parseFloat(calculatable_2.value);
                        break;
                    case '-':
                        value = parseFloat(calculatable_1.value) - parseFloat(calculatable_2.value);
                        break;
                    default:
                        value = null;
                        break;
                }
            }
            
            const inputContainer = <div className="unitInputContainer extraCalculatable">
                <ApInput
                    className="text-right"
                    label={ tr('value') }
                    type="text"
                    id={ `extra-calculatable-value-${ i.extra_calculatable.id }` }
                    name={ `extra-calculatable-value-${ i.extra_calculatable.id }` }
                    value={ value ? value.toFixed(2) : null }
                    loading={ this.state.loading }
                    disabled={ true }

                    autoComplete="off"

                    // validationState={ this.validator.getState('calculatables', index, 'value') }
                    // tooltip={ this.validator.getText('calculatables', index, 'value') }

                />
                { <div className='unit'>{i.extra_calculatable.unit}</div> }
            </div>

            listDoms.push(<div key={ 'extra_calc'+i.extra_calculatable.id } className="calculatable listStack">
                <ApInputStack gap="0">
                    <ApAddon noRightBorder width="200px" className="titleAddon">
                        <ApTooltip block text={ i.extra_calculatable.description }>
                            <div className="title">{ i.extra_calculatable.name }</div>
                        </ApTooltip>
                    </ApAddon>
                    { inputContainer }
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete') }>
                            <div className="apSimpleButton" onClick={ () => { this.removeExtraCalculatable( [i.extra_calculatable.id] ) }}>
                                <SvgIcon icon="trash" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                </ApInputStack>
            </div>)
        });

        return <div>
            { addButton }
            { listDoms }
        </div>
    }

    setAltNameValue( id, value )
    {
        let altNames = this.state.altNames.slice( 0 );
        altNames = this.state.altNames.map( i => {
            if( i.id === id ) i.name = value;
            return i;
        })
        this.setState({ altNames: altNames, edited: true });
    }

    removeAltName( id )
    {
        let altNames = this.state.altNames.slice( 0 );
        altNames = altNames.filter( i => i.id !== id );
        this.setState({ altNames: altNames, edited: true });
    }

    addAltName()
    {
        this.newAltNameCounter++;

        let altNames = this.state.altNames.slice( 0 );
        altNames.push({ id: 'new' + this.newAltNameCounter, name: '' });
        this.setState({ altNames: altNames, edited: true });
    }

    renderAltNames()
    {
        const addButton = <ApButton color="white" onClick={ this.addAltName }>
            <SvgIcon icon="plus" type="solid" />
            { tr('add_unifying_name') }
        </ApButton>

        let listDoms = [];

        this.state.altNames.forEach( ( i, index ) => {

            if( i.name === this.state.name ) return null;

            listDoms.push( <div key={ index } className="altNames listStack">
                <ApInputStack gap="0">
                    <ApInput
                        label={ tr('unifying_name') }
                        type="text"
                        id={ `altname-value-${ index }` }
                        name={ `altname-value-${ index }` }
                        value={ i.name ? i.name : '' }
                        onChange={ ( e ) => this.setAltNameValue( i.id, e.target.value ) }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }

                        autoComplete="off"

                        validationState={ this.validator.getState('altNames', index, 'name') }
                        tooltip={ this.validator.getText('altNames', index, 'name') }
                    />
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete') }>
                            <div className="apSimpleButton" onClick={ () => { this.removeAltName( i.id ) }}>
                                <SvgIcon icon="trash" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                </ApInputStack>
            </div>)
        });

        listDoms.push(
            <div key="currentname" className="altNames listStack">
                <ApInputStack gap="0">
                    <ApInput
                        label={ tr('unifying_name') }
                        type="text"
                        id={ `altname-current` }
                        name={ `altname-current` }
                        value={ this.state.name }
                        loading={ this.state.loading }
                        disabled={ true }
                        autoComplete="off"
                    />
                </ApInputStack>
            </div>
        )

        return <div>
            { addButton }
            { listDoms }
        </div>
    }

    renderProjectTab()
    {
        let calculatablesDom = null;
        let trackunitsDom = null;
        let inTimetracking = null;
        let isProduced = null;

        if( this.state.type_name !== 'work' )
        {
            inTimetracking = <div className="padding">
                <h4 className="formHeader">
                    <SvgIcon icon="cogs" type="solid" /> { tr('other_settings') }
                </h4>
                <div className="apSwitchBlock small">
                    <label htmlFor="in-timetracking-default" className="info">
                        { tr('by_default_in_timetracking_entry') }
                        <small>{ tr('storage_component_added_to_project_note') }</small>
                    </label>
                    <ApSwitch
                        id="in-timetracking-default"
                        on={ this.state.in_timetracking_default }
                        onChange={() => this.setState({ in_timetracking_default: !this.state.in_timetracking_default, edited: true }) }
                        disabled={ this.state.loading }
                    />

                </div>
                    


            </div>
            isProduced = <div className="padding">
                    <h4 className="formHeader">
                        <SvgIcon icon="cogs" type="solid" /> { tr('product_manufacturing_settings') }
                    </h4>
                    <div className="apSwitchBlock small">
                        <label htmlFor="component-is-produced" className="info">
                            { tr('component_manufactured_in_projects') }
                            <small>{ tr('component_manufactured_in_projects_info') }</small>
                        </label>
                        <ApSwitch
	                        id="component-is-produced"
	                        on={ this.state.component_is_produced }
                        onChange={() => this.setState({ component_is_produced: !this.state.component_is_produced, edited: true }) }
	                        disabled={ this.state.loading }
	                    />

                    </div>
                  </div>
        }

        if( this.state.type_name === 'item' )
        {
            calculatablesDom = <div className="padding">
                <h4 className="formHeader">
                    <SvgIcon icon={ getTypeIcon( 'calculatable' ) } type="solid" /> { tr('meters') }
                </h4>
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    <ApTooltip text={ tr('installation_time_weight') }>
                        { tr('meters_info') }
                    </ApTooltip>
                </div>
                { this.renderCalculatables() }
            </div>

            trackunitsDom = <div className="padding">
                <h4 className="formHeader">
                    <SvgIcon icon={ getTypeIcon( 'trackingunit' ) } type="solid" /> { tr('monitoring_units') }
                </h4>
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    {/* TODO: kuvaus seurantayksiköille */}
                </div>
                <ApSelect
                    label={ tr('monitoring_units') }
                    value={ this.state.trackingunits || [] }
                    options={ this.state.trackingunitOptions || [] }
                    objKeyId="id"
                    objKeySearchable={[ "name", "description", "unit" ]}
                    multiselect
                    clearable
                    optionRenderer={ ( item ) => <div>{ item.name }</div> }
                    valueRenderer={ ( item ) => <div>{ item.name }</div> }
                    onChange={items => this.setState({ trackingunits: items, edited: true } ) }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </div>
        }

        return <ApTab key="property" icon={ getTypeIcon( 'project' ) } label={ tr('projects') }>
            <div className="clear">
                <div className="apColumn w50">
                    { calculatablesDom }
                    { inTimetracking }
                    { isProduced }
                </div>
                <div className="apColumn w50">
                    { trackunitsDom }
                </div>
            </div>
        </ApTab>
    }

    locationChange( index, selected )
    {
        let locations = this.state.locations.slice( 0 );

        locations[ index ].id = selected.id;
        locations[ index ].code = selected.code;
        locations[ index ].description = selected.description;

        this.setState({ locations: locations, edited: true });
    }

    setLocationBalanceValue( index, value )
    {
        if( value ) value = onlyNumber( value );

        let locations = this.state.locations.slice( 0 );
        locations[ index ].balance = value;
        this.setState({ locations: locations, edited: true });
    }

    setLocationValue( index, field, value )
    {
        if (!hasPermissions('storage.items.locations')) return;
        if( value ) value = onlyNumber( value );

        let locations = this.state.locations.slice( 0 );
        locations[ index ][ field ] = value;
        this.setState({ locations: locations, edited: true });
    }

    removeLocation( index )
    {
        const locs = this.state.locationsRemoved;
        locs.push(index);
        this.setState({locationsRemoved: locs});

        let locations = this.state.locations.slice( 0 );
        locations.splice( index, 1 );
        this.setState({ locations: locations, edited: true });
    }

    addLocation( selected )
    {
        const index = this.state.locations.findIndex( l => ( l.name > selected.code ) );
        let locations = this.state.locations.slice( 0 );
        locations.splice( index, 0,  {
            id: selected.id,
            code: selected.code,
            balance: null,
            limit_alarm: null,
            limit_notification: null,
            order_amount: null,
            name: selected.name,
        });

        this.setState({ locations: locations, edited: true });
    }

    attemptSave(status_name) {
        if (this.state.locationsRemoved.length > 0) {
            this.setState({
                showConfirmDeletion: true,
                status_name: status_name
            });
        } else { this.save(status_name); }
    }

    renderLocations()
    {
        const componentDisabled = !hasPermissions('storage.items.locations');
        const unit = this.state.unit;

        const addButton = <ApSelect
            label={ tr('add_storage') }
            value={ null }
            optionRenderer="storage_location"
            onChange={ this.addLocation }
            apiUrl="storage/locations/find"
            apiData={{
                canComponents: true,
                excludeIds: this.state.locations.map( i => i.id )
            }}
            loading={ this.state.loading }
            disabled={ this.state.loading }
            readOnly={componentDisabled}
        />

        const getOptionalInput = ( title, field, location, index ) => {

            let value = location[ field ];
            let noValue = false;
            if( value === null )
            {
                value = '-';
                noValue = true;
            }

            const input = <ApInput
                className="text-right"
                label={ title }
                type="text"
                id={ `location-${ field }-${ location.id }` }
                name={ `location-${ field }-${ location.id }` }
                value={ value ? value : '' }
                onChange={ ( e ) => this.setLocationValue( index, field, e.target.value ) }

                autoComplete="off"
                autoFocus={noValue ? false : true}

                loading={ this.state.loading }
                disabled={ this.state.loading }
                readOnly={componentDisabled}

                validationState={ this.validator.getState( 'locations', index, field ) }
                tooltip={ this.validator.getText( 'locations', index, field ) }

            />

            if( noValue )
            {
                return <div className="disabledInputContainer" onClick={ () => this.setLocationValue( index, field, '' ) }>
                    <ApTooltip block text={ tr('click_to_configure') }>
                        { input }
                    </ApTooltip>
                </div>
            }

            let unitDom  = null;
            if( value ) unitDom = <div className="unit">{ unit ? unit : '' }</div>

            return <div>
                <ApInputStack gap="0">
                    <div className="optionalInputContainer">
                        { input }
                        { unitDom }
                    </div>
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete') }>
                            <div className="apSimpleButton" onClick={ () => { this.setLocationValue( index, field, null ) }}>
                                <SvgIcon icon="eraser" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                </ApInputStack>
            </div>
        }

        const locationRows = this.state.locations.map( ( i, index ) => {

            const label = i.type != 'P' ? tr('storage') : tr('project_storage');
            let limitNotifLabel, limitAlarmLabel, orderAmountLabel;

            if (i.type != 'P') { //Normivarastoon
                limitNotifLabel = tr('attention_limit');
                limitAlarmLabel = tr('alert_limit');
                orderAmountLabel = tr('default_order_amount');
            } else { //Projektivarastoon
                limitNotifLabel = tr('initial_demand');
                limitAlarmLabel = tr('demand_now');
                orderAmountLabel = tr('ordered_amount');
            }

            return <tr key={ i.id }>
                <td className="selectLocationInputCol">
                    <ApInput
                        label={label}
                        type="text"
                        id={ `location-name-${ i.id }` }
                        name={ `location-name-${ i.id }` }
                        value={ i.code ? (i.code+" ("+i.name+")") : '' }
                        loading={ this.state.loading }
                        readOnly
                    />
                </td>
                <td className="balanceCol">
                    <div className="balanceContainer">
                        <ApInput
                            className="text-right"
                            label={ tr('balance') }
                            type="text"
                            id={ `location-balance-${ i.id }` }
                            name={ `location-balance-${ i.id }` }
                            value={ i.balance ? i.balance : '' }
                            onChange={ ( e ) => this.setLocationBalanceValue( index, e.target.value ) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }

                            autoComplete="off"

                            validationState={ this.validator.getState( 'locations', index, 'balance' ) }
                            tooltip={ this.validator.getText( 'locations', index, 'balance' ) }
                            readOnly={componentDisabled}
                        />
                        { Boolean( i.balance ) && <div className="unit">{ unit ? unit : '' }</div> }
                    </div>
                </td>
                <td className="optionalInputCol">
                    { getOptionalInput( limitNotifLabel, 'limit_notification', i, index )}
                </td>
                <td className="optionalInputCol">
                    { getOptionalInput( limitAlarmLabel, 'limit_alarm', i, index )}
                </td>
                <td className="optionalInputCol">
                    { getOptionalInput( orderAmountLabel, 'order_amount', i, index )}
                </td>
                <td className="removeCol">
                    <ApTooltip text={ tr('delete') } block>
                        <ApButton color="white" onClick={ () => { this.removeLocation( index ) } } disabled={componentDisabled}>
                            <SvgIcon icon="trash" type="solid" />
                        </ApButton>
                    </ApTooltip>
                </td>
            </tr>
        });

        return <div>
            { addButton }
            <table className="listTable">
                <tbody>
                    { locationRows }
                </tbody>
            </table>
        </div>
    }

    renderLocationTab()
    {
        return <ApTab key="location" icon="map-signs" label={ tr('storages') }>
            <div className="padding">
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('storages_info') }
                </div>
                { this.renderLocations() }

            </div>
        </ApTab>
    }

    addSupplier( selected )
    {
        let suppliers = this.state.suppliers.slice( 0 );
        let index = this.state.suppliers.findIndex( s => ( s.name > selected.name ) );
        suppliers.splice( index, 0,  {
            id: selected.id,
            name: selected.name,
            primary: false,
            purchase_price: null,
            discount_percent: null,
            visible_to_supplier: true,
            delivery_time:null,
        });
        this.setState({ suppliers: suppliers, edited: true });
    }

    removeSupplier( index )
    {
        let suppliers = this.state.suppliers.slice( 0 );
        suppliers.splice( index, 1 );
        this.setState({ suppliers: suppliers, edited: true });
    }

    unsetPrimarySupplier( index )
    {
        let suppliers = this.state.suppliers.slice( 0 );
        suppliers = suppliers.map( ( s, i )  => {
             if ( i === index ) {
                s.primary=false;
             }

            return s;
        });
        
        this.setState({ suppliers: suppliers, edited: true });
    }  

    setPrimarySupplier( index )
    {
        let suppliers = this.state.suppliers.slice( 0 );
        let price = this.state.price;
        let price_sell = this.state.price_sell;
        suppliers = suppliers.map( ( s, i )  => {
            s.primary = ( i === index );
            if (s.primary && s.purchase_price>0)
            {
                price = s.discount_percent>0?((100-s.discount_percent)/100)*s.purchase_price:s.purchase_price;
                let profitPercent = this.state.profitPercent;    
                price_sell = profitPercentToSellPrice( s.purchase_price,  profitPercent);
            }
            return s;
        });
        
        this.setState({ price: price, price_sell: price_sell, suppliers: suppliers, edited: true });
    }   

    setSupplierPrice( index, value )
    {
        if( value ) value = onlyNumber( value );

        let suppliers = this.state.suppliers.slice( 0 );
        let price = this.state.price;
        let price_sell = this.state.price_sell;

        suppliers[ index ].purchase_price = value;

        if (suppliers[ index ].primary) {
            price = suppliers[ index ].discount_percent>0?((100-suppliers[ index ].discount_percent)/100)*suppliers[ index ].purchase_price:suppliers[ index ].purchase_price;
            price_sell = profitPercentToSellPrice( price, this.state.profitPercent);
        }
        
        this.setState({ price: price, price_sell: price_sell, suppliers: suppliers, edited: true });
    }

    setSupplierDiscount( index, value )
    {
        if( value ) value = onlyNumber( value );
        if (value>100) {
            value=100;
        }
        if (value<0) {
            value=0;
        }
        let suppliers = this.state.suppliers.slice( 0 );
        let price = this.state.price;
        let price_sell = this.state.price_sell;

        suppliers[ index ].discount_percent = value;

        if (suppliers[ index ].primary) {
            price = suppliers[ index ].discount_percent>0?((100-suppliers[ index ].discount_percent)/100)*suppliers[ index ].purchase_price:suppliers[ index ].purchase_price;
            price_sell = profitPercentToSellPrice( price, this.state.profitPercent);
        }

        this.setState({ price: price, price_sell: price_sell, suppliers: suppliers, edited: true });
    }

    setSupplierDeliveryTime(index, value) {
        if (value) value = onlyNumber(value);
        
        let suppliers = this.state.suppliers.slice(0);
        
        suppliers[index].delivery_time = value;

        this.setState({ suppliers: suppliers, edited: true });
    }

    setSupplierVisibility(index) {
        // console.log(index);
        let suppliers = this.state.suppliers.slice(0);
        
        suppliers[index].visible_to_supplier = !suppliers[index].visible_to_supplier;
        this.setState({ suppliers: suppliers, edited: true });
    }

    renderSuppliers()
    {
        const addButton = <ApSelect
            label={ tr('add_supplier') }
            value={ null }
            optionRenderer="storage_supplier"
            onChange={ this.addSupplier }
            clearable
            apiUrl="storage/po/supplier/find"
            apiData={{
                excludeIds: this.state.suppliers.map( i => i.id ),
            }}
            loading={ this.state.loading }
            disabled={ this.state.loading }
        />

        const supplierRows = this.state.suppliers.map( ( i, index ) => {
            let primaryDom = <ApButton color="white" onClick={ () => { this.setPrimarySupplier( index ) } }>
                    { tr('set_as_primary') }
                </ApButton>

            if( i.primary )
            {
                primaryDom = <ApButton color="white" onClick={ () => { this.unsetPrimarySupplier( index ) } }>
                        { tr('remove_primary') }
                    </ApButton>
            }

            return <tr key={ i.id }>
                <td className="setPrimaryCol">
                    { primaryDom }
                </td>
                <td className="selectSupplierInputCol">
                    <ApInput
                        label={ tr('supplier') }
                        type="text"
                        className={i.primary?'primary-supplier':''}
                        id={ `supplier-name-${ i.id }` }
                        name={ `supplier-name-${ i.id }` }
                        value={ i.name ? i.name : '' }
                        loading={ this.state.loading }
                        readOnly
                    />
                </td>
                <td className="priceCol">
                    <div className="priceContainer">
                        <ApInput
                            className="text-right"
                            label={ tr('supplier_price') }
                            type="text"
                            id={ `supplier-price-${ i.id }` }
                            name={ `supplier-price-${ i.id }` }
                            value={ i.purchase_price ? i.purchase_price : '' }
                            onChange={ ( e ) => this.setSupplierPrice( index, e.target.value ) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }

                            //validationState={ this.validator.getState( 'suppliers', index, 'purchase_price' ) }
                            //tooltip={ this.validator.getText( 'suppliers', index, 'purchase_price' ) }

                            noFocusDecimals={ 2 }
                            autoComplete="off"
                        />
                        { Boolean( i.purchase_price ) && <div className="unit">{ this.state.currencySign ? this.state.currencySign : '' }</div> }
                    </div>
                </td>
                <td className="priceCol">
                    <div className="priceContainer">
                        <ApInput
                                className="text-right"
                                label={ tr('discount_percentage') }
                                type="text"
                                id={ `supplier-discount_percent-${ i.id }` }
                                name={ `supplier-discount_percent-${ i.id }` }
                                value={ i.discount_percent ? i.discount_percent : '' }
                                onChange={ ( e ) => this.setSupplierDiscount( index, e.target.value ) }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                noFocusDecimals={ 1 }
                                autoComplete="off"
                            />
                            { <div className="unit">%</div> }
                    </div>
                </td>

                <td className="deliveryTimeCol">
                    <div className="deliveryTimeContainer" style={{position: 'relative'}}>
                        <ApInput
                            // className="text-right"
                            label={tr('delivery_time')}
                            type="text"
                            id={`supplier-delivery_time-${i.id}`}
                            name={`supplier-delivery_time-${i.id}`}
                            value={i.delivery_time ? i.delivery_time : ''}
                            onChange={(e) => this.setSupplierDeliveryTime(index, e.target.value)}
                            loading={this.state.loading}
                            disabled={this.state.loading}
                            //noFocusDecimals={1}
                            autoComplete="off"
                            align='left'
                        />
                        {<div className="unit">{tr("day_abbr")}</div>}
                    </div>
                </td>

                <td className="visibleToSupplier">
                    
                    <div className="visibleToSupplierContainer">
                        {tr("add_supplier_visible")}
                        <ApSwitch
                            id={`visibleToSupplier-${i.id }`}
                            on={i.visible_to_supplier}
                            onChange={() => this.setSupplierVisibility(index)}
                            //inline
                            small
                        />
                        
                    </div>
                </td>
                
                <td className="removeCol">
                    <ApTooltip text={ tr('delete') } block>
                        <ApButton color="white" onClick={ () => { this.removeSupplier( index ) } }>
                            <SvgIcon icon="trash" type="solid" />
                        </ApButton>
                    </ApTooltip>
                </td>
            </tr>
        });

        return <div>
            { addButton }
            <table className="listTable">
                <tbody>
                    { supplierRows }
                </tbody>
            </table>
        </div>
    }

    removeWholesale( index )
    {
        let wholesales = this.state.wholesale_components.slice( 0 );
        wholesales.splice( index, 1 );
        this.setState({ wholesale_components: wholesales, edited: true });
    }

    renderWholesales()
    {
        const wholesaleRows = this.state.wholesale_components.map( ( i, index ) => {

            return <tr key={ i.wholesale_component_id } className="LVISrow">
                <td className="LVISSupplierInputCol">
                    <ApInput
                        label={`LVISNet ${tr('supplier')}`}
                        type="text"
                        id={ `lvis-name-${ i.id }` }
                        name={ `lvis-name-${ i.id }` }
                        value={ ucfirst( i.wholesale_name ) }
                        loading={ this.state.loading }
                        readOnly
                    />
                </td>

                <td className="LVISPriceCol">
                    <div className="priceContainer">
                        <ApInput
                            className="text-right"
                            label={ tr('supplier_price') }
                            type="text"
                            id={ `lvis-price-${ i.id }` }
                            name={ `lvis-price-${ i.id }` }
                            value={ i.price ? i.price : '' }

                            loading={ this.state.loading }
                            noFocusDecimals={ 2 }
                            readOnly

                        />
                        <div className="unit">{ this.state.currencySign ? this.state.currencySign : '' }</div>
                    </div>
                </td>
                <td className="removeCol">
                    <ApTooltip text={ tr('delete') } block>
                        <ApButton color="white" onClick={ () => { this.removeWholesale( index ) } }>
                            <SvgIcon icon="trash" type="solid" />
                        </ApButton>
                    </ApTooltip>
                </td>
            </tr>
        });

        let autoPriceOptions = this.state.wholesale_components.map( i => {
            return { value: i.wholesale_component_id, label: ucfirst( i.wholesale_name ) };
        });
        autoPriceOptions.unshift({ value: null, label: tr('will_not_be_updated') });
        return <div>
            <table className="listTable">
                <tbody>
                    { wholesaleRows }
                </tbody>
            </table>

            <ApInputStack gap="0">
                <ApAddon noRightBorder >
                    { tr('update_product_LVISNet' ) }
                </ApAddon>
                <ApInput
                    type="select"
                    options={ autoPriceOptions }
                    id="showDetail"
                    name="showDetail"
                    value={ this.state.wholesaleUpdateId  }
                    onChange={ (e) => {
                        let value = parseInt(  e.target.value );
                        if( isNaN( value )) value = null;
                        this.setState({ wholesaleUpdateId: value })
                    }}
                />
            </ApInputStack>
        </div>

    }

    renderSupplierTab()
    {
        return <ApTab key="location" icon={ getTypeIcon( 'supplier' ) } label={ tr('suppliers') }>
            <div className="padding">
                <h4 className="formHeader">
                    <SvgIcon icon={ getTypeIcon( 'supplier' ) } type="solid" /> { tr('suppliers') }
                </h4>
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('storage_component_suppliers_info') }
                </div>
                { this.renderSuppliers() }
            </div>

            {this.state.wholesale_components!==false && this.state.wholesaleUpdateId!==false &&
            <div className="padding">
                <h4 className="formHeader">
                    <SvgIcon icon={ getTypeIcon( 'supplier' ) } type="solid" /> { tr('LVISNet_suppliers') }
                </h4>
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    { tr('LVISNet_suppliers_info') }
                </div>
                { this.renderWholesales() }
            </div>
            }
        </ApTab>
    }

    removeFile( index )
    {
        let files = this.state.files.slice( 0 );
        files.splice( index, 1 );
        this.setState({ files: files, edited: true });
    }

    renderFiles()
    {
        const addButton = <ApFileUpload
            ref={ ref => this.fileUploadRef = ref }
            files={ this.state.newFiles }
            onChange={ this.filesChange }
            description={ "File for component " + this.props.id }
            maxSize={ 1024 * 1024 * 20 } // 20 Mt
            disabled={ this.state.loading }
            noFilesLabel={ tr('add_files') }
            noFilesSubLabel={ tr('add_files_storage_component') }
            addButtonLabel={ `${tr('choose_file')}...` }
        />

        const listDoms = this.state.files.map( ( i, index ) => {
            return <div key={ i.id } className="files listStack">
                <ApInputStack gap="0">
                        <ApAddon>
                            <File  fileId={ i.id } name={ i.original_name }>
                                <SvgIcon icon="file" type="solid" /> { i.original_name }
                            </File>
                        </ApAddon>
                    <ApAddon noLeftBorder width="50px">
                        <ApTooltip block text={ tr('delete') }>
                            <div className="apSimpleButton" onClick={ () => { this.removeFile( index ) }}>
                                <SvgIcon icon="trash" type="solid" />
                            </div>
                        </ApTooltip>
                    </ApAddon>
                </ApInputStack>
            </div>
        });
        return <div>
            { addButton }
            { listDoms }
        </div>
    }

    filesChange(  files , userAction )
    {
        let newFileIds = files.map( f => f.id )
        this.setState({ newFileIds: newFileIds });
    }

    renderMoreDetailsTab()
    {
        return <ApTab key="files" icon="list-alt" label={ tr('storage_component_additional_info') }>

            <div className="clear">
                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon={ getTypeIcon( 'property' ) } type="solid" /> { tr('properties') }
                        </h4>

                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            <ApTooltip text={ tr('storage_component_properties_examples') }>
                                { tr('storage_component_properties_info') }
                            </ApTooltip>
                        </div>
                        { this.renderProperties() }
                    </div>

                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon={ getTypeIcon( 'types' ) } type="solid" /> { tr('types') }
                        </h4>

                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { tr('component_type_info') }
                        </div>
                        { this.renderTypes() }
                    </div>
                </div>


                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon="file" type="solid" /> { tr('files') }
                        </h4>

                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { tr('storage_component_files_info') }
                        </div>
                        { this.renderFiles() }
                    </div>
                </div>
            </div>

        </ApTab>
    }

    setHourTypeBoolean( index )
    {
        let hourTypes = this.state.hourTypes.slice(0);
        hourTypes[ index ].value_manual = !hourTypes[ index ].value_manual;
        this.setState({ hourTypes: hourTypes, edited: true }, () => { this.hourTypesUpdateValue() });
    }

    setHourTypeNoBilling( index )
    {
        let hourTypes = this.state.hourTypes.slice(0);
        hourTypes[ index ].component_no_billing = !hourTypes[ index ].component_no_billing;
        this.setState({ hourTypes: hourTypes, edited: true }, () => { this.hourTypesUpdateValue() });
    }

    setHourTypeMultiplier( index, value )
    {
        if( value ) value = onlyNumber( value );

        let hourTypes = this.state.hourTypes.slice(0);
        hourTypes[ index ].component_multiplier = value;

        this.setState({ hourTypes: hourTypes, edited: true }, () => { this.hourTypesUpdateValue() });
    }

    hourTypesUpdateValue()
    {
        let hourTypes = this.state.hourTypes;
        if( !hourTypes || !hourTypes.length ) return null;
        hourTypes =  hourTypes.slice(0).map( ( i, index ) => {
            const price = i.hour_price || this.state.price_sell;
            i.value = price * i.salary_multiplier * i.component_multiplier || 0;
            return i;
        });


        this.setState({ hourTypes: hourTypes });
    }

    renderHourTypes()
    {
        const listDoms = this.state.hourTypes.map( ( i, index ) => {

            const labelDom = <ApAddon noRightBorder width="100px" className="titleAddon">
                <ApTooltip block text={ i.description }>
                    <div className="title">{ i.name }</div>
                </ApTooltip>
            </ApAddon>

            const noBillingDom = <ApAddon className="isBooleanContainer" width="50px">
                <ApTooltip block text="Laskutetaan">
                    <ApSwitch
                        id={`hour-type-no-billing-switch-${ i.id }`}
                        on={ !i.component_no_billing }
                        onChange={ () => this.setHourTypeNoBilling( index ) }
                        inline
                        small
                    />
                </ApTooltip>
            </ApAddon>

            if( i.component_no_billing )
            {
                return <div key={ i.id } className="property listStack">
                    <ApInputStack gap="0">
                        { labelDom }
                        { noBillingDom }
                        <ApAddon>&nbsp;</ApAddon>
                    </ApInputStack>
                </div>

            }
            else
            {
                return <div key={ i.id } className="property listStack">
                    <ApInputStack gap="0">
                        { labelDom }
                        { noBillingDom }
                        <ApInput
                            className="text-right"
                            label={ tr('work_multiplier') }
                            type="text"
                            id={ `property-salary-multiplier-${ i.id }` }
                            name={ `property-salary-multiplier-${ i.id }` }
                            value={ i.salary_multiplier ? i.salary_multiplier : '' }
                            loading={ this.state.loading }
                            disabled={ true }
                            autoComplete="off"

                        />

                        <ApInput
                            className="text-right"
                            label={ tr('component_multiplier') }
                            type="text"
                            id={ `property-multiplier-${ i.id }` }
                            name={ `property-multiplier-${ i.id }` }
                            value={ i.component_multiplier ? i.component_multiplier : '' }
                            onChange={ ( e ) => this.setHourTypeMultiplier( index, e.target.value ) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            autoComplete="off"
                            validationState={ this.validator.getState('hourTypes', index, 'component_multiplier') }
                            tooltip={ this.validator.getText('hourTypes', index, 'component_multiplier') }
                        />

                        <ApInput
                            className="text-right"
                            label={`${tr('billing_price')} (${ this.state.currencySign })`}
                            type="text"
                            id={ `property-value-${ i.id }` }
                            name={ `property-value-${ i.id }` }
                            value={ i.value ? i.value : '' }
                            loading={ this.state.loading }
                            disabled={ true }
                            noFocusDecimals={ 2 }

                            autoComplete="off"
                        />
                    </ApInputStack>
                </div>
            }
        });

        return <div style={{overflowX: 'auto'}}>
            { listDoms }
        </div>
    }

    renderBillingTab()
    {
        let hourTypesDom = null;
        if( this.state.billing )
            hourTypesDom = this.renderHourTypes();

        return <ApTab key="billing" icon="money-bill-wave-alt" label={ tr('billing') }>
            <div className="clear">
                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon="money-bill-wave-alt" type="solid" /> { tr('price_info') }
                        </h4>
                        <PriceInputs
                            typeName={ this.state.type_name }
                            price={ this.state.price }
                            lastprice={ this.state.lastprice }
                            avgprice={ this.state.avgprice }
                            price_sell={ this.state.price_sell }
                            profitPercent={ this.state.profitPercent }
                            order_price={ this.state.order_price }
                            order_price_type={ this.state.order_price_type }
                            order_price_min_profit={ this.state.order_price_min_profit }
                            online_store_product_price={ this.state.online_store_product_price }
                            online_store_profit_percent={ this.state.online_store_profit_percent }
                            loading={ this.state.loading }
                            onChange={(data) => this.setState(data, () => { this.hourTypesUpdateValue() })}
                            project_price_type={this.state.project_price_type}
                            changeState={(data, afterFunction) => this.changeState(data, afterFunction)}
                        />
                    </div>
                </div>
                <div className="apColumn w50">
                    <div className="padding">
                        <h4 className="formHeader">
                            <SvgIcon icon="cash-register" type="solid" /> { tr('hourly_billing') }
                        </h4>
                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { tr('hourly_billing_info') }
                        </div>
                            <div className="apSwitchBlock small billingSwitch">
                                <label htmlFor="billing-hourly-switch" className="info">
                                    { tr('hourly_billed') }
                                </label>
                                <ApSwitch
                                    id="billing-hourly-switch"
                                    on={ this.state.billing }
                                    onChange={() => this.setState({ billing: !this.state.billing, edited: true }) }
                                    disabled={ this.state.loading }
                                />
                            </div>
                            { hourTypesDom }
                    </div>
                </div>
            </div>
        </ApTab>
    }

    tabChange( selected )
    {
        this.setState({ selectedTab: selected });
    }

    renderBody()
    {
        if( !this.props.id ) return <div></div>;

        let tabs = [];
        tabs.push( this.renderBasicInfoTab() );

        if( this.state.type_name === 'work' )
            tabs.push( this.renderBillingTab() );

        tabs.push( this.renderMoreDetailsTab() );
        tabs.push( this.renderIdentifyingTab() );

        if( this.state.type_name !== 'work' )
            tabs.push( this.renderProjectTab() );

        if( this.state.type_name === 'item' )
        {
            if(hasPermissions('storage.locations')) tabs.push( this.renderLocationTab() );
            tabs.push( this.renderSupplierTab() );
        }

        return <ApTabs selected={ this.state.selectedTab } onChange={ this.tabChange } >
            { tabs }
        </ApTabs>
    }

    renderHeader()
    {
        if( !this.props.id ) return null;

        let header = null;
        let status = null;

        if( this.state.type_name )
        {

            let titleText = [];
            if( keyExists( this.state, 'code.ancestors') )
                titleText = this.state.code.ancestors.map( a => a.name );
            if( keyExists( this.state, 'code.name' ) )
                titleText.push( this.state.code.name );
            if( this.state.name )
                titleText.push( this.state.name );
            titleText = titleText.filter( f => f ).join(' / ');

            header = <div>
                <SvgIcon icon={ getTypeIcon( this.state.type_name ) } type="solid" />
                    <h4 className='ellipsis'> 
                        { tr('edit') } { tr(this.state.type_text).toLowerCase() }
                        <div className="headerCode">( { titleText } )</div>
                    </h4>
            </div>

            let statusDetail = <span>{ tr('storage_component_inactive_info') }</span>
            if( this.state.status_name === 'active' )
                statusDetail = <span>{ tr('storage_component_active_info') }</span>

            status = <div>
                <ApTooltip block text={
                    <div>
                        <div>{ tr('storage_component_current_status', [tr(this.state.status_text)]) }</div>
                        <div>{ statusDetail }</div>
                    </div>
                }>
                <div className="mainStatus">
                    <div className={`apStatusBox ${ getComponentStatusColor( this.state.status_name ) }`}> { tr(this.state.status_text) } {this.state.temporary ? ` - ${tr('external')}` : ""}</div>
                </div>
                </ApTooltip>
            </div>
        }
        else
        {
            header = <div>
                <SvgIcon icon="question" type="solid" />
                <div className="apLoader"></div>
                <h4>&nbsp;</h4>
            </div>
        }



        return <div className="header">
            { header }
            { status }
        </div>
    }

    renderFooter()
    {
        const validatorText = this.validator.getStatusAll();
        const  statusName = this.state.status_name

        const actions = [
            {
                visible: () => {
                    return ( statusName === 'plan' );
                },
                action: 'DELETE',
                icon: 'trash',
                label: <div className={`apStatusBox error`}> { tr('delete') }</div>,
                text: <div>
                        <div>{ tr('storage_component_confirm_delete') }</div>
                        <ul>
                            <li>{ tr('storage_component_confirm_delete_note1') }</li>
                        </ul>
                    </div>
            },
            {
                visible: () => {
                    return ( statusName !== 'active' )
                },
                action: 'active',
                icon: 'play-circle',
                label: <div className={`apStatusBox success`}> { tr('save_as_active') }</div>,
                text: <div>
                        <div>{ tr('storage_component_confirm_save_active') }</div>
                        <ul>
                            <li>{ tr('storage_component_confirm_save_active_note1') }</li>
                            <li>{ tr('storage_component_confirm_save_active_note2') }</li>
                        </ul>
                    </div>,
            },
            {
                visible: () => {
                    if( statusName === 'plan' ) return false;
                    return ( statusName !== 'frozen' )
                },
                action: 'frozen',
                icon: 'pause-circle',
                label: <div className={`apStatusBox info`}>{ tr('save_as_frozen') }</div>,
                text: <div>
                        <div>{ tr('storage_component_confirm_save_frozen') }</div>
                        <ul>
                            <li>{ tr('storage_component_confirm_save_frozen_note') }</li>
                        </ul>
                    </div>,
            },
            {
                visible: () => {
                    if( this.state.type_id === 3 ) return true;
                },
                action: 'changeTo',
                icon: 'exchange-alt',
                label: <div className={`apStatusBox info`}>{tr('change_to_component')}</div>,
                text: <div>
                        <div>{tr('change_to_component_confirm')}</div>
                        <ul>
                            <li>{tr('change_to_component_confirm_note')}</li>
                        </ul>
                    </div>,
            },
            {
                visible: () => {
                    return true;
                },
                action: 'changeTemporarity',
                icon: 'exchange-alt',
                label: <div className={`apStatusBox info`}>{this.state.temporary ?
                    tr('change_temporary_component_to_component') :
                    tr('change_component_to_temporary')}</div>,
                text: <div>
                    <div>{this.state.temporary ? tr('change_temporary_component_to_component_confirm') :
                        tr('change_component_to_temporary_confirm') }</div>
                    
                </div>,
            },
            {
                visible: () => {
                    if( statusName === 'plan' ) return false;
                    return ( statusName !== 'removed' )
                },
                action: 'removed',
                icon: 'trash',
                label: <div className={`apStatusBox error`}>{ tr('delete') }</div>,
                text: <div>
                        <div>{ tr('storage_component_confirm_delete') }</div>
                        <ul>
                            <li>{ tr('storage_component_confirm_delete_note2') }</li>
                            <li>{ tr('storage_component_confirm_delete_note3') }</li>
                        </ul>
                    </div>,
            },
        ]

        let menuActions = [];
        actions.forEach( a => {
            if( !a.visible() ) return null;
            menuActions.push({
                label: a.label,
                icon: a.icon,
                action: ( item, closeFunc ) => {
                    this.setState({
                        confirmSaveShow: true,
                        confirmSaveText: a.text,
                        confirmSaveAction: a.action,
                    });
                    closeFunc();
                },
            });
        })

        let menu = null;
        if( menuActions.length > 0 )
        {
            menu = <ApDropdown
                position="top"
                actions={ menuActions }
                button={ <ApButton className="footerMenu" color="white">
                    <SvgIcon icon="ellipsis-h" type="solid" />
                </ApButton> }
            />
        }

        return <div className="footer padding">
            { menu }

            <ApTooltip text={ validatorText ? validatorText : tr('save_in_status', [tr(this.state.status_text)]) }>
                <ApButton
                    className={"save"+ ( this.state.edited && !this.state.loading ? " highlight" : "" ) }
                    color={ this.state.status_name === 'active' ? 'blue' : 'white' }
                    onClick={ () =>  this.attemptSave( this.state.status_name )  }
                    disabled={ this.state.loading || Boolean( validatorText ) }
                >
                    <SvgIcon icon="save" type="solid" />
                    { tr('save') }
                </ApButton>
            </ApTooltip>
        </div>
    }

    renderConfirmSave()
    {
        return <div className="confirmSave ApModalConfirm">
            <ApModal
                show={ this.state.confirmSaveShow }
                handleClose={ () => this.setState({ confirmSaveShow: false }) }
                closeFromBg
                className="narrow"
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('storage_component_confirm_change') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <div>{ this.state.confirmSaveText }</div>
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton className="cancel" onClick={ () => this.setState({ confirmSaveShow: false }) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('no') }
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ () => { this.save( this.state.confirmSaveAction ) } }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('yes') }
                        </ApButton>
                    </div>
                }
            />
        </div>
    }

    renderConfirmDelete() {
        return <ApConfirm
                show={ this.state.showConfirmDeletion }
                onClose={ () => this.setState({ showConfirmDeletion: false }) }
                onConfirm={ () => this.save(this.state.status_name) }
                header={ tr('confirm_delete') }
                body={
                    <div>
                        <p>
                            <strong>{ tr('storage_locations_confirm_delete') }</strong>
                        </p>
                        <p>{ tr('storage_locations_confirm_delete_note') }</p>
                    </div>
                }
            />
    }

    render()
    {
        return <div id="componentEdit">
            <div className="componentEdit">
                <ApModal
                    show={ this.props.show }
                    handleClose={ this.props.onClose }
                    header={ this.renderHeader() }
                    body={ this.renderBody() }
                    footer={ this.renderFooter() }
                    onOpen={ this.onOpen }
                />
            </div>
            <div className="componentEditConfirmSave">
                { this.renderConfirmSave() }
                { this.renderConfirmDelete() }
            </div>
        </div>
    }
}

ComponentEdit.propTypes = {
    id: PropTypes.number,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func,
};

export default ComponentEdit;

