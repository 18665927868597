import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import Measure from 'react-measure';
import './ApFormPage.css';
import { Prompt } from 'react-router'
import auth from 'services/Authed/Authed.js';
import { tr } from 'services/Helpers/Helpers.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';

class ApFormPage extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            
        };
        autoBind(this); 
    }

    componentDidUpdate( prevProps )
    {
        if( this.props.unsaved !== prevProps.unsaved )
            window.onbeforeunload = this.props.unsaved ? () => true : undefined;
    }

    componentWillUnmount()
    {
        window.onbeforeunload = undefined;
    }

    render()
    {
        
        if( this.props.readOnly )
            return (
                <div className={ this.props.className || "apBox" }>
                    { this.props.children }
                </div>
            );

        let classes = [ "info" ];
        let info = tr('saved');;

        if( this.props.loading )
        {
            classes.push( "loading" );
            info = `${tr('loading')}...`;
        }
        else if ( this.props.error )
        {
            classes.push( "error" );
            info = this.props.error;
        }
        else if( this.props.unsaved )
        {
            classes.push( "unsaved" );
            info = tr('save_changes');
        }
        
        return (
            <React.Fragment>

                <Prompt
                    when={ this.props.unsaved }
                    message={ location => {

                        // This prevents confimation when user have already logged out from the system.
                        // For example when idle timer have triggered
                        if( !auth.isLoggedIn() )
                            return true;

                        if( typeof( this.props.noPrompt ) === "function" && this.props.noPrompt( location.pathname ) )
                            return true;

                        else if ( typeof( this.props.noPrompt ) === "boolean" )
                            return this.props.noPrompt;
                    
                        return this.props.PromptMsg || tr('unsaved_changes'); 
                    }}
                />
        
                <Measure
                    onResize={ () => {
                        if( this.stickyBar )
                            this.stickyBar.updateBounds();
                    }}
                >
                {({ measureRef }) =>
                    <div className={ this.props.className || "apBox" } ref={ measureRef }>
                        
                        { this.props.children }
                        
                        <ApStickyBar bottomMode zIndex={101} ref={ node => this.stickyBar= node }>
                            { typeof( this.props.customFooter ) == "function" ? this.props.customFooter() :
                                <div className="apFormPageFooter">

                                    <div className={ classes.join(" ") }>
                                        <SvgIcon icon="info-circle" type="solid" />
                                        { info }
                                    </div>

                                    <ApButton className={ "save" + ( this.props.unsaved && !this.props.loading ? " highlight" : "" ) } color="blue" onClick={ this.props.onSave } disabled={ !this.props.unsaved || this.props.loading || this.props.error } loading={ this.props.loading }>
                                        <SvgIcon icon="save" type="solid" />
                                        { tr('save') }
                                    </ApButton>
                                </div>
                            }
                        </ApStickyBar>

                    </div>
                }
                </Measure>

            </React.Fragment>
        );
    }
}

ApFormPage.propTypes = {
    onSave:         PropTypes.func,
    loading:        PropTypes.bool,
    unsaved:        PropTypes.bool,
    error:          PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
    readOnly:       PropTypes.bool,
    className:      PropTypes.string,
    customFooter:   PropTypes.func,
    noPrompt:       PropTypes.oneOfType([ PropTypes.func, PropTypes.bool ]),
};

export default ApFormPage;
