import React from 'react';
import autoBind from 'react-autobind';
import { ApInput
       , ApInputStack
       , ApAddon }     from 'common/ApInput/ApInput.js';

import ApSelect        from 'common/ApSelect/ApSelect.js';

import './SenderDataSelect.css';

class SenderDataSelect extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
        };

        this.findTimer = null;
        autoBind(this);
    }

    handleChange(e)
    {
        this.props.onChange({[e.target.name]: e.target.value});
    }

    handleSenderTypeChange( e )
    {
        this.props.onChange( this.nullSender() );
        this.props.onChange({[e.target.name]: e.target.value});
    }

    nullSender()
    {
        return {
            senderTitle:   null,
            senderId:      null,
        };
    }

    setSenderStorage( selected )
    {
        if( selected )
        {
            this.props.onChange({
                senderTitle:   selected.name,
                senderId:      selected.id,
            });
        }
        else
        {
            this.props.onChange( this.nullSender() );
        }
    }

    setSenderProject( selected )
    {
        if( selected )
        {
            this.props.onChange({
                senderTitle:   selected.name,
                senderId:      selected.id,
            });
        }
        else
        {
            this.props.onChange( this.nullSender() );
        }
    }

    setSenderCompany( selected )
    {
        if( selected )
        {
            this.props.onChange({
                senderTitle:   selected.name,
                senderId:      selected.id,
            });
        }
        else
        {
            this.props.onChange( this.nullSender() );
        }
    }

    renderSenderType()
    {
        return <ApInputStack gap="0">
            <ApAddon
                noRightBorder width="200px"
            >
                Lähettäjä *
            </ApAddon>
            <ApInput
                type="select"
                options={ [
                    { value: null,      label: '' },
                    { value: 'location', label: 'Oma varasto', disabled: true },
                    { value: 'project', label: 'Projekti', disabled: true },
                    { value: 'supplier', label: 'Yritys' },
                    { value: 'other',   label: 'Muu' },
                ] }
                id="senderType"
                name="senderType"
                value={ this.props.senderType }
                onChange={ this.handleSenderTypeChange }
                validationState={ this.props.validator.getState('senderType') }
                disabled={ this.props.senderLocked }
            />
        </ApInputStack>
    }

    renderSenderSelect()
    {
        if( this.props.senderType === 'location' )
        {
            return <ApSelect
                label="Lähettävä varasto *"
                value={ this.props.senderTitle ? this.props.senderTitle : '' }
                optionRenderer="storage_location_address"
                onChange={ this.setSenderStorage }
                objKeyId="id"
                clearable
                apiUrl="storage/locations/find"
                apiData={{ 
                    canReceive: true,
                }}
                validationState={ this.props.validator.getState('senderId') }
                disabled={ this.props.senderLocked }

            />

        }
        else if( this.props.senderType === 'project' )
        {
            return <ApSelect
                label="Lähettävä projekti *"
                loading={ this.state.loading }
                value={ this.props.senderTitle ? this.props.senderTitle : '' }
                optionRenderer="project"
                onChange={ this.setSenderProject }
                objKeyId="id"
                clearable
                apiUrl="storage/delivery/findprojects"
                validationState={ this.props.validator.getState('senderId') }
                disabled={ this.props.senderLocked }
            />
        }
        else if( this.props.senderType === 'supplier' )
        {
            return <ApSelect
                label="Lähettävä yritys *"
                value={ this.props.senderTitle ? this.props.senderTitle : '' }
                optionRenderer="storage_supplier"
                onChange={ this.nameChange }
                objKeyId="id"
                clearable
                apiUrl="storage/ar/findsupplier"
                validationState={ this.props.validator.getState('senderId') }
                disabled={ this.props.senderLocked }
            />
        }
        return null;
    }


    render()
    {
        let senderType    = this.renderSenderType();
        let senderSelect  = this.renderSenderSelect();

        return <div id="orderSenderDataSelect">
            { senderType }
            { senderSelect }
        </div>
    }
}

export default SenderDataSelect;
