import React from 'react';
import autoBind from 'react-autobind';

import ApButton         from 'common/ApButton/ApButton.js';
import ApReactTable     from 'common/ApReactTable/ApReactTable.js';
import SvgIcon          from 'common/SvgIcon/SvgIcon.js';

import RowEdit from './RowEdit/RowEdit.js';

import { tr } from 'services/Helpers/Helpers.js';

import './BankRelated.css';

class BankRelated extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            rowEditIndex: null,
            rowEditData: null,
            rowEditOpen: false,
        }
        autoBind(this);
    }

    addRow()
    {
        this.setState({
            rowEditIndex: -1,
            rowEditData: {},
            rowEditOpen: true,
        });
    }

    editRow( data, index )
    {
        console.log( data );

        this.setState({
            rowEditIndex: index,
            rowEditData: data,
            rowEditOpen: true,
        });
    }

    editRowClose( save )
    {
        if( save )
        {
            let data = this.props.rows.splice( 0 );

            if( this.state.rowEditIndex !== -1 )
                data[ this.state.rowEditIndex ] = save;
            else
                data.push( save );

            const instance = this.props.instance;
            this.props.onChange({ [ instance ]: data });
        }

        this.setState({ rowEditOpen: false });
    }

    removeRows( indexes )
    {
        let data = this.props.rows.splice( 0 );

        data = data.filter( ( d, i ) => !indexes.includes( i ) );

        const instance = this.props.instance;
        this.props.onChange({ [ instance ]: data });
    }

    getColumns()
    {
        return [
            {
                Header: "",
                columns: [
                    {
                        id: 'bankname',
                        Header: tr('bank_name'),
                        accessor: "bankname"
                    }, {
                        id: 'iban',
                        Header: "IBAN",
                        accessor: "iban",
                    }, {
                        id: 'bic',
                        Header: "BIC",
                        accessor: "bic",
                    }
                ]
            },
         ]
    }

    render()
    {
        let addButtonTitle = null;
		if( this.props.instance === 'bd' )
			addButtonTitle = tr('add_bank_account')

        return <div className="ComponentRelations">
            <ApReactTable
                loading={ this.props.loading }
                data={ this.props.rows }
                columns={ this.getColumns() }
                filterable
                multiselect={[
                    { icon: "trash-alt", label: "Poista valitut", action: ( ids ) => { this.removeRows( ids ) }, unselectAfter: true, closeAfter: true },
                ]}
                minRows={ 5 }
                rowActions={ ( row, index ) =>
                    <div className="apSimpleButton" onClick={ () => this.editRow( row, index ) }><SvgIcon icon="edit" type="solid" /></div>
                }
            />
            <ApButton size="small" color="green" onClick={ () => this.addRow() }>
                <SvgIcon icon="plus-square" type="solid" />
                { addButtonTitle }
            </ApButton>

            <RowEdit
                instance={ this.props.instance }
                show={ this.state.rowEditOpen }
                data={ this.state.rowEditData }
                type={ this.state.rowEditKey }
                onClose={ this.editRowClose }
            />
        </div>
    }
}

export default BankRelated;
