import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
// import auth from 'services/Authed/Authed.js';

import { keyExists,
         validateDate,
         tr }     from 'services/Helpers/Helpers.js'

import { ApInput,
         ApInputStack,
         ApAddon }          from 'common/ApInput/ApInput.js';
import ApSwitch             from 'common/ApSwitch/ApSwitch.js';
import SvgIcon              from 'common/SvgIcon/SvgIcon.js';
import ApImageUpload        from 'common/ApImageUpload/ApImageUpload.js';

/*
TODO features: 
    - Fill birthday from finnish social security number (nin) 
    - Fill city and country from finnish postal code 
    - Fill BIC from iban 
*/

class PersonInfo extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {

        };
        autoBind(this);
    }

    validBirthday( day )
    {
        return day.isBefore( moment() );
    }

    render()
    {
        return (
            <div className="padding">
                <div className="clear">

                    <div className="apColumn w50">
                        <div className="padding">

                            <h4 className="formHeader">{ tr('personal_information') }</h4>

                            <ApImageUpload 
                                label={ tr('picture') }
                                description="User image"
                                fileId={ keyExists( this.props.data, "person_detail.image_file_id", true, undefined ) }
                                onChange={ ( file ) => this.props.onChange( "person_detail.image_file_id", file.id ) }
                                onClear={ () => this.props.onChange( "person_detail.image_file_id", null ) }
                            />

                            <ApInputStack>
                                <ApInput
                                    width="50%"
                                    type="text"
                                    id="firstname"
                                    name="first_name"
                                    label={ tr('first_name') }
                                    value={ keyExists( this.props.data, "person_detail.first_name", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "person_detail.first_name", e.target.value ) } }
                                    tooltip={ this.props.validator.getText( "data.person_detail.first_name" ) }
                                    validationState={ this.props.validator.getState( "data.person_detail.first_name" ) }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />

                                <ApInput
                                    type="text"
                                    id="middlename"
                                    name="middle_names"
                                    label={ tr('second_name') }
                                    value={ keyExists( this.props.data, "person_detail.middle_names", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "person_detail.middle_names", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />
                             </ApInputStack>

                            <ApInput
                                type="text"
                                id="lastname"
                                name="last_name"
                                label={ tr('last_name') }
                                value={ keyExists( this.props.data, "person_detail.last_name", true ) }
                                onChange={ ( e ) => { this.props.onChange( "person_detail.last_name", e.target.value ) } }
                                tooltip={ this.props.validator.getText( "data.person_detail.last_name" ) }
                                validationState={ this.props.validator.getState( "data.person_detail.last_name" ) }
                                loading={ this.props.loading }
                                disabled={ this.props.loading }
                            />

                            <br />

                            <ApInput
                                type="text"
                                id="nin"
                                name="nin"
                                label={ tr('nin') }
                                value={ keyExists( this.props.data, "person_detail.nin", true ) }
                                onChange={ ( e ) => { this.props.onChange( "person_detail.nin", e.target.value ) } }
                                loading={ this.props.loading }
                                disabled={ this.props.loading }
                            />

                            <ApInputStack>

                                <ApInput
                                    type="date"
                                    id="birthday"
                                    name="birthday"
                                    label={ tr('date_of_birth') }
                                    value={ keyExists( this.props.data, "person_detail.birthday", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "person_detail.birthday", e.target.value ) } }
                                    tooltip={ this.props.validator.getText( "data.person_detail.birthday" ) }
                                    validationState={ this.props.validator.getState( "data.person_detail.birthday" ) }
                                    dateFormat="DD.MM.YYYY"
                                    isValidDate={ this.validBirthday }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />
                                <ApAddon width="120px">
                                    { validateDate( keyExists( this.props.data, "person_detail.birthday", true ) ) 
                                        ? moment().diff( moment( this.props.data.person_detail.birthday, 'DD.MM.YYYY' ), 'years' ) + ` ${tr('years')}`
                                        : "-" 
                                    }
                                </ApAddon>

                            </ApInputStack>

                        </div>
                    </div>

                    <div className="apColumn w50">
                        <div className="padding">

                            <h4 className="formHeader">{ tr('contact_details') }</h4>

                            <ApInput
                                type="text"
                                id="street"
                                name="street"
                                label={ tr('street_address') }
                                value={ keyExists( this.props.data, "address.street", true ) }
                                onChange={ ( e ) => { this.props.onChange( "address.street", e.target.value ) } }
                                loading={ this.props.loading }
                                disabled={ this.props.loading }
                                tooltip={ this.props.validator.getText( "data.address.street" ) }
                                validationState={ this.props.validator.getState( "data.address.street" ) }
                            />

                            <ApInputStack>
                                <ApInput
                                    width="140px"
                                    type="tel"
                                    id="postal_code"
                                    name="postal_code"
                                    label={ tr('postal_code') }
                                    maxLength="5"
                                    value={ keyExists( this.props.data, "address.postal_code", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "address.postal_code", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                    tooltip={ this.props.validator.getText( "data.address.postal_code" ) }
                                    validationState={ this.props.validator.getState( "data.address.postal_code" ) }
                                />

                                <ApInput
                                    type="text"
                                    id="city"
                                    name="city"
                                    label={ tr('locality') }
                                    value={ keyExists( this.props.data, "address.city", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "address.city", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                    tooltip={ this.props.validator.getText( "data.address.city" ) }
                                    validationState={ this.props.validator.getState( "data.address.city" ) }
                                />

                                <ApInput
                                    type="text"
                                    id="country"
                                    name="country"
                                    label={ tr('country') }
                                    value={ keyExists( this.props.data, "address.country", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "address.country", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />
                            </ApInputStack>

                            <br />

                            

                            <ApInputStack gap="0">
                                <ApInput
                                    type="email"
                                    id="email"
                                    name="email"
                                    label={ tr('email') }
                                    value={ keyExists( this.props.data, "contact_info.email", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "contact_info.email", e.target.value ) } }
                                    tooltip={ this.props.validator.getText( "data.contact_info.email" ) }
                                    validationState={ this.props.validator.getState( "data.contact_info.email" ) }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />
                                <ApAddon width="150px" labelFor="public-email-switch">
                                    <ApSwitch
                                        small
                                        inline
                                        id="public-email-switch"
                                        on={ keyExists( this.props.data, "contact_info.email_public", true ) }
                                        onChange={ () => { this.props.onChange( "contact_info.email_public", !this.props.data.contact_info.email_public ) } }
                                        disabled={ this.props.loading }
                                    />
                                    <span>{ tr('public') }</span>

                                </ApAddon>
                            </ApInputStack>

                            <ApInputStack gap="0">
                                <ApInput
                                    type="email"
                                    id="email_2"
                                    name="email_2"
                                    label={ `${tr('email')} (${tr('work')})` }
                                    value={ keyExists( this.props.data, "contact_info.email_2", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "contact_info.email_2", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />
                                <ApAddon width="150px" labelFor="public-email_2-switch">
                                    <ApSwitch
                                        small
                                        inline
                                        id="public-email_2-switch"
                                        on={ keyExists( this.props.data, "contact_info.email_2_public", true ) }
                                        onChange={ () => { this.props.onChange( "contact_info.email_2_public", !this.props.data.contact_info.email_2_public ) } }
                                        disabled={ this.props.loading }
                                    />
                                    <span>{ tr('public') }</span>

                                </ApAddon>
                            </ApInputStack>

                            <ApInputStack gap="0">
                                <ApInput
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    label={ tr('phone') }
                                    value={ keyExists( this.props.data, "contact_info.phone", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "contact_info.phone", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />
                                <ApAddon width="150px" labelFor="public-phone-switch">
                                    <ApSwitch
                                        small
                                        inline
                                        id="public-phone-switch"
                                        on={ keyExists( this.props.data, "contact_info.phone_public", true ) }
                                        onChange={ () => { this.props.onChange( "contact_info.phone_public", !this.props.data.contact_info.phone_public ) } }
                                        disabled={ this.props.loading }
                                    />
                                    <span>{ tr('public') }</span>

                                </ApAddon>
                            </ApInputStack>

                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { keyExists( this.props.data, "contact_info.phone_public", true ) 
                                    ? tr('phone_public_note')
                                    : tr('phone_private_note') 
                                }
                            </div>
                            
                            <ApInputStack gap="0">
                                <ApInput
                                    type="tel"
                                    id="phone_2"
                                    name="phone_2"
                                    label={ `${tr('phone')} (${tr('work')})` }
                                    value={ keyExists( this.props.data, "contact_info.phone_2", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "contact_info.phone_2", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />
                                <ApAddon width="150px" labelFor="public-phone_2-switch">
                                    <ApSwitch
                                        small
                                        inline
                                        id="public-phone_2-switch"
                                        on={ keyExists( this.props.data, "contact_info.phone_2_public", true ) }
                                        onChange={ () => { this.props.onChange( "contact_info.phone_2_public", !this.props.data.contact_info.phone_2_public ) } }
                                        disabled={ this.props.loading }
                                    />
                                    <span>{ tr('public') }</span>

                                </ApAddon>
                            </ApInputStack>

                            <p>{tr('preferred_method_of_contact')}:</p>
							<div className="apSwitchBlock">
								<label htmlFor='contact_by_email' className="info">
									{tr('email')}
								</label>
								<ApSwitch
									id='contact_by_email'
									on={ keyExists(this.props.data, 'contact_info.contact_by_email', true, false) }
									onChange={ () => this.props.onChange(
										'contact_info.contact_by_email', 
										!this.props.data.contact_info.contact_by_email
									) }
									disabled={ this.props.loading }
								/>
							</div>
							<div className="apSwitchBlock">
								<label htmlFor='contact_by_phone' className="info">
									{tr('contact_by_phone')}
								</label>
								<ApSwitch
									id='contact_by_phone'
									on={ keyExists(this.props.data, 'contact_info.contact_by_phone', true, false) }
									onChange={ () => this.props.onChange(
										'contact_info.contact_by_phone', 
										!this.props.data.contact_info.contact_by_phone
									) }
									disabled={ this.props.loading }
								/>
							</div>

                            <ApInputStack gap="0">
								<ApAddon noRightBorder>
									{ tr('language') }
								</ApAddon>
								<ApInput
									type="select"
									id="language"
									name="language"
                                    value={ keyExists( this.props.data, "person_detail.client_language_id", true ) }
									options={this.props.languageOptions}
									onChange={ ( e ) => this.props.onChange( "person_detail.client_language_id", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
							</ApInputStack>

							<div className="apInfo small">
									<SvgIcon icon="info-circle" type="solid" />
									{ tr('language_note') }
							</div>

                            <ApInput
                                type="textarea"
                                id="free_text"
                                name="free_text"
                                label={ tr('free_text') }
                                rows={ 3 }
                                value={ keyExists( this.props.data, "person_detail.free_text", true ) }
                                onChange={ ( e ) => { this.props.onChange( "person_detail.free_text", e.target.value ) } }
                                disabled={ this.props.loading }
                            />

                        </div>
                    </div>

                </div>
                <div className="clear">

                    <div className="apColumn w50">
                        <div className="padding">
                                  
                            <h4 className="formHeader">{ tr('bank_connection_tax_number') }</h4>

                            <ApInputStack>
                                <ApInput
                                    type="text"
                                    id="iban"
                                    name="iban"
                                    label={ tr('account_number') }
                                    value={ keyExists( this.props.data, "contract_detail.iban", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "contract_detail.iban", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }  
                                />
                                <ApInput
                                    width="40%"
                                    type="text"
                                    id="bic"
                                    name="BIC"
                                    label="BIC"
                                    maxLength="11"
                                    value={ keyExists( this.props.data, "contract_detail.bic", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "contract_detail.bic", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }  
                                />
                            </ApInputStack>

                            <ApInput
                                type="text"
                                id="taxnumber"
                                name="taxnumber"
                                label={ tr('tax_number') }
                                value={ keyExists( this.props.data, "contract_detail.taxnumber", true ) }
                                onChange={ ( e ) => { this.props.onChange( "contract_detail.taxnumber", e.target.value ) } }
                                loading={ this.props.loading }
                                disabled={ this.props.loading } 
                            />

                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('tax_number_note') }
                            </div>

                        </div>
                    </div>

                    <div className="apColumn w50">
                        <div className="padding">

                            <h4 className="formHeader">{ tr('next_of_kin') }</h4>

                             <ApInputStack>
                                <ApInput
                                    type="text"
                                    id="nextofkin_firstname"
                                    name="first_name"
                                    label={ tr('first_name') }
                                    value={ keyExists( this.props.data, "next_of_kin.first_name", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "next_of_kin.first_name", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />

                                <ApInput
                                    type="text"
                                    id="nextofkin_lastname"
                                    name="last_name"
                                    label={ tr('last_name') }
                                    value={ keyExists( this.props.data, "next_of_kin.last_name", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "next_of_kin.last_name", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }
                                />
                            </ApInputStack>

                            <ApInput
                                type="text"
                                id="nextofkin_relation"
                                name="relation"
                                label={ tr('relationship') }
                                tooltip={ tr('relation_examples') }
                                value={ keyExists( this.props.data, "next_of_kin.relation", true ) }
                                onChange={ ( e ) => { this.props.onChange( "next_of_kin.relation", e.target.value ) } }
                                loading={ this.props.loading }
                                disabled={ this.props.loading }  
                            />

                            <ApInput
                                type="tel"
                                id="nextofkin_phone"
                                name="phone"
                                label={ tr('phone') }
                                value={ keyExists( this.props.data, "next_of_kin.phone", true ) }
                                onChange={ ( e ) => { this.props.onChange( "next_of_kin.phone", e.target.value ) } }
                                loading={ this.props.loading }
                                disabled={ this.props.loading } 
                            />
                            
                    
                        </div>
                    </div>
                    {/*
                    <div className="apColumn w50">
                         <div className="padding">
						 <h4 className="formHeader">Integraatioasetukset</h4>
							<ApInput
								type="text"
								label="Henkilökohtainen Netvisor API -käyttäjätunnus"
								id="netvisorUserId"
								name="netvisorUserId"
								value={ keyExists( this.props.data, "person_detail.netvisorUserId", true ) }
								onChange={ (e) => this.props.onChange( "person_detail.netvisorUserId", e.target.value ) }
								loading={ this.props.loading }
								disabled={ this.props.loading }
							/>
						    <ApInput
								type="text"
								label="Henkilökohtainen Netvisor API -avain"
								id="netvisorUserKey"
								name="netvisorUserKey"
								value={ keyExists( this.props.data, "person_detail.netvisorUserKey", true ) }
								onChange={ (e) => this.props.onChange( "person_detail.netvisorUserKey", e.target.value ) }
								loading={ this.props.loading }
								disabled={ this.props.loading }
							/>
						 </div>
				    </div>
                    */}
                </div>
                {this.props.data.payment_method === 'nova' && (
                    <div className="clear">
                        <div className="apColumn w50">
                            <div className="padding">
                                <h4 className="formHeader">Rajapintatunnukset</h4>
                                <ApInput
                                    type="text"
                                    id="nova_employee_code"
                                    name="nova_employee_code"
                                    label="Visma Nova työntekijän numero"
                                    value={ keyExists( this.props.data, "person_detail.nova_employee_code", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "person_detail.nova_employee_code", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }  
                                />
                            </div>
                        </div>
                    </div>
                )}
                
                {this.props.data.has_efina_external && (
                    <div className="clear">
                        <div className="apColumn w50">
                            <div className="padding">
                                <h4 className="formHeader">eFina</h4>
                                <ApInput
                                    type="text"
                                    id="efina_employee_code"
                                    name="efina_employee_code"
                                    label={tr('employee_code')}
                                    value={ keyExists( this.props.data, "person_detail.efina_employee_code", true ) }
                                    onChange={ ( e ) => { this.props.onChange( "person_detail.efina_employee_code", e.target.value ) } }
                                    loading={ this.props.loading }
                                    disabled={ this.props.loading }  
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default PersonInfo;

