/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApEditGroup } from 'common/ApChat/ApChat.js';

import moment from 'moment';
import 'moment/locale/fi';

import './InfoBar.css';

import { ApInput } from 'common/ApInput/ApInput.js';

import { keyExists
       , objectIsEmpty, sqlToDateInput, tr, currentLang } from 'services/Helpers/Helpers.js';
import ProjectSections from '../ProjectSections/ProjectSections';
       
class InfoBar extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            declineModal: {},
            customerModal: {},
            managerModal: {},
            workhourApproverModal: {},
			customer_order_numberModal: {},
            dateModal: {},

            customer: null,
            customer_contact: null,
            manager: null,
            workhour_approver: null,
            hour_approvers: [],
            customer_order_number: {},
            
            decline_reason_enabled: false,
            decline_reason: {},
            decline_description: {},
        };
        autoBind(this);
    }

    getSettings()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'projects/settings'
        }).then(response => {

            //console.log('GET PROJECT SETTINGS', response);
            this.setState({ 
                loading:        false,
                decline_reason_enabled: response.decline_reason_enabled,
            });

        }).catch(error => {
            //console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('unexpected_error') ) || "ERROR",
                loading: false 
            });
        });
    }

    componentDidMount()
    {
        this.getSettings();
    }

    customerModalOpen()
    {
        const project = this.props.project;
        if( !project ) return null;
        this.setState({
            customer: project.customer,
            customer_contact: project.customer_contact,
        });
    }

    declineModalOpen()
    {
        const project = this.props.project;
        if( !project ) return null;
        this.setState({
            decline_reason: project.decline_reason,
            decline_description: project.decline_description,
        });
    }

    managerModalOpen()
    {
        const project = this.props.project;
        if( !project ) return null;
        this.setState({
            manager: project.manager,
        });
    }

    workhourApproverModalOpen()
    {
        const project = this.props.project;
        if( !project ) return null;
        this.setState({
            hour_approvers: project.hour_approvers,
            workhour_approver: project.workhour_approver,
        });
    }
	
	customer_order_numberModalOpen()
    {
        const project = this.props.project;
        if( !project ) return null;
        this.setState({
            customer_order_number: project.customer_order_number,
        });
    }

    dateModalOpen()
    {
        //console.log('dateModalOpen');
    }


    preset_status(type, item )
    {
        const project = this.props.project;
        if( type === 'value' )
        {
            if( project.ended )
            {
                if( project.done )
                    return <span className="green">{ tr('completed') } { sqlToDateInput( project.ended ) } </span>
                return <span className="red">{ tr('ended_at') } { sqlToDateInput( project.ended ) }</span>
            }
            return tr('running');
        }
        return tr('status');
    }
    
    preset_created_by(type, item )
    {
    	const project = this.props.project;
        if( type === 'title' )
            return tr('project_creator');
        let value=null;
        if( type === 'value' && project.creator!=null)
        {
            value = keyExists( project, 'creator.name', true, value );
        }

        let diff = null;

        if( type === 'value' )
            return <ApTooltip text={ diff } position="top">
                { value }
            </ApTooltip>
    }

    preset_billing(type, item )
    {
        if( type === 'title' )
            return tr('billing');
        return ( this.props.project.billing_hourly ) ? tr('hourly_billing') : tr('contract_job_billing');
    }

    preset_start(type, item )
    {
        if( type === 'title' )
            return tr('start_date');

        let value = this.props.project.start;
        let diff = null;

        if( Math.abs( moment().diff( moment( value ), 'days')) < 1 )
            diff = tr('today');
        else
            diff  = moment().locale(currentLang()).to( moment( value ) );

        value = sqlToDateInput( value );

        if( type === 'value' )
            return <ApTooltip text={ diff } position="top">
                { value }
            </ApTooltip>
    }

    preset_end(type, item )
    {
        if( type === 'title' )
            return tr('end_date');

        let value = this.props.project.end;
        let diff = null;

        if( !value ) return tr('continuous');

        if( Math.abs( moment().diff( moment( value ), 'days')) < 1 )
            diff = tr('today');
        else
            diff  = moment().locale(currentLang()).to( moment( value ) );

            value = sqlToDateInput( value );

        if( type === 'value' )
            return <ApTooltip text={ diff } position="top">
                { value }
            </ApTooltip>
    }

    preset_storage_location(type, item) {
        if (type === 'title')
            return tr('storage_location');

        
        
        let storage = this.props.project.storage_location;
        if (type === 'value')
            return storage?.name ?? "-";
            return <ApTooltip text={diff} position="top">
                {value}
            </ApTooltip>
    }

    preset_customer(type, item )
    {
        const project = this.props.project;
        let value = tr('customer');

        if( type === 'value' )
        {
            value = keyExists( project, 'customer_parent.name', true, null );
            value = keyExists( project, 'customer.name', true, value );
        }

        if( item.locked || this.props.locked ) return value;

        if( project.parent_id )
        {
            return <ApTooltip text={ tr('customer_editable_main_project') } position="top">
                { value }
            </ApTooltip>
        }

        return <div className="pointer" onClick={() => { this.setState({ customerModal: { show: true } })}}>
            { value }
        </div>
    }

    preset_customer_contact(type, item )
    {
        const project = this.props.project;
        let value = tr('contact_person');

        if( type === 'value' )
        {
            value = keyExists( project, 'customer_contact_parent.name', true, null );
            value = keyExists( project, 'customer_contact.name', true, value );
        }
        if( !value ) return false;

        if( item.locked || this.props.locked ) return value;

        if( project.parent_id )
        {
            return <ApTooltip text={ tr('customer_editable_main_project') } position="top">
                { value }
            </ApTooltip>
        }

        return <div className="pointer" onClick={() => { this.setState({ customerModal: { show: true } })}}>
            { value }
        </div>
    }

    preset_manager(type, item )
    {
        const project = this.props.project;
        let value = tr('project_manager');

        if( type === 'value' )
        {
            value = keyExists( project, 'manager_parent.name', true, null );
            value = keyExists( project, 'manager.name', true, value );
        }

        if( item.locked || this.props.locked ) return value;

        return <div className="pointer" onClick={() => { this.setState({ managerModal: { show: true } })}}>
            { value }
        </div>
    }

    preset_workhour_approver(type, item )
    {
        const project = this.props.project;
        let value = tr('approver_of_hours');

        if( type === 'value' )
        {
            let approvers = [];
            approvers = keyExists( project, 'hour_approvers_parent', true, [] );
            approvers = keyExists( project, 'hour_approvers', true, approvers );
            if (!Array.isArray(approvers)) approvers = [];
            value = approvers.map(approver => approver.name).join(', ');
        }

        if( item.locked || this.props.locked ) return value;

        return <div className="pointer" onClick={() => { this.setState({ workhourApproverModal: { show: true } })}}>
            { value }
        </div>
    }
	
	preset_customer_order_number(type, item )
    {
        const project = this.props.project;
        let value = tr('customer_order_number');

        if( type === 'value' )
        {
            value = keyExists( project, 'customer_order_number', true, value );
			//value = keyExists( project, 'customer_order_number.name', true, value );
        }

        if( item.locked || this.props.locked ) return value;

        return <div className="pointer" onClick={() => { this.setState({ customer_order_numberModal: { show: true } })}}>
            { value }
        </div>
    }


    preset_offer_received(type, item )
    {
        if( type === 'title' )
            return tr('offer_received_at');

        const project = this.props.project;

        let value = keyExists( project, 'offer.date_received', true, null );
        let diff = null;

        if( Math.abs( moment().diff( moment( value ), 'days')) < 1 ) diff = tr('today');
        else diff  = moment().locale(currentLang()).to( moment( value ) );

        let dom = <ApTooltip text={ diff } position="top">{ sqlToDateInput( value ) }</ApTooltip>
        if( item.locked || this.props.locked )
            return dom;

        return <div className="pointer" onClick={() => { this.setState({ dateModal: {
            show: true,
            date: value,
            type: 'offer_received',
        } })}}>
            { dom }
        </div>
    }

    preset_offer_deadline(type, item )
    {
        if( type === 'title' )
            return tr('offer_deadline');

        const project = this.props.project;

        let value = keyExists( project, 'offer.date_deadline', true, null );
        let diff = null;

        if( Math.abs( moment().diff( moment( value ), 'days')) < 1 ) diff = tr('today');
        else diff  = moment().locale(currentLang()).to( moment( value ) );

        let dom = <ApTooltip text={ diff } position="top">{ sqlToDateInput( value ) }</ApTooltip>
        if( item.locked || this.props.locked )
            return dom;

        return <div className="pointer" onClick={() => { this.setState({ dateModal: {
            show: true,
            date: value,
            type: 'offer_deadline',
        } })}}>
            { dom }
        </div>


    }

    preset_decline_reason(type, item )
    {
        const project = this.props.project;
        let value = tr('decline_reason');

        if( type === 'value' )
        {
            value = keyExists( project, 'decline_reason', true, value );
        }

        //if( item.locked || this.props.locked ) return value;

        return <div className="pointer" onClick={() => { this.setState({ declineModal: { show: true } })}}>
            { value }
        </div>
    }

    preset_decline_description(type, item )
    {
    	const project = this.props.project;
        if( type === 'title' )
            return tr('additional_info');
            
        let value=null;
        if( type === 'value' && project.decline_description!=null)
        {
            value = keyExists( project, 'decline_description', true, value );
        }

        let diff = null;

        if( type === 'value' )
            return <ApTooltip text={ diff } position="top">
                { value }
            </ApTooltip>
    }
    preset_chat(type, item) {
        if (type === 'title')
            return <div className="pointer" onClick={() => { this.setState({ showChatEdit:true  }) }}>
                {tr('chat')}
            </div>;
        
        // console.log("type",this.props);
        return this.props.chatUserCount > 0 ? tr("users") + ": " + this.props.chatUserCount : tr("add_chat");
    }

    // If this returns false the item will not be rendered
    renderField( type, item )
    {
        if( item.preset )
        {
            const funcName = `preset_${ item.preset }`;

            if( typeof this[ funcName ] === 'function' )
                return this[ funcName ]( type, item );

            console.error( `Function ${ funcName } not defined in InfoBar.js`);
            return null;
        }

        if( type === 'title' )
            return item.title;

        if( item.tooltip )
            return <ApTooltip text={ null } position="top">
                { item.value }
            </ApTooltip>

        return item.value;


    }

    renderColumns()
    {
        let colDoms = [];

        let items = this.props.items;

        if( !items )
        {
            if( this.props.form === 'offer' )
            {
                items = [
                    { column: 0, preset: 'status' },
                    { column: 0, preset: 'customer' },
                    { column: 0, preset: 'customer_contact'},
                    { column: 1, preset: 'manager' },
                    { column: 1, preset: 'workhour_approver' },
					{ column: 2, preset: 'customer_order_number' },
                    { column: 2, preset: 'billing' },
                    { column: 3, preset: 'offer_received' },
                    { column: 3, preset: 'offer_deadline' },
                    { column: 4, preset: 'created_by' },
                    { column: 4, preset: 'chat' },

                ];
            }

            if ( this.props.form === 'offer' && this.props.project.offer.status === 'declined' && this.state.decline_reason_enabled === true)
            {
                items = [
                    { column: 0, preset: 'status' },
                    { column: 0, preset: 'customer' },
                    { column: 0, preset: 'customer_contact'},
                    { column: 1, preset: 'manager' },
                    { column: 1, preset: 'workhour_approver' },
					{ column: 2, preset: 'customer_order_number' },
                    { column: 2, preset: 'billing' },
                    { column: 3, preset: 'offer_received' },
                    { column: 3, preset: 'offer_deadline' },
                    { column: 4, preset: 'created_by' },
                    { column: 0, preset: 'decline_reason' },
                    { column: 4, preset: 'chat' },
                ];
            } 

            else
            {
                items = [
                    { column: 0, preset: 'status' },
                    { column: 0, preset: 'customer' },
                    { column: 0, preset: 'customer_contact'},
                    { column: 1, preset: 'manager' },
                    { column: 1, preset: 'workhour_approver' },
					{ column: 2, preset: 'customer_order_number' },
                    { column: 2, preset: 'billing' },
                    { column: 3, preset: 'start' },
                    { column: 3, preset: 'end' },
                    { column: 4, preset: 'created_by' },
                    { column: 4, preset: 'storage_location' },
                    { column: 5, preset: 'chat' },
                ];
            }
        }

        items.forEach( ( item, index ) => {

            if( typeof colDoms[ item.column ] === 'undefined')
                colDoms[ item.column ] = [];

            const title = this.renderField('title', item );
            const value = this.renderField('value', item );

            if( value !== false && title !== false )
            {
                colDoms[ item.column ].push(
                    <tr  key={ index }>
                        <td className="title">{ title }</td>
                        <td className="value">{ value }</td>
                    </tr>
                );
            }
        });
        return colDoms.map( ( colItem, index ) => {
            return <table key={ index } className="column"><tbody>
                { colItem }
            </tbody></table>
        });
    }

    customerSave()
    {
        let project = { ...this.props.project };
        project.customer = this.state.customer;
        project.customer_contact = this.state.customer_contact;
        this.props.onChange( 'customer', project );
        this.setState({ customerModal: {} });
    }

    declineSave()
    {
        let project = { ...this.props.project };
        project.decline_reason = this.state.decline_reason;
        project.decline_descriptiont = this.state.decline_description;
        this.props.onChange( 'decline_reason', project );
        this.setState({ declineModal: {} });
    }

    managerSave()
    {
        this.props.onChange('manager',  this.state.manager );
        this.setState({ managerModal: {} });
    }
    workhourApproverSave()
    {
        this.props.onChange('hour_approvers',  this.state.hour_approvers );
        this.setState({ workhourApproverModal: {} });
    }
	customer_order_numberSave()
    {
        this.props.onChange('customer_order_number',  this.state.customer_order_number );
        this.setState({ customer_order_numberModal: {} });
    }
    dateSave()
    {
        this.props.onChange(this.state.dateModal.type,  this.state.dateModal.date );
        this.setState({ dateModal: {} });
    }

    setCustomer( customer )
    {
        this.setState({
            customer: customer,
            customer_contact: null,
        });
    }

    setDeclineReason( decline_reason )
    {
        this.setState({
            decline_reason: decline_reason,
        });
    }

    renderCustomerModal()
    {
        return <ApModal
            show={ Boolean( this.state.customerModal.show ) }
            handleClose={ () => this.setState({ customerModal: {} }) }
            className="narrow overflow"

            onOpen={ this.customerModalOpen }
            header={
                <div className="padding">
                   { tr('edit_customer') }
                </div>
            }
            body={ <div className="padding">
                <ApSelect
                    label={ tr('customer') }
                    value={ this.state.customer }
                    optionRenderer={ ( item ) => {
                        return <div>
                            <strong>{ item.name }</strong><br />
                            <small>{ item.taxnumber }</small>
                        </div>
                    }}
                    onChange={ ( c ) => this.setCustomer( c ? c : null) }
                    objKeyId="id"
                    objKeyValue="name"
                    clearable
                    apiUrl="project/find/customer"
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    validationState={ !this.state.customer && this.props.customer_mandatory ? 'error' : '' }
                />

                <ApSelect
                    label={ tr('contact_person') }
                    value={ this.state.customer_contact }
                    optionRenderer={ (item) => <div>{ item.name }</div> }
                    onChange={ ( c ) => this.setState({ customer_contact: c ? c : null }) }
                    objKeyValue="name"
                    objKeyId="id"
                    clearable
                    apiUrl="project/find/customer/contact"
                    apiData={{
                        crm_company: this.state.customer ? this.state.customer.id : false,
                    }}
                    loading={ this.state.loading }
                    disabled={ this.state.loading || !this.state.customer }
                />


            </div> }
            footer={ <div className="padding text-right">

                    <ApButton onClick={ () => this.setState({ customerModal: {} }) }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>

                    <ApButton disabled={!this.state.customer && this.props.customer_mandatory} color="green" onClick={ this.customerSave }>
                        <SvgIcon icon="check" type="solid" />
                        { tr('continue') }
                    </ApButton>

            </div> }
        />
    }

    renderDeclineModal()
    {
        return <ApModal
            show={ Boolean( this.state.declineModal.show ) }
            handleClose={ () => this.setState({ declineModal: {} }) }
            className="narrow overflow"

            onOpen={ this.declineModalOpen }
            header={
                <div className="padding">
                    { tr('edit_decline_reason') }
                </div>
            }
            body={ <div className="padding">
                <ApInput
                    type="text"
                    label={ tr('decline_reason') }
                    value={ this.state.decline_reason }
                    optionRenderer="decline_reason"
                    onChange={ ( c ) => this.setDeclineReason( c ? c : null) }
                    objKeyId="id"
                    objKeyValue="decline_reason"
                    clearable
                    apiUrl='project/declinereason/find'
                    loading={ this.state.loading }
                    disabled={true}
                />

                <ApInput
                    type="textarea"
                    label={ tr('additional_info') }
                    value={ this.state.decline_description }
                    optionRenderer={ (item) => <div>{ item.name }</div> }
                    onChange={ ( c ) => this.setState({decline_description: c ? c : null }) }
                    objKeyValue="decline_description"
                    objKeyId="id"
                    clearable
                    apiUrl="project/find/customer/contact"
                    apiData={{
                        crm_company: this.state.decline_reason ? this.state.decline_reason.id : false,
                    }}
                    loading={ this.state.loading }
                    disabled={ true }
                />


            </div> }
          /*  footer={ <div className="padding text-right">

                    <ApButton onClick={ () => this.setState({ declineModal: {} }) }>
                        <SvgIcon icon="times" type="solid" />
                        Peruuta
                    </ApButton>

                    <ApButton color="green" onClick={ this.declineSave }>
                        <SvgIcon icon="check" type="solid" />
                        Jatka
                    </ApButton>

            </div> } */
        />
    }

    renderManagerModal()
    {
        const project = this.props.project;

        let info = null;

        if( this.props.project.parent_id )
        {
            let parentInfo = <span>{ tr('upper_project_manager_missing') }</span>
            if( project.manager_parent )
                parentInfo = <span>{ tr('upper_project_manager_info', [<strong>{ project.manager_parent.name }</strong>]) }</span>

            info = <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('project_manager_inherits') }
                { parentInfo }
            </div>
        }

        return <ApModal
            show={ Boolean( this.state.managerModal.show ) }
            handleClose={ () => this.setState({ managerModal: {} }) }
            className="narrow overflow"

            onOpen={ this.managerModalOpen }
            header={
                <div className="padding">
                    { tr('edit_project_manager') }
                </div>
            }
            body={ <div className="padding">
                <ApSelect
                    label={ tr('project_manager') }
                    value={ this.state.manager }
                    optionRenderer="user"
                    onChange={ ( m ) => this.setState({ manager: m ? m : null }) }
                    objKeyId="id"
                    objKeyValue="name"
                    clearable
                    apiUrl="project/find/manager"
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    filterNonActives={true}
                />
                { info }
            </div> }
            footer={ <div className="padding text-right">
                    <ApButton onClick={ () => this.setState({ managerModal: {} }) }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>
                    <ApButton color="green" onClick={ this.managerSave }>
                        <SvgIcon icon="check" type="solid" />
                        { tr('continue') }
                    </ApButton>
            </div> }
        />
    }

    renderWorkhourApproverModal()
    {
        const project = this.props.project;

        let info = null;

        if( this.props.project.parent_id )
        {
            let parentInfo = <span>{ tr('upper_project_approver_of_hours_missing') }</span>
            if( Array.isArray(project.hour_approvers_parent) && project.hour_approvers_parent.length > 0 )
                parentInfo = <span>{ tr('upper_project_approver_of_hours_info', [<strong>{ project.hour_approvers_parent.map(approver => approver.name).join(', ') }</strong>]) }</span>

            info = <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('approver_of_hours_inherits') }
                { parentInfo }
            </div>
        }

        return <ApModal
            show={ Boolean( this.state.workhourApproverModal.show ) }
            handleClose={ () => this.setState({ workhourApproverModal: {} }) }
            className="narrow overflow"

            onOpen={ this.workhourApproverModalOpen }
            header={
                <div className="padding">
                    { tr('edit_approver_of_hours') }
                </div>
            }
            body={ <div className="padding">
                <ApSelect
                    label={ tr('approver_of_hours') }
                    value={ this.state.hour_approvers }
                    optionRenderer="user"
                    onChange={ ( values ) => this.setState({ hour_approvers: values }) }
                    objKeyId="id"
                    objKeyValue="name"
                    clearable
                    apiUrl="project/find/workhourapprover"
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    filterNonActives={true}
                    multiselect
                    valueRenderer="user"
                    objKeySearchable="user"
                />
                { info }
            </div> }
            footer={ <div className="padding text-right">
                    <ApButton onClick={ () => this.setState({ workhourApproverModal: {} }) }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>
                    <ApButton color="green" onClick={ this.workhourApproverSave }>
                        <SvgIcon icon="check" type="solid" />
                        { tr('continue') }
                    </ApButton>
            </div> }
        />
    }

    renderDateModal()
    {
        return <ApModal
            show={ Boolean( this.state.dateModal.show ) }
            handleClose={ () => this.setState({ dateModal: {} }) }
            className="narrow overflow"

            onOpen={ this.dateModalOpen }
            header={
                <div className="padding">
                    { tr('edit_date') }
                </div>
            }
            body={ <div className="padding">
                <ApInput
                    type="dateSafe"
                    id="date_modal_date"
                    name="date"
                    label={ tr('date') }
                    value={ this.state.dateModal.date }
                    onChange={ ( e ) => {
                        let data = { ...this.state.dateModal }
                        data.date = e.target.value;
                        this.setState({ dateModal: data }); 
                    }}
                    clearable
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </div> }
            footer={ <div className="padding text-right">

                    <ApButton onClick={ () => this.setState({ dateModal: {} }) }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>

                    <ApButton color="green" onClick={ this.dateSave }>
                        <SvgIcon icon="check" type="solid" />
                        { tr('continue') }
                    </ApButton>

            </div> }
        />
    }

    rendercustomer_order_numberModal()
    {
        const project = this.props.project;

        let info = null;

        if( this.props.project.parent_id )
        {
            let parentInfo = <span>{ tr('upper_project_customer_order_number_missing') }</span>
            if( project.customer_order_number_parent )
                parentInfo = <span>{ tr('upper_project_customer_order_number_info', [<strong>{ project.customer_order_number_parent.name }</strong>]) }</span>

            info = <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('customer_order_number_inherits') }
                { parentInfo }
            </div>
        }

        return <ApModal
            show={ Boolean( this.state.customer_order_numberModal.show ) }
            handleClose={ () => this.setState({customer_order_numberModal: {} }) }
            className="narrow overflow"

            onOpen={ this.customer_order_numberModalOpen }
            header={
                <div className="padding">
                    { tr('edit_customer_order_number') }
                </div>
            }
            body={ <div className="padding"> <ApInput type="text"
                    label={ tr('customer_order_number') }
                    value={ this.state.customer_order_number }
                    optionRenderer="user"onChange={ ( e ) => this.setState({ customer_order_number:  e.target.value }) }
                    objKeyId="customer_order_number"
                    objKeyValue="name"
                    clearable
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
                { info }
            </div> }
            footer={ <div className="padding text-right">
                    <ApButton onClick={ () => this.setState({customer_order_numberModal: {} }) }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>
                    <ApButton color="green" onClick={ this.customer_order_numberSave }>
                        <SvgIcon icon="check" type="solid" />
                        { tr('continue') }
                    </ApButton>
            </div> }
        />
    }

    renderChatEdit() {
        //console.log(this.props.project)
        return <ApEditGroup
            show={this.state.showChatEdit}
            //users={[]}
            namePlaceHolder={this.props.project.name+"-"+this.props.project.project_code}
            projectId={this.props.project.id}
            overRideEditPermission={true}
            onClose={() => this.setState({ showChatEdit: false })}
            edited={(edit) => this.props.onChange("chatUserCount", edit ? edit.users.length:0,false)}
            editMode={true}
        />
    }
   

    render()
    {
        if( !this.props.project ) return null;

        return <div style={{overflowX: 'auto'}}>
            <div className="ProjectInfoBar">
            { this.renderColumns() }
            { this.renderCustomerModal() }
            { this.renderDeclineModal() }
            { this.renderManagerModal() }
            { this.renderWorkhourApproverModal() }
			{ this.rendercustomer_order_numberModal() }
            {this.renderDateModal()}
            {this.renderChatEdit()}
        </div>
        </div>
    }

}

export default InfoBar;
