import axios from "axios";
import React from "react";
import autobind from "react-autobind";
import { errorPopper, keyExists, tr } from "services/Helpers/Helpers";
import api from 'services/Api/Api.js';
import SvgIcon from "common/SvgIcon/SvgIcon";
import ApReactTable from "common/ApReactTable/ApReactTable";
import moment from "moment";
import { ApAddon, ApInput, ApInputStack } from "common/ApInput/ApInput";
import { connect } from "react-redux";
import ApSelect from "common/ApSelect/ApSelect";
import _ from "lodash";
import ApModal from "common/ApModal/ApModal";
import ApButton from "common/ApButton/ApButton";

const IDLE_TIME = 180000; // 3 minutes

class TimetrackingAssignments extends React.Component {
    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            loading: false,
            user: null,
            card: null,
            userId: null,
            assignments: [],
            statusOptions: [{ value: "", label: tr("all") }],
            selectFocused: false,

            // Modal
            showModal: false,
            selectedAssignment: null,
        };

        this.timer = null;
        this.idleTimer = null;
        this.cancelToken = null;
    }

    componentDidMount() {
        this.getStatusOptions();
        document.addEventListener('mousemove', this.handleIdleTime);
        document.addEventListener('keypress', (e) => {
            this.handleIdleTime();
            this.handleCardInput(e);
        });
        document.getElementById('timetracking-assignments').focus();
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.handleIdleTime);
        document.removeEventListener('keypress', this.handleIdleTime);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState.user, this.state.user)) {
            if (this.state.user && this.state.user.id) {
                this.getData(this.state.user.id);
            }
            // Clear list if user is cleared
            if (!this.state.user) {
                this.resetState();
            }
        }
        if (prevState.card !== this.state.card) {
            if (this.state.card && this.state.card.length > 0) {
                this.getData(this.state.card, true);
            }
        }
    }

    handleIdleTime() {
        if (this.idleTimer) clearTimeout(this.idleTimer);
        this.idleTimer = setTimeout(() => {
            this.resetState();
        }, IDLE_TIME);
    }

    handleChange(data, key) {
        this.setState({
            [key]: data
        });
    }

    handleCardInput(e) {
        if (document.activeElement != document.body) {
            return;
        }
        if (parseInt(e.key) >= 0 && this.state.selectFocused === false) {
            let card = this.state.card ? this.state.card : '';
            this.setState({ card: card + e.key })
        }
    }

    handleCard(card) {
        card = parseInt(card).toString(16);
        card = card.match(/.{1,2}/g);
        card = card.join(':')
        return card;
    }

    getData(id, card = false) {
        if (!id) return;

        if (this.timer)
            clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            if (this.cancelToken)
                this.cancelToken.cancel("Only one request allowed at a time.");
            this.cancelToken = axios.CancelToken.source();

            if (card) {
                id = this.handleCard(id);
            }

            this.setState({
                loading: true,
            });

            api({
                method: 'get',
                url: `timetracking/assignments/${card ? 'card' : 'user'}/${id}`,
            }).then((response) => {
                this.setState({
                    loading: false,
                    assignments: response.data,
                    userId: response.user_id,
                    card: null,
                });
                this.cancelToken = null;
            }).catch((error) => {
                this.setState({ loading: false, card: null });
                if (axios.isCancel(error)) return null;
                console.error(error);
                errorPopper(error, tr('get_error'));
            });
        }, 500);
    }

    getStatusOptions() {
        api({
            method: 'get',
            url: 'assignment/getStatuses'
        }).then((response) => {
            let options = [{ value: "", label: tr("all") }];
            Object.entries(response).forEach(([key, value]) => {
                if (key <= 7) {
                    options.push({ value: key, label: tr(value) })
                }
            });
            this.setState({ statusOptions: options })
        }).catch((error) => {
            console.log(error);
        });
    }

    renderUserSelect() {
        return <div style={{ width: '350px' }}>
            <ApInputStack gap='0'>
                <ApAddon>
                    {tr('person')}
                </ApAddon>
                <ApSelect
                    value={this.state.user}
                    onChange={(user) => this.handleChange(user, 'user')}
                    apiUrl='search/user'
                    apiData={{
                        modules: ['assignments'],
                        is_active: true,
                        active_contracts_only: true
                    }}
                    label={tr('person')}
                    optionRenderer="user"
                    objKeyId="id"
                    objKeyValue="name"
                    clearable={true}
                    loading={this.state.loading}
                    disabled={this.state.locked || this.state.loading || false}
                    filterNonActives={true}
                    valueRenderer="user"
                    objKeySearchable="user"
                    onFocus={() => this.setState({ selectFocused: true })}
                    onBlur={() => this.setState({ selectFocused: false })}
                />
            </ApInputStack>
        </div>
    }

    renderCardInput() {
        return <ApInput
            type='hidden'
            value={this.state.card}
            // onChange={(e) => this.handleChange(e.target.value, 'card')}
            onChange={(e) => null}
        />
    }

    renderColumns() {
        return [
            {
                Header: tr('name'),
                accessor: 'name',
                customFilter: {
                    type: 'text',
                    placeholder: tr('assignment'),
                },
            },
            {
                Header: tr('description'),
                accessor: 'description',
                customizable: true,
                customFilter: {
                    type: 'text',
                    placeholder: tr('description'),
                },
            },
            {
                Header: tr('project'),
                accessor: 'search',
                customizable: true,
                showInitially: true,
                customFilter: {
                    type: 'text',
                    placeholder: tr('project'),
                },
                Cell: row => {
                    const projectName = keyExists(row.original, 'project.name', true, false);
                    const projectCode = keyExists(row.original, 'project.project_code', true, false);
                    return (
                        <>
                            {projectName} {projectCode && <div><small>{projectCode}</small></div>}
                        </>
                    );
                },
            },
            {
                Header: tr('customer'),
                accessor: 'customer.name',
                customizable: true,
                showInitially: true,
                customFilter: {
                    type: 'text',
                    placeholder: tr('customer'),
                },
            },
            {
                Header: tr('assignment_worker'),
                accessor: 'worker',
                Cell: (row) => row.original.team_id ? <>{row.value} <SvgIcon icon='user-friends' type='solid' /></> : row.value,
                customizable: true,
                showInitially: true,
                customFilter: {
                    type: 'text',
                    placeholder: tr('assignment_worker'),
                },
            },
            {
                Header: tr('start_date'),
                accessor: 'begin_at',
                Cell: (row) => row.original.begin_at ? moment(row.original.begin_at).format('DD.MM.YYYY') : '',
                customizable: true,
                filterable: false,
            },
            {
                Header: tr('end_date'),
                accessor: 'end_at',
                Cell: (row) => row.original.end_at ? moment(row.original.end_at).format('DD.MM.YYYY') : '',
                customizable: true,
                filterable: false,
            },
            {
                Header: tr('status'),
                accessor: 'status_id',
                customizable: true,
                showInitially: true,
                Cell: (row) => tr(row.original.status_show),
                customFilter: {
                    type: 'select',
                    options: this.state.statusOptions,
                },
            }
        ];
    }

    multiselectActions() {
        return [
            {
                icon: "clock",
                label: tr('assignment_status_control_4'),
                action: (indexes) => this.setStatus(indexes, 4),
                unselectAfter: true,
                closeAfter: true,
            },
            {
                icon: "user-clock",
                label: tr('assignment_status_control_7'),
                action: (indexes) => this.setStatus(indexes, 7),
                unselectAfter: true,
                closeAfter: true,
            },
            {
                icon: "check",
                label: tr('assignment_status_control_10'),
                action: (indexes) => this.setStatus(indexes, 10),
                unselectAfter: true,
                closeAfter: true,
            },
            {
                icon: "check-double",
                label: tr('assignment_status_control_9'),
                action: (indexes) => this.setStatus(indexes, 9),
                unselectAfter: true,
                closeAfter: true,
            },
            {
                icon: "times-circle",
                label: tr('assignment_status_control_8'),
                action: (indexes) => this.setStatus(indexes, 8),
                unselectAfter: true,
                closeAfter: true,
            },
            {
                icon: "backspace",
                label: tr('clear'),
                action: this.resetState,
                unselectAfter: true,
                closeAfter: true,
                alwaysOn: true,
            },
        ]
    }

    setStatus(indexes, statusId) {
        const assignments = this.state.assignments.filter((assignment, index) => indexes.includes(index));
        const assignmentIds = assignments.map(assignment => assignment.id);
        this.setState({ loading: true });
        api({
            method: 'POST',
            url: 'timetracking/assignments/set/status',
            data: {
                assignment_ids: assignmentIds,
                status_id: statusId,
                user_id: this.state.userId
            },
            errorPopper: tr('status_error')
        }).then(res => {
            this.setState({ loading: false });
            this.getData(res.user_id);
        }).catch(err => {
            this.setState({ loading: false });
            console.log(err);
        });
    }

    renderModalHeader() {
        return (
            <div className="modal-header">
                <h4 className="modal-title">{tr('assignment')}: {this.state.selectedAssignment ? this.state.selectedAssignment.name : ''}</h4>
            </div>
        );
    }

    renderModalBody() {
        return (
            <div className="modal-body">
                Body...
            </div>
        );
    }

    renderModalFooter() {
        return (
            <div className="modal-footer">
                <ApButton onClick={() => this.setState({ selectedAssignment: null, showModal: false })}>
                    <SvgIcon icon="times" type="solid" /> {tr('cancel')}
                </ApButton>
            </div>
        );
    }

    goToAssignment(id) {
        this.props.history.push(`/assignments/${id}/1`);
    }

    resetState() {
        if (this.state.assignments.length > 0 || this.state.id !== null) {
            this.setState({
                assignments: [],
                card: null,
                user: null,
            });
        }
    }

    handleRowClick(row) {
        this.setState({ selectedAssignment: row, showModal: true });
    }

    render() {
        return (
            <div>
                <div className="apBox" id="timetracking-assignments">
                    <div className="apBoxHeader">
                        <h1>{tr('work_list')}</h1>
                        <p>{tr('timetracking_assignments_info')}</p>
                    </div>
                    <div className="padding">
                        {this.props.apProjectSettings.assignment_card_only === false
                            ? this.renderUserSelect()
                            : null
                        }
                        {this.renderCardInput()}
                        <ApReactTable
                            data={this.state.assignments}
                            columns={this.renderColumns()}
                            loading={this.state.loading}
                            rowActions={(row) => {
                                return <div className="apSimpleButton" onClick={() => this.goToAssignment(row.id)}>
                                    <SvgIcon
                                        type="solid"
                                        icon="arrow-right"
                                    />
                                </div>
                            }}
                            showFiltersInitially
                            customizable
                            rememberId="timetracking-assignments"
                            multiselect={this.multiselectActions()}
                            noDataText={this.props.apProjectSettings.assignment_card_only === true && !this.state.card
                                ? <strong>{tr('read_card')}</strong>
                                : null
                            }
                        />
                    </div>
                </div>
                <ApModal
                    show={this.state.showModal}
                    className='narrow'
                    onClose={() => this.setState({ showModal: false, selectedAssignment: null })}
                    hideCross
                    header={this.renderModalHeader()}
                    body={this.renderModalBody()}
                    footer={this.renderModalFooter()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => (
    { apProjectSettings: state.apProjectSettings }
)

export default connect(mapStateToProps)(TimetrackingAssignments);