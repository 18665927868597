import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import { tr } from 'services/Helpers/Helpers';

class ResetPassword extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            loading: true,

            password: '',
            password2: '',
            user: null,
        };
        autoBind(this);
    }

    componentDidMount()
    {
        const token = this.props.match.params.token;
        //console.log('Get reset user by token', token );
        this.getUser( token )
    }

    getUser( token )
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: `reset/user/${ token }`,
        }).then((response) => {
            this.setState({
                loading: false,
                user: response,
            });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    save()
    {
        const token = this.props.match.params.token;

        this.setState({ loading: true });
        api({
            method: 'post',
            url: `reset/submit/${ token }`,
            data: {
                password: this.state.password,
            },
        }).then((response) => {

            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('password_updated') }</strong>,
            });

            this.goToLogin();

            // setTimeout(() => {
            // }, 1000 );

        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    goToLogin()
    {
        this.props.history.push('/login');
    }

    handleChange(e)
    {
        this.setState({ [e.target.name]: e.target.value, errorMsg: false });
    }

    renderForm()
    {
        const pwMatch = ( this.state.password === this.state.password2 );

        let buttonDisabled = false;
        if( this.state.loading ) buttonDisabled = true;
        if( !pwMatch ) buttonDisabled = true;
        if( this.state.password.length === 0 ) buttonDisabled = true;

        return <div>
            <ApInputStack gap="0">
                <ApAddon
                    noRightBorder
                    width="48px"
                    labelFor="password">
                    <SvgIcon icon="key" type="solid" />
                </ApAddon>
                <ApInput
                    type="password"
                    id="password"
                    name="password"
                    label={ tr('new_password') }
                    onChange={ this.handleChange }
                    onKeyPress={ this.handleKeyPress }
                    value={ this.state.password }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </ApInputStack>

            <ApInputStack gap="0">
                <ApAddon
                    noRightBorder
                    width="48px"
                    labelFor="password2">
                    <SvgIcon icon="check-double" type="solid" />
                </ApAddon>
                <ApInput
                    type="password"
                    id="password2"
                    name="password2"
                    label={ tr('confirm_new_password') }
                    onChange={ this.handleChange }
                    onKeyPress={ this.handleKeyPress }
                    value={ this.state.password2 }
                    validationState={ pwMatch ? null : 'warning'  }
                    tooltip={ pwMatch ? null : tr('passwords_do_not_match') }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </ApInputStack>

            <ApButton
                fullWidth
                color="blue"
                disabled={ buttonDisabled }
                loading={ this.state.loading }
                onClick={ this.save }
            >
                <span>{ tr('change_password') }</span>
            </ApButton>
        </div>
    }

    render()
    {
        let body = null;
        if( !this.state.loading && !this.state.user )
            body = <div className="apErrorMsg">{ tr('user_not_found') }</div>
        else 
            body = this.renderForm();

        return <div id="login">
            <div className="login-box">
                <div className="padding">
                    <h3 className="header">
                        <SvgIcon icon="lock" type="solid" /> { tr('new_password') }
                    </h3>
                    { body }

                    <ApButton ghost size="small" fullWidth onClick={ this.goToLogin }>{ tr('cancel') }</ApButton>
                </div>
            </div>
            <img className="bgLogo" src="/img/logo.svg" alt="EASY-PRO Intra" />
            <div className="footer">
                Easy-Pro - Copyright © { new Date().getFullYear() } <a href="https://www.amiproservice.fi/" target="_blank" rel="noopener noreferrer">AmiPro Service Oy</a>
            </div>
        </div>
    }
}

export default ResetPassword;
