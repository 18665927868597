import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { tr } from 'services/Helpers/Helpers';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import { Collapse } from 'react-bootstrap';

class CreateStock extends React.Component
{
    
    constructor(props)
    {
        super(props);
        this.state = {
            storageType: 'project',
            storageTypes: [],
            receiverTitle: null,
            receiverId: null,
            createNewLocation: false,
            newLocationCode: '',
            newLocationName: '',
        }

        this.steps = [{
            name: "Tietojen tarkistus",
            render: this.renderStepInformation.bind( this ),
            invalid:  () => {
                if (this.state.createNewLocation && (!this.state.newLocationCode || !this.state.newLocationName)) {
                    return true;
                }
                return ( !this.state.receiverTitle && !this.state.receiverId )
            },
        },{
            name: "Varmistus",
            render: this.renderStepConfirmation.bind( this ),
        }];

        autoBind(this);
    }

    componentDidMount() {
        const types = [{
            name: 'project',
            value: 'Projektivarasto'
        }, {
            name: 'location',
            value: 'Varasto'
        }];

        this.setState({ storageTypes: types });
    }

    resetState()
    {
        this.resetReceiver();
        this.setState({ storageType: 'location', createNewLocation: false, newLocationCode: '', newLocationName: '' });

        const data = this.props.data;
        if( !data ) return null;
    }

    resetReceiver() {
        this.setState({
            receiverTitle: null,
            receiverId: null
        });
    }

    save() {
        let data = {};

        data.location_id = this.state.receiverId;        
        data.component_id = this.props.component_id;
        data.row_id = this.props.row_id;
        data.createNewLocation = this.state.createNewLocation;
        data.newLocationCode = this.state.newLocationCode;
        data.newLocationName = this.state.newLocationName;
        
        if ( data.component_id!=null ) {
            return api({
                method: 'post',
                url: `storage/component/stock/new`,
                data: data,
            }).then( ( response ) => {
                if (response) {
                    window.emitter.emit('popper', {
                        type: 'success',
                        content: <strong>Varastopaikka luotu!</strong>,
                    });
                } else {
                    window.emitter.emit('popper', {
                        type: 'danger',
                        content: <strong>Varastopaikan luonnissa tapahtui virhe</strong>,
                    });
                }
                data.location_id = response;
                this.props.onSave(data);
            }).catch((error) => {
                console.warn(`Varastopaikan luonti epäonnistui: [${error}]`);
                window.emitter.emit('popper', {
                    type: 'danger',
                    content: <div>Varastopaikan luonti epäonnistui...</div>,
                });
            });
        }
        else {
            this.props.onSave(data);
        }
    }

    setReceiverStorage( selected )
    {
        if (selected) { this.setState({ receiverTitle: selected.name, receiverId: selected.id }) } 
        else {this.resetReceiver()}
    }

    handleTypeChange(target) {
        this.resetReceiver();
        this.setState( {storageType: target});
    }

    renderStepInformation()
    {
        const isRegularStorage = this.state.storageType == 'project';

        const storageSelection =  <ApSelect
            label={tr('add_storage')}
            value={ this.state.receiverTitle }
            optionRenderer="storage_location"
            onChange={ this.setReceiverStorage }
            apiUrl="storage/locations/find"
            apiData={{
                canComponents: this.state.createNewLocation ? false : true,
                projectStorage: isRegularStorage,
                excludeExistingStocks: true,
                component_id: this.props.component_id
            }}
            loading={ this.state.loading }
            disabled={ this.state.loading }
        />;

        const view = <div className="stepSupplier">
            <div className="supplier-select">
            <ApInput
                type="select"
                id="storageType"
                name="type"
                value={ this.state.storageType ? this.state.storageType : null}
                options={ this.state.storageTypes.map( t => { return { value: t.name, label: t.value } }) }
                onChange={ (e) => this.handleTypeChange(e.target.value) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            </div>
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                Valitse varaston tyyppi.<br/><br/>
                Huomio; tässä näkymässä ei näytetä varastopaikkoja jotka ovat jo olemassa!
            </div>
            {this.state.storageType === 'location' &&
                <div className='justify-space-between' style={{marginBottom: '1em'}}>
                    <label htmlFor='newLocationSwitch'>{tr('add_sub_storage')}</label>
                    <ApSwitch 
                        on={this.state.createNewLocation}
                        inline
                        onChange={() => this.setState({createNewLocation: !this.state.createNewLocation})}
                        id='newLocationSwitch'
                        label='asd'
                    />
                </div>
            }
            {storageSelection}
            <Collapse in={this.state.createNewLocation && this.state.storageType === 'location'}>
                <div>
                    <ApInput
                        id={'newLocationCode'}
                        name={'newLocationCode'}
                        label={tr('code')}
                        onChange={e => this.setState({newLocationCode: e.target.value})}
                        value={this.state.newLocationCode}
                        loading={this.state.loading}
                        disabled={this.state.loading}
                        validationState={this.state.newLocationCode ? '' : 'error'}
                    />
                    <ApInput
                        id={'newLocationName'}
                        name={'newLocationName'}
                        label={tr('name')}
                        onChange={e => this.setState({newLocationName: e.target.value})}
                        value={this.state.newLocationName}
                        loading={this.state.loading}
                        disabled={this.state.loading}
                        validationState={this.state.newLocationName ? '' : 'error'}
                    />
                </div>
            </Collapse>
        </div>

        return view;
    }

    renderStepConfirmation()
    {
        let title;
        const description = `Luodaan uusi varastopaikka komponentille.`;
        
        this.state.storageType == 'location' 
            ? title = `Varasto: ${this.state.receiverId} - ${this.state.receiverTitle}`
            : title = 'Projektivarasto';
        
        return <div>
            <h4>Viimeinen tarkistus</h4>
            <h5 style={{ marginTop: '1.4em' }}>{title}</h5>
            <p>{description}</p>
        </div>
    }

    render()
    {
        return <div id="newPo">
            <ApModalWizard
                header="Varastopaikan luonti"
                onOpen={ this.resetState }
                show={ this.props.show }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.save }
            />
        </div>
    }
    
}

export default CreateStock;