import React from 'react';
import autoBind from 'react-autobind';

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import FileUpload from 'common/FileUpload/FileUpload.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import ApValidate from 'services/ApValidate/ApValidate.js';
import { dateInputToSql, tr } from 'services/Helpers/Helpers.js';
import { ApInput
       , ApInputStack
       , ApAddon } from 'common/ApInput/ApInput.js';

import './ConfirmOrder.css';

class SendOrder extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            comment: '',

            file: null,
            confirmed: false,
            skipFile: false,
            loading: false,
            dueDate: null,
            deliveryDate: null,
        }

        const fileValidator = ( value ) => {
            if( this.state.skipFile ) return true;
            return Boolean( value );
        };

        this.validator = new ApValidate( this, {
            file: { filter: fileValidator, text: tr('oc_file_missing') },
            dueDate: { filter: 'date', text: tr('due_date_invalid') },
            deliveryDate: { filter: 'date', text: tr('delivery_date_invalid') },
            confirmed: { filter: 'required', state: 'error', text: tr('po_check_missing') },

        });

        autoBind(this);
    }

    save()
    {
        this.setState({ loading: true });

        let data = {};
        data.latest_comment = this.state.comment;

        data.due_date = this.state.dueDate;
        data.delivery_date = this.state.deliveryDate;

        data.file_id = null;
        if( !this.state.skipFile )
            data.file_id = this.state.file.id;

        //console.log( 'data', data );

        this.props.onSubmit( data ).then( () => {
            this.setState({ loading: false });
        }).catch( (error) => {
            this.setState({ loading: false });
        });
    }

    renderFileInput()
    {
        let fileDom = null;

        if( this.state.skipFile )
        {
            fileDom = <ApAddon>&nbsp;</ApAddon>
        }
        else
        {
            fileDom = <FileUpload
                onUpload={ ( file ) => { this.setState({ file: file }) } }
                onClear={ () => { this.setState({ file: null }) } }
                description="PoOrderConfirmFile"
                value={ this.state.file }
                loading={ this.state.loading }
                disabled={ this.state.loading }
                validationState={ this.validator.getState('file') }
            />
        }

        return <ApInputStack gap="0">
            <ApAddon width="210px"
                noRightBorder
                validationState={ this.validator.getState('file') }
            >
                { tr('order_confirmation_file') } *
            </ApAddon>

            { fileDom }

            <ApAddon width="140px" className="skip-file" noLeftBorder>
                <ApTooltip position="topright" text={ tr('dont_attach_oc_file') }>
                    <div className="text-right">
                        { tr('skip') }
                        <ApSwitch
                            id="skip-file"
                            on={ this.state.skipFile }
                            onChange={ () => { this.setState({ skipFile: !this.state.skipFile }) } }
                            inline
                            small
                            disabled={ this.state.loading }
                        />
                    </div>
                </ApTooltip>
            </ApAddon>

        </ApInputStack>
    }

    renderBody()
    {
        return <div>
            <ApInput
                type="textarea"
                id="comment-to-log"
                name="comment"
                label={ tr('comment_to_event_log' ) }
                autoComplete="off"
                value={ this.state.comment }
                onChange={ ( e ) => { this.setState({ comment: e.target.value }) } }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />

            <div className="fileInputContainer">
                { this.renderFileInput() }
            </div>

            <ApInputStack gap="0">
                <ApAddon width="200px"
                    noRightBorder
                    validationState={ this.validator.getState('dueDate') }
                >
                    { tr('due_date') } *
                </ApAddon>
                <ApInput
                    type="datetimeV2"
                    id="due-date"
                    name="due-date"
                    label={ tr('due_date') }
                    value={ this.state.dueDate }
                    onChange={ ( val ) => { this.setState({ dueDate: val }) } }
                    validationState={ this.validator.getState('dueDate') }
                />
            </ApInputStack>

            <ApInputStack gap="0">
                <ApAddon width="200px"
                    noRightBorder
                    validationState={ this.validator.getState('deliveryDate') }
                >
                    { tr('delivery_date') } *
                </ApAddon>
                <ApInput
                    type="datetimeV2"
                    id="delivery-date"
                    name="delivery-date"
                    label={ tr('delivery_date') }
                    value={ this.state.deliveryDate }
                    onChange={ ( val ) => { this.setState({ deliveryDate: val }) } }
                    validationState={ this.validator.getState('deliveryDate') }
                />
            </ApInputStack>

            <div className="apSwitchBlock">
                <label htmlFor="confirm-order-switch" className="info">
                    { tr('po_checked') }
                    <small>{ tr('po_checked_info') }</small>
                </label>
                <ApSwitch
                    id="confirm-order-switch"
                    on={ this.state.confirmed }
                    onChange={ () => { this.setState({ confirmed: !this.state.confirmed }) } }
                    disabled={ this.state.loading }
                />
            </div>
        </div>
    }

    setDates() {
        if ( this.props.due_date!=null && this.state.dueDate == null ) {
            this.setState({dueDate: this.props.due_date});
        }
    
        if ( this.props.delivery_date!=null && this.state.deliveryDate == null ) {
            this.setState({deliveryDate: this.props.delivery_date});
        }
    }

    render()
    {
        let validatorMessage = this.validator.getStatusAll();

        return <div id="confirmPo">
            <ApModal
                show={ this.props.show }
                handleClose={ this.props.onClose }
                className="narrow"
                onOpen={ this.setDates }
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('po_confirmation') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="footer padding-small">
                        <ApButton
                            onClick={ () => { this.props.onClose() }}
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        >
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <div className="saveButtonContainer">
                            <ApTooltip text={ validatorMessage } block>
                                <ApButton
                                    color="green"
                                    onClick={ this.save }
                                    loading={ this.state.loading }
                                    disabled={ Boolean( validatorMessage ) }
                                >
                                    <SvgIcon icon="clipboard-check" type="solid" />
                                    { tr('confirm_order') }
                                </ApButton>
                            </ApTooltip>
                        </div>
                    </div>
                }
            />
        </div>
    }
}

export default SendOrder;
