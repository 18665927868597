import React from 'react';
import autoBind from 'react-autobind';
import { ApInput
       , ApInputStack
       , ApAddon }     from 'common/ApInput/ApInput.js';

import ApSelect        from 'common/ApSelect/ApSelect.js';

import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import ApModal from 'common/ApModal/ApModal.js';
import api from 'services/Api/Api.js';
import { errorPopper, tr } from 'services/Helpers/Helpers.js';
import './ReceiverModal.css';

class ReceiverDataSelect extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            suggests: [],
        };

        this.findTimer = null;
        autoBind(this);


        this.validator = new ApValidate(this, {
            receiver_type:        { filter: 'required', textAll: tr('mandatory') },
            receiver_id:          { filter: 'required', textAll: tr('mandatory') },
            receiver_name:        { filter: 'required', textAll: tr('mandatory') },
            receiver_address:     { filter: 'required', textAll: tr('mandatory') },
            receiver_zipcode:     { filter: 'required', textAll: tr('mandatory') },
            receiver_city:        { filter: 'required', textAll: tr('mandatory') },
        });

    }

    resetState()
    {
        this.setState({
            receiver: this.props.receiver,

            receiver_type: this.props.receiver_type,
            receiver_id: this.props.receiver_id,
            receiver_name:this.props.receiver_name,
            receiver_address: this.props.receiver_address,
            receiver_zipcode: this.props.receiver_zipcode,
            receiver_city: this.props.receiver_city,
            receiver_country: this.props.receiver_country,
            receiver_contact_name: this.props.receiver_contact_name,
            receiver_project_id: this.props.receiver_project_id,
            receiver_title: this.props.receiver_title,

            receiverSubProjectId: null,
            receiverSubProjectName: null,
            receiverSubProjectCode: null,
        } );
    }

    close()
    {
        this.props.handleClose({})
    }


    save(saveReceiverAddress = false)
    {
        let changed = false;
        if (this.props.receiver_id != this.state.receiver_id) { changed = true }

        let recType, recId;

        if (this.state.receiver_type == 'project') {
            recType = this.state.receiver_loc_type == 'P' ? 'project': 'location';
            recId = this.state.receiver.storage_location_id;
        } else {
            recId = this.state.receiver_id;
            recType = this.state.receiver_type 
        }
        
        if (saveReceiverAddress) {
            api({
                url: 'storage/locations/saveAddress',
                method: 'POST',
                data: {
                    id: recId,
                    delivery_name: this.state.receiver_name,
                    delivery_address: this.state.receiver_address,
                    delivery_zipcode: this.state.receiver_zipcode,
                    delivery_city: this.state.receiver_city,
                    delivery_country: this.state.receiver_country,
                    delivery_contact: this.state.receiver_contact_name,
                },
            }).then(response => {
                // console.log("response", response);
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>{ tr('saved') }</strong>,
                });
            }).catch(error => {
                console.error("Error: ", error);
                errorPopper(error, tr('save_error'));
            })
        }

        this.props.handleClose({
            receiver: this.state.receiver,
            receiver_title: this.state.receiver_title,
            receiver_type: recType,
            receiver_id: recId,
            receiver_name: this.state.receiver_name,
            receiver_address: this.state.receiver_address,
            receiver_zipcode: this.state.receiver_zipcode,
            receiver_city: this.state.receiver_city,
            receiver_country: this.state.receiver_country,
            receiver_contact_name: this.state.receiver_contact_name,
            receiver_changed: changed,
            receiver_project_id: this.state.receiver_project_id,

            //Sub project
            receiverSubProjectId: this.state.receiverSubProjectId,
            receiverSubProjectName: this.state.receiverSubProjectName,
            receiverSubProjectCode: this.state.receiverSubProjectCode,
        });

    }


    handleChange(e)
    {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleReceiverTypeChange( value )
    {
        this.nullReceiver();
        this.setState({ receiver_type: value });
    }

    nullReceiver()
    {
        this.setState({
            receiver: null,
            receiver_title:   null,
            receiver_id:      null,
            receiver_name:    null,
            receiver_address: null,
            receiver_zipcode: null,
            receiver_city:    null,
            receiver_country: null,
            receiver_contactName: null,
            receiver_loc_type: null,
            receiver_project_id:null,
        })
    }

    setReceiverStorage( selected )
    {
        this.nullSubProject();
        if( selected )
        {
            const locationId = selected.storage_location_id ? selected.storage_location_id : selected.id;
            this.setState({
                receiver: selected,
                receiver_title:   selected.name,
                receiver_id:      locationId,
                receiver_name:    selected.delivery_name,
                receiver_address: selected.delivery_address,
                receiver_zipcode: selected.delivery_zipcode,
                receiver_city:    selected.delivery_city,
                receiver_country: selected.delivery_country,
                receiver_project_id: selected.receiver_project_id,
                receiver_contactName: null,
            });

            //Hae oletusarvot jos ei määritelty lokaatiolle
            if (selected.delivery_address == null && selected.delivery_name == null) {
                this.setDefaultStorage(selected.id);
            }
        }
        else
        {
            this.nullReceiver();
        }
    }

    setReceiverStorage_project( selected )
    {
        this.nullSubProject();
        if (selected) {
            const locId = selected.storage_location_id;
            api({
                method: 'get',
                url: `storage/location/get/${locId}`,
            }).then(( response ) => {
                if (response != 0) {
                    this.setState({
                        receiver: selected,
                        receiver_title:   selected.name,
                        receiver_id:      selected.id,
                        receiver_name:    response.delivery_name,
                        receiver_address: response.delivery_address,
                        receiver_zipcode: response.delivery_zipcode,
                        receiver_city:    response.delivery_city,
                        receiver_country: response.delivery_country,
                        receiver_contactName: response.delivery_contact,
                        receiver_loc_type: response.type,
                        receiver_project_id: selected.id,
                    });
                }
                if (response.delivery_address == null && response.delivery_name == null) { 
                    this.setDefaultStorage(selected.id);
                }
            }).catch(( error ) => {
                console.log(`Ongelma tietojen haussa ${error}`);
            });
        } else {
            this.nullReceiver();
        }
    }

    setDefaultStorage(locId) {
        if (this.state.receiver_type !== 'location') {
            api({
                method: 'get',
                url: `crm/address/findByLoc/${locId}`,
            }).then(( response ) => {
                if (response != 0) {
                    this.setState({
                        receiver_name: response.name,
                        receiver_address: response.street,
                        receiver_zipcode: response.postal_code,
                        receiver_city: response.city,
                        receiver_country: response.country,
                        receiver_contactName: null,
                    })
                }
            }).catch(( error ) => {
                console.error(`Ongelma asiakkaan oletustietojen haussa ${error}`);
            });
        }
    } 

    setSubProject(selected) {
        if (selected) {
            this.setState({
                receiverSubProjectId: selected.id,
                receiverSubProjectName: selected.name,
                receiverSubProjectCode: selected.project_code,
            })
        } else {
            this.nullSubProject();
        }
    }

    nullSubProject() {
        this.setState({
            receiverSubProjectId: null,
            receiverSubProjectName: null,
            receiverSubProjectCode: null,
        });
    }

    renderReceiverType()
    {
        return <ApInputStack gap="0">
            <ApAddon noRightBorder width="200px">
                { tr('recipient') } *
            </ApAddon>
            <ApInput
                type="select"
                options={ [
                    { value: null,      label: '' },
                    { value: 'location', label: tr('storage') },
                    { value: 'project', label: tr('project') },
                ] }
                id="receiverType"
                name="receiverType"
                value={ this.state.receiver_type }
                onChange={ ( e ) => this.handleReceiverTypeChange( e.target.value ) }
                validationState={ this.validator.getState('receiver_type') }
            />
        </ApInputStack>
    }

    renderReceiverSelect()
    {
        if( this.state.receiver_type === 'location' )
        {
            return <ApSelect
                label={ `${tr('recipient_storage')} *` }
                value={ this.state.receiver }
                optionRenderer="storage_location_address"
                onChange={ this.setReceiverStorage }
                objKeyId="id"
                objKeyValue={ ( l ) => {
                    let name = l.name ? `: ${ l.name }` : '';
                    return `${ l.code }${ name }`
                }}
                clearable
                apiUrl="storage/locations/find"
                apiData={{ 
                    canReceive: true,
                    projectStorage: false,
                }}
                validationState={ this.validator.getState('receiver_id') }
            />
        }
        else if( this.state.receiver_type === 'project' )
        {

            return <div>
                <ApSelect
                label={ `${tr('recipient_project')} *` }
                loading={ this.state.loading }
                value={ this.state.receiver_title ? this.state.receiver_title : '' }
                optionRenderer="project"
                onChange={ this.setReceiverStorage_project }
                objKeyId="id"
                clearable
                apiUrl="report/projects/find"
                apiData={{
                    	formatter: 'select',
                    	withoutOffers : 'true',
                    	hasStorageLocation : 'true',
                    	onlyRoot : ' true',
                        withoutClosed: true
                }}
                validationState={ this.validator.getState('receiver_id') }
            />
            <ApSelect
                label={ `${tr('recipient_sub_project')}` }
                loading={ this.state.loading }
                value={ this.state.receiverSubProjectName ? this.state.receiverSubProjectName : '' }
                optionRenderer="project"
                onChange={ this.setSubProject }
                objKeyId="id"
                clearable
                apiUrl="storage/locations/findSub"
                apiData={{ 
                    pId: this.state.receiver_project_id
                }}
            />
            </div>
        }
        return null;
    }

    renderFields()
    {
        if( !this.state.receiver_id ) return null;

        const getInput = ( field, label, validate = false ) => {
            let validationState = ( validate ) ? this.validator.getState( field ) : null;

            if( validate ) label = label + ' *'

            return <ApInput
                type="text"
                id={ field }
                name={ field }
                label={ label }
                autoComplete="off"
                onChange={ this.handleChange }
                value={ this.state[ field ] }
                validationState={ validationState }
            />
        }

        return <div>
            { getInput('receiver_name', tr('recipient'), true ) }
            <div className="receiver-indent">
                { getInput('receiver_address', tr('address'), true ) }
                <div className="col-container">
                    <div className="col-left">
                        { getInput('receiver_zipcode', tr('postal_code'), true ) }
                    </div>
                    <div className="col-right">
                        { getInput('receiver_city', tr('post_office'), true ) }
                    </div>
                </div>
                <div className="col-container">
                    <div className="col-left">
                        { getInput('receiver_country', tr('country') ) }
                    </div>
                </div>
                { getInput('receiver_contact_name', tr('contact_person') ) }
            </div>
        </div>
    }

    render()
    {
        let receiverType   = this.renderReceiverType();
        let receiverSelect = this.renderReceiverSelect();
        let receiverFields = this.renderFields();



        return <div className="receiverEdit">
            <ApModal
                show={ this.props.show }
                handleClose={ this.props.handleClose }
                className="narrow"
                onOpen={ this.resetState }
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('edit_recipient') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        { receiverType }
                        { receiverSelect }
                        { receiverFields }
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton onClick={ this.close }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        {this.props.showSaveReceiverAddress &&
                        <ApButton onClick={() => this.save(true)} color='blue' disabled={ Boolean( this.validator.getStatusAll() ) && !this.state.changed }>
                            <SvgIcon icon='save' type='solid' />
                            {tr('save')}
                        </ApButton>}
                        <ApButton color="green" onClick={() => this.save(false) } disabled={ Boolean( this.validator.getStatusAll() ) }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('continue') }
                        </ApButton>
                    </div>
                }
            />
        </div>

    }
}

export default ReceiverDataSelect;
