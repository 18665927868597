/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import Measure from 'react-measure';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';
import {matchSorter} from 'match-sorter'

import './Settings.css';
import RowEdit from './RowEdit/RowEdit.js';

import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import ApSwitch         from 'common/ApSwitch/ApSwitch.js';
import ApSelect         from 'common/ApSelect/ApSelect.js';
import ApButton         from 'common/ApButton/ApButton.js';
import ApReactTable     from 'common/ApReactTable/ApReactTable.js';
import ApStickyBar      from 'common/ApStickyBar/ApStickyBar.js';
import ApTooltip        from 'common/ApTooltip/ApTooltip.js';

import { ApTabs, 
         ApTab }        from 'common/ApTabs/ApTabs.js';

import { ApInput,
         ApInputStack,
         ApAddon }      from 'common/ApInput/ApInput.js';

import ApValidate       from 'services/ApValidate/ApValidate.js';
import { formatMoney, 
         keyExists }    from 'services/Helpers/Helpers.js';

import { Collapse }     from 'react-bootstrap';


class Settings extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            settings: false,
            unsavedChanges: false,

            globalAllowanceLoading: false,

            rowEditOpen: false,
            rowEditKey: false,
            rowEditData: false,
        };

        // Temporary Id for new rows
        this.newRowId = 0;

        this.validator = new ApValidate( this, {
            // TODO
        });

        autoBind(this); 
    }

    componentDidMount()
    {
        this.getSettings();
    }

    getSettings()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'company/settings/workhour',
        }).then(( response ) => {

            this.setState({ 
                settings: response,
                loading: false,
                unsavedChanges: false,
            });
            
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    getGlobalAllowances()
    {   
        if( !keyExists( this.state.settings, "daily_allowances" ) )
            return false;

        this.setState({ globalAllowanceLoading: true });
        api({
            method: 'get',
            url: 'company/settings/workhour/allowances',
        }).then(( response ) => {

            const current = this.state.settings.daily_allowances.slice();
            const getCurrentIndex = ( id ) => {
                for( let i = 0; i < current.length; i++ )
                    if( current[i].global_allowance_id == id )
                        return i;

                return -1;
            };

            let settings = { ...this.state.settings };
            let counterUpdate = 0;
            let counterNew = 0;
            
            response.forEach(( item ) => {
                const index = getCurrentIndex( item.global_allowance_id );
                if( index != -1 )
                {
                	item.id = current[ index ].id;
                	item.entries_count = current[ index ].entries_count;
                    settings.daily_allowances[ index ] = item;
                    counterUpdate++;
                }
                else {
                	this.newRowId++;
                	item.id = "new" + this.newRowId;
                	item.entries_count = 0;
                    settings.daily_allowances.push( item );
                    counterNew++;
                }
            });

            window.emitter.emit('popper', {
                type: 'success',
                content: <div>Tuonti onnistui: <br />Kokonaan uusia: <strong>{ counterNew } kpl</strong>, Päivitettyjä: <strong>{ counterUpdate } kpl</strong></div>,
            });

            this.setState({ 
                settings: settings,
                unsavedChanges: ( counterUpdate > 0 || counterNew > 0 ),
                globalAllowanceLoading: false 
            });
            
        }).catch( ( error ) => {
            this.setState({ globalAllowanceLoading: false });
        });
    }

    handleChange( key, value )
    {
    	let settings = { ...this.state.settings };
    	settings[ key ] = value;
    	this.setState({
    		settings: settings,
    		unsavedChanges: true,
    	});
    }

    handelSwitchChange( key )
    {
    	let settings = { ...this.state.settings };
    	settings[ key ] = !this.state.settings[ key ];
    	this.setState({
    		settings: settings,
    		unsavedChanges: true,
    	});
    }

    addRow( key )
    {
        this.editRow( key, {} );	
    }

    removeRows( key, indexes ) 
    {
        let settings = { ...this.state.settings };
        if( Array.isArray( settings[ key ] ) )
        {
            let newArray = [];
            settings[ key ].forEach( ( row, rowIndex ) => {
                if( indexes.indexOf( rowIndex ) == -1 )
                    newArray.push( row );
            });
            settings[ key ] = newArray;
        }
        this.setState({ 
        	settings: settings,
    		unsavedChanges: true,
        });
    }

    editRow( key, data )
    {
    	this.setState({
    		rowEditKey: key,
    		rowEditData: data,
    		rowEditOpen: true,
    	});
    }

    editRowClose( savedData )
    {
    	let settings = { ...this.state.settings };
    	let unsavedChanges = this.state.unsavedChanges;

    	if( savedData )
    	{
    		if( savedData.id )
    		{
    			let items = [];
    			settings[ this.state.rowEditKey ].forEach( row => {
    				if( row.id === savedData.id )
	    				items.push( savedData );
	    			else
	    				items.push( row );
	    		});
	    		settings[ this.state.rowEditKey ] = items;
    		}
    		else 
    		{
    			this.newRowId++;
    			savedData.id = "new" + this.newRowId;
    			settings[ this.state.rowEditKey ].push( savedData );
    		}
    		unsavedChanges = true;
    	}

    	this.setState({
    		rowEditOpen: false,
    		settings: settings,
    		unsavedChanges: unsavedChanges,
    	});
    }

    saveSettings() 
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'company/settings/workhour/save',
            data: this.state.settings,
        }).then(( response ) => {

            auth.reloadSettings();
        	this.getSettings();
         
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    nameColumn()
    {	
        return {
            id: 'name', 
            accessor: 'name',
            Header: "Nimi ja kuvaus", 
            Cell: ( props ) => {
            	return (
            		<div className="nameCell">
            			<span>{ props.original.name ? props.original.name : <span className="missingData">Nimi puuttuu</span> }</span><br />
                        <small>{ props.original.description ? props.original.description : "ei kuvausta" }</small>
            		</div>
            	);
            },
            filterMethod: (filter, rows) => matchSorter( rows, filter.value, { keys: [ "_original.name", "_original.description" ] }),
			filterAll: true
        };
    }

    iconColumn( accessor, icon, tooltip, cellRenderer = false )
    {
        if( typeof( cellRenderer ) != "function" )
            cellRenderer = ( p ) => this.renderBooleanCell( p, accessor, false );

        return {
            id: 'iconColumn_' + accessor, 
            accessor: accessor,
            width: 50, 
            resizable: false, 
            headerClassName: "overflowableOnHover", 
            Header: 
                <div className="iconHeader">
                    <ApTooltip text={ tooltip }>
                        <SvgIcon icon={ icon } type="solid" />
                    </ApTooltip>
                </div>, 
            Cell: cellRenderer
        };
    }

    salaryCategoryColumn( accessor, name )
    {
        return { 
            width: 120, 
            id: 'salary_category', 
            Header: name, 
            accessor: accessor,
            Cell: ( props ) => {
                return (
                    <div className="salaryCategoryCell">
                        { props.original[ accessor ] 
                            ? props.original[ accessor ]
                            : <span className="missingData">Ei palkkalajia</span>
                        }
                    </div>
                );
            }
        }
    }

    renderIconHeader( icon, tooltip )
    {
        return (
            <div className="iconHeader">
                <ApTooltip text={ tooltip }>
                    <SvgIcon icon={ icon } type="solid" />
                </ApTooltip>
            </div>
        )
    }

    renderBooleanCell( props, accessor, withText = true )
    {   
        const checked = ( typeof( accessor ) == "boolean" ? accessor : props.original[ accessor ] );
        return (
            <div className={ "booleanCell " + ( checked ? "true" : "false" ) + ( withText ? "" : " onlyIcons" ) }>
                <SvgIcon className="small-inline" icon={ checked ? "check" : "times" } type="solid" />
                { withText &&
                    <span>{ checked ? "Kyllä" : "Ei" }</span>
                }
            </div>
        );
    }

    renderCurrencyCell( props, accessor, unit )
    {
        const value = props.original[ accessor ];
        return (
            <div className="currencyCell">
                { formatMoney( value ) } <span className="unit">{ unit }</span>
            </div>
        );
    }

    render() 
    {       
        let tabBadges = {
            general: [],
            hourtypes: [],
            absences: []
        };

    	let activeConfirmUsers = 0;
    	keyExists( this.state.settings, "confirmation_users", true, [] ).forEach( user => {
    		if( user.is_active )
    			activeConfirmUsers++;
    	});

        let generalWarningCount = 0;

        if( !keyExists( this.state.settings, "travel_component.name", true ) )
            generalWarningCount++;

        if( !keyExists( this.state.settings, "expense_component.name", true ) )
            generalWarningCount++;

        if( !keyExists( this.state.settings, "allowance_component.name", true ) )
            generalWarningCount++;

        if( !keyExists( this.state.settings, 'expense_salary_category', true ) )
            generalWarningCount++;

        if( keyExists( this.state.settings, 'confirmation_required', true ) && activeConfirmUsers == 0 )
            generalWarningCount++;

        if( generalWarningCount > 0 )
            tabBadges.general.push( { value: generalWarningCount, color: "orange" } );


        //tabBadges.general.push( { value: "X", color: "green" } );
        //tabBadges.general.push( { value: "100", color: "red" } );

        const hourtypesMissing = ( keyExists( this.state.settings, "hour_types", true, [] ).length == 0 );
        const absencesMissing = ( keyExists( this.state.settings, "absence_reasons", true, [] ).length == 0 );
        

        //const errors = groupify( auth.getSettings().company.errors, "navId" );
        //const tabErrors = groupify( errors["company.settings.workhour"], "tab" );
        
        /*
        const getTabErrors = ( tab ) => {
            
            if( !(tab in tabErrors) )
                return false

            let haveErrors = false;
            let errors = 0;
            let warnings = 0;
            tabErrors[ tab ].map( item => {
                if( item.type === "error" )
                {
                    errors++;
                    haveErrors = true;
                }
                else if ( item.type === "warning" )
                    warnings++;
            });

            if( errors == 0 && warnings == 0 )
                return false;

            return haveErrors ? errors : ( warning * -1 );
        };
        */

        return (
            <Measure
                onResize={ () => {
                    if( this.stickyBar )
                        this.stickyBar.updateBounds();
                }}
            >
            {({ measureRef }) =>
                <div className="apBox" id="workhourSettings" ref={ measureRef }>
                    <div className="apBoxHeader">
                        <h1>Työajan asetukset</h1>
                        <p>TODO: teksti tähän </p>
                    </div>

                    <ApTabs>
                    	<ApTab icon="cogs" label="Yleiset" badge={ tabBadges.general }>

                			<div className="clear">
                				<div className="apColumn w50">
                					<div className="padding">
                						
                						<h4 className="formHeader">Kulujen budjetointi</h4>

                						<p>Jotta työajanseurannan korvauksia voidaan budjetoida projekteille, täytyy korvaukset yhdistää varastonimikkeisiin. </p>

                						<ApInputStack gap="0">
                							<ApAddon width="40px" noRightBorder>
                								<SvgIcon className="small-inline" icon="car" type="solid" />
                							</ApAddon>
	                						<ApSelect
	                                            label="Kilometrikorvaus"
	                                            loading={ this.state.loading }
	                                            value={ keyExists( this.state.settings, "travel_component", true, "" )  }
	                                            optionRenderer="component"
	                                            onChange={ ( component ) => this.handleChange( "travel_component", component ) }
	                                            objKeyId="id"
	                                            objKeyValue="name"
	                                            apiUrl="storage/components"
	                                            apiData={{ 
	                                                type: 3 // other
	                                            }}
	                                            validationState={ !keyExists( this.state.settings, 'travel_component.name' ) ? "warning" : "" }
	                                        	clearable
	                                        />
                                        </ApInputStack>

                                        <ApInputStack gap="0">
                							<ApAddon width="40px" noRightBorder>
                								<SvgIcon className="small-inline" icon="receipt" type="solid" />
                							</ApAddon>
	                                        <ApSelect
	                                            label="Kulukorvaus"
	                                            loading={ this.state.loading }
	                                            value={ keyExists( this.state.settings, "expense_component", true, "" )  }
	                                            optionRenderer="component"
	                                            onChange={ ( component ) => this.handleChange( "expense_component", component ) }
	                                            objKeyId="id"
	                                            objKeyValue="name"
	                                            apiUrl="storage/components"
	                                            apiData={{ 
	                                                type: 3 // other
	                                            }}
                                                validationState={ !keyExists( this.state.settings, 'expense_component.name' ) ? "warning" : "" }
	                                        	clearable
	                                        />
                                        </ApInputStack>

                                        <ApInputStack gap="0">
                							<ApAddon width="40px" noRightBorder>
                								<SvgIcon className="small-inline" icon="suitcase" type="solid" />
                							</ApAddon>
	                                        <ApSelect
	                                            label="Päivärahakorvaus"
	                                            loading={ this.state.loading }
	                                            value={ keyExists( this.state.settings, "allowance_component", true, "" )  }
	                                            optionRenderer="component"
	                                            onChange={ ( component ) => this.handleChange( "allowance_component", component ) }
	                                            objKeyId="id"
	                                            objKeyValue="name"
	                                            apiUrl="storage/components"
	                                            apiData={{ 
	                                                type: 3 // other
	                                            }}
                                                validationState={ !keyExists( this.state.settings, 'allowance_component.name' ) ? "warning" : "" }
	                                        	clearable
	                                        />
                                        </ApInputStack>

                                        {( !keyExists( this.state.settings, "travel_component.name" ) || 
                                           !keyExists( this.state.settings, "expense_component.name" ) ||
                                      	   !keyExists( this.state.settings, "allowance_component.name" )) && 
	                                        <div className="apInfo small warning">
					                            <SvgIcon icon="exclamation-triangle" type="solid" />
					                            Jos korvausta ei ole yhdistetty varastonimikkeeseen, niin kyseistä korvausta ei voida seurata projektien kulujen seurannassa! 
					                        </div>
					                    }

                                        <div className="apInfo small">
				                            <SvgIcon icon="info-circle" type="solid" />
				                            Mikäli ei ole tarvetta eritellä korvauskuluja, ne voidaan kaikki yhdistää samaan varastonimikkeeseen.
				                        </div>

				                        <h4 className="formHeader">Palkkalajit</h4>
                                        
                                        <ApInputStack gap="0">
                							<ApAddon width="40px" noRightBorder>
                								<SvgIcon className="small-inline" icon="receipt" type="solid" />
                							</ApAddon>
					                        <ApInput
			                                    type="text"
			                                    id="expense_salary_category"
			                                    name="expense_salary_category"
			                                    label="Kulukorvausten palkkalaji"
			                                    value={ keyExists( this.state.settings, "expense_salary_category", true ) }
			                                    onChange={ ( e ) => { this.handleChange( e.target.name, e.target.value ) } }
			                                    loading={ this.props.loading }
			                                    disabled={ this.props.loading }
                                                validationState={ !keyExists( this.state.settings, 'expense_salary_category', true ) ? "warning" : "" }
			                                />
		                                </ApInputStack>

                                        { !keyExists( this.state.settings, 'expense_salary_category', true ) &&
                                            <div className="apInfo small warning">
                                                <SvgIcon icon="exclamation-triangle" type="solid" />
                                                Jos kulukorvauksen palkkalajia ei ole asetettu, niin kulukorvauksia ei voida kirjata!
                                            </div>
                                        }

		                                <div className="apInfo small">
				                            <SvgIcon icon="info-circle" type="solid" />
				                            Käytössä oleva palkanlaskentaohjelmisto, määrittää käytettävät palkkalajit. Kilometrikorvausten ja päivärahojen palkkalajit säädetään omilla välilehdillään. 
				                        </div>

                					</div>
                				</div>
                				<div className="apColumn w50">
                					<div className="padding">
                						
                						<h4 className="formHeader">Yleiset asetukset</h4>

                						<div className={ "apFormGroup" + ( this.state.settings.confirmation_required ? activeConfirmUsers > 0 ? " success" : " warning" : "" ) }>
				                            <div className="apSwitchBlock">
				                                <label htmlFor="confirmation-required-switch" className="info">
				                                    Työaikojen vahvistaminen
				                                    <small>Esimiehen hyväksymät tuntikirjaukset tulee vielä erikseen vahvistaa ennen kuin ne siirtyvät palkanmaksuun</small>
				                                </label>
				                                <ApSwitch
				                                    id="confirmation-required-switch"
				                                    on={ keyExists( this.state.settings, "confirmation_required", true, false ) }
				                                    onChange={ () => this.handelSwitchChange('confirmation_required') }
				                                    disabled={ this.state.loading }
				                                />
				                            </div>

				                            <Collapse in={ this.state.settings.confirmation_required }>
				                                <div>
				                                    <hr />

				                                    { keyExists( this.state.settings, "confirmation_users", true, [] ).length > 0 && 
				                                    	<div className="confirmationUsers">
				                                    		<strong>Työaikojen vahvistajat:</strong>
						                                    { this.state.settings.confirmation_users.map( user => {
						                                    	return (
						                                    		<div className={ "userBlock" + ( user.is_active ? " active" : "" ) }>
						                                    			{ user.first_name + " " + user.last_name }
						                                    		</div>
						                                    	);
						                                    })}
					                                    </div>
					                                }

				                                    { activeConfirmUsers == 0 &&
					                                    <div className="apInfo warning small">
	                                                        <SvgIcon icon="exclamation-triangle" type="solid" />
	                                                        Yhtään aktiivista tuntien vahvistajaa ei valittuna. Kirjatut tunnit eivät tällä hetkellä etene palkanlaskentaan!
	                                                    </div>
	                                                }
				                                    
				                                </div>
				                            </Collapse>
				                        </div>

				                        <div className={ "apFormGroup" + ( this.state.settings.entry_customer_work_number_allowed ? " success" : "" ) }>
				                            <div className="apSwitchBlock">
				                                <label htmlFor="customer-work-number-switch" className="info">
				                                    Asiakastyönumero
				                                    <small>Asiakastyönumero tai asiakkaan viitenumero voidaan kirjata projektin lisäksi</small>
				                                </label>
				                                <ApSwitch
				                                    id="customer-work-number-switch"
				                                    on={ keyExists( this.state.settings, "entry_customer_work_number_allowed", true, false ) }
				                                    onChange={ () => this.handelSwitchChange('entry_customer_work_number_allowed') }
				                                    disabled={ this.state.loading }
				                                />
				                            </div>
				                        </div>

				          
                					</div>
                				</div>
                			</div>

                    	</ApTab>
                    	<ApTab icon="business-time" label="Tuntiluokitukset" badge={ hourtypesMissing ? "1" : false } badgeColor="red">
                    		<div className="padding">

                    			<h4 className="formHeader">Tuntiluokitukset</h4>
                    			<p>Tuntiluokitukset määrittävät mitä eri tyyppisiä työtunteja henkilöt voivat kirjata. Yleisiä tuntiluokituksia ovat mm. vuorotyö-, ylityö- ja pyhätunnit. </p>

                                { hourtypesMissing &&
                                    <div className="apErrorMsg">
                                        Vähintään yksi tuntiluokitus on pakollinen, jotta käyttäjät voivat kirjata työaikojaan
                                    </div>
                                }

                    			<ApReactTable 
                                    loading={ this.state.loading }
                                    data={ keyExists( this.state.settings , "hour_types", true, [] ) } 
                                    columns={[
                                        {
                                            Header: "Tuntiluokitukset",
                                            columns: [
                                                this.nameColumn(),
                                                { width: 150, id: 'group', Header: "Ryhmä", accessor: "group" },
                                                this.iconColumn( 'worktime', 'clock', 'Kuuluu työaikaan' ),
                                                this.iconColumn( 'overtime', 'moon', 'Kuuluu ylitöihin' ),
                                                this.iconColumn( 'flexitime', 'hourglass-half', 'Kuuluu liukumaan' ),
                                                //this.iconColumn( 'absence', 'ban', 'Poissaolo' ),
                                                { width: 150, id: 'salary', Header: "Korvaus", accessor: "salary_extra_percent", Cell: ( p ) => {
                                                    return (
                                                        <div>
                                                            { p.original.salary_extra && 
                                                                <div>{ p.original.salary_extra } &euro;</div> 
                                                            }
                                                            { p.original.salary_extra_percent && 
                                                                <div>{ p.original.salary_extra_percent } %</div> 
                                                            }
                                                            { p.original.salary_extra_per_unit && 
                                                                <div>{ p.original.salary_extra_per_unit } &euro;/h</div> 
                                                            }
                                                        </div>
                                                    );
                                                }},
                                                this.salaryCategoryColumn( 'salary_category', "Palkkalaji" ),
                                                { width: 100, id: 'usage', Header: "Käytetty", accessor: "entries_count" },
                                            ]
                                        },      
                                     ]}
                                    filterable
                                    multiselect={[
                                        { icon: "trash-alt", label: "Poista valitut", action: ( ids ) => { this.removeRows( "hour_types", ids ) }, unselectAfter: true, closeAfter: true },
                                    ]}
                                    minRows={ 5 }
                                    rowActions={ (row) => 
                                        <div className="apSimpleButton" onClick={ () => this.editRow( "hour_types", row ) }><SvgIcon icon="edit" type="solid" /></div>
                                    }
                                />
                                <ApButton size="small" color="green" onClick={ () => this.addRow( "hour_types" ) }>
                                	<SvgIcon icon="plus-square" type="solid" />
                                	Lisää tuntiluokitus
                                </ApButton>

                    		</div>
                    	</ApTab>
                    	<ApTab icon="ban" label="Poissaolot" badge={ absencesMissing ? "1" : false } badgeColor="red">
                    		<div className="padding">
                    			
                    			<h4 className="formHeader">Koko päivän poissaolot</h4>
                    			<p>Tänne listataan hyväksyttävät poissaolosyyt kokonaisille päiville. Yleisiä poissaolosyitä ovat mm. sairas- ja vuosilomat sekä äitiys- ja isyysvapaa. </p>

                                { absencesMissing &&
                                    <div className="apErrorMsg">
                                        Vähintään yksi poissaolo syy on pakollinen, jotta käyttäjät voivat kirjata työaikojaan
                                    </div>
                                }

                    			<ApReactTable 
                                    loading={ this.state.loading }
                                    data={ keyExists( this.state.settings , "absence_reasons", true, [] ) } 
                                    columns={[
                                        {
                                            Header: "Poissaolot",
                                            columns: [
                                                this.nameColumn(),
                                                { width: 150, id: 'group', Header: "Ryhmä", accessor: "group" },
                                                this.iconColumn( 'worktime', 'clock', 'Kuuluu työaikaan' ),
                                                this.iconColumn( 'public_holiday', 'couch', 'Yleinen vapaapäivä' ),
                                                this.salaryCategoryColumn( 'salary_category', "Palkkalaji" ),
                                                { width: 100, id: 'usage', Header: "Käytetty", accessor: "entries_count" }, 
                                            ]
                                        },
                                     ]}
                                    filterable
                                    multiselect={[
                                        { icon: "trash-alt", label: "Poista valitut", action: ( ids ) => { this.removeRows( "absence_reasons", ids ) }, unselectAfter: true, closeAfter: true },
                                    ]}
                                    minRows={ 5 }
                                    rowActions={ (row) => 
                                        <div className="apSimpleButton" onClick={ () => this.editRow( "absence_reasons", row ) }><SvgIcon icon="edit" type="solid" /></div>
                                    }
                                    
                                />
                                <ApButton size="small" color="green" onClick={ () => this.addRow( "absence_reasons" ) }>
                                	<SvgIcon icon="plus-square" type="solid" />
                                	Lisää poissaolo
                                </ApButton>

                    		</div>
                    	</ApTab>
                    	<ApTab icon="car" label="Km-korvaukset">
                    		<div className="padding">
                    			
                    			<h4 className="formHeader">Kilometrikorvaukset</h4>
                    			<p>Tänne listataan yrityksen kilometrikorvauksien määrät ja erityyppiset käytettävissä olevat ajoneuvot. </p>

                    			{ !keyExists( this.state.settings, "travel_component.name" ) &&
                                    <div className="apInfo small warning">
                                        <SvgIcon icon="exclamation-triangle" type="solid" />
                                        Kilometrikorvauksia ei ole yhdistetty varastonimikkeeseen <strong>Yleiset</strong> -välilehdellä, joten kuluja ei voida seurata!
                                    </div>
                                }

                    			<ApReactTable 
                                    loading={ this.state.loading }
                                    data={ keyExists( this.state.settings , "travel_vehicles", true, [] ) } 
                                    columns={[
                                        {
                                            Header: "Kilometrikorvaukset",
                                            columns: [
                                                this.nameColumn(),
                                                { width: 150, id: 'group', Header: "Ryhmä", accessor: "group" },
                                                { width: 150, align: 'center', id: 'compensation', Header: "Korvaus", accessor: "compensation", Cell: ( p ) => this.renderCurrencyCell( p, "compensation", "€/km" ) },
                                                this.salaryCategoryColumn( 'salary_category', "Palkkalaji (km)" ),
                                                this.salaryCategoryColumn( 'salary_category_hours', "Palkkalaji (h)" ),
                                                { width: 100, id: 'usage', Header: "Käytetty", accessor: "entries_count" },
                                            ]
                                        },
                                     ]}
                                    filterable
                                    multiselect={[
                                        { icon: "trash-alt", label: "Poista valitut", action: ( ids ) => { this.removeRows( "travel_vehicles", ids ) }, unselectAfter: true, closeAfter: true },

                                    ]}
                                    minRows={ 5 }
                                    rowActions={ (row) => 
                                        <div className="apSimpleButton" onClick={ () => this.editRow( "travel_vehicles", row ) }><SvgIcon icon="edit" type="solid" /></div>
                                    }
                                />
                                <ApButton size="small" color="green" onClick={ () => this.addRow( "travel_vehicles" ) }>
                                	<SvgIcon icon="plus-square" type="solid" />
                                	Lisää km-korvaus
                                </ApButton>

                    		</div>
                    	</ApTab>
                    	<ApTab icon="suitcase" label="Päivärahat">
                    		<div className="padding">
                    			
                    			<h4 className="formHeader">
                    				Päiväraha- ja ateriakorvaukset
                    				<div className="headerAddon">
	                    				<ApButton size="tiny" onClick={ this.getGlobalAllowances } disabled={ this.state.globalAllowanceLoading } loading={ this.state.globalAllowanceLoading }>
		                                    <SvgIcon icon="book" type="solid" />
		                                    <span>Tuo lakisääteiset</span>
		                                </ApButton>
	                                </div>
                    			</h4>
                    			<p>Tänne listataan hyväksyttävät työmatkakorvaukset, kuten päivärahat ja ateriakorvaukset. Voit tuoda myös verottajan määrittämät lakisääteiset maksimit, jolloin järjestelmä luo puuttuvat ja päivittää olemassa olevat rivit lakisääteisten mukaisiksi. </p>

                    			{ !keyExists( this.state.settings, "allowance_component.name" ) &&
                                    <div className="apInfo small warning">
                                        <SvgIcon icon="exclamation-triangle" type="solid" />
                                        Päivärahakuluja ei ole yhdistetty varastonimikkeeseen <strong>Yleiset</strong> -välilehdellä, joten kuluja ei voida seurata!
                                    </div>
                                }

                    			<ApReactTable 
                                    loading={ this.state.loading }
                                    data={ keyExists( this.state.settings , "daily_allowances", true, [] ) } 
                                    columns={[
                                        {
                                            Header: "Päivärahat",
                                            columns: [
                                                this.nameColumn(),
                                                { width: 150, id: 'group', Header: "Ryhmä", accessor: "group" },
                                                this.iconColumn( 'global_allowance_id', 'book', 'Linkitetty lakisääteisiin', ( p ) => this.renderBooleanCell( p, ( p.original.global_allowance_id ? true : false ), false ) ),
                                                { width: 150, align: 'center', id: 'compensation', Header: "Korvaus", accessor: "compensation", Cell: ( p ) => this.renderCurrencyCell( p, "compensation", "€/pv" ) },
                                                this.salaryCategoryColumn( 'salary_category', "Palkkalaji" ),
                                                { width: 100, id: 'usage', Header: "Käytetty", accessor: "entries_count" },
                                            ]
                                        },
                                    ]}
                                    filterable
                                    multiselect={[
                                        { icon: "trash-alt", label: "Poista valitut", action: ( ids ) => { this.removeRows( "daily_allowances", ids ) }, unselectAfter: true, closeAfter: true },
                                    ]}
                                    minRows={ 5 }
                                    rowActions={ (row) => 
                                        <div className="apSimpleButton" onClick={ () => this.editRow( "daily_allowances", row ) }><SvgIcon icon="edit" type="solid" /></div>
                                    }
                                />
                                <ApButton size="small" color="green" onClick={ () => this.addRow( "daily_allowances" ) }>
                                	<SvgIcon icon="plus-square" type="solid" />
                                	Lisää päiväraha
                                </ApButton>

                    		</div>
                    	</ApTab>
                    </ApTabs>
                    
                   
                    <ApStickyBar bottomMode zIndex={10} ref={ node => this.stickyBar= node }>
                        <div className="saveBar">
                            <ApButton color="blue" onClick={ this.saveSettings } disabled={ this.state.loading || !this.state.unsavedChanges } loading={ this.state.loading }>
                                <SvgIcon icon="save" type="solid" />
                                Tallenna
                            </ApButton>
                        </div>
                    </ApStickyBar>
                    
                    <RowEdit
						show={ this.state.rowEditOpen }
						data={ this.state.rowEditData }
						type={ this.state.rowEditKey }
						onClose={ this.editRowClose }
					/>

                </div>
            }
            </Measure>
	    );		
    }
}

export default Settings;
