import React from 'react';
import autoBind from 'react-autobind';
import { debounce } from 'throttle-debounce';

import SvgIcon         from 'common/SvgIcon/SvgIcon.js';
import ApButton        from 'common/ApButton/ApButton.js';
import ApTooltip       from 'common/ApTooltip/ApTooltip.js';
import ApSelect        from 'common/ApSelect/ApSelect.js';
import { tr } from 'services/Helpers/Helpers.js';
import DeliveryList     from './DeliveryList/DeliveryList.js';
import './Deliveries.css';

class Deliveries extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            data: [],
            pages: -1,

            results: [],
            selected: '',
        };
        autoBind(this);
        this.getDeliverySelectItemsDebounced = debounce( 200, this.getDeliverySelectItems );
    }

    goToEdit( path )
    {
        window.emitter.emit('goTo', {
            pathname: `/storage/logistic/delivery/${path}`,
        });
    }

    onSelect( item, index )
    {
        this.goToEdit(`order/${ item.id }`)
        this.setState({ selected: item.number });
    }

    render()
    {
        return (
            <div className="apBox" id="pageDeliveries">
                <div className="apBoxHeader">
                    <h1>
                        { tr('logistics_deliveries') }
                    </h1>
                    <p> { tr('logistics_deliveries_info') } </p>
                </div>

                <div className="padding">

                    <div className="apForm">
                        <h3 className="apFormHeader">{ tr('new_shipment') }</h3>
                        <div className="apFormColumn fullColumn">
                            <div>
                                {/* <ApTooltip position="top" text={tr('search_order_tooltip')} block>  Kommentoitu pois koska ei ole kunnollista käännöstä vielä*/}
                                <ApTooltip position="top" text={tr('search_order')} block>
                                    <ApSelect
                                        label={ tr('search_order') }
                                        value=""
                                        optionRenderer="storage_order"
                                        onChange={ this.onSelect }
                                        objKeyId="id"
                                        apiUrl="storage/delivery/findoptions"
                                        apiData={{
                                            onlyDeliverable: true,
                                        }}
                                    />
                                </ApTooltip>
                            </div>
                            <div className="newDelivery">
                                <ApButton color="blue" size="small" onClick={ () => { this.goToEdit('new') } }>
                                    <SvgIcon icon="plus" type="solid" />
                                    { tr('shipment_without_order') }
                                </ApButton>
                            </div>
                        </div>
                    </div>

                    <div className="apForm">
                        <h3 className="apFormHeader">{ tr('previous_shipments') }</h3>
                        <div className="apFormColumn fullColumn">
                            <DeliveryList />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Deliveries;
