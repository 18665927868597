import { ApTab, ApTabs } from 'common/ApTabs/ApTabs';
import React from 'react';
import autobind from 'react-autobind';
import { tr } from 'services/Helpers/Helpers';
import WooCommerceCategories from './WoocommerceCategories';
import WooCommerceProducts from './WoocommerceProducts';
import "./WooCommerce.css";

class WooCommerce extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        autobind(this);
    }

    render() {
        return (
            <div id='woocommerce'>
                <div className='apBox'>
                    <div className='apBoxHeader'>
                        <h1>WooCommerce</h1>
                        <p>{tr('online_store_manage_info')}</p>
                    </div>
                    <ApTabs>
                        <ApTab icon="list" label={tr('categories')} mountOnSelect>
                            <WooCommerceCategories />
                        </ApTab>
                        <ApTab icon="puzzle-piece" label={tr('all_products')} mountOnSelect>
                            <WooCommerceProducts />
                        </ApTab>
                    </ApTabs>
                </div>
            </div>
        )
    }
}

export default WooCommerce;