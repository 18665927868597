import React from 'react';
import autoBind from 'react-autobind';

import { keyExists, tr }  from 'services/Helpers/Helpers.js'

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import { ApInput } from 'common/ApInput/ApInput.js';


import './RowEdit.css';

class RowEdit extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            data: null,
        }
        autoBind(this);
    }

    resetState()
    {
        this.setState({ data: this.props.data });

        // Auto focus to name field (also scrolls view up if nessessary)
        if( this.nameRef )
            this.nameRef.focus();
    }

    handleChange( key, value )
    {
        let data = { ...this.state.data };
        data[ key ] = value;
        this.setState({ data });
    }

    onWorkSelect( component )
    {
        let data = { ...this.state.data };

        if( component )
        {
            data.work_component = component;
            data.unit = component.unit;
        }
        else
        {
            data.work_component = null;
            data.unit = null;
        }

        this.setState({ data });
    }

    save()
    {
        this.props.onClose( this.state.data );
    }

    renderBody()
    {
        const isset = ( key ) => {
            if( !keyExists( this.state.data, key ) )
                return false;
            if( this.state.data[ key ] === null )
                return false;
            if( this.state.data[ key ].length === 0 )
                return false;
            return true;
        }


        let unitInput = null;
        let relatedWorkInput = null;
        let booleanInput = null;
        let calculatableSelectInput = null;

        if( this.props.instance === 'calculatables' )
        {
            unitInput = <ApInput
                type="text"
                id="unit"
                name="unit"
                label={ tr('unit') }
                value={ keyExists( this.state.data, "unit", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'unit', e.target.value ) } }
                validationState={ !isset( "unit" ) ? "error" : "" }
                disabled={ keyExists( this.state.data, 'work_component' ) && this.state.data.work_component }
            />

            relatedWorkInput = <ApSelect
                label={ tr('related_work_component') }
                optionRenderer="component"
                value={ keyExists( this.state.data, "work_component", true, '' ) }
                onChange={ ( component ) => this.onWorkSelect( component ) }
                objKeyId="id"
                objKeyValue="name"
                apiUrl="storage/components"
                apiData={{
                    type: 2 // work
                }}
                clearable
            />

        }

        if( this.props.instance === 'properties' )
        {
            if( !keyExists( this.state.data, "is_boolean", true, false ) )
            {
                unitInput = <ApInput
                    type="text"
                    id="unit"
                    name="unit"
                    label={ tr('unit') }
                    value={ keyExists( this.state.data, "unit", true, '' ) }
                    onChange={ ( e ) => { this.handleChange( 'unit', e.target.value ) } }
                    disabled={ keyExists( this.state.data, 'work_component' ) && this.state.data.work_component }
                />
            }

            booleanInput = <div className="apSwitchBlock">
                <label htmlFor="cre-boolean-input" className="info">
                    { `${tr('yes')} / ${tr('no')}` }
                    <small>{ tr('property_info') }</small>
                </label>
                <ApSwitch
                    small
                    id="cre-boolean-input"
                    disabled={ this.state.loading || this.state.locked }
                    on={ keyExists( this.state.data, "is_boolean", true, false ) }
                    onChange={ ( e ) => { this.handleChange( 'is_boolean', e.target.checked ) } }
                />
            </div>

        }

        if( this.props.instance === 'trackingunits') 
        {
            unitInput = <ApInput
                type="text"
                id="unit"
                name="unit"
                label={ tr('unit') }
                value={ keyExists( this.state.data, "unit", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'unit', e.target.value ) } }
                disabled={ keyExists( this.state.data, 'work_component' ) && this.state.data.work_component }
            />
        }

        if (this.props.instance === 'extraCalculatables') {
            unitInput = <ApInput
                type="text"
                id="unit"
                name="unit"
                label={ tr('unit') }
                value={ keyExists( this.state.data, "unit", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'unit', e.target.value ) } }
                validationState={ !isset( "unit" ) ? "error" : "" }
                disabled={ keyExists( this.state.data, 'work_component' ) && this.state.data.work_component }
            />
            calculatableSelectInput = (
                <>
                    <ApSelect
                        options={this.props.rows}
                        optionRenderer={item => <div>{item.name}</div>}
                        valueRenderer={ ( item ) => <div>{item.name}</div>}
                        label={ tr('meter') + ' 1' }
                        value={ keyExists(this.state.data, 'calculatable_1', true, '') }
                        onChange={ ( item ) => this.handleChange( 'calculatable_1', item ) }
                        // onChange={ ( item ) => console.log(item) }
                        objKeyId="id"
                        objKeyValue="name"
                        objKeySearchable='name'
                        validationState={!isset( "calculatable_1" ) ? "error" : ""}
                    />
                    <ApSelect
                        options={['/', '*', '+', '-']}
                        label={tr('operator')}
                        onChange={ ( item ) => this.handleChange( 'operator', item ) }
                        value={ keyExists(this.state.data, 'operator', true, '') }
                        validationState={!isset( "operator" ) ? "error" : ""}
                    />
                    <ApSelect
                        options={this.props.rows}
                        optionRenderer={item => <div>{item.name}</div>}
                        valueRenderer={ ( item ) => <div>{item.name}</div>}
                        label={ tr('meter') + ' 2' }
                        value={ keyExists(this.state.data, 'calculatable_2', true, '') }
                        onChange={ ( item ) => this.handleChange( 'calculatable_2', item ) }
                        // onChange={ ( item ) => console.log(item) }
                        objKeyId="id"
                        objKeyValue="name"
                        objKeySearchable='name'
                        validationState={!isset( "calculatable_2" ) ? "error" : ""}
                    />
                </>
            )
        }

        return <div className="padding">
            <ApInput
                type="text"
                id="name"
                name="name"
                label={ tr('name') }
                value={ keyExists( this.state.data, "name", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'name', e.target.value ) } }
                validationState={ !isset( "name" ) ? "error" : "" }
                inputRef={ ref => this.nameRef = ref }
            />
            <ApInput
                type="textarea"
                id="description"
                name="description"
                label={ tr('description') }
                value={ keyExists( this.state.data, "description", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'description', e.target.value ) } }
            />
            { booleanInput }
            { unitInput }
            { relatedWorkInput }
            { calculatableSelectInput }
        </div>
    }

    render()
    {
        let saveDisabled = false;
        if (this.props.instance === 'calculatables'
        && ( !keyExists(this.state.data, 'name', true) || !keyExists(this.state.data, 'unit', true) ) ) {
            saveDisabled = true;
        }
        if (this.props.instance === 'types'
            && (!keyExists(this.state.data, 'name', true) )) {
            saveDisabled = true;
        }
        if (this.props.instance === 'extraCalculatables'
        && ( !keyExists(this.state.data, 'name', true) 
            || !keyExists(this.state.data, 'unit', true)
            || !keyExists(this.state.data, 'calculatable_1', true)
            || !keyExists(this.state.data, 'calculatable_2', true)
            || !keyExists(this.state.data, 'operator', true)
        )) {
            saveDisabled = true;
        }
        let headerName = null;
        if( this.props.instance === 'identifiers' ) headerName = tr('identifier');
        if( this.props.instance === 'properties' ) headerName = tr('property');
        if( this.props.instance === 'calculatables' ) headerName = tr('meter');
        if( this.props.instance === 'trackingunits' ) headerName = tr('monitoring_unit');
        if( this.props.instance === 'extraCalculatables' ) headerName = tr('extra_meter');
        if( this.props.instance === 'types' ) headerName = tr('type')
        

        return (
            <div id="StorageSettingRowEdit">
            <ApModal
                show={ this.props.show }
                onOpen={ this.resetState }
                handleClose={ () => this.props.onClose( false) }
                //closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            { Boolean( this.props.data ) && this.props.data.id ? tr('edit') : tr('new') } ({ headerName })
                        </h4>
                    </div>
                }
                body={
                    <div id="StorageSettingsRowEditBody">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="padding" id="StorageSettingsRowEditFooter">
                        <ApButton className="cancel" onClick={ () => this.props.onClose( false ) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ this.save } disabled={ this.state.loading || saveDisabled }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('continue') }
                        </ApButton>
                    </div>
                }
            />
        </div>
        )

    }

}

export default RowEdit;
