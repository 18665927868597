/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import { keyExists, tr } from 'services/Helpers/Helpers.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import {
    ApInput,
    ApInputStack,
    ApAddon
} from 'common/ApInput/ApInput.js';
import ApRadioSwitch from 'common/ApRadioSwitch/ApRadioSwitch.js';

import {
    Container, Row, Col
} from 'react-bootstrap';




export default class TimeAttendanceSetting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.newCounter = 0;

        autoBind(this);
    }

    addDevice() {
        let devices = this.props.devices.slice(0);

        this.newCounter++;
        devices.push({
            id: `new${this.newCounter}`,
            name: null,
            active: true,
            project_id: null,
            work_id: null,
            timer_time_attendance: false,
            over_max_distance_enabled: false,
        });
        this.props.handleChange('timeAttendanceDevices', devices);
    }

    handleTimerTimeAttendanceRequired(value) {
        const company = this.props.company;
        company.timer_time_attendance_required = value;
        this.props.handleChange('company', company);
    }

    editDevice(row, data) {
        let devices = this.props.devices;

        devices = devices.map(n => {
            if (n.id === row.id)
                n = { ...n, ...data };
            return n;
        })
        // console.log(data);
        this.props.handleChange('timeAttendanceDevices', devices);
    }

    removeDevice(row) {
        this.editDevice(row, { delete: true });

    }



    renderRow(row) {

        if (row.delete) {
            return null;
        }
        // Type, Day
        let sendTime = <div className="apInfo small">
            <SvgIcon icon="info-circle" type="solid" />
            {tr('time_tracking_notifications_settings_info3')}</div>;


        return <div className="notificationRow" key={row.id}>

            <div className="clear">

                <div className="apColumn w50">

                    <ApSwitch
                        id={`activeswitch_${row.id}`}
                        on={row.active}
                        onChange={(v) => this.editDevice(row, { active: !row.active })}
                        disabled={this.props.loading}
                        title={tr('active')}
                    />
                    
                    <ApSwitch
                        id={`timer_time_attendanceswitch_${row.id}`}
                        on={row.timer_time_attendance}
                        onChange={(v) => this.editDevice(row, { timer_time_attendance: !row.timer_time_attendance })}
                        disabled={this.props.loading}
                        title={tr('timer_time_attendance')}
                    />
                    {row.timer_time_attendance 
                    ? <>
                        <ApSwitch 
                            id={`over_max_distance_enabledswitch_${row.id}`}
                            on={row.over_max_distance_enabled}
                            onChange={(v) => this.editDevice(row, { over_max_distance_enabled: !row.over_max_distance_enabled })}
                            disabled={this.props.loading}
                            title={tr('over_max_distance_enabled')}
                        />
                        <div className='apInfo small'>
                            <SvgIcon icon="info-circle" type="solid" /> {tr('over_max_distance_enabled_info')}
                        </div>
                    </>
                    : null}

                </div>
                <div className="apColumn w50">
                    <div className="text-right">
                        <ApButton color="red" size="small" onClick={() => this.removeDevice(row)}>
                            <SvgIcon icon="trash" type="solid" />
                            {tr('delete')}
                        </ApButton>
                    </div>
                </div>
            </div>
            <ApInput
                type="name"
                id="name"
                name="name"
                label={tr('name')}
                value={row.name ? row.name : ''}
                onChange={(e) => this.editDevice(row, { name: e.target.value })}
                loading={this.props.loading}
                disabled={this.props.loading}
                validationState={row.name ? null : 'error'}
            />
            <small>
                <SvgIcon icon='info-circle' type='solid' className='size-small' />
                {tr('time_attendance_generate_key_info')}
            </small>
            <pre>{row.token ? row.token : "---"}</pre>

            <div className="clear">
                <div className="apColumn w33">
                    <ApSelect
                        label={tr('select_project')}
                        disabled={this.props.loading}
                        value={row.project ? row.project.name + ": " + row.project.project_code : ""}
                        optionRenderer="project_assignment"
                        onChange={(e) => this.editDevice(row, { project: e, project_id: e.id, work: null, work_id: null, hour_type: null, hour_type_id: null })}
                        objKeyId="id"
                        clearable
                        request_time={true}
                        apiUrl="timetracking/projects"
                        apiData={{
                            date: moment().format('YYYY-MM-DD'),
                            //exlude: [].map( p => p.id ),
                        }}
                    />
                </div>
                <div className="apColumn w33">

                    <ApSelect
                        label={tr('work')}
                        loading={this.props.loading}
                        disabled={this.props.loading || !row.project}
                        value={row.work ? row.work.name : ""}
                        onChange={(v) => this.editDevice(row, { work: v, work_id: v.id, hour_type: null, hour_type_id: null })}
                        options={row.project ? row.project.works : []}
                        clearable
                        optionRenderer="work"
                        objKeyId="id"
                        objKeySearchable="name"
                        objKeyValue="name"
                    //validationState={row.send_type ? null : 'error'}
                    />
                </div>
                <div className="apColumn w33">

                    <ApSelect
                        label={tr('hour_type')}
                        loading={this.props.loading}
                        disabled={this.props.loading || !row.work}
                        value={row.hour_type ? row.hour_type.name : ""}
                        onChange={(v) => this.editDevice(row, { hour_type: v, hour_type_id: v.id })}
                        options={this.props.hourTypes || []}
                        clearable
                        optionRenderer="hour_type"
                        objKeyId="id"
                        objKeySearchable="name"
                        objKeyValue="name"
                    //validationState={row.send_type ? null : 'error'}
                    />
                </div>

            </div>



        </div>
    }

    handleCompanyChange(option, type) {
        let company = this.props.company;
        company[type] = option;
        this.props.handleChange("company", company);
        //this.setState({ [type]: option, hasChanges: true })
    }
    

    getRelatedRadioSwitch = (props) => {
        if (props.disabled && this.state.settings.project_storage_enabled === false) return null

        return <div className='apSwitchBlock'>
            <label style={{ display: 'flex', flexDirection: 'column', marginTop: '1em' }}>
                {props.title}
                <small> {props.sTitle}</small>
            </label>

            <ApRadioSwitch
                options={props.options}
                checked={this.props.company[props.type]}
                onClick={(e) => { this.handleCompanyChange(e, props.type) }}
            />
        </div>
    }

    render() {
        const timerTimeAttendanceRequired = keyExists(this.props, 'company.timer_time_attendance_required', true, false);

        return <div className="padding">
            <h3>{tr('time_attendance')}</h3>
            <p>
                {tr('time_attendance_settings')}
            </p>

            <Container fluid={true} className="padding">

                <Row>
                    <Col sm={6}>
                        {this.getRelatedRadioSwitch({
                            related: 'codereader',
                            type: 'time_attendance_save_point_mode',
                            title: tr('type_of_time_attendance'),
                            sTitle: tr('timetracking_mode_in_time_attendance'),
                            options: [
                                tr('only_time_attendance_time_points'),
                                tr('fill_missing_time_tracking_entrys'),
                                tr('fill_missing_time_tracking_entrys_between_save_points'),
                                tr('use_clock')
                            ],

                        })}
                    </Col>
                    <Col sm={6}>
                        <label style={{ display: 'flex', flexDirection: 'column', marginTop: '1em' }}>
                            {tr("time_attendance_max_time")}
                            <small>{tr("time_attendance_time_limit_info")}</small>    
                        </label>
                        <ApInputStack gap="0">
                            <ApInput
                                type="number"
                                label={tr('time_in_hours')}
                                //className="text-right"
                                value={this.props.company.time_attendance_time_limit ? this.props.company.time_attendance_time_limit / 60 : 0}

                                onChange={(e) => this.handleCompanyChange(e.target.value * 60, "time_attendance_time_limit")}
                            //onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                            />
                        <ApAddon noLeftBorder width="25px">
                            h
                        </ApAddon>
                    </ApInputStack>
                        
                    </Col>
                    <Col sm={6}>
                        <label style={{ display: 'flex', flexDirection: 'column', marginTop: '1em', }}>
                            {tr("lunch_break")}
                            <small>{tr("lunch_break_length_info")}</small>
                        </label>
                        <ApInputStack gap="0">
                            <ApInput
                                type="number"
                                label={tr('lunch_break_length')}
                                //className="text-right"
                                value={this.props.company.time_attendance_lunch_break ? this.props.company.time_attendance_lunch_break : 0}

                                onChange={(e) => this.handleCompanyChange(e.target.value, "time_attendance_lunch_break")}
                            //onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                            />
                            <ApAddon noLeftBorder width="25px">
                                 min
                            </ApAddon>
                        </ApInputStack>

                    </Col>
                    <Col sm={4}>
                        <label style={{ display: 'flex', flexDirection: 'column', marginTop: '1em' }}>
                            {tr("lunch_break_min_time")}
                            <small>{tr("time_when_accept_lunch_break_info")}</small>
                        </label>
                        <ApInputStack gap="0">
                            <ApInput
                                type="number"
                                label={tr('lunch_break_length')}
                                //className="text-right"
                                value={this.props.company.time_attendance_min_time_accept_lunch_break ? this.props.company.time_attendance_min_time_accept_lunch_break/60 : 0}

                                onChange={(e) => this.handleCompanyChange(e.target.value * 60, "time_attendance_min_time_accept_lunch_break")}
                            //onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                            />
                            <ApAddon noLeftBorder width="25px">
                                h
                            </ApAddon>
                        </ApInputStack>

                    </Col>
                    <Col sm={2}></Col>
                    <Col sm={6}>
                        <label style={{ display: 'flex', flexDirection: 'column', marginTop: '1em' }}>
                            {tr("warning_time")}
                            <small>{tr("warning_time_in_approval_or_confirm")}</small>
                        </label>
                        <ApInputStack gap="0">
                            <ApInput
                                type="number"
                                label={tr('allowed_difference')}
                                //className="text-right"
                                value={this.props.company.time_attendance_warning_time }
                                
                                onChange={(e) => this.handleCompanyChange(e.target.value, "time_attendance_warning_time")}
                            //onBlur={(e) => this.changeAllProfitPercent(e.target.value, true)}
                            />
                            <ApAddon noLeftBorder width="25px">
                                min
                            </ApAddon>
                        </ApInputStack>

                    </Col>
                    
                    
                </Row>
            </Container>

            <div className={"apFormGroup" + (timerTimeAttendanceRequired ? " success" : "")}>
                <div className="apSwitchBlock">
                    <label htmlFor="timer_time_attendance_required" className="info">
                        {tr('timer_time_attendance_required')}
                        <small>{tr('timer_time_attendance_required_info')}</small>
                    </label>
                    <ApSwitch
                        id="timer_time_attendance_required"
                        on={timerTimeAttendanceRequired}
                        onChange={() => this.handleTimerTimeAttendanceRequired(!timerTimeAttendanceRequired)}
                        disabled={this.props.loading}
                    />
                </div>
            </div>

            {/* <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('time_tracking_notifications_settings_info2') }
            </div> */}
            
            <small>
                <SvgIcon icon='info-circle' type='solid' className='size-small' />
                {tr('address_to_timeattendance_web')}<br/>
                {window.location.hostname + "/timeattendance/{" + tr("device_key")+"}"}
            </small>
            <br />
            <ApButton color="white" size="small" onClick={this.addDevice} style={{marginTop:"5px"}}>
                <SvgIcon icon="plus-circle" type="solid" />
                {tr('add_time_attendance_device')}
            </ApButton>
            

            {this.props.devices.map(n => this.renderRow(n))}


        </div>
    }

}
