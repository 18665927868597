import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import './CompanySettings.css';

import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import ApButton         from 'common/ApButton/ApButton.js';
import ApProgressbar    from 'common/ApProgressbar/ApProgressbar.js';
import ApModal          from 'common/ApModal/ApModal.js';

import ApImageUpload    from 'common/ApImageUpload/ApImageUpload.js';

import { ApInput,
         ApInputStack,
         ApAddon } from 'common/ApInput/ApInput.js';

import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import { Collapse } from 'react-bootstrap';

import ApValidate       from 'services/ApValidate/ApValidate.js';
import { keyExists, tr }    from 'services/Helpers/Helpers.js';

import {
    FormGroup,
    FormControl,
} from 'react-bootstrap';
import { connect } from 'react-redux';

class CompanySettings extends React.Component {

    constructor(props) 
    {
        super(props);
		this.state = {
			loading: false,
			settings: false,
			netvisor: false,
			fivaldi: false,
		};
        this.validator = new ApValidate( this, {
            "settings.workhour.confirmation_users": {
                filter: ( value ) => {
                    if( this.state.settings && !this.state.settings.workhour.confirmation_required ) return true;
                    return( value && value.length > 0 ? true : false );
                },
                state: 'warning', 
                textAll: tr('working_hour_confirmors_missing') 
            },
            "settings.workhour.entry_travel_component": {
                filter: ( value ) => { 
                    if( this.state.settings && !this.state.settings.workhour.entry_travel_allowed ) return true;
                    return ( value && value.id ? true : false ); 
                }, 
                state: 'warning', 
                textAll: tr('travel_storage_component_missing')
            },
            "settings.workhour.expense_salary_category": { 
                filter: ( value ) => { 
                    if( this.state.settings && !this.state.settings.workhour.entry_expense_allowed ) return true;
                    return ( value && value.length > 0 ? true : false ); 
                }, 
                state: 'warning', 
                textAll: tr('salary_type_of_expense_compensation_missing')
            },
        });

        autoBind(this); 
    }

    componentDidMount()
    {
        this.getCompanySettings();
    }

    getCompanySettings()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'company/settings',
        }).then(( response ) => {
            // console.log(response)
            this.setState({ 
                settings: response,
                loading: false 
            });
            this.props.saveCompanySettings(response);
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    saveSettings() 
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'company/settings/save',
            data: this.state.settings,
        }).then(( response ) => {

            auth.reloadSettings();

            this.setState({ 
                loading: false 
            });
            
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }
	
	testNetvisorConnection()
	{
		this.setState({ loading: false });
		api({
            method: 'get',
            url: 'company/connection',
			data: this.state.netvisor,
        }).then(( response ) => {
            this.setState({ 
                connection: response,
                loading: false 
            });
			
			if (response.ResponseStatus.Status=='OK') {
				var AccountingPeriod = "";
			
				if (response.AccountingPeriodList.Period.BeginDate == null && response.AccountingPeriodList.Period.EndDate == null) {
					AccountingPeriod = 'Ensimmäinen tilikautesi on tai oli: ' + response.AccountingPeriodList.Period[0].BeginDate + '-' + response.AccountingPeriodList.Period[0].EndDate;
				} else if (response.AccountingPeriodList.Period.BeginDate != null && response.AccountingPeriodList.Period.EndDate != null) {
					AccountingPeriod = "Sinulla on vain yksi tilikausi Visma Netvisorissa, joka on: " + response.AccountingPeriodList.Period.BeginDate + '-' + response.AccountingPeriodList.Period.EndDate;
				} else {
					AccountingPeriod = 'Yritimme hakea tilikausia mutta sinulle ei niitä vielä ole Visma Netvisoriin syötettynä.';
				}
				alert('Yhteys tomii. ' + AccountingPeriod);
			} else if (response.ResponseStatus.Status=='FAILED,AUTHENTICATION_FAILED :: Yritystä ei löydy tai siihen ei ole oikeutta. Katso dokumentaatio') {
				alert('Y-tunnnus on väärin, eli olet syöttänyt vahingossa yrityksesi Y-tunnuksen väärin.');
			} else if (response.ResponseStatus.Status=='FAILED,AUTHENTICATION_FAILED :: Integraatioavaimet eivät kelpaa. Ole hyvä ja tarkista käyttäjä- ja kumppanikohtaiset avaimet.') {
				alert('Integraatioavaimet ovat väärin, eli olet syöttänyt joko oman Visma Netvisor -käyttäjätunnuksen tai API-avaimen väärin henkilöstöhallinta-moduulissa. Tai sitten Integraation tekiäyritys on laittanut omat avimensa väärin, jolloin sinun on otettava viipymättä yhteyttä AmiPro Service Oy:n ylläpitoon, mutta tarkista ensin omat asetuksesi henkilöstöhallinta-moduulista, että ne ovat oikein ennen kuin otat yhteyttä AmiPro Service Oy:n ylläpitoon.');
			} else if (response.ResponseStatus.Status[1].includes('AUTHENTICATION_FAILED :: URI-metodi ei kelpaa, katso dokumentaatio.')) {
				alert('Jäjstelmän kehittäjä on syöttänyt mahdollisesti oman API-avaimen väärin joten ota yhteyttä AmiPro Service Oy:n ylläpitoon välittömästi.');
			} else if (response.ResponseStatus.Status[1].includes('REQUEST_NOT_UNIQUE')) {
				alert('Aineiston yksilöivä tunnus on jo käytetty.');
			} else if (response.ResponseStatus.Status[1].includes('SERVICE_ACCESS_ERROR')) {
				alert('Ei käyttöoikeutta tai sovellus, jonka materiaalia yritetään tuoda/noutaa, ei ole käytössä. Ota yhteys välittömästi AmiPro Sevice Oy:n ylläpitoon, että mahdollistavat tämän toiminnin toimisen.');
			} else if (response.ResponseStatus.Status[1].includes('SYSTEM_MAINTANANCE')) {
				alert('Järjestelmässä Visma Netvisor on huoltokatko. Huoltokatkon aikana rajapintapyyntöjä ei voi lähettää. Odota, että huoltokatko on ohi.');
			} else if (response.ResponseStatus.Status[1].includes('TECHNICAL_ERROR')) {
				alert('Muusta teknisestä virheestä sanoman käsittelyssä tapahtunut virhe. Palautesanomassa annetaan vain virhekategoria ja virheen yksilöivä tunniste ilman tarkempaa selitettä. Tekniset virheet myös lokitetaan, jolloin virhetilanteiden selvitteleminen yksilöivällä tunnisteella on mahdollista kumppanituen ja ylläpidon toimesta jälkikäteen.');
			} else if (response.ResponseStatus.Status=='FAILED,AUTHENTICATION_FAILED') {
				alert('Virhe! Paina ensin tallenna kuin kokeilet yhteyttä!');
			} else {
				alert('Tuntematon virhe: Ota yhteys Ampro Service Oy:n tekniseen tukeen. Tämä voi olla mm. bugi EASY-PRO-järjestelmässä. Esim. Esim. AmiPro Service Oy:n ylläpito on syöttänyt asetuksiinsa omat Netvisot ISV -tunnisteet väärin. Ota yhteyttä välittömästi järjestelmän kehittäjän ylläpitoon.');
			}
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
	}

	testFivaldiConnection()
	{
		this.setState({ loading: false });
		api({
            method: 'get',
            url: 'company/connection',
			data: this.state.fivaldi,
        }).then(( response ) => {
            this.setState({ 
                connection: response,
                loading: false 
            });
			
			if (response=='WSDL') {
				alert('WSDL-virhe: Ei ehkä oikeuksia noutaa ja/tai tuoda dataa Visma Fivaldiin!');
			} else {
				alert('Joku muu virhe: ' + response);
			}
			
		}).catch( ( error ) => {
            this.setState({ loading: false });
        });
	}
	
    handleChange( key, value )
    {
        let settings = { ...this.state.settings };
        settings[ key ] = value;
        this.setState({ settings });
    }

   

    onLogoChange( file )
    {
        let settings = { ...this.state.settings };
        settings.logo_file_id = file.id;
        this.setState({ settings });
    }

    onLogoClear() 
    {
        let settings = { ...this.state.settings };
        settings.logo_file_id = null;
        this.setState({ settings }); 
    }

    render() 
    {

        const getRelatedSwitch = ( args ) => {
            const sName = `${ args.related }_${ args.type }`;
            return <div className="apSwitchBlock">
                <label htmlFor={`${sName}-switch`} className="info">
                    { args.title }
                    <small> { args.sTitle }</small>
                </label>
                <ApSwitch
                    id={`${sName}-switch`}
                    on={ !!this.state.settings[ sName ] }
                    onChange={(e) => {
                        const settings = {...this.state.settings}
                        settings[sName] = !settings[sName]
                        this.setState({settings: settings})
                    }}
                    disabled={ this.props.loading }
                />
            </div>
        }

        let userCount = keyExists( this.state.settings, "user_count", true, "-" );
        let userLimit = keyExists( this.state.settings, "user_limit", true );
		let testConnectionDisabled = keyExists( this.state.settings, "integrated_software", false, true );
		let testFivaldiConnectionDisabled = keyExists( this.state.settings, "integrated_software", false, true );
		if (this.state.settings.integrated_software=='none') {
			testConnectionDisabled=true;
			testFivaldiConnectionDisabled=true;
		} else if (this.state.settings.integrated_software=='netvisor') {
			testConnectionDisabled=false;
			testFivaldiConnectionDisabled=true;
		} else if (this.state.settings.integrated_software=='fivaldi') {
			testConnectionDisabled=true;
			testFivaldiConnectionDisabled=false;	
		} else if (this.state.settings.integrated_software=='procountor') {
			testConnectionDisabled=true;
			testFivaldiConnectionDisabled=true;
		} else {
			testConnectionDisabled=true;
			testFivaldiConnectionDisabled=true;
		}

        return (
            <div className="apBox" id="pageCompanySettings">
                <div className="apBoxHeader">
                    <h1>{ tr('company_settings') }</h1>
                </div>

                
                <div className="clear">
                    <div className="apColumn w50">
                        <div className="padding">

                            <h4 className="formHeader">{ tr('company_basic_info') }</h4>
                            <ApInput
							    id="name"
                                type="text"
                                name="name"
                                label={ tr('company_name') }
                                value={ keyExists( this.state.settings, "name", true, '' ) }
                                readOnly
                            />

                            <ApInput
							    id="suffix"
                                type="text"
                                name="suffix"
                                label={ tr('company_login_suffix') }
                                value={ keyExists( this.state.settings, "suffix", true, '' ) }
                                readOnly
                            />

                            <ApInputStack>
                                <ApInput
								    id="currency"
                                    type="text"
                                    name="currency"
                                    label={ tr('company_currency') }
                                    value={ keyExists( this.state.settings, "currency", true, '' ) }
                                    readOnly
                                />

                                <ApInput
								    id="users"
                                    type="text"
                                    name="users"
                                    label={ tr('number_of_users') }
                                    value={ userCount + ( userLimit ? " / " + userLimit : "" ) }
                                    readOnly
                                />
                            </ApInputStack>

                            <ApInput
							    id="business_id"
                                type="text"
                                name="business_id"
                                label="Y-tunnus"
                                value={ keyExists( this.state.settings, "business_id", true, '' ) }
                                onChange={(e) => {
                                    const settings = {...this.state.settings}
                                    settings.business_id = e.target.value
                                    this.setState({settings: settings})
                                }}
                            />

                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                { tr('company_basic_info_note') } 
                            </div>

                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                       { tr('project_storage_settings_info') }
                                </div>
                                { getRelatedSwitch({
                                    related: 'mobile',
                                    type: 'enabled',
                                    title: tr('mobile_app_enabled'),
                                    sTitle: tr('mobile_app_enabled_info'),
                                }) }
                                <Collapse in={ this.state.settings.mobile_enabled }>
                                    <div className="indentleft">
                                        { getRelatedSwitch({
                                            related: 'mobile',
                                            type: 'timetracking_save_location',
                                            title: tr('mobile_app_save_location'),
                                            sTitle: tr('mobile_app_save_location_info'),
                                        }) }
                                    </div>
                                </Collapse>

                            <div>
                            {/*
							<h4 className="formHeader">Integraatioasetukset</h4>
							<ApInput
							    id="netvisor_organisation_id"
                                type="text"
                                name="netvisor_organisation_id"
                                label="Y-tunnnus"
                                value={ keyExists( this.state.settings, "netvisor_organisation_id", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "netvisor_organisation_id", e.target.value ) }
                            />
							<ApInput
							    id="netvisor_language"
                                type="text"
                                name="netvisor_language"
                                label="Visma Netvisor -kieli"
                                value={ keyExists( this.state.settings, "netvisor_language", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "netvisor_language", e.target.value ) }
								readOnly
                            />
							<div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                Visma Netvisorin kieltä ei voi muuttaa ja se on vain Suomi.
                            </div>
							<ApInput
							    id="fivaldi_yt"
                                type="text"
                                name="fivaldi_yt"
                                label="Visma Fivaldi -yritystunnus"
                                value={ keyExists( this.state.settings, "fivaldi_yt", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "fivaldi_yt", e.target.value ) }
                            />
							<ApInput
							    id="fivaldi_un"
                                type="text"
                                name="fivaldi_un"
                                label="Visma Fivaldi -käyttäjätunnus"
                                value={ keyExists( this.state.settings, "fivaldi_un", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "fivaldi_un", e.target.value ) }
                            />
							<div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                Älä kirjoita yllä olevaa väärin sillä Visma voi estää koko tunnukset, jos kirjautumisia tulee liikaa väärillä tunnuksilla.
                            </div>
							<ApInput
							    id="fivaldi_pw"
                                type="password"
                                name="fivaldi_pw"
                                label="Visma Fivaldi -salasana"
                                value={ keyExists( this.state.settings, "fivaldi_pw", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "fivaldi_pw", e.target.value ) }
                            />
							<div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                Yllä oleva on salatussa muodossa tietokannassa. Älä kirjoita väärin sillä Visma voi estää koko tunnukset, jos kirjautumisia tulee liikaa väärillä tunnuksilla.
                            </div>
							<ApInput
							    id="fivaldi_db"
                                type="text"
                                name="fivaldi_db"
                                label="Visma Fivaldi -tietokanta"
                                value={ keyExists( this.state.settings, "fivaldi_db", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "fivaldi_db", e.target.value ) }
                            />
							<div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                Yllä oleva on joko fv001, fv002, fv003, fv005 tai fv006.
                            </div>
							<select
								className="integrated-software"
								name="integrated_software"
								value={ keyExists( this.state.settings, "integrated_software", true, 'none' ) }
								onChange={ ( e ) => this.handleChange( "integrated_software", e.target.value ) }
							>
								<option value="select" disabled>Valitse integroitu sovullus jota käytät</option>
								<option value="none">Ei integroitua sovellusta</option>
								<option value="netvisor">Visma Netvisor</option>
								<option value="fivaldi">Visma Fivaldi</option>
								<option value="procountor" disabled>Finago Procountor</option>
							</select>
							<div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                Valitse yllä ovelasta sovellusintegraatio.
                            </div>
							</div>
							<div>
							<h4 className="formHeader">Osoitetiedot</h4>
							<div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                Muunmuassa nämä tiedot menevät myyntitilauksiin.
                            </div>
							<ApInput
							    id="address"
                                type="text"
                                name="address"
                                label="Osoite"
                                value={ keyExists( this.state.settings, "address", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "address", e.target.value ) }
                            />
							<ApInput
							    id="zipcode"
                                type="text"
                                name="zipcode"
                                label="Postinumero"
                                value={ keyExists( this.state.settings, "zipcode", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "zipcode", e.target.value ) }
                            />
							<ApInput
							    id="city"
                                type="text"
                                name="city"
                                label="Postitoimipaikka"
                                value={ keyExists( this.state.settings, "city", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "city", e.target.value ) }
                            />
							<ApInput
							    id="country"
                                type="text"
                                name="country"
                                label="Maa"
                                value={ keyExists( this.state.settings, "country", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "country", e.target.value ) }
                            />
                            */}
							</div>
                        </div>
                    </div>
                    <div className="apColumn w50">
                        <div className="padding">

                            <h4 className="formHeader">{ tr('top_bar_branding') }</h4>
                            <ApImageUpload 
                                label={ tr('company_logo') }
                                description="Company logo"
                                fileId={ keyExists( this.state.settings, "logo_file_id", true, undefined ) }
                                onChange={ this.onLogoChange }
                                onClear={ this.onLogoClear }
                            />
                            
                            <ApInput
							    id="logo_bg_color"
                                type="color"
                                className='pointer'
                                name="logo_bg_color"
                                label={ tr('logo_background_color') }
                                value={ keyExists( this.state.settings, "logo_bg_color", true, "#000000" ) }
                                onChange={ ( e ) => this.handleChange( "logo_bg_color", e.target.value ) }
                                //validationState={ this.validator.getState('settings.name') }
                            />
                            <ApInput
							    id="logo_bg_color"
                                type="text"
                                name="logo_bg_color"
                                label={ tr('logo_background_color') }
                                value={ keyExists( this.state.settings, "logo_bg_color", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "logo_bg_color", e.target.value ) }
                                //validationState={ this.validator.getState('settings.name') }
                            />


                            <ApInput
							    id="top_bar_text"
                                type="text"
                                name="top_bar_text"
                                label={ tr('top_bar_title') }
                                value={ keyExists( this.state.settings, "top_bar_text", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "top_bar_text", e.target.value ) }
                                //validationState={ this.validator.getState('settings.name') }
                            />

                            <ApInput
							    id="top_bar_subtext"
                                type="text"
                                name="top_bar_subtext"
                                label={ tr('top_bar_subtitle') }
                                value={ keyExists( this.state.settings, "top_bar_subtext", true, '' ) }
                                onChange={ ( e ) => this.handleChange( "top_bar_subtext", e.target.value ) }
                                //validationState={ this.validator.getState('settings.name') }
                            />

                        </div>
                    </div>
                </div>

                <div className="padding">
                    <ApButton color="blue" onClick={ this.saveSettings } disabled={ this.state.loading } loading={ this.state.loading }>
                        <SvgIcon icon="save" type="solid" />
                        { tr('save') }
                    </ApButton>
                </div>
				
				{/*<div className="padding">
				    <ApButton color="blue" onClick={ this.testNetvisorConnection } disabled={ testConnectionDisabled } loading={ this.state.netvisor }>
                        <SvgIcon icon="tools" type="solid" />
                        Testaa Netvisor-yhteyttä
                    </ApButton>
					
				</div>
				
				<div className="padding">
				    <ApButton color="blue" onClick={ this.testFivaldiConnection } disabled={ testFivaldiConnectionDisabled } loading={ this.state.fivaldi }>
                        <SvgIcon icon="tools" type="solid" />
                        Testaa Fivaldi-yhteyttä
                    </ApButton>
					
                </div>*/}

            </div>
        );
    }
}


CompanySettings.propTypes = {
    // number:       PropTypes.number.isRequired,
    // string:       PropTypes.string,
    // multiple:     PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
    // bool:         PropTypes.bool,
};

const mapStateToProps = state => ({
	apGeneralSettings: state.apGeneralSettings
});

const mapDispatchToProps = dispatch => ({
    saveCompanySettings: (settings) => dispatch({type: 'SAVE_GENERAL_SETTINGS', payload: settings})
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettings);
