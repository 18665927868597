import React from 'react';
import autoBind from 'react-autobind';
import { hasPermissions, keyExists, tr, currentLang } from 'services/Helpers/Helpers.js';
import auth from 'services/Authed/Authed.js';
import moment from 'moment';

import SvgIcon 			from 'common/SvgIcon/SvgIcon.js';
import ApSwitch 		from 'common/ApSwitch/ApSwitch.js';
import ApImageUpload 	from 'common/ApImageUpload/ApImageUpload.js';
import { ApInput, 
		 ApInputStack, 
		 ApAddon } 		from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect';

export default class BasicInfo extends React.Component
{
	constructor( props )
	{
		super( props );
		autoBind(this); 
	}

	render()
	{
		return(
			<div>
				
				<div className="clear">
					<div className="apColumn w50">
						<div className="padding">
							
							<ApImageUpload 
	                            label={ tr('picture') }
	                            description="User image"
	                            fileId={ keyExists( this.props.data, "person_detail.image_file_id", true, undefined ) }
	                            onChange={ ( file ) => this.props.handleChange( "person_detail", "image_file_id", file.id ) }
	                            onClear={ () => this.props.handleChange( "person_detail", "image_file_id", null ) }
	                            readOnly={ !auth.hasModule( "profileimage" ) }
	                        />

							<ApInputStack>
								<ApInput
									label={ tr('first_name') }
									id="first_name"
									name="first_name"
									value={ keyExists( this.props.data, "person_detail.first_name", true ) }
									onChange={ (e) => this.props.handleChange( "person_detail", "first_name", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
								<ApInput
									label={ tr('second_name') }
									id="middle_names"
									name="middle_names"
									value={ keyExists( this.props.data, "person_detail.middle_names", true ) }
									onChange={ (e) => this.props.handleChange( "person_detail", "middle_names", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
							</ApInputStack>

							<ApInput
								label={ tr('last_name') }
								id="last_name"
								name="last_name"
								value={ keyExists( this.props.data, "person_detail.last_name", true ) }
								onChange={ (e) => this.props.handleChange( "person_detail", "last_name", e.target.value ) }
								loading={ this.props.loading }
								disabled={ this.props.loading }
							/>

						</div>
					</div>
					<div className="apColumn w50">
						<div className="padding">

							<ApInputStack>
								<ApInput
									label={ tr('date_of_birth') }
									id="birthday"
									name="birthday"
									type="date"
									value={ keyExists( this.props.data, "person_detail.birthday", true ) }
									onChange={ (e) => this.props.handleChange( "person_detail", "birthday", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
								<ApInput
									label={ tr('nin') }
									id="nin"
									name="nin"
									value={ keyExists( this.props.data, "person_detail.nin", true ) }
									onChange={ (e) => this.props.handleChange( "person_detail", "nin", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
							</ApInputStack>

							<br />

							<ApInputStack>
								<ApInput
									label={ tr('account_number') }
									id="iban"
									name="iban"
									value={ keyExists( this.props.data, "contract_detail.iban", true ) }
									onChange={ (e) => this.props.handleChange( "contract_detail", "iban", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>

								<ApInput
									width="40%"
									label="BIC"
									id="bic"
									name="bic"
									value={ keyExists( this.props.data, "contract_detail.bic", true ) }
									onChange={ (e) => this.props.handleChange( "contract_detail", "bic", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
							</ApInputStack>

							<ApInput
								label={ tr('tax_number') }
								id="taxnumber"
								name="taxnumber"
								value={ keyExists( this.props.data, "contract_detail.taxnumber", true ) }
								onChange={ (e) => this.props.handleChange( "contract_detail", "taxnumber", e.target.value ) }
								loading={ this.props.loading }
								disabled={ this.props.loading }
							/>

							<div className="apInfo small">
									<SvgIcon icon="info-circle" type="solid" />
									{ tr('tax_number_note') }
							</div>

							<ApInputStack gap="0">
								<ApAddon noRightBorder>
									{ tr('language') }
								</ApAddon>
								<ApInput
									type="select"
									id="language"
									name="language"
									value={ keyExists( this.props.data, "person_detail.client_language_id", true ) }
									options={this.props.languageOptions}
									onChange={ (e) => this.props.handleChange( "person_detail", "client_language_id", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
							</ApInputStack>

							<div className="apInfo small">
									<SvgIcon icon="info-circle" type="solid" />
									{ tr('language_note') }
							</div>

						</div>
					</div>
				</div>

				<div className="clear">
					<div className="apColumn w50">
						<div className="padding">

							<h4 className="formHeader">{ tr('contact_details') }</h4>

							<ApInput
								label={ tr('street_address') }
								id="street"
								name="street"
								value={ keyExists( this.props.data, "address.street", true ) }
								onChange={ (e) => this.props.handleChange( "address", "street", e.target.value ) }
								loading={ this.props.loading }
								disabled={ this.props.loading }
							/>

							<ApInputStack>
								<ApInput
									label={ tr('postal_code') }
									id="postal_code"
									name="postal_code"
									value={ keyExists( this.props.data, "address.postal_code", true ) }
									onChange={ (e) => this.props.handleChange( "address", "postal_code", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
								<ApInput
									label={ tr('locality') }
									id="city"
									name="city"
									value={ keyExists( this.props.data, "address.city", true ) }
									onChange={ (e) => this.props.handleChange( "address", "city", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
								<ApInput
									label={ tr('country') }
									id="country"
									name="country"
									value={ keyExists( this.props.data, "address.country", true ) }
									onChange={ (e) => this.props.handleChange( "address", "country", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
							</ApInputStack>


							<ApInputStack>
								<ApInput
									label={ tr('email_address') }
									id="email"
									name="email"
									value={ keyExists( this.props.data, "contact_info.email", true ) }
									onChange={ (e) => this.props.handleChange( "contact_info", "email", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
								<ApAddon width="150px" labelFor="public-email-switch">
	                                <ApSwitch
	                                    small
	                                    inline
	                                    id="public-email-switch"
	                                    on={ keyExists( this.props.data, "contact_info.email_public", true ) }
	                                    onChange={ (e) => this.props.handleChange( "contact_info", "email_public", e.target.checked ) }
	                                    disabled={ this.props.loading }
	                                />
	                                <span>{ tr('public') }</span>
	                            </ApAddon>
	                        </ApInputStack>

							<ApInputStack>
								<ApInput
									label={ `${tr('email_address')} (${tr('work')})` }
									id="email_2"
									name="email_2"
									value={ keyExists( this.props.data, "contact_info.email_2", true ) }
									onChange={ (e) => this.props.handleChange( "contact_info", "email_2", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
								<ApAddon width="150px" labelFor="public-email_2-switch">
	                                <ApSwitch
	                                    small
	                                    inline
	                                    id="public-email_2-switch"
	                                    on={ keyExists( this.props.data, "contact_info.email_2_public", true ) }
	                                    onChange={ (e) => this.props.handleChange( "contact_info", "email_2_public", e.target.checked ) }
	                                    disabled={ this.props.loading }
	                                />
	                                <span>{ tr('public') }</span>
	                            </ApAddon>
	                        </ApInputStack>

							<ApInputStack>
								<ApInput
									label={ tr('phone') }
									id="phone"
									name="phone"
									value={ keyExists( this.props.data, "contact_info.phone", true ) }
									onChange={ (e) => this.props.handleChange( "contact_info", "phone", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
								<ApAddon width="150px" labelFor="public-phone-switch">
	                                <ApSwitch
	                                    small
	                                    inline
	                                    id="public-phone-switch"
	                                    on={ keyExists( this.props.data, "contact_info.phone_public", true ) }
	                                    onChange={ (e) => this.props.handleChange( "contact_info", "phone_public", e.target.checked ) }
	                                    disabled={ this.props.loading }
	                                />
	                                <span>{ tr('public') }</span>
	                            </ApAddon>
	                        </ApInputStack>
							
							<ApInputStack>
								<ApInput
									label={ `${tr('phone')} (${tr('work')})` }
									id="phone_2"
									name="phone_2"
									value={ keyExists( this.props.data, "contact_info.phone_2", true ) }
									onChange={ (e) => this.props.handleChange( "contact_info", "phone_2", e.target.value ) }
									loading={ this.props.loading }
									disabled={ this.props.loading }
								/>
								<ApAddon width="150px" labelFor="public-phone_2-switch">
	                                <ApSwitch
	                                    small
	                                    inline
	                                    id="public-phone_2-switch"
	                                    on={ keyExists( this.props.data, "contact_info.phone_2_public", true ) }
	                                    onChange={ (e) => this.props.handleChange( "contact_info", "phone_2_public", e.target.checked ) }
	                                    disabled={ this.props.loading }
	                                />
	                                <span>{ tr('public') }</span>
	                            </ApAddon>
	                        </ApInputStack>

	                        <div className="apInfo small">
	                            <SvgIcon icon="info-circle" type="solid" />
	                            { keyExists( this.props.data, "contact_info.phone_public", true ) 
	                                ? tr('phone_public_note') 
	                                : tr('phone_private_note')
	                            }
	                        </div>
							<p>{tr('preferred_method_of_contact')}:</p>
							<div className="apSwitchBlock">
								<label htmlFor={`contact_by_email`} className="info">
									{tr('email')}
								</label>
								<ApSwitch
									id={`contact_by_email`}
									on={ keyExists(this.props.data, 'contact_info.contact_by_email', true, false) }
									onChange={ () => this.props.handleChange(
										'contact_info', 
										'contact_by_email',
										!keyExists(this.props.data, 'contact_info.contact_by_email', true, true)
									) }
									disabled={ this.props.loading }
								/>
							</div>
							<div className="apSwitchBlock">
								<label htmlFor={`contact_by_phone`} className="info">
									{tr('contact_by_phone')}
								</label>
								<ApSwitch
									id={`contact_by_phone`}
									on={ keyExists(this.props.data, 'contact_info.contact_by_phone', true, false) }
									onChange={ () => this.props.handleChange(
										'contact_info', 
										'contact_by_phone',
										!keyExists(this.props.data, 'contact_info.contact_by_phone', true, true)
									) }
									disabled={ this.props.loading }
								/>
							</div>
							<div className="apSwitchBlock">
								<label htmlFor={`crm_send_message_to_me`} className="info">
									{tr('send_message_to_me_creating_crm_event')}
								</label>
								<ApSwitch
									id={`crm_send_message_to_me`}
									on={keyExists(this.props.data, 'contact_info.crm_send_message_to_me', true, false)}
									onChange={() => this.props.handleChange(
										'contact_info',
										'crm_send_message_to_me',
										!keyExists(this.props.data, 'contact_info.crm_send_message_to_me', true, true)
									)}
									disabled={this.props.loading}
								/>
							</div>

						</div>
					</div>

					<div className="apColumn w50">
						<div className="padding">

							<h4 className="formHeader">{ tr('next_of_kin') }</h4>

							<ApInputStack>
	                            <ApInput
	                                label={ tr('first_name') }
	                                id="nextofkin_firstname"
	                                name="nextofkin_firstname"
	                                value={ keyExists( this.props.data, "next_of_kin.first_name", true ) }
	                                onChange={ (e) => this.props.handleChange( "next_of_kin", "first_name", e.target.value ) }
	                                loading={ this.props.loading }
									disabled={ this.props.loading }
	                            />

	                            <ApInput
	                       			label={ tr('last_name') }
	                                id="nextofkin_lastname"
	                                name="nextofkin_lastname"
	                                value={ keyExists( this.props.data, "next_of_kin.last_name", true ) }
	                                onChange={ (e) => this.props.handleChange( "next_of_kin", "last_name", e.target.value ) }
	                                loading={ this.props.loading }
									disabled={ this.props.loading }
	                            />
	                        </ApInputStack>

							<ApInput
								label={ tr('relationship') }
								id="nextofkin_relation"
								name="nextofkin_relation"
	                        	tooltip="Esimerkiksi: vaimo, avopuoliso tai isä"
								value={ keyExists( this.props.data, "next_of_kin.relation", true ) }
								onChange={ (e) => this.props.handleChange( "next_of_kin", "relation", e.target.value ) }
								loading={ this.props.loading }
								disabled={ this.props.loading }
							/>

							<ApInput
								label={ tr('phone') }
								id="nextofkin_phone"
								name="nextofkin_phone"
								value={ keyExists( this.props.data, "next_of_kin.phone", true ) }
								onChange={ (e) => this.props.handleChange( "next_of_kin", "phone", e.target.value ) }
								loading={ this.props.loading }
								disabled={ this.props.loading }
							/>
						</div>
					</div>
					
				</div>

				<div className="clear">
				{ (hasPermissions('timetracking.holiday.annualholidays') || hasPermissions('timetracking.holiday.flextime') || hasPermissions('timetracking.holiday.longerworkinghourscompensation')) &&
					<div className="apColumn w50">
						<div className="padding">
							<h4 className="formHeader">{ tr('annual_leave') }, { tr('flexitime_count') } & { tr('unpaid_leave') }</h4>
							{ hasPermissions('timetracking.holiday.annualholidays') &&
							<div>
							<strong>{ tr('annual_leave_balance') }: </strong> 
							{ keyExists( this.props.data, "holiday_balance.current_balance") 
							? 
							`${this.props.data.holiday_balance.current_balance > 0 ? `+${this.props.data.holiday_balance.current_balance}` : this.props.data.holiday_balance.current_balance} ${tr('day_abbr')} (${tr('updated_at')} ${moment(this.props.data.holiday_balance.updated_at).locale(currentLang()).format('LLL')})` 
							:
							tr('not_set')}
							</div>
							}
							{ hasPermissions('timetracking.holiday.flextime') &&
							<div>
							<strong>{ tr('flexitime_balance') }: </strong>
							{ keyExists( this.props.data, "flextime_balance.current_balance") 
							? 
							`${this.props.data.flextime_balance.current_balance > 0 ? `+${this.props.data.flextime_balance.current_balance}` : this.props.data.flextime_balance.current_balance} h (${tr('updated_at')} ${moment(this.props.data.flextime_balance.updated_at).locale(currentLang()).format('LLL')})` 
							:
							tr('not_set')}
							</div>
							}
							{ hasPermissions('timetracking.holiday.longerworkinghourscompensation') &&
							<div>
							<strong>{ tr('unpaid_leave_balance') }: </strong>
							{ keyExists( this.props.data, "longer_working_hours_compensation_balance.current_balance") 
							? 
							`${this.props.data.longer_working_hours_compensation_balance.current_balance > 0 ? `+${this.props.data.longer_working_hours_compensation_balance.current_balance}` : this.props.data.longer_working_hours_compensation_balance.current_balance} h (${tr('updated_at')} ${moment(this.props.data.longer_working_hours_compensation_balance.updated_at).locale(currentLang()).format('LLL')})` 
							:
							tr('not_set')}
							</div>
							}
						</div>
					</div>
				}
				{hasPermissions('timetracking.approval') &&
				<div className='apColumn w50'>
					<div className='padding'>
						<h4 className="formHeader">{ tr('employment_relationship') }</h4>
						<ApSelect
							label={ tr('approver_of_hours_substitute') }
							value={ keyExists( this.props, "data.contract_detail.hour_approver_substitutes", true, [] ) }
							optionRenderer="user"
							onChange={ ( items ) => this.props.handleChange( "contract_detail", "hour_approver_substitutes", items ) }
							objKeyId="id"
							clearable
							apiUrl="usermanagement/user/approvers"
							apiData={{ 
								company_id: auth.getCompanyID(),
								exludeIds: [auth.getID()], 
								is_active: true,
							}}
							loading={ this.props.loading }
							disabled={ this.props.loading }
							filterNonActives={true}
							multiselect
							valueRenderer="user"
						/>
						<div className="apInfo small">
							<SvgIcon icon="info-circle" type="solid" />
							{ tr('approver_of_hours_substitute_info') }
						</div>
					</div>
				</div>
				}
				</div>
			</div>
		);

	}

}
