import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';
import { Collapse } from 'react-bootstrap';
import { tr } from 'services/Helpers/Helpers.js';

import './BugReport.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput } from 'common/ApInput/ApInput.js';

class BugReport extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            loading:        false,
            sent:           false,
            description:    '',
        };

        autoBind(this); 
    }

    reset()
    {
        this.setState({
            loading: false,
            sent: false,
            description: '',
        });
    }

    sendReport()
    {
        const data = {
            description: this.state.description,
            url: window.location.href,
            userAgent: navigator.userAgent,
            viewport: {
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight
            },
            authSettings: auth.getSettings(),
        };

        //console.log('sending...', data );

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'bugreport/send',
            data: data,
        }).then(( response ) => {

            //console.log('bug report', response );
   
            this.setState({ 
                sent: true,
                loading: false,
            });

            setTimeout( this.reset, 5000 );
            
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    render()
    {   
        return (
            <div className="block bugs">
                <div className="button" onClick={() => { this.props.toggleModal("bugs"); } }>
                    <SvgIcon icon="exclamation-triangle" type="solid" />
                </div>
                <div className={"topModal" + (this.props.open ? " open" : "" ) }>
                    <div className="padding-small">
                        
                        <h6 className="formHeader">{ tr('report_problem') }</h6>
                        <ApInput
                            type="textarea"
                            id="bug_report_description"
                            name="bug_report_description"
                            label={ tr('problem_description') }
                            value={ this.state.description ? this.state.description : '' }
                            rows={ 5 }
                            onChange={ ( e ) => { this.setState({ description: e.target.value }) }}
                            disabled={ this.state.loading || this.state.sent }
                        />

                        <ApButton fullWidth size="small" color="red" disabled={ this.state.loading || this.state.sent || this.state.description.length === 0 } loading={ this.state.loading } onClick={ this.sendReport }>
                            <SvgIcon icon="paper-plane" type="solid" />
                            { tr('send_error_message') }
                        </ApButton>

                        <Collapse in={ this.state.sent }>
                            <div>
                                <hr />
                                <div className="apInfo success">
                                    <SvgIcon icon="check-circle" type="solid" />
                                    { tr('error_message_sent') }
                                </div>
                            </div>
                        </Collapse>
                        
                    </div>
                </div>
            </div>
        );
    }
};


export default BugReport;

