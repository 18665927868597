import ApReactTable, { colPreset } from "common/ApReactTable/ApReactTable";
import SvgIcon from "common/SvgIcon/SvgIcon";
import React from "react";
import autobind from "react-autobind";
import { errorPopper, getMinMax, tr } from "services/Helpers/Helpers";
import api from 'services/Api/Api';
import moment from "moment";
import axios from "axios";

const statusOptions = [
    { value: '', label: '' },
    { value: 'processing', label: tr('processing') },
    { value: 'on-hold', label: tr('on-hold') },
    { value: 'cancelled', label: tr('cancelled') },
    { value: 'refunded', label: tr('refunded') },
    { value: 'pending', label: tr('pending') },
    { value: 'failed', label: tr('failed') },
    { value: 'completed', label: tr('completed') },
];
class WooCommerceOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            pages: 0,

            cancelToken: axios.CancelToken.source(),
        }
        autobind(this);
    }

    componentDidMount() {
        // this.getData();
    }

    getData(state = null) {
        const data = {};
        if (state) {
            data.page = state.page;
            data.per_page = state.pageSize;
            if (state.filtered.length) {
                if (state.filtered.find(f => f.id === 'number')) {
                    data.include = state.filtered.find(f => f.id === 'number').value;
                }
                if (state.filtered.find(f => f.id === 'status')) {
                    data.status = state.filtered.find(f => f.id === 'status').value;
                }
                if (state.filtered.find(f => f.id === "billing.company")) {
                    data.search = state.filtered.find(f => f.id === 'billing.company').value;
                }
                if (state.filtered.find(f => f.id === "billing.name")) {
                    data.search = data.search 
                        ? data.search + " " +  state.filtered.find(f => f.id === 'billing.name').value
                        :state.filtered.find(f => f.id === 'billing.name').value;
                }
            }
        }

        let cancelToken = axios.CancelToken.source();
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'woocommerce/orders',
            data,
            cancelToken: cancelToken.token,
        }).then((response) => {
            // console.log('RES', response);
            this.setState({ data: response.data, pages: response.pages, loading: false, cancelToken: null });
        }).catch((error) => {
            this.setState({loading: false, cancelToken: null})
            if (axios.isCancel(error)) { return null };
            errorPopper(error, tr('get_error'));
        });
    }

    goTo(id) {
        window.emitter.emit('goTo', { pathname: `/storage/online_store/order/${id}` })
    }

    getColumns() {
        const sortedOptions = statusOptions.sort((a,b) => {
            const aLabel = a.label,
            bLabel = b.label;
            if (aLabel < bLabel) return -1;
            if (aLabel > bLabel) return 1;
            return 0;
        })
        const columns = [
            {
                Header: tr('number'),
                accessor: 'number',
                sortable: true,
                filterable: true,
                customizable: false,
                showInitially: true,
                width: 70,
                onClick: row => this.goTo(row.id),
            },
            {
                Header: tr('date'),
                accessor: 'date_created',
                Cell: props => moment(props.value).format('L'),
                sortable: true,
                filterable: false,
                customizable: false,
                showInitially: true,
                width: 120,
                onClick: row => this.goTo(row.id),
            },
            {
                Header: tr('orderer'),
                columns: [
                    {
                        Header: tr('company'),
                        accessor: 'billing.company',
                        customFilter: {
                            type: "text",
                            placeholder: tr('company'),
                        },
                        sortable: true,
                        filterable: true,
                        customizable: false,
                        showInitially: true,
                        onClick: row => this.goTo(row.id),
                    },
                    {
                        Header: tr('name'),
                        accessor: 'billing.name',
                        Cell: props => `${props.original.billing.last_name} ${props.original.billing.first_name}`,
                        customFilter: {
                            type: "text",
                            placeholder: tr('name'),
                            fields: ["billing.last_name", "billing.first_name"]
                        },
                        sortable: true,
                        filterable: true,
                        customizable: false,
                        showInitially: true,
                        onClick: row => this.goTo(row.id),
                    },
                ],
            },
            colPreset({
                type: 'currency',
                Header: tr('price'),
                accessor: 'total',
                customFilter: {
                    type: "range",
                    min: getMinMax('total', 'min', this.state.data),
                    max: getMinMax('total', 'max', this.state.data),
                },
                sortable: true,
                filterable: false,
                customizable: true,
                showInitially: true,
                width: 100,
                onClick: row => this.goTo(row.id),
            }),
            {
                Header: tr('status'),
                accessor: 'status',
                Cell: props => tr(props.value),
                customFilter: {
                    type:'select',
                    options: sortedOptions
                },
                sortable: true,
                filterable: true,
                customizable: false,
                showInitially: true,
                onClick: row => this.goTo(row.id),
            },
        ];

        return columns;
    }

    render() {
        return (
            <div id="woocommerce-orders">
                <ApReactTable
                    loading={this.state.loading}
                    data={this.state.data}
                    columns={this.getColumns()}
                    manual
                    onFetchData={(state) => {
                        this.getData(state)
                    }}
                    pages={this.state.pages}
                    rowActions={(row) =>
                        <div className="apSimpleButton" onClick={() => window.emitter.emit('goTo', { pathname: `/storage/online_store/order/${row.id}` })}>
                            <SvgIcon icon="arrow-right" type="solid" />
                        </div>
                    }
                />
            </div>
        )
    }
}

export default WooCommerceOrders;