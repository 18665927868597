import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import moment from 'moment';
import { tr, currentLang, capitalize } from 'services/Helpers/Helpers.js';

import './ApOptionInput.css';

export default class ApOptionInput extends React.Component {

    constructor( props )
    {
        super( props );
        autoBind(this); 
    } 

    renderOption( option, index )
    {
        const datePresets = [
            { name: "year", labelFormat: "YYYY" },
            { name: "month", labelFormat: "MMMM" },
            { name: "week", labelFormat: `[${capitalize(tr('week_abbr'))} ]W`},
        ];
        const dateFormat = 'YYYY-MM-DD';

        if( typeof( option ) === "string" )
        {
            const findPreset = ( value ) => {
                const index = datePresets.findIndex( p => value.startsWith( p.name ) );
                if( index != -1 )
                    return { 
                        name: datePresets[ index ].name, 
                        offset: parseInt( value.substring( datePresets[ index ].name.length ) ),
                        labelFormat: datePresets[ index ].labelFormat,
                    };
                return false;
            };

            const preset = findPreset( option );
            if( preset )
            {
                option = {
                    value: [ 
                        moment().add( preset.offset, preset.name ).startOf( preset.name ).format( dateFormat ), 
                        moment().add( preset.offset, preset.name ).endOf( preset.name ).format( dateFormat ) 
                    ],
                    label: moment().add( preset.offset, preset.name ).locale(currentLang()).format( preset.labelFormat ),
                };
            }
            else {
                option = {
                    value: option,
                    label: option,
                };
            }
        }

        let classes = [ "option" ];

        // Check if value objects (or arrays) mathces
        if( typeof( option.value ) === "object" && typeof( this.props.value ) === "object" )
        {
            if( JSON.stringify( option.value ) == JSON.stringify( this.props.value ) )
                classes.push( "selected" );
        }
        else if ( option.value === this.props.value )
        {
            classes.push( "selected" );
        }

        if( option.disabled )
            classes.push( "disabled" );

        const handleClick = () => {
            if( !this.props.disabled && !option.disabled )
                this.props.onChange( option.value );
        };

        return (
            <div key={index} className={ classes.join(" ") } onClick={ handleClick }>
                { option.label }
            </div>
        )
    }

    render()
    {
        let classes = ["apOptionBar apOptionInput"];

        if( this.props.fullWidth )
            classes.push( "fullWidth", "count-" + this.props.options.length );

        if( this.props.disabled )
            classes.push( "disabled" );

        return (
        	<div className={ classes.join(" ") }>
                { this.props.options.map( this.renderOption ) }
            </div>
        );
    }
};

ApOptionInput.propTypes = {
    value:          PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
    options:        PropTypes.array.isRequired, 
    onChange:       PropTypes.func,
    fullWidth:      PropTypes.bool,
    disabled:       PropTypes.bool,
};


