import React from 'react';
import moment from 'moment';
import autoBind from 'react-autobind';

import Measure from 'react-measure';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';

import { Collapse } from 'react-bootstrap';

import api             from 'services/Api/Api.js';
import auth            from 'services/Authed/Authed.js';

import ApTooltip       from 'common/ApTooltip/ApTooltip.js';
import SvgIcon         from 'common/SvgIcon/SvgIcon.js';
import ApValidate      from 'services/ApValidate/ApValidate.js';
import ApButton        from 'common/ApButton/ApButton.js';
import { errorPopper, tr } from 'services/Helpers/Helpers.js';

// import ComponentTable      from './ComponentTable/ComponentTable.js';
import DataEdit            from './DataEdit/DataEdit.js';
import DataSummary         from './DataSummary/DataSummary.js';

import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';

import './DeliveryEdit.css';

class DeliveryEdit extends React.Component {

    constructor(props)
    {
        super(props);

        let relatedId = null;
        let type = null;
        const params = this.props.match.params;
        if( params.id )
            relatedId = parseInt( params.id, 10 );
        if( params.type )
            type = params.type;

        this.state = {

            errorMessage: null,
            loadingCounter: 0,

            editShow: true,

            senderId: null,
            senderType: null,
            senderTitle: null,

            receiverType: null,
            receiverId: null,
            receiverTitle: null,

            receiverName: '',
            receiverAddress: '',
            receiverZipcode: '',
            receiverCity: '',
            receiverCountry: '',
            receiverContactName: '',

            originId: '',
            isReturned:'',
            referenceYour: '',
            referenceOur: '',
            comment: '',

            creatorName: auth.getName(),
            date: moment(),

            components: [],

            relatedNumber: null,
            relatedType: null,
            relatedId: relatedId,
            type: type,

        };

        // const componentsExistFilter = () => {
            // return this.state.components.some( c => {
                // // Only pickup componenets that are from current sender and check that one of
                // // them have delivery_count above 0 so we are sending atleast something
                // if( this.componentIsInCurrentSender( c ) )
                // {
                    // if( parseFloat( c.delivery_count ) > 0 )
                        // return true;
                // }
                // return false;
            // });
        // }
        //
        const componentsCountFilter = ( value, index ) => {
            return this.state.components.every( c => {

                if( !this.componentIsInCurrentSender( c ) )
                    return true;

                if( c.delivery_from_type === 'location' )
                {
                    let found = c.locations.find( l => l.id === c.delivery_from_id );
                    if( found ){
                        if(found.delivery_count===undefined && parseFloat( found.balance )==0){
                            return true
                        }
                        return ( parseFloat( found.balance ) >= parseFloat( c.delivery_count ) )
                    }
                        
                    else
                    {
                        console.error('Location not found');
                        return false;
                    }
                }
                else if( c.delivery_from_type === 'direct' )
                {
                    let balance = null;
                    c.tmp_components.find( d => {
                        let found = d.locations.find( dl =>  dl.id === this.state.senderId );
                        if( found )
                        {
                            //console.log('Found', found );
                            balance = found.balance;
                            return true;
                        }
                        return false;
                    });
                    if( balance === null )
                    {
                        console.error('Tmp location not found');
                        return false;
                    }
                    return ( parseFloat( balance ) >= parseFloat( c.delivery_count ) )
                }
                else
                {
                    console.error('Unknown from type');
                    return false;
                }
            });

            // TODO also need to check if sending from same component from same storage that the count can not go below zero
        }
        // const fromFilter = ( locationId, index ) => {
            // if( Boolean( parseFloat(this.state.components[ index ].count ) ) )
                // return Boolean( locationId );
            // return true;
        // }

        this.validator = new ApValidate(this, {
            date: {filter: 'required', textAll: tr('no_date_selected')},

            senderId:            { filter: 'required', textAll: tr('no_sender_selected')},

            receiverType:        { filter: 'required', textAll: tr('no_recipient_selected')},
            receiverId:          { filter: 'required', textAll: tr('no_recipient_selected')},
            receiverName:        { filter: 'required', textAll: tr('no_recipient_selected')},
            receiverAddress:     { filter: 'required', textAll: tr('recipient_address_missing')},
            receiverZipcode:     { filter: 'required', textAll: tr('recipient_postal_code_missing')},
            receiverCity:        { filter: 'required', textAll: tr('recipient_city_missing')},

            components_counts:   { filter: componentsCountFilter, textAll: tr('sending_quantities_error') },
            // components_needed:   { filter: componentsExistFilter, textAll: 'Ainuttakaan varastonimikettä ei valittu' },

            /*
            components: {
                filters: {
                    // id: { filter: 'required', state: 'error', textAll: 'Varastonimikkeissä virheitä'},
                    delivery_count: { filter: countFilter, state: 'error', textAll: 'Varastonimikkeiden määrissä virheitä'},
                    // from_id: { filter: fromFilter, state: 'error', textAll: 'Varastonimikkeen kohdetta ei valittu'},
                }
            },
            */
        });
        autoBind(this);
    }

    componentDidMount()
    {
        this.getRelated( this.state.type, this.state.relatedId );
    }

    getRelated( type, id )
    {
        this.setState({loadingCounter: this.state.loadingCounter + 1});

        api({
            method: 'get',
            url: 'storage/delivery/related',
            params: {
                type: type,
                related_id: id,
            },
        }).then((response) => {
            if( type === 'show' )
                this.setState({ editShow: false });

            if( response.creator_name )
                this.setState({ creatorName: response.creator_name });

            this.setState({
                number: response.number,

                senderType: response.sender_type,
                senderId:   response.sender_id,
                senderTitle: response.sender_title,

                relatedNumber:   response.related_number,
                relatedType:     response.related_type,
                relatedId:       response.related_id,               

                receiverLocked:  ( type === 'order' ),
                receiverType:    response.receiver_type,
                receiverId:      response.receiver_id,
                
                receiverTitle:   response.receiver_title,
                receiverName:    response.receiver_name,
                receiverAddress: response.receiver_address,
                receiverZipcode: response.receiver_zipcode,
                receiverCity:    response.receiver_city,
                receiverCountry: response.receiver_country,
                receiverContactName: response.receiver_contact_name,

                referenceYour: response.reference_your,
                referenceOur:  response.reference_our,
                originId:       response.origin_id,
                isReturned:     response.isReturned,
                id:       response.id,

                components: response.components,
                confirmCancel: false,
            });
            this.setState({loadingCounter: this.state.loadingCounter - 1});

        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({
                errorMessage: tr('get_error'),
            });
            this.setState({loadingCounter: this.state.loadingCounter - 1});
        });
    }

    componentIsInCurrentSender( c )
    {
        if( c.delivery_from_type === 'location' )
        {
            let found = c.locations.find( l => {
                if( l.id === c.delivery_from_id )
                    return l.delivery_locations.find( dl => this.state.senderId === dl.id );
                return false;
            });
            return Boolean( found );

        }
        else if( c.delivery_from_type === 'direct' )
        {
            let found = c.tmp_components.find( d => {
                return d.locations.find( dl =>  dl.id === this.state.senderId );
            });
            return Boolean( found );
        }
        return false;
    }

    formatComponents( )
    {
        let components = [];

        this.state.components.forEach( c => {
            if( this.componentIsInCurrentSender( c ) )
            {
                let toType = this.state.receiverType;
                let toId   = this.state.receiverId;
                if( this.state.receiverType === 'location' )
                {
                    toType = c.delivery_to_type;
                    toId   = c.delivery_to_id;
                }

                if( this.state.receiverType === 'project_storage' )
                {
                    toType = 'location';
                }

                components.push({
                    name: c.name,
                    id: c.id,
                    component_id: c.component_id,
                    delivery_count: c.delivery_count?c.delivery_count:0,
                    unit: c.unit,

                    delivery_from_type: c.delivery_from_type,
                    delivery_from_id: c.delivery_from_id,

                    delivery_to_type: toType,
                    delivery_to_id:   toId,
                    order_delivered: c.order_delivered,
                });
            }
        });

        return components;
    }

    downloadFile( file, name, type )
    {
        this.setState({loading: true });
        return api({
            method: 'get',
            url: `storage/order/id/${ this.state.relatedId }/file/${ file }/${ type }`,
            responseType: 'blob',
            errorPopper: tr('file_download_error'),
        }).then(( response ) => {
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${ name }_${ this.state.relatedNumber }.${ type }` );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    saveDelivery()
    {
        const components = this.formatComponents();

        this.setState({loadingCounter: this.state.loadingCounter + 1});

        let data = {

            related_id: this.state.relatedId,
            related_type: this.state.type,

            sender_type: this.state.senderType,
            sender_id: this.state.senderId,

            receiver_type: this.state.receiverType=='project_storage'?'location':this.state.receiverType,
            receiver_id: this.state.receiverId,

            receiver_name:  this.state.receiverName,
            receiver_address:  this.state.receiverAddress,
            receiver_zipcode:  this.state.receiverZipcode,
            receiver_city:  this.state.receiverCity,
            receiver_country:  this.state.receiverCountry,
            receiver_contactName:  this.state.receiverContactName,

            reference_your: this.state.referenceYour,
            reference_our: this.state.referenceOur,
            comment: this.state.comment,
            components: components,
        };

        api({
            method: 'post',
            url: 'storage/delivery/save',
            data: data,
        }).then((response) => {
            window.emitter.emit('goTo', {pathname: `/storage/logistic/delivery/show/${response.id}`});
            this.getRelated( 'show', response.id );
            this.setState({
                relatedId: response.id,
                type: 'show',
            });
            this.setState({loadingCounter: this.state.loadingCounter - 1});
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({loadingCounter: this.state.loadingCounter - 1});
        });

    }

    confirmCancel() {
                
        this.setState({ confirmCancel: true })
    }

    cancelDelivery()
    {
        const id = this.state.id;
        this.setState({loading:true});
       api({
           method: 'post',       
           url: `storage/delivery/cancelDelivery/${id }`,      
           errorPopper: tr('save_error'),
       }).then(( response ) => {
        this.setState({loading:false});
            window.emitter.emit('goTo', {pathname: `/storage/logistic/delivery/show/${response.id}`});
            this.getRelated( 'show', response.id );
            this.setState({
                relatedId: response.id,
                type: 'show',
            });
        
       }).catch( ( error ) => {
           this.setState({ loading: false });
       });
          
     
    
   }


    allSent()
    {
        let components = this.state.components.slice( 0 );
        components.map( ( c ) => {

            const missing = parseFloat( c.order_missing );
            if( missing != 0 )
                c.delivery_count = missing;
            else
                c.delivery_count = '0';
            return c;
        });
        this.setState({ components: components })
    }

    setRestToZero()
    {
        let components = this.state.components.slice( 0 );
        components.map(( c ) => {
            if( !c.count )
                c.count = '0';
            return c;
        });
        this.setState({ components: components })
    }

    getPdf()
    {
        //console.log('Get PDF');
    }

    dataChange( data )
    {
        if( !( this.state.type === 'order' ) && ( data.senderId || data.receiverId ) )
        {
            let components = this.state.components.slice( 0 );
            components = components.map( ( c ) => {
                c.delivery_from_type = null;
                c.delivery_from_id = null;
                c.delivery_count = null;
                c.delivery_to_type = null;
                c.delivery_to_id = null;
                return c;
            });

            this.componentsChange( components );
        }
        this.setState( data );
    }

    componentsChange( components )
    {
        this.setState({ components: components })
    }

  

    render()
    {
        if( this.state.loadingCounter > 0 )
            return <div className="apLoader"></div>;

        const getButton = ( file, title, type ) => {//console.log(this.state.relatedId);console.log(this.state.relatedNumber)
            const icon = ( type === 'docx' ) ? 'file-word' : 'file-pdf';
            return <ApButton color="white"
                loading={ this.state.loading }
                disabled={ this.state.loading }
                onClick={ () => this.downloadFile( file, title, type ) }
                size="tiny"
            >
                <SvgIcon icon={ icon } type="solid" />
            </ApButton>
        }

        let dataEdit = <Collapse in={ this.state.editShow }><div>
            <DataEdit
                summary={ true }
                onSummaryClick={ () => { this.setState({ editShow: false } )} }

                validator={ this.validator }
                onChange={ this.dataChange }

                id  ={ this.state.id }
                senderTitle={ this.state.senderTitle }
                senderId={ this.state.senderId }

                receiverLocked={ this.state.receiverLocked }
                receiverTitle={ this.state.receiverTitle }
                receiverType={ this.state.receiverType }
                receiverId={ this.state.receiverId }

                receiverName={ this.state.receiverName }
                receiverAddress={ this.state.receiverAddress }
                receiverZipcode={ this.state.receiverZipcode }
                receiverCity={ this.state.receiverCity }
                receiverCountry={ this.state.receiverCountry }
                receiverContactName={ this.state.receiverContactName }

                number={ this.state.number }
                numberReserved={ this.state.numberReserved }

                originId  ={ this.state.originId }
                isReturned  ={ this.state.isReturned }
                referenceYour={ this.state.referenceYour }
                referenceOur={ this.state.referenceOur }
                comment={ this.state.comment }
                relatedNumber = { this.state.relatedNumber }
            />
        </div></Collapse>

        let dataSummary = <Collapse in={ !this.state.editShow }><div>
            <DataSummary
                editable={ !( this.state.type === 'show' ) }
                onEditClick={ () => { this.setState({ editShow: true } )} }

                id  ={ this.state.id }
                senderTitle={ this.state.senderTitle }
                senderType={ this.state.senderType }
                senderId={ this.state.senderId }

                receiverTitle={ this.state.receiverTitle }
                receiverType={ this.state.receiverType }

                receiverName={ this.state.receiverName }
                receiverAddress={ this.state.receiverAddress }
                receiverZipcode={ this.state.receiverZipcode }
                receiverCity={ this.state.receiverCity }
                receiverCountry={ this.state.receiverCountry }
                receiverContactName={ this.state.receiverContactName }

                number={ this.state.number }
                numberReserved={ this.state.numberReserved }

                creatorName={ this.state.creatorName }
                createdDate={ this.state.createdDate }

                relatedNumber={ this.state.relatedNumber }
                relatedType={ this.state.relatedType }
                relatedId={ this.state.relatedId }

                originId  ={ this.state.originId }
                isReturned  ={ this.state.isReturned }
                referenceYour={ this.state.referenceYour }
                referenceOur={ this.state.referenceOur }
                comment={ this.state.comment }
              
            />
        </div></Collapse>

        const validatorMessage = this.validator.getStatusAll();
        const type = this.state.type;

        if( this.state.loadingCounter > 0 )
            return <div className="apLoader"></div>;

        if( this.state.errorMessage )
            return <div> { this.state.errorMessage  } </div>

        let allDeliverButton = null;
        if(type === 'order' )
        {
            allDeliverButton = <ApButton color="blue" onClick={ this.allSent } >
                <SvgIcon icon="clipboard-check" type="solid" />
                { tr('all_sent') }
            </ApButton>
        }

        let saveBox = null;
        if( type === 'order' || type === 'new' )
        {
            saveBox = <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar= node }>
                <div className="saveBar">
                    <div className="left">
                    </div>
                    <div className="right">

                        <ApTooltip text={ validatorMessage }>
                        <ApButton className={ "save" + ( this.state.senderTitle && this.state.receiverTitle ? " highlight" : "" ) } color="blue" onClick={ this.saveDelivery } disabled={ Boolean(validatorMessage) }>
                                <SvgIcon icon="save" type="solid" />
                                { tr('save_shipment') }
                            </ApButton>
                        </ApTooltip>
                    </div>
                </div>
            </ApStickyBar>
        }

        if (saveBox== null &&  (this.state.originId=="" || this.state.originId==null) && !this.state.isReturned ) {
            saveBox = <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar= node }>
            <div className="saveBar">
                <div className="left">
                </div>
                <div className="right">
           

                <ApButton                
                    color="red"
                    className="editButton"
                    onClick={ this.confirmCancel }
                >
                    { tr("cancel_shipment") }
                </ApButton>

               
                 
                </div>
            </div>
            <ApConfirm 
                    show={this.state.confirmCancel}
                    onClose={() => this.setState({ confirmCancel: false })}
                    onConfirm={() => this.cancelDelivery()}
                    header={tr('confirm_cancel_shipment')}
                    body={
                        <div>
                            {tr('confirm_cancel_shipment_info')}
                        </div>
                    }
                   
                />
        </ApStickyBar>        
        }

        let palautettuInfo="";
        if (this.state.isReturned) {
           
            palautettuInfo =<div  style={{ color: `var(--clr-error-light2)` }}> {tr("shipment_is_canceled")} </div>
        }

        return <Measure
            onResize={ () => {
                if( this.stickyBar )
                    this.stickyBar.updateBounds();
            }}
        >
        {({ measureRef }) => <div  ref={ measureRef } className="apBox" id="pageDeliveryEdit">
            <div className="apBoxHeader">
                <h1>
                    { tr('logistics_deliveries') }
                </h1>
                <p> { tr('record_shipment_of_goods') } </p>
            </div>
            <div className="padding">
                <p> {  palautettuInfo }</p>
                <div className="apForm">
                    <div className="apFormColumn fullColumn">
                        { dataEdit }
                        { dataSummary }
                    </div>
                </div>

                <div className="apForm">
                    {/* <div className="apFormColumn fullColumn">
                        <ApButton disabled={true} color="white" onClick={ this.getPdf }>
                            TODO lataa PDF
                        </ApButton>
                    </div> */}
                    <div className="fileContainer">
                        {(this.state.relatedId && this.state.relatedType === 'order') && (
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="fileContainerText">{ tr('download_referral') }</td>
                                        <td>{getButton('dispatch_note', tr('referral'), 'pdf')}</td>
                                        <td>{getButton('dispatch_note', tr('referral'), 'docx')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                

                <div className="apForm">
                    <div className="apFormColumn fullColumn">
                        <div className="text-right">
                            { allDeliverButton }
                        </div>

                        <ComponentInstanceTable
                            componentsChange={ this.componentsChange }
                            components={ this.state.components }

                            instance={ 'delivery' }

                            deliverySenderType={ this.state.senderType }
                            deliverySenderId={ this.state.senderId }

                            deliveryReceiverType={ this.state.receiverType }
                            deliveryReceiverId={ this.state.receiverId }

                            deliveryForOrder={ type === 'order' }

                            deliveryFromLocked={ type === 'show' }
                            deliveryToLocked={ type === 'show' }
                            deliveryCountLocked={ type === 'show' }
                            removeComponentLocked={  type === 'show' }
                            addComponentLocked={ type !== 'new' }
                        />

                    </div>
                </div>
            </div>
            { saveBox }
        </div>
        }
        </Measure>
    }
}

export default DeliveryEdit;
