import ApButton from "common/ApButton/ApButton";
import { ApInput, ApInputStack, ApAddon } from "common/ApInput/ApInput";
import ApReactTable, { colPreset } from "common/ApReactTable/ApReactTable";
import SvgIcon from "common/SvgIcon/SvgIcon";
import React from "react";
import autoBind from "react-autobind";
import { errorPopper, getExcel, getMinMax, keyExists, tr } from "services/Helpers/Helpers";
import api from "services/Api/Api";
import ApOptionInput from "common/ApOptionInput/ApOptionInput";
import moment from "moment";

const statusOptions = [
    { value: '', label: tr('all') },
    { value: 'open', label: tr('open') },
    { value: 'submitted', label: tr('submitted') },
    { value: 'rejected', label: tr('rejected') },
    { value: 'approved', label: tr('approved') },
    { value: 'confirmed', label: tr('confirmed') },
    { value: 'payrolled', label: tr('payrolled') },
    { value: 'paid', label: tr('paid') },
]

class UserTimetrackingReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            startDate: null,
            endDate: null,
            data: [],
        };
        autoBind(this);
    }

    getData() {
        if (!this.state.startDate) {
            errorPopper(null, tr('start_date_mandatory'));
            return;
        }
        if (!this.state.endDate) {
            errorPopper(null, tr('end_date_mandatory'));
            return;
        }
        this.setState({ loading: true });
        api({
            method: 'get',
            url: `currentUser/reports/timetracking/${this.state.startDate}/${this.state.endDate}`,
        }).then((response) => {
            this.setState({ data: response, loading: false });
        }).catch((error) => {
            console.log(error);
            errorPopper(error, tr('get_error'))
            this.setState({ loading: false });
        });
    }

    async getExcelFile(selectedRowIndexes, createPdf = false) {
        if (!this.state.data.length) return;

        let data = [...this.state.data];
        if (selectedRowIndexes.length) {
            data = data.filter((row, index) => selectedRowIndexes.includes(index));
        }

        const visibleColumns = this.reactTable.getVisibleColumns();

        const excelData = data.map(row => {
            const data = {};
            visibleColumns.forEach(col => {
                switch (col.original_key) {
                    case 'date':
                        data.date = moment(row.date).format('DD.MM.YYYY');
                        break;
                    case 'description':
                        data.description = row.description;
                        break;
                    case 'project':
                        data.project = row.project ? `${row.project.name}-${row.project.project_code}` : '';
                        break;
                    case 'customer':
                        data.customer = (row.project && row.project.root_customer) ? `${row.project.root_customer.name}` : '';
                        break;
                    case 'normal_hours':
                        data.normal_hours = Number(row.hours_regular);
                        break;
                    case 'overtime_hours':
                        data.overtime_hours = Number(row.hours_overtime);
                        break;
                    case 'travel_hours':
                        data.travel_hours = Number(row.hours_travel);
                        break;
                    case 'absences':
                        data.absences = Number(row.hours_absence);
                        break;
                    case 'in_total':
                        data.in_total = Number(row.total_hours);
                        break;
                    case 'status':
                        data.status = row.status ? tr(row.status) : '';
                        break;
                    default:
                        break;
                }
            });
            return data;
        });

        this.setState({ loading: true });
        await getExcel(
            excelData,
            `${tr('timetracking_entries')} ${moment(this.state.startDate).format('DD.MM.YYYY')} - ${moment(this.state.endDate).format('DD.MM.YYYY')}`,
            tr('timetracking_entries'),
            {createPdf, fileName: 'user_timetracking_report'},
        );
        this.setState({ loading: false });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    getColumns() {
        return [
            colPreset({
                type: 'date',
                Header: tr('date'),
                accessor: 'date',
                width: 125,
                filterable: false,
                original_key: 'date'
            }),
            {
                Header: tr('description'),
                accessor: 'description',
                customizable: true,
                showInitially: true,
                customFilter: {
                    type: 'text',
                    placeholder: tr('description'),
                },
                original_key: 'description'
            },
            {
                Header: tr('project'),
                accessor: 'project',
                customizable: true,
                customFilter: {
                    type: 'text',
                    placeholder: tr('project'),
                    fields: ['_original.project.name', '_original.project.project_code']
                },
                Cell: row => {
                    if (!row.original.project) return '';
                    return <div>
                        {row.original.project.name}
                        <small style={{ display: 'block' }}>{row.original.project.project_code}</small>
                    </div>
                },
                original_key: 'project'
            },
            {
                Header: tr('customer'),
                accessor: 'customer',
                customizable: true,
                showInitially: true,
                customFilter: {
                    type: 'text',
                    placeholder: tr('customer'),
                    fields: ['_original.project.customer.name']
                },
                Cell: row => {
                    if (!row.original.project) return '';
                    if (!row.original.project.root_customer) return '';
                    return <div>
                        {row.original.project.root_customer.name}
                    </div>
                },
                original_key: 'customer'
            },
            colPreset({
                type: 'numberNice',
                decimals: 2,
                unit: 'h',
                Header: tr('normal_hours'),
                accessor: 'hours_regular',
                customizable: true,
                // customFilter: {
                //     type: "range",
                //     min: getMinMax('hours_regular', 'min', this.state.data),
                //     max: getMinMax('hours_regular', 'max', this.state.data),
                // },
                filterable: false,
                original_key: 'normal_hours',
                showInitially: true,
            }),
            colPreset({
                type: 'numberNice',
                decimals: 2,
                unit: 'h',
                Header: tr('overtime_hours'),
                accessor: 'hours_overtime',
                customizable: true,
                // customFilter: {
                //     type: "range",
                //     min: getMinMax('hours_overtime', 'min', this.state.data),
                //     max: getMinMax('hours_overtime', 'max', this.state.data),
                // },
                filterable: false,
                original_key: 'overtime_hours',
                showInitially: true,
            }),
            colPreset({
                type: 'numberNice',
                decimals: 2,
                unit: 'h',
                Header: tr('travel_hours'),
                accessor: 'hours_travel',
                customizable: true,
                // customFilter: {
                //     type: "range",
                //     min: getMinMax('hours_overtime', 'min', this.state.data),
                //     max: getMinMax('hours_overtime', 'max', this.state.data),
                // },
                filterable: false,
                original_key: 'travel_hours',
                showInitially: true,
            }),
            colPreset({
                type: 'numberNice',
                decimals: 2,
                unit: 'h',
                Header: tr('absences'),
                accessor: 'hours_absence',
                customizable: true,
                // customFilter: {
                //     type: "range",
                //     min: getMinMax('hours_absence', 'min', this.state.data),
                //     max: getMinMax('hours_absence', 'max', this.state.data),
                // },
                filterable: false,
                original_key: 'absences',
                showInitially: true,
            }),
            colPreset({
                type: 'numberNice',
                decimals: 2,
                unit: 'h',
                Header: tr('recorded_hours_total'),
                accessor: 'total_hours',
                customizable: true,
                showInitially: true,
                // customFilter: {
                //     type: "range",
                //     min: getMinMax('total_hours', 'min', this.state.data),
                //     max: getMinMax('total_hours', 'max', this.state.data),
                // },
                filterable: false,
                original_key: 'in_total'
            }),
            {
                Header: tr('status'),
                accessor: 'status',
                customizable: true,
                customFilter: {
                    type: "select",
                    options: statusOptions,
                },
                Cell: row => row.value ? tr(row.value) : null,
                original_key: 'status'
            },
        ];
    }

    render() {
        return <div className="padding" id="user-timetracking-report">
            <div className="date-selection">
                <ApInputStack collapseAt={400} gap="0">
                    <ApInput
                        id="startDate"
                        name="startDate"
                        type="datetimeV2"
                        label={tr('start_date')}
                        value={this.state.startDate}
                        onChange={e => this.handleChange({ target: { value: e, name: 'startDate' } })}
                        weekNumbers={true}
                    />
                    <ApAddon width="20" noLeftBorder noRightBorder> - </ApAddon>
                    <ApInput
                        id="endDate"
                        name="endDate"
                        type="datetimeV2"
                        label={tr('end_date')}
                        value={this.state.endDate}
                        onChange={e => this.handleChange({ target: { value: e, name: 'endDate' } })}
                        weekNumbers={true}
                    />
                </ApInputStack>
                <ApInputStack gap="0">
                    <ApAddon custom gapLeft="10">
                        <ApOptionInput
                            value={[this.state.startDate, this.state.endDate]}
                            onChange={(values) => { this.setState({ startDate: values[0], endDate: values[1] }, this.getEntries) }}
                            options={["month-3", "month-2", "month-1", "month"]}
                        />
                        <ApOptionInput
                            value={[this.state.startDate, this.state.endDate]}
                            onChange={(values) => { this.setState({ startDate: values[0], endDate: values[1] }, this.getEntries) }}
                            options={["week-3", "week-2", "week-1", "week"]}
                        />
                    </ApAddon>
                </ApInputStack>
            </div>

            <ApButton color='green' size="small" onClick={this.getData} disabled={this.state.loading}>
                <SvgIcon type="solid" icon="search" /> {tr('search')}
            </ApButton>

            <ApReactTable
                ref={r => this.reactTable = r}
                rememberId="user-timetracking-report"
                columns={this.getColumns()}
                data={this.state.data}
                loading={this.state.loading}
                filterable
                showFiltersInitially
                multiselect={[
                    {
                        icon: 'file-pdf',
                        label: tr('create_pdf'),
                        action: selectedRows => this.getExcelFile(selectedRows, true),
                        unselectAfter: false,
                        closeAfter: true,
                        alwaysOn: true,
                    },
                    {
                        icon: 'file-excel',
                        label: tr('create_excel'),
                        action: this.getExcelFile,
                        unselectAfter: false,
                        closeAfter: true,
                        alwaysOn: true,
                    },
                ]}
            />
        </div>;
    }
}

export default UserTimetrackingReport;