import { ApTab, ApTabs } from 'common/ApTabs/ApTabs';
import React from 'react';
import autobind from 'react-autobind';
import { errorPopper, hasPermissions, mapTree, sortByField, tr } from 'services/Helpers/Helpers';
import ComponentNeeds from './ComponentNeeds';
import ProjectOrderedComponents from './ProjectOrderedComponents';
import api from 'services/Api/Api.js';
import ProjectPurchaseOrders from './ProjectPurchaseOrders';
import SentPurchaseOrderOffers from './PurchaseOrderOffer/SentPurchaseOrderOffers';
import PurchaseOrderOffer from './PurchaseOrderOffer/PurchaseOrderOffer';
import _ from 'lodash';

class ComponentNeedsTabs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            componentNeeds: [],
            locations: [],

            purchaseOrders: [],

            locationOptions: [{ value: '', label: tr('all') }],
            componentOptions: [{ value: '', label: tr('all') }],
            supplierOptions: [{ value: '', label: tr('all') }],
            purchaseOrderStatusOptions: [{ value: '', label: tr('all') }],
            componentTypeOptions: [{ value: '', label: tr('all') }],
            selectedTab: 0,
        };

        autobind(this);
    }

    componentDidMount() {
        this.getProjectNeedsInfo();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tree && this.props.tree && !_.isEqual(prevProps.tree, this.props.tree)) {
            this.getProjectNeedsInfo();
        }
    }

    getProjectNeedsInfo() {
        const id = this.props.tree ? this.props.tree[0].id : null;
        if (!id) return;

        const componentIds = [];
        const locationIds = [];
        const componentsWithLocations = [];
        const treeCopy = JSON.parse(JSON.stringify(this.props.tree));
        mapTree(treeCopy, (project) => {
            if (project.storage_location && !locationIds.includes(project.storage_location.id)) {
                locationIds.push(project.storage_location.id);
            }
            for (let component of project.components) {
                if (!componentIds.includes(component.component_id)) {
                    componentIds.push(component.component_id);
                }
                if (project.storage_location) {
                    componentsWithLocations.push({ locationId: project.storage_location.id, componentId: component.component_id });
                }
            }
            return project;
        });

        this.setState({ loading: true });
        api({
            url: `projects/projectneeds/get/${id}`,
            method: 'POST',
            data: { componentIds, locationIds, componentsWithLocations }
        }).then(response => {
            // console.log("RES", response);

            const purchaseOrderStatusOptions = response.purchaseOrderStatuses.map(status => {
                return { label: tr(status.text), value: status.id }
            });
            purchaseOrderStatusOptions.unshift({ value: '', label: tr('all') });

            this.setState({
                loading: false,
                purchaseOrders: response.purchaseOrders,
                componentStocks: response.componentStocks,
                waitingArrival: response.waitingArrival,
                purchaseOrderStatusOptions,
            }, this.parseComponentNeeds);
        }).catch(error => {
            errorPopper(error, tr('get_error'));
            console.error(error);
            this.setState({ loading: false });
        });
    }

    parseComponentNeeds() {
        let locations = [];
        let componentNeeds = [];

        const treeCopy = JSON.parse(JSON.stringify(this.props.tree));

        mapTree(treeCopy, (project) => {
            let storageLocation;

            if (project.storage_location) {
                storageLocation = project.storage_location;
                if (!this.state.locations.find(location => location.id == project.storage_location.id)) {
                    locations.push(storageLocation);
                }
            } else if (project.storage_location_parent) {
                storageLocation = project.storage_location_parent;
                if (!this.state.locations.findIndex(location => location.id == project.storage_location_parent.id)) {
                    locations.push(storageLocation);
                }
            }

            for (let component of project.components) {
                // Don't show project components that are not saved
                if (!component.id || isNaN(component.id)) continue;
                if (!component.alloc_count) {
                    component.alloc_count = 0;
                }
                component.storageLocation = storageLocation;
                component.storageName = storageLocation ? storageLocation.name : '';
                component.storageId = storageLocation ? storageLocation.id : null;
                component.suppliers = component.component.suppliers.map(supplier => supplier.name).join(', ')
                component.componentTypes = component.component.component_types.map(ct => ct.type.name).join(', ')
                const found = componentNeeds.findIndex(c => (
                    c.component_id == component.component_id
                    && (c.storageLocation && c.storageLocation.id) == (component.storageLocation && component.storageLocation.id)
                ));
                if (storageLocation && storageLocation.type == 'S') {
                    component.balance_available = ((isNaN(component.balance_available) ? 0 : (parseFloat(component.balance_available))) - (isNaN(component.alloc_count) ? 0 : parseFloat(component.alloc_count))).toFixed(2);
                    if (found != -1) {
                        componentNeeds[found].balance_available = ((isNaN(componentNeeds[found].balance_available) ? 0 : parseFloat(componentNeeds[found].balance_available)) - (isNaN(component.alloc_count) ? 0 : parseFloat(component.alloc_count))).toFixed(2);
                    }
                }

                if (found == -1) {
                    component.count = undefined;
                    componentNeeds.push(component);
                } else {
                    componentNeeds[found].alloc_count = (isNaN(componentNeeds[found].alloc_count) ? 0 : parseFloat(componentNeeds[found].alloc_count)) + (isNaN(component.alloc_count) ? 0 : parseFloat(component.alloc_count));
                }

                // Laske komponenttien määrä muissa varastoissa
                component.componentStocks = [];
                component.stocksBalanceTotal = 0;
                this.state.componentStocks.forEach(stock => {
                    if (stock.component_id == component.component_id) {
                        component.componentStocks.push(stock);
                        component.stocksBalanceTotal += Number(stock.balance_available);
                    }
                });

                // Aseta komponentin saapuva määrä
                const foundArrival = this.state.waitingArrival && this.state.waitingArrival.find(arrival =>
                    arrival.component_id == component.component_id
                    && arrival.location_id == component.storageLocation.id);
                if (foundArrival) {
                    component.waitingArrival = foundArrival.count;
                } else {
                    component.waitingArrival = 0;
                }

                component.balance_available >= 0 ? component.needFilled = true : component.needFilled = false;
            }

            return project;
        });

        // Näytä vain komponentit joita tarvitaan lisää
        // componentNeeds = componentNeeds.filter(c => c.balance_available < 0);

        this.setState({ locations, componentNeeds }, this.handleOptions);
    }

    handleOptions() {
        const all = { value: '', label: tr('all') };
        let locationOptions = [
            ...this.state.locations.map(l => ({ value: l.id, label: l.name }))
        ];

        let componentOptions = [
            ...this.state.componentNeeds.reduce((acc, current) => {
                if (!acc.find(item => item.name == current.name)) {
                    acc.push(current);
                }
                return acc;
            }, []).map(c => ({ value: c.component_id, label: c.component.name }))
        ];

        let supplierOptions = [
            ...this.state.componentNeeds.reduce((acc, current) => {
                if (current.component && current.component.suppliers) {
                    current.component.suppliers.forEach(supplier => {
                        if (!acc.find(item => item.name == supplier.name)) {
                            acc.push(supplier);
                        }
                    })
                }
                return acc;
            }, []).map(supplier => ({
                value: supplier.id,
                label: supplier.name,
                filter: ((row, value) => row.component && row.component.suppliers.find(s => s.id == value))
            }))
        ];

        let componentTypeOptions = [
            ...this.state.componentNeeds.reduce((acc, current) => {
                if (current.component && current.component.component_types) {
                    current.component.component_types.forEach(ct => {
                        if (!acc.find(item => item.type.id == ct.type.id)) {
                            acc.push(ct);
                        }
                    })
                }
                return acc;
            }, []).map(ct => ({
                value: ct.type.id,
                label: ct.type.name,
                filter: ((row, value) => row.component && row.component.component_types.find(ct => ct.type.id == value))
            }))
        ];

        sortByField(locationOptions, 'label');
        sortByField(componentOptions, 'label');
        sortByField(supplierOptions, 'label');
        sortByField(componentTypeOptions, 'label');

        locationOptions.unshift(all);
        componentOptions.unshift(all);
        supplierOptions.unshift(all);
        componentTypeOptions.unshift(all);

        this.setState({ locationOptions, componentOptions, supplierOptions, componentTypeOptions });
    }

    onOfferSave() {
        this.setState({selectedTab: 4});
    }

    render() {
        const purchasedComponentsCount = !this.state.purchaseOrders.length 
            ? undefined
            : this.state.purchaseOrders.reduce((count, po) => {
                po.components.forEach(c => count++);
                return count;
            }, 0)
        return (
            <ApTabs parentTabControl={this.state.selectedTab} onChange={(index) => this.setState({selectedTab: index})}>
                <ApTab icon="shopping-cart" label={tr('order_requirements')} mountOnSelect>
                    <ComponentNeeds
                        history={this.props.history}
                        loading={this.state.loading || this.props.loading}
                        tree={this.props.tree}
                        projectID={this.props.tree[0].id || null}
                        projectNeedsUrl={`/storage/purchase/demands/project/${this.props.selectedProject.id}`}
                        componentNeeds={this.state.componentNeeds}
                        locations={this.state.locations}
                        locationOptions={this.state.locationOptions}
                        componentOptions={this.state.componentOptions}
                        supplierOptions={this.state.supplierOptions}
                        componentTypeOptions={this.state.componentTypeOptions}
                    />
                </ApTab>
                <ApTab
                    icon="cube"
                    label={tr('storage_components_ordered')}
                    badge={purchasedComponentsCount}
                    mountOnSelect
                >
                    <ProjectOrderedComponents
                        history={this.props.history}
                        purchaseOrders={this.state.purchaseOrders}
                        loading={this.state.loading || this.props.loading}
                        tree={this.props.tree}
                        purchaseOrderStatusOptions={this.state.purchaseOrderStatusOptions}
                    />
                </ApTab>
                <ApTab
                    icon="clipboard-check"
                    label={tr('purchase_orders')}
                    badge={this.state.purchaseOrders.length || null}
                    mountOnSelect
                >
                    <ProjectPurchaseOrders
                        history={this.props.history}
                        purchaseOrders={this.state.purchaseOrders}
                        loading={this.state.loading || this.props.loading}
                        tree={this.props.tree}
                        purchaseOrderStatusOptions={this.state.purchaseOrderStatusOptions}
                    />
                </ApTab>
                {hasPermissions('purchases.orders') && 
                    <ApTab icon="envelope-open-text" label={tr('send')} mountOnSelect>
                        <PurchaseOrderOffer tree={this.props.tree} onSave={this.onOfferSave} />
                    </ApTab>
                }
                {hasPermissions('purchases.orders') && 
                    <ApTab icon="mail-bulk" label={tr('sent')} mountOnSelect disabled={!this.props.tree.length}>
                        <SentPurchaseOrderOffers projectId={this.props.tree.length ? this.props.tree[0].id : null} tree={this.props.tree} />
                    </ApTab>
                }
            </ApTabs>
        )
    }
}

export default ComponentNeedsTabs;