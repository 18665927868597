import React from 'react';
import moment from 'moment'
import autoBind from 'react-autobind';
import Measure from 'react-measure';
import _ from 'lodash'

import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';
import ApValidate from 'services/ApValidate/ApValidate.js';

import ApModalInput from 'common/ApModalInput/ApModalInput.js';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';
import File from 'common/File/File.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit.js';

import CommentDialog from './CommentDialog/CommentDialog.js'
import SaveBox from './SaveBox/SaveBox.js'
import SendOrder from './SendOrder/SendOrder.js'
import Steps from './Steps/Steps.js';
import ReceiverModal from './ReceiverModal/ReceiverModal.js';
import RelatedSelectModal from 'modules/Storage/common/RelatedSelectModal/RelatedSelectModal.js';
import ConfirmOrder from './ConfirmOrder/ConfirmOrder.js';
import Audits from './Audits/Audits.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import { connect } from "react-redux";

import { ApInput }  from 'common/ApInput/ApInput.js';

import { getTypeIcon
       , getTypeName } from 'modules/Storage/common/StorageHelpers.js';
import { dateInputToSql
       , sqlToDateInput
       , formatMoney
       , errorPopper
       , tr, 
       hasPermissions,
       renderNetvisorAttachment} from 'services/Helpers/Helpers.js';
import { getPoList } from '../PurchaseOrderHelper.js';

import './EditPurchaseOrder.css';
import './ComponentMove/ComponentMove.js';
import ComponentMove from './ComponentMove/ComponentMove.js';
import ComponentsMoved from './ComponentMove/ComponentsMoved.js';

import NewOrder from 'modules/Storage/Orders/NewOrder/NewOrder.js';
import CalculationsTable from 'modules/Projects/OfferCalculation/BudgetStatistics/CalculationsTable/CalculationsTable.js';
import ApModal from 'common/ApModal/ApModal.js';
class EditPurchaseOrder extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {

            loading: false,

            audits: [],
            components: [],
            acceptors: [],
            arrivals: [],

            fieldEditShow: false,
            fieldEditType: null,
            fieldEditTitle: null,
            fieldEditValue: null,
            fieldEditRequired: false,


            receiverEditShow: false,
            relatedEditShow: false,
            historyShow: false,
            confirmDialogShow: false,
            commentDialogShow: false,
            sendDialogShow: false,
            markPaidConfirmShow: false,
            original_receiver: null,

            componentMoveButtonShow: false,
            componentMoveDialogShow: false,
            validComponentsDialogShow: false,
            validAction: false,

            currencySign: auth.getCurrencySign(),

            //Muuttujia joita käytetään kun kyseessä projektiin liittyvä ostotilaus
            projectCode: null,
            projectName: null,
            sub_project_code: null,
            project_id: null,
            previousItem: null,
            nextItem: null,
            projectAttachmentEditShow: false,
            
            save_as_new_default: false,

            currencySign: auth.getCurrencySign(),
            ext_interfaces: [],
            ext_imports: {},
            //Settings:
            storage_required: true,
            contactEmails: [],

            // User came from po import page
            import: false,

            goToArrival: false,
            attachmentLoading: false,
            showAttachmentModal: false,
            attachments: [],
        }
        this.eListener = null;

        const noComponentsValidate = () => {
            return (this.state.components.length > 0)
        };

        const targetValidator = ( value, index )  => {
            //Jos tilataan projektiin, jätä huomiotta
            if (this.state.receiver_type == 'project') { return true; }
            if (this.state.storage_required == false) { return true; }

            const c = this.state.components[ index ];
            if( c.type_name !== 'item' || c.component_id == null ) { return true }
            return Boolean(value);
        };

        this.validator = new ApValidate(this, {
            totalPrice: { filter: noComponentsValidate, text: tr('no_storage_components_selected') },
            components: {
                filters: {
                    count:                { filter: 'required', text: tr('storage_component_quantity_not_specified') },
                    purchase_order_price: { filter: 'required', text: tr('storage_component_price_not_specified') },
                    target_final_type:    { filter: targetValidator, text: tr('storage_component_target_not_specified') },
                }
            },
            status_name: {
                filter: (value) => {
                    if (value === 'arrival') return hasPermissions('logistic.arrivals')
                    return true;
                },
                text: tr('not_enough_permissions')
            }
        });

        autoBind(this);
    }

    componentDidMount()
    {
        const params = this.props.match.params;
        if (sessionStorage.getItem('import')) {
            this.setState({import: true});
        }
        if( params.type === 'id' )
            this.getPurchaseOrder('id', params.id );
        else
            this.getPurchaseOrder('number', params.type);
    }

    componentWillUnmount() {
        if (!this.state.goToArrival) {
            sessionStorage.removeItem('import');
        }
    }

    attachToProject(data, poId) {

        const url = `storage/po/addProjectLink`;

        if ((data.receiver_type == 'project' || data.receiver_project_id) && data.receiver_id ) {
            const recId = data.receiver_id;
           
            api({
                method: 'post',
                url: url,
                data: { 
                    receiver_id: recId, 
                    purchase_order_id: poId,
                    receiver_type: data.receiver_type,
                    receiver_name: data.receiver_name,
                    receiver_address: data.receiver_address,
                    receiver_zipcode: data.receiver_zipcode,
                    receiver_city: data.receiver_city,
                    receiver_country: data.receiver_country,
                    receiver_contact_name: data.receiver_contact_name,
                    receiver_project_id: data.receiver_project_id,
                },
            }).then((response) => {
                console.log(`Projektikoodi lisätty projektille.`);
                this.setState({ projectName: `${response.project_code} - ${response.name}` })
            }).catch((error) => {
                console.warn(`Ongelma esiintyi: ${error}`);
            });
        } else if (this.state.projectName != null) {
            this.setState({ projectName: null });

            api({
                method: 'post',
                url: url,
                data: { purchase_order_id: poId, remove: true },
            }).then((response) => {
                console.log(`Projektikoodi poistettu projektilta.`);
            }).catch((error) => {
                console.warn(`Ongelma esiintyi: ${error}`);
            });
        }

    }
    

    redirectToProject() {
        window.emitter.emit('goTo', {pathname: `../../../projects/${this.state.project_id}/management/buy`});
    }

    getComponentInstanceData( components )
    {
        this.setState({loadingCounter: this.state.loadingCounter+1});
        return api({
            method: 'post',
            url: 'storage/components/format',
            data: {
                components: components,
                instance: 'po',
                poId: this.state.id,
            }
        }).then((response) => {
            const rComponents = response.map( ( c, i ) => {

                let lComponent = components[ i ];

                c.count = lComponent.count;
                c.purchase_order_price = lComponent.purchase_order_price;

                if (lComponent.purchase_order_price === null) {
                    c.purchase_order_price = lComponent.price
                }

                if( lComponent.target_final_type === 'direct' )
                {
                    const found = c.demands.find( d => d.demand_id === lComponent.demand_id )
                    c.id                = found.demand_id;
                    c.target_final_type = found.target_final_type;
                    c.target_final_id   = found.target_final_id;
                }
                else
                {
                    c.target_final_type = lComponent.target_final_type;
                    c.target_final_id   = lComponent.target_final_id;
                }
                return c;
            });

            this.calculateTotalPrice( components );

            this.setState({
                components: rComponents,
                loadingCounter: this.state.loadingCounter - 1,
            });
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            this.setState({loadingCounter: this.state.loadingCounter - 1});
        });

    }

    getPurchaseOrder( type, id )
    {
        this.setState({loading: true,  sent_file_id: null, confirm_file_id: null });

        return api({
            method: 'get',
            url: `storage/po/${type}/${id}`,
        }).then((response) => {
            this.setPurchaseOrder(response);
            let acceptInvoice=false;
            let showAcceptInvoice = true;
            if (response.ext_imports.length === 0) {
                showAcceptInvoice = false;
            }
            else {
                acceptInvoice = !response.ext_imports.every(item => item.allow_payment == true); 
            }
            this.setState({
                loading: false,
                original_receiver: response.receiver,
                projectName: response.name,
                ext_interfaces: response.ext_interfaces,
                ext_imports: response.external_imports,
                storage_required: response.storage_required,
                contactEmails: response.contact_emails,
                ext_imports: response.ext_imports,
                acceptInvoice: acceptInvoice,
                showAcceptInvoice: showAcceptInvoice,
                attachments: [],
            }, () => {
                this.getAttachments();
            });
            this.getNavigationsIds()

        }).catch((error) => {
            let message = tr('get_error');
            if(error.status === 404)
                message = tr('not_found');
            this.setState({
                loading: false,
                error: message,
            });
        });


    }

    getAttachments() {
        const attachments = [];
        this.setState({attachmentLoading: true});
        if (this.state.ext_imports && Array.isArray(this.state.ext_imports)) {
            this.state.ext_imports.forEach(item => {
                if (item.import && item.import.import_id) {
                    const id = item.import.import_id;
                    api({
                        method: 'get',
                        url: `storage/po/import/attachments/${id}/get`
                    }).then(response => {
                        // console.log("response", response);
                        if (Array.isArray(response)) {
                            attachments.push(...response);
                            this.setState({ attachmentLoading: false });
                        }
                    }).catch(error => {
                        console.error("error", error)
                        this.setState({ attachmentLoading: false });
                    })
                }
            });
        }
        this.setState({ attachments });
    }

    closeAttachmentModal() {
        this.setState({ showAttachmentModal: false });
    }

    setPurchaseOrder( po )
    {
        try {
            const dateFields = [ 'date', 'need_date', 'due_date', 'delivery_date' ];

            dateFields.forEach( df => {
                po[ df ] = sqlToDateInput( po[ df ] );
            });

            po.components = po.components.map(component => {
                if (component.conversion_unit_id) {
                    component.selectedUnit = component.conversion_units.find(unit => unit.id == component.conversion_unit_id);
                    component.count = Number((parseFloat(component.count) * parseFloat(component.selectedUnit.conversion_factor)).toFixed(5));
                    component.purchase_order_price = parseFloat(component.purchase_order_price) / parseFloat(component.selectedUnit.conversion_factor);
                }
                return component;
            });
            
            po.components = _.orderBy(po.components, 'name', 'asc')
            this.setState( po );
            this.calculateTotalPrice( po.components );

            if( po.number )
                this.props.history.replace(`/storage/purchase/order/${po.number}`);
        } catch {
            console.error('setPurchaseOrder ei onnistunut');
            // window.location.reload();
        }
        
    }

    getNavigationsIds()
    {   
        const data = getPoList()

        if (data.length === 0) {
            api({
                method: 'post',
                url: 'storage/pos/apt',
                data: {
                    archived: false,
                    pageSize: 9000,
                    sorted: [{id: "id", desc: true}]
                },
            }).then((response) => {
                this.setButtons(response.data)
            }).catch((error) => {
                console.error(error)
            })
            return
        }

        this.setButtons(data)   
    }

    setButtons(data)
    {
        const id = this.state.number

        for (let i = 0; i < data.length; i++) {
            if (data[i].number === id) {
                if (i !== data.length -1) {
                    this.setState({ previousItem: data[i + 1].number });
                } else {
                    this.setState({ previousItem: null });
                }

                if (i !== 0) {
                    this.setState({ nextItem: data[i - 1].number });
                } else {
                    this.setState({ nextItem: null });
                }

                break
            }
        }
    }

    goToNextItem()
    {
        if (!this.state.nextItem) {
            window.emitter.emit( 'goTo', { pathname: '/storage/purchase/orders' } )
            return
        }

        this.setState({loading: true})
        this.getPurchaseOrder('number', this.state.nextItem);

        const path = `/storage/purchase/order/${this.state.nextItem}`;
        window.emitter.emit( 'goTo', { pathname: path } );  
    }

    goToPreviousItem()
    {
        if (!this.state.previousItem) {
            window.emitter.emit( 'goTo', { pathname: '/storage/purchase/orders' } )
            return
        }

        this.setState({loading: true})
        this.getPurchaseOrder('number', this.state.previousItem);

        const path = `/storage/purchase/order/${this.state.previousItem}`;
        window.emitter.emit( 'goTo', { pathname: path } );
    }

    saveBoxSubmit( action, options )
    {
        const openCommentDialogFor = [
            'save_draft',
            'save',
            'accept_cancel',
            'accept',
            'send_cancel',
            'confirm_cancel',
            'confirm_save',
            'arrival_edit',
            'arrived_edit',
            'archive',
            'remove_archive',
            'paid',
        ];

        if( openCommentDialogFor.includes( action ) )
        {
            this.setState({
                commentDialogShow: true,
                commentDialogAcceptor: (action === 'save' || action === 'accept' ),
                commentDialogMeta: options,
            });
        }
        else if( action === 'remove' )
        {
            this.remove();
        }
        else if( action === 'send' )
        {
            this.setState({sendDialogShow: true});
        }
        else if( action === 'confirm' )
        {
            this.setState({confirmDialogShow: true});
        }
        else if( action === 'arrival_add' )
        {
            this.setState({goToArrival: true});
            window.emitter.emit( 'goTo', { pathname: `/storage/logistic/arrival/po/${ this.state.id }`});
        }
        else if ( action === 'create_order' ) {
            this.setState({ newOrderShow: true, selected: this.componentInstanceTableRef.getSelected()});
        }
        else if ( action === 'show_attachments' ) {
            this.setState({showAttachmentModal: true});
        }
        else
        {
            console.error( 'saveBoxSubmit unknown action', action );
        }
    }

    commentDialogOnSubmit( action, comment, acceptor, data=[] )
    {
        this.setState({
            commentDialogShow: false,
            commentDialogMeta: {},
        });

        if(action === 'save')                this.save( false, comment, acceptor, data );
        else if(action === 'save_draft')     this.save( true, comment);
        else if(action === 'accept_cancel')  this.acceptCancel( comment );
        else if(action === 'accept')         this.accept( comment, acceptor );
        else if(action === 'send_cancel')    this.sendCancel( comment );
        else if(action === 'remove_archive') this.remove( comment );
        else if(action === 'confirm_cancel') this.confirmCancel( comment );


        else if(action === 'confirm_save')   this.confirmSave( comment );
        else if(action === 'arrival_edit')   this.editArrival( comment );
        else if(action === 'arrived_edit')   this.editArrived( comment );
        else if(action === 'archive')        this.archive( comment );
        else if(action === 'paid')           this.savePaid( comment );
        else if(action === 'create_order')   this.createOrder( comment );
        else console.error('commentDialogOnSubmit unknown action', action);
    }

    commentDialogOnSkip(data) {
        this.setState({
            commentDialogShow: false,
            commentDialogMeta: {},
            delivery_date: data.delivery_date,
            due_date: data.due_date
        }, () => {
            let saveData = this.formatSaveData();

            saveData.skipArrival = data.skip_arrival;
            api({
                method: 'post',
                url: `storage/po/skip/save`,
                data: saveData,
            }).then((response) => {
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>Tallennettu</strong>,
                });
                this.setPurchaseOrder(response);
            }).catch((error) => {
                errorPopper(error, 'Tallennuksessa tapahtui virhe');
            });
        });
    }

    sendCancel( comment )
    {
        api({
            method: 'post',
            url: `storage/po/id/${this.state.id}/send/cancel`,
            data: {latest_comment: comment},
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('po_moved_to_edit') }</strong>,
            });
            this.setPurchaseOrder(response);
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });

    }

    acceptCancel( comment )
    {
        api({
            method: 'post',
            url: `storage/po/id/${this.state.id}/accept/cancel`,
            data: {latest_comment: comment},
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('po_moved_to_edit') }</strong>,
            });
            this.setPurchaseOrder(response);
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });
    }

    accept( comment, acceptor )
    {
        api({
            method: 'post',
            url: `storage/po/id/${this.state.id}/accept`,
            data: {latest_comment: comment, acceptor: acceptor},
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('po_accepted') }</strong>,
            });
            this.setPurchaseOrder(response);
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });
    }

    sendSubmit( data )
    {
        return api({
            method: 'post',
            url: `storage/po/id/${this.state.id}/send`,
            data: data,
        }).then((response) => {
            const message = ( data.manual ) ? tr('po_marked_as_shipped') : tr('po_shipped');
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ message }</strong>,
            });
            this.setState({sendDialogShow: false});
            this.setPurchaseOrder(response);
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });
    }

    confirmCancel( comment )
    {
        api({
            method: 'post',
            url: `storage/po/id/${this.state.id}/confirm/cancel`,
            data: {latest_comment: comment},
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('po_moved_to_shipping') }</strong>,
            });
            this.setPurchaseOrder(response);
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });

    }

    confirmSave( comment )
    {
        let data = this.formatSaveData();
        data.latest_comment = comment;
        api({
            method: 'post',
            url: `storage/po/id/${this.state.id}/confirm/edit`,
            data: data,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('save_successful') }</strong>,
            });
            this.setState({
                original_receiver: data.receiver,
            })
            this.setPurchaseOrder(response);
        }).catch((error) => {
            console.error( error );

            errorPopper(error, tr('save_error'));
        });
    }

    confirmSubmit( data )
    {
        const sUrl = `storage/po/id/${this.state.id}/confirm`;
        data = { ...this.formatSaveData(), ...data };

        return api({
            method: 'post',
            url: sUrl,
            data: data,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('po_marked_as_confirmed') }</strong>,
            });
            this.setState({confirmDialogShow: false});
            this.setPurchaseOrder(response);
        }).catch((error) => {
            console.error( error );
            // window.location.reload();
            //errorPopper(error, 'Ostotilauksen tilan muuttamisessa tapahtui virhe');
        });
    }

    confirmStorageMove(response) {

        this.setState({ 
            componentMoveDialogShow: false,
            movedComponents: response,
            validComponentsDialogShow: true,
        });
    }

    confirmedMove() {
        this.setState({
            validComponentsDialogShow: false,
            componentMoveButtonShow: false,
        });
        
        //Päivitä näkymä
        const params = this.props.match.params;
        if( params.type === 'id' )
            this.getPurchaseOrder('id', params.id );
        else
            this.getPurchaseOrder('number', params.type);
    }

    editArrival( comment )
    {
        let data = this.formatSaveData();
        data.latest_comment = comment;

        api({
            method: 'post',
            url: `storage/po/id/${ this.state.id }/edit/arriving`,
            data: data,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('save_successful') }</strong>,
            });
            this.setPurchaseOrder( response );
        }).catch((error) => {
            errorPopper(error, tr('save_error'));

            // Removed component with arrivals from list so we refresh components
            if( error.status === 424)
                this.getPurchaseOrder( 'id', this.state.id );
        });
    }

    editArrived( comment )
    {
        let data = this.formatSaveData();
        data.latest_comment = comment;

        api({
            method: 'post',
            url: `storage/po/id/${ this.state.id }/edit/arrived`,
            data: data,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('save_successful') }</strong>,
            });
            this.setPurchaseOrder( response );
        }).catch((error) => {
            errorPopper(error, tr('save_error'));

            // Removed component with arrivals from list so we refresh components
            if( error.status === 424)
                this.getPurchaseOrder( 'id', this.state.id );
        });
    }

    createOrder( comment ) {
        let data = this.formatSaveData();
        data.latest_comment = comment;
        data.paid = !this.state.paid;

        api({
            method: 'post',
            url: `storage/po/id/${ this.state.id }/paid`,
            data: data
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('paid') }</strong>,
            });
            this.setPurchaseOrder( response );
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });
    }

    savePaid( comment )
    {
        let data = this.formatSaveData();
        data.latest_comment = comment;
        data.paid = !this.state.paid;

        api({
            method: 'post',
            url: `storage/po/id/${ this.state.id }/paid`,
            data: data
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('paid') }</strong>,
            });
            this.setPurchaseOrder( response );
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });
    }

    archive( comment )
    {
        let data = {
            latest_comment: comment,
            archived: !this.state.archived,
        }

        api({
            method: 'post',
            url: `storage/po/id/${ this.state.id }/archive`,
            data: data,
        }).then((response) => {
            const message = response.archived ? tr('archived') : tr('archiving_canceled')

            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ message }</strong>,
            });
            this.setPurchaseOrder( response );
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });
    }

    save( draft = false, comment = null, acceptor = null, data = [] )
    {
        let saveData = this.formatSaveData();
        saveData.latest_comment = comment;
        saveData.acceptor_id = acceptor;
        saveData.skip_acceptor= data.skip_acceptor;
        saveData.skip_arrival= data.skip_arrival;

        if (data.due_date) {
            saveData.due_date = dateInputToSql(data.due_date);
        }
        if (data.delivery_date) {
            saveData.delivery_date = dateInputToSql(data.delivery_date);
        }

        let url = ( draft ) ? 'storage/po/save/draft' : 'storage/po/save';
        api({
            method: 'post',
            url: url,
            data: saveData,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('save_successful') }</strong>,
            });
            this.setPurchaseOrder(response);
            if (data.skip_acceptor && data.skip_arrival) {
                this.setState({goToArrival: true});
                window.emitter.emit( 'goTo', { pathname: `/storage/logistic/arrival/po/${ this.state.id }`});
            }
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
        });
    }

    remove( comment = null )
    {
        api({
            method: 'delete',
            url: `storage/po/id/${this.state.id}/delete`,
            data: {latest_comment: comment},
            errorPopper: tr('save_error'),
        }).then((response) => {
            if(response.removed)
            {
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>{ tr('deleted') }</strong>,
                });
                this.props.history.replace('/storage/orders');
            }
        }).catch((error) => {
        });
    }

    formatComponentData()
    {
        //Jos tilataan projektille, kaikkien komponenttien target on projektivarasto
        if (this.state.receiver_type=='project')
        {
            return this.state.components.map(c => {
                return {
                    id: isNaN( parseInt( c.id, 10 ) ) ? null : c.id,
                    type_id: c.type_id,
                    name: c.name,
                    component_id:         c.component_id,
                    save_as_new:          c.save_as_new,
                    unit:                 c.unit,
                    count:                c.selectedUnit 
                                            ? Number((parseFloat(c.count) / parseFloat(c.selectedUnit.conversion_factor)).toFixed(5))
                                            : c.count,
                    purchase_order_price: c.selectedUnit 
                                            ? (parseFloat(c.purchase_order_price) * parseFloat(c.selectedUnit.conversion_factor))
                                            : c.purchase_order_price,
                    target_final_type:    c.target_final_type,
                    target_final_id:      c.target_final_id,
                    supplier_id:		  this.state.supplier_id,
                    order_netvisor_vat: c.order_netvisor_vat,
                    order_vat: c.order_vat,
                    tax_group_id: c.tax_group_id,
                    conversion_unit_id:   c.selectedUnit ? c.selectedUnit.id : null,
                };
            });
        }
        else
        {
            return this.state.components.map(c => {
                let target_type = c.target_final_type;
                let target_id   = c.target_final_id;

                if ( c.component_id == null && c.target_final_id === undefined ) {
                    target_type = 'location';
                    target_id = this.state.receiver_id;
                } 

                return {
                    id: isNaN( parseInt( c.id, 10 ) ) ? null : c.id,
                    type_id: c.type_id,
                    name: c.name,
                    component_id:         c.component_id,
                    save_as_new: 		  c.save_as_new,
                    unit:                 c.unit,
                    count:                c.selectedUnit 
                                            ? Number((parseFloat(c.count) / parseFloat(c.selectedUnit.conversion_factor)).toFixed(5))
                                            : c.count,
                    purchase_order_price: c.selectedUnit 
                                            ? parseFloat(c.purchase_order_price) * parseFloat(c.selectedUnit.conversion_factor) 
                                            : c.purchase_order_price,
                    target_final_type:    target_type,
                    target_final_id:      target_id,
                    supplier_id:		  this.state.supplier_id,
                    order_netvisor_vat: c.order_netvisor_vat,
                    order_vat: c.order_vat,
                    tax_group_id: c.tax_group_id,
                    conversion_unit_id:    c.selectedUnit ? c.selectedUnit.id : null,
                };
                
            });
        }
    }

    formatSaveData()
    {
        const components = this.formatComponentData();

        let data = {};

        data.id = this.state.id;
        data.components = components;
        data.supplier_details = this.state.supplier_details;

        data.receiver_type = this.state.receiver_type;
        data.receiver_id = this.state.receiver_id;
        data.receiver_name = this.state.receiver_name;
        data.receiver_address = this.state.receiver_address;
        data.receiver_zipcode = this.state.receiver_zipcode;
        data.receiver_city = this.state.receiver_city;
        data.receiver_country = this.state.receiver_country;
        data.receiver_contact_name = this.state.receiver_contact_name;

        data.date =  dateInputToSql( this.state.date );

        data.reference_our = this.state.reference_our;
        data.reference_your = this.state.reference_your;
        data.details = this.state.details;
        data.need_date = dateInputToSql( this.state.need_date );

        data.dt_id = this.state.dt_id;
        data.dm_id = this.state.dm_id;
        data.pc_id = this.state.pc_id;

        data.internal_comment = this.state.internal_comment;

        data.due_date = dateInputToSql( this.state.due_date );
        data.delivery_date = dateInputToSql( this.state.delivery_date );
        data.project_id = this.state.project_id;
        
        //commented back until fix receiver subproject id
        data.receiver_code = this.state.projectCode;
        data.sub_project_code = this.state.sub_project_code;

        return data;
    }

    componentsChange( components )
    {
        this.calculateTotalPrice( components );
        this.setState({ components: components });
    }

    calculateTotalPrice( components )
    {
        let total = 0;
        components.forEach( c => {
            const cPrice = ( c.count * c.purchase_order_price );
            if( !isNaN( cPrice ) )
                total += cPrice;
        });
        this.setState({ totalPrice: total });
    }

    sendToExternal(type)
    {
        if (this.state.loading) {
            return
        }
        this.setState({loading: true})
        api({
            method: 'get',
            url: `storage/po/external/${type}/${this.state.id}`,
        }).then((response) => {
            //console.log(response)
            const params = this.props.match.params;
            if( params.type === 'id' )
                this.getPurchaseOrder('id', params.id );
            else
                this.getPurchaseOrder('number', params.type);
        }).catch((error) => {
            console.log('error', error)
            errorPopper(error);
            this.setState({loading: false})
        })
    }

    renderExternalExport()
    {
        const locked = this.isLocked();
        const editClass = locked ? '' : 'edit';

        const externals = [];
        
        this.state.ext_interfaces.forEach((item) => {
            /*if (item.type === 'fivaldi_new' && this.state.status_id >= 4) {
                const imported = this.state.ext_imports[item.type]

                let dd = imported ? 
                    <div>Viety</div>
                    : <a style={{cursor: 'pointer'}} onClick={() => this.sendToExternal(item.type)}>
                        <SvgIcon icon="check" type="solid" />
                        Vie Fivaldiin
                    </a>

                if (this.state.loading) dd = <div className="apLoader" />

                externals.push(
                    <dl className="dl-horizontal">
                        <dt>Fivaldi</dt>
                        <dd className={ editClass }>
                            {dd}
                        </dd>
                    </dl>
                )
            }*/

            if (item.type === 'netvisor' && this.state.status_id >= 4) {
                const imported = this.state.external_imports[item.type]

                let dd = imported ? 
                    <div>Viety</div>
                    : <a style={{cursor: 'pointer'}} onClick={() => this.sendToExternal(item.type)}>
                        <SvgIcon icon="check" type="solid" />
                        Vie Netvisoriin
                    </a>

                if (this.state.loading) dd = <div className="apLoader" />

                externals.push(
                    <dl className="dl-horizontal">
                        <dt>Netvisor</dt>
                        <dd className={ editClass }>
                            {dd}
                        </dd>
                    </dl>
                )
            }
            
        })

        return externals;
    }

    renderSummary()
    {
        const locked = this.isLocked();

        let numberDom = this.state.number;
        if( !this.state.number )
            numberDom = <ApTooltip text={ tr('next_available_number_purchase_order') }>
                <span className="noNumber">{ this.state.next_number }</span>
        </ApTooltip>

        const editClass = locked ? '' : 'edit';

        let relatedDoms = [];
        const relatedFields = {
            pc: tr('payment_term'),
            dt: tr('delivery_term'),
            dm: tr('delivery_method'),
        };
        for (let field in relatedFields ) {
            if( !this.state[ `${ field }_enabled` ]) continue;

            relatedDoms.push(
                <dt key={`${field}-title`} className='pointer' onClick={() => { this.showRelatedEdit(field, relatedFields[field]) }}>
                    {relatedFields[field]}
                </dt>
            );
            relatedDoms.push(
                <dd key={`${field}-value`} className={editClass} onClick={() => { this.showRelatedEdit(field, relatedFields[field]) }}>
                    { this.state[ `${ field }_name` ] }
                </dd> 
            );
        }

        const getEditDd = ( field, translation = null ) => {
            return <>
                <dt onClick={() => this.showEdit( field ) } className='pointer'>{ tr(translation ? translation : field) }</dt>
                <dd className={ editClass } onClick={() => this.showEdit( field ) }><span>{ this.state[ field ] }</span></dd>
            </>
        }

        const getEditDdNotice = ( field, translation = null ) => {
            if (field === 'due_date' && !this.state.paid) {
                const currentDate = moment().format('YYYY-MM-DD')
                const date = moment(this.state[field], 'DD-MM-YYYY')

                if (moment(date).isBefore(currentDate, 'day')) {
                    return (
                        <>
                            <dt onClick={() => this.showEdit( field ) } className="pointer">{ tr(translation ? translation : field) }</dt>
                            <ApTooltip position="top" block text={ tr('payment_is_due') }>
                                <dd className={ `${editClass} due-red` } onClick={() => this.showEdit( field ) }><span>{ this.state[ field ] }</span></dd>
                            </ApTooltip>
                        </>
                    )
                }
            }

            if (field === 'delivery_date' && this.state.status_name === 'arrival') {
                const currentDate = moment().format('YYYY-MM-DD')
                const date = moment(this.state[field], 'DD-MM-YYYY')

                if (moment(date).isBefore(currentDate, 'day')) {
                    return (
                        <>
                            <dt onClick={() => this.showEdit( field ) } className="pointer">{ tr(translation ? translation : field) }</dt>
                            <ApTooltip position="top" block text={ tr('delivery_late_or_not_received') }>
                                <dd className={ `${editClass} due-red` } onClick={() => this.showEdit( field ) }><span>{ this.state[ field ] }</span></dd>
                            </ApTooltip>
                        </>
                    )
                }
            }           

            return <>
                <dt onClick={() => this.showEdit( field ) } className="pointer">{ tr(translation ? translation : field) }</dt>
                <dd className={ editClass } onClick={() => this.showEdit( field ) }>
                    <span>{ this.state[ field ] }</span>
                </dd>
            </>
        }

        const editSupplier = ( field ) => {
            return <dd className={ editClass } onClick={ this.showReceiverEdit }>{ this.state[ field ] }</dd>
        }

        let contactDom = null;
        if( this.state.receiver_contact_name )
        {
            contactDom = [
                <dt key="title">{ tr('contact_person') }</dt>,
                <dd  key="value" className={ editClass } onClick={ this.showReceiverEdit }>{ this.state.receiver_contact_name }</dd>
            ];
        }

        let acceptExtInterfaces = [];
        if (this.state.showAcceptInvoice) {
            for (let interfa of this.state.ext_interfaces) {
                acceptExtInterfaces.push(
                    < dl className = "dl-horizontal" >
                        <dt>{tr(interfa.type)}</dt>

                        <dd className={this.state.acceptInvoice?editClass:''} onClick={() => this.accpetInvoice(interfa.type)}><div className="pre-wrap">{this.state.acceptInvoice?tr('accept_invoice'):tr('approved')}</div></dd>
                    </dl >
                            
                    

                );
            }
        }

        return <div className="storageSummaryBox">
            <div className="apForm">
                <div className="apFormColumn">
                    <dl className="dl-horizontal">
                        <dt className={ this.state.number ? '' : 'noNumber' }>{ tr('number') }</dt>
                        <dd>{ numberDom }</dd>

                        <dt>{ tr('creator') }</dt>
                        <dd> { this.state.creator_name } </dd>
                    </dl>

                    <dl className="dl-horizontal">
                        <dt>{ tr('supplier') }</dt>
                        <dd className={ editClass } onClick={() => this.showEdit('supplier_details') }>{ this.state.supplier_name }</dd>
                        <dt></dt>
                        <dd className={ editClass } onClick={() => this.showEdit('supplier_details') }><div className="pre">{ this.state.supplier_details }</div></dd>

                    </dl>

                    <dl className="dl-horizontal">

                        <dt>{ tr('recipient') }</dt>
                        <dd className={ editClass } onClick={ this.showReceiverEdit }>
                            { Boolean( this.state.receiver_type ) && <ApTooltip text={ getTypeName( this.state.receiver_type ) }>
                                <SvgIcon icon={ getTypeIcon( this.state.receiver_type ) } type="solid" />
                            </ApTooltip> }
                            { this.state.receiver_name == null && this.state.receiver_type == 'project' ? 'Projekti ilman varastoa' : this.state.receiver_name }
                        </dd>

                        <dt>{ tr('shipping_address') }</dt>
                        <dt></dt>{ editSupplier( 'receiver_title' ) }
                        <dt></dt>{ editSupplier( 'receiver_address' ) }
                        <dt></dt>{ editSupplier( 'receiver_zipcode' ) }
                        <dt></dt>{ editSupplier( 'receiver_city' ) }
                        <dt></dt>{ editSupplier( 'receiver_country' ) }
                        { contactDom }
                    </dl>

                    {
                        this.state.componentMoveButtonShow && this.state.validAction ? <ApButton 
                            onClick={ () => this.setState({componentMoveDialogShow: true}) }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('transfer_component_balances') }
                        </ApButton>
                        : ''
                    }
                    

                </div>
                <div className="apFormColumn">
                    <dl className="dl-horizontal">
                        { getEditDd( 'date' ) }

                        <dt>{ tr('price_total') }</dt>
                        <dd> { formatMoney( this.state.totalPrice ) } { this.state.currencySign }</dd>
                    </dl>

                    <dl className="dl-horizontal">

                        { relatedDoms }

                        { getEditDd( 'reference_your', 'reference_number_your' ) }

                        { getEditDd( 'reference_our', 'reference_number_our' ) }

                        { getEditDd( 'need_date', 'day_of_need' ) }

                    </dl>
                    <dl className="dl-horizontal">
                        { getEditDdNotice( 'due_date' ) }

                        { getEditDdNotice( 'delivery_date' ) }
                    </dl>

                    <dl className="dl-horizontal">
                        <dt className='pointer' onClick={() => this.showEdit('details') }>{ tr('additional_info') }</dt>

                        <dd className={ editClass } onClick={() => this.showEdit('details') }><div className="pre-wrap">{ this.state.details }</div></dd>
                    </dl>
                    {acceptExtInterfaces}
                    
                    


                    {this.state.projectName ? <dl className="dl-horizontal">
                        <dt>{ tr('project') }</dt>
                        <dd className={ editClass } onClick={() => this.redirectToProject() }><div>{ this.state.projectName }</div></dd>
                        {this.state.sub_project_code ? 
                            <dd className={ editClass } onClick={() => this.redirectToProject() }><div>[{ this.state.sub_project_code }]</div></dd> 
                        : ''}
                    </dl> : ''}

                    {this.renderExternalExport()}
                    
                </div>
            </div>
        </div>

    }

    showEdit( field )
    {
        if( this.isLocked() ) return null;

        const fields = {
            details: { label: tr('additional_info'), title: tr('edit_additional_info'), type: 'textarea', required: true },
            need_date: { label: tr('day_of_need'), title: tr('edit_day_of_need'), type: 'datetimeV2ToSql' },
            date: { label: tr('date'), title: tr('edit_date'), type: 'datetimeV2ToSql' },
            reference_our: { label: tr('reference_number_our'), title: tr('edit_reference_number_our'), type: 'text' },
            reference_your: { label: tr('reference_number_your'), title: tr('edit_reference_number_your'), type: 'text' },
            supplier_details: { label: tr('supplier_additional_info'), title: tr('edit_supplier_additional_info'), type: 'textarea' },

            due_date: { label: tr('due_date'), title: tr('edit_due_date'), type: 'datetimeV2ToSql', required: true },
            delivery_date: { label: tr('delivery_date'), title: tr('edit_delivery_date'), type: 'datetimeV2ToSql', required: true },

        };

        this.setState({
            fieldEditShow: true,
            fieldEditField: field,

            fieldEditLabel: fields[ field ].label,
            fieldEditType: fields[ field ].type,
            fieldEditTitle: fields[ field ].title,
            fieldEditValue: this.state[ field ],
            fieldEditRequired: Boolean( fields[ field ].required ),
        });
    }

    fieldEdit( field, value )
    {
        this.setState({ [ field ]: value });
    }

    receiverEditSave( data = null )
    {
        if( this.isLocked() ) return null;

        const prev = this.state.receiver;

        if( data ) {
            this.setState( data );
            this.setState({sub_project_code: data.receiverSubProjectCode});
            if (data.receiver_changed) {
                this.attachToProject(data, this.state.id);

                //TODO: Fix: if target location is changed. If that is still necessary in the future.
                // next code try move arrived component to new location.
                
                // if (this.state.audits.length > 0) {
                //     const action = this.state.audits[0].action;
    
                //     if (action == 'confirmed' || action == 'arriving_edit' || action == 'arrival' || action == 'paid' || action == 'arrived_all') {
                //         this.setState({
                //             validAction: true,
                //             componentMoveButtonShow: true,
                //         })
                //     }
                
                // }
            }
        }
        
        this.setState({ receiverEditShow: false });
    }

    showReceiverEdit()
    {
        if( this.isLocked() ) return null;
        this.setState({ receiverEditShow: true })
    }

    accpetInvoice(type) {
        if (this.state.acceptInvoice) {
            api({
                method: 'post',
                url: `storage/po/acceptInvoice`,
                data: {
                    type: type,
                    po_id:this.state.id
                }
            }).then((response) => {
                let gotError = false;
                if (response.errors.length > 0) {
                    gotError = true;
                    for (const error of response.errors) {
                        console.log(error);
                        errorPopper(null,error);
                    }
                }
                this.setState({ acceptInvoice: gotError });
            }).catch((error) => {
                console.log(error);
                errorPopper(error, tr('save_error'));
                this.setState({ loading: false });
            });
            
        }
        
    }

    renderReceiverEdit()
    {
        if( this.isLocked() ) return null;

        return <ReceiverModal
            show={ this.state.receiverEditShow }
            handleClose={ this.receiverEditSave }

            receiver={ this.state.receiver }
            receiver_type={ this.state.receiver_type }
            receiver_id={ this.state.receiver_id }
            receiver_name={ this.state.receiver_name }
            receiver_address={ this.state.receiver_address }
            receiver_zipcode={ this.state.receiver_zipcode }
            receiver_city={ this.state.receiver_city }
            receiver_country={ this.state.receiver_country }
            receiver_contact_name={this.state.receiver_contact_name}
            receiver_project_id={this.state.project_id}
            receiver_title={this.state.receiver_title}
        />

    }

    renderFieldEdit()
    {
        return <div className="fieldEdit">
            <ApModalInput
                show={ this.state.fieldEditShow }
                title={ this.state.fieldEditTitle }
                value={ this.state.fieldEditValue }
                label={ this.state.fieldEditLabel }
                required={ this.state.fieldEditRequired }

                type={ this.state.fieldEditType }
                onSave={ ( value ) => this.fieldEdit( this.state.fieldEditField, value ) }
                onClose={ () => this.setState({ fieldEditShow: false }) }
            />
        </div>
    }

    showRelatedEdit( field, title )
    {
        if( this.isLocked() ) return null;

        this.setState({
            relatedEditShow: true,
            relatedEditField: field,
            relatedEditTitle: title,
            relatedEditValue: this.state[ `${ field }_id` ],
            relatedEditOptions: this.state[ field ],
            relatedEditRequired: this.state[ `${ field }_required` ],
        })
    }

    renderRelatedEdit()
    {
        return <RelatedSelectModal
            show={ this.state.relatedEditShow }
            handleClose={ this.relatedEditSave }

            field={ this.state.relatedEditField }
            title={ this.state.relatedEditTitle }
            value={ this.state.relatedEditValue }
            options={ this.state.relatedEditOptions }
            required={ this.state.relatedEditRequired }
        />
    }

    relatedEditSave( data = null )
    {
        if( data )
            this.setState( data );
        this.setState({ relatedEditShow: false });
    }

    isLocked()
    {
        if (this.state.deleted) return true;
        if( !this.state.status_name ) return true;
        if(  this.state.archived ) return true;
        return [ 'approval', 'order_send' ].includes( this.state.status_name );
    }

    saveInternalComment()
    {
        this.setState({ loading: true });

        return api({
            method: 'post',
            url: `storage/po/id/${ this.state.id }/internalcomment`,
            data: { internal_comment: this.state.internal_comment },
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('save_successful') }</strong>,
            });
            this.setState({
                audits: response.audits,
                loading: false,
            })
        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });
    }

    renderInternalComment()
    {
        return <div className="commentContainer">
            <ApInput
                type="textarea"
                id="comment"
                name="comment"
                label={ tr('internal_comment') }
                autoComplete="off"
                value={ this.state.internal_comment ? this.state.internal_comment : '' }
                onChange={  ( e ) => this.setState( { internal_comment: e.target.value } ) }
                loading={ this.state.loading }
            />
            <div className="internalCommentSave" onClick={ this.saveInternalComment }>
                <ApTooltip text={ tr('save_internal_comment') } block>
                    <SvgIcon icon="save" type="solid" />
                </ApTooltip>
            </div>
        </div>
    }

    handleUnitSelect(c, unitId) {
        const components = this.state.components.slice();
        const foundComponent = components.find(component => component.id == c.id);
        if (foundComponent) {
            let prevConversionFactor = null;
            if (foundComponent.selectedUnit) {
                prevConversionFactor = foundComponent.selectedUnit.conversion_factor;
            }
            foundComponent.selectedUnit = foundComponent.conversion_units.find(item => item.id == unitId);
            if (prevConversionFactor) {
                foundComponent.count = foundComponent.count 
                    ? Number((parseFloat(foundComponent.count) / parseFloat(prevConversionFactor)).toFixed(5))
                    : '';
                foundComponent.purchase_order_price = foundComponent.purchase_order_price 
                    ? parseFloat(foundComponent.purchase_order_price) * parseFloat(prevConversionFactor) 
                    : '';
            }
            if (foundComponent.selectedUnit) {
                foundComponent.count = foundComponent.count 
                    ? Number(parseFloat(foundComponent.count) * parseFloat(foundComponent.selectedUnit.conversion_factor).toFixed(5))
                    : '';
                foundComponent.purchase_order_price = foundComponent.purchase_order_price 
                    ? parseFloat(foundComponent.purchase_order_price) / parseFloat(foundComponent.selectedUnit.conversion_factor) 
                    : '';
            }
            this.setState({components});
        }
    }
    removePurchaseOrders() {

        api({
            method: 'post',
            url: `storage/po/deleteByIds`,
            data: {
                ids: [this.state.id],
            },
        }).then((response) => {
            //setPoList(response.data)
            this.getPurchaseOrder('id', this.state.id);
        }).catch((error) => {
            console.error(error)
        })


    }
    returnDeleted() {

        api({
            method: 'post',
            url: `storage/po/returnDeletedByIds`,
            data: {
                ids: [this.state.id],
            },
        }).then((response) => {
            //setPoList(response.data)
            this.getPurchaseOrder('id', this.state.id);
        }).catch((error) => {
            console.error(error)
        })


    }

    render()
    {
        //console.log("state",this.state);
        if( this.state.error )
            return <div  className="apErrorMsg">{ this.state.error }</div>

        const locked = this.isLocked();

        let instanceTableInfo = null;
        if( !locked )
        {
            instanceTableInfo = <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('po_instance_table_info') }
            </div>
        }

        let sentFileDom = null;
        if( this.state.sent_file_id )
        {
            sentFileDom = <File  fileId={ this.state.sent_file_id }>
                <ApButton color="white"
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                >
                    <SvgIcon icon="file" type="solid" />
                    { tr('download_file_sent_to_supplier') }
                </ApButton>
            </File>
        }

        let confirmFileDom = null;
        if( this.state.confirm_file_id )
        {
            confirmFileDom = <File fileId={ this.state.confirm_file_id }>
                <ApButton color="white"
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                >
                    <SvgIcon icon="file" type="solid" />
                    { tr('download_order_confirmation_file') }
                </ApButton>
            </File>
        }

        const actions = [];

        if (this.state.deleted) {
            actions.push({
                label: tr('return'),
                icon: "recycle",
                //disabled: this.state.selectedKeys == 0,
                action: () => this.returnDeleted(),
                closeAfter: true,
            });
        }
        else {
            actions.push({
                label: tr('delete'),
                icon: "trash",
                //disabled: this.state.selectedKeys == 0,
                action: () => this.setState({ showDeleteConfirm: true }),
                closeAfter: true,
            });
        }

        const receiver_change_data = {
            previous_rec: this.state.original_receiver,
            current_rec: this.state.receiver,
            components: this.state.components,
            poId: this.state.id,

            receiver_id: this.state.receiver_id,
            receiver_type: this.state.receiver_type,
            receiver_name: this.state.receiver_name,
            receiver_address: this.state.receiver_address,
            receiver_zipcode: this.state.receiver_zipcode,
            receiver_city: this.state.receiver_city,
            receiver_country: this.state.receiver_country,
        };

        let vatOptions = [];
        if (this.props.apOrderSettings && this.props.apOrderSettings.vats) {
            vatOptions = this.props.apOrderSettings.vats;
        }

        return <div id="pagePo">
				<div className="pageBackButton" onClick={ () => window.emitter.emit( 'goTo', { pathname: '/storage/purchase/orders' } ) }>
                <SvgIcon icon="arrow-left" type="solid" size="small" />
                { tr('all_purchase_orders') }
                </div>
				{sessionStorage.getItem('import') 
                ? <div style={{marginTop: '1rem'}}><div className="pageBackButton" onClick={ () => window.emitter.emit( 'goTo', { pathname: '/storage/purchase/orders/import' } ) }>
                    <SvgIcon icon="arrow-left" type="solid" size="small" />
                    { tr('import_pos') }
                </div></div> 
                : null}
        <Measure
            onResize={ () => {
                if( this.stickyBar )
                    this.stickyBar.updateBounds();
            }}
        >
        {({ measureRef }) => <div  ref={ measureRef } className="apBox">
                <div className="apBoxHeader">
                    <h1>
                        { tr('purchase_order') }
                    </h1>
                    {this.state.loading ? <p>{ tr('loading') }</p> : <p>{ tr('manage_po') }</p>}
                    <div className='edit-po-navigation-buttons'>
                        {this.state.previousItem ? 
                            <ApButton onClick={this.goToPreviousItem} color='blue' className='previous-button'>
                                <SvgIcon icon="arrow-left" type="solid" size="small" />
                                { tr('previous') }
                            </ApButton> : null}
                        {this.state.nextItem ? 
                            <ApButton onClick={this.goToNextItem} color='blue' className='next-button'>
                                { tr('next') }
                                <SvgIcon icon="arrow-right" type="solid" size="small" />
                            </ApButton>: null
                        }
                    </div>
                </div>
                <div className="padding">
                    <div style={{overflowX: 'auto'}}>
                    <div style={{minWidth: 950}}>
                    <Steps
                        status={ this.state.status_name }
                        acceptors={ this.state.acceptors }
                        arrivals={ this.state.arrivals }
                        paid={ this.state.paid }
                        archived={ this.state.archived }
                    />
                    </div>
                    </div>

                    <div style={{overflowX: 'auto'}}>
                    <div className="storageSummaryAuditContainer">
                        <div className="summaryBlock">
                            { this.renderSummary() }
                        </div>
                        <div className="auditBlock">
                            <Audits audits={ this.state.audits } />
                        </div>
                    </div>
                    </div>

                    <div style={{overflowX: 'hidden'}}>
                    <div className="commentFilesContainer">
                        { this.renderInternalComment() }
                        <div className="filesContainer">
                            { sentFileDom }
                            { confirmFileDom }
                        </div>
                    </div>
                    </div>

                    <div className="apForm">
                        <h3 className="apFormHeader">{ tr('storage_components') }</h3>
                            <ComponentInstanceTable
                                componentsChange={ this.componentsChange }
                                components={ this.state.components }
                                ref={ref => { this.componentInstanceTableRef = ref }}
                                instance={ 'po' }
                                project_id = { this.state.project_id }
                                receiverType={ this.state.receiver_type }
                                receiverCode={ this.state.original_receiver ? this.state.original_receiver.code : null }
                                supplierId={ this.state.supplier_id }
                                supplierName={ this.state.supplier_name }
                                poId={ this.state.id }
                                vats={vatOptions}
                                useTaxGroups={this.props.apOrderSettings.use_tax_groups}
                                //poTargetLocked= { this.state.receiver_type=='project'}
                                poCountLocked={ locked }
                                poPriceLocked={ locked }
                                poTargetId= { this.state.receiver_id }

                                removeComponentLocked={ locked }
                                addComponentLocked={ locked }
                                handleUnitSelect={this.handleUnitSelect}
                                editableName={true}
                                showCodeInEditableName={true}
                            />
                            { instanceTableInfo }
                    </div>
                </div>

            <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar= node }>
                <div className="saveBar">
                    <div className="left">
                        { tr('price_total') }: { formatMoney( this.state.totalPrice ) } { this.state.currencySign }<br />
                        { tr('rows_count') }: { this.state.components.length }
                    </div>
                    <div className="right">
                        {!this.state.deleted&& <SaveBox
                            onSubmit={ this.saveBoxSubmit }
                            userIsCurrentAcceptor={ this.state.is_current_acceptor }
                            number={ this.state.number }
                            status={ this.state.status_name }
                            paid={ this.state.paid }
                            isImported={this.state.ext_imports.length > 0}
                            archived={ this.state.archived }
                            validatorMessage={ this.validator.getStatusAll() }
                                />}
                                
                            </div>
                            <ApDropdown
                                position="top"
                                actionId={55}
                                actions={actions}
                            />
                </div>
            </ApStickyBar>

            <CommentDialog
                show={ this.state.commentDialogShow }
                meta={ this.state.commentDialogMeta }
                due_date={ this.state.due_date }
                delivery_date={ this.state.delivery_date }
                selectAcceptor={ this.state.commentDialogAcceptor }
                onClose={ () => this.setState({ commentDialogShow: false }) }
                onSave={ this.commentDialogOnSubmit }
            />

            <NewOrder
                show={ this.state.newOrderShow }
                onClose={ () => { this.setState({ newOrderShow: false, copyId: null, isCreditNote: false }) } }
                onSave={ () => { } }
                selectProject={true}
                isCreditNote={this.state.isCreditNote}
                import_po_id={this.state.id}
                import_po_components={this.state.selected}
            />

            <SendOrder
                show={ this.state.sendDialogShow }
                number={ this.state.number }
                id={ this.state.id }
                onClose={ () => {this.setState({ sendDialogShow: false }) } }
                onSubmit={ this.sendSubmit }
                contactEmails={this.state.contactEmails}
            />

            <ConfirmOrder
                show={ this.state.confirmDialogShow }
                due_date={ moment(this.state.due_date, 'DD.MM.YYYY').format('YYYY-MM-DD') }
                delivery_date={ moment(this.state.delivery_date, 'DD.MM.YYYY').format('YYYY-MM-DD') }
                onClose={ () => {this.setState({ confirmDialogShow: false }) } }
                onSubmit={ this.confirmSubmit }
            />

            <ComponentMove
                show={ this.state.componentMoveDialogShow }
                data={receiver_change_data}
                onClose={ () => {this.setState({ componentMoveDialogShow: false }) } }
                onSave={ (response) => this.confirmStorageMove(response) }
            />

            <ComponentsMoved
                show={ this.state.validComponentsDialogShow }
                data={this.state.movedComponents}
                onClose={ () => this.confirmedMove() }
                onSave={ () => this.confirmedMove() }
            />
            <ApConfirm
                show={this.state.showDeleteConfirm}
                header={tr("purchase_order_delete_confirm")}
                body={tr("purchase_order_delete_info")}
                onConfirm={() => { this.removePurchaseOrders() }}
                onClose={() => { this.setState({ showDeleteConfirm: false }) }}
            />

            <ApModal
                closeFromBg
                show={ this.state.showAttachmentModal }
                handleClose={ this.closeAttachmentModal }
                header={
                    <div className="padding">
                        <h4>
                            { tr('files') }
                        </h4>
                    </div>
                }
                body={ 
                    <div className="padding">
                        {this.state.attachmentLoading ? <div className='apLoader' /> : null}
                        {(!this.state.attachmentLoading && Array.isArray(this.state.attachments) && !this.state.attachments.length) ? tr('no_invoice_attachments') : null}
                        {(!this.state.attachmentLoading && Array.isArray(this.state.attachments) && this.state.attachments.length) 
                        ? this.state.attachments.map((file, index) => {
                            if (file.netvisor) 
                                return renderNetvisorAttachment(file, index);

                            return (
                                <iframe key={index} src={file.netvisor ? 'data:application/pdf;base64,' + file.base64Data : file} width="100%" height="1000" type="application/pdf"></iframe>
                            );
                        }) 
                        : null}
                    </div>
                }
                footer={ 
                    <div className="padding">
                        <ApButton onClick={ this.closeAttachmentModal }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>
                    </div> 
                }
            />

        </div>
        }
        </Measure>

        { this.renderFieldEdit() }
        { this.renderReceiverEdit() }
        { this.renderRelatedEdit() }
        </div>
    }
}

const mapStateToProps = state => ({
    apOrderSettings: state.apOrderSettings
});

export default connect(mapStateToProps)(EditPurchaseOrder);

