export const companyOnlineUsersReducer = (state = {}, action) => {
    let users;
    switch (action.type) {
        case 'here':
            return {
                users:action.payload,
            }
        case 'join':
            users = state.users;
            users.push(action.payload);
            return {
                users,
            }
        case 'leave':
            users = state.users;
            users = users.filter(filterUser => filterUser.id != action.payload.id);
            return {
                users,
            }
        default:
            return state;
    }
}