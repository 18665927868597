import ApModalWizard from 'common/ApModalWizard/ApModalWizard';
import ApSelect from 'common/ApSelect/ApSelect';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import React from 'react';
import autobind from 'react-autobind';
import { errorPopper, tr } from 'services/Helpers/Helpers';
import ComponentInstanceTable from '../../common/ComponentInstanceTable/Cit';
import api from 'services/Api/Api.js';

import "./NewTransport.css";
import ApValidate from 'services/ApValidate/ApValidate';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';
import ApButton from 'common/ApButton/ApButton';
import ApTooltip from 'common/ApTooltip/ApTooltip';

class NewTransport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            sendingStorage: null,
            sendingStorageIsProject: false,
            receivingStorage: null,
            receivingStorageIsProject: false,

            components: [],
        };

        this.steps = [
            {
                name: tr('storages'),
                render: this.storageSelectionStep.bind(this),
                invalid: () => this.validator.getText( 'receivingStorage' ) || this.validator.getText( 'sendingStorage' ),
            },
            {
                name: tr('storage_components'),
                render: this.componentSelectionStep.bind(this),
                invalid: () => this.validator.getText('components'),
            },
        ];

        this.validator = new ApValidate( this, {            
            sendingStorage: { filter: value => {
                return Boolean(this.state.sendingStorage)
            }, text: tr('sending_storage_required')},

            receivingStorage: { filter: value => {
                return Boolean(this.state.receivingStorage)
            }, text: tr('receiving_storage_required')},

            components: {filter: value => {
                for (let c of value) {
                    if (!c.delivery_from_id || !c.delivery_to_id) {
                        return false;
                    }
                }
                return true;
            }, text: tr('sender_and_receiver_required')}
        });

        autobind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.show && prevProps.show != this.props.show && this.props.data) {
            this.setState({
                sendingStorage: this.props.data.sendingStorage,
                sendingStorageIsProject: this.props.data.sendingStorage && this.props.data.sendingStorage.type === 'P'
                    ? true : false,

                receivingStorage: this.props.data.receivingStorage,
                receivingStorageIsProject: this.props.data.receivingStorage && this.props.data.receivingStorage.type === 'P'
                    ? true : false,
            }, this.init);
        }

        if (prevProps.show && prevProps.show != this.props.show) {
            this.setState({components: []});
        }

    }

    // Muuta komponentit tarvittavaan muotoon, hae ylimmän tason varastot
    init() {
        const components = this.props.data.components && this.props.data.components.map(component => {
            component.id = component.component_id;
            return component;
        });
        if (!Array.isArray(components)) return;

        this.setState({loading: true});
        api({
            url: 'storage/delivery/initDelivery',
            method: 'post',
            data: {
                senderId: this.state.sendingStorage ? this.state.sendingStorage.id : null,
                receiverId: this.state.receivingStorage ? this.state.receivingStorage.id : null,
                components: components,
            }
        }).then(response => {
            // console.log("RES", response, components);

            const formattedComponents = response.components 
                ? response.components.map(component => {
                    const foundComponent = components.find(c => c.component_id === component.component_id)
                    const firstLocation = component.locations && component.locations[0];

                    component.id = component.component_id;
                    component.delivery_from_id = firstLocation ? firstLocation.id : null;
                    component.delivery_from_type = 'location'
                    component.delivery_count = Number(foundComponent.count) || 0;
                    return component;
                })
                : [];

            this.setState({
                loading: false,
                sendingStorage: response.sender,
                receivingStorage: response.receiver,
                components: formattedComponents,
            });
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        });
    }

    setStorage(value, target) {
        if (target === 'sendingStorageIsProject' || target === 'receivingStorageIsProject') {
            value === 'true' ? value = true : value = false;
        }
        this.setState({ [target]: value, components: [] })
    }

    storageSelectionStep() {
        return <div>
            <h4 className='apFormHeader'>{tr('sending_storage')}:</h4>
            <ApInputStack gap="0">
                <ApAddon
                    noRightBorder width="200px"
                >
                    { tr('sender') }
                </ApAddon>
                <ApInput
                    type="select"
                    options={ [
                        { value: false, label: tr('storage') },
                        { value: true, label: tr('project') },
                    ] }
                    id="sender"
                    name="sender"
                    value={ this.state.sendingStorageIsProject }
                    onChange={ e => this.setStorage(e.target.value, 'sendingStorageIsProject') }
                />
            </ApInputStack>
            <ApSelect
                value={this.state.sendingStorage ? this.state.sendingStorage.name : ''}
                label={tr('sending_storage')}
                optionRenderer="storage_location"
                onChange={(val) => this.setStorage(val, 'sendingStorage')}
                objKeyId="id"
                clearable
                apiUrl="storage/locations/find"
                apiData={{
                    canReceive: true,
                    projectStorage: this.state.sendingStorageIsProject,
                }}
            />
            <h4 className='apFormHeader'>{tr('receiving_storage')}:</h4>
            <ApInputStack gap="0">
                <ApAddon
                    noRightBorder width="200px"
                >
                    { tr('recipient') }
                </ApAddon>
                <ApInput
                    type="select"
                    options={ [
                        { value: false, label: tr('storage') },
                        { value: true, label: tr('project') },
                    ] }
                    id="recipient"
                    name="recipient"
                    value={ this.state.receivingStorageIsProject }
                    onChange={ e => this.setStorage(e.target.value, 'receivingStorageIsProject') }
                />
            </ApInputStack>
            <ApSelect
                value={this.state.receivingStorage ? this.state.receivingStorage.name : ''}
                label={tr('receiving_storage')}
                optionRenderer="storage_location"
                onChange={(val) => this.setStorage(val, 'receivingStorage')}
                objKeyId="id"
                clearable
                apiUrl="storage/locations/find"
                apiData={{
                    canReceive: true,
                    projectStorage: this.state.receivingStorageIsProject,
                }}
            />
        </div>
    }

    componentSelectionStep() {
        return <div className='componentSelectionStep'>
            <div className='justify-end'>
                <ApTooltip text={tr('send_all_transfer_info')} position='bottom'>
                    <ApButton 
                        onClick={this.setFullBalanceAll}
                        color='green'>
                        {tr('send_all')}
                    </ApButton>
                </ApTooltip>
                <ApTooltip text={tr('select_all_components_transfer_info')} position='bottomright'>
                    <ApButton 
                        onClick={this.getAllComponents}
                        color='green'>
                        {tr('select_all_components')}
                    </ApButton>
                </ApTooltip>
            </div>
            <ComponentInstanceTable
                type={'delivery'}
                orderType={'delivery'}
                componentsChange={this.componentsChange}
                components={this.state.components}

                instance={'delivery'}

                loading={this.state.loading}

                deliverySenderId={this.state.sendingStorage && this.state.sendingStorage.id}
                deliverySenderType={this.state.sendingStorage && this.state.sendingStorage.type === 'P' ? 'project_storage' : 'location'}

                deliveryReceiverId={this.state.receivingStorage && this.state.receivingStorage.id}
                deliveryReceiverType={this.state.receivingStorage && this.state.receivingStorage.type === 'P' ? 'project_storage' : 'location'}

                // removeComponentLocked={ this.state.assignment.status_id >= 7 }
                // addComponentLocked={ this.state.assignment.status_id >= 7 }
            />
        </div>
    }

    componentsChange(components) {
        this.setState({ components });
    }

    save() {
        const sender_id = this.state.sendingStorage.id;
        const receiver_id = this.state.receivingStorage.id;
        const components = this.state.components.map(c => {
            if (!c.delivery_to_id) {
                c.delivery_to_id = this.state.receivingStorage.id;
            }
            if (!c.delivery_from_id) {
                c.delivery_from_id = this.state.sendingStorage.id;
            }
            return c;
        });

        this.setState({loading: true});
        api({
            url: 'storage/delivery/saveStorageTransport',
            method: 'POST',
            data: {
                sender_id,
                receiver_id,
                components,
            }
        }).then(response => {
            this.setState({loading: false});
            sessionStorage.setItem('transportId', response);
            window.emitter.emit('goTo', {pathname: '/storage/transports/edit'});
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        })
    }

    setFullBalanceAll() {
        const components = this.state.components.slice(0);
        components.map(c => this.setFullBalance(c));
        this.setState({components});
    }

    setFullBalance(component) {
        const foundComponent = this.state.components.find(c => c.id === component.id);
        if (foundComponent) {
            const foundSender = foundComponent.locations.find(location => location.id === component.delivery_from_id);
            if (foundSender) {
                component.delivery_count = foundSender.balance;
            }
        }
        return component;
    }

    getAllComponents() {
        if (!this.state.sendingStorage) return;
        const locationId = this.state.sendingStorage.id;
        this.setState({loading: true});

        api({
            url: `storage/delivery/${locationId}/getAllComponents`,
            method: 'GET'
        }).then(response => {
            // console.log("response", response);
            if (response.length) {
                const components = response.map((component, index) => {
                    component.id = `cnew${index}`;

                    const foundSender = component.locations.find(location => {
                        if (location.delivery_locations) {
                            for (let dl of location.delivery_locations) {
                                if (this.state.sendingStorage.id === dl.id) return true;
                            }
                        }
                        return false;
                    });
                    const foundReceiver = component.locations.find(location => {
                        if (location.delivery_locations) {
                            for (let dl of location.delivery_locations) {
                                if (this.state.receivingStorage.id === dl.id) return true;
                            }
                        }
                        return false;
                    });

                    if (this.state.sendingStorageIsProject) {
                        component.delivery_from_id = this.state.sendingStorage.id;
                        component.delivery_from_type = 'project';
                        component.delivery_count = foundSender ? foundSender.balance : 0;
                    } else if (foundSender) {
                        component.delivery_from_id = foundSender.id;
                        component.delivery_from_type = 'location';
                        component.delivery_count = foundSender ? foundSender.balance : 0;
                    }

                    if (this.state.receivingStorageIsProject) {
                        component.delivery_to_id = this.state.receivingStorage.id;
                        component.delivery_to_type = 'project';
                    } else if(foundReceiver) {
                        component.delivery_to_id = foundReceiver.id;
                        component.delivery_to_type = 'location';
                    }

                    return component;
                })
                this.setState({components});
            }
            this.setState({loading: false});

        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        })
    }

    render() {
        return (
            <div id='new-transport-modal'>
                <ApModalWizard
                    header={tr('prepare_storage_transfer')}
                    onOpen={this.resetState}
                    show={this.props.show}
                    steps={this.steps}
                    onClose={this.props.onClose}
                    onSave={this.save}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

export default NewTransport;