const initialState = {
    status: "stopped",
    dayIsLocked: false,
    lastTimeAttendanceSavePoint: null,
    isSignedInTimeAttendance: false,
}
export const timerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'STATUS_CHANGE':
            return {
                ...state,
                status: action.payload,
            }
        case 'DAY_IS_LOCKED':
            return {
                ...state,
                dayIsLocked: action.payload,
            }
        case 'SET_TIME_ATTENDANCE':
            return {
                ...state,
                lastTimeAttendanceSavePoint: action.payload.lastTimeAttendanceSavePoint,
                isSignedInTimeAttendance: action.payload.isSignedInTimeAttendance,
            }
        default:
            return state;
    }
}