import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import { tr } from 'services/Helpers/Helpers.js';
import { ApInput } from 'common/ApInput/ApInput.js';
// import ApButton from 'common/ApButton/ApButton.js';

export default class GeneralSettings extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			titleText: ""
		};

		autoBind(this);

	}

	UNSAFE_componentWillReceiveProps(props)
	{

		//this.getSettings();
		//console.log(props.data);

		this.setState({ titleText: props.data.title_text });

	}

	getSettings()
	{

		api({
			method: 'get',
			url: 'crm/extranet/settings'
		}).then((response) => {
			//console.log(response);
			this.setState({ titleText: response.title_text });
		}).catch((error) => {
			console.log(error);
		});

	}

	saveChanges()
	{

		api({
			method: 'post',
			url: 'crm/extranet/saveSettings',
			data: { titleText: this.state.titleText }
		}).then((response) => {
			//console.log(response);
		}).catch((error) => {
			console.log(error);
		});

	}

	handleChange(e)
	{

		this.setState({ [e.target.name]: e.target.value });
		this.props.onChange(e.target.name, e.target.value);

	}

	render()
	{

		return(

			<div className="apBox">

				<div className="apBoxHeader">
					<h1> { tr('general_settings') } </h1>
				</div>

				<div className="clear padding">
					<div className="apColumn w50">
						<ApInput
							type="textarea"
							name="titleText"
							id="titleText"
							label={ tr('frontpage_welcome_text') }
							value={this.state.titleText}
							onChange={this.handleChange}
						/>
					</div>			
				</div>

			</div>

		);

	}

}
