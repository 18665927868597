import React, { useState } from "react";

import ApSwitch from "common/ApSwitch/ApSwitch";
import SvgIcon from "common/SvgIcon/SvgIcon.js";
import { tr } from "services/Helpers/Helpers";

import styles from "./MetersTable.module.css";

const MetersTable = (props) => {
  const [showUnit, setShowUnit] = useState(false);

  const foundCalculatableIds = [];
  const foundExtraCalculatableIds = [];
  const tableHeaders = [];
  if (props.items.length > 0) {
    props.items.forEach((item) => {
      if (item.component == undefined) return;
      item.component.calculatables.forEach((calculatable) => {
        if (!foundCalculatableIds.includes(calculatable.id)) {
          foundCalculatableIds.push(calculatable.id);
          tableHeaders.push(
            <th key={calculatable.id} className="text-right">
              {calculatable.name}
            </th>
          );
        }
      });

      item.component.component_extra_calculatables.forEach(calculatable => {
        if (!foundExtraCalculatableIds.includes(calculatable.extra_calculatable_id)) {
          if (!calculatable.extra_calculatable) return;
          foundExtraCalculatableIds.push(calculatable.extra_calculatable_id);
          tableHeaders.unshift(
            <th key={calculatable.extra_calculatable_id} className="text-right">
              {calculatable.extra_calculatable.name}
            </th>
          );
        }
      })

    });
  }

  const toggleHandler = () => {
    setShowUnit((state) => !state);
  };

  const calculateExtraCalcValue = (extraCalc, calc_1, calc_2, multiplier) => {
    const calc_1_value = (parseFloat(calc_1.pivot.value) * multiplier);
    const calc_2_value = (parseFloat(calc_2.pivot.value) * multiplier);

    let value;
    switch (extraCalc.extra_calculatable.operator) {
      case '/':
        value = parseFloat(calc_1_value) / parseFloat(calc_2_value);
        return value.toFixed(2) + ' ' + extraCalc.extra_calculatable.unit;
    
      case '*':
        value = parseFloat(calc_1_value) * parseFloat(calc_2_value);
        return value.toFixed(2) + ' ' + extraCalc.extra_calculatable.unit;

      case '+':
        value = parseFloat(calc_1_value) + parseFloat(calc_2_value);
        return value.toFixed(2) + ' ' + extraCalc.extra_calculatable.unit;

      case '-':
        value = parseFloat(calc_1_value) - parseFloat(calc_2_value);
        return value.toFixed(2) + ' ' + extraCalc.extra_calculatable.unit;
      
      default:
        return null;
    }
  }

  const renderRows = () => {
    return props.items.map((item) => {
      const multiplier = showUnit ? 1 : parseFloat(item.count);
      const valueCells = foundCalculatableIds.map((id) => {
        if (item.component == undefined)
          return <td key={item.id + item.name} className="text-right"></td>;
        const foundItem = item.component.calculatables.find((c) => id === c.id);
        if (foundItem) {
          if (isNaN(multiplier)) {
            return (
              <td
                key={foundItem.name + foundItem.unit}
                className="text-right"
              ></td>
            );
          }
          return (
            <td key={foundItem.name + foundItem.unit} className="text-right">
              {(parseFloat(foundItem.pivot.value) * multiplier).toFixed(2)}
              {foundItem.unit}
            </td>
          );
        } else {
          return (
            <td
              key={item.name + id}
              className="text-right"
            ></td>
          );
        }
      });
      foundExtraCalculatableIds.forEach(id => {
        if (!item.component)
          valueCells.unshift( <td key={item.id + item.name} className="text-right"></td> );

        const foundItem = item.component && item.component.component_extra_calculatables.find((c) => id === c.extra_calculatable_id);
        let foundCalculatable1 = null;
        let foundCalculatable2 = null;
        if (foundItem) {
          foundCalculatable1 = item.component.calculatables.find(
            (c) => foundItem.extra_calculatable?.calculatable_1_id === c.id
          );
          foundCalculatable2 = item.component.calculatables.find(
            (c) => foundItem.extra_calculatable?.calculatable_2_id === c.id
          );
        }
        if (foundCalculatable1 && foundCalculatable2) {
          const multiplier = showUnit ? 1 : parseFloat(item.count);
          if (isNaN(multiplier)) {
            valueCells.unshift((
              <td
                key={item.name + id}
                className="text-right"
              ></td>
            ));
          }

          valueCells.unshift((
            <td key={foundItem.extra_calculatable.name + foundItem.extra_calculatable.id} className="text-right">
              {calculateExtraCalcValue(foundItem, foundCalculatable1, foundCalculatable2, multiplier)}
            </td>
          ));
        } else {
          valueCells.unshift((
            <td
              key={item.name + id}
              className="text-right"
            ></td>
          ));
        }
      });
      return (
        <tr key={item.id}>
          <td>
            <SvgIcon icon="puzzle-piece" type="solid" className="icon" />
          </td>
          <td>{item.name}</td>
          <td className="text-right">
            {item.count} {item.component && item.component.unit}
          </td>
          {valueCells.reverse()}
        </tr>
      );
    });
  };

  const renderTotalsRow = () => {
    let totals = {};
    props.items.forEach((item) => {
      const count = showUnit ? 1 : parseFloat(item.count);
      if (item.component == undefined) {
        return;
      } else {
        item.component.calculatables.forEach((c) => {
          if (totals[c.name]) {
            totals[c.name].value += parseFloat(c.pivot.value) * count;
          } else {
            totals[c.name] = {
              unit: c.unit,
              value: parseFloat(c.pivot.value) * count,
            };
          }
        });
      }
    });

    let valueCells = [];
    for (const item in totals) {
      if (isNaN(totals[item].value)) {
        valueCells.push(<td key={item} className="text-right"></td>);
      } else {
        valueCells.push(
          <td key={item} className="text-right">
            {totals[item].value.toFixed(2)} {totals[item].unit}
          </td>
        );
      }
    }

    foundExtraCalculatableIds.forEach(item => {
      valueCells.unshift(<td key={'extra_calc_' + item} className="text-right"></td>);
    })

    return (
      <tr>
        <td></td>
        <td>{tr("total")}</td>
        <td></td>
        {valueCells.reverse()}
      </tr>
    );
  };

  return (
    <div className={styles.metersTableContainer}>
      <div className={styles.metersTableHeader}>
        <h5>{tr("meters")}</h5>
        <div className={styles.toggleContainer}>
          <label htmlFor="totalsCount" className="info">
            <small>{tr("unit")}</small>
          </label>
          <ApSwitch
            id={"totalsCount"}
            small
            on={showUnit}
            onChange={toggleHandler}
            disabled={false}
          />
        </div>
      </div>
      <table className="componentTable">
        <thead>
          <tr className="componentHeader">
            <th></th>
            <th>{tr("name")}</th>
            <th className="text-right">{tr("amount")}</th>
            {props.items.length > 0 && tableHeaders.reverse()}
          </tr>
        </thead>
        <tbody>
          {renderRows()}
          {renderTotalsRow()}
        </tbody>
      </table>
    </div>
  );
};

export default MetersTable;
