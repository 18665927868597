import React from 'react';
import {Switch, Route} from 'react-router-dom'
import routes from 'routes/public.js'
import Notfound from 'layout/Notfound/Notfound.js'
import Popper from 'layout/Popper/Popper.js';

class PublicLayout extends React.Component {

    UNSAFE_componentWillMount()
    {
        document.title = (this.props.title) ? 'EASY-PRO :: '+this.props.title : 'EASY-PRO';
    }

    render() 
    {
        return (
            <div>
                <Popper />

                <Switch>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            exact={true}
                            path={route.path}
                            breadcrumbs={route.breadcrumbs}
                            title={route.title}
                            menu={route.menu}
                            component={route.component}
                        />
                    ))}
                <Route component={Notfound} />
            </Switch>
            </div>
        );
    }
}
export default PublicLayout
