import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import EditAssignmentClass from './EditAssignmentClass.js';

import ApButton from 'common/ApButton/ApButton.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

export default class AssignmentSettings extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			classes: [],
			columns: [],
			showEditModal: false
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		this.getClasses();
		//this.getSampleContent();
		this.getTableColumns();

	}

	getClasses()
	{

		api({
			method: 'get',
			url: 'assignment/getClasses'
		}).then((response) => {
			let data = response;
			for (var i = 0; i < response.length; i++)
			{
				data[i].subclasses = JSON.parse(response[i].subclasses);
			}
			this.setState({ classes: response })
		}).catch((error) => {
			console.log(error);
		});

	}

	getSampleContent()
	{

		const assignments = [
			{
				id: 1,
				name: 'Ventus',
				description: 'Ventukseen liittyvät ongelmat',
				receiver_group: 'Esimiehet',
				subclasses: 2
			},
			{
				id: 2,
				name: 'ATK-tukipyyntö',
				description: 'Kaikki ATK-tukipyynnöt',
				receiver_group: 'IT-tiimi',
				subclasses: 4
			},
			{
				id: 3,
				name: 'Portaali',
				description: 'Portaaliin liittyvät pyynnöt',
				receiver_group: 'Esimiehet',
				subclasses: 8
			}
		];

		this.setState({ assignments });

	}

	getTableColumns()
	{

		const columns = [
			{
				id: 'name',
				Header: 'nimi',
				accessor: 'name'
			},
			{
				id: 'description',
				Header: 'Kuvaus',
				accessor: 'description'
			},
			{
				id: 'subclasses',
				Header: 'Alaluokkia',
				Cell: (props) => {
					return (
						props.original.subclasses !== null ? props.original.subclasses.length : 0
					)
				}
			}
		];

		this.setState({ columns });

	}

	showClass(row)
	{

		this.setState({ selectedClassId: row.id, showEditModal: true });

	}

	hideClass()
	{

		this.setState({ selectedClassId: null, showEditModal: false }, () => {
			this.getClasses();
		});

	}

	render()
	{

		return(

			<div id="assignmentSettings" className="apBox">
				
				<div className="apBoxHeader">
					<h1> Toimeksiantoasetukset </h1>
					<p> Hallitse toimeksiantoluokkia ym. niihin liittyviä asetuksia </p>
				</div>
				
				<div className="padding">

					<div className="apBoxHeader">
						<h3> Toimeksiantoluokat </h3>
					</div>

					<div style={{textAlign: 'right', paddingTop: '8px'}}>
						<ApButton 
							color="green" 
							size="small"
							onClick={this.showClass}
						> 
							Uusi toimeksiantoluokka 
						</ApButton>
					</div>

					<ApReactTable
						data={this.state.classes}
						columns={this.state.columns}
						rowActions={ (row) => 
							<div className="apSimpleButton" onClick={ () => this.showClass(row) }> 
									<SvgIcon icon="arrow-right" type="solid" /> 
							</div>
						}
					/>
				</div>

				{this.state.showEditModal &&
				<EditAssignmentClass
					show={this.state.showEditModal}
					classId={this.state.selectedClassId}
					onClose={this.hideClass}
				/>
				}

			</div>

		);

	}

}