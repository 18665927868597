import React, {Component} from "react";

import ApButton from "common/ApButton/ApButton";
import ApModal from "common/ApModal/ApModal";
import SvgIcon from "common/SvgIcon/SvgIcon";

import { tr } from "services/Helpers/Helpers";
import { ApInput, ApInputStack } from "common/ApInput/ApInput";
import ApValidate from "services/ApValidate/ApValidate";
import api  from "services/Api/Api";
import moment from "moment";

class AddSubordinateHolidayModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            name: "",
            start_date: null,
            end_date: null,
        };

        this.validator = new ApValidate(this, {
            name: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
            start_date: [
                { filter: 'date', state: 'error', text: tr('mandatory') },
            ],
            end_date: [
                { filter: 'date', state: 'error', text: tr('mandatory') },
            ],
        });
    }

    clear() {
        this.setState({
            name: "",
            start_date: null,
            end_date: null
        });
    }

    handleSave() {
        const {name, start_date, end_date} = this.state;
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/holiday/saveholiday',
            data: {
                user_id: this.props.selectedUser,
                name: name,
                start: start_date,
                end: end_date
            },
        }).then((response) => {
            this.setState({ loading: false });
            this.clear();
            this.props.onClose();
            this.props.getUsers(this.props.selectedUser);
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });
        }).catch((error) => {
            this.setState({ loading: false });
            this.props.onClose();
            this.clear();
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('save_error') }</strong>,
            });
        });
    }

    renderHeader(){
        return (
            <div className="padding-small">
                <h4>{tr('new')}</h4>
            </div>
        )
    }

    renderBody() {
        return (
            <div className="padding">
                <ApInput
                    name="name"
                    id="name"
                    label={ tr('name') }
                    value={ this.state.name }
                    type="text"
                    onChange={ (e) => this.setState({ name: e.target.value }) }
                    validationState={ this.validator.getState('name') }
                />
                <ApInputStack>
                    <ApInput 
                        type="dateSafe" 
                        id="start_date" 
                        name="start_date" 
                        label={ tr('start_date') }
                        value={ this.state.start_date }
                        onChange={ (e) => this.setState({ start_date: e.target.value }) }
                        validationState={ this.validator.getState('start_date') }
                    />
                    <ApInput 
                        type="dateSafe" 
                        id="end_date" 
                        name="end_date" 
                        label={ tr('end_date') }
                        value={ this.state.end_date }
                        onChange={ (e) => this.setState({ end_date: e.target.value }) }
                        validationState={ this.validator.getState('end_date') }
                    />
                </ApInputStack>
            </div>
        )
    }

    renderFooter(saveIsValid) {
        return (
            <div style={{padding: '1em'}}>
                <ApButton
                    style={{float: 'left'}}
                    className="cancel"
                    onClick={this.props.onClose}
                >
                    <SvgIcon icon="times" type="solid" />
                    { tr('cancel') }
                </ApButton>
                
                <ApButton
                    color="blue"
                    style={{float: 'right'}}
                    loading={this.state.loading}
                    className="save"
                    onClick={() => this.handleSave()}
                    disabled={!saveIsValid}
                >
                    <SvgIcon icon="save" type="solid" />
                    { tr('save') }
                </ApButton>
            </div>
        )
    }

    render(){
        const isValid = (!this.validator.getState("name")
            && !this.validator.getState("start_date")
            && !this.validator.getState("end_date")
            && moment(this.state.start_date).isBefore(this.state.end_date)
            ) && !this.state.loading;

        return (
            <ApModal 
                show={this.props.show}
                className="narrow"
                handleClose={this.props.onClose}
                closeFromBg

                header={this.renderHeader()}

                body={this.renderBody()}

                footer={this.renderFooter(isValid)}
            />
        )
    }
}

export default AddSubordinateHolidayModal;