import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import './FrontPageCalendar.css';

class FrontPageCalendar extends React.Component {

	constructor(props) {

		super(props);

		this.state = {

		}

	}

	render() {

		return (

			<div>
				<Calendar
					events={[
						{
							'title': 'My event',
							'allDay': false,
							'start': new Date(2018, 0, 1, 10, 0), // 10.00 AM
							'end': new Date(2018, 0, 1, 14, 0), // 2.00 PM 
						}
					]}
					step={30}
					view='week'
					views={['week']}
					// views={['week', 'month', 'agenda', 'day']}
					min={new Date(2008, 0, 1, 8, 0)}
					max={new Date(2008, 0, 1, 19, 0)}
					date={new Date(2018, 0, 1)}
					localizer={momentLocalizer(moment)}
				/>
			</div>

		);

	}

}

export default FrontPageCalendar;
