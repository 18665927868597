/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import SvgIcon 		from 'common/SvgIcon/SvgIcon.js';
import { ApInput, 
		 ApInputStack, 
		 ApAddon } 	from 'common/ApInput/ApInput.js';
import ApSelect		from 'common/ApSelect/ApSelect.js';
import ApSwitch 	from 'common/ApSwitch/ApSwitch.js';
import ApList       from 'common/ApList/ApList.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';

import { keyExists, tr } from  'services/Helpers/Helpers.js';
import { Collapse } from 'react-bootstrap';
import ApModal from 'common/ApModal/ApModal';

export default class General extends React.Component {

	constructor(props) 
    {
        super(props);
        this.methods = [
            { value: null, label: '' },
            { value: "fivaldi", label: "Fivaldi" },
            { value: "netvisor", label: "Netvisor" },
            { value: "nova", label: "Nova" }
        ];
        this.state = {
            paymentCardModalData: false,
            netvisorSalaryModel: false,
            netvisorSettlementPoint: false,
            nextId: 0,
        };
        autoBind(this);
    }

	handleChange( type, key, value )
	{
		let data = { ...this.props[ type ] };
		data[ key ] = value;
		this.props.handleChange( type, data );
	}

    openPaymentCardModal( data )
    {
        if( !data )
            data = {
                id: "new" + this.state.nextId,
                new: true,
            }
        
        this.setState({ 
            paymentCardModalData: data,
            nextId: this.state.nextId + 1,
        });
    }

    savePaymentCardModal( values )
    {
        let payment_cards = this.props.paymentCards.slice();
        const index = payment_cards.findIndex( p => p.id == this.state.paymentCardModalData.id );

        if( index != -1 )
        {
            payment_cards[ index ].name = values[0];
            payment_cards[ index ].number = values[1];
            payment_cards[ index ].description = values[2];
            payment_cards[ index ].unsaved = true;
        }
        else 
        {
            payment_cards.push({
                id: this.state.paymentCardModalData.id,
                name: values[0],
                number: values[1],
                description: values[2],
                unsaved: true,
            });
        }
        this.props.handleChange( "payment_cards", payment_cards );
    }

    copyPaymentCard( item )
    {
        const data = {
            ...item, 
            id: "copy" + this.state.nextId,
            unsaved: true,
            created_at: null,
            updated_at: null,
            deleted_at: null,
        };

        let payment_cards = this.props.paymentCards.slice();
        const index = payment_cards.findIndex( p => p.id == item.id ) + 1;
        payment_cards.splice( index, 0, data );
        this.props.handleChange( "payment_cards", payment_cards );
        this.openPaymentCardModal( data );
    }

    removePaymentCard( id )
    {
        let payment_cards = this.props.paymentCards.slice();
        const index = payment_cards.findIndex( p => p.id == id );
        if( index != -1 )
        {
            payment_cards[ index ].removed = !payment_cards[ index ].removed;
            this.props.handleChange( "payment_cards", payment_cards );
        }
    }

    handleListItemClick(item, target) {
        if( !item ) {
            item = {
                id: "new" + this.state.nextId,
                new: true,
            }
        }
        
        this.setState({ 
            [target]: item,
            nextId: this.state.nextId + 1,
        });
    }

    handleSaveSalaryModel(item) {
        const netvisorSalaryModels = keyExists( this.props, 'company.netvisor_salary_models', true, false);
        const index = netvisorSalaryModels.findIndex( p => p.id == this.state.netvisorSalaryModel.id );

        if( index != -1 )
        {
            netvisorSalaryModels[ index ].name = item[0];
            netvisorSalaryModels[ index ].unsaved = true;
        }
        else 
        {
            netvisorSalaryModels.push({
                id: this.state.netvisorSalaryModel.id,
                name: item[0],
                unsaved: true,
            });
        }
        this.handleChange( "company.netvisor_salary_models", netvisorSalaryModels );
    }
    
    handleSaveSettlementPoint(item) {
        const netvisorSettlementPoints = keyExists( this.props, 'company.netvisor_settlement_points', true, false);
        const index = netvisorSettlementPoints.findIndex( p => p.id == this.state.netvisorSettlementPoint.id );
        console.log(item, index);

        if( index != -1 )
        {
            netvisorSettlementPoints[ index ].type = item[0];
            netvisorSettlementPoints[ index ].name = item[1];
            netvisorSettlementPoints[ index ].unsaved = true;
        }
        else 
        {
            netvisorSettlementPoints.push({
                id: this.state.netvisorSettlementPoint.id,
                type: item[0],
                name: item[1],
                unsaved: true,
            });
        }
        this.handleChange( "company.netvisor_settlement_points", netvisorSettlementPoints );
    }

    handleRemoveListItem(id, target) {
        const items = keyExists( this.props, `company.${target}`, true, [] ).slice();
        const index = items.findIndex( item => item.id == id );

        if( index != -1 )
        {
            items[ index ].removed = !items[ index ].removed;
            this.handleChange( `company.${target}`, items );
        }
    }

    openNetvisorSalaryModelModal( data )
    {
        if( !data )
            data = {
                id: "new" + this.state.nextId,
                new: true,
            }
        
        this.setState({ 
            netvisorSalaryModel: data,
            nextId: this.state.nextId + 1,
        });
    }

    render()
    {
        const confirmationRequired = keyExists( this.props, "company.confirmation_required", true, false );
        const allApprovalRequired = keyExists( this.props, "company.all_approval_required", true, false );
        const projectEntriesAfterEnd = keyExists( this.props, "company.project_ext_work_enabled", true, false );
        const entriesOnlyIfInPersonnel = keyExists( this.props, "company.entries_only_if_in_personnel", true, false );
        const clientApproverTimetracking = keyExists( this.props, "company.client_approver_timetracking", true, false );
        const showRemainingHours = keyExists( this.props, 'company.show_remaining_hours', true, false);
        const remainingHoursOnlySelectedProjects = keyExists( this.props, 'company.remaining_hours_show_selected_projects', true, false);
        const travelNotToFlextime = keyExists(this.props, 'company.travel_dont_add_flextime', true, false);
        const workDayAutomaticSending = keyExists(this.props, 'company.work_day_automatic_sending', true, false);
        const workDayAutomaticSendingDays = keyExists(this.props, 'company.work_day_automatic_sending_days', true, false);
        const workDayAutomaticSendingSendWeekends = keyExists(this.props, 'company.work_day_automatic_sending_weekends', true, false);
        const workDayAutomaticSendingSendEmptyDays = keyExists(this.props, 'company.work_day_automatic_sending_empty_days', true, false);
        const netvisorPayrollAsAccepted = keyExists( this.props, 'company.netvisor_payroll_as_accepted', true, false);
        const entriesOnlyIfInPersonnelGroups = keyExists(this.props, 'company.entries_only_if_in_personnel_groups') ? this.props.company.entries_only_if_in_personnel_groups : [];
        const timerTimeAttendanceRequired = keyExists(this.props, 'company.timer_time_attendance_required', true, false);

        return (
            <div className="padding generalSettings">

            	<div className={ "apFormGroup" + ( confirmationRequired ? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor="confirmation-required-switch" className="info">
                            { tr('confirmation_of_working_hours') }
                            <small>{ tr('confirmation_of_working_hours_info') }</small>
                        </label>
                        <ApSwitch
                            id="confirmation-required-switch"
                            on={ confirmationRequired }
                            onChange={ () => this.handleChange( 'company', 'confirmation_required', !confirmationRequired ) }
                            disabled={ this.props.loading }
                        />
                    </div>
                </div>
            	<div className={ "apFormGroup" + ( allApprovalRequired ? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor="all-approval-required-switch" className="info">
                            { tr('all_approval_required') }
                            <small>{ tr('all_approval_required_info') }</small>
                        </label>
                        <ApSwitch
                            id="all-approval-required-switch"
                            on={ allApprovalRequired }
                            onChange={ () => this.handleChange( 'company', 'all_approval_required', !allApprovalRequired ) }
                            disabled={ this.props.loading }
                        />
                    </div>
                </div>
                <div className={ "apFormGroup" + ( entriesOnlyIfInPersonnel? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor="entries_only_if_in_personnel" className="info">
                            { tr('entries_only_if_in_personnel') }
                            <small>{ tr('entries_only_if_in_personnel_info') }</small>
                        </label>
                        <ApSwitch
                            id="entries_only_if_in_personnel"
                            on={ entriesOnlyIfInPersonnel }
                            onChange={ () => this.handleChange( 'company', 'entries_only_if_in_personnel', !entriesOnlyIfInPersonnel ) }
                            disabled={ this.props.loading }
                        />
                    </div>
                    <Collapse in={entriesOnlyIfInPersonnel}>
                        <div className="indentleft">
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                {tr('personnel_group_filter_info')}
                            </div>
                            <ApSelect
                                label={tr('personnel_group')}
                                value={entriesOnlyIfInPersonnelGroups}
                                valueRenderer={(item) => <div>{item.name}</div>}
                                optionRenderer={(item) => <div>{item.name}</div>}
                                onChange={(personnelGroups) => this.handleChange('company', 'entries_only_if_in_personnel_groups', personnelGroups)}
                                objKeyId="id"
                                clearable
                                apiUrl="usermanagement/personnelGroups"
                                // apiData={{
                                //     all_entries: this.state.all_entries
                                // }}
                                loading={this.state.loading}
                                disabled={this.state.loading}
                                multiselect
                            />
                        </div>
                    </Collapse>
                </div>
                <div className={ "apFormGroup" + ( projectEntriesAfterEnd ? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor="add-entries-after-ending-switch" className="info">
                            { tr('add_entries_after_ending') }
                            <small>{ tr('add_entries_after_ending_info') }</small>
                        </label>
                        <ApSwitch
                            id="add-entries-after-ending-switch"
                            on={ projectEntriesAfterEnd }
                            onChange={ () => this.handleChange( 'company', 'project_ext_work_enabled', !projectEntriesAfterEnd ) }
                            disabled={ this.props.loading }
                        />
                    </div>
                </div>
                <div className={ "apFormGroup" + ( clientApproverTimetracking ? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor="client-approver-timetracking-switch" className="info">
                            { tr('client_approver_timetracking') }
                            <small>{ tr('client_approver_timetracking_info') }</small>
                        </label>
                        <ApSwitch
                            id="client-approver-timetracking-switch"
                            on={ clientApproverTimetracking }
                            onChange={ () => this.handleChange( 'company', 'client_approver_timetracking', !clientApproverTimetracking ) }
                            disabled={ this.props.loading }
                        />
                    </div>
                </div>
                <div className={ "apFormGroup" + ( showRemainingHours ? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor="remaining-hours" className="info">
                            { tr('show_remaining_hours_timetracking') }
                            <small>{ tr('show_remaining_hours_timetracking_info') }</small>
                        </label>
                        <ApSwitch
                            id="remaining-hours"
                            on={ showRemainingHours }
                            onChange={ () => this.handleChange( 'company', 'show_remaining_hours', !showRemainingHours ) }
                            disabled={ this.props.loading }
                        />
                    </div>
                </div>
                <Collapse in={showRemainingHours}>
                    <div className={ "apFormGroup" + ( remainingHoursOnlySelectedProjects ? " success" : "" ) } style={{marginLeft: '20px'}}>
                        <div className="apSwitchBlock">
                            <label htmlFor="remaining-hours-show-only-selected" className="info">
                                {tr('remaining_hours_show_only_selected')}
                                <small>{tr('remaining_hours_show_only_selected_info')}</small>
                            </label>
                                <ApSwitch
                                    id="remaining-hours-show-only-selected"
                                    on={ remainingHoursOnlySelectedProjects }
                                    onChange={ () => this.handleChange( 'company', 'remaining_hours_show_selected_projects', !remainingHoursOnlySelectedProjects ) }
                                    disabled={ this.props.loading }
                                />
                        </div>
                    </div>
                </Collapse>

                <div className={ "apFormGroup" + ( travelNotToFlextime ? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor="travel_not_to_flextime" className="info">
                            {tr('show_travel_dont_count_to_flextime_setting') }
                            <small>{ tr('travel_dont_count_to_flextime_info') }</small>
                        </label>
                        <ApSwitch
                            id="travel_not_to_flextime"
                            on={ travelNotToFlextime }
                            onChange={ () => this.handleChange( 'company', 'travel_dont_add_flextime', !travelNotToFlextime ) }
                            disabled={ this.props.loading }
                        />
                    </div>
                </div>

                <div className={"apFormGroup" + (workDayAutomaticSending ? " success" : "")}>
                    <div className="apSwitchBlock">
                        <label htmlFor="work_day_automatic_sending" className="info">
                            {tr('work_day_automatic_sending')}
                            <small>{tr('work_day_automatic_sending_info')}</small>
                        </label>
                        <ApSwitch
                            id="work_day_automatic_sending"
                            on={workDayAutomaticSending}
                            onChange={() => this.handleChange('company', 'work_day_automatic_sending', !workDayAutomaticSending)}
                            disabled={this.props.loading}
                        />
                    </div>
                </div>
                <Collapse in={workDayAutomaticSending}>
                    <div>
                        
                        <div className="padding" style={{ marginLeft: '10px' }}>
                                <label className="info">
                                {tr('work_day_automatic_sending_days')}
                                    {/* <small>{tr('remaining_hours_show_only_selected_info')}</small> */}
                            </label>
                            <ApInputStack gap="0">
                                <ApInput
                                    type="number"
                                    id="work_day_automatic_sending_days"
                                    name="work_day_automatic_sending_days"
                                    value={workDayAutomaticSendingDays ? workDayAutomaticSendingDays:"0"}
                                    // options={this.methods}
                                    label={tr('days')}
                                    min="0"
                                    onChange={(e) => { this.handleChange("company", "work_day_automatic_sending_days", e.target.value < 0 ? "0" : e.target.value) }}
                                    loading={this.state.loading}
                                    disabled={this.state.loading}
                            />
                        </ApInputStack>
                            </div>
                        
                        <div className={"apFormGroup" + (workDayAutomaticSendingSendWeekends ? " success" : "")} style={{ marginLeft: '20px' }}>
                            <div className="apSwitchBlock">
                                <label htmlFor="work_day_automatic_sending_weekends" className="info">
                                    {tr('work_day_automatic_sending_weekends')}
                                </label>
                                <ApSwitch
                                    id="work_day_automatic_sending_weekends"
                                    on={workDayAutomaticSendingSendWeekends}
                                    onChange={() => this.handleChange('company', 'work_day_automatic_sending_weekends', !workDayAutomaticSendingSendWeekends)}
                                    disabled={this.props.loading}
                                />
                            </div>
                        </div>
                        <div className={"apFormGroup" + (workDayAutomaticSendingSendEmptyDays ? " success" : "")} style={{ marginLeft: '20px' }}>
                            <div className="apSwitchBlock">
                                <label htmlFor="work_day_automatic_sending_empty_days" className="info">
                                    {tr('work_day_automatic_sending_send_empty_days')}
                                </label>
                                <ApSwitch
                                    id="work_day_automatic_sending_empty_days"
                                    on={workDayAutomaticSendingSendEmptyDays}
                                    onChange={() => this.handleChange('company', 'work_day_automatic_sending_empty_days', !workDayAutomaticSendingSendEmptyDays)}
                                    disabled={this.props.loading}
                                />
                            </div>
                        </div>
                        
                    </div>
                    
                </Collapse>

                {/* Siirretty kulunvalvonnan välilehdelle <div className={"apFormGroup" + (timerTimeAttendanceRequired ? " success" : "")}>
                    <div className="apSwitchBlock">
                        <label htmlFor="timer_time_attendance_required" className="info">
                            {tr('timer_time_attendance_required')}
                            <small>{tr('timer_time_attendance_required_info')}</small>
                        </label>
                        <ApSwitch
                            id="timer_time_attendance_required"
                            on={timerTimeAttendanceRequired}
                            onChange={() => this.handleChange('company', 'timer_time_attendance_required', !timerTimeAttendanceRequired)}
                            disabled={this.props.loading}
                        />
                    </div>
                </div> */}

                <br />
            	<h3>{ tr('expense_budgeting') }</h3>
                <p>{ tr('expense_budgeting_info') }</p>

                <ApInputStack gap="0">
                    <ApAddon width="50" noRightBorder>
                        <SvgIcon icon="car" type="solid" />
                    </ApAddon>
                    <ApSelect
                        label={ tr('kilometer_allowance') }
                        value={ keyExists( this.props, "company.travel_component.name", true, "" )  }
                        optionRenderer="component"
                        onChange={ ( c ) => this.handleChange( "company", "travel_component", c ) }
                        objKeyId="id"
                        objKeyValue="name"
                        apiUrl="storage/components"
                        apiData={{ 
                            type: 3 // expenses
                        }}
                        validationState={ !keyExists( this.props, 'company.travel_component.id' ) ? "warning" : "" }
                    	clearable
                    	loading={ this.props.loading }
                        disabled={ this.props.loading }
                    />
                </ApInputStack>

                <ApInputStack gap="0">
                    <ApAddon width="50" noRightBorder>
                        <SvgIcon icon="route" type="solid" />
                    </ApAddon>
                    <ApSelect
                        label={ tr('travel_time') }
                        value={ keyExists( this.props, "company.travel_hour_component.name", true, "" )  }
                        optionRenderer="component"
                        onChange={ ( c ) => this.handleChange( "company", "travel_hour_component", c ) }
                        objKeyId="id"
                        objKeyValue="name"
                        apiUrl="storage/components"
                        apiData={{ 
                            type: 2 // hours
                        }}
                        validationState={ !keyExists( this.props, 'company.travel_hour_component.id' ) ? "warning" : "" }
                    	clearable
                    	loading={ this.props.loading }
                        disabled={ this.props.loading }
                    />
                </ApInputStack>

                <ApInputStack gap="0">
                    <ApAddon width="50" noRightBorder>
                        <SvgIcon icon="receipt" type="solid" />
                    </ApAddon>
                    <ApSelect
                        label={ tr('expense_compensation') }
                        value={ keyExists( this.props, "company.expense_component.name", true, "" )  }
                        optionRenderer="component"
                        onChange={ ( c ) => this.handleChange( "company", "expense_component", c ) }
                        objKeyId="id"
                        objKeyValue="name"
                        apiUrl="storage/components"
                        apiData={{ 
                            type: 3 // expenses
                        }}
                        validationState={ !keyExists( this.props, 'company.expense_component.id' ) ? "warning" : "" }
                    	clearable
                    	loading={ this.props.loading }
                        disabled={ this.props.loading }
                    />
                </ApInputStack>

                <ApInputStack gap="0">
                    <ApAddon width="50" noRightBorder>
                        <SvgIcon icon="suitcase" type="solid" />
                    </ApAddon>
                    <ApSelect
                        label={ tr('daily_allowance_compensation') }
                        value={ keyExists( this.props, "company.allowance_component.name", true, "" )  }
                        optionRenderer="component"
                        onChange={ ( c ) => this.handleChange( "company", "allowance_component", c ) }
                        objKeyId="id"
                        objKeyValue="name"
                        apiUrl="storage/components"
                        apiData={{ 
                            type: 3 // expenses
                        }}
                        validationState={ !keyExists( this.props, 'company.allowance_component.id' ) ? "warning" : "" }
                    	clearable
                    	loading={ this.props.loading }
                        disabled={ this.props.loading }
                    />
                </ApInputStack>

                <br />
                <h2>{ tr('company_payment_cards') }</h2>
                <p>{ tr('company_payment_cards_info') }</p>

                <ApList
                    loading={ this.props.loading }
                    items={ this.props.paymentCards || [] }
                    itemRenderer={ ( item ) => 
                        <div>
                            <strong>{ item.name }</strong> - { item.number }<br />
                            <small>{ item.description || tr('no_description') }</small>
                        </div> 
                    }
                    columns={[
                        { value: ( item ) => <small>{ item.entries_count || 0 } { tr('pluralize_entries') }</small> },
                    ]}
                    onClick={ this.openPaymentCardModal }
                    actions={[
                        {
                            label: tr('copy'),
                            icon: "copy",
                            action: ( item, closeFunc ) => { 
                                this.copyPaymentCard( item );
                                closeFunc(); 
                            }
                        },
                        { divider: true },
                        {
                            label: ( item ) => item.removed ? tr('undo_deletion') : tr('delete'),
                            icon: ( item ) => item.removed ? "times" : "trash",
                            action: ( item, closeFunc ) => { 
                                this.removePaymentCard( item.id );
                                closeFunc(); 
                            }
                        },
                    ]}
                    validationState={ ( item ) => 
                        item.removed ? "removed" : 
                        item.unsaved ? "warning" : 
                        "" 
                    }
                    icon="credit-card"
                    sortable
                    onSort={ ( items ) => this.props.handleChange( "payment_cards", items ) }
                    addNewItem={ () => this.openPaymentCardModal() }
                />

                <br />
                <h2>{ tr('payroll') }</h2>
                <p>{ tr('payroll_settings_info') }</p>

                <ApInputStack gap="0">
                    <ApAddon noRightBorder>
                        { tr('payroll_method') }
                    </ApAddon>
                    <ApInput
                        type="select"
                        id="payroll_method"
                        name="payroll_method"
                        value={ keyExists( this.props, 'company.payroll_payment_method', true, '' ) }
                        options={ this.methods }
                        onChange={  ( e ) => { this.handleChange("company", "payroll_payment_method", e.target.value) } }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }
                    />
                </ApInputStack>
                {keyExists(this.props, 'company.payroll_payment_method', true, '') === 'fivaldi' && (
                    <ApInput 
                        type="text" 
                        id="fivaldi_business_id" 
                        name="fivaldi_business_id" 
                        label={ tr('fivaldi_company_id') }
                        value={ keyExists( this.props, "company.fivaldi_business_id", true, '' ) }
                        onChange={ ( e ) => this.handleChange( "company", "fivaldi_business_id", e.target.value ) }
                        loading={ this.props.loading }
                        disabled={ this.props.loading }
                    />
                )}
                {keyExists(this.props, 'company.payroll_payment_method', true, '') === 'netvisor' && (
                    <div>
                        <ApInput 
                            type="text" 
                            id="netvisor_customer_id" 
                            name="netvisor_customer_id" 
                            label={ tr('netvisor_customer_id') }
                            value={ keyExists( this.props, "company.netvisor_customer_id", true, '' ) }
                            onChange={ ( e ) => this.handleChange( "company", "netvisor_customer_id", e.target.value ) }
                            loading={ this.props.loading }
                            disabled={ this.props.loading }
                        />
                        <ApInput 
                            type="text" 
                            id="netvisor_user_key" 
                            name="netvisor_user_key" 
                            label={ tr('netvisor_user_key') }
                            value={ keyExists( this.props, "company.netvisor_user_key", true, '' ) }
                            onChange={ ( e ) => this.handleChange( "company", "netvisor_user_key", e.target.value ) }
                            loading={ this.props.loading }
                            disabled={ this.props.loading }
                        />
                        <ApInput 
                            type="text" 
                            id="company_business_id" 
                            name="company_business_id" 
                            label={ tr('business_id') }
                            value={ keyExists( this.props, "company.company_business_id", true, '' ) }
                            onChange={ ( e ) => this.handleChange( "company", "company_business_id", e.target.value ) }
                            loading={ this.props.loading }
                            disabled={ this.props.loading }
                        />
                        <div className={"apFormGroup" + (netvisorPayrollAsAccepted ? " success" : "")}>
                            <div className="apSwitchBlock">
                                <label htmlFor="netvisor_payroll_as_accepted" className="info">
                                    {tr('netvisor_payroll_as_accepted')}
                                    <small>{tr('netvisor_payroll_as_accepted_info')}</small>
                                </label>
                                <ApSwitch
                                    id="netvisor_payroll_as_accepted"
                                    on={netvisorPayrollAsAccepted}
                                    onChange={() => this.handleChange('company', 'netvisor_payroll_as_accepted', !netvisorPayrollAsAccepted)}
                                    disabled={this.props.loading}
                                />
                            </div>
                        </div>
                        {/* Palkkamallit */}
                        <h4 className='formHeader'>{tr('salary_models')}</h4>
                        <ApList
                            loading={ this.props.loading }
                            items={ keyExists( this.props, "company.netvisor_salary_models", true, [] ) }
                            itemRenderer={ ( item ) => 
                                <div>
                                    <strong>{ item.name }</strong>
                                </div> 
                            }
                            onClick={(item) =>  this.handleListItemClick(item, "netvisorSalaryModel") }
                            actions={
                                [
                                    {
                                        label: tr('edit'),
                                        icon: "edit",
                                        action: ( item, closeFunc ) => { 
                                            this.handleListItemClick(item, "netvisorSalaryModel");
                                            closeFunc();
                                        },
                                    },
                                    {
                                        label: (item) => item.removed ? tr('undo_deletion') : tr('delete'),
                                        icon: "trash",
                                        action: ( item, closeFunc ) => {
                                            this.handleRemoveListItem(item.id, 'netvisor_salary_models');
                                            closeFunc();
                                        },
                                    }
                                ]
                            }
                            icon={ ( item ) => <SvgIcon icon="money-check-alt" type="solid" /> }
                            sortable
                            onSort={ (items) => this.handleChange('company', 'netvisor_salary_models', items) }
                            addNewItem={() => this.handleListItemClick(null, "netvisorSalaryModel") }
                            validationState={ ( item ) => 
                                item.removed ? "removed" : 
                                item.unsaved ? "warning" : 
                                "" 
                            }
                        />
                        <div className='apInfo small'><SvgIcon icon='info-circle' type='solid' /> {tr('netvisor_salary_models_info')} {tr('accountant_info')}</div>

                        {/* Tilityspisteet */}
                        <h4 className='formHeader'>{tr('settlement_points')}</h4>
                        <ApList
                            loading={ this.props.loading }
                            items={ keyExists( this.props, "company.netvisor_settlement_points", true, [] ) }
                            itemRenderer={ ( item ) => 
                                <div>
                                    <strong>{tr(item.type)} - { item.name }</strong>
                                </div> 
                            }
                            onClick={(item) => this.handleListItemClick(item, 'netvisorSettlementPoint') }
                            actions={
                                [
                                    {
                                        label: tr('edit'),
                                        icon: "edit",
                                        action: ( item, closeFunc ) => { 
                                            this.handleListItemClick(item, 'netvisorSettlementPoint');
                                            closeFunc();
                                        },
                                    },
                                    {
                                        label: (item) => item.removed ? tr('undo_deletion') : tr('delete'),
                                        icon: "trash",
                                        action: ( item, closeFunc ) => {
                                            this.handleRemoveListItem(item.id, 'netvisor_settlement_points');
                                            closeFunc();
                                        },
                                    }
                                ]
                            }
                            icon={ ( item ) => <SvgIcon icon="money-check-alt" type="solid" /> }
                            sortable
                            onSort={ (items) => this.handleChange('company', 'netvisor_settlement_points', items) }
                            addNewItem={() => this.handleListItemClick(null, 'netvisorSettlementPoint') }
                            validationState={ ( item ) => 
                                item.removed ? "removed" : 
                                item.unsaved ? "warning" : 
                                "" 
                            }
                        />
                        <div className='apInfo small'><SvgIcon icon='info-circle' type='solid' /> {tr('netvisor_settlement_points_info')} {tr('accountant_info')}</div>
                    </div>
                )}
                {keyExists(this.props, 'company.payroll_payment_method', true, '') === 'nova' && (
                    <div>
                        <ApInput 
                            type="text" 
                            id="nova_hash" 
                            name="nova_hash" 
                            label="Yrityksen hash-tunnus"
                            value={ keyExists( this.props, "company.nova_hash", true, '' ) }
                            onChange={ ( e ) => this.handleChange( "company", "nova_hash", e.target.value ) }
                            loading={ this.props.loading }
                            disabled={ this.props.loading }
                        />
                        <ApInput 
                            type="text" 
                            id="nova_url" 
                            name="nova_url" 
                            label="Nova WebService URL"
                            value={ keyExists( this.props, "company.nova_url", true, '' ) }
                            onChange={ ( e ) => this.handleChange( "company", "nova_url", e.target.value ) }
                            loading={ this.props.loading }
                            disabled={ this.props.loading }
                        />
                    </div>
                )}


                
                <ApModalInput
                    show={ this.state.paymentCardModalData ? true : false }
                    title={ keyExists( this.state, "paymentCardModalData.new" ) ? tr('new_payment_card') : tr('edit_payment_card') }
                    value={[
                        ( keyExists( this.state, "paymentCardModalData.name" ) ? this.state.paymentCardModalData.name : "" ),
                        ( keyExists( this.state, "paymentCardModalData.number" ) ? this.state.paymentCardModalData.number : "" ),
                        ( keyExists( this.state, "paymentCardModalData.description" ) ? this.state.paymentCardModalData.description : "" )
                    ]}
                    label={[
                        tr('name'),
                        tr('card_number'),
                        tr('description')
                    ]}
                    required={[
                        true,
                        true,
                        false,
                    ]}
                    onSave={ this.savePaymentCardModal }
                    onClose={ () => this.setState({ paymentCardModalData: false }) }
                />
                
                <ApModalInput
                    show={ this.state.netvisorSalaryModel ? true : false }
                    title={tr('salary_model') }
                    value={[
                        ( keyExists( this.state, "netvisorSalaryModel.name" ) ? this.state.netvisorSalaryModel.name : "" ),
                    ]}
                    label={[
                        tr('name'),
                    ]}
                    required={[
                        true,
                    ]}
                    onSave={ this.handleSaveSalaryModel }
                    onClose={ () => this.setState({ netvisorSalaryModel: false }) }
                />
                
                <ApModalInput
                    show={ this.state.netvisorSettlementPoint ? true : false }
                    title={tr('settlement_point') }
                    value={[
                        ( keyExists( this.state, "netvisorSettlementPoint.type" ) ? this.state.netvisorSettlementPoint.type : "" ),
                        ( keyExists( this.state, "netvisorSettlementPoint.name" ) ? this.state.netvisorSettlementPoint.name : "" ),
                    ]}
                    label={[
                        tr('type'),
                        tr('name'),
                    ]}
                    required={[
                        true,
                        true,
                    ]}
                    type={[
                        "select",
                        "text",
                    ]}
                    options={[
                        {value: null, label: ""},
                        {value: "employeeworkpensioninsurance", label: tr("employeeworkpensioninsurance")},
                        {value: "employeeunemploymentinsurance", label: tr("employeeunemploymentinsurance")},
                        {value: "employeeunionmembershipfee", label: tr("employeeunionmembershipfee")},
                        {value: "employeeaccidentinsurance", label: tr("employeeaccidentinsurance")},
                        {value: "employeegrouplifeinsurance", label: tr("employeegrouplifeinsurance")},
                        {value: "employeeotherinsurance", label: tr("employeeotherinsurance")},
                    ]}
                    onSave={ this.handleSaveSettlementPoint }
                    onClose={ () => this.setState({ netvisorSettlementPoint: false }) }
                />
            </div>
        );
    }

}
