/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import moment from 'moment';

import { findItemById, addParentIds, tr, capitalize } from 'services/Helpers/Helpers.js';

import Timeline, { TimelineMarkers, TodayMarker } from 'react-calendar-timeline'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import './ApTimeline.css';

import SvgIcon from '../SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown';
import ApButton from 'common/ApButton/ApButton';


class ApTimeline extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            zoomLevel: 30,
            zoomLevelMin: 7,
            zoomLevelMax: 365,
            items: [],
            itemsStart: moment(),
            itemsEnd: moment().add( 30, 'days' ),
            groups: [],
            flatProjectIds: [],
        }

        this.timeline = false;
        autoBind(this); 
    } 

    UNSAFE_componentWillMount()
    {
        this.parseItems( this.props.items );
    }

    UNSAFE_componentWillReceiveProps( nextProps )
    {
        if( this.props.items != nextProps.items )
            this.parseItems( nextProps.items );
    }


    /**
     *  Recalculate items and groups for timeline component
     *
     **/
    parseItems( newItems ) 
    {
        const childs = this.props.childName ? this.props.childName : 'childs';
        if( !newItems )
            return false;

        let items = [];
        let groups = [];
        let firstDate = false;
        let lastDate = false;
        let flatProjectIds = [];

        // Iterate through array of items
        //  - Find out the first and last dates (edges)
        //  - Generate items and groups arrays for timeline component
        const loopItems = ( array, level, parentId ) => {
            if( Array.isArray( array ) )
            {
                if( typeof( level ) != "number" ) level = 0;
                for( let i = 0; i < array.length; i++ )
                {
                    const item = array[i];
                    flatProjectIds.push(item.id);
                    if( !firstDate || firstDate > moment( item.start ) )
                        firstDate = moment( item.start );

                    if( !lastDate || lastDate < moment( item.end ) )
                        lastDate = moment( item.end );

                    let move = true;
                    let resize = "both";

                    if( this.props.readOnly )
                    {
                        move = false;
                        resize = false;
                    }

                    items.push({
                        id: item.id, 
                        group: "group_" + item.id, 
                        canMove: this.props.holidayTimeline ? false : move,
                        canResize: this.props.holidayTimeline ? false : resize,
                        canChangeGroup: false,
                        title: item.name, 
                        start_time: moment( item.start), 
                        end_time: moment( item.end ),
                        parentId: parentId,
                    });

                    const open = ( this.props.nodeOpenName ) ? item[ this.props.nodeOpenName ] : item.childsOpen;

                    groups.push({
                        id: "group_" + item.id,
                        item_id: item.id,
                        title: !this.props.holidayTimeline ? item.name + " " + level : item.name,
                        open: open,
                        [ childs ]: ( item[ childs ] && item[ childs ].length > 0 ? true : false ),
                        level: level,
                        first_child: (i == 0 ? true : false),
                        last_child: ( (i + 1) == array.length ? true : false ),
                    }); 
                    
                    if( item[ childs ] && open )
                        loopItems( item[ childs ], (level + 1), item.id );
                }
            }
        };
        
        loopItems( newItems );

        const totalLength = lastDate.diff( firstDate, "days" ) + ( this.props.padding ? this.props.padding * 2 : 0 );

        items = this.updateItemsStatus( items );

        this.setState({
            items:          items,
            itemsStart:     firstDate,
            itemsEnd:       lastDate,
            itemsLength:    totalLength,
            groups:         groups,
            zoomLevel:      this.props.daysVisible ? this.props.daysVisible : totalLength,
            flatProjectIds: flatProjectIds,
        });
    }


    // Convert string (YYYY-MM-DD) or number (timestamp) to moment object
    momentify( date ) 
    {
        if( moment.isMoment( date ) )
            return date;

        if( typeof( date ) == "string" || typeof( date ) == "number" )
            return moment( date );
    
        return false;
    }


    // Convert days to microseconds (react-calendar-timeline uses microseconds)
    days( days = 1 )
    {
        return 1000 * 60 * 60 * 24 * days;
    }


    onTimeChange( visibleTimeStart, visibleTimeEnd, updateScrollCanvas ) {
        
        const start = this.momentify( visibleTimeStart );
        let end = this.momentify( visibleTimeEnd );
        const diff = end.diff( start, "days" );

        if( diff < this.state.zoomLevelMin )
            end = start.clone().add( this.state.zoomLevelMin, "days" );

        else if ( diff > this.state.zoomLevelMax )
            end = start.clone().add( this.state.zoomLevelMax, "days" );

        updateScrollCanvas( start.format('x'), end.format('x') );  
    }


    centerTo( target )
    {
        if( this.timeline )
        {
            const halfZoom = this.days( this.state.zoomLevel ) / 2;
            let middle = false;
            
            switch( target )
            {
                case "today":
                    middle = parseInt( moment().format('x'), 10 );
                    break;

                default:
                    middle = moment( target ).format('x');
                    break;
            }

            if( middle )
                this.onTimeChange( middle - halfZoom, middle + halfZoom, this.timeline.updateScrollCanvas );
        }
    }

    setZoom( days ) 
    {
        if( this.timeline )
        {
            const start = parseInt( this.timeline.state.visibleTimeStart, 10 );
            const end = parseInt( this.timeline.state.visibleTimeEnd, 10 );
            const middle = start + Math.round( ( end - start ) / 2 );
            const halfZoom = this.days( days ) / 2;
            this.onTimeChange( middle - halfZoom, middle + halfZoom, this.timeline.updateScrollCanvas );
        }
    }

    onZoom( timelineContext )
    {
        //console.log( 'onZoom', timelineContext.visibleTimeStart, timelineContext.visibleTimeEnd, timelineContext  );
        const level = Math.round( ( timelineContext.visibleTimeEnd - timelineContext.visibleTimeStart ) / 1000 / 60 / 60 / 24 );
      
        if( isNaN( level ) )
            this.setState( { zoomLevel: level } );
    }

    updateItemsStatus( items )
    {
        items.map( ( item ) => {

            let status = "ok";

            if( item.parentId )
            {
                const parent = findItemById( items, item.parentId );
                if( item.start_time < parent.start_time || item.end_time > parent.end_time )
                    status = "error";
            }

            item.status = status;
            return item;

        });

        return items;
    }

    handleItemMove( itemId, dragTime, newGroupId )
    { 
        const childs = this.props.childName ? this.props.childName : 'childs';

        if( !this.props.overflowParent )
        {   
            const item = findItemById( addParentIds( this.props.items, childs ), itemId, childs );
            
            const loopChilds = ( obj ) => {
                if( Array.isArray( obj ) )
                    for( let i = 0; i < obj.length; i++ )
                    {   
                        //console.log( 'child found', obj[i] );
                        if( obj[i][ childs ] )
                            loopChilds( obj[i][ childs ]);
                    }
            }

            loopChilds( item );

            //console.log('handleItemMove', item );

        }

        if( typeof( this.props.onMove ) == "function" )
            this.props.onMove( itemId, dragTime );
    }

    handleItemResize( itemId, dragTime, edge )
    {  
        if( !this.props.overflowParent )
        {
            // TODO
        }

        if( typeof( this.props.onResize ) == "function" )
            this.props.onResize( itemId, dragTime, edge );
    }

    /*
    handleItemSelect( itemId, e, time ) 
    {
        const childs = this.props.childName ? this.props.childName : 'childs';

        console.log( 'Selected item ID: ', itemId );
        
        const item = findItemById( this.props.items, itemId, childs );
        let subItems = [];

        const getChildIds = ( item ) => {
            if( item[ childs ] )
            {
                for( let i = 0; i < item[ childs ].length; i++ )
                {
                    subItems.push( item[ childs ][i].id );
                    getChildIds( item[ childs ][i] );
                }
            }
        };

        getChildIds( item );
        
        console.log( itemId, subItems );

        this.setState({
            selectedIds: [ itemId ],
            selectedSubIds: subItems,
        });
    }

    handleItemDeselect()
    {
        this.setState({
            selectedIds: [],
            selectedSubIds: [],
        });
    }
    */

    handleItemRemove(group)
    {
        let groups = [...this.state.groups]
        groups.splice(groups.findIndex(item => item.item_id === group.item_id), 1);
        this.setState({ groups: groups })
    }

    itemRenderer( { item, itemContext, getItemProps, getResizeProps } )
    {

        const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
        let itemProps = getItemProps();

        // Remove some inline styles so we can modify item via CSS
        delete itemProps.style.background;
        delete itemProps.style.border;
        delete itemProps.style.borderLeftWidth;
        delete itemProps.style.borderRightWidth;

        itemProps.className += " status-" + item.status;

        const index = this.state.flatProjectIds.findIndex(projectId => projectId === item.id);
        if (index !== -1) {
            // Adjust the top position to align the element inline by subtracting the index's amount of pixels
            let top = parseInt(itemProps.style.top.replace("px", ""));
            top = top - index - 1 ;
            itemProps.style.top = top + "px";
        }
        
        if( itemContext.selected )
            itemProps.className += " selected"

        return (
            <div { ...itemProps }>
                
                { itemContext.useResizeHandle && 
                    <div className="resizeHandle" {...leftResizeProps} />
                }

                { !this.props.holidayTimeline ?
                <div className="itemContent">
                {item.title} - { tr('status') }: { item.status }
                </div>
                :
                <div className="itemContent">
                    {item.title}
                </div>
                }
                

                <SvgIcon className="moveIcon" icon="arrows-alt-h" type="solid" />

                { itemContext.useResizeHandle && 
                    <div className="resizeHandle" {...rightResizeProps} />
                }
                

            </div>
        );
    }

    groupRenderer( { group } )
    {
        const childs = this.props.childName ? this.props.childName : 'childs';
        let classes = [ "side-row" ];

        if( group[ childs ] )
            classes.push( "haveChilds" );
        
        if( group.open )
            classes.push( "open" );

        let onClick = () => false;
        if( typeof( this.props.onGroupClick ) == "function" )
            onClick = () => this.props.onGroupClick( group.item_id );

        return (
            <div 
                className={ classes.join(" ") } 
                style={{ paddingLeft: 0.5 + ( group.level * 1 ) + "em" }} 
                onClick={ onClick }
            >
                { group[ childs ] && <SvgIcon className="arrow" icon="chevron-left" type="solid" /> }
                { !this.props.holidayTimeline ? 
                 <span className="title">{ group.title }</span>
                 :
                <div>
                <span style={{width: 200, cursor: 'pointer'}}>
                    <ApTooltip text={ this.props.handleItemEdit ? tr('edit_plan') : tr('approve_plan')} position="right">
                        <ApButton
                            onClick={this.props.handleItemEdit ? () => this.props.handleItemEdit(group) : () => this.props.handleItemApprove(group)}
                            color="green"
                            size="xtiny"
                            style={{padding: "3px", marginRight: "3px"}}
                        >
                            <SvgIcon 
                                className="small"
                                icon={this.props.handleItemEdit ? "edit" : "check"}
                                type="solid"
                            />
                        </ApButton>
                    </ApTooltip>
                </span>
                { group.title }
                </div>
                }
                
            </div>
        );
    } 

    horizontalLineClassNamesForGroup( group ) 
    {
        let classes = [];

        if( group.level > 0 )
            classes.push('level' + group.level );

        if( group.level > 0 && group.first_child )
            classes.push('firstchild');

        if( group.level > 0 && group.last_child )
            classes.push('lastchild');

        return classes;
    }


    render()
    {
        let start = this.state.itemsStart;
        let end = this.state.itemsEnd;
        let zoom = this.days( this.state.zoomLevel );        
        
        if( this.props.padding ) {
            const padding = this.days( this.props.padding );
            start -= padding;
            end += padding;
            zoom += padding * 2;
        }

        let classes = [ "apTimeline" ];

        return (
        	<div className={ classes.join(" ") }>

                { this.props.loading &&
                    <div className="loadingMask">
                        <div className="apLoader"></div>
                    </div>
                }

                {/* 
                <p>ZoomLevel: { this.state.zoomLevel }</p>
                <button onClick={ () => this.centerTo('today') }>Tänään</button>
                <br />
                */}

     
                    <Timeline
                        ref={r => { this.timeline = r; }}
                        onTimeChange={ this.onTimeChange }
                        onZoom={ this.onZoom }
                        timeSteps={{ second: 0, minute: 0, hour: 0, day: 1, month: 1, year: 1 }} 
                        dragSnap={ this.days() }
                        useResizeHandle
                        groups={ this.state.groups }
                        items={ this.state.items }
                        headerLabelGroupHeight={25}
                        headerLabelHeight={25}
                        lineHeight={30}
                        itemHeightRatio={0.8}
                        stickyHeader={false}
                        defaultTimeStart={ moment( start ) }
                        defaultTimeEnd={ moment( end ) }

                        // These prevent zooming via touchboard, mouse scroll etc.
                        minZoom={ zoom }
                        maxZoom={ zoom }
                      
                        //verticalLineClassNamesForTime={ this.verticalLineClassNamesForTime }
                        horizontalLineClassNamesForGroup={ this.horizontalLineClassNamesForGroup }
                        resizeDetector={containerResizeDetector}
                        groupRenderer={ this.groupRenderer }
                        itemRenderer={ this.itemRenderer }
                        onItemMove={ this.handleItemMove }
                        onItemResize={ this.handleItemResize }

                        sidebarWidth={this.props.holidayTimeline ? 300 : 150}
                        
                        //selected={ this.state.selectedIds }
                        //onItemClick={ this.handleItemSelect }
                        //onItemSelect={ this.handleItemSelect }
                        //onItemDeselect={ this.handleItemDeselect }
                    >
                        <TimelineMarkers>
                            <TodayMarker interval={ 1000 * 60 * 60 }>
                                { ({ styles, date }) => <div className="TodayMarker" style={styles} /> }
                            </TodayMarker>
                        </TimelineMarkers>

                    </Timeline>

                    
                
                <div className="apOptionBar">
                    <div className={"option" + ( this.state.zoomLevel == this.state.itemsLength ? " selected" : "" ) } onClick={ () => this.setZoom( this.state.itemsLength ) }>{ tr('automatic') }</div>
                    <div className={"option" + ( this.state.zoomLevel == 7 ? " selected" : "" ) } onClick={ () => this.setZoom(7) }>{ capitalize(tr('week')) }</div>
                    <div className={"option" + ( this.state.zoomLevel >= 28 && this.state.zoomLevel <= 31 ? " selected" : "" ) } onClick={ () => this.setZoom(30) }>{ tr('month') }</div>
                    <div className={"option" + ( this.state.zoomLevel >= 89 && this.state.zoomLevel <= 92 ? " selected" : "" ) } onClick={ () => this.setZoom(91) }>{ tr('quarter') }</div>
                    <div className={"option" + ( this.state.zoomLevel == 365 ? " selected" : "" ) } onClick={ () => this.setZoom(365) }>{ tr('year') }</div>
                </div>
                
            </div>
        );
    }
};


ApTimeline.propTypes = {
    nodeOpenName:  PropTypes.string,  // value to store if child is open default is childsOpen

    items:          PropTypes.array.isRequired,
    loading:        PropTypes.bool,
    readOnly:       PropTypes.bool, // TRUE = items cannot be moved or resized
    overflowParent: PropTypes.bool, // TRUE = child can be outside parents dates
    onMove:         PropTypes.func,
    onResize:       PropTypes.func,
    onGroupClick:   PropTypes.func,
    daysVisible:    PropTypes.number,
    padding:        PropTypes.number, // On automatic zoom how many days we offset the view
    // TODO
};

export default ApTimeline;

