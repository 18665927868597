import React from 'react';
import autoBind from 'react-autobind';
import { Switch, Route, Redirect } from 'react-router-dom'

import Popper          from 'layout/Popper/Popper.js';
import Notfound        from 'layout/Notfound/Notfound.js'
import Echo            from 'services/Echo/Echo.js';
import routes          from 'routes/intra.js'
import config          from 'env.js';
import auth            from 'services/Authed/Authed.js'

import NavTop        from './NavTop/NavTop.js';
import NavSide       from './NavSide/NavSide.js';
import MobileNav     from './MobileNav/MobileNav.js';
import IdleScreen    from './IdleScreen/IdleScreen.js';
import ErrorBoundary from './ErrorBoundary.js'
import { connect } from 'react-redux';
import api from 'services/Api/Api.js';
import { errorPopper, tr } from 'services/Helpers/Helpers.js';


// When router mounts a component it first mounts this ComponentWithCallbacks. Here we find out
// what values should breadcrumbs have and what menu will be marked as selected. And we
// call the callbacks that will set those values to actual layout. Main purpose of
// this is so we dont remount sidenav or breadcrumbs on every component.
class ComponentWithCallbacks extends React.Component {
    UNSAFE_componentWillMount()
    {
        this.props.setBreadCrumbs(this.props.breadcrumbs);
        this.props.setActiveMenu(this.props.menu);

        // Change page eventlistener
        window.emitter.removeAllListeners('goTo');
        window.emitter.addListener('goTo', args => {
            if(args.replace)
                this.props.history.replace(args);
            else
                this.props.history.push(args);
        });

        //Echo.init();
    }

    render()
    {
        let {component, ...props} = this.props
        return React.createElement(component, {...props});
    }
}

class IntraLayout extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            url: null,
            breadcrumbs: [],
            updateTimer: false,
            sideNav: {
                selected: null,
                minified: ( window.innerWidth < 1000 ? true : false )
            },
            mobileMenuOpen: false,
            unreadChatMessageCount:0,
        }
        
        autoBind(this);
        if (auth.isLoggedIn()) {
            window.getUnreadMessageCountQuery=()=> {
                api({
                    method: 'get',
                    url: 'chat/getUnreadMessageCount',
    
                }).then((response) => {
                    this.setState({ unreadChatMessageCount: response })
    
                }).catch((error) => {
                    this.setState({ loading: false });
                    errorPopper(error, tr('get_error'));
                });
            }
            Echo.init();
            this.echoInited();
        }

    }


    echoInited() {
        if (!config.echo) return null;
        window.Echo.channel('test')
            .listen('TestEvent', e => {
                console.log(e)
            });

        window.Echo.private(`${config.echoName}-user-${auth.getID()}`)
            .listen('SendUserNotification', (e) => {
                window.emitter.emit('user-notification', { ...e, event: 'add' });
            })
            .listen('SendUserEvent', (e) => {
                window.emitter.emit('user-event', { ...e });
            });

        window.Echo.join(`${config.echoName}-company-${auth.getCompanyID()}`)
            .here((users) => {
                this.props.onlineUsers(users);
            })
            .joining((user) => {
                // console.log('joined to company channel', user);
                this.props.onlineUsersJoin(user);
            })
            .leaving((user) => {
                // console.log('left the company channel', user);
                this.props.onlineUsersLeave(user);
            })
            .listen('SendCompanyEvent', (e) => {
                window.emitter.emit('company-event', { ...e });
            });
    }

    

    getUnreadChatMessageCount() {
        if (!auth.isLoggedIn() || !config.echo) {
            return null;
        }
        if (!window.Echo) {
            Echo.init();
        }
        window.getUnreadMessageCountQuery();
        if (typeof(window.updateUnreadMessageCount)=='function') {
            window.updateUnreadMessageCount = (count) => {
                this.setState({
                    unreadChatMessageCount: count
                });
            }
        };
        
        let userId = auth.getID();
        // console.log("userId", userId);
        if (window.Echo) {
            
            window.Echo.private("user." + userId).listen("UnreadMessageCount", (e) => {
                //console.log("UnreadMessage",e)
                let count = this.state.unreadChatMessageCount;
                let url = window.location.href.split("/");
                let roomId = url.pop();
                let route = url.pop();
                // for (let item of e) {
                    
                    if (Number(roomId) && route == "chat") {
                        
                        if (e.roomId != roomId) {
                            count = count + e.count;
                        }
                        
                    }
                    else {
                        count = count + e.count;
                    }
                    
                // }
                
                this.setState({
                    unreadChatMessageCount: count
                });
                
                
            });
        }
        
        

    }
    

    UNSAFE_componentWillMount()
    {
        this.unlisten = this.props.history.listen( location => {
            this.setState({ url: location.pathname });
        });
        this.getUnreadChatMessageCount();
    }

    componentDidMount() {
        if (auth.isLoggedIn()) {
            api({
                method: 'get',
                url: 'getallsettings'
            }).then(response => {
                // console.log(response);
                this.props.saveCustomerSettings(response.customerSettings);
                this.props.saveGeneralSettings(response.generalSettings);
                this.props.saveOrderSettings(response.orderSettings);
                this.props.saveProjectSettings(response.projectSettings);
                this.props.savePurchaseSettings(response.purchaseSettings);
                this.props.saveTimetrackingSettings(response.timetrackingSettings);
                this.props.saveStorageSettings(response.storageSettings);
                this.props.saveExternalSettings(response.externalSettings);
                this.props.saveCostCenterSettings(response.costcenterSettings);
            }).catch((error) => {
                errorPopper(error);
                console.log(error);
            });
        }
    }

    componentWillUnmount()
    {
        this.unlisten();
    }

    setTitle(title)
    {
        document.title = (title) ? 'EASY-PRO - '+ title : 'EASY-PRO';
    }

    setBreadCrumbs(breadcrumbs)
    {
        if(JSON.stringify(breadcrumbs) !==  JSON.stringify(this.state.breadcrumbs))
        {
            this.setState({breadcrumbs})
        }
    }

    setActiveMenu(menu)
    {
        if( menu && menu !== this.state.sideNav.selected ) {
            let sideNav = {...this.state.sideNav};
            sideNav.selected = menu;
            this.setState({ sideNav });
        }
    }

    toggleSideNav()
    {
        var sideNav = {...this.state.sideNav};
        sideNav.minified = !sideNav.minified;
        this.setState({ sideNav });
    }
    
    setUpdateTimer()
    {
        this.setState(prevState => ({
			updateTimer: Date.now()
		  }));
    }

    selectSideNav( id )
    {   
        var sideNav = {...this.state.sideNav};
        sideNav.selected = id;
        this.setState({ sideNav });
    }

    toggleMobileMenu()
	{
		this.setState(prevState => ({
			mobileMenuOpen: !prevState.mobileMenuOpen
		  }));
    }
    
    clickOutsideHandler(event) {
        if (this.state.mobileMenuOpen && this.contentRef.contains(event.target))
            this.setState({ mobileMenuOpen: false })
    }

    render()
    {
        if(!auth.isLoggedIn())
        {
            return <Redirect to="/login"/>
        }

        return (
            <div className={"mainWrapper"  + ( this.state.sideNav.minified ? " sideNavMinified" : "" )}>
                
                <IdleScreen />

                <Popper />
                
                <NavTop 
                    url={ this.state.url }
                    mobileMenuOpen={ this.state.mobileMenuOpen }
                    toggleMobileMenu={ this.toggleMobileMenu } 
                    updateTimer={ this.state.updateTimer } 
                />

                <div className={"hideUnder700"}>
                    <NavSide 
                        // Make navSide as global reference so we can call it's methods from outside.
                        // Used when settings are changed (in services/Authed/Authed.js) and sidebar
                        // badges should be reloaded. Maybe not the best solution, but it works...
                        ref={ ( ref ) => { window.sidebarRef = ref } }

                        selected={this.state.sideNav.selected} 
                        minified={this.state.sideNav.minified} 
                        handleMinify={this.toggleSideNav} 
                        handleSelect={this.selectSideNav}
                        mobileMenuOpen={this.state.mobileMenuOpen}
                        unreadChatMessageCount={this.state.unreadChatMessageCount}
                        onlineStoreEnabled={this.props.apOrderSettings && this.props.apOrderSettings.woocommerce_enabled}
                    />
                </div>

                <div className="showUnder700">
                    <MobileNav
                        open={this.state.mobileMenuOpen}
                        selected={this.state.sideNav.selected}
                        minified={false} 
                        handleSelect={this.selectSideNav}
                        onClose={this.toggleMobileMenu}
                    />
				</div>

                <div className="content" ref={contentRef => this.contentRef = contentRef} onClick={this.clickOutsideHandler}>
                    {/* <Breadcrumbs breadcrumbs={this.state.breadcrumbs} /> */}
                    <Switch>
                        {routes.map((route, index) => {
                            return <Route
                                key={index}
                                exact={true}
                                path={route.path}

                                breadcrumbs={route.breadcrumbs}
                                title={route.title}
                                menu={route.menu}

                                render={(props) => {
                                    this.setTitle(route.title);

                                    const componentWithCallbacks = <ComponentWithCallbacks
                                        {...props}
                                        component={route.component}
                                        title={route.title}
                                        breadcrumbs={route.breadcrumbs}
                                        timerUpdate={this.setUpdateTimer}
                                        menu={route.menu}
                                        setBreadCrumbs={this.setBreadCrumbs}
                                        setActiveMenu={this.setActiveMenu}
                                    />

                                    if(config.dev)
                                        return componentWithCallbacks;
                                    else
                                    {
                                        return <ErrorBoundary route={props}>
                                            { componentWithCallbacks }
                                        </ErrorBoundary>
                                    }
                                }}
                            />
                        })}
                        <Route component={Notfound} />
                    </Switch>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    apOrderSettings: state.apOrderSettings
});

const mapDispatchToProps = dispatch => ({
    saveCustomerSettings: (settings) => dispatch({type: 'SAVE_CUSTOMER_SETTINGS', payload: settings}),
    saveGeneralSettings: (settings) => dispatch({type: 'SAVE_GENERAL_SETTINGS', payload: settings}),
    saveOrderSettings:  (settings) => dispatch({type: 'SAVE_ORDER_SETTINGS', payload: settings}),
    saveProjectSettings: (settings) => dispatch({type: 'SAVE_PROJECT_SETTINGS', payload: settings}),
    savePurchaseSettings: (settings) => dispatch({type: 'SAVE_PURCHASE_SETTINGS', payload: settings}),
    saveStorageSettings: (settings) => dispatch({type: 'SAVE_STORAGE_SETTINGS', payload: settings}),
    saveTimetrackingSettings: (settings) => dispatch({ type: 'SAVE_TIMETRACKING_SETTINGS', payload: settings }),
    saveExternalSettings: (settings) => dispatch({ type: 'SAVE_EXTERNAL_SETTINGS', payload: settings }),
    saveCostCenterSettings: (settings) => dispatch({ type: 'SAVE_COSTCENTER_SETTINGS', payload: settings }), 
    onlineUsers: (users) => dispatch({ type: 'here', payload: users }),
    onlineUsersJoin: (users) => dispatch({ type: 'join', payload: users }),
    onlineUsersLeave: (users) => dispatch({ type: 'leave', payload: users }),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntraLayout)
