/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
//import auth from 'services/Authed/Authed.js';

import { keyExists, findItemByField } from 'services/Helpers/Helpers.js';

import './ExcelImport.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';

class ExcelImport extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,

            companies: [],
            tables: [],

            selected_table: null,
            selected_id_column: null,
            selected_company: null,
            selected_file: null,

            only_errors: false,
            import_new: true,
            import_old: true,
            remove_others: false,

            importing: false,
            import_progress: false,

            result: false,
            

        };

        this.eListener = null;

        autoBind(this); 
    }

    componentDidMount()
    {
        this.getSettings();

        this.eListener = window.emitter.addListener('company-event', ( response ) => {
            if( response['type'] === 'management-excel-import')
                this.updateImportProgress( response['meta'] );
        });
    }

    componentWillUnmount()
    {
        this.eListener.remove();
    }

    getSettings()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'management/import/settings',
        }).then(( response ) => {

            //console.log('getSettings', response );

            let companies = [{ value: false, label: " - Valitse - " }];
            if( response.companies )
                response.companies.forEach( company => {
                    companies.push( {
                        value: company.id,
                        label: company.name,
                    });
                });

            let tables = [{ 
                value: '', 
                label: " - Valitse - ",
                unique_colums: [],
            }];
            if( response.tables )

                for( const table in response.tables )
                {
                    tables.push({
                        value: table, 
                        label: table,
                        unique_colums: response.tables[ table ].unique,
                        pivot: response.tables[ table ].pivotTable,
                    });
                }

            this.setState({ 
                loading: false,
                companies: companies,
                tables: tables,
                importing: response.importing,
            });
            
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    handleChange( e )
    {
        let newState = {};
        newState[ e.target.name ] = e.target.value;

        if( e.target.name === "selected_table" )
            newState[ "selected_id_column" ] = '';

        this.setState( newState );
    }


    buttonDisabled( previewRequired = false )
    {
        /*
        if( previewRequired && !this.state.result )
            return true;
        
        const selectedTable = findItemByField( this.state.tables, "value", this.state.selected_table, false ); 
        if( selectedTable.pivot )
        {
            if( !this.state.selected_table ||
            !this.state.selected_company ||
            !this.state.selected_file )
            return true;
        }
        else if( !this.state.selected_table ||
            !this.state.selected_id_column ||
            !this.state.selected_company ||
            !this.state.selected_file )
            return true;
        */
        return false;
    }

    export()
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'management/export/xlsx',
            data: {
                table: this.state.selected_table,
                company: this.state.selected_company,
            },
            responseType: 'blob',
        }).then(( response ) => {
            
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export_' + this.state.selected_table + '.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            this.setState({ 
                loading: false,
            });
            
        }).catch( ( error ) => {
            this.setState({ loading: false });
        }); 
    }

    importDev( makeChanges = false )
    {
        let data = new FormData();
        data.append( 'table',       this.state.selected_table );
        data.append( 'id_column',   this.state.selected_id_column );
        data.append( 'company',     this.state.selected_company );
        data.append( 'file',        this.state.selected_file );

        if( makeChanges )
        {
            data.append( 'import', JSON.stringify({
                new:        this.state.import_new,
                old:        this.state.import_old,
                remove:     this.state.remove_others,
            }));
        }

        //console.log('Start DEV import');
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'management/import/dev',
            data: data,
        }).then(( response ) => {

            this.setState({ 
                importing: true,
                loading: false,
            });
            
        }).catch( ( error ) => {
            this.setState({ 
                loading: false,
                result: { error: keyExists( error, "response.data.message", true, "Tuntematon virhe " ) }
            });
        });
    }

    updateImportProgress( data )
    {
        //console.log( data );

        if( data.done && data.result )
        {
            this.setState({ 
                import_progress: false,
                importing: false,
                result: data.result,
            });
        }
        else if( data.progress ) 
        {
            this.setState({ 
                import_progress: data.progress 
            });
        }
        else if ( data.fail )
        {
            this.setState({ 
                import_progress: false, 
                importing: false,
                result: { error: data.message }
            });
        }
    }

    import( makeChanges = false ) {
        
        let data = new FormData();
        data.append( 'table',       this.state.selected_table );
        data.append( 'id_column',   this.state.selected_id_column );
        data.append( 'company',     this.state.selected_company );
        //data.append( 'only_errors', this.state.only_errors );
        data.append( 'file',        this.state.selected_file );

        if( makeChanges )
        {
            data.append( 'import', JSON.stringify({
                new:        this.state.import_new,
                old:        this.state.import_old,
                remove:     this.state.remove_others,
            }));
        }

        //console.log('start import', data );

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'management/import/xlsx',
            data: data,
        }).then(( response ) => {

            //console.log('import response', response );

            this.setState({ 
                loading: false,
                result: response
            });
            
        }).catch( ( error ) => {
            this.setState({ 
                loading: false,
                result: { error: keyExists( error, "response.data.message", true, "Tuntematon virhe " ) }
            });
        });
    }

    renderPreview()
    {

        if( this.state.importing )
            return (
                <div className="apInfoMsg">
                    { this.state.import_progress ? this.state.import_progress : "Ladataan tiedostoa palvelimelle..." }
                </div> 
            );

        const result = this.state.result;
        if( !result ) return false;

        if( result.error )
            return <div className="apErrorMsg">{ result.error }</div>;

        const cellError = ( r, c ) => {
            for( let i = 0; i < result.cellErrors.length; i++)
            {
                if( result.cellErrors[i].row == r && result.cellErrors[i].col == c )
                    return result.cellErrors[i].message;
            }
            return false;
        };

        if( result.rows )
        {
            return (
                <div>
                    <hr />
                     <div className="tableScroller">
                        <div className="padding">
                            <table className={ "import" + ( this.state.skipNewRows ? " skipNewRows" : "" ) + ( this.state.skipOldRows ? " skipOldRows" : "" ) }>
                                <tbody>
                                { result.rows.map( ( row, r ) => {
                                    
                                    if( r > 0 && this.state.only_errors && !row.error )
                                        return false;

                                    let rowClasses = [];

                                    if( r == 0 )
                                        rowClasses.push( "header" );
                                    
                                    else 
                                    {
                                        rowClasses.push( row.rowId ? "oldRow" : "newRow" );

                                        if( row.skipped )
                                            rowClasses.push( "skipped" ); 

                                        if( row.error )
                                            rowClasses.push( "error" ); 

                                        if( row.imported )
                                            rowClasses.push( "imported" );

                                        if( row.importError )
                                            rowClasses.push( "importError" );
                                    }

                                    return (
                                        <tr key={ "row" + r } className={ rowClasses.join(" ") }>
                                            <td className="rowNum">
                                                { r == 0 ? "#" : ( r + 1 ) }
                                                { row.imported &&
                                                    <SvgIcon className="imported" icon="check" type="solid" />
                                                }
                                                { row.importError &&
                                                    <ApTooltip text={ row.importError } position="right">
                                                        <SvgIcon className="importError" icon="times" type="solid" />
                                                    </ApTooltip>
                                                }
                                            </td>
                                            { Object.keys( row.values ).map( ( col, c ) => {
                                                
                                                const errorMsg = cellError( r, c );
                                                let cellClasses = [];
                                                
                                                if( result.skippedCols.indexOf( c ) !== -1 && c != result.idColNum )
                                                    cellClasses.push( "skippedCol" );

                                                if( errorMsg )
                                                    cellClasses.push( "error" );

                                                if( c == result.idColNum )
                                                    cellClasses.push( "idColumn" );

                                                if( row.defaults.indexOf( c ) !== -1 )
                                                    cellClasses.push( "defaultValue" );

                                                let cellValue = false;
                                                if( typeof( row.values[ col ] ) === "boolean" )
                                                    cellValue = ( row.values[ col ] ? "TRUE" : "FALSE" );

                                                else if( row.values[ col ] )
                                                    cellValue = row.values[ col ]; 

                                                // Header cell have different table (subtable)
                                                if( r == 0 && cellValue && cellValue.indexOf('.') != -1 )
                                                {
                                                    cellValue = <span><small>{ cellValue.split('.')[0] }</small><br />{ cellValue.split('.')[1] }</span>
                                                }

                                                return (
                                                    <td key={ "row" + r + "col" + c } className={ cellClasses.join(" ") }>
                                                        { cellValue }
                                                        { errorMsg && 
                                                            <ApTooltip text={ errorMsg }>
                                                                <SvgIcon className="errorInfo" icon="info-circle" type="solid" />
                                                            </ApTooltip>
                                                        }
                                                    </td>
                                                );
                                                
                                            })}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    { result.warnings.length > 0 && 
                        <div className="apWarningMsg">
                            { result.warnings.map( msg => <div>{ msg }</div> ) }
                        </div>
                    }

                    <div className="apColumn w33">
                        <div className="padding-small">

                            <div className={"apFormGroup" + ( this.state.import_old ? " success" : "" ) }>
                                <div className="apSwitchBlock">
                                    <label htmlFor="import-old-switch" className="info">
                                        Päivitä vanhat
                                        <small>Yhteensä { result.stats.old_rows } rivi(ä)</small>
                                    </label>
                                    <ApSwitch
                                        id="import-old-switch"
                                        on={ this.state.import_old }
                                        onChange={ ( e ) => this.setState( { 'import_old': e.target.checked } ) }
                                        disabled={ this.state.loading }
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="apColumn w33">
                        <div className="padding-small">

                            <div className={"apFormGroup" + ( this.state.import_new ? " success" : "" ) }>
                                <div className="apSwitchBlock">
                                    <label htmlFor="import-new-switch" className="info">
                                        Lisää uudet
                                        <small>Yhteensä { result.stats.new_rows } rivi(ä)</small>
                                    </label>
                                    <ApSwitch
                                        id="import-new-switch"
                                        on={ this.state.import_new }
                                        onChange={ ( e ) => this.setState( { 'import_new': e.target.checked } ) }
                                        disabled={ this.state.loading }
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="apColumn w33">
                        <div className="padding-small">

                            <div className={"apFormGroup" + ( this.state.remove_others ? " success" : "" ) }>
                                <div className="apSwitchBlock">
                                    <label htmlFor="remove-others-switch" className="info">
                                        Poista muut
                                        <small>Yhteensä { result.stats.other_rows } rivi(ä)</small>
                                    </label>
                                    <ApSwitch
                                        id="remove-others-switch"
                                        on={ this.state.remove_others }
                                        onChange={ ( e ) => this.setState( { 'remove_others': e.target.checked } ) }
                                        disabled={ this.state.loading }
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <ApButton color="blue" onClick={ () => this.import( true ) } disabled={ this.buttonDisabled( true ) /*|| importableRows == 0*/ } loading={ this.state.loading }>
                        <SvgIcon icon="download" type="solid" />
                        Tuo tiedot
                    </ApButton>

                    { result.imported && 
                        <div className="apSuccessMsg">
                            <h4>Rivit tuotu onnistuneesti</h4>
                            <p>
                                Päivitetyt rivit: { result.stats.updated } kpl <br />
                                { result.stats.subtable_updated > 0 && <small>(Päivitetyt alataulujen rivit: { result.stats.subtable_updated } kpl)</small> }
                            </p>
                            <p>
                                Lisätyt rivit: { result.stats.inserted } kpl <br />
                                { result.stats.subtable_inserted > 0 && <small>(Lisätyt alataulujen rivit: { result.stats.subtable_inserted } kpl)</small> }
                            </p>
                        </div>
                    }
            
                </div>
            );
        }

    }

    render() 
    {

        const selectedTable = findItemByField( this.state.tables, "value", this.state.selected_table, false ); 

        let idColumnOptions = [{ value: '', label: ' - Valitse - ' }];

        if( selectedTable && selectedTable.unique_colums )
            selectedTable.unique_colums.forEach( col => { 
                idColumnOptions.push({ value: col, label: col });
            });

        if( selectedTable && selectedTable.pivot )
            idColumnOptions = [{ value: 'pivot', label: 'Ei yksilöivää saraketta (pivot taulu)' }];

        return (
            <div className="apBox" id="pageExcelImport">
                <div className="apBoxHeader">
                    <h1>Excel tuonti</h1>
                    <p>Päivitä tai tuo dataa excel taulukoilla.</p>
                </div>
                <div className="padding">

                    <ApInputStack gap="0">
                        <ApAddon width="150px" noRightBorder>
                            1. Valitse taulu
                        </ApAddon>
                        <ApInput
                            type="select"
                            id="selected_table"
                            name="selected_table"
                            value={ this.state.selected_table }
                            onChange={ this.handleChange }
                            options={ this.state.tables }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>

                    <ApInputStack gap="0">
                        <ApAddon width="150px" noRightBorder>
                            2. Valitse yritys
                        </ApAddon>
                        <ApInput
                            type="select"
                            id="selected_company"
                            name="selected_company"
                            value={ this.state.selected_company }
                            onChange={ this.handleChange }
                            options={ this.state.companies }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                        <ApAddon custom width="200px">
                            <ApButton style={{ margin: "5px" }} size="small" color="blue" onClick={ this.export } disabled={ !this.state.selected_table || !this.state.selected_company }>
                                <SvgIcon icon="download" type="solid" />
                                Lataa Excel-pohja
                            </ApButton>
                        </ApAddon>
                    </ApInputStack>

                    

                    <ApInputStack gap="0">
                        <ApAddon width="210px" noRightBorder>
                            3. Valitse yksilöivä sarake
                        </ApAddon>
                        <ApInput
                            type="select"
                            id="selected_id_column"
                            name="selected_id_column"
                            value={ this.state.selected_id_column }
                            onChange={ this.handleChange }
                            options={ idColumnOptions }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                        
                    <ApInputStack gap="0">
                        <ApAddon width="210px" noRightBorder>
                            4. Valitse Excel-tiedosto
                        </ApAddon>
                        <ApInput
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            id="selected_file"
                            name="selected_file"
                            label="Valitse Excel-tiedosto..."
                            value={ this.state.selected_file }
                            onChange={ ( file ) => { this.setState({ selected_file: file }) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>

                    <div className={"apFormGroup" + ( this.state.only_errors ? " success" : "" ) }>
                        <div className="apSwitchBlock">
                            <label htmlFor="only-errors-switch" className="info">
                                Näytä vain virheelliset rivit
                                <small>Tämä saattaa nopeuttaa käyttöliittymää isojen tiedostojen kanssa</small>
                            </label>
                            <ApSwitch
                                id="only-errors-switch"
                                on={ this.state.only_errors }
                                onChange={ ( e ) => this.setState( { 'only_errors': e.target.checked } ) }
                                disabled={ this.state.loading }
                            />
                        </div>
                    </div>

                    <ApButton color="green" onClick={ () => this.import() } disabled={ this.buttonDisabled() } loading={ this.state.loading }>
                        <SvgIcon icon="glasses" type="solid" />
                        Esikatsele tuonti
                    </ApButton>

                    {/* 
                    <button onClick={ () => this.importDev() }>Read</button>
                    <button onClick={ () => this.importDev( true ) }>Import</button>
                    */}
                    
                    { this.renderPreview() }
                    
                </div>
            </div>
        );
    }
}


ExcelImport.propTypes = {
    // number:       PropTypes.number.isRequired,
    // string:       PropTypes.string,
    // multiple:     PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
    // bool:         PropTypes.bool,
};


export default ExcelImport;
