export const customerSettingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SAVE_CUSTOMER_SETTINGS':
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

export const generalSettingsReducer = (state = {}, action) =>{
    switch (action.type) {
        case 'SAVE_GENERAL_SETTINGS':
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

export const orderSettingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SAVE_ORDER_SETTINGS':
            const data = {
                ...state,
                ...action.payload,
            }
            
            // Delete sensitive information if backend returns them
            delete data.woocommerce_user_key;
            delete data.woocommerce_user_secret;
            delete data.woocommerce_webhook_secret;
            
            return data;
        default:
            return state;
    }
}

export const projectSettingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SAVE_PROJECT_SETTINGS':
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

export const purchaseSettingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SAVE_PURCHASE_SETTINGS':
            const data = {
                ...state,
                ...action.payload,
            }

            return data;
        default:
            return state;
    }
}

export const storageSettingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SAVE_STORAGE_SETTINGS':
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

export const timetrackingSettingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SAVE_TIMETRACKING_SETTINGS':
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

export const externalSettingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SAVE_EXTERNAL_SETTINGS':
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}

export const apCostCenterSettingsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SAVE_COSTCENTER_SETTINGS':
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}




