import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import Measure from 'react-measure';
import * as d3 from "d3";
import nextId from 'services/NextId/NextId.js';

import { formatMoney } from 'services/Helpers/Helpers.js';

import './Chart.css';

const sqlToDate = d3.timeParse( '%Y-%m-%d' );

class Chart extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            width: 0,
            height: 300,
            margin: { top: 10, right: 50, bottom: 50, left: 50 },
            tooltip: {},
        }

        this.drawn = false;

        this.max = 100;


        this.domid = null;
        this.idleTimeout = null;

        autoBind(this);
    }

    componentDidMount()
    {
        this.domid = nextId('d3chart');
        // Initial drawing is done by this.resized() function

        // if( !this.drawn )
            // this.initialDraw();

    }

    componentDidUpdate(prevProps, prevState )
    {
        if( prevProps.projectId !== this.props.projectId )
        {
            this.reset();
        }

        if(JSON.stringify( prevProps ) !== JSON.stringify( this.props) )
        {
            this.cleanOldLines( prevProps.lines )
            this.updateLines();
        }
    }

    getData()
    {
        return [
            {"name": "A", "value": 10, "date": "2016-01"},
            {"name": "B", "value": 30, "date": "2016-02"},
            {"name": "C", "value": 20, "date": "2016-03"},
            {"name": "D", "value": 40, "date": "2016-04"},
            {"name": "E", "value": 50, "date": "2016-05"},
            {"name": "F", "value": 60, "date": "2016-06"},
            {"name": "G", "value": 90, "date": "2016-07"},
            {"name": "H", "value": 70, "date": "2016-08"},
            {"name": "I", "value": 80, "date": "2016-09"},
            {"name": "J", "value": 50, "date": "2016-10"},
            {"name": "K", "value": 20, "date": "2016-11"},
            {"name": "L", "value": 10, "date": "2016-12"}
        ];
    }

    formatFI( date )
    {
        var locale = d3.timeFormatLocale({
          "decimal": ",",
          "thousands": " ",
          "grouping": [3],
          "currency": ["€", ""],
          "dateTime": "%a %b %e %X %Y",
          "date": "%m/%d/%Y",
          "time": "%H:%M:%S",
          "periods": ["AM", "PM"],
          "days": ["Sunnuntai", "Maanantai", "Tiistai", "Keskiviikko", "Torstai", "Perjantai", "Lauantai"],
          "shortDays": ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"],
          "months": ["Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu", "Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"],
          "shortMonths": ["Tammi", "Helmi", "Maalis", "Huhti", "Touko", "Kesä", "Heinä", "Elo", "Syys", "Loka", "Marras", "Joulu"]
        });

        var formatMillisecond = locale.format(".%L"),
            formatSecond = locale.format(":%S"),
            formatMinute = locale.format("%I:%M"),
            formatHour = locale.format("%I %p"),
            formatDay = locale.format("%a %d"),
            formatWeek = locale.format("%b %d"),
            formatMonth = locale.format("%B"),
            formatYear = locale.format("%Y");

      return (d3.timeSecond(date) < date ? formatMillisecond
          : d3.timeMinute(date) < date ? formatSecond
          : d3.timeHour(date) < date ? formatMinute
          : d3.timeDay(date) < date ? formatHour
          : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
          : d3.timeYear(date) < date ? formatMonth
          : formatYear)(date);
    }

    resetXScale()
    {
        const start = moment( this.props.start );
        const end = moment( this.props.end );
        this.xScale = d3.scaleTime()
            .domain([ start.toDate(), end.toDate() ])
            .range([0, this.innerWidth() ]);
    }

    resetYScale()
    {
        this.yScale = d3.scaleLinear()
            .domain([0, this.max ]) // input
            .range([ this.innerHeight(), 0]); // output
    }

    innerWidth() { return this.state.width - this.state.margin.left - this.state.margin.right; }
    innerHeight() { return this.state.height - this.state.margin.top - this.state.margin.bottom; }

    calculateMax()
    {
        let max = 100;
        this.props.lines.forEach( line => {
            line.data.forEach( i => {
                if( ( i.percent + 4 ) > max ) max = ( i.percent + 4 );
            })
        });
        this.max = max;
    }

    drawCanvas()
    {
        // Clear image before redraw
        d3.select(`#${ this.domid } > *`).remove();
        this.canvas = d3.select( '#'+this.domid )
            .append("svg")
            .attr("width", this.state.width )
            .attr("height", this.state.height )
          .append("g")
            .attr("transform", "translate(" + this.state.margin.left + "," + this.state.margin.top + ")");
    }

    drawYScale()
    {
        this.canvas.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - this.state.margin.left)
            .attr("x", 0 - ( this.innerHeight() / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text("Prosenttia");

        this.yElem = this.canvas.append("g")
            .attr("class", "y-axis")
            .call(d3.axisLeft( this.yScale ));
    }

    updateYScale()
    {
        const scale = this.yScale;

        //const max = scale.domain()[1];
        //if( this.max > 100 ) this.draw100Mark();

        this.yElem.transition().duration(1000).call( d3.axisLeft(scale) )
    }

    drawXScale()
    {
        this.xElem = this.canvas.append("g").call( d3.axisBottom( this.xScale ).tickFormat( this.formatFI ) )
            .attr("transform", `translate(0, ${ this.innerHeight() } )`)
            .attr("class", "x-axis");
    }

    onDotMouseOver( item, line )
    {
        if( typeof this.props.onMouseoverDot === 'function' )
            this.props.onMouseoverDot( item );

        let tooltip = { ...this.state.tooltip }
        tooltip.show = true;

        let otherValuesDom = null;

        if( line.tooltip )
        {
            otherValuesDom = line.tooltip.map( (t, i ) => {
                    return <tr key={ t.title }>
                        <td className="title">{ t.title } </td>
                        <td className="value">{ formatMoney(  item[ t.value ], 1 ) }{ t.unit }</td>
                    </tr>
            })
        }
        tooltip.text = <div>
            <div>{ line.title }</div>
            <table>
                <tbody>
                    <tr>
                        <td className="title">Päivä</td>
                        <td className="value">{ moment( item.date).format( 'dddd DD.MM.YY' ) }</td>
                    </tr>
                    <tr>
                        <td className="title"></td>
                        <td className="value">{ formatMoney( item.percent, 0 ) }%</td>
                    </tr>
                    { otherValuesDom }
                </tbody>
            </table>
        </div>

        this.setState({ tooltip: tooltip });
    }

    onDotMouseOut( item )
    {
        if( typeof this.props.onMouseoutDot === 'function' )
            this.props.onMouseoutDot( item );

        let tooltip = { ...this.state.tooltip }
        tooltip.show = false;
        tooltip.text = null;
        this.setState({ tooltip: tooltip });
    }

    getLineData( line )
    {
        let data = line.data ? line.data : [];
        data = [{
            change: 0,
            date: this.props.start,
            percent: 0,
            value: 0,
        }, ...data ];
        return data;
    }

    updateLineDots( line, isNew = false )
    {
        const data = this.getLineData( line );
        data.forEach( d => {

            const dotLClass = `infodot-${ line.name }`;
            const dotClass = `infodot-${ line.name }-${ d.date}`;

            const x = this.xScale( sqlToDate( d.date ) );
            const y = this.yScale(d.percent );

            const mouseOver = (...a) => {
                this.onDotMouseOver( d, line );

                // Creating line between circle and axis
                this.layer.append("line")
                    .attr( "class", "hover-line" )
                    .attr("x1", () => { return x } )
                    .attr("x2", () => { return x } )
                    .attr("y1", () => { return y } )
                    .attr("y2", this.yScale( 0 ) )
                    .style("stroke-dasharray","5,5")
                    .style("pointer-events","none")
                    .style("stroke","black");

                // Creating line between circle and axis
                this.layer.append("line")
                    .attr( "class", "hover-line" )
                    .attr("x1", () => { return x } )
                    .attr("x2", this.xScale( 0 ) )
                    .attr("y1", () => { return y } )
                    .attr("y2", () => { return y } )
                    .style("stroke-dasharray","5,5")
                    .style("pointer-events","none")
                    .style("stroke","black");

            };
            const mouseOut = () => {
                this.onDotMouseOut( d, line );
                this.layer.selectAll(".hover-line").remove();
            };

            if( isNew )
            {
                this.layer.append("circle")
                    .on("mouseover", mouseOver ).on("mouseout", mouseOut )
                    .attr( "class", `infodot ${ dotLClass } ${ dotClass }` )
                    .attr("cx", x)
                    .attr("cy", y)
                    .attr("r", 2)
                    .style("fill", line.color )

                    .style("stroke-width", 8 ) // Add invisible border around the dot so mouse over area is little bit bigger
                    .style("stroke", "black")
                    .style("stroke-opacity", .0)

            }
            else
            {
                const dotElem = this.layer.select(`.${ dotClass }`)
                dotElem.on( "mouseover", mouseOver ).on("mouseout", mouseOut )
                dotElem.transition().duration(1000)
                    .attr("cx", x)
                    .attr("cy", y)
            }
        });
    };

    updateXScale()
    {
        this.xElem.transition().duration(1000).call( d3.axisBottom( this.xScale ).tickFormat( this.formatFI ) )
    }

    cleanOldLines( oldLines )
    {
        const lines = this.props.lines;
        oldLines.forEach( old => {
            const found = lines.find( f =>  f.name === old.name );
            if( found ) return null;
            this.layer.selectAll(`.line-${ old.name }`).remove();
            this.layer.selectAll(`.infodot-${ old.name }`).remove();
        });
    }

    updateLines()
    {
        // Update Y scale based on max value
        this.calculateMax();
        // this.resetYScale();
        // this.updateYScale();

        this.draw100Mark();

        this.drawToday();

        this.props.lines.forEach( line => {

            const data = this.getLineData( line );

            let d3line = d3.line()
                .x( ( d ) => { return this.xScale( sqlToDate( d.date ) ); })
                .y( ( d ) => { return this.yScale(d.percent ); })
                //.curve(d3.curveMonotoneX)

            let lineClasses = [ 'line', `line-${ line.name }` ];
            if( this.props.highlight === line.name )
                lineClasses.push( 'strong' );

            let lineElem = this.layer.select(`.line-${ line.name }`)
            const isNew = ( lineElem.size() === 0 )

            if( isNew ) lineElem = this.layer.append("path").datum( data );
            else lineElem = lineElem.transition().duration(1000)

            lineElem.attr( "d", d3line )
                .attr( "class", lineClasses.join(' ') )
                .style( "stroke", line.color )

            this.updateLineDots( line, isNew );

        });

    }

    draw100Mark()
    {
        let markElem = this.layer.select( '.line100' );
        const isNew = ( markElem.size() === 0 );
        const xDomain = this.xScale.domain();
        const x1 = this.xScale( xDomain[ 0 ] );
        const x2 = this.xScale( xDomain[ 1 ] );
        const y = this.yScale( 100 );

        if( isNew )
        {
            markElem = this.layer.append("line")
                .attr( "class", 'line100' )
                .style('shape-rendering','crispEdges')
        }
        else
        {
            markElem = markElem.transition().duration(1000)
        }
        markElem.attr("x1", x1 )
            .attr("x2", x2 )
            .attr("y1", y )
            .attr("y2", y );
    }

    drawToday()
    {
        let markElem = this.layer.select( '.lineToday' );
        const isNew = ( markElem.size() === 0 );

        const yDomain = this.yScale.domain();

        const x = this.xScale( sqlToDate( moment().format('YYYY-MM-DD') ) );
        const y1 = this.yScale( yDomain[ 0 ] );
        const y2 = this.yScale( yDomain[ 1 ]);

        if( isNew )
        {
            markElem = this.layer.append("line")
                .attr( "class", 'lineToday' )
                .style('shape-rendering','crispEdges')
        }
        else
        {
            markElem = markElem.transition().duration(1000)
        }
        markElem.attr("x1", x )
            .attr("x2", x )
            .attr("y1", y1 )
            .attr("y2", y2 );


    }


    // zoom1D()
    // {
        // const extent = d3.event.selection
        // if( extent )
        // {
            // this.xScale.domain([ this.xScale.invert( extent[0]), this.xScale.invert(extent[1]) ])
            // this.layer.select(".brush").call(this.brush.move, null) // This remove the grey brush area as soon as the selection has been done
        // }
        // this.update();
    // }

    zoom2D()
    {
        const extent = d3.event.selection
        if( extent )
        {
            this.xScale.domain([ this.xScale.invert( extent[0][0]), this.xScale.invert(extent[1][0]) ])
            this.yScale.domain([ this.yScale.invert( extent[1][1]), this.yScale.invert(extent[0][1]) ])
            this.layer.select(".brush").call(this.brush.move, null) // This remove the grey brush area as soon as the selection has been done
        }
        this.update();
    }

    initialDraw()
    {
        this.resetXScale();
        this.resetYScale();
        this.drawCanvas();

        this.drawXScale();
        this.drawYScale();

        // Add a clipPath: everything out of this area won't be drawn.
        /*
        let clip = this.canvas.append("defs").append("svg:clipPath")
            .attr("id", "clip")
            .append("svg:rect")
            .attr("width", this.innerWidth() )
            .attr("height", this.innerWidth() )
            .attr("x", 0)
            .attr("y", 0)
            */

        // Create layer that lines and brush are drawn
        this.layer = this.canvas.append('g').attr("clip-path", "url(#clip)")

        // Add the brush feature using the d3.brush function
        // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
        // Each time the brush selection changes, trigger the 'zoom' function
        this.brush = d3.brush()
            .extent( [ [ 0, 0 ], [ this.innerWidth(), this.innerHeight() ] ] )
            .on("end", () => this.zoom2D() )

        // 1D Brush
        // this.brush = d3.brushX()
            // .extent( [ [ 0, 0 ], [ this.innerWidth(), this.innerHeight() ] ] )
            // .on("end", () => this.zoom1D() )


        // Add the brushing
        this.layer.append("g").attr("class", "brush").call(this.brush);

        // If user double click, reinitialize the chart
        this.canvas.on("dblclick", () => {
            this.resetXScale();
            this.resetYScale();
            this.zoom2D();
        });

        this.drawn = true;
    }

    resized( bounds )
    {
        this.setState({ width: bounds.width }, () => {

            // If view has already been drawn we just update it
            // however if this is first drawing we call initialDraw()
            if( this.drawn )
            {
                let canvas = d3.select( '#'+this.domid+' > svg');
                canvas.attr("width", this.state.width );
                this.canvas = canvas;

                this.reset();

                // this.resetXScale();
                // this.resetYScale();
                // this.update();
            }
            else this.initialDraw();
        });
    }

    reset()
    {
        this.resetXScale();
        this.resetYScale();
        this.update();
    }


    update()
    {
        this.updateXScale();
        this.updateYScale();
        this.updateLines();
    }

    updateTooltipPosition( e )
    {
        let tooltip = {...this.state.tooltip}
        tooltip.top = e.clientY + 10;

        // Show toolit on the right side of the line when goes above window middle
        if( e.clientX > ( window.innerWidth / 2 ))
        {
            tooltip.left =  undefined;
            tooltip.right = ( window.innerWidth - e.clientX - 10 );
        }
        else
        {
            tooltip.left = e.clientX + 10;
            tooltip.right =  undefined;
        }
        this.setState({ tooltip: tooltip });
    }

    render()
    {
        const tooltip = this.state.tooltip;
        let tooltipDom = null;
        if( tooltip.show )
        {
            tooltipDom = <div className="trackingChartTooltip" style={{ top: tooltip.top, left: tooltip.left, right: tooltip.right }}>
                { tooltip.text }
            </div>
        }

        return <div className="trackingChart">
            <Measure
                bounds
                onResize={ ( data ) => { this.resized( data.bounds ); } }>
                {({ measureRef }) => <div ref={ measureRef } id={ this.domid }  onMouseMove={ this.updateTooltipPosition }></div> }
            </Measure>
            { tooltipDom }
        </div>
    }
}

export default Chart;
