import React from 'react';
import autoBind from 'react-autobind';

import './Help.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import VerticalScroller from 'common/VerticalScroller/VerticalScroller.js';
import { tr } from 'services/Helpers/Helpers';

class Help extends React.Component {
	
    constructor( props ) {
		super(props);
        this.state = {
            location: null,
            pages: [
                { 
                    url: "GENERAL",
                    name: "Yleiset ohjeet",
                },
                { 
                    url: "/",
                    name: tr("frontpage"),
                },
                { 
                    url: "/timetracking/entry",
                    name: tr("own_working_time"),
                },
                { 
                    url: "/timetracking/approval",
                    name: tr("approval_of_hours"),
                },                
                { 
                    url: "/timetracking/confirm",
                    name: tr("administrator_approval"),
                },
                { 
                    url: "/timetracking/overview",
                    name: tr("working_hours_overview"),
                },
                { 
                    url: "/timetracking/salary_extras",
                    name: tr("fees_supplements"),
                },
                { 
                    url: "/timetracking/payroll",
                    name: tr("payroll"),
                },
                { 
                    url: "/workhours/holidays",
                    name: tr("holidays"),
                },
                { 
                    url: "/crm/customers",
                    name: tr("customers"),
                },
                { 
                    url: "/crm/groups",
                    name: tr("customer_groups_management"),
                },
                { 
                    url: "/crm/extranet",
                    name: "Extranet",
                },
                { 
                    url: "/crm/groups/customer",
                    name: tr('customer_groups'),
                },
                { 
                    url: "/crm/groups/supplier",
                    name: tr('supplier_groups'),
                },
                { 
                    url: "/crm/viestisuora",
                    name: "Viestisuora",
                },
                { 
                    url: "/crm/company",
                    name: tr('customer'),
                },
                { 
                    url: "/hr/users",
                    name: tr("personnel"),
                },
                { 
                    url: "/hr/usergroups",
                    name: tr("user_groups_management"),
                },
                { 
                    url: "/hr/permissions",
                    name: tr("user_permissions"),
                },
                { 
                    url: "/hr/permits",
                    name: tr("personnel_work_permits"),
                },
                { 
                    url: "/storage/components",
                    name: tr("storage_components"),
                },
                { 
                    url: "/storage/locations",
                    name: tr("storages_storage_locations"),
                },
                { 
                    url: "/storage/purchase/demands",
                    name: tr("order_requirements"),
                },
                { 
                    url: "/storage/purchase/orders",
                    name: tr("purchase_orders"),
                },
                { 
                    url: "/storage/purchase/order/",
                    name: tr("purchase_order"),
                },
                { 
                    url: "/storage/purchase/orders/import",
                    name: tr("import_pos"),
                },
                { 
                    url: "/storage/purchase/suppliers",
                    name: tr("suppliers"),
                },
                { 
                    url: "/storage/logistic/arrivals",
                    name: tr("logistics_arrivals"),
                },
                { 
                    url: "/storage/logistic/deliveries",
                    name: tr("logistics_deliveries"),
                },
                { 
                    url: "/storage/logistic/delivery/show",
                    name: tr("logistics_deliveries"),
                },
                { 
                    url: "/storage/logistic/tracking",
                    name: tr("logistics_tracking"),
                },
                { 
                    url: "/storage/reader",
                    name: tr("storage_reader"),
                },
                { 
                    url: "/storage/transports",
                    name: tr("storage_transfers"),
                },
                {
                    url: "/projects",
                    name: tr("projects"),
                },
                { 
                    url: "/projectspersonnel",
                    name: tr("production_management"),
                },
                {
                    url: "/taskqueue",
                    name: tr("task_queue"),
                },
                {
                    url: "/workgroups",
                    name: tr("workgroups"),
                },
                {
                    url: "/billable",
                    name: tr("billing_work"),
                },
                { 
                    url: "/invoices",
                    name: "Laskut",
                },
                { 
                    url: "/storage/orders",
                    name: tr("sales_orders"),
                },
                { 
                    url: "/storage/order/",
                    name: tr("order"),
                },
                { 
                    url: "/reports",
                    name: tr("reports"),
                },
                { 
                    url: "/settings/general",
                    name: tr("general_settings"),
                },
                { 
                    url: "/settings/customers",
                    name: tr("customer_settings"),
                },
                { 
                    url: "/timetracking/settings",
                    name: tr("working_time_settings"),
                },
                { 
                    url: "/settings/workpermits",
                    name: tr("work_permit_settings"),
                },
                { 
                    url: "/settings/storage",
                    name: tr("storage_settings"),
                },
                { 
                    url: "/settings/purchase",
                    name: tr("purchase_settings"),
                },
                { 
                    url: "/settings/order",
                    name: tr("sales_settings"),
                },
                { 
                    url: "/settings/projects",
                    name: tr("project_settings"),
                },
                { 
                    url: "/settings/costcenter",
                    name: tr("cost_center_settings"),
                },
                { 
                    url: "/settings/notifications",
                    name: tr("notification_settings"),
                },
                { 
                    url: "/settings/extranet",
                    name: tr("extranet_settings"),
                },
                {
                    url: "/management/customermanagement",
                    name: "Asiakashallinta",
                },
                { 
                    url: "/management/billofuse",
                    name: "EASY-PRO käytöstä laskutettavat",
                },
                { 
                    url: "/management/import",
                    name: "Excel tuonti",
                },
                { 
                    url: "/management/bugs",
                    name: "Lähetetyt bugi-ilmoitukset",
                },
                { 
                    url: '/assignments',
                    name: tr('assignments')
                },
                {
                    url: '/ext/assignments',
                    name: tr('ext_assignment_header')
                },
                {
                    url: '/orders/checkout',
                    name: tr('checkout')
                },
                {
                    url: '/orders/checkout/collection',
                    name: tr('collection')
                },
                {
                    url: '/user',
                    name: tr('user_page')
                },
                {
                    url: "/foobar",
                    name: "TODO",
                },
            ]
        };
        autoBind(this); 
	}

    clickOutsideHandler(event) {
    	if( !this.node.contains(event.target) ) {
    		this.props.closeEvent();
    	}
    }

    changeLocation( url )
    {
        this.setState({ location: url });
    }

    clearLocation()
    {
        this.changeLocation( null );
    }

    getUrl()
    {
        return this.state.location ? this.state.location : this.props.url ? this.props.url : window.location.pathname;
    }

    renderPageHelp( url )
    {
        switch( url )
        {
            case "general":
                return (
				
                    <div className="padding">
                        <h3>Yleiset ohjeet</h3>
                        <p className="ingress">Tervetuloa käyttämään Easy-Pro-järjestelmää.</p>

                        <h5>Yläpalkki</h5>
                        <p>Yläpalkista löytyy seuraavat toiminnot:
                            <ul>
                                <li>
                                    <SvgIcon className="inline topbar" icon="clock" />
                                    <strong>Sähköinen kellokortti</strong>, jolla voit helpottaa työajan kirjaamista. Kun aloitat työt, leimaat itsesi sisään ja kun lopetat työt, leimaat itsesi ulos, jolloin järjestelmä laskee automaattisesti työtuntimäärän ja lisää sen tuntikirjaukseen. 
                                </li>
                                <li>
                                    <SvgIcon className="inline topbar" icon="user-circle" type="solid" />
                                    <strong>Oma profiili</strong> näyttää kirjautuneen henkilön perustiedot. Salasanan vaihtaminen ja ulos kirjautuminen löytyvät myös oman profiilin alta.   
                                </li>
                                <li>
                                    <SvgIcon className="inline topbar" icon="exclamation-triangle" type="solid" />
                                    <strong>Vikailmoitus</strong> mahdollistaa järjestelmän virheiden ja ongelmien ilmoittamisen kehittäjille.    
                                </li>
                                <li>
                                    <SvgIcon className="inline topbar" icon="question-circle" />
                                    <strong>Ohje</strong>-sivu tarjoaa vinkkejä ja opastusta järjestelmän käyttöön.  
                                </li>
                            </ul>
                        </p>

                        <h5>Sivupalkki</h5>
                        <p>Vasemmassa reunassa olevasta sivupalkista pääset siirtymään eri osioiden välillä. Sinulle näkyy vain ne osiot joihin sinulla on käyttöoikeus. </p>
                    
                        <p>
                            <SvgIcon className="inline" icon="search" type="solid" />
                            <strong>Haku</strong>-ominaisuudella voit hakea projekteja, yrityksiä ja henkilöitä järjestelmän sisältä. 
                        </p>

                        <p>
                            <SvgIcon className="inline" icon="angle-double-left" type="solid" />
                            <strong>Tuplanuolilla</strong> voit tarvittaessa pienentää sivupalkin, jolloin saat enemmän tilaa itse sisällölle. Vastaavasti tuplanuolilla voit myös laajentaa sivupalkin, kun se on pienennettynä. 
                        </p>

                        <h5>Yleisiä kuvakkeita</h5>

                        <p>
                            Järjestelmässä usein käytössä olevia kuvakkeita:
                            <ul>
                                <li>
                                    <SvgIcon className="inline noBg" icon="info-circle" type="solid" />
                                    <strong>Lisätieto</strong>-kuvake antaa lisää tietoa tai ilmaisee, että lisää tietoa on saatavilla.
                                </li>
                                <li>
                                    <SvgIcon className="inline noBg warning" icon="exclamation-circle" type="solid" />
                                    <strong>Huomio</strong>-kuvake ilmaisee, että käyttäjän tulee huomioida jotain. Esimerkiksi jos jokin tehty muutos ei vielä ole tallennettu. 
                                </li>

                                <li>
                                    <SvgIcon className="inline" icon="ellipsis-h" type="solid" />
                                    <strong>Lisää</strong>-kuvake antaa lisää vaihtoehtoja tai toimintoja.
                                </li>

                                <li>
                                    <SvgIcon className="inline" icon="filter" type="solid" />
                                    <strong>Suodata</strong>-kuvake mahdollistaa tiedon rajaamisen tietyin hakuehdoin.
                                </li>
                            </ul>
                        </p>

                    </div>
                );
            case "/":
                return (
                    <div className="padding">
                        <h3>Aloitussivu</h3>
                        <p className="ingress">Aloitussivu kokoaa sinulle yhteenvedon käytössäsi olevista osioista ja näet tilastotietoja järjestelmästä.</p>

                        <p>Voit itse määrittää mitä tilastoja haluat nähdä ja missä järjestyksessä. <SvgIcon className="inline" icon="th-list" type="solid" />-kuvakkeesta pääset valitsemaan mitkä osiot haluat nähdä aloitussivulla. </p>

                        <h5>Yhteenvedot</h5>

                        <p>
                            <SvgIcon className="inline" icon="arrow-right" type="solid" />
                            <strong>Nuolella</strong> pääset siirtymään kyseiseen osioon ja saat tarkemmat tiedot kohteesta.  
                        </p>
                    </div>
                );

                case "/timetracking/entry":
                    return (
                        <div className="padding">
                            <h3>Oma työaika</h3>
<p> - Oma työaika kohdassa sovelluksessa kirjaat tekemäsi työtunnit, matkat ja matkatunnit,
poissaolot ja muut kulut (kuitit) järjestelmään.</p>

<p> - Ohjelma aukeaa automaattisesti kuluvaan viikkoon. Tässä kannattaa olla tarkkana.
Ylävalikosta pääset muuttamaan helposti vuotta ja viikkoa, joko suoraan valikosta tai siten
nuolinäppäimillä eteen- tai taaksepäin.</p>

<p> - Näkymässä näkyy yhden viikon näkymä. Klikkaamalla päivää avautuu näkymä johon
tulee teksti ”lisää projekti”. Kun tähän kirjaa joko työnumeron tai projektinimen, niin
löydät nopeasti sen työnumeron, jonka esimiehesi on sinulle antanut. Valitse työnumero,
jolloin tulee näkyviin sininen palkki, jossa lukee ”lisää tuntikirjaus”. Kun tästä klikkaa, niin
tulee näkyviin uusi ikkuna, johon tuntikirjaus kirjataan.</p>

<p> 1. Työnimike tulee yleensä automaattisesti, mutta tänne voidaan projektien alta kirjata
joku muu määre, kuin tuntikirjaus, jos laskutusperuste on esim. kappale. Yleensä
tässä ei ole muuta valintamahdollisuutta.</p>

<p> 2. Kuvaus kohtaan kirjataan tehty työ tarkemmin. Se on vapaa kenttä ja riippuen
asetuksista joko pakollinen kenttä tai ei pakollinen. Lisätietoa napista aukeaa isompi
näyttö, johon voi sitten kirjata enemmän tehtäviä.</p>

<p> 3. Työtunti kohtaan laitetaan tehty työmäärä tunteina. Mikäli asetuksissa on määritelty,
niin tähän voi lisätä tarpeen mukaan ylityöt ”lisää tuntirivi” sarakkeesta.</p>

<p> 4. Mikäli projekti asetuksissa on määritelty, niin näkymässä pystytään myös kirjaamaan
tehdyt määrät (metriä/kpl/kg jne), jotka on määritelty projektin alla.</p>

<p> - Kun mennään takaisin perusnäkymään voidaan valita toinen projektinumero. Tai
voidaan sinisestä plus -merkistä valita lisää tuntikirjaus, matkat tai kulukorvaus.</p>

<p> a) Matkakorvaus. Ensin tulee kuvaus, johon kirjataan matkan tarkoitus. Sitten valitaan
listasta (mikäli asetuksiin on määritetty vaihtoehtoja), millä kulkuneuvolla on oltu
liikkeellä. Matkareitti (tämä on pakollinen kenttä), matkan pituus ja matkan aika.
Matka-aika tarkoittaa työaikaan laskettavaa matka-aikaa.</p>

<p> b) Kulukorvaus. Ensin valitaan maksutapa (vaihtoehdot on määritelty asetuksissa).
Mikäli kulu on tullut tilaisuudessa, joka on ollut neuvottelu, Asiakastapaaminen tai
vastaava tilaisuus, niin aktivoidaan nappi. Tällöin tulee näkymä, johon laitetaan
tilaisuuden nimi (pakotettu kenttä) ja vapaa kenttä, jossa tulee ilmetä tilaisuuden
luonne ja edustuskuluissa niiden henkilöiden nimet, joita edustus on koskenut.
Valokuva kuitista liitetään ”lisää kuva kuitista” kohtaan. Pakolliset kulurivit kuvaus,
hinta ja valuutta. Ja kulurivejä voidaan lisätä ”lisää kulurivi” kohdasta.</p>

<p> - Päiväkohtaisen näkymän oikeassa reunassa on kolme pistettä. Näissä on näkymä ”Koko
päivän poissaolo”, kopioi rivi seuraavalle päivälle ja kopioi rivi loppuviikolle tai tyhjennä.
Koko päivän poissaolo estää muut kirjaukset kyseiselle päivälle. Napin ollessa kuitenkin
ei aktiivinen, voidaan merkitä myös osapäivä poissaoloja (tuntien perusteella) Valikossa
nähdään ne poissaolosyyt, mitä on asetuksissa määritelty. Yleisiä ovat sairausloma,
arkipyhä, vuosiloma, poissa luvalla jne. Näkymässä on myös poissaolon syy, joka
voidaan asetuksissa määritellä pakolliseksi /ei pakolliseksi.</p>

<p> - Matkalaukun kuvasta (rivin oikealla reunalla) voidaan lisätä päivälle päiväraha.
Asetuksissa määritellyt päivärahat näkyvät listalla ja näkymässä määritetään myös
työmatkan kesto vetovalikosta.</p>

<p> - Kokonaisvalikosta näkee helposti viikon kirjaukset eri väreillä. Sininen väri on työtunti ja
poissaolo on oranssi väri. Riippuen henkilön asetuksista onko määritelty pakollinen
työtuntimäärä tai onko käytössä työaikapankki ym, niin näkymässä näkyy paljonko
tunteja on kirjaamatta tai paljonko on mahdollisia työaikajoustoja plus/miinus.</p>

<p> - Muista aina tallentaa kirjaukset sinisestä talleta napista. Työtunteja voi kirjata kuinka
usein haluaa. Vasta kun painaa lähetä nappia, niin tunnit lähtevät esimiehelle
hyväksyttäväksi. Kun painaa lähetä nappia, niin järjestelmä laskee työtunnit ja tekee
asetuksista riippuen ehdotuksen. Tällöin hyväksyt ehdotuksen tai teet muutokset joko
tässä näkymässä tai sitten poistut ja teet muutokset kirjauksiin. Kun painat lähetä viikko,
niin järjestelmä lähettää ne päivät ja viikon tunnit, mitkä olet päättänyt lähettää.</p>

<p> - Voit milloin tahansa tarkastella historiatietojasi ja näet myös lähettämäsi työtuntien
hyväksyntäprosessin oikeassa reunassa eri väreillä. Kun laitat hiiren ko. nuolen päälle,
niin näet onko tunnit hyväksytty, hylätty (tästä tulee myös etusivun ilmoitus-osioon ja
etusivulle kohtaan työtunnit merkintä). Pääset korjaamaan työtunnit klikkaamalla ko.
kohtaa.</p> 
                            </div>
                              );


                    case "/timetracking/approval":
                        return (
                            <div className="padding">
                                <h3>Tuntien hyväksyntä</h3>
<p> - Tuntien hyväksyntä osiossa hyväksytään tunnit, jotka tiimisi jäsen on lähettänyt sinulle ja/
tai kun joku on kirjannut tunteja projektiin, jossa sinulla on tuntien hyväksyntä oikeus.</p>  

<p> - Ensin valitaan henkilö. Sitten tunnit hyväksytään joko rivikohtaisesti rivin oikeassa reunassa olevasta odottaa painikkeesta. 
Sitä painamalla kerran tulee hyväksyntä (vahvistettu), painamalla kaksi kertaa tulee hylätty. 
Hylätyn kohdalla tulee ajatuskupla, jota klikkaamalla tulee mahdollisuus kirjoittaa hylkäämisen syy. 
Kolmannen kerran klikkaamalla painike palautuu alkuperäiseen odottaa tilaan.</p>  

<p> - Oikealla viikko numeron edessä on pieni laatikko, johon laittamalla ruksin aktivoi kaikki näkyvät rivit. 
Jokaisen rivin etupuolella on samanlainen pieni laatikko, joka voidaan ottaa pois tai laittaa päälle. 
Oikealla alakulmassa on kolme pistettä ja sieltä voi vahvistaa valitut (hyväksyä) hylkää valitut (hylkää) 
tai kumoa päätös valituista (palautuu alkutilaan).</p>  

<p> - Voit käydä kaikki henkilöt läpi ja tehdä hyväksyntäpäätökset. Vasta kun painat "tallenna" muutokset, toimenpiteet tallentuvat.</p>  

<p> - Ylhäällä oikealla on vihreä nappi “näytä kaikki kirjaukset”. Tästä näet henkilön muut kirjaukset, 
myös ne, joita ei ole osoitettu sinulle hyväksyttäväksi. Näin näet, että henkilö on kirjannut muut kirjaukset.</p>           
                        </div>
                        );

                        case "/timetracking/confirm":
                            return (
                                <div className="padding">
                                    <h3>Pääkäyttäjän hyväksyntä</h3>
<p>- Pääkäyttäjä hyväksyntä on samanlainen toiminnoiltaan kuin tuntien hyväksyntä. Tässä toiminnossa on kuitenkin hyväksyttävä kaikki yrityksen tunnit. 
Yleisesti tämä oikeus on yhdellä henkilöllä yrityksessä, esim. pääkäyttäjä / talouspäällikkö / IT päällikkö</p>
                        </div>
                        );

                        case "/timetracking/overview":
                            return (
                                <div className="padding">
                                    <h3>Yleiskatsaus</h3>                                     
<p> - Yleiskatsauksessa pääkäyttäjä näkee kaikki ja esimies oman tiimin tuntikirjaustilanteen.</p>

<p> - Kun päivän kohdalla on vihreä pallura yhdellä väkäsellä, on esimies merkinnät hyväksynyt, 
jos on kaksi väkästä, niin myös pääkäyttäjä on kirjaukset hyväksynyt.</p>

<p> - Sininen pallura tarkoittaa, että merkinnät on kirjattu, mutta esimies ei ole niitä hyväksynyt.</p>
 
<p> - Punainen pallura tarkoittaa, että esimies on merkinnät hylännyt.</p>

<p> - Harmaa pallura tarkoittaa, että merkinnät on tehty, mutta niitä ei ole lähetetty.</p>

<p> - Lukon kuva tarkoittaa, että päivät on lukittu (ne on lähetetty eteenpäin hyväksyttäväksi).</p>
 
<p> - Ja mikäli kohdassa ei ole mitään, niin silloin ko. päivälle ei ole kirjattu mitään.</p>
                        </div>
                        );

                        case "/timetracking/salary_extras":
                            return (
                                <div className="padding">
                                    <h3>Palkkiot ja lisät</h3>                                    
<p> - Palkat ja lisät kohdassa voidaan määrittää joko kertaluonteisia tai automaattisia (jatkuvia) palkkioita ja lisiä.</p> 

<p> - Kun haluat tehdä uuden kertaluonteisen lisän, niin paina uusi kertaluonteinen lisä. Tulee näkymä, jossa kirjaat päivämäärän. 
Riittää, että päivämäärä on palkkakaudella, jolloin lisä tulee ko. palkkajaksoon. Kirjaa yksi tai useampi lisän saaja, 
kirjaa lisän nimi ja palkkalaji (tämän saat yleensä palkanlaskijalta). Sen jälkeen määrä ja yksikköhinta. 
Huomaa, että yksikköhinta voi olla myös negatiivinen, mikäli henkilön palkkaan halutaan tehdä vähennyksiä.</p> 

<p> - Kertaluonteiset lisät näkymässä näkyy palkanmaksua odottavat kertaluonteiset lisät ja palkanmaksuun siirretyt lisät. 
Näitä voi hakea ja sortata eri hakutoiminnoin.</p> 

<p> - Kun haluat uuden automaattisen lisän, niin paina lisää automaattinen lisä. Tulee näkymä, johon laitetaan lisän nimi, kuvaus ja palkkalaji. 
Valitaan maksettava euro summa, joko kiinteä summa, tuntimäärän mukaan tai tuntipalkan mukaan. 
Tämän jälkeen voit tehdä rajoituksen korvauksen määrään: euroa, voimassaoloaikaan, päivään, viikkoon, kuukauteen tai vuodessa. 
Automaattinen lisä voidaan lisäksi kytkeä pois päältä. Valitaan aikaväli ja henkilöt kenelle lisä maksetaan. 
Lisäksi voidaan vielä rajata lisän maksua projektikohtaisesti.</p> 

<p> - Automaattiset lisät näkymässä näkyy palkanmaksua odottavat automaattiset lisät ja palkanmaksuun siirretyt lisät. 
Näitä voi hakea ja sortata eri hakutoiminnoin.</p> 

<p> - Hyväksytyt lisät kohdasta näet yhteenvedon kaikista lisistä ja palkkiosta, jotka joko odottavat siirtoa palkanmaksuun tai ovat jo siirretty.</p> 
                        </div>
                        );

                        case "/timetracking/payroll":
                            return (
                                <div className="padding">
                                    <h3>Palkanmaksu</h3>
<p> - Palkanmaksu osiossa siirretään palkat, lisät, kilometrit jne. palkanlaskentaan. 
Valitaan joko aikaväli, tai pikanäppäimistä kuukausi tai viikko. 
Lisäksi jos halutaan, niin voidaan ottaa “ei maksettavat” tiedot.</p> 

<p> - Hae tuntikirjauksesta -napista ladataan tiedosto.</p> 

<p> - Voit tutkia rivikohtaisesti maksettavia palkkoja. Ja sitten luodaan palkanlaskenta-aineisto. 
Se siirtyy tämän jälkeen sovittuun palkanlaskentaohjelmistoon, 
jossa palkanlaskija suorittaa itse palkanlaskennan.</p>

<p> - Oikeassa yläkulmassa napista “aikaisemmat palkanmaksuerät”, 
voidaan tarkastella aikaisempia palkanmaksuajoja.</p>
                        </div>
                        );

                        case "/workhours/holidays":
                            return (
                                <div className="padding">
                                    <h3>Lomat</h3>
                                     
<p> - Tässä osiossa nähdään omat lomat. Ja riippuen oikeuksista nähdään koko yrityksen kaikki lomat (ei pelkästään lomapäivät, vain mahdolliset pekkaset, liukumat jne). 
Lisäksi esimies näkee oman tiiminsä vapaat. Tässä näkymässä hallinnoidaan ja annetaan lopullinen lupa mahdollisiin vapaisiin.</p>
                        </div>
                        );

                        case "/crm/customers":
                            return (
                                <div className="padding">
                                    <h3>Asiakkaat</h3>
<p> - Asiakkaat hallinnassa hallitset ja muokkaat Asiakasyrityksiä ja yhteyshenkilöitä sekä heidän tietojaan.</p> 

<p> - Ylhäällä oikealla on vihreä nappi “lisää Asiakas”. Siitä voi nopeasti kirjata Asiakkaan. 
Tarvitaan vain yksilöivä yrityksen nimi pakollisena. Suositellaan myös Y-tunnuksen antamista. Näin helposti on Asiakas perustettu. 
Tämän jälkeen avautuu näkymä, johon suositellaan laittamaan kaikki oleellinen tieto yrityksestä.</p> 

<p> - Perustiedot: Vastuuhenkilöt (oman puolen), Toimialaluokitus (lakisääteinen pohja), 
Asiakasryhmän (nämä tulee kohdasta Asiakasryhmät ja niitä voi olla rajaton määrä). maksuehdot, toimitusehdot, toimitustavat. 
Onko Asiakas aktiivinen järjestelmässä, onko hänellä Extranet aktiivisena. Asiakkaan yhteystiedot ja verkkosivut jne.</p> 

<p> - Osoitteet: Lisää “uusi osoite” valitaan joko yleinen osoite, postiosoite, laskutusosoite tai toimitusosoite. Lisätään yhteystiedot ja paina jatka. 
Voit lisätä uuden osoitteen “uusi osoite” painikkeesta.</p> 

<p> - Yhteyshenkilöt: Lisää “uusi yhteyshenkilö”. 
Pakollisina kenttinä on etu- ja sukunimi, mutta suosittelemme kaikkien kenttien täyttöä. Kaikissa kentissä lukee mitä sinne kuuluu syöttää. 
Asiakasryhmä kentässä voidaan valita rajattomasti eri ryhmiä ja näin voidaan kätevästi ryhmitellä henkilöt ilman yritys rajoitetta. 
Mikäli henkilölle annetaan Extranet tunnus, niin suosituksemme on etunimi.sukunimi. Tunnus voi olla joku muukin tunniste. 
Valitaan onko Extranet voimassa ja voidaan vaihtaa salasana sekä valitaan lähetetäänkö se. Joskus voi olla tilanne, että extranet-tunnus on voimassa, 
mutta salasanaa ei ole lähetetty Asiakkaalle. Esimerkiksi Asiakas ei pysty tai halua hyväksyä toimintorivejä (esim. tunteja tai materiaalia), 
niin ohjelmassa voidaan määrittää asetuksissa automaattihyväksyntä, jolloin tunnit lähetetään ja ne hyväksytään automaattisesti. 
Henkilöstä voidaan kirjoittaa myös lisätietoja.</p> 

<p> - Tapahtumat: Täällä kirjataan Asiakkaan kanssa käyty dialogi /pidetään huolta Asiakkaan kanssa käytävästä kommunikoinnista. 
Paina “lisää tapahtuma” Valitse tapahtumatyyppi. Soitto, Sähköposti, Tapaaminen tai joku muu. Valitse otsikko ja tapahtuma-aika. 
Kirjaa itse tapahtuma ja myös tapaamisen perusteella syntyvät toimenpiteet. Paina jatka. 
Näkymään alkaa kerääntymään aikajärjestyksessä tapahtumia ja niitä voi selailla ja aukaista.</p> 

<p> - Kun lopetat muokkaamisen, niin paina ylhäällä vasemmalla olevaa “merkitse tiedot tarkistetuksi” nappia ja sen jälkeen "tallenna" nappia.</p> 

<p> - Extranet: Pääset tästä näkemään ja hallinnoimaan Asiakkaan extranet oikeuksia.</p> 
                        </div>
                        );

                        case "/crm/groups":
                            return (
                                <div className="padding">
                                    <h3>Asiakasryhmät</h3>
<p> - Näkymässä voidaan lisätä rajattomasti eri Asiakasryhmiä tai eri yhteystietoja (henkilöryhmiä). 
Esimerkiksi Asiakasryhmä “VIP Asiakkaat” tai yhteystiedot “Toimitusjohtajat”.</p> 
                        </div>
                        );

                        case "/crm/extranet":
                            return (
                                <div className="padding">
                                    <h3>Extranet</h3>
<p> - Näkymässä hallinnoidaan Asiakaskohtaisia Extranet oikeuksia. Pystytään eri hakuperusteilla hakemaan tiettyä Asiakasta. 
Näkymässä voidaan aktivoida tai poistaa Extranet oikeudet.</p> 

<p> - Asiakas näkymään siirrytään nimen oikealla reunalla olevasta nuolesta. 
Siitä pääsee henkilötason näkymään.</p> 

<p> - Nimen oikealla puolella on kolme pistettä, josta voidaan mennä henkilön tietokorttiin/tietoihin 
tai henkilön Extranet näkymään.</p> 

<p> - Joskus on hyvä tarkistaa Asiakkaan näkymä, että kaikki oleellinen tieto on siellä.</p>
                        </div>
                        );

                        case "/hr/users":
                            return (
                                <div className="padding">
                                    <h3>Henkilöstö</h3>                 
<p> - Henkilöstö osiossa luodaan uusia käyttäjiä, muokataan ja tarkastellaan henkilöiden tietoja.</p>  

<p> - Uuden käyttäjän lisäys: Paina oikeassa yläkulmassa olevaa “uusi käyttäjä” painiketta. 
Ensin kysytään onko käyttäjä peruskäyttäjä, toimihenkilö tai pääkäyttäjä. Kun tästä etenee, 
niin ainoat pakolliset kentät on etunimi, sukunimi ja sähköpostiosoite. Suosielemme kaikkien kenttien täyttöä. 
Puhelinnumeron voi valita joko julkiseksi (yrityksen sisällä) tai ei julkiseksi. 
Paina jatka, niin ohjelma kysyy työsuhteen alkamisaikaa. Tämä vaikuttaa siihen, pääseekö henkilö järjestelmään vai ei. 
Kun painaa jatka, niin seuraavaksi ohjelma ehdottaa käyttäjätunnusta ja salasanaa. 
Voit vielä valita pääseekö henkilö heti järjestelmään (vaikka työsuhde alkaisi myöhemmin) ja lähetetäänkö hänelle tunnukset sähköpostilla. 
Näin saadaan helposti ja nopeasti avattua henkilölle tili.</p>  

<p> - Henkilön tietojen muokkaus. Hae joko nimellä tai hiirellä ko. henkilön nimi ja paina rivistä jolloin avautuu näkymä “muokkaa käyttäjää” 
Tämä on jaettu neljään osioon, 1. Käyttäjätili, 2 Perustiedot, 3 Työsuhde ja 4. Käyttöoikeudet.</p> 

<p> - 1. Käyttäjätili: Tässä näkymässä voidaan vaihtaa salasana ja lähettää salasana käyttäjälle. 
Samoin tässä määritellään tilin ominaisuus/tila (peruskäyttäjä, toimihenkilö vai pääkäyttäjä) sekä se onko tili aktiivinen. 
Käyttäjätili kohdassa näkyy myös muutamia logitietoja profiilista.</p> 

<p> - 2. Perustiedot: Tässä näkymässä on paljon perustietoja osoitteita, tilinumeroita, veronumeroita jne. Suosittelemme täyttämään ne kaikki.</p>  

<p> - 3. Työsuhde: Tähän näyttöön kirjataan toimenkuvat, työehtosopimukset jne. Palkkatiedot on tärkeä osio, niin palkanlaskennan osalta, 
kuin kulujen osaltakin. Esimies valinnassa/kohdassa voi olla useampi taho. Tärkeä tieto on se, onko työajanseuranta pakollinen. 
Ellei, niin ohjelma ei seuraa onko henkilö kirjannut tunteja vai ei, eikä niistä muistuteta. 
Jos työajanseuranta on päällä, niin ohjelma seuraa ja muistuttaa käyttäjää kirjaamaan tuntinsa. Tuntien hyväksyjiä voi olla vain yksi. 
Yleisesti tuntien hyväksyjä määritellään projektien alla. Eli projektien tuntienhyväksyjä menee henkilökohtaisen/esimiehen hyväksyjän ohi. 
Tässä määritelty hyväksyjä hyväksyy esim. poissaolot, lomat, pekkaset jne. Säännöllinen työaika määritys tarkoittaa sitä, että ohjelma valvoo, 
että ko. työtuntimäärä kirjataan tuntijärjestelmään. Ja viimeisenä kohtana määritellään onko henkilöllä liukuva työaika käytössä. 
Jos on, niin ohjelma automaattisesti siirtää yli päiväkohtaisen tuntimäärän menevät tunnit liukumaan ja jos niitä pitää, 
niin ohjelma myös automaattisesti poistaa liukumatunteja. Jos liukuma ei ole käytössä, niin palkanlaskentaan siirtyy se määrä tunteja, mitä on kirjattu.</p> 
 
<p> - 4. Käyttöoikeudet: Näkymässä määritellään käyttäjäryhmät (jotka määritetään kohdassa Käyttäjäryhmien hallinta). Kun käyttäjä kuuluu johonkin ryhmään, 
hänellä on automaattisesti myös kyseisen ryhmän oikeudet. Ryhmältä saadut oikeudet merkitään sinisellä värillä 
ja kunkin ryhmän nimen perässä näytetään montako oikeutta kyseinen ryhmä sisältää. 
Käyttäjän oikeuksia voidaan räätälöidä vapaasti (ei suositeltavaa) kohdasta käyttöoikeudet. Muutokset näkyvät vihreällä.</p>
                        </div>
                        );

                        case "/hr/usergroups":
                            return (
                                <div className="padding">
                                    <h3>Käyttäjäryhmien hallinta </h3>  
<p> - Käyttäjäryhmän hallinnassa voidaan lisätä, tarkastella ja muokata käyttäjäryhmiä.
Tämä suositellaan tehtäväksi huolella ohjelman käyttöönoton yhteydessä.</p>

<p> - Lisää käyttäjäryhmä: Lisää uusi käyttäjäryhmä painamalla “lisää käyttäjäryhmä”.
Ohjelma pyytää käyttäjäryhmän nimityksen ja tarvittaessa tarkemman kuvauksen. 
Ja sitten valitaan käyttöoikeudet nappi kerrallaan. Kun on valmista, niin "tallenna" napista pääsee eteenpäin.</p>

<p> - Voit hakea käyttäjäryhmiä nimellä ja napista näytä oikeudet, voi luoda nopean näkymän siitä, mitä oikeuksia kullakin käyttäjäryhmällä on.</p>
                        </div>
                        );

                        case "/hr/permissions":
                            return (
                                <div className="padding">
                                    <h3>Käyttäjien oikeudet</h3>                     
<p> - Käyttäjien oikeus on käytännössä näkymä, josta pystyy helposti näkemään jokaisen henkilön käyttöoikeudet käyttöoikeus kerrallaan. 
Vihreällä oleva tarkoittaa käyttäjäoikeutta, sinisellä tarkoittaa käyttäjäryhmäoikeutta ja pääkäyttäjäoikeus näkyy harmaalla. 
Tyhjä pallura tarkoittaa sitä, että oikeutta ei ole. Lisäksi ei oikeutta näkymä kertoo, onko henkilö merkitty peruskäyttäjäksi, 
toimihenkilöksi tai pääkäyttäjäksi.</p>                        
                        </div>
                        );

                        case "/hr/permits":
                            return (
                                <div className="padding">
                                    <h3>Luvat</h3>               
<p> - Luvat osiossa nähdään henkilön luvat, niitä voidaan muokata ja lisätä. Hakutoiminnoilla lupia voidaan hakea henkilön tai käyttäjäryhmän mukaan.</p> 

<p> - Eri luokat lupien käytössä ovat “Voimassa, Raukeamassa, Ei voimassa, Lupa vaaditaan ja lupaa ei vaadita.</p>

<p> - Vaadittavat luvat tulevat asetuksista kohdasta “työlupien asetukset” Kun painaa pyöreästä pallosta henkilön ja luvan kohdalta, 
niin aukeaa näkymä, johon voi kirjata luvan tunnuksen, myöntämispäivän ja voimassaolon päättymisen. Sekä päätös siitä, 
vaaditaanko tämä lupa ko. henkilöltä. Ja sitten tallennetaan tieto. Kun halutaan lisätä kuva luvasta tms. tiedosto, 
niin lupa aukaistaan uudestaan ja kuva tallennetaan. Tässä näkymässä voidaan myös poistaa ko. lupa.</p>
                        </div>
                        );

                        case "/storage/components":
                            return (
                                <div className="padding">
                                    <h3>Varastonimikkeet</h3>                        
<p> - Varastonimikkeet hallitsee yrityksen varastonimikkeitä. Varastonimikkeet on tärkeää tehdä huolella, 
koska ohjelma pohjautuu pitkälle näihin nimikkeisiin.</p> 

<p> - Päänäkymässä näkyy varastonimikelistaus, vienti ja tuonti sekä tukkurien materiaalihinnastot. 
Uusi varastonimike määritellään “uusi varastonimike” listauksessa tai sitten tuomalla varastonimikkeet excelillä. 
Tällöin Varaston asetuksissa pitää olla määrittelyt tehtynä.</p>

<p> - Uusi varastonimike: Ensimmäiseksi pitää määritellä onko nimike 1. komponentti, 2. työ vai 3. kustannus.</p> 

<p> - 1. Komponentti varastonimike on fyysinen asennettava materiaali. Komponentteja voidaan tilata ja varastoida. 
Myös ei fyysiset asennukset merkitään komponenteiksi.</p> 

<p> - 2. Työ. Kaikki järjestelmän tuntikirjaukset tehdään jollekin työlajin varastonimikkeelle. Kaikilla töillä on yksikkönä tunti.</p> 

<p> - 3. Kustannukset ovat muuhun toimintaan liittyvät kustannukset (esim. ulkopuolisen tekemät työt). 
Kaikki projekteihin kirjattavat laskut kirjataan jonkin kustannuksen alle. Kustannuksia voi myös määrittää osto- ja myyntitilauksiin.</p> 

<p> - Perustiedoissa pakollinen tieto on nimi. Suosittelemme muidenkin tietojen kirjaamista. Ohjelma kysyy käytetäänkö varastokoodia, joka on suositeltavaa. 
Kun koodia käytetään, niin valitaan asetuksissa määritellyt varastonimikkeet aina nimikekohtaiseen numerosarjaan. Ohjelma näyttää viimeisen numeron. 
Valitaan yksikkö ja tilaa on myös vapaalle tekstille. Seuraavaksi määritellään (halutessa) hinta ja /tai kateprosentti. 
Ja viimeisessä vaiheessa halutaan varmistaa, että nimike tehdään. Nimike kuitenkin tallentuu suunnittelu tilaan. 
Kun painaa "tallenna", siirtyy järjestelmä laajempaan näyttöön, jossa voidaan lisätä tietoja. 
"Tallenna" napin vieressä olevasta kolmesta pisteestä voidaan nimike poistaa tai tallentaa aktiiviseksi.</p> 

<p> - Näkymä jakaantuu kuuteen osaan. 1. Perustiedot, 2 Lisätiedot, 3. Tunnistaminen, 4. Projekti, 5. Varastot ja 6. Toimittajat.</p> 

<p> - 1. Perustiedot. Määritetään näkymän tiedot. Muuntoyksikköä voidaan käyttää osto- ja myyntitilauksissa, 
jos tavaraa ostetaan tai myydään toisessa yksikössä kun sitä varastoidaan. 
Muuntoyksikkö määrittää eri yksikön ja muuntokerroin kertoimen jolla muuntoyksikkö muutetaan nimikkeen oletusyksiköksi. 
Tuotteen kuva voidaan liittää näkymään sekä antaa hintatiedot ja määrittää yksittäisen varastonimikkeen arvo. 
Arvoa käytetään varaston kokonaisarvon laskennassa, tarjouslaskennassa omakustannushintana, sekä myyntihinnan ja kateprosentin laskennassa. 
Kateprosentti määrittää oletuskatteen tarjouslaskennassa ja myyntitilauksissa. 
Myyntihinta on katteen perusteella laskettu myyntihinta ja sitä käytetään tarjouslaskennassa ja myyntitilauksissa.</p> 

<p> - 2. Lisätiedot. Lisätietoihin voidaan lisätä ominaisuuksia (jotka määritellään kohdassa Varaston asetukset) 
Esimerkkinä ominaisuus voi olla ruostumaton teräs tai muovi. Tällöin voidaan myöhemmin varastosta erotella eri ominaisuuksia. 
Lisäksi ominaisuuksia kenttään voidaan lisätä nimikkeen tietoja, kuten ohjekirjoja jne.</p> 

<p> - 3. Tunnistaminen. Tunnisteet ovat varastonimikkeen yksilöiviä koodeja. Samaa tunnistetta ei voi olla kahdella eri varastonimikkeellä. 
Tunnisteita käytetään varastonimikkeiden haussa. Mahdolliset tunnisteet on määriteltävä asetuksissa 
(Varaston asetukset). Varastonimikkeiden yhdistäminen massalistojen avulla käyttää tässä listassa olevia nimiä. 
Samaa nimeä ei voi olla kahdessa eri varastonimikkeessä. Asiakkaalla voi olla samasta nimikkeestä eri nimityksiä 
ja siten tänne lisäämällä saman varastonimikkeen eri nimet (tai massalistan kautta), voidaan helpottaa huomattavasti esim. tarjouslaskentaa.</p> 

<p> - 4. Projektit. Projektin edistymistä voidaan seurata erilaisin mittarein. Mikäli mittariin on liitetty jokin työnimike, 
sitä voidaan käyttää myös tarjouslaskennassa työmäärän laskemiseen. Mahdolliset mittarit on määriteltävä asetuksissa (Varaston asetukset). 
Mittarit ovat erittäin hyödyllisiä tarjouslaskennassa ja raportoinnissa. Kg/kpl, saumaa/kpl. Mittareita voidaan lisätä rajaton määrä. 
Ja ne ovat todella hyödyllisiä moneen asiaan. Projektiin lisätty varastonimike näkyy oletuksena edistymisen kirjaamisessa. 
Seurantayksikkö on kuvaus eri seurantayksiköistä, jotka määritellään asetuksissa (Varaston asetukset).</p>

<p> - 05. Varastot. Varastonimikkeitä voidaan varastoida eri varastoihin, täällä määritellään mitkä varastot sisältävät kyseistä varastonimikettä. 
Saldo määrittää kyseisessä varastossa olevan varastonimikkeen määrän. Kun saldo laskee huomiorajan alle, 
tulee huomiohälytys, ja varastonimike lisätään tilaustarpeet listaan. Kun saldo laskee alle hälytysrajan, tulee hälytys
ja varastonimike lisätään tilaustarpeet näkymään/listaan. Oletustilausmäärä on tilaustarpeet näkymässä arvo, 
joka syötetään oletuksena tilausmääräkenttään.</p> 

<p> - 06. Toimittajat. Varastonimikkeellä voi olla useita toimittajia. Täällä määritellään kyseisen varastonimikkeen toimittajat ja ostohinnat. 
Varastonimikkeelle voidaan myös määritellä oletustoimittaja. Järjestelmä tukee hintatietojen hakemista joiltakin toimittajilta automaattisesti 
esim. LVISNetin kautta. Näiden toimittajien materiaaleja pääsee lisäämään varastonimike hallinnasta tukkurien materiaalit välilehdestä.</p> 

<p> - Vienti. Ensin valitaan ruksista, mitkä tiedoston sarakkeet valitaan tiedostoon. Painetaan “Generoi Xlsx” jolloin tästä muodostuu excel, 
johon voi lisätä arvoja, joita on valinnut.</p> 

<p> - Tuonti. Varastonimikkeiden tuonnilla pystyy lisäämään tai päivittämään varastonimikkeitä excel tiedostosta. 
Excel tiedoston ensimmäinen rivi on otsikkorivi ja ensimmäinen sarake on nimi, jolla varastonimike yhdistetään varastonimikkeeseen. 
Loppuihin sarakkeisiin tulee syöttää tieto, joka halutaan lisätä tai päivittää varastoon. 
Järjestelmä koittaa päätellä otsikon perusteella mitä tietoa missäkin sarakkeessa on, 
tämän pystyy valitsemaan myös manuaalisesti esikatselun yläpalkista. Järjestelmä päivittää ainoastaan sarakkeet, jotka on määritelty sisältävän tietoa. 
Excel tuonti ei poista varastonimikkeitä. Järjestelmästä viedyt excel tiedostot pystyy lukemaan suoraan. 
Järjestelmä lukee ainoastaan excel tiedoston 1000 ensimmäistä riviä, mikäli rivejä on enemmän, jaa tiedosto useaan eri tiedostoon, 
niin että ensimmäinen tiedosto sisältää 1000 ensimmäistä riviä, toinen tiedosto 1000 seuraavaa jne.</p> 

<p> - Tukkureiden materiaalit. Tänne generoituu automaattisesti (Ahlsell, Dahl, Onninen, Rexel, Slo) tuotenimet ja tuotehinnat. 
Täältä voi ( rivin vasemmalla reunalla oleva neliö) ruksilla valita tuotteet ja lisätä varastonimikkeeksi omaan varastoon. 
Hakutoiminto helpottaa hakua huomaavasti.</p>
                        </div>
                        );

                        case "/storage/locations":
                            return (
                                <div className="padding">
                                    <h3>Varastopaikat</h3> 
<p> - Tämä näkymä hallitsee fyysisiä varastoja ja varastopaikkoja.</p> 

<p> - Varastojen ja varastopaikkojen muokkaus. Varastot voivat sisältää useita alivarastoja, jotka jälleen voivat sisältää useita alivarastoja. 
Jokainen varasto voi sisältää useita varastonimikkeitä. Varastopaikkojen muokkaus ei tallenna mitään ennen kuin sivun alalaidassa olevaa 
"Tallenna" nappulaa painetaan. Varastot, jotka on merkitty "Ottaa vastaan ja lähettää varastonimikkeitä" pystyvät vastaanottamaan ostotilauksia 
ja lähetyksiä. Mikäli varastopaikka ei ole merkitty varaston alivarastona, tavaran tilaus kyseiseen varastoon ei ole mahdollista. 
Varastoihin, jotka on merkitty "Kirjataan varastonimikkeitä" pystyy kirjaamaan varastonimikkeitä. 
Tällä rajoitetaan varastonimikkeen hallinnassa olevaa listaa tuomaan ainoastaan niihin varastopaikkoihin mihin tavaraa pystyy kirjaamaan.</p> 

<p> - Uusi varasto: Paina “uusi varasto” napista. Pakollinen koodi (varastonimike, ykkösvarasto tms. Suositeltava tapa on nimetä varastot (01,02 jne.) 
ja antaa varaston nimi sekä kuvaus. Varastoon voidaan tehdä kaksi “lukitusta”. Päätös siitä, kirjataanko varastoon varastonimikkeitä. 
Varastot ja varastopaikat joihin voidaan kirjata varastonimikkeitä. Esimerkiksi hylly saattaa sisältää useita hyllypaikkoja, 
tällöin tavara kirjataan hyllypaikoille, joten hyllypaikoilla tulisi olla tämä valinta, mutta ei hyllyillä. 
Ja lisäksi päätös, voidaanko varastoon ottaa vastaan ja lähettää varastonimikkeitä. Tavaran vastaanotto 
ja tavaran lähetys voidaan ainoastaan tehdä näihin varastoihin. Jos varastoon otetaan tavaraa, niin ohjelma kysyy vielä varaston osoitetiedot.</p> 

<p> - Varastopaikan nimen edessä on kolme pistettä. Siitä voidaan poistaa tai lisätä alivarastopaikkoja. Ja taas sille voi lisätä varastopaikan jne. 
Eli voidaan määrittää tietylle varastonimikkeelle hyllypaikan laatikko, johon sitten voidaan ostaa ja toimittaa tavaraa tai 
sitten myydä laatikosta tavaraa.</p>
                        </div>
                        );

                        case "/storage/purchase/demands":
                            return (
                                <div className="padding">
                                    <h3>Tilaustarpeet</h3>
<p> - Mikäli varastonimikkeiden taakse on määritetty huomio ja/tai hälytysrajat, tai jos varastosaldo menee varausten vuoksi (projekteille jne.) negatiiviseksi,
 niin tässä näytössä näkyy tilausta tarvitsevat nimikkeet.</p>            
                        </div>
                        );

                        case "/storage/purchase/orders":
                            return (
                                <div className="padding">
                                    <h3>Ostotilaukset</h3>
<p> - Ostotilausosiossa hallitaan ostotilauksia. Etusivulla näkyy lista auki olevista ostotilauksista ja niitä voi tässä näytöllä hallita ja muokata. 
Etusivulla näkyy toimittajatiedot, tekijä, tila, hintatietoja ja maksettu tilanne.</p> 

<p> - Uusi ostotilaus: Paina “uusi ostotilaus”. Ohjelma kysyy toimittajaa (pakollinen tieto). Toimittaja valitaan listalta. 
Vastaanottaja on oma varasto tai projekti. Ohjelma kysyy vastaanottajan varastoa (jos on monta) ja esille tulee varaston osoitetiedot. 
Lisäksi ohjelma kysyy maksuehdot, toimitusehdot ja toimitustavan. Sekä mahdolliset viitetiedot, tarvepäivän jne. 
Tämän jälkeen aukeaa suoraan ostotilausnäkymä. Voit muokata jo tallennettuja tietoja. Ostotilaus etenee seuraavasti. Muokataan ostotilaus, 
lähetetään ostotilaus (tässä voi olla myös hyväksyntämenettely), kuitataan tilausvahvistus saapuneeksi, saavutetaan, 
eli otetaan vastaan tuote ja kuitataan se oikeaksi, maksetaan ja arkistoidaan.</p> 

<p> - 1. Muokkaus: Ostotilausta ei ole vielä lähetetty, joten voit muokata kaikkia kohtia vielä tässä kohtaa. 
Nyt valitaan ostettavat tuotteet varastolista. Ainoastaan komponentteja, joille on määritelty varastopaikka tai 
joita on määritelty tilattavaksi myyntitilauksissa pystyy lisäämään ostotilauksiin. Voidaan myös tehdä päätös rivin oikeassa reunassa olevasta napista, 
että vain ne varastonimikkeet näytetään, joilla on toimittaja merkitty nimikkeen alle. Lisäksi kolmesta pisteestä (toimittajavalinnan jälkeen) 
voidaan valita “Näytä valintalistaus” (jotta haku helpottuu) ja lisäksi tässä voidaan lisätä varaston ulkopuolinen komponentti tai kustannus, 
jos tätä ei haluta varastoon lisätä. Valitaan siis varastonimike. Mikäli nimikkeelle on jo valittu/määritetty fyysinen varaston kohde, 
se tulee automaattisesti, muuten sen joutuu valitsemaan. Sitten valitaan määrä. Ostohinta tulee joko järjestelmästä 
tai sitten toimittajan tarjouksen pohjalta. Ja näin varastonimikkeitä voidaan lisätä tarvittava määrä. 
Ostotilaus voidaan tallentaa keskeneräisenä “talleta muutokset” -napista ja jatkaa myöhemmin. Kun kaikki kirjaukset on valmiina, 
niin painetaan “luo ostotilaus” Tämän jälkeen tulee näkymä, jossa voi laittaa kommentteja tapahtumalokiin. Lisäksi ohjelma kysyy, 
tarvitaanko ostotilaukselle hyväksyjä. Mikäli vastaus on kyllä, niin haetaan hyväksyjä listalta. 
Ostotilaus lähetetään valitulle henkilölle hyväksyttäväksi ennen kuin sitä voi lähettää toimittajalla. 
Mikäli Sinulla on oikeus tehdä ostotilaus ilman hyväksyntää, niin sinun pitää asia kuitata. Napilla “varmistan”, 
että ostotilauksen sisältö on oikein ja minulla on oikeus tehdä ostotilaus ilman erillistä hyväksyntää. 
Jompikumpi valinta pitää olla päällä, jotta voidaan edetä. Näin jokainen ostotilaus tehdään valtuutuksen kautta 
ja luvattomat ostot loppuvat.</p> 

<p> - 2. Lähetys: Kun ostotilaus, joko hyväksynnän kautta tai ilman, on lähetys valmis, tilaajalle se tulee näkymään, 
josta se voidaan lähettää painamalla “lähetä toimittajalle” -nappia. Tässä kohtaa on myös “hups oho” nappi, 
jolla pääset takaisin muokkaamaan ostotilausta. Kun ostotilaus lähetetään toimittajalle, on mahdollisuus taas kommentoida tapahtumalokiin asioita. 
Voit tästä näkymästä ladata joko PDF tai Word dokumentin. Kun vielä kuittaat ohjelmaan, että olet tiedoston lähettänyt toimiajalle 
ja olet halutessasi tallentanut tiedoston järjestelmään, paina “lähetetty”.</p> 

<p> - 3. Tilausvahvistus: Nyt joko odotetaan toimittajan tilausvahvistusta (suositus) tai luotetaan toimittajan kykyyn ja vahvistetaan tilaus 
(ostotilaushan on jo toimittajalle lähetetty). Kun painaa “vahvista tilaus”, ohjelma vielä varmistaa seuraavia tietoja: 
Voit taas kommentoida asioita tapahtumalokiin. Voit tallentaa toimittajan tilausvahvistuksen järjestelmään 
ja kirjata toimittajan eräpäivän ja toimituspäivän. Vahvista vielä, että olet tarkistanut, että 
ostotilauksen varastonimikkeet vastaavat tilausvahvistuksen rivejä sekä muut ostotilauksen tiedot täsmäävät tilausvahvistukseen.</p> 

<p> - 4. Saapuminen: Kun tavara / toimitus saapuu, niin se vahvistetaan saapuneeksi. Eli se henkilö, joka ottaa vastaan toimituksen, kuittaa, 
että määrät täsmäävät. Määriä voi muokata sen mukaan mitä on toimitettu. Tässä näkymässä voidaan todeta toimitus saapuneeksi 
“kirjaa saapuminen”- napilla. Kun tästä etenee, niin voidaan vielä merkitä mahdolliset puutteet tai painaa “kaikki saapuivat”.</p> 

<p> - 6. Maksettu: Kun toimitus on maksettu, niin paina “merkitse maksetuksi”. Tällöin ostotilaus siirtyy arkistoon kommentointi kentän kautta.</p>
                        </div>
                        );

                        case "/storage/purchase/suppliers":
                            return (
                                <div className="padding">
                                    <h3>Toimittajat</h3>
<p> - Täällä pystyy lisäämään uuden toimittajan ja hallinnoimaan jo rekisterissä olevia sekä lisäämää heille Y-tunnuksen.</p> 
                        </div>
                        );

                        case "/storage/logistic/arrivals":
                            return (
                                <div className="padding">
                                    <h3>Tavaran vastaanotto</h3>
<p> - Tavaran vastaanotto näkymässä näet, uudet vastaanottamatomat toimitukset, jotka ei ole vielä tullut tai niitä ei ole kuitattu tulleeksi. 
Näet myös listauksen aikaisemmat vastaanotot näkymän/aikaisemmin vastaanotetuista toimituksista ja voit tarkastella yksittäistä ostotilausta.</p>
                        </div>
                        );

                        case "/storage/logistic/deliveries":
                            return (
                                <div className="padding">
                                    <h3>Tavaran lähetys</h3>                    
<p> - Varaston näkymä, jossa lähetetään tavaraa ja merkitään tavaraa lähetetyksi. Tässä on myös joko varastosta varastoon tai varastosta projektille toiminto,
eli sisäiset materiaalisiirrot. Toki myös varastosta Asiakkaalle lähetettävät toimitukset näkyvät tässä.</p>                      
                        </div>
                        );

                        case "/storage/logistic/tracking":
                            return (
                                <div className="padding">
                                    <h3>Lähetysten seuranta</h3>                      
<p> - Täällä on listattuna yrityksen sisäiset lähetykset, joita ei ole merkitty vastaanotetuiksi. Sekä myös ostotilaukset, 
joiden saapumista odotetaan. </p>
                       </div>
                        );

                        case "/projects":
                            return (
                                <div className="padding">
                                    <h3>Projektit</h3>                      
<p> - Uusi projekti/tarjous määritetään näkymän oikeassa yläkulmasta ”uusi projekti/tarjous”. Ensin valitaan projektityyppi (jotka on määritetty asetuksissa projektiasetukset). Annetaan projektin nimi. Tähän on hyvä miettiä samantyyppinen kirjoitusmääritys. Projektinumero tulee automaattisesti tai se voidaan myös määrittää itse. Jatketaan painamalla seuraava. Valitaan Asiakas, mikäli projekti sen vaatii. Seuraavassa näkymässä Määritetään onko projekti tuntilaskutteinen. Mikäli projektinumero on tuntilaskutteinen, niin nämä kirjaukset tulevat taloushallinto laskutyöt osioon näkyviin. Muistathan, että mikäli projekti on urakkatyö, niin projekti ei voi olla samalla laskutyö. Valitse projektipäällikkö sekä tuntien hyväksyjä. Tuntien hyväksyjä hyväksyy kaikki ko. projektin tunnit. Jos projekti on tuntilaskutteinen, niin on hyvä, jos se on myös jatkuva. Valitse aloitus ja lopetuspäivä ja jatka. Viimeiseksi päätetään tehdäänkö projektille tarjouslaskenta. Tuntitöille ei laskentaa tehdä, vain urakkatöissä. Vielä on vapaaehtoisia kyselyjä tarjouksen vastaanottopäivästä ja jättöpäivästä sekä tarjouksen todennäköisyydestä. Nämä ovat hyviä mittareita, kunhan ohjelmaan on saatu tarpeeksi dataa. Kun talletat, niin projektinumero on perustettu.</p> 
<p> - Riippuen projektin asetuksista projektinumero asettuu joko suoraan projektiksi tai tarjouslaskentaan. Seuraavassa kerrotaan eri vaiheet. Projekti on joko tarjousvaiheessa, hallinnassa tai seurannassa. </p>
                                <h4>Tarjoukset</h4>
                                <h5>Päänäkymä</h5>
<p> - Kun painat projektit napista ja sieltä tarjoukset, näet neljä näkymää. 1. Laskennassa olevat tarjoukset. Eli tarjoukset, joita ei ole vielä lähetetty Asiakkaalle, mutta niitä vielä muokataan. Pääset tarjouslaskentaan ja hakea eri projekteja. 2. Avoimet tarjoukset. Eli nämä tarjoukset on lähetetty Asiakkaalle. Näet tarjousten yhteissumman, eli tarjouskannan. Samoin, jos olet kirjannut jokaiselle projektille arvioidun saantiprosentin, niin näen myös todennäköisen saantikannan. 3. Hylätyt, eli hävityt tarjoukset sekä 4. Kaikki, eli kaikki projektit. Näkymässä on eri hakutoimintoja ja esim. todennäköisyys sanan oikealla puolella oleva suppilo piilottaa hakunäkymään ja suppilon yläpuolella olevasta ikonista voi valita ne sarakkeet mitä halut näkymässä nähdä. </p>

                                <h5>Tarjouslaskenta</h5>
<p> - Tarjouslaskenta osiossa tehdään tarjous. Näkymässä on kymmeniä ominaisuuksia, joten niitä kannattaa rohkeasti kokeilla. Ensin tarjousnumero on laskennan päänumero. Numeron perässä olevasta kolmesta pisteestä voidaan lisätä aliprojekti. Ja aliprojektiin voidaan lisätä aliprojekti jne. Loputtomasti. Jokaista nimeä voi muokata ja/tai poistaa jne. Jokaisen projektin/aliprojektin kohdalla on lisää varastonimike kohta. Siitä voit hakea varastonimikkeen. Mikäli olet laittanut varastoon nimikkeen alle ostohinnan sekä katteen tulee ne näkyviin tähän. Ja saman tien se päivittyy tarjouksen kokonaishintaan. Voit muokata jokaista rivin saraketta. Kolmesta pisteestä voit valita varaston ulkopuolisen varastonimikkeen.  Kohdassa tarjoushinta on erilaisia näkymiä, mitkä voit laittaa näkyviin. Tarjoushinta, Omakustannushinta, Katehinta, Kateprosentti, Komponenttien määrä, Työtuntien määrä ja Varaston ulkopuolisten määrä. Voit siirrellä varastonimikkeitä projekti/aliprojektien välillä rajattomasti. Rivin vasemmalla reunalla on neliö, johon klikkaat ruksin. Ja projektin kohdalle ilmestyy nuoli. Klikkaamalla tästä nimike siirtyy ko. projektin alle. Mikäli olet varastonimikkeen alle tehnyt mittareita, niin mittarit näkymässä näet mittarit.</p>
<p> - Aikataulu osiossa voit tehdä alustavia aikatauluja.</p>
<p> - Asetukset kohdassa voit määrittää projektin kuvauksen ja aikataulun.</p>
<p> - Lähetykset kohdassa määritetään tarjouksen viimeinen voimassaolopäivä, valitaan tarjouspohja, muokataan tarjousta sekä valitaan kenelle tarjous lähetetään. Lisäksi stilisoidaan tarjouksen otsikko sekä teksti. Varmistetaan vielä tarjouksen todennäköisyys ja lähetetään tarjous.</p>
<p> - Laskentaversio kohdasta voidaan luoda uusi tarjous tai kopioida tarjous.</p>
<p> - Lisäksi oikeassa yläkulmassa on paikka, johon voit tallettaa dokumentit.</p>
                                <h4>Projektit</h4>
<p> - Projekti näkymässä on kaikki aktiiviset projektit Voit tehdä hakuja ei kiriteereillä ja muokata näkymän haluamaksesi. Ja pääset suoraan projektiin klikkaamalla projektin riviä. Samalla näet yksittäisen projektin arvon ja koko yrityksen tilauskannan.</p>

                                <h5>Hallinta</h5> 
<p> - Hallinta osiossa siis hallitaan jo saatua projektia. Tämä on projektipäällikön tai hänen valtuuttamien henkilöiden paikka projektoida projektia.</p>
<p> - Hallinta näkymässä on projektinäkymä, aikataulunäkymä, henkilöstö, osto ja myyntinäkymä.</p>

                                <h5>Projektit</h5>
<p> - Projektit näkymässä näet neljä päänäkymää. 1. Työt, 2. Komponentit, 3. Kustannukset ja 4. Asetukset</p>
<p> - 1. Työt kohdassa voit projektipäällikkönä laittaa jokaisen rivin arvion prosentteina tai laittaa rivin kokonaan (100%) valmiiksi. Näkymässä näet myös todelliset työtunnit mitä riveille on kirjattu. Allakoitu näkymässä näet mitä on tarjouslaskennassa määritetty. Eli pysyt nopeasti rivitasolla kartalla missä mennään / rivi/ aliprojekti ja koko projekti.</p>
<p> - 2. Komponentti kohdassa näet mitä komponentteja projektiin on laskettu. Voi laittaa rivin valmiiksi. Jos klikkaat tuntikirjauksessa aktiiviseksi, niin henkilöt joilla on oikeus tähän tuntikirjaukseen, voivat kirjata asennetut komponentit määrissä. Näet myös allakoidut, eli lasketut komponentit. Asennettu näkymässä näet paljonko ko nimikettä on asennettu.</p>
<p> - 3. Kustannukset kohdassa sama kun työ ja komponentti. Mikäli olet laskenut urakkaan kustannuksia.</p>
<p> - 4. Asetukset kohdassa voi kuvat projektia/aliprojektia tarkemmin. Voi määrittää aliprojektille eri projektipäällikön sekä aikatauluttaa sitä tarkemmin. Laskutyö projektissa voit lisäksi määrittää kuka lähettää tunnit Asiakkaalle (jos halutaan) ja kenelle Asiakkaalle ne menee (voi olla useampia) ja kuka Asiakas on oletusasiakas.</p>

                                <h6>Aikataulutus</h6>
<p> - Aikataulunäkymässä voit tehdä tarkempaa aikataulua aliprojektitasolla. Vain ne projekti näkyvät tuntikirjauksessa, jotka ovat aktiivisia ko. päivänä.</p>
                       
                                <h6>Henkilöstö</h6>
<p> - Tulossa, työpöydällä. Tässä näkymässä hallinnoidaan yksittäisen projektin miehitystä. Ja ohjelmaan tulee näkymä, jossa koko henkilökuntaa hallinnoidaan. Vain henkilö, jolle on täällä annettu oikeus, voi kirjata tuntejaan. Eli näkymä on tuotannonohjaus.</p>                       
                                
                                <h6>Osto</h6>
<p> - Tulossa, työpöydällä. Tässä näkymässä nähdään kaikki komponentit mitä projektissa on ja hallinnoidaan sitä otetaanko tuotteet varastosta vai toimittajalta ja seurataan komponenttien matkaa projektissa. Ohjelma myös hälyttää, mikäli näkee, että joku toimitus myöhästyessään vaarantaa projektin valmistumisen. </p>

                                <h6>Myynti</h6>
<p> - Tulossa, työpöydällä. Projektilla on maksupostit, joiden hallinta hoidetaan täältä. Nythän se voidaan tehdä myyntitilausten kautta. </p>  
                       
                                <h5>Seuranta</h5>
<p> - Seurantanäkymässä on tarkoitus luoda yleisnäkymä projektin tilasta ja projekteista yhteensä.  Näkymässä on päänäkymät 1. Yhteenveto 2. Valmiusaste 3. Kustannukset, 4. Aikataulu ja 5. Materiaali.</p>

                                <h6>1. Yhteenveto</h6>
<p> - Yhteenveto näkymässä nähdään oranssilla allakoitus tavoite, joka ottaa työtunnit aliprojektien aikataulutetuista tunneista. Kirjatut tunnit tulee työkirjauksen kautta kirjatuista tunneista ja työn valmiusarvio projektipäällikön valmiusarvioit/ aika. Lisäksi nähdään erilaisia numeerisia arvioita eurot, tunnit, yksiköt jne.</p>
                      
                                <h6>2. Valmiusaste</h6>
<p> - Näkymässä nähdään sama näkymä kun yhteenvedossa, mutta aliprojektikohtaisesti.</p>

                                <h6>Kustannukset</h6>
<p> - Tulossa, työpöydällä. Tähän tulee tarkempi näkymä kustannuksista euroissa.</p>

                                <h6>Aikataulutus</h6>
<p> - Jo muissakin näkymissä näkyvä aikataulu.</p>

                                <h6>Materiaalit</h6>
<p> - Tulossa, työpöydällä. Materiaalien näkymä ja kulutus.</p>
                        </div>
                        );


                        case "/billable":
                            return (
                               <div className="padding">
                                <h3>Laskutustyöt</h3>
<p> - Ensimmäinen asia lähtiessäsi laskuttamaan paina oikealla yläkulmassa ”päivitä laskutusrivit”. Tämä toiminto kestää muutaman minuutin, mutta se kerää kaikista tahoista viimeisimmän tiedon ja on siksi erittäin tärkeä toiminto.</p>
<p> - Tuntilaskutettavien projektien laskutus ja laskutusrivien hallinta. Valitse laskutettava aikaväli tai valitse pikakuvakkeet vuosi, kuukausinapista. Valitse yrityskohdasta, jolloin näkyviin tulee yritykset, joilla on jotain keskeneräistä ko. aikavälillä.</p>
<p> - Näkyviin tulee yrityksen nimi, laskutettavat rivit (sininen pallura) Asiakaan hylkäämät rivit (punainen pallura) Valmiit rivit laskutettavaksi (vihreä pallura) Hyväksyttävänä olevat rivit (keltainen pallura) Laskutettu (violetti pallura) Ei laskutettavia rivejä (harmaa pallura.</p>
<p> - Prosessi menee seuraavasti. Tuntikirjauksesta tänne tulee (laskutettavat rivit sininen pallura) esimiehen hyväksynnän jälkeen (tai jos on pääkäyttäjähyväksyntä, niin sen jälkeen). Tästä se voidaan lähettää joko Asiakkaan hyväksyttäväksi (asetukset) tai suoraan laskutettavaksi riveiksi. Jos on Asiakashyväksyntä, niin Asiakkaan hyväksynnän jälkeen rivit siirtyy laskutettavat (vihreä) kohtaan. Eli henkilö kirjaa &gt;&gt; esimies hyväksyy &gt;&gt; pääkäyttäjä hyväksyy &gt;&gt; Asiakas hyväksyy &gt;&gt; Muodostetaan myyntitilaus &gt;&gt; Siirtyy laskutukseen.</p>
<p> - Valitse Asiakas. Voit hyväksyä rivit eteenpäin yksitellen joko vasemmasta neliöstä tai oikeasta tilanäkymästä. Tai vasemmalla oleva neliö (tyyppi nimen edessä) kaikki kerrallaan. Paina kolmesta pisteestä vaihtoehdot. Laskutukseen, ei laskuteta, muokkaa Asiakastyönumeroita, määriä, yksikköhintoja tai hyväksyjiä. Kun painat laskutukseen, siirtyy rivit kohtaan ”valmiina laskutukseen”. Ja siitä ne siirtyy suoraan kohtaan Myyntitilaukset, jolloin se on sama kun myyntilasku. Mikäli asetuksissa on määritetty Asiakashyväksyntä jää rivit odottamaan Asiakkaan päätöstä.</p>
                        </div>
                        );

                        case "/invoices":
                            return (
                               <div className="padding">
                                <h3>Saapuneet laskut</h3>                
<p> - Kesken. Tulee myyntireskontra ohjelmasta saapuneet laskut ja niiden tila. Riippuu aina myyntireskontraohjelmasta.</p>
                        </div>
                        );

                        case "/storage/orders":
                            return (
                               <div className="padding">
                                <h4>Myyntitilaukset</h4>
<p> - Myyntitilaukset tulee joko laskutyöt osiosta tai uusi tilaus (oikea ylänurkka). Paina uusi tilaus. Ohjelma kysyy ensin tehdäänkö lasku jossa tilauksesta tehdään lasku, ja tilauksessa olevan tavaran toimitusta ei seurata ja toinen vaihtoehto on, että Toimitetaan tavara tilaajalta ja tavara varataan varastosta tai merkitään tilattavaksi tilausta tehdessä. Seuraavaksi valitaan Asiakas ja mikäli Asiakasrekisteriin on merkitty laskutusosite ja toimitusosoite, niin ne tulevat näkymään automaattisesti. Mikäli näin ei ole, niin ne pitää kirjata näkymään. Seuraavaksi kysytään maksuehtoa, toimitusehtoa ja toimitustapaa. Sekä viitteen itselle sekä Asiakkaalle. Mahdollinen sovittu tarvepäivä sekä eräpäivä. Tämän jälkeen tullaan myyntitilausnäkymään. Valitaan varastonimikkeistä myydyt tuotteet tai rivin oikeasta reunasta kolmesta pisteestä varaston ulkopuoliset komponentit, työt tai kustannukset.</p>
<p> - Prosessi kulkee 1. Muokataan myyntitilausta. 2. lähetetään se Asiakkaalle 3. Tulee kuittaus laskun maksusta 4. Kuitataan ja arkistoidaan. Oikealla olevassa ruudussa näkee koko ajan kuka on tehnyt ja mitä myyntitilaukselle. Muokkausvaiheessa voidaan kaikkia rivejä ja kohtia muuttaa.</p>
<p> - Myyntitilaus etusivulla näkee kaikki avoinna olevat myyntitilaukset. Maksettu rivissä näkee onko maksettu vai ei ja tila rivissä näkee onko tila luoto vai luonnos.</p>
                                
                                <h4>Rajapinta myyntireskontraan</h4>
<p> - Toimii Fivaldi ja Netvisor ohjelmalla. Tehdään uusia liittymiä sovittaessa.</p> 
                        </div>
                        );


                        case "/storage/reader":
                            return (
                                <div className="padding">
                                <h3>Varastolukija</h3>
<p> - Lukee QR-koodeja sekä tavallisia viivakoodeja käyttämällä puhelimen kameraa.</p>
<p> - Toimii Android-laitteilla Chrome-, Firefox- ja mahdollisesti myös muilla selaimilla.</p>
<p> - Applen laitteilla lukija toimii ainoastaan Applen omalla Safari-selaimella laitevalmistajan muille selaimille asettamien rajoitusten vuoksi. </p>
<p> - Jos lukija ei toimi, päivitä selaimesi. Yleisin syy lukijan toimimattomuuteen on liian vanha selain.</p>
                        </div>
                        );

                        case "/reports":
                            return (
                                <div className="padding">
                                <h3>Raportit</h3>
<p> - Raportit osiossa ovat talletetut pohjat sekä raportit.</p>
<p> - Talletetut pohjat ovat räätälöityjä pohjia joita voit raportit kohdassa muodostaa.</p> 
<p> - Raportit osiossa voit luoda erilaisia raportteja järjestelmästä. Valitse ensin kohde ryhmästä Tuntikirjaukset, projektit, Projektiasennukset, Matkakirjaukset, Kulukorvaukset. Valitse näistä ja.</p>
<p> - Valitse sarakkeet joita haluat nähdä. Erottelu sarakkeisiin ja muut sarakkeet. Toinen rajaava tekijä on rajoittimet. Aikarajoitin, Henkilö tai henkilöt, työ tai työt, projekti tai projektit ja kirjauksen tila. Näillä pääset lähes rajoittamattomiin hakuihin. Paina luo raportti. Voit viedä raportin esxcellin ja voit rajata sitä ylöspäin projektirakenteessa. Ja jos haluat tallentaa raporttihaun omiin tallennuksiin, niin paina ”tallenna hakukriteerit” Voit nimetä raportin mieleiseksesi ja automaattisella aikarajoituksella voit määrittää aikarajan tulemaan suhteessa raportin hakupäivään. Esim. voit laittaa haun siten, että maaliskuussa otettu raportti näyttää helmikuun tiedot jne.</p>
                        </div>
                        );



                        case "/settings/general":
                            return (
                                <div className="padding">
                                 <h3>Yleiset asetukset</h3>
<p> - Yleiset asetukset kohdassa on kaksi eri tasoa. Yrityksen asetukset ja Uutiset.</p>  
<p> - Yrityksen asetukset kohdassa nähdään (näitä ei voi muokata) yrityksen perustiedot, kuten yrityksen nimi, yrityksen kirjautumispääte, valuutta mitä käytetään sekä käyttäjämäärä. Muokattavia kohtia on Yläpalkin brändäys, eli voidaan liittää kuva yrityksen logosta ja määrittää taustaväri. Lisäksi yläpalkin otsikko sekä alaotsikko nimitykset. Lisäksi määritetään Integraatioasetukset muihin ohjelmiin sekä osoitetiedot.</p>  
<p> - Uutiset osiossa voidaan lähettää yrityksen sisäisiä uutisia joko kaikille tai toimihenkilöille. Uutinen tulee valituille henkilöille näkyviin kun he seuraavan kerran kirjautuvat ohjelmaan ja näkyy niin kauan, kunnes he kuittaavat uutisen nähdyksi.</p>
                            </div>
                        );



                        case "/timetracking/settings":
                            return (
                                <div className="padding">                        
                            <h3>Työajan asetukset</h3>
<p> - Työaikojen asetukset jakaantuu osioihin, Yleiset asetukset, Luokitukset ja korvaukset, palkkalajit ja muistutukset.</p>
<p> - Yleiset asetukset kohdasta määritetään ensin kulujen budjetointi. Jotta työajanseurannan korvauksia voidaan budjetoida projekteille, täytyy korvaukset yhdistää varastonimikkeisiin. Ne on asetuksissa hyvä merkitä oletusvarastoihin. Kilometrikorvaus kilometrikorvaukseen jne. Mikäli yrityksellä on maksukortteja kirjaa ne yrityksen maksukortit osioon. Viimeiseksi määritetään rajapinta palkanlaskentaosioon. Luokitukset ja korvaukset kohdassa. Tuntiluokituksilla saadaan jaettua mm. ylityöt, lisätyö ja normaali työaika omiksi palkkalajeikseen palkanlaskentaa varten. Listaa tähän kaikki yrityksellä käytössä olevat tuntiluokat ja mahdolliset tuntiperusteiset lisäkorvaukset/palkkiot. Poissaolot kirjataan myös järjestelmään, jolloin ne saadaan tilastoitua ja järjestelmä pystyy tarkkailemaan työntekijöiden säännöllistä työaikaa. Listaa tähän kaikki yrityksellä käytössä olevat poissaolot. Kilometrikorvauksen määrä saattaa vaihdella ajoneuvon ja erinäisten lisäkuormien (mm. peräkärry, koira, painava esine) mukaan. Listaa tähän kaikki yrityksellä käytössä olevat kilometrikorvausmaksuluokat. Työntekijät voivat saada päivärahaa tai ateriakorvausta työmatkoiltaan. Listaa tähän yrityksellä käytössä olevat päivärahat ja ateriakorvaukset. Voit myös käyttää lakisääteisiä verovapaita maksimimääriä, jotka verottaja määrittää vuosittain.</p>
<p> - Palkkalajit. Palkkalajit määrittävät miten tuntikirjaukset siirtyvät henkilön palkanlaskentaan. Tyhjät palkkalajit eivät siirry, joten voit halutessasi siirtää vain tietyt tuntikirjaukset palkanmaksuun. Palkkalajeja voidaan säätää erikoissääntöjen mukaan, jolloin tietyt palkkalajit voidaan jakaa useammaksi tai vaihtaa kokonaan toiseksi tiettyjen ehtojen perusteella. Tämä osio kannattaa hienosäätää toimittajan ja palkkahallinon kanssa yhdessä.</p>
<p> - Muistutukset. Myöhästyneiden tuntikirjausten palautuksista lähetettävien muistutusten aikataulutus. Voit siis asettaa muistutuksen esim. siitä, että henkilön tunnit on myöhässä, niin ohjelma lähettää siitä sähköpostiin ja ohjelman ”kelloon” muistutuksen. Samoin voit muistuttaa tuntien hyväksyjää.</p>
                            </div>
                        );



                        case "/settings/workpermits":
                            return (
                                <div className="padding"> 
                            <h3>Työlupien asetukset</h3>
<p> - Yrityksellä voi olla käytössä erilaisia työlupia. Tässä asetusosioissa määritetään ne luvat, joita yrityksessä halutaan seurata. Paina ”luo uusi lupa” Kysytään työluvan nimeä. Kuinka paljon aikaisemmin asiasta muistutetaan henkilöä. Kenelle käyttäjäryhmälle lupa on näkyvissä ja kenellä käyttäjällä (valinnaiset vaihtoehdot).</p>
                            </div>
                        );



                        case "/settings/storage":
                            return (
                                <div className="padding">                      
                            <h3>Varaston asetukset</h3>
<p> - Varaston asetukset jakaantuu osioihin, Nimikekoodit, Tunnisteet, Mittarit, Ominaisuudet ja Seurantayksikkö.</p>
<p> - Nimikekoodit.  Nimikekoodi on yrityksen oma koodi. Ensin valitaan koodin muoto. Nappi löytyy keskeltä oikealta. Varastokoodin muoto voi olla 1. Täysin vapaa 2. Rajoitettu pituudella 3. Tasot rajoitettu merkillä 4. Tasot erotettu merkillä ja rajoitettu pituudella. Suositus on käyttää muotoa 4. Ensin määritetään erotinmerkin muoto. Se voi olla mikä tahansa merkki. Suositellaan käytettäväksi pistettä. Lisää uusi taso kohdasta voidaan lisätä rajoittamaton määrä tasoja. Jokaisen tason nimi voidaan kirjoittaa haluttu teksti ja koodin pituus voi olla mikä tahansa. Esimerkkinä 01.01.001. Tuoteryhmä 01. Tuote 01 ja tuotenimike 001.  Kun olet määrittänyt nimikekoodin, niin talleta asetus ja siirry uusi koodi kohtaan. Tässä määritetään perusvarasto. Eli esimerkissä pitää määrittää tuoteryhmä ja tuote. Itse varastonimike määritetään varastot osiossa. Paina siis uusi koodi ja lisää koodin etuliite (määritetyn koodin mukaan). ja nimike. Kolmesta pisteestä pääset aina eteenpäin kooditasossa. Varastokooditus on oikein tehty, kun punainen viiva nimikkeiden määrässä poistuu</p>
<p> - Tunnisteet ovat varastonimikkeen yksilöiviä koodeja. Samaa tunnistetta ei voi olla kahdella eri varastonimikkeellä. Tunniste voi olla toimittajan koodin, joka on sama kun oma koodi. Se voi olla viranomaisten koodi, kuten LVI koodi, EU koodi jne Paina lisää tunniste ja lisää tunniste.</p>
<p> - Mittari on varastonimikkeeseen yhdistävä mittari. Esim, jos tuote painaa kg/kpl, kg/metri. Työtunti/kpl jne. Paina lisää mittari lisäysnappia ja lisää ko. mittari. Valitse myös yksikkö. Lisäksi järjestelmässä on ominaisuus, että voi kohdistaa lisäksi mittarin tiettyyn liittyvään työhön. Tämä ominaisuus mahdollistaa urakkalaskennassa mallin, jossa työmäärä tulee automaattisesti. </p>
<p> - Ominaisuudet kohdassa liitetään varastonimikkeeseen liittyviä ominaisuuksia. Ominaisuus voi olla kyllä tai ei arvo tai vapaa arvo yksiköllä tai ilman. Esimerkkinä, onko nimike valkoinen vai ei. Tämä mahdollistaa sen, että ison varastomassan pystyy erottelemaan haluamallaan tavalla.</p>
<p> - Seurantayksikkö on kehityksessä.</p>
                            </div>
                        );



                        case "/settings/purchase":
                            return (
                                <div className="padding"> 
                            <h3>Ostojen asetukset</h3>
<p> - Ostojen asetukset jakaantuu osioihin. Pohja, Numerointi, Maksuehdot, Toimitusehdot ja Toimitustavat. </p>
<p> - Pohja. Voit ladata yleisen peruspohjan keskellä vasemmalla olevasta kolmesta pisteestä. Lataa tiedosto ja voi liittää siihen oman logon. Mikäli haluta muokata ostotilausta edelleen, suosittelemme että otat yhteyttä tukeen. Uuden pohjan voit tallettaa kohdasta ”valitse DOCX-tiedosto.</p> 
<p> - Numerointi osassa määritetään seuraava ostotilauksennumero ja numeron pituus. Pituus lisää nollia numeron alkuun. Numeron ylittäessä määritetyn pituuden pituutta ei oteta huomioon.</p>
<p> - Maksuehdon määrittäminen. Maksuehdoksi voi valita ainoastaan listan vaihtoehtoja. Samat maksuehdot ovat käytössä sekä osto- että myyntitilauksissa. Määritä ensin ostotilausehdot. Määritä lisäksi onko ostotilauksissa pakollinen maksuehtojen valinta käytössä sekä onko maksuehtojen valinta ostotilauksissa pakollinen. Lisää maksuehto napista saat lisää maksuehtoja.</p>
<p> - Toimitusehdon määrittäminen. Toimitusehdoksi voi valita ainoastaan listan vaihtoehtoja. Samat toimitusehdot ovat käytössä sekä osto- että myyntitilauksissa. Määritä lisäksi onko ostotilauksissa pakollinen toimitusehtojen valinta käytössä sekä onko toimitusehdot valinta ostotilauksissa pakollinen. Lisää toimitusehto napista saat lisää toimitusehtoja.</p>
<p> - Toimitustavan määrittäminen. Toimitustavaksi voi valita ainoastaan alla olevan listan vaihtoehtoja. Samat toimitustavat ovat käytössä sekä osto- että myyntitilauksissa. Määritä lisäksi onko ostotilauksissa pakollinen toimitustapojen valinta käytössä sekä onko toimitustavan valinta ostotilauksissa pakollinen. Lisää toimitustapa napista saat lisää toimitustapoja.</p>
                            </div>
                        );



                        case "/settings/order":
                            return (
                                <div className="padding">                  
                            <h3>Myyntiasetukset</h3>
<p> - Myynnin asetukset jakaantuu osioihin. Tilausvahvistus, Lasku, Keruulista, Numerointi, Alv, Maksuehdot, Toimitusehdot ja Toimitustavat.</p>
<p> - Tilausvahvistus. Voit ladata yleisen peruspohjan keskellä vasemmalla olevasta kolmesta pisteestä. Lataa tiedosto ja voi liittää siihen oman logon. Mikäli haluta muokata tilausvahvistusta edelleen, suosittelemme että otat yhteyttä tukeen. Uuden pohjan voit tallettaa kohdasta ”valitse DOCX-tiedosto.</p>
<p> - Lasku. Voit ladata yleisen peruspohjan keskellä vasemmalla olevasta kolmesta pisteestä. Lataa tiedosto ja voi liittää siihen oman logon. Mikäli haluta muokata Laskua edelleen, suosittelemme että otat yhteyttä tukeen. Uuden pohjan voit tallettaa kohdasta ”valitse DOCX-tiedosto.</p> 
<p> - Keruulista. Voit ladata yleisen peruspohjan keskellä vasemmalla olevasta kolmesta pisteestä. Lataa tiedosto ja voi liittää siihen oman logon. Mikäli haluta muokata Keruulistaa edelleen, suosittelemme että otat yhteyttä tukeen. Uuden pohjan voit tallettaa kohdasta ”valitse DOCX-tiedosto.</p> 
<p> - Numerointi osassa määritetään seuraava myyntitilauksennumero ja numeron pituus. Pituus lisää nollia numeron alkuun. Numeron ylittäessä määritetyn pituuden pituutta ei oteta huomioon.</p>
<p> - Alv määritys. Lisää vat. kohdasta voit lisätä eri myynti alv prosentteja. Ja voi määrittää sen alv prosentin, joka on oletusalv.</p>
<p> - Maksuehdon määrittäminen. Maksuehdoksi voi valita ainoastaan listan vaihtoehtoja. Samat maksuehdot ovat käytössä sekä osto- että myyntitilauksissa. Määritä ensin ostotilausehdot. Määritä lisäksi onko myyntitilauksissa pakollinen maksuehtojen valinta käytössä sekä onko maksuehtojen valinta myyntitilauksissa pakollinen. Lisää maksuehto napista saat lisää maksuehtoja.</p>
<p> - Toimitusehdon määrittäminen. Toimitusehdoksi voi valita ainoastaan listan vaihtoehtoja. Samat toimitusehdot ovat käytössä sekä osto- että myyntitilauksissa. Määritä lisäksi onko myyntitilauksissa pakollinen toimitusehtojen valinta käytössä sekä onko toimitusehdot valinta myyntitilauksissa pakollinen. Lisää toimitusehto napista saat lisää toimitusehtoja.</p>
<p> - Toimitustavan määrittäminen. Toimitustavaksi voi valita ainoastaan alla olevan listan vaihtoehtoja. Samat toimitustavat ovat käytössä sekä osto- että myyntitilauksissa. Määritä lisäksi onko myyntitilauksissa pakollinen toimitustapojen valinta käytössä sekä onko toimitustavan valinta myyntitilauksissa pakollinen. Lisää toimitustapa napista saat lisää toimitustapoja.</p>
                             </div>
                        );



                        case "/settings/projects":
                            return (
                                <div className="padding">                  
                            <h3>Projektien asetukset</h3>
<p> - Projektien asetukset jakaantuvat Tyypit ja numerointi, Käytettävät hinnat ja tarjous-tiedostopohjat.</p>
<p> - Tyypit ja numerointi. Lisää projektityyppi kohdasta lisäät projektin nimitys sekä numeron etuliite ja numeron pituus. Esimerkki kohdassa näet esimerkin numeronäkymästä. Oikealla rivin viimeisenä näkyy joko roskakorin kuva, jolloin numerotyypin voi poistaa. Mikäli kohdassa on joku kpl numero, niin numerosarjaan on projektit osioissa lukittu projekti, jolloin sitä ei suositella enää poistettavaksi. Kohdassa aliprojektin numerointi kohdassa määritetään aliprojektinumeron pituus. Suositeltava on mahdollisimman lyhyt numero, sillä pitkässä numerosarjassa voi olla hankala lukea sarjaa. Vertaa 80001.0001.0001.0001 tai esim. 80001.1.1.1.</p> 
<p> - Käytettävät hinnat kohdassa määritetään tarjouslaskennassa käytettävän komponentin hinnan. Vaihtoehdot on toimittajan hinta, Viimeinen ostohinta ja Keskihinta.</p> 
<p> - Tarjous-tiedostopohjat. Tähän listataan tiedostopohjat, joita käytetään tarjouksen lähettämiseen. Tarjouspohjan tulee olla Microsoft Office Open XML .docx tyyppinen tiedosto. Kohdasta tarjouspohjan määritykset näet mitä eri nimityksiä käytetään tarjouspohjassa. Tiedostoon voi määritellä tekstejä jotka korvataan jollakin tarjoukseen tai projektiin liittyvällä tiedolla. Lisää napista lataat oman tarjouspohjan ja kohdan tarjouspohjan määritykset mukaisesti korvaat ohjelmasta tulevat tiedot ko. nimityksillä. Tässä kannattaa kääntyä ohjelmistotuen puoleen. Lisäksi voidaan määrittää tarjousviestin oletusotsikko sekä tarjousviestin oletusteksti.</p> 
                            </div>
                        );    
                        
                        case "/settings/notifications":
                            return (
                                <div className="padding">                         
                            <h3>Ilmoitusasetukset</h3>
<p> - Voit muokata järjestelmän lähettämiä ilmoituksia</p>
<p> - Uudet CRM merkinnät sähköpostiin. Voi määrittää ne henkilöt, joille halutaan lähtevän kaikki CRM merkinnät sähköpostiin. Lisää vastaanottaja kohdasta. Ohjelma tarkista, että ko. sähköposti on henkilörekisterissä.</p>
                            </div>
                        );                         

                        case "/settings/extranet":
                            return (
                                <div className="padding"> 
                            <h3>Extranet-asetukset</h3> 
<p> - Täällä voi räätälöidä Extranet (oma Asiakkaasi) sivujen yleistä sisältöä ja ulkoasua. Osiot ovat Yleiset asetukset, Uutiset, Näkyvyysasetukset sekä Tiedostot.</p>
<p> - Yleisissä asetuksissa voidaan kirjoittaa etusivun tervetuloa tekstin. Tämä näkyy siis kaikilla Asiakkailla samanlaisena.</p>
<p> - Uutiset kohdassa voit lisätä erilaisia kohdennettuja uutisia Asiakkaille. Voit myös poistaa uutisen näkyvistä.</p>
<p> - Näkyvyysasetuksissa on kuusi eri näkyvyyttä. 1. näytä työaikojen hyväksyntä. 2. Näytä esitteet. 3. Näytä projektit 4. Näytä tilastot. 5. Näytä dokumentit ja 6 Näytä uutiset. Kohdat 3-4 ovat vielä kehityksessä.</p>
                            </div>
                        );





            default:
                return (
                    <div className="padding">
                        <h3>{tr('coming_soon')}</h3>
                        <p>{tr('coming_soon_text')}</p>
                    </div>
                );
        }
    }

    // Käsittele URL jossa on päivätietoja ja muut erikoistapaukset
    handleUrl(url) {
        // console.log(`URL: ${url}`);
        if (url.includes('/timetracking/entry')) {
            return '/timetracking/entry';
        }
        if (url.includes('/timetracking/overview')) {
            return '/timetracking/overview';
        }
        if (url.includes('/billable')) {
            return '/billable';
        }
        if (url.includes('/workhours/holidays')) {
            return '/workhours/holidays';
        }
        if (url.includes('/ext/assignments')) {
            return '/ext/assignments'
        }
        if (url.includes('/assignments')) {
            return '/assignments'
        }
        if (url.includes('/crm/company')) {
            return '/crm/company'
        }
        if (url.includes('/storage/logistic/delivery/show')) {
            return '/storage/logistic/delivery/show'
        }
        if (url.includes('/storage/purchase/order/')) {
            return '/storage/purchase/order/'
        }
        if (url.includes('/storage/order/')) {
            return '/storage/order/'
        }
        if (url.includes('/orders/checkout/collection')) {
            return '/orders/checkout/collection'
        }
        if (url.includes('/orders/checkout')) {
            return '/orders/checkout'
        }
        return url;
    }

    render()
    {
        let url = this.getUrl();
        url = this.handleUrl(url.toLowerCase());
        let currentUrl = this.props.url || window.location.pathname;
        currentUrl = this.handleUrl(currentUrl.toLowerCase());
        const page = this.state.pages.find( p => p.url === currentUrl );

        return (
        	<div id="help" className={ (this.props.open ? "open" : "" ) } onClick={this.clickOutsideHandler}>
        		<div ref={node => this.node = node} className="help">
        			<div className="top">
        				<h3 className="title">{tr('guide_and_tips')}</h3>
        				<div className="close" onClick={ this.props.closeEvent }>
        					<SvgIcon type="solid" icon="times" />
        				</div>
        			</div>

        			<VerticalScroller offsetTop={50} srcollShadows hideScrollbar>
                        <div className="navigation">
                            <div className={ "section" + ( this.state.location !== "GENERAL" ? " selected" : "" ) } onClick={ this.clearLocation }>
                                <SvgIcon icon="compass" type="solid" />
                                <div className="text">
                                    { page ? page.name : currentUrl }<br />
                                    <small>{tr('page_specific_guide')}</small>
                                </div>
                            </div>
                            <div className={ "section" + ( this.state.location === "GENERAL" ? " selected" : "" ) } onClick={ () => this.changeLocation( "GENERAL" ) }>
                                <SvgIcon icon="globe" type="solid" />
                                <div className="text">
                                    {tr('general_guide')}
                                </div>
                            </div>
                        </div>
        				{ this.renderPageHelp( url ) }
        			</VerticalScroller>
        		</div>
        	</div>	
        );
    }
};


export default Help;
