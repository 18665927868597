import React from 'react';
import autobind from 'react-autobind';
import PropTypes from "prop-types";
import { dateInputToSql, errorPopper, keyExists, mapTree, saveSuccessPopper, getExcel, tr, validateEmail, sqlToDateInput } from 'services/Helpers/Helpers';
import ComponentInstanceTable from 'modules/Storage/common/ComponentInstanceTable/Cit';
import ApSelect from 'common/ApSelect/ApSelect';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';
import api from "services/Api/Api";
import SvgIcon from 'common/SvgIcon/SvgIcon';
import ApButton from 'common/ApButton/ApButton';
import ApDropdown from 'common/ApDropdown/ApDropdown';
import _ from 'lodash';

import './PurchaseOrderOffer.css';
import ApTooltip from 'common/ApTooltip/ApTooltip';

class PurchaseOrderOffer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            suppliers: [],
            componentTypes: [],
            title: '',
            text: '',
            email: '',
            components: [],
            selectedTemplateId: null,
            selectedFile: null,
            deadline: null,
        }

        this.validator = 
        autobind(this);
    }

    allProjectComponents(options = {}) {
        if (!this.props.tree && !this.props.tree[0]) return;
        let tree = JSON.parse(JSON.stringify(this.props.tree));
        const allComponents = [];
        mapTree(tree, item => {
            if (item.components && item.components.length) {
                for (let component of item.components) {
                    component.id = component.component.id;
                    component.component_id = component.component.id;
                    component.price = component.component.price;
                    component.unit = component.component.unit;
                    component.component_types = component.component.component_types;
                    component.suppliers = component.component.suppliers;
                    component.identifiers = component.component.identifiers;
                    if (component.type_id === 1) {
                        if (options.supplier && this.state.suppliers.length && options.componentTypes && this.state.componentTypes.length) {
                            const supplierIds = this.state.suppliers.map(supplier => supplier.id);
                            const typeIds = this.state.componentTypes.map(type => type.id);
                            const foundSupplier = component.component.suppliers.find(supplier => supplierIds.includes(supplier.id));
                            const foundType = component.component_types.find(type => typeIds.includes(type.type_id));
                            if (foundSupplier && foundType) {
                                allComponents.push(component);
                            } else if (!component.component_types.length && this.state.componentTypes.find(type => !type.id)) {
                                allComponents.push(component);
                            }
                        }
                        else if (options.supplier && this.state.suppliers.length) {
                            const supplierIds = this.state.suppliers.map(supplier => supplier.id);
                            const foundSupplier = component.component.suppliers.find(supplier => supplierIds.includes(supplier.id));
                            if (foundSupplier) {
                                allComponents.push(component);
                            }
                        } else if (options.componentTypes && this.state.componentTypes.length) {
                            const typeIds = this.state.componentTypes.map(type => type.id);
                            const foundType = component.component_types.find(type => typeIds.includes(type.type_id));
                            if (foundType) {
                                allComponents.push(component);
                            } else if (!component.component_types.length && this.state.componentTypes.find(type => !type.id)) {
                                allComponents.push(component);
                            }
                        } else {
                            allComponents.push(component);
                        }
                    }
                }
            }
            return item;
        });

        const components = allComponents.reduce((componentsList, component) => {
            if (!component.count && component.alloc_count) {
                component.count = component.alloc_count;
            }
            const foundComponent = componentsList.find(c => c.component.id === component.component.id)
            if (!foundComponent) {
                componentsList.push(component);
            } else {
                foundComponent.count = Number(foundComponent.count) + Number(component.count);
            }
            return componentsList;
        }, []);

        const componentsOrdered = _.orderBy(components, 'name', 'asc');

        this.setState({ components: componentsOrdered });
    }

    componentsChange(components) {
        this.setState({components})
    }

    saveOffer(sendEmail = false) {
        // Calculate price from selected components
        const price = this.state.components.reduce((total, component) => {
            return total + (Number(component.price) * Number(component.count));
        }, 0);

        const suppliers = JSON.stringify(this.state.suppliers);
        const components = JSON.stringify(this.state.components);
        const deadline = this.state.deadline ? this.state.deadline : '';

        let data = new FormData();
        data.append( 'file', this.state.selectedFile );
        data.append( 'suppliers', suppliers);
        data.append( 'title', this.state.title);
        data.append( 'text', this.state.text);
        data.append( 'price', price);
        data.append( 'project_id', this.props.tree[0].real_project_id ? this.props.tree[0].real_project_id : this.props.tree[0].id);
        data.append( 'sendEmail', sendEmail === true ? 'true' : 'false' );
        data.append( 'components', components );
        data.append( 'file', this.state.selectedFile );
        data.append( 'deadline', deadline );
        if (this.props.sheetId) {
            data.append('sheet_id', this.props.sheetId);
        }

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'project/po/offer/save',
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(( response ) => {
            // console.log("RES", response);
            
            this.setState({loading: false});
            saveSuccessPopper();

            if (response.emailErrors && Array.isArray(response.emailErrors)) {
                response.emailErrors.forEach(email => {
                    errorPopper(null, tr('send_email_failed', [email]));
                })
            }

            if (this.props.onSave && typeof this.props.onSave === 'function') {
                this.props.onSave();
            }

        }).catch( ( error ) => {
            this.setState({ loading: false });

            errorPopper(error, tr('get_error') );
        });
    }

    handleEmailChange(supplierId, value) {
        const suppliers = [...this.state.suppliers];
        const foundSupplier = suppliers.findIndex(supplier => supplier.id === supplierId);
        if (foundSupplier != -1) {
            suppliers[foundSupplier].sendEmailTo = value;
        }

        this.setState({suppliers});
    }

    removeSupplier(id) {
        const suppliers = [...this.state.suppliers].filter(supplier => supplier.id != id);
        this.setState({suppliers});
    }

    async getComponentsExcel() {
        const components = [...this.state.components];

        const identifiers = components.reduce((all, component) => {
            component.identifiers.forEach(identifier => {
                if (!all.find(item => item.id === identifier.id)) {
                    all.push(identifier);
                }
            })
            return all;
        }, [])

        const orderedIdentifiers = _.orderBy(identifiers, 'name', 'asc');

        if (components.length) {
            const excelData = components.map(component => {
                const data = {
                    component_name: component.name,
                    unit: component.unit,
                }

                orderedIdentifiers.map(identifier => {
                    const found = component.identifiers.find(cIdentifier => cIdentifier.id === identifier.id )
                    if (found) {
                        data[found.name] = found.pivot.value;
                    } else {
                        data[identifier.name] = null;
                    }
                });

                data['amount'] = Number(component.count);
                data['price'] = null;
                // data['price'] = Number(component.price);
                
                return data;
            });

            this.setState({ loading: true });
            await getExcel(excelData, tr('price_inquiry'));
            this.setState({ loading: false });
        } else {
            errorPopper(null, tr('select_component_required'));
        }
    }

    render() {
        let sendByEmailErrors = [];
        if(this.state.suppliers.some(supplier => !validateEmail(supplier.sendEmailTo))) {
            sendByEmailErrors.push(tr('email_missing'));
        }
        if (!this.state.title) sendByEmailErrors.push(tr('inquiry_title_missing'));
        if (!this.state.text) sendByEmailErrors.push(tr('inquiry_text_missing'));

        return (
            <div id='po-offer' className='padding'>
                <div>
                    <h3>{tr('send_price_inquiry')}</h3>
                    <p>{tr('send_price_inquiry_info')}</p>
                </div>

                <div className='justify-space-between collapse-flex-800' style={{gap:'2em'}}>
                    <div className='apColumn w50'>
                        <ApInputStack gap='0'>
                            <ApAddon width='220px'>
                                {tr('supplier')}
                            </ApAddon>
                            <ApSelect
                                id='supplier'
                                label={tr('supplier')}
                                value={this.state.suppliers}
                                onChange={e => this.setState({suppliers: e})}
                                type='select'
                                apiUrl="storage/po/supplier/find"
                                apiData={{
                                    contacts: true
                                }}
                                optionRenderer="storage_supplier"
                                valueRenderer="storage_supplier"
                                objKeyId="id"
                                clearable
                                validationState={this.state.suppliers.length ? 'success' : 'error'}
                                multiselect
                                keepFocusOnSelect
                                loading={this.state.loading}
                                disabled={this.state.loading}
                            />
                        </ApInputStack>
                    </div>
                    <div className='apColumn w50'>
                        <ApInputStack gap='0'>
                            <ApAddon width='220px'>
                                {tr('storage_component_types')}
                            </ApAddon>
                            <ApSelect
                                label={tr('storage_component_types')}
                                value={keyExists(this.state, 'componentTypes', true, [])}
                                onChange={e => this.setState({componentTypes: e})}
                                multiselect
                                apiUrl="search/storage/componentTypes"
                                apiData={{
                                    with_empty: true,
                                }}
                                objKeyId='id'
                                optionRenderer={(item) => item.id === null ? tr(item.name) : item.name}
                                valueRenderer={(item) => item.id === null ? tr(item.name) : item.name}
                                keepFocusOnSelect
                                loading={this.state.loading}
                                disabled={this.state.loading}
                            />
                            <ApAddon width='50px' onClick={() => this.allProjectComponents({componentTypes: true})} disabled={!this.state.componentTypes.length}>
                                <ApTooltip text={tr('add')}>
                                    <SvgIcon type='solid' icon='plus-square' />
                                </ApTooltip>
                            </ApAddon>
                        </ApInputStack>
                    </div>
                </div>
                <div className='justify-space-between collapse-flex-800' style={{gap:'2em'}}>
                    <div className='apColumn'>
                        {this.state.suppliers.map((supplier, index) => {
                            const contacts = [...supplier.contacts];
                            if (supplier.email) {
                                contacts.unshift({last_name: supplier.name, first_name: '', email: supplier.email, company: true});
                            }
                            return <ApInputStack gap='0' key={index}>
                                <ApAddon width='350px' style={{marginBottom: '0.5em'}} labelFor={`supplier-${supplier.id}-email`}>
                                    {supplier.name} {tr('email')}
                                </ApAddon>
                                <ApInput
                                    id={`supplier-${supplier.id}-email`}
                                    label={tr('email')}
                                    value={supplier.sendEmailTo}
                                    onChange={(e)=>this.handleEmailChange(supplier.id, e.target.value)}
                                    type='text'
                                    validationState={!supplier.sendEmailTo ? 'error' : validateEmail(supplier.sendEmailTo) ? 'success' : 'warning'}
                                    loading={this.state.loading}
                                    disabled={this.state.loading}
                                />
                                <ApAddon width='50px' style={{marginBottom: '0.5em'}} tooltip={tr('remove_selection')} onClick={() => this.removeSupplier(supplier.id)}>
                                    <SvgIcon type='solid' icon='times' fill='var(--clr-error-main)' />                                    
                                </ApAddon>
                                <ApAddon width='50px' style={{marginBottom: '0.5em'}} labelFor={`supplier-${supplier.id}-email`}>
                                    <ApDropdown 
                                        button={<SvgIcon type='solid' icon='book' />}
                                        actions={!contacts.length 
                                        ? [
                                            {
                                                label: <div className='addressRow' style={{lineHeight: 'initial'}}>
                                                    <p style={{wordBreak: 'break-word', fontWeight: '400', marginBottom: '5px'}}>
                                                        {tr('email_missing')}
                                                    </p>
                                                </div>,
                                                icon: "at",
                                                action: () => null,
                                                disabled: true,
                                                closeAfter: true,
                                            }
                                        ]
                                        : contacts.map(contact => (
                                            {
                                                label: <div className='addressRow' style={{lineHeight: 'initial'}}>
                                                    <p style={{wordBreak: 'break-word', fontWeight: '400', marginBottom: '5px'}}>
                                                        {contact.last_name + ' ' + contact.first_name}
                                                    </p>
                                                    <p style={{wordBreak: 'break-word'}}>
                                                        {contact.email}
                                                    </p>
                                                </div>,
                                                icon: contact.company ? 'industry' : "at",
                                                action: () => this.handleEmailChange(supplier.id, contact.email),
                                                disabled: !contacts.length,
                                                closeAfter: true,
                                            }
                                        ))}
                                    />
                                    
                                </ApAddon>
                            </ApInputStack>
                        })}
                    </div>
                </div>

                <div className='justify-space-between collapse-flex-800' style={{gap:'2em'}}>
                    <div className='apColumn w50'>
                        <ApInputStack gap='0'>
                            <ApAddon width='220px' labelFor='offer-title'>
                                {tr('message_title')}
                            </ApAddon>
                            <ApInput
                                id='offer-title'
                                type='text'
                                label={tr('message_title')}
                                value={this.state.title}
                                onChange={e => this.setState({title: e.target.value})}
                                loading={this.state.loading}
                                disabled={this.state.loading}
                            />
                        </ApInputStack>
                        <ApInputStack gap='0'>
                            <ApAddon width='220px' style={{height: '86px'}} labelFor='offer-text'>
                                {tr('message_text')}
                            </ApAddon>
                            <ApInput
                                id='offer-text'
                                type='textarea'
                                label={tr('message_text')}
                                value={this.state.text}
                                onChange={e => this.setState({text: e.target.value})}
                                loading={this.state.loading}
                                disabled={this.state.loading}
                            />
                        </ApInputStack>
                    </div>
                    <div className='apColumn w50'>
                        <ApInputStack gap='0'>
                            <ApAddon width='220px' onClick={this.getComponentsExcel}>
                                <SvgIcon type='solid' icon='download' /> {tr('download_excel_template')}
                            </ApAddon>
                            <ApInput
                                type="file"
                                accept=".xlsx"
                                id="po-selectedFile"
                                name="po-selectedFile"
                                label={`${tr('choose_file')}...`}
                                value={ this.state.selectedFile }
                                validationState={this.state.selectedFile ? 'success' : 'error'}
                                onChange={ ( file ) => { this.setState({ selectedFile: file }) } }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                            />
                        </ApInputStack>
                        <ApInputStack gap='0'>
                            <ApAddon width='220px'>
                                {tr('select_last_day_of_validity')}
                            </ApAddon>
                            <ApInput 
                                type='datetimeV2'
                                id='deadline'
                                value={this.state.deadline}
                                onChange={value => this.setState({deadline: value})}
                                clearable
                                loading={this.state.loading}
                                disabled={this.state.loading}
                            />
                        </ApInputStack>
                    </div>
                </div>

                <ComponentInstanceTable
                    componentsChange={this.componentsChange}
                    components={this.state.components}

                    instance={'supplier_offer'}

                    loading={this.state.loading}
                    suppliers={this.state.suppliers}
                />

                <div className='justify-end'>
                        <ApTooltip text={sendByEmailErrors.map((error, index) => <div key={index}>{error}</div>)}>
                            <ApButton 
                                style={{marginRight: '10px'}} 
                                onClick={()=>this.saveOffer(true)} 
                                color='green' 
                                disabled={Boolean(!this.props.tree.length) || Boolean(sendByEmailErrors.length) || this.state.loading}
                            >
                                <SvgIcon type='solid' icon='at' /> {tr('send_inquiry_email')}
                            </ApButton>
                        </ApTooltip>
                        <ApButton 
                            onClick={this.saveOffer} 
                            color='blue' 
                            disabled={!this.props.tree.length || this.state.loading}
                        >
                            <SvgIcon type='solid' icon='envelope' /> {tr('send_inquiry_manual')}
                        </ApButton>
                        <ApDropdown
                            position='top'
                            button={ <ApButton narrow><SvgIcon icon="ellipsis-h" type="solid" /></ApButton> }
                            actions={
                                [
                                    {
                                        label: tr('all_project_components_supplier'),
                                        icon: "box",
                                        action: () => this.allProjectComponents({supplier: true}),
                                        disabled: !this.state.suppliers.length || this.state.loading,
                                        closeAfter: true,
                                    },
                                    {
                                        label: tr('all_component_types_supplier'),
                                        icon: "box",
                                        action: () => this.allProjectComponents({supplier: true, componentTypes: true}),
                                        disabled: !this.state.componentTypes.length || !this.state.suppliers.length || this.state.loading,
                                        closeAfter: true,
                                    },
                                    {
                                        label: tr('all_project_components'),
                                        icon: "boxes",
                                        action: this.allProjectComponents,
                                        disabled: this.state.loading,
                                        closeAfter: true,
                                    },
                                ]
                            }
                        />
                </div>
            </div>
        )
    }
}

PurchaseOrderOffer.propTypes = {
    tree: PropTypes.array.isRequired,
};

export default PurchaseOrderOffer;