let date_start = ''
let date_end = ''
let cameFromBillable = false
let company = null
let continueWithCompany = false

export const rememberStartDate = (start) => {
    date_start = start
}

export const rememberEndDate = (end) => {
    date_end = end
}

export const getStartDate = () => {
    return date_start
}

export const getEndDate = () => {
    return date_end
}

export const setFrom = (value) => {
    cameFromBillable = value
}

export const getFrom = () => {
    return cameFromBillable
}

export const setCompanyId = (companyToSave) => {
    company = companyToSave
}

export const getCompanyId = () => {
    return company
}

export const setContinueWithCompany = (value) => {
    continueWithCompany = value
}

export const getContinueWithCompany = () => {
    return continueWithCompany
}