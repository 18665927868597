import React from 'react';
import autoBind from 'react-autobind';

import Measure from 'react-measure';

import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';
import SvgIcon     from 'common/SvgIcon/SvgIcon.js';
import ApButton    from 'common/ApButton/ApButton.js';
import ApTooltip   from 'common/ApTooltip/ApTooltip.js';
import api         from 'services/Api/Api.js';
import ApModal     from 'common/ApModal/ApModal.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';

import { ApTabs
       , ApTab }       from 'common/ApTabs/ApTabs.js';
import { mapTree
       , keyExists
       , errorPopper, onlyNumber, tr } from 'services/Helpers/Helpers.js';

import { getComponentStatusId } from 'modules/Storage/common/StorageHelpers.js';

import { getTypeIcon }  from 'modules/Storage/common/StorageHelpers.js';
import { Collapse } from 'react-bootstrap';

import Code from './Code/Code.js';
import CodeFormat from './CodeFormat/CodeFormat.js';
import ComponentRelations from './ComponentRelations/ComponentRelations.js';
import ApSelect from 'common/ApSelect/ApSelect.js';

import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import './Settings.css';
import { connect } from 'react-redux';

class StorageSettings extends React.Component {
    constructor(props)
    {
        super(props);

        const params = this.props.match.params;
        let tab = ( params.tab ) ? parseInt( params.tab , 10 ) : 0;

        this.state = {
            selectedTab: tab,
            loading: false,

            identifiers: [],
            calculatables: [],
            extraCalculatables: [],
            properties: [],
            componentsCount: 0,

            modeOptions: [],
            codes: [],
            
            lvisnet_enabled: false,
            ean_identifier: null,
            
            
            format: {},
            formatSaved: {},
            confirmFormatShow: false,

            trackingunits: [],

            labels_ean_identifier: null,
            labels_ean_identifier_id: null,
            labels_cols: 0,
            labels_rows: 0,

            types: [],

        };
        autoBind(this);
    }

    componentDidMount()
    {
        this.getSettings();
    }

    getSettings()
    {
        this.setState({ loading: true });

        return api({
            method: 'get',
            url: 'storage/settings',
        }).then((response) => {
            this.setSettings( response );
            this.setState({ loading: false });
            this.props.saveStorageSettings(response);
        }).catch(( error ) => {
            console.log(error);

            errorPopper( error, tr('get_error') );
            this.setState({ loading: false });
        });
    }

    setSettings( data )
    {
        this.setState({
            formatSaved: JSON.stringify( data.format ),
            format: data.format,
            modeOptions: data.mode_options,

            identifiers: data.identifiers,
            calculatables: data.calculatables,
            extraCalculatables: data.extraCalculatables,
            properties: data.properties,
            trackingunits: data.trackingunits,
            
            lvisnet_enabled: data.lvisnet_enabled,
            ean_identifier_id: data.ean_identifier_id,
            ean_identifier: data.ean_code_identifier,

            labels_ean_identifier: data.labels_ean_identifier,
            labels_ean_identifier_id: data.labels_ean_identifier_id,
            labels_cols: data.labels_cols,
            labels_rows: data.labels_rows,

            componentsCount: data.components_count,
            componentsWithoutCodeCount: data.components_without_code_count,

            extranet_supplier_enabled: data.extranet_supplier_enabled,

            types: data.types,
            woocommerce_enabled: data.woocommerce_enabled
        }, () => {
            this.calculateCodeTree( data.codes );
        });

    }

    goToComponents( filters )
    {
        window.emitter.emit('goTo', {
            pathname: '/storage/components',
            state: {filters: filters },
        });
    }

    setIdentifier( identifier )
    {
    	if (identifier==null) {
    		this.setState({
	    		ean_identifier: identifier,
	    		ean_identifier_id: null
    		});
    	}
    	else {
            this.setState({
            	ean_identifier: identifier,
            	ean_identifier_id: identifier.id
            });
    	}

    }

    changeA4RowCol( value, type )
    {
        if (type=="col_count") {
            value = onlyNumber( value )
            if (value<1) {value=1}
            if (value>5) {value=5}
            this.setState({ labels_cols: value });
        }
        if (type=="row_count") {
            value = onlyNumber( value )
            if (value<1) {value=1}
            if (value>15) {value=15}
            this.setState({ labels_rows: value });

        }
    }

    setLabelIdentifier( identifier )
    {
    	if (identifier==null) {
    		this.setState({
	    		labels_ean_identifier: null,
	    		labels_ean_identifier_id: null
    		});
    	}
    	else {
            this.setState({
            	labels_ean_identifier: identifier,
            	labels_ean_identifier_id: identifier.id
            });
    	}

    }
    
    saveClicked()
    {
        if( this.formatIsChanged() )
            this.setState({ confirmFormatShow: true });
        else
            this.saveSettings();
    }

    saveSettings()
    {
        this.setState({ confirmFormatShow: false });

        let calculatables = JSON.parse(JSON.stringify( this.state.calculatables ));
        let extraCalculatables = JSON.parse(JSON.stringify( this.state.extraCalculatables ));
        let identifiers   = JSON.parse(JSON.stringify( this.state.identifiers ));
        let properties    = JSON.parse(JSON.stringify( this.state.properties ));
        let trackingunits = JSON.parse(JSON.stringify( this.state.trackingunits ));
        let types = JSON.parse(JSON.stringify( this.state.types ));

        calculatables = calculatables.map( c => {
            c.work_component = c.work_component ? c.work_component.id : null;
            return c;
        });

        let data = {
            identifiers: identifiers,
            calculatables: calculatables,
            extraCalculatables,
            properties: properties,
            trackingunits: trackingunits,
            types,
            lvisnet_enabled: this.state.lvisnet_enabled,
            ean_identifier_id: this.state.ean_identifier_id,
            labels_ean_identifier_id: this.state.labels_ean_identifier_id,
            labels_cols: this.state.labels_cols,
            labels_rows: this.state.labels_rows,
        };

        if( this.formatIsChanged() )
        {
            let format = {
                mode_id: this.state.format.mode.id,
                levels: this.state.format.levels,
                separator: this.state.format.separator,
                tail_length: this.state.format.tail_length,
            };
            data.format = format;
        }
        else
        {
            if( this.codeTreeRef )
                this.codeTreeRef.updateSelectedCodeSettings();
            let tree = JSON.parse( JSON.stringify( this.state.codes ) );

            data.codes = mapTree( tree, null, ( item ) => {
                return {
                    id: isNaN( parseInt( item.id, 10 ) ) ? null : item.id,
                    name: item.name,
                    value: item.value,
                    code: item.code,
                    description: item.description,
                    children: item.children,
                    supplier_added: item.supplier_added,
                    supplier_removed: item.supplier_removed,
                    show_to_online_store: item.show_to_online_store,
                    hide_from_online_store: item.hide_from_online_store,
                };
            });
        }

        this.setState({ loading: true });

        //console.log('Saving', data );

        return api({
            method: 'post',
            url: 'storage/settings',
            data: data,
        }).then((response) => {

            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });

            this.setSettings( response );
            this.setState({ loading: false });

        }).catch(( error ) => {
            errorPopper( error, tr('save_error') );
            this.setState({ loading: false });
        });
    }

    calculateCodeTree( tree = null )
    {
        if( tree === null )
            tree = this.state.codes.slice( 0 );

        tree = mapTree( tree, null, ( item ) => {

            item.invalid = false;
            item.child_invalid = false;
            item.missing_children = false;
            item.code_invalid = false;

            let own = {
                components_count:      parseFloat( item.components_count ),
            };

            // Clone own stuff to total stuff
            let  all = JSON.parse( JSON.stringify( own ) );

            item.children.forEach(( child ) => {
                if( child.calculated )
                    all.components_count += child.calculated.all.components_count;
                if( child.invalid )
                    item.child_invalid = true;
            });

            item.calculated = {
                own: own,
                all: all,
            }

            item.missing_children = false;
            if( item.level < this.state.format.levels.length && item.children.length === 0 )
                item.missing_children = true;

            item.code_invalid = this.codeTreeNodeCodeInvalid( item )

            if( item.child_invalid
                || item.missing_children
                || item.code_invalid
            ){
                item.invalid = true;
            }

            return item;
        });
        this.setState( { codes: tree } );
    }

    codeTreeNodeCodeInvalid( item )
    {
        if( !item.value ) return tr('code_missing');
        if( this.state.format.mode.need_length )
        {
            const level = this.state.format.levels.find( l => l.level === item.level )
            if( level )
            {
                if( item.value.length !== level.length )
                    return tr('code_min_length', [level.length]);
            }
        }
        return false;
    }

    tabChange( selected )
    {
        this.props.history.replace( `/settings/storage/${ selected }` );
        this.setState({ selectedTab: selected });
    }

    formatChange( data )
    {
        let format = { ...this.state.format, ...data };
        this.setState({ format: format });
    }

    formatIsChanged()
    {
        return ( this.state.formatSaved !== JSON.stringify( this.state.format ) );
    }

    renderConfirmFormatChange()
    {
        return <div id="confirmFormatChange" className="ApModalConfirm">
            <ApModal
                show={ this.state.confirmFormatShow }
                handleClose={ () => this.setState({ confirmFormatShow: false }) }
                closeFromBg
                className="narrow"
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('edit_component_code_format_confirm') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <div>{ tr('edit_component_code_format_confirm_info') }</div>
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton className="cancel" onClick={ () => this.setState({ confirmFormatShow: false }) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('no') }
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ this.saveSettings }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('yes') }
                        </ApButton>
                    </div>
                }
            />
        </div>
    }

    renderCount()
    {
        if( this.state.loading )
            return null;

        if( !keyExists( this.state, 'format.mode') )
            return null;

        if( this.state.format.mode.is_free )
        {
            const count = this.state.componentsCount;
            return <ApTooltip position="top" text={ tr('show_in_storage_component_management') } block>
                <div className="withoutCodeContainer" onClick={ () => this.goToComponents( [{ id: 'status_id', value: getComponentStatusId( 'active' ) }]) }>
                    <SvgIcon className="headerSvg" icon="cubes" type="solid" />
                    { tr('storage_component_count') }: <strong>{ count }</strong>
                </div>
            </ApTooltip>
        }
        else
        {
            const count = this.state.componentsWithoutCodeCount;
            return <ApTooltip position="top" text={ tr('show_in_storage_component_management') } block>
                <div className="withoutCodeContainer" onClick={ () => this.goToComponents( [{id: 'code', value: '!'}, { id: 'status_id', value: getComponentStatusId( 'active' ) }]) }>
                    <SvgIcon className="headerSvg" icon="ban" type="solid" />
                    { tr('storage_component_without_code_count') }: <strong>{ count }</strong>
                </div>
            </ApTooltip>
        }
    }

    renderCodeTree()
    {
        if( this.state.loading )
            return null;
        if( !keyExists( this.state, 'format.mode') )
            return null;
        if( this.state.format.mode.is_free )
            return null;

        return <Code
            ref={ ( ref ) => { this.codeTreeRef = ref } }
            loading={ this.state.loading }
            tree={ this.state.codes }

            separator={ ( this.state.format.separator ) ? this.state.format.separator : '' }
            mode={ this.state.format.mode }
            levels={ this.state.format.levels }
            tailLength={ this.state.format.tail_length }
            onTreeChange={ ( tree ) =>  this.calculateCodeTree( tree ) }
            formatChanged={ ( this.formatIsChanged() ) }
            isCodeInvalid={this.codeTreeNodeCodeInvalid}
            supplierEnabled={this.state.extranet_supplier_enabled}
            woocommerce_enabled={this.state.woocommerce_enabled}
        />
    }

    render()
    {
        /*
        */
        const getRelatedSwitch = ( args ) => {
            const sName = `${ args.related }_${ args.type }`;
            return <div className="apSwitchBlock">
                <label htmlFor={`${sName}-switch`} className="info">
                    { args.title }
                    <small> { args.sTitle }</small>
                </label>
                <ApSwitch
                    id={`${sName}-switch`}
                    on={ this.state[ sName ] }
                    onChange={ () => { this.setState({ [ sName ]: !this.state[ sName ]  }) } }
                    disabled={ this.props.loading }
                />
            </div>
        }
        let a4="";
        let col_count=this.state.labels_cols;
        let rows_count=this.state.labels_rows;
        let cols=[...Array(col_count)];
        let rows=[...Array(rows_count)];

        a4=<table border="1px" width="210px" height="297px">
        {rows.map((x, i) =>
        <tr>
            {cols.map((y, j) =>
                <td>&nbsp;</td>
            )}

        </tr>
        )}
        </table>
        
        
        return <div>
            <Measure
                onResize={ () => {
                    if( this.stickyBar )
                        this.stickyBar.updateBounds();
                }}
            >
            {({ measureRef }) =>
                <div className="apBox" id="storageSettings" ref={ measureRef }>
                    <div className="apBoxHeader">
                        <h1>{ tr('storage_settings') }</h1>
                    </div>

                    <ApTabs selected={ this.state.selectedTab } onChange={ this.tabChange } >
                        <ApTab icon="list-ol" label={ tr('component_codes') }>
                            <div className="padding">

                                <CodeFormat
                                    loading={ this.state.loading }
                                    mode={ this.state.format.mode }
                                    modeOptions={ this.state.modeOptions }
                                    levels={ this.state.format.levels }
                                    separator={ this.state.format.separator }
                                    tailLength={ this.state.format.tail_length }
                                    onChange={ ( data ) =>  this.formatChange( data ) }
                                />
                                { this.renderCodeTree() }
                                { this.renderCount() }
                            </div>
                        </ApTab>
                        <ApTab icon={ getTypeIcon( 'identifier' ) } label={ tr('identifiers') }>
                            <div className="padding">
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    <ApTooltip text={ tr('identification_examples') }>
                                        { tr('identifiers_info') }
                                    </ApTooltip>
                                </div>
                                <ComponentRelations
                                    instance="identifiers"
                                    loading={ this.state.loading }
                                    rows={ this.state.identifiers }
                                    onChange={ ( data ) =>  this.setState( data ) }
                                />
                            </div>
                        </ApTab>
                        <ApTab icon={ getTypeIcon( 'calculatable' ) } label={ tr('meters') }>
                            <div className="padding">
                                <ComponentRelations
                                    instance="calculatables"
                                    loading={ this.state.loading }
                                    rows={ this.state.calculatables }
                                    extraCalculatables={ this.state.extraCalculatables }
                                    onChange={ ( data ) =>  this.setState( data ) }
                                />
                            </div>
                        </ApTab>
                        
                        <ApTab icon={ getTypeIcon( 'types' ) } label={ tr('types') }>
                            <div className="padding">
                                <ComponentRelations
                                    instance="types"
                                    loading={ this.state.loading }
                                    rows={ this.state.types }
                                    onChange={ ( data ) =>  this.setState( data ) }
                                />
                            </div>
                        </ApTab>

                        <ApTab icon={ getTypeIcon( 'property' ) } label={ tr('properties') }>
                            <div className="padding">
                                <div className="apInfo small">
                                    <SvgIcon icon="info-circle" type="solid" />
                                    <ApTooltip text={ tr('storage_component_properties_examples') }>
                                        { tr('storage_component_properties_info') }
                                    </ApTooltip>
                                </div>
                                <ComponentRelations
                                    instance="properties"
                                    loading={ this.state.loading }
                                    rows={ this.state.properties }
                                    onChange={ ( data ) =>  this.setState( data ) }
                                />
                            </div>
                        </ApTab>

                        <ApTab icon={ getTypeIcon( 'trackingunit' ) } label={ tr('monitoring_units') }>
                            <div className="padding">
                                
                                <ComponentRelations
                                    instance="trackingunits"
                                    loading={ this.state.loading }
                                    rows={ this.state.trackingunits }
                                    onChange={ ( data ) =>  this.setState( data ) }
                                />

                            </div>
                        </ApTab>

                        <ApTab icon="money-check" label={ tr('wholesalers') }>
                        <div className="padding">
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                    { tr('wholesalers_info') }
                            </div>
                            { getRelatedSwitch({
                                related: 'lvisnet',
                                type: 'enabled',
                                title: tr('lvis_suppliers_active'),
                                sTitle: tr('lvis_suppliers_active_info'),
                            }) }
                          
                            <Collapse in={ this.state.lvisnet_enabled }>
                           
                            <div className="indentleft">
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                    { tr('lvis_identification_based_on') }
                            </div>
                          
                            <ApSelect
	                            label={ tr('attach_ean_code_to_identifier') }
	                            value={ this.state.ean_identifier ? this.state.ean_identifier : null }
	                            optionRenderer={ identifier => 
	                                <div className="identifierOptions">
	                                    <strong>{ identifier.name }</strong>
	                                </div>
	                            }
	
	                            onChange={ ( c ) => this.setIdentifier( c ? c : null ) }
	                            objKeyId="id"
	                            objKeyValue="name"
	                            clearable
	                            apiUrl="storage/wholesale/identifiers"
	                            apiData={{
	                            }}
	                        />
                            </div>
	                        </Collapse>
                           
                           </div>
                    </ApTab>

                    <ApTab icon="print" label={ tr('shelf_stickers') }>
                        <div className="padding">
                            <div className="apInfo small">
                                <SvgIcon icon="info-circle" type="solid" />
                                    { tr('shelf_stickers_info') }
                            </div>

                            <div className="indentleft">
                            <ApSelect
	                            label={ tr('select_ean_code_to_stickers') }
	                            value={ this.state.labels_ean_identifier ? this.state.labels_ean_identifier : null }
	                            optionRenderer={ identifier => 
	                                <div className="identifierOptions">
	                                    <strong>{ identifier.name }</strong>
	                                </div>
	                            }
	
	                            onChange={ ( c ) => this.setLabelIdentifier( c ? c : null ) }
	                            objKeyId="id"
	                            objKeyValue="name"
	                            clearable
	                            apiUrl="storage/wholesale/identifiers"
                                id="label_ean"
                                name="label_ean"
	                        />
                            </div>
                            <div>
                            
                            <ApInputStack gap="0" timetrackingMobileResponsive={true}>
                            <ApAddon width="250"> { tr('grid_of_sticker_paper') } </ApAddon>   
                            <ApAddon noLeftBorder width="50"> { tr('horizontal') } </ApAddon>    
                                <ApInput 
                                        width="100"
                                        type="number" 
                                        id= "col_count" 
                                        name="col_count"
                                        value={ this.state.labels_cols }
                                        align="right"
                                        onChange={ ( e ) => this.changeA4RowCol( e.target.value,  "col_count") }

                                    /> 
                             <ApAddon noLeftBorder width="50"> X </ApAddon>    
                             <ApAddon noLeftBorder width="50"> { tr('vertical') } </ApAddon>    
                                <ApInput 
                                    width="100"
                                    type="number" 
                                    id="row_count" 
                                    name="row_count" 
                                    value={ this.state.labels_rows }
                                    align="right"
                                    onChange={ ( e ) => this.changeA4RowCol(  e.target.value,  "row_count" ) }
                                />
                                <ApAddon width="50">
                                { `${tr('paper')} A4` }             
                                {a4}
                                <br/>
                            </ApAddon>
                            </ApInputStack>


                            </div>
                            </div>
                     </ApTab>
                    </ApTabs>

                    <ApStickyBar bottomMode zIndex={10} ref={ node => this.stickyBar= node }>
                        <div className="saveBar">
                            <div className="left">
                            </div>
                            <div className="right">
                                <ApButton color="blue"
                                    onClick={ this.saveClicked }
                                    loading={ this.state.loading }
                                    disabled={ this.state.loading }
                                >
                                    <SvgIcon icon="save" type="solid" />
                                    { tr('save') }
                                </ApButton>
                            </div>
                        </div>
                    </ApStickyBar>
                </div>
            }
            </Measure>
            { this.renderConfirmFormatChange() }
        </div>;
    }
}

const mapStateToProps = state => ({
    apStorageSettings: state.apStorageSettings
});

const mapDispatchToProps = dispatch => ({
    saveStorageSettings: (settings) => dispatch({type: 'SAVE_STORAGE_SETTINGS', payload: settings})
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageSettings);

