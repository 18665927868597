/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import { keyExists, roundToDecimals, tr } from  'services/Helpers/Helpers.js';
import { entryIcon, statusText } from  'modules/Timetracking/TimetrackingHelpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApPath from 'common/ApPath/ApPath.js';
import moment from 'moment';
import { index } from 'd3';


export default class Day extends React.Component {

    constructor(props) 
    {
        super(props);
        autoBind(this); 
    }

    // componentDidMount()
    // {

    //     console.log(this.props.data);

    // }

    renderProjectTooltip( project, assignment )
    {
        let mainProject = project;
        let projectPath = [];
        if( project.ancestors.length > 0 )
        {
            mainProject = project.ancestors[0];
            project.ancestors.forEach( a => {
                projectPath.push( a.name );
            })
        }
        projectPath.push( project.name );

        return (
            <div>
                {assignment ? <div><strong>{ tr('assignment') }: </strong>{assignment.name}</div>: null}
                <ApPath items={ projectPath } />
                { keyExists( mainProject, "customer.name" ) && 
                    <React.Fragment>
                        <div><strong>{ mainProject.customer.name }</strong></div>
                        <div>{ `${tr('project')} / ${tr('customer_work_number')}` }</div>
                    </React.Fragment>
                }
            </div>
        );
    }

    renderDescriptionToolip( entry )
    {
        const workName = keyExists( entry, "detail.projectwork.name", true, null );
        return workName;
      
    }

    renderDescriptionLongToolip( entry )
    {    
        const description = keyExists( entry, "detail.description_long", true, null );
        return description;
    }
    renderApprovedTooltipContent( entry )
    {
        if (entry.status === 'rejected' && entry.approver_comment)
        {
            return (
                <div>                   
                    {entry.checked && <>{ tr('rejector') }: <strong>{ entry.checked ? entry.approver_user_person_detail.full_name : '' }</strong></>}
                    <div>{ entry.approver_comment ? `${tr('comment')}: ${entry.approver_comment}` : '' }</div> 
                </div>
            )
        } 
        else if (entry.status === 'confirmed' && entry.confirmor)
        {
            return (
                <div>
                    { tr('confirmor') }: <strong>{ entry.confirmor.name }</strong>
                </div> 
            )
        }
        else if (entry.status === 'approved' && entry.approver)
        {
            if (entry.approvers) {
                return (
                    <div>
                        {entry.approvers.length > 1 ? tr('approvers') : tr('approver')}: {entry.approvers.length > 1 && <br/>}
                        {entry.approvers.map((approver, index) => {
                            return <strong key={index}>{keyExists(approver, 'person_detail.full_name', true, '')}<br/></strong>
                        })}
                    </div>
                );
            }
            if (entry.approver_user_person_detail ) {
                return (
                    <div>
                        {/* { tr('approver') }: <strong>{ entry.approver.name }</strong> */}
                   

                        
                    {  entry.approver_user_person_detail.full_name && tr('approver') }: <strong>{ entry.approver_user_person_detail.full_name }</strong>
                </div> 
                )
                }
        } 
        else if (entry.status === 'submitted' && entry.approvers)  {
            return (
                <div>               
                    
                {typeof entry.approver != 'undefined' && entry.approvers.length > 1 ? tr('approvers') : tr('approver')}: {entry.approvers.length > 1 && <br/>}
                {entry.approvers.map((approver, index) => {
                    return <strong key={index}>{keyExists(approver, 'person_detail.full_name', true, '') } 
                     {approver.approved==true ? " " + moment(approver.update_at).format('DD.MM.YYYY') :""} {approver.approved==true ? <SvgIcon icon="check" type="solid" /> : "" }   <br/></strong>
                    
                })}
            </div>
            ) 
        }
      
        else
        {
            return null;
        }
        
    }
    getSetting(path, column, id, idColumn = "id") {
        if (!this.props.settings)
            return "";

        const item = keyExists(this.props.settings, path, true, []).find(s => s[idColumn] == id);
        if (!item)
            return "";

        if (!column)
            return item;

        if (column in item)
            return item[column];

        return "";
    }

    render() 
    {
        const entry = this.props.data;

        let classes = [ "entry" ];
        let status = entry.status;
        let comment = entry.approver_comment;
        let statusIcon = "question";
        const hasProject = keyExists( entry, "project.name" );

        //console.log(entry.customer_work_number);

        let description = entry.description;

        if( entry.type === "absence" && keyExists( entry, "detail.absence_id" ) )
        {
            const absence = keyExists( this.props, "settings.absences", true, [] ).find( a => a.id == entry.detail.absence_id );
            if( absence.name )
                description = description ? ( absence.name + ": " + description ) : absence.name;
        }
        else if (entry.type === "hour") {
            description = [<strong key={1}>{this.renderDescriptionToolip(entry)+(description?": ":"")}</strong> ,description];
        }
        else if (entry.type === "travel") {
            if (entry.detail) {
                
                let vehicle = this.getSetting("vehicles", "name", entry.detail.vehicle_id);
                description = description ? description : (<strong>{vehicle}</strong>);
                //description = [<strong>{vehicle + (description ? ": " : "")}</strong>, description];
            }
        }

        if( this.props.changes && !entry.readOnly ) 
        {
            status = this.props.changes.status;
            comment = this.props.changes.approver_comment;

            if( this.props.changes.status == this.props.statuses[0] )
                statusIcon = "question";

            else if( this.props.changes.status == "approved" )
                statusIcon = "check";

            else if( this.props.changes.status == "confirmed" )
                statusIcon = "check-circle";
            
            else if ( this.props.changes.status === "rejected" )
                statusIcon = "times";

            if( !this.props.changes.approver_comment )
                classes.push( "comment-missing" );
        }

        if( entry.is_misentry )
            classes.push( "misentry" );

        if( this.props.selected )
            classes.push( "selected" );

        if( entry.readOnly )
            classes.push( "readOnly" );

        if( hasProject )
            classes.push( "hasProject" );

        classes.push( status );
        //console.log( entry.timer_save_points);
        const round = (value) => {
            return Math.round(value * 1000) / (1000);
        };

        const warningStyles = {
            color: 'var(--clr-warning-main)', 
            fontSize: '12px', 
            // paddingLeft: '50px',
            position: 'absolute',
            top: '50%',
            // left: '-75px',
            transform: 'translateY(-50%)',
            zIndex: '10',
        }

        const inBillingWarning = entry.billing_entry_added
            ? <div style={{...warningStyles, left: '-25px'}}>
                <ApTooltip text={tr('in_billing')} position="topleft">
                    <SvgIcon className="size-small" type="solid" icon="exclamation-triangle" />
                </ApTooltip>
            </div>
            : null;

        let entryTooltip = null;
        let distanceWarning = null;
        if (entry.timer_in && entry.timer_out) {
            if (entry.timer_in?.distance_to_project_metres > entry.timer_in?.project_site_max_distance) {
                distanceWarning = <div style={{...warningStyles, left: '-50px'}}>
                    <ApTooltip
                        text={tr('distance_to_site_warning',
                            [(entry.timer_in.distance_to_project_metres - entry.timer_in.project_site_max_distance).toFixed(2)])}
                        position='topleft'
                    >
                        <SvgIcon className="size-small" type="solid" icon="arrows-alt-h" />
                    </ApTooltip>
                </div>;
            }
            entryTooltip = <div>
                {tr(entry.timer_in.type)}: {moment(entry.timer_in.created_at).format("HH:mm")}{entry.timer_in.address ? `, ${entry.timer_in.address}` : null}<br />
                {tr(entry.timer_out.type)}: {moment(entry.timer_out.created_at).format("HH:mm")}{entry.timer_out.address ? `, ${entry.timer_out.address}` : null}<br />
                {/* {tr("total_time")}: {roundToDecimals(moment.duration(moment(entry.timer_out.created_at).diff(moment(entry.timer_in.created_at))).asHours(), 2)} h */}
            </div>;
        } else if (entry.timer_save_points && entry.timer_save_points.length > 0) {
            entryTooltip = entry.timer_save_points.map((item, index) => {
                return <div key={index}>{tr(item.type)}: {moment(item.created_at).format("HH:mm")}{item.address ? `, ${item.address}` : null}</div>
            });
        }
        

        return (
            <React.Fragment>
                <div className={ classes.join(" ") } key={ entry.id }>

                    {distanceWarning}
                    {inBillingWarning}
                    
                    <SvgIcon className="icon" icon={ entry.assignment_id ? 'tasks' : entryIcon( entry.type ) } type="solid" />
                    
                    { !entry.readOnly && 
                        <input 
                            type="checkbox" 
                            checked={ this.props.selected }
                            readOnly
                        />
                    }                    
                  
                    <div className="description" onClick={ () => { if( !entry.readOnly ) this.props.onEntrySelect( entry.id ); } }>    
                      
                            {   description  || <span>&nbsp;</span> }

                            { entry.detail.description_long &&
                                <ApTooltip text = { entry.detail.description_long }  position="top" multiline="true">   
                                  
                                    {'  ' }{  <div className="apInfo small" >
                                        <SvgIcon icon="info-circle" type="solid" />                                    
                                    </div> }   
                                </ApTooltip>
                            }
                          
                        {entry.time_attendance_save_point != undefined &&
                            <ApTooltip text={null} block >                               

                              
                           
                                {tr("time_attendance")}: {(entry.timeAttendanceTimestampStart ? entry.timeAttendanceTimestampStart.format("HH:mm") : "") + " - " +
                                    (entry.timeAttendanceTimestampStop ? entry.timeAttendanceTimestampStop.format("HH:mm") : "")}

                            </ApTooltip>
                        }

                        {entryTooltip &&
                        <ApTooltip text={ entryTooltip } block>
                                {"-" + tr("additional_information")+"-"}
                        </ApTooltip>
                        }
                        
                    </div>

                    { hasProject &&
                        <div className="project">
                            <ApTooltip text={ this.renderProjectTooltip( entry.project, entry.assignment ) } block>
                                <SvgIcon icon="folder" type="solid" />
                                <span className="code">{ entry.project.project_code }</span>
                                <span className="code">{ entry.customer_work_number }</span>
                            </ApTooltip>
                        </div>
                    }

                    { this.props.renderOverview( entry ) }
                    
                    <div className={ "status " + status } onClick={ () => !entry.readOnly ? this.props.onToggleStatus( entry.id ) : false }>
                       
                            <ApTooltip 
                                position="topright" 
                                text={ 
                                    this.renderApprovedTooltipContent( entry )
                                }                           
                            >
                            <SvgIcon icon={ statusIcon } type="solid" />      
                            { status == this.props.statuses[0] ? tr('waiting') : statusText( status ) }    
                            </ApTooltip>
                      

                      
                    </div>
                    
                    { !entry.readOnly &&
                        <div className="comment" onClick={ () => this.props.onEditComment( entry.id, comment ) }>   
                           <ApTooltip position="topright" text={ status === "rejected" ? comment || tr('add_comment') : false }>
                                <SvgIcon icon={ comment ? "comment-dots" : "comment" } type="solid" />     
                                  
                            </ApTooltip>                                                 
                            {/* <ApTooltip 
                                position="topright" 
                                text={ 
                                    this.renderApprovedTooltipContent( entry )
                                }                           
                            >
                                <SvgIcon icon={ statusIcon } type="solid" />      
                            </ApTooltip> */}
                        </div>
                    }

                    { entry.readOnly &&
                        <div className="comment">
                            <ApTooltip 
                                position="topright" 
                                text={ 
                                    this.renderApprovedTooltipContent( entry )
                                }
                            >
                                <SvgIcon icon="lock" type="solid" />
                            </ApTooltip>
                        </div>
                    }

                </div> 
                { !this.props.showReadOnly && entry.readOnly && !this.props.noOwnEntries &&
                    <div className="readOnlyPlaceholder" onClick={ () => this.props.onToggleReadOnly( true ) }></div> 
                }
            </React.Fragment>
        );
    }
}
