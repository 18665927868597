import React from 'react';
import autoBind from 'react-autobind';

import ApModal from 'common/ApModal/ApModal.js';
// import ApFileUpload from 'common/ApFileUpload/ApFileUpload.js';
import FileUpload from 'common/FileUpload/FileUpload.js';
import ApButton from 'common/ApButton/ApButton.js';
import { tr } from 'services/Helpers/Helpers.js';
// import api from 'services/Api/Api.js';
// import ApModalInput from 'common/ApModalInput/ApModalInput.js';

import {
	ApInputStack, ApAddon 
} from 'common/ApInput/ApInput.js';

// import File from 'common/File/File.js';
// import SvgIcon from 'common/SvgIcon/SvgIcon.js';
// import ApTooltip from 'common/ApTooltip/ApTooltip.js';

export default class Files extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			files: [],
			filesSaved: [],
			manualFile: null,
			loading: false,
			showfiletypeError:false,
		}

		autoBind(this);

	}

	fileClear()
	{

	}

	fileUpload(newFile)
	{
		//console.log(newFile);

		let files = this.state.files.slice(0);
		files.push(newFile);
		this.setState({ files: files });
		this.props.onUpload(newFile);

	}

	clickCancelButton()
	{

		this.props.onClose();

	}

	renderHeader()
	{

		const header =
			<div className="padding-small">
				<h4>{ tr('permit_file') }</h4>
			</div>;

		return header;

	}

	renderBody()
	{

		const body = 
			<div className="padding">
				<div className="fileSelectContainer">
					{this.state.showfiletypeError &&
						<h5 style={{ color: "red" }}>{tr("wrong_image_file_type")}</h5>}
					<ApInputStack gap="0">
						<ApAddon width="200px" noRightBorder>
							{ tr('new_file') }
						</ApAddon>
						<FileUpload
							onUpload={this.fileUpload}
							description="PermitSendFile"
							label={`${tr('choose_image_file')}...` }
							value={this.state.manualFile}
							onClear={this.fileClear}
							loading={this.state.loading}
							disabled={this.state.loading}
							accept="image/*"
							acceptedFiletypes={["jpg", "jpeg", "png", "svg", "webp"]}
							filetypeError={() => { this.setState({ showfiletypeError: true });}}
						/>
					</ApInputStack>
				</div>
			</div>;

		return body;

	}

	renderFooter()
	{

		const footer = 
			<div style={{textAlign: "right", padding: "12px"}}>
				<ApButton color="red" onClick={() => {this.clickCancelButton()}}>
					{ tr('cancel') }
				</ApButton>
			</div>;


		return footer;

	}

	render()
	{

		const header = this.renderHeader();
		const body = this.renderBody();
		const footer = this.renderFooter();

		return(

			<ApModal
				className="narrow"
				show={this.props.show}
				handleClose={this.onCloseConfirm}
				header={header}
				body={body}
				footer={footer}
				hideCross={false}
			/>

		);

	}

}
