/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import { Collapse } from 'react-bootstrap';
import { debounce } from 'throttle-debounce';

import auth               from 'services/Authed/Authed.js';
import api                from 'services/Api/Api.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import { validateEmail,
         randomPassword,
         tr, 
         keyExists,
         errorPopper} from 'services/Helpers/Helpers.js'

import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApSwitch           from 'common/ApSwitch/ApSwitch.js';
import ApSelect           from 'common/ApSelect/ApSelect.js';
import { ApInput,
         ApInputStack,
         ApAddon }        from 'common/ApInput/ApInput.js';

import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';


import './NewUser.css';
import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect';

class NewUser extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            // company: null,
        }

        this.validator = new ApValidate( this, {
            "selectedType": { filter: 'required', state: 'error', text: tr('user_type_error') },
            "data.first_name":      { filter: 'required', state: 'error', text: tr('first_name_required')},
            "data.last_name":       { filter: 'required', state: 'error', text: tr('last_name_required')},
            "data.email":           { filter: 'email', state: 'error', text: tr('incorrect_info')},
            "data.contract_start":  { filter: 'required', state: 'error', text: tr('incorrect_info')},
            "data.phone": {
                filter: value => {
                    if (this.state.settings && !this.state.settings.isNetvisorPayroll) return true;
                    if (keyExists(this.state, 'settings.netvisor_salary_models', true, []).length <= 1) return true;
                    if (keyExists(this.state, 'settings.isNetvisorPayroll', true, false) && keyExists(this.state, 'data.personnelGroup.no_payroll', true, false))
                        return true;
                    return value ? true : false;
                }, 
                state: 'warning', 
                text: tr('mandatory'),
                optional: true,
            },
            "data.nin": {
                filter: value => {
                    if (this.state.settings && !this.state.settings.isNetvisorPayroll) return true;
                    if (keyExists(this.state, 'settings.netvisor_salary_models', true, []).length <= 1) return true;
                    if (keyExists(this.state, 'settings.isNetvisorPayroll', true, false) && keyExists(this.state, 'data.personnelGroup.no_payroll', true, false))
                        return true;
                    return value ? true : false;
                }, 
                state: 'warning', 
                text: tr('mandatory'),
                optional: true,
            },
            "data.street": {
                filter: value => {
                    if (this.state.settings && !this.state.settings.isNetvisorPayroll) return true;
                    if (keyExists(this.state, 'settings.netvisor_salary_models', true, []).length <= 1) return true;
                    if (keyExists(this.state, 'settings.isNetvisorPayroll', true, false) && keyExists(this.state, 'data.personnelGroup.no_payroll', true, false))
                        return true;
                    return value ? true : false;
                }, 
                state: 'warning', 
                text: tr('mandatory'),
                optional: true,
            },
            "data.postal_code": {
                filter: value => {
                    if (this.state.settings && !this.state.settings.isNetvisorPayroll) return true;
                    if (keyExists(this.state, 'settings.netvisor_salary_models', true, []).length <= 1) return true;
                    if (keyExists(this.state, 'settings.isNetvisorPayroll', true, false) && keyExists(this.state, 'data.personnelGroup.no_payroll', true, false))
                        return true;
                    return value ? true : false;
                }, 
                state: 'warning', 
                text: tr('mandatory'),
                optional: true,
            },
            "data.city": {
                filter: value => {
                    if (this.state.settings && !this.state.settings.isNetvisorPayroll) return true;
                    if (keyExists(this.state, 'settings.netvisor_salary_models', true, []).length <= 1) return true;
                    if (keyExists(this.state, 'settings.isNetvisorPayroll', true, false) && keyExists(this.state, 'data.personnelGroup.no_payroll', true, false))
                        return true;
                    return value ? true : false;
                }, 
                state: 'warning', 
                text: tr('mandatory'),
                optional: true,
            },
            "data.netvisor_salary_model_id": {
                filter: value => {
                    if (this.state.settings && !this.state.settings.isNetvisorPayroll) return true;
                    if (keyExists(this.state, 'settings.netvisor_salary_models', true, []).length <= 1) return true;
                    if (keyExists(this.state, 'settings.isNetvisorPayroll', true, false) && keyExists(this.state, 'data.personnelGroup.no_payroll', true, false))
                        return true;
                    return value ? true : false;
                }, 
                state: 'warning', 
                text: tr('mandatory'),
                optional: true,
            },
            // "data.netvisor_advance_debt_account": {
            //     filter: value => {
            //         if (this.state.settings && !this.state.settings.isNetvisorPayroll) return true;
            //         if (keyExists(this.state, 'settings.netvisor_salary_models', true, []).length <= 1) return true;
            //         if (keyExists(this.state, 'settings.isNetvisorPayroll', true, false) && keyExists(this.state, 'data.personnelGroup.no_payroll', true, false))
            //             return true;
            //         return value ? true : false;
            //     }, 
            //     state: 'warning', 
            //     text: tr('mandatory')
            // },
            "data.netvisor_payslip_delivery_method": {
                filter: value => {
                    if (this.state.settings && !this.state.settings.isNetvisorPayroll) return true;
                    if (keyExists(this.state, 'settings.netvisor_salary_models', true, []).length <= 1) return true;
                    if (keyExists(this.state, 'settings.isNetvisorPayroll', true, false) && keyExists(this.state, 'data.personnelGroup.no_payroll', true, false))
                        return true;
                    return value ? true : false;
                }, 
                state: 'warning', 
                text: tr('mandatory'),
                optional: true,
            },
            "data.job_description": {
                filter: value => {
                    if (this.state.settings && !this.state.settings.isNetvisorPayroll) return true;
                    if (keyExists(this.state, 'settings.netvisor_salary_models', true, []).length <= 1) return true;
                    if (keyExists(this.state, 'settings.isNetvisorPayroll', true, false) && keyExists(this.state, 'data.personnelGroup.no_payroll', true, false))
                        return true;
                    return value ? true : false;
                }, 
                state: 'warning', 
                text: tr('mandatory'),
                optional: true,
            },
            "data.collectiveAgreement": {
                filter: value => {
                    if (this.state.settings && this.state.settings.collective_agreement_required) {
                        if (this.state.data.personnelGroup && this.state.data.personnelGroup.no_payroll) {
                            return true
                        }
                        return !!value.name;
                    }
                    return true;
                }, 
                state: 'error', 
                text: tr('mandatory')
            },
            "data.personnelGroup": {
                filter: value => {
                    if (this.state.settings && this.state.settings.personnel_group_required) {
                        return !!value.name;
                    }
                    return true;
                }, 
                state: 'error', 
                text: tr('mandatory')
            },
            "data.password": [
                {
                    filter: 'required',
                    state: 'error',
                    text: tr('password_empty')
                },
                {
                    filter: ( value ) => {
                        return ( value.length < 8 ? false : true );
                    },
                    state: 'warning',
                    text: tr('user_password_note')
                },
            ],
        });

        const oneIsInvalid = ( test ) => {
            let text = null;
            const invalid = test.some( field  => {
                text = this.validator.getText( field )
                return text;
            });
            if( invalid ) return text;
            return null;
        }

        this.steps = [{
            name: tr('type'),
            render: this.renderStepType.bind( this ),
            invalid:  () => {
                return this.validator.getText( 'selectedType' );
            }
        },{
            name: tr('basics'),
            render: this.renderStepUser.bind( this ),
            invalid:  () => {
                return oneIsInvalid([
                    'data.first_name',
                    'data.last_name',
                    // 'data.nin',
                    'data.email',
                    // 'data.phone',
                    // 'data.street',
                    // 'data.postal_code',
                    // 'data.city',
                ]);
            }
        },{
            name: tr('employment_relationship'),
            render: this.renderStepJob.bind( this ),
            invalid:  () => {
                return oneIsInvalid([
                    'data.contract_start',
                    // 'data.netvisor_salary_model_id',
                    // 'data.netvisor_advance_debt_account',
                    // 'data.netvisor_payslip_delivery_method',
                ]);
            }
        },{
            name: tr('user_account'),
            render: this.renderStepAccount.bind( this ),
            invalid:  () => {
                const passwordError = this.validator.getText( 'data.password' );
                if( passwordError ) return passwordError;
                if( this.state.usernameLoading ) return tr('username_being_checked');
                if( this.state.usernameError ) return this.state.usernameError;
                return null;
            }
        }];

        this.validateUsernameDebounced = debounce( 500, this.validateUsername );

        autoBind(this);
    }

    componentDidMount()
    {
        this.resetState();
        this.getSettings();
    }

    resetState()
    {
        this.setState({

            selectedType: null,

            loading: false,
            data: {
                companyId: this.props.companyId,
                first_name: '',
                last_name: '',
                nin: '',
                email: '',
                phone: '',
                phone_public: false,
                job_description: '',
                collectivebargain: '',
                personnelGroup: {},
                collectiveAgreement: {},
                contract_start: null,
                contract_end: null,
                supervisors: [],
                username: '',
                password: '',
                is_active: false,
                send_email_to_user: false,
                organization_position: null,
                organization_position_id: null,
            },
            usernameLoading: false,
            usernameError: "",
            showPassword: false,
            settings: this.state.settings,
        }, this.getCompanySettings );
    }

    getCompanySettings()
    {
        // Skip this if company ID have not been changed
        if( this.state.company && this.state.company.id == this.props.companyId )
            return false;

        // No need for loader because company suffix is not showing until the last step.
        // User can start filling the form while this is still loading.
        api({
            method: 'get',
            url: 'usermanagement/company/' + this.props.companyId,
        }).then(( response ) => {
            this.setState({ company: response });
        }).catch((error) => {
            console.log('Yrityksen tietoja haku epäonnistui! ', error);
        });
    }

    getSettings() {
        api({
            method: 'get',
            url: 'usermanagement/getSettings',
        }).then(( response ) => {
            this.setState({settings: response});
        }).catch((error) => {
            console.error(error);
        });
    }

    save()
    {
        let data = this.state.data;
        data.is_limited = ( this.state.selectedType.name === 'limited' );
        data.is_admin = ( this.state.selectedType.name === 'admin' );

        this.setState({
            loading: true,
        });
        api({
            method: 'post',
            url: 'usermanagement/user/add',
            data: data,
            errorPopper: tr('save_error'),
        }).then(( response ) => {
            // console.log("RES", response);

            if( typeof( this.props.onSave ) == "function" )
                this.props.onSave( response['newId']['user'] );

            if (keyExists(response, "netvisorResponse.ResponseStatus.Status") && response.netvisorResponse.ResponseStatus.Status[0] == 'FAILED') {
                console.error(response.netvisorResponse.ResponseStatus.Status);
                window.emitter.emit('popper', {
                    type: 'info',
                    content: <strong>Virhe henkilön viennissä Netvisoriin: {response.netvisorResponse.ResponseStatus.Status[1]}</strong>, // TODO
                    time: 8000
                });
            }

            if (Array.isArray(response.newId.errors)) {
                response.newId.errors.forEach(error => {
                    // TODO: change errorPopper message if more errors added than email send error
                    errorPopper(null, tr('send_email_error'))
                });
            }

            this.props.onClose();

        }).catch((error) => {
            this.setState({ loading: false });
        });

    }

    validateUsername()
    {
        const data = {
            username: this.state.data.username,
            suffix: this.state.company ? this.state.company.suffix : "",
        };
        this.setState({ usernameLoading: true });
        api({
            method: 'post',
            url: 'usermanagement/validate/username',
            data: data
        }).then(( errorMsg ) => {
            this.setState({
                usernameError: errorMsg,
                usernameLoading: false,
            });
        }).catch((error) => {
            console.log('Käyttäjätunnuksen validointi epäonnistui ', error);
            this.setState({
                usernameError: "",
                usernameLoading: false,
            });
        });
    }

    handleChange( e )
    {
        let data = { ...this.state.data };
        const value = e.target.value;
        const name = e.target.name;

        switch( name )
        {
            // Automatically generate username from name fields
            case "first_name":
            case "last_name":
                data[ name ] = value;
                data[ 'username' ] = ( data[ 'first_name' ] + "." + data['last_name'] ).toLowerCase().replace(" ", ".");
                break;

            default:
                data[ name ] = value;
                break;
        }

        this.setState({ data }, () => {

            if( name == "username" )
            {
                this.setState({ usernameLoading: true });
                this.validateUsernameDebounced();
            }
        });
    }

    handleOrganizationChart(position) {
        let data = { ...this.state.data };
        data['organization_position'] = position ? position : null;
        data['organization_position_id'] = position ? position.id : null;

        this.setState({ data });
    }

    handelSwitchChange( name )
    {
        let data = { ...this.state.data };
        data[ name ] = !data[ name ];
        this.setState({ data });
    }

    handleSupervisorChange( newSups )
    {
        let data = { ...this.state.data };
        data[ 'supervisors' ] = newSups;
        this.setState({ data });
    }

    handleSelect(el, type) {
        let data = {...this.state.data};
        data[type] = el;
        this.setState({data});
    }

    randomizePassword()
    {
        let data = { ...this.state.data };
        data[ 'password' ] = randomPassword();
        this.setState({
            data: data,
            showPassword: true
        });
    }

    renderStepType()
    {
        let types = [
            {
                icon: 'user-clock',
                name: 'limited',
                title: tr('basic_user'),
                description: tr('basic_user_info'),
            },{
                icon: 'user',
                name: 'normal',
                title: tr('official'),
                description: tr('official_info'),
            }
        ];
        
        if( auth.isAdmin() )
        {
            types.push({
                    icon: 'user-tie',
                    name: 'admin',
                    title: tr('administrator'),
                    description: tr('administrator_info'),
            });
        }
        const selectedType = this.state.selectedType


        const typeDoms = types.map( type => {
            const optionClass = ['option'];
            if( selectedType && selectedType.name === type.name )
                optionClass.push( 'selected' );

            return <div key={ type.name } className={ optionClass.join(' ') } onClick={ () => { this.setState({ selectedType: type })}}>
                <div className="iconContainer">
                    <SvgIcon icon={ type.icon } type="solid" />
                </div>
                <div className="textContainer">
                    <div className="header">
                        { type.title }
                    </div>
                    <div className="text">
                        { type.description }
                    </div>
                </div>
            </div>
        });

        return <div className="stepType">
            { typeDoms }
        </div>
    }

    renderStepUser()
    {
        return <div className="stepUser">
            <strong>{ tr('personal_information') }</strong>
            <ApInputStack>
                <ApInput
                    type="text"
                    id="newUser_first_name"
                    name="first_name"
                    label={ tr('first_name') }
                    value={ this.state.data.first_name }
                    onChange={ this.handleChange }
                    validationState={ this.validator.getState('data.first_name') }
                    tooltip={ this.validator.getText('data.first_name') }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
                <ApInput
                    type="text"
                    id="newUser_last_name"
                    name="last_name"
                    label={ tr('last_name') }
                    value={ this.state.data.last_name }
                    onChange={ this.handleChange }
                    validationState={ this.validator.getState('data.last_name') }
                    tooltip={ this.validator.getText('data.last_name') }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </ApInputStack>

            <ApInput
                type="text"
                id="newUser_nin"
                name="nin"
                label={ tr('nin') }
                validationState={ this.validator.getState('data.nin') }
                tooltip={ this.validator.getText('data.nin') }
                value={ this.state.data.nin }
                onChange={ this.handleChange }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />

            <br />

            <strong>{tr('contact_details')}</strong>
            <ApInput
                type="text"
                id="street"
                name="street"
                label={ tr('street_address') }
                value={ keyExists( this.state.data, "street", true ) }
                onChange={ this.handleChange }
                loading={ this.state.loading }
                disabled={ this.state.loading }
                tooltip={ this.validator.getText( "data.street" ) }
                validationState={ this.validator.getState( "data.street" ) }
            />

            <ApInputStack>
                <ApInput
                    width="140px"
                    type="tel"
                    id="postal_code"
                    name="postal_code"
                    label={ tr('postal_code') }
                    maxLength="5"
                    value={ keyExists( this.state.data, "postal_code", true ) }
                    onChange={ this.handleChange }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    tooltip={ this.validator.getText( "data.postal_code" ) }
                    validationState={ this.validator.getState( "data.postal_code" ) }
                />

                <ApInput
                    type="text"
                    id="city"
                    name="city"
                    label={ tr('locality') }
                    value={ keyExists( this.state.data, "city", true ) }
                    onChange={ this.handleChange }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    tooltip={ this.validator.getText( "data.city" ) }
                    validationState={ this.validator.getState( "data.city" ) }
                />

                <ApInput
                    type="text"
                    id="country"
                    name="country"
                    label={ tr('country') }
                    value={ keyExists( this.state.data, "country", true ) }
                    onChange={ this.handleChange }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </ApInputStack>
            <ApInput
                type="email"
                id="newUser_email"
                name="email"
                label={ tr('email') }
                value={ this.state.data.email }
                onChange={ this.handleChange }
                validationState={ this.validator.getState('data.email') }
                tooltip={ this.validator.getText('data.email') }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />

            <ApInputStack>
                <ApInput
                    type="tel"
                    id="newUser_phone"
                    name="phone"
                    label={ tr('phone') }
                    value={ this.state.data.phone }
                    onChange={ this.handleChange }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                    validationState={ this.validator.getState('data.phone') }
                    tooltip={ this.validator.getText('data.phone') }
                />
                <ApAddon width="150px" labelFor="newUser_public-phone-switch">
                    <ApSwitch
                        small
                        inline
                        id="newUser_public-phone-switch"
                        on={ this.state.data.phone_public }
                        onChange={ () => this.handelSwitchChange('phone_public') }
                        disabled={ this.state.loading }
                    />
                    <span>{ tr('public') }</span>
                </ApAddon>
            </ApInputStack>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { this.state.data.phone_public
                    ? tr('phone_public_note')
                    : tr('phone_private_note')
                }
            </div>
        </div>
    }

    renderStepJob()
    {
        return <div className="stepUser">
            <strong>{ tr('employment_relationship') }:</strong>

            <ApInput
                type="text"
                id="newUser_job_description"
                name="job_description"
                label={ tr('job_description') }
                value={ this.state.data.job_description }
                onChange={ this.handleChange }
                loading={ this.state.loading }
                disabled={ this.state.loading }
                tooltip={ this.validator.getText( "data.job_description" ) }
                validationState={ this.validator.getState( "data.job_description" ) }
            />
            {this.state.settings && this.state.settings.use_personnel_groups_list &&
            <ApSelect
                label={tr('personnel_group')}
                id="personnelGroup"
                name="personnelGroup"
                value={this.state.data.personnelGroup.name ? this.state.data.personnelGroup : '' }
                optionRenderer={item => <div>{item.name}</div>}
                objKeyId="id"
                objKeyValue="name"
                onChange={(e) => this.handleSelect(e, 'personnelGroup')}
                validationState={this.validator.getState("data.personnelGroup")}
                apiUrl='usermanagement/personnelGroups'
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            }
            { this.state.settings && !this.state.settings.use_collective_agreement_list ?
            <ApInput
                type="text"
                id="newUser_collectivebargain"
                name="collectivebargain"
                label={ tr('collective_agreement') }
                value={ this.state.data.collectivebargain }
                onChange={ this.handleChange }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            /> :
            <ApSelect
                label={tr('collective_agreement')}
                id="collectiveAgreement"
                name="collectiveAgreement"
                value={this.state.data.collectiveAgreement.name ? this.state.data.collectiveAgreement : '' }
                optionRenderer={item => <div>{item.name}</div>}
                objKeyId="id"
                objKeyValue="name"
                onChange={(e) => this.handleSelect(e, 'collectiveAgreement')}
                validationState={this.validator.getState("data.collectiveAgreement")}
                apiUrl='usermanagement/collectiveAgreements'
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            }
            {this.state.settings.organization_chart_enabled
            && <ApNestedSelect
                label={<span>{tr('organization')}</span>}
                valueRenderer={(item) => item.nameTree}
                value={this.state.data.organization_position}
                parentRenderer="value"
                parentTooltipRenderer="name"
                optionRenderer={(item) => {
                    return <div className="codeOption">
                        <span className="name">{item.nameTree}</span>
                        <span className="code">{item.code}</span>
                    </div>
                }}
                onChange={(value) => { this.handleOrganizationChart(value) }}
                apiUrl="organizationchart/search"
                loading={this.props.loading}
                disabled={this.props.loading || this.props.skipped}
            />}
            <ApInputStack>
                <ApInput
                    type="datetimeV2"
                    id="newUser_contract_start"
                    name="contract_start"
                    label={ tr('start_date') }
                    value={ this.state.data.contract_start }
                    validationState={ this.validator.getState('data.contract_start') }
                    tooltip={ this.validator.getText('data.contract_start') }
                    onChange={e => this.handleChange({target: {value: e, name: 'contract_start'}}) }
                />

                <ApInput
                    type="datetimeV2"
                    id="newUser_contract_end"
                    name="contract_end"
                    label={ tr('end_date') }
                    value={ this.state.data.contract_end }
                    onChange={e => this.handleChange({target: {value: e, name: 'contract_end'}}) }
                    clearable
                />
            </ApInputStack>

            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('contract_note') }
            </div>

            <br />

            <ApSelect
                label={ tr('supervisors') }
                value={ this.state.data.supervisors ? this.state.data.supervisors : [] }
                valueRenderer="user"
                optionRenderer="user"
                onChange={ ( newSups ) => { this.handleSupervisorChange( newSups ) } }
                objKeyId="id"
                multiselect
                clearable
                apiUrl="usermanagement/user/supervisors"
                apiData={{
                    company_id: this.props.companyId,
                    exludeIds: [],
                }}
                loading={ this.state.loading }
                disabled={ this.state.loading }
                filterNonActives={true}
            />

            {this.state.settings && this.state.settings.isNetvisorPayroll && 
                <>
                    <strong>Netvisor:</strong>
                    <ApInputStack gap="0">
                        <ApAddon noRightBorder>
                            {tr('salary_model')}
                        </ApAddon>
                        <ApInput
                            width="50%"
                            options={ keyExists( this.state, "settings.netvisor_salary_models", true, [] ) }
                            type="select"
                            id="netvisor_salary_model_id"
                            name="netvisor_salary_model_id"
                            label={ tr('salary_model') }
                            value={ keyExists( this.state.data, "netvisor_salary_model_id", true ) }
                            onChange={ this.handleChange }
                            tooltip={ this.validator.getText( "data.netvisor_salary_model_id" ) }
                            validationState={ this.validator.getState( "data.netvisor_salary_model_id" ) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                    {/* <ApInput
                        width="50%"
                        type="number"
                        id="netvisor_advance_debt_account"
                        name="netvisor_advance_debt_account"
                        label={ tr('advance_debt_account') }
                        value={ keyExists( this.state.data, "netvisor_advance_debt_account", true ) }
                        onChange={ this.handleChange }
                        tooltip={ tr('advance_debt_account_info') }
                        validationState={ this.validator.getState( "data.netvisor_advance_debt_account" ) }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }
                    /> */}
                    <ApInputStack gap="0">
                        <ApAddon noRightBorder>
                            {tr('payslip_delivery_method')}
                        </ApAddon>
                        <ApInput
                            width="50%"
                            options={[
                                {value: null, label: null},
                                {value: 1, label:"Paikallinen tulostus"},
                                {value: 2, label:"Tulostuspalvelu"},
                                {value: 3, label:"Verkkopalkka"}
                            ]}
                            type="select"
                            id="netvisor_payslip_delivery_method"
                            name="netvisor_payslip_delivery_method"
                            label={ tr('payslip_delivery_method') }
                            value={ keyExists( this.state.data, "netvisor_payslip_delivery_method", true ) }
                            onChange={ this.handleChange }
                            tooltip={ this.validator.getText( "data.netvisor_payslip_delivery_method" ) }
                            validationState={ this.validator.getState( "data.netvisor_payslip_delivery_method" ) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                    </ApInputStack>
                </>
            }

        </div>
                }

    renderStepAccount()
    {
        return <div className="stepAccount">
            <ApInputStack gap="0">
                <ApAddon
                    noRightBorder
                    width="200px"
                    labelFor="username"
                >
                    <SvgIcon className="small-inline" icon="user" type="solid" />
                    { tr('username') }
                </ApAddon>
                <ApInput
                    type="text"
                    id="newUser_username"
                    name="username"
                    value={ this.state.data.username }
                    onChange={ this.handleChange }
                    tooltip={ this.state.usernameError }
                    validationState={ this.state.usernameError ? "error" : "" }
                    loading={ this.state.loading || this.state.usernameLoading }
                    disabled={ this.state.loading }
                />
                <ApAddon laberFor="username" noLeftBorder>
                    @{ this.state.company ? this.state.company.suffix : "???" }
                </ApAddon>
            </ApInputStack>

            <ApInputStack gap="0">
                <ApAddon
                    noRightBorder
                    width="200px"
                    labelFor="password"
                >
                    <SvgIcon className="small-inline" icon="key" type="solid" />
                    { tr('password') }
                </ApAddon>
                <ApInput
                    type={ this.state.showPassword ? "text" : "password" }
                    id="newUser_password"
                    name="password"
                    value={ this.state.data.password }
                    onChange={ this.handleChange }
                    validationState={ this.validator.getState('data.password') }
                    tooltip={ this.validator.getText('data.password') }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
                <ApAddon
                    noLeftBorder
                    width="50px"
                    tooltip={ this.state.showPassword ? tr('hide_password') : tr('show_password') }
                    onClick={ () => this.setState({ showPassword: !this.state.showPassword }) }
                >
                    <SvgIcon icon={ this.state.showPassword ? "eye" : "eye-slash" } type="solid" />
                </ApAddon>
                <ApAddon
                    noLeftBorder
                    width="50px"
                    tooltip={ tr('random_password') }
                    tooltipPosition="topright"
                    onClick={ this.randomizePassword }
                >
                    <SvgIcon icon="dice" type="solid" />
                </ApAddon>
            </ApInputStack>

            <br />

            <div className={ "apFormGroup" + ( this.state.data.is_active ? " success" : "" ) }>
                <div className="apSwitchBlock small">
                    <label htmlFor="newUser_is-active-switch" className="info">
                        { tr('active') }
                        <small>{ tr('user_active_note') }</small>
                    </label>
                    <ApSwitch
                        id="newUser_is-active-switch"
                        on={ this.state.data.is_active }
                        onChange={ () => this.handelSwitchChange('is_active') }
                        disabled={ this.state.loading }
                    />
                </div>

                <Collapse in={ this.state.data.is_active && validateEmail( this.state.data.email ) }>
                    <div>
                        <hr />

                        <div className="apSwitchBlock small">
                            <label htmlFor="newUser_email-switch" className="info">
                                <small>{ tr('send_account_to_email') }<br />({ this.state.data.email })</small>
                            </label>
                            <ApSwitch
                                small
                                id="newUser_email-switch"
                                on={ this.state.data.send_email_to_user }
                                onChange={ () => this.handelSwitchChange('send_email_to_user') }
                                disabled={ this.state.loading }
                            />
                        </div>

                    </div>
                </Collapse>
            </div>

        </div>

    }

    render()
    {
        return <div id="NewUser">
            <ApModalWizard
                header={ tr('add_new_user') }
                onOpen={ this.resetState }
                show={ this.props.show }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.save }
            />
        </div>
    }
}

export default NewUser;
