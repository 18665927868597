/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import moment from 'moment';
//import api from 'services/Api/Api.js';
//import auth from 'services/Authed/Authed.js';

import './WeekDay.css';

import { statusCode } from  'modules/WorkHours/WorkHourHelpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

//import ApReactTable from 'common/ApReactTable/ApReactTable.js';
//import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
//import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

import { roundToDecimals, truncate } from  'services/Helpers/Helpers.js';

import { Collapse } from 'react-bootstrap';


class WeekDay extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            showDetails: false,
        };
        autoBind(this); 
    }

    toggleDetails( e )
    {
        if( !this.actionsRef || !this.actionsRef.contains(e.target) || e == undefined )
            this.setState({ showDetails: !this.state.showDetails }); 
    }


    dayHaveEntries()
    { 
        if( this.props.data.entries.hours.length > 0  || 
            this.props.data.entries.travels.length > 0 ||
            this.props.data.entries.expenses.length > 0 || 
            this.props.data.entries.absence ||
            this.props.data.entries.allowance )
            return true;

        return false;
    }
        
    dayActions()
    {
        let dayActions = [];

        dayActions.push( { 
            label: "Päivän asetukset",
            icon: "cog",
            action: ( id, closeFunc ) => { 
                this.props.onEntryEdit( 'edit', 'day', id );
                closeFunc(); 
            }
        });

        if( this.dayHaveEntries() )
        {

            // Copy whole day
            dayActions.push( { divider: "Kopioi päivä" } );
            dayActions.push( { 
                label: "Seuraavalle päivälle",
                icon: "copy",
                action: ( id, closeFunc ) => { 
                    this.props.onEntryEdit( 'copy', 'day', { date: id, target: "nextDay" } );
                    closeFunc(); 
                }
            });
            dayActions.push( { 
                label: "Loppuviikolle",
                icon: "copy",
                action: ( id, closeFunc ) => { 
                    this.props.onEntryEdit( 'copy', 'day', { date: id, target: "endOfTheWeek" } );
                    closeFunc(); 
                }
            });

            // Not today
            if( this.props.data.date != moment().format('YYYY-MM-DD') )
                dayActions.push( { 
                    label: "Kuluvalle päivälle",
                    icon: "copy",
                    action: ( id, closeFunc ) => { 
                        this.props.onEntryEdit( 'copy', 'day', { date: id, target: "today" } );
                        closeFunc(); 
                    }
                });

            // Clear day
            dayActions.push( { divider: true } );
            dayActions.push( { 
                label: "Tyhjennä päivä",
                icon: "trash-alt",
                action: ( id, closeFunc ) => { 
                    this.props.onEntryEdit( 'clear', 'day', id );
                    closeFunc();
                }
            });
        }

        return dayActions.length > 0 ? dayActions : false;
    }


    calcDayTotals( data )
    {
        let totals = {
            hours_absence:                  0,
            hours_total:                    0,
            hours_work:                     0,
            travels_distance:               0,
            travels_hours:                  0,
            expenses_total:                 0,
            daily_allowance:                0,
        };

        // All day absence 
        if( data.absence && data.absence.absence_reason_id )
        {
            // Absence hours included to working hours
            if( data.absence.worktime )
            {
                totals.hours_work = Math.round( this.props.settings.contract.hours_daily * 4 ) / 4;
                totals.hours_total = Math.round( this.props.settings.contract.hours_daily * 4 ) / 4;
            }
            return totals;
        }

       
        // Hours
        if( data.hours ) 
            data.hours.slice().forEach( ( entry ) => {
                
                // Individual hours
                //totals.hours_normal                     += this.parseNumber( entry.hours_normal );
                //totals.hours_50                         += this.parseNumber( entry.hours_50 );
                //totals.hours_100                        += this.parseNumber( entry.hours_100 );
                //totals.hours_200                        += this.parseNumber( entry.hours_200 );
                //totals.hours_weekly_rest_compensation   += this.parseNumber( entry.hours_weekly_rest_compensation );
                //totals.hours_absence                    += this.parseNumber( entry.hours_absence );

                // Group all hours
                //totals.hours_total += this.getEntryTotalHours( entry );

                totals.hours_total += this.parseNumber( entry.hours );

                if( entry.worktime )
                    totals.hours_work += this.parseNumber( entry.hours );

                if( entry.absence )
                    totals.hours_absence += this.parseNumber( entry.hours );

                // Group overtime
                //totals.hours_overtime += this.parseNumber( entry.hours_50 )
                //    + this.parseNumber( entry.hours_100 )
                //    + this.parseNumber( entry.hours_200 );
            });
        
        // Travels (+ travel hours)
        if( data.travels )
            data.travels.slice().forEach( ( travel ) => {

                let travelHours = this.parseNumber( travel.hours ); 

                totals.travels_distance += this.parseNumber( travel.distance );
                totals.travels_hours    += travelHours;
                totals.hours_total      += travelHours;
                totals.hours_work       += travelHours;

            });


        // Expenses 
        if( data.expenses )
            data.expenses.forEach( expense => {
                expense.rows.forEach( row => {
                    totals.expenses_total += this.parseNumber( row.cost ) / this.parseNumber( row.currency_rate );
                });
            });


        // Daily allowance
        if( data.allowance && data.allowance.daily_allowance_id )
            totals.daily_allowance      = this.props.settings.getAllowance( data.allowance.daily_allowance_id ).compensation;

        return totals;
    }


    /**
     * Get entrys absence hours that are included to work hours
     *   - returns number
     */
    getAbsenseHours( entry )
    {
        if( entry.absence_reason_id )
        {
            if( this.props.settings.getAbsence( entry.absence_reason_id ).worktime )
                return this.parseNumber( entry.hours_absence );
        }
        return 0
    }


    getEntrySummary( type, data )
    {
        switch( type )
        {
            case "hour":
                return (
                    <div>
                        <strong>{ data.worktime ? data.hours : 0 } h</strong>
                        <small>
                            { this.props.settings.getHourType( data.hour_type_id ).name }{ !data.worktime ? ": " + data.hours + " h" : "" }
                        </small>
                    </div>
                );

            case "travel":
                return (
                    <div>
                        <strong>{ data.distance } km</strong>
                        <small>{ data.hours } h</small>
                    </div>
                );

            case "expense":
                
                let sum = 0;
                data.rows.forEach( row => {
                    sum += this.parseNumber( row.cost ) / this.parseNumber( row.currency_rate );
                });

                return (
                    <div>
                        <strong>{ roundToDecimals( sum, 2 ) } &euro;</strong>
                        <small>{ data.payment_method == "own_money" ? "Maksu omista rahoista" : "Yrityksen luottokortti" }</small>
                    </div>
                );
            case "allowance":
                return (
                    <div>
                        <strong>{ roundToDecimals( data.compensation, 2 ) } &euro;</strong>
                    </div>
                );
            case "absence":
                return (
                    <div>
                        <strong>{ data.worktime ? this.props.settings.contract.hours_daily : 0 } h</strong>
                    </div>
                );

            default:
                return null;
        }
    }

    getEntryActions( type, entry )
    {   
        const dayLocked = this.props.data.locked;
        const entryLocked = ( entry.status_id == statusCode("submitted") || entry.status_id == statusCode("approved") );
        let options = [];

        options.push( {
            label: dayLocked || entryLocked ? "Näytä tiedot" : "Muokkaa",
            icon: dayLocked || entryLocked ? "eye" : "edit",
            action: ( id, closeFunc ) => { 
                this.props.onEntryEdit( 'edit', type, id );
                closeFunc(); 
            },
        });

        if( !dayLocked )
        {
            if( type == "hour" || type == "travel" || type == "expense" )
                options.push( {
                    label: "Kopioi",
                    icon: "copy",
                    action: ( id, closeFunc ) => { 
                        this.props.onEntryEdit( 'copy', type, id );
                        closeFunc(); 
                    },
                });
            
            // Add travel for hours or expense
            if( type == "hour" || type == "expense" )
                options.push( {
                    label: "Lisää matka",
                    icon: "car",
                    action: ( id, closeFunc ) => { 
                        this.props.onEntryEdit( 'add', 'travel', { 
                            date: entry.date, 
                            description: entry.description,
                            project_id: entry.project_id, 
                            customer_work_number: entry.customer_work_number 
                        });
                        closeFunc(); 
                    },
                });

            // Add expense for hours or travel
            if( type == "hour" || type == "travel" )
                options.push( {
                    label: "Lisää kulu",
                    icon: "receipt",
                    action: ( id, closeFunc ) => { 
                        this.props.onEntryEdit( 'add', 'expense', { 
                            date: entry.date, 
                            description: entry.description,
                            project_id: entry.project_id, 
                            customer_work_number: entry.customer_work_number 
                        });
                        closeFunc(); 
                    },
                });
            
            // Remove entry
            options.push( {
                label: ( entry.misentry ? "Peruuta poisto" : "Poista" ),
                icon: ( entry.misentry ? "undo" : "trash-alt" ),
                disabled: entryLocked,
                action: ( id, closeFunc ) => { 
                    this.props.onEntryEdit( 'remove', type, id );
                    closeFunc(); 
                },
            });
        }

        return options;
    }

    getDailyAllowanceTime( entry )
    {
        if( entry )
        {
            // Converts DB time format (HH:MM:SS) to human readable format
            const parseTime = ( time ) => {
                if( time && time.indexOf(':') > -1 )
                {
                    const parts = time.split(":");
                    return parts[0] + "." + parts[1];
                }
                return false;
            };

            const start = parseTime( entry.daily_allowance_start );
            const end = parseTime( entry.daily_allowance_end );

            if( start && end )
            {
                if( start == "00.00" && end == "24.00" )
                    return "koko päivä";

                return "klo " + start + " - " + end;
            }
        }
        return false;
    }

    parseNumber( number )
    {
        let value = parseFloat( number );
        if( !isNaN( value ) ) 
            return value;
        return 0;
    };


    getDayStatus()
    {
        let entries = {
            total: 0,
            suggested: 0,
            open: 0,
            submitted: 0,
            rejected: 0,
            approved: 0,
        };

        if( this.props.data.entries )
        {
            const count = ( obj ) => {
                if( Array.isArray( obj ) )
                {
                    for( let i = 0; i < obj.length; i++ )
                    {
                        entries.total++;

                        if( obj[i].status_id == statusCode("approved") )
                            entries.approved++;

                        else if ( obj[i].status_id == statusCode("rejected") )
                            entries.rejected++;

                        else if ( obj[i].status_id == statusCode("submitted") )
                            entries.submitted++;

                        else if ( obj[i].status_id == statusCode("open") )
                            entries.open++;

                        else if ( obj[i].status_id == statusCode("suggested") )
                            entries.suggested++;
                    }
                }
                else if ( obj && obj.status_id )
                {
                    entries.total++;

                    if( obj.status_id == statusCode("approved") )
                        entries.approved++;

                    else if ( obj.status_id == statusCode("rejected") )
                        entries.rejected++;

                    else if ( obj.status_id == statusCode("submitted") )
                        entries.submitted++;

                    else if ( obj.status_id == statusCode("open") )
                        entries.open++;

                    else if ( obj.status_id == statusCode("suggested") )
                        entries.suggested++;
                } 

            };

            count( this.props.data.entries['hours'] );
            count( this.props.data.entries['travels'] );
            count( this.props.data.entries['expenses'] ); 
            count( this.props.data.entries['allowance'] );
            count( this.props.data.entries['absence'] );
        }

        if( entries.total > 0 )
        {
            // At least one entry is rejected
            if( entries.rejected > 0 )
                return {
                    text: "Hylätty",
                    state: "error",
                    count: entries.rejected + "/" + entries.total,
                }
    
            // At least one entry still waiting for approval (and none is rejected)
            else if ( entries.submitted > 0 )
                return {
                    text: "Odottaa",
                    state: "info",
                    count: entries.submitted + "/" + entries.total,
                }

            // All entries are approved
            else if ( entries.approved > 0 && entries.approved == entries.total )
                return {
                    text: "Hyväksytty",
                    state: "success",
                    count: entries.total,
                }  
        }

        return {
            text: "Avoin",
            state: "open",
            count: entries.total > 0 ? entries.total : false,
        }
    }


    
    renderEntryRow( index, type, entry )
    {
        let icon        = false;
        let desc        = false;
        let descSmall   = false;
        let proj        = false;
        let projSmall   = false;
        let actionId    = entry.id;
        let actions     = this.getEntryActions( type, entry );

        let classes     = [ "entryRow" ];
        let statusIcon  = false;
        let statusTip   = false;

        if( entry.status_id == statusCode("submitted") )
        {
            classes.push( "status-submitted" );
            statusIcon = "arrow-right";
            statusTip = "Lähetetty";
        }

        else if( entry.status_id == statusCode("rejected") )
        {
            classes.push( "status-rejected" );
            statusIcon = "times";
            statusTip = "Hylätty";

            if( entry.approver_comment ) 
                statusTip += " - " + truncate( entry.approver_comment, 50 );
        }

        else if( entry.status_id == statusCode("approved") )
        {
            classes.push( "status-approved" );
            statusIcon = "check";
            statusTip = "Hyväksytty";
        }

        if( entry.misentry )
            classes.push( "misentry" );

        if( type == "hour" )
        {
            icon        = "clock";
            desc        = entry.description;
            descSmall   = entry.description_long;
            proj        = entry.project ? entry.project.name : false;
            projSmall   = entry.project ? entry.project.project_code : false;

            if( entry.customer_work_number )
            {
                if( projSmall ) projSmall += " / ";
                projSmall += entry.customer_work_number;
            }
            
            if( entry.absence_reason_id ) 
            {
                icon        = "ban";
                proj        = this.props.settings.getAbsence( entry.absence_reason_id ).name;
                projSmall   = false;
            }
        }
        else if ( type == "travel" )
        {
            icon        = "car";
            desc        = entry.description;
            descSmall   = entry.route;
            proj        = entry.project ? entry.project.name : false;
            projSmall   = entry.project ? entry.project.project_code : false;

            if( entry.customer_work_number )
            {
                if( projSmall ) projSmall += " / ";
                projSmall += entry.customer_work_number;
            }
        }
        else if ( type == "expense" )
        {
            let rowOverview = "";
            entry.rows.forEach( row => {
                rowOverview += ( rowOverview == "" ? "" : ", " ) + row.description;
            });

            icon        = "receipt";
            desc        = entry.event ? entry.event : "Kulukorvaus";
            descSmall   = rowOverview;
            proj        = entry.project ? entry.project.name : false;
            projSmall   = entry.project ? entry.project.project_code : false;

            if( entry.customer_work_number )
            {
                if( projSmall ) projSmall += " / ";
                projSmall += entry.customer_work_number;
            }
        }
        else if ( type == "allowance" )
        {
            const allowance = this.props.settings.getAllowance( entry.daily_allowance_id );
            entry.compensation = allowance.compensation;

            icon        = "suitcase";
            desc        = !allowance.abroad ? allowance.name : "Ulkomaan päiväraha";
            descSmall   = !allowance.abroad ? this.getDailyAllowanceTime( entry ) : allowance.name + " (" + this.getDailyAllowanceTime( entry ) + ")";
            proj        = entry.project ? entry.project.name : false;
            projSmall   = entry.project ? entry.project.project_code : false;

            if( entry.customer_work_number )
            {
                if( projSmall ) projSmall += " / ";
                projSmall += entry.customer_work_number;
            }
            actionId    = this.props.data.date;
        }
        else if ( type == "absence" )
        {

            const absence = this.props.settings.getAbsence( entry.absence_reason_id ); 

            icon        = "ban";
            desc        = absence.name;
            descSmall   = entry.worktime ? "Kerryttää työaikaa" : "Ei lasketa työaikaan";
            proj        = "Poissa koko päivän";
            projSmall   = false;
            actionId    = this.props.data.date;
        }

        const cellAction = actions.length > 0 ? () => actions[0].action( actionId, () => false ) : () => false;

        return (
            <tr key={ type + index } className={ classes.join(" ") }>
                <td className="icon" onClick={ cellAction }>
                    { statusIcon && 
                        <div className="statusBadge">
                            <ApTooltip block text={ statusTip } position="topleft">
                                <SvgIcon icon={ statusIcon } type="solid" />
                            </ApTooltip>
                        </div>
                    }
                    { icon && <SvgIcon icon={ icon } type="solid" /> }
                </td>
                <td className="desc clip" onClick={ cellAction }>
                    { desc }
                    { descSmall && <small>{ descSmall }</small> }
                </td>
                <td className="project clip" onClick={ cellAction }>
                    { proj }
                    { projSmall && <small>{ projSmall }</small> }
                </td>
                <td className="hours clip" onClick={ cellAction }>
                    { this.getEntrySummary( type, entry ) }
                </td>
                <td className="actions">
                    { actions &&
                        <ApDropdown
                            actionId={ actionId }
                            actions={ actions }
                        />
                    }
                </td>
            </tr>
        );
    }

    render() 
    {

        const locked = this.props.data.locked;
        const totals = this.calcDayTotals( this.props.data.entries );
        const absenceAllDay = ( this.props.data.entries.absence && this.props.data.entries.absence.absence_reason_id ? true : false );
        const dayActions = this.dayActions();

        // const dailyAllowance = ( this.props.data.entries.allowance ? this.props.settings.getAllowance( this.props.data.entries.allowance.daily_allowance_id ) : false );

        let classes = ["WeekDay"];
        let dayStatus = this.getDayStatus();

        if( this.props.loading ) 
            classes.push( "loading" );

        if( this.state.showDetails )
            classes.push( "detailsOpen" );

        if( this.props.unselected )
            classes.push( "unselected" );

        if( locked ) 
            classes.push("locked");

        if( absenceAllDay )
        {
            classes.push("absenceAllDay");

            dayStatus.state = "warning";
            dayStatus.text = this.props.settings.getAbsence( this.props.data.entries.absence.absence_reason_id ).name;
            dayStatus.count = false;
        }

        return (
            <div className={ classes.join(" ") }>
                
                <div className="header" onClick={ this.toggleDetails }>

                    <SvgIcon className="indicator" icon="chevron-right" type="solid" />

                    <div className="date">
                        <span className="weekday">{ moment( this.props.data.date ).format( 'dddd' ) }</span>
                        <span className="date">{ moment( this.props.data.date ).format( 'D.M.YYYY' ) }</span>
                    </div>

                    <div className="status">
                        <div className={ "apStatusBox " + dayStatus.state }>
                            { locked && <SvgIcon icon="lock" type="solid" /> }
                            { dayStatus.text } 
                            { dayStatus.count !== false && <span> ({ dayStatus.count })</span> }
                        </div>
                    </div>

                    <div className="overview">

                        <div className={"block" + ( totals['hours_total'] == 0 ? " none" : "" ) }>
                            <SvgIcon icon="clock" type="solid" />
                            <span>{ totals['hours_work'] } h</span>
                            { this.props.settings.contract.flex_time_allowed && this.props.data.interpretation.flexitime != 0 &&
                                <ApTooltip text="Liukuman muutos">
                                    <div className={ "flexitimeBadge" + ( this.props.data.interpretation.flexitime < 0 ? " negative" : " positive" ) }>
                                        { ( this.props.data.interpretation.flexitime > 0 ? "+" : "" ) + this.props.data.interpretation.flexitime } h
                                    </div>
                                </ApTooltip>
                            }
                        </div>

                        <div className={"block" + ( totals['travels_distance'] == 0 ? " none" : "" ) }>
                            <SvgIcon icon="car" type="solid" />
                            <span>{ totals['travels_distance'] } km</span>
                        </div>

                        <div className={"block" + ( totals['expenses_total'] == 0 ? " none" : "" ) }>
                            <SvgIcon icon="receipt" type="solid" />
                            <span>{ roundToDecimals( totals['expenses_total'], 2 ) } &euro;</span>
                        </div>

                        <div className={"block" + ( totals['daily_allowance'] == 0 ? " none" : "" ) }>
                            <SvgIcon icon="suitcase" type="solid" />
                            <span>{ roundToDecimals( totals['daily_allowance'], 2 ) } &euro;</span>
                        </div>
                  
                    </div>

                    { dayActions && 
                        <div className="actions" ref={ node => this.actionsRef = node }>
                            <ApDropdown actions={ dayActions } actionId={ this.props.data.date } />
                        </div>
                    }
                    

                </div>

                <Collapse in={this.state.showDetails}>
                    <div>
                        <div className="details">
                            <div className="padding">

                                <table>
                                    <tbody>

                                        { absenceAllDay &&
                                            this.renderEntryRow( 1, "absence", this.props.data.entries.absence )
                                        }

                                        { this.props.data.entries.hours.map( (entry, index) => { 
                                            return this.renderEntryRow( index, "hour", entry );
                                        })}
                                        
                                        { this.props.data.entries.travels.map( ( entry, index ) => {
                                            return this.renderEntryRow( index, "travel", entry );
                                        })}

                                        { this.props.data.entries.expenses.map( ( entry, index ) => {
                                            return this.renderEntryRow( index, "expense", entry );
                                        })}
                                        
                                        { this.props.data.entries.allowance && 
                                            this.renderEntryRow( 1, "allowance", this.props.data.entries.allowance )
                                        }
   
                                        { !this.dayHaveEntries() &&
                                            <tr className="noRows">
                                                <td colSpan="5">
                                                    <SvgIcon icon="exclamation-triangle" type="solid" />
                                                    Ei kirjattuja tunteja, matkoja tai kuluja
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                
                                <Collapse in={ this.props.showInterpretation }> 
                                    <div>
                                        <div className="interpretation">
                                            { this.props.data.interpretation.errors && this.props.data.interpretation.errors.length > 0 &&
                                                <div className="error">
                                                    { this.props.data.interpretation.errors.map( ( row, index ) => 
                                                        <div key={ index }>{ row }</div>
                                                    )}
                                                </div>
                                            }
                                            { this.props.data.interpretation.result.map( ( row, index ) => 
                                                <div key={ index }>{ row }</div>
                                            )}
                                        </div>
                                    </div>
                                </Collapse>

                                <br />

                                <div className="apButtonGroup">

                                    <ApButton color="blue" size="small" disabled={ locked || absenceAllDay } onClick={ () => this.props.onEntryEdit( 'add', 'hour', { date: this.props.data.date } ) }>
                                        <SvgIcon icon="clock" type="solid" />
                                        Lisää tuntikirjaus
                                    </ApButton>

                                    <ApButton color="blue" size="small" disabled={ locked || absenceAllDay || this.props.settings.vehicles.length == 0 } onClick={ () => this.props.onEntryEdit( 'add', 'travel', { date: this.props.data.date } ) }>
                                        <SvgIcon icon="car" type="solid" />
                                        Lisää matka
                                    </ApButton>

                                    <ApButton color="blue" size="small" disabled={ locked || absenceAllDay || !this.props.settings.company.expense_salary_category } onClick={ () => this.props.onEntryEdit( 'add', 'expense', { date: this.props.data.date } ) }>
                                        <SvgIcon icon="receipt" type="solid" />
                                        Lisää kulukorvaus
                                    </ApButton>

                                    <div style={{ float: "right" }}>
                                        <ApButton size="small" disabled={ locked } onClick={ () => this.props.onEntryEdit( 'edit', 'day', this.props.data.date ) }>
                                            <SvgIcon icon="cog" type="solid" />
                                            Päivän asetukset
                                        </ApButton>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </Collapse>
                
            </div>
        );
    }
}


WeekDay.propTypes = {
    loading:        PropTypes.bool.isRequired,
    data:           PropTypes.object.isRequired,
    settings:       PropTypes.object.isRequired,
    onEntryEdit:    PropTypes.func.isRequired,
    unselected:     PropTypes.bool,
    // number:       PropTypes.number.isRequired,
    // string:       PropTypes.string,
    // multiple:     PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
    // bool:         PropTypes.bool,
};


export default WeekDay;
