import React from 'react';
import autoBind from 'react-autobind';
import { ApTabs, 
         ApTab } from 'common/ApTabs/ApTabs.js';

import Suppliers from './Suppliers.js';
import CrmList from './CrmList.js';
import { tr } from 'services/Helpers/Helpers.js';

export default class Main extends React.Component
{

	constructor(props)
	{

		super(props);

		autoBind(this);

	}

	render()
	{ 

		return(

			<div className="apBox" id="pageSuppliers">
				<div className="apBoxHeader">
					<h1>
						{tr('purchases_suppliers_module')}
					</h1>
				</div>
				
				<ApTabs>

					<ApTab icon="building" label={ tr('suppliers') }>
						<Suppliers />
					</ApTab>

					<ApTab icon="marker" label={ tr('crm_entries') }>
                        <CrmList />
					</ApTab>

				</ApTabs>

			</div>

		);

	}

}
