import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApReactTable 	from 'common/ApReactTable/ApReactTable.js';
import { tr } from 'services/Helpers/Helpers.js';

export default class Extranet extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			customers: [],
			columns: []
		}

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		this.getColumns();
		this.getCustomers();

	}

	getCustomers()
	{

		api({
			method: 'get',
			url: 'crm/extranet/getAll'
		}).then((response) => {
			this.setState({ customers: response });
		}).catch((error) => {
			console.log(error);
		});

	}

	getColumns()
	{

		const columns = [
			{
				Header: tr('name'),
				accessor: 'name',
				customFilter: {
					type: "text",
					placeholder: tr('enter_name_business_id'),
					fields: ["name"]
				}
			},
			{
				Header: tr('active'),
				maxWidth: '100px',
				Cell: (props) => {
					return this.getStatusIcon(props.original.is_active, props.original.id, 'customerStatus');
				}
			},
			{
				Header: tr('extranet_active'),
				maxWidth: '100px',
				Cell: (props) => {
					return this.getStatusIcon(props.original.extranet_active, props.original.id, 'extranet');
				}
			},		
		];

		this.setState({ columns: columns });

	}

	switchStatus(id, type)
	{

		let url = null;

		if (type === 'customerStatus')
		{
			url = 'crm/extranet/switchCustomerStatus';
		}
		else if (type === 'extranet')
		{
			url = 'crm/extranet/switchCustomerExtranetStatus';
		}

		api({
			method: 'post',
			url: url,
			data: { customerId: id }
		}).then((response) => {
			this.getCustomers();
		}).catch((error) => {
			console.log(error);
		});

	}

	getStatusIcon(status, id, type)
	{

		let element = null;

		if (status) {
			element = 
				<div style={{textAlign: 'center'}} onClick={() => {this.switchStatus(id, type)}}>
					<SvgIcon fill="#4c0" icon="check-circle" />
				</div>;
		} else {
			element = 
				<div style={{textAlign: 'center'}} onClick={() => {this.switchStatus(id, type)}}>
					<SvgIcon fill="#c40" icon="times-circle" />
				</div>;
		}

		return element;

	}

	showCustomer(row)
	{
		window.emitter.emit('goTo', { pathname: '/crm/company/' + row.id + '/extranet'});
	}

	render()
	{

		return(

			<div className="apBox">

				<div className="apBoxHeader">
					<h1> Extranet </h1>
					<p> { tr('extranet_info') } </p>
				</div>

				<div className="padding">
					<ApReactTable
						showFiltersInitially
						data={this.state.customers}
						columns={this.state.columns}
					    rowActions={ (row) => 
			            	<div className="apSimpleButton" onClick={ () => this.showCustomer( row ) }><SvgIcon icon="arrow-right" type="solid" /></div>
			            }
					/>
				</div>

			</div>

		);

	}

}
