import React from 'react';
import autoBind from 'react-autobind';
import { tr }  from 'services/Helpers/Helpers.js'
import ApModal          from 'common/ApModal/ApModal.js';
import ApButton         from 'common/ApButton/ApButton.js';
import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import ApSwitch         from 'common/ApSwitch/ApSwitch.js';

class LinkConfirm extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            linkModified: false,
        }
        autoBind(this);
    }

    close( data )
    {
        this.props.onClose( data, this.state.linkModified );
    }

    render()
    {
        const counts = this.props.counts;

        let linkModifiedSwitch = null;
        if( counts.modified > 0 )
        {
            linkModifiedSwitch = <div className="apSwitchBlock">
                <ApSwitch
                    id="link-modified-too"
                    on={ this.state.linkModified }
                    onChange={ () => { this.setState({ linkModified: !this.state.linkModified }) } }
                />
                <label htmlFor="link-modified-too">
                    { tr('update_modified_links') }
                </label>
            </div>
        }

        return <div id="ProjectMassListLinkConfirm">
            <ApModal
                show={ this.props.show }
                handleClose={ () => this.props.onClose( false) }
                //closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            { tr('create_new_links') }
                        </h4>
                    </div>
                }
                body={
                    <div>
                        <div className="padding">

                            { linkModifiedSwitch }
                            <div>{ tr('confirm_create_new_links', [counts.created]) }</div>
                            { this.state.linkModified && <div>
                                { tr('confirm_update_links', [counts.modified]) }
                            </div> }
                        </div>
                    </div>
                }
                footer={
                    <div className="padding footer">
                        <div className="left">
                            <ApButton onClick={ () => this.close( false ) }>
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>
                        </div>

                        <ApButton color="red" onClick={ () => this.close( 'no' ) }>
                            <SvgIcon icon="times-circle" type="solid" />
                            { tr('no') }
                        </ApButton>

                        <ApButton color="green" onClick={ () => this.close( 'yes' ) }>
                            <SvgIcon icon="link" type="solid" />
                            { tr('yes') }
                        </ApButton>
                    </div>
                }
            />
        </div>
    }

}

export default LinkConfirm;
