import React from 'react';
import autobind from "react-autobind";
import { connect } from 'react-redux';
import { tr } from 'services/Helpers/Helpers';
import WooCommerce from './WooCommerce/WooCommerce';

class OnlineStore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        autobind(this);
    }

    renderStore() {
        if (this.props.apOrderSettings && this.props.apOrderSettings.woocommerce_enabled) {
            return <WooCommerce />
        } 
        return <h4> {tr('online_store_not_enabled')} </h4>
    }

    render() {
        return (
            <div id='online-store'>
                {this.renderStore()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    apOrderSettings: state.apOrderSettings
});

export default connect(mapStateToProps)(OnlineStore);