import React from 'react';
import autoBind from 'react-autobind';

import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApReactTable       from 'common/ApReactTable/ApReactTable.js';
import auth               from 'services/Authed/Authed.js';
import api                from 'services/Api/Api.js';
import ApTooltip          from 'common/ApTooltip/ApTooltip.js';
import { errorPopper
       , formatMoney
       , sqlToDateInput
       , tr } from 'services/Helpers/Helpers.js'
import { getTypeName
       , getTypeIcon }    from 'modules/Storage/common/StorageHelpers.js';

import './DeliveryList.css';

class DeliveryList extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            data: [],
            pages: -1,

            results: [],
            selected: '',

            currency: auth.getCurrency(),
            currencySign: auth.getCurrencySign(),
        };
        this.state.columns = this.getColumns();

        autoBind(this);
    }

    getData( state )
    {
        this.setState({loading: true})
        api({
            method: 'post',
            url: 'storage/deliveries/apt',
            data: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
            },
        }).then((response) => {
            this.setState({
                data: response.data,
                pages: response.pages,
                loading: false
            })
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            console.error(error);
            this.setState({
                data: [],
                pages: -1,
                loading: false
            })
        });
    }

    getColumns()
    {
        return [{

            id: 'date',
            Header: tr('date'),
            accessor: 'date',
            filterable: false,
            sortable: false,
            width: 150,
            onClick: row => this.viewDelivery( row.id ),
            Cell: props => {
                return sqlToDateInput( props.value );
            },
        },{

            id: 'sender',
            Header: tr('sender'),
            className: "overflowableOnHover",
            accessor: 'sender_type',
            filterable: false,
            sortable: false,
            onClick: row => this.viewDelivery( row.id ),
            Cell: props => {
                let icon = <SvgIcon icon={ getTypeIcon( props.original.sender_type ) } type="solid" />
                let name = getTypeName( props.original.sender_type );
                let typeTitle = props.original.sender_title;
                return <div>
                    <ApTooltip position="top" text={ name }>
                        { icon }
                    </ApTooltip>
                    { typeTitle }
                </div>
            },

        },{
            id: 'receiver',
            Header: tr('recipient'),
            className: "overflowableOnHover",
            accessor: 'receiver_type',
            filterable: false,
            sortable: false,
            onClick: row => this.viewDelivery( row.id ),
            Cell: props => {

                let icon = <SvgIcon icon={ getTypeIcon( props.original.receiver_type ) } type="solid" />
                let name = getTypeName( props.original.receiver_type );
                let typeTitle = props.original.receiver_title;
                return <div>
                    <ApTooltip position="top" text={ name }>
                        { icon }
                    </ApTooltip>
                    { typeTitle }
                </div>
            },
        },{
            id: 'created',
            Header: tr('creator_of_entry'),
            accessor: 'created_by',
            filterable: false,
            sortable: false,
            onClick: row => this.viewDelivery( row.id ),
        },{
            id: 'related',
            Header: tr('related'),
            className: "overflowableOnHover",
            filterable: false,
            sortable: false,
            onClick: row => this.viewDelivery( row.id ),
            Cell: props => {
                let icon = props.original.related_type
                    ? <SvgIcon icon={ getTypeIcon( props.original.related_type ) } type="solid" />
                    : null;
                let name = props.original.related_type
                    ? getTypeName( props.original.related_type )
                    : null;
                let typeTitle = props.original.related_title;
                return <div>
                    <ApTooltip position="top" text={ name }>
                        { icon }
                    </ApTooltip>
                    { name } { typeTitle }
                </div>
            },
        },{
            id: 'price',
            Header: tr('value'),
            accessor: 'price',
            filterable: false,
            sortable: false,
            onClick: row => this.viewDelivery( row.id ),
            Cell: props => {
                if( props.value )
                    return <div className="text-right"> { formatMoney( props.value ) } { this.state.currencySign }</div>
                return null;
            },

        },{
            id: 'ccount',
            Header: tr('rows_count'),
            accessor: 'components_count',
            filterable: false,
            sortable: false,
            width: 80,
            onClick: row => this.viewDelivery( row.id ),
            Cell: props => {
                return <div className="text-right">{ props.value }</div>
            }
        }];
    }

    viewDelivery( id )
    {
        window.emitter.emit( 'goTo', { pathname: `/storage/logistic/delivery/show/${id}` } );
    }

    render()
    {
        return <div id="DeliveryList">

            <ApReactTable

                // defaultSorted={[{id: 'id', desc: true}]}

                manual
                onFetchData={ this.getData }
                pages={ this.state.pages }

                loading={ this.state.loading }
                data={ this.state.data }
                columns={ this.state.columns }
                rowActions={[
                    {
                        icon: "eye",
                        label: tr('show'),
                        action: this.viewDelivery,
                    },
                ]}
                minRows={ 10 }
            />
        </div>
    }
}

export default DeliveryList;
