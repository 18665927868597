import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import ApTimeline from '../../../../common/ApTimeline/ApTimeline.js';
import ApModal            from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import { ApInputStack, ApInput, ApAddon } from 'common/ApInput/ApInput.js';
import api from 'services/Api/Api.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import { tr, currentLang } from 'services/Helpers/Helpers.js';

class UserHours extends React.Component {

	constructor( props ) 
	{
		super(props);
        this.state = {
            name: null,
            start_date: null,
            end_date: null,
        };

        autoBind(this);
    }

    renderChangesColumns()
	{
        return (
		[
			{
				Header: `${tr('balance_change')} (${tr('day_abbr')})`,
                accessor: 'change',
                id: 'change',
                Cell: (props) => {
					return (
						<div>
							{props.original.change > 0? `+${props.original.change}` : props.original.change}
						</div>
					);
				}
			},
			{
				Header: tr('description'),
                accessor: 'description',
                id: 'description'
			},
			{
				Header: tr('date'),
                accessor: 'date',
                id: 'date',
                Cell: (props) => {
					return (
						<div>
							{moment(props.original.date).format('DD.MM.YYYY')}
						</div>
					);
				}
            },
            {
				Header: tr('responsible_person'),
                accessor: 'creator',
                id: 'creator',
                Cell: (props) => {
					return (
						<div>
							{`${props.original.creator.first_name} ${props.original.creator.last_name}`}
						</div>
					);
				}
			},
        ]
        );

    }
    
    renderContent()
    {
        if (!this.props.settings.current_balance && !this.props.loading)
        {
            return(
                <h4>{ tr('opening_balance_not_set') }</h4>
            )
        }
        else if (this.props.settings.current_balance && !this.props.loading)
        {
            return(
                <div>

                    <h4>{ tr('current_balance') }: {this.props.settings.current_balance.current_balance > 0 ? `+${this.props.settings.current_balance.current_balance}` : this.props.settings.current_balance.current_balance} h</h4>
                    <strong>{ tr('updated_at') }: </strong>{moment(this.props.settings.current_balance.updated_at).locale(currentLang()).format('LLL')}

                    <br/><br/>

                    <h3>{ tr('balance_changes') }</h3>
                    { this.props.settings.changes && this.props.settings.changes.length > 0 ?
                    <ApReactTable
                        data={this.props.settings.changes}
                        columns={this.renderChangesColumns()}
                        defaultSorted={[ 
                            { id: "date", desc: true }
                        ]}
                        defaultPageSize={10}
                    />
                    :
                    <div>{ tr('no_balance_changes') }</div>
                    }

                </div>
            )
        }
        else
        {
            return (
            <div className="padding">
                <div className="apLoader"></div>
            </div>
            )
        }
    }

    render() 
    {
        return (
            <div className="padding">

                { this.renderContent() }

             </div>
        );
    }
}
export default UserHours
