/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import { findItemById, tr } from 'services/Helpers/Helpers.js';

import SvgIcon      from 'common/SvgIcon/SvgIcon.js';
import ApListTree   from 'common/ApListTree/ApListTree.js';
import ApSwitch     from 'common/ApSwitch/ApSwitch.js';

import './ModulesTree.css';


class ModulesTree extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            isLoading: false,
            moduleData: []
        };
        autoBind(this);
    }

    componentDidMount()
    {
        this.getModules();
    }

    getModules()
    {
        let url = 'usermanagement/modules/tree';
        if(this.props.forCompany)
            url = 'management/modules/tree';

        this.setState({ isLoading: true });
        api({
            method: 'get',
            url: url,
            params: {
                company_id: this.props.companyID,
            },
        }).then((response) => {
            this.setState({
                moduleData: response,
                isLoading: false,
            });
        }).catch((error) => {
            console.error(error);
        });
    }

    toggleModule( id )
    {   
        let selected = this.props.selectedIDs.slice();
        const index = selected.indexOf( id );

        if( index != -1 )
            selected.splice(index, 1);
        else 
        {   
            selected.push( id );

            // Also select parent ids automatically
            const item = findItemById( this.state.moduleData, id, "children" );
            item.parents.map( parentId => selected.push( parentId ) );
        }

        this.props.onChange( selected );
    }

    toggleAll( value ) 
    {
        const locked = this.props.lockedIDs ? this.props.lockedIDs : [];
        let ids = [];

        if( value )
        {
            const loop = ( items ) => {
                if( Array.isArray( items ) && ( items.length > 0 ) )
                    items.forEach( item => {
                        if( locked.indexOf( item.id ) == -1 )
                            ids.push( item.id );
                        loop( item.children );
                    });
            };
            loop( this.state.moduleData ); 
        }  
        
        // Add locked items if they were enabled before
        locked.forEach( lockedId => {
            if( this.props.selectedIDs.indexOf( lockedId ) != -1 )
                ids.push( lockedId )
        });

        this.props.onChange( ids );
    }

    renderModuleItem( item, toggleFunc )
    {
        const selected = this.props.selectedIDs ? this.props.selectedIDs : [];
        const inherited = this.props.inheritedIDs ? this.props.inheritedIDs : [];
        const locked = this.props.lockedIDs ? this.props.lockedIDs : [];

        const countChilds = function( data ) {
            if( Array.isArray( data ) && ( data.length > 0 ) ) {
                let active = 0;
                for( let i = 0; i < data.length; i++ ) {
                    if( selected.indexOf( data[i].id ) != -1 ) active++;
                }
                return active + "/" + data.length;
            }
            return "";
        };

        // Module enabled because user belongs to group that enables 
        const enabledByGroup = inherited.indexOf( item.id ) != -1;
 
        const enabled = selected.indexOf( item.id ) != -1; 
        let parentsEnabled = true;

        item.parents.forEach( parentId => {
            if( selected.indexOf( parentId ) == -1 && inherited.indexOf( parentId ) == -1 )
                parentsEnabled = false;
        });

        return (
            <div>
                <div className="info" onClick={ toggleFunc }>
                    { tr(item.name).length > 0 ? tr(item.name) : item.name }
                    { item.description && <small>{ tr(item.description) }</small> }
                </div>
                <div className={"details" + ( enabled ? " visible" : "" ) }>{ countChilds( item.children ) }</div>
                <ApSwitch
                    on={ enabled }
                    disabled={ locked.indexOf( item.id ) != -1 }
                    name={ item.name }
                    id={ "module-" + this.props.id + "-" + item.id }
                    onChange={ () => { this.toggleModule( item.id ) } }
                    colorOn={ !parentsEnabled ? "#ff4444" : undefined } // Highlight if parent is NOT enabled
                    colorOff={ enabledByGroup ? "#4285F4" : undefined } // Highlight if module inherited
                />
                { enabledByGroup && <SvgIcon className="inheritedIcon" icon="users" type="solid" /> }
            </div>
        );
    }

    render()
    {
        return(
            <div className="modulesTree">
                <div className="top">
                    <div className="toggleAll">
                        <div className="button" onClick={() => { this.toggleAll( true ) }}>
                            <SvgIcon icon="check-circle" type="solid" />
                            <span>{ tr('select_all') }</span>
                        </div>
                        <div className="button" onClick={() => { this.toggleAll( false ) }}>
                            <SvgIcon icon="times-circle" type="solid" />
                            <span>{ tr('clear_selections') }</span>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <ApListTree items={ this.state.moduleData } content={ this.renderModuleItem } childsName="children" />
                </div>
            </div>
        );

    }

}

export default ModulesTree;

ModulesTree.propTypes = {
    // TODO
};
