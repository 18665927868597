import React from 'react';
import autoBind from 'react-autobind';

import { ApTabs, ApTab }    from 'common/ApTabs/ApTabs.js';

import CompanyTable from './CompanyTable.js';
import LoginAsUser from './LoginAsUser.js';
import Timetracking from './Timetracking/Timetracking.js';

class CustomerManagement extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
        }
        autoBind(this);
    }

    render()
    {
        return(
            <div className="apBox">
                <div className="apBoxHeader">
                    <h1>Asiakashallinta</h1>
                    <p>Järjestelmän asiakasyritysten hallinta ja muokkaus. Vain järjestelmän pääkäyttäjälle (AmiPro). </p>
                </div>

                <ApTabs>
                    <ApTab icon="toolbox" label="Asiakashallinta">
                        <div className="padding">

                            <CompanyTable />
                        
                        </div>
                    </ApTab>
                    <ApTab icon="user-secret" label="Kirjaudu sisään käyttäjänä" mountOnSelect>
                        <div className="padding">
                        
                            <LoginAsUser />
                        
                        </div>
                    </ApTab>
                    <ApTab icon="th" label="Muut toiminnot">
                        <div className="padding">

                            <Timetracking />

                        </div>
                    </ApTab>
                </ApTabs>

            </div>
        );
    }
}

export default CustomerManagement;
