/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import './ApProgressbar.css';
import ApTooltip from '../ApTooltip/ApTooltip.js';

class ApProgressbar extends React.Component {

    constructor( props )
    {
        super( props );
        autoBind(this); 
    }

    getBars()
    {
        let bars = [];
        if( typeof( this.props.value ) == "number" )
        {
            bars.push({ 
                value: this.props.value * 100, 
                start: 0,
                color: "#33b5e5",
            });
        }
        else if ( this.props.value.length > 0 )
        {
            for( let i = 0; i < this.props.value.length; i++ )
            {   
                const item = this.props.value[i];
                bars.push({ 
                    value: item.value * 100, 
                    start: item.start ? item.start * 100 : 0,
                    color: item.color ? item.color : "#33b5e5",
                });
            }
        }
        return bars;
    }

    getTooltip() {

        if( this.props.tooltip === true )
        {
            if ( this.props.value.length > 0 )
            {
                let lines = [];
                for( let i = 0; i < this.props.value.length; i++ )
                {
                    const item = this.props.value[i];
                    lines.push( 
                        <div key={"tooltipLine" + i} className="tooltipLine">
                            <span className="color" style={{ backgroundColor: ( item.color ? item.color : "#33b5e5" ) }}></span>
                            <span className="name">{ item.label }</span>
                            <span className="value">{ item.labelValue }</span>
                        </div>
                    );
                }
                return lines;
            }
            return false;
        }
    
        return this.props.tooltip;
    }

    render()
    {
    	const bars = this.getBars();

        let classes = ["apProgressbar"];
        let styles = {};

    	let warningLimit = this.props.warningLimit ? this.props.warningLimit * 100 : false;
    	let errorLimit = this.props.errorLimit ? this.props.errorLimit * 100 : false;
        // let tooltip = this.props.tooltip ? this.props.tooltip : false;

    	if( warningLimit && warningLimit > 100 ) warningLimit = false;
    	if( errorLimit && errorLimit > 100 ) errorLimit = false;

        /*
    	if( errorLimit && value < errorLimit ) 
    		classes.push("color-error");

    	else if ( warningLimit && value < warningLimit ) 
    		classes.push("color-warning");
        */

        if( this.props.loading )    classes.push("loading");

        if( typeof( this.props.height ) == "number" ) 
            styles.height = this.props.height + "px";

        return (
            <div className={ classes.join(" ") }>
            	<ApTooltip block text={ this.getTooltip() }>
	            	<div className="innerBar" style={ styles }>
	            		
                        { bars.map( ( bar, index ) => 
                            <div key={index} className="bar" style={{ 
                                width: bar.value + "%",
                                left: bar.start + "%",
                                background: bar.color,
                            }} />
                        )}
                        
	            		{ warningLimit && <div className="warningLimit" style={{ left: warningLimit + "%" }} ></div> }
	        			{ errorLimit && <div className="errorLimit" style={{ left: errorLimit + "%" }} ></div> }
	            	</div>
            	</ApTooltip>
            </div>
        );
    }
};

ApProgressbar.propTypes = {
    value:      	PropTypes.oneOfType([ PropTypes.number, PropTypes.array ]).isRequired, 
    warningLimit:  	PropTypes.number,
    errorLimit: 	PropTypes.number,
    tooltip: 		PropTypes.oneOfType([ PropTypes.string, PropTypes.bool, PropTypes.element ]),
    height:         PropTypes.number,
    loading:        PropTypes.bool,
};

ApProgressbar.defaultProps = {
    value: 		[0],
};


export default ApProgressbar;

