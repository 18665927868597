import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import moment from 'moment';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApTooltip         from 'common/ApTooltip/ApTooltip.js';
import SvgIcon     from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import { errorPopper, tr } from 'services/Helpers/Helpers';

class CompanyInterfaces extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
        	showEditModal: false,
            interfaces: [],
            inputs: [],
            ext_types: [],
            formDelete: [],
            all_interfaces: [],
            fivaldi_vats: [],
            fivaldi_posting_group_default: null,
            vats: [],
            delivery_methods:[],
            
        }
        this.tableState = {};
        autoBind(this);
    }

    componentDidMount()
    {
        this.getInterfaces();
    }

    renderSwitchbox( value, label, id, onChangeMore = null, disabled = false )
    {
        let onChange = null;
        
        let toggleValue = () => (this.handleChangeBoolean(id, value));
        
        onChange = () => { toggleValue();};

        return <div className={ "apFormGroup" + ( this.state[ value ] ? " success" : "" ) }>
            <div className="apSwitchBlock small">
                <label htmlFor={`newProject_${ value }`} className="info">
                    { label }
                </label>
                <ApSwitch
                    id={`newProject_${ id+value }`}
                    on={ this.state.inputs[id][value] }
                    onChange={ onChange }
                    disabled={ this.state.loading || disabled }
                />
            </div>
        </div>
    }
    
    renderTypeEnable( value, label, id, onChangeMore = null, disabled = false )
    {
        let onChange = null;
        
        let toggleValue = () => (this.handleChangeType(id, value));
        
        onChange = () => { toggleValue();};

        return <div className={ "apFormGroup" + ( this.state[ id+label ] ? " success" : "" ) }>
            <div className="apSwitchBlock small">
                <label htmlFor={`newProject_${ label }`} className="info">
                    { label }
                </label>
                <ApSwitch
                    id={`newProject_${ id+label }`}
                    on={ this.state.ext_types[id][value]['enabled'] }
                    onChange={ onChange }
                    disabled={ this.state.loading || disabled }
                />
            </div>
        </div>
    }

    renderVatSelect( value, label, id, onChangeMore = null, disabled = false )
    {
        return <div className="apSwitchBlock small">
                <label htmlFor={`select_vat_${ id }`}>
                   {tr('vat')} { label } % 
                </label>
                <ApInput
                    type="select"
                    options={this.getFivaldiVatOptions()}
                    id={`select_vat_${ id }`}
                    name={`select_vat_${ id }`}
                    value={ value }
                    onChange={ ( e ) => { this.selectVat( id, e.target.value ); } }
                />
            </div>
    }

    renderProcountorDeliveryMethodSelect(value,name, id, onChangeMore = null, disabled = false) {
        return <div className="apSwitchBlock small">
            <label htmlFor={`select_procountor_delivery_method_${id}`}>
                {tr('delivery_method')} {name}
            </label>
            <ApInput
                type="select"
                options={this.getProcountorDeliveryMethodOptions()}
                id={`select_procountor_delivery_method_${id}`}
                name={`select_procountor_delivery_method_${id}`}
                value={value}
                onChange={(e) => { this.selectProcountorDeliveryMethod(id, e.target.value); }}
            />
        </div>
    }

    selectVat(id, value) {
        let vats = this.state.vats.slice( 0 );
        vats = vats.map( v => {
            if ( v.id === id ) {
                v.storage_posting_code=value;
            }
            return v;
        });

		this.setState({ vats:vats });
    }
    selectProcountorDeliveryMethod(id, value) {
        let delivery_methods = this.state.delivery_methods.slice(0);
        delivery_methods = delivery_methods.map(m => {
            if (m.id === id) {
                m.procountor_delivery_method = value != "" ? value :null;
            }
            return m;
        });

        this.setState({ delivery_methods: delivery_methods });
    }
    
    getFivaldiVatOptions()
    {
        const vats = this.state.fivaldi_vats;
        
        let options = [];
        options.push({0:tr('select')});
        if( vats )
        { 
            vats.forEach( option => {
                options.push( { value: option.id, label: option.description });
            });
        }
        
        return options;
    }
    getProcountorDeliveryMethodOptions() {
        const vats = ['Postitus', 'verkossa', 'rahtikuljetus', 'kuriiripalvelu', 'VR cargo', 'linja-auto', 'noudettava'];

        let options = [];
        options.push({ 0: tr('select') });

        vats.forEach(option => {
            options.push({ value: option, label: option });
        });


        return options;
    }

    
    getInterfaces(state = null)
    {
        this.setState({loading: true});

        if(!state)
            state = this.tableState;
        this.tableState = state;

        api({
            method: 'post',
            url: 'company/settings/interfaces',
            data: { },
            errorPopper: 'Error on getting errors',
        }).then((response) => {
            // console.log( 'data', response );
            this.setState({
            	interfaces: response.interfaces,
            	inputs: response.input,
            	ext_types: response.ext_types,
                fivaldi_vats: response.fivaldi_vats!=false?response.fivaldi_vats.dtos_list:[],
                fivaldi_posting_group_default: response.fivaldi_vats!=false?response.fivaldi_vats.posting_group_default:null,
                vats: response.vats,
                delivery_methods:response.delivery_methods,
                loading: false,
            });


        }).catch((error) => {
            this.setState({loading: false});
        });
    }
    
    saveSettings() 
    {
    	//console.log(this.state);
        this.setState({ loading: true });

        api({
            method: 'post',
            url: 'company/settings/save/interfaces',
            data: {
                inputs: this.state.inputs,
                ext_types: this.state.ext_types,
                vats: this.state.vats,
                fivaldi_posting_group_default: this.state.fivaldi_posting_group_default,
                delivery_methods: this.state.delivery_methods,
            }
        }).then(( response ) => {

            this.setState({ 
                loading: false 
            });
            
        }).catch( ( error ) => {
            errorPopper(error, error.message)
            this.setState({ loading: false });
        });
    }

	handleChange( value, id, setting )
	{
		let inputs = this.state.inputs;
		inputs[id][setting] = value;
		this.setState({ inputs });
	}
	
	handleChangeBoolean( id, setting )
	{
		
		let inputs = this.state.inputs;
		if (inputs[id][setting]==true) {
			inputs[id][setting]=false;
		}
		else {
			inputs[id][setting]=true;
		}
		this.setState({ inputs });
		return inputs[id][setting];
	}
	
	handleChangeType( id, setting )
	{
		console.log( id, setting, this.state.ext_types[id][setting]);
		let ext_types = this.state.ext_types;
		if (ext_types[id][setting]['enabled']==true) {
			ext_types[id][setting]['enabled']=false;
		}
		else {
			ext_types[id][setting]['enabled']=true;
		}
		this.setState({ ext_types });
		return ext_types[id][setting]['enabled'];
	}

	createNewItem()
	{

		this.setState({ showEditModal: true });

	}

	closeEditModal()
	{

		this.setState({ showEditModal: false });

	}
	
	deleteInterfaceClick(id)
	{

		this.setState({ formDelete: {show: true, id: id} });
		console.log(this.state.formDelete);
	}
    
	
	deleteInterface()
	{
        this.setState({loading: true});

        api({
            method: 'post',
            url: 'company/settings/delete/interface',
            data: { id: this.state.formDelete.id },
            errorPopper: 'Error on getting errors',
        }).then((response) => {
            console.log( 'poistettu', response );
            this.setState({
                loading: false,
            });
            this.getInterfaces();
        }).catch((error) => {
            this.setState({loading: false});
        });
	}
	
	interfaceModalOpen()
	{
        this.setState({loading: true});

        api({
            method: 'post',
            url: 'company/settings/all/interfaces',
            data: { },
            errorPopper: 'Error on getting errors',
        }).then((response) => {
            console.log( 'all', response );
            this.setState({
            	all_interfaces: response,
                loading: false,
            });
            
        }).catch((error) => {
            this.setState({loading: false});
        });
	}
	
	interfaceAdd()
	{
		console.log("tallennusnappi painettu");
		this.setState({ showEditModal: false });
        this.setState({loading: true});
        api({
            method: 'post',
            url: 'company/settings/add/interface',
            data: this.state.addInterface,
            errorPopper: 'Error on getting errors',
        }).then((response) => {
            console.log( 'lisätty', response );
            this.getInterfaces();
        }).catch((error) => {
            this.setState({loading: false});
        });
	}
	
	interfaceSelect(inter)
	{
		console.log("valittu",inter);
		this.setState({ addInterface: inter });
	}

    syncNetvisor(type)
    {
        this.setState({loading: true,});
        api({
            method: 'get',
            url: `company/settings/sync/netvisor/${type}`
        }).then((response) => {
            this.getInterfaces()
            this.setState({loading: false,})
        }).catch((error) => {
            this.setState({loading: false})
        })
    }

    syncFivaldi()
    {
        this.setState({loading: true,});
        api({
            method: 'get',
            url: `company/settings/sync/fivaldi`,
        }).then((response) => {
            this.getInterfaces()
            this.setState({loading: false,})
        }).catch((error) => {
            errorPopper(null,tr('synchronize_error'))
            this.setState({loading: false})
        })
    }
	
    renderInterfaceAddModal()
    {
        const options = this.state.all_interfaces.filter((custom_interface) => {
            const searchExisting = this.state.interfaces.find((a) => a.interface_id === custom_interface.id)
            if (!searchExisting) return true
        })

        return <ApModal
            show={ Boolean( this.state.showEditModal ) }
            handleClose={ () => this.setState({ showEditModal: false }) }
            className="narrow overflow"

            onOpen={ this.interfaceModalOpen }
            header={
                <div className="padding">
                    {tr("add_interfaces_to_external")}
                </div>
            }
            body={ <div className="padding">
	            <ApSelect
		            label={ tr('programming') }
		            options={options}
		            value={ this.state.addInterface }
		            optionRenderer={ ( item ) => {
		                return <div>
		                    <strong>{ item.name }</strong><br />
		                </div>
		            }}
		            onChange={ ( c ) => this.interfaceSelect( c ? c : null ) }
		            objKeyId="id"
		            objKeySearchable="name"
		            objKeyValue="name"
		            clearable
		            loading={ this.state.loading }
		            disabled={ this.state.loading }
		        />
            </div> }
            footer={ <div className="padding text-right">

                    <ApButton onClick={ () => this.setState({ showEditModal: false }) }>
                        <SvgIcon icon="times" type="solid" />
                        { tr('cancel') }
                    </ApButton>

                    <ApButton color="green" onClick={ this.interfaceAdd }>
                        <SvgIcon icon="check" type="solid" />
                        { tr('continue') }
                    </ApButton>

            </div> }
        />
    }
    
	
    render()
    {
        return <div className="apBox" id="frontErrors">
            <div className="apBoxHeader">
                <h1>{ tr('interfaces_to_external') }</h1>
                <p>{ tr('interfaces_to_external') }</p>
            </div>
            {this.renderInterfaceAddModal()}
            <ApConfirm
	            show={Boolean( this.state.formDelete.show ) }
	            header={ tr('delete_notification')}
	            body={ tr('delete_notification_confirm') }
	            onConfirm={this.deleteInterface }
	            onClose={ () => this.setState({ formDelete: {} })}
	        />
			<div className="padding">
				<ApButton
					color="green"
					onClick={() => {this.createNewItem()}}
				>
	                <SvgIcon icon="plus" type="solid" />
					{ tr('add_interface')}
				</ApButton>
			</div>

            <div className="padding">
        	{this.state.interfaces.map((inter, index) => (
            		<div key={index} className="listItem">
                		<h2> {inter.interface.name} <SvgIcon onClick={(e) => {this.deleteInterfaceClick(inter.id)}} icon="trash" type="solid" /></h2>
                		<table width="100%"><tbody>
                		<tr><td>
                		{Object.keys(inter.interface_settings).map((setting, name) => (
                        		<div key={name}>
                        		{setting.includes('_boolean')?<div> 
                        		{this.renderSwitchbox(
                        				setting, 
                                        inter.interface_settings[setting],
                                        inter.id,
                                       )}</div>
                        		:
    							<ApInput
									type="text"
									name={setting + index}
									id={setting + index}
									label={inter.interface_settings[setting]}
									value={this.state.inputs[inter.id][setting]}
									onChange={(e)=>this.handleChange(e.target.value,inter.id,setting)}
								/>
                        		}
                        		</div>
                        		))}

                		</td>
                		<td>
                		{Object.values(this.state.ext_types[inter.id]).map((type, index) => (
                				<div key={index}>
                        		{this.renderTypeEnable(
                        				type.extType_id, 
                        				type.type_name,
                                        inter.id,
                                       )}
                				</div>
                		))}
                		</td>
                		</tr></tbody></table>
                        {inter.interface.type === 'fivaldi_new' &&
                            <div>
                                {Object.values(this.state.vats).map((vat) => (
                                    this.renderVatSelect( vat.storage_posting_code, vat.value, vat.id)
                                ))}
                                <div style={{marginTop: '1em', marginBottom: '10px',whiteSpace:"pre-line"}}>
                                    { tr('fivaldi_interface_info') }
                                </div>
                                <div style={{display: 'flex', marginBottom: '3em'}}>
                                    <ApButton
                                        color='blue'
                                        disabled={this.state.loading}
                                        onClick={() => this.syncFivaldi() }
                                        loading={ this.state.loading }>
                                        {tr("synchronize")}
                                    </ApButton>
                                </div>
                            </div>
                        }
                        {inter.interface.type === 'netvisor' &&
                            <div>
                                <div style={{marginTop: '1em', marginBottom: '10px'}}>
                                { tr('netvisor_interface_info') }
                                </div>
                                <div style={{display: 'flex', marginBottom: '3em'}}>
                                    <ApButton
                                        color='blue'
                                        disabled={this.state.loading || inter.netvisor_crm_synced}
                                        onClick={() => this.syncNetvisor('crm') }
                                        loading={ this.state.loading }
                                        style={{marginRight: '1em'}}>
                                        {inter.netvisor_crm_synced ? tr( 'customer_registry_synchronized')  : tr('synchronize_customer_registry')}
                                    </ApButton>
                                    <ApButton
                                        color='blue'
                                        disabled={this.state.loading || inter.netvisor_components_synced}
                                        onClick={() => this.syncNetvisor('components')  }
                                        loading={ this.state.loading }>
                                        {inter.netvisor_components_synced ? tr( 'product_registry_synchronized')  : tr('synchronize_product_registry')}
                                    </ApButton>
                                </div>
                            </div>
                    }
                    {inter.interface.type === 'procountor' &&
                        <div>
                            {Object.values(this.state.delivery_methods).map((delivery_method) => (
                                this.renderProcountorDeliveryMethodSelect(delivery_method.procountor_delivery_method,delivery_method.name, delivery_method.id)
                            ))}
                            <div style={{ marginTop: '1em', marginBottom: '10px', whiteSpace: "pre-line" }}>
                                {tr('fivaldi_interface_info')}
                            </div>
                            <div style={{ display: 'flex', marginBottom: '3em' }}>
                                {/* <ApButton
                                    color='blue'
                                    disabled={this.state.loading}
                                    onClick={() => this.syncFivaldi()}
                                    loading={this.state.loading}>
                                    {tr("synchronize")}
                                </ApButton> */}
                            </div>
                        </div>
                    }
                	</div>
                	
            	))}
			<ApButton
				color="blue"
				onClick={ this.saveSettings }
				loading={ this.state.loading }
	
				style={{marginLeft: 'auto'}}
			>
				<SvgIcon icon="save" type="solid" />
				{ tr('save') }
			</ApButton>
            </div>
        </div>
    }
}

export default CompanyInterfaces;
