import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import { Collapse } from 'react-bootstrap';

import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';

import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import ApFileUpload from 'common/ApFileUpload/ApFileUpload.js';
import File from 'common/File/File.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApModal from 'common/ApModal/ApModal.js';

import { sqlToDateInput, dateInputToSql, keyExists, tr, errorPopper, getWeekdays } from 'services/Helpers/Helpers.js';

import './EditAssignment.css';
import WorkgroupModal from 'modules/Workgroups/WorkgroupModal';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

class EditAssignment extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			loading: false,

			classes: [],
			subclasses: [],
			priorities: [],
			new_customer: false,
			newFileIds: [],
			files: [],

			hasDeadline: false,
			has_project: false,
			has_customer: false,
			has_location: false,
			project: null,
			projectWork:{},
			customer: null,
			newCustomer_street: null,
			newCustomer_postal_code: null,
			newCustomer_city: null,
			newCustomer_country: null,

			groupModalOpen: false,

			data: {
				header: null,
				description: null,
				class_id: null,
				subclass_id: null,
				priority_id: null,
				occurred_at: null,
				hours: 0,
				customer_work_number: null,
			},

			automatic_project_enabled: false,
			automatic_project_type: null,

			createNewProject: false,
			newProjectName: '',
			newProjectCode: null,

			createNewSubproject: false,
			newSubprojectName: '',

			recurrence: null,
			recurrence_period: null,
			next_recurrence: null,
			recur_reset_worker: false,
			exclude_days: [],
			addToProductionManagement: false,
		}
		autoBind(this);

	}

	componentDidMount() {
		this.getSettings();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.createNewProject && this.state.createNewProject) {
			this.setState({ loading: true });
			api({
				method: 'get',
				url: `project/type/${this.state.automatic_project_type}/nextcode`,
			}).then(response => {
				// console.log("res", response);
				this.setState({
					newProjectCode: response,
					loading: false,
				});
			}).catch((error) => {
				errorPopper(error, tr('get_error'))
				this.setState({ loading: false });
			});
		}
	}

	getSettings() {
		this.setState({ loading: true });
		api({
			method: 'get',
			url: 'assignment/getSettings',
		}).then(response => {
			// console.log("res", response);
			const automatic_project_enabled = response.automatic_project_enabled
				&& response.automatic_project_type !== null
				&& response.automatic_project_work !== null;
			this.setState({
				...response,
				automatic_project_enabled,
				automatic_project_type: response.automatic_project_type ? response.automatic_project_type.id : null,
				loading: false,
			});
		}).catch((error) => {
			errorPopper(error, tr('get_error'))
			this.setState({ loading: false });
		});
	}

	UNSAFE_componentWillMount() {

		let project_id = this.props.addToProject ? this.props.addToProject : null;
		this.setState({ project: project_id });
		let project = null;
		if (project_id != null) {
			this.setState({ loading: true });
			api({
				method: 'get',
				url: 'assignment/getProject/' + project_id
			}).then((response) => {
				this.setState({ project: response });
				if (response.root_customer != null) {
					this.customerChange(response.root_customer);
				}
				if (response.name != null) {
					let data = this.state.data;
					data['header'] = response.name;
					this.setState({ data });
				}
			}).catch((error) => {
				console.log(error);
			});
			this.setState({ loading: false });
		}

		//this.setState({ project : this.props.match.params.id  });

		//this.getClasses();
		//this.getPriorities();

	}

	onOpen() {

		//if (this.props.show=="project") {
		//this.setState({ project : this.props.match.params.id  });
		//}

		if (this.fileUploadRef)
			this.fileUploadRef.resetFiles();
	}

	handleDateChange(name, value) {
		if (name === "end_at") {
			// value = dateInputToSql(value, true);
			this.setState({ end_at: value });
		}
		if (name === "begin_at") {
			// value = dateInputToSql(value, true);
			this.setState({ begin_at: value });
		}
		if (name === "next_recurrence") {
			// value = dateInputToSql( value );
			this.setState({ next_recurrence: value });
		}
	}

	getClasses() {

		api({
			method: 'get',
			url: 'assignment/getClasses'
		}).then((response) => {
			let classes = [{ value: null, label: "" }];
			for (var i = 0; i < response.length; i++) {
				const newClass = { value: response[i].id, label: response[i].name };
				classes.push(newClass);
			}
			this.setState({ classes });
		}).catch((error) => {
			console.log(error);
		});

	}

	getSubclasses() {

		api({
			method: 'get',
			url: 'assignment/getSubclasses',
			params: { classId: this.state.data.class_id }
		}).then((response) => {
			let subclasses = [{ value: null, label: "" }];
			for (var i = 0; i < response.length; i++) {
				const newSubclass = { value: response[i].id, label: response[i].name };
				subclasses.push(newSubclass);
			}
			this.setState({ subclasses });
		})

	}

	renderSwitchbox(value, label, labelSmall = null, onChangeMore = null, disabled = false) {
		let onChange = null;

		let toggleValue = () => this.setState({ [value]: !this.state[value] })
		if (typeof onChangeMore === 'function')
			onChange = () => { toggleValue(); onChangeMore(); };
		else
			onChange = () => { toggleValue(); };

		return <div className={"apFormGroup" + (this.state[value] ? " success" : "")}>
			<div className="apSwitchBlock small">
				<label htmlFor={`newProject_${value}`} className="info">
					{label}
					<small>{labelSmall}</small>
				</label>
				<ApSwitch
					id={`newProject_${value}`}
					on={this.state[value]}
					onChange={onChange}
					disabled={this.state.loading || disabled}
				/>
			</div>
		</div>
	}

	customerChange(customer) {
		if (customer != null) {

			if (customer.phone != null) {
				let data = this.state.data;
				data['phone'] = customer.phone;
				this.setState({ data });
			}
			if (customer.addresses != null) {
				if (customer.addresses.length == 1) {
					this.setState({
						newCustomer_street: customer.addresses[0].street,
						newCustomer_postal_code: customer.addresses[0].postal_code,
						newCustomer_city: customer.addresses[0].city,
						newCustomer_country: customer.addresses[0].country,
					});

				}
				else {
					let bestAddress = 0;
					let selectedAddress = null;
					customer.addresses.map((address) => {
						if (address.id == customer.default_shipping_address_id) {
							selectedAddress = address;
							bestAddress = 3;
						}
						if (bestAddress < 3 && address.type == 'shipping') {
							selectedAddress = address;
							bestAddress = 2;
						}
						if (bestAddress < 2 && address.type == 'general') {
							selectedAddress = address;
							bestAddress = 1;
						}
					});
					if (selectedAddress != null) {
						this.setState({
							newCustomer_street: selectedAddress.street,
							newCustomer_postal_code: selectedAddress.postal_code,
							newCustomer_city: selectedAddress.city,
							newCustomer_country: selectedAddress.country,
						});
					}
				}
			}

		}
		this.setState({
			customer: customer,
		});

	}

	projectChange(project) {
		this.setState({
			project: project,
			projectWork:null,
			has_project: true,
		});
		if (project != null) {
			if (project.root_customer != null) {
				this.customerChange(project.root_customer);
			}
		}
	}

	projectWorkChange(projectWork) {
		this.setState({
			projectWork: projectWork,
		});
		
	}

	/* projectChange( project )
	 {   
		 let copyProject = { ...this.state.project };
		 this.setState( {loading: true } );
 
		 let id = null;
		 copyProject["project"]=project;
		 
		 api({
			 method: 'get',
			 url: `project/management/${ project.id }/tree/copy`,
		 }).then((response) => {
			 copyProject["original"]=response.tree[0];
			 copyProject["projectSelected"]=true;
			 this.setState({ "project" : copyProject });
			 this.setState( {loading: false } );
 
		 });
 
	 }*/


	getPriorities() {

		api({
			method: 'get',
			url: 'assignment/getPriorities'
		}).then((response) => {
			let priorities = [{ value: null, label: "" }];
			for (var i = 0; i < response.length; i++) {
				const newPriority = { value: response[i].id, label: response[i].name };
				priorities.push(newPriority);
			}
			this.setState({ priorities });
		}).catch((error) => {
			console.log(error);
		});

	}

	isFormValidated() {

		if (
			this.state.data.header !== null && this.state.data.header !== "" &&
			this.state.data.description !== null && this.state.data.description !== "" &&
			this.state.data.class_id !== null && this.state.data.class_id !== "" && !isNaN(this.state.data.class_id) &&
			this.state.data.subclass_id !== null && this.state.data.subclass_id !== "" && !isNaN(this.state.data.subclass_id) &&
			this.state.data.priority_id !== null && this.state.data.priority_id !== "" && !isNaN(this.state.data.priority_id)
		)
			return true;
		else
			return false;

	}

	selectChange(field, value) {
		this.setState({ [field]: value ? value : null });
	}

	clickCancelButton() {

		this.props.onClose();

	}

	clickSaveButton() {

		//this.props.onClose();

		let data = this.state.data;
		data.newFileIds = this.state.newFileIds;
		if (this.state.customer != null) {
			data.customer_id = this.state.customer.id;
		}
		if (this.state.project !== null) {
			data.project_id = this.state.project.id;
		}
		data.project_work_id = this.state.projectWork?.id;
		data.status_id = 1;
		if (this.state.user_id != null && !this.state.user_id.is_group) {
			data.user_id = this.state.user_id.id;
			data.status_id = 2;
			data.team_id = null;
		} else {
			data.user_id = null;
			data.status_id = 2;
			data.team_id = this.state.user_id && this.state.user_id.id;
		}
		data.new_customer = this.state.new_customer;
		if (this.state.new_customer) {
			data.new_customerName = this.state.newCustomer_name;
		}

		data.address_street = this.state.newCustomer_street ? this.state.newCustomer_street : '';
		data.address_city = this.state.newCustomer_postal_code ? this.newCustomer_postal_code : '';
		data.address_postal_code = this.state.newCustomer_city ? this.state.newCustomer_city : '';
		data.address_country = this.state.newCustomer_country ? this.state.newCustomer_country : '';

		data.begin_at = this.state.begin_at ? this.state.begin_at : '';
		data.end_at = this.state.end_at ? this.state.end_at : '';
		data.has_deadline = this.state.hasDeadline;

		data.createNewProject = this.state.createNewProject;
		data.newProjectName = this.state.newProjectName;
		data.createNewSubproject = this.state.createNewSubproject;
		data.newSubprojectName = this.state.newSubprojectName;
		data.newProjectCode = this.state.newProjectCode;
		data.recurrence = this.state.recurrence;
		data.recurrence_period = this.state.recurrence_period;
		data.next_recurrence = this.state.next_recurrence;
		data.recur_reset_worker = this.state.recur_reset_worker;
		data.exclude_days = this.state.exclude_days;
		data.addToProductionManagement = this.state.addToProductionManagement;

		api({
			method: 'post',
			url: 'assignment/new/save',
			data: this.state.data
		}).then((response) => {
			this.props.onClose();

			if ('id' in response)
				window.emitter.emit(
					'goTo',
					{
						pathname: '/assignments/' + response.id + "/" + "0"
					}
				);
		}).catch((error) => {
			console.log(error);
			errorPopper(error, tr('save_error'))
			this.setState({
				error: keyExists(error, "response.data.message", true, tr('save_error')) || tr('error'),
			});
		});


	}

	handleChange(e) {

		let data = this.state.data;
		data[e.target.name] = e.target.value;
		this.setState({ data });
	}

	handleClassChange(e) {

		const classId = e.target.value === "" ? null : parseInt(e.target.value);
		let data = this.state.data;

		data.class_id = classId;
		data.subclass_id = null;

		this.setState({
			data,
			subclasses: []
		}, () => {
			if (this.state.data.class_id !== null) this.getSubclasses();
		});

	}

	handleSubclassChange(e) {

		let data = this.state.data;
		data.subclass_id = parseInt(e.target.value);
		this.setState({ data });

	}

	handlePriorityChange(e) {

		let data = this.state.data;
		data.priority_id = parseInt(e.target.value);
		this.setState({ data });


	}
	removeFil(index) {
		let files = this.state.files.slice(0);
		files.splice(index, 1);
		this.setState({ files: files });

	}

	filesChange(files, userAction) {
		let newFileIds = files.map(f => f.id)
		this.setState({ newFileIds: newFileIds });
	}

	renderFiles() {
		const addButton = <ApFileUpload
			ref={ref => this.fileUploadRef = ref}
			files={this.state.newFiles}
			onChange={this.filesChange}
			description={"File for assignment " + this.props.id}
			maxSize={1024 * 1024 * 20} // 20 Mt
			disabled={this.state.loading}
			noFilesLabel={tr('add_files')}
			noFilesSubLabel={tr('add_assignment_files')}
			addButtonLabel={`${tr('add_file')}...`}
		/>

		const listDoms = this.state.files.map((i, index) => {
			return <div key={i.id} className="files listStack">
				<ApInputStack gap="0">
					<ApAddon>
						<File fileId={i.id} name={i.original_name}>
							<SvgIcon icon="file" type="solid" /> {i.original_name}
						</File>
					</ApAddon>
					<ApAddon noLeftBorder width="50px">
						<ApTooltip block text={tr('delete')}>
							<div className="apSimpleButton" onClick={() => { this.removeFil(index) }}>
								<SvgIcon icon="trash" type="solid" />
							</div>
						</ApTooltip>
					</ApAddon>
				</ApInputStack>
			</div>
		});
		return <div>
			{addButton}
			{listDoms}
		</div>
	}

	renderForm() {
		const hasLocationDom = this.renderSwitchbox(
			'has_location',
			tr('assignment_has_location'),
			tr('assignment_has_location_info'),
		);

		const newCustomerDom = this.renderSwitchbox(
			'new_customer',
			tr('assignment_new_customer'),
			tr('assignment_new_customer_info'),
		);

		const hasDeadlineDom = this.renderSwitchbox(
			'hasDeadline',
			tr('completion_deadline'),
			tr('completion_deadline_info'),
		);

		const addToProductionManagementDom = this.renderSwitchbox(
			'addToProductionManagement',
			tr('add_to_production_management'),
			tr('add_to_production_management_info'),
		);

		const newProjectDom = this.renderSwitchbox(
			'createNewProject',
			tr('new_main_project'),
			tr('assignment_new_project_info'),
		);

		const createSubprojectDom = this.renderSwitchbox(
			'createNewSubproject',
			tr('new_subproject'),
			'Luodaan projektille aliprojekti ja liitetään työmääräys siihen'
		);

		const newSubprojectInputs = (
			<div>
				{createSubprojectDom}
				<Collapse in={this.state.createNewSubproject}>
					<div>
						<ApInputStack>
							<ApInput
								type="text"
								id="newSubprojectName"
								name="newSubprojectName"
								label={tr('subproject_name')}
								validationState={!this.state.newSubprojectName ? "error" : ""}
								value={this.state.newSubprojectName ? this.state.newSubprojectName : ''}
								onChange={(e) => this.setState({ newSubprojectName: e.target.value })}
								loading={this.state.loading}
							/>
						</ApInputStack>
					</div>
				</Collapse>
			</div>
		)

		const LocationInfo = <Collapse in={this.state.has_location}><div>
			<ApInput
				type="text"
				id="newCustomer_street"
				name="street"
				label={tr('street_address')}
				value={this.state.newCustomer_street ? this.state.newCustomer_street : ''}
				onChange={(e) => this.setState({ newCustomer_street: e.target.value })}
			/>

			<ApInputStack>
				<ApInput
					width="140px"
					type="text"
					id="newCustomer_postal_code"
					name="postal_code"
					label={tr('postal_code')}
					value={this.state.newCustomer_postal_code ? this.state.newCustomer_postal_code : ''}
					onChange={(e) => this.setState({ newCustomer_postal_code: e.target.value })}
				/>
				<ApInput
					type="text"
					id="newCustomer_city"
					name="city"
					label={tr('post_office')}
					value={this.state.newCustomer_city ? this.state.newCustomer_city : ''}
					onChange={(e) => this.setState({ newCustomer_city: e.target.value })}
				/>
				<ApInput
					type="text"
					id="newCustomer_country"
					name="country"
					label={tr('country')}
					value={this.state.newCustomer_country ? this.state.newCustomer_country : ''}
					onChange={(e) => this.setState({ newCustomer_country: e.target.value })}
				/>
			</ApInputStack>
		</div>
		</Collapse>
		const newCustomerInfo = <div><Collapse in={this.state.new_customer}><div>
			<ApInput
				type="text"
				id="newCustomer_name"
				name="street"
				label={tr('new_customer')}
				validationState={!this.state.newCustomer_name ? "error" : ""}
				tooltip={!this.state.newCustomer_name ? tr('name_required') : false}
				value={this.state.newCustomer_name ? this.state.newCustomer_name : ''}
				onChange={(e) => this.setState({ newCustomer_name: e.target.value })}
			/>

		</div>
		</Collapse>
			<Collapse in={!this.state.new_customer}><div>
				<ApSelect
					label={tr('customer')}
					value={this.state.customer}
					optionRenderer={(item) => {
						return <div>
							<strong>{item.name}</strong><br />
							<small>{item.taxnumber}</small>
						</div>
					}}
					onChange={(c) => this.customerChange(c ? c : null)}
					objKeyId="id"
					objKeyValue="name"
					clearable
					apiUrl="project/find/customer"
					loading={this.state.loading}
					disabled={this.state.loading}
				/>
				{/* Karita special   */}
				{this.state.customer && this.state.customer.cost_center &&
					<small style={{ marginLeft: 5, marginBottom: 10, display: "block" }}>{tr('cost_center')}: {this.state.customer.cost_center.full_name} </small>}
			</div>
			</Collapse></div>

		const recurrenceOptions = [
			{ label: tr('no_recurrence'), value: '' },
			{ label: tr('daily'), value: 'daily' },
			{ label: tr('weekly'), value: 'weekly' },
			{ label: tr('monthly'), value: 'monthly' },
		];

		let recurrenceLabel = null;
		if (this.state.recurrence) {
			if (this.state.recurrence == 'weekly') {
				recurrenceLabel = tr('recur_weeks', [keyExists(this.state, 'recurrence_period', true, 1) || 1]);
			}
			else if (this.state.recurrence == 'monthly') {
				recurrenceLabel = tr('recur_months', [keyExists(this.state, 'recurrence_period', true, 1) || 1]);
			}
			else if (this.state.recurrence == 'daily') {
				recurrenceLabel = tr('recur_days', [keyExists(this.state, 'recurrence_period', true, 1) || 1]);
			}
		}
		let noWorksWarning = null;
		if (this.state.project && !this.state.project.works_count) {
			noWorksWarning = <div className="apInfo small" style={{color:'var(--clr-warning-main)'}}>
				<SvgIcon icon="exclamation-triangle" type="solid" />
				{tr('project_no_works_added')}
			</div>;
		}

		const daysOptions = getWeekdays();

		return (
			<div>
				<ApInput
					label={tr('header')}
					type="text"
					name="header"
					id="header"
					value={this.state.data.header}
					onChange={this.handleChange}
				/>
				<ApInput
					label={tr('description')}
					type="textarea"
					name="description"
					id="description"
					value={this.state.data.description}
					onChange={this.handleChange}
				/>
				{newCustomerDom}
				{newCustomerInfo}
				<ApInput
					label={tr('customer_work_number')}
					type="text"
					name="customer_work_number"
					id="customer_work_number"
					value={this.state.data.customer_work_number}
					onChange={this.handleChange}
				/>
				<ApInput
					label={tr('phone')}
					type="text"
					name="phone"
					id="phone"
					value={this.state.data.phone}
					onChange={this.handleChange}
				/>
				{this.props.apProjectSettings.automatic_project_enabled &&
					<div>
						{newProjectDom}
					</div>}
				<Collapse in={this.state.automatic_project_enabled && this.state.createNewProject}>
					<div>
						<ApInputStack>
							<ApInput
								type="text"
								id="newProjectName"
								name="newProject"
								label={tr('project_name')}
								validationState={!this.state.newProjectName ? "error" : ""}
								value={this.state.newProjectName ? this.state.newProjectName : ''}
								onChange={(e) => this.setState({ newProjectName: e.target.value })}
								loading={this.state.loading}
							/>
							{this.state.automatic_project_create_subproject &&
								<ApInput
									type="text"
									id="newSubprojectName"
									name="newSubprojectName"
									label={tr('subproject_name')}
									validationState={!this.state.newSubprojectName ? "error" : ""}
									value={this.state.newSubprojectName ? this.state.newSubprojectName : ''}
									onChange={(e) => this.setState({ newSubprojectName: e.target.value })}
									loading={this.state.loading}
								/>}
						</ApInputStack>
					</div>
				</Collapse>
				<Collapse in={!this.state.createNewProject}>
					<div>
						<ApSelect
							label={tr('project')}
							loading={this.state.loading}
							value={keyExists(this.state.project, "name", true, '')}
							optionRenderer="project"
							onChange={(c) => this.projectChange(c ? c : null)}
							objKeyId="id"
							clearable
							apiUrl="report/projects/find"
							apiData={{
								formatter: 'select',
								withParents: 'true',
								customer: this.state.customer ? this.state.customer.id : null,
								withOffers: true,
								withoutClosed: true,
								withWorksCount: true,
								withWorks: true,
								// status: 'all',
							}}
						/>
						<ApSelect
							label={tr('work')}
							loading={this.state.loading}
							value={keyExists(this.state.projectWork, "name", true, '')}
							options={this.state.project?.works}
							optionRenderer="work"
							onChange={(c) => this.projectWorkChange(c ? c : null)}
							objKeyId="id"
							objKeySearchable="name"
							objKeyValue="name"
							clearable
							
						/>
						{noWorksWarning}
						<Collapse in={this.state.automatic_project_enabled && !!this.state.project}>
							{newSubprojectInputs}
						</Collapse>
					</div>
				</Collapse>
				<ApSelect
					label={tr('assignment_worker')}
					value={this.state.user_id}
					optionRenderer="user"
					onChange={(v) => this.selectChange('user_id', v)}
					objKeyId="id"
					objKeyValue="name"
					clearable
					apiUrl="project/find/manager"
					apiData={{ withGroups: true }}
					loading={this.state.loading}
					disabled={this.state.loading}
					filterNonActives={true}
				/>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<ApButton onClick={() => this.setState({ groupModalOpen: true })} color='green'>
						{tr('new_workgroup')}
					</ApButton>
				</div>

				{hasLocationDom}
				{LocationInfo}

				<ApInputStack collapseAt={650}>
					<ApInput
						width="350px"
						type="datetimeV2"
						id="begin_at"
						name="begin_at"
						label={tr('start_date')}
						value={this.state.begin_at}
						onChange={(e) => this.handleDateChange('begin_at', e)}
						enableTime
					/>
					<ApInput
						type="number"
						id="hours"
						name="hours"
						label={`${tr('duration')} (h)`}
						value={this.state.data.hours}
						onChange={this.handleChange}

						loading={this.state.loading}
						disabled={this.state.loading}
					/>
				</ApInputStack>
				<ApInputStack collapseAt={650}>
					<ApInput
						width="350px"
						type="datetimeV2"
						id="end_at"
						name="end_at"
						label={tr('completion_date')}
						value={this.state.end_at}
						onChange={(e) => this.handleDateChange('end_at', e)}
						enableTime
					/>
					{hasDeadlineDom}
				</ApInputStack>
				{addToProductionManagementDom}
				<ApSelect
					label={tr('recurrence')}
					value={this.state.recurrence ? tr(this.state.recurrence) : tr('no_recurrence')}
					options={recurrenceOptions}
					optionRenderer='label'
					objKeyId="value"
					objKeySearchable="label"
					valueRenderer="label"
					onChange={e => this.setState({ recurrence: e.value })}
					clearable
					loading={this.state.loading}
					disabled={this.state.loading}
				/>
				<Collapse in={!!this.state.recurrence}>
					<div>
						<ApInputStack>
							<ApInput
								label={recurrenceLabel}
								type='number'
								id='recurrence_period'
								name='recurrence_period'
								value={keyExists(this.state, 'recurrence_period', true, 1)}
								onChange={this.handleRecurrencePeriodChange}
								width='350px'
							/>
							{this.renderSwitchbox(
								'recur_reset_worker',
								tr('recur_reset_worker'),
								tr('recur_reset_worker_info'),
							)}
						</ApInputStack>
						<ApInput
							width="350px"
							type="datetimeV2"
							id="next_recurrence"
							name="next_recurrence"
							label={tr('next_recurrence')}
							value={this.state.next_recurrence}
							onChange={(e) => this.handleDateChange('next_recurrence', e)}
						/>
						{this.state.recurrence === 'daily' &&
							<>
								<ApSelect
									multiselect
									clearable
									options={daysOptions}
									optionRenderer="label"
									objKeyId="value"
									objKeySearchable="label"
									valueRenderer="label"
									value={this.state.exclude_days || []}
									onChange={this.handleExcludeDaysChange}
									loading={this.state.loading}
									disabled={this.state.loading}
									label={tr('exclude_days')}
								/>
								<div className="apInfo small">
									<SvgIcon icon="info-circle" type="solid" />
									{tr('exclude_days_info')}
								</div>
							</>}
					</div>
				</Collapse>

				{this.renderFiles()}
			</div>
		);

	}

	handleRecurrencePeriodChange(e) {
		let { value } = e.target;
		if (value < 1) {
			value = 1;
		}
		let duration = null;
		const recurrence_period = Math.round(value);
		if (this.state.recurrence == 'weekly') {
			duration = {
				'weeks': value
			}
		} else if (this.state.recurrence == 'monthly') {
			duration = {
				'months': value
			}
		} else if (this.state.recurrence == 'daily') {
			duration = {
				'days': value > 6 ? 6 : value
			}
		}
		const next_recurrence = moment().add(duration).format('YYYY-MM-DD');
		this.setState({ recurrence_period, next_recurrence });
	}

	handleExcludeDaysChange(days) {
		if (days.length >= 7) {
			errorPopper(null, tr('too_many_days_selected'));
		} else {
			const orderedDays = this.handleExcludeDaysOrder(days);
			this.setState({ exclude_days: orderedDays });
		}
	}

	handleExcludeDaysOrder(days) {
		let sunday = null
		const sundayIndex = days.findIndex(day => day.value === 0);
		if (sundayIndex > -1) {
			sunday = days.splice(sundayIndex, 1)[0];
		}
		const orderedDays = _.orderBy(days, ['value'], ['asc']);
		if (sunday) {
			orderedDays.push(sunday);
		}
		return orderedDays;
	}

	handleNewGroup(newGroup) {
		let group = newGroup[0];
		group.is_group = true;
		this.setState({
			user_id: group,
		});
	}

	closeGroupModal() {
		this.setState({ groupModalOpen: false });
	}

	renderWorkgroupModal() {
		return (
			<WorkgroupModal
				show={this.state.groupModalOpen}
				closeModal={this.closeGroupModal}
				onSave={this.handleNewGroup}
			/>
		);
	}

	render() {

		return (

			<div>

				<ApModal
					show={this.props.show}
					onClose={this.clickCancelButton}
					handleClose={this.clickCancelButton}
					backdrop="static"
					className="sendModal"
					header={
						<div className="padding">
							<h3>{tr('new_assignment')}</h3>
						</div>
					}

					body={<div className="padding">{this.renderForm()}</div>}

					footer={


						<div id="newAssignmentFooter">


							<ApButton className="cancel" onClick={() => { this.clickCancelButton() }}>
								{tr('cancel')}
							</ApButton>

							<ApButton className="applyButton" color="green"
								onClick={() => { this.clickSaveButton() }}
							>
								<SvgIcon icon="save" type="solid" />
								{tr('save')}
							</ApButton>

						</div>
					}

				/>
				{this.renderWorkgroupModal()}
			</div>

		);

	}

}

const mapStateToProps = state => ({
	apProjectSettings: state.apProjectSettings
});

export default connect(mapStateToProps)(EditAssignment);
