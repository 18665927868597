import React from 'react';
import autoBind from 'react-autobind';

import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApValidate         from 'services/ApValidate/ApValidate.js';
import ApModal from 'common/ApModal/ApModal.js';
import { tr } from 'services/Helpers/Helpers.js';

import { ApInput
       , ApInputStack
       , ApAddon }     from 'common/ApInput/ApInput.js';

import './RelatedSelectModal.css';

class RelatedSelectModal extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            value: null,
        };

        this.validator = new ApValidate(this, {
        });

        autoBind(this);
    }

    resetState()
    {
        this.setState({
            value: this.props.value,
        });
    }

    close()
    {
        this.props.handleClose({})
    }


    save()
    {
        const field = this.props.field;
        const id = parseInt( this.state.value, 10 );
        const selected = this.props.options.find( s => id === s.id );

        this.props.handleClose({
            [ `${ field }_id` ]: selected ? selected.id : null,
            [ `${ field }_name` ]: selected ? selected.name : null,
        });
    }

    renderBody()
    {
        const field = this.props.field;
        let title = this.props.title;
        let validationState = null;

        if( this.props.required )
        {
            title = title + " *";

            if( !this.state.value )
                validationState = 'error';
        }

        if( !this.props.options ) return null;

        const options = this.props.options.map( item => {
            return {value: item.id, label: item.name };
        });
        options.unshift( { value: null, label: ''} );

        return <ApInputStack gap="0">
            <ApAddon
                noRightBorder width="200px"
                validationState={ validationState }
            >
                { title }
            </ApAddon>
            <ApInput
                type="select"
                options={ options }
                id={ `${ field }-select` }
                name={ `${ field }-select` }

                value={ this.state.value }
                onChange={ ( e ) =>  this.setState({ value: e.target.value } ) }
                autoComplete="off"
                validationState={ validationState }
            />
        </ApInputStack>

    }

    render()
    {
        const saveDisabled = ( this.props.required && !this.state.value );

        return <div className="poRelatedEdit">
            <ApModal
                show={ this.props.show }
                handleClose={ this.props.handleClose }
                className="narrow"
                onOpen={ this.resetState }
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('edit') } { this.props.title }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton onClick={ this.close }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton color="green" onClick={ this.save } disabled={ saveDisabled }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('continue') }
                        </ApButton>
                    </div>
                }
            />
        </div>

    }
}

export default RelatedSelectModal;
