import React from 'react';
import autobind from 'react-autobind';
import api from 'services/Api/Api.js';
import { errorPopper, tr } from 'services/Helpers/Helpers';
import { Tree, TreeNode } from 'react-organizational-chart';
import ApOrganizationChart from 'common/ApOrganizationChart/ApOrganizationChart';

class OrganizationalChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tree: []
        }
        autobind(this);
    }

    componentDidMount() {
        this.getOrgChart();
    }

    getOrgChart() {
        this.setState({ loading: true });
		api({
            method: 'get',
            url: 'currentUser/organizationalchart/get',
        }).then((response) => {
            this.setState({ 
            	tree: response,
            	loading: false,
            });
        }).catch((error) => {
        	console.log( error );
            errorPopper(error, tr('get_error'));
        	this.setState({ 
        		loading: false
        	});
        });
    }

    render() {
        return (
            <div id='user-organizational-chart' className='padding'>
                <ApOrganizationChart tree={this.state.tree} loading={this.state.loading} showPrint />
            </div>
        )
    }
}

export default OrganizationalChart;