/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import { keyExists, tr } from 'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
// import ApButton from 'common/ApButton/ApButton.js';
// import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';

// import { truncate } from  'services/Helpers/Helpers.js';

import { Collapse } from 'react-bootstrap';


export default class AbsenceEntry extends React.Component {

	constructor(props) 
    {
        super(props);
        autoBind(this);
    }

    // Imitate input event by wrapping value to "e.target.value" object
    eventify( value )
    {
        return { target: { value: value } };
    }

	getAbsenceOptions()
	{
		let options = [];
        const absences = keyExists( this.props, "absences", true, [] );
        const user = keyExists( this.props, "user", true, [] );
        const flexTimeAllowed = user.flex_time_allowed;
        let flexiUsage;
		absences.forEach( a => {
            if( !a.flexitime_usage )
    			options.push({
    				value: a.id,
    				label: a.name
    			});
            else 
                flexiUsage = {
                    value: a.id,
                    label: a.name
                };
		});

        if( flexTimeAllowed )
            options.push( flexiUsage );

		return options;
	}

    validateDescription()
    {
        const absenceId = keyExists( this.props, "data.detail.absence_id", true );
        const absence = keyExists( this.props, "absences", true, [] ).find( a => a.id == absenceId );
        const desc = keyExists( this.props, "data.description", true, '' );
        if( absence.is_entry_description_required && !desc )
            return false;

        return true;
    }

    roundHours( e )
    {
        // Round hours to nearest 15 min (.25, .5, .75)
        const roundedValue = Math.round( e.target.value * 4 ) / 4;
        if( e.target.value != roundedValue )
            this.props.onChange( this.eventify( roundedValue ), "detail.hours" );
    }

    validHours()
    {
        const hours = keyExists( this.props, "data.detail.hours", true, 0 );
        if( hours > 0 && hours <= 24 )
            return "";

        return "error";
    }


    render()
    {
        const removableStatuses = [ "open", "suggested" ];
        let allDayDisabled = keyExists( this.props, "dayEntries", true, [] ).findIndex( e => removableStatuses.indexOf( e.status ) == -1 ) != -1;
        
        if( !this.props.hoursDaily )
            allDayDisabled = true;

        const allDay = allDayDisabled ? false : keyExists( this.props, "data.detail.is_all_day", true, false );
        const otherEntries = keyExists( this.props, "dayEntries", true, [] ).filter( e => e.id != this.props.data.id );
        
        

        return (
            <div>
                <div className={ "apFormGroup" + ( allDay ? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor="absence-all-day-switch" className="info">
                            { tr('full_day_absence') }
                            <small>{ tr('full_day_absence_note') }</small>
                            { allDayDisabled && this.props.hoursDaily &&
                                <small style={{ color: "#ff4444" }}>{ tr('full_day_absence_locked_note') }</small>
                            }
                            { allDayDisabled && !this.props.hoursDaily &&
                                <small style={{ color: "#ff4444" }}>{ tr('full_day_absence_regular_note') }</small>
                            }
                        </label>
                        <ApSwitch
                            id="absence-all-day-switch"
                            on={ allDay }
                            disabled={ allDayDisabled }
                            onChange={ () => this.props.onChange( this.eventify( !this.props.data.detail.is_all_day ), "detail.is_all_day" ) }
                        />
                    </div>
                </div>

                <ApInput 
                    type="select" 
                    id="absence_id" 
                    name="absence_id" 
                    value={ keyExists( this.props, "data.detail.absence_id", true, '' ) }
                    options={ this.getAbsenceOptions() }
                    onChange={ ( e ) => this.props.onChange( e, "detail.absence_id" ) }
                />

                <ApInput 
                    type="text" 
                    id="description" 
                    name="description" 
                    label={ tr('absence_description') }
                    value={ keyExists( this.props, "data.description", true, '' ) }
                    onChange={ this.props.onChange }
                    validationState={ this.validateDescription() ? "" : "error" }
                />

                <Collapse in={ !allDay }>
                    <div>
                        <ApInputStack gap="0">
                            <ApAddon noRightBorder>
                                { tr('absence_duration') }
                            </ApAddon>
                            <ApInput 
                                width="150"
                                type="number" 
                                id="hours" 
                                name="hours"
                                align="right"
                                value={ keyExists( this.props, "data.detail.hours", true, '' ) }
                                onChange={ ( e ) => this.props.onChange( e, "detail.hours" ) }
                                onBlur={ this.roundHours }
                                disabled={ allDay }
                                validationState={ this.validHours() }
                            />
                            <ApAddon noLeftBorder width="50">
                                h
                            </ApAddon>
                        </ApInputStack>        
                    </div>
                </Collapse>

                <Collapse in={ allDay && otherEntries.length > 0 }>
                    <div>
                        <div className="apWarningMsg">
                            <SvgIcon className="small-inline" icon="exclamation-triangle" type="solid" />
                            { tr('full_day_absence_warning') }
                        </div>
                    </div>
                </Collapse>

            </div>
        );
    }

}
