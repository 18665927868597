import React from 'react'

import ApSteps from 'common/ApSteps/ApSteps.js';
import { tr } from 'services/Helpers/Helpers.js'
import './Steps.css';

// Icons
const iEdit     = 'edit';
const iOffer    = 'envelope';
const iReserved = 'clipboard-check';
const iDelivery = 'truck';
const iSent     = 'flag';
const iPaid     = 'money-bill-wave-alt';
const iArchive  = 'archive';

class Steps extends React.Component {

    getDeliveryNumber(deliveries, delivery) {
        // if (delivery['isReturned'] == true) {
        //     return delivery['number'];
        // }
       
        if (delivery['origin_id'] == null) {
            return delivery['number'];
        }
        for (var i=0; i< deliveries.length; i++) {
            if (deliveries[i]['id'] == delivery['origin_id']) {
                return deliveries[i]['number'];
            }
        }
        return "";
    }

    getDeliverySteps()
    {
        const status = this.props.status;
        const paid = this.props.paid;
        const archived = this.props.archived;

        let deliveries = [];
        if( this.props.deliveries )
        {
            deliveries = this.props.deliveries.map( d => {
                var number =this.getDeliveryNumber( this.props.deliveries,d);
                return {                   
                    icon: iDelivery,
                    name: (d.origin_id==null ? tr('shipment') : tr('restore')) + " " + number ,
                    success: true,
                    description: d.date,
                    return:  d.origin_id==null ? false:true,
                    isReturned: d.isReturned,                    
                    onClick: () =>  {
                        window.emitter.emit('goTo', { pathname: `/storage/logistic/delivery/show/${d.id}`}); 
                    }
                };
            });
        }

        if( status === 'archived' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success: true },
                { icon: iOffer, name: tr('order_confirmation'), success: true },  ...deliveries,
                { icon: iSent, name: tr('sent'), success: true },
                { icon: iPaid, name: tr('paid'), success: paid, selected: paid },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'sent_all' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success: true }, 
                { icon: iOffer, name: tr('order_confirmation'), success: true }, ...deliveries,
                { icon: iSent, name: tr('sent'), success: true },
                { icon: iPaid, name: tr('paid'), success: paid, selected: !paid },
                { icon: iArchive, name: tr('archiving'), success: archived, selected: paid },
            ];
        }
        if( status === 'available_all' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success:  true },
                { icon: iOffer, name: tr('order_confirmation'), success: true }, ...deliveries,
                { icon: iReserved, name: tr('ready_to_ship'), selected: true },
                { icon: iSent, name: tr('sent')},
                { icon: iPaid, name: tr('paid'), success: paid, selected: paid },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'available_some' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success:  true }, 
                { icon: iOffer, name: tr('order_confirmation'), success: true }, ...deliveries,
                { icon: iReserved, name: tr('partially_ready_to_ship'), selected: true },
                { icon: iSent, name: tr('sent')},
                { icon: iPaid, name: tr('paid'), success: paid, selected: paid },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'available_none' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success:  true },
                { icon: iOffer, name: tr('order_confirmation'), success: true}, ...deliveries,
                { icon: iReserved, name: tr('waiting_for_goods'), selected: true },
                { icon: iSent, name: tr('sent')},
                { icon: iPaid, name: tr('paid'), success: paid, selected: paid },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'offer' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success:  true },
                { icon: iOffer, name: tr('order_confirmation'), selected: true}, ...deliveries,
                { icon: iReserved, name: tr('ready_to_ship') },
                { icon: iSent, name: tr('sent') },
                { icon: iPaid, name: tr('paid'), success: paid, selected: paid },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'open' )
        {
            return [
                { icon: iEdit, name: tr('editing'), selected: true },
                { icon: iOffer, name: tr('order_confirmation') }, ...deliveries,
                { icon: iReserved, name: tr('ready_to_ship') },
                { icon: iSent, name: tr('sent') },
                { icon: iPaid, name: tr('paid'), success: paid, selected: paid },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }

        return [
            { icon: iEdit, name: tr('editing'), },
            { icon: iOffer, name: tr('order_confirmation') }, ...deliveries,
            { icon: iReserved, name: tr('ready_to_ship') },
            { icon: iSent, name: tr('sent') },
            { icon: iPaid, name: tr('paid') },
            { icon: iArchive, name: tr('archiving'), success: archived },
        ];
    }

    getSteps()
    {
        const status = this.props.status;
        const paid = this.props.paid;
        const archived = this.props.archived;

        if( status === 'archived' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success: true },
                { icon: iOffer, name: tr('order_confirmation'), selected: true },
                { icon: iPaid, name: tr('paid'), success: paid, selected: !paid },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'sent_all' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success: true },
                { icon: iOffer, name: tr('order_confirmation'), success: true },
                { icon: iPaid, name: tr('paid'), success: paid, selected: !paid },
                { icon: iArchive, name: tr('archiving'), success: archived, selected: paid },
            ];
        }
        if( status === 'open' )
        {
            return [
                { icon: iEdit, name: tr('editing'), selected: true },
                { icon: iOffer, name: tr('order_confirmation') },
                { icon: iPaid, name: tr('paid') },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'offer' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success: true },
                { icon: iOffer, name: tr('order_confirmation'), selected: true  },
                { icon: iPaid, name: tr('paid') },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        
        return [
            { icon: iEdit, name: tr('editing'), },
            { icon: iOffer, name: tr('order_confirmation') },
            { icon: iPaid, name: tr('paid') },
            { icon: iArchive, name: tr('archiving'), success: archived },
        ];
    }

    render()
    {
        let steps = [];

        if( this.props.type === 'delivery' )
            steps = this.getDeliverySteps();
        else 
            steps = this.getSteps();

        return <div id="orderSteps">
            <ApSteps
                steps={ steps }
                flatEnd
            />
        </div>
    }
}

Steps.propTypes = {

};

export default Steps;
