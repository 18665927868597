import React from 'react';
import autoBind from 'react-autobind';
import { debounce } from 'throttle-debounce';

import auth            from 'services/Authed/Authed.js';
import ApSelect        from 'common/ApSelect/ApSelect.js';
import SvgIcon         from 'common/SvgIcon/SvgIcon.js';
import { formatMoney
       , sqlToDateInput
       , tr } from 'services/Helpers/Helpers.js';

import { poStatusBoxColor
       , getTypeName
       , getTypeIcon }  from 'modules/Storage/common/StorageHelpers.js';

import ArrivalList     from './ArrivalList/ArrivalList.js';

import './Arrivals.css';

class Arrivals extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            results: [],
            selected: '',

            currencySign: auth.getCurrencySign(),
        };
        autoBind(this);
        this.getArrivalSelectItemsDebounced = debounce( 200, this.getArrivalSelectItems );
    }

    goToEdit( path )
    {
        window.emitter.emit('goTo', {
            pathname: `/storage/logistic/arrival/${path}`,
        });
    }

    onSelect( item, index )
    {
        this.goToEdit(`${ item.type }/${ item.id }`)
        this.setState({ selected: item.number });
    }

    renderFindOption( item )
    {
        let numberDom = null;
        if( item.number )
        {
            numberDom = <span>
                <span className="title">{ tr('number') }:</span>
                <span className="value">{ item.number }</span>
            </span>
        }

        let statusDom = null;
        let relatedDom = null;
        if( item.type === 'po')
        {
            statusDom = <div className="status">
                <div className={`apStatusBox ${ poStatusBoxColor( item.status_name ) }`}> { tr(item.status_title) }</div>
            </div>
        }
        else if( item.type === 'delivery')
        {
            relatedDom = <div className="related">
                <div className="title">{ tr('related') }</div>
                <div className="valueContainer">
                    <span className="type">{ getTypeName( item.related_type ) }</span>
                    <span className="value">{ item.related_title }</span>
                </div>
            </div>
        }
        return <div className="arrivalFindItem">
            <div className="createdContainer">
                <span className="title">{ tr('created_at') }:</span>
                <span className="value">{ sqlToDateInput( item.date ) }</span>
            </div>
            { statusDom }
            { relatedDom }
            <div className="typeContainer">
                <span className="type">{ getTypeName( item.type ) }</span>
                { numberDom }
            </div>
            <table className="detailTable">
                <tbody>
                    <tr>
                        <td className="title">{ tr('recipient') }</td>
                        <td className="value">
                            <SvgIcon icon={ getTypeIcon( item.receiver_type ) } type="solid" />
                            { item.receiver_title }
                        </td>
                    </tr>
                    <tr>
                        <td className="title">{ tr('sender') }</td>
                        <td className="value">
                            <SvgIcon icon={ getTypeIcon( item.sender_type ) } type="solid" />
                            { item.sender_title }
                        </td>
                    </tr>

                    <tr>
                        <td className="title">{ tr('order_creator') }</td>
                        <td className="value">{ item.created_by }</td>
                    </tr>

                    <tr>
                        <td className="title">{ tr('value') }</td>
                        <td className="value bold">
                            { formatMoney( item.price, 2 ) } { this.state.currencySign }
                        </td>
                    </tr>
                    <tr>
                        <td className="title">{ tr('rows_count') }</td>
                        <td className="value">{ item.components_count }</td>
                    </tr>
                </tbody>
            </table>
        </div>
    }

    render()
    {
        return (
            <div className="apBox" id="pageArrivals">
                <div className="apBoxHeader">
                    <h1>
                        { tr('logistics_arrivals') }
                    </h1>
                    <p> { tr('logistics_arrivals_info') } </p>
                </div>

                <div className="padding">

                    <div className="apForm">
                        <h3 className="apFormHeader">{ tr('new_reception') }</h3>
                        <div className="apFormColumn fullColumn">
                            <div>
                                <ApSelect
                                    label={ tr('search_to_receive') }
                                    value=""
                                    onChange={ this.onSelect }
                                    objKeyId="id"
                                    apiUrl="storage/ar/find"
                                    optionRenderer={ this.renderFindOption }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="apForm">
                        <h3 className="apFormHeader">{ tr('previous_receptions') }</h3>
                        <div className="apFormColumn fullColumn">
                            <ArrivalList />
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Arrivals;
