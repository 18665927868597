import ApReactTable from "common/ApReactTable/ApReactTable";
import React from "react";
import autobind from "react-autobind";
import { getExcel, mapTree, tr } from "services/Helpers/Helpers";
import _ from "lodash";
import moment from "moment";

class TrackingReportsMassList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            components: [],
            identifiers: [],
            loading: false,
        };
        autobind(this);
    }

    componentDidMount() {
        this.parseData();
    }
    
    componentDidUpdate(prevProps) {
        if ((prevProps.project && prevProps.project.id) != (this.props.project && this.props.project.id)) {
            this.parseData();
        }
    }

    parseData() {
        let components = [];
        let identifiers = [];

        mapTree([this.props.project], item => {
            const projectComponents = _.cloneDeep(item.components);
            projectComponents.forEach(project_component => {
                project_component.component.identifiers.forEach(identifier => {
                    if (!identifiers.find(i => i.id === identifier.id)) identifiers.push(identifier);
                });
            });
            return item;
        })

        mapTree([this.props.project], item => {
            const projectComponents = _.cloneDeep(item.components);
            projectComponents.forEach(project_component => {
                identifiers.forEach(identifier => {
                    const foundIdentifier = project_component.component.identifiers.find(i => i.id === identifier.id);
                    if (foundIdentifier) {
                        project_component[`identifier-${identifier.id}`] = foundIdentifier.pivot.value;
                    } else {
                        project_component[`identifier-${identifier.id}`] = null
                    }
                })

                const foundIndex = components.findIndex(project_c => project_c.component.id === project_component.component.id);
                if (foundIndex == -1) {
                    if (!project_component.alloc_count) project_component.alloc_count = 0
                    if (!project_component.installed_count) project_component.installed_count = 0
                    components.push(project_component);
                } else {
                    components[foundIndex].alloc_count = project_component.alloc_count 
                        ? Number(components[foundIndex].alloc_count) + Number(project_component.alloc_count) 
                        : components[foundIndex].alloc_count ? Number(components[foundIndex].alloc_count) : 0;
                    components[foundIndex].installed_count = project_component.installed_count 
                        ? Number(components[foundIndex].installed_count) + Number(project_component.installed_count) 
                        : components[foundIndex].installed_count ? Number(components[foundIndex].installed_count) : 0;
                }
            })
            return item;
        })

        components = _.orderBy(components, "name", "asc")
        identifiers = _.orderBy(identifiers, "name", "asc")

        this.setState({components, identifiers})
    }

    getColumns() {
        const columns = [
            {
				id: 'name',
				Header: tr('name'),
				accessor: 'name',
				customizable: false,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('name'),
                },
			},
            {
				id: 'alloc_count',
				Header: tr('allocated'),
				accessor: props => props.alloc_count ? Number(props.alloc_count) : 0,
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('allocated'),
                },
			},
            {
				id: 'installed_count',
				Header: tr('installed'),
				accessor: props => props.installed_count ? Number(props.installed_count) : 0,
				customizable: true,
				showInitially: true,
				customFilter: {
                    type: "text",
                    placeholder: tr('installed'),
                },
			},
        ];

        if (this.state.identifiers) {
            this.state.identifiers.forEach(identifier => {
                columns.push({
                    id: 'identifier-'+identifier.name,
                    Header: `${tr('identifier')} - ${identifier.name}`,
                    accessor: `identifier-${identifier.id}`,
                    customizable: true,
                    showInitially: true,
                    customFilter: {
                        type: "text",
                        placeholder: '',
                    },
                })
            })
        }

        return columns;
    }

    async getExcel() {
        if (!this.state.components.length) return;
        const data = _.cloneDeep(this.state.components);
        const excelData = data.map(row => {
            const identifiers = {};
            this.state.identifiers.forEach(identifier => {
                if (row['identifier-'+identifier.id]) {
                    identifiers[identifier.name] = row['identifier-'+identifier.id];
                } else {
                    identifiers[identifier.name] = null;
                }
            })

            return {
                storage_component: row.name,
                allocated: row.alloc_count ? Number(row.alloc_count) : 0,
                installed: row.installed_count ? Number(row.installed_count) : 0,
                ...identifiers,
            }
        });

        this.setState({ loading: true });
        await getExcel(excelData, `${tr('mass_list')} ${this.props.project.name} ${moment().format("DD.MM.YYYY")}`, tr('mass_list'));
        this.setState({ loading: false });
    }

    render() {
        return (
            <ApReactTable 
                data={this.state.components}
                columns={this.getColumns()}
                rememberId={`TrackingReportsMassList`}
                multiselect={[
                    {
                        icon: 'file-excel',
                        label: tr('create_excel'),
                        action: this.getExcel,
                        unselectAfter: false,
                        closeAfter: true,
                        alwaysOn: true,
                    }
                ]}
                showFiltersInitially={true}
                filterable
            />
        );
    }
}

export default TrackingReportsMassList;