import React, { useState } from "react";

import ApSwitch from "common/ApSwitch/ApSwitch";
import SvgIcon from "common/SvgIcon/SvgIcon.js";
import { tr, priceToProfitPercent, formatMoney } from "services/Helpers/Helpers.js";

import styles from "./CalculationsTable.module.css";

const CalculationsTable = (props) => {
  const [subprojectsIncluded, setSubprojectsIncluded] = useState(true);

  const group = subprojectsIncluded ? "all" : "own";
  const selectedGroup = props.selectedProject.calculated
    ? props.selectedProject.calculated[group]
    : "";

  // Totals row
  const totalRow = {
    name: tr("total"),
    accessor: "",
    // empty string as fallback if parent component is still fetching data
    hours: props.selectedProject.calculated
      ? parseFloat(selectedGroup.total.hours).toFixed(1) + " h"
      : "",
    profit: props.selectedProject.calculated
      ? parseFloat(selectedGroup.total.profit_all).toFixed(2)
      : "",
    costPrice: props.selectedProject.calculated
      ? parseFloat(selectedGroup.total.price_all).toFixed(2)
      : "",
    offerPrice: props.selectedProject.calculated
      ? parseFloat(selectedGroup.total.price_sell_all).toFixed(2)
      : "",
  };

  // Work row
  const workRow = {
    name: tr("work"),
    accessor: "work",
    icon: "user-clock",
    hours: props.selectedProject.calculated
      ? parseFloat(selectedGroup.total.hours).toFixed(1) + " h"
      : "",
    profit: props.selectedProject.calculated
      ? parseFloat(selectedGroup.work.profit_all).toFixed(2)
      : "",
    costPrice: props.selectedProject.calculated
      ? parseFloat(selectedGroup.work.price_all).toFixed(2)
      : "",
    offerPrice: props.selectedProject.calculated
      ? parseFloat(selectedGroup.work.price_sell_all).toFixed(2)
      : "",
  };

  // Item row
  const itemRow = {
    name: tr("components_alt"),
    accessor: "item",
    icon: "puzzle-piece",
    hours: "",
    profit: props.selectedProject.calculated
      ? parseFloat(selectedGroup.item.profit_all).toFixed(2)
      : "",
    costPrice: props.selectedProject.calculated
      ? parseFloat(selectedGroup.item.price_all).toFixed(2)
      : "",
    offerPrice: props.selectedProject.calculated
      ? parseFloat(selectedGroup.item.price_sell_all).toFixed(2)
      : "",
  };

  // Other expenses row
  const otherRow = {
    name: tr("expenses"),
    accessor: "other",
    icon: "money-bill-wave",
    hours: "",
    profit: props.selectedProject.calculated
      ? parseFloat(selectedGroup.other.profit_all).toFixed(2)
      : "",
    costPrice: props.selectedProject.calculated
      ? parseFloat(selectedGroup.other.price_all).toFixed(2)
      : "",
    offerPrice: props.selectedProject.calculated
      ? parseFloat(selectedGroup.other.price_sell_all).toFixed(2)
      : "",
  };

  const toggleHandler = () => {
    setSubprojectsIncluded((state) => !state);
  };

  const renderRows = () => {
    const rows = [workRow, itemRow, otherRow, totalRow];
    return rows.map((row) => (
      <tr key={row.name}>
        <td>
          {row.icon && (
            <SvgIcon icon={row.icon} type="solid" className="icon" />
          )}
        </td>
        <td>{row.name}</td>
        <td className="text-right">{row.hours}</td>
        <td className="text-right">{formatMoney(row.profit)} €</td>
        <td className="text-right">
          {row.offerPrice > 0 && totalRow.offerPrice > 0 ? (
            100 -
            priceToProfitPercent(
              parseFloat(row.offerPrice),
              parseFloat(totalRow.offerPrice)
            )
          ).toFixed(1) + "%" : "0%"}
        </td>
        <td className="text-right">{formatMoney(row.costPrice)} €</td>
        <td className="text-right">{formatMoney(row.offerPrice)} €</td>
      </tr>
    ));
  };

  return (
    <div className={styles.calculationsTable}>
      <div className={styles.calculationsHeader}>
        <h5>{tr("calculation")}</h5>
        {/* Only show toggle if the project has subprojects */}
        {props.selectedProject.children.length > 0 && (
          <div className={styles.toggleContainer}>
            <label htmlFor="include-subprojects-toggler" className="info">
              <small>{tr("subprojects_included")}</small>
            </label>
            <ApSwitch
              id={"include-subprojects-toggler"}
              small
              on={subprojectsIncluded}
              onChange={toggleHandler}
              disabled={false}
            />
          </div>
        )}
      </div>
      <table className="componentTable">
        <thead>
          <tr className="componentHeader">
            <th></th>
            <th>{tr("name")}</th>
            <th className="text-right">{tr("number_of_working_hours")}</th>
            <th className="text-right">{tr("gross_profit_price")}</th>
            <th className="text-right">%-osuus</th>
            <th className="text-right">{tr("cost_price")}</th>
            <th className="text-right">{tr("offer_price")}</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default CalculationsTable;
