/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import {matchSorter} from 'match-sorter';
import auth from 'services/Authed/Authed.js';

import api           from 'services/Api/Api.js';
import { keyExists,
         errorPopper,
         sqlToDateInput,
         pluralizeCount,
         tr } from 'services/Helpers/Helpers.js';
         
import ApReactTable  from 'common/ApReactTable/ApReactTable.js';
import ApSwitch             from 'common/ApSwitch/ApSwitch.js';

import SvgIcon       from 'common/SvgIcon/SvgIcon.js';
import UserEdit from 'modules/UserManagement/UserEdit//UserEdit.js';

import './UserTable.css';

/*
TODO features: 
    - Add optional columns
    - More multiselect functions for rows (enable/disable user, add group, etc.)
    - ...
*/

class UserManager extends React.Component
{

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            users: [],
            columns: [
                {
                    Header: `${tr('person')} / ${tr('username')}`,
                    columns: [
                        { 
                            id: 'is_active', 
                            Header: tr('status'), 
                            align: "center", 
                            width: 150, 
                            Cell: (props) => {
                                let status = "error";
                                let text = tr('inactive');
                                if( props.original.is_active && props.original.is_login_allowed )
                                {
                                    status = "success";
                                    text = tr('active');
                                }
                                else if ( props.original.is_active )
                                {
                                    status = "warning";
                                    text = tr('invalid');
                                }
                                return <div className={ "apStatusBox " + status }>{ text }</div>;
                                    
                            }, 
                            accessor: ( props ) => {
                                return props.is_active + props.is_login_allowed;
                            }, 
                            filterMethod: ( filter, row ) => {
                                if( filter.value === "all" )
                                    return true;
                                if( filter.value === "active" )
                                    return ( row._original.is_active && row._original.is_login_allowed ? true : false );
                                if( filter.value === "invalid" )
                                    return ( row._original.is_active && !row._original.is_login_allowed ? true : false );
                                if( filter.value === "inactive" )
                                    return row._original.is_active == false; 
                                return false;
                            }, 
                            Filter: ({ filter, onChange }) => 
                                <select
                                    onChange={ e => onChange( e.target.value ) }
                                    onMouseDown={ e => onChange( e.target.value ) } // Fixes FireFox bug (https://github.com/facebook/react/issues/12584)
                                    style={{ width: "100%" }}
                                    value={ filter ? filter.value : "active" }
                                >
                                    <option value="all">{ tr('all') }</option>
                                    <option value="active">{ tr('active') }</option>
                                    <option value="invalid">{ tr('invalid') }</option>
                                    <option value="inactive">{ tr('inactive') }</option>
                                </select>
                        },
                        { 
                            id: 'name', 
                            Header: tr('name'), 
                            onClick: (row) => this.handleEditClick( row.id ), 
                            Cell: ( props ) => {
                                
                                let name = <span style={{ color: "#aaa" }}>{ tr('name_missing') }</span>;
                                if( props.original.person_detail.first_name || props.original.person_detail.last_name )
                                    name = props.original.person_detail.last_name + " " + props.original.person_detail.first_name;
                                
                                return (
                                    <div className="nameCell">
                                        <strong>{ name }</strong><br />
                                        <small>{ props.original.contract_detail.job_description ? props.original.contract_detail.job_description : <span style={{ color: "#aaa" }}>{ tr('no_job_description') }</span> }</small>
                                    </div>
                                );
                            }, 
                            accessor: ( props ) => {
                                return props.person_detail.last_name + " " + props.person_detail.first_name;
                            }, 
                            filterMethod: ( filter, rows ) => matchSorter( rows, filter.value, { keys: [ 
                                "_original.person_detail.first_name", 
                                "_original.person_detail.last_name", 
                                "_original.contract_detail.job_description" 
                            ]}), 
                            filterAll: true
                        },
                        { 
                            id: tr('customer_manager'), 
                            Header: tr('person_is_customer_manager'),
                            accessor: "is_client_manager",
                            Cell: ( props ) =>{
                            return(
                                     <ApSwitch
                                                 small
                                                 id={"client-manager-switch"+props.original.id}
                                                 on={ keyExists( props.original, "is_client_manager", true ) }
                                                 onChange={ () => { this.onChange( props.original.id ) } }
                                             />
                            )},
                        },
                    ]
                },
                {
                    Header: tr('permissions'),
                    columns: [
                        { 
                            id: 'supervisor', 
                            Header: tr('user_groups'), 
                            accessor: "groups", 
                            onClick: (row) => this.handleEditClick( row.id ), 
                            Cell: (props) => {

                                if( props.original.is_limited )
                                    return <span><strong>{ tr('basic_user') }</strong><br /><small>{ tr('basic_user_permissions') }</small></span>

                                if( props.original.is_admin )
                                    return <span><strong>{ tr('administrator') }</strong><br /><small>{ tr('all_permissions') }</small></span>

                                let groups = [];
                                let modules = [];
                                if( props.original.groups )
                                { 
                                    props.original.groups.forEach( g => {
                                        groups.push( g.name );
                                        g.modules.forEach( m => {
                                            if( modules.indexOf( m.id ) == -1 )
                                                modules.push( m.id );
                                        });
                                    });
                                }
                                return (
                                    <span>
                                        { groups.length > 0 ? groups.join(", ") : "-" }<br />
                                        <small>{ pluralizeCount( props.original.modules_count, tr('one_permission'), tr('many_permissions') ) }</small>
                                    </span>
                                );  
                            },
                            filterable: false,
                        },
                    ]
                }
            ],
            usersRemaining: null,

            newUserShow: false,

            data: null,

            userModalShow: false,
            userModalID: null,

            removeConfirmShow: false,
            removeIndexes: [],
        };

        this.tableState = {};
        autoBind(this);
    }

    componentDidMount()
    {
        this.getUsersApt();
        //this.getUserInfo();
    }



    onChange( rowId )
    {
        //console.log(rowId)
        const newUsers = this.state.users.map((user) =>{
            if(user.id === rowId){
                user.is_client_manager = !user.is_client_manager

                api({
                    method: 'post',
                    url: 'usermanagement/clientmanager/save',
                    data: user,
                }).then((response) => {
                    //console.log( 'Is client manager', response, );
                }).catch((error) => {
                    errorPopper( error, tr('save_error') );
                    this.setState({
                        loading: false
                    });
                });

            }
            return user
        })
        this.setState({
            users: newUsers
        })
    }

    getUsersApt(state = null)
    {
        if(!state)
            state = this.tableState;
        this.tableState = state;

        this.setState({ loading: true});
        /*
        api({
            method: 'post',
            url: 'usermanagement/users/apt',
            data: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                company_id: this.props.companyId ? this.props.companyId : null,
            },
        }).then((response) => {
            console.log( 'ressponse APT: ', response );
            this.setState({
                users: response.data,
                pages: response.pages,
                loading: false,
            });
            console.log( 'USERS', response.data );
        }).catch((error) => {
            console.error(error);
            this.setState({loading: false});
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Käyttäjien haussa tapahtui virhe</strong>,
            });
        });
        */
        api({
            method: 'post',
            url: 'usermanagement/users',
            data: {
                company_id: this.props.companyId ? this.props.companyId : null, option_modules: "clients.edit",
            },
        }).then((response) => {
            this.setState({
                users: response.users,
                usersRemaining: response.remaining,
                loading: false,
            });
            // console.log( 'USERS', response.users, "remaining: " + response.remaining );
        }).catch((error) => {
            errorPopper( error, tr('get_error') );
            this.setState({loading: false});
        });

    }

    handleNewClick()
    {
        this.setState({
            userModalShow: true,
            userModalID: null,
        });
    }

    handleEditClick(id = null)
    {
        this.setState({
            userModalShow: true,
            userModalID: id,
        });
    }


    confirmRemoveUsers( indexes )
    {
        this.setState({
            removeIndexes: indexes,
            removeConfirmShow: true
        });
    }

    // Convert selected indexes to ids 
    index2id( indexes )
    {
        if( Array.isArray( indexes ) )
        {
            let ids = [];
            indexes.map( i => ids.push( this.state.users[ i ].id ) );
            return ids;
        }
        else if( typeof( indexes ) == "number" || typeof( indexes ) == "string" )
        {
            return this.state.users[ parseInt( indexes, 10 ) ].id;
        }
        return false;
    }

    render()
    {   

        return(
            <div id="UserTable">

                <ApReactTable 

                    //manual
                    //onFetchData={ this.getUsersApt }
                    //pages={ this.state.pages }

                    loading={ this.state.loading }
                    data={ this.state.users } 
                    columns={ this.state.columns }
                    rowActions={ (row) => 
                        <div className="apSimpleButton" onClick={ () => this.handleEditClick( row.id ) }><SvgIcon icon="user-cog" type="solid" /></div>
                    }
                    minRows={10}
                    defaultSorted={[
                        { id: "name", desc: false }
                    ]}
                />
                
                <div>

                    <UserEdit
                        show={ this.state.userModalShow }
                        userID={ this.state.userModalID }
                        companyID={ this.props.companyId }
                        companySuffix={ this.props.companySuffix }
                        onClose={ () => this.setState({ userModalShow: false }) }
                        onSave={ () => this.getUsersApt() }
                    />

                </div>

            </div>
        );
    }

}

export default UserManager;