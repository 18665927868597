import ApButton from "common/ApButton/ApButton";
import { ApInput } from "common/ApInput/ApInput";
import ApModal from "common/ApModal/ApModal";
import ApSelect from "common/ApSelect/ApSelect";
import SvgIcon from "common/SvgIcon/SvgIcon";
import React from "react";
import autobind from "react-autobind";
import { errorPopper, mapTree, tr } from "services/Helpers/Helpers";
import api from 'services/Api/Api';

class EditCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            changes: false,
            name: '',
            parent: null,
            description: '',
        }
        autobind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show && this.props.selectedCategory) {
            const foundParent = this.props.categories.find(c => c.id === this.props.selectedCategory.parent)
            this.setState({
                name: this.props.selectedCategory.name,
                description: this.props.selectedCategory.description,
                parent: foundParent ? foundParent.id : null,
            })
        }
    }

    saveCategory() {
        this.setState({loading: true});
        const url = !this.props.selectedCategory 
            ? 'woocommerce/categories/new' 
            : 'woocommerce/categories/edit/' + this.props.selectedCategory.id;

        api({
            method: 'post',
            url: url,
            data: {
                name: this.state.name,
                description: this.state.description,
                parent: this.state.parent ? this.state.parent : null,
            }
        }).then((response) => {
            // console.log('RES', response);
            this.setState({ loading: false });
            this.props.onClose(true);
        }).catch((error) => {
            this.setState({ loading: false });
            errorPopper(error, tr('get_error'));
        })
    }

    onClose() {
        this.setState({ name: '', parent: null });

        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    }

    renderHeader() {
        return <div className=''>
            <h4 style={{ padding: '10px' }}>
                <SvgIcon type="solid" icon='list' /> {tr('category')}
            </h4>
        </div>
    }

    renderBody() {
        const categoryOptions = [{label: '', value: null}];

        mapTree(this.props.categoryTree, (item, parent) => {
            if (parent) {
                item.level = parent.level + 1;
            } else {
                item.level = 0;
            }
            categoryOptions.push({
                label: '- '.repeat(item.level) + item.name,
                value: item.id,
            });
            return item;
        })
        return <div className='padding'>
            <ApInput 
                type="select"
                options={ categoryOptions }
                id="category"
                name="category"
                label={tr('parent_category')}
                value={ this.state.parent ? this.state.parent : '' }
                onChange={ e => this.setState({parent: e.target.value, changes: true}) }
                loading={this.state.loading}
            />
            <div className="apInfo small" style={{marginTop: "-10px"}}>
                <SvgIcon icon="info-circle" type="solid" />
                {tr('parent_category')}
            </div>
            <ApInput 
                type="text"
                value={this.state.name}
                id="name"
                name="name"
                label={tr('name')}
                onChange= {(e) => this.setState({name: e.target.value, changes: true})}
                loading={this.state.loading}
            />
            <ApInput 
                type="textarea"
                value={this.state.description}
                id="name"
                name="name"
                label={tr('description')}
                onChange= {(e) => this.setState({description: e.target.value, changes: true})}
                rows="4"
                loading={this.state.loading}
            />
        </div>
    }

    renderFooter() {
        return <div className='padding justify-space-between'>
            <ApButton onClick={() => this.onClose()}>
                <SvgIcon type="solid" icon="times" /> {tr('back')}
            </ApButton>

            <ApButton onClick={this.saveCategory} color="blue" disabled={!this.state.changes} className={this.state.changes ? "highlight" : ""}>
                <SvgIcon type="solid" icon="save" /> {tr('save')}
            </ApButton>
        </div>
    }

    render() {
        return (
            <ApModal
                className="narrow"
                show={this.props.show}
                onClose={this.onClose}
                handleClose={this.onClose}
                header={this.renderHeader()}
                body={this.renderBody()}
                footer={this.renderFooter()}
            />
        )
    }
}

export default EditCategory;