import React from 'react'

import ApSteps from 'common/ApSteps/ApSteps.js';

import { sqlToDateInput, tr }  from 'services/Helpers/Helpers.js'

import './Steps.css';

class Steps extends React.Component {
    getSteps()
    {
        // Icons
        const iEdit = 'edit';
        const iAccept = 'user-check';
        const iCheck = 'clipboard-check'
        const iSend = 'envelope-open';
        const iArrivals = 'truck';
        const iArrival = 'shipping-fast';
        const iPaid = 'money-bill-wave-alt';
        const iArchive  = 'archive';

        const status = this.props.status;
        const paid = this.props.paid;
        const archived = this.props.archived;

        let acceptors = [];
        if( this.props.acceptors.length )
        {
            acceptors = this.props.acceptors.map( a => {
                return {
                    icon: iAccept,
                    name: tr('approval'),
                    description: a.name,
                    success: a.accepted,
                    selected: a.current,
                };
            });
        }

        let arrivals = [];
        if( this.props.arrivals.length )
        {
            arrivals = this.props.arrivals.map( a => {
                return {
                    icon: iArrivals,
                    name: tr('arrival'),
                    description: sqlToDateInput( a.date ),
                    success: true,
                    onClick: () =>  {
                        window.emitter.emit('goTo', { pathname: `/storage/logistic/arrival/show/${a.id}`}); 
                    }
                };
            });
        }


        if( status === 'open' )
        {
            return [
                { icon: iEdit, name: tr('editing'), selected: true },
                { icon: iSend, name: tr('shipment') },
                { icon: iCheck, name: tr('order_confirmation') },
                { icon: iArrival, name: tr('arrival') },
                { icon: iPaid, name: tr('paid') },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'approval' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success: true }, ...acceptors,
                { icon: iSend, name: tr('shipment') },
                { icon: iCheck, name: tr('order_confirmation') },
                { icon: iArrival, name: tr('arrival') },
                { icon: iPaid, name: tr('paid') },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'order_send' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success: true }, ...acceptors,
                { icon: iSend, name: tr('shipment'), selected: true },
                { icon: iCheck, name: tr('order_confirmation') },
                { icon: iArrival, name: tr('arrival') },
                { icon: iPaid, name: tr('paid') },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'order_confirm' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success: true }, ...acceptors,
                { icon: iSend, name: tr('shipment'), success: true },
                { icon: iCheck, name: tr('order_confirmation'), selected: true },
                { icon: iArrival, name: tr('arrival') },
                { icon: iPaid, name: tr('paid') },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }
        if( status === 'arrival' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success: true }, ...acceptors,
                { icon: iSend, name: tr('shipment'), success: true },
                { icon: iCheck, name: tr('order_confirmation'), success: true }, ...arrivals,
                { icon: iArrival, name: tr('arrival'), selected: true },
                { icon: iPaid, name: tr('paid'), success: paid, selected: !paid },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }

        if( status === 'arrived' )
        {
            return [
                { icon: iEdit, name: tr('editing'), success: true }, ...acceptors,
                { icon: iSend, name: tr('shipment'), success: true },
                { icon: iCheck, name: tr('order_confirmation'), success: true }, ...arrivals,
                { icon: iArrival, name: tr('arrival'), success: true },
                { icon: iPaid, name: tr('paid'), success: paid, selected: !paid },
                { icon: iArchive, name: tr('archiving'), success: archived },
            ];
        }

        return [
            { icon: iEdit, name: tr('editing') },
            { icon: iSend, name: tr('shipment') },
            { icon: iCheck, name: tr('order') },
            { icon: iArrival, name: tr('arrival') },
            { icon: iPaid, name: tr('paid') },
            { icon: iArchive, name: tr('archiving') },
        ];
    }

    render()
    {
        const steps = this.getSteps();

        return <div id="orderSteps">
            <ApSteps steps={ steps } flatEnd />
        </div>
    }


}

Steps.propTypes = {

};

export default Steps;
