import React from "react";
import autobind from "react-autobind";
import { keyExists, mapTree, tr } from "services/Helpers/Helpers";
import { ApAddon, ApInput, ApInputStack } from "common/ApInput/ApInput";
import { ApTab, ApTabs } from "common/ApTabs/ApTabs";
import TrackingReportsMassList from "./TrackingReportsMassList";
import TrackingReportsProgress from "./TrackingReportsProgress";
import _ from "lodash";
import ApSelect from "common/ApSelect/ApSelect";
import TrackingReportsIncomeStatement from "./TrackingReportsIncomeStatement";

class TrackingReportsMain extends React.Component {
    constructor(props) {
        super(props);

        this.state={
            selectedProject: null,
        }

        autobind(this);
    }

    componentDidMount() {
        if (Array.isArray(this.props.tree) && this.state.selectedProject == null) {
            this.setState({selectedProject: this.props.tree[0]});
        }
    }

    componentDidUpdate(prevProps) {
        if (Array.isArray(this.props.tree) && this.state.selectedProject == null) {
            this.setState({selectedProject: this.props.tree[0]});
        } else if (!_.isEqual( prevProps.tree, this.props.tree)) {
            this.setState({selectedProject: this.props.tree[0]});
        }
    }

    handleProjectSelect(value) {
        let foundProject = null;
        mapTree(this.props.tree, item => {
            if (foundProject) return item;
            if(item.id === parseInt(value)) foundProject = item;
            return item;
        });

        this.setState({selectedProject: foundProject});
    }

    renderReportSelect() {
        if (!this.state.selectedProject) return null;

        return (
            <ApTabs>
                <ApTab key="end_report" icon="chart-line" label={ tr('income_statement') } mountOnSelect>
                    <TrackingReportsIncomeStatement project={this.state.selectedProject} />
                </ApTab>
                <ApTab key="masslist" icon="boxes" label={ tr('mass_list') } mountOnSelect>
                    <TrackingReportsMassList project={this.state.selectedProject} />
                </ApTab>
                <ApTab key="progress" icon="clock" label={ tr('progress_report') } mountOnSelect>
                    <TrackingReportsProgress project={this.state.selectedProject} />
                </ApTab>
            </ApTabs>
        )
    }

    render() {
        const projectOptions = [];

        const tree = Array.isArray(this.props.tree)
            ? this.props.tree[0]
            : null;

        if(tree) {
            // projectOptions.push({label: '', value: null})
            mapTree(this.props.tree, item => {
                projectOptions.push({label: `${item.project_code} ${item.name}`, value: item.id});
                return item;
            });
        }

        return (
            <div className="padding">
            <h4 className="formHeader">{tr('select_project')}</h4>
                <ApInputStack gap="0">
                    <ApAddon width="200">
                        {tr('select_project')}
                    </ApAddon>
                    {/* <ApInput 
                        type="select"
                        options={ projectOptions }
                        id="selectedProject"
                        name="selectedProject"
                        value={ this.state.selectedProject ? this.state.selectedProject.id : null }
                        onChange={ e => this.handleProjectSelect(e.target.value) }
                    /> */}
                    <ApSelect 
                        options={projectOptions}
                        optionRenderer="label"
                        valueRenderer="label"
                        label={ tr('select_project') }
                        value={ this.state.selectedProject ? `${this.state.selectedProject.project_code} ${this.state.selectedProject.name}` : null }
                        objKeyId="value"
                        objKeySearchable="label"
                        onChange={ value => this.handleProjectSelect(value.value) }
                    />
                </ApInputStack>
                {this.renderReportSelect()}
            </div>
        )
    }
}

export default TrackingReportsMain;