import React from 'react';
import autoBind from 'react-autobind';

import ApButton       from 'common/ApButton/ApButton.js';
import { ApInput }    from 'common/ApInput/ApInput.js';

import ReceiverDataSelect from './ReceiverDataSelect/ReceiverDataSelect.js';
import SenderDataSelect from './SenderDataSelect/SenderDataSelect.js';

import './DataEdit.css';

class DataEdit extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
        };

        autoBind(this);
    }

    handleChange(e)
    {
        this.props.onChange({[e.target.name]: e.target.value});
    }

    renderSender()
    {
        return <div>
            <h3 className="apFormHeader"> Lähettäjä </h3>

            <SenderDataSelect
                validator={ this.props.validator }
                onChange={  this.props.onChange }

                senderLocked={ this.props.senderLocked }
                senderTitle={  this.props.senderTitle }
                senderType={   this.props.senderType }
                senderId={     this.props.senderId }
            />
        </div>
    }

    renderReceiver()
    {
        return <div>
            <h3 className="apFormHeader">Vastaanottaja</h3>

            <ReceiverDataSelect
                validator={ this.props.validator }
                onChange={  this.props.onChange }

                receiverLocked={ this.props.receiverLocked }
                receiverTitle={  this.props.receiverTitle }
                receiverType={   this.props.receiverType }
                receiverId={     this.props.receiverId }
            />
        </div>
    }

    handleReceiverTypeChange( e )
    {
        this.props.onChange( this.nullReceiver() );
        this.props.onChange({[e.target.name]: e.target.value});
    }

    renderDetails()
    {
        return <div>
            <h3 className="apFormHeader">Muut tiedot</h3>
            {/*
            <ApInput
                type="text"
                id="number"
                name="number"
                label="Vastaanotto kirjauksen numero"
                disabled={ true }
                value={ this.props.number }
                tooltip={ ( !this.props.numberReserved ) ? 'Seuraava vapaa numero jota ei ole vielä varattu ostotilaukselle' : '' }
            />
            */}
            <ApInput
                type="date"
                id="date"
                name="date"
                label="Saapumispäivä"
                autoComplete="off"
                value={ this.props.date }
                onChange={ this.handleChange }
            />
        {/*

            <ApInput
                type="text"
                id="referenceYour"
                name="referenceYour"
                label="Viitteenne"
                autoComplete="off"
                value={ this.props.referenceYour }
                onChange={ this.handleChange }
            />
            <ApInput
                type="text"
                id="referenceOur"
                name="referenceOur"
                label="Viittemme"
                autoComplete="off"
                value={ this.props.referenceOur }
                onChange={ this.handleChange }
            />
        */}
            <ApInput
                type="textarea"
                id="comment"
                name="comment"
                label="Kommentti"
                autoComplete="off"
                value={ this.props.comment }
                onChange={ this.handleChange }
            />
        </div>
    }

    render()
    {
        let summaryButton = null;
        if( this.props.summary )
        {
            summaryButton = <div className="summary-button-container">
                <ApButton
                    size="xtiny"
                    color="white"
                    className="summary-button"
                    onClick={ this.props.onSummaryClick }
                >Yhteenveto</ApButton>
            </div>
        }


        let sender   = this.renderSender();
        let receiver = this.renderReceiver();
        let details  = this.renderDetails();

        return <div id="po-data-edit">
            { summaryButton }
            <div className="apForm">
                <div className="apFormColumn">
                    { sender }
                    { receiver }
                </div>
                <div className="apFormColumn">
                    { details }
                </div>
            </div>
        </div>

    }
}

export default DataEdit;
