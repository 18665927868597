import React from 'react';
import autoBind from 'react-autobind';
import { ApTabs, 
         ApTab } from 'common/ApTabs/ApTabs.js';

import { tr } from 'services/Helpers/Helpers.js';
import Approve from './tabs/Approve.js';
import SubordinatesOverview from './tabs/SubordinatesOverview.js';
import OldHours from './tabs/OldHours.js';

export default class Main extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state={changes:[]}

		autoBind(this);

	}

	setChanges(changes){
		this.setState({changes});
	}

	render()
	{ 

		return(

			<div className="apBox">

				<div className="apBoxHeader">
					<h1> { tr('approval_of_hours') } </h1>
					<p> { tr('approval_of_hours_info') } </p>
				</div>

				<ApTabs>

					<ApTab icon="calendar-check" label={ tr('approval_of_hours') }>
						<Approve setChanges={this.setChanges} />
					</ApTab>

					<ApTab icon="eye" label={ tr('overview') }>
						<SubordinatesOverview changes={this.state.changes} />
					</ApTab>

					<ApTab icon="calendar-week" label={ tr('timetracking_previous_entries') }>
						<OldHours />
					</ApTab>

				</ApTabs>

			</div>

		);

	}

}
