import Echo from "laravel-echo"

import config from '../../env.js';
import auth from '../Authed/Authed.js';
import { apiurl } from 'services/Api/Api.js';



const ApEcho = {
    inited: false,
    enabled: false,

    maxWaitTime: 5, // Seconds

    init()
    {
        if (config.echo && !this.inited)
        {
            this.inited = true;
            //this.addScriptToHead();
            //this.initEcho();
            this.initEcho();
            
        }
    }, 

    addScriptToHead()
    {
        let head = document.getElementsByTagName('head')[0];
        let script = document.createElement('script');
        // script.src = `${ config.api.url }:${ config.echoPort }/socket.io/socket.io.js`;
        script.src = `http://localhost:6001/apia2/public/socket.io/socket.io.js`;
        script.type = 'text/javascript';
        // script.src = 'https://cdn.socket.io/4.5.0/socket.io.min.js';
        // script.integrity = "sha384-7EyYLQZgWBi67fBtVxw60/OWl1kjsfrPFcaU0pp0nAh+i8FD068QogUvg85Ewy1k";
        // script.crossorigin = "anonymous";
        head.appendChild(script);
        
    },

    initEcho() {
        let token = auth.getToken();

        // window.Pusher = require('pusher-js');
        //console.log("api url", apiurl)
        window.Echo = new Echo({
            broadcaster: 'socket.io',
            //key: config.echoKey,
            //cluster: 'mt1',
            client: require('socket.io-client'),
            //wssHost: window.location.hostname,
            host: config.echoHost + ":" + config.echoPort,
            encrypted: true,
            // Port: config.echoPort,

            //salaus päälle

            // wssHost: window.location.hostname,
            // wssPort: config.echoPort,
            // forceTLS: true,

            //enabledTransports: ['ws', 'wss'], 
            //encrypted: true,
            //client: pusherClient,
            authEndpoint: apiurl + 'broadcasting/auth',
            auth: {
                headers: {
                    Authorization: 'Bearer ' + token,
                    Accept: 'application/json'
                }
            }
            /*auth: {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },*/
        })
        //window.Echo.connector.pusher.config.auth.headers['Authorization'] = 'Bearer ' + token;

        /*window.Echo.channel("home").listen("ChatMessage", (e) => {
            console.log(e.message);
        });*/


        

       

        /*
        window.Pusher = require('pusher-js');
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: config.echoKey,
            cluster: 'mt1',
            wsHost: window.location.hostname,
            wsPort: 6001,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            },
        })
        window.Echo.channel("home").listen("ChatMessage", (e) => {
            console.log(e.message);
        })
        */
       
    },

    // initEcho()
    // {
        
    //     this.maxWaitTime = this.maxWaitTime * 100;
    //     let counter = 0;
    //     let checkIO = () => {
    //         setTimeout(() => {
    //             if(typeof io === 'undefined' && false)
    //             {
    //                 if(counter < this.maxWaitTime)
    //                 {
    //                     counter++;
    //                     checkIO();
    //                 }
    //                 else
    //                 {
    //                     console.error('Echo not enabled');
    //                 }
    //             }
    //             else
    //             {
    //                 let token = auth.getToken();
    //                 window.io = require('socket.io-client');
    //                 window.Echo = new Echo({
    //                     broadcaster: 'socket.io',
    //                     //host: `${config.api.url}:${ config.echoPort }`,
    //                     host: 'https://localhost:6001',
    //                     client: require('socket.io-client'),
    //                     encrypted: true,
    //                     auth: {
    //                         headers: {
    //                             Authorization: 'Bearer ' + token,
    //                         },
    //                     },
    //                 });
    //                 this.enabled = true;
    //                 this.afterEchoInited();
    //             }
    //         }, 10);
    //     };
    //     checkIO();
    // },

    // New events should be added here
    // afterEchoInited()
    // {
    //     window.Echo.channel('test')
    //         .listen('TestEvent', e => {
    //             console.log(e)
    //         });
        
    //     window.Echo.private(`${config.echoName}-user-${auth.getID()}`)
    //         .listen('SendUserNotification', (e) => {
    //             window.emitter.emit('user-notification', {...e, event: 'add'});
    //         })
    //         .listen('SendUserEvent', (e) => {
    //             window.emitter.emit('user-event', {...e});
    //         });

    //     window.Echo.join(`${ config.echoName }-company-${auth.getCompanyID()}`)
    //         .here((users) => {
    //             // console.log('here', users);
    //         })
    //         .joining((user) => {
    //             // console.log('joined to company channel', user);
    //         })
    //         .leaving((user) => {
    //             // console.log('left the company channel', user);
    //         })
    //         .listen('SendCompanyEvent', (e) => {
    //             window.emitter.emit('company-event', {...e});
    //         });

    // },

    logout()
    {
        if( config.echo && window.Echo )
        {
            window.Echo.leave( `${ config.echoName }-user-${auth.getID()}` )
            window.Echo.leave( `${ config.echoName }-company-${auth.getCompanyID()}` )
        }
    }
}

export default ApEcho;
