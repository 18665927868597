/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import { sqlToDateInput, formatMoney, tr, capitalize } from 'services/Helpers/Helpers.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';

import ApReactTable from 'common/ApReactTable/ApReactTable.js';

import RuleEdit from './RuleEdit/RuleEdit.js';

export default class ApprovedExtras extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loadingRules: false,
            loadingExtras: false,
            rules: [],
            extras: [],
            extraPages: -1
        };
        this.getTimer = null;
        autoBind(this); 
    }

    componentDidMount()
    {
        this.getRules();
    }

    getRules()
    {
        this.setState({ loadingRules: true });
        api({
            method: 'get',
            url: 'timetracking/salary_extras/rules',
            errorPopper: 'Virhe automaattisten lisien sääntöjen haussa'
        }).then(( response ) => {

            //console.log('Rules', response );

            this.setState({
                loadingRules: false,
                rules: response,
            });
            
        }).catch( ( error ) => {
            this.setState({ loadingRules: false });
        });
    }

    ruleActions( action, indexes )
    {
        if( [ "remove", "reload" ].indexOf( action ) == -1 )
            return false;
            
        let ids = [];
        indexes.forEach( index => ids.push( this.state.rules[ index ].id ) );
        
        this.setState({ loadingRules: true });
        api({
            method: 'post',
            url: 'timetracking/salary_extras/rules/' + action,
            data: { ids: ids },
            errorPopper: 'Toimintoa (' + action + ') ei voitu suorittaa'
        }).then(( response ) => {

            //console.log('RESPONSE', action, response );

            this.setState({ loadingRules: false }, this.getRules );

            if( action === "reload" ) {
                this.getExtraData();
            }

        }).catch( ( error ) => {
            this.setState({ loadingRules: false });
        });
    }

    getExtraData = (state) => {
        const fetchPage = state.page;
        this.setState({loadingExtras: true});
        api({
            method: 'post',
            url: 'timetracking/salary_extras/automatic',
            data: {
                page: fetchPage,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            },
        }).then((response) => {
            //console.log( 'response', response);

            this.setState({
                extras: response.data,
                extraPages: response.pages,
                loadingExtras: false
            })
        }).catch((error) => {
            console.error(error);
            this.setState({
                extras: [],
                extraPages: -1,
                loadingExtras: false
            })
        });
    };

    deleteRow(id) {
        let ids = [];
        ids.push( id ) ;

        this.setState({ loadingExtras: true });
        api({
            method: 'post',
            url: 'timetracking/salary_extras/remove',
            data: { ids: ids },
            errorPopper: tr('delete_error'),
        }).then(( response ) => {
            let extras = this.state.extras.slice( 0 );
            extras = extras.filter(item => item.id != id);
            this.setState({ loadingExtras: false, extras: extras });
  
        }).catch( ( error ) => {
            this.setState({ loadingExtras: false });
        });
    }
   
    render() 
    {
        return (
            <div id="TimetrackingSalaryExtraAutomatic">

                <h3>{ tr('automatic_supplements') }</h3>
                <p>{ tr('automatic_supplements_info1') }</p>
                <p>{ tr('automatic_supplements_info2') }</p> 
                
                <ApButton color="green" onClick={ () => this.setState({ ruleEditId: "new" }) }>
                    <SvgIcon icon="plus" type="solid" />
                    { tr('new_automatic_supplement') }
                </ApButton>

                <ApReactTable 
                    loading={ this.state.loadingRules }
                    data={ this.state.rules ? this.state.rules : [] } 
                    columns={[
                        {
                            Header: tr('automatic_supplements'),
                            columns: [
                                { width: 50, id: 'active', Header: tr('status'), accessor: "active", Cell: ( props ) => {
                                    return (
                                        <div className="activityCell">
                                            <SvgIcon icon={ props.original.active ? "check" : "times" } type="solid" />
                                        </div>
                                    );
                                }},
                                { id: 'name', Header: tr('name_description'), accessor: "name", Cell: ( props ) => {
                                    return (
                                        <div className="nameCell">
                                            <span>{ props.original.name }</span><br />
                                            <small>{ props.original.description ? props.original.description : tr('no_description') }</small>
                                        </div>
                                    );
                                }},
                                { width: 150, id: 'unit_price', Header: tr('compensation'), filterable: false, sortable: false, Cell: ( props ) => {
                                    
                                    let unit = false;
                                    if( props.original.amount_type === "fixed" )
                                        unit = auth.getCurrencySign();
                                    else if ( props.original.amount_type === "per_hour" )
                                        unit = auth.getCurrencySign() + " / h";
                                    else if ( props.original.amount_type === "per_hour_salary" )
                                        unit = "% / h";

                                    return (
                                        <div className="compensationCell">
                                            <div className="block">{ props.original.unit_price } { unit }</div>
                                        </div>
                                    );
                                }},
                                { width: 150, id: 'start_date', Header: capitalize(tr('begins')), accessor: "start_date", Cell: ( props ) => sqlToDateInput( props.original.start_date ) },
                                { width: 150, id: 'end_date', Header: capitalize(tr('ends')), accessor: "end_date", Cell: ( props ) => sqlToDateInput( props.original.end_date ) },
                                { width: 150, id: 'target', Header: tr('adjustment'), filterable: false, sortable: false, Cell: ( props ) => {
                                    return (
                                        <div className="targetCell">
                                            <div className="block">
                                                <SvgIcon className="small-inline" icon="users" type="solid" />
                                                <span>{ props.original.users.length > 0 ? props.original.users.length + ` ${tr('pcs')}` : tr('all') }</span>
                                            </div>
                                            <div className="block">
                                                <SvgIcon className="small-inline" icon="folder" type="solid" />
                                                <span>{ props.original.projects.length > 0 ? props.original.projects.length + ` ${tr('pcs')}` : tr('all') }</span>
                                            </div>
                                        </div>
                                    )
                                }},
                                { id: 'limit', Header: tr('limit'), accessor: row => ( row.limit_type + row.limit ), Cell: ( props ) => {
                                    if( !props.original.limit || !props.original.limit_type )
                                        return "-";

                                    return <span>{ props.original.limit || 0 } { auth.getCurrencySign() } / { props.original.limit_type }</span>
                                }},
                                { id: 'count', Header: tr('used'), accessor: "extras_count" },
                            ]
                        },      
                     ]}
                    filterable
                    multiselect={[
                        { icon: "redo", label: tr('check_earned_supplements'), action: ( ids ) => { this.ruleActions( "reload", ids ) }, unselectAfter: true, closeAfter: true },
                        { divider: true },
                        { icon: "trash-alt", label: tr('delete_selected'), action: ( ids ) => { this.ruleActions( "remove", ids ) }, unselectAfter: true, closeAfter: true },
                    ]}
                    minRows={ 5 }
                    rowActions={ (row) => 
                        <div className="apSimpleButton" onClick={ () => this.setState({ ruleEditId: row.id }) }><SvgIcon icon="edit" type="solid" /></div>
                    }
                />

                <br />
                
                <h3>{ tr('accepted_automatic_supplements') }</h3>
                <p>{ tr('accepted_automatic_supplements_info') }</p>

                <ApReactTable 
                    loading={this.state.loadingExtras}
                    data={this.state.extras || []}
                    pages={this.state.extraPages}
                    manual
                    onFetchData={this.getExtraData}
                    columns={[
                        {
                            Header: tr('automatic_supplements'),
                            columns: [
                                { id: 'date', Header: tr('date'), accessor: 'date', Cell: (props) => {
                                    return <span>{ moment( props.value ).format('DD.MM.YYYY') }</span>;
                                }},
                                { id: 'user_id', Header: tr('recipient'), accessor: 'user_id', Cell: (props) => props.original.user.name },
                                { id: 'name', Header: tr('name'), accessor: 'name' },
                                { id: 'sum', Header: tr('sum'), accessor: ( row ) => ( row.amount * row.unit_price ), Cell: (props) => { 
                                    return ( 
                                        <span>
                                            { formatMoney( props.value ) } { auth.getCurrencySign() }<br />
                                            <small>{ tr('amount') }: { props.original.amount }, { tr('price') }: { formatMoney( props.original.unit_price ) } { auth.getCurrencySign() }</small>
                                        </span>
                                    );
                                }},
                                { id: 'batch_created', Header: tr('transferred'), accessor: ( props ) => props.payroll_batch ? props.payroll_batch.created_at : null, Cell: (props) => {
                                    if( !props.original.payroll_batch )
                                        return tr('waiting_for_transfer');

                                    return ( 
                                        <span>
                                            { moment( props.value ).format('DD.MM.YYYY') }<br />
                                            <small>{ props.original.payroll_batch.name }</small>
                                        </span>
                                    );
                                }},
                                { id: 'salary_category', Header: tr('salary_type'), accessor: 'salary_category' },
                                { id: 'creator_user_id', Header: tr('creator'), accessor: 'creator_user_id', Cell: (props) => props.original.creator.name },
                            ]
                        }
                    ]}
                    filterable
                    minRows={ 5 }
                    rowActions={[
                        {
                            icon: "edit",
                            label: tr('edit_supplement'),
                            disabled: true,
                            action: ( id ) => {/*console.log('TODO: edit extra', id )*/},
                        },
                        { divider: true },
                        {
                            icon: "trash",
                            label: tr('delete_supplement'),
                            disabled: false,
                            action: ( id, closeFunc ) => {
                                this.deleteRow(id);
                                closeFunc();
                            }
                        },
                    ]}
                />

                <RuleEdit 
                    id={ this.state.ruleEditId }
                    onClose={ ( unsavedChanges, reloadNeeded ) => {
                        if( unsavedChanges )
                        {
                            if( window.confirm( tr('cancel_confirm') ) )
                                this.setState({ ruleEditId: false });
                        }
                        else 
                            this.setState({ ruleEditId: false }, () => {
                                if( reloadNeeded )
                                    this.getRules();
                            });
                    }}
                />

            </div>
        );
    }
}

