import ApReactTable, { colPreset } from "common/ApReactTable/ApReactTable";
import ApTooltip from "common/ApTooltip/ApTooltip";
import SvgIcon from "common/SvgIcon/SvgIcon";
import React from "react";
import autobind from "react-autobind";
import { keyExists, tr } from "services/Helpers/Helpers";

class ContractBillingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        autobind(this);
    }

    openRow(row) {
        if (typeof this.props.openRow === 'function' && row.id) {
            this.props.openRow(row.id);
        }
    }

    getColumns() {
        return [
            {
                Header: tr('name'),
                accessor: 'name',
                customFilter: {
                    type: "text",
                    placeholder: tr('name'),
                },
                sortable: true,
                customizable: false,
                showInitially: true,
                onClick: this.openRow,
            },
            {
                Header: tr('description'),
                accessor: 'description',
                customFilter: {
                    type: "text",
                    placeholder: tr('description'),
                },
                sortable: true,
                customizable: false,
                showInitially: true,
                onClick: this.openRow,
            },
            {
                Header: tr('project'),
                accessor: 'project.name',
                customFilter: {
                    type: "text",
                    placeholder: tr('project'),
                    fields: ['_original.project.name', '_original.project.project_code']
                },
                Cell: row => {
                    if (!row.original.project || !row.original.project.name || !row.original.project.project_code) return '';
                    return <div>{row.original.project.name} <small style={{display: 'block'}}>{row.original.project.project_code}</small></div>
                },
                sortable: true,
                customizable: false,
                showInitially: true,
                onClick: this.openRow,
            },
            {
                Header: tr('customer'),
                accessor: 'project.root_customer.name',
                customFilter: {
                    type: "text",
                    placeholder: tr('customer'),
                    fields: ['_original.project.root_customer.name']
                },
                sortable: true,
                Cell: row => {
                    if (!row.original?.project?.root_customer?.name) 
                        return <ApTooltip text={tr('no_customer')}><SvgIcon type="solid" icon="times" fill="red" /></ApTooltip>;
                    return row.original.project.root_customer.name;
                },
                className:"overflowableOnHover"
            },
            {
                Header: tr('rows'),
                accessor: 'billings_count',
                width: 75,
                customFilter: {
                    type: "text",
                    placeholder: tr('rows'),
                },
                sortable: true,
                customizable: false,
                showInitially: true,
                onClick: this.openRow,
            },
            {
                Header: tr('recurrence'),
                accessor: 'recurrence',
                customFilter: {
                    type: "select",
                    placeholder: tr('recurrence'),
                    options: [
                        { value: '', label: tr('all') },
                        { value: 'monthly', label: tr('monthly') },
                        { value: 'weekly', label: tr('weekly') },
                        { value: 'daily', label: tr('daily') },
                    ]
                },
                Cell: row => {
                    let recurrenceLabel = null;
                    if (row.original.recurrence) {
                        if (row.original.recurrence == 'weekly') {
                            recurrenceLabel = tr('recur_weeks', [keyExists(row.original, 'recurrence_period', true, 1) || 1]);
                        }
                        else if (row.original.recurrence == 'monthly') {
                            recurrenceLabel = tr('recur_months', [keyExists(row.original, 'recurrence_period', true, 1) || 1]);
                        }
                        else if (row.original.recurrence == 'daily') {
                            recurrenceLabel = tr('recur_days', [keyExists(row.original, 'recurrence_period', true, 1) || 1]);
                        }
                    }
                    return recurrenceLabel;
                },
                sortable: true,
                customizable: false,
                showInitially: true,
                onClick: this.openRow,
            },
            colPreset({
                type: "date",
                Header: tr('next_billing_date'),
                accessor: 'next_billing_date',
                customFilter: {
                    type: "text",
                    placeholder: tr('next_billing_date'),
                },
                sortable: true,
                filterable: false,
                customizable: false,
                showInitially: true,
                onClick: this.openRow,
            }),
            {
                width: 80,
                Header: tr('active'),
                accessor: 'is_active',
                sortable: true,
                customizable: false,
                showInitially: true,
                onClick: this.openRow,
                Cell: (props) => {
					if (props.value)
						return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                    else
                        return <div className="text-center"><SvgIcon fill="#CC0000" icon="times-circle" type="solid" /></div>
				},
				customFilter: {
					type: "toggle",
				},
            }
        ];
    }

    render() {
        return (
            <div>
                <ApReactTable
                    data={this.props.data || []}
                    columns={this.getColumns()}
                    loading={this.props.loading}
                    showFiltersInitially={true}
                    filterable
                />
            </div>
        );
    }
}

export default ContractBillingList;