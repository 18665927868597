import React from 'react';

import auth     from 'services/Authed/Authed.js';
import SvgIcon  from 'common/SvgIcon/SvgIcon.js';
import { getImageUrl, tr } from 'services/Helpers/Helpers.js';

import BugsMenu             from './BugReport/BugReport.js';
import TimerMenu            from './TimerMenu/TimerMenu.js';
import NotificationButton   from './NotificationButton/NotificationButton.js';
// import TasksMenu            from './TasksMenu/TasksMenu.js';
import UserMenu             from './UserMenu/UserMenu.js';
import Help                 from './Help/Help.js';
import LangMenu             from './LangMenu/LangMenu.js';

import './NavTop.css';


class NavTop extends React.Component {
    
    constructor( props ) {
        super( props );
        this.state = {
            bugsOpen:           false,
            timerOpen:          false,
            notificationsOpen:  false,
            tasksOpen:          false,
            userOpen:           false,
            helpOpen:           false,
            langOpen:           false
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.clickOutsideHandler = this.clickOutsideHandler.bind(this);
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.clickOutsideHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.clickOutsideHandler);
    }


    toggleModal( block ) {
        var newState = {
            bugsOpen:           false,
            timerOpen:          false,
            notificationsOpen:  false,
            tasksOpen:          false,
            userOpen:           false,
            helpOpen:           false,
            langOpen:           false
        };
        switch( block ) {
            case "bugs":
                if( !this.state.bugsOpen ) newState.bugsOpen = true;
                break;

            case "timer":
                if( !this.state.timerOpen ) newState.timerOpen = true;
                break;

            case "notifications":
                if( !this.state.notificationsOpen ) newState.notificationsOpen = true;
                break;

            case "tasks":
                if( !this.state.tasksOpen ) newState.tasksOpen = true;
                break;

            case "user":
                if( !this.state.userOpen ) newState.userOpen = true;
                break;

            case "help":
                if( !this.state.helpOpen ) newState.helpOpen = true;
                break;

            case "language":
                if( !this.state.langOpen ) newState.langOpen = true;
                break;

            default:
                break;
        }
        this.setState( newState );
    }

    clickOutsideHandler(event) {
        if( !this.node.contains(event.target) ) {
            this.setState( {
                bugsOpen:           false,
                timerOpen:          false,
                notificationsOpen:  false,
                tasksOpen:          false,
                userOpen:           false,
                langOpen:           false
            });
        }
    }

    render() {

        const settings = auth.getSettings();

        let logo = "/img/logo.svg";
        let logo_bg = "transparent";
        let title = false;
        let subtitle = false

        if( settings && settings.company &&  settings.company.layout )
        {
            if( settings.company.layout.logo_file_id )
                logo = getImageUrl( settings.company.layout.logo_file_id );

            if( settings.company.layout.logo_bg_color )
                logo_bg = settings.company.layout.logo_bg_color;

            if( settings.company.layout.top_bar_text )
                title = settings.company.layout.top_bar_text;

            if( settings.company.layout.top_bar_subtext )
                subtitle = settings.company.layout.top_bar_subtext;
        }

        return (
            <nav id="navTop">

                <div className='showUnder700'>
                    <div className="mobileMenu">
                        <SvgIcon onClick={this.props.toggleMobileMenu} icon={this.props.mobileMenuOpen ? "times" : "bars"} type="solid"/>
                    </div>
                </div>
                
                <div className={ "company" + ( !title && !subtitle ? " logoOnly" : "" ) }>
                    <div className="logoBg" style={{ backgroundColor: logo_bg }}>
                        <div className="logo" style={{ backgroundImage: "url('" + logo + "')" }} />
                    </div>
                    { ( title || subtitle ) &&
                        <div className="text">
                            { title && <div className="title">{ title }</div> }
                            { subtitle && <div className="subtitle">{ subtitle }</div> }
                        </div>
                    }
                </div>

                <div className="blocks" ref={node => this.node = node}>
                    { auth.hasModule( 'assignments.manage' ) &&
                            <div className="block help">
                                <div className="button" onClick={ () => { window.emitter.emit( 'goTo', 
                        				{ 
                        					pathname: "/assignmentsNew/?true"
                        				} 
                        			) } } >
                                    <span className="text">{ tr('add_assignment') }</span>
                                    <SvgIcon icon="tasks" type="solid" />
                                </div>
                            </div>
                    }
                	{ auth.hasModule( 'assignments.manage' ) &&
                		<div className="divider secondary" />
                	}
                    { auth.hasModule( 'timetracking.entries' ) &&
                        <TimerMenu open={ this.state.timerOpen } toggleModal={ this.toggleModal } updateTimer={ this.props.updateTimer } />
                    }
                    { auth.hasModule( 'timetracking.entries' ) &&
                        <div className="divider secondary" />
                    }

                    <NotificationButton />
                    
                    <div className="divider secondary" />

                    {/* 
                    <TasksMenu open={ this.state.tasksOpen } toggleModal={ this.toggleModal } />
                    
                    <div className="divider secondary" />
                    */}

                    <UserMenu open={ this.state.userOpen } toggleModal={ this.toggleModal } />

                    <div className="divider secondary" />
                    
                    <LangMenu open={ this.state.langOpen } toggleModal={ this.toggleModal } />

                    <div className="divider secondary" />
                
                    <BugsMenu open={ this.state.bugsOpen } toggleModal={ this.toggleModal } />

                    <div className="divider" />

                    <div className="block help">
                        <div className="button" onClick={ () => { this.toggleModal('help'); } }>
                            <span className="text">{ tr('guide') }</span>
                            <SvgIcon icon="question-circle" />
                        </div>
                    </div>

                </div>

               <Help open={this.state.helpOpen} closeEvent={ () => { this.toggleModal('help'); } } url={ this.props.url } /> 

            </nav>
        );
    }
}

export default NavTop;


