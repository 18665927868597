import React, {useState, useEffect} from 'react'
import moment from 'moment'
import api from 'services/Api/Api.js';
import { Collapse } from 'react-bootstrap';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApFormPage from 'common/ApFormPage/ApFormPage';
import { tr, currentLang } from 'services/Helpers/Helpers.js';
import { useDispatch } from 'react-redux';

const GeneralCustomerSettings = () => {
    const [loading, setLoading] = useState(false)
    const [autoApproving, setAutoApproving] = useState(false)
    const [autoProjectApproving, setProjectAutoApproving] = useState(false)
    const [selected, setSelected] = useState('mon')
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const dispatch = useDispatch();

    let inputSelection = []
    for (let i = 1; i < 8; i++)
    {
        inputSelection.push({
            value: moment().locale('en').isoWeekday(i).format('ddd'),
            label: moment().locale(currentLang()).isoWeekday(i).format('dddd')
        })
    }

    useEffect(() => {
        getSettings()
    }, [])

    const save = async () => {
        setLoading(true)
        try {
            await api({
                method: 'post',
                url: 'crm/generalsettings',
                data: {autoApprove: autoApproving,autoProjectApproving:autoProjectApproving, approveDate: selected},
                errorPopper: tr('save_error'),
            })

            setLoading(false)
            setUnsavedChanges(false)
        } catch (error) {
            setLoading(false)
        } 
    }

    const getSettings = async () => {
        setLoading(true)

        try {
            const response = await api({
                method: 'get',
                url: 'crm/generalsettings',
                errorPopper: tr('get_error'),
            })

            setAutoApproving(response.auto_approve)
            setProjectAutoApproving(response.project_auto_approve)
            setSelected(response.approval_day)
            setLoading(false)
            setUnsavedChanges(false)
            dispatch({type: 'SAVE_CUSTOMER_SETTINGS', payload: response});
        } catch (error) {
            setLoading(false)
        } 
    }

    return (
        <ApFormPage onSave={ save } 
            loading={ loading } 
            unsaved={ unsavedChanges }>
            <div className={ "apFormGroup" + ( autoApproving ? " success" : "" ) }>
                <div className="apSwitchBlock">
                    <label htmlFor="auto-approval-enabled-switch" className="info">
                        { tr('automatic_approval_invoicing') }
                        <small>{ tr('automatic_approval_invoicing_info') }</small>
                    </label>
                    <ApSwitch
                        id="auto-approval-enabled-switch"
                        on={ autoApproving }
                        onChange={ () => {
                            setAutoApproving(!autoApproving)
                            setUnsavedChanges(true)
                        }}
                        disabled={ loading }
                    />
                </div>

                <Collapse in={ autoApproving }>
                    <div className="indentleft">
                        <ApInputStack gap="0">
                            <ApAddon width="150" noRightBorder> { tr('day_of_approval') } </ApAddon>
                            <ApInput
                                type="select"
                                id="dateWeek"
                                name="dateWeek"
                                value={ selected }
                                options={ inputSelection }
                                onChange={ ( e ) =>{ 
                                    setSelected( e.target.value )
                                    setUnsavedChanges(true) 
                                }}
                                loading={ loading }
                                disabled={ loading }
                            />
                        </ApInputStack>
                    </div>
                </Collapse>
            </div>
                <div className={ "apFormGroup" + ( autoProjectApproving ? " success" : "" ) }>
                    <div className="apSwitchBlock">
                        <label htmlFor="project-auto-approval-enabled-switch" className="info">
                            { tr('automatic_approval_invoicing_project') }
                            <small>{ tr('automatic_approval_invoicing_project_info') }</small>
                        </label>
                        <ApSwitch
                            id="project-auto-approval-enabled-switch"
                            on={ autoProjectApproving }
                            onChange={ () => {
                                setProjectAutoApproving(!autoProjectApproving)
                                setUnsavedChanges(true)
                            }}
                            disabled={ loading }
                        />
                    </div>
            
            </div>
        </ApFormPage>
    )
}

export default GeneralCustomerSettings