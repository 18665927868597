/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip 			from 'common/ApTooltip/ApTooltip.js';
import ApList from 'common/ApList/ApList.js';
import ApButton from 'common/ApButton/ApButton.js';
import { keyExists, tr } from 'services/Helpers/Helpers.js';
import moment from 'moment';
import './TaskQueue.css';
import _ from 'lodash';

export default class TaskQueue extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			tasks: [],
			showRemoveConfirmDialog: false,
			showPermitModal: false,
			selectedPermitId: null,
			changes:false,
			loading:false,
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		this.getTaskQueue();

	}

	getTaskQueue()
	{
		this.setState({loading: true});
		api({
			method: 'get',
			url: 'taskqueue/list'
		}).then((response) => {
			// console.log(response);
			response = response.map((item) => {
				if (item.temp_changes) {
					if (item.temp_changes.user_id) {
						item.user = item.temp_changes.user;
						item.user.name = item.user.person_detail.full_name;
						item.team_id = null;
						item.team = null;
						item.team_members = null;
					}
					if (item.temp_changes.team_id) {
						item.team = item.temp_changes.team;
						item.team_members = item.temp_changes.team.team_members.map((member) => {
							return keyExists(member, 'user.person_detail.full_name', true, '');
						});
						item.user_id = null;
						item.user = null;
					}
					if (item.temp_changes.begin_at) {
						item.begin_at = item.temp_changes.begin_at;
					}
					if (item.temp_changes.end_at) {
						item.end_at = item.temp_changes.end_at;
					}
				}
				return item;
			});
			this.setState({ tasks: response, loading: false });
		}).catch((error) => {
			console.log(error);
			this.setState({loading: false});
		});

	}
	
	renderItem(assignment) {
		return (
			<div className='taskqueue-assignment-name'>
				<strong>{assignment.name}</strong><br />
				{assignment.customer!=null &&<small>{assignment.customer.name}</small>}<br />
			</div>
			
			
		);
	}

	renderTeam(assignment) {
		
		return (
			<div>
				<ApTooltip text={assignment.team_members.map( (member, index)=> {
					return <small key={index}>{member}<br/></small>
				})}>
				<strong><SvgIcon icon="user-friends" type="solid" />{assignment.team.name}</strong>
				</ApTooltip>
			</div>
			
			
		);
	}

	renderUser(assignment) {
		return (
			<div>
				<strong>{assignment.user.name}</strong>
			</div>
			
			
		);
	}

	getListActions() {
		return [
			/*
			{
				label: tr('delete'),
				icon: "trash",
				disabled: false,
				action: (item, closeFunc) => {
					this.clickDeletePermit(item.id);
					closeFunc();
				}
			},
			*/
		]
	}

	handleChange(value, afterFunc = false) {
		this.setState({
			tasks:value,
			changes: true,
		});
		
	}

	handleRearrange() {
		const tasks = [...this.state.tasks];
		const orderedTasks = _.orderBy(tasks, item => item.end_at || item.begin_at);

		this.setState({tasks: orderedTasks, changes: true})
	}

	saveOrder() {
		api({
			method: 'post',
			url: 'taskqueue/savePriority',
			data: {
				tasks: this.state.tasks,
			}
		}).then((response) => {
			this.setState({
				changes:false,
			})
			window.emitter.emit('popper', {
				type: 'success',
				content: <strong>{tr('saved')}</strong>,
			});
		}).catch((error) => {
			console.log(error);
		});
	}

	render()
	{

		return(

			<div id="TaskQueue">
				<div className="apBox">
                    <div className="apBoxHeader">
                        <h1>{tr('task_queue')}</h1>
                        <small>{tr('task_queue_info')}</small>
						<div className='apBoxCorner'>
							<ApTooltip text={tr('rearrange_task_queue_info')} position='topright'>
								<ApButton onClick={this.handleRearrange} color='green'>{tr('rearrange')}</ApButton>
							</ApTooltip>
						</div>
                    </div>	
					<div className="list">
						<ApList
							loading={this.state.loading}
							items={this.state.tasks || []}
							itemRenderer={this.renderItem}
							columns={[

								{
									style: { width: "200px",padding:"5px" }, value: (item) => <div>
										{item.team != null  && this.renderTeam(item)}
										{item.user != null  && this.renderUser(item)}
									</div>
								},
								{
									style: { width: "180px",padding:"5px" }, value: (item) => <div>
											{item.begin_at!=null && <small>{ tr('start_date') }: {moment(item.begin_at).format("L")}</small>}
											<br />
											{item.end_at!=null && <small>{ tr('end_date') }: {moment(item.end_at).format("L")}</small>}
									</div>
								},
								{
									style: { width: "200px",padding:"5px" }, value: (item) => <div>
										{item.project != null  &&
											<p className="taskqueue-project-name">
												<small>{item.project.name}
												<br />{item.project.project_code}</small>
											</p>}
									</div>
								},
							]}
							icon="tasks"
							sortable
							onSort={(items) => this.handleChange(items)}
						/>
                    	
                    	
                    </div>				
				</div>

				<div className="padding">
					<ApButton className={"save" + (this.state.changes ? " highlight" : "")}
						color="blue"
						onClick={this.saveOrder}
						style={{ float: "right" }}>
						<SvgIcon icon="save" type="solid" />
						{tr('save')}
					</ApButton>
				</div>

			</div>

		);

	}

}
