import React from 'react';
import autoBind from 'react-autobind';

import api              from 'services/Api/Api.js';
import ApButton         from 'common/ApButton/ApButton.js';
import ApValidate       from 'services/ApValidate/ApValidate.js';
import { errorPopper, tr }  from 'services/Helpers/Helpers.js';
import ApTooltip        from 'common/ApTooltip/ApTooltip.js';

import ApModal from 'common/ApModal/ApModal.js';

import { 
	ApInput,
    ApInputStack,
    ApAddon  
} from 'common/ApInput/ApInput.js';

import SvgIcon          from 'common/SvgIcon/SvgIcon.js';

class SupplierEdit extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            name: null,
            taxnumber: null,
            wholesales: [],
            wholesale_id: null,
            wholesale: null,
            purchase_order_details: null,
            changes: false,
        }

        this.validator = new ApValidate(this, {
            name: {filter: 'required', textAll: tr('name_required')},
        });

        autoBind(this);
    }

    componentDidMount()
    {
        if( this.props.supplierId )
            this.getSupplier();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.supplierId !== this.props.supplierId) {
            this.getSupplier();
        }
            
    }

    getSupplier()
    {
        this.setState({loading: true});
        api({
            method: 'get',
            url: `storage/supplier/get/${this.props.supplierId}`,
        }).then((response) => {
            console.log(response);
            this.setState({
                loading: false,
                name:    response.name,
                taxnumber: response.taxnumber,
                purchase_order_details: response.purchase_order_details,
                wholesales: response.wholesales,
                wholesale: response.wholesale,
                wholesale_id: response.wholesale_id,
            });

        }).catch((error) => {
            this.setState({loading: false});
            errorPopper(error, tr('get_error'));
        });
    }

    save()
    {
        this.setState({loading: true});
        api({
            method: 'post',
            url: 'storage/supplier/save',
            data: {
                id:      this.props.supplierId,
                name:    this.state.name,
                taxnumber: this.state.taxnumber,
                purchase_order_details: this.state.purchase_order_details,
                wholesale_id: this.state.wholesale_id,
            },
        }).then((response) => {
            if( typeof this.props.onSave === 'function')
                this.props.onSave();
            this.props.onClose();

        }).catch((error) => {
            this.setState({loading: false});
            errorPopper(error, tr('save_error'));
        });
    }

    getWholesales()
    {
        let suppliers = [];
        let k = [];
        let sc = this.state.wholesales;

        sc.map((s) => {
            if(s.name !== tr('not_given'))
            {
                k = { 
                    'value' : s.id ,
                    'label' : s.name 
                }
                suppliers.push(k);
            }

        })
        const vl = 
        {
            value: '',
            label: `${tr('no_wholesale')}`
        }
        suppliers.unshift(vl);
        return suppliers;
    }

    render()
    {
        let body = 'Loading';
        let wholesale = '';
        if( !this.state.loading )
        {
            if (this.state.wholesales===false) {
                console.log("wholesale off");
            }
            else {
                wholesale = <div className="apFormColumn fullColumn">
                    <ApInputStack gap="0">
						<ApAddon noRightBorder width="400px">
                            { tr('select_wholesale')}
						</ApAddon>
                        <ApInput
                            type="select"
                            id="wholesale"
                            name="wholesale"
                            value={ this.state.wholesale_id }
                            label={ `${tr('select_wholesale')} *` }
                            autoComplete="off"
                            onChange={ ( e ) => this.setState({ wholesale_id: e.target.value, changes: true }) }
                            options={ this.getWholesales() }
                            objKeyId="id"
                            clearable
                        />
                    </ApInputStack>
                </div>
            }

            body = <div>
                <div className="apForm">
                    <div className="apFormColumn fullColumn">
                        <ApInput
                            type="text"
                            id="name"
                            name="name"
                            label={ `${tr('supplier_name')} *` }
                            autoComplete="off"
                            value={ this.state.name }
                            onChange={ ( e ) => this.setState({ name: e.target.value, changes: true }) }
                        />
                    </div>

                    <div className="apFormColumn fullColumn">
                        <ApInput
                            type="text"
                            id="taxnumber"
                            name="taxnumber"
                            label={ tr('business_id') }
                            autoComplete="off"
                            value={ this.state.taxnumber }
                            onChange={ ( e ) => this.setState({ taxnumber: e.target.value, changes: true }) }
                        />
                    </div>

                    <div className="apFormColumn fullColumn">
                        <ApInput
                            type="textarea"
                            id="podetails"
                            name="podetails"
                            label={ tr('info_to_po') }
                            autoComplete="off"
                            rows="6"
                            value={ this.state.purchase_order_details ? this.state.purchase_order_details : '' }
                            onChange={ ( e ) => this.setState({ purchase_order_details: e.target.value, changes: true }) }
                        />
                    </div>
                    
                    {wholesale}
                </div>
            </div>
        }

        const validatorMessage = this.validator.getStatusAll();
        return (
            <div>
                <ApModal
                    show={this.props.show}

                    className="medium"

                    handleClose={this.props.onClose}

                    header={
                        <div className="padding-small">
                            <h4>{ tr('edit_supplier') }</h4>
                        </div>
                        }

                    body={body}
                        

                    footer={
                        <div className="padding" style={{ display: 'flex', flexDirection: 'row' }}>
                            <ApTooltip text={ validatorMessage }>
                                <ApButton className={ "save" + ( this.state.changes ? " highlight" : "" ) } 
                                    style={{ marginLeft: 'auto' }}
                                    color="blue" 
                                    onClick={ this.save } 
                                    disabled={ Boolean(validatorMessage) }>
                                <SvgIcon icon="save" type="solid" />
                                    { tr('save') }
                                </ApButton>
                            </ApTooltip>
                        </div>
                        }

                />
            </div>
        );
    }

}

export default SupplierEdit;
