import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';
import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApTree from 'common/ApTree/ApTree.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import { errorPopper } from 'services/Helpers/Helpers.js';
import './WholesaleLogs.css';

const actionNames = {
    'all': 'Ajo',
    'fetch_all': 'Lataa kaikki',
    'fetch': 'Lataa',
    'read_all': 'Lue kaikki',
    'read': 'Lue',
    'read_file': 'Lue tiedosto',
    'update_all': 'Päivitä kaikki',
    'update': 'Päivitä',
}

const getDuraction = ( start, end ) => 
{
    if( !start ) return null;
    if( !end ) return null;
    start = moment( start );
    end = moment( end );
    let diff = end.diff( start );
    return moment.utc( diff ).format( "HH:mm:ss" );
}

class Wholesales extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            pages: 0,
            data: [],
            rows: [],
            loading: false,
            showModal: false,
            details: null,
            onlyRoots: true,
            errorModal: {},

            errors: [],
        }
        this.tableState = {};
        autoBind(this);
    }

    getData(state = null)
    {
        this.setState({loading: true});

        if(!state)
            state = this.tableState;
        this.tableState = state;

        api({
            method: 'post',
            url: 'management/wholesale/log/runtimes',
            data: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                only_roots: this.state.onlyRoots,
            },
        }).then((response) => {
            this.setState({
                data: response.data,
                pages: response.pages,
                loading: false,
            })
        }).catch((error) => {
            console.error(error);
            this.setState({loading: false});
            errorPopper(error, 'Tukkurilogien haussa tapahtui virhe');
        });
    }

    getDetails( id  )
    {
        //console.log( 'bs', id );
        this.setState({ showModal: true });
        this.setState({ loading: true });

        api({
            method: 'get',
            url: `management/wholesale/log/${ id }/details`,
        }).then((response) => {
            //console.log('tree', response );
            this.setState({
                loading: false,
                details: response,
            });
        }).catch((error) => {
            console.error(error);
            this.setState({loading: false});
            errorPopper(error, 'Tukkurilogin haussa tapahtui virhe');
        });

    }

    getColumns()
    {
        return [
            {
                Header: 'Toiminto',
                accessor: 'action',
                Cell: props => {
                    return <div>
                        { actionNames[ props.value] }<br />
                    </div>
                },
                onClick: ( row ) => { this.getDetails( row.id ) },
            },{
                Header: 'Meta',
                accessor: 'meta',
                onClick: ( row ) => { this.getDetails( row.id ) },
            },{
                Header: 'Alkoi',
                accessor: 'start',
                onClick: ( row ) => { this.getDetails( row.id ) },
            },{
                Header: 'Päättyi',
                accessor: 'end',
                onClick: ( row ) => { this.getDetails( row.id ) },
            },{
                Header: 'Kesto',
                sortable: false,
                Cell: props => {
                    let c = props.original;
                    let duration = getDuraction( c.start, c.end );
                    return <div>
                        { duration }
                    </div>
                },
                onClick: ( row ) => { this.getDetails( row.id ) },
            },{
                Header: 'Virheitä',
                accessor: 'error_count',
                sortable: false,
                onClick: ( row ) => { this.getDetails( row.id ) },
            },
        ];
    }

    closeModal()
    {
        this.setState({ showModal: false });
    }

    showErrors( id )
    {
        this.setState({
            errorModal: {
                show: true,
            },
        });

        this.setState({ loading: true });

        api({
            method: 'get',
            url: `management/wholesale/log/${ id }/errors`,
        }).then((response) => {
            console.log('errors', response );
            this.setState({
                loading: false,
                errors: response,
            });
        }).catch((error) => {
            console.error(error);
            this.setState({loading: false});
            errorPopper(error, 'Tukkurilogi virheiden haussa tapahtui virhe');
        });
    }

    modalBody()
    {
        if( this.state.loading )
        {
            return <div className="padding">
                <div className="apLoader"></div>
            </div>
        }

        const details = this.state.details;
        if( !details ) return null;

        const renderNode = ( node, level = 0 ) => {
            const duration = getDuraction ( node.start, node.end );

            const tooltip = <div>
                <div>Alkoi: { node.start }</div>
                <div>Päättyi: { node.end } </div>
            </div>

            let errorDom = null;
            let subErrorsDom = <div className="apBadge orange">Alivirheitä: { node.r_errors_count }</div>
            if( node.errors_count )
            {
                errorDom = <div className="apBadge red" onClick={ () => this.showErrors( node.id ) }>Virheitä: { node.errors_count }</div>
                if( node.errors_count !== node.r_errors_count )
                    errorDom = <div>{ errorDom }{ subErrorsDom }</div>
            }
            else if( node.r_errors_count )
                errorDom = subErrorsDom;

            return <ApTooltip position="right" text={ tooltip }>
                <div className="duration">
                    { actionNames[ node.action ] } { node.meta }<br />
                    <small>Kesto: { duration }</small>
                    { errorDom }
                </div>
            </ApTooltip>
        };

        return <div className="padding">
            <ApTree
                tree={ details }
                onTreeChange={ ( tree ) => this.setState({ details: tree }) }
                nodeRenderer={ renderNode }
            />
        </div>
    }

    errorModalBody()
    {
        /*
        const errorDoms = this.state.errors.map( e => {
            return <div key={ e.id }>
                <strong>{ e.error }</strong>
                <textarea value={ e.trace } />
            </div>
        });
        */

        return <div className="padding errorModalBody">
            <ApReactTable 
                columns={ [
                    {
                        Header: 'Id',
                        accessor: 'id',
                        width: 50,
                    },{
                        Header: 'Error',
                        accessor: 'error',
                    },{
                        Header: 'Trace',
                        accessor: 'trace',
                    }
                ] }
                data={ this.state.errors }
                loading={ this.state.loading }
                minRows={ 10 }
            />
        </div>
    }

    render()
    {
        return <div className="apBox" id="pageWholesaleLogs">
            <div className="apBoxHeader">
                <h1>Tukkureiden päivitys logit</h1>
                <p>Voit tarkastella kuinka kauvan tukkureiden päivtys on kestänyt, jo onko tullut ongelmia</p>
            </div>
            <div className="padding">
                <div className="apSwitchBlock small">
                    <label htmlFor="runtime-all-switch" className="info">
                        Näytä sisäkkäiset ajot
                        <small>Näytetätäänkö listalla myös sisäkkäiset ajot</small>
                    </label>
                    <ApSwitch
                        small
                        id="runtime-all-switch"
                        on={ !this.state.onlyRoots }
                        onChange={ () => { this.setState({ onlyRoots: !this.state.onlyRoots }, () => { this.getData(); }); } }
                    />
                </div>


                <ApReactTable 
                    columns={ this.getColumns() }
                    data={ this.state.data }
                    loading={ this.state.loading }

                    manual
                    onFetchData={ this.getData }
                    pages={ this.state.pages }
                    minRows={ 10 }
                />
            </div>
            <ApModal
                show={ this.state.showModal }
                handleClose={ () => this.closeModal() }
                className="narrow"
                header={ 
                    <div className="padding">
                        Tiedot
                    </div>
                }
                body={ this.modalBody() }
                footer={
                    <div className="padding">
                        <ApButton  color="white" onClick={ () => this.closeModal( ) }>
                            <SvgIcon icon="check" type="solid" />
                            OK
                        </ApButton>
                    </div>
                }
            />
            <ApModal

                show={ this.state.errorModal.show }
                handleClose={ () => this.setState({ errorModal: {}}) }
                header={ 
                    <div className="padding">
                        Virheet
                    </div>
                }
                body={ this.errorModalBody() }
                footer={
                    <div className="padding">
                        <ApButton  color="white" onClick={  () => this.setState({ errorModal: {}}) }>
                            <SvgIcon icon="check" type="solid" />
                            OK
                        </ApButton>
                    </div>
                }
            />


        </div>
    }
}

export default Wholesales;
