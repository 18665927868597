import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import { tr, errorPopper } from 'services/Helpers/Helpers.js';
import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import auth from '../../services/Authed/Authed.js';
import { apiurl } from '../../services/Api/Api.js';
import moment from 'moment'
import {
    ChatItem, MessageBox, SystemMessage, MessageList, ChatList, Input, Button, Popup, SideBar, Navbar,
    Dropdown, Avatar, LocationMessage, MeetingItem
} from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import './ApChat.css';


import faRegular from 'common/SvgIcon/fontAwesomeRegular.js';
import faSolid from 'common/SvgIcon/fontAwesomeSolid.js';

//Documentation
//https://openbase.com/js/react-chat-elements/documentation

class ApChatTest extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            show: true
        };

        autoBind(this);

    }
    render() {
        const icon = this.props.type === "solid" ? faSolid[this.props.icon] : faRegular[this.props.icon];

        return <div>
            <ChatItem
                avatar={"https://cdn-icons-png.flaticon.com/512/10/10938.png"}
                alt={'Reactjs'}
                title={'chat icon'}
                subtitle={'What are you doing?'}
                date={new Date()}
                unread={1} />

            <MessageBox
                position={'left'}
                type={'photo'}
                text={'messageBox.svg'}
                data={{
                    uri: 'https://cdn-icons-png.flaticon.com/512/10/10938.png',
                    status: {
                        click: false,
                        loading: 0,
                    }
                }} />

            <MessageBox
                reply={{
                    photoURL: 'https://cdn-icons-png.flaticon.com/512/10/10938.png',
                    title: 'Reoply message',
                    titleColor: '#8717ae',
                    message: 'Aliqua amet incididunt id nostrud',
                }}
                onReplyMessageClick={() => console.log('reply clicked!')}
                position={'left'}
                type={'text'}
                text={'Tempor duis do voluptate enim duis velit veniam aute ullamco dolore duis irure.'} />

            <SystemMessage
                text={'End of conversation'} />

            <MessageList
                className='message-list'
                lockable={true}
                toBottomHeight={'100%'}
                dataSource={[
                    {
                        position: 'right',
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                        date: new Date(),
                    },
                    {
                        position: 'right',
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                        date: new Date(),
                    },
                    {
                        position: 'right',
                        type: 'text',
                        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                        date: new Date(),
                    },

                ]} />

            <ChatList
                className='chat-list'
                dataSource={[
                    {
                        avatar: 'https://cdn-icons-png.flaticon.com/512/10/10938.png',
                        alt: 'Reactjs',
                        title: 'Facebook',
                        subtitle: 'What are you doing?',
                        date: new Date(),
                        unread: 0,
                    },
                ]} />



            <Input
                placeholder="Type here..."
                multiline={true}
                rightButtons={
                    <Button
                        color='black'
                        backgroundColor='cyan'
                        text='Send' />
                } />

            {/*// Clear text, e.g.:
            // For pure components, use inputRef instead of this.inputRef

            inputRef = React.createRef();
// ...*/}
            <Input
                ref={el => (this.inputRef = el)}
                placeholder="Type here..." />
            {/* ...
            this.inputRef.clear();*/}

            <Popup
                show={this.state.show}
                header='Lorem ipsum dolor sit amet.'
                headerButtons={[{
                    type: 'transparent',
                    color: 'black',
                    text: 'close',
                    onClick: () => {
                        this.setState({ show: false })
                    }
                }]}
                text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatem animi veniam voluptas eius!'
                footerButtons={[{
                    color: 'white',
                    backgroundColor: '#ff5e3e',
                    text: "Vazgeç",
                }, {
                    color: 'white',
                    backgroundColor: 'lightgreen',
                    text: "Tamam",
                }]} />


            <Navbar
                left={
                    <div>'LEFT' area</div>
                }
                center={
                    <div>'CENTER' area</div>

                }
                right={
                    <div>'RIGHT' area</div>
                } />
            <SideBar
                top={
                    <div>'TOP' area</div>
                }
                center={
                    <div>'CENTER' area</div>
                }
                bottom={
                    <div>'BOTTOM' area</div>
                } />

            <Dropdown
                buttonProps={{
                    text: 'Dropdown',
                }}
                items={[
                    {
                        icon: {
                            component: icon,
                            float: 'left',
                            color: 'red',
                            size: 22,
                        },
                        text: 'lorem'
                    },
                    {
                        icon: {
                            component: icon,
                            float: 'left',
                            color: 'purple',
                            size: 22,
                        },
                        text: 'ipsum'
                    },
                    {
                        text: 'dolor'
                    },
                ]} />
            <Avatar
                src={'https://cdn-icons-png.flaticon.com/512/10/10938.png'}
                alt={'logo'}
                size="large"
                type="circle flexible" />
            <MeetingItem
                subject={'New Release!!!'}
                avatars={[
                    {
                        src: 'https://cdn-icons-png.flaticon.com/512/10/10938.png'
                    },
                    {
                        src: 'https://cdn-icons-png.flaticon.com/512/10/10938.png'
                    },
                    {
                        src: 'https://cdn-icons-png.flaticon.com/512/10/10938.png'
                    },
                    {
                        src: 'https://cdn-icons-png.flaticon.com/512/10/10938.png'
                    },
                ]}
                onMeetingClick={console.log}
                onShareClick={console.log}
                onCloseClick={console.log} />
        </div>


    }
}

ApChatTest.propTypes = {

};



class ApRoomSelect extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            show: true,
        };

        autoBind(this);

    }
    getImageFile(id) {

        return null;
    }
    getUrl(id, props) {
        const name = this.props.name ? this.props.name : 'file';
        if (id == null) {
            return null;
        }
        return `${apiurl}file/id/${id}/${name}?token=${auth.getToken()}`;
    }


    createChatRoomList() {
        let list = this.props.rooms.map((row, index) => {
            let date = new Date(row.created_at);
            if (row.messages.length > 0) {
                date = row.messages[row.messages.length - 1].date;
            }

            return {
                id: row.id,
                avatar: this.getUrl(row.avatarId),
                //alt: 'Ress',
                title: row.title,
                subtitle: row.subtitle,
                date: date,
                unread: row.unread,
                statusColor: row.online != null ? (row.online ? "#44ffb6a6" : "#ff000078") : null,
                originalData: row,
            }
        })
        return list;

    }

    handleModalInputClickAddPerson(item) {
        this.setState({ modalAddPersonSelectedPerson: item });
    }
    onAddPersonSave(value) {
        this.createGroup([value.id, auth.getID()])
    }
    onAddGroupSave(data) {
        //console.log(data);
        let ownId = auth.getID();
        let users = data[1].map((user) => {
            if (user.id !== ownId) {
                return user.id;
            }

        });
        users.push(ownId);
        this.createGroup(users, data[0]);
    }
    onAddOutsiderPersonSave(rawtext, groupId) {
        let textcolumns = rawtext.split("#");
        let users = [{ name: textcolumns[0], id: textcolumns[1], outsider: true }]
        if (groupId) {
            this.AddPersonToGroup(groupId, users);
        }
        else {
            //private
            this.createGroup([users[0], auth.getId]);
        }

    }
    AddPersonToGroup(groupId, Users) {


    }
    createGroup(userIDs, title) {
        let data = { private: title ? false : true, users: userIDs, title: title };


        api({
            method: 'post',
            url: `chat/createGroup`,
            data: data,
        }).then((response) => {
            this.props.groupChange();

        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
            errorPopper(error, tr('get_error'));
        });
    }

    

    render() {

        let actions = [
            {
                label: tr('add_person'),
                icon: "user",
                //disabled: this.state.selectedKeys == 0,
                action: () => this.setState({ showAddPerson: true }),
                closeAfter: true,
            },
            {
                label: tr('add_group'),
                icon: "users",
                //disabled: this.state.selectedKeys == 0,
                action: () => this.setState({ showAddGroup: true }),
                closeAfter: true,
            },
        ]
        return (
            <div className={"LeftList" + (this.props.minified ? " chatSideNavMinified" : "" )} style={{ minHeight: "300px" }}>



                <ApDropdown
                    position="right"
                    actionId={55}
                    actions={actions}
                />
                {!this.props.minified && <div style={{ float: "right", fontWeight: "bold" }}>{auth.getName() + "#" + auth.getID()}</div>}
                {!this.props.minified && <ChatList
                    className='chat-list'
                    dataSource={this.createChatRoomList()}
                    onClick={(data) => this.props.onChange(data)}
                //onContextMenu={data => console.log("onContextMenu",data)}
                //onAvatarError={data => console.log("avatar error",data)}
                />}
                <div className="bottom">
                    <div className="toggleButton" onClick={this.props.toggleMinify}>
                        <SvgIcon icon="angle-double-left" type="solid" />
                    </div>
                </div>
                <ApModalInput
                    show={this.state.showAddPerson}
                    value={this.state.modalAddPersonSelectedPerson ? this.state.modalAddPersonSelectedPerson.name : ""}
                    onSave={this.onAddPersonSave}
                    onClose={() => this.setState({ showAddPerson: false })}
                    title={tr("add_person")}
                    label={tr("add_person")}
                    filteredIDs={{ exludeIds: [auth.getID(), ...this.props.privateUserIds], chat: true }}
                    //info={"info"}
                    type={"selectUser"}
                    required
                />
                <ApModalInput
                    show={this.state.showAddGroup}
                    value={[null, []]}
                    onSave={this.onAddGroupSave}
                    onClose={() => this.setState({ showAddGroup: false })}
                    title={tr("add_group")}
                    label={[tr("group_name"), tr("users")]}
                    filteredIDs={{ exludeIds: [auth.getID()], chat: true }}
                    //info={"info"}
                    type={["text", "selectUsers"]}
                    required
                />

            </div>

        );
    }
    

}

ApRoomSelect.propTypes = {
    name: PropTypes.string,
    rooms: PropTypes.array,
    groupChange: PropTypes.func,
    onChange: PropTypes.func,
    privateUserIds: PropTypes.array,
};

class ApChat extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            show: true
        };
        this.chatlistRef = React.createRef();
        let inputRef = React.createRef();
        autoBind(this);

    }
    handleKeyUp(e) {
        //console.log(e.shiftKey);

        if (e.key == 'Enter' && e.shiftKey == false) {
            e.preventDefault();
            this.props.sendMessage(this.inputRef.input.value, this.inputRef, this.state.replyMessage);
            this.setState({ replyMessage: null });
        }


    }
    componentDidMount() {

        this.props.setChatElement(this.chatlistRef);
        //console.log("ref",this.chatlistRef);
    }
    render() {
        return (
            <div className='MessageAndWritingArea'>
                <MessageList
                    className='messageList'
                    //lockable={false}
                    toBottomHeight={'100%'}
                    dataSource={this.props.messages}
                    //onReplyMessageClick={(e)=>console.log(e)}
                    onScroll={(e) => this.props.needOlderMessages(e)}
                    onReplyClick={(e) => this.setState({ replyMessage: e })}
                    //downButtonBadge={(e) => console.log(e)}
                    onRemoveMessageClick={(e) => this.setState({ confirmDelete: true, selectedMessage: e })}
                    lockable={true}
                    ref={this.chatlistRef}
                />

                {this.state.replyMessage && <div>
                    {tr("reply_message") + ":   "}
                    <SvgIcon icon="times" type="solid" style={{cursor:"pointer"}} fill="red" onClick={() => this.setState({ replyMessage: null })} />


                    <MessageBox
                        position={"left"}
                        type={this.state.replyMessage.type}
                        text={this.state.replyMessage.text}
                        title={this.state.replyMessage.title}
                        titleColor={this.state.replyMessage.titleColor}
                        date={this.state.replyMessage.date}
                    />


                </div>}

                <Input
                    placeholder={tr("write") + " ..."}
                    multiline={true}
                    minHeight={50}
                    autofocus={true}
                    onKeyPress={this.handleKeyUp}
                    ref={el => (this.inputRef = el)}
                    onChange={(el) => this.props.messageChange(el.target.value)}
                    rightButtons={
                        < ApButton className="sendMessage"
                            color="blue"
                            onClick={() => {
                                this.props.sendMessage(this.inputRef.input.value, this.inputRef, this.state.replyMessage);
                                this.setState({ replyMessage: null });
                            }}
                            disabled={this.state.loading}
                            loading={this.state.loading}
                        >
                            {tr("send")}
                        </ApButton>
                    } />
                <ApConfirm
                    show={this.state.confirmDelete}
                    onClose={() => this.setState({ confirmDelete: false, selectedMessage: null })}
                    onConfirm={() => this.props.removeMessage(this.state.selectedMessage)}
                    header={tr('delete_message')}
                    body={
                        <div>
                            <p>
                                <strong>{tr('message_delete_confirm')}</strong>
                            </p>

                        </div>
                    }
                />
            </div >
        );
    }

}

ApChat.propTypes = {


    messages: PropTypes.array,
    removeMessage: PropTypes.func,
    messageChange: PropTypes.func,
    needOlderMessages: PropTypes.func,
    setChatElement: PropTypes.func,
    sendMessage: PropTypes.func,
};

class ApChatHeader extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            show: false,
        };

        autoBind(this);

    }
    getUrl(id, props) {
        const name = this.props.name ? this.props.name : 'file';
        if (id == null) {
            return null;
        }
        return `${apiurl}file/id/${id}/${name}?token=${auth.getToken()}`;
    }
    render() {
        let data = this.props.data;

        let actions = [
            {
                label: tr('leave_group'),
                icon: "times",
                //disabled: this.state.selectedKeys == 0,
                action: () => this.setState({ confirmDelete: true }),
                disabled: data.private,
                closeAfter: true,
            },
        ]

        return (
            <>
                <ApDropdown
                    position="left"
                    actionId={55}
                    actions={actions}
                    style={{}}
                />
                <ChatItem
                    avatar={this.getUrl(data.avatarId)}
                    alt={data.alt}
                    title={data.title}
                    subtitle={data.subtitle}
                    dateString={""}
                    onClick={() => {
                        if (data.private == null || data.private == false) {
                            this.setState({ show: !this.state.show })
                        }
                    }
                    }
                    unread={0} />

                <ApEditGroup
                    show={this.state.show}
                    users={data.users}
                    name={data.title}
                    roomId={data.id}
                    //onSave={this.props.onSave}
                    onClose={() => this.setState({ show: false })}
                    edited={this.props.edited}
                //editMode={!(data.project_id || data.assignment_id) }
                />
                <ApConfirm
                    show={this.state.confirmDelete}
                    onClose={() => this.setState({ confirmDelete: false })}
                    onConfirm={() => this.props.leaveRoom(data)}
                    header={tr('leave_group')}
                    body={
                        <div>
                            <p>
                                <strong>{tr('confirm_leave_room')}</strong>
                            </p>

                        </div>
                    }
                />
            </>




        );
    }

}

ApChatHeader.propTypes = {
    //show: PropTypes.bool.isRequired,
    name: PropTypes.string,
    data: PropTypes.object,
    leaveRoom: PropTypes.func,

};
class ApEditGroup extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            show: false,
            useState: true,
            data: { title: null, users: [], can_edit: [] },
        };

        autoBind(this);

    }
    componentDidMount() {
        let canEditUsers = [];

        if (this.props.users) {

            canEditUsers = [...this.props.users];
            canEditUsers = canEditUsers.filter((u) => u.chatUser.can_edit);
        }

        if (this.props.show) {
            this.setState({ useState: false, data: [this.props.name, [this.props.users, canEditUsers]] });
        }
        else {

            this.setState({ useState: false, data: [this.props.name, this.props.users ? this.props.users : []] });
        }
        if (this.props.projectId || this.props.assignmentId) {
            this.getRoomUsers();
        }
    }
    UNSAFE_componentWillReceiveProps(props) {
        //console.log("propsit tulee", props);
        let canEditUsers = [];
        if (this.props.users) {

            canEditUsers = [...this.props.users];
            canEditUsers = canEditUsers.filter((u) => u.chatUser.can_edit);

        }
        let data = this.state.data;
        if (this.props.projectId || this.props.assignmentId) {
            //data[1] = [this.props.users, canEditUsers];
        }
        else {
            data.title = this.props.name;
            data.users = this.props.users;
            data.can_edit = canEditUsers;
        }

        this.setState({ useState: false, data: data });


    }
    getRoomUsers() {
        let url = ""
        if (this.props.roomId) {
            url = `chat/getRoomUsersByID/` + this.props.roomId;
        }
        else if (this.props.projectId) {
            url = `chat/getRoomUsersByProjectID/` + this.props.projectId;
        }
        else if (this.props.assignmentId) {
            url = `chat/getRoomUsersByAssignmentID/` + this.props.assignmentId;
        }
        else {
            return [];
        }

        api({
            method: 'get',
            url: url,

        }).then((response) => {

            //data.push(permitedUsers);
            this.setState({ data: response, roomId: this.props.roomId ? this.props.roomId : response.roomId });

        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
            errorPopper(error, tr('get_error'));
        });
    }
    getUrl(id, props) {
        const name = this.props.name ? this.props.name : 'file';
        if (id == null) {
            return null;
        }
        return `${apiurl}file/id/${id}/${name}?token=${auth.getToken()}`;
    }
    userCanEdit(users) {
        if (!users || users.length == 0) {
            return true;
        }
        let userId = auth.getID();

        let found = users.find((user) => user.id == userId);

        if (found && found.chatUser.can_edit) {
            return true;
        }
        else {
            return false;
        }


    }

    save(data) {
        let users = data[1][0];
        let can_edit = data[1][1];
        let title = data[0];
        if (typeof this.props.onSave === 'function') {
            this.props.onSave(data);
        }
        else {
            if (!(this.props.projectId || this.props.assignmentId) && !this.userCanEdit(users)) {
                this.close();
                return null;
            }
            users = users.map((user) => {
                return user.id;
            });
            can_edit = can_edit.map((user) => {
                return user.id;
            });
            //if (this.props.editMode)
            //{
            api({
                method: 'post',
                url: 'chat/editGroup',
                data: { users: users, can_edit: can_edit, roomId: this.props.roomId ? this.props.roomId : this.state.roomId, title: title, project_id: this.props.projectId, assignment_id: this.props.assignmentId },
            }).then((response) => {


                this.setState({ data: response });
                if (typeof this.props.edited === 'function') {
                    this.props.edited(response);
                }
                this.close();


            }).catch((error) => {
                this.setState({ loading: false });
                console.error(error);
                errorPopper(error, tr('get_error'));
            });
            //}
            /*else {
                let requestData = { private:  false , users: users, title: data[0] };


                api({
                    method: 'post',
                    url: `chat/createGroup`,
                    data: requestData,
                }).then((response) => {
                    this.props.groupChange();

                }).catch((error) => {
                    this.setState({ loading: false });
                    errorPopper(error, tr('get_error'));
                });
            }*/
        }
    }
    close() {
        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
        else {
            this.setState({ show: false });
        }
    }
    render() {

        let canEdit;
        if (this.state.data) {
            canEdit = this.userCanEdit(this.state.data.users);
        }
        if (this.props.overRideEditPermission) {
            canEdit = this.props.overRideEditPermission;
        }

        return (
            <div id='ChatGroupEdit'>
                <ApModalInput
                    show={this.state.useState ? this.state.show : this.props.show}
                    value={[this.state.data.title, [this.state.data.users, this.state.data.can_edit]]}
                    onSave={this.save}
                    onClose={this.close}
                    title={canEdit ? tr("edit_group") : tr("spectate_group")}
                    label={[tr("group_name"), [tr("users"), tr("users_who_can_edit")]]}
                    disabled={[!canEdit, [!canEdit, !canEdit]]}
                    //filteredIDs={{ exludeIds: [auth.getID()], chat: true }}
                    //info={"info"}
                    type={["text", "selectUsersAndSelectModifiers"]}
                    required
                />
            </div>




        );
    }

}

ApEditGroup.propTypes = {
    show: PropTypes.bool,
    users: PropTypes.array,
    name: PropTypes.string,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    edited: PropTypes.func,
    roomId: PropTypes.number,
    projectId: PropTypes.number,
    assignmentId: PropTypes.number,
    overRideEditPermission: PropTypes.bool,
};

export {
    ApChat,
    ApChatHeader,
    ApRoomSelect,
    ApChatTest,
    ApEditGroup
};