/* eslint-disable eqeqeq  */
/* eslint-disable no-unused-vars */
import { parseNumber, keyExists } from  'services/Helpers/Helpers.js';

// This should match with DB table 'static_work_hour_statuses'
const WOKRHOUR_STATUSES = {
    0: { name: "suggested", text: "Ehdotettu" },
    1: { name: "open",      text: "Avoinna" },
    2: { name: "submitted", text: "Lähetetty" },
    3: { name: "rejected",  text: "Hylätty" },
    4: { name: "approved",  text: "Hyväksytty" },
    5: { name: "confirmed", text: "Vahvistettu" },
    6: { name: "payable",   text: "Maksussa" },
    7: { name: "paid",      text: "Maksettu" },
};

export function statusCode( name )
{
    for (const key in WOKRHOUR_STATUSES) {
        if( WOKRHOUR_STATUSES[ key ].name == name )
            return key;
    }
    return false;
}

export function statusName( code )
{
    for (const key in WOKRHOUR_STATUSES) {
        if( key == code )
            return WOKRHOUR_STATUSES[ key ].name;
    }
    return false;
}

export function statusText( code )
{
    for (const key in WOKRHOUR_STATUSES) {
        if( key == code )
            return WOKRHOUR_STATUSES[ key ].text;
    }
    return false;
}

// TES tulkitse päivä
export function dayInterpretation( entries, settings )
{   
    const weeklyGoalRemaining   = settings.hours_weekly - settings.weeklyWorktime; 
    const dailyGoal             = settings.dayNumber <= settings.days_weekly ? Math.min( settings.hours_daily, weeklyGoalRemaining ) : 0;
    const overtimeLimit         = 8; // Specified in the Working Hours Act (Työaikalaki)
    const overtimeWeeklyLimit   = 40; // Specified in the Working Hours Act (Työaikalaki) 
    const overtime50Limit       = 1;
    const flexitimeOn           = settings.flex_time_allowed;

    const weeklyExtraworkRemaining  = ( overtimeWeeklyLimit - settings.hours_weekly ) - settings.weeklyExtrawork;
    const extraworkLimit            = overtimeLimit - dailyGoal;

    let errors = [];
    let totalHours = 0;
    let flexiOvertime = 0;
    // let absenceHours = 0;

    // All day absence 
    if( entries.absence )
    {
        if( entries.absence.worktime )
            totalHours += settings.hours_daily;
    }
    // Not an absence day
    else 
    {
        if( entries.hours.length > 0 )
            entries.hours.forEach( entry => {
                const hours = parseNumber( entry.hours );

                if( entry.hour_type.worktime || entry.hour_type.overtime )
                {
                    totalHours += hours;
                    if( entry.hour_type.overtime ) 
                        flexiOvertime += hours;
                }

                // if( entry.hour_type.absence )
                    // absenceHours += hours;

            });

        if( entries.travels.length > 0 )
            entries.travels.forEach( entry => {
                totalHours += parseNumber( entry.hours );
            });
    }

    
    
    if( totalHours > 24 )
        errors.push( "Työtunteja enemmän kuin tunteja vuorokaudessa");

    if( flexiOvertime > totalHours )
        errors.push( "Ylityötunteja ei voi olla enemmän kuin työtunteja" );

    let normal      = Math.min( totalHours, dailyGoal );
    let extrawork   = Math.min( totalHours - normal, extraworkLimit, weeklyExtraworkRemaining );
    let overtime    = totalHours - overtimeLimit;
    let flexitime   = 0;

    if( !flexitimeOn && normal < dailyGoal )
        errors.push("Työtunteja on " + (dailyGoal - normal) + " h liian vähän");

    if( flexitimeOn )
    {
        normal      = totalHours - flexiOvertime;
        extrawork   = Math.min( flexiOvertime, ( overtimeLimit - normal ) );
        overtime    = flexiOvertime - extrawork;
        flexitime   = normal - ( settings.dayNumber <= settings.days_weekly ? settings.hours_daily : 0 );
    }

    // First overtime hour is 50%, and the rest 100%
    let overtime50 = Math.min( overtime, overtime50Limit );
    let overtime100 = overtime - overtime50;

    let result = [];

    /*
    result.push( "Säännöllinen työaika: " + settings.hours_daily + " h/päivä, " + settings.hours_weekly + " h/vko" );
    result.push( "Päivä: " + settings.dayNumber + "/7" );
    result.push( "Viikon työtunnit: " + settings.weeklyWorktime + " / " + settings.hours_weekly + " h" );
    result.push( "Viikon lisätyöt: " + settings.weeklyExtrawork + " h" );
    result.push( "Viikon ylityöt: " + settings.weeklyOvertime + " h" );
    result.push( "Viikon liukuman muutos: " + settings.weeklyFlexitime + " h" );
    result.push( "-" );
    */

    result.push( "Työtunteja: " + normal + " h" );

    if( extrawork > 0 )
        result.push( "Lisätyö: " + extrawork + " h" );

    if( overtime50 > 0 )
        result.push( "Ylityö 50%: " + overtime50 + " h" );

    if( overtime100 > 0 )
        result.push( "Ylityö 100%: " + overtime100 + " h" );

    if( flexitimeOn )
        result.push( "Liukuman muutos: " + flexitime + " h" );

    return {
        total: totalHours,
        normal: normal,
        extrawork: extrawork,
        overtime: overtime,
        overtime50: overtime50,
        overtime100: overtime100,
        flexitime: flexitime,
        errors: errors,
        result: result,
        settings: settings,
    };

}

// TES tulkitse viikko
export function weekInterpretation( data, settings )
{
    let errors = [];
    let daySettings = {
        // Fixed settings for user
        hours_daily:        keyExists( settings, 'contract.hours_daily', true ),
        hours_weekly:       keyExists( settings, 'contract.hours_weekly', true ),
        days_weekly:        keyExists( settings, 'contract.days_weekly', true ),
        flex_time_allowed:  keyExists( settings, 'contract.flex_time_allowed', true ),
        
        // Cumulative stats for week
        dayNumber:          1,
        weeklyTotalhours:   0,
        weeklyWorktime:     0,
        weeklyExtrawork:    0,
        weeklyOvertime:     0,
        weeklyFlexitime:    0,
    }

    for( let i = 0; i < data.length; i++ ) 
    {
        const dayInt = dayInterpretation( data[i].entries, { ...daySettings } );
        data[i].interpretation = dayInt;

        // Add day stats to cumulative stats
        daySettings.dayNumber          += 1;
        daySettings.weeklyTotalhours   += parseFloat( dayInt.total );
        daySettings.weeklyWorktime     += parseFloat( dayInt.normal );
        daySettings.weeklyExtrawork    += parseFloat( dayInt.extrawork );
        daySettings.weeklyOvertime     += parseFloat( dayInt.overtime );
        daySettings.weeklyFlexitime    += parseFloat( dayInt.flexitime );

        if( dayInt.errors && dayInt.errors.length > 0 )
            dayInt.errors.forEach( err => {
                errors.push( data[i].date + " - " + err );
            });
    }


    let weeklyOvertime = Math.max( daySettings.weeklyTotalhours - daySettings.weeklyOvertime - 40, 0 );

    // TODO: Check this from payroll people!!
    // When using flexitime, user cannot have weekly overtime at all (only daily overtime)
    if( daySettings.flex_time_allowed )
        weeklyOvertime = 0;

    return {
        weekData: data,
        summary: {
            errors:             errors,
            total:              daySettings.weeklyTotalhours,
            normal:             daySettings.weeklyWorktime,
            extrawork:          daySettings.weeklyExtrawork,
            overtime_daily:     daySettings.weeklyOvertime,
            overtime_weekly:    weeklyOvertime,
            flexitime:          daySettings.weeklyFlexitime,
        }
    };
}
