/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import moment from 'moment';
import { tr, currentLang } from 'services/Helpers/Helpers.js';

import './Notifications.css';

import { timeSince } from 'services/Helpers/Helpers.js';
import ApButton from 'common/ApButton/ApButton';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar';

class Notifications extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            items: [],
            loading: true,         

            deleteConfirmModal: {},
        };
        autoBind(this); 
    }

    componentDidMount()
    {
        this.getNotifications()
    }

    goTo( link )
    {
        window.emitter.emit( 'goTo', {
            pathname: link,
        });
    }

    getNotifications()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'currentUser/notifications',
            errorPopper: tr('get_notifications_error'),
        }).then((response) => {
            const unread = response.notifications.filter( n => !n.read );
            window.emitter.emit('notification', { count: unread.length });
            this.setState({
                items: response.notifications,
                loading: false
            });
            if (this.stickyBar && typeof this.stickyBar.updateBounds === 'function') {
                this.stickyBar.updateBounds();
            }
        }).catch((error) => {
            this.setState({
                loading: false
            });
        });
    }

    markRead( id )
    {
        let items = this.state.items.slice(0);

        items = items.map( i => {
            if( i.id === id )
                i.read = true;
            return i;
        })
        this.setState({ items: items })

        api({
            method: 'post',
            url: `currentUser/notification/${ id }/read`,
            errorPopper: tr('read_notification_error'),
        }).then((response) => {
            window.emitter.emit('notification', { count: response, });
        }).catch((error) => {
        });
    }

    markAllRead() {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: `currentUser/notifications/read/all`,
            errorPopper: tr('read_notification_error'),
        }).then((response) => {
            this.getNotifications();
            window.emitter.emit('notification', { count: 0 });

            let items = this.state.items.slice(0);
            items = items.map( i => {
                i.read = true;
                return i;
            })

            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    deleteRow()
    {
        const id = this.state.deleteConfirmModal.id;
        this.setState( { multi: false });
        this.setState({ loading: true });
        api({
            method: 'post',
            url: `currentUser/notification/${ id }/remove`,
            errorPopper: tr('delete_error'),
        }).then((response) => {
            this.getNotifications();
            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    deleteSelected() {
        const selectedItems = this.state.items.filter( i => i.checked );
        if (selectedItems.length > 0) {
            this.setState({deleteConfirmModal: { show: true }});           
            this.setState({ loading: true });
            api({
                method: 'post',
                url: `currentUser/notifications/remove`,
                data: { ids: selectedItems.map( i => i.id ) },
                errorPopper: tr('delete_error'),
            }).then((response) => {
                this.getNotifications();
                this.setState({ loading: false });
            }).catch((error) => {
                console.log('error', error);
                this.setState({ loading: false });
            });
        }
    }

    renderNotification( item )
    {
        let className = ['item'];
        let clickFunction = null;

        if( !item.read )
        {
            clickFunction = () => this.markRead( item.id );
            className.push('unread');
        }

        if (item.checked) {
            className.push('checked');
        }

        const checkItem = () => {
            let foundIndex = this.state.items.findIndex( i => i.id === item.id );
            if (foundIndex !== -1) {
                const items = this.state.items.slice(0);
                items[foundIndex].checked = !items[foundIndex].checked;
                this.setState({ items: items });
            }
        }

        let linkDom = null;
        if( item.link )
            linkDom = <div className="linkContainer">
                <span  className="link" onClick={ () => this.goTo( item.link ) }>Linkki</span>
            </div>

        return <div key={item.id } className={ className.join(' ') } onClick={ clickFunction }>
            <div className="title">
                <input className='checkItemInput' type='checkbox' checked={Boolean(item.checked)} onChange={checkItem} /> 
                <span className='pointer' onClick={checkItem}>{ item.title }</span>
            </div>
                <div className="remove" onClick={ () => {this.setState({deleteConfirmModal: { show: true, id: item.id }})}  }>
                    <SvgIcon icon="trash" type="solid" />
                </div>
            <div className="description">{ item.description }</div>
            { linkDom }
            <div className="time">
                { moment( item.created_at ).locale(currentLang()).format('LLL') } ({ timeSince( item.created_at ) })
            </div>
        </div>
    }

    checkAll() {
        const items = this.state.items.slice(0);
        // unselect all if all are selected
        if (items.every( i => i.checked )) {
            items.forEach( i => i.checked = false );
        } else {
            items.forEach( i => i.checked = true );
        }
        this.setState({ items: items });
    }

    unCheckAll() {
        const items = this.state.items.slice(0);
        // unselect all     
        items.forEach( i => i.checked = false );      
        this.setState({ items: items });
    }

    render() 
    {
        let content = this.state.items.map( i => this.renderNotification( i ) );

        const no_selected = Array.isArray(this.state.items) && !this.state.items.some( i => i.checked );

        if( this.state.items.length === 0 && !this.state.loading )
        {
            content = <div className="apMsg">
                <h4>{ tr('no_notifications') }</h4>
            </div>
        }
        const selectedItems = this.state.items.filter( i => i.checked );

        let onConfirm= this.deleteRow 
        let header= tr('delete_notification')
        let body= tr('delete_notification_confirm')  
        if (selectedItems.length>0) {
       
          
            onConfirm= this.deleteSelected 
            header= tr('delete_notifications')
            body=  tr('delete_notification_confirms') 
          
        }
       

        return <div className="apBox" id="pageNotifiations">
            <div className="apBoxHeader">
                <h1>{ tr('notifications') }</h1>
                <p>{ tr('notifications_info') }</p>
            </div>
            <div className="padding">
                { (this.state.items.length !== 0)
                 ? <ApButton onClick={this.checkAll} color='green' size='small'>
                        <SvgIcon type='solid' icon='check' /> {tr('select_all')}
                    </ApButton>
                 : null }
                { content }
            </div>
            <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar = node }>
                <div className='saveBar justify-end' style={{backgroundColor: 'white', paddingRight: '1em', gap: '10px'}}>
                    <ApButton onClick={this.markAllRead} color='green' disabled={this.state.loading} className='mark-all-read-button'>
                        <SvgIcon type='solid' icon='check' /> {tr('mark_all_read')}
                    </ApButton>
                    <ApButton color='red' disabled={no_selected} className='delete-all-button' onClick={ () => {this.setState({deleteConfirmModal: { show: true}}) }  }>
                
                    {/* <ApButton onClick={this.deleteSelected} color='red' disabled={no_selected} className='delete-all-button'> */}
                        <SvgIcon type='solid' icon='trash' />
                        {tr('delete_selected')}
                    </ApButton>
                </div>
            </ApStickyBar>

           
            <ApConfirm
                show={ Boolean( this.state.deleteConfirmModal.show ) }
                onClose={ () => { this.setState( { deleteConfirmModal: {} } ) ; this.unCheckAll() } } 
             
                onConfirm = { onConfirm }
                header={ header }
                body={ body }
            
            />
        </div>
    }
}

export default Notifications;

