import React from "react";

import styles from "./BudgetStatistics.module.css";
import CalculationsTable from "./CalculationsTable/CalculationsTable";
import MetersTable from "./MetersTable/MetersTable";
import ProjectMeters from "./ProjectMeters/ProjectMeters";

const BudgetStatistics = (props) => {
  const items = props.selectedProject.components.filter(
    (component) => component.type_name === "item"
  );

  return (
    <div className={`padding ${styles.statisticsContainer}`}>
      <CalculationsTable selectedProject={props.selectedProject} />
      <hr />
      <MetersTable items={items} />
      <hr />
      <ProjectMeters
        budgetDetailOptions={props.budgetDetailOptions}
        selectedProject={props.selectedProject}
        handleProjectMeter={props.handleProjectMeter}
        deleteProjectMeter={props.deleteProjectMeter}
        mainProject={props.mainProject}
      />
    </div>
  );
};

export default BudgetStatistics;
