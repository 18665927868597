/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { errorPopper, tr } from 'services/Helpers/Helpers.js'
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ComponentTooltip from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApModal from 'common/ApModal/ApModal.js';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import './MassImport.css';

import { getComponentStatusNames } from 'modules/Storage/common/StorageHelpers.js';

// Exclude defines indexes that cannot appear at the same time
const defaultHeaderOptions = {
    is_active: {
        label: tr('active'),
        icon: 'toggle-on',
        headerValues: [tr('active')],
    },
    taxnumber: {
        label: tr('business_id'),
        icon: 'align-center',
        headerValues: [tr('business_id')],
    },
    responsible_person_first_name: {
        label: tr('responsible_person_first_name'),
        icon: 'signature',
        headerValues: [tr('responsible_person_first_name')],
    },
    responsible_person_last_name: {
        label: tr('responsible_person_last_name'),
        icon: 'signature',
        headerValues: [tr('responsible_person_last_name')],
    },
    parent_company: {
        label: tr('parent_company'),
        icon: 'align-center',
        headerValues: [tr('parent_company')],
    },
    extranet_active: {
        label: tr('extranet_active'),
        icon: 'toggle-on',
        headerValues: [tr('extranet_active')],
    },
    is_customer: {
        label: tr('extranet_customer_active'),
        icon: 'toggle-on',
        headerValues: [tr('extranet_customer_active')],
    },
    is_supplier: {
        label: tr('extranet_supplier_active'),
        icon: 'toggle-on',
        headerValues: [tr('extranet_supplier_active')],
    },
    phone: {
        label: tr('phone'),
        icon: 'phone',
        headerValues: [tr('phone')],
    },
    email: {
        label: tr('email'),
        icon: 'envelope',
        headerValues: [tr('email')],
    },
    website: {
        label: tr('website'),
        icon: 'external-link-alt',
        headerValues: [tr('website')],
    },
    no_mailing: {
        label: tr('no_customer_mailing'),
        icon: 'toggle-on',
        headerValues: [tr('no_customer_mailing')],
    },


    //oletus ehdot
    default_payment_condition: {
        label: tr('default_payment_term'),
        icon: 'align-center',
        headerValues: [tr('default_payment_term')],
    },
    default_delivery_term: {
        label: tr('default_delivery_term'),
        icon: 'align-center',
        headerValues: [tr('default_delivery_term')],
    }, 
    default_delivery_method: {
        label: tr('default_delivery_method'),
        icon: 'align-center',
        headerValues: [tr('default_delivery_method')],
    },
    default_billing_method: {
        label: tr('default_billing_method'),
        icon: 'align-center',
        headerValues: [tr('default_billing_method')],
    },
    default_tax: {
        label: tr('default_vat'),
        icon: 'align-center',
        headerValues: [tr('default_vat')],
    },
    default_tax_code: {
        label: tr('default_vat_code'),
        icon: 'align-center',
        headerValues: [tr('default_vat_code')],
    },
    cost_center_code: {
        label: tr('cost_center'),
        icon: 'align-center',
        headerValues: [tr('cost_center'), tr('cost_center_code')],
    },


    //laskutus
    e_invoice_ovt: {
        label: tr('edi_id'),
        icon: 'hashtag',
        headerValues: [tr('edi_id')],
    },
    e_invoice_address: {
        label: tr('e_invoice_address'),
        icon: 'hashtag',
        headerValues: [tr('e_invoice_address')],
    },
    e_invoice_operator: {
        label: tr('e_invoice_operator_id'),
        icon: 'hashtag',
        headerValues: [tr('e_invoice_operator_id')],
    },

    ext_efina_number: {
        label: tr('customer_number_in_efina'),
        icon: 'list-ol',
        headerValues: [tr('customer_number_in_efina')],
    },
    //yleinen lisättävä osoite
    generalAddressAdress: {
        label: tr('add')+" "+tr('general_address')+": "+tr('address'),
        icon: 'book',
        headerValues: [tr('address'),tr('add')+" "+tr('general_address')+": "+tr('address')],
    },
    generalAddressCity: {
        label: tr('add')+" "+tr('general_address')+": "+tr('city'),
        icon: 'book',
        headerValues: [tr('city'),tr('add')+" "+tr('general_address')+": "+tr('city')],
    },
    generalAddressPostalCode: {
        label: tr('add')+" "+tr('general_address')+": "+tr('postal_code'),
        icon: 'book',
        headerValues: [tr('postal_code'),tr('add')+" "+tr('general_address')+": "+tr('postal_code')],
    },
    generalAddressCountry: {
        label: tr('add')+" "+tr('general_address')+": "+tr('country'),
        icon: 'book',
        headerValues: [tr('country'),tr('add')+" "+tr('general_address')+": "+tr('country')],
    },
    //supplier
    supplierAddressAdress: {
        label: tr('supplier_address')+": "+tr('address'),
        icon: 'book',
        headerValues: [tr('address'),tr('supplier_address')+": "+tr('address')],
    },
    supplierAddressCity: {
        label: tr('supplier_address')+": "+tr('city'),
        icon: 'book',
        headerValues: [tr('city'),tr('supplier_address')+": "+tr('city')],
    },
    supplierAddressPostalCode: {
        label: tr('supplier_address')+": "+tr('postal_code'),
        icon: 'book',
        headerValues: [tr('postal_code'),tr('supplier_address')+": "+tr('postal_code')],
    },
    supplierAddressCountry: {
        label: tr('supplier_address')+": "+tr('country'),
        icon: 'book',
        headerValues: [tr('country'),tr('supplier_address')+": "+tr('country')],
    },

    //shippingAddress
    shippingAddressAdress: {
        label: tr('delivery_address')+": "+tr('address'),
        icon: 'book',
        headerValues: [tr('address'),tr('delivery_address')+": "+tr('address')],
    },
    shippingAddressCity: {
        label: tr('delivery_address')+": "+tr('city'),
        icon: 'book',
        headerValues: [tr('city'),tr('delivery_address')+": "+tr('city')],
    },
    shippingAddressPostalCode: {
        label: tr('delivery_address')+": "+tr('postal_code'),
        icon: 'book',
        headerValues: [tr('postal_code'),tr('delivery_address')+": "+tr('postal_code')],
    },
    shippingAddressCountry: {
        label: tr('delivery_address')+": "+tr('country'),
        icon: 'book',
        headerValues: [tr('country'),tr('delivery_address')+": "+tr('country')],
    },

    //billingAddress

    billingAddressAdress: {
        label: tr('billing_address')+": "+tr('address'),
        icon: 'book',
        headerValues: [tr('address'),tr('billing_address')+": "+tr('address')],
    },
    billingAddressCity: {
        label: tr('billing_address')+": "+tr('city'),
        icon: 'book',
        headerValues: [tr('city'),tr('billing_address')+": "+tr('city')],
    },
    billingAddressPostalCode: {
        label: tr('billing_address')+": "+tr('postal_code'),
        icon: 'book',
        headerValues: [tr('postal_code'),tr('billing_address')+": "+tr('postal_code')],
    },
    billingAddressCountry: {
        label: tr('billing_address')+": "+tr('country'),
        icon: 'book',
        headerValues: [tr('country'),tr('billing_address')+": "+tr('country')],
    },

    //yhteyshenklö
    contact_name: {
        label: tr('contact_name'),
        icon: 'address-book',
        headerValues: [tr('contact_name')],
    },
    contact_last_name: {
        label: tr('contact_last_name'),
        icon: 'address-book',
        headerValues: [tr('contact_last_name')],
    },
    contact_old_name: {
        label: tr('contact_old_name'),
        icon: 'address-book',
        headerValues: [tr('contact_old_name')],
    },
    contact_old_last_name: {
        label: tr('contact_old_last_name'),
        icon: 'address-book',
        headerValues: [tr('contact_old_last_name')],
    },
    contact_title: {
        label: tr('contact_title'),
        icon: 'address-book',
        headerValues: [tr('contact_title')],
    },
    contact_phone: {
        label: tr('contact_phone'),
        icon: 'address-book',
        headerValues: [tr('contact_phone')],
    },
    contact_phone2: {
        label: tr('contact_phone2'),
        icon: 'address-book',
        headerValues: [tr('contact_phone2')],
    },
    contact_email: {
        label: tr('contact_email'),
        icon: 'address-book',
        headerValues: [tr('contact_email')],
    },
    contact_no_mailing: {
        label: tr('no_customer_mailing_for_contact'),
        icon: 'toggle-on',
        headerValues: [tr('no_customer_mailing_for_contact')],
    },

    add_group: {
        label: tr('add_group'),
        icon: 'address-book',
        headerValues: [tr('add_group')],
    },

    remove_group: {
        label: tr('remove_group'),
        icon: 'address-book',
        headerValues: [tr('remove_group')],
    },

    add_contact_group: {
        label: tr('add_contact_group'),
        icon: 'address-book',
        headerValues: [tr('add_contact_group')],
    },

    remove_contact_group: {
        label: tr('remove_contact_group'),
        icon: 'address-book',
        headerValues: [tr('remove_contact_group')],
    },


    // description: {
    //     label: tr('free_text'),
    //     icon: 'info',
    //     headerValues: [tr('free_text')],
    // },
    // alt_name: {
    //     label: tr('unifying_component'),
    //     icon: 'signature',
    //     multiple: true,
    //     headerValues: [`${tr('unifying_name')}*`],
    // },
}

const filters = [
    { value: 'noComponent', label: tr('unlinked') },
    { value: 'invalid', label: tr('invalid_value') },
    { value: 'change', label: tr('value_changes') },
    { value: 'new', label: tr('new_rows') },
];

class MassImport extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            loading: false,
            rows: [],
            selected_file: null,
            code_options: {},

            identifiers: [],

            otherCols: [],

            headerOptions: defaultHeaderOptions,

            filtersSelected: [],

            counts: {},

            rowChangeIds: [],
            rowsInvalidIds: [],
            rowsNoComponenIds: [],
            rowsNewIds: [],

            tooManyRows: false,

            linkModal: {},

        }
        autoBind(this);
    }

    componentDidMount()
    {
        //this.getRelated();
    }

    getRelated()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'storage/masslist/import',
            errorPopper: tr('get_error'),
        }).then((response) => {
            let headerOptions = { ...this.state.headerOptions }


            this.setState({
                loading: false,
                headerOptions: headerOptions,
                identifiers: response.identifiers,
                calculatables: response.calculatables,
                properties: response.properties,
                code_options: response.code_options,
            });

        }).catch((error) => {
            this.setState({ loading: false });
            console.error(error);
        });
    }

    import()
    {
        this.setState({
            loading: true,
            rows: [],
        });

        let data = new FormData();
        data.append( 'file', this.state.selected_file );

        api({
            method: 'post',
            url: 'crm/company/masslist/readxlsx',
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(( response ) => {
            let headerOptions = this.state.headerOptions;
            let otherCols = this.state.otherCols;

            // If we have made changes to headers and want to reload file we dont want to loose the changes
            // but if we load another file we want to update that, so we have a loose check that
            // if the column ammount is same then we keep the file
            if( otherCols.length !== response.headers.length )
            {
                let usedCols = [];

                otherCols = response.headers.map( h => {

                    if( !h ) return null;
                    h = `${h}`.toLowerCase();

                    for ( let key in headerOptions )
                    {

                        const fOptions = headerOptions[ key ].headerValues;
                        if( !Array.isArray( fOptions ) ) continue;

                        let found = fOptions.find( f => {
                            f = f.toLowerCase();

                            let tmp = h;

                            if( f.slice(-1) === '*' )
                            {
                                const l = f.length - 1;
                                f = f.substring( 0, l )
                                tmp = h.substring( 0, l )
                            }
                            return ( tmp === f );
                        })
                        if( found )
                        {
                            // Skip so there are no same column dublicated
                            if( usedCols.includes( key ) && !headerOptions[ key ].multiple  )
                                return null;

                            // Also add exclueds as used cols
                            if( headerOptions[ key ].exclude )
                            {
                                headerOptions[ key ].exclude.forEach( e => usedCols.push( e ));
                            }

                            usedCols.push( key );
                            return key;
                        }
                    }

                    return null;
                });
            }

            this.setState({
                loading: false,
                rows: response.rows,
                otherCols: otherCols,
                tooManyRows: response.tooManyRows,
            },() => {
                this.updateRows();
            });
        }).catch( ( error ) => {
            console.error(error );
            errorPopper(error, tr('file_read_error'));
            this.setState({
                loading: false,
            });
        });
    }

    save()
    {
        const idsToSave = [ ...this.state.rowChangeIds, ...this.state.rowsNewIds ];
        let saveData = this.state.rows.filter( f => idsToSave.includes( f.id ) );

        saveData = saveData.map( row => {
            let update = {};
            row.otherDetails.forEach( (detail, index) => {
                if( [ 'newCompany', 'change' ].includes( detail.status ) )
                {
                    const value = row.other[ index ];
                    let column = this.state.otherCols[ index ];

                    // If column can be mulitple times dont override the data
                    if( this.state.headerOptions[ column ].multiple )
                        column = `${ column }_${ index }`

                    update[ column ] = value;
                }
            })
            if (row.crmCompany )
            {
                return {
                    id: row.crmCompany.id,
                    contactId: row.crmCompany.contact ? row.crmCompany.contact.id : null,
                    values: update,
                }
            }
            else
            {
                // New component
                return {
                    name: row.name,
                    values: update,
                };
            }
        })

        api({
            method: 'post',
            url: 'crm/company/masslist/save',
            data: { companies: saveData },
            errorPopper: tr('file_read_error'),
        }).then(( response ) => {
            this.setState({
                loading: false,
                rows: [],
                columnSettings: [],
            });
        }).catch( ( error ) => {
            this.setState({ loading: false });
        });
    }

    updateRows(reupdate=false)
    {
        let counts = {
            colInvalid: 0,
            colToNull: 0,
            colChange: 0,

            rows: 0,
            rowsInvalid: 0,
            rowsNoComponent: 0,
            rowsChange: 0,
            rowsNew: 0,

            unusedCols: 0,
        }

        this.state.otherCols.forEach( c => {
            if( c === null ) counts.unusedCols++;
        });

        let rowChangeIds = [];
        let rowsInvalidIds = [];
        let rowsNoComponenIds = [];
        let rowsNewIds = [];

        let rows = this.state.rows.slice(0);
        rows = rows.map( ( r, rIndex ) => {

            let rowInvalid = false;
            let rowChange = false;
            let rowToNull = false;

            const typeColIndex = this.state.otherCols.findIndex( c => c === 'type' );
            r.type = null;
            if( r.component ) r.type = r.component.type_name;
            if( typeColIndex >= 0 ) r.type = r.other[ typeColIndex ]

            r.otherDetails = this.state.otherCols.map(( selected, i ) => {
                const details = this.colDetails(r, selected, r.other[i], rIndex, reupdate )
                if( details.status === 'invalid' )
                {
                    counts.colInvalid++;
                    rowInvalid = true;
                }
                else if( details.status === 'toNull' )
                {
                    counts.colToNull++;
                    rowToNull = true;
                }
                else if( details.status === 'change' )
                {
                    counts.colChange++;
                    rowChange = true;
                }
                return details;
            });

            counts.rows++;

            if( r.link === 'newCompany' )
            {
                counts.rowsNew++;
                rowsNewIds.push( r.id )
            }
            else if( r.link === 'noLink' )
            {
                counts.rowsNoComponent++;
                rowsNoComponenIds.push( r.id );
            }

            if( rowInvalid )
            {
                counts.rowsInvalid++;
                rowsInvalidIds.push( r.id );
            }
            if( rowChange )
            {
                counts.rowsChange++;
                rowChangeIds.push( r.id );
            }
            if( rowToNull )
            {
                counts.rowsChange++;
                rowChangeIds.push( r.id );
            }

            return r;
        });

        this.setState({
            rows: rows,
            counts: counts,

            rowChangeIds: rowChangeIds,
            rowsInvalidIds: rowsInvalidIds,
            rowsNoComponenIds: rowsNoComponenIds,
            rowsNewIds: rowsNewIds,
        },
            () => {
                if (this.state.columnsNeedUpdate) {
                    this.setState({ columnsNeedUpdate: false }, () => {
                        this.updateRows(true);
                    })
                }
        });
        
    }
    findContact(contacts, firstName, lastName) {
        let contact = contacts.find((item) => (item.first_name == firstName && item.last_name == lastName))
        return contact;
    }

    colDetails(row, selected, updateValue, key, reupdate)
    {
        // console.log("row", row);
        // console.log("selected", selected);
        // console.log("updateValue", updateValue);
        const notNumeric = ( v ) => {
            return ( v && isNaN(parseFloat( v )) )
        }

        let company = row.crmCompany;
        const link = row.link;
        const type = row.type;

        if( !company ) company = {};

        if( !selected )
        {
            return {
                status: 'noCol',
                tooltip: null,
                oldValue: null,
            }
        }

        let originalValue = null;
        
        if( selected === 'name' )
        {
            originalValue = company.name;
        }
        else if (selected === 'is_active') {
            originalValue = company.is_active;
        }
        else if (selected === 'taxnumber') {
            originalValue = company.taxnumber;
        }
        else if (selected === 'responsible_person_first_name') {
            originalValue = company.account_manager_details?company.account_manager_details.first_name:null;
        }
        else if (selected === 'responsible_person_last_name') {
            originalValue = company.account_manager_details?company.account_manager_details.last_name:null;
        }
        else if (selected === 'parent_company') {
            originalValue = company.parent_company ? company.parent_company.name:null;
        }
        else if (selected === 'extranet_active') {
            originalValue = company.extranet_active;
        }
        else if (selected === 'is_customer') {
            originalValue = company.is_customer;
        }
        else if (selected === 'is_supplier') {
            originalValue = company.is_supplier;
        }
        else if (selected === 'phone') {
            originalValue = company.phone;
        }
        else if (selected === 'email') {
            originalValue = company.email;
        }
        else if (selected === 'website') {
            originalValue = company.website;
        }
        else if (selected === 'no_mailing') {
            originalValue = company.no_mailing;
        }
        else if (selected === 'default_payment_condition') {
            originalValue = company.default_payment_condition ? company.default_payment_condition.name:null;
        }
        else if (selected === 'default_delivery_term') {
            originalValue = company.default_delivery_term ? company.default_delivery_term.name:null;
        }
        else if (selected === 'default_delivery_method') {
            originalValue = company.default_delivery_method ? company.default_delivery_method.name:null;
        }
        else if (selected === 'default_billing_method') {
            originalValue = company.default_billing_method;
        }
        else if (selected === 'default_tax') {
            originalValue = company.vat ? company.vat.value:null;
        }
        else if (selected === 'default_tax_code') {
            originalValue = company.default_tax_code;
        }
        else if (selected === 'cost_center_code') {
            originalValue = company.cost_center_code;
        }
        else if (selected === 'e_invoice_ovt') {
            originalValue = company.e_invoice_ovt;
        }
        else if (selected === 'e_invoice_address') {
            originalValue = company.e_invoice_address;
        }
        else if (selected === 'e_invoice_operator') {
            originalValue = company.e_invoice_operator;
        }
        else if (selected === 'ext_efina_number') {
            originalValue = company.ext_efina_number;
        }
        /*else if (selected === 'generalAddressAdress') {
            originalValue = company.description;
        }
        else if (selected === 'generalAddressCity') {
            originalValue = company.description;
        }
        else if (selected === 'generalAddressPostalCode') {
            originalValue = company.description;
        }
        else if (selected === 'generalAddressCountry') {
            originalValue = company.description;
        }*/
        else if (selected === 'supplierAddressAdress') {
            originalValue = company.default_supplier_address?company.default_supplier_address.street:null;
        }
        else if (selected === 'supplierAddressCity') {
            originalValue = company.default_supplier_address?company.default_supplier_address.city:null;
        }
        else if (selected === 'supplierAddressPostalCode') {
            originalValue = company.default_supplier_address?company.default_supplier_address.postal_code:null;
        }
        else if (selected === 'supplierAddressCountry') {
            originalValue = company.default_supplier_address?company.default_supplier_address.country:null;
        }
        else if (selected === 'shippingAddressAdress') {
            originalValue = company.default_shipping_address?company.default_shipping_address.street:null;
        }
        else if (selected === 'shippingAddressCity') {
            originalValue = company.default_shipping_address?company.default_shipping_address.city:null;
        }
        else if (selected === 'shippingAddressPostalCode') {
            originalValue = company.default_shipping_address?company.default_shipping_address.postal_code:null;
        }
        else if (selected === 'shippingAddressCountry') {
            originalValue = company.default_shipping_address?company.default_shipping_address.country:null;
        }
        else if (selected === 'billingAddressAdress') {
            originalValue = company.default_billing_address?company.default_billing_address.street:null;
        }
        else if (selected === 'billingAddressCity') {
            originalValue = company.default_billing_address?company.default_billing_address.city:null;
        }
        else if (selected === 'billingAddressPostalCode') {
            originalValue = company.default_billing_address?company.default_billing_address.postal_code:null;
        }
        else if (selected === 'billingAddressCountry') {
            originalValue = company.default_billing_address?company.default_billing_address.country:null;
        }
        else if (selected === 'contact_name') {
            //console.log("key", key);
            //console.log("data", this.state.rows[key]);
            let rows = this.state.rows;
            let needUpdate = false;
            rows[key]["contact_name"] = updateValue;
            if (company.contacts) {
                if (rows[key]["contact_old_name"] && rows[key]["contact_old_last_name"] && !reupdate) {
                    rows[key]["crmCompany"]["contact"] = this.findContact(company.contacts, rows[key]["contact_old_name"], rows[key]["contact_old_last_name"]);
                    needUpdate = true;
                }
                else if (rows[key]["contact_name"] && rows[key]["contact_last_name"] && !reupdate) {
                    rows[key]["crmCompany"]["contact"] = this.findContact(company.contacts, rows[key]["contact_name"], rows[key]["contact_last_name"]);
                    needUpdate = true;
                }
                this.setState({ rows: rows, columnsNeedUpdate: needUpdate, });
            }
            
            originalValue = company.contact?company.contact.first_name:null;
        }
        else if (selected === 'contact_last_name') {
            let rows = this.state.rows;
            let needUpdate = false;
            rows[key]["contact_last_name"] = updateValue;
            //console.log("update contact", reupdate);
            if (company.contacts) {
                if (rows[key]["contact_old_name"] && rows[key]["contact_old_last_name"] && !reupdate) {
                    rows[key]["crmCompany"]["contact"] = this.findContact(company.contacts, rows[key]["contact_old_name"], rows[key]["contact_old_last_name"]);
                    needUpdate = true;
                }
                else if (rows[key]["contact_name"] && rows[key]["contact_last_name"] && !reupdate) {
                    rows[key]["crmCompany"]["contact"] = this.findContact(company.contacts, rows[key]["contact_name"], rows[key]["contact_last_name"]);
                    needUpdate = true;
                    
                }
                this.setState({ rows: rows, columnsNeedUpdate: needUpdate, });
            }
            originalValue = company.contact?company.contact.last_name:null;
        }
        else if (selected === 'contact_old_name') {
            //console.log("key", key);
            //console.log("data", this.state.rows[key]);
            let rows = this.state.rows;
            let needUpdate = false;
            rows[key]["contact_old_name"] = updateValue;
            if (company.contacts) {
                if (rows[key]["contact_old_name"] && rows[key]["contact_old_last_name"] && !reupdate) {
                    rows[key]["crmCompany"]["contact"] = this.findContact(company.contacts, rows[key]["contact_old_name"], rows[key]["contact_old_last_name"]);
                    needUpdate = true;
                }
                else if (rows[key]["contact_name"] && rows[key]["contact_last_name"] && !reupdate) {
                    rows[key]["crmCompany"]["contact"] = this.findContact(company.contacts, rows[key]["contact_name"], rows[key]["contact_last_name"]);
                    needUpdate = true;
                }
                this.setState({ rows: rows, columnsNeedUpdate: needUpdate, });
            }

            originalValue = company.contact ? company.contact.first_name : null;
        }
        else if (selected === 'contact_old_last_name') {
            let rows = this.state.rows;
            let needUpdate = false;
            rows[key]["contact_old_last_name"] = updateValue;
            //console.log("update contact", reupdate);
            if (company.contacts) {
                if (rows[key]["contact_old_name"] && rows[key]["contact_old_last_name"] && !reupdate) {
                    rows[key]["crmCompany"]["contact"] = this.findContact(company.contacts, rows[key]["contact_old_name"], rows[key]["contact_old_last_name"]);
                    needUpdate = true;
                }
                else if (rows[key]["contact_name"] && rows[key]["contact_last_name"] && !reupdate) {
                    rows[key]["crmCompany"]["contact"] = this.findContact(company.contacts, rows[key]["contact_name"], rows[key]["contact_last_name"]);
                    needUpdate = true;

                }
                this.setState({ rows: rows, columnsNeedUpdate: needUpdate, });
            }
            originalValue = company.contact ? company.contact.last_name : null;
        }
        else if (selected === 'contact_title') {
            originalValue = company.contact?company.contact.title:null;
        }
        else if (selected === 'contact_phone') {
            originalValue = company.contact?company.contact.phone1:null;
        }
        else if (selected === 'contact_phone2') {
            originalValue = company.contact?company.contact.phone2:null;
        }
        else if (selected === 'contact_email') {
            originalValue = company.contact?company.contact.email:null;
        }
        else if (selected === 'contact_no_mailing') {
            originalValue = company.contact ? company.contact.no_mailing : false;
        }

        






        // else if( selected === 'type' )
        // {
        //     originalValue = component.type_name;
        //     if( originalValue === 'other') originalValue = 'expense';
        //     let options = ['item', 'work', 'expense'];
        //     if( !options.includes( updateValue ) )
        //     {
        //         return {
        //             status: 'invalid',
        //             tooltip: <div><strong>{ tr('invalid_value') }</strong><br /> { tr('acceptable_values_are') }: { options.join(', ') }</div>,
        //             oldValue: originalValue,
        //         }
        //     }
        // }
        // else if( selected === 'status' )
        // {
        //     originalValue = component.status_name
        //     let options = getComponentStatusNames();
        //     if( !options.includes( updateValue ) )
        //     {
        //         return {
        //             status: 'invalid',
        //             tooltip: <div><strong>{ tr('invalid_value') }</strong><br /> { tr('acceptable_values_are') }: { options.join(', ') }</div>,
        //             oldValue: originalValue,
        //         }
        //     }
        // }
        // else if( selected === 'code' )
        // {
        //     originalValue = component.code
        //     const tmp = this.codeIsInvalid( updateValue );
        //     if( tmp )
        //         return {
        //             status: 'invalid',
        //             tooltip: <div><strong>{ tr('invalid_value') }</strong><br />{ tmp }</div>,
        //             oldValue: originalValue,
        //         }
        // }
        // else if( selected === 'price' )
        // {
        //     if( notNumeric( updateValue ) )
        //     {
        //         return {
        //             status: 'invalid',
        //             tooltip: <div><strong>{ tr('value_not_numeric') }</strong></div>,
        //         }
        //     }
        //     originalValue = component.price
        // }
        // else if( selected === 'price_sell' )
        // {
        //     if( notNumeric( updateValue ) )
        //     {
        //         return {
        //             status: 'invalid',
        //             tooltip: <div><strong>{ tr('value_not_numeric') }</strong></div>,
        //         }
        //     }
        //     originalValue = component.price_sell
        // }
        // else if( selected === 'profit_percent' )
        // {
        //     if( notNumeric( updateValue ) )
        //     {
        //         return {
        //             status: 'invalid',
        //             tooltip: <div><strong>{ tr('value_not_numeric') }</strong></div>,
        //         }
        //     }
        //     if( parseFloat( updateValue ) >= 100 )
        //     {
        //         return {
        //             status: 'invalid',
        //             tooltip: <div><strong>{ tr('gross_profit_cant_be_100') }</strong></div>,
        //         }
        //     }


        //     originalValue = component.profit_percent
        // }
        // else if( selected === 'description' )
        // {
        //     originalValue = component.description
        // }
        // else if( selected === 'conversion_factor' )
        // {
        //     if( type !== 'item' )
        //     {
        //         return {
        //             status: 'noThisType',
        //             tooltip: tr('storage_component_no_conversion_factor'),
        //             oldValue: null,
        //         }
        //     }
        //     if( notNumeric( updateValue ) )
        //     {
        //         return {
        //             status: 'invalid',
        //             tooltip: <div><strong>{ tr('value_not_numeric') }</strong></div>,
        //         }
        //     }

        //     originalValue = component.conversion_factor
        // }
        // else if( selected === 'conversion_unit' )
        // {
        //     if( type !== 'item' )
        //     {
        //         return {
        //             status: 'noThisType',
        //             tooltip: tr('storage_component_no_conversion_unit'),
        //             oldValue: null,
        //         }
        //     }

        //     originalValue = component.conversion_unit
        // }
        // else if( selected === 'alt_name' )
        // {
        //     if( component.altnames )
        //     {
        //         const tmp = component.altnames.find( f => f.name === updateValue )
        //         if( tmp ) originalValue = updateValue;
        //     }
        // }

        // else if( selected.startsWith('identifier') )
        // {
        //     const id = parseInt( selected.split('_')[ 1 ], 10 )
        //     if( component.identifiers )
        //     {
        //         const tmp = component.identifiers.find( i => i.id === id );
        //         if( tmp ) originalValue = tmp.pivot.value;
        //     }
        // }
        // else if( selected.startsWith('calculatable') )
        // {
        //     if( type !== 'item' )
        //     {
        //         return {
        //             status: 'noThisType',
        //             tooltip: tr('storage_component_no_meters'),
        //             oldValue: null,
        //         }
        //     }

        //     if( notNumeric( updateValue ) )
        //     {
        //         return {
        //             status: 'invalid',
        //             tooltip: <div><strong>{ tr('value_not_numeric') }</strong></div>,
        //         }
        //     }

        //     const id = parseInt( selected.split('_')[ 1 ], 10 )
        //     if( component.calculatables )
        //     {
        //         const tmp = component.calculatables.find( i => i.id === id );
        //         if( tmp ) originalValue = tmp.pivot.value;
        //     }
        // }

        // else if( selected.startsWith('property') )
        // {
        //     const id = parseInt( selected.split('_')[ 1 ], 10 )
        //     if( component.properties )
        //     {
        //         const tmp = component.properties.find( i => i.id === id );
        //         if( tmp ) originalValue = tmp.pivot.value;
        //     }
        // }

        if( link === 'noLink')
        {
            return {
                status: 'noComponent',
                tooltip: tr('not_linked'),
                oldValue: null,
            }
        }

        if (!updateValue && typeof updateValue !== 'number' && originalValue )
        {
            return {
                status: 'toNull',
                tooltip: tr('old_value_will_be_deleted'),
                oldValue: originalValue,
            }
        }
        if( originalValue == updateValue )
        {
            return {
                status: 'noChange',
                tooltip: tr('no_change'),
                oldValue: null,
            }
        }

        if( link === 'newCompany')
        {
            return {
                status: 'newCompany',
                tooltip: tr('new_value'),
                oldValue: null,
            }
        }
        return {
            status: 'change',
            tooltip: tr('value_update'),
            oldValue: originalValue,
        }
    }

    

    getColumns()
    {
        let cols = [];

        cols.push({
            Header: '#',
            id: 'nmb',
            width: 50,
            filterable: false,
            sortable: false,
            accessor: 'id',
            className: "overflowableOnHover",
            Cell: props => {
                let dom = <div className="rowNumber">{ props.value }.</div>;
                if( props.value == 2 )
                return <ApTooltip block text={ tr('excel_first_row_title') } position="topleft">
                    { dom }
                </ApTooltip>
                return dom;
            }
        });

        cols.push({
            Header: tr('link'),
            id: 'link',
            width: 50,
            className: "overflowableOnHover linkTd center",
            filterable: false,
            Cell: props => {

                const row = props.original;

                let color = "#000"
                let tooltip = null;
                let icon = null;
                let onClick = ()  => { this.setState({ linkModal: {
                    show: true,
                    rowId: props.original.id,
                    component: row.component || null,
                    selected: row.link

                }}); }

                if( row.link === 'old' )
                {
                    tooltip = tr('crm_company_link_exists');
                    icon = 'check-circle';
                    color = '#0099CC';
                    onClick = null;
                }
                if( row.link === 'noLink' )
                {
                    tooltip = tr('crm_company_no_link');
                    icon = 'times-circle';
                    color = '#CC0000';
                }
                else if( row.link === 'newLink' )
                {
                    tooltip = tr('crm_company_new_link');
                    icon = 'arrow-alt-circle-left';
                    color = '#388E3C';
                }
                else if( row.link === 'newComponent' )
                {
                    tooltip = tr('new_storage_component');
                    icon = 'plus-circle';
                    color = '#388E3C';
                }

                let tooltipDom = [];
                if( tooltip ) tooltipDom.push(<div key="tt">{ tooltip }</div>);
                if( row.component ) tooltipDom.push(<ComponentTooltip key="c" component={ row.component } />);

                return <div onClick={ onClick } className={ onClick ? 'pointer' : ''}>
                    <ApTooltip block text={ tooltipDom } position="topleft">
                        <SvgIcon icon={ icon } type="solid" fill={ color } />
                    </ApTooltip>
                </div>
            },

        });

        cols.push({
            id: 'name',
            Header: tr('name'),
            // showInitially: true,
            // customizable: true,
            accessor: 'name',
            customFilter: {
                type: "text",
                placeholder: tr('enter_name'),
            },
        });


        this.state.otherCols.forEach(( selected, index ) => {
            const header = row => {
                const actions = this.getHeaderActions( index );

                let title = tr('not_used');
                if( selected )
                    title = this.state.headerOptions[ selected ].label;

                return <ApDropdown
                    fullWidth
                    button={ <div className="input-menu"><div className="headerLabel">{ title }</div><div className="input-menu-button"><SvgIcon icon="caret-down" type="solid" /></div></div> }
                    actions={ actions }
                />
            }


            cols.push({
                id: `othercol_${ index }`,
                //Header: `Sarake ${ index + 1 }`,
                Header: header,
                headerClassName: "overflowable",

                //showInitially: true,
                //customizable: true,
                sortable: false,
                accessor: row => {
                    return row.other[index];
                },
                className: 'otherCell overflowableOnHover',
                Cell: props => {

                    if( !props.original.otherDetails ) return null

                    const details = props.original.otherDetails[ index ];
                    let tooltip = [];
                    if( details.tooltip )
                        tooltip.push( <div key="tt">{ details.tooltip }</div> )
                    if( details.oldValue )
                        tooltip.push( <div key="ov">{ tr('old_value') }: <strong>{ details.oldValue }</strong></div> )
                    tooltip = ( tooltip.length ) ? <div>{ tooltip }</div> : null;

                    let className= [ 'other', details.status ];

                    return <div className={ className.join(' ')}>
                        <ApTooltip block text={ tooltip } position="top">
                            {!(props.value === null || props.value === "" ) ? props.value : <span>&nbsp;</span> }
                        </ApTooltip>
                    </div>
                },
            });
        })
        return cols;

    }

    setOtherColSelected( index, value )
    {
        let otherCols = this.state.otherCols.slice(0);
        otherCols = otherCols.map(( col, i ) => {
            if( i === index )
                return value;
            return col;
        });
        this.setState({ otherCols: otherCols }, () => {
            this.updateRows();
        });
    }

    getHeaderActions( index )
    {
        let options = [{
            label: tr('not_used'),
            action: ( id, closeFunc ) => {
                this.setOtherColSelected( index, null );
                closeFunc();
            },
        }];

        let headerOptions = this.state.headerOptions;

        for ( let key in headerOptions )
        {
            let excludes = [];
            if( !headerOptions[ key ].multiple )
                excludes = [ key ];

            const otherExclude = headerOptions[ key ].exclude;
            if( otherExclude )
                excludes = [ ...excludes, ...otherExclude ];

            let skip = excludes.some( e => {
                return this.state.otherCols.includes( e );
            });
            if( skip ) continue;

            options.push( {
                label: headerOptions[ key ].label,
                icon:  headerOptions[ key ].icon,
                action: ( id, closeFunc ) => {
                    this.setOtherColSelected( index, key );
                    closeFunc();
                },
            });
        }
        return options;
    }

    noLinkToNewById( ids )
    {
        let rows = this.state.rows.slice(0);
        rows = rows.map( r => {
            if( ids.includes( r.id ) && r.link === 'noLink' )
            {
                r.link = 'newCompany';
                r.component = null;
            }
            return r;
        })
        this.setState({ rows: rows }, () => {
            this.updateRows();
        });
    }

    deleteSelectedRows(ids) {
        let rows = this.state.rows.slice(0);
        rows = rows.filter(r => {
            if (ids.includes(r.id)) {
                return false;
            }
            return true;
        })

        this.setState({ rows: rows }, () => {
            this.updateRows();
        });
    }

    renderFileSelect()
    {
        /*
                <ApAddon custom width="250px">
                    <ApButton style={{ margin: "5px" }} size="small" color="blue" onClick={ this.getTemplate }>
                        <SvgIcon icon="download" type="solid" />
                        Lataa Excel-pohja
                    </ApButton>
                </ApAddon>
                */


        return <div className="fileSelectContainer">
            <ApInputStack gap="0">
                <ApAddon width="300px" noRightBorder>
                    { tr('select_excel_file') }
                </ApAddon>
                <ApInput
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    id="selected_file"
                    name="selected_file"
                    label={ `${tr('select_excel_file')}...` }
                    value={ this.state.selected_file }
                    onChange={ ( file ) => { this.setState({ selected_file: file }) } }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
                <ApAddon noLeftBorder width="150px">
                    <ApTooltip block text={ tr('read_data_excel') } position="bottom">
                        <div className="apSimpleButton" onClick={ this.import }>
                            { tr('read_file') }
                        </div>
                    </ApTooltip>
                </ApAddon>
            </ApInputStack>
        </div>
    }

    renderTable()
    {
        let rows = this.state.rows;

        let filters = this.state.filtersSelected.map( f => f.value )
        let filterIds = [];

        if( filters.includes( 'change' ) )
            filterIds = [ ...filterIds,  ...this.state.rowChangeIds ];
        if( filters.includes( 'invalid' ) )
            filterIds = [ ...filterIds,  ...this.state.rowsInvalidIds ];
        if( filters.includes( 'noComponent' ) )
            filterIds = [ ...filterIds,  ...this.state.rowsNoComponenIds ];
        if( filters.includes( 'new' ) )
            filterIds = [ ...filterIds,  ...this.state.rowsNewIds ];

        if( filters.length )
            rows = this.state.rows.filter( r => filterIds.includes( r.id ) );

        return <div className="componentsTable">
            <ApReactTable
                columns={ this.getColumns() }
                data={ rows }
                loading={ this.state.loading }

                defaultPageSize={ 10 }
                noDataText={ tr('no_rows') }

                multiselect={[
                    {
                        icon: "plus-square",
                        label: tr('to_new_customer'),
                        action: ( indexes ) => { this.noLinkToNewById( indexes.map( i => rows[ i ].id ) ) },
                        unselectAfter: true,
                    },
                    {
                        icon: "trash",
                        label: tr('delete_rows'),
                        action: (indexes) => { this.deleteSelectedRows(indexes.map(i => rows[i].id)) },
                        unselectAfter: true,
                    },
                ]}
            />
        </div>

    }

    renderFooterInfo()
    {
        const counts = this.state.counts;
        const drawIcon = ( count, color, icon, clickFilter, title ) => {
            let onClick = null;

            let className = ['stat'];
            if( counts[ count ] ) className.push( color );
            else className.push( 'empty' );

            if( clickFilter )
            {
                className.push('pointer');
                onClick = () => {
                    if( clickFilter === 'all')
                        this.setState({ filtersSelected: [] });
                    else
                    {
                        const filter = filters.find( f => f.value === clickFilter)
                        this.setState({ filtersSelected: [ filter ] });
                    }
                }
            }

            return <ApTooltip text={ title } position="topleft">
                <div className={ className.join( ' ' ) } onClick={ onClick }>
                    <SvgIcon icon={ icon } type="solid" />
                    { counts[ count ] }
                </div>
            </ApTooltip>
        };

        return <table>
            <tbody>
            <tr>
                <td>{ drawIcon( 'rows',            '',        'list',              'all',         tr('total_rows') ) }</td>
                <td>{ drawIcon( 'rowsNoComponent', 'error',   'square',            'noComponent', tr('rows_without_linking') ) }</td>
                <td>{ drawIcon( 'rowsChange',      'info',    'caret-square-left', 'change',      tr('rows_that_will_change') ) }</td>
                <td>{ drawIcon( 'rowsNew',         'success', 'plus-square',       'new',         tr('new_rows') ) }</td>
            </tr><tr>
                <td>{ drawIcon( 'unusedCols',      'warning', 'columns',               null,      tr('unspecified_columns') ) }</td>
                <td>{ drawIcon( 'colInvalid',      'error',   'exclamation-circle',    'invalid', tr('incorrect_values') ) }</td>
                <td>{ drawIcon( 'colChange',       'info',    'arrow-alt-circle-left', 'change',  tr('values_that_will_change') ) }</td>
            </tr>
            </tbody>
        </table>
    }

    rowsDublicated()
    {
        let ids = [];

        let dublicateIds = [];

        this.state.rows.forEach( row => {
            if( !row ) return null;
            if( !row.component ) return null;
            if( !row.component.id ) return null;

            if( ids.includes(row.component.id) )
                dublicateIds.push( row.component.id );

            ids.push( row.component.id );
        });

        let dublicateRows = [];

        this.state.rows.forEach( row => {
            if( !row ) return null;
            if( !row.component ) return null;
            if( !row.component.id ) return null;

            if( dublicateIds.includes(row.component.id) )
                dublicateRows.push( row.id );
        });
        return dublicateRows;

    }

    formInvalid()
    {
        const counts = this.state.counts;
        if( !counts.rows ) return tr('no_rows');
        if( counts.colInvalid ) return tr('incorrect_values');
        if( counts.rowsNoComponent ) return tr('all_rows_not_linked');
        if( !counts.rowsChange && !counts.rowsNew ) return tr('no_changes');

        return false;
    }

    renderFooter()
    {
        if( !this.state.rows.length ) return null;

        const formInvalidMessage = this.formInvalid();

        return <div className="apBox massImportCustomFooter">
            { this.renderFooterInfo() }
            <div className="apButtonGroup">
                <ApTooltip text={ formInvalidMessage } position="topright">
                    <ApButton className="save" color="blue" onClick={ this.save } disabled={ this.state.loading || Boolean( formInvalidMessage ) } loading={ this.state.loading }>
                        <SvgIcon icon="save" type="solid" />
                        { tr('save') }
                    </ApButton>
                </ApTooltip>
            </div>
        </div>
    }

    renderFilters()
    {
        if( !this.state.rows.length ) return null;

        return <ApSelect
            label={ tr('crop_rows') }
            loading={ this.state.loading }
            value={ this.state.filtersSelected }
            onChange={ ( values ) =>  this.setState({ filtersSelected: values }) }
            objKeyId="value"
            multiselect
            options={ filters }
            optionRenderer="label"
            objKeySearchable="label"
            valueRenderer="label"
        />
    }

    linkModalSubmit()
    {
        const data = this.state.linkModal;
        let rows = this.state.rows.slice(0);
        rows = rows.map( r => {

            if( r.id === data.rowId )
            {
                r.link = data.selected;
                r.crmCompany = null;
                if( r.link === 'newLink')
                    r.crmCompany = data.crmCompany;
            }
            return r;
        });

        this.setState({
            linkModal: {},
            rows: rows,
        }, () => {
            this.updateRows();
        });
    }

    linkSelectCompany( company = null )
    {
        let linkModal = { ...this.state.linkModal };
        linkModal.crmCompany = company;
        this.setState({ linkModal: linkModal });
    }

    linkSelectType( option )
    {
        if( this.state.linkModal.selected === option ) return null;
        let linkModal = { ...this.state.linkModal };
        linkModal.selected = option;
        this.setState({ linkModal: linkModal });
    }

    renderLinkModal()
    {
        const options = [{
            select: 'noLink',
            content: <div className="optionText noLink">
                {tr('crm_company_no_link') }
            </div>,
        },
            {
            select: 'newComponent',
            content: <div className="optionText newUserContainer">
                {tr('to_new_customer') }
            </div>,
        },
        {
            select: 'newLink',
            content: <div className="optionText selectContainer">
                <ApSelect
                    label={tr('connect_to_customer') }
                    loading={ this.state.loading }
                    value={this.state.linkModal.crmCompany }
                    optionRenderer="crmCompany"
                    onChange={ this.linkSelectCompany }
                    clearable
                    disabled={ this.state.linkModal.selected !== 'newLink' }

                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="crm/companies/find"
                    apiData={{
                        options: {
                            massListData: true,
                        }
                        
                        
                    }}
                />
            </div>
        }];

        const optionDoms = options.map( o => {
            let icon = 'circle';
            let className = ['option']
            if( o.select === this.state.linkModal.selected )
            {
                className.push( 'selected' );
                icon = 'dot-circle';
            }
            return <div className={ className.join( ' ' ) } onClick={ () => { this.linkSelectType( o.select ) }}>
                <div className="iconContainer">
                    <SvgIcon icon={ icon } />
                </div>
                { o.content }
            </div>
        })

        return <ApModal
            className="narrow linkModal"
            show={ Boolean( this.state.linkModal.show ) }
            handleClose={ () => this.setState({ linkModal: {} }) }
            closeFromBg
            header={
                <div className="padding-small">
                    <h3>{ tr('define_component_for_row') }</h3>
                </div>
            }
            body={
                <div className="padding">
                    { optionDoms }
                </div>
            }
            footer={
                <div className="padding-small text-right">
                    <ApButton
                        color="white"
                        onClick={ this.linkModalSubmit }
                        disabled={ !this.state.linkModal.selected || ( this.state.linkModal.selected === 'newLink' && !this.state.linkModal.crmCompany ) }
                    >
                        <SvgIcon icon="check" type="solid" />
                        OK
                    </ApButton>
                </div>
            }
            />
    }

    renderTooManyRowsInfo()
    {
        let className = ['apInfo', 'small' ];
        let icon = 'info-circle';

        if( this.state.tooManyRows )
        {
            className.push( 'warning' );
            className.push( 'orange' );
            icon = 'exclamation-triangle';
        }

        return <div className={ className.join(' ') }>
            <SvgIcon icon={ icon } type="solid" />
                { tr('mass_import_too_many_rows_info') }
        </div>
    }

    render()
    {
        let fileSelect    = this.renderFileSelect();
        let table         = this.renderTable();

        return <div id="storageMassImport">
            <ApFormPage
                className="componentsListForm"
                customFooter={ () => this.renderFooter() }
                unsaved={ false }
                onSave={ () => {} }
            >
                <div className="padding">
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        {tr('mass_import_info_customer') }
                    </div>

                    { this.renderTooManyRowsInfo() }

                    { fileSelect }
                    { this.renderFilters() }
                    { table }
                </div>
            </ApFormPage>
            { this.renderLinkModal() }
        </div>
    }
}

export default MassImport;
