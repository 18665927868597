import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import ApModalWizard from 'common/ApModalWizard/ApModalWizard.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { tr } from 'services/Helpers/Helpers.js';

class CreateStorage extends React.Component
{
    
    constructor(props)
    {
        super(props);
        this.state = {
            storageType: 'project',
            storageTypes: [],
            receiverTitle: null,
            receiverId: null,
        }

        this.steps = [{
            name: tr('verification_of_data'),
            render: this.renderStepInformation.bind( this ),
            invalid:  () => {
                return ( this.state.storageType == 'location' && !this.state.receiverTitle && !this.state.receiverId )
            },
        },{
            name: tr('confirm'),
            render: this.renderStepConfirmation.bind( this ),
        }];

        autoBind(this);
    }

    componentDidMount() {
        const types = [{
            name: 'project',
            value: tr('project_storage')
        }, {
            name: 'location',
            value: tr('storage')
        }];

        this.setState({ storageTypes: types });
    }

    resetState()
    {
        this.resetReceiver();
        this.setState({ storageType: 'project' });

        const data = this.props.data;
        if( !data ) return null;
    }

    resetReceiver() {
        this.setState({
            receiverTitle: null,
            receiverId: null
        });
    }

    save()
    {        
        let data = {};

        data.project_id = this.props.project_id;
        data.id = this.state.receiverId;
        if (this.state.storageType != 'project') { data.type = 'location'; }
        
        return api({
            method: 'post',
            url: `project/management/doProjectStorage`,
            data: data,
        }).then( ( response ) => {
            if(response==1) {
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <div>{ tr('save_successful') }</div>,
                });
            } else if (response == 2) { //Jos projektivarasto jo olemassa
                window.emitter.emit('popper', {
                    type: 'warning',
                    content: <div>{ tr('project_storage_already_exists_id') }</div>,
                });
            } else {
                console.warn(`Projektivaraston luonnin koodi: [${response}]`);
                window.emitter.emit('popper', {
                    type: 'danger',
                    content: <div>{ tr('save_error') }</div>,
                });
            }
            this.props.onSave();
        }).catch((error) => {
            console.warn(`Projektivaraston luonti epäonnistui: [${error}]`);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <div>{ tr('save_error') }</div>,
            });
        });
    }

    setReceiverStorage( selected )
    {
        if (selected) { this.setState({ receiverTitle: selected.name, receiverId: selected.id }) } 
        else {this.resetReceiver()}
    }

    handleTypeChange(target) {
        this.resetReceiver();
        this.setState( {storageType: target});
    }

    renderStepInformation()
    {
        const regStorageSelection =  <ApSelect
            label={ `${tr('storage_to_be_used')} *` }
            value={ this.state.receiverTitle }
            optionRenderer="storage_location_address"
            onChange={ this.setReceiverStorage }
            objKeyId="id"
            clearable
            apiUrl="storage/locations/find"
            apiData={{ 
                canReceive: true,
                projectStorage: false,
                mainLevel: true //Ei salli alivarastoja
            }}
        />;

        const view = <div className="stepSupplier">
            <div className="supplier-select">
            <ApInput
                type="select"
                id="storageType"
                name="type"
                value={ this.state.storageType ? this.state.storageType : null}
                options={ this.state.storageTypes.map( t => { return { value: t.name, label: t.value } }) }
                onChange={ (e) => this.handleTypeChange(e.target.value) }
                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            </div>
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { tr('select_storage_type') }<br/><br/>
                { tr('project_storage_type_info') }<br/>
                { tr('normal_storage_type_info') }
            </div>
            {this.state.storageType == 'location' ? regStorageSelection : ''}
        </div>

        return view;
    }

    renderStepConfirmation()
    {
        let title, description;
        if (this.state.storageType == 'location') {
            title = `${tr('storage')}: ${this.state.receiverId} - ${this.state.receiverTitle}`;
            description = `${tr('create_normal_storage_desc1')}
            ${tr('create_normal_storage_desc2')}`;
        } else {
            title = tr('project_storage');
            description = tr('create_project_storage_desc');
        }
        return <div>
            <h4>{ tr('last_check') }</h4>
            <h5 style={{ marginTop: '1.4em' }}>{title}</h5>
            <p>{description}</p>
        </div>
    }

    render()
    {
        return <div id="newPo">
            <ApModalWizard
                header={ tr('storage_creation') }
                onOpen={ this.resetState }
                show={ this.props.show }
                steps={ this.steps }
                onClose={ this.props.onClose }
                onSave={ this.save }
            />
        </div>
    }
    
}

export default CreateStorage;