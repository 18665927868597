/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import { keyExists } from 'services/Helpers/Helpers.js';

// import SvgIcon from 'common/SvgIcon/SvgIcon.js';
// import ApButton from 'common/ApButton/ApButton.js';
// import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
// import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApRangeSlider from 'common/ApRangeSlider/ApRangeSlider.js';

import { formatMoney, tr } from 'services/Helpers/Helpers.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import moment from 'moment'

// import { Collapse } from 'react-bootstrap';


export default class AllowanceEntry extends React.Component {

	constructor(props) 
    {
        super(props);
        this.state = {
            selectedProject: "",
        };
        autoBind(this);
    }

    // Imitate input event by wrapping value to "e.target.value" object
    eventify( value )
    {
        return { target: { value: value } };
    }

	getAllowanceOptions()
	{
		let options = [];
		const allowances = keyExists( this.props, "allowances", true, [] );
		allowances.forEach( a => {
			options.push({
				value: a.id,
				label: a.name
			});
		});
		return options;
	}

    allowanceChange( e )
    {
        this.props.onChange( e, "detail.allowance_id" );

        const allowance = keyExists( this.props, "allowances", true, [] ).find( a => a.id == e.target.value );
        if( allowance )
            this.props.onChange( this.eventify( allowance.name ), "description" );
    }

    timeChange( values )
    {
        if( values.length != 2)
            return false;

        const start = this.mins2Time( values[0], ":", true );
        const end = this.mins2Time( values[1], ":", true );

        this.props.onChange( this.eventify( start ), "detail.start_time" );
        this.props.onChange( this.eventify( end ), "detail.end_time" );
    }

    getCompensation() 
    {
        const id = keyExists( this.props, "data.detail.allowance_id", true );
        const allowance = keyExists( this.props, "allowances", true, [] ).find( a => a.id == id );
        return allowance ? allowance.compensation : 0;
    }

    
    // Converts total minutes to time (HH:MM or with custom delimiter or to DB format HH:MM:SS)
    mins2Time( min, customDelimiter = ".", dbFormat = false )
    {
        const total = parseInt( min, 10 );
        if( !isNaN( total ) )
        {
            const hours = Math.floor( total / 60 );
            const minutes = total % 60;

            return ( hours < 10 ? "0" : "" ) + hours + ( customDelimiter ? customDelimiter : ":" ) + ( minutes < 10 ? "0" : "" ) + minutes + ( dbFormat === true ? ":00" : "");
        }
        return false;
    }

    // Converts time (HH:MM or HH.MM) to total minutes
    time2mins( time )
    {   
        if( typeof( time ) == "string" )
        {
            let parts = time.split( ( time.indexOf(":") > -1 ? ":" : "." ) );
            if( parts.length >= 2 )
            {
                const hours = parseInt( parts[0], 10 );
                const minutes = parseInt( parts[1], 10 );
                if( !isNaN( hours ) && !isNaN( minutes ) )
                    return ( hours * 60 ) + minutes;
            }
        }
        return false;
    }
    onAddProject(date, e) {
        //console.log("date", date);
        //console.log("e", e);
        if (e) {
            let projectName = "";
            for (let ancestor of e.ancestors) {
                projectName += " / "+ancestor.name
            }
            this.setState({
                selectedProjectText: e.project_code + ", " + e.name + projectName,
                selectedProject: e
            });
            this.props.data.project_id = e.id;
            this.props.data.project = e;
        } else {
            this.setState({selectedProject: null, selectedProjectText: null})
        }
        
    }

    totalTime( start, end )
    {
        const total = this.time2mins( end ) - this.time2mins( start );
        const h = Math.floor( total / 60 );
        const min = total % 60;
        return h + " h " + ( min > 0 ? min + " min" : "" );
    }

    render()
    {
        const start = keyExists( this.props, "data.detail.start_time", true, '00:00:00' );
        const end = keyExists( this.props, "data.detail.end_time", true, '24:00:00' );
        //console.log(moment(this.props.data.date).isoWeekday());
        let weekDay = moment(this.props.data.date).isoWeekday();

        let publicHoliday = false;
        if ( this.props.data.date == '2022-01-06' || this.props.data.date == '2022-04-15' || this.props.data.date == '2022-04-18' || this.props.data.date == '2022-05-26' || this.props.data.date == '2022-12-06' || this.props.data.date == '2022-12-26') { //TODO: kunnon toteutus, pieni viritys
            publicHoliday = true;
        }
            
        let showProjectSelect = (weekDay === 6 || weekDay === 7 || publicHoliday || this.props.dayNotHaveEntry);
        return (
            <div>

                <ApInputStack gap="0">
                    <ApInput 
                        type="select" 
                        id="allowance_id" 
                        name="allowance_id" 
                        value={ keyExists( this.props, "data.detail.allowance_id", true, '' ) }
                        options={ this.getAllowanceOptions() }
                        onChange={ this.allowanceChange }
                    />
                    <ApAddon noLeftBorder width="100">
                        { formatMoney( this.getCompensation() ) } €
                    </ApAddon>
                </ApInputStack>
                
                {showProjectSelect && <div>
                    <h5 style={{color: 'var(--clr-error-light'}}>{tr("Without_hourly_recording_the_project_is_mandatory")}</h5>
                    <ApSelect
                    label={`${tr('add_project')}...`}
                        value={(this.props.data.project_id == null && this.state.selectedProjectText != "") ?"":
                            this.state.selectedProjectText}
                    optionRenderer="project_assignment"
                    
                    
                    onChange={(e) => this.onAddProject(this.props.data.date, e)}
                    objKeyId="id"
                    clearable
                    
                    request_time={true}
                    apiUrl="timetracking/projects"
                    apiData={{
                        date: this.props.data.date,
                        exlude: [],
                    }}
                    />
                </div>}

                <div className="apFormGroup allowanceTime">
                    <h6>{ tr('duration_of_trip') } <span className="total">- { this.totalTime( start, end ) }</span></h6>
                    <ApRangeSlider
                        min={ 0 }
                        max={ 1440 } // Minutes in day
                        step={ 15 }
                        marks={{
                            0: "00.00",
                            360: "06.00",
                            720: "12.00",
                            1080: "18.00",
                            1440: "24.00",
                        }}
                        defaultValue={[ 0, 1440 ]} 
                        value={[
                            start ? this.time2mins( start ) : 0, 
                            end ? this.time2mins( end ) : 1440
                        ]}
                        onChange={ this.timeChange }
                        tooltip
                        tooltipRenderer={ (value) => <span>{ this.mins2Time( value ) }</span> }
                    />
                </div>
                
                

            </div>
        );
    }

}
