import React from 'react';

import { currentLang, availableLanguages } from 'services/Helpers/Helpers.js';
import './LangMenu.css';

export default class LangMenu extends React.Component {

	constructor( props )
	{
		super( props );
		this.state = {
			lang: null
		}
		this.changeLanguage = this.changeLanguage.bind(this);
		this.getLanguage = this.getLanguage.bind(this);
	}

	componentDidMount() 
	{
		this.getLanguage();
	}

  deleteLangCookies() 
	{
		document.cookie.split(";").forEach(cookie => {
			const eqPos = cookie.indexOf("=");
			const name = (eqPos > -1 ? cookie.substr(0, eqPos) : cookie).replace(/ /g, '');
			if (name === 'language')
			{
				document.cookie = `language=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
				document.cookie = `language=; path=/; expires=Thu 01 Jan 1970 00:00:00 GMT;`;
				document.cookie = `language=; domain=${document.domain.split('.').reverse().splice(0,2).reverse().join('.')}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
				document.cookie = `language=; domain=${document.domain.split('.').reverse().splice(0,2).reverse().join('.')}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
			}
		})
	}

	changeLanguage( lang )
	{
		this.props.toggleModal('language');
		if (lang !== this.state.lang)
		{
			this.deleteLangCookies();
			document.documentElement.lang = lang;
			const cookieDate = new Date;
			cookieDate.setFullYear(cookieDate.getFullYear() + 5);
			document.cookie = `language=${lang}; domain=${document.domain.split('.').reverse().splice(0,2).reverse().join('.')}; path=/; expires=${cookieDate.toUTCString()};`;
			window.location.reload();
		}
	}

	getLanguage() 
	{
		const lang = currentLang();
		this.setState({ lang });
	}

	render()
	{
		const language = availableLanguages().find(item => item.code === this.state.lang);
		const flagUrl = language ? language.flag : null;

		return (
			<div className="block languages">
				<div className="button" onClick={() => { this.props.toggleModal("language"); } }>
						<div className="flag" style={{ backgroundImage: `url(${flagUrl}` }}></div>
				</div>
				<div className={"topModal" + (this.props.open ? " open" : "" ) }>
					{
						availableLanguages().map((lang, index) => {
							return( 
								<button key={index} className={( lang.code === this.state.lang ? "selected" : "")} onClick={ () => { this.changeLanguage( lang.code ) }}>
									<div className="flag" style={{ backgroundImage: "url('" + lang.flag + "')"}}></div>
									<span className="text">{ lang.code.toUpperCase() }</span>
								</button> 
							);
						})
					}
				</div>
			</div>
		);
	}
};