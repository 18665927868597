/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Collapse } from 'react-bootstrap';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';

import { dateInputToSql, sqlToDateInput, tr } from 'services/Helpers/Helpers.js';
import ApValidate from 'services/ApValidate/ApValidate.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect from 'common/ApSelect/ApSelect.js';


class RuleEdit extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            data: [],
            unsavedChanges: false,
        };
        this.validator = new ApValidate(this, {
            "data.name": {
                filter: 'required',
                state: 'error',
                textAll: tr('name')
            },
            "data.unit_price": {
                filter: 'required',
                state: 'error',
                textAll: 'unit_price'
            },
            
            
        });
        autoBind(this); 
    }

    fetchData()
    {
        const id = this.props.id;

        // Id is not number so adding new rule
        if( typeof( id ) != "number" ) 
        {
            this.setState({ 
                data: {
                    active:                 true,
                    start_date:             moment().format('YYYY-MM-DD'),
                    end_date:               null,
                    name:                   '',
                    description:            '',
                    users:                  [],
                    projects:               [],
                    //salary_extra:           null,
                    //salary_extra_per_unit:  null,
                    //salary_extra_percent:   null,
                    unit_price:             null,
                    amount_type:            'fixed',
                    salary_category:        null,
                    limit_available:        false,
                    limit:                  null,
                    limit_type:             null,
                    sub_projects:           false,
                    no_travel_hours:        false,
                },
                unsavedChanges: false,
            });
        }
        // Id isset so we are editing old rule
        else 
        {
            this.setState({ loading: true });
            api({
                method: 'get',
                url: 'timetracking/salary_extras/rule/' + id,
            }).then(( response ) => {

                //console.log('fetchData', response );

                // Add limit availablility based on limit/limit_type values 
                response.limit_available = ( response.limit && response.limit_type );

                this.setState({
                    loading: false,
                    data: response,
                    unsavedChanges: false,
                });
                
            }).catch( ( error ) => {
                console.error(error);
                this.setState({ loading: false });
            });
        }
    }

    handleChange( e )
    {
        let data = { ...this.state.data };
     
        switch( e.target.name )
        {   
            case "start_date":
            case "end_date":
                data[ e.target.name ] = dateInputToSql( e.target.value );
                break;

            default: 
                data[ e.target.name ] = e.target.value;
                break;
        }

        this.setState({ 
            data: data, 
            unsavedChanges: true,
        });
    }

    handleSwitchChange( name, checked )
    {
        let data = { ...this.state.data }; 
        data[ name ] = checked;
        this.setState({ 
            data: data, 
            unsavedChanges: true,
        });
    }

    handleListChange( values, name ) 
    {
        let data = { ...this.state.data }; 
        data[ name ] = values;
        this.setState({ 
            data: data, 
            unsavedChanges: true,
        });
    }

    save()
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/salary_extras/rule/save',
            data: this.state.data,
        }).then(( response ) => {
            
            //console.log('save success: ', response );

            if( typeof( this.props.onClose ) == "function" )
                this.props.onClose( false, true );

            this.setState({
                loading: false,
                unsavedChanges: false,
            });
            
        }).catch( ( error ) => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    renderBody()
    {
        return (
            <div id="RuleEditBody">
                <div className="apForm">
                    <div className="apFormColumn">

                        <ApInput 
                            type="text" 
                            id="name" 
                            name="name" 
                            label={ tr('name') }
                            value={ this.state.data.name }
                            onChange={ this.handleChange }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            validationState={this.validator.getState('data.name') }
                        />

                        <ApInput 
                            type="textarea" 
                            id="description" 
                            name="description" 
                            label={ tr('description') }
                            value={ this.state.data.description }
                            onChange={ this.handleChange }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            //validationState={ this.validatorHour.getState('data.description') }
                        />

                        <ApInput 
                            type="text" 
                            id="salary_category" 
                            name="salary_category" 
                            label={ tr('salary_type') }
                            value={ this.state.data.salary_category }
                            onChange={ this.handleChange }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            //validationState={ this.validatorHour.getState('data.description') }
                        />

                        <br />  

                        <ApInputStack gap="0">
                            <ApInput 
                                gapRight={ 10 }
                                type="select" 
                                id="amount_type" 
                                name="amount_type" 
                                value={ this.state.data.amount_type }
                                options={[
                                    { value: "fixed", label: tr('amount_type_fixed') },
                                    { value: "per_hour", label: tr('amount_type_per_hour') },
                                    { value: "per_hour_salary", label: tr('amount_type_per_hour_salary') },
                                ]}
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                //validationState={ this.validatorHour.getState('data.description') }
                            />
                            <ApInput 
                                width="100"
                                type="number" 
                                id="unit_price" 
                                name="unit_price" 
                                align="right"
                                value={ this.state.data.unit_price }
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                validationState={this.validator.getState('data.unit_price') }
                            />
                            <ApAddon noLeftBorder width="100">
                                { this.state.data.amount_type === "fixed" &&
                                    <span>{ auth.getCurrency() }</span> 
                                }
                                { this.state.data.amount_type === "per_hour" &&
                                    <span>{ auth.getCurrency() } / h</span> 
                                }
                                { this.state.data.amount_type === "per_hour_salary" &&
                                    <span>% / h</span> 
                                }
                            </ApAddon>
                        </ApInputStack>

                        {/* 
                        <ApInputStack gap="0">
                            <ApAddon noRightBorder>
                                Tuntimäärään perustuva korvaus
                            </ApAddon>
                            <ApInput 
                                width="100px"
                                type="number" 
                                id="salary_extra_per_unit" 
                                name="salary_extra_per_unit" 
                                align="right"
                                value={ this.state.data.salary_extra_per_unit }
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                //validationState={ this.validatorHour.getState('data.description') }
                            />
                            <ApAddon noLeftBorder width="100px">
                                { auth.getCurrency() } / h
                            </ApAddon>
                        </ApInputStack>

                        <ApInputStack gap="0">
                            <ApAddon noRightBorder>
                                Tuntipalkkaan perustuva korvaus
                            </ApAddon>
                            <ApInput 
                                width="100px"
                                type="number" 
                                id="salary_extra_percent" 
                                name="salary_extra_percent" 
                                align="right"
                                value={ this.state.data.salary_extra_percent }
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                //validationState={ this.validatorHour.getState('data.description') }
                            />
                            <ApAddon noLeftBorder width="100px">
                                %
                            </ApAddon>
                        </ApInputStack>
                        */}

                        <div className={"apFormGroup" + (this.state.data.no_travel_hours ? " success" : "")}>
                            <div className="apSwitchBlock small">
                                <label htmlFor="no_travel_hours-switch" className="info">
                                    {tr('no_for_travel_hours')}
                                    <small>{tr('no_for_travel_hours_info')}</small>
                                </label>
                                <ApSwitch
                                    small
                                    id="no_travel_hours-switch"
                                    on={this.state.data.no_travel_hours}
                                    onChange={(e) => this.handleSwitchChange('no_travel_hours', e.target.checked)}
                                    disabled={this.state.loading}
                                />
                            </div>
                        </div>


                        <div className={ "apFormGroup" + ( this.state.data.limit_available ? " success" : "" ) }>
                            <div className="apSwitchBlock small">
                                <label htmlFor="limit-switch" className="info">
                                    { tr('limit_compensation') }
                                    <small>{ tr('limit_compensation_info') }</small>
                                </label>
                                <ApSwitch
                                    small
                                    id="limit-switch"
                                    on={ this.state.data.limit_available }
                                    onChange={ ( e ) => this.handleSwitchChange( 'limit_available', e.target.checked ) }
                                    disabled={ this.state.loading }
                                />
                            </div>
                            
                            <Collapse in={ this.state.data.limit_available }>
                                <div>
                                    <hr />

                                    <ApInputStack gap="0">
                                        <ApInput 
                                            gapRight={ 10 }
                                            type="select" 
                                            id="limit_type" 
                                            name="limit_type" 
                                            value={ this.state.data.limit_type }
                                            options={[
                                                { value: "period", label: tr('limit_type_period') },
                                                { value: "daily", label: tr('limit_type_daily') },
                                                { value: "weekly", label: tr('limit_type_weekly') },
                                                { value: "montly", label: tr('limit_type_monthly') },
                                                { value: "yearly", label: tr('limit_type_yearly') },
                                            ]}
                                            onChange={ this.handleChange }
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading }
                                            //validationState={ this.validatorHour.getState('data.description') }
                                        />
                                        <ApInput 
                                            width="100px"
                                            type="number" 
                                            id="limit" 
                                            name="limit" 
                                            align="right"
                                            value={ this.state.data.limit }
                                            onChange={ this.handleChange }
                                            loading={ this.state.loading }
                                            disabled={ this.state.loading }
                                            //validationState={ this.validatorHour.getState('data.description') }
                                        />
                                        <ApAddon width="100px">
                                            { auth.getCurrency() }
                                            { this.state.data.limit_type == "daily" && `/${tr('day_abbr')}` }
                                            { this.state.data.limit_type == "weekly" && `/${tr('week_abbr')}` }
                                            { this.state.data.limit_type == "montly" && `/${tr('month_abbr')}` }
                                            { this.state.data.limit_type == "yearly" && `/${tr('year_abbr')}` }
                                        </ApAddon>
                                        
                                    </ApInputStack>
                                </div>
                            </Collapse>
                        </div>


                    </div>
                    <div className="apFormColumn">
                        
                        <div className={ "apFormGroup" + ( this.state.data.active ? " success" : "" ) }>
                            <div className="apSwitchBlock small">
                                <label htmlFor="activity-switch" className="info">
                                    { tr('active') }
                                    <small>{ tr('rule_edit_active_note') }</small>
                                </label>
                                <ApSwitch
                                    small
                                    id="activity-switch"
                                    on={ this.state.data.active }
                                    onChange={ ( e ) => this.handleSwitchChange( 'active', e.target.checked ) }
                                    disabled={ this.state.loading }
                                />
                            </div>
                        </div>

                        <ApInputStack>
                            <ApInput 
                                type="date" 
                                id="start_date" 
                                name="start_date" 
                                label={ tr('valid_from') }
                                value={ sqlToDateInput( this.state.data.start_date ) }
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                //validationState={ this.validatorHour.getState('data.description') }
                            />

                            <ApInput 
                                type="date" 
                                id="end_date" 
                                name="end_date" 
                                label={ tr('valid_until') }
                                value={ sqlToDateInput( this.state.data.end_date ) }
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                //validationState={ this.validatorHour.getState('data.description') }
                            />
                        </ApInputStack>

                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { !this.state.data.active && tr('compensation_not_active') }
                            { this.state.data.active && !this.state.data.end_date && tr('compensation_active') }
                            { this.state.data.active && this.state.data.end_date && 
                                `${tr('compensation_active_days')} ${moment(this.state.data.end_date).diff(moment(this.state.data.start_date), 'days')+1} ${tr('days')}`
                            }
                        </div>

                        <br />

                        <ApSelect
                            label={ tr('valid_for_persons') }
                            loading={ this.state.loading }
                            value={ this.state.data ? this.state.data.users : [] }
                            valueRenderer="user"
                            optionRenderer="user"
                            onChange={ ( values ) => this.handleListChange( values, "users" ) }
                            objKeyId="id"
                            multiselect
                            apiUrl="company/settings/users"
                            apiData={{ exluded: [] }}
                            filterNonActives={true}
                            //validationState={ this.validator.getState('settings.workhour.confirmation_users') }
                        />

                        <div className="apSwitchBlock small">
                            <label htmlFor="sub_projects-switch" className="info">
                                {tr('salary_extra_sub_project_info')}
                            </label>
                            <ApSwitch
                                small
                                id="sub_projects-switch"
                                on={this.state.data.sub_projects}
                                onChange={(e) => this.handleSwitchChange('sub_projects', e.target.checked)}
                                disabled={this.state.loading}
                            />
                        </div>
                        <ApSelect
                            label={ tr('valid_for_projects') }
                            loading={ this.state.loading }
                            value={ this.state.data ? this.state.data.projects : [] }
                            valueRenderer="project"
                            optionRenderer="project"
                            onChange={ ( values ) => this.handleListChange( values, "projects" )  }
                            objKeyId="id"
                            multiselect
                            apiUrl="timetracking/salary_extras/projects"
                            apiData={{ exluded: [] }}
                            //validationState={ this.validator.getState('settings.workhour.confirmation_users') }
                        />
                    
                    </div>
                </div>
            </div>

        )
    }

    render() 
    {
        return (
            
            <ApModal
                show={ typeof( this.props.id ) == "number" || this.props.id == "new" }
                handleClose={ () => this.props.onClose( this.state.unsavedChanges, false ) }
                onOpen={ this.fetchData }
                closeFromBg
                header={ 
                    <div className="padding" id="RuleEditHeader">
                        <h3>{ this.props.id == "new" ? tr('new') : tr('edit') }</h3>
                    </div>
                }
                body={ this.renderBody() }
                footer={
                    <div className="padding" id="RuleEditFooter">
                        
                        {/* 
                        <div className="validateMsg">
                            <SvgIcon icon="info-circle" type="solid" />
                            TODO: INFO TEXT HERE
                        </div>
                        */}
                        
                        <ApButton onClick={ () => this.props.onClose( this.state.unsavedChanges, false ) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton className={"save" + (this.state.unsavedChanges ? " highlight" : "")} color="blue" onClick={this.save} disabled={!this.state.unsavedChanges || Boolean(this.validator.getStatusAll())}>
                            <SvgIcon icon="save" type="solid" />
                            { tr('save') }
                        </ApButton>

                    </div>
                }

            />


        );
    }
}


RuleEdit.propTypes = {
    id:         PropTypes.any.isRequired,
    onClose:    PropTypes.func,
    // number:       PropTypes.number.isRequired,
    // string:       PropTypes.string,
    // multiple:     PropTypes.oneOfType([ PropTypes.array, PropTypes.func ]),
    // bool:         PropTypes.bool,
};


export default RuleEdit;
