import React from 'react';
import autobind from 'react-autobind';
import ApReactTable, { colPreset } from 'common/ApReactTable/ApReactTable';
import api from 'services/Api/Api';
import { errorPopper, getMinMax, mapTree, tr } from 'services/Helpers/Helpers';
import _ from 'lodash';
import axios from "axios";
import ApTree from 'common/ApTree/ApTree';

import './WooCommerceCategories.css';
import ApTooltip from 'common/ApTooltip/ApTooltip';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import EditProduct from './EditProduct';
import ApButton from 'common/ApButton/ApButton';
import EditCategory from './EditCategory';
import ApDropdown from 'common/ApDropdown/ApDropdown';
import ApConfirm from 'common/ApConfirm/ApConfirm';
import ApModalInput from 'common/ApModalInput/ApModalInput';
import ExportProductModal from './ExportProductModal';

class WooCommerceCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoriesLoading: false,
            componentsLoading: false,
            cancelToken: axios.CancelToken.source(),

            columns: [],
            data: [],
            pages: 0,
            tree: [],
            components: [],
            selectedCategory: null,

            showEditModal: false,
            selectedProduct: null,

            showEditCategoryModal: false,
            editCategory: null,

            showDeleteConfirm: false,

            manualLink: null,
            showExportModal: false,
        };
        autobind(this);
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.selectedCategory && this.state.selectedCategory) {
            this.getComponents();
        }
        if (prevState.selectedCategory && this.state.selectedCategory && prevState.selectedCategory.id != this.state.selectedCategory.id) {
            this.getComponents();
        }
    }

    selectNode(id) {
        let tree = JSON.parse(JSON.stringify(this.state.tree));
        tree = mapTree(tree, (item, parent) => {

            item.__selected = false;
            if (id === item.id) {
                item.__selected = true;

                if (item.children)
                    item.__open = true;
            }

            return item;
        });

        this.setState({ tree: tree });
    }

    handleLocationSelect(category) {
        this.selectNode(category.id)
        this.setState({ selectedCategory: category });
    }

    toTree(list) {
        try {
            const arrayToTree = (arr, parent = 0) =>
                arr.filter(item => item.parent == parent)
                    .map(child => ({
                        ...child, children: arrayToTree(arr,
                            child.id)
                    }));
            return arrayToTree(list);

        } catch (error) {
            console.error(error);
        }
    }

    openProductEdit(row) {
        this.setState({ selectedProduct: row.id, showEditModal: true })
    }

    renderBody() {
        if (this.state.categoriesLoading) {
            return <div className="padding apLoader"></div>
        } else {
            return <div>
                <div className="splitView">
                    <div className="left">
                        <div className="categories ">
                            <ApTree
                                tree={this.state.tree}
                                nodeRenderer={(location, level) => location.name}
                                onTreeChange={(tree) => this.setState({ tree })}
                                onNodeClick={this.handleLocationSelect}
                            />
                            <hr />
                            <ApButton color="green" onClick={() => this.setState({ showEditCategoryModal: true })} size="small" fullWidth>
                                <SvgIcon type="solid" icon="plus" /> {tr('new_category')}
                            </ApButton>
                        </div>
                    </div>
                    <div className="right">
                        <div className="details">
                            <div className="padding">
                                {this.renderComponents()}
                            </div>
                        </div>
                    </div>
                </div>
                <div id='link-product-modal'>
                    <ApModalInput
                        show={ Boolean(this.state.manualLink) }
                        value={ this.state.modalValue }
                        onSave={ ( value ) => this.saveManualLink( value ) }
                        onClose={ () => this.setState({ manualLink: null }) }
                        title={ tr('link_product_with_storage_component') }
                        label={tr('link_product_with_storage_component')}
                        type='selectStock'
                        required
                    />
                </div>
            </div>
        }
    }

    renderComponents() {
        return <div>
            {this.state.selectedCategory
                ? <h4>
                    <span style={{ paddingRight: "15px" }}>{this.state.selectedCategory.name}</span>
                    <ApDropdown
                        position="right"
                        actions={[
                            {
                                label: tr('edit'),
                                icon: "edit",
                                action: (item, closeFunc) => {
                                    this.setState({ showEditCategoryModal: true, editCategory: this.state.selectedCategory });
                                    closeFunc();
                                },
                            },
                            {
                                label: tr('delete'),
                                icon: "trash",
                                action: (item, closeFunc) => {
                                    this.setState({ showDeleteConfirm: true });
                                    closeFunc();
                                },
                            },
                        ]}
                    />
                </h4>
                : ''}
            <ApReactTable
                data={this.state.components}
                columns={this.getColumns()}
                loading={this.state.componentsLoading}
                rowActions={(row) =>
                    <div className="apSimpleButton" onClick={() => this.setState({ selectedProduct: row.id, showEditModal: true })}>
                        <SvgIcon icon="edit" type="solid" />
                    </div>
                }
                manual
                onFetchData={(state) => {
                    this.getComponents(state)
                }}
                pages={this.state.pages}
                multiselect={[
                    {
                        icon: "link",
                        label: tr('link_products'),
                        action: ( indexes ) => this.syncComponents(indexes),
                        unselectAfter: true,
                        closeAfter: true,
                        alwaysOn: true
                    },
                    {
                        icon: "unlink",
                        label: tr('remove_link'),
                        action: ( indexes ) => this.removeLink(indexes),
                        unselectAfter: true,
                        closeAfter: true,
                    },
                    {
                        icon: "sync",
                        label: tr('export_storage_components_online_store'),
                        action: ( indexes ) => this.setState({showExportModal: true,}),
                        unselectAfter: true,
                        closeAfter: true,
                        alwaysOn: true
                    },
                    {
                        icon: "warehouse",
                        label: tr('update_price_balance'),
                        action: ( indexes ) => this.updatePriceAndBalance(indexes),
                        unselectAfter: true,
                        closeAfter: true,
                        alwaysOn: true
                    },
                ]}
            />
        </div>
    }

    getColumns() {
        const columns = [
            {
                Header: tr('link'),
                className: 'overflowableOnHover',
                accessor: 'easypro_stock',
                customFilter: {
                    type: "toggle",
                    placeholder: tr('link'),
                },
                Cell: (props) => {
                    if (props.value)
                        return <div className="text-center pointer"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                    else
                        return <div className="text-center pointer">
                            <ApTooltip position='right' text={tr('storage_component_no_link')}>
                                <SvgIcon fill="#ff8800" icon="exclamation-triangle" type="solid" />
                            </ApTooltip>
                        </div>
                },
                sortable: true,
                filterable: false, // TODO filter with manual filtering
                customizable: false,
                showInitially: true,
                width: 50,
                onClick: (props) => {
                    if (!props.easypro_stock) {
                        this.setState({manualLink: props.id})
                    }
                }
            },
            {
                Header: tr('name'),
                accessor: 'name',
                customFilter: {
                    type: "text",
                    placeholder: tr('name'),
                    fields: ["name", "sku"]
                },
                sortable: true,
                filterable: true,
                customizable: false,
                showInitially: true,
                onClick: this.openProductEdit,
            },
            colPreset({
                type: 'currency',
                Header: tr('price'),
                accessor: 'price',
                customFilter: {
                    type: "range",
                    placeholder: tr('price'),
                    min: getMinMax('price', 'min', this.state.components),
                    max: getMinMax('price', 'max', this.state.components),
                },
                sortable: true,
                filterable: false,
                customizable: true,
                showInitially: true,
                width: 75,
            },
            ),
            colPreset({
                type: 'number',
                Header: tr('amount'),
                accessor: 'stock_quantity',
                customFilter: {
                    type: "range",
                    placeholder: tr('amount'),
                    min: getMinMax('stock_quantity', 'min', this.state.components),
                    max: getMinMax('stock_quantity', 'max', this.state.components),
                },
                sortable: true,
                filterable: false,
                customizable: true,
                showInitially: true,
                width: 75,
            }),
            {
                Header: tr('identifier'),
                accessor: 'sku',
                customFilter: {
                    type: "text",
                    placeholder: tr('identifier'),
                    fields: ["sku"]
                },
                sortable: true,
                filterable: false, // WooCommerce requires exact match when searching
                customizable: true,
                showInitially: true,
            },
            {
                Header: tr('status'),
                className: 'overflowableOnHover',
                accessor: 'status',
                customFilter: {
                    type: "toggle",
                    placeholder: tr('status'),
                },
                Cell: (props) => {
                    if (props.value == 'publish')
                        return <div className="text-center pointer"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                    else
                        return <div className="text-center pointer">
                            <SvgIcon fill="#ff8800" icon="exclamation-triangle" type="solid" />
                        </div>
                },
                sortable: true,
                filterable: true,
                customizable: false,
                showInitially: true,
                width: 50,
            },
        ];
        return columns;
    }

    getData() {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        let cancelToken = axios.CancelToken.source();
        this.setState({ categoriesLoading: true, cancelToken });

        api({
            method: 'get',
            url: 'woocommerce/categories',
            cancelToken: cancelToken.token,
        }).then((response) => {
            // console.log('RES', response);
            let data = [];
            let tree = [];
            if (response.data && response.data.length) {
                data = _.orderBy(response.data, 'name');
                tree = this.toTree(response.data);
            }
            this.setState({ data, categoriesLoading: false, cancelToken: null, tree });
        }).catch((error) => {
            this.setState({ categoriesLoading: false, cancelToken: null })
            if (axios.isCancel(error)) { return null };

            errorPopper(error, tr('get_error'));
        })
    }

    getComponents(state = null) {
        if (!this.state.selectedCategory) return

        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        const data = {
            page: 0,
            per_page: 20
        };
        if (state) {
            data.page = state.page;
            data.per_page = state.pageSize;
            if (state.filtered.length) {
                if (state.filtered.find(f => f.id === 'name')) {
                    data.search = state.filtered.find(f => f.id === 'name').value;
                }
                if (state.filtered.find(f => f.id === 'sku')) {
                    data.sku = state.filtered.find(f => f.id === 'sku').value;
                }
                if (state.filtered.find(f => f.id === 'status')) {
                    data.status = state.filtered.find(f => f.id === 'status').value === 'true' ? 'publish' : 'private';
                }
            }
        }

        let cancelToken = axios.CancelToken.source();
        this.setState({ componentsLoading: true, cancelToken });

        api({
            method: 'post',
            url: 'woocommerce/products/category/' + this.state.selectedCategory.id,
            data,
            cancelToken: cancelToken.token,
        }).then((response) => {
            // console.log('RES', response);
            const components = _.orderBy(response.data, 'name');
            this.setState({ components, pages: response.pages, componentsLoading: false, cancelToken: null });
        }).catch((error) => {
            this.setState({ cancelToken: null });
            if (axios.isCancel(error)) { return null };
            this.setState({ componentsLoading: false });

            errorPopper(error, tr('get_error'));
        })
    }

    deleteCategory() {
        this.setState({ categoriesLoading: true, selectedCategory: null, components: [] });

        api({
            method: 'post',
            url: 'woocommerce/categories/delete/' + this.state.selectedCategory.id,
        }).then((response) => {
            // console.log('RES', response);
            this.getData();
            this.setState({ categoriesLoading: false });
            this.selectNode(null);
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            this.setState({ categoriesLoading: false });
        })
    }

    syncComponents(indexes) {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        const products = _.cloneDeep(this.state.components);
        let data = [];
        if (indexes.length) {
            data = indexes.map(index => products[index]);
        } else {
            data = products;
        }

        let cancelToken = axios.CancelToken.source();
        this.setState({ componentsLoading: true, cancelToken });
        
        api({
            method: 'post',
            url: 'woocommerce/sync/products',
            cancelToken: cancelToken.token,
            data: {products: data}
        }).then((response) => {
            // console.log('RES', response);
            this.setState({componentsLoading: false, cancelToken: null});
            this.getComponents();
        }).catch((error) => {
            this.setState({componentsLoading: false, cancelToken: null})
            if (axios.isCancel(error)) { return null };
            errorPopper(error, tr('get_error'));
        })
    }

    saveManualLink(stock) {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        let cancelToken = axios.CancelToken.source();
        this.setState({ componentsLoading: true, cancelToken });
        
        api({
            method: 'post',
            url: 'woocommerce/sync/products/manual',
            cancelToken: cancelToken.token,
            data: {
                stockId: stock.id,
                productId: this.state.manualLink
            }
        }).then((response) => {
            // console.log('RES', response);
            this.setState({componentsLoading: false, cancelToken: null, manualLink: null});
            this.getComponents();
        }).catch((error) => {
            this.setState({componentsLoading: false, cancelToken: null, manualLink: null})
            if (axios.isCancel(error)) { return null };
            errorPopper(error, tr('get_error'));
        })
    }
    
    removeLink(indexes) {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        const data = _.cloneDeep(this.state.components).map(item => item.id);
        const products = indexes.map(index => data[index]);

        let cancelToken = axios.CancelToken.source();
        this.setState({ componentsLoading: true, cancelToken });
        
        api({
            method: 'post',
            url: 'woocommerce/sync/products/remove',
            cancelToken: cancelToken.token,
            data: {
                products,
            }
        }).then((response) => {
            // console.log('RES', response);
            this.setState({componentsLoading: false, cancelToken: null});
            this.getComponents();
        }).catch((error) => {
            this.setState({loading: false, cancelToken: null})
            if (axios.isCancel(error)) { return null };
            errorPopper(error, tr('get_error'));
        })
    }

    updatePriceAndBalance(indexes) {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        const products = _.cloneDeep(this.state.components);
        let data = [];
        if (indexes.length) {
            data = indexes.map(index => products[index]);
        } else {
            data = products;
        }

        data = data.filter(item => item.easypro_stock);

        let cancelToken = axios.CancelToken.source();
        this.setState({ componentsLoading: true, cancelToken });
        
        api({
            method: 'post',
            url: 'woocommerce/update/pricesbalances',
            cancelToken: cancelToken.token,
            data: {products: data}
        }).then((response) => {
            // console.log('RES', response);
            this.setState({componentsLoading: false, cancelToken: null});
            this.getComponents();
        }).catch((error) => {
            this.setState({componentsLoading: false, cancelToken: null})
            if (axios.isCancel(error)) { return null };
            errorPopper(error, tr('get_error'));
        })
    }

    closeModal(refresh) {
        this.setState({ showEditModal: false, selectedProduct: null });

        if (refresh) {
            this.getComponents();
        }
    }

    closeExportModal(refresh) {
        this.setState({showExportModal: false});

        if (refresh) {
            this.getComponents();
        }
    }

    closeNewCategoryModal(refresh) {
        this.setState({ showEditCategoryModal: false, editCategory: null });

        if (refresh) {
            this.getData();
        }
    }

    render() {
        return (
            <div id='woocommerce-catgories' className="padding">
                {this.renderBody()}
                <EditProduct
                    onClose={this.closeModal}
                    selectedProduct={this.state.selectedProduct}
                    show={this.state.showEditModal}
                />
                <EditCategory
                    onClose={this.closeNewCategoryModal}
                    selectedProduct={this.state.selectedProduct}
                    show={this.state.showEditCategoryModal}
                    categoryTree={this.state.tree}
                    categories={this.state.data}
                    selectedCategory={this.state.editCategory}
                />

                <ExportProductModal 
                    onClose={this.closeExportModal}
                    show={this.state.showExportModal}
                />

                <ApConfirm 
                    show={this.state.showDeleteConfirm}
                    onClose={() => { this.setState({ showDeleteConfirm: false }) }}
                    onConfirm={this.deleteCategory}
                    header={tr('delete_category')}
                    body={tr('delete_category_confirm')}
                />
            </div>
        )
    }
}

export default WooCommerceCategories;
