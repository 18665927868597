import React from 'react';
import autoBind from 'react-autobind';

import ApModal from 'common/ApModal/ApModal.js';
import FileUpload from 'common/FileUpload/FileUpload.js';
import ApButton from 'common/ApButton/ApButton.js';

import api from 'services/Api/Api.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';

import { ApInputStack,
         ApAddon } from 'common/ApInput/ApInput.js';

import File from 'common/File/File.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import { tr } from 'services/Helpers/Helpers.js';

import './Files.css';

class Files extends React.Component
{
    constructor( props )
    {
        super( props );
        this.state = {
            files: [],
            filesSaved: [],
            renameModal: {},
        }
        autoBind(this);
    }

    fileUpload( newFile )
    {
        let files = this.state.files.slice(0);
        files.push( newFile )
        this.setState({ files: files });
    }

    onOpen()
    {
        this.setState({ files: []});
        return api({
            method: 'get',
            url: `crm/company/files/${this.props.crmId}`,
            errorPopper: tr('get_error'),
        }).then((response) => {

            this.setState({
                files: response,
                filesSaved: JSON.parse(JSON.stringify( response )),
                loading: false,
            });
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    save()
    {
        let files = this.state.files.map( f => {
            return {
                id: f.id,
                name: f.name,
            };
        })

        this.setState({loading: true });
        return api({
            method: 'post',
            url: `crm/company/files/${this.props.crmId}`,
            // url: `storage/order/saveRelatedFiles/${this.props.crmId}`,
            errorPopper: tr('save_error'),
            data: {
                crm_files: files,
            },
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('saved') }</strong>,
            });
            this.setState({ loading: false });

            this.props.onClose();

        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    onCloseConfirm()
    {
        if( JSON.stringify( this.state.files) !== JSON.stringify( this.state.filesSaved) )
        {
            if( window.confirm( tr('cancel_confirm') ) )
                this.props.onClose();
        }
        else
            this.props.onClose();
    }

    removeFile( index )
    {
        let files = this.state.files.slice(0);
        files.splice( index, 1 );
        this.setState({ files: files });
    }

    renameFileOpen( index )
    {
        const row = this.state.files[ index ];
        const name = row.name ? row.name : row.original_name;
        const renameModal = {
            show: true,
            index: index,
            name: name,
        };
        this.setState({ renameModal: renameModal });
    }

    renameFileSave( value )
    {
        let files = this.state.files.slice(0);
        files[ this.state.renameModal.index ].name = value;
        this.setState({
            files: files,
            renameModal: {},
        });
    }

    render()
    {
        let addDom = null;
        let footer = null;

        if( !this.props.locked )
        {
            addDom = <div className="fileSelectContainer">
                <ApInputStack gap="0">
                    <ApAddon width="200px" noRightBorder>
                        { tr('new_file') }
                    </ApAddon>
                    <FileUpload
                        onUpload={this.fileUpload }
                        description="PoSentFile"
                        label={ `${tr('choose_file')}...` }
                        value={ this.state.manualFile }
                        onClear={ this.fileClear }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }
                    />
                </ApInputStack>
            </div>

            footer = <div className="padding-small text-right">
                <ApButton
                    className="save"
                    color="blue"
                    onClick={ () => this.save() } 
                    disabled={ this.state.loading } 
                    loading={ this.state.loading } 
                >
                    <SvgIcon icon="save" type="solid" />
                    { tr('save') }
                </ApButton>
            </div>
        }


        const fileRows = this.state.files.map( ( i, index ) => {

            let renameButton = null;
            let removeButton = null;

            if( !this.props.locked )
            {
                renameButton = <ApTooltip block text={ tr('rename') }>
                    <div className="apSimpleButton" onClick={ () => { this.renameFileOpen( index ) }}>
                        <SvgIcon icon="pen" type="solid" />
                    </div>
                </ApTooltip>
                removeButton = <ApTooltip block text={ tr('delete') }>
                    <div className="apSimpleButton" onClick={ () => { this.removeFile( index ) }}>
                        <SvgIcon icon="trash" type="solid" />
                    </div>
                </ApTooltip>
            }
            return <tr key={ i.id }>
                <td>
                    <File  fileId={ i.id } name={ i.original_name }>
                        <SvgIcon icon="file" type="solid" /> { i.name ? i.name : i.original_name }
                    </File>
                </td>
                <td className="smallButton">{ renameButton }</td>
                <td className="smallButton">{ removeButton }</td>
            </tr>
        });

        return <div id="projectFiles" className="ApModalConfirm">
            <ApModal
                show={ this.props.show }
                handleClose={ this.onCloseConfirm }
                className="narrow"
                onOpen={ this.onOpen }
                header={
                    <div className="padding-small"><h4>
                        {tr('customer_files') }
                    </h4></div>
                }
                body={
                    <div className="padding">
                        { addDom }
                        <table className="filesTable"><tbody>
                            { fileRows }
                        </tbody></table>
                    </div>
                }
                footer={ footer }
            />
            <ApModalInput
                show={ Boolean( this.state.renameModal.show ) }
                title={ tr('rename_file') }
                value={ this.state.renameModal.name }
                label={ tr('file_name') }
                onSave={ this.renameFileSave }
                onClose={ () => this.setState({ renameModal: {} }) }
            />
        </div>
    }
}



export default Files;
