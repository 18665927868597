import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import { ApTabs, 
         ApTab } from 'common/ApTabs/ApTabs.js';

import ExtranetDocuments from './ExtranetDocuments.js';
import ExtranetNews from './ExtranetNews.js';
import ExtranetModules from './ExtranetModules.js';
import GeneralSettings from './GeneralSettings.js';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import { tr } from 'services/Helpers/Helpers.js';

export default class ExtranetSettings extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			loading: false,
			unsavedChanges: false,
			settings: [],
			files: [],
			news: []
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount()
	{

		this.getSettings();

	}

	getSettings()
	{

		this.setState({ loading: true });

		api({
			method: 'get',
			url: 'crm/extranet/settings'
		}).then((response) => {
			let data = response;
			this.setState({ settings: data }, () => {
				this.getFiles();
			});
		}).catch((error) => {
			console.log(error);
		});

	}

	getFiles()
	{

		api({
			method: 'get',
			url: 'crm/extranet/getFilesAndBrochures'
		}).then((response) => {
			this.setState({ files: response }, () => {
				this.getNews();
			});
		}).catch((error) => {
			console.log(error);
		});

	}

	getNews()
	{

		api({
			method: 'get',
			url: 'crm/extranet/getNewsitems'			
		}).then((response) => {
			this.setState({ news: response, loading: false });
		}).catch((error) => {
			console.log(error);
		});

	}

	handleChange(field, value)
	{

		//console.log(field);
		//console.log(value);

		let settings = this.state.settings;

		switch (field)
		{

			case "titleText":
				settings.title_text = value;
				break;
            default: break;



		}

		this.setState({
			unsavedChanges: true,		
			settings
		}, () => {
			//console.log(settings);
		});

	}

	handleSwitchChange(field)
	{

		//console.log(field);

		let settings = this.state.settings;

		switch (field)
		{

			case "showTimetracking":
				settings.show_timetracking = !settings.show_timetracking;
				break;

			case "showProjects":
				settings.show_projects = !settings.show_projects;
				break;

			case "showDocuments":
				settings.show_documents = !settings.show_documents;
				break;

			case "showBrochures":
				settings.show_brochures = !settings.show_brochures;
				break;

			case "showStats":
				settings.show_statistics = !settings.show_statistics;
				break;

			case "showNews":
				settings.show_news = !settings.show_news;
				break;
			case "showReports":
				settings.show_reports = !settings.show_reports;
				break;
			case "showDescription":
				settings.show_description = !settings.show_description;
				break;
			case "showPermits":
				settings.show_permits = !settings.show_permits;
				break;
			case "showSupplierPortal":
				settings.show_supplier_portal = !settings.show_supplier_portal;
				break;
			case "supplierPortalIsActive":
				settings.supplier_portal_is_active = !settings.supplier_portal_is_active;
				break;
			case 'showAssignmentRequest':
				settings.show_assignment_requests = !settings.show_assignment_requests;
				break;
				
			default:

		}

		this.setState({
			unsavedChanges: true,
			settings
		}, () => {
			//console.log(settings);
		});

	}
	onChange(variableName, data) {
		let settings = this.state.settings;
		settings[variableName] = data;
		this.setState({ settings: settings, unsavedChanges: true,});
		
	}

	saveChanges()
	{

		this.setState({ loading: true });

		const data = this.state.settings;

		api({
			method: 'post',
			url: 'crm/extranet/saveSettings',
			data: { data }
		}).then((response) => {
			this.setState({ 
				loading: false, 
				unsavedChanges: false 
			});
		}).catch((error) => {
			this.setState({ 
				errorMsg: tr('unexpected_error'),
				loading: false
			});
		});

	}

	render()
	{

		return(

			<ApFormPage
				loading={this.state.loading}
				unsaved={this.state.unsavedChanges}
				onSave={this.saveChanges}
				error={this.state.errorMsg}
			>

			<div className="apBox" id="pageExtranetSettings">

				<div className="apBoxHeader">
					<h1>{ tr('extranet_settings') }</h1>
					<p>{ tr('extranet_settings') }</p>
				</div>

				<ApTabs>

					<ApTab icon="cog" label={ tr('general_settings') }>
						<GeneralSettings 
							data={this.state.settings}
							onChange={this.handleChange}
						/>
					</ApTab>

					<ApTab icon="newspaper" label={ tr('news') }>
						<ExtranetNews 
							data={this.state.news}
							onChange={this.handleChange}
						/>
					</ApTab>

					<ApTab icon="server" label={ tr('visibility_settings') }>
						<ExtranetModules 
							data={this.state.settings}
							handleSwitchChange={this.handleSwitchChange}
							onChange={this.onChange}
							loading={this.state.loading}
						/>
					</ApTab>

					<ApTab icon="copy" label={ tr('files') }>
						<ExtranetDocuments />
					</ApTab>

				</ApTabs>

			</div>

			</ApFormPage>

		);

	}

}
