/* eslint-disable eqeqeq */
import React from 'react';
import './Stopwatch.css';
import { tr } from 'services/Helpers/Helpers.js';
import DonutChart from "react-svg-donut-chart";

class Stopwatch extends React.Component {

    render()
    {

        const value = this.props.value;
        const max = this.props.max;
    
        let donutData = [];
        let remain = ( value < max ? max - value : 0 );
        let overtime = ( value > max ? value - max : 0 );

        // BG
        donutData.push( { value: remain, stroke: "rgba(255,255,255,0.3)", strokeWidth: 1 } );

        // Main progress
        donutData.push( { value: value, stroke: "#33b5e5", strokeWidth: 1 } );
        
        // Overtime
        donutData.push( { value: overtime, stroke: "#00C851", strokeWidth: 1 } );
        

        let infoTop = tr('stopped');
        if( this.props.status == "playing" )        infoTop = `${tr('running')}...`;
        else if( this.props.status == "paused" )    infoTop = tr('paused');

        let infoBottom = "";
        if( overtime > 0 ) {
            infoBottom = "+" + Math.floor( overtime / 60 ) + " min";
        }
        else {
            infoBottom = tr('goal') + " " + ( max / 3600 ) + " h";
        }

        return (
            <div className={"stopwatch " + this.props.status}>

                <div className="chart">
                    <DonutChart data={donutData} />
                
                    <div className="numbers">

                        <div className="info">{ infoTop }</div>

                        <div className="number hours">
                            <strong>{ this.props.time.h }</strong>
                            <small>h</small>
                        </div>
                        <span className="dots">:</span>
                        <div className="number minutes">
                            <strong>{ this.props.time.m }</strong>
                            <small>min</small>
                        </div>
                        <span className="dots">:</span>
                        <div className="number seconds">
                            <strong>{ this.props.time.s }</strong>
                            <small>s</small>
                        </div>

                        <div className="info">{ infoBottom }</div>
                    </div>
                </div>

            </div> 
        );
    }
};


export default Stopwatch;

