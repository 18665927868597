import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import { keyExists, tr } from 'services/Helpers/Helpers.js';

import {
	Container, Row, Col
} from 'react-bootstrap';
import ApModal from 'common/ApModal/ApModal.js';

import {
	ApInput,
} from 'common/ApInput/ApInput.js';

export default class PermitModalForm extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			companyUsers: [],
			userGroups: [],
			name: null,
			users: [],
			groups: [],
			noteBefore: null,
			noteBeforeToUser: null,
			defaultLength: null,
			formValid: false,
			changes: false
		};

		autoBind(this);

	}

	UNSAFE_componentWillMount() {

		this.getUsers();
		this.getUsersGroups();

		if (this.props.permitId !== undefined && this.props.permitId !== null) {
			this.getWorkPermitInfo(this.props.permitId);
			this.getPermitViewerGroups(this.props.permitId);
			this.getPermitViewerPeople(this.props.permitId);
		}


	}

	async getWorkPermitInfo(id) {

		await api({
			method: 'get',
			url: 'company/permits/getPermit',
			params: { permitId: id }
		}).then((response) => {
			this.setState({
				id: response.id,
				name: response.name,
				noteBefore: response.note_before_expiry_dates != null ? response.note_before_expiry_dates.toString():null,
				noteBeforeToUser: response.note_before_expiry_dates_to_user != null ? response.note_before_expiry_dates_to_user.toString() : null,
				defaultLength: response.work_permission_default_length
			}, () => { this.setValidationState() });
		}).catch((error) => {
			console.log(error);
		});

	}

	async getUsersGroups() {
		this.setState({ loading: true });
		await api({
			method: 'get',
			url: 'usermanagement/groups/getGroups',
		}).then((response) => {
			//console.log(response);
			this.setState({
				userGroups: response,
				loading: false
			});
		}).catch((error) => {
			//errorPopper( error, 'Käyttäjäryhmien hakeminen ei onnistunut' );
			this.setState({ error });
		});
	}

	async getUsers() {

		this.setState({ loading: true });
		await api({
			method: 'post',
			url: 'usermanagement/userNamesWithId'
		}).then((response) => {
			this.setState({
				companyUsers: response,
				loading: false
			});
		}).catch((error) => {
			this.setState({ error });
		});

	}

	saveWorkPermit() {
		//console.log(this.state);
		api({
			method: 'post',
			url: 'company/permits/savePermit',
			data: {
				permitId: this.props.permitId,
				name: this.state.name,
				noteBefore: this.state.noteBefore !== "" ? this.state.noteBefore:null,
				noteBeforeToUser: this.state.noteBeforeToUser !== "" ? this.state.noteBeforeToUser:null,
				groups: this.state.groups,
				users: this.state.users,
				defaultLength: this.state.defaultLength,
				order: this.props.newPermitPos,
			}
		}).then((response) => {
			this.props.onSave();
		}).catch((error) => {
			console.log(error);
		});

	}

	async getPermitViewerGroups(permitId) {

		await api({
			method: 'get',
			url: 'usermanagement/permits/permitViewerGroups',
			params: { permitId: permitId }
		}).then((response) => {
			let groups = response;
			groups.forEach((group, index) => {
				group.modules = [];
			});
			this.setState({ groups: groups });
		}).catch((error) => {
			console.log(error);
		});

	}

	async getPermitViewerPeople(permitId) {

		await api({
			method: 'get',
			url: 'usermanagement/permits/permitViewerPeople',
			params: { permitId: permitId }
		}).then((response) => {
			//console.log(response);
			this.setState({ users: response });
		}).catch((error) => {
			console.log(error);
		});

	}

	setValidationState() {

		let state = false;
		if (this.state.name !== null)
			state = true;
		else
			state = false;

		this.setState({ formValid: state });

	}

	clickCancelButton() {

		this.props.onClose();

	}

	clickSaveButton() {

		this.saveWorkPermit();

	}

	handleChange(e) {

		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			this.setState({changes: true});
			this.setValidationState();
		});
		//console.log(this.state);
	}

	changeGroups(groups) {

		//console.log(groups);
		this.setState({ groups: groups, changes: true });

	}

	changeUsers(users) {

		//console.log(users);
		this.setState({ users: users, changes: true });

	}

	renderForm() {

		let form =
			<Container fluid={true} className="padding">
				<Row>
					<Col sm={12}>
						<ApInput
							type="text"
							id="name"
							name="name"
							label={tr("work_permit_name")}
							autoComplete="off"
							onChange={this.handleChange}
							value={this.state.name}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<span>{tr("reminder_margin(days)")}</span>
					</Col>
				</Row>
				<Row>
					
					<Col sm={3}>
						<ApInput
							type="number"
							id="noteBefore"
							name="noteBefore"
							label={tr("to_supervisor")}
							onChange={this.handleChange}
							value={this.state.noteBefore}
						/>
					</Col>
					<Col sm={3}>
						<ApInput
							type="number"
							id="noteBeforeToUser"
							name="noteBeforeToUser"
							label={tr("to_user")}
							onChange={this.handleChange}
							value={this.state.noteBeforeToUser}
						/>
					</Col>
					<Col sm={6}>
						<ApInput
							type="number"
							id="defaultLength"
							name="defaultLength"
							label={tr("default_work_permit_length(years)")}
							autoComplete="off"
							onChange={this.handleChange}
							value={this.state.defaultLength}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<ApSelect
							options={this.state.userGroups}
							label={tr("user_groups_who_can_see_work_permits")}
							value={keyExists(this.state, "groups", true, [])}
							valueRenderer="usergroup"
							optionRenderer="usergroup"
							onChange={(groups) => { this.changeGroups(groups) }}
							objKeyId="id"
							objKeySearchable="name"
							multiselect
							clearable
							
						/>
					</Col>
					<Col sm={6}>
						<ApSelect
							options={this.state.companyUsers}
							label={tr("users_who_can_see_work_permits")}
							value={keyExists(this.state, "users", true, [])}
							valueRenderer="personlist"
							optionRenderer="personlist"
							onChange={(users) => { this.changeUsers(users) }}
							objKeyId="id"
							objKeySearchable="full_name"
							multiselect
							clearable
						/>
					</Col>
				</Row>
			</Container>;

		return form;

	}

	render() {

		const form = this.renderForm();

		return (

			<div id="userPermitModal">
				<ApModal
					show={this.props.show}

					className="medium"
					handleClose={this.clickCancelButton}
					header={
						<div className="padding-small">
							<h4>{this.props.permitId !== null ? tr("work_permit_info") : tr("new_work_permit")}</h4>
							

						</div>
					}
					body={
						<div className="padding">
							{form}
						</div>
					}
					footer={
						<div className="padding" style={{ display: 'flex', flexDirection: 'row' }}>
							<ApButton className="cancel" onClick={() => { this.clickCancelButton(null) }}>
								<SvgIcon icon="times" type="solid" />
								{tr('cancel')}
							</ApButton>
							<ApButton className={"save" + (!this.props.formValid && this.state.changes ? " highlight" : "")}
								color="blue"
								onClick={this.clickSaveButton}
								disabled={!this.state.formValid}
								style={{ marginLeft: 'auto' }}>
								<SvgIcon icon="save" type="solid" />
								{tr('save')}
							</ApButton>
						</div>
					}
				/>
			</div>
			

		);

	}

}
/*
<Modal
	show={this.props.show}
	backdrop="static"
	bsSize="large"
	handleClose={this.clickCancelButton()}

>

	<Modal.Header>
		<Modal.Title> {this.props.permitId !== null ? tr("work_permit_info") : tr("new_work_permit")} </Modal.Title>
	</Modal.Header>

	<Modal.Body>
		{form}
	</Modal.Body>

	<Modal.Footer>
		<ApButton color="red" onClick={() => { this.clickCancelButton(null) }}>
			{tr('cancel')}
		</ApButton>
		<ApButton className={"save" + (!this.props.formValid && this.state.changes ? " highlight" : "")} color="blue" onClick={this.clickSaveButton} disabled={!this.state.formValid}>
			<SvgIcon icon="save" type="solid" />
			{tr('save')}
		</ApButton>
	</Modal.Footer>

</Modal>
*/