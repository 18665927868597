import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import Calendar from 'react-calendar';

import './ApDatePicker.css';

import moment from 'moment';

import {sqlToDateInput, dateInputToSql, currentLang } from 'services/Helpers/Helpers.js';
import autoBind from 'react-autobind';

class ApDatePicker extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            focused: false,
            hideCalendar: true,
            innerValue: null,
            value:null
        };

        autoBind(this);
    }

    calendarOnOpen()
    {
        this.setState({ focused: true, hideCalendar: false });
    }

    calendarOnClose()
    {
        this.setState({ focused: false });
    }

    changeValue(value)
    {
        let inner;
        let isValid=false;
        if (value == 'clear') {

            inner='';
            this.setState({ value: '', innerValue: '', focused: false, hideCalendar: true });
        }
        else {
            let array=value.target.value.split('.');
            if ( array.length==3 && array[2].length==4) {
                isValid=true;
                inner=dateInputToSql(value.target.value, false);
            }
        }

        if( inner!="Invalid date" && isValid) {
            const e = {
                target: {
                    type: "ApDatePicker",
                    name: this.props.name,
                    id: this.props.id,
                    value: inner
                }
            };
            this.props.onChange(e);
            this.setState({ value: value.target.value,  inner: value.target.value });
        }
        else {
            const e = {
                target: {
                    type: "ApDatePicker",
                    name: this.props.name,
                    id: this.props.id,
                    value: value == 'clear'?"":value.target.value
                }
            };
            this.props.onChange(e);
            this.setState({ value: value == 'clear' ? "" : value.target.value});
        }
        if (value==null) {
            this.setState({ hideCalendar: true });
        }
    }
    
    datePicked( value )
    {
        let innerValue=moment(value).format( 'YYYY-MM-DD' );

        value = moment(value).format( 'DD.MM.YYYY' );

        this.setState({ hideCalendar: true, value:value, innerValue:innerValue });
        const e = {
            target: {
                type: "ApDatePicker",
                name: this.props.name,
                id: this.props.id,
                value: innerValue,
            }
        };
        this.props.onChange(e);
    }

    render()
    {
        let {
            size,
            color,
            fullWidth,
            ghost,
            narrow,
            loading,

            className,
            onClick,

            children,
            ...otherProps

        } = this.props;

        let classNames = ["ApDatePicker"];
        let inputElement;
        let value;
        
        
        let innerValue = this.props.value;
        if ( this.props.value=='' || this.props.value==null ) {
            value='';
            innerValue='';
        }
        else {   
            if  ( this.state.innerValue == this.props.value && this.state.focused ) {
                value=this.state.value;
            }
            else {
                if ( this.props.value == '' ) {
                    value = this.props.value 
                }
                else {
                    if (this.state.in_valid_date) {
                        value = this.props.value;
                    }
                    else {
                        value = this.props.value ? sqlToDateInput( this.props.value ) : '';
                    }
                }
                
            }
        }

        if( size ) classNames.push( "size-" + size );
        if( color ) classNames.push( "color-" + color );
        if( fullWidth ) classNames.push( "full-width");
        if( ghost ) classNames.push("ghost");
        if( narrow ) classNames.push("narrow");
        if( loading ) classNames.push("loading");

        if( className ) classNames.push(className);
        let calendar="";

        let valueCalendar;

        if (value=="Invalid date" || value==''  )
        {
            valueCalendar = new Date(  );
            value=this.props.value;
        }   
        else {
            valueCalendar = new Date(innerValue);
        }

        if (this.state.hideCalendar) {
            calendar="";
        }
        else {
            calendar=<div className="open-date-picker">
                <Calendar
                    format="dd.MM.yyyy"
                    locale={ currentLang() }
                    value= { valueCalendar }
                    onChange={ (e) =>  this.datePicked( e ) }
                    showWeekNumbers={true}
                />
            </div>;
        }

        inputElement = <div>
                <input
                    { ...otherProps }
                    type={ "input" }
                    id={ this.props.id }
                    name={ this.props.name }
                    disabled={ this.props.disabled }
                    onFocus={ () => this.calendarOnOpen() }
                    onBlur={ () => this.calendarOnClose() }
                    value={ value }
                    onChange={  (e) => this.changeValue(e) }
                    readOnly={window.innerWidth < 700 ? true : false}
                />              
                {calendar}  
                <SvgIcon className="calendarIcon" icon={ this.props.calendarIcon } />

                { this.props.clearable && value && !this.props.disabled &&
                        <SvgIcon className="clearIcon" icon="times-circle" type="solid" onClick={ () => this.changeValue('clear') } /> 
                }

            </div>

        return (
            <OutsideClickHandler clickOutsideHandler={() => {this.setState({hideCalendar: true})}}>
                {inputElement}
            </OutsideClickHandler>
        );
    }
};

ApDatePicker.propTypes = {
    name:               PropTypes.string.isRequired,
    id:                 PropTypes.string.isRequired,
    onChange:           PropTypes.func,
    size: PropTypes.string,
    color: PropTypes.string,
    ghost: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    narrow: PropTypes.bool,
    loading: PropTypes.bool,
    className: PropTypes.string,
    
};

export default ApDatePicker;

class OutsideClickHandler extends React.Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        autoBind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.clickOutsideHandler();
        }
    }

    render() {
        return <div ref={this.wrapperRef}>{this.props.children}</div>;
    }
}
