import React from 'react';
import autoBind from 'react-autobind';

import api from 'services/Api/Api.js';
import {apiurl} from 'services/Api/Api.js';

import ApModal from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import FileUpload from 'common/FileUpload/FileUpload.js';
import ApValidate from 'services/ApValidate/ApValidate.js';

import { ApInput
       , ApInputStack
       , ApAddon } from 'common/ApInput/ApInput.js';

import { errorPopper, tr } from 'services/Helpers/Helpers.js';

import { Collapse } from 'react-bootstrap';

import './SendOrder.css';
import ApDropdown from 'common/ApDropdown/ApDropdown';

class SendOrder extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            loading: false,

            sendFromSystem: false,
            sendManually: false,
            manualFileSelect: 'generated',
            manualFile: null,

            emailTo: '',
            subject: tr('purchase_order'),
            message: tr('po_attached'),
            comment: '',

            pdf_id: null,
            docx_id: null,

        }

        this.validator = new ApValidate( this, {
            "emailTo": { filter: 'email', state: 'warning', text: tr('incorrect_info')}
        }); 

        autoBind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show == false && this.props.show == true && (this.props.contactEmails && this.props.contactEmails.length > 0)) {
            this.setState({emailTo: this.props.contactEmails[0].email});
        }
    }

    save()
    {
        this.setState({ loading: true });

        let data = {};
        data.latest_comment = this.state.comment;
        data.manual = this.state.sendManually;
        data.emailTo = this.state.emailTo;
        data.subject = this.state.subject;
        data.message = this.state.message; 
        if( this.state.sendManually )
        {
            const select = this.state.manualFileSelect;
            if( select === 'generated' )
            {
                data.file_id = this.state.pdf_id;
                if( data.file_id === null )
                    data.file_id = 'generate'; // File is not yet generated but we will generate it
            }
            else if( select === 'other' )
                data.file_id = this.state.manualFile.id;
            else
                data.file_id = null;
        }
        this.props.onSubmit( data ).then( () => {
            this.setState({ loading: false, pdf_id: null, docx_id:null });
        }).catch( (error) => {
            this.setState({ loading: false });
        });
    }

    formIsValid()
    {
        if( this.state.sendManually )
        {
            const select = this.state.manualFileSelect;
            if( select === 'other' ) return Boolean( this.state.manualFile )
            if( select === 'generated' ) return true;
            if( select === 'none' ) return true;
            return false;
        }
        else
        {
            return true;
        }
    }

    toggleSendFromSystem()
    {
        this.setState({
            sendFromSystem: !this.state.sendFromSystem,
            sendManually: false,
        });
    }

    toggleSendManually()
    {
        this.setState({ sendManually: !this.state.sendManually });
    }

    fileUpload( file )
    {
        file = {
            name: file.original_name,
            id: file.id,
            extension: file.extension,
        };
        this.setState({ manualFile: file });
    }

    fileClear()
    {
        this.setState({ manualFile: null });
    }

    getFile( type )
    {
        const name = `${tr('purchase_order')}_${ this.props.number }.${ type }`;
        const field = `${ type }_id`;

        if( this.state[ `${ type }_id` ] )
        {
            this.downloadFile( this.state[ field ], name );
            return null;
        }

        this.fetchFileIds( () => {
            this.downloadFile( this.state[ field ], name );
        })
    }

    fetchFileIds( after )
    {
        if( typeof after !== 'function' ) after = () => {};
        this.setState({ loading: true });
        return api({
            method: 'get',
            url: `storage/po/id/${ this.props.id }/sendfiles`,
        }).then( ( response ) => {
            this.setState({
                pdf_id: response.pdf_id,
                docx_id: response.docx_id,
                loading: false,
            }, after );
        }).catch(( error ) => {
            errorPopper(error, tr('get_error'));
            this.setState({ loading: false });
        });
    }

    downloadFile( id, name )
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: `${ apiurl }file/id/${ id }`,
            responseType: 'blob',
        }).then(( response ) => {

            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.setState({ loading: false });

        }).catch( ( error ) => {
            errorPopper(error, tr('get_error'));
            this.setState({ loading: false });
        });
    }

    renderSendFromSystem()
    {
        let emailsDom = null;
        if (this.props.contactEmails && this.props.contactEmails.length > 0) {
            let actions = this.props.contactEmails.map(contact => {
                return {
                    label: <div>{contact.name}<br/>{contact.email}</div>,
                    icon: 'at',
                    action: ( item, closeFunc ) => {
                        this.setState({
                            emailTo: contact.email
                        });
                        closeFunc();
                    },
                }
            });
            emailsDom = <ApDropdown position="right"
                actions={ actions }
                button={ <div className='defaultButton'>
                    <SvgIcon icon="book" type="solid" /> 
                </div>}
            />
        }

        return <Collapse in={ this.state.sendFromSystem }>
            <div>
                <ApInputStack gap='0px'>
                    {emailsDom}
                    <ApInput
                        type="email"
                        id="email-target"
                        name="emailTo"
                        label={ tr('recipient_email') }
                        autoComplete="off"
                        value={ this.state.emailTo }
                        onChange={ ( e ) => { this.setState({ emailTo: e.target.value }) } }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }
                        validationState={ this.validator.getState('emailTo') }
                        width='100%'
                    />
                </ApInputStack>

                <ApInput
                    type="text"
                    id="email-subject"
                    name="subject"
                    label={ tr('message_header') }
                    autoComplete="off"
                    value={ this.state.subject }
                    onChange={ ( e ) => { this.setState({ subject: e.target.value }) } }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />

                <ApInput
                    type="textarea"
                    id="email-message"
                    name="message"
                    label={ tr('message_content') }
                    autoComplete="off"
                    rows="6"
                    value={ this.state.message }
                    onChange={ ( e ) => { this.setState({ message: e.target.value }) } }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />

                <div className="">
                    <span className="attachment-container">
                        <SvgIcon icon="paperclip" type="solid" />
                        { `${tr('purchase_order')}_pdf` }
                    </span>
                </div>
            </div>
        </Collapse>
    }

    renderFileSelect()
    {
        if( this.state.manualFileSelect !== 'other' ) return null;
        return <div className="fileSelectContainer">
            <ApInputStack gap="0">
                <ApAddon width="200px" noRightBorder>
                    { tr('sent_file') }
                </ApAddon>
                <FileUpload
                    onUpload={this.fileUpload }
                    description="PoSentFile"
                    accept="*"
                    label={ `${tr('choose_file')}...` }
                    value={ this.state.manualFile }
                    onClear={ this.fileClear }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </ApInputStack>
        </div>
    }

    renderSentManually()
    {
        if( this.state.sendFromSystem ) return null;
        return <div>
            <div className="apSwitchBlock">
                <label htmlFor="send-manually-switch" className="info">
                    { tr('sent_po_manually') }
                    <small>{ tr('sent_po_manually_info') }</small>
                </label>
                <ApSwitch
                    id="send-manually-switch"
                    on={ this.state.sendManually }
                    onChange={ this.toggleSendManually }
                    disabled={ this.state.loading }
                />
            </div>
            { this.renderManualSelect() }
        </div>
    }

    renderManualSelect()
    {
        if( !this.state.sendManually ) return null;
        const options = [
            { value: 'generated', label: tr('sent_system_pdf') },
            { value: 'other', label: tr('sent_other_file') },
            { value: 'none', label: tr('skip_file_selection') },
        ]
        return <div>
         <ApInputStack gap="0">
                <ApAddon
                    noRightBorder width="200px"
                >
                    { tr('po_file') }
                </ApAddon>
                <ApInput
                    type="select"
                    options={ options }
                    id={ 'manual-file-select' }
                    name={ 'manual-file-select' }

                    value={ this.state.manualFileSelect }
                    onChange={ ( e ) =>  this.setState({ manualFileSelect: e.target.value } ) }
                    autoComplete="off"
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />
            </ApInputStack>
            { this.renderFileSelect() }
        </div>
    }

    renderSendButton()
    {
        let icon = 'envelope-open-text';
        let title = tr('send');
        let disabled = true;

        if( this.state.sendManually )
        {
            icon = 'envelope';
            title = tr('sent');
            disabled = false;
        }
        else
        {
            disabled = (this.validator.getState('emailTo') !== null) || !this.state.sendFromSystem;
        }

        return <ApButton className="save-button"
            color="green"
            onClick={ this.save }
            loading={ this.state.loading }
            disabled={ this.state.loading || disabled }
        >
            <SvgIcon icon={ icon } type="solid" />
            { title } 
        </ApButton>
    }

    render()
    {
        return <div id="sendPo">
            <ApModal
                show={ this.props.show }
                handleClose={ this.props.onClose }
                className="narrow"
                onOpen={ this.resetState }
                header={
                    <div className="padding-small">
                        <h4>
                            { tr('send_po_to_supplier') }
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <ApInput
                            type="textarea"
                            id="comment-to-log"
                            name="comment"
                            label={ tr('comment_to_event_log') }
                            autoComplete="off"
                            value={ this.state.comment }
                            onChange={ ( e ) => { this.setState({ comment: e.target.value }) } }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />

                        <ApButton className="pdf-button"
                            color="white"
                            onClick={ ()  => this.getFile( 'pdf' ) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        >
                            <SvgIcon icon="file-pdf" type="solid" />
                            { `${tr('download')} ${tr('purchase_order')}.pdf` }
                        </ApButton>
                        <ApButton className="docx-button"
                            color="white"
                            onClick={ ()  => this.getFile( 'docx' ) }
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        >
                            <SvgIcon icon="file-word" type="solid" />
                            { `${tr('download')} ${tr('purchase_order')}.docx` }
                        </ApButton>



                        <div className="apSwitchBlock">
                            <label htmlFor="send-from-system-switch" className="info">
                                { tr('send_po_from_system') }
                                <small>{ tr('send_po_from_system_info') }</small>
                            </label>
                            <ApSwitch
                                id="send-from-system-switch"
                                on={ this.state.sendFromSystem }
                                onChange={ this.toggleSendFromSystem }
                            />
                        </div>

                        { this.renderSentManually() }
                        { this.renderSendFromSystem() }
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton
                            onClick={ () => { this.props.onClose() }}
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        >
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        { this.renderSendButton() }
                    </div>
                }
            />
        </div>
    }
}

export default SendOrder;
