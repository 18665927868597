import React from 'react';

import autobind from 'react-autobind';
import { formatCurrency, tr } from 'services/Helpers/Helpers';

class Totals extends React.Component {
    constructor(props) {
        super(props);

        autobind(this);
    }

    render() {
        return (
            <div className='padding' style={{display: 'flex', justifyContent: 'space-between'}}>
                <h4>{tr('revenue')}: {formatCurrency(this.props.orders_in_total)} </h4>
                <h4>{tr('expenses_2')}: {formatCurrency(this.props.po_in_total)} </h4>
                <h4>{tr('in_total')}: {formatCurrency(this.props.in_total)} </h4>
            </div>
        )
    }
}

export default Totals;
