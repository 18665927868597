import React from 'react';

import api, {apiurl} from 'services/Api/Api.js';
import ApButton from 'common/ApButton/ApButton';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import SvgIcon from 'common/SvgIcon/SvgIcon';

import { dateInputToSql, errorPopper, keyExists, sqlToDateInput, tr } from 'services/Helpers/Helpers';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar';

import styles from './ExtAssignments.module.css';
import { Collapse } from 'react-bootstrap';
import ApSelect from 'common/ApSelect/ApSelect';
import moment from 'moment';
import { connect } from 'react-redux';
import ApModal from 'common/ApModal/ApModal';
import File from 'common/File/File';

let timer = null;

class ExtAssignmentPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            assignment: {},
            project: null,
            selectedProjectNewSubproject: false,
            settings: [],
            changed: false,

            foundCustomer: null,
            linkToExistingCustomer: false,
            createNewProject: false,
            createNewSubproject: false,
        }
    }

    componentDidMount() {
        this.getAssignment();
    }

    getAssignment() {
        this.setState({loading: true});
        api({
            method: 'get',
            url: 'assignment/extassignments/get/' + this.props.match.params.id
        }).then(response => {
            // console.log('res', response);
            const assignment = response.assignment;
            if (assignment.customer_billing_method_id) {
                const billingMethod = response.billing_methods[assignment.customer_billing_method_id];
                if (billingMethod) {
                    assignment.assignment_description 
                        ? assignment.assignment_description += `\n${tr('preferred_billing_method')}: ${tr(billingMethod)}`
                        : assignment.assignment_description = `${tr('preferred_billing_method')}: ${tr(billingMethod)}`;
                }
            }
            this.setState({
                ...response,
                assignment,
                project: response.assignment.project,
                loading: false,
                linkToExistingCustomer: !!response.assignment.crm_company_id
            });
        }).catch(err => {
            errorPopper(err, 'error');
            console.error(err);
            this.setState({loading: false});
        })
    }

    handleChange = (e, callback = null) => {
        const data = {...this.state.assignment};
        const changedItem = e.target.name;
        let value = e.target.value;
        
        if (changedItem === 'assignment_begin_at' || changedItem === 'assignment_end_at') {
            value = dateInputToSql(value, true);
        }

        this.setState({
            assignment: {
                ...data,
                [changedItem]: value
            },
            changed: true
        }, callback);
    }

    handleCustomerChange = (e) => {
        this.handleChange(e, () => {
            clearTimeout(timer);
            timer = setTimeout( () => api({
                method: 'post',
                url: 'crm/company/find/existing',
                data: {
                    name: this.state.assignment.customer_name,
                    business_id: this.state.assignment.customer_business_id
                }
            }).then(response => {
                this.setState({foundCustomer: response});
                // console.log('res', response);
            }).catch(error => {
                console.error(error);
            }), 1000);
        })
    }

    handleApproval(approval) {
        this.setState({loading: true});
        api({
            method: 'post',
            url: 'assignment/extassignments/approval',
            data: {
                approval,
                assignment: this.state.assignment,
                project: this.state.project,
                linkToExistingCustomer: this.state.linkToExistingCustomer,
                createNewProject: this.state.createNewProject,
                createNewSubproject: this.state.createNewSubproject,
                selectedProjectNewSubproject: this.state.selectedProjectNewSubproject,
                user: this.state.user,
            }
        }).then(response => {
            // console.log('res', response);
            this.setState({
                loading: false,
                // assignment: response
                assignment: {
                    ...this.state.assignment,
                    status: response.status,
                    approve_date: response.approve_date,
                    approved_assignment_id: response.approved_assignment_id
                }
            });
        }).catch(err => {
            errorPopper(err, 'error');
            console.error(err);
            this.setState({loading: false});
        })
    }

    renderFilesList() {
        const filesList = this.state.assignment.files.map( ( i ) => {
            return <div key={ i.id } className="files listStack">
                <ApInputStack gap="0">
                        <ApAddon>
                            <div
                                onClick={ () => {this.setState({
                                    selectedImage: i,
                                })}}
                                className="pointer"
                            >
                                <SvgIcon 
                                    className="showImageBtn pointer"
                                    icon="file" 
                                    type="solid"
                                />
                                { i.original_name }
                            </div>
                        </ApAddon>
                </ApInputStack>
            </div>
        });
        return filesList;
    }

    renderImageModal() {
        return (
            <ApModal 
                show={!!this.state.selectedImage}
                onClose={() => this.setState({selectedImage: null})}
                handleClose={() => this.setState({selectedImage: null})}
                closeFromBg
                className="narrow"
                header={ 
                    <div className="padding">
                        <h3>{ tr('file') }</h3>
                    </div>
                }
                body={
                    <div className="padding">
                        {this.state.selectedImage && <File
                            fileId={this.state.selectedImage.id} 
                            name={this.state.selectedImage.original_name} 
                            type={this.state.selectedImage.extension.toLowerCase() === 'pdf' ? 'pdf' : 'image'}
                            style={{ width: "100%", height: "300px", objectFit: "contain" }} 
                        />}
                    </div>
                }
                footer={
                    <div className="padding">
                        <ApButton 
                            onClick={() => {this.setState({selectedImage: null})}} 
                            className="cancel"
                        >
                            { tr('cancel') }
                        </ApButton>
                        <ApButton 
                            onClick={() => this.downloadFile(this.state.selectedImage.id, this.state.selectedImage.original_name)}
                            style={{float: "right"}} 
                            className="applyButton" 
                            color="green"
                        >
                            <SvgIcon icon="file" type="solid" />
                            { tr('download') }
                        </ApButton>
                    </div>
                }
            />
        )
    }

    downloadFile(id, name)
	{
		return api({
			method: 'get',
			url: `${ apiurl }file/id/${ id }`,
			responseType: 'blob',
		}).then(( response ) => {
			const url = window.URL.createObjectURL( new Blob([ response ]) );
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', name );
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			this.setState({ loading: false, showImageModal: false });
		}).catch( ( error ) => {
			errorPopper(error, tr('file_download_error'));
			this.setState({ loading: false, showImageModal: false });
		});
	}

    render() {
        let statusEl = null;
        if (this.state.assignment.status && this.state.assignment.status != 'pending') {
            let color = '';
            if (this.state.assignment.status == 'approved') {
                color = '#4caf50';
            } else if (this.state.assignment.status == 'rejected') {
                color = '#ff4444';
            }
            const status = this.state.assignment.status;
            statusEl = (
                <p style={{color}}>
                    {tr(status)} {moment(this.state.assignment.approve_date).format('L')}
                    {this.state.assignment.status == 'approved' && <p style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>window.emitter.emit('goTo', {pathname: '/assignments/' + this.state.assignment.approved_assignment_id + "/" + 2})}>{tr('go_to_assignment')}</p>}
                </p>
            )
        }
        const autoProjectSettingsSet = !!this.props.apProjectSettings &&
            !!this.props.apProjectSettings.automatic_project_enabled &&
            !!this.props.apProjectSettings.automatic_project_type &&
            !!this.props.apProjectSettings.automatic_project_work;
        return (
            <div id='extassignment' className='apBox'>
                <div className="pageBackButton" onClick={ () => this.props.history.push( '/assignments/4' ) }>
                    <SvgIcon icon="arrow-left" type="solid" size="small" />
                    { tr('back') }
                </div>
                <div className='apBoxHeader'>
                    <h1>{tr('ext_assignment_header')}</h1>
                    <p>{tr('ext_assignment_info')}</p>
                </div>
                <div className='padding clear'>
                    <h2>{tr('customer')}</h2>
                    <div className='apColumn'>
                        <div className='apColumn w50 padding'>
                            <ApInput
                                type='text'
                                id='customer_name'
                                name='customer_name'
                                label={tr('name')}
                                value={this.state.assignment && this.state.assignment.customer_name}
                                onChange={this.handleCustomerChange}
                                disabled={this.state.loading || this.state.linkToExistingCustomer || this.state.assignment.crm_company_id}
                                loading={this.state.loading}
                            />
                            <ApInput
                                type='text'
                                id='customer_business_id'
                                name='customer_business_id'
                                label={tr('business_id')}
                                value={this.state.assignment && this.state.assignment.customer_business_id}
                                onChange={this.handleCustomerChange}
                                disabled={this.state.loading || this.state.linkToExistingCustomer || this.state.assignment.crm_company_id}
                                loading={this.state.loading}
                            />
                            {this.state.foundCustomer &&
                            <div className='apSwitchBlock'>
                                <ApSwitch 
                                    id='linkToExistingCustomer-switch'
                                    on={this.state.assignment.crm_company_id ? true : this.state.linkToExistingCustomer}
                                    onChange={() => this.setState({linkToExistingCustomer: !this.state.linkToExistingCustomer})}
                                    disabled={this.state.loading || !!this.state.assignment.crm_company_id}
                                    loading={this.state.loading}
                                />
                                <label htmlFor='linkToExistingCustomer-switch' style={{color: '#ff8800'}}>
                                    {tr('link_to_existing_customer')}<br />
                                    <small>{this.state.foundCustomer.name} {this.state.foundCustomer.taxnumber && `- ${this.state.foundCustomer.taxnumber}`}</small>
                                </label>
                            </div>
                            }
                        </div>

                        <div className='apColumn w50 padding'>
                            <ApInput
                                type='text'
                                id='customer_phone'
                                name='customer_phone'
                                label={tr('phone')}
                                value={this.state.assignment.customer_phone}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                            <ApInput
                                type='text'
                                id='customer_email'
                                name='customer_email'
                                label={tr('email')}
                                value={this.state.assignment.customer_email}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                            <ApInput
                                type='text'
                                id='customer_website'
                                name='customer_website'
                                label={tr('website')}
                                value={this.state.assignment.customer_website}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                        </div>
                    </div> {/* customer info end */}

                    <h2>{tr('billing_address')}</h2>
                    <div className='apColumn'>
                        <div className='apColumn w50 padding'>
                            <ApInput
                                type='text'
                                id='customer_billing_street'
                                name='customer_billing_street'
                                label={tr('street_address')}
                                value={this.state.assignment && this.state.assignment.customer_billing_street}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                            <ApInput
                                type='text'
                                id='customer_billing_postal_code'
                                name='customer_billing_postal_code'
                                label={tr('postal_code')}
                                value={this.state.assignment && this.state.assignment.customer_billing_postal_code}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                        </div>
                        <div className='apColumn w50 padding'>
                            <ApInput
                                type='text'
                                id='customer_billing_city'
                                name='customer_billing_city'
                                label={tr('locality')}
                                value={this.state.assignment && this.state.assignment.customer_billing_city}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                            <ApInput
                                type='text'
                                id='customer_billing_country'
                                name='customer_billing_country'
                                label={tr('country')}
                                value={this.state.assignment && this.state.assignment.customer_billing_country}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                        </div>
                    </div> {/* customer billing address end */}

                    <h2>{tr('assignment')}</h2>
                    <div className='apColumn'>
                        <div className='apColumn w50 padding'>
                            <ApInput
                                type='text'
                                id='assignment_name'
                                name='assignment_name'
                                label={tr('name')}
                                value={this.state.assignment && this.state.assignment.assignment_name}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                            <ApInput
                                type="textarea"
                                rows="4"
                                id="assignment_description"
                                name="assignment_description"
                                label={ tr('description') }
                                value={this.state.assignment && this.state.assignment.assignment_description}
                                onChange={ this.handleChange }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                            />
                            <ApSelect
                                label={ tr('assignment_worker') }
                                value={ this.state.user }
                                optionRenderer="user"
                                onChange={ ( user ) => this.setState({user}) }
                                objKeyId="id"
                                objKeyValue="name"
                                clearable
                                apiUrl="project/find/manager"
                                apiData= {{withGroups: true}}
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                                filterNonActives={true}
                            />
                            <ApInput
                                type="datetime"
                                id="assignment_begin_at"
                                name="assignment_begin_at"
                                label={ tr('start_date') }
                                value={ this.state.assignment.assignment_begin_at ? sqlToDateInput( this.state.assignment.assignment_begin_at, true ) : '' }
                                onChange={ (e) => this.handleChange( e ) }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
                            />
                            <ApInput
			                    type="datetime"
			                    id="assignment_end_at"
			                    name="assignment_end_at"
			                    label={ tr('completion_date') }
			                    value={ this.state.assignment.assignment_end_at ? sqlToDateInput( this.state.assignment.assignment_end_at, true ) : '' }
			                	onChange={ (e) => this.handleChange( e ) }
                                loading={ this.state.loading }
                                disabled={ this.state.loading }
			                />
                        </div>

                        <div className='apColumn w50 padding'>
                            <ApInput
                                type='text'
                                id='assignment_street'
                                name='assignment_street'
                                label={tr('street_address')}
                                value={this.state.assignment.assignment_street}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                            <ApInput
                                type='text'
                                id='assignment_postal_code'
                                name='assignment_postal_code'
                                label={tr('postal_code')}
                                value={this.state.assignment.assignment_postal_code}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                            <ApInput
                                type='text'
                                id='assignment_city'
                                name='assignment_city'
                                label={tr('locality')}
                                value={this.state.assignment.assignment_city}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                            <ApInput
                                type='text'
                                id='assignment_country'
                                name='assignment_country'
                                label={tr('country')}
                                value={this.state.assignment.assignment_country}
                                onChange={this.handleChange}
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            />
                        </div>
                    </div> {/* assignment info end */}
                    <h2>{tr('project')}</h2>
                    <div className='apColumn'>
                        <div className='apColumn w50 padding'>
                            {autoProjectSettingsSet &&
                            <div className='apSwitchBlock'>
                                <ApSwitch 
                                    id='createNewProject-switch'
                                    on={this.state.createNewProject}
                                    onChange={() => this.setState({createNewProject: !this.state.createNewProject})}
                                    disabled={this.state.loading}
                                    loading={this.state.loading}
                                />
                                <label htmlFor='createNewProject-switch'>{tr('add_new_project')}</label>
                            </div>}
                            <Collapse in={this.state.createNewProject}>
                                <div>
                                    <ApInput
                                        type='text'
                                        id='project_name'
                                        name='project_name'
                                        label={tr('project_name')}
                                        value={this.state.assignment.project_name}
                                        onChange={this.handleChange}
                                        disabled={this.state.loading}
                                        loading={this.state.loading}
                                    />
                                    {this.props.apProjectSettings.automatic_project_create_subproject &&
                                    <ApInput
                                        type='text'
                                        id='subproject_name'
                                        name='subproject_name'
                                        label={tr('subproject_name')}
                                        value={this.state.assignment.subproject_name}
                                        onChange={this.handleChange}
                                        disabled={this.state.loading}
                                        loading={this.state.loading}
                                    /> }
                                </div>
                            </Collapse>

                            <Collapse in={!this.state.createNewProject}>
                                <div>
                                    <ApSelect
                                        label={ tr('project') }
                                        loading={ this.state.loading }
                                        value={ keyExists( this.state.project, "name", true, '' ) }
                                        optionRenderer="project"
                                        onChange={ ( c ) => this.setState( {project: c} ) }
                                        objKeyId="id"
                                        clearable
                                        apiUrl="report/projects/find"
                                        apiData={{
                                            formatter: 'select',
                                            withParents : 'true',
                                            customer: this.state.assignment.crm_company_id?this.state.assignment.crm_company_id:null,
                                            withOffers: true,
                                            withoutClosed: true,
                                        }}
                                    />
                                    {this.state.project && autoProjectSettingsSet && this.props.apProjectSettings.automatic_project_create_subproject &&
                                    <div className='apSwitchBlock'>
                                        <ApSwitch 
                                            id='selectedProjectNewSubproject-switch'
                                            on={this.state.selectedProjectNewSubproject}
                                            onChange={() => this.setState({selectedProjectNewSubproject: !this.state.selectedProjectNewSubproject})}
                                            disabled={this.state.loading}
                                            loading={this.state.loading}
                                        />
                                        <label htmlFor='selectedProjectNewSubproject-switch'>{tr('new_subproject')}</label>
                                    </div>}
                                    <Collapse in={this.state.selectedProjectNewSubproject}>
                                        <div>
                                            <ApInput
                                                type='text'
                                                id='subproject_name'
                                                name='subproject_name'
                                                label={tr('subproject_name')}
                                                value={this.state.assignment.subproject_name}
                                                onChange={this.handleChange}
                                                disabled={this.state.loading}
                                                loading={this.state.loading}
                                            />
                                        </div>
                                    </Collapse>
                                </div>
                            </Collapse>
                        </div>
                    </div> {/* project div end */}
                    <h2>{tr('files')}</h2>
                    <div className='apColumn w50 padding'>
                        {this.state.assignment.files && this.state.assignment.files.length > 0 && 
                        <div>
                            {this.renderFilesList()}
                        </div>}
                    </div>
                </div> {/* main content div end */}
                <ApStickyBar zIndex={ 10 } bottomMode ref={ node => this.stickyBar = node }>
                    <div className={`${styles.saveBar} padding`}>
                        <div className="right">
                            {statusEl}
                            <ApButton 
                                color="red"
                                onClick={ () => this.handleApproval('reject') }
                                disabled={this.state.loading || this.state.assignment.status != 'pending'}
                            >
                                <SvgIcon icon="ban" type="solid" />
                                {tr('reject')}
                            </ApButton>

                            <ApButton 
                                color="green"
                                onClick={ () => this.handleApproval('approve') }
                                disabled={this.state.loading || this.state.assignment.status != 'pending'}
                                style={{marginLeft: '1em'}}
                            >
                                <SvgIcon icon="check" type="solid" />
                                {tr('accept')}
                            </ApButton>
                        </div>
                    </div>
                </ApStickyBar>
                {this.renderImageModal()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
	apProjectSettings: state.apProjectSettings
});

export default connect(mapStateToProps)(ExtAssignmentPage);
