import React, { useState, useEffect } from 'react'

import api from 'services/Api/Api.js';
import ApList from 'common/ApList/ApList'
import SvgIcon from 'common/SvgIcon/SvgIcon'
import { tr } from 'services/Helpers/Helpers.js';

const LayoutSettings = ({stateLoading, handleLayoutChange, handleLoading, setBlocks, blocks}) => {

    useEffect(() => {
        getBlocks()
    }, [])

    const getBlocks = async () => {
        handleLoading(true)

        try {
            const response = await api({
                method: 'get',
                url: 'currentUser/blocks',
            })

           // console.log('user blocks: layout setting', response );

            const filtered = response.filter((block) => {
                if (block.id !== 'hr.overview') {
                    return block
                }
            })

            setBlocks(filtered)
        } catch (error) {
            console.log( error )
            handleLoading(false)
        }
    }

    const renderItem = ( item ) => {

        //by default, overview is locked to top and ordering is not currently in use
        /*
        if (item.id === 'hr.overview') {
            return (
                <div>
                    <SvgIcon icon='address-card' type='solid' />
                    <strong className='layout-left-margin'>Yleiskatsaus</strong><br />
                </div>
            )
        }
        */
        
        return (
            <div>
                <SvgIcon icon={item.icon} type='solid' />
                <strong className='layout-left-margin'>{ tr(item.header) }</strong><br />
            </div>
        );
    }

    const handleChange = (items) => {
        setBlocks(items)
        handleLayoutChange(true)
    }

    return (
        <div className="apBox" id="pageCompanySettings">
            <div className="apBoxHeader">
                <h1>{ tr('layout_settings') }</h1>
            </div>

            <div className="padding">
                <h4>{ tr('layout_settings_info1') }</h4>
                <p>{ tr('layout_settings_info2') }</p>
                <ApList 
                    loading={ stateLoading }
                    items={ blocks }
                    itemRenderer={ renderItem }
                    onSort={ ( items ) => handleChange(items) }
                    sortable
                />

            </div>
        </div>
    )
}

export default LayoutSettings