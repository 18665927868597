import ApButton from 'common/ApButton/ApButton';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';
import ApModal from 'common/ApModal/ApModal';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import React from 'react';
import autobind from 'react-autobind';
import api from 'services/Api/Api';
import { capitalize, errorPopper, mapTree, tr } from 'services/Helpers/Helpers';
import _ from 'lodash';
import "./EditProduct.css";
import ApDropdown from 'common/ApDropdown/ApDropdown';
import ApConfirm from 'common/ApConfirm/ApConfirm';

const statusOptions = [
    {value: 'publish', label: tr('active')},
    {value: 'private', label: tr('hidden')},
]
class EditProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {},
            categories: [],

            changes: false,
            showDeleteConfirm: false,
        }
        autobind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.show && !!this.props.show) {
            if (this.props.selectedProduct) {
                this.getData();
            }
        }
    }

    getData() {
        if (!this.props.selectedProduct) return;

        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'woocommerce/products/' + this.props.selectedProduct + '/edit',
        }).then((response) => {
            // console.log('RES', response);
            const data = typeof response[0] === 'object' ? response[0] : {};
            const categories = Array.isArray(response[1]) ? response[1] : [];
            this.setState({ loading: false, data, categories })
        }).catch((error) => {
            this.setState({ loading: false })

            errorPopper(error, tr('get_error'));
        })
    }

    saveData() {
        if (!this.props.selectedProduct) return;

        const data = {
            name: this.state.data.name,

            regular_price: String(this.state.data.regular_price),
            sale_price: String(this.state.data.sale_price),
            date_on_sale_from: this.state.data.date_on_sale_from,
            date_on_sale_to: this.state.data.date_on_sale_to,

            categories: this.state.data.categories,

            stock_quantity: this.state.data.stock_quantity,
            manage_stock: true,
            status: this.state.data.status,

            description: this.state.data.description,
            short_description: this.state.data.short_description,
        };

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'woocommerce/products/' + this.props.selectedProduct + '/edit',
            data
        }).then((response) => {
            // console.log('RES', response);
            this.setState({ loading: false });
            this.onClose(true);
        }).catch((error) => {
            this.setState({ loading: false })

            errorPopper(error, tr('get_error'));
        })
    }

    deleteProduct() {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'woocommerce/products/' + this.props.selectedProduct + '/delete',
        }).then((response) => {
            // console.log('RES', response);
            this.setState({ loading: false });
            this.onClose(true);
        }).catch((error) => {
            this.setState({ loading: false })

            errorPopper(error, tr('get_error'));
        })
    }

    toTree(list) {
        try {
            const arrayToTree = (arr, parent = 0) =>
                arr.filter(item => item.parent == parent)
                    .map(child => ({
                        ...child, children: arrayToTree(arr,
                            child.id)
                    }));
            return arrayToTree(list);

        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Remove html tags and html entities from string
     */
    handleDescription(value = "") {
        var txt = document.createElement("textarea");
        txt.innerHTML = value;
        return txt.value.replace( /(<([^>]+)>)/ig, '');
    }

    renderHeader() {
        return <div className=''>
            <h4 style={{ padding: '10px' }}>
                <SvgIcon type="solid" icon='puzzle-piece' /> {this.state.data.name}
            </h4>
            <div className='mainStatus'>
                <div className={`apStatusBox ${this.state.data.easypro_stock ? 'success' : 'warning'}`}>
                    {this.state.data.easypro_stock ? tr('active') : tr('storage_component_no_link')}
                </div>
            </div>
        </div>
    }

    renderBody() {
        let body = '';

        const productInfo = <div style={{ flex: '1' }} className='padding'>
            <h4 className='formHeader'>{tr('product_info')}</h4>
            <ApInput
                type="text"
                id="name"
                name="name"
                label={tr('name')}
                value={this.state.data.name}
                onChange={e => this.handleChange(e)}
                loading={this.state.loading}
                disabled={this.state.loading}
            />
            
            {(this.state.data.easypro_stock && this.state.data.easypro_stock.component.name != this.state.data.name) 
            && 
                <div className="apInfo small">
                    <SvgIcon icon="info-circle" type="solid" />
                    {tr('name_in_easypro')}: {this.state.data.easypro_stock.component.name}
                </div>
            }

            <ApInput
                type="number"
                id="regular_price"
                name="regular_price"
                label={tr('selling_price')}
                value={this.state.data.regular_price}
                onChange={e => this.handleChange(e)}
                loading={this.state.loading}
                disabled={this.state.loading}
            />

            <ApInput
                type="number"
                id="sale_price"
                name="sale_price"
                label={tr('discounted_price')}
                value={this.state.data.sale_price}
                onChange={e => this.handleChange(e)}
                loading={this.state.loading}
                disabled={this.state.loading}
            />

            <ApInputStack gap='5'>
                <ApInput
                    type="datetimeV2"
                    id="date_on_sale_from"
                    name="date_on_sale_from"
                    label={capitalize(tr('begins'))}
                    value={this.state.data.date_on_sale_from}
                    onChange={(value) => this.handleDateChange(value, 'date_on_sale_from')}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    clearable
                />
                <ApInput
                    type="datetimeV2"
                    id="date_on_sale_to"
                    name="date_on_sale_to"
                    label={capitalize(tr('ends'))}
                    value={this.state.data.date_on_sale_to}
                    onChange={(value) => this.handleDateChange(value, 'date_on_sale_to')}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    clearable
                />
            </ApInputStack>
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                {tr('sale_schedule')}
            </div>

            <ApInput
                type="number"
                id="stock_quantity"
                name="stock_quantity"
                label={tr('storage_balance')}
                value={this.state.data.stock_quantity}
                onChange={e => this.handleChange(e)}
                loading={this.state.loading}
                disabled={this.state.loading}
                validationState={String(this.state.data.stock_quantity).includes('.') ? 'error' : ''}
            />
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                {tr('no_decimal_warning')}
            </div>
            
            <ApInput
                type="textarea"
                id="description"
                name="description"
                label={tr('description')}
                value={this.handleDescription(this.state.data.description)}
                onChange={e => this.handleChange(e)}
                loading={this.state.loading}
                disabled={this.state.loading}
            />
            
            <ApInput
                type="textarea"
                id="short_description"
                name="short_description"
                label={tr('short_description')}
                value={this.handleDescription(this.state.data.short_description)}
                onChange={e => this.handleChange(e)}
                loading={this.state.loading}
                disabled={this.state.loading}
            />

            <ApInputStack gap='0'>
                <ApAddon>
                    {tr('status')}
                </ApAddon>
                <ApInput
                    type="select"
                    options={statusOptions}
                    id="status"
                    name="status"
                    label={tr('status')}
                    value={this.state.data.status}
                    onChange={e => this.handleChange(e)}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                />
            </ApInputStack>
            
        </div>

        const categoryTree = this.toTree(this.state.categories);
        const categoryToggles = [];
        let first = true;
        mapTree(categoryTree, (item, parent) => {
            if (parent) {
                item.level = parent.level + 1;
            } else {
                item.level = 0;
            }
            categoryToggles.push(
                <div key={item.id} style={{ marginTop: !first && !parent ? '10px' : "0" }}>
                    <ApInputStack key={item.id}>
                        <ApAddon labelFor={`category-${item.id}`}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <span>{'- '.repeat(item.level)} {item.name}</span>
                                <ApSwitch
                                    id={`category-${item.id}`}
                                    on={this.state.data.categories && Boolean(this.state.data.categories.find(c => c.id === item.id))}
                                    onChange={() => this.handleCategoryToggle(item.id)}
                                />
                            </div>
                        </ApAddon>
                    </ApInputStack>
                </div>
            );
            first = false;
            return item;
        })

        const categoriesEl = <div style={{ flex: '1' }} className='padding'>
            <h4 className='formHeader'>{tr('categories')}</h4>
            {categoryToggles}
        </div>

        body = <div className='modal-content-container'>
            {productInfo}
            {categoriesEl}
        </div>

        return <div>
            {body}
        </div>
    }

    renderFooter() {
        return <div className='padding justify-space-between'>
            <ApButton onClick={() => this.onClose()}>
                <SvgIcon type="solid" icon="times" /> {tr('back')}
            </ApButton>

            <div>
                <ApButton onClick={this.saveData} color="blue" disabled={!this.state.changes || this.state.loading} className={this.state.changes ? "highlight" : ""}>
                    <SvgIcon type="solid" icon="save" /> {tr('save')}
                </ApButton>
                <ApDropdown
                    position="top"
                    actions={[
                        {
                            label: tr('delete'),
                            icon: "trash",
                            action: (item, closeFunc) => {
                                this.setState({ showDeleteConfirm: true });
                                closeFunc();
                            },
                        },
                    ]}
                    button={ <ApButton className="footerMenu" color="white" style={{paddingLeft: '10px', paddingRight: '10px'}}>
                        <SvgIcon icon="ellipsis-h" type="solid" />
                    </ApButton> }
                />
            </div>
        </div>
    }

    handleChange(event) {
        const { value, name } = event.target;
        const data = _.cloneDeep(this.state.data);

        data[name] = value;
        this.setState({ data, changes: true })
    }

    handleDateChange(value, key) {
        const data = _.cloneDeep(this.state.data);
        data[key] = value;
        this.setState({ data, changes: true });
    }

    handleCategoryToggle(categoryId) {
        const data = _.cloneDeep(this.state.data);

        const belongsToCategory = data.categories.find(c => c.id == categoryId);
        if (belongsToCategory) {
            data.categories = data.categories.filter(c => c.id != categoryId);
        } else {
            const foundCategory = this.state.categories.find(c => c.id == categoryId)
            data.categories.push({
                id: foundCategory.id,
                name: foundCategory.name,
                slug: foundCategory.slug
            });
        }
        this.setState({ data, changes: true });
    }

    onClose(refresh = false) {
        this.setState({ data: {}, categories: [], changes: false });
        if (typeof this.props.onClose === 'function') {
            this.props.onClose(refresh);
        }
    }

    render() {
        return (
            <div id='woocommerce-edit-product'>
                <ApModal
                    onClose={this.onClose}
                    show={!!this.props.show}
                    handleClose={this.onClose}
                    header={this.renderHeader()}
                    body={this.renderBody()}
                    footer={this.renderFooter()}
                />
                <ApConfirm
                    show={this.state.showDeleteConfirm}
                    onClose={() => { this.setState({ showDeleteConfirm: false }) }}
                    onConfirm={this.deleteProduct}
                    header={tr('delete_product_online_store')}
                    body={tr('delete_product_online_store_confirm')}
                />
            </div>
        )
    }
}

export default EditProduct;