import React from 'react';
import { ApTabs, 
    ApTab } from 'common/ApTabs/ApTabs.js';

import './CustomerSettings.css';
import 'main.css';
import { tr } from 'services/Helpers/Helpers.js';

import GroupsSettings from 'modules/Crm/Groups/GroupsSettings.js'
import UsersSettings from 'modules/UserManagement/UsersSettings.js'
import GeneralCustomerSettings from './GeneralCustomerSettings'

export default class CrmSettings extends React.Component {

    render() 
    {
        return (

                <div className="apBox">
                    <div className="apBoxHeader">
                        <h1>{ tr('customer_settings') }</h1>
                        <p>{ tr('customer_settings_info') }</p>
                    </div>

                    <ApTabs>
                        {/*<ApTab icon="tags" label={ tr('customer_groups') }>
                            <div className="padding">
                                    <GroupsSettings />
                            </div>
                        </ApTab>*/}
                        <ApTab icon="user-friends" label={ tr('customer_managers') }>
                            <div className="padding">
                                    <UsersSettings />
                            </div>
                        </ApTab>
                        <ApTab icon="address-book" label={ tr('general_settings') }>
                            <div className="padding">
                                    <GeneralCustomerSettings />
                            </div>
                        </ApTab>
                    </ApTabs>
                </div>

        );
    }
}
