import React from 'react';
import autoBind from 'react-autobind';

import ApButton         from 'common/ApButton/ApButton.js';
import ApReactTable     from 'common/ApReactTable/ApReactTable.js';
import SvgIcon          from 'common/SvgIcon/SvgIcon.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import { tr } from 'services/Helpers/Helpers.js';
import './OfferDecline.css';
import NewOfferDecline from './NewOfferDecline';
import api                from 'services/Api/Api.js';



class OfferDecline extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            pages: -1,
            data: [],
            columns: [],
            customColumns: [],
            decline_reason: [],
            id: [],

            declineEdit: {},
            
            removeConfirmShow: false,
            removeIndexes: [],

            newDeclineReasonShow: false,

        }
        this.state.columns = this.getColumns();

        autoBind(this);
    }
    
    componentDidMount()
    {
    	console.log(this.rows);
    	console.log(this.props.rows);
        this.getColumns();
    }

    editRowClose( )
    {
        this.setState({ newDeclineReasonShow: false });
    }


    confirmDeleteDeclineReason( indexes )
    {
        this.setState({
            removeIndexes: indexes,
            removeConfirmShow: true
        });
    }

    index2id( indexes )
    {
        if( Array.isArray( indexes ) )
        {
            let ids = [];
            indexes.map( i => ids.push( this.state.data[ i ].id ) );
            return ids;
        }
        else if( typeof( indexes ) == "number" || typeof( indexes ) == "string" )
        {
            return this.state.data[ parseInt( indexes, 10 ) ].id;
        }
        return false;
    }

    removeRows()
    {
    	let indexes=this.state.removeIndexes;
    	let ids = [];
    	indexes.map( i => ids.push( this.props.rows[ i ].id ) );
    	this.props.onRemove( ids )
    }

    getColumns()
    {
        return [
            {
                id: 'decline_reason',
                Header: tr('decline_reason'),
                accessor: "decline_reason",
            },
        ]
    }

    render()
    {
        return <div id="OfferDecline">
            <div className="padding offerDeclineContainer">

                <h4 className="formHeader">{ tr('offer_decline_reasons') }</h4>
                <p>{ tr('offer_decline_reasons_info') }</p>


                <ApReactTable
                    loading={ this.state.loading }
                    data={ this.props.rows }
                    columns={ this.state.columns }
                    filterable
                    multiselect={[
                        { icon: "trash-alt", label: tr('delete_selected'), action: ( indexes ) => { this.confirmDeleteDeclineReason( indexes ) }, unselectAfter: true, closeAfter: true },
                    ]}
                    minRows={ 5 }
                    /*rowActions={ ( row, index ) =>
                        <div className="apSimpleButton" onClick={ () => this.editRow( row, index ) }><SvgIcon icon="edit" type="solid" /></div>
                    }*/
                />
                <ApButton size="small" color="green" onClick={ () => this.setState({ newDeclineReasonShow: true }) }>
                    <SvgIcon icon="plus-square" type="solid" />
                    { tr('add') }
                </ApButton>

            </div>

            <NewOfferDecline
                show={ this.state.newDeclineReasonShow }
            	onAdd={ this.props.onAdd }
                onClose={ this.editRowClose }
            />

        { this.state.removeConfirmShow && 
            <ApConfirm
                show={ this.state.removeConfirmShow }
                onClose={() => {this.setState({removeConfirmShow: false})}}
                onConfirm={ this.removeRows }
                header={ tr('confirm_delete') }

                            
            />
        }

        </div>

    }
}

export default OfferDecline;
