import React from 'react';
import autoBind from 'react-autobind';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import UserPermitModal from '../../UserManagement/UserWorkPermits/UserPermitModal.js';
import moment from 'moment';
import { tr, roundToDecimals } from 'services/Helpers/Helpers.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js'
import './Permits.css';
import {matchSorter} from 'match-sorter';


export default class Permits extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showPermitEditorModal: false,
			selectedPermitId: null,
			data: this.props.permits,
			columns: [
				{
					Header: tr("permits"),
					columns: [
						{
							id: 'status',
							Header: tr('status'),
							
							align: "center",
							width: 50,
							Cell: (props) => {
								let required=false;
								if (props.original.user_permit) {
									required = props.original.user_permit.is_required
								}
								return <div>
									{this.getPermitStatusIcon(props.original)}<br />
									<small>
										{required ? <SvgIcon fill={this.statusColors.required} icon="exclamation-circle" type="solid" style={{height:13}} />:""}
									</small>
								</div>
							},
							accessor: (props) => {
								
							},
							filterMethod: (filter, row) => {
								
							},
							Filter: ({ filter, onChange }) => { },
						},
						{
						
							id: 'expiredDay',
							Header: tr("end_date"),
							width: 200,
							
							onClick: (row) => {
								this.openPermitEditor(row);
							},
							Cell: (props) => {
								let text;
								let text2 ;
								let daysToExpiry;
								if (props.original.user_permit) {
									daysToExpiry = this.getDaysToExpiry(props.original.user_permit);
									if (props.original.user_permit.is_required) {
										if (daysToExpiry >= 0) {
											text = props.original.user_permit.date_expires != null ? <strong>{moment(props.original.user_permit.date_expires).format("DD.MM.YYYY")}</strong> : <strong style={{ color: "red" }}>{tr("permit_is_not_valid")}</strong >;
										}
										else {
											text = props.original.user_permit.date_expires != null ? <strong style={{ color: "red" }}>{moment(props.original.user_permit.date_expires).format("DD.MM.YYYY")}</strong> : <strong style={{ color: "red" }}>{tr("permit_is_not_valid")}</strong >;
										}
										
									}
									else {
										text = props.original.user_permit.date_expires != null ? <strong>{moment(props.original.user_permit.date_expires).format("DD.MM.YYYY")}</strong> : <strong>{tr("permit_is_not_valid")}</strong >;
									}
									
									
									if (daysToExpiry >= 0) {
										text2 = <small>{tr("permit_remaining:") + " " + daysToExpiry + " " + tr("days")}</small>;
									}
									else if (props.original.user_permit.is_required){
										text2 = <small style={{ color: "red" }}>{tr("permit_required")}</small>;
									}
									else {
										text2 = <small style={{color:"red"}}>{tr("permit_is_expired")}</small>;
									}
									
								}
								else {
									text = <strong>{tr("permit_is_not_valid")}</strong >;
								}
									

								return (
									<div className="nameCell">
										{text}<br />
										{text2}
									</div>
								);
							},
							accessor: "user_permit.date_expires",
							filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
								keys: [
									"_original.user_permit.date_expires"
									
								]
							}),
							filterAll: true,
							sortable:true,
								
						},
						{

							id: 'name',
							Header: tr("work_permit_name"),
							onClick: (row) => { this.openPermitEditor(row);},
							Cell: (props) => {
								let name ;
								if (props.original.name)
									name = props.original.name ;

								return (
									<div className="nameCell">
										<strong>{name}</strong><br />
										{//tulevaisuudessa jos halutaan että käyttäjä näkee mistä ryhmästä oikeus tulee
										}
										<small>{false ? "if user get permit by group x" : ""}</small>
									</div>
								);
							},
							accessor: "name",
							filterMethod: (filter, rows) => { }

						},
					],
				}
				
			]
		}
		autoBind(this);

		this.statusColors = {
			notRequired: "rgba(0,0,0,0.1)",
			required: "#6699FF",
			valid: "#65CE50",
			expiring: "orange",
			expired: "#F96D6D",
			notAccepted: '#D7EF4F'
		};
		
	}

	openPermitEditor(permit) {
		const permitId = permit ? permit.id : null;
		if (permitId !== null)
			this.setState({ showPermitEditorModal: true, selectedPermitId: permitId });
	}

	closePermitEditor() {
		this.setState({
			showPermitEditorModal: false, selectedPermitId: null
		}, () => {
			this.props.getPermits();
		});
	}

	getPermitStatus(permit) {
		if (permit == null) {
			return "notgranted";
		}
		const now = moment();
		const accepted = permit.user_permit ? permit.user_permit.is_accepted : null;
		const required = permit.user_permit ? permit.user_permit.is_required : null;
		const granted = permit.user_permit && permit.user_permit.date_granted ? true : false;
		const expires = permit.user_permit ? moment(permit.user_permit.date_expires) : moment(null);
		const tmp = permit.user_permit ? moment(permit.user_permit.date_expires) : moment(null);
		const noteBefore = permit.note_before_expiry_dates === null ? 0 : permit.note_before_expiry_dates;
		const warning = now >= tmp.add(-noteBefore, 'days');
		const expired = now >= expires;

		//if (required) return "required";
		if (granted && !warning && !expired && accepted) return "valid";
		//if (granted && warning && !expired && accepted) return "expiring";
		if (granted && warning && !expired) return "expiring";
		if (granted && !accepted) return "notaccepted";
		if (granted && expired) return "expired";
		if (granted && expired && required) return "expired";
		if (!granted && expired && required) return "expired";
		if (!granted) return "notgranted";

		return "not available";
	}


	getPermitStatusIcon(permit) {
		let element = null;
		switch (this.getPermitStatus(permit)) {
			case "notgranted":
				element = <SvgIcon fill={this.statusColors.notRequired} icon="circle" />
				break;

			case "valid":
				element = <SvgIcon fill={this.statusColors.valid} icon="check-circle" type="solid" />
				break;

			case "required":
				element = <SvgIcon fill={this.statusColors.required} icon="exclamation-circle" type="solid" />
				break;

			case "expiring":
				element = <SvgIcon fill={this.statusColors.expiring} icon="exclamation-circle" type="solid" />
				break;

			case "notaccepted":
				element = <SvgIcon fill={this.statusColors.notAccepted} icon="question-circle" type="solid" />
				break;

			case "expired":
				element = <SvgIcon fill={this.statusColors.expired} icon="times-circle" type="solid" />
				break;

			default:
				console.error("Unknown permit status");
		}
		return element;
	}
	//calculate how many days to expired
	// used also
	getDaysToExpiry(permit) {
		if (permit == null) return null;
		let now = moment();
		let expires = moment(permit.date_expires);
		let daysToExpiry = 0;

		let diff = roundToDecimals(moment.duration(expires.diff(now)).asDays(), 0);
		//if (diff >= 0) daysToExpiry = diff;
		daysToExpiry = diff;
		return daysToExpiry;
	}

	render() {
		//console.log(this.props.permits);
		/*
		<ApReactTable

//manual
//onFetchData={ this.getUsersApt }
//pages={ this.state.pages }

loading={ this.state.loading }
data={ this.state.users } 
columns={ this.state.columns }
filterable
multiselect={[
	//{ icon: "toggle-on", label: "Aktivoi valitut", action: ( indexes ) => { console.log('activate', indexes ); } },
	//{ icon: "toggle-off", label: "Poista valitut käytöstä", action: ( indexes ) => { console.log('deactivate', indexes ); } },
	{ icon: "trash-alt", label: tr('delete_selected'), action: ( indexes ) => this.confirmRemoveUsers( indexes ) },
]}
rowActions={ (row) => 
	<div className="apSimpleButton" onClick={ () => this.handleEditClick( row.id ) }><SvgIcon icon="user-cog" type="solid" /></div>
}
minRows={10}
defaultSorted={[
	{ id: "name", desc: false }
]}
/>
*/
		return (
			<div className="padding" id="Permits">

				<div className="guides">
					<div className="guide">
						<SvgIcon fill={this.statusColors.valid} icon="check-circle" type="solid" /> {tr('valid')}
					</div>
					<div className="guide">
						<SvgIcon fill={this.statusColors.expiring} icon="exclamation-circle" type="solid" /> {tr('expiring')}
					</div>
					<div className="guide">
						<SvgIcon fill={this.statusColors.expired} icon="times-circle" type="solid" /> {tr('expired')}
					</div>
					<div className="guide">
						<SvgIcon fill={this.statusColors.required} icon="exclamation-circle" type="solid" /> {tr('required')}
					</div>
					<div className="guide">
						<SvgIcon fill={this.statusColors.notAccepted} icon="question-circle" type="solid" /> {tr('waiting_for_approval1')}
					</div>
				</div>

				<ApReactTable

					//manual
					//onFetchData={ this.getUsersApt }
					//pages={ this.state.pages }

					loading={this.state.loading}
					data={this.props.permits}
					columns={this.state.columns}
					
					//defaultSorted={[
						//{ id: "name", desc: false }
					//]}
				/>




				
				

				<UserPermitModal
					show={this.state.showPermitEditorModal}
					permitId={this.state.selectedPermitId}
					onClose={this.closePermitEditor}
				/>

			</div>
		);

	}

}