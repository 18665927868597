import React, {Component} from 'react';

import ApButton from 'common/ApButton/ApButton';
import { errorPopper, tr } from 'services/Helpers/Helpers';
import api from 'services/Api/Api';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import { ApAddon, ApInput, ApInputStack } from 'common/ApInput/ApInput';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import moment   from 'moment';

class ApiSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initial: true,

            source: 'fi',
            target: 'fi',

            missingTranslations: {}
        }
        this.getApiSetting();
    }

    renderApitamoPKI() {
        
        return (
            <div>
                <div className='padding'>
                    <ApInputStack gap='0'>
                        <ApAddon noRightMargin labelFor='source'>{tr('source')}</ApAddon>
                        <ApInput
                            type='textarea'
                            id='source'
                            name='source'
                            value={this.state.apikey}
                            // options={sourceLanguages.map((lang) => ({ value: lang[0], label: lang[1] }))}
                            onChange={(e) => this.handleLangChange(e)}
                        />

                        <ApAddon noRightMargin>{tr('expires') + ": " + moment( this.state.expires).format('DD.MM.YYYY') }</ApAddon>
                        <ApAddon noRightMargin>{tr('reNew') + ": " + moment( this.state.reNew).format('DD.MM.YYYY') }</ApAddon>
                        {/* <ApInput
                            // type='select'
                            id='target'
                            name='target'
                            value={this.state.target}
                            // options={Object.entries(languages).map((lang) => ({ value: lang[0], label: lang[1] }))}
                            onChange={(e) => this.handleLangChange(e)}
                        /> */}
                        
                        
                    </ApInputStack>
                    <ApButton
                        onClick={this.updateApiKey}
                        color="green"
                        style={{ display: 'flex', marginLeft: 'auto' }}
                    >
                        Hae
                    </ApButton>
                </div>
            </div>
        );
    }

    renderFivaldiApi() {
        return (
            <div>
                TODO
            </div>
        );
    }

    render() {
        return (
           
            <div id="api">
                <div className="apBox">
                    <div className="apBoxHeader">
                        <h1>{tr('api_interfaces')}</h1>
                    </div>
                    <div className='padding'>
                        <ApTabs fullWidth>
                            <ApTab icon="cubes" label={'Vero Api'}>
                                {this.renderApitamoPKI()}
                            </ApTab>

                            <ApTab icon="chart-bar" label={'Fivaldi Api'}>
                                {this.renderFivaldiApi()}
                            </ApTab>
                        </ApTabs>
                    </div>
                </div>
            </div>
        )
    }

    updateApiKey() {
        api({
            method: 'get',
            url: `Tax/reNewCert`,
        }).then((response) => {
            if(response==1) {
              
            }  
        }).catch((error) => {
            errorPopper(error);
            console.error(error);
        });
    }

    getApiSetting() {
        this.setState({loading: true})
        api({
            method: 'get',
            url: `apiSettings/getAll`,
        }).then((response) => {
            if (response && response.length > 0) {
                this.setState({ 
                    apikey: response[0].vero_api_key ,
                    expires: response[0].expires,
                    reNew: response[0].reNew,
                    loading: false
                });
            }
           
        });

    }
}

export default ApiSettings;