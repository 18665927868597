import React from 'react';
import autoBind from 'react-autobind';
import ApButton from 'common/ApButton/ApButton.js';
import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import { tr }  from 'services/Helpers/Helpers.js'

export default class HideConfirmModal extends React.Component
{
	constructor(props)
	{
        super(props);
        autoBind(this);
	}

	handleSave()
	{
        this.props.hideProject(this.props.selectedProject.id)
		this.props.onClose()
	}

	render()
	{
        const projectName = this.props.selectedProject ? this.props.selectedProject.title : null;
		return(
			<ApModal
				show={this.props.show}
				handleClose={ this.props.handleClose }
				closeFromBg
				className="narrow"
				header={ 
					<div className="padding-small">
						<h4>{ tr('hide_project') }</h4>
					</div>
				}
				body={
					<div className="padding" style={{ marginTop: 10 }}>
					    <div>{ tr('confirm_hide_project', [<b>{projectName}</b>]) }?</div>
					</div>
				}
				footer={
					<div className="padding" style={{display: 'flex', flexDirection: 'row'}}>
                        <ApButton className="cancel" onClick={() => this.props.onClose()}>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton
                            className="save"
                            color="blue"
                            onClick={ this.handleSave }
                            style={{marginLeft: 'auto'}}
                        >
                            <SvgIcon icon="save" type="solid" />
                            { tr('save') }
                        </ApButton>
					</div>
				  }
			/>
		);
	}
}