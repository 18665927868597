import React from 'react';
import { TransitionGroup } from 'react-transition-group' // ES6
import './NotificationBox.css';
import '../../../main.css';
import * as ReactBootstrap from 'react-bootstrap';

class NotificationBox extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      visible: true,
      title: "Ilmoitus",
      message: "",
      style: "danger" // success, warning, danger, info
    }

    this.handleAlertDismiss = this.handleAlertDismiss.bind(this);

  }

  getMessage() {
    // POST to /frontpageMessages
    let msg = "";

    this.setState(prevState => ({
      message: msg,
      visible: true
    }));
  }

  handleAlertDismiss() {
    this.setState({ visible: false })
  }

  render() {
    var alert;
    if(this.state.visible)
    {
      alert = <ReactBootstrap.Alert key="alert" onDismiss={this.handleAlertDismiss} variant={this.state.style}>
                <h4> {this.state.style === 'danger' && <i className="glyphicon glyphicon-ban-circle"/>} {this.state.title} </h4>
                <p> {this.state.message} </p>
              </ReactBootstrap.Alert>;
    }
    return (
      <div className="row">
        <div className="col-xs-12">
            <TransitionGroup
                transitionName="alert"
                transitionEnterTimeout={0}
                transitionLeaveTimeout={1000}
            >
            {alert}
          </TransitionGroup>
          </div>
      </div>
    );
  }
}

export default NotificationBox;
