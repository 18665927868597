import React, { Component } from "react";
import moment from 'moment';

import ApButton from "common/ApButton/ApButton";
import { ApInput } from "common/ApInput/ApInput";
import ApModal from "common/ApModal/ApModal";
import ApSelect from "common/ApSelect/ApSelect";
import SvgIcon from "common/SvgIcon/SvgIcon";
import api from "services/Api/Api";
import { errorPopper, tr } from "services/Helpers/Helpers";

import styles from './InventoryModal.module.css';

class InventoryModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            componentChanges: [],
            newComponents: [],
            allComponents: [],
            location: [],

            unsavedChanges: false,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show === false && this.props.show === true) {
            this.getLocation();
        }
    }
    
    getLocation = () => {
        this.setState({loading:true});
        api({
            method: 'get',
            url: 'storage/location/inventory/get/' + this.props.location.id,
            data: {
                components: this.state.componentChanges,
                newComponents: this.state.newComponents,
                location: this.props.location,
            },
        }).then((response) => {
            // console.log('res', response);
            this.setState({
                loading: false,
                location: response[0]
            }, () => this.parseComponents(this.state.location));
        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        });
    }

    parseComponents = (loc) => {
        const components = [];
        const getComponents = (location) => {
            if (location.locComponents) {
                location.locComponents.forEach(c => components.push(c));
            }
            if (location.children) {
                location.children.forEach(subLocation => getComponents(subLocation));
            }
        }
        getComponents(loc);
        this.setState({allComponents: components});
    }

    handleSave = () => {
        this.setState({loading: true});
        api({
            method: 'post',
            url: 'storage/location/inventory',
            data: {
                components: this.state.componentChanges,
                newComponents: this.state.newComponents,
                location: this.props.location,
            },
        }).then((response) => {
            // console.log('res', response);
            this.setState({loading: false});

            this.handleClose(true);

        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('save_error'));
            this.setState({loading: false});
        });
    }

    handleBalanceChange = (e, isNewComponent = false, isSublocation = false) => {
        const {value, name} = e.target;
        const location =  Number(e.target.getAttribute('data-location'));

        if (value < 0) return;

        const componentId = name.split('-')[1];
        const componentChanges = [...this.state.componentChanges];
        const newComponents = [...this.state.newComponents];
        let foundComponent = isNewComponent
            ? newComponents.find(c => c.id === Number(componentId) && c.location_id === location)
            : componentChanges.find(c => c.id === Number(componentId) && c.locationId == location);

        if (foundComponent) {
            foundComponent['newBalance'] = value;
            foundComponent['locationId'] = location;
        } else {
            const found = isSublocation
                ? this.state.allComponents.find(c => c.component.id === Number(componentId) && c.location_id === location)
                : this.props.components.find(c => c.id === Number(componentId));
            found['newBalance'] = value;
            found['locationId'] = location;
            if (isSublocation && found['component']) {
                found['id'] = found['component_id'];
            }
            
            componentChanges.push(found)
        }

        this.setState({
            componentChanges,
            newComponents,
            unsavedChanges: true, 
        })
    }

    handleAddComponent = (component, locationId) => {
        const newComponents = [...this.state.newComponents];
        component['location_id'] = locationId;
        newComponents.push(component);

        this.setState({newComponents})
    }

    handleClose = (refresh = false) => {
        this.setState({componentChanges: [], newComponents: [], unsavedChanges: false});
        this.props.close(refresh);
    }

    handleComponentRemove = (componentId) => {
        const newComponents = [...this.state.newComponents];
        const foundIndex = newComponents.findIndex(c => c.id === componentId);
        if (foundIndex !== -1) {
            newComponents.splice(foundIndex,1);
            this.setState({newComponents});
        }
    }

    handlePrint = () => {
        const printWindow = window.open('', 'PRINT', 'width=900,height=900');
        const head = document.head.innerHTML;

        printWindow.document.write('<html>');
        printWindow.document.write(head);
        printWindow.document.write('<body >');
        printWindow.document.write(document.getElementById(styles.inventoryModalBody).innerHTML);
        printWindow.document.write('</body></html>');

        printWindow.document.close(); // necessary for IE >= 10
        printWindow.focus(); // necessary for IE >= 10*/

        // odotetaan että printti-ikkuna lataa sisällön ennen printtaamista
        printWindow.addEventListener("load", function() {
            setTimeout(() => {
                printWindow.print();
                printWindow.close();
            }, 1000);
        });
    }

    getLastInventoryHistory = (history) => {
        if (!history || history.length < 1) {
            return null;
        }

        let lastItem = null;
        history.forEach(item => {
            if (item.from == 'inventory') lastItem = item;
        });
        if (!lastItem) return null;

        return (
            <>
                <br/>
                <small>
                    {/* {tr('last_inventory')}: {moment(lastItem.created_at).format('L HH:mm')}{' - '}  */}
                    {tr('balance_before_inventory')}: {Math.round((parseFloat(lastItem.balance_after) - parseFloat(lastItem.balance_change))*100)/100}
                </small>
            </>
        )
    }

    renderHeader() {
        const lastInventory = this.props.location && this.props.location.last_inventory
            ? <p>{tr('last_inventory')}: {moment(this.props.location.last_inventory).format('L')}</p>
            : null;
        return(
            <div className='padding'>
                <h1>{tr('inventory')}</h1>
                {lastInventory}
            </div>
        );
    }

    renderBody() {
        if (this.state.loading) return <div className="apLoader" style={{display: 'flex', justifyContent: 'center'}}></div>
        if (!this.props.location || !this.state.location.locComponents) return <div id={styles.inventoryModalBody} className='padding'></div>;
        
        return(
            <div id={styles.inventoryModalBody} className='padding'>
                {this.renderMultipleLocations()}
            </div>
        );
    }

    renderMultipleLocations = () => {
        if (!this.state.location) return null;
        const subLocationsEl = [];

        const parseLocations = (location) => {
            if (location.can_have_components || (location.locComponents && location.locComponents.length > 0) ) {
                const componentList = location.locComponents.map(c => {
                    if (!c.component) return null;
                    const foundValue = this.state.componentChanges.find(component=> component.id === c.component.id && Number(component.locationId) === location.id);
                    return (
                        <tr key={`${location.id}-${c.component.id}`}>
                            <td className='icon'><SvgIcon icon='puzzle-piece' type="solid" /></td>
                            <td>{c.component.name} {this.getLastInventoryHistory(c.history)}</td>
                            <td className={styles.alignRight}>{c.balance} {c.component.unit}</td>
                            <td className={`${styles.width100} fixed`}>
                                <ApInput
                                    data-location={location.id}
                                    value={foundValue && foundValue.newBalance}
                                    name={`component-${c.component.id}`}
                                    id={`component-${c.component.id}`}
                                    type='number'
                                    onChange={(e) => this.handleBalanceChange(e, false, true)}
                                    className={`${styles.alignRight} ${styles.newBalanceInput}`} />
                            </td>
                        </tr>
                    );
                });
                const newComponentsList = this.state.newComponents.map(component => {
                    if (component.location_id != location.id) return null;
                    return (
                        <tr key={component.id}>
                            <td className='icon'><SvgIcon icon='puzzle-piece' type="solid" /></td>
                            <td><SvgIcon icon='times' type="solid" onClick={() => this.handleComponentRemove(component.id)} className={`apBadge red pointer`} /> {component.name}</td>
                            <td className={styles.alignRight}>{component.balance} {component.unit}</td>
                            <td className={`${styles.width100} fixed`}>
                                <ApInput
                                    data-location={location.id}
                                    value={component.newBalance}
                                    name={`component-${component.id}`}
                                    id={`component-${component.id}`}
                                    type='number'
                                    onChange={(e) => this.handleBalanceChange(e, true)}
                                    className={`${styles.alignRight} ${styles.newBalanceInput}`} />
                            </td>
                        </tr>
                    );
                });

                const excludeIds = this.state.newComponents.reduce((accumulator, current) => {
                    if (current.location_id === location.id) return [...accumulator, current.id];

                    return [...accumulator];
                }, []);
                location.locComponents.forEach(c => excludeIds.push(c.component_id));

                subLocationsEl.push(
                    <div key={location.id}>
                        <h5>{location.name} - {location.code} {location.last_inventory && 
                            <span className="small"> ({tr('last_inventory')} {moment(location.last_inventory).format('L')})</span>}
                        </h5>
                        <table className='componentTable'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{tr('name')}</th>
                                    <th className={styles.alignRight}>{tr('balance')}</th>
                                    <th className={`${styles.width100} ${styles.alignRight}`}>{tr('found')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {componentList}
                                {newComponentsList}
                            </tbody>
                        </table>
                        <div style={{marginBottom: '100px'}}>
                            <ApSelect
                                label={tr('add_component')}
                                value=''
                                optionRenderer="component_detail"
                                onChange={ (e) => this.handleAddComponent(e, location.id) }
                                objKeyId="id"
                                objKeyValue="name"
                                apiUrl="storage/components"
                                apiData={{
                                    include: [ 'identifiers' ],
                                    formatter: 'offer_calculate',
                                    exludeIds: excludeIds,
                                    type: 'item'
                                }}
                            />
                        </div>
                    </div>
                );
            }
            if (location.children && location.children.length > 0) {
                location.children.forEach(subLocation => {
                    parseLocations(subLocation);
                })
            }
        }
        parseLocations(this.state.location);

        return subLocationsEl;
    }

    renderFooter() {
        return(
            <div className='padding' style={{display: 'flex', justifyContent: 'space-between'}}>
                <ApButton onClick={() => this.handleClose(false)}>{tr('cancel')}</ApButton>
                <div>
                    <ApButton onClick={this.handlePrint}>
                        <SvgIcon icon='print' type='solid' /> {tr('print')}
                    </ApButton>
                    <ApButton 
                    disabled={!this.state.unsavedChanges ||this.state.loading} 
                    onClick={this.handleSave} 
                    color='blue' 
                    className={this.state.unsavedChanges ? 'highlight' : ''} >
                        <SvgIcon icon='save' type='solid' /> {tr('save')}
                    </ApButton>
                </div>
            </div>
        );
    }

    render() {
        return(
            <ApModal
                show        = {this.props.show}
                handleClose = {this.handleClose}
                header      = {this.renderHeader()}
                body        = {this.renderBody()}
                footer      = {this.renderFooter()}
            />
        );
    }
}

export default InventoryModal;