import React from 'react';
import autoBind from 'react-autobind';

import auth            from 'services/Authed/Authed.js';

import { formatMoney, tr }  from 'services/Helpers/Helpers.js';

import './ComponentTooltip.css';

class ComponentTooltip extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            currencySign: auth.getCurrencySign(),
        }
        autoBind(this);
    }

    render()
    {
        const component = this.props.component;

        if( !component ) return null;

        let calcTable = null;
        if( component.calculatables )
        {
            if( component.calculatables.length === 0 )
                calcTable = <div className="noCalc">{ tr('no_meters') }</div>
            else
            {
                let calcRows = [];
                calcRows = component.calculatables.map( ( calc, index ) => {
                    let value = null;
                    if( calc.value )
                        value = calc.value;
                    if( calc.pivot )
                        value = calc.pivot.value;

                    return <tr key={ index }>
                        <td className="infoLabel">{ calc.name }: </td>
                        <td className="valueNumber">{ formatMoney( value ) } </td>
                        <td className="infoUnit">{ calc.unit } </td>
                    </tr>
                });
                calcTable = <div>
                    <table className="calcTable">
                        <thead>
                            <tr>
                                <th colSpan="3"  className="header">{ tr('meters') }</th>
                            </tr>
                        </thead>
                        <tbody>
                            { calcRows }
                        </tbody>
                    </table>
                </div>
            }
        }

        let identifiersDom = null;
        if (Array.isArray(component.identifiers) && component.identifiers.length > 0) {
            identifiersDom = <>
                <div className='verticalLine'></div>
                <div className='componentTooltipRight'>
                    <table className="infoTable">
                        <thead>
                            <tr>
                                <th colSpan="2" className="header">{ tr('identifiers') }</th>
                            </tr>
                        </thead>
                        <tbody>
                            { component.identifiers.map((identifier, index) => {
                                return <tr key={index}>
                                    <td className="infoLabel">{ identifier.name }:</td>
                                    <td className="valueText">{ identifier.pivot.value }</td>
                                </tr>
                            }) }
                        </tbody>
                    </table>
                </div>
            </>
        }

        let code = component.code
        if( this.props.code ) code = this.props.code;

        let statusDom = null;
        if( component.status_text )
        {
            statusDom = <tr>
                <td className="infoLabel">{ tr('status') }:</td>
                <td className="valueText">{ tr(component.status_text) }</td>
            </tr>
        }

        return <div className="componentTooltip">
            <div className='componentTooltipLeft'>
                <table className="infoTable">
                    <thead>
                        <tr>
                            <th colSpan="2" className="header">{ tr('storage_component') }</th>
                        </tr>
                    </thead>
                    <tbody>
                        { statusDom }
                        <tr>
                            <td className="infoLabel">{ tr('type') }:</td>
                            <td className="valueText">{ component.type_text }</td>
                        </tr>
                        <tr>
                            <td className="infoLabel">{ tr('name') }:</td>
                            <td className="valueText">{ component.name }</td>
                        </tr>
                        <tr>
                            <td className="infoLabel">{ tr('code') }:</td>
                            <td className="valueText">{ code }</td>
                        </tr>
                        <tr>
                            <td className="infoLabel">{ tr('price') }:</td>
                            <td className="valueNumber">{ formatMoney( component.price ) } { this.state.currencySign }</td>
                        </tr>
                        <tr>
                            <td className="infoLabel">{ tr('selling_price') }:</td>
                            <td className="valueNumber">{ formatMoney( component.price_sell ) } { this.state.currencySign }</td>
                        </tr>

                    </tbody>
                </table>
                { calcTable }
            </div>
            {identifiersDom}
        </div>
    }
}

export default ComponentTooltip;

