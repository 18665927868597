import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import axios from 'axios';

import ApTimeline from '../../../../common/ApTimeline/ApTimeline.js';
import ApModal            from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import { ApInputStack, ApInput, ApAddon } from 'common/ApInput/ApInput.js';
import UserBrowser from '../../../Timetracking/Approve/UserBrowser/UserBrowser.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import api from 'services/Api/Api.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApValidate from 'services/ApValidate/ApValidate.js';
import { tr, currentLang } from 'services/Helpers/Helpers.js';

class UserHours extends React.Component {

	constructor( props ) 
	{
		super(props);
        this.state = {
            loading: false,  
            users: [],
            selectedUser: null,
            settings: [],

            showOpeningBalanceModal: false,
            opening_balance: null,
            opening_balance_date: null,
            opening_balance_description: null,

            showBalanceChangeModal: false,
            balance_change: null,
            balance_change_date: null,
            balance_change_description: null,

            showChangeRemoveModal: false,
            removingChange: null
        };

        this.validator = new ApValidate(this, {
            opening_balance: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
            opening_balance_date: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
            opening_balance_description: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
            balance_change: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
            balance_change_date: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
            balance_change_description: [
                { filter: 'required', state: 'error', text: tr('mandatory') },
            ],
        });
        
        autoBind(this);
    }

    componentDidMount()
    {
        this.getUsers();
    }
    
    getUsers( user = null )
    {
        const url = this.props.subordinates ? 'timetracking/longerworkinghourscompensation/subordinates' : 'timetracking/longerworkinghourscompensation/users'
        this.setState({ loading: true });
        api({
            method: 'get',
            url: url,
        }).then(( response ) => {

            //console.log('USERS', response.users );
         
            this.setState({
                loading: false,
                users: response.users,
            }, () => {

                if( response.users.length > 0 )
                {
                    if( !user || response.users.findIndex( r => r.id == user ) == -1 ) {
                        const foundUser = response.users.find(u => {
                            if (!u.is_active) return false;

                            if (moment(u.contract_start).isAfter(moment())) return false;
                            if (u.contract_end && moment(u.contract_end).isBefore(moment())) return false;

                            return true;
                        })
                        if (foundUser) {
                            user = foundUser.id;
                        } else {
                            user = response.users[0].id;
                        }
                    }
                  
                    this.changeUser( user );
                }
                else 
                    this.setState({ 
                        selectedUser:       null,
                        entries:            [],
                    });

            });

        }).catch( ( error ) => {
            console.log( 'getUsers ERROR', error );
            this.setState({ loading: false });
        });
    }

    changeUser( id )
    {

        // Cancel possible old query 
        if( this.state.cancelToken )
            this.state.cancelToken.cancel('Operation canceled by the user.');
           
        this.setState({ 
            selectedUser: id,
            loading: true,
            cancelToken: axios.CancelToken.source(),
        }, () => {
            api({
                cancelToken: this.state.cancelToken.token,
                method: 'post',
                url: 'timetracking/longerworkinghourscompensation/settings',
                data: { id: id }
            }).then(( response ) => {

                //console.log('settings', response)
                this.setState({
                    loading: false,
                    settings: response,
                    cancelToken: null,
                });

            }).catch( ( error ) => {
                if( axios.isCancel( error ) ) return null;
                console.log('ERROR', error );
            });
        });
    }

    saveOpeningBalance()
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/longerworkinghourscompensation/saveopeningbalance',
            data: { 
                id: this.state.selectedUser,
                balance: this.state.opening_balance,
                date: this.state.opening_balance_date,
                description: this.state.opening_balance_description
            }
        }).then(( response ) => {
            this.closeOpeningBalanceModal();
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('save_successful') }</strong>,
            });
        }).catch( ( error ) => {
            this.closeOpeningBalanceModal();
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('save_error') }</strong>,
            });
        });
    }

    saveBalanceChange()
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/longerworkinghourscompensation/savebalancechange',
            data: { 
                id: this.state.selectedUser,
                change: this.state.balance_change,
                date: this.state.balance_change_date,
                description: this.state.balance_change_description
            }
        }).then(( response ) => {
            this.closeBalanceChangeModal();
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('save_successful') }</strong>,
            });
        }).catch( ( error ) => {
            this.closeBalanceChangeModal();
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('save_error') }</strong>,
            });
        });
    }

    removeBalanceChange()
    {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/longerworkinghourscompensation/removebalancechange',
            data: {
                id: this.state.removingChange.id,
                userId: this.state.selectedUser
            },
        }).then((response) => {
            this.getUsers(this.state.selectedUser);
            this.props.getSettings();
            this.setState({ showChangeRemoveModal: false, removingChange: null });
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{`${tr('balance_change')} '${response.description}, ${response.change}' ${tr('deleted')}`}</strong>,
            });
        }).catch((error) => {
            this.setState({ loading: false, showChangeRemoveModal: false, removingChange: null });
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{ tr('delete_error') }</strong>,
            });
        });
    }

    handleChangeRemoveModal(item)
    {
        this.setState({ removingChange: item, showChangeRemoveModal: true });
    }

    closeOpeningBalanceModal()
    {
        this.setState({
            showOpeningBalanceModal: false,
            opening_balance: null,
            opening_balance_date: null,
            opening_balance_description: null,
            loading: false
        })
        this.getUsers(this.state.selectedUser);
        this.props.getSettings();
    }

    closeBalanceChangeModal()
    {
        this.setState({
            showBalanceChangeModal: false,
            balance_change: null,
            balance_change_date: null,
            balance_change_description: null,
            loading: false
        })
        this.getUsers(this.state.selectedUser);
        this.props.getSettings();
    }

    renderOpeningBalanceForm()
    {
        return (
            <div className="padding" >
            <ApInputStack gap="0">
            <ApInput
                name="opening_balance"
                id="opening_balance"
                label={ tr('opening_balance') }
                value={ this.state.opening_balance }
                type="number"
                onChange={ (e) => this.setState({ opening_balance: e.target.value }) }
                validationState={ this.validator.getState( 'opening_balance' ) }
            />
            <ApAddon width="50px" labelFor="opening_balance" >
                <span>h</span>
            </ApAddon>
            </ApInputStack>
            <ApInput 
                width="300"
                type="dateSafe" 
                id="opening_balance_date" 
                name="opening_balance_date" 
                label={ tr('date') }
                value={ this.state.opening_balance_date }
                onChange={ (e) => this.setState({ opening_balance_date: e.target.value }) }
                validationState={this.validator.getState( 'opening_balance_date' )}
            />
            <ApInput 
                width="300"
                type="text" 
                id="opening_balance_description" 
                name="opening_balance_description" 
                label={ tr('description') }
                value={ this.state.opening_balance_description }
                onChange={ (e) => this.setState({ opening_balance_description: e.target.value }) }
                validationState={this.validator.getState( 'opening_balance_description' )}
            />
            </div>
        )
    }

    renderBalanceChangeForm()
    {
        return (
            <div className="padding" >
            <ApInputStack gap="0">
            <ApInput
                name="balance_change"
                id="balance_change"
                label={ tr('balance_change') }
                value={ this.state.balance_change }
                type="number"
                onChange={ (e) => this.setState({ balance_change: e.target.value }) }
                validationState={ this.validator.getState( 'balance_change' ) }
            />
            <ApAddon width="50px" labelFor="balance_change" >
                <span>h</span>
            </ApAddon>
            </ApInputStack>
            <ApInput 
                width="300"
                type="dateSafe" 
                id="balance_change_date" 
                name="balance_change_date" 
                label={ tr('date') }
                value={ this.state.balance_change_date }
                onChange={ (e) => this.setState({ balance_change_date: e.target.value }) }
                validationState={ this.validator.getState( 'balance_change_date' ) }
            />
            <ApInput 
                width="300"
                type="text" 
                id="balance_change_description" 
                name="balance_change_description" 
                label={ tr('description') }
                value={ this.state.balance_change_description }
                onChange={ (e) => this.setState({ balance_change_description: e.target.value }) }
                validationState={ this.validator.getState( 'balance_change_description' ) }
            />
            </div>
        )
    }

    renderChangesColumns()
	{
        return (
		[
			{
				Header: `${tr('balance_change')} (${tr('day_abbr')})`,
                accessor: 'change',
                id: 'change',
                Cell: (props) => {
					return (
						<div>
							{props.original.change > 0 ? `+${props.original.change}` : props.original.change}
						</div>
					);
				}
			},
			{
				Header: tr('description'),
                accessor: 'description',
                id: 'description'
			},
			{
				Header: tr('date'),
                accessor: 'date',
                id: 'date',
                Cell: (props) => {
					return (
						<div>
							{moment(props.original.date).format('DD.MM.YYYY')}
						</div>
					);
				}
            },
            {
				Header: tr('responsible_person'),
                accessor: 'creator',
                id: 'creator',
                Cell: (props) => {
					return (
						<div>
							{`${props.original.creator.first_name} ${props.original.creator.last_name}`}
						</div>
					);
				}
            },
        ]
        );
    }
    
    renderContent()
    {
        if (this.props.subordinates && this.state.users && this.state.users.length === 0 && !this.props.loading)
        {
            return(
                <h4>{ tr('user_not_supervisor') }</h4>
            ) 
        }
        else if (!this.state.settings.current_balance && !this.state.loading)
        {
            return(
                <div>
                    <h4>{ tr('opening_balance_not_set') }</h4>
                    <ApButton 
                        color='green'
                        onClick={() => this.setState({ showOpeningBalanceModal: true })}
                    >
                        { tr('set_opening_balance') }
                    </ApButton>
                </div>
            )
        }
        else if (this.state.settings.current_balance && !this.state.loading)
        {
            const user = this.state.users && this.state.users.find(user => user.id === this.state.selectedUser);
            return(
                <div>

                    <h4>{user ? <strong style={{display: 'block', marginBottom: '10px'}}>{user.name}:</strong> : ''}{ tr('current_balance') }: {this.state.settings.current_balance.current_balance > 0 ? `+${this.state.settings.current_balance.current_balance}` : this.state.settings.current_balance.current_balance} h</h4>
                    <strong>{ tr('updated_at') }: </strong>{moment(this.state.settings.current_balance.updated_at).locale(currentLang()).format('LLL')}    

                    <br/><br/>

                    <h3>{ tr('balance_changes') }</h3>
                    <ApButton 
                        color='green'
                        onClick={() => this.setState({ showBalanceChangeModal: true })}
                    >
                        { tr('set_balance_change') }
                    </ApButton>
                    { this.state.settings.changes && this.state.settings.changes.length > 0 ?
                    <ApReactTable
                        data={this.state.settings.changes}
                        columns={this.renderChangesColumns()}
                            // rowActions={(row) => {
                            //     reutr
                            //     if (row.entry_id) {
                            //         return null;
                            //     }
                            //     return (
                            //     <div className="apSimpleButton" onClick={ () => this.handleChangeRemoveModal(row) }>
                            //         <SvgIcon icon="trash" type="solid" />
                            //     </div>
                            // )}}
                        defaultSorted={[ 
                            { id: "date", desc: true }
                        ]}
                        defaultPageSize={10}
                    />
                    :
                    <div>{ tr('no_balance_changes') }</div>
                    }

                    <br/>

                

                </div>
            )
        }
        else
        {
            return (
            <div className="padding">
                <div className="apLoader"></div>
            </div>
            )
        }
    }

    render() 
    {
        return (

            <div className="padding">

                { this.state.users && this.state.users.length > 0 &&
                <UserBrowser 
                    users={ this.state.users } 
                    selectedUser={ this.state.selectedUser }
                    onChangeUser={ this.changeUser }
                    subordinates={this.props.subordinates}
                    showActiveToggle={true}
                />
                }

                { this.renderContent() }

                {/* alkusaldon asetus */}
                <div id="modal">
                <ApModal
                    show={this.state.showOpeningBalanceModal}
                    className="narrow"
                    handleClose={() => this.setState({ showOpeningBalanceModal: false })}
                    header={ 
                    <div className="padding-small">
                        <h4>{ tr('set_opening_balance') }</h4>
                    </div>
                    }
                    body={ this.renderOpeningBalanceForm() }
                    footer={
                        <div style={{padding: '1em'}}>
                            <ApButton
                            style={{float: 'right'}}
                            className="save"
                            color="blue"
                            onClick={this.saveOpeningBalance}
                            loading={ this.state.loading }
                            disabled={!(!this.validator.getState( 'opening_balance' ) && !this.validator.getState( 'opening_balance_date' ) && !this.validator.getState( 'opening_balance_description' )) || this.state.loading}
                            >
                                <SvgIcon icon="check" type="solid" />
                                { tr('save') }
                            </ApButton>
                            <ApButton
                            style={{float: 'left'}}
                            className="cancel"
                            onClick={() => this.setState({
                                showOpeningBalanceModal: false,
                                opening_balance: null,
                                opening_balance_date: null,
                                opening_balance_description: null,
                            })}
                            >
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>
                        
                        </div>
                    }
                />
                </div>

                {/* saldomuutoksen asetus */}
                <div id="modal">
                <ApModal
                    show={this.state.showBalanceChangeModal}
                    className="narrow"
                    handleClose={() => this.setState({ showBalanceChangeModal: false })}
                    header={ 
                    <div className="padding-small">
                        <h4>{ tr('set_balance_change') }</h4>
                    </div>
                    }
                    body={ this.renderBalanceChangeForm() }
                    footer={
                        <div style={{padding: '1em'}}>
                            <ApButton
                            style={{float: 'right'}}
                            className="save"
                            color="blue"
                            onClick={this.saveBalanceChange}
                            loading={ this.state.loading }
                            disabled={!(!this.validator.getState( 'balance_change' ) && !this.validator.getState( 'balance_change_date' ) && !this.validator.getState( 'balance_change_description' )) || this.state.loading}
                            >
                                <SvgIcon icon="check" type="solid" />
                                { tr('save') }
                            </ApButton>
                            <ApButton
                            style={{float: 'left'}}
                            className="cancel"
                            onClick={() => this.setState({ 
                                showBalanceChangeModal: false,
                                balance_change: null,
                                balance_change_date: null,
                                balance_change_description: null,
                            })}
                            >
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>
                        
                        </div>
                    }
                />
                </div>

                {/* saldomuutoksen poisto */}
                <ApModal
                    show={this.state.showChangeRemoveModal}
                    className="narrow"
                    handleClose={() => this.setState({ showChangeRemoveModal: false })}
                    header={ 
                    <div className="padding-small">
                        <h4>{this.state.removingChange && `${tr('delete_confirm')} '${this.state.removingChange.description}, ${moment(this.state.removingChange.date).format('DD.MM.YYYY')}'?`}</h4>
                    </div>
                    }
                    footer={
                        <div style={{padding: '1em'}}>
                            <ApButton
                            style={{float: 'right'}}
                            onClick={this.removeBalanceChange}
                            color="red"
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                            >
                                <SvgIcon icon="trash" type="solid" />
                                { tr('delete') }
                            </ApButton>
                            <ApButton
                            style={{float: 'left'}}
                            className="cancel"
                            onClick={() => this.setState({ showChangeRemoveModal: false })}
                            >
                                <SvgIcon icon="times" type="solid" />
                                { tr('cancel') }
                            </ApButton>
                        
                        </div>
                    }
                />
 
            </div>
        );

        
    }
}
export default UserHours
