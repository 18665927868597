import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import { keyExists, tr }  from 'services/Helpers/Helpers.js'

import ApModal     from 'common/ApModal/ApModal.js';
import SvgIcon     from 'common/SvgIcon/SvgIcon.js';
import ApButton    from 'common/ApButton/ApButton.js';
import ApSwitch    from 'common/ApSwitch/ApSwitch.js';
import FileUpload  from 'common/FileUpload/FileUpload.js';
import File        from 'common/File/File.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import { Collapse } from 'react-bootstrap';

import './RowEdit.css';

class RowEdit extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            data: null,
            file: null,
            previousShow: false,
            keepOld: true,
        }
        autoBind(this);
    }

    resetState()
    {
        this.setState({
            data: this.props.data,
            file: null,
            previousShow: false,
            keepOld: true,
        });

        // Auto focus to name field (also scrolls view up if nessessary)
        if( this.nameRef )
            this.nameRef.focus();
    }

    handleChange( key, value )
    {
        let data = { ...this.state.data };
        data[ key ] = value;
        this.setState({ data });
    }

    onWorkSelect( component )
    {
        let data = { ...this.state.data };

        if( component )
        {
            data.work_component = component;
            data.unit = component.unit;
        }
        else
        {
            data.work_component = null;
            data.unit = null;
        }

        this.setState({ data });
    }

    save()
    {
        let data = { ...this.state.data };

        data[ 'keep_old' ] = false;

        if( this.state.file )
        {
            data.file_id = this.state.file.id;
            data.file_extension = this.state.file.extension;
            data.keep_old = this.state.keepOld;
            data.created_at = moment().format( 'YYYY-MM-DD' );
        }
        this.props.onClose( data );
    }

    fileUpload( file )
    {
        //console.log('Fi', file );

        this.setState({
            file: {
                name: file.original_name,
                id: file.id,
                extension: file.extension,
            }
        });
    }

    fileClear()
    {
        this.setState({
            file: null,
        });
    }

    renderPreviousVersion()
    {
        if( !this.state.data ) return null;
        if( !this.state.data.old_versions ) return null;
        if( this.state.data.old_versions.length === 0 ) return null;

        let versions = this.state.data.old_versions;

        let headerClass = [ 'formHeader', 'pointer' ];
        if( this.state.previousShow ) headerClass.push( 'open' );

        const versionDoms = versions.map( (v) => {
            return <tr>
                <td>
                    { moment( v.created_at ).format( 'DD.MM.YYYY' ) }
                </td>
                <td>{ v.creator_user_name }</td>
                <td>
                    <File  fileId={ v.file_id } name={`${tr('offer_template')}-${ v.created_at }.${ v.file_extension }`}>
                        <SvgIcon icon="file-word" type="solid" />
                    </File>
                </td>
            </tr>
        });


        return <div className="previousVersionContainer">
            <h4 className={ headerClass.join(' ') } onClick={ () => { this.setState({ previousShow: !this.state.previousShow }) } }>
                <SvgIcon icon="chevron-right" type="solid" />
                { tr('previous_versions') }
            </h4>
            <Collapse in={ this.state.previousShow }>
                <div className="apFormGroup">
                    <table className="previousVersionTable">
                        <thead>
                            <tr>
                                <th>{ tr('created_at') }</th>
                                <th>{ tr('creator') }</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { versionDoms }
                        </tbody>
                    </table>
                </div>
            </Collapse>
        </div>
    }

    renderFileInput()
    {
        let fileIcon = null;
        let keepOld = null;
        let file = this.state.file;
        if( file )
        {
            fileIcon = <ApAddon width="50px" noLeftBorder>
                <File  fileId={ file.id } name={ file.name }>
                    <SvgIcon icon="file-word" type="solid" />
                </File>
            </ApAddon>

            keepOld = <div className="keepPreviousContainer apSwitchBlock small">
                <label htmlFor="keep-old" className="info">
                    { tr('keep_previous_version') }
                    <small>{ tr('keep_previous_version_info') }</small>
                </label>
                <ApSwitch
                    small
                    id="keep-old"
                    on={ this.state.keepOld }
                    onChange={ () => { this.setState({ keepOld: !this.state.keepOld }) } }
                    disabled={ this.props.loading }
                />
            </div>
        }

        return <div className="fileSelectContainer">
            <ApInputStack gap="0">
                <ApAddon width="200px" noRightBorder>
                    { tr('offer_template') }
                </ApAddon>
                <FileUpload
                    onUpload={this.fileUpload }
                    description="OfferFileTemplate"
                    accept=".docx"
                    label={ `${tr('select_docx_file')}` }
                    value={ this.state.file }
                    onClear={ this.fileClear }

                />
                { fileIcon }
            </ApInputStack>
            { keepOld }
        </div>
    }

    renderBody()
    {
        const isset = ( key ) => {
            if( !keyExists( this.state.data, key ) )
                return false;
            if( this.state.data[ key ] === null )
                return false;
            if( this.state.data[ key ].length === 0 )
                return false;
            return true;
        }

        return <div className="padding">
            <ApInput
                type="text"
                id="name"
                name="name"
                label={ tr('name') }
                value={ keyExists( this.state.data, "name", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'name', e.target.value ) } }
                validationState={ !isset( "name" ) ? "error" : "" }
                inputRef={ ref => this.nameRef = ref }
            />
            <ApInput
                type="textarea"
                id="description"
                name="description"
                label={ tr('description') }
                value={ keyExists( this.state.data, "description", true, '' ) }
                onChange={ ( e ) => { this.handleChange( 'description', e.target.value ) } }
            />
            { this.renderFileInput() }

            { this.renderPreviousVersion() }
        </div>
    }


    render()
    {
        return <div id="OfferTemplateEdit">
            <ApModal
                show={ this.props.show }
                onOpen={ this.resetState }
                handleClose={ () => this.props.onClose( false) }
                //closeFromBg
                className="narrow"
                header={
                    <div className="padding">
                        <h4>
                            { tr('offer_template') } - { Boolean( this.props.data ) && this.props.data.id ? tr('edit') : tr('add') }
                        </h4>
                    </div>
                }
                body={
                    <div id="OfferTemplateEditBody">
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div className="padding" id="OfferTemplateEditFooter">
                        <ApButton className="cancel" onClick={ () => this.props.onClose( false ) }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton className="save" color="green" onClick={ this.save } disabled={ this.state.loading }>
                            <SvgIcon icon="check" type="solid" />
                            { tr('continue') }
                        </ApButton>
                    </div>
                }
            />
        </div>

    }

}

export default RowEdit;
