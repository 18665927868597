import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import moment from 'moment';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApTooltip         from 'common/ApTooltip/ApTooltip.js';
import SvgIcon     from 'common/SvgIcon/SvgIcon.js';

class ExtInterfaces extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            pages: 0,
            interfaces: [],
            columns: [],
            loading: false,
        }
        this.tableState = {};
        autoBind(this);
    }

    componentDidMount()
    {
        this.getColumns();
    }

    getInterfaces(state = null)
    {
        this.setState({loading: true});

        if(!state)
            state = this.tableState;
        this.tableState = state;

        api({
            method: 'post',
            url: 'management/interfaces/getall',
            data: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
            },
            errorPopper: 'Error on getting errors',
        }).then((response) => {
            console.log( 'data', response );
            this.setState({
            	interfaces: response,
                loading: false,
            })
        }).catch((error) => {
            this.setState({loading: false});
        });
    }

    getColumns()
    {
        const columns = [
            {
                Header: 'ID',
                accessor: 'id',
                width: 50,
            },{
                Header: 'Interface',
                accessor: 'name',
            },
            {
                id: 'types',
                Header: 'Type',
                accessor: 'ext_type_list',
                Cell: ( props ) => {
                	 return props.original.ext_types.map((item)=>{ 
                		 return <div>{item.type_name}</div>
                	})
                },         
            },
            {
                Header: 'Settings',
                accessor: 'settings',
            },
            {
                Header: 'Active',
                accessor: 'active',
                Cell: ( props ) => {
                    if( props.value )
                        return <div className="text-center"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                },
            },
            
        ];
        this.setState({ columns: columns });
    }

    render()
    {
        return <div className="apBox" id="frontErrors">
            <div className="apBoxHeader">
                <h1>External interfaces</h1>
                <p>Manage interfaces and settings here</p>
            </div>
            <div className="padding">
                <ApReactTable
                    columns={ this.state.columns }
                    data={ this.state.interfaces }
                    loading={ this.state.loading }
                    onFetchData={ this.getInterfaces }
                    minRows={ 10 }
                    manual
                />
            </div>
        </div>
    }
}

export default ExtInterfaces;
