/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
//import auth from 'services/Authed/Authed.js';
import moment from 'moment';

import './WorkHours.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import WeekBrowser from './WeekBrowser/WeekBrowser.js';
import WeekDay  from './WeekDay/WeekDay.js';
import EntryEdit from './EntryEdit/EntryEdit.js';

import { Collapse } from 'react-bootstrap';

import { roundToDecimals } from  'services/Helpers/Helpers.js';
import { weekInterpretation } from  'modules/WorkHours/WorkHourHelpers.js';

class WorkHours extends React.Component {

    constructor(props) 
    {
        super(props);

        this.state = {
            loading: true,
            settings: null,

            selectedYear: null,
            selectedWeek: null,
            weekSummaryOpen: false,
            weekData: [],

            sendPartialWeek: false,
            selectedWeekdays: [],

            entryEditShow: false,
            entryEditData: false,
            entryEditAction: "",

            showInterpretation: false,
            interpretation: false,
        };
        autoBind(this); 
    }


    componentDidMount()
    {

        //console.log("workhours mounted");

        const params = this.props.match.params;
        let year = params.year;
        let week = params.week;

        if(!year || !week)
        {
            const today = moment();
            year = today.year();
            week =  today.week();
        }

        // Get global settings from backend
        this.getSettings( () => {
            //console.log( 'Settings: ', this.state.settings );
            this.changeWeek( year, week );
        });
    }


    /**
     * Get entries for days given in arguments.
     * Sets this.state.days
     *
     **/
    getEntries( days, updateMode )
    {   
        // Convert single date string ("YYYY-MM-DD") to array
        if( typeof( days ) == "string" ) 
            days = [ days ];

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'hour/entries/get',
            data: {
                dates: days,
            }
        }).then(( response ) => {

            let weekData = response;

            // Update mode loops trough old week data and only updates new rows
            if( updateMode ) {
                weekData = this.state.weekData;
                for( let i = 0; i < weekData.length; i++ )
                {
                    for( let j = 0; j < response.length; j++ )
                    {
                        if( weekData[i].date == response[j].date )
                            weekData[i] = response[j];

                    }
                }
            }

            const interpretation = weekInterpretation( weekData, this.state.settings );
            weekData = interpretation.weekData;

            //console.log( 'TES tulkinta: ', interpretation );
            //console.log( 'getEntries', weekData );

            this.setState({
                loading: false,
                weekData: weekData,
                interpretation: interpretation.summary,
            });
            
        }).catch( ( error ) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Virhe kirjausten hakemisessa</strong>,
            });
            this.setState({ loading: false });
        });
    }


  


    getSettings( afterFunc )
    {
        api({
            method: 'get',
            url: 'hour/settings',
        }).then(( settings  ) => {
            
            // Add absence getter
            settings.getHourType = ( id ) => {
                if( this.state.settings.hourtypes )
                    for( let i = 0; i < this.state.settings.hourtypes.length; i++ )
                        if( this.state.settings.hourtypes[i].id == id )
                            return this.state.settings.hourtypes[i];
                return false;
            }

            // Add absence getter
            settings.getAbsence = ( id ) => {
                if( this.state.settings.absences )
                    for( let i = 0; i < this.state.settings.absences.length; i++ )
                        if( this.state.settings.absences[i].id == id )
                            return this.state.settings.absences[i];
                return false;
            }

            // Add allowance getter 
            settings.getAllowance = ( id ) => {
                if( this.state.settings.allowances )
                    for( let i = 0; i < this.state.settings.allowances.length; i++ )
                        if( this.state.settings.allowances[i].id == id )
                            return this.state.settings.allowances[i];
                return false;
            }

            // Add vehicle getter 
            settings.getVehicle = ( id ) => {
                if( this.state.settings.vehicles )
                    for( let i = 0; i < this.state.settings.vehicles.length; i++ )
                        if( this.state.settings.vehicles[i].id == id )
                            return this.state.settings.vehicles[i];
                return false;
            }

            this.setState({ settings: settings }, afterFunc );

        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Virhe asetusten hakemisessa</strong>,
            });
        });
    }

    /**
     * Changes selected week (and year) and get entries for
     * each day of the week (monday -> sunday).
     *
     **/
    changeWeek( year, week )
    {

        //console.log(year);
        //console.log(week);

        year = parseInt(year, 10);
        week = parseInt(week, 10);

        // Get the first day of the week and loop through the week
        const monday = moment().year(year).week(week).startOf("week");
        let days = [];
        for (let i = 0; i < 7; i++) {
            days.push( monday.clone().add(i, 'd').format('YYYY-MM-DD') );
        }

        // Set browsers URL to match selected week/year
        this.props.history.replace(`/workhours/overview/${year}/${week}`);
        
        this.setState({
            selectedYear: year,
            selectedWeek: week,
            selectedWeekdays: [],
        });

        this.getEntries(days);
    }


    selectWeekday( dayIndex ) 
    {
        let selectedWeekdays = this.state.selectedWeekdays.slice();
        let index = selectedWeekdays.indexOf( dayIndex );
        if (index > -1)
            selectedWeekdays.splice(index, 1);
        else 
            selectedWeekdays.push( dayIndex );

        this.setState( { selectedWeekdays } );
    }


    /**
     * Modify and control individual entries
     *
     **/
    handleEntryEdit( action, type, data )
    {
        //console.log('handleEntryEdit', action, type, data );


        if( type == "allowance" || type == "absence" )
            type = "day";

    
        if( action === "add" || action === "edit" )
        {
            const newState = {
                entryEditShow: true,
                entryEditAction: action,
                entryEditData: {
                    type: type,
                    data: data, 
                }
            };
            this.setState( newState );
        }
        else if ( action === "copy" || action === "remove" || action === "clear" )
        {   

            let method = 'post';
            let url = false;
            let params = false;
            let after = false;
   
            if( action === "copy" )
            {
                // Copy single entry to same date (hour or travel)
                if( type == "hour" || type == "travel" || type == "expense" )
                {
                    url = 'hour/entry/copy';
                    params = {
                        id: data,
                        type: type,
                    };
                    after = ( response ) => this.getEntries( response, true );
                }

                // Copy whole day (all entries and settings)
                else if ( type == "day" )
                {
                    
                    const parseTarget = ( target ) => {
                        if( target == "today" )
                            return moment().format('YYYY-MM-DD');
                        
                        else if ( target == "nextDay" )
                            return moment( data.date, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');

                        else if ( target == "endOfTheWeek")
                        {
                            let days = [];
                            let current = moment( data.date, 'YYYY-MM-DD');
                            while ( current.format('d') != 0 ) // 0 == Sunday
                            {   
                                current.add(1, 'day');
                                days.push( current.format('YYYY-MM-DD') );
                            }
                            return days;
                        }
                    };

                    url = 'hour/day/copy';
                    params = {
                        date: data.date,
                        target: parseTarget( data.target ),
                    };
                    after = ( response ) => this.getEntries( response, true );
                }
            }
            else if ( action === "remove" )
            {

                method = "delete";

                if( type == "hour" || type == "travel" || type == "expense" )
                {
                    //if( window.confirm( 'Haluatko varmasti poistaa rivin?') )
                    //{
                    url = 'hour/entry/remove';
                    params = {
                        id: data,
                        type: type,
                    };
                    after = ( response ) => this.getEntries( response, true );
                    //}
                }
            }
            else if ( action === "clear" && type == "day" )
            {  
                if( window.confirm( 'Haluatko varmasti tyhjentää päivän? Toimintoa ei voida peruuttaa. ') )
                {
                    url = 'hour/day/clear';
                    params = {
                        date: data,
                    };
                    after = ( response ) => this.getEntries( response, true );
                }
            }

            if( url && params ) 
            {
                this.setState({ 'loading': true });
                api({
                    method: method,
                    url: url,
                    data: params,
                }).then(( response ) => {

                    //console.log('Done action: ' + action, response );

                    if( typeof( after ) == "function" )
                        after( response );

                }).catch((error) => {
                    console.log( 'Error, action: ' + action, error, params );
                    this.setState({ 'loading': false });
                });
            }


         
        }     
    }

    entrySaved( response )
    {
        //console.log('entrySaved', response );
        this.setState({ entryEditShow: false });

        if( response.date )
            this.getEntries( response.date, true );
    }


    submitDays()
    {

        this.setState({ 'loading': true });
        api({
            method: 'post',
            url: 'hour/days/submit',
            data: {
                days: this.getSelectedDates()
            },
        }).then(( response ) => {

            //console.log( 'Submitted', response );
            this.getEntries( response, true );

        }).catch((error) => {
            
            console.log('submit error', error );
            this.setState({ 'loading': false });

        });

    }


    getSelectedDates()
    {   
        let days = [];
        this.state.weekData.forEach( ( weekday, index ) => {
            // Weekday selected (only if sending partial week)
            if( !this.state.sendPartialWeek || ( this.state.sendPartialWeek && this.state.selectedWeekdays.indexOf( index ) != -1 ) )
            {
                // Weekday not locked
                if( !weekday.locked )
                {
                    if( this.haveEntries( weekday.entries ) )
                        days.push( weekday.date );
                }
            }
        });
        return days;
    }

    haveEntries( entries )
    {
        if( entries.hours.length > 0  || 
            entries.travels.length > 0 ||
            entries.expenses.length > 0 || 
            entries.absence ||
            entries.allowance )
            return true;
        return false;
    }

    getWeekStats()
    {
        let stats = {
            days_total:                     0,
            days_filled:                    0,
            days_absence:                   0,
        
            hours_total:                    0,
            hours_work:                     0,
            hours_absence:                  0,

            travels_distance:               0,
            travels_compensation:           0,
            travels_hours:                  0,
            
            expenses_total:                 0,
            expenses_sum:                   0,

            daily_allowance:                0,

            projects:                       [],
        };

        if( this.state.loading )
            return stats;

        const parseNumber = ( number ) => {
            let value = parseFloat( number );
            if( !isNaN( value ) ) 
                return value;
            return 0;
        };

        const addProject = ( id ) => {
            if( stats.projects.indexOf( id ) == -1 )
                stats.projects.push( id );
        };

        this.state.weekData.forEach( ( weekday, index ) => {

            stats.days_total++;

            if( this.haveEntries( weekday.entries ) )
                stats.days_filled++;

            // All day absence 
            if( weekday.entries.absence && weekday.entries.absence.absence_reason_id )
            {
                stats.days_absence++;

                if( this.state.settings.getAbsence( weekday.entries.absence.absence_reason_id ).worktime )
                    stats.hours_total = Math.round( this.state.settings.contract.hours_daily * 4 ) / 4;
            }
            // Not all day absence
            else 
            {

                // HOURS
                for( let i = 0; i < weekday.entries.hours.length; i++ )
                {
                    const entry = weekday.entries.hours[i];
                    
                    stats.hours_total += parseNumber( entry.hours );

                    if( entry.worktime )
                        stats.hours_work += parseNumber( entry.hours );

                    if( entry.absence )
                        stats.hours_absence += parseNumber( entry.hours );
                 
                    addProject( entry.project_id );
                }

                // TRAVELS
                for( let i = 0; i < weekday.entries.travels.length; i++ )
                {
                    const entry = weekday.entries.travels[i];
                    stats.travels_distance      += parseNumber( entry.distance );
                    stats.travels_compensation  += parseNumber( entry.distance ) * entry.vehicle_compensation;
                    stats.travels_hours         += parseNumber( entry.hours );
                    stats.hours_total           += parseNumber( entry.hours );

                    addProject( entry.project_id );
                }

                // EXPENSES
                for( let i = 0; i < weekday.entries.expenses.length; i++ )
                {
                    const entry = weekday.entries.expenses[i];
                    stats.expenses_total++;
                    
                    for( let j = 0; j < entry.rows.length; j++ )
                    {
                        const rate = entry.rows[j].currency_rate ? entry.rows[j].currency_rate : 1;
                        stats.expenses_sum += parseNumber( entry.rows[j].cost ) / rate; 
                    }

                    addProject( entry.project_id );
                }

                // DAILY ALLOWANCES
                if( weekday.entries.allowance )
                {
                    stats.daily_allowance += parseNumber( weekday.entries.allowance.daily_allowance_compensation );
                    addProject( weekday.entries.allowance.project_id );
                }
            

            }

        });

        return stats;
    }

    settingErrors()
    {
        if( this.state.loading )
            return false;

        let errors = [];
        if( this.state.settings )
        {
            if( this.state.settings.hourtypes.length == 0 )
                errors.push( "Tuntiluokitukset puuttuvat yrityksen asetuksista" );

            /*
            if( this.state.settings.vehicles.length == 0 )
                errors.push( "Km-korvaukset puuttuvat yrityksen asetuksista" );
            
            if( this.state.settings.allowances.length == 0 )
                errors.push( "Päivärahat puuttuvat yrityksen asetuksista" );
            */
            if( this.state.settings.absences.length == 0 )
                errors.push( "Poissaolot puuttuvat yrityksen asetuksista" );

            if( !this.state.settings.contract.hours_daily ||
                !this.state.settings.contract.hours_weekly ||
                !this.state.settings.contract.days_weekly )
                errors.push( "Säännöllistä työaikaa ei ole määritetty");

            if( !this.state.settings.contract.iban )
                errors.push( "Tilinumerosi puuttuu" )
        }

        if( errors.length > 0 )
            return errors;

        return false;
    }

    render() 
    {   
        const settingErrors = this.settingErrors();
        const stats = this.getWeekStats();

        return (
            <div id="pageWorkHours">
                <div className="apBox">
                    <div className="apBoxHeader">
                        <h1>Oma työaika</h1>
                        <p>Kirjaa tekemäsi työtunnit, matkat ja muut kulut järjestelmään.  </p>

                        <div className="apBoxCorner">
                            <ApTooltip text="TES-tulkinta" position="left">
                                <ApSwitch
                                    id="show-interpretation"
                                    on={ this.state.showInterpretation }
                                    onChange={ () => { this.setState( { showInterpretation: !this.state.showInterpretation } ) } }
                                    small
                                />
                            </ApTooltip>
                        </div>

                    </div>
                    <div className="padding">

                        <WeekBrowser 
                            year={ parseInt( this.state.selectedYear, 10 ) } 
                            week={ parseInt( this.state.selectedWeek, 10 ) }
                            onChange={ this.changeWeek }
                        />
                    </div>
                    <div className={ "weekSummary" + ( this.state.weekSummaryOpen ? " open" : "" ) }>
                        <Collapse in={ this.state.weekSummaryOpen }>
                            <div>

                                <div className="apStatBoxContainer">
                                    
                                    <div className="apStatBox">
                                        <SvgIcon icon="calendar" type="solid" />
                                        <div className="padding-small">
                                            Syötetty: { stats.days_filled } pv<br />
                                            Poissa: { stats.days_absence } pv
                                        </div>
                                    </div>

                                    <div className="apStatBox">
                                        <SvgIcon icon="clock" type="solid" />
                                        <div className="padding-small">
                                            Yhteensä: { stats.hours_total } h<br />
                                            <small>Työtunnit: { stats.hours_work } h</small><br />
                                            <small>Poissa: { stats.hours_absence } h</small>
                                        </div>
                                    </div>

                                    <div className="apStatBox">
                                        <SvgIcon icon="car" type="solid" />
                                        <div className="padding-small">
                                            Pituus: { stats.travels_distance } km<br />
                                            Matka-aika: { stats.travels_hours } h<br />
                                            Km-korvaus: { roundToDecimals( stats.travels_compensation, 2 ) } &euro;
                                        </div>
                                    </div>

                                    <div className="apStatBox">
                                        <SvgIcon icon="money-bill-wave" type="solid" />
                                        <div className="padding-small">
                                            Kulut: { roundToDecimals( stats.expenses_sum, 2 ) } &euro;<br />
                                            Matkakorvaukset: { roundToDecimals( stats.daily_allowance, 2 ) } &euro;<br />
                                            Projekteja: { stats.projects.length } kpl
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </Collapse>
                        <div className="toggler" onClick={ () => this.setState({ weekSummaryOpen: !this.state.weekSummaryOpen }) }>
                            <SvgIcon icon="angle-down" type="solid" />
                            { this.state.weekSummaryOpen ? "Sulje" : "Näytä" } yhteenveto
                        </div>
                    </div>
                </div>

                { this.state.loading && 
                    <div className="loader" style={{ position: "relative" }}>
                        <div className="apLoader"></div>
                    </div>
                }

                { !settingErrors && this.state.weekData.map( ( weekday, index ) => {

                    return (
                        <WeekDay
                            key={ index }
                            loading={ this.state.loading }
                            data={ weekday }
                            settings={ this.state.settings }     
                            showInterpretation={ this.state.showInterpretation }
                            onEntryEdit={ this.handleEntryEdit }
                            unselected={ this.state.sendPartialWeek && this.state.selectedWeekdays.indexOf( index ) == -1 }
                        />
                    );

                })}

                { settingErrors &&
                    <div className="apErrorMsg" style={{ textAlign: 'left' }}>
                        <h4>Korjaa seuraavat kohdat, kirjataksesi työaikasi:</h4>
                        <ul>
                            { settingErrors.map( error => <li>{ error }</li> )}
                        </ul>
                    </div>
                }

                <Collapse in={ this.state.showInterpretation && this.state.interpretation }>
                    <div className="interpretation">
                        { this.state.interpretation.errors && this.state.interpretation.errors.length > 0 &&
                            <div className="error">
                                { this.state.interpretation.errors.map( ( row, index ) => 
                                    <div key={ index }>{ row }</div>
                                )}
                            </div>
                        }
                        <div>Kokonaistunnit: { this.state.interpretation.total } h</div>
                        <div>Viikon työtunnit: { this.state.interpretation.normal } h</div>
                        <div>Viikon lisätyöt: { this.state.interpretation.extrawork } h</div>
                        <div>Viikon vuorokautinen ylityö: { this.state.interpretation.overtime_daily } h</div>
                        <div>Viikon viikottainen ylityö: { this.state.interpretation.overtime_weekly } h</div>
                        <div>Viikon liukuman muutos: { this.state.interpretation.flexitime }</div>
                    </div>
                </Collapse>
                
        

                <div className="apBox">
                    <div className="padding">

                        <div className={ "apFormGroup" + ( this.state.sendPartialWeek ? " success" : "" ) }>
                            <div className="apSwitchBlock small">
                                <label htmlFor="send-partial-week-switch" className="info">
                                    Lähetä osittainen viikko
                                    <small>Voit lähettää viikon myös osissa valitsemalla haluamasi viikonpäivät</small>
                                </label>
                                <ApSwitch
                                    id="send-partial-week-switch"
                                    on={ this.state.sendPartialWeek }
                                    onChange={ () => { this.setState( { sendPartialWeek: !this.state.sendPartialWeek } ) } }
                                    small
                                />
                            </div>

                            <Collapse in={ this.state.sendPartialWeek }>
                                <div>
                                    <br />
                                    <strong>Valitse lähetettävät viikonpäivät ({ this.state.selectedWeekdays.length }/7)</strong>
                                    <div className="apOptionBar">
                                        { ["Ma", "Ti", "Ke", "To", "Pe", "La", "Su"].map( (weekday, index) => {
                                            
                                            let classes = [ "option" ];
                                            
                                            if( this.state.selectedWeekdays.indexOf( index ) != -1 )
                                                classes.push( "selected" );

                                            if( this.state.weekData[index] && this.state.weekData[ index ].locked )
                                                classes.push( "disabled" );
                                            
                                            return (
                                                <div key={index} className={ classes.join(" ") } onClick={ () => this.selectWeekday( index ) }>
                                                    { weekday }
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </Collapse>
                        </div>

                        <ApButton color="blue" disabled={ this.state.loading || this.getSelectedDates().length == 0 } onClick={ this.submitDays } loading={ this.state.loading }>
                            <SvgIcon icon="paper-plane" type="solid" />
                            Lähetä { !this.state.sendPartialWeek || this.state.selectedWeekdays.length == 7 ? " viikko" : "valitut päivät" }
                        </ApButton>
                        
                    </div>
                </div>
                
                <EntryEdit
                    show={ this.state.entryEditShow }
                    action={ this.state.entryEditAction }
                    type={ this.state.entryEditData.type }
                    data={ this.state.entryEditData.data }
                    settings={ this.state.settings }
                    onSave={ this.entrySaved }
                    onClose={ ( unsavedChanges ) => {
                        if( unsavedChanges )
                        {
                            if( window.confirm( "Tekemiäsi muutoksia ei ole vielä tallennettu. Haluatko varmasti peruuttaa? ") )
                                this.setState({ entryEditShow: false });
                        }
                        else 
                            this.setState({ entryEditShow: false });
                    }}
                />
                    
            </div>
        );
    }
}
  
export default WorkHours;
