import ApReactTable, { colPreset } from 'common/ApReactTable/ApReactTable';
import React from 'react';
import autobind from 'react-autobind';
import api from 'services/Api/Api';
import { errorPopper, getMinMax, tr } from 'services/Helpers/Helpers';
import _ from 'lodash';
import axios from "axios";
import ApTooltip from 'common/ApTooltip/ApTooltip';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import EditProduct from './EditProduct';
import ApModalInput from 'common/ApModalInput/ApModalInput';
import ApButton from 'common/ApButton/ApButton';
import ExportProductModal from './ExportProductModal';

class WooCommerceProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            cancelToken: axios.CancelToken.source(),

            columns: [],
            data: [],
            pages: 0,

            showEditModal: false,
            selectedProduct: null,

            manualLink: null,
            showExportModal: false,
        };
        autobind(this);
    }

    componentDidMount() {
        // this.getData();
    }

    getData(state = null) {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        const data = {
            page: 0,
            per_page: 20
        };
        if (state) {
            data.page = state.page;
            data.per_page = state.pageSize;
            if (state.filtered.length) {
                if (state.filtered.find(f => f.id === 'name')) {
                    data.search = state.filtered.find(f => f.id === 'name').value;
                }
                if (state.filtered.find(f => f.id === 'sku')) {
                    data.sku = state.filtered.find(f => f.id === 'sku').value;
                }
                if (state.filtered.find(f => f.id === 'status')) {
                    data.status = state.filtered.find(f => f.id === 'status').value === 'true' ? 'publish' : 'private';
                }
            }
        }

        let cancelToken = axios.CancelToken.source();
        this.setState({ loading: true, cancelToken });
        
        api({
            method: 'post',
            url: 'woocommerce/products',
            data,
            cancelToken: cancelToken.token,
        }).then((response) => {
            // console.log('RES', response);
            const data = _.orderBy(response.data, 'name');
            this.setState({data, pages: response.pages, loading: false, cancelToken: null});
        }).catch((error) => {
            this.setState({cancelToken: null})
            if (axios.isCancel(error)) { return null };
            this.setState({loading: false});
            errorPopper(error, tr('get_error'));
        })
    }

    syncComponents(indexes) {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        const products = _.cloneDeep(this.state.data);
        let data = [];
        if (indexes.length) {
            data = indexes.map(index => products[index]);
        } else {
            data = products;
        }

        let cancelToken = axios.CancelToken.source();
        this.setState({ loading: true, cancelToken });
        
        api({
            method: 'post',
            url: 'woocommerce/sync/products',
            cancelToken: cancelToken.token,
            data: {products: data}
        }).then((response) => {
            // console.log('RES', response);
            this.setState({loading: false, cancelToken: null});
            this.getData();
        }).catch((error) => {
            this.setState({loading: false, cancelToken: null})
            if (axios.isCancel(error)) { return null };
            errorPopper(error, tr('get_error'));
        })
    }
    
    saveManualLink(stock) {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        let cancelToken = axios.CancelToken.source();
        this.setState({ loading: true, cancelToken });
        
        api({
            method: 'post',
            url: 'woocommerce/sync/products/manual',
            cancelToken: cancelToken.token,
            data: {
                stockId: stock.id,
                productId: this.state.manualLink
            }
        }).then((response) => {
            // console.log('RES', response);
            this.setState({loading: false, cancelToken: null, manualLink: null});
            this.getData();
        }).catch((error) => {
            this.setState({loading: false, cancelToken: null, manualLink: null})
            if (axios.isCancel(error)) { return null };
            errorPopper(error, tr('get_error'));
        })
    }
    
    removeLink(indexes) {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        const data = _.cloneDeep(this.state.data).map(item => item.id);
        const products = indexes.map(index => data[index]);

        let cancelToken = axios.CancelToken.source();
        this.setState({ loading: true, cancelToken });
        
        api({
            method: 'post',
            url: 'woocommerce/sync/products/remove',
            cancelToken: cancelToken.token,
            data: {
                products,
            }
        }).then((response) => {
            // console.log('RES', response);
            this.setState({loading: false, cancelToken: null});
            this.getData();
        }).catch((error) => {
            this.setState({loading: false, cancelToken: null})
            if (axios.isCancel(error)) { return null };
            errorPopper(error, tr('get_error'));
        })
    }

    updatePriceAndBalance(indexes) {
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel("Operation canceled by the user.");
        }

        const products = _.cloneDeep(this.state.data);
        let data = [];
        if (indexes.length) {
            data = indexes.map(index => products[index]);
        } else {
            data = products;
        }

        data = data.filter(item => item.easypro_stock);

        let cancelToken = axios.CancelToken.source();
        this.setState({ loading: true, cancelToken });
        
        api({
            method: 'post',
            url: 'woocommerce/update/pricesbalances',
            cancelToken: cancelToken.token,
            data: {products: data}
        }).then((response) => {
            // console.log('RES', response);
            this.setState({loading: false, cancelToken: null});
            this.getData();
        }).catch((error) => {
            this.setState({loading: false, cancelToken: null})
            if (axios.isCancel(error)) { return null };
            errorPopper(error, tr('get_error'));
        })
    }
    openProductEdit(row) {
        this.setState({selectedProduct: row.id, showEditModal: true});
    }

    getColumns() {
        const columns = [
            {
				Header: tr('link'),
                className: 'overflowableOnHover',
				accessor: 'easypro_stock',
				customFilter: {
					type: "toggle",
					placeholder: tr('link'),
				},
                Cell: (props) => {
					if (props.value)
						return <div className="text-center pointer"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                    else
                        return <div className="text-center pointer">
                            <ApTooltip position='right' text={tr('storage_component_no_link')}>
                                <SvgIcon fill="#ff8800" icon="exclamation-triangle" type="solid" />
                            </ApTooltip>
                        </div>
				},
                sortable: true,
                filterable: false, // TODO filter with manual filtering
                customizable: false,
                showInitially: true,
                width: 100,
                onClick: (props) => {
                    if (!props.easypro_stock) {
                        this.setState({manualLink: props.id})
                    }
                }
			},
            {
				Header: tr('name'),
				accessor: 'name',
				customFilter: {
					type: "text",
					placeholder: tr('name'),
					fields: ["name", "sku"]
				},
                sortable: true,
                filterable: true,
                customizable: false,
                showInitially: true,
                onClick: this.openProductEdit
			},
            {
				id: 'categories',
                Header: tr('categories'),
                headerClassName: "overflowable",
                className: "overflowableOnHover",
                customizable: true,
                showInitially: ( this.props.instance === 'management' ),
                filterable: false,
                sortable: true,
                showInitially: true,
                // customFilter: {
                //     type: "select",
                //     placeholder: tr('categories'),
                //     optionRenderer: 'label',
                //     options: this.getCategories(),
                // },

                Cell: props => {
                    if (props.original.categories && props.original.categories.length > 1) {
                        return <ApTooltip text={props.original.categories.map((category, index) => <div key={index}>{category.name} <br/></div>)}>
                            {`${tr('categories')}: ${props.original.categories.length}`}
                        </ApTooltip>
                    } else {
                        return props.original.categories[0].name
                    }
                },
			},
            colPreset({
                    type: 'currency',
                    Header: tr('price'),
                    accessor: 'price',
                    customFilter: {
                        type: "range",
                        placeholder: tr('price'),
                        min: getMinMax('price', 'min', this.state.data),
                        max: getMinMax('price', 'max', this.state.data),
                    },
                    sortable: true,
                    filterable: false,
                    customizable: true,
                    showInitially: true,
                    width: 100,
                },
            ),
            colPreset({
                type: 'number',
				Header: tr('amount'),
				accessor: 'stock_quantity',
				customFilter: {
					type: "range",
					placeholder: tr('amount'),
                    min: getMinMax('stock_quantity', 'min', this.state.data),
                    max: getMinMax('stock_quantity', 'max', this.state.data),
				},
                sortable: true,
                filterable: false,
                customizable: true,
                showInitially: true,
                width: 100,
			}),
            {
				Header: tr('identifier'),
				accessor: 'sku',
				customFilter: {
					type: "text",
					placeholder: tr('identifier'),
					fields: ["sku"]
				},
                sortable: true,
                filterable: false, // WooCommerce requires exact match when searching
                customizable: true,
                showInitially: true,
			},
            {
                Header: tr('status'),
                className: 'overflowableOnHover',
                accessor: 'status',
                customFilter: {
                    type: "toggle",
                    placeholder: tr('status'),
                },
                Cell: (props) => {
                    if (props.value == 'publish')
                        return <div className="text-center pointer"><SvgIcon fill="#007E33" icon="check-circle" type="solid" /></div>
                    else
                        return <div className="text-center pointer">
                            <SvgIcon fill="#ff8800" icon="exclamation-triangle" type="solid" />
                        </div>
                },
                sortable: true,
                filterable: true,
                customizable: false,
                showInitially: true,
                width: 50,
            },
        ];

        return columns;
    }

    getCategories() {
        const categoryOptions = [];
        if (this.state.data.length) {
            this.state.data.forEach(product => {
                if (Array.isArray(product.categories)) {
                    product.categories.forEach(category => {
                        const foundCategory = categoryOptions.find(c => category.id == c.value);
                        if (!foundCategory) {
                            categoryOptions.push({
                                value: category.id,
                                label: category.name,
                            });
                        }
                    });
                }
            });
        }

        _.orderBy(categoryOptions, 'label');
        categoryOptions.unshift({value: '', label: tr('all')});

        return categoryOptions;

    }

    closeModal(refresh) {
        this.setState({showEditModal: false, selectedProduct: null});

        if (refresh) {
            this.getData();
        }
    }
    
    closeExportModal(refresh = false) {
        this.setState({showExportModal: false});

        if (refresh) {
            this.getData();
        }
    }

    render() {
        return (
            <div className="padding">
                <ApReactTable 
                    loading={this.state.loading}
                    columns= {this.getColumns()}
                    data={this.state.data}
                    filterable
                    rowActions={ (row) => 
						<div className="apSimpleButton" onClick={ () => this.setState({selectedProduct: row.id, showEditModal: true})}>
                            <SvgIcon icon="edit" type="solid" />
                        </div>
					}
                    manual
                    onFetchData={(state) => {
                        this.getData(state)
                    }}
                    pages={this.state.pages}
                    multiselect={[
                        {
                            icon: "link",
                            label: tr('link_products'),
                            action: ( indexes ) => this.syncComponents(indexes),
                            unselectAfter: true,
                            closeAfter: true,
                            alwaysOn: true
                        },
                        {
                            icon: "unlink",
                            label: tr('remove_link'),
                            action: ( indexes ) => this.removeLink(indexes),
                            unselectAfter: true,
                            closeAfter: true,
                        },
                        {
                            icon: "sync",
                            label: tr('export_storage_components_online_store'),
                            action: ( indexes ) => this.setState({showExportModal: true}),
                            unselectAfter: true,
                            closeAfter: true,
                            alwaysOn: true
                        },
                        {
                            icon: "warehouse",
                            label: tr('update_price_balance'),
                            action: ( indexes ) => this.updatePriceAndBalance(indexes),
                            unselectAfter: true,
                            closeAfter: true,
                            alwaysOn: true
                        },
                    ]}
                />

                <EditProduct 
                    onClose={this.closeModal}
                    selectedProduct={this.state.selectedProduct}
                    show={this.state.showEditModal}
                />

                <ExportProductModal
                    onClose={this.closeExportModal}
                    show={this.state.showExportModal}
                />

                <div id='link-product-modal'>
                    <ApModalInput
                        show={ Boolean(this.state.manualLink) }
                        value={ this.state.modalValue }
                        onSave={ ( value ) => this.saveManualLink( value ) }
                        onClose={ () => this.setState({ manualLink: null }) }
                        title={ tr('link_product_with_storage_component') }
                        label={tr('link_product_with_storage_component')}
                        type='selectStock'
                        required
                    />
                </div>
            </div>
        );
    }
}

export default WooCommerceProducts;