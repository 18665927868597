import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import ApButton from 'common/ApButton/ApButton.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import moment from 'moment';

import EditNewsModal from './EditNewsModal.js';
import { tr } from 'services/Helpers/Helpers.js';

export default class News extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			showEditModal: false,
			newsitems: [],
			columns: [],
			selectedItemId: null,
			loading: false
		}

		autoBind(this);

	}

	componentDidMount()
	{
		this.getNewsitems();
	}

	getNewsitems()
	{

		this.setState({ loading: true });
		api({
			method: 'get',
			url: 'management/news/getNewsItems'
		}).then(async (response) => {
			await this.setState({ newsitems: response, loading: false });
			//console.log(response)
		}).catch((error) => {
			console.log(error);
			this.setState({ loading: false });
		});

	}

	getColumns()
	{

		return [
			{
				Header: 'Otsikko',
				accessor: 'header',
				onClick: this.openItem
			},
			{
				Header: 'Sisältö',
				accessor: 'content'
			},
			{
				Header: 'Laatija',
				accessor: 'full_name'
			},
			{
				Header: 'Luotu',
				accessor: 'created_at',
				Cell: (props) => {
					return (
						<div>
							{moment( props.original.created_at ).format( 'D.M.YYYY [klo] HH.mm' )}
						</div>
					);
				}
			},
			{
				Header: 'Näkyvyys',
				accessor: 'limited',
				Cell: (props) => {
					return (
						<div>
							{props.original.limited ? tr('only_administrator') : tr('all')}
						</div>
					);
				}
			},
			{
				Header: 'Näkyvissä',
				accessor: 'hidden',
				Cell: (props) => {
					return (
						<div>
							{this.getStatusIcon(props.original)}
						</div>
					);
				}
			},
			
		];

	}

	createNewItem()
	{

		this.setState({ showEditModal: true });

	}

	openItem(item)
	{

		this.setState({ selectedItemId: item.id, showEditModal: true });

	}

	closeEditModal()
	{

		this.setState({ selectedItemId: null, showEditModal: false }, () => {
			this.getNewsitems();
		});

	}

	switchVisibility(id)
	{

		//console.log(id);
		
		api({
			method: 'post',
			url: 'management/news/switchNewsitemVisibility',
			data: { id }
		}).then((response) => {
			this.getNewsitems();
		}).catch((error) => {
			console.log(error);
		});

	}

	getStatusIcon(item)
	{

		let element = null;

		if (!item.hidden)
		{
			element = 
				<div 
					style={{cursor: 'pointer', textAlign: 'center'}}
					onClick={() => this.switchVisibility(item.id)}
				>
					<SvgIcon fill="#4c0" icon="check-circle" />
				</div>;		
		}
		else 
		{
			element = 
				<div 
					style={{cursor: 'pointer', textAlign: 'center'}}
					onClick={() => this.switchVisibility(item.id)}
				>
					<SvgIcon fill="#c40" icon="times-circle" />
				</div>;			
		}

		return element;
	}

	render()
	{
		return(

			<div className="apBox" id="extranetNewsSettings">

				<div className="apBoxHeader">
					<h1> Uutiset </h1>
					<p>Hallinnoi etusivulla näkyviä ilmoituksia.</p>
				</div>

				<div className="padding">
					<ApButton
						color="green"
						onClick={() => {this.createNewItem()}}
					>
                        <SvgIcon icon="plus" type="solid" />
						Lisää uutinen
					</ApButton>
				</div>

				{this.state.newsitems && !this.state.loading ?
				<div className="padding">
					<ApReactTable
						data={this.state.newsitems}
						columns={this.getColumns()}
						rowActions={ (row) => (
							<div className="apSimpleButton" onClick={ () => this.openItem(row) }>
								<SvgIcon icon="arrow-right" type="solid" />
							</div>
						)}
					/>
				</div>
				:
				<div className="apMsg">
					<div className="apInlineLoader"></div>
				</div>
				}

				<EditNewsModal 
					show={this.state.showEditModal}
					onClose={this.closeEditModal}
					itemId={this.state.selectedItemId}
				/>
               

			</div>



		);

	}

}
