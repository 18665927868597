import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';

import { ApTabs, ApTab } from '../../../common/ApTabs/ApTabs.js';
import api from 'services/Api/Api.js';
import auth from   'services/Authed/Authed.js';
import { hasPermissions, tr } from 'services/Helpers/Helpers.js';

import UserHolidays from './tabs/UserHolidays.js';
import OthersHolidays from './tabs/OthersHolidays.js';

import HolidaySections from './HolidaySections.js';
import HolidaysReport from './tabs/HolidaysReport.js';

class AnnualHolidays extends React.Component {

	constructor( props ) 
	{
		super(props);
        this.state = {
            loading: false,
            settings: [],
            open_schedules: []
        };
        autoBind(this);
    }
    
    componentDidMount()
    {
        this.getSettings();
    }

    openTab( page )
    {
        this.props.history.push( `/holidays/${ page }` );
    }

    getSettings( )
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'timetracking/holiday/personalsettings',
        }).then((response) => { //console.log(response)
            response.open_schedules.forEach(item => {
                item.modalName = item.name;
                item.name = `${item.name} (${moment(item.start_date).format('DD.MM.YYYY')} - ${moment(item.end_date).format('DD.MM.YYYY')})`;
                item.start = item.start_date;
                item.end = moment(item.end_date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
            })

            this.setState({
                open_schedules: response.open_schedules,
                settings: response,
                loading: false
            });
        }).catch((error) => {
            console.log('Error', error);
            this.setState({ loading: false });
        });
    }

    render()
    {
        return (
            <div className="apBox">
            
                <div className="apBoxHeader">
            	   <h1>{ tr('annual_leave') }</h1>
                </div>

                <ApTabs>

                    <ApTab icon="user" label={ tr('own') }>
                        <UserHolidays 
                            loading={this.state.loading}
                            settings={this.state.settings}
                            open_schedules={this.state.open_schedules}
                            getSettings={this.getSettings}
                        />
                    </ApTab>

                    { hasPermissions('timetracking.holiday.annualholidays.subordinates') && 
                    <ApTab icon="user-friends" label={ tr('subordinates') }>
                        <OthersHolidays
                            subordinates={true}
                            getSettings={this.getSettings}
                        />
                    </ApTab>
                    }
                    
                    { hasPermissions('timetracking.holiday.annualholidays.master') && 
                    <ApTab icon="users" label={ tr('personnel') }>
                        <OthersHolidays 
                            getSettings={this.getSettings}
                        />
                    </ApTab>
                    }
                    
                    { hasPermissions('timetracking.holiday.annualholidays.master') && 
                    <ApTab icon="chart-pie" label={ tr('report') } mountOnSelect>
                        <HolidaysReport />
                    </ApTab>
                    }

                </ApTabs>

            </div>
        );

    }
}
export default AnnualHolidays
