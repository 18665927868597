/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';

import api           from 'services/Api/Api.js';
import { errorPopper,
         pluralizeCount,
         tr } from 'services/Helpers/Helpers.js';

import ApConfirm     from 'common/ApConfirm/ApConfirm.js';
import ApReactTable  from 'common/ApReactTable/ApReactTable.js';
import ApButton      from 'common/ApButton/ApButton.js';
import SvgIcon       from 'common/SvgIcon/SvgIcon.js';
import { ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApSelect			from 'common/ApSelect/ApSelect.js';

import NewUser from 'modules/UserManagement/NewUser//NewUser.js';
import UserEdit from 'modules/UserManagement/UserEdit//UserEdit.js';


import './UserTable.css';

/*
TODO features: 
    - Add optional columns
    - More multiselect functions for rows (enable/disable user, add group, etc.)
    - ...
*/

class UserTable extends React.Component
{

    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            users: [],
            columns: [
                {
                    Header: `${tr('person')} / ${tr('username')}`,
                    columns: [
                        { 
                            id: 'is_active', 
                            Header: tr('status'), 
                            align: "center", 
                            width: 150, 
                            Cell: (props) => {
                                let status = "error";
                                let text = tr('inactive');
                                if( props.original.is_active && props.original.is_login_allowed )
                                {
                                    status = "success";
                                    text = tr('active');
                                }
                                else if ( props.original.is_active )
                                {
                                    status = "warning";
                                    text = tr('invalid');
                                }
                                return <div className={ "apStatusBox " + status }>{ text }</div>;
                                    
                            }, 
                            accessor: ( props ) => {
                                return props.is_active + props.is_login_allowed;
                            }, 
                            filterMethod: ( filter, row ) => {
                                if( filter.value === "all" )
                                    return true;
                                if( filter.value === "active" )
                                    return ( row._original.is_active && row._original.is_login_allowed ? true : false );
                                if( filter.value === "invalid" )
                                    return ( row._original.is_active && !row._original.is_login_allowed ? true : false );
                                if( filter.value === "inactive" )
                                    return row._original.is_active == false; 
                                return false;
                            }, 
                            Filter: ({ filter, onChange }) => 
                                <select
                                    onChange={ e => onChange( e.target.value ) }
                                    onMouseDown={ e => {
                                        e.stopPropagation();
                                        onChange( e.target.value )
                                    } } // Fixes FireFox bug (https://github.com/facebook/react/issues/12584)
                                    style={{ width: "100%" }}
                                    value={ filter ? filter.value : "active" }
                                >
                                    <option value="all">{ tr('all') }</option>
                                    <option value="active">{ tr('active') }</option>
                                    <option value="invalid">{ tr('invalid') }</option>
                                    <option value="inactive">{ tr('inactive') }</option>
                                </select>
                        },
                        { 
                            id: 'name', 
                            Header: tr('name'), 
                            onClick: (row) => this.handleEditClick( row.id ), 
                            Cell: ( props ) => {
                                
                                let name = <span style={{ color: "#aaa" }}>{ tr('name_missing') }</span>;
                                if( props.original.person_detail.first_name || props.original.person_detail.last_name )
                                    name = props.original.person_detail.last_name + " " + props.original.person_detail.first_name;
                                
                                return (
                                    <div className="nameCell">
                                        <strong>{ name }</strong><br />
                                        <small>{ props.original.contract_detail.job_description ? props.original.contract_detail.job_description : <span style={{ color: "#aaa" }}>{ tr('no_job_description') }</span> }</small>
                                    </div>
                                );
                            }, 
                            accessor: ( props ) => {
                                return props.person_detail.last_name + " " + props.person_detail.first_name;
                            }, 
                            customFilter: {
                                type: "text",
                                placeholder: tr('name_or_job_description'),
                                fields: [
                                    "_original.person_detail.full_name",
                                    // "_original.person_detail.last_name",
                                    "_original.contract_detail.job_description" 
                                ]
                            },
                            // filterMethod: ( filter, rows ) => matchSorter( rows, filter.value, { keys: [ 
                            //     "_original.person_detail.first_name", 
                            //     "_original.person_detail.last_name", 
                            //     "_original.contract_detail.job_description" 
                            // ]}), 
                            // filterAll: true
                        },
                    ]
                },
                {
                    Header: tr('permissions'),
                    columns: [
                        { 
                            id: 'supervisor', 
                            Header: tr('user_groups'), 
                            // accessor: "groups", 
                            accessor: props => {
                                if (props.is_limited) return tr('basic_user');
                                if (props.is_admin) return tr('administrator');
                                if (Array.isArray(props.groups) && props.groups[0]) return props.groups[0].name;
                            },
                            onClick: (row) => this.handleEditClick( row.id ), 
                            Cell: (props) => {

                                if( props.original.is_limited )
                                    return <span><strong>{ tr('basic_user') }</strong><br /><small>{ tr('basic_user_permissions') }</small></span>

                                if( props.original.is_admin )
                                    return <span><strong>{tr('administrator')}</strong><br /><small>{tr('all_permissions')}</small></span>
                                
                                //TODO:
                                //tempporary remove when fixed back. Too slow queries
                                return <span><strong>{tr('official')}</strong><br /><small>{}</small></span>

                                let groups = [];
                                let modules = [];
                                if( props.original.groups )
                                { 
                                    props.original.groups.forEach( g => {
                                        groups.push( g.name );
                                        g.modules.forEach( m => {
                                            if( modules.indexOf( m.id ) == -1 )
                                                modules.push( m.id );
                                        });
                                    });
                                }
                                return (
                                    <span>
                                        { groups.length > 0 ? groups.join(", ") : "-" }<br />
                                        <small>{ pluralizeCount( props.original.modules_count, tr('one_permission'), tr('many_permissions') ) }</small>
                                    </span>
                                );  
                            },
                            filterable: false,
                        },
                    ]
                }
            ],
            usersRemaining: null,

            newUserShow: false,

            userModalShow: false,
            userModalID: null,

            removeConfirmShow: false,
            removeIndexes: [],
        };

        this.tableState = {};
        autoBind(this);
    }

    componentDidMount()
    {
        this.getUsersApt();
    }

    getUsersApt(state = null)
    {
        if(!state)
            state = this.tableState;
        this.tableState = state;

        this.setState({ loading: true});
        /*
        api({
            method: 'post',
            url: 'usermanagement/users/apt',
            data: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                company_id: this.props.companyId ? this.props.companyId : null,
            },
        }).then((response) => {
            console.log( 'ressponse APT: ', response );
            this.setState({
                users: response.data,
                pages: response.pages,
                loading: false,
            });
            console.log( 'USERS', response.data );
        }).catch((error) => {
            console.error(error);
            this.setState({loading: false});
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Käyttäjien haussa tapahtui virhe</strong>,
            });
        });
        */
        api({
            method: 'post',
            url: 'usermanagement/users',
            data: {
                company_id: this.props.companyId ? this.props.companyId : null,
            },
        }).then((response) => {
            this.setState({
                users: response.users,
                usersRemaining: response.remaining,
                useNetvisorPayroll: response.useNetvisorPayroll,
                loading: false,
            });
            //console.log( 'USERS', response.users, "remaining: " + response.remaining );
        }).catch((error) => {
            errorPopper( error, tr('get_error') );
            this.setState({loading: false});
        });

    }

    handleNewClick()
    {
        this.setState({
            userModalShow: true,
            userModalID: null,
        });
    }

    handleEditClick(id = null)
    {
        this.setState({
            userModalShow: true,
            userModalID: id,
        });
    }


    confirmRemoveUsers( indexes )
    {
        this.setState({
            removeIndexes: indexes,
            removeConfirmShow: true
        });
    }

    // Convert selected indexes to ids 
    index2id( indexes )
    {
        if( Array.isArray( indexes ) )
        {
            let ids = [];
            indexes.map( i => ids.push( this.state.users[ i ].id ) );
            return ids;
        }
        else if( typeof( indexes ) == "number" || typeof( indexes ) == "string" )
        {
            return this.state.users[ parseInt( indexes, 10 ) ].id;
        }
        return false;
    }

    removeUsers()
    {
        const ids = this.index2id( this.state.removeIndexes );
        api({
            method: 'post',
            url: 'usermanagement/user/remove',
            data: {
                ids: ids,
            }
        }).then((response) => {
            
            this.getUsersApt();

        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Error removing user</strong>,
            });
        });
    }

    syncUsers(ids = []) {
        if (ids.length) {
            ids = this.index2id(ids)
        }
        this.setState({loading:true});
        api({
            method: 'post',
            url: 'usermanagement/user/sync',
            data: {ids}
        }).then((response) => {
            // console.log("RES", response);

            let successCounter = 0;
            const errors = [];

            const successful = response.forEach(item => {
                if (typeof(item.ResponseStatus.Status) === 'string') {
                    if (item.ResponseStatus.Status === 'OK') {
                        successCounter++;
                    }
                } else if (Array.isArray(item.ResponseStatus.Status)) {
                    if (item.ResponseStatus.Status[0] === 'FAILED') {
                        if (item.ResponseStatus.Status[1].includes("INVALID_DATA :: Tiedon muoto virheellinen:. Palkansaajan tiedot ovat jo olemassa:")) return;
                        errors.push(item);
                    }
                }
            });

            if (errors.length > 0) {
                alert(errors.map(error => `${error.user} ${tr('failed')}: ${error.ResponseStatus.Status[1]} \n \n`).join(''));
            }

            window.emitter.emit('popper', {
                type: 'info',
                content: <strong>{tr('successful_person_export', [successCounter])}</strong>,
            });

            this.setState({loading:false});
        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{tr('synchronize_error')}</strong>,
            });
            this.setState({loading:false});
        });
    }

    render()
    {   

        let activeUsers = 0;
        let adminUsers = 0;
        this.state.users.forEach( user => {
            if( user.is_active ) activeUsers++;
            if( user.is_admin ) adminUsers++;
        });

        const multiselectActions = [{ 
            icon: "trash-alt", 
            label: tr('delete_selected'), 
            action: (indexes) => this.confirmRemoveUsers(indexes) 
        }];
        
        if (this.state.useNetvisorPayroll) {
            multiselectActions.push({ 
                icon: "sync-alt", 
                label: tr('export_personnel_to_netvisor'), 
                action: (indexes) => this.syncUsers(indexes), 
                alwaysOn: true 
            })
        }

        return(
            <div id="UserTable">

                <div className="userCount">

                    <div className="apInfo small hideUnder700">
                        <SvgIcon icon="info-circle" type="solid" />
                        { tr('company_users_info', [pluralizeCount( this.state.users, tr('users_count_one'), tr('users_count_many') ), pluralizeCount( activeUsers, tr('users_count_one'), tr('users_count_many') ), pluralizeCount( adminUsers, tr('users_count_one'), tr('users_count_many') )]) }
                    </div>

                    <ApButton 
                        color="green" 
                        onClick={ () => this.setState({ newUserShow: true }) } 
                        disabled={ parseInt( this.state.usersRemaining, 10 ) <= 0 }
                    >
                        <SvgIcon icon="user-plus" type="solid" />
                        { tr('new_user') }
                    </ApButton>
                
                </div>

                <ApInputStack gap="0">
		                	<ApAddon noRightBorder width="50px" labelFor="search">
		                		<SvgIcon icon="search" type="solid" />
		                	</ApAddon>
			                <ApSelect
                                value=""
                                optionRenderer={ (item) => {
                                	
                                		return (
                                			<div className="searchOption usertable">
	                                			<SvgIcon className="typeIcon" icon="user" type="solid" />
	                                			<strong>{ item.name }</strong><br />
	                                			<small> { item.title }</small>
	                                		</div>
                                		);
                                }}
                                onChange={ ( item ) => { this.handleEditClick( item.id ) } }
                                objKeyId="id"
                                clearable
                                filterNonActives={true}
                                apiUrl="search/user"
                            />
		                </ApInputStack>

                <ApReactTable 

                    //manual
                    //onFetchData={ this.getUsersApt }
                    //pages={ this.state.pages }

                    loading={ this.state.loading }
                    data={ this.state.users } 
                    columns={ this.state.columns }
                    filterable
                    // multiselect={[
                    //     //{ icon: "toggle-on", label: "Aktivoi valitut", action: ( indexes ) => { console.log('activate', indexes ); } },
                    //     //{ icon: "toggle-off", label: "Poista valitut käytöstä", action: ( indexes ) => { console.log('deactivate', indexes ); } },
                    //     { icon: "trash-alt", label: tr('delete_selected'), action: ( indexes ) => this.confirmRemoveUsers( indexes ) },
                        
                    // ]}
                    multiselect={multiselectActions}
                    rowActions={ (row) => 
                        <div className="apSimpleButton" onClick={ () => this.handleEditClick( row.id ) }><SvgIcon icon="user-cog" type="solid" /></div>
                    }
                    minRows={10}
                    defaultSorted={[
                        { id: "name", desc: false }
                    ]}
                    defaultFiltered={[{id: "is_active", value: "active"}]}
                    showFiltersInitially={true}
                />
                {/* {this.state.useNetvisorPayroll &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ApButton color="green" onClick={this.syncUsers}>
                            <SvgIcon type="solid" icon="sync-alt" /> {tr('export_personnel_to_netvisor')}
                        </ApButton>
                    </div>
                } */}
                <div>

                    <NewUser
                        show={ this.state.newUserShow }
                        onClose={ () => this.setState({ newUserShow: false }) }
                        onSave={ this.getUsersApt }
                        companyId={ this.props.companyId }
                    />

                    <UserEdit
                        show={ this.state.userModalShow }
                        userID={ this.state.userModalID }
                        companyID={ this.props.companyId }
                        companySuffix={ this.props.companySuffix }
                        onClose={ () => this.setState({ userModalShow: false }) }
                        onSave={ () => this.getUsersApt() }
                    />

                    { this.state.removeConfirmShow && 
                        <ApConfirm
                            show={ this.state.removeConfirmShow }
                            onClose={() => {this.setState({removeConfirmShow: false})}}
                            onConfirm={ this.removeUsers }
                            header={ this.state.removeIndexes.length > 1 ? "Vahvista käyttäjien poisto" : "Vahvista käyttäjän poisto" }
                            body={ 
                                <div>
                                    <p>
                                        <strong>Oletko varma että haluat poistaa { this.state.removeIndexes.length == 1 ? "käyttäjän" : this.state.removeIndexes.length + " käyttäjää" }?</strong>
                                        <ul>
                                            { this.state.removeIndexes.map( i =>
                                                <li key={ i }>{ this.state.users[ i ].person_detail.last_name + " " + this.state.users[ i ].person_detail.first_name }</li>
                                            )}
                                        </ul>
                                    </p>
                                    <p>{ tr('delete_user_note') }</p>
                                </div>
                            }
                        />
                    }
                </div>
            </div>
        );
    }

}

export default UserTable;
