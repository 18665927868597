import { ApTabs, ApTab } from "common/ApTabs/ApTabs";
import React from "react";
import autoBind from "react-autobind";
import { tr } from "services/Helpers/Helpers";
import UserTimetrackingReport from "./UserReports/UserTimetrackingReport";
import "./UserReports/UserReports.css";
import WorkloadReport from "modules/Projects/Reports/WorkloadReport";
import TimeAttendanceSavePoints from "modules/Timetracking/TimeAttendance/TimeAttendanceSavePoints";

class UserReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        autoBind(this);
    }

    render() {
        return <div className="padding">
            <ApTabs>
                <ApTab icon="calendar-alt" label={tr('timetracking_entries')}>
                    <UserTimetrackingReport />
                </ApTab>
                <ApTab icon="calendar-alt" label={tr('planned_workload')}>
                    <WorkloadReport currentUserReport />
                </ApTab>
                <ApTab icon="id-card" label={tr('time_attendance')} mountOnSelect>
                    <TimeAttendanceSavePoints userReport={true} />
                </ApTab>
            </ApTabs>
        </div>;
    }
}

export default UserReports;