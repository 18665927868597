import React from "react";
import autobind from "react-autobind";
import ContractBillingList from "./ContractBillingList";
import NewContractBillingModal from "./NewContractBillingModal";
import PropTypes from 'prop-types';
import { errorPopper, tr } from "services/Helpers/Helpers";
import ApButton from "common/ApButton/ApButton";
import api from 'services/Api/Api';

class ContractBillingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            showModal: false,
            loading: false,
            selectedRowId: null,
        };

        autobind(this);
    }

    componentDidMount() {
        if (this.props.projectPage) {
            this.getProjectData();
        } else {
            this.getData();
        }
    }

    getData() {
        // Get all contract billing data
        this.setState({loading:true});
        api({
            method: 'get',
			url: 'storage/order/contractbilling/get',
        }).then(response => {
            this.setState({loading: false, data: response.data});
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        })
    }

    getProjectData() {
        this.setState({loading:true});
        api({
            method: 'get',
			url: 'project/management/getContractBillingData/' + this.props.project.id,
        }).then(response => {
            this.setState({loading: false, data: response.data});
        }).catch(error => {
            console.error(error);
            errorPopper(error, tr('get_error'));
            this.setState({loading: false});
        })
    }

    openRow(rowId) {
        this.setState({ selectedRowId: rowId, showModal: true });
    }

    onClose(refresh = false) {
        this.setState({ showModal: false, selectedRowId: null });
        if (refresh) {
            if (this.props.projectPage) {
                this.getProjectData();
            } else {
                this.getData();
            }
        }
    }

    renderHeader() {
        const newBillingBtn = <div className="apBoxCorner">
            <ApButton
                onClick={() => this.setState({ showModal: true })}
                color="green"
                disabled={this.state.loading}
                loading={this.state.loading}
            >
                {tr('create_new')}
            </ApButton>
        </div>;
        
        return (
            <div>
                <h1>{tr('contract_billing')}</h1>
                <p>{tr('contract_billing_info')}</p>
                {newBillingBtn}
            </div>
        )
    }

    render() {
        const classes = ['apBoxHeader', 'padding'];
        return (
            <div id='contractBillingPage' className="">
                <div className={this.props.projectPage ? '' : 'apBox'}>
                    <div className={classes.join(' ')}>
                        {this.renderHeader()}
                        <ContractBillingList 
                            project={this.props.project} 
                            loading={this.state.loading} 
                            data={this.state.data} 
                            openRow={this.openRow}
                        />
                        <NewContractBillingModal
                            show={this.state.showModal}
                            project={this.props.project}
                            onSave={this.onSave}
                            onClose={this.onClose}
                            selectedRowId={this.state.selectedRowId}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ContractBillingPage.propTypes = {
    projectPage: PropTypes.bool,  // Boolean to determine if this is the project page or not
    project: PropTypes.object,
}

export default ContractBillingPage;