import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
//import { debounce } from 'throttle-debounce';

import auth from 'services/Authed/Authed.js';
import api from 'services/Api/Api.js';
import ApValidate from 'services/ApValidate/ApValidate.js';

import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApButton from 'common/ApButton/ApButton.js';

import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import { ApInput } from 'common/ApInput/ApInput.js';

import ApModal from 'common/ApModal/ApModal.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import { errorPopper
    , formatMoney
    , keyExists
    , dateInputToSql
    , tr } from 'services/Helpers/Helpers.js';


import { getComponentStatusColor, getTypeIcon }  from 'modules/Storage/common/StorageHelpers.js';
import './PaymentPostEdit.css';

class PaymentPostEdit extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {

            //Dataa joka liittyy maksupostiin.
            post_id: -1,
            payment_id: -1,
            description: '',
            payment_amount: -1,
            percentage: -1,
            billing_date: '',
            payment_date: '',
            original_sum: -1.0,
            unallocated_sum: -1.0,
            valid_billing_date: true,

            selectedTab: 0,

            confirmSaveShow: false,
            confirmSaveText: null,
            confirmSaveAction: null,

            currencySign: auth.getCurrencySign(),
        };

        this.newAltNameCounter = 0;


        this.validator = new ApValidate(this, {
            sum: [
                { filter: 'required', state: 'error', text: tr('project_fee_must_be_amount')},
            ],
            //billing_date_valid: (value) => {return (this.state.valid_billing_date);},
        });

        autoBind(this);
    }

    //Varmistaa että syötetty arvo pysyy rajoissa.
    handlePaymentPostSumEdit(newSum) {        
        let originalSum = parseFloat(this.state.original_sum);
        const newSumFloat = parseFloat(newSum);
        let unAllocated = parseFloat(this.state.unallocated_sum);
        const maxValue = unAllocated + originalSum; //Maksimi jonka maksun määrä voi olla

        if (isNaN(newSumFloat)) {
            this.setState({ 
                invalidSum: true,
                payment_amount: ``,
            });
        } else {
            if (newSumFloat > maxValue) {
                this.setState({ 
                    invalidSum: true,
                    payment_amount: `${newSum}`,
                });
            } else {
                this.setState({
                    invalidSum: false,
                    payment_amount: `${newSum}`,   
                });
            } 
        }

        
    }

    delete(id) {
        let data = {};
        data.post_id = this.state.post_id;

        this.setState({loading: true });
        return api({
            method: 'post',
            url: 'projects/payments/paymentPosts/delete',
            data: data,
        }).then((response) => {
            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{ tr('deleted') }</strong>,
            });

            this.setState({ loading: false }, () => {
                if( typeof this.props.onDelete === 'function')
                    this.props.onDelete( data.post_id );
            });

        }).catch((error) => {
            console.error(error);
            errorPopper( error, tr('delete_error') );
            this.setState({ loading: false });
        });
    }

    save()
    {
        this.setState({ confirmSaveShow: false });

        let data = {};
        
        data.post_id = this.state.post_id;
        data.paymentId = this.state.payment_id;
        data.description = this.state.description;
        data.payment_amount = this.formatToNum(this.state.payment_amount, 2);

        //Laske prosenttimäärä
        let totalSum = this.formatToNum(this.props.data.total_sum);
        totalSum = parseFloat(totalSum).toFixed(2);
        const newPerc = parseFloat(100 / (totalSum / data.payment_amount));
        data.percentage = newPerc.toFixed(2);
        
        data.billing_date = dateInputToSql(this.state.billing_date);
        data.payment_date = dateInputToSql(this.state.payment_date);

        //console.log('saving...', data );

        this.setState({loading: true });
        return api({
            method: 'post',
            url: 'projects/payments/paymentPosts/edit',
            data: data,
        }).then((response) => {
            if (response === 2) {
                window.emitter.emit('popper', {
                    type: 'danger',
                    content: <strong>{ tr('no_payment_post_for_id') }</strong>,
                });
            } else { //Maksuposti löytyi
                window.emitter.emit('popper', {
                    type: 'success',
                    content: <strong>{ tr('saved') }</strong>,
                });
    
                this.setState({ loading: false }, () => {
                    if( typeof this.props.onSave === 'function')
                        this.props.onSave( response );
                });
            }
        }).catch((error) => {
            console.error(error);
            errorPopper( error, tr('save_error') );
            this.setState({ loading: false });
        });

    }

    onOpen()
    {
        if( this.fileUploadRef )
            this.fileUploadRef.resetFiles();

        this.setState({ selectedTab: 0 });
        this.getPaymentPost();
    }

    //Varmistaa että annetut propsit lasketaan tilaan.
    setupAllocations() {
        let unAlloc = this.props.data.unalloc;

        //Varmista mikä projektin allokoimaton summa on
        unAlloc = String(unAlloc);
        unAlloc = parseFloat(unAlloc.replace(',', '.')).toFixed(2);
        
        this.setState({
            unallocated_sum: unAlloc,
        });
    }

    getPaymentPost()
    {
        this.setState({loading: true });
        return api({
            method: 'get',
            url: `projects/payments/paymentPost/${this.props.data.payment_post_id}`,
        }).then((response) => {
            if (response === 0) {
                const message = errorPopper( new Error, tr('no_payment_post_for_id') );
                this.setState({
                    loading: false,
                    error: message,
                });
            } else {
                //console.log('Response', response );

                let formattedBillingDate = this.formatTime(response.billing_date);
                let formattedPaymentDate = this.formatTime(response.payment_date);

                this.setState({
                    loading: false,
                    post_id: response.id,
                    payment_id: response.payment_id,
                    description: response.description,
                    payment_amount: `${formatMoney(response.payment_amount)}${this.state.currencySign}`,
                    percentage: response.percentage,
                    billing_date: formattedBillingDate,
                    payment_date: formattedPaymentDate,
                    original_sum: response.payment_amount,
                });

                this.setupAllocations();
            }     
        }).catch((error) => {
            console.error(error);
            const message = errorPopper( error, tr('get_error') );
            this.setState({
                loading: false,
                error: message,
            });
        });
    }

    formatToNum(input, fixedNum = null) { 
        let retVal = String(input);
        retVal = parseFloat(retVal.replace(/\s/g, '').replace(',', '.'));
        if (fixedNum) { retVal = retVal.toFixed(fixedNum); }
        return retVal; 
    }

    formatTime(toFormat) {
        //Formatoi ajan SQL-datetime muodosta muotoon dd.mm.yyyy
        if (toFormat != null) {
            var chunks = toFormat.split("-");
            var sYear = chunks[0];
            var sMonth = chunks[1].toString();
            var chunks2 = chunks[2].split(" ");
            var sDay = chunks2[0];
            toFormat = `${sDay}.${sMonth}.${sYear}`;
            return toFormat;
        }
    
        return toFormat = 'Ei määritelty!';
    }

    handleNameChange( value )
    {
        this.setState({ payment_name: value });
    }

    handleBillingDateChange(e) {
        //Varmista että laskutuspäivämäärä on ennen maksupäivää
        let paymentDate = this.dateConverter(this.state.payment_date);
        let billingDate = this.dateConverter(e.target.value);

        if (billingDate <= paymentDate) {
            this.setState({ 
                billing_date: e.target.value,
                valid_billing_date: true, 
            });
        } else {
            this.setState({ 
                billing_date: e.target.value,
                valid_billing_date: false, 
            });
        }

        
    }

    handlePaymentDateChange(e) {
        //Varmista että laskutuspäivämäärä on ennen maksupäivää
        let paymentDate = this.dateConverter(e.target.value);
        let billingDate = this.dateConverter(this.state.billing_date);

        if (billingDate <= paymentDate) {
            this.setState({ 
                payment_date: e.target.value,
                valid_billing_date: true, 
            });
        } else {
            this.setState({ 
                payment_date: e.target.value,
                valid_billing_date: false, 
            });
        }
    }

    dateConverter(date) {
        date = date.replace(/\./g, '/');
        let dateParts = date.split("/");
        date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
        return date;
    }
    
    renderPaymentPostInfo()
    {
        let info = tr('payment_sum');

        return <div className="padding">
            <h4 className="formHeader">{ tr('pricing') }</h4>

            <ApInput
                label={ tr('sum') }
                type="text"
                id="sum"
                name="sum"
                value={ this.state.payment_amount !== -1 ? 
                    (this.state.payment_amount).replace(/\s/g, '')
                    : '' }
                //onChange={ ( e ) =>  this.setState({ sum: e.target.value }) }
                onChange={ ( e ) => this.handlePaymentPostSumEdit(e.target.value) }
                autoComplete="off"

                loading={ this.state.loading }
                disabled={ this.state.loading }
            />
            <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />
                { info }
            </div>
            
            {//Näytä virheviesti jos summa liian pieni
            }
            {this.state.invalidSum ? <div> 
                <h4>{ tr('payment_sum_invalid') }: </h4>
                <ul>
                    <li>{ tr('payment_sum_invalid_info1') }</li>
                    <li>{ tr('payment_sum_invalid_info2') }</li>    
                </ul>
            </div> : null}
            
        </div>
    }
    
    
    renderTerms()
    {
        return <div className="padding">
            <h4 className="formHeader">{ tr('terms') }</h4>

            <ApInput
                type="date"
                id="billing_date"
                name="billing_date"
                label={ tr('date_of_billing') }
                value={ this.state.billing_date ? this.state.billing_date : '' }
                //onChange={ ( e ) => this.setState({ billing_date: e.target.value }) }
                onChange={ ( e ) => this.handleBillingDateChange(e) }
            />

            <ApInput
                type="date"
                id="payment_date"
                name="payment_date"
                label={ tr('date_of_payment') }
                value={ this.state.payment_date ? this.state.payment_date : '' }
                //onChange={ ( e ) => this.setState({ payment_date: e.target.value }) }
                onChange={ ( e ) => this.handlePaymentDateChange(e) }
            />

            {!this.state.valid_billing_date ? <h6>{ tr('payment_date_error') }</h6> : ''}

            <ApButton
                    className="delete"
                    //color={ this.state.status_name === 'active' ? 'green' : 'white' }
                    color='red' 
                    onClick={ () =>  this.delete( this.state.post_id) }
                    disabled={ 
                        this.state.loading 
                    }
                    style={{ marginTop: '2em' }}
                >
                <SvgIcon icon="trash-alt" type="solid" />
                { tr('delete') }
            </ApButton>

        </div>

    }
    

    renderBasicInfoTab()
    {
        return <ApTab key="basicInfo" icon="info-circle" label={ tr('basics') }>
            <div id="editPaymentPost" className="clear">
                <div className="apColumn w50">
                    <div className="padding">
                        <ApInput
                            label={ tr('description') }
                            type="text"
                            id="name"
                            name="name"
                            maxLength={255}
                            charCounter 
                            value={ this.state.description ? this.state.description : '' }
                            onChange={ ( e ) =>  this.setState({
                                description: e.target.value,
                            })}

                            //validationState={ this.validator.getState( 'name' ) }
                            //tooltip={ this.validator.getText( 'name' ) }
                            autoComplete="off"
                            loading={ this.state.loading }
                            disabled={ this.state.loading }
                        />
                        
                        <div className="apInfo small">
                            <SvgIcon icon="info-circle" type="solid" />
                            { tr('payment_description_info') }
                        </div>
                        
                     </div>
                </div>  
                     
            </div>         


            <div className="apColumn w50">
                { this.renderPaymentPostInfo() }
            </div>

            <div className="clear">
            <div className="apColumn w50">
                    <div className="padding">
                        { this.renderTerms()}
                    </div>
                </div>   
            </div>

        </ApTab>
    }



    tabChange( selected )
    {
        this.setState({ selectedTab: selected });
    }

    renderBody()
    {
        //Älä näytä mitään jos tietoa ei haettu
        if( !this.props.data.payment_post_id ) return <div></div>;

        let tabs = [];
        tabs.push( this.renderBasicInfoTab() );

        return <ApTabs selected={ this.state.selectedTab } onChange={ this.tabChange } >
            { tabs }
        </ApTabs>
    }

    renderHeader()
    {
        if( !this.props.id ) return null;

        let header = null;
        let status = null;

        if( this.state.type_name )
        {

            let titleText = [];
            if( keyExists( this.state, 'code.ancestors') )
                titleText = this.state.code.ancestors.map( a => a.name );
            if( keyExists( this.state, 'code.name' ) )
                titleText.push( this.state.code.name );
            if( this.state.name )
                titleText.push( this.state.name );
            titleText = titleText.filter( f => f ).join(' / ');

            header = <div>
                <SvgIcon icon={ getTypeIcon( this.state.type_name ) } type="solid" />
                    <h4> 
                        { tr('edit') } { this.state.type_text.toLowerCase() }
                        <div className="headerCode">( { titleText } )</div>
                    </h4>
            </div>

            let statusDetail = <span>{ tr('storage_component_inactive_info') }</span>
            if( this.state.status_name === 'active' )
                statusDetail = <span>{ tr('storage_component_active_info') }</span>

            status = <div>
                <ApTooltip block text={
                    <div>
                        <div>{ tr('storage_component_current_status', [<strong>{ this.state.status_text }</strong>]) }</div>
                        <div>{ statusDetail }</div>
                    </div>
                }>
                <div className="mainStatus">
                    <div className={`apStatusBox ${ getComponentStatusColor( this.state.status_name ) }`}> { this.state.status_text }</div>
                </div>
                </ApTooltip>
            </div>
        }
        else
        {
            header = <div>
                <SvgIcon icon="question" type="solid" />
                <div className="apLoader"></div>
                <h4>&nbsp;</h4>
            </div>
        }



        return <div className="header">
            { header }
            { status }
        </div>
    }

    renderFooter()
    {
        //const validatorText = this.validator.getStatusAll();
        const statusName = this.state.status_name

        return <div className="footer padding">
                <ApButton
                    className="save"
                    //color={ this.state.status_name === 'active' ? 'green' : 'white' }
                    color='green' 
                    onClick={ () =>  this.save( this.state.status_name ) }
                    disabled={ 
                        this.state.loading 
                        //|| Boolean( validatorText ) 
                        || this.state.invalidSum
                        || !this.state.valid_billing_date
                    }
                >
                    <SvgIcon icon="save" type="solid" />
                    { tr('save') }
                </ApButton>
        </div>
    }



    render()
    {
        return <div>
            <div className="componentEdit">
                <ApModal
                    show={ this.props.show }
                    handleClose={ this.props.onClose }
                    header={ this.renderHeader() }
                    body={ this.renderBody() }
                    footer={ this.renderFooter() }
                    onOpen={ this.onOpen }
                />
            </div>
            
        </div>
    }
}

PaymentPostEdit.propTypes = {
    id: PropTypes.number,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func,
};

export default PaymentPostEdit;

