import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import auth from '../../services/Authed/Authed.js';
import {apiurl} from '../../services/Api/Api.js';
import nextId from '../../services/NextId/NextId.js';
import { Document, Page, pdfjs } from 'react-pdf';
import api              from 'services/Api/Api.js';
import { errorPopper, tr }  from 'services/Helpers/Helpers.js'


import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/Page/AnnotationLayer.css';

import {
    Pagination,
} from 'react-bootstrap';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

// When fetching file from backend only fileId matters. Name is only used
// to set nice name to file when user wants to save the file
class File extends React.Component {
    constructor(props)
    {
        super(props);

        this.state = {
            pdfPage: 1,
            pdfPages: 0,
        }

        this.domid = null;

        autoBind(this)
    }

    componentDidMount() {
        this.domid = nextId('file');
    }

    getUrl(props)
    {
        const name = this.props.name ? this.props.name : 'file';
        return `${apiurl}file/id/${this.props.fileId}/${name}?token=${auth.getToken()}`;
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ pdfPages: numPages });
    }

    getOriginalName()
    {
        return api({
            method: 'get',
            url: `${ apiurl }file/id/${ this.props.fileId }/getname`,
        }).then(( response ) => {
            //console.log('on', response )
            return response;
        }).catch( ( error ) => {
            errorPopper(error, tr('file_download_error'));
            this.setState({ loading: false });
        });
    }

    getFile( name )
    {
        return api({
            method: 'get',
            url: `${ apiurl }file/id/${ this.props.fileId }`,
            responseType: 'blob',
        }).then(( response ) => {
            const url = window.URL.createObjectURL( new Blob([ response ]) );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.setState({ loading: false });
        }).catch( ( error ) => {
            errorPopper(error, tr('file_download_error'));
            this.setState({ loading: false });
        });

    }

    downloadFile()
    {
        this.setState({ loading: true });

        // If props name is not given, we first get the original name for the file
        // before downloading it
        if( this.props.name )
        {
            this.getFile( this.props.name );
        }
        else
        {
            this.getOriginalName().then(( name ) => {
                this.getFile( name );
            }).catch( ( error ) => {
                errorPopper(error, tr('file_download_error'));
                this.setState({ loading: false });
            });
        }
    }


    render()
    {
        // Remove stuff we dont want to pass to elements
        let {fileIcon, fileId, type, fileUrl, ...props} = this.props;

        if(!fileId && !fileUrl)
            return null;

        const url = this.getUrl();

        if(type === 'pdf')
        {
            let links = [];
            let i;
            for(i = 0; i < this.state.pdfPages; i++)
            {
                const page = i+1;
                links.push(
                    <Pagination.Item key={page}
                        onClick={() => {this.setState({pdfPage: page})}}
                        active={(page === this.state.pdfPage)}
                    >{page}</Pagination.Item>
                );
            }

            return <div>
                <Document file={url} onLoadSuccess={ this.onDocumentLoadSuccess }>
                    <Page className="pdfbg" pageNumber={this.state.pdfPage} />
                </Document>
                <nav aria-label="Page navigation" style={{
                    margin: '0 1em',
                    backgroundColor: 'gray',
                    border: 'solid 1px',
                    borderTop: 0,
                    padding: '0 1em 1em 1em',
                }}>
                    <Pagination style={{margin: 0}}>
                        <Pagination.Item disabled={true}>
                            { tr('page') }:
                        </Pagination.Item>
                        {links}
                    </Pagination>
                </nav>
            </div>
        }

        // Return image
        if(['image', 'img'].includes(type))
        {
            return <img alt="" src={url} {...props} />
        }

        // Return file link
        let text = props.children;
        if(fileIcon)
        {
            text = <div>
                <div>
                    <img alt="" src="/img/fileico.png" style={{width: '4em', height: '6em'}} />
                </div>
                <div>
                    {text}
                </div>
            </div>
        }
        return <span className="pointer" onClick={ this.downloadFile }>
            { text }
        </span>
    }
}
File.propTypes = {
    fileId: PropTypes.number,
    type: PropTypes.string,
    name: PropTypes.string,
    fileicon: PropTypes.bool,
};
export default File;
