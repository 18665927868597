import React from 'react';
import autoBind from 'react-autobind';

import { tr } from 'services/Helpers/Helpers.js';

import ApModal            from 'common/ApModal/ApModal.js';
import SvgIcon            from 'common/SvgIcon/SvgIcon.js';
import ApButton           from 'common/ApButton/ApButton.js';
import { ApInput }         from 'common/ApInput/ApInput.js';
import './NewOfferDecline.css';

class NewOfferDecline extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            error: false,
            data: {
                decline_reason: '',
            },
        }
        autoBind(this);
    }

    UNSAFE_componentWillMount()
    {
        this.resetState();
    }

    resetState()
    {
        this.setState({ data: {
            decline_reason: '',
        }});
    }

    
    handleChange( e )
    {
        const decline_reason = e.target.name;
        let data = { ...this.state.data };
        data[ decline_reason ] = e.target.value;
        this.setState({ data });
    }


    addDeclineReason()
    {
        this.props.onAdd(this.state.data);
        this.props.onClose();
    }

    renderBody()
    {
        return (
            <div className="padding">

                <ApInput
                    type="text"
                    id="newDecline_reason"
                    name="decline_reason"
                    label={ tr('decline_reason') }
                    value={ this.state.data.decline_reason }
                    onChange={ this.handleChange }
                    validationState={ !this.state.data.decline_reason ? "error" : "" }
                    //tooltip={ !this.state.data.decline_reason ? "Syy on pakollinen" : false }
                    loading={ this.state.loading }
                    disabled={ this.state.loading }
                />


                { this.state.error && 
                    <div className="apErrorMsg">
                        { this.state.error }
                    </div>
                }

            </div>
        );
    }

    render()
    {
    
        return (
            <ApModal
                show={ this.props.show }
                onOpen={ this.resetState }
                handleClose={ this.props.onClose }
                //closeFromBg
                className="narrow"
                header={ 
                    <div className="padding-small">
                        <h4>{ tr('decline_reason') } - { Boolean( this.props.data ) && this.props.data.id ? tr('edit') : tr('new') }</h4>
                    </div>
                }
                body={ 
                    <div>
                        { this.renderBody() }
                    </div>
                }
                footer={
                    <div id="newOfferDeclineFooter">
    
                        <ApButton className="cancel" onClick={ this.props.onClose } disabled={ this.state.loading }>
                            <SvgIcon icon="times" type="solid" />
                            { tr('cancel') }
                        </ApButton>

                        <ApButton className={ "save" + ( this.state.data.decline_reason && !this.state.loading ? " highlight" : "" ) } color="blue" onClick={ this.addDeclineReason } disabled={ this.state.loading || !this.state.data.decline_reason } loading={ this.state.loading }>
                            <SvgIcon icon="save" type="solid" />
                            { tr('save') }
                        </ApButton>
                    
                    </div>
                }

            />
        )

    }

}

export default NewOfferDecline;
