import React from 'react';
import autoBind from 'react-autobind';

import ApTooltip from 'common/ApTooltip/ApTooltip.js';

import './ComponentHeader.css';
import { tr } from 'services/Helpers/Helpers';

class ComponentHeader extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {

        };
        autoBind(this); 
    }

    renderInputCols()
    {
        const colShow = this.props.colShow;

        let cols = [];
        this.props.cols.forEach( ( col, i ) => {
            if( col.hidden ) return null;
            let optionBar = null;
            if( col.options )
            {
                let doms = col.options.map( ( o, i ) => {
                    const className = ( colShow[ col.toggle ] === o.showAs ) ? 'option selected' : 'option';
                    return <div key={ i }
                        className={ className }
                        onClick={ () => this.props.changeColShow({ [ col.toggle ]: o.showAs }) }
                    >
                        <ApTooltip text={ o.tooltip ? o.tooltip : null } block position="topright">
                            { o.label }
                        </ApTooltip>
                    </div>
                })
                optionBar = <div className={`apOptionBar count-${ col.options.length }`}>
                    { doms }
                </div>
            }
            cols.push( <th key={ i } className="fixed">
                <ApTooltip text={ col.tooltip ? col.tooltip : null } block position="top">
                    { col.label }
                </ApTooltip>
                { optionBar }
            </th>)
        });
        return cols;
    }

    render() 
    {
        return <tr className="componentHeader">
            <th>{/* Checkbox */}</th>
            <th>{/* GroupTd */}</th>
            <th>{/* Connected */}</th>
            <th>{tr("name")}</th>
            <th>{tr("comment")}</th>
            <th>{/* save as new */}</th>
            { this.renderInputCols() }
            <th>{/* Menu */}</th>
        </tr>
    }
}


export default ComponentHeader;
