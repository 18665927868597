/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
//import auth from 'services/Authed/Authed.js';

import './Settings.css';

import ApFormPage from 'common/ApFormPage/ApFormPage.js';
// import SvgIcon from 'common/SvgIcon/SvgIcon.js';
//import ApDropdown from 'common/ApDropdown/ApDropdown.js';
// import ApButton from 'common/ApButton/ApButton.js';
//import ApReactTable from 'common/ApReactTable/ApReactTable.js';
//import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
//import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';

import { keyExists, tr } from 'services/Helpers/Helpers.js';

import GeneralTab from './tabs/General.js';
import ListingsTab from './tabs/Listings.js';
import SalaryCategoriesTab from './tabs/SalaryCategories.js';
import NotificationsTab from './tabs/Notifications.js';
import SocialExpensesTab from './tabs/SocialExpenses.js';
import CollectiveAgreement from './tabs/CollectiveAgreement';
import TimeAttendanceSetting from './tabs/TimeAttendanceSetting';


import { connect } from 'react-redux';

// import { Collapse } from 'react-bootstrap';

class TimetrackingSettings extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {
            loading: false,
            error: false,
            unsavedChanges: false,

            hour_types: null,
            vehicles: null,
            allowances: null,
            absences: null,
            payment_cards: null,
            company: null,
            social_expenses: null,

            modalShow: false,
            modalData: false,

            notifications: [],

            collectiveAgreements: [],
            personnelGroups: [],
            timeAttendanceDevices:[],

            nextId: 1,
        };
        autoBind(this);
    }

    componentDidMount()
    {
        this.getSettings();
    }

    getSettings()
    {  
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'timetracking/settings'
        }).then((response) => {
            let company = response.company;
            if (company) {
                let mode = company.time_attendance_save_point_mode;
                switch (mode) {
                    case "save_point":
                        company.time_attendance_save_point_mode = tr('only_time_attendance_time_points');
                        break;
                    case "fill":
                        company.time_attendance_save_point_mode = tr('fill_missing_time_tracking_entrys');
                        break;
                    case 'fill_save_points':
                        company.time_attendance_save_point_mode = tr('fill_missing_time_tracking_entrys_between_save_points');
                        break;
                    case "clock":
                        company.time_attendance_save_point_mode = tr('use_clock');
                        break;
                    case "auto":
                        // TODO
                        break;
                }
                    
            }
            this.setState({ 
                hour_types: response.hour_types || null,
                vehicles: response.vehicles || null,
                allowances: response.allowances || null,
                absences: response.absences || null,
                payment_cards: response.payment_cards || null,
                company: company || null,
                notifications: response.notifications || [],
                social_expenses: !response.social_expenses || response.social_expenses.length === 0 ? {} : response.social_expenses,
                collectiveAgreements: response.collective_agreements || [],
                personnelGroups: response.personnel_groups || [],
                timeAttendanceDevices: response.time_attendance || [],

                unsavedChanges: false,
                loading: false 
            });
            this.props.saveTimetrackingSettings(response);
        }).catch((error) => {
            console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('get_error') ) || tr('error'),
                loading: false 
            });
        });
    }

    handleChange( key, value, afterFunc = false )
    {
        let newState = {};
        newState[ key ] = value;
        newState.unsavedChanges = true;
        this.setState( newState, () => {
            if( typeof( afterFunc ) === "function" )
                afterFunc();
        });
    }

    handleEmptyListings(count) {
        let error ={};
        if (count ==0) {           
            error =  tr('hour_ratings_default_value_missing');          
            this.setState({ error, unsavedChanges: false });         
        }
        else {
            error = null;
      
            this.setState({ error, unsavedChanges: true });
        }
    }
    
    saveSettings()
    {
        let  notifications=this.state.notifications.filter(n => {
            return (n.type && n.day && n.send_type && n.title);
             
        });
        notifications = this.state.notifications.map(n => {
            return { ...n, id: isNaN(parseInt(n.id, 10)) ? null : n.id, }
        });

        const timeAttendanceDevices = this.state.timeAttendanceDevices.map(d => {
            return {
                id: d.id,
                name: d.name,
                token: d.token,
                active:d.active,
                project_id: d.project_id,
                work_id: d.work_id,
                hour_type_id: d.hour_type_id,
                delete:d.delete,
                timer_time_attendance: d.timer_time_attendance,
                over_max_distance_enabled: d.over_max_distance_enabled,
            }
        });
        let company = this.state.company;
        if (company) {
            let mode = company.time_attendance_save_point_mode;
            switch (mode) {
                case tr('only_time_attendance_time_points'):
                    company.time_attendance_save_point_mode = "save_point";
                    break;
                case tr('fill_missing_time_tracking_entrys'):
                    company.time_attendance_save_point_mode = "fill";
                    break;
                case tr('fill_missing_time_tracking_entrys_between_save_points'):
                    company.time_attendance_save_point_mode = "fill_save_points";
                    break;
                case tr('use_clock'):
                    company.time_attendance_save_point_mode = "clock";
                    break;
                case "auto":
                    // TODO
                    break;
            }

            if (company.time_attendance_warning_time === "") {
                company.time_attendance_warning_time = null;
            }

        }
        let personnel_groups = this.state.personnelGroups;
        for (let personnel_group of personnel_groups) {
            if (personnel_group.time_attendance_settings) {
                
                personnel_group.time_attendance_settings.project_id = personnel_group.time_attendance_settings.project ? personnel_group.time_attendance_settings.project.id : null;
                personnel_group.time_attendance_settings.work_id = personnel_group.time_attendance_settings.work ? personnel_group.time_attendance_settings.work.id : null;
                personnel_group.time_attendance_settings.hour_type_id = personnel_group.time_attendance_settings.hour_type ? personnel_group.time_attendance_settings.hour_type.hour_type_id : null;
            }
        }
        notifications = notifications.map(n => {
            return { ...n, id: isNaN(parseInt(n.id, 10)) ? null : n.id, }
        })
        

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'timetracking/settings/save',
            data: {
                absences:       this.state.absences,
                allowances:     this.state.allowances,
                hour_types:     this.state.hour_types,
                payment_cards:  this.state.payment_cards,
                vehicles:       this.state.vehicles,
                company:        company,
                notifications:  notifications,
                social_expenses: this.state.social_expenses,
                collective_agreements: this.state.collectiveAgreements,
                personnel_groups: this.state.personnelGroups,
                time_attendance: timeAttendanceDevices,
            },
        }).then((response) => {
            this.getSettings();

        }).catch((error) => {
            console.log(error);
            this.setState({ 
                error: keyExists( error, "response.data.message", true, tr('save_error') ) || tr('error'),
                loading: false 
            });
        });
    }

    
    render() 
    {
        return (
            <div id="pageTimetrackingSettings">

                <ApFormPage 
                    onSave={ this.saveSettings } 
                    loading={ this.state.loading } 
                    unsaved={ this.state.unsavedChanges }
                    error={ this.state.error }
                >
                    <div className="apBoxHeader">
                        <h1>{ tr('working_time_settings') }</h1>
                        <p>{ tr('working_time_settings_info') }</p>
                    </div>

                    <ApTabs>
                        <ApTab icon="cogs" label={ tr('general_settings') }>

                            <GeneralTab
                                loading={ this.state.loading }
                                company={ this.state.company }
                                paymentCards={ this.state.payment_cards }
                                handleChange={ this.handleChange }
                            />

                        </ApTab>

                        {/* <ApTab icon="glass-cheers" label="Arkipyhät" disabled>
                            TODO
                        </ApTab> */}
                        
                        <ApTab icon="list-ol" label={ tr('classifications_and_compensations') }>

                            <ListingsTab
                                loading={ this.state.loading }
                                hour_types={ this.state.hour_types }
                                vehicles={ this.state.vehicles }
                                allowances={ this.state.allowances }
                                absences={ this.state.absences }
                                handleChange={ this.handleChange }
                                handleEmptyListings = { this.handleEmptyListings }

                            />

                        </ApTab>
                        <ApTab icon="money-check" label={ tr('salary_types') }>
    
                            <SalaryCategoriesTab
                                loading={ this.state.loading }
                                company={ this.state.company }
                                hour_types={ this.state.hour_types }
                                vehicles={ this.state.vehicles }
                                allowances={ this.state.allowances }
                                absences={ this.state.absences }
                                handleChange={ this.handleChange }
                            />
                        
                        </ApTab>

                        <ApTab icon="bell" label={ tr('notifications') }>
    
                            <NotificationsTab
                                loading={ this.state.loading }
                                company={ this.state.company }
                                notifications={ this.state.notifications }
                                handleChange={ this.handleChange }
                            />
                        
                        </ApTab>
                        <ApTab icon="money-bill-wave" label={ tr('social_expenses') }>
    
                            <SocialExpensesTab
                                loading={ this.state.loading }
                                company={ this.state.company }
                                handleChange={this.handleChange}
                                social_expenses={this.state.social_expenses}
                                roles={this.props.apTimetrackingSettings.personnel_groups}
                            />
                        
                        </ApTab>

                        <ApTab icon="file-contract" label={ tr('collective_agreements') }>
    
                            <CollectiveAgreement
                                loading={ this.state.loading }
                                handleChange={ this.handleChange }
                                collectiveAgreements={this.state.collectiveAgreements}
                                personnelGroups={this.state.personnelGroups}
                                company={this.state.company}
                            />

                        </ApTab>
                        <ApTab icon="circle" label={tr('time_attendance')}>

                            <TimeAttendanceSetting
                                loading={this.state.loading}
                                company={this.state.company ? this.state.company:[]}
                                devices={this.state.timeAttendanceDevices}
                                hourTypes={this.state.hour_types}
                                handleChange={this.handleChange}
                            />

                        </ApTab>
                        
                    </ApTabs>

                </ApFormPage>

                

            </div>
        );
    }
}

const mapStateToProps = state => ({
    apTimetrackingSettings: state.apTimetrackingSettings
});

const mapDispatchToProps = dispatch => ({
    saveTimetrackingSettings: (settings) => dispatch({type: 'SAVE_TIMETRACKING_SETTINGS', payload: settings})
});

export default connect(mapStateToProps, mapDispatchToProps)(TimetrackingSettings);