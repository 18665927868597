import ApReactTable, { colPreset } from "common/ApReactTable/ApReactTable";
import moment from "moment";
import React from "react";
import api from 'services/Api/Api.js';
import { errorPopper, getExcel, tr } from "services/Helpers/Helpers";

class FlextimeReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'timetracking/flextime/report',
        }).then((response) => {
            // console.log("res", response);
            this.setState({ loading: false, data: Array.isArray(response.data) ? response.data : [] });
        }).catch((error) => {
            this.setState({ loading: false });
            console.error('Error', error);
            errorPopper(error, tr('get_error'));
        });
    }

    getExcel = async () => {
        this.setState({ loading: true });
        await getExcel(this.state.data, tr('flexitime_count'));
        this.setState({ loading: false });
    }

    render() {
        return (
            <div className="padding">
                <ApReactTable 
                    data={this.state.data}
                    multiselect={[
                        {
                            icon: 'file-excel',
                            label: tr('create_excel'),
                            action: this.getExcel,
                            unselectAfter: false,
                            closeAfter: true,
                            alwaysOn: true,
                        }
                    ]}
                    columns={[
                        {
                            id: 'name',
                            Header: tr('name'),
                            accessor: 'name',
                            sortable: true,
                            filterable: true,
                            customFilter: {
                                type: 'text',
                                placeholder: tr('name'),
                            }
                        },
                        colPreset({
                            type: 'numberNice',
                            decimals: 2,
                            id: 'current_balance',
                            Header: tr('current_balance'),
                            accessor: "current_balance",
                            sortable: true,
                        }),
                        {
                            id: 'latest_event',
                            Header: tr('latest_event'),
                            accessor: 'latest_event',
                            sortable: true,
                            Cell: row => moment(row.value).format('L'),
                        },
                    ]}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

export default FlextimeReport;