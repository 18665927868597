import React from 'react';
import PropTypes from 'prop-types';
import api from '../../services/Api/Api.js';

import './ApProjectPicker.css';

import SvgIcon from '../../common/SvgIcon/SvgIcon.js';

class ApProjectPicker extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            id: null,
            input: '',
            levels: [],
            options: [],
            optionSelected: null,
            loading: false
        };
        this.addLevel = this.addLevel.bind(this);
        this.removeLevel = this.removeLevel.bind(this);
        this.clearLevel = this.removeLevel.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.nextOption = this.nextOption.bind(this);
        this.prevOption = this.prevOption.bind(this);
        this.inputKeyDown = this.inputKeyDown.bind(this);
    }

    getOptions()
    {   
        
        this.setState({loading: true});
        api({
            method: 'post',
            url: 'projects/management/projectFind',
            data: {
                value: this.state.input,
                nosubprojects: ( this.state.levels.length == 0 ? false : true ),
            }
        }).then((response) => {
            //console.log( response );
            this.setState({
                loading: false,
                options: response,
                optionSelected: 0,
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    addLevel() {
        if( this.state.options.length > 0 ) {
        	let levels = this.state.levels.splice(0);
            levels.push( this.state.options[ this.state.optionSelected ] );
            this.setState({ 
                levels: levels, 
                options: [], 
                input: '' 
            }, this.getOptions );
        }
    }

    removeLevel()
    {
        let levels = this.state.levels.splice(0);
        levels.pop();
        this.setState({ 
            levels: levels, 
            options: [], 
            input: '' 
        }, this.getOptions );
    }

    clearLevel()
    {
        this.setState({ levels: [], options: [], input: '' }, this.getOptions);
    }

    nextOption()
    {
        let next = this.state.optionSelected;
        next++;
        if( next > (this.state.options.length - 1) ) next = 0;
        this.setState({ optionSelected: next });
    }

    prevOption()
    {
        let next = this.state.optionSelected;
        next--;
        if( next < 0 ) next = this.state.options.length - 1;
        this.setState({ optionSelected: next });
    }

    inputKeyDown(e) 
    {   
        //console.log( e.keyCode );
        switch( e.keyCode ) {
            case 13: // Enter
                this.addLevel();
                break;

            case 27: // Esc
                this.clearLevel();
                break;

            case 8: // Backspace
            case 37: // Left arrow
                if( this.state.input.length == 0 ) {
                    this.removeLevel();
                }
                break;

            case 38: // Arrow up
                this.prevOption();
                break;

            case 40: // Arrow down
                this.nextOption();
                break;

            default:
                this.getOptions();
                break;
        }

    }

    render()
    {
        return (
        	<div className="apProjectPicker">
                <SvgIcon icon="chart-pie" type="solid" />
                {
                    this.state.levels.map((level, index) => 
                        <span key={index} className="apProjectPart">
                            <strong>{ level.description }</strong> 
                            <small>{ level.project_number}</small>
                        </span>
                    )
                }
                <div className="apProjectPart">
                    <input 
                        type="text" 
                        placeholder="Valitse" 
                        value={this.state.input}
                        onChange={(e) => { this.setState( { input: e.target.value } ) }} 
                        onKeyDown={ this.inputKeyDown } 
                    />
                    { this.state.options.length > 0 && 
                        <ul className="options">
                            {
                                this.state.options.map((option, index) => 
                                    <li key={index} className={ index == this.state.optionSelected ? "selected" : ""}>
                                        <strong>{ option.description }</strong>
                                        <small>{ option.project_number}</small>
                                    </li>
                                )
                            }
                        </ul>
                    }
                </div>
            </div>
        );
    }
};

ApProjectPicker.propTypes = {

};

export default ApProjectPicker;

