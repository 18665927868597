import React from 'react';

import moment from 'moment';
import api from 'services/Api/Api.js';
import { errorPopper, tr } from 'services/Helpers/Helpers';
import SvgIcon from 'common/SvgIcon/SvgIcon';
import ApReactTable from 'common/ApReactTable/ApReactTable';

const statusOptions = [
    {value: '', label: tr('all')},
    {value: 'pending', label: tr('waiting_for_approval1')},
    {value: 'rejected', label: tr('rejected')},
    {value: 'approved', label: tr('approved')},
]

class ExtAssignments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            assignments: [],
            tableColumns: [],
        }
    }
    
    UNSAFE_componentWillMount() {
        this.getExtAssignments();
    }

    getExtAssignments() {
        this.setState({loading: true});
        api({
            method: 'get',
            url: 'assignment/extassignments/get/all'
        }).then(response => {
            this.setState({
                assignments: response.assignments,
                loading: false
            });
            const pending = response.assignments.filter(item => item.status === 'pending');
            this.props.handleCount(this.props.tabName, pending.length);
        }).catch(err => {
            errorPopper(err, 'error');
            console.error(err);
            this.setState({loading: false});
        })
    }

    renderStatus(status) {
        let statusText = '';
        switch (status) {
            case 'pending':
                statusText = tr('waiting_for_approval1');
                break;
            case 'approved':
                statusText = tr('approved');
                break;
            case 'rejected':
                statusText = tr('rejected');
                break;
            default:
                break;
        }
        return statusText;
    }

    goToAssignment = (id) => {
        window.emitter.emit('goTo', {pathname: '/ext/assignments/' + id + "/" + this.props.tabSelected})
    }

    render() {
        return(
            <div id='extAssignments'>
                <ApReactTable
					rememberId={`assignmentsTable_${this.props.tabSelected}`}
					loading={this.state.loading}
					data={this.state.assignments}
					columns={
                        [
                            {
                                id: 'assignment_name',
                                Header: tr('header'),
                                accessor: 'assignment_name',
                                customizable: true,
                                showInitially: true,
                                // type: 'text'
                                customFilter: {
                                    type: "text",
                                    placeholder: tr('header'),
                                },
                                onClick: (row) => this.goToAssignment(row.id)
                            },
                            {
                                id: 'description',
                                Header: tr('description'),
                                accessor: 'assignment_description',
                                customizable: true,
                                showInitially: true,
                                customFilter: {
                                    type: "text",
                                    placeholder: tr('description'),
                                },
                                onClick: (row) => this.goToAssignment(row.id)
                            },
                            {
                                id: 'customer_name',
                                Header: tr('customer'),
                                accessor: 'customer_name',
                                customizable: true,
                                showInitially: true,
                                customFilter: {
                                    type: "text",
                                    placeholder: tr('customer'),
                                },
                                onClick: (row) => this.goToAssignment(row.id)
                            },
                            {
                                id: 'customer_business_id',
                                Header: tr('business_id'),
                                accessor: 'customer_business_id',
                                customizable: true,
                                showInitially: true,
                                customFilter: {
                                    type: "text",
                                    placeholder: tr('business_id'),
                                },
                                onClick: (row) => this.goToAssignment(row.id)
                            },
                            {
                                id: 'customer_phone',
                                Header: tr('phone'),
                                accessor: 'customer_phone',
                                customizable: true,
                                showInitially: true,
                                customFilter: {
                                    type: "text",
                                    placeholder: tr('phone'),
                                },
                                onClick: (row) => this.goToAssignment(row.id)
                            },
                            {
                                id: 'customer_email',
                                Header: tr('email'),
                                accessor: 'customer_email',
                                customizable: true,
                                showInitially: true,
                                customFilter: {
                                    type: "text",
                                    placeholder: tr('email'),
                                },
                                onClick: (row) => this.goToAssignment(row.id)
                            },
                            {
                                id: 'customer_website',
                                Header: tr('website'),
                                accessor: 'customer_website',
                                customizable: true,
                                showInitially: true,
                                customFilter: {
                                    type: "text",
                                    placeholder: tr('website'),
                                },
                                onClick: (row) => this.goToAssignment(row.id)
                            },
                            {
                                id: 'created_at',
                                Header: tr('created_at'),
                                accessor: 'created_at',
                                filterable: false,
                                customizable: true,
                                showInitially: false,
                                Cell: (props) => props.value
                                    ? moment(props.value).format("L HH:mm")
                                    : "",
                                onClick: (row) => this.goToAssignment(row.id)
                            },
                            {
                                id: 'status',
                                Header: tr('status'),
                                accessor: 'status',
                                customizable: true,
                                showInitially: true,
                                Cell: (props) => {
                                    return this.renderStatus(props.value);
                                },
                                customFilter: {
                                    type: 'select',
                                    options: statusOptions || [{value: "", label: tr('all')}],
                                },
                                onClick: (row) => this.goToAssignment(row.id)
                            },
                        ]
                    }
					showFiltersInitially={true}
					filterable
                    defaultFiltered={[{id: 'status', value: 'pending'}]}
                    defaultSorted={[{id: 'created_at', desc: false}]}
                    rowActions={ (row) => (
                        <div 
                            className="apSimpleButton" 
                            onClick={ (row) => this.goToAssignment(row.id) }
                        >
                            <SvgIcon icon="arrow-right" type="solid" />
                        </div>
                    )}
				/>
            </div>
        );
    }
}

export default ExtAssignments;