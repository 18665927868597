/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import DayEntries from './DayEntries/DayEntries.js';

import { statusCode } from  'modules/WorkHours/WorkHourHelpers.js';

import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApModalInput from 'common/ApModalInput/ApModalInput.js';

import './Approve.css';

import { keyExists, pluralizeCount } from  'services/Helpers/Helpers.js';

class Approve extends React.Component {

    constructor( props ) 
    {
        super(props);
        this.state = {
            loading:            true,
            showUserFilter:     false,
            showProjectFilter:  false,
            commentEntryIndex:  null, 
            commentText:        "",
            entries:            [],
            days:               [],
            hiddenEntries:      0, 
            activeFilters:      0,
            users:              [],
            projects:           [],
            selectedEntries:    [],
            selectedUsers:      [],
            selectedProjects:   [],
            selectedTypes:      [],
        };
        autoBind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.clickOutsideHandler);
        this.getSubmittedEntries();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutsideHandler);
    }

    clickOutsideHandler(event) {
     
        let showUserFilter = this.state.showUserFilter;
        let showProjectFilter = this.state.showProjectFilter;

        if( !this.usersRef.contains( event.target ) )
            showUserFilter = false;

        if( !this.projectsRef.contains( event.target ) )
            showProjectFilter = false;

        this.setState({
            showUserFilter: showUserFilter,
            showProjectFilter: showProjectFilter
        });
        
    }


    getSubmittedEntries()
    {   
        api({
            method: 'get',
            url: 'hour/approval/entries',
        }).then(( response ) => {

            //console.log( 'getSubmittedEntries', response );

            const addWithCount = ( array, id, details ) => {
                if( Array.isArray( array ) )
                {
                    let found = false;
                    for( let i = 0; i < array.length; i++ )
                    {
                        if( array[i].id == id )
                        {
                            array[i].count++;
                            found = true;
                            break;
                        }
                    }
                    if( !found )
                        array.push( { id: id, count: 1, details: details } );
                }
                return array;
            };

            let users = [];
            let projects = [];

            response.forEach( entry => {
                users = addWithCount( users, entry.user_id, entry.user ); 
                projects = addWithCount( projects, entry.project_id, entry.project );
            });

            users.sort( ( a, b ) => {
                const aValue = a.details && a.details.person_detail && a.details.person_detail.first_name ? a.details.person_detail.first_name : "";
                const bValue = b.details && b.details.person_detail && b.details.person_detail.first_name ? b.details.person_detail.first_name : "";
                if( aValue < bValue )
                    return -1;
                else if( aValue > bValue )
                    return 1;
                return 0;
            });

            projects.sort( ( a, b ) => {
                const aValue = a.details && a.details.project_code ? a.details.project_code : "";
                const bValue = b.details && b.details.project_code ? b.details.project_code : "";
                if( aValue < bValue )
                    return -1;
                else if( aValue > bValue )
                    return 1;
                return 0;
            });

            //console.log( 'users', users );
            //console.log( 'projects', projects );

            this.setState({
                entries: response,
                users: users,
                //selectedUsers: [ 1 ], //[ users[0].id ],
                selectedEntries: [],
                projects: projects,
            }, this.filtersChange );
            
        }).catch( ( error ) => {
            console.log( 'getSubmittedEntries ERROR', error );
            this.setState({ loading: false });
        });
    }


    groupByDays( entries )
    {
        let days = [];
        for( let i = 0; i < entries.length; i++ )
        {
            let dayFound = false;

            for( let j = 0; j < days.length; j++ )
                if( entries[i].date == days[j].date )
                {
                    dayFound = true;
                    days[j].entries.push( entries[i] );
                }
                
            if( !dayFound )
                days.push( { 
                    date: entries[i].date,
                    entries: [ entries[i] ] 
                });
        }
        return days;
    }


    filtersChange()
    {
        this.setState({ loading: true });

        let filtered = [];
        let hiddenEntries = 0;

        this.state.entries.forEach( ( entry, index ) => {

            let show = true;

            if( this.state.selectedUsers.length > 0 && this.state.selectedUsers.indexOf( entry.user_id ) == -1 )
                show = false;
            
            if( this.state.selectedProjects.length > 0 && this.state.selectedProjects.indexOf( entry.project_id ) == -1 )
                show = false;
            
            if( this.state.selectedTypes.length > 0 && this.state.selectedTypes.indexOf( entry.type ) == -1 )
                show = false;
            
            if( show )
            {
                // Save original index as 'uniqueIndex' so we can reference to correct row
                entry.uniqueIndex = index;

                filtered.push( entry );
            }
            else
                hiddenEntries++;
        });

        let activeFilters = 0;

        if( this.state.selectedUsers.length > 0 )
            activeFilters++;

        if( this.state.selectedProjects.length > 0 )
            activeFilters++;

        if( this.state.selectedTypes.length > 0 )
            activeFilters++;

        this.setState({ 
            days: this.groupByDays( filtered ),
            loading: false, 
            hiddenEntries: hiddenEntries,
            activeFilters: activeFilters,
        });
    }

    toggleUserFilters()
    {  
        this.setState({ showUserFilter: !this.state.showUserFilter });
    }

    toggleProjectFilters()
    {
        this.setState({ showProjectFilter: !this.state.showProjectFilter });
    }

    toggleId( array, id )
    {
        const index = array.indexOf( id );
        if( index == -1 )
            array.push( id );
        else 
            array.splice( index, 1 );
        return array;
    }

    toggleUser( id )
    {
        // Multiple users select
        this.setState({ selectedUsers: this.toggleId( this.state.selectedUsers.slice(), id ) }, this.filtersChange );
        
        // One user at the time
        //this.setState({ selectedUsers: [ id ] }, this.filtersChange );
    }

    toggleProject( id )
    {
        this.setState({ selectedProjects: this.toggleId( this.state.selectedProjects.slice(), id ) }, this.filtersChange );
    }

    toggleEntry( id )
    {
        this.setState({ selectedEntries: this.toggleId( this.state.selectedEntries.slice(), id ) });
    }

    toggleType( id )
    {
        this.setState({ selectedTypes: this.toggleId( this.state.selectedTypes.slice(), id ) }, this.filtersChange );
    }

    selectAll()
    {
        let selectedEntries = [];
        for( let i = 0; i < this.state.entries.length; i++ )
            selectedEntries.push( i );
    
        this.setState({ selectedEntries });
    }

    selectVisible()
    {
        let selectedEntries = [];
    
        this.state.days.forEach( day => {
            day.entries.forEach( entry => {
                selectedEntries.push( entry.uniqueIndex ); 
            });
        });
        
        this.setState({ selectedEntries });
    }

    clearFilters()
    {
        this.setState({
            selectedUsers: [],
            selectedProjects: [],
            selectedTypes: [],
        }, this.filtersChange );
    }

    clearEntrySelection()
    {
        this.setState({
            selectedEntries: []    
        });
    }

    rotateStatus( rowIndex )
    {
        let entries = this.state.entries.slice();
        entries.map( ( entry, index ) => {
            if( index == rowIndex )
            {  
                entry.status_id--;
                if( entry.status_id < statusCode("submitted") )
                    entry.status_id = statusCode("approved");
            }
            return entry;
        });
        
        this.setState({ entries });
    }
    
    getStatusCount()
    {
        let count = {
            'approved':         0,
            'rejected':         0,
            'comment_missing':  0,
            'changed':          0,
            'noChange':         0,
        };

        this.state.entries.forEach( entry => {
            if( entry.status_id == statusCode("submitted") )
                count.noChange++;
            else if ( entry.status_id == statusCode("approved") )
                count.approved++;
            else if ( entry.status_id == statusCode("rejected") )
            {
                if ( !entry.approver_comment )
                    count.comment_missing++;

                count.rejected++;
            }
        });

        count.changed = count.approved + count.rejected;

        return count;
    }

    addApproverComment( index )
    {   
        this.setState({ 
            commentText: this.getApproverComment( index ),
            commentEntryIndex: index,
        }, () => {
            if( this.commentInput )
                this.commentInput.focus();
        });
    }

    getApproverComment( rowIndex )
    {
        for( let i = 0; i < this.state.entries.length; i++ )
            if( i == rowIndex )
                return this.state.entries[i].approver_comment ? this.state.entries[i].approver_comment : "";

        return ""; 
    }

    saveComment( comment )
    {
        let entries = this.state.entries.slice();
        for( let i = 0; i < entries.length; i++ )
        {
            // All selected entries
            if( this.state.commentEntryIndex == "selected" )
            {
                if( this.state.selectedEntries.indexOf( i ) != -1 )
                {
                    entries[i].status_id = statusCode("rejected");
                    entries[i].approver_comment = comment;
                }
                
            }
            // Single index 
            else if ( i == this.state.commentEntryIndex )
            {
                entries[i].approver_comment = comment;
                break;
            }
        }

        this.setState({ 
            entries: entries,
            commentText: "",
            commentEntryIndex: null 
        });
    }


    closeComment()
    {
        this.setState({ 
            commentText: "",
            commentEntryIndex: null 
        });
    }

    approveSelected()
    {
        let entries = this.state.entries.slice();
        entries.map( ( entry, index ) => {
            if( this.state.selectedEntries.indexOf( index ) != -1 )
                entry.status_id = statusCode("approved");
            
            return entry;
        });
        this.setState({ entries });
    }

    rejectSelected()
    {
        this.setState({ 
            commentText: "",
            commentEntryIndex: "selected",
        }, () => {
            if( this.commentInput )
                this.commentInput.focus();
        });   
    }

    undoSelected()
    {
        let entries = this.state.entries.slice();
        entries.map( ( entry, index ) => {
            if( this.state.selectedEntries.indexOf( index ) != -1 )
                entry.status_id = statusCode("submitted");

            return entry;
        });
        this.setState({ entries });
    }

    confirm()
    {

        //console.log( 'CONFIRM: ', this.state.entries );

        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'hour/approval/confirm',
            data: {
                entries: this.state.entries,
            },
        }).then(( response ) => {

            //console.log( 'confirmed', response );
            //this.setState({ loading: false });
            this.getSubmittedEntries();

        }).catch((error) => {
            console.error(error);
            this.setState({ loading: false });
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>Virhe vahvistamisessa</strong>,
            });
        });
    }

    render() 
    {
        // const hideUser = this.state.selectedUsers.length == 1 ? true : false;
        // const hideProject = this.state.selectedProjects.length == 1 ? true : false;

        const statusCount = this.getStatusCount();

        const types = [
            { id: "hour", icon: "clock", label: "Työtunnit" },
            { id: "travel", icon: "car", label: "Matkat"},
            { id: "expense", icon: "receipt", label: "Kulukorvaukset" },
            { id: "allowance", icon: "suitcase", label: "Työmatkakorvaukset" },
            { id: "absence", icon: "ban", label: "Poissaolot" },
        ];

        let noRowsMessage = false;

        if( this.state.loading )
            noRowsMessage = { 
                icon: "hourglass-half", 
                title: "Ladataan...",
            };

        else if( this.state.entries.length == 0 )
            noRowsMessage = { 
                icon: "thumbs-up", 
                title: "Ei hyväksyttäviä rivejä",
                description: "Kaikki vastuullasi olevat rivit on hoidettu.",
            };

        else if( this.state.days.length == 0 )
            noRowsMessage = { 
                icon: "eye-slash", 
                title: "Ei näytettäviä rivejä",
                description: <span>Kaikki rivit suodatettu pois näkyvistä. <span className="link" onClick={ this.clearFilters }>Poista suodatus</span> </span>,
            };


        return (
            <div id="HoursApproval">
                <div className="apBox">
                
                    <div className="apBoxHeader">
                       <h1>Työaikojen hyväksyntä</h1>
                       <p>Tarkista alaisten kirjaamat työtunnit ja hyväksy tai hylkää ne. </p>
                    </div>

                     
                    <ApStickyBar zIndex={1}>

                        <div className="filterBar">
                            
                            <div className="filter title">
                                <SvgIcon className="small-inline" icon="filter" type="solid" />
                                <span>Suodata:</span>

                                { this.state.activeFilters > 0 && 
                                    <span className="apBadge">{ this.state.activeFilters }</span>
                                }
                        
                            </div>

                            <div className={"filter types" + ( this.state.selectedTypes.length > 0 ? " active" : "" ) }>
                                <div className="apOptionBar">
                                    { types.map( ( type, index ) => {  

                                        let optionClass = [ "option" ];

                                        if( this.state.selectedTypes.indexOf( type.id ) == -1 )
                                            optionClass.push( "disabled" );

                                        return (
                                            <div className={ optionClass.join(" ") } onClick={ () => this.toggleType( type.id ) } style={{ cursor: "pointer" }}>
                                                <ApTooltip text={ type.label } block position="bottom">
                                                    <SvgIcon icon={ type.icon } type="solid" />
                                                </ApTooltip>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            <div className={ "filter users" + ( this.state.showUserFilter ? " open" : "" ) + ( this.state.selectedUsers.length > 0 ? " active" : "" ) } ref={ node => this.usersRef = node }>
                                <div className="button" onClick={ this.toggleUserFilters }>
                                    <SvgIcon icon="user" type="solid" />
                                    { this.state.selectedUsers.length > 0 &&
                                        <span>{ pluralizeCount( this.state.selectedUsers, "käyttäjä", "käyttäjää" ) } valittu</span>
                                    }
                                    { this.state.selectedUsers.length == 0 &&
                                        <span>Kaikki käyttäjät</span>
                                    }
                                </div>
                                <div className="box users">
                                    <div className="padding scroller">
                                        { this.state.users.map( ( user, index ) => {

                                            let classes = [ "item", "user" ];

                                            if( this.state.selectedUsers.indexOf( user.id ) != -1 )
                                                classes.push( "selected" );

                                            let image = "/img/user_default.jpg";
                                            if( keyExists( user, "details.person_detail.image.file", true ) )
                                                image = user.details.person_detail.image.file;

                                            return (
                                                <div key={ "user" + index } className={ classes.join(" ") } onClick={ () => this.toggleUser( user.id ) }>
                                                    <div className="image" style={{ backgroundImage: "url(" + image + ")" }}></div>
                                                    <div className="name">{ keyExists( user, "details.person_details" ) ? user.details.person_detail.first_name + " " + user.details.person_detail.last_name : "Tuntematon henkilö" }</div>
                                                    <div className="count">{ user.count }</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className={ "filter projects" + ( this.state.showProjectFilter ? " open" : "" ) + ( this.state.selectedProjects.length > 0 ? " active" : "" ) } ref={ node => this.projectsRef = node }>
                                <div className="button" onClick={ this.toggleProjectFilters }>
                                    <SvgIcon icon="sitemap" type="solid" />
                                    { this.state.selectedProjects.length > 0 &&
                                        <span>{ pluralizeCount( this.state.selectedProjects, "projekti", "projektia" ) } valittu</span>
                                    }
                                    { this.state.selectedProjects.length == 0 &&
                                        <span>Kaikki projektit</span>
                                    }
                                </div>
                                <div className="box projects">
                                    <div className="padding scroller">
                                        { this.state.projects.map( ( project, index ) => {

                                            let classes = [ "item", "project" ];

                                            if( this.state.selectedProjects.indexOf( project.id ) != -1 )
                                                classes.push( "selected" );

                                            return (
                                                <div key={ "project" + index } className={ classes.join(" ") } onClick={ () => this.toggleProject( project.id ) }>
                                                    <div className="name">
                                                        { project.id && 
                                                            <div>
                                                                { project.details.name }<br />
                                                                <small>{ project.details.project_code }</small>
                                                            </div>
                                                        }
                                                        { !project.id &&
                                                            <div>Ei projektia</div>
                                                        }
                                                    </div>
                                                    <div className="count">{ project.count }</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="selectedCount">
                              <small>Valittu</small><br />
                              { this.state.selectedEntries.length }/{ this.state.entries.length }
                            </div>

                            <ApDropdown
                                actions={[
                                    {
                                        divider: "Muokkaa valintaa"
                                    },
                                    { 
                                        icon: "check-square",
                                        label: "Valitse kaikki",
                                        action: this.selectAll,
                                    },
                                    { 
                                        icon: "minus-square",
                                        label: "Valitse näkyvät",
                                        //disabled: rows.length == 0, 
                                        action: this.selectVisible,
                                    },
                                    { 
                                        icon: "square",
                                        label: "Poista valinta",
                                        disabled: this.state.selectedEntries.length == 0,
                                        action: this.clearEntrySelection,
                                    },
                                    {
                                        divider: "Muokkaa valittuja (" + pluralizeCount( this.state.selectedEntries, "rivi", "riviä" ) + "): "
                                    },
                                    { 
                                        icon: "check",
                                        label: "Hyväksy",
                                        disabled: this.state.selectedEntries.length == 0,
                                        action: ( id, close ) => {
                                            this.approveSelected();
                                            close();
                                        }
                                    },
                                    { 
                                        icon: "times",
                                        label: "Hylkää",
                                        disabled: this.state.selectedEntries.length == 0,
                                        action: ( id, close ) => {
                                            this.rejectSelected();
                                            close();
                                        }
                                    },
                                    { 
                                        icon: "question",
                                        label: "Odottaa",
                                        disabled: this.state.selectedEntries.length == 0,
                                        action: ( id, close ) => {
                                            this.undoSelected();
                                            close();
                                        }
                                    },
                                ]}
                            />

                        </div>

                    </ApStickyBar>

                  
                </div>

                { this.state.loading && 
                    <div className="loader" style={{ position: "relative" }}>
                        <div className="apLoader"></div>
                    </div>
                }

                { this.state.days.length > 0 &&
                    <div className="guide">
                        Hyväksy klikkaamalla,<br />
                        Hylkää klikkaamalla uudestaan
                    </div>
                }

                { this.state.days.map( ( day, index ) =>
                    <DayEntries 
                        key={index} 
                        date={ day.date } 
                        entries={ day.entries }
                        selectedEntries={ this.state.selectedEntries }
                        handeRowSelect={ this.toggleEntry }
                        handleStatusChange={ this.rotateStatus }
                        handleCommentClick={ this.addApproverComment }
                     />
                )}

                { !noRowsMessage && this.state.hiddenEntries > 0 &&
                    <div className="hiddenRowsMessage">
                        <SvgIcon icon="eye-slash" />
                        { this.state.hiddenEntries == this.state.entries.length ? "Kaikki" : "Yhteensä" } { this.state.hiddenEntries } rivi{ this.state.hiddenEntries != 1 ? "ä" : "" } suodatettu pois näkyvistä. <span className="link" onClick={ this.clearFilters }>Poista suodatus</span>
                    </div>
                }

                { noRowsMessage && 
                    <div className="noRowsMessage">
                        { noRowsMessage.icon && <SvgIcon icon={ noRowsMessage.icon } type="solid" /> }
                        { noRowsMessage.title && <h4>{ noRowsMessage.title }</h4> }
                        { noRowsMessage.description && <p>{ noRowsMessage.description}</p> }
                    </div>
                }

                

                <div className="apBox">
                    <div className="padding">

                        <div className="stats">
                            <div className="stat waiting">
                                <SvgIcon icon="question" type="solid" />
                                Odotaa:
                                <strong> { statusCount.noChange } kpl</strong>
                            </div>
                            <div className="stat approved">
                                <SvgIcon icon="check" type="solid" />
                                Hyväksyttyjä:
                                <strong> { statusCount.approved } kpl</strong>
                            </div>
                            <div className="stat rejected">
                                <SvgIcon icon="times" type="solid" />
                                Hylättyjä:
                                <strong> { statusCount.rejected } kpl</strong>
                                { statusCount.comment_missing > 0 && 
                                    <span> (joista kommentti puuttuu: { statusCount.comment_missing } kpl)</span>
                                }
                            </div>
                        </div>

                        <div className="apInfo">
                            <SvgIcon icon="info-circle" type="solid" />
                            <strong>HUOM!</strong> Vahvistamista ei voi peruuttaa. Hyväksytyt lukitaan ja hylätyt lähetetään takaisin käyttäjlle korjattavaksi!
                        </div>

                        <ApButton color="blue" onClick={ this.confirm } disabled={ statusCount.changed == 0 || this.state.loading } loading={ this.state.loading }>
                            <SvgIcon icon="stamp" type="solid" />
                            Vahvista muutokset
                        </ApButton>

                    </div>

                </div>

        
                <ApModalInput 
                    show={ this.state.commentEntryIndex !== null }
                    value={ this.state.commentText }
                    onSave={ this.saveComment }
                    onCancel={ this.closeComment }
                    title="Hylkäyksen syy"
                    info={ this.state.commentEntryIndex == "selected" ? "Valittuna " + this.state.selectedEntries.length + " riviä" : undefined }
                />

                <p>
                    TODO lista:
                    <ul>
                        <li>Kuluvanvonnan ajat näkyviin</li>
                        <li>Asiakashyväksymiseen lähettäminen (asiakastyönumerot muokattavissa)</li>
                    </ul>
                </p>

            </div>
        );

    }
}
export default Approve
