/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import { keyExists, dateInputToSql } from 'services/Helpers/Helpers.js';
import { entryIcon, statusText } from  'modules/Timetracking/TimetrackingHelpers.js';

export default class Timetracking extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            loading: false,
            error: false,
            result: false,

            companies: [],

            company_id: '',
            date_start: '',
            date_end: '',
            types: [],
            statuses: [],
        }

        this.types = [
            { icon: entryIcon( "hour" ),        value: "hour",      label: "Tuntikirjaus" },
            { icon: entryIcon( "travel" ),      value: "travel",    label: "Matkakirjaus" },
            { icon: entryIcon( "expense" ),     value: "expense",   label: "Kulukirjaus" },
            { icon: entryIcon( "allowance" ),   value: "allowance", label: "Päiväraha" },
            { icon: entryIcon( "absence" ),     value: "absence",   label: "Poissaolo" },
        ];

        this.statuses = [
            { icon: "question",         value: "suggested",   label: statusText( "suggested" ) },
            { icon: "pen",              value: "open",        label: statusText( "open" ) },
            { icon: "paper-plane",      value: "submitted",   label: statusText( "submitted" ) },
            { icon: "times",            value: "rejected",    label: statusText( "rejected" ) },
            { icon: "check",            value: "approved",    label: statusText( "approved" ) },
            { icon: "check-circle",     value: "confirmed",   label: statusText( "confirmed" ) },
            { icon: "hand-holding-usd", value: "payrolled",   label: statusText( "payrolled" ) },
            { icon: "wallet",           value: "paid",        label: statusText( "paid" ) },
        ];

        autoBind(this);
    }

    componentDidMount()
    {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'management/companies',
        }).then((response) => {

            let companies = [{ value: '', label: ' - Kaikki - ' } ];
            response.forEach( c => {
                companies.push({
                    value: c.id,
                    label: c.name,
                });
            })

            this.setState({ 
                loading: false, 
                companies: companies 
            });
        }).catch((error) => {
            console.error('reset ERROR', error );
            this.setState({ 
                loading: false,
                error: keyExists( error, "response.data.message", true, "Odottamaton virhe!" ) || "VIRHE"
            });
        });
    }

    toggleOption( name, value ) 
    {
        if( this.state.loading )
            return false;

        let selected = this.state[ name ].slice();
        const index = selected.indexOf( value );
        
        if( index != -1 )
            selected.splice( index, 1 );
        else 
            selected.push( value );

        this.setState({ [ name ]: selected });
    }

    resetTotals()
    {
        this.reset( 'totals' );
    }

    resetSalaryCategories()
    {
        this.reset( 'salaryCategories' );
    }

    resetFlexiChange()
    {
        this.reset( 'flexi_change' )
    }

    reset( action )
    {
        this.setState({ loading: true, error: false, result: false });
        api({
            method: 'post',
            url: 'timetracking/reset/entries',
            data: {
                action: action,
                company_id: this.state.company_id,
                date_start: dateInputToSql( this.state.date_start ),
                date_end: dateInputToSql( this.state.date_end ),
                types: this.state.types,
                statuses: this.state.statuses,
            }
        }).then((response) => {
        
            this.setState({ 
                loading: false, 
                result: "Yhteensä " + response + " rivi(ä) korjattu. "
            });

        }).catch((error) => {

            console.error('reset ERROR', error );
            this.setState({ 
                loading: false,
                error: keyExists( error, "response.data.message", true, "Odottamaton virhe!" ) || "VIRHE"
            });

        });
    }

    render()
    {
        return (
            <div id="pageManagementTimetracking">

                <h2>Tuntikirjauksien massakorjaukset</h2>

                <p>Voit tehdä tiettyjä korjauksia järjestelmän tuntikirjauksiin myös jälkikäteen. Voit rajata toiminnon laajuutta alla olevin asetuksin, jolloin pystyt kohdentamaan muutokset vain tiettyihin tuntikirjauksiin. </p>

                <ApInputStack gap="0">
                    <ApAddon width="150" noRightBorder>Yritys: </ApAddon>
                    <ApInput
                        type="select"
                        id="company_id"
                        name="company_id"
                        value={ this.state.company_id }
                        onChange={ (e) => { this.setState({ company_id: e.target.value }) } }
                        disabled={ this.state.loading }
                        options={ this.state.companies }
                    />
                </ApInputStack>

                <ApInputStack gap="0">
                    <ApAddon width="150" noRightBorder>Rajaa aikavälille: </ApAddon>
                    <ApInput
                        type="date"
                        id="date_start"
                        name="date_start"
                        value={ this.state.date_start }
                        onChange={ (e) => { this.setState({ date_start: e.target.value }) } }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }
                    />
                    <ApAddon width="20" noLeftBorder noRightBorder> - </ApAddon>
                    <ApInput
                        type="date"
                        id="date_end"
                        name="date_end"
                        value={ this.state.date_end }
                        onChange={ (e) => { this.setState({ date_end: e.target.value }) } }
                        loading={ this.state.loading }
                        disabled={ this.state.loading }
                    />
                    <ApAddon 
                        width="70" 
                        gapLeft="10" 
                        onClick={ () => this.setState({ date_start: '', date_end: '' }) }
                        tooltip="Tyhjennä rajaus"
                    >
                        <SvgIcon icon="times-circle" type="solid" />
                    </ApAddon>
                </ApInputStack>

                <h6>Kirjauksen tyyppi:</h6>
                <div className={ "apOptionBar count-" + this.types.length + ( this.state.loading ? " disabled" : "" ) }>
                    { this.types.map( t => 
                        <div 
                            className={ "option" + ( this.state.types.indexOf( t.value ) != -1 ? " selected" : "" ) } 
                            onClick={ () => this.toggleOption( "types", t.value ) }
                        >
                            <SvgIcon icon={ t.icon } type="solid" className="block" />
                            { t.label }
                        </div>
                    )}
                </div>

                <h6>Kirjauksen tila:</h6>
                <div className={ "apOptionBar count-" + this.statuses.length + ( this.state.loading ? " disabled" : "" ) }>
                    { this.statuses.map( s => 
                        <div 
                            className={ "option" + ( this.state.statuses.indexOf( s.value ) != -1 ? " selected" : "" ) } 
                            onClick={ () => this.toggleOption( "statuses", s.value ) }
                        >
                            <SvgIcon icon={ s.icon } type="solid" className="block" />
                            { s.label }
                        </div>
                    )}
                </div>

                <hr />

                <p>Joissain erikoistilanteissa (esim. tuntikirjauksia korjattu kantaan käsin) saattaa tuntien summat heittää, jolloin ne on hyvä laskea uudestaan. </p>

                <ApButton color="blue" onClick={ this.resetTotals } disabled={ this.state.loading }>
                    <SvgIcon icon="calculator" type="solid" />
                    Laske määrät uudestaan
                </ApButton>

                <hr />

                <p>Mikäli yrityksen palkkalajeja muokataan, ne eivät päivity jo aikaisemmin kirjattuihin tuntikirjauksiin, joten mikäli ne halutaan voimaan myös takautuvasti, tulee palkkaljit määrittää uudestaan tällä työkalulla. </p>

                <ApButton color="blue" onClick={ this.resetSalaryCategories } disabled={ this.state.loading }>
                    <SvgIcon icon="money-check" type="solid" />
                    Määritä palkkalajit uudestaan
                </ApButton>

                <hr />

                <p>Laskee uudelleen päivittäiset liukuman vaihtelut. <strong>HUOM: Vain yrityksen valinta rajaa toimintaa!</strong></p>

                <ApButton color="blue" onClick={ this.resetFlexiChange } disabled={ this.state.loading }>
                    <SvgIcon icon="hourglass-half" type="solid" />
                    Laske päivän liukumat uudestaan
                </ApButton>

                <hr />

                { this.state.error && 
                    <div className="apErrorMsg">{ this.state.error }</div>
                }

                { this.state.result &&
                    <div className="apSuccessMsg">{ this.state.result }</div>
                }

            </div>
        );
    }
}
