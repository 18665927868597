import React from 'react';
import autoBind from 'react-autobind';
import { tr } from 'services/Helpers/Helpers.js'
import SvgIcon       from 'common/SvgIcon/SvgIcon.js';
import ApButton      from 'common/ApButton/ApButton.js';
import ApTooltip     from 'common/ApTooltip/ApTooltip.js';
import ApDropdown from 'common/ApDropdown/ApDropdown.js';
import './SaveBox.css';

class SaveBox extends React.Component {

    constructor(props)
    {
        super(props);
        autoBind(this);
    }

    getPaidAction()
    {
        if( this.props.paid ) {
            return {
                action: 'paid',
                text: tr('cancel_payment'),
                icon: 'money-bill-wave',
                color: 'red',
                label: <div className={`apStatusBox error`}>{ tr('cancel_payment') }</div>,
            };
        }else{
            return {
                action: 'paid',
                text: tr('mark_as_paid'),
                icon: 'money-bill-wave',
                color: 'blue',
                label: <div className={`apStatusBox info`}>{ tr('mark_as_paid') }</div>,
            };
        }
    }

    getButtons()
    {
        const status = this.props.status;
        let buttons = [];

        if( this.props.archived )
        {
            buttons.push({
                action: 'archive',
                text: tr('cancel_archive'),
                icon: 'box-open',
                color: 'red',
            });
            return buttons;
        }

        if( status === 'open')
        {
            buttons.push({
                action: 'save_draft',
                text: tr('save'),
                icon: 'save',
                color: 'blue',
            });

            
            buttons.push({
                action: 'offer',
                text: tr("create_order_confirmation/offer"),
                icon: 'calculator',
                color: 'green',
                validation: true,
            });

            buttons.push({
                action: 'save',
                text: tr("create_order"),
                icon: 'truck',
                color: 'green',
                validation: true,
            });
        }
        
        if( status === 'offer')
        {   
            buttons.push({
                action: 'back_to_edit',
                text: tr("return_to_editing"),
                icon: 'edit',
                color: 'white',
                validation: true,
            });

            buttons.push({
                action: 'save',
                text: tr('create_order'),
                icon: 'truck',
                color: 'green',
                validation: true,
            });
        }

        if( [ 'available_none', 'available_some' ].includes( status ) )
        {           
            buttons.push({
                action: 'demand_goto',
                text: tr('order_requirements'),
                icon: 'clipboard-list',
                color: 'white',
            });
        }
        if( [ 'available_some', 'available_all' ].includes( status ) )
        {           
            buttons.push({
                action: 'delivery_goto',
                text: tr('send_goods'),
                icon: 'truck-loading',
                color: 'blue',
            });
        }

        if( status === 'sent_all' )
        {           
            if( this.props.paid )
            {
                buttons.push({
                    action: 'archive',
                    text: tr('archive'),
                    icon: 'archive',
                    color: 'blue',
                });
            }
            else
            {
                buttons.push({
                    action: 'paid',
                    text: tr('mark_as_paid'),
                    icon: 'money-bill-wave',
                    color: 'blue',
                });
            }
        }
        return buttons;
    }


    getMenuActions()
    {
        const status = this.props.status;
        let actions = [];

        if( this.props.archived )
        {
            actions.push({
                action: 'archive',
                text: tr('cancel_archive'),
                label: <div className={`apStatusBox error`}>{ tr('cancel_archive') }</div>,
                icon: 'box-open',
                color: 'red',
            });
            return actions;
        }

       
       

        if( status !== 'open' ) {
            actions.push( this.getPaidAction() );

            if (status !='sent_all'){
                actions.push({
                    action: 'back_to_edit',
                    text: tr("return_to_editing"),
                    icon: 'edit',
                    label: <div className={`apStatusBox info`}>{ tr('return_to_editing') }</div>,
                    color: 'blue',                
                });
            }
     
        }

        if( status === 'open')
        {
            if( this.props.number )
            {
                actions.push({
                    action: 'archive',
                    text: tr('archive'),
                    icon: 'archive',
                    label: <div className={`apStatusBox info`}>{ tr('archive') }</div>,
                    color: 'blue',
                });
            }
            else
            {
                actions.push({
                    action: 'remove',
                    text: tr('delete'),
                    icon: 'trash',
                    label: <div className={`apStatusBox error`}>{ tr('delete') }</div>,
                    color: 'red',
                });
            }
        }

        if( status === 'offer' )
        {
                actions.push({
                    action: 'archive',
                    text: tr('archive'),
                    icon: 'archive',
                    label: <div className={`apStatusBox info`}>{ tr('archive') }</div>,
                    color: 'blue',
                });
                actions.push({
                    action: 'remove',
                    text: tr('delete'),
                    icon: 'trash',
                    label: <div className={`apStatusBox error`}>{ tr('delete') }</div>,
                    color: 'red',
                });

        }
        if( status === 'available_none' || status === 'available_some' || status === 'available_all') 
        {
            if( this.props.number &&  status !== 'available_all' )
            {
                // actions.push({
                //     action: 'revert_order',
                //     text: "Peruuta tilaus",
                //     icon: 'undo',
                //     label: <div className={`apStatusBox error`}>{ 'Peruuta tilaus' }</div>,
                //     color: 'red',
                    
                // });
              

                actions.push({
                    action: 'archive',
                    text: tr('archive_no_send'),
                    icon: 'archive',
                    label: <div className={`apStatusBox info`}>{ tr('archive') }</div>,
                    color: 'blue',
                });
            }
        }

        if(  status  === 'sent_all') 
        {
            if( this.props.number )
            {
                if (this.props.sendedToExt != true) {
                    actions.push({
                        action: 'cancelDeliveries',
                        text: "Peruuta lähetys",
                        icon: 'undo',
                        label: <div className={`apStatusBox error`}>{ tr('cancel_shipments') }</div>,
                        color: 'red',
                    });
                }

                actions.push({
                    action: 'archive',
                    text: tr('archive_unnecessary'),
                    icon: 'archive',
                    label: <div className={`apStatusBox info`}>{ tr('archive') }</div>,
                    color: 'blue',
                });
            }
        }

        actions.push({
            action: 'newProject',
            text: tr('add_new_project'),
            icon: 'project-diagram',
            label: <div className={`apStatusBox info`}>{tr('add_new_project')}</div>,
            color: 'green',
        });


        return actions;

    }

    getMenu()
    {
        const actions = this.getMenuActions();

        let menuActions = [];
        actions.forEach( a => {
            menuActions.push({
                label: a.label,
                icon: a.icon,
                action: ( item, closeFunc ) => {
                    this.props.onSubmit( a.action, a );
                    closeFunc();
                },
            });
        })

        if( menuActions.length > 0 )
        {
            return <ApDropdown
                position="top"
                actions={ menuActions }
                button={ <ApButton className="footerMenu" color="white">
                    <SvgIcon icon="ellipsis-h" type="solid" />
                </ApButton> }
            />
        }
        return null;
    }

    render()
    {
        const buttons = this.getButtons();

        // if( !buttons.length )
            // return null;

        let menu = this.getMenu();

        return <div >
            { buttons.map( ( button, index ) => {
                const icon = ( button.icon ) ? <SvgIcon icon={ button.icon } type="solid" /> : null

                const buttonDom = <ApButton
                    key={ index }
                    className={"save-box-button" + ((this.props.edited && ((button.action === "save_draft") || button.action === "save")  ) ? " highlight" : "")}
                    color={ button.color }

                    onClick={() => { this.props.onSubmit( button.action, button ) } }
                    disabled={ ( button.validation && Boolean( this.props.validatorMessage ) ) || this.props.loading }
                    loading={ this.props.loading }

                > { icon } { button.text }</ApButton>

                if( button.validation )
                {
                    return <ApTooltip position="topright"  key={ index } text={ this.props.validatorMessage }>
                        { buttonDom }
                    </ApTooltip>
                }
                return buttonDom;
            } ) }
            { menu }
        </div>
    }
}

SaveBox.propTypes = {
};

export default SaveBox;
