import React from 'react';
import autoBind from 'react-autobind';
import './Reports.css';
import { tr } from 'services/Helpers/Helpers';
import { ApTab, ApTabs } from 'common/ApTabs/ApTabs';
import ApOptionInput from 'common/ApOptionInput/ApOptionInput';
import { ApAddon, ApInputStack } from 'common/ApInput/ApInput';
import WorkloadReport from './WorkloadReport';


class ProjectReports extends React.Component {

    constructor(props) 
    {
        super(props);
        this.state = {

        };
        autoBind(this); 
    }

    render() 
    {
        return (
            <div className="apBox" id="pageProjectReports">
                <div className="apBoxHeader">
                    <h3>{tr('project_reports')}</h3>
                </div>
                <div className="padding">
                    <ApTabs gap="0">
                        <ApTab icon="list-alt" label={tr('planned_workload')}>
                            <WorkloadReport />
                        </ApTab>
                    </ApTabs>
                </div>
            </div>
        );
    }
}
  
export default ProjectReports;
