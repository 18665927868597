/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';

import moment from 'moment';

import './WeekBrowser.css';
import { tr, capitalize } from 'services/Helpers/Helpers.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { ApInput, ApInputStack } from 'common/ApInput/ApInput.js';


class WeekBrowser extends React.Component {

    constructor(props) 
    {
        super(props);
        
        autoBind(this); 
    }

     /**
     * Get years for the select
     *
     **/
    getYearsList()
    {
        let data = [];
        for (let i = this.props.startYear; i <= this.props.endYear; i++)
        {
            data.push( { value: i, label: i } );
        }
        return data;
    }

    /**
     * Get weeks for the select
     *
     **/
    getWeekList()
    {
        let end = 52;

        if( this.props.year )
            end = parseInt( moment().year( this.props.year ).isoWeeksInYear(), 10 );

        let data = [];
        for (let i = 1; i <= end; i++)
        {
            data.push( { value: i, label: `${capitalize(tr('week'))} ` + i } );
        }
        return data;
    }


    /**
     * Used by next and previous arrows to scroll to next or previous week
     *
     **/
    scrollWeek(direction)
    {
        let week = parseInt(this.props.week, 10);
        let year = parseInt(this.props.year, 10);

        // Get total number of weeks in given year (52 or 53)
        const totalWeeks = moment().year( year ).isoWeeksInYear();
 
        if(direction === 'next')
        {
            if(week === totalWeeks)
            {
                year += 1;
                week = 1;
            }
            else
                week += 1;
        }
        else
        {
            
            if(week === 1)
            {
                //console.log("special case");
                year = year-1;
                week = moment().year( year ).isoWeeksInYear();
            }
            else 
            {
                week -= 1;
            }
        }
        
        this.props.onChange(year, week);
    }

    /**
     * Disable previous or next arrow button if at the end of the selectebles
     *
     **/
    disableWeekScroll(direction)
    {
        if( this.props.disabled )
            return true;

        if( !this.props.year )
            return true;

        // Get total number of weeks in given year (52 or 53)
        const totalWeeks = moment().year( this.props.year ).isoWeeksInYear();
 
        if(direction === 'next')
            return (this.props.week === totalWeeks && this.props.year === this.props.endYear)

        return (this.props.week === 1 && this.props.year === this.props.startYear)
    }

    render() 
    {
        //console.log(this.getYearsList())
        return (
            <div id="WorkhourWeekBrowser">
                
                <div className="arrow prev">
                    <button 
                        className="apSimpleButton"
                        disabled={this.disableWeekScroll('prev')}
                        onClick={() => {this.scrollWeek('prev')}}
                    >
                        <SvgIcon icon="chevron-left" type="solid" />
                    </button>
                </div>

                <div className="currentWeek">

                    <ApInputStack>
                        <ApInput
                            width="40%"
                            type="select"
                            headermode
                            options={ this.getYearsList() }
                            id="selected_year"
                            name="selected_year"
                            value={ this.props.year }
                            onChange={ (e) => { this.props.onChange( e.target.value, this.props.week ) } }
                            align="right"
                            disabled={ this.props.disabled }
                        />
                        <ApInput
                            type="select"
                            headermode
                            options={ this.getWeekList() }
                            id="selected_week"
                            name="selected_week"
                            value={ this.props.week }
                            onChange={ (e) => { this.props.onChange( this.props.year, e.target.value ) } }
                            disabled={ this.props.disabled }
                        />
                    </ApInputStack>

                </div>

                <div className="arrow next">
                    <button 
                        className="apSimpleButton" 
                        disabled={this.disableWeekScroll('next')}
                        onClick={() => {this.scrollWeek('next')}}
                    >
                        <SvgIcon icon="chevron-right" type="solid" />
                    </button>
                </div>

            </div>

        );
    }
}


WeekBrowser.propTypes = {
    year:       PropTypes.number,
    week:       PropTypes.number,
    onChange:   PropTypes.func.isRequired,
};
  
export default WeekBrowser;
