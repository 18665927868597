/* eslint-disable eqeqeq */

// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import {EventEmitter} from 'fbemitter';
import './polyfills.js';
import './main.css';
import 'modules/Storage/common/StorageStyles.css';
import 'react-table/react-table.css';
import 'react-datetime/css/react-datetime.css'
import 'react-calendar-timeline/lib/Timeline.css'
import IntraLayout  from './layout/Layouts/IntraLayout/IntraLayout.js'
import PublicLayout from './layout/Layouts/PublicLayout/PublicLayout.js'
import 'moment/locale/fi';
import 'moment/locale/de';
import 'moment/locale/et';
import {Provider} from "react-redux";
import store from './store/index';
import {createRoot} from "react-dom/client";
import moment from 'moment';
import { availableLanguages } from 'services/Helpers/Helpers';

const container = document.getElementById('app');
const root = createRoot(container);
class App extends React.Component {

    UNSAFE_componentWillMount()
    {
        // Register global EventEmitter
        window.emitter = new EventEmitter();
        
    }

    componentDidMount() {
        let value = window.document.cookie.split('; ').find(row => row.startsWith('language'));
        moment.updateLocale(
            value && value.split('=')[1] && availableLanguages().find(item => item.code === value.split('=')[1])
                ? value.split('=')[1]
                : 'fi',
            {
                week: {
                    dow: 1, // First day of week is Monday
                },
                longDateFormat: {
                    L: 'DD.MM.YYYY'
                }

            });
    }

    render()
    {
        // Login is only public route that does not have public/ prefix. If we wants other public routes
        // without suffix they need to be added here. IntraLayout catches all other routes
        // without public/ prefix. If we go to non existing page and we have not
        // logged in the IntraLayout will redirect us to login page.
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={PublicLayout} />
                    <Route path="/resetpw" component={PublicLayout} />
                    <Route path="/public" component={PublicLayout} />
                    <Route path="/timeattendance" component={PublicLayout} />
                    <Route path="/" component={IntraLayout} />
                </Switch>
            </BrowserRouter>
        );
    }
}

// ReactDOM.render(
//     <Provider store={store}>
//         <App />
//     </Provider>, 
//     document.getElementById('app')
// );

root.render(
    <Provider store={store}>
        <App />
    </Provider>, 
);

//registerServiceWorker();
unregister();
