import React from 'react';


import moment from 'moment';


import faRegular from '../../common/SvgIcon/fontAwesomeRegular.js';
import faSolid from '../../common/SvgIcon/fontAwesomeSolid.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import { ApTabs, ApTab } from '../../common/ApTabs/ApTabs.js';


class DevAllIcons extends React.Component {

    constructor(props) {

        super(props);

        this.state = {

        }

    }

    UNSAFE_componentWillMount() {

        

        //console.log(moment(new Date()));

    }
    getIcon(name,type) {
        return <div style={{flexGrow: 1,width:"14%",marginBottom:8,marginRight:2, textAlign:"center", border:"solid",borderWidth:1 }}>
            {name} <br/>
            <SvgIcon icon={name} type={type} />
        </div>
    }

    render() {
        let solidIcons = [];
        for (let name in faSolid) {
            solidIcons.push(this.getIcon(name,"solid")
                
            );
        }
        let reqularIcons = [];
        for (let name in faRegular) {
            reqularIcons.push(this.getIcon(name, "reqular")
                
            );
        }

        return (

            <div>
                <h2>Iconi esimerkkit</h2>
                <ApTabs>
                    
                    
                    <ApTab icon="ice-cream" label="Solid" >
                        <div className="padding" style={{ display: "flex", flexWrap: "wrap" }}>
                            {solidIcons}

                        </div>
                    </ApTab>
                    <ApTab icon="thumbs-down" label="Regular" >
                        <div className="padding" style={{ display: "flex", flexWrap: "wrap" }}>
                            {reqularIcons}
                        </div>
                    </ApTab>
                </ApTabs>
                
            </div>

        );

    }

}

export default DevAllIcons;
