import Login from 'modules/Login/Login.js'
import ResetPassword from 'modules/Login/ResetPassword/ResetPassword.js'

import PublicInfo from 'modules/PublicInfo/PublicInfo.js'
import PublicTimeAttendance from 'modules/Public/PublicTimeAttendance.js'
import GDPR from 'modules/Public/GDPR.js'
import { tr } from 'services/Helpers/Helpers';

const routes = [
    {
        path: '/login',
        title: tr('login_title'),
        component: Login,
    },
    {
        path: '/public/info',
        title: 'Info',
        component: PublicInfo,
    },
    {
        path: '/public/resetpw/:token',
        title: tr('new_password'),
        component: ResetPassword,
    },
    {
        path: '/timeattendance/:token',
        title: tr('time_attendance'),
        component: PublicTimeAttendance,
    },
    {
        path: '/public/gdpr',
        title: 'GDPR',
        component: GDPR,
    },
];

export default routes;
