import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import api from '../../services/Api/Api.js';
import axios from 'axios';
import { tr } from 'services/Helpers/Helpers.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import nextId      from 'services/NextId/NextId.js';

import './FileUpload.css';

import {
    Row,Col,
    ProgressBar,
    Button,
    Alert,
} from 'react-bootstrap';

class FileUpload extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            uploading: false,
            percent: 0,
            errorMessage: '',
        };

        this.cancelSource = axios.CancelToken.source();

        this.domid = nextId('fileupload');

        autoBind(this);
    }

    upload()
    {
        this.setState({
            uploading: true,
            percent: 0,
            errorMessage: '',
        });

        const onUploadProgress = (progressEvent) => {
            let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            this.setState({percent: percentCompleted});
        };

        let formData = new FormData();
        formData.append('filedata', ReactDOM.findDOMNode(this.refs.uploadInput).files[0]);

        if(this.props.description)
            formData.append('description', this.props.description);

        if(this.props.keep)
            formData.append('keep', true);
        if (this.props.acceptedFiletypes) {
            
            let value = ReactDOM.findDOMNode(this.refs.uploadInput).files[0].name;
            //console.log("formdata", value);
            let filetype = String(value).split(".").pop().toLowerCase();
            //console.log("filetype", filetype);
            if (!this.props.acceptedFiletypes.includes(filetype)) {
                if (typeof this.props.filetypeError === 'function') {
                    this.props.filetypeError();
                }
                this.setState({
                    uploading: false,
                    percent: 0
                });
                return null;
            }
        }
        console.log("formdata", ReactDOM.findDOMNode(this.refs.uploadInput).value);

        return api({
            cancelToken: this.cancelSource.token,
            headers: {'Content-Type': 'multipart/form-data'},
            method: 'post',
            url: 'file/upload',
            data: formData,
            onUploadProgress: onUploadProgress,
        }).then((response) => {
            this.props.onUpload(response);
            this.setState({
                uploading: false,
                percent: 0
            });
        }).catch(response => {
            console.error(response);

            this.setState({
                uploading: false,
                percent: 0,
                errorMessage: (typeof response.data === 'string') ? response.data : '',
            });
        });
    }

    cancel()
    {
        this.cancelSource.cancel('Operation canceled by the user.');
        this.cancelSource = axios.CancelToken.source();
    }

    changeHandler(e)
    {
        if( typeof( this.props.onChange ) === "function" )
        {
            this.props.onChange(e);
        }
    }

    inputFocusChanged( e, focused )
    {
        this.setState({ focused: focused });
        if( e )
        {
            if( typeof( this.props.onFocus ) === "function" && focused )
            {
                this.props.onFocus(e);
            }
            else if ( typeof( this.props.onBlur ) === "function" && !focused )
            {
                this.props.onBlur(e);
            }
        }
    }

    inputClear()
    {
        if( typeof( this.props.onClear ) === "function" )
            this.props.onClear();
    }

    render()
    {
        if(this.state.uploading)
        {
            return <div>
                <Row>
                    <Col xs={9}>
                        <ProgressBar active now={this.state.percent} label={`${this.state.percent}%`}  />
                    </Col>
                    <Col xs={3}>
                        <Button bsSize="xsmall" variant="warning" onClick={this.cancel}>{ tr('cancel') }</Button>
                    </Col>
                </Row>

            </div>
        }

        let labelDom = <label className="fileLabel" htmlFor={ this.props.id ? this.props.id : this.domid }>
            <SvgIcon className="small-inline" icon="file" type="solid" /> 
            { this.props.label ? this.props.label : `${tr('choose_file')}...` }
        </label>

        const value = this.props.value;
        let valueDom = null;
        if( value )
        {
            let valueName = tr('file_selected');
            if( value.name ) valueName = value.name;
            else if( value.original_name ) valueName = value.original_name;

            labelDom = null;
            valueDom = <div className="fileNameContainer">
                &nbsp;<span className="fileName">{ valueName }</span>
                <SvgIcon className="removeFile" icon="times-circle" type="solid" onClick={ this.inputClear } />
            </div>
        }

        const accept  = this.props.accept ? this.props.accept : '*';
        return <div className="fileUpload">
            {Boolean(this.state.errorMessage) && <Alert variant="danger">
                <strong>{ tr('error') }!</strong> {this.state.errorMessage}
            </Alert>}
            <div className="fileWrapper">
                { labelDom }
                { valueDom }
                <input
                    accept={ accept }
                    ref="uploadInput"
                    type="file"
                    id={ this.props.id ? this.props.id : this.domid }
                    name={ this.props.name }

                    disabled={ this.props.disabled }
                    readOnly={ this.props.readOnly }
                    onChange={ this.upload }
                    onFocus={ (e) => { this.inputFocusChanged(e, true); }}
                    onBlur={ (e) => { this.inputFocusChanged(e, false); }}

                />
            </div>
        </div>
    }
}
FileUpload.propTypes = {
    value: PropTypes.object,
    label: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    keep: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClear: PropTypes.func,
    onUpload: PropTypes.func.isRequired,
    description: PropTypes.string.isRequired,
    accept: PropTypes.string,
    acceptedFiletypes: PropTypes.array,
    filetypeError: PropTypes.func,
};
export default FileUpload;
