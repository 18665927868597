import React from 'react';
import autoBind from 'react-autobind';

import ApButton         from 'common/ApButton/ApButton.js';
import ApReactTable     from 'common/ApReactTable/ApReactTable.js';
import api              from 'services/Api/Api.js';
import { errorPopper, tr, timeSince, formatMoney, keyExists }  from 'services/Helpers/Helpers.js';
import ApConfirm        from 'common/ApConfirm/ApConfirm.js';

import SupplierEdit from './SupplierEdit/SupplierEdit.js';
import moment from 'moment';

import { debounce } from 'throttle-debounce';
import auth from 'services/Authed/Authed.js';

import './Crm.css';

import SvgIcon from 'common/SvgIcon/SvgIcon.js';

import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import { ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApModal from 'common/ApModal/ApModal.js';

import NewCrmCompany from 'modules/Crm/NewCompany/NewCrmCompany.js';

class Suppliers extends React.Component {

    constructor(props)
    {
        super(props);
 
        this.state = {
            data: [],
            removeConfirmShow: false,
            removeId: null,

            supplierEditShow: false,
            supplierEditId: null,
            //loput kopsattu
            
            search: '',
            searchLoading: false,

            settingsLoaded: true,
            loading: false,
            customers: [],
            pages: -1,
            groups: [],
            managers: [],
            maxTurnover: 0,
            maxEmploys: 0,

            showPassive: false,

            confirmDeleteIds: false,

            newCompanyShow: false,

            showCRM: false,
            CrmEvents: null
        };
        this.state.columns = this.getColumns();

        autoBind(this);
    }

    componentDidMount()
    {
        this.getSuppliers();
    }

    getSuppliers()
    {
        this.setState({loading: true})
        api({
            method: 'get',
            url: 'storage/suppliers/get',
        }).then((response) => {
            //console.log(response);
            this.setState({
                data: response,
                loading: false,
            })
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
            console.error(error);
            this.setState({
                data: [],
                loading: false,
            })
        });
    }

    handleEditClick( id = null )
    {
        //console.log("Clicked",id);
        this.setState({
            supplierEditShow: true,
            supplierEditId: id,
        });
    }

    removeClicked( id )
    {
        this.setState({
            removeConfirmShow: true,
            removeId: id,
        });
    }

    remove()
    {
        const id = this.state.removeId;
        this.setState({ removeConfirmShow: false });
        api({
            method: 'delete',
            url: `storage/suppliers/remove/${id}`,
        }).then((response) => {
            this.getSuppliers();
        }).catch((error) => {
            errorPopper(error, tr('delete_error'));
        });
    }

    getColumns()
    {
        return [{
            id: 'name',
            Header: tr('name'),
            accessor: 'name',
            onClick: row => this.handleEditClick( row.id ),
        },{
            id: 'taxnumber',
            Header: tr('business_id'),
            accessor: 'taxnumber',
            onClick: row => this.handleEditClick( row.id ),
        },{
            id: 'purchase_order_details',
            Header: tr('info_to_po'),
            accessor: 'purchase_order_details',
            onClick: row => this.handleEditClick( row.id ),
        },{
            id: 'purchase_orders_count',
            Header: tr('number_of_po'),
            accessor: 'purchase_orders_count',
            onClick: row => this.handleEditClick( row.id ),
        },{
            id: 'components_count',
            Header: tr('number_of_priced_components'),
            accessor: 'components_count',
            onClick: row => this.handleEditClick( row.id ),
        }];
    }
    getManagerList() {
        let options = [{ value: '', label: `- ${tr('all')} -` }, { value: "null", label: tr('no_responsible_person') }];
        this.state.managers.forEach(manager => {
            if (manager.active || this.state.showPassive)
                options.push({ value: manager.id, label: manager.name });
        })
        //console.log('managerlist', options)
        return options;
    }
    getGroupList() {
        let options = [{ value: '', label: `- ${tr('all')} -` }];
        this.state.groups.forEach(group => {
            options.push({ value: group.id, label: group.name });
        })
        return options;
    }
    showCompany(company) {
        //console.log("open comppany",company);
        if (("contact_id" in company) && ("id" in company))
            window.emitter.emit('goTo', { pathname: '/crm/company/' + company.id + '/contacts/' + company.contact_id });

        else if ("crm_companies_id" in company && company.crm_companies_id!==null)
            window.emitter.emit('goTo', { pathname: '/crm/company/' + company.crm_companies_id });
        else if ("id" in company && "company_id" in company)
            this.handleEditClick(company.id)
        else if ("id" in company)
            window.emitter.emit('goTo', { pathname: '/crm/company/' + company.id });
        //window.emitter.emit('goTo', { pathname: '/crm/company/' + company.id });
    }
    togglePassiveSwitch() {
        this.setState({ showPassive: !this.state.showPassive });
    }

    render()
    {
        let data =this.state.data.filter(row=>row.is_active||this.state.showPassive);
        return (
            <div className="apBox" id="pageSuppliers">
                <div style={{ height: 50, float: 'right' }}>
                    <div style={{ marginRight: 16 }}>
                        
                        <ApButton
                            styles={{ float: "right" }}
                            color="green"
                            onClick={() => { this.setState({newCompanyShow: true}); }}>
                            {tr('new_supplier')}
                        </ApButton>
                    </div>
                </div>
                {/*<div className="apBoxHeader">
                    <h1>
                        { tr('purchases_suppliers_module') }
                    </h1>
                    <p> { tr('manage_suppliers') } </p>
                    <div className="apBoxCorner">
                        
                    </div>
                </div>*/}
                <div style={{ clear: "both"}}>
                    
                </div>
                
                <div className="padding" style={{ float: 'none' }}>
                    {/*<ApReactTable
                        defaultSorted={[{ id: 'name', desc: false }]}
                        loading={this.state.loading}
                        data={this.state.data}
                        columns={this.state.columns}
                        filterable
                        minRows={10}
                        rowActions={[
                            {
                                icon: "edit",
                                label: tr('edit'),
                                action: this.handleEditClick,
                            },
                            {
                                icon: "trash",
                                label: tr('delete'),
                                action: this.removeClicked,
                            },
                        ]}

                    />*/}
                </div>

                
                    <NewCrmCompany
                    show={this.state.newCompanyShow}
                    onClose={() => this.setState({ newCompanyShow: false })}
                    onSave={(newId) => this.showCompany({ id: newId })}
                    supplier={true}
                    />
                <SupplierEdit
                    show={ this.state.supplierEditShow }
                    onClose={ () => { this.setState({ supplierEditShow: false }) } }
                    supplierId={ this.state.supplierEditId }
                    onSave={ () => { this.getSuppliers() }}
                />

                {this.state.removeConfirmShow && <ApConfirm
                    show={this.state.removeConfirmShow}
                    onClose={() => {this.setState({removeConfirmShow: false})}}
                    onConfirm={ this.remove }
                    header={ tr('delete_supplier_header') }
                    body={ tr('delete_supplier_body') }
                />}

                {this.state.settingsLoaded &&
                    <ApReactTable
                        ref={node => this.tableRef = node}
                        rememberId="crm_companies"
                        //manual
                        //onFetchData={this.getCustomerDataDebounced}
                        pages={this.state.pages}

                        showFiltersInitially
                        loading={this.state.loading}
                        data={data}
                        columns={[
                            {
                                Header: () => {
                                    return (
                                        <div>
                                            <label className="optionSwitch">
                                                {tr('show_inactive')}
                                                <ApSwitch
                                                    id="show-passive-switch"
                                                    on={this.state.showPassive}
                                                    onChange={this.togglePassiveSwitch}
                                                    small
                                                />
                                            </label>
                                        </div>
                                    );
                                },
                                customizable: true,
                                columns: [
                                    {
                                        id: 'name',
                                        Header: tr('name'),
                                        accessor: "name",
                                        onClick: row => this.showCompany(row),
                                        Cell: (props) => {
                                            return (
                                                <div className={"nameCell" + (!props.original.is_active ? " passive" : "")}>
                                                    <strong>{props.original.name}</strong><br />
                                                    <small>{props.original.taxnumber ? props.original.taxnumber : <span style={{ color: "#aaa" }}>{tr('no_business_id')}</span>}</small>
                                                </div>
                                            );
                                        },
                                        customFilter: {
                                            type: "text",
                                            placeholder: tr('enter_name_business_id'),
                                            fields: [ "name", "_original.taxnumber" ]
                                        },
                                    },
                                   {
                                        id: 'purchase_orders_count',
                                        Header: tr('number_of_po'),
                                        accessor: 'purchase_orders_count',
                                        onClick: row => this.showCompany(row),
                                        customizable: true,
                                        showInitially: true,
                                        filterable: false
                                        
                                    }, {
                                        id: 'components_count',
                                        Header: tr('number_of_priced_components'),
                                        accessor: 'components_count',
                                        onClick: row => this.showCompany(row),
                                        customizable: true,
                                        showInitially: true,
                                        filterable: false
                                        
                                    },
                                    /*
                                    { 
                                        id: 'is_active', 
                                        Header: "Tila", 
                                        accessor: "is_active",
                                        align: "center",
                                        width: 150, 
                                        Cell: ( props ) => {
                                            return (
                                                <div className={ "apStatusBox " + ( props.original.is_active ? "success" : "error" ) }>
                                                    { props.original.is_active ? "Aktiivinen" : "Ei käytössä" }
                                                </div>
                                            )
                                        },                   
                                        customizable: true,     
                                        showInitially: true, 
                                        customFilter: { 
                                            type: "select",
                                            options: [
                                                { value: '', label: " - Kaikki - " },
                                                { value: true, label: "Aktiivinen", filter: ( row ) => { return row.is_active } },
                                                { value: false, label: "Ei käytössä", filter: ( row ) => { return !row.is_active } },
                                            ] 
                                        },
                                    },
                                    
                                    {
                                        id: 'account_manager_id',
                                        Header: tr('responsible_person'),
                                        accessor: "account_manager_id",
                                        Cell: (props) => {
                                            return (props.original.account_manager_id
                                                ? <span>{props.original.account_manager.name}</span>
                                                : <span className="empty">-</span>
                                            );
                                        },
                                        customizable: true,
                                        customFilter: {
                                            type: "select",
                                            options: this.getManagerList()
                                        }
                                    },
                                    {
                                        id: 'groups',
                                        Header: tr('customer_group'),
                                        accessor: "groups",
                                        Cell: (props) => {
                                            return (
                                                <div>
                                                    {props.original.groups.map((group, index) => {
                                                        return (
                                                            <div className="group">{group.name}</div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        },
                                        customizable: true,
                                        sortable: false,
                                        showInitially: true,
                                        customFilter: {
                                            type: "select",
                                            options: this.getGroupList()
                                        },
                                    },
                                    {
                                        id: 'industry',
                                        Header: tr('main_business'),
                                        accessor: "industry_id",
                                        Cell: (props) => {
                                            if (props.original.industry)
                                                return (
                                                    <div>
                                                        {props.original.industry.code}:<br />
                                                        <small>{props.original.industry.name}</small>
                                                    </div>
                                                );
                                            return <span className="empty">-</span>;
                                        },
                                        customizable: true,
                                        showInitially: true,
                                        customFilter: {
                                            type: "text",
                                        },
                                    },
                                    {
                                        id: 'turnover',
                                        Header: tr('turnover'),
                                        accessor: "turnover",
                                        align: "right",
                                        Cell: (props) => {
                                            return (props.original.turnover
                                                ? formatMoney(props.original.turnover, 0) + " €"
                                                : <span className="empty">-</span>
                                            );
                                        },
                                        customizable: true,
                                        showInitially: true,
                                        customFilter: {
                                            type: "range",
                                            min: 0,
                                            step: this.state.maxTurnover > 1000000 ? 10000 : this.state.maxTurnover > 100000 ? 1000 : this.state.maxTurnover > 10000 ? 100 : 1,
                                            max: this.state.maxTurnover,
                                            valueRenderer: (value) => { return formatMoney(value[0], 0) + " - " + formatMoney(value[1], 0) + " €" }
                                        }
                                    },
                                    {
                                        id: 'employs',
                                        Header: tr('personnel'),
                                        accessor: "employs",
                                        align: "right",
                                        customizable: true,
                                        customFilter: {
                                            type: "range",
                                            min: 0,
                                            step: this.state.maxEmploys > 1000 ? 10 : 1,
                                            max: this.state.maxEmploys,
                                            valueRenderer: (value) => { return formatMoney(value[0], 0) + " - " + formatMoney(value[1], 0) + ` ${tr('people_abbr')}` }
                                        }
                                    },
                                    {
                                        id: 'lastEvent',
                                        Header: tr('latest_event'),
                                        accessor: "last_event.occurred_at",
                                        align: "center",
                                        className: "overflowableOnHover",
                                        Cell: (props) => {

                                            if (!props.original.last_event)
                                                return <span className="empty">-</span>;

                                            return (
                                                <div>
                                                    <ApTooltip text={
                                                        <div>
                                                            <span>{moment(props.original.last_event.occurred_at).format('DD.MM.YYYY')}:</span><br />
                                                            <strong>
                                                                <SvgIcon className="small-inline" icon={this.eventIcons[props.original.last_event.type]} type="solid" />
                                                                {props.original.last_event.title}
                                                            </strong>
                                                        </div>}>
                                                        {timeSince(props.original.last_event.occurred_at)}
                                                    </ApTooltip>
                                                </div>
                                            );
                                        },
                                        customizable: true,
                                        showInitially: true,
                                        filterable: false,
                                    }, */
                                    {
                                        id: 'verified',
                                        Header: tr('verified'),
                                        accessor: "verified",
                                        width: 100,
                                        className: "overflowableOnHover",
                                        Cell: (props) => {
                                            let icon = "question-circle";
                                            let tooltip = tr('unverified');
                                            let className = "unverified";
                                            if (props.original.verified) {
                                                const daysAgo = moment().diff(moment(props.original.verified), "days");
                                                if (daysAgo > 365 * 5)
                                                    className = "verified over5Years";
                                                else if (daysAgo > 365)
                                                    className = "verified overYear";
                                                else
                                                    className = "verified";

                                                icon = "check-circle";
                                                tooltip = timeSince(props.original.verified);
                                            }
                                            return (
                                                <div className={"iconCell " + className}>
                                                    <ApTooltip text={tooltip}>
                                                        <SvgIcon icon={icon} />
                                                    </ApTooltip>
                                                </div>
                                            );
                                        },
                                        customizable: true,
                                        customFilter: {
                                            type: "select",
                                            options: [
                                                { value: "", label: `- ${tr('all')} -` },
                                                { value: 1, label: tr('verified'), filter: (row, value) => { return row.verified != null; } },
                                                { value: 2, label: tr('unverified'), filter: (row) => { return row.verified==null } },
                                                {
                                                    value: moment().subtract(1, 'week').format('YYYY-MM-DD'),
                                                    label: tr('over_week_ago'),
                                                    filter: (row, value) => { return moment(value).isSameOrAfter(moment(row.verified)); }
                                                },
                                                {
                                                    value: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                                                    label: tr('over_month_ago'),
                                                    filter: (row, value) => { return moment(value).isSameOrAfter(moment(row.verified)); }
                                                },
                                                {
                                                    value: moment().subtract(3, 'month').format('YYYY-MM-DD'),
                                                    label: tr('over_3month_ago'),
                                                    filter: (row, value) => { return moment(value).isSameOrAfter(moment(row.verified)); }
                                                },
                                                {
                                                    value: moment().subtract(6, 'month').format('YYYY-MM-DD'),
                                                    label: tr('over_6month_ago'),
                                                    filter: (row, value) => { return moment(value).isSameOrAfter(moment(row.verified)); }
                                                },
                                                {
                                                    value: moment().subtract(1, 'year').format('YYYY-MM-DD'),
                                                    label: tr('over_year_ago'),
                                                    filter: (row, value) => { return moment(value).isSameOrAfter(moment(row.verified)); }
                                                },
                                                {
                                                    value: moment().subtract(2, 'year').format('YYYY-MM-DD'),
                                                    label: tr('over_2year_ago'),
                                                    filter: (row, value) => { return moment(value).isSameOrAfter(moment(row.verified)); }
                                                },
                                                {
                                                    value: moment().subtract(5, 'year').format('YYYY-MM-DD'),
                                                    label: tr('over_5year_ago'),
                                                    filter: (row, value) => { return moment(value).isSameOrAfter(moment(row.verified)); }
                                                },
                                            ]
                                        },
                                    },
                                    /*{
                                        id: 'projects',
                                        Header: tr('stats_projects'),
                                        accessor: "projects_count",
                                        width: 100,
                                        align: "right",
                                        customizable: true,
                                        filterable: false,
                                    },*/    
                                ],
                            }
                        ]}
                        defaultSorted={[
                            { id: "lastEvent", desc: true }
                        ]}
                        filterable
                        //multiselect={[
                        //    {
                        //        icon: "tags",
                        //        label: "Lisää ryhmään",
                        //        action: (indexes) => this.addToGroup(indexes),
                        //        closeAfter: true,
                        //        disabled: !auth.hasModule('clients.edit'),
                        //    },
                        //    {
                        //        icon: "toggle-on",
                        //        label: tr('activate_selected'),
                        //        action: (indexes) => this.setActive(true, indexes),
                        //        closeAfter: true,
                        //        disabled: !auth.hasModule('clients.edit'),
                        //    },
                        //    {
                        //        icon: "toggle-off",
                        //        label: tr('disable_selected'),
                        //        action: (indexes) => this.setActive(false, indexes),
                        //        closeAfter: true,
                        //        disabled: !auth.hasModule('clients.edit'),
                        //    },
                        //    //{ divider: true },
                        //    //{ icon: "user-tie", label: "Aseta vastuuhenkilö...", action: ( indexes ) => this.setAccountManager( indexes ), closeAfter: true },
                        //    //{ icon: "industry", label: "Aseta päätoimiala...", action: ( indexes ) => this.setIndustry( indexes ), closeAfter: true },
                        //    //{ icon: "tag", label: "Lisää asiakasryhmään...", action: ( indexes ) => this.addToGroups( indexes ), closeAfter: true },
                        //    {
                        //        divider: true
                        //    },
                        //    {
                        //        icon: "trash-alt",
                        //        label: tr('delete_selected'),
                        //        action: (indexes) => this.confirmDelete(indexes),
                        //        closeAfter: true,
                        //        unselectAfter: true,
                        //        disabled: !auth.hasModule('clients.edit.remove'),
                        //    },
                        //]}
                        //rowActions={(row) =>
                        //    <div className="apSimpleButton" onClick={() => this.showCompany(row)}><SvgIcon icon="arrow-right" type="solid" /></div>
                        //}
                        //customRowClass={(row) => {
                        //    return (keyExists(row, "original.is_active", true, true) ? "active" : "notActive");
                        //}}
                        minRows={10}
                    />
                }


            </div>
        );
    }
}

export default Suppliers;
